import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from '../../../shared/configuration/config';
import { api } from '../../../shared/configuration/urls';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../shared/services/authentication/auth-guard.service";
var EstadistiquesService = /** @class */ (function () {
    function EstadistiquesService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
    }
    EstadistiquesService.prototype.getAparells = function (dia) {
        if (this.authGuard.canActivate()) {
            var params = new HttpParams()
                .append('Fecha', dia.toISOString());
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAparellsEstadistiques'], params)
                .pipe(map(function (res) { return res; }));
        }
    };
    EstadistiquesService.prototype.getEstadistiquesSetmanals = function (aparell, dia) {
        var params = new HttpParams()
            .append('IdAparato', aparell || '0')
            .append('Fecha', dia.toISOString());
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticaSetmanal'], params).pipe(map(function (r) { return r; }));
    };
    EstadistiquesService.prototype.getEstadistiquesMensuals = function (aparell, dia) {
        var params = new HttpParams()
            .append('IdAparato', aparell || '0')
            .append('Fecha', dia.toISOString());
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticaMensual'], params);
    };
    EstadistiquesService.prototype.getEstadistiquesAparcareReserves = function (filter) {
        var params = new HttpParams()
            .append('fechaInicioConsulta', filter.fechaInicioConsulta ? this.formatDate(filter.fechaInicioConsulta) : '')
            .append('fechaFinConsulta', filter.fechaFinConsulta ? this.formatDate(filter.fechaFinConsulta) : '');
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasAparcareReservas'], params);
    };
    EstadistiquesService.prototype.getEstadisticasReincidencias = function (reincidencias, fechaInicio, fechaFin, infraccion) {
        var params = new HttpParams()
            .append('reincidencias', reincidencias)
            .append('fechaInicio', fechaInicio ? fechaInicio.toDateString() : '')
            .append('fechaFin', fechaFin ? fechaFin.toDateString() : '')
            .append('infraccion', infraccion ? infraccion.toString() : '');
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasReincidencias'], params);
    };
    EstadistiquesService.prototype.formatDate = function (fecha) {
        var d = new Date(fecha);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();
        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        return [year, month, day].join('/');
    };
    EstadistiquesService.prototype.getEstadistiquesAparcare = function (filter) {
        var params = new HttpParams()
            .append('tipo', filter.tipo)
            .append('tipoValor', filter.tipoValor)
            .append('tipoTemporizacion', filter.tipoTemporizacion)
            .append('fecha', filter.fecha ? this.formatDate(filter.fecha) : '')
            .append('zonaSecureId', filter.zonaSecureId);
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasAparcare'], params);
    };
    EstadistiquesService.prototype.getEstadistiquesDenuncias = function (filter) {
        var params = new HttpParams()
            .append('tipoTemporizacion', filter.tipoTemporizacion)
            .append('fecha', filter.fecha ? this.formatDate(filter.fecha) : '')
            .append('zonaSecureId', filter.zonaSecureId)
            .append('infraccion', filter.infraccion)
            .append('agente', filter.agente)
            .append('anulada', filter.anulada);
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasDenuncias'], params);
    };
    EstadistiquesService.prototype.getEstadistiquesVarios = function (filter) {
        var params = new HttpParams()
            .append('tipoTemporizacion', filter.tipoTemporizacion)
            .append('fecha', filter.fecha ? this.formatDate(filter.fecha) : '')
            .append('aparell', filter.aparell)
            .append('tipo', filter.tipo)
            .append('agente', filter.agente)
            .append('estadoPropuesta', filter.estadoPropuesta);
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasVarios'], params);
    };
    EstadistiquesService.prototype.generateExcelDenuncias = function (filter) {
        var params = new HttpParams()
            .append('tipoTemporizacion', filter.tipoTemporizacion)
            .append('fecha', filter.fecha ? this.formatDate(filter.fecha) : '')
            .append('zonaSecureId', filter.zonaSecureId)
            .append('agente', filter.agente)
            .append('anulada', filter.anulada)
            .append('infraccion', filter.infraccion);
        if (this.authGuard.canActivate()) {
            return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasDenunciasExcel'], params);
        }
    };
    EstadistiquesService.prototype.generateExcelAparcare = function (filter) {
        var params = new HttpParams()
            .append('tipo', filter.tipo)
            .append('tipoValor', filter.tipoValor)
            .append('tipoTemporizacion', filter.tipoTemporizacion)
            .append('fecha', filter.fecha ? this.formatDate(filter.fecha) : '')
            .append('zonaSecureId', filter.zonaSecureId);
        if (this.authGuard.canActivate()) {
            return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasAparcareExcel'], params);
        }
    };
    EstadistiquesService.prototype.generateExcel = function (filter) {
        if (filter.hasOwnProperty('agente')) {
            return this.generateExcelDenuncias(filter);
        }
        else {
            return this.generateExcelAparcare(filter);
        }
    };
    EstadistiquesService.prototype.getDatosAgentes = function (filter) {
        var params = new HttpParams()
            .append('mensual', filter.mensual)
            .append('pdf', filter.pdf)
            .append('dataInici', filter.dataInici ? this.formatDate(filter.dataInici) : '')
            .append('dataFi', filter.dataFi ? this.formatDate(filter.dataFi) : '')
            .append('tipo', filter.tipo)
            .append('agentes', filter.agentes);
        return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasAgents'], params);
    };
    EstadistiquesService.ngInjectableDef = i0.defineInjectable({ factory: function EstadistiquesService_Factory() { return new EstadistiquesService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: EstadistiquesService, providedIn: "root" });
    return EstadistiquesService;
}());
export { EstadistiquesService };
