export enum TravelModeEnum {
    Coche = "Drive",//Viaja en automóvil de pasajeros.
    Bicicleta = "BICYCLE",//Viaja en bicicleta.
    Andando = "WALK",//Viaja a pie.
    Moto="TWO_WHEELER",//Vehículo motorizado de dos ruedas. Por ejemplo, una motocicleta. Ten en cuenta que esto difiere del modo de viaje BICYCLE, que abarca el modo en que funcionan las personas.
    TransportePublico="TRANSIT"
};

export const TravelMode2LabelMapping: Record<TravelModeEnum, string> = {
    [TravelModeEnum.Coche]: "directions_walk",
    [TravelModeEnum.Andando]: "directions_car",
    [TravelModeEnum.Moto]: "directions_bike",
    [TravelModeEnum.Bicicleta]: "pedal_bike",
    [TravelModeEnum.TransportePublico]: "directions_bus"
};
