/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./envios.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i3 from "../../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i4 from "../../../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i5 from "../../../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i6 from "../../../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component.ngfactory";
import * as i7 from "../../../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component";
import * as i8 from "../../../../../../shared/services/authorization/authorization-service";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../components/envios-table/envios-table.component.ngfactory";
import * as i11 from "../../../components/envios-table/envios-table.component";
import * as i12 from "../../../services/envios-service";
import * as i13 from "../../../../../../shared/services/filter/filter.service";
import * as i14 from "@ngx-translate/i18n-polyfill";
import * as i15 from "./envios.component";
import * as i16 from "../../../../../../shared/services/notification/notification.service";
var styles_EnviosComponent = [i0.styles];
var RenderType_EnviosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnviosComponent, data: {} });
export { RenderType_EnviosComponent as RenderType_EnviosComponent };
export function View_EnviosComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { paginator: 0 }), i1.ɵqud(402653184, 2, { sort: 0 }), i1.ɵqud(402653184, 3, { EnviosTable: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 9, "app-toolbar", [], null, null, null, i2.View_ToolbarComponent_0, i2.RenderType_ToolbarComponent)), i1.ɵdid(4, 114688, null, 0, i3.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "app-tool-button", [["color", "has-color-blue"], ["icon", "fa-sync"], ["text", "Actualitzar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(6, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "app-tool-button", [["color", "has-color-orange"], ["icon", "fa-filter"], ["text", "Filtre"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFilterClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(8, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-tool-button", [["color", "has-color-darkgreen"], ["icon", "fa-file-excel"], ["text", "Excel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.generateExcel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(10, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "app-tool-button-permissions", [], null, null, null, i6.View_ToolButtonPermissionsComponent_0, i6.RenderType_ToolButtonPermissionsComponent)), i1.ɵdid(12, 114688, null, 0, i7.ToolButtonPermissionsComponent, [i8.AuthorizationService, i9.MatDialog], { permission: [0, "permission"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-envios-table", [], null, null, null, i10.View_EnviosTableComponent_0, i10.RenderType_EnviosTableComponent)), i1.ɵdid(14, 4833280, [[3, 4], ["table", 4]], 0, i11.EnviosTableComponent, [i12.EnviosService, i13.FilterService, i9.MatDialog, i14.I18n], { updatedFilterData: [0, "updatedFilterData"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_0 = "Actualitzar"; var currVal_1 = "has-color-blue"; var currVal_2 = "fa-sync"; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Filtre"; var currVal_4 = "has-color-orange"; var currVal_5 = "fa-filter"; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = "Excel"; var currVal_7 = "has-color-darkgreen"; var currVal_8 = "fa-file-excel"; _ck(_v, 10, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.authService.Pages.Tramitar; _ck(_v, 12, 0, currVal_9); var currVal_10 = _co.filter; _ck(_v, 14, 0, currVal_10); }, null); }
export function View_EnviosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-envios", [], null, null, null, View_EnviosComponent_0, RenderType_EnviosComponent)), i1.ɵdid(1, 4308992, null, 0, i15.EnviosComponent, [i8.AuthorizationService, i16.NotificationService, i9.MatDialog, i8.AuthorizationService, i14.I18n], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnviosComponentNgFactory = i1.ɵccf("app-envios", i15.EnviosComponent, View_EnviosComponent_Host_0, {}, {}, []);
export { EnviosComponentNgFactory as EnviosComponentNgFactory };
