import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';
import { MarquesVehiclesComponent } from '../pages/marques-vehicles.component';


export class MarquesVehiclesFilter extends FilterData {
    id: string;
    descripcion: string;
    referenciaIntegracion: string;

    constructor(sortField: string, sortDir: SortDirection) {
      super(sortField, sortDir);
      this.id = '';
      this.descripcion = '';
      this.referenciaIntegracion = '';

      this.labels['id'] = this.i18n('Id');
      this.labels['descripcion'] = this.i18n('Descripció');
      this.labels['referenciaIntegracion'] = this.i18n('Referencia Integració');

    }
  }
