import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { TipusTiquets } from '../models/tipus-tiquets.model';

const defaultSortParam = 'dia';
const defaultSortDirection = 'desc';

@Injectable()
export class TipusTiquetsService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setTipusTiquet(tipusTiquet: TipusTiquets): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTipusTiquet'], tipusTiquet);
    }
  }

  removeTipusTiquet(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteTipusTiquet'].replace('{secure_id}', secure_id), null);
    }
  }

  getTipusTiquet(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTipusTiquet'].replace('{secure_id}', secure_id), null);
    }
  }

  publicar(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlPublicarTipusTiquets'], null);
    }
  }

  // generateExcel(filter: ColorFilter, sortDir: string, sortField: string): Observable<any> {
  //   const start = 0;
  //   const pageSize = 1000;

  //   sortField = sortField === undefined ? defaultSortParam : sortField;
  //   sortDir = sortDir === '' ? defaultSortDirection : sortDir;
  //   const params = new ColorsFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

  //   if (this.authGuard.canActivate()) {
  //     return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlColorsExcel'],  params );
  //   }
  // }
}
