import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuditoriaService } from 'path-shared/services/auditoria/auditoria.service';
import { TablasAuditoria } from 'path-shared/models/tablasAuditoria';

@Component({
    selector: 'app-auditoria',
    templateUrl: 'auditoria.component.html',
    styleUrls: ['auditoria.component.scss']
})
export class AuditoriaComponent implements OnInit {
    pantalla: string;
    tablaAuditoria: TablasAuditoria;
    tablasAsociadas = [];
    taulaSeleccionada = null;
    dataSource: MatTableDataSource<any>;
    length = 0;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    constructor(
        public thisDialogRef: MatDialogRef<AuditoriaComponent>,
        private toastr: ToastrService,
        private i18n: I18n,
        private auditoriaService: AuditoriaService,
        @Inject(MAT_DIALOG_DATA) public data: any,
      ) {
          this.pantalla = data.Form;
      }

      ngOnInit() {
        this.auditoriaService.getTablasAsociadas(this.pantalla).subscribe(res => {
          this.tablasAsociadas = res;
          if (this.tablasAsociadas.length === 1) {
            this.taulaSeleccionada = 0;
            this.getTablaAuditoria(0);
          }
        });
      }

      getTablaCount(event) {
        if (event === null) {
          return;
        }
        this.auditoriaService.getTablaCount(this.tablasAsociadas[event].descripcio).subscribe(res => {
          this.length = res;
        });
      }
      getTablaAuditoria (event) {
        if (event === null) {
          return;
        }
        this.taulaSeleccionada = event;
        this.tablaAuditoria = new TablasAuditoria();
        this.auditoriaService.getTabla(this.tablasAsociadas[event].descripcio, this.sort, this.paginator).subscribe(res => {
          this.tablaAuditoria = res;
          if (this.tablaAuditoria !== null) {
            this.dataSource = new MatTableDataSource(this.tablaAuditoria.Rows);
            this.dataSource.sort = this.sort;
            // this.dataSource.paginator = this.paginator;
          }
        });
        this.getTablaCount(event);
      }



      public getValue(row, column) {
        const index = this.tablaAuditoria.Columns.findIndex(x => x === column);
        return row[index];
      }

      applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }

      public Sort(sort) {
        this.getTablaAuditoria(this.taulaSeleccionada);
        // this.auditoriaService.getTabla(this.tablasAsociadas[this.taulaSeleccionada].descripcio, sort, this.paginator).subscribe(res => {
        //   this.tablaAuditoria = res;
        //   if (this.tablaAuditoria !== null) {
        //     this.dataSource = new MatTableDataSource(this.tablaAuditoria.Rows);
        //     this.dataSource.sort = this.sort;
        //   }
        // });
      }

      public Page(page) {
        this.getTablaAuditoria(this.taulaSeleccionada);

        // this.auditoriaService.getTabla(this.tablasAsociadas[this.taulaSeleccionada].descripcio, this.sort, page).subscribe(res => {
        //   this.tablaAuditoria = res;
        //   if (this.tablaAuditoria !== null) {
        //     this.dataSource = new MatTableDataSource(this.tablaAuditoria.Rows);
        //     this.dataSource.sort = this.sort;
        //     this.dataSource.paginator = this.paginator;

        //   }
        // });
      }



}
