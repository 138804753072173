import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';
import { NumberSymbol } from '@angular/common';

export class AliesGruaFilter extends FilterData {
  codi: string;
  descripcio: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.codi = '';
    this.descripcio = '';

    this.labels['codi'] = this.i18n('Codi');
    this.labels['descripcio'] = this.i18n('Descripció');
  }
}
