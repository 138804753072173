var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";
import { HttpParams } from "@angular/common/http";
var defaultSortParam = 'matricula';
var defaultSortDirection = 'asc';
var ActuacioMatriculaFilterService = /** @class */ (function (_super) {
    __extends(ActuacioMatriculaFilterService, _super);
    function ActuacioMatriculaFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ActuacioMatriculaFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('matricula', (filter.matricula ? filter.matricula : ''))
            .append('datafi', filter.datafi == undefined ? '' : typeof (filter.datafi) === 'string' ?
            filter.datafi : filter.datafi.toDateString())
            .append('datainici', filter.datainici == undefined ? '' : typeof (filter.datainici) === 'string' ?
            filter.datainici : filter.datainici.toDateString())
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return ActuacioMatriculaFilterService;
}(CustomFilterService));
export { ActuacioMatriculaFilterService };
