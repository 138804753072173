export class MapFeatures {
    static ACTIVATE_HOVER = 'HOVER_ACTIVE';
    static TRACKING_PDA = 'TRACKING_PDA';
    static DENUNCIES = 'DENUNCIES';
    static DENUNCIES_ZONA_BLAVA = 'DENUNCIES_ZONA_BLAVA';
    static DENUNCIES_POLICIA = 'DENUNCIES_POLICIA';
    static ALARMES_SISTEMA = 'ALARMES_SISTEMA';
    static ALARMES_SMARTPHONES = 'ALARMES_SMARTPHONE';
    static MAQUINES = 'MAQUINES';
    static OCUPACIO = 'OCUPACIO';
    static OCUPACIO_GENERAL = 'OCUPACIO_GENERAL';
    static OCUPACIO_CALOR = 'OCUPACIO_CALOR';
    static OCUPACIO_PARKING = 'OCUPACIO_PARKING';
    static TRACKING_GRUA = 'TRACKING_GRUA';
    static PROPOSTES_GRUA = 'PROPOSTES_GRUA';
    static PROPOSTES_GRUA_REBUTJADES = 'PROPOSTES_GRUA_REBUTJADES';
    static PROPOSTES_GRUA_ACCEPTADES = 'PROPOSTES_GRUA_ACCEPTADES';
    static PROPOSTES_GRUA_PENDENTS = 'PROPOSTES_GRUA_PENDENTS';
    static SENYALS_VERTICALS = 'SENYALS_VERTICALS';
    static PROPOSTES_GRUA_RETIRADES = 'PROPOSTES_GRUA_RETIRADES';
}
