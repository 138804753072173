import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class RespostesFilter extends FilterData {
  codi: string;
  descripcio: string;
  acceptacio: boolean;


  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.codi = '';
    this.descripcio = '';
    this.acceptacio = null;

    this.labels['codi'] = this.i18n('Cód. Respuesta');
    this.labels['descripcio'] = this.i18n('Descripción');
    this.labels['acceptacio'] = this.i18n('Aceptación');

  }
}
