import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';


export class TiquetsFilter extends FilterData {
  id: number;
  fechaTicketInicio: Date;
  fechaTicketFin: Date;
  fechaBancoInicio: Date;
  fechaBancoFin: Date;
  matricula: string;
  email: string;
  zonaSecureId: string;
  
  proveidors: Array<FormMultiSelect>;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.id = null;
    this.matricula = '';
    this.email = '';
    this.fechaTicketInicio = null;
    this.fechaTicketFin = null;
    this.fechaBancoInicio = null;
    this.fechaBancoFin = null; 
    this.zonaSecureId = '';
    this.proveidors = null;

    this.labels['id'] = this.i18n('Id');
    this.labels['matricula'] = this.i18n('Matrícula');
    this.labels['email'] = this.i18n('Email');
    this.labels['fechaTicketInicio'] = this.i18n('Data Tiquet Inici');
    this.labels['fechaTicketFin'] = this.i18n('Data Tiquet Fi');
    this.labels['fechaBancoInicio'] = this.i18n('Data Banc Inici');
    this.labels['fechaBancoFin'] = this.i18n('Data Banc Fi');
    this.labels['zonaSecureId'] = this.i18n('Zona ID');
    this.labels['proveidors'] = this.i18n('Proveidors');

    this.formatOutput['fechaTicketInicio'] = 'shortDate';
    this.formatOutput['fechaTicketFin'] = 'shortDate';
    this.formatOutput['fechaBancoInicio'] = 'shortDate';
    this.formatOutput['fechaBancoFin'] = 'shortDate';
  }

}
