<div class="row">
    <app-form-text-edit id="retirada-num" class="col-xl-4" i18n-label label="Nº retirada" [(value)]="data.numRetirada" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
    <app-form-text-edit id="retirada-import" class="col-xl-4" i18n-label label="Import retirada" [(value)]="data.retiradaImporte" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
    <app-form-date-edit id="retirada-data" class="col-xl-4" i18n-label label="Data retirada" [(value)]="data.fechaRetirada" type="calendar" [isReadOnly]="this.isReadOnly"></app-form-date-edit>
</div>

<div class="row">
    <app-form-text-edit id="retirada-impDip" class="col-xl-4" i18n-label label="Import dipòsit" [(value)]="data.retiradaImporteDeposito" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
    <app-form-text-edit id="retirada-impCob" class="col-xl-4" i18n-label label="Import cobrat" [(value)]="data.retiradaImporteCobrado" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
    <app-form-text-edit id="retirada-liquidacio" class="col-xl-4" i18n-label label="Liquidació" [(value)]="data.retiradaLiquidacion" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
</div>
