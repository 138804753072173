import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';


export class SomPolGruaFilter extends FilterData {
    codi: number;
    dataPropostaPda: Date;
    dataInici: Date;
    dataFinal: Date;
    dataFinalitzacio: Date;
    idVehiclesFK: string;
    idAgentFk: string;
    numCarrer: string;
    nombreCalle: string;
    valida: boolean;
    observacions: string;
    idInfraccioFK: string;
    latitud: number;
    longitud: number;
    finalitzada: boolean;
    numPDA: number;
    origen: string;
    revisada: boolean;
    implicaDenuncia: boolean;
    idTecnicFK: string;
    idMotiuDesestimada: string;
    fechaAcepDenegInici: Date;
    fechaAcepDenegFinal: Date;
    numFotos: number;
    sinCargo: boolean;


  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.codi = null;
    this.dataPropostaPda = null;
    this.dataInici = null;
    this.dataFinal = null;
    this.dataFinalitzacio = null;
    this.idVehiclesFK = '';
    this.idAgentFk = '';
    this.numCarrer = '';
    this.nombreCalle = '';
    this.valida = null;
    this.observacions = '';
    this.idInfraccioFK = '';
    this.latitud = null;
    this.longitud = null;
    this.finalitzada = null;
    this.numPDA = null;
    this.origen = '';
    this.revisada = null;
    this.implicaDenuncia = null;
    this.idTecnicFK = '';
    this.idMotiuDesestimada = '';
    this.fechaAcepDenegInici = null;
    this.fechaAcepDenegFinal = null;
    this.numFotos = null;
    this.sinCargo = null;

    this.labels['codi'] = this.i18n('Codi');
    this.labels['dataPropostaPda'] = this.i18n('Data proposta PDA');
    this.labels['dataInici'] = this.i18n('Data inici');
    this.labels['dataFinal'] = this.i18n('Data final');
    this.labels['dataFinalitzacio'] = this.i18n('Data finalització');
    this.labels['idVehiclesFK'] = this.i18n('Matricula');
    this.labels['idAgentFk'] = this.i18n('Agent');
    this.labels['nombreCalle'] = this.i18n('Carrer');
    this.labels['numCarrer'] = this.i18n('Núm carrer');
    this.labels['valida'] = this.i18n('Vàlida');
    this.labels['observacions'] = this.i18n('Observacions');
    this.labels['idInfraccioFK'] = this.i18n('Infracció');
    this.labels['latitud'] = this.i18n('Latitud');
    this.labels['longitud'] = this.i18n('Longitud');
    this.labels['finalitzada'] = this.i18n('Finalitzada');
    this.labels['numPDA'] = this.i18n('Núm PDA');
    this.labels['origen'] = this.i18n('Origen');
    this.labels['revisada'] = this.i18n('Revisada');
    this.labels['implicaDenuncia'] = this.i18n('Implica Denúncia');
    this.labels['idTecnicFK'] = this.i18n('Tecnic');
    this.labels['idMotiuDesestimada'] = this.i18n('Motiu desestimar');
    this.labels['fechaAcepDenegInici'] = this.i18n('Data inici Acep/Deng');
    this.labels['fechaAcepDenegFinal'] = this.i18n('Data final Acep/Deng');
    this.labels['numFotos'] = this.i18n('Núm fotos');
    this.labels['sinCargo'] = this.i18n('Sense càrrec');


    this.formatOutput['fechaTicketInicio'] = 'shortDate';
    this.formatOutput['fechaTicketFin'] = 'shortDate';
    this.formatOutput['fechaBancoInicio'] = 'shortDate';
    this.formatOutput['fechaBancoFin'] = 'shortDate';
  }

}
