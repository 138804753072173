/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-uploader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./file-uploader.component";
import * as i3 from "@angular/material/dialog";
var styles_FileUploaderComponent = [i0.styles];
var RenderType_FileUploaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploaderComponent, data: {} });
export { RenderType_FileUploaderComponent as RenderType_FileUploaderComponent };
export function View_FileUploaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "Block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.fileChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_FileUploaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-uploader", [], null, null, null, View_FileUploaderComponent_0, RenderType_FileUploaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.FileUploaderComponent, [i3.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileUploaderComponentNgFactory = i1.ɵccf("app-file-uploader", i2.FileUploaderComponent, View_FileUploaderComponent_Host_0, {}, { ficheroCargado: "ficheroCargado" }, []);
export { FileUploaderComponentNgFactory as FileUploaderComponentNgFactory };
