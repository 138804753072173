import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-view-list-icon',
  templateUrl: './view-list-icon.component.html',
  styleUrls: ['./view-list-icon.component.scss']
})
export class ViewListIconComponent implements OnInit {

  @Output() viewClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() listClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() hideView: boolean;
  @Input() hideList: boolean;

  constructor() {
    this.hideView = false;
    this.hideList = false;
  }

  ngOnInit() {
  }

}
