export class Domicili {
    id: number;
    nom: string;
    dataDomicli: Date;
    domicili: string;
    nomComplet: string;
    localitat: string;
    provincia: string;
    actual: boolean;
    idPersones: number;
    // secure_id_persona: boolean;
    origen: string;
    codiPostal: string;
    numero: string;
    escala: string;
    pis: string;
    porta: string;
    dirSimple: string;
    id_extern: string;
    idCarrer: string;
    siglas: string;
    pais: string;
    secure_id: string;
    nombre: string;

    new() {
      this.dirSimple = '';
      this.dataDomicli = new Date();
      this.actual = true;
      this.idPersones = -1;
    }
    Domicili() {
      this.dirSimple = '';
      this.dataDomicli = new Date();
      this.actual = true;
      this.idPersones = -1;

    }
  }
