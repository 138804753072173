import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class AparellFilter extends FilterData {
  id: string;
  descripcion: string;
  tipoRadar: string;
  fechaRevisionInicio: Date;
  fechaRevisionFin: Date;
  fechaCaducidadInicio: Date;
  fechaCaducidadFin: Date;
  margenErrorInicio: number;
  margenErrorFin: number;
  marca: string;
  modelo: string;
  numeroSerie: string;
  codigoCEM: string;
  numeroAntena: string;
  numeroBastidor: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.id = '';
    this.descripcion = '';
    this.tipoRadar = '';
    this.fechaRevisionInicio = null;
    this.fechaRevisionFin = null;
    this.fechaCaducidadInicio = null;
    this.fechaCaducidadFin = null;
    this.margenErrorInicio = null;
    this.margenErrorFin = null;
    this.marca = '';
    this.modelo = '';
    this.numeroSerie = '';
    this.codigoCEM = '';
    this.numeroAntena = '';
    this.numeroBastidor = '';

    this.labels['id'] = this.i18n('Id');
    this.labels['descripcion'] = this.i18n('Descripció');
    this.labels['tipoRadar'] = this.i18n('Tipus radar');
    this.labels['fechaRevisionInicio'] = this.i18n('Data revisió inici');
    this.labels['fechaRevisionFin'] = this.i18n('Data revisió fi');
    this.labels['fechaCaducidadInicio'] = this.i18n('Data caducitat inici');
    this.labels['fechaCaducidadFin'] = this.i18n('Data caducitat fi');
    this.labels['margenErrorInicio'] = this.i18n('Marge error inici');
    this.labels['margenErrorFin'] = this.i18n('Marge error fi');
    this.labels['marca'] = this.i18n('Marca');
    this.labels['modelo'] = this.i18n('Model');
    this.labels['numeroSerie'] = this.i18n('Número sèrie');
    this.labels['codigoCEM'] = this.i18n('Codi CEM');
    this.labels['numeroAntena'] = this.i18n('Número antena');
    this.labels['numeroBastidor'] = this.i18n('Número bastidor');

  }
}
