import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TriarC60Model } from 'app/sections/denuncies/models/tirarC60Model';
import { SOM_C60 } from 'path-models/SOM_C60';

@Component({
  selector: 'app-triar-c60-component',
  templateUrl: './triar-c60-component.component.html',
  styleUrls: ['./triar-c60-component.component.scss']
})
export class TriarC60ComponentComponent implements OnInit {
  SOM_C60_rows: TriarC60Model[];
  constructor( 
    public dialogRef: MatDialogRef<TriarC60ComponentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.SOM_C60_rows= data.rows;
  }

  ngOnInit() {


  }

  SelectC60(currentRow){
    this.dialogRef.close({row: currentRow});
  }

}
