/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/usuaris.component.ngfactory";
import * as i2 from "../components/usuaris.component";
import * as i3 from "./usuaris-page.component";
var styles_UsuarisPageComponent = [];
var RenderType_UsuarisPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UsuarisPageComponent, data: {} });
export { RenderType_UsuarisPageComponent as RenderType_UsuarisPageComponent };
export function View_UsuarisPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-usuaris", [], null, null, null, i1.View_UsuarisComponent_0, i1.RenderType_UsuarisComponent)), i0.ɵdid(1, 114688, null, 0, i2.UsuarisComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_UsuarisPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-usuaris-page", [], null, null, null, View_UsuarisPageComponent_0, RenderType_UsuarisPageComponent)), i0.ɵdid(1, 114688, null, 0, i3.UsuarisPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UsuarisPageComponentNgFactory = i0.ɵccf("app-usuaris-page", i3.UsuarisPageComponent, View_UsuarisPageComponent_Host_0, {}, {}, []);
export { UsuarisPageComponentNgFactory as UsuarisPageComponentNgFactory };
