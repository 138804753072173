import { TipoSenalesVerticalesService } from './../../tipos-senales-verticales/services/tipo-senales-verticales-service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from './../../../../shared/models/component-dialog.model';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { TipoSenalesVerticalesFilter } from '../../tipos-senales-verticales/models/tipo-senales-verticales.filter';
import { SenalesVerticalesFilter } from '../models/senales-verticales.filter';
import { SenalesVerticalesFilterComponent } from '../components/senales-verticales-filter/senales-verticales-filter.component';
import { SenalesVerticalesService } from '../services/senales-verticales.service';
import { SenalesVerticalesTableComponent } from '../components/senales-verticales-table/senales-verticales-table.component';
import { SenalesVerticalesEditorComponent } from '../components/senales-verticales-editor/senales-verticales-editor.component';
import { TipoSenalVertical } from '../../tipos-senales-verticales/models/tipo-senal-vertical';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-senales-verticales-page-component',
  templateUrl: './senales-verticales-page.component.html',
  styleUrls: ['./senales-verticales-page.component.scss']
})
export class SenalesVerticalesPageComponent implements OnInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: SenalesVerticalesFilter;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  tiposSenales: BehaviorSubject<TipoSenalVertical[]>;

  @ViewChild(SenalesVerticalesTableComponent) senalesVerticalesTable: SenalesVerticalesTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private senalesVerticalesService: SenalesVerticalesService,
    private tipoSenalsService: TipoSenalesVerticalesService,
    private i18n: I18n,
    private toastr: ToastrService,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<SenalesVerticalesPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog
  ) {
    this.filterData = new SenalesVerticalesFilter('id', 'desc');
    if (data !== undefined) {
      this.dataDialog = data;
    }

    this.tiposSenales = new BehaviorSubject(undefined);
  }

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.SenyalsVerticals);
    this.senalesVerticalesTable.itemClicked.subscribe(secureId => {
      this.update(secureId);
    });
    this.senalesVerticalesTable.itemSelected.subscribe(id => {
      this.thisDialogRef.close(id);
    });

    // Descargamos la informacion de los tipos de señal.
    this.tipoSenalsService.getAll().subscribe((data: TipoSenalVertical[]) => {
      this.tiposSenales.next(data);
    });

  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Senyals Verticals'));
    }
  }

  edit() {
    const idSeleccionat: string = this.senalesVerticalesService.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secureId: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secureId);
    const ref = this.dialog.open(SenalesVerticalesEditorComponent, {
      width: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'data': data,
        'tiposSenalesVerticales': this.tiposSenales.value
      }
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.senalesVerticalesTable.load(); }
    });
  }

  refresh() {
    this.senalesVerticalesTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(SenalesVerticalesFilterComponent, {
      width: '25%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.senalesVerticalesTable.filter,
        'tiposSenalesVerticales': this.tiposSenales.value
      }
    });

    ref.componentInstance.notify.subscribe((filter: SenalesVerticalesFilter) => {
      this.senalesVerticalesTable.filter.set(filter);
      this.senalesVerticalesTable.load();
    });
  }

  selectCheckedItems() {
    return this.senalesVerticalesService.displayedItems['checkedValues'];
  }

  onRowSelected(id) {
    this.update(id);
  }

  generateExcel() {
    this.senalesVerticalesTable.generateExcel();
  }

  close() {
    this.thisDialogRef.close();
  }

}
