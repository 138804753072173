import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TipoSenalesVerticalesFilter } from './../../models/tipo-senales-verticales.filter';
import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-tipo-senales-verticales-filter',
  templateUrl: './tipo-senales-verticales-filter.component.html',
  styleUrls: ['./tipo-senales-verticales-filter.component.scss']
})
export class TipoSenalesVerticalesFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: TipoSenalesVerticalesFilter;

  @Output() notify: EventEmitter<TipoSenalesVerticalesFilter> = new EventEmitter<TipoSenalesVerticalesFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<TipoSenalesVerticalesFilter>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
