import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificarInicioRequestDto } from './../../models/notificar/notificar-inicio-request.dto';


@Injectable()
export class NotificarFilterService {

    public createFilterParams(filter: NotificarInicioRequestDto) {
        return new HttpParams()
        .append('useC60Double', filter.useC60Double.toString())
        .append('primeraDenuncia', filter.primeraDenuncia.toString());
    }
}
