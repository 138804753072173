/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tiquets-client.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i3 from "../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i4 from "../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i5 from "../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i6 from "../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component.ngfactory";
import * as i7 from "../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component";
import * as i8 from "../../../shared/services/authorization/authorization-service";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../shared/components/filter/filter-selector/filter-selector.component.ngfactory";
import * as i11 from "../../../shared/components/filter/filter-selector/filter-selector.component";
import * as i12 from "./tiquets-client-table/tiquets-client-table.component.ngfactory";
import * as i13 from "./tiquets-client-table/tiquets-client-table.component";
import * as i14 from "../services/tiquets-client.sevice";
import * as i15 from "@ngx-translate/i18n-polyfill";
import * as i16 from "./tiquets-client.component";
import * as i17 from "../../../shared/services/notification/notification.service";
import * as i18 from "../../tiquets/services/tiquets.service";
import * as i19 from "../../../shared/services/filter/filter.service";
import * as i20 from "../../../shared/components/loader/loader.service";
var styles_TiquetsClientComponent = [i0.styles];
var RenderType_TiquetsClientComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TiquetsClientComponent, data: {} });
export { RenderType_TiquetsClientComponent as RenderType_TiquetsClientComponent };
export function View_TiquetsClientComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { paginator: 0 }), i1.ɵqud(402653184, 2, { sort: 0 }), i1.ɵqud(402653184, 3, { tiquetsClientTable: 0 }), i1.ɵqud(402653184, 4, { provCombo: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 9, "app-toolbar", [], null, null, null, i2.View_ToolbarComponent_0, i2.RenderType_ToolbarComponent)), i1.ɵdid(5, 114688, null, 0, i3.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "app-tool-button", [["color", "has-color-blue"], ["icon", "fa-sync"], ["text", "Actualitzar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(7, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "app-tool-button", [["color", "has-color-orange"], ["icon", "fa-filter"], ["text", "Filtre"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFilterClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(9, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "app-tool-button", [["color", "has-color-darkgreen"], ["icon", "fa-file-excel"], ["text", "Excel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.generateExcel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(11, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "app-tool-button-permissions", [], null, null, null, i6.View_ToolButtonPermissionsComponent_0, i6.RenderType_ToolButtonPermissionsComponent)), i1.ɵdid(13, 114688, null, 0, i7.ToolButtonPermissionsComponent, [i8.AuthorizationService, i9.MatDialog], { permission: [0, "permission"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-filter-selector", [], null, null, null, i10.View_FilterSelectorComponent_0, i10.RenderType_FilterSelectorComponent)), i1.ɵdid(16, 114688, null, 0, i11.FilterSelectorComponent, [], { filter: [0, "filter"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "app-tiquets-client-table", [], null, [[null, "currentCount"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("currentCount" === en)) {
        var pd_0 = (_co.setBadgeCount($event, 1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_TiquetsClientTableComponent_0, i12.RenderType_TiquetsClientTableComponent)), i1.ɵdid(18, 4308992, [[3, 4], ["tiquetsClientTable", 4]], 0, i13.TiquetsClientTableComponent, [i14.TiquetsClientService, i9.MatDialog, i15.I18n], { sharedFilter: [0, "sharedFilter"] }, { currentCount: "currentCount" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_0 = "Actualitzar"; var currVal_1 = "has-color-blue"; var currVal_2 = "fa-sync"; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Filtre"; var currVal_4 = "has-color-orange"; var currVal_5 = "fa-filter"; _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = "Excel"; var currVal_7 = "has-color-darkgreen"; var currVal_8 = "fa-file-excel"; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.authService.Pages.Tiquets; _ck(_v, 13, 0, currVal_9); var currVal_10 = _co.filter; _ck(_v, 16, 0, currVal_10); var currVal_11 = _co.filter; _ck(_v, 18, 0, currVal_11); }, null); }
export function View_TiquetsClientComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tiquets-page", [], null, null, null, View_TiquetsClientComponent_0, RenderType_TiquetsClientComponent)), i1.ɵdid(1, 4308992, null, 0, i16.TiquetsClientComponent, [i8.AuthorizationService, i17.NotificationService, i18.TiquetsService, i19.FilterService, i20.LoaderService, i9.MatDialog, i15.I18n], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TiquetsClientComponentNgFactory = i1.ɵccf("app-tiquets-page", i16.TiquetsClientComponent, View_TiquetsClientComponent_Host_0, {}, {}, []);
export { TiquetsClientComponentNgFactory as TiquetsClientComponentNgFactory };
