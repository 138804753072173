import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class TarifasGruaFilter extends FilterData {
  somId: string;
  descripcion: string;
  tipoTarifa: string;
  tipoVehiculo: string;
  importeTarifa: number;
  tipoEstancia: string;
  fechaDesde: Date;
  fechaHasta: Date;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.somId = '';
    this.descripcion = '';
    this.tipoTarifa = '';
    this.tipoVehiculo = '';
    this.importeTarifa = null;
    this.tipoEstancia = '';
    this.fechaDesde = null;
    this.fechaHasta = null;
    this.labels['somId'] = this.i18n('Codi');
    this.labels['tipoTarifa'] = this.i18n('Tipus tarifa');
    this.labels['tipoVehiculo'] = this.i18n('Tipus vehicle');
    this.labels['importeTarifa'] = this.i18n('Import tarifa');
    this.labels['tipoEstancia'] = this.i18n('Tipus estància');
    this.labels['descripcion'] = this.i18n('Descripció');
    this.labels['hora_inicio'] = this.i18n('Data inici');
    this.labels['hora_fin'] = this.i18n('Data fi');

  }
}
