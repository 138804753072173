import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActuacioAgentFilter } from '../models/actuacioAgent-filter';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { ActuacioAgent } from '../models/actuacioAgent.model';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { ActuacioAgentFilterService } from '../services/actuacioAgent-filter.service';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Instituciones } from 'path-shared/models/instituciones';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { MaquinasService } from 'app/sections/zona-blava/maquinas/services/maquinas.service';
import { Marker } from '../../models/marker.model';



@Component({
  selector: 'app-actuacions-agents',
  templateUrl: './actuacions-agents.component.html',
  styleUrls: ['./actuacions-agents.component.scss']
})
export class ActuacionsAgentsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() updatedFilterData: ActuacioAgentFilter;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  public styleMap = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];

  filter: ActuacioAgentFilter = new ActuacioAgentFilter('SOM_ID', 'asc');
  public currentLocation: {
    latitude: number,
    longitude: number
  };
  show: boolean;
  map: any;
  zoomMapa: number;
  markersArray: Array<Marker>;
  agentsList = [];

  dataSource: CustomDataSource<ActuacioAgent, ActuacioAgentFilter>;
  displayedItems: ActuacioAgent[] = [];
  displayedColumns = ['mapa', 'SOM_ID', 'AGENT', 'DATA', 'LONGITUD', 'LATITUD', 'TIPUS_ACTUACIO'];
  constructor(
    public auth: AuthorizationService,
    private maquinesService: MaquinasService,
    private agentsService: AgentsService,
    private dialog: MatDialog,
    private i18n: I18n,
    private parametersService: ParametersService,
    private filterService: FilterService,
  ) { 
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      const institution: Instituciones = JSON.parse(inst);
      this.currentLocation = {latitude: institution.Latitud, longitude: institution.Longitud};
    }
    
    this.zoomMapa = 8;
  }

  ngOnInit() {
    // const zoom = this.parametersService.getParameter(Parametros.GPSZOMINICIAL);
    // if ( zoom !== undefined && zoom !== null ) {
    //   this.zoomMapa = Number.parseInt(this.parametersService.getParameter(Parametros.GPSZOMINICIAL));
    // }
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<ActuacioAgent, ActuacioAgentFilter>(this.paginator, new ActuacioAgentFilterService(), 'urlGetActuacionsAgents', 'urlGetActuacionsAgentsCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach((item, index) => {
        this.displayedItems[index] = {
          SOM_ID: item.SOM_ID,
          AGENT: item.AGENT,
          DATA: item.DATA,
          LATITUD: item.LATITUD,
          LONGITUD: item.LONGITUD,
          TIPUS_ACTUACIO: item.TIPUS_ACTUACIO,
          ID_INSTIT: item.ID_INSTIT
        };
      });
    });
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        this.filterService.saveFilter('actuacions-filter', this.filter);
      }
    });

    this.filterService.loadFilter('actuacions-filter', this.filter);
    this.filter.paginator.init(this.sort, this.paginator);

    this.agentsList = [];
    this.agentsService.getAll().subscribe(data => {
      data.forEach(element => {
        this.agentsList.push({'codi': element.codi })
      });
    });
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();
    // this.load();
  }

  ngOnChanges() {

  }

  load() {
    if (this.dataSource !== undefined) {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.maquinesService, filter, this.i18n('Maquines'), this.sort.direction, this.sort.active)
    });
  }

  onFilterClicked() {

  }
  mapReady(map: any) {
    this.markersArray = [];
    this.map = map;
    // this.loadMarkers();
  }

  // loadMarker(LAT, LON, codi) {
  //   if (LAT != null && LON != null) {
  //     const marker = new google.maps.Marker ({
  //       map: this.map,
  //       clickable: false,
  //       draggable: false,
  //       position: new google.maps.LatLng(LAT, LON),
  //     });
  //     this.markInMap(marker, codi);
  //   }
    
  // }
  toggleAllCheckbox($event) {
    this.displayedItems.filter(x => x.AGENT != null).forEach(item => 
      this.loadMarker(item.LATITUD, item.LONGITUD, item.SOM_ID, item.TIPUS_ACTUACIO),
      this
    )
  }

  loadMarker(LAT, LON, codi, tipusActuacio) {
    if (LAT != null && LON != null) {
      const marker: Marker = {
        lat: LAT,
        lng: LON,
        id: codi,
        label: codi.toString(),
        iconPath: tipusActuacio.ICON_ROUTE
      };
      if (this.markersArray.find(x => x.id == marker.id) !== undefined) {
        this.markersArray = this.markersArray.filter(x => x.id != codi)
      }
      else {
        this.markersArray.push(marker);
      }
      
      var bounds = new google.maps.LatLngBounds();
      this.markersArray.forEach(customMarker => {
        bounds.extend(new google.maps.LatLng(customMarker.lat, customMarker.lng));
      })
      this.map.fitBounds(bounds)
    }
    
  }
  // loadMarkers() {
  //   this.dataSource.getData().forEach(x => {
  //     const marker = new google.maps.Marker ({
  //       map: this.map,
  //       clickable: false,
  //       draggable: false,
  //       position: new google.maps.LatLng(x.LATITUD, x.LONGITUD),
  //     });
  //     this.markInMap(marker)
  //   });
  // }

  // markInMap(marker:google.maps.Marker, codi = null) {
  //   if (!this.markersArray.find(customMarker => customMarker.id == codi)) {
  //     this.markersArray.push({
  //       lat:marker.getPosition().lat,
  //       lng:marker.getPosition().lng,
  //       id:codi,
  //     });
  //   }
  //   else {
  //     this.markersArray = this.markersArray.filter(x => x.id != codi);
  //   }

  //   var bounds = new google.maps.LatLngBounds();
  //     this.markersArray.forEach(customMarker => {
  //       if (customMarker.marker.getPosition()) {
  //         bounds.extend(customMarker.marker.getPosition());
  //       }
  //     })
  //     this.map.fitBounds(bounds)
  // }

  public getMarkerIconColor(codi:any) {
    if (this.markersArray.find(customMarker => customMarker.id == codi)) {
      return "blue";
    }
    else {
      return "gray";
    }
  }

  filterChange(event: any) {
    if (this.filter.agent !== undefined && this.filter.agent !== null && this.filter.agent !== '' ) {
      this.filter.updated(true);
    } else if (this.filter.agent === '' && this.filter.agent !== this.filter.agent) {
      this.filter.agent = '';
      this.filter.updated(true);
    } else {
      this.filter.agent = '';
      this.filter.updated(false);
    }
  }

  showInfo(){
    this.show = true;
    console.log(this.show);

  }

}
