/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i2 from "../../../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i3 from "../../../../../../shared/services/validation/validation.service";
import * as i4 from "./recaptacio.component";
var styles_RecaptacioComponent = [];
var RenderType_RecaptacioComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RecaptacioComponent, data: {} });
export { RenderType_RecaptacioComponent as RenderType_RecaptacioComponent };
export function View_RecaptacioComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-3"], ["id", "recaptacio-num"], ["label", "Num. rebut"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.data.numRecibo = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FormTextEditComponent_0, i1.RenderType_FormTextEditComponent)), i0.ɵdid(2, 4308992, null, 0, i2.FormTextEditComponent, [i3.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"], id: [4, "id"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Num. rebut"; var currVal_1 = _co.data.numRecibo; var currVal_2 = "col-xl-3"; var currVal_3 = _co.isReadOnly; var currVal_4 = "recaptacio-num"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_RecaptacioComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-recaptacio", [], null, null, null, View_RecaptacioComponent_0, RenderType_RecaptacioComponent)), i0.ɵdid(1, 114688, null, 0, i4.RecaptacioComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecaptacioComponentNgFactory = i0.ɵccf("app-recaptacio", i4.RecaptacioComponent, View_RecaptacioComponent_Host_0, { data: "data", isReadOnly: "isReadOnly" }, {}, []);
export { RecaptacioComponentNgFactory as RecaptacioComponentNgFactory };
