import { OnInit, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { AvisosService } from '../../services/avisos.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { AvisosConf } from 'app/sections/configuracio/avisos/models/avisos.model';
import { LoaderService } from 'path-shared/components/loader/loader.service';
/**
 * Node for to-do item
 */
var TodoItemNode = /** @class */ (function () {
    function TodoItemNode() {
    }
    return TodoItemNode;
}());
export { TodoItemNode };
/** Flat to-do item node with expandable and level information */
var TodoItemFlatNode = /** @class */ (function () {
    function TodoItemFlatNode() {
    }
    return TodoItemFlatNode;
}());
export { TodoItemFlatNode };
var ChecklistDatabase = /** @class */ (function () {
    function ChecklistDatabase(avisosService, loaderService) {
        this.avisosService = avisosService;
        this.loaderService = loaderService;
        this.dataChange = new BehaviorSubject([]);
        this.loaderService.show(true);
        this.initialize();
    }
    Object.defineProperty(ChecklistDatabase.prototype, "data", {
        get: function () { return this.dataChange.value; },
        enumerable: true,
        configurable: true
    });
    ChecklistDatabase.prototype.initialize = function () {
        var _this = this;
        // Carreguem carpetes per a passar-les al dialog
        this.avisosService.getCarpetes().subscribe(function (result) {
            var avisos = Object.assign(result);
            var data = _this.buildFileTree(avisos, 0);
            // Notify the change.
            _this.dataChange.next(data);
            _this.loaderService.hide(true);
        }, function (error) {
            _this.loaderService.hide(true);
        });
    };
    ChecklistDatabase.prototype.buildFileTree = function (obj, level) {
        var _this = this;
        return Object.keys(obj).reduce(function (accumulator, key) {
            var value = obj[key].children;
            var node = new TodoItemNode();
            node.item = new AvisosConf(obj[key].id, obj[key].nom, '', obj[key].pareId, obj[key].isFolder, '', false);
            if (value != null) {
                if (typeof value === 'object') {
                    node.children = _this.buildFileTree(value, level + 1);
                }
                else {
                    node.item = value;
                }
            }
            return accumulator.concat(node);
        }, []);
    };
    return ChecklistDatabase;
}());
export { ChecklistDatabase };
var AvisosFilterComponent = /** @class */ (function () {
    function AvisosFilterComponent(thisDialogRef, agentsService, i18n, _database, data) {
        var _this = this;
        this.thisDialogRef = thisDialogRef;
        this.agentsService = agentsService;
        this.i18n = i18n;
        this._database = _database;
        this.data = data;
        /** Map from flat node to nested node. This helps us finding the nested node to be modified */
        this.flatNodeMap = new Map();
        /** Map from nested node to flattened node. This helps us to keep the same object for selection */
        this.nestedNodeMap = new Map();
        /** A selected parent node to be inserted */
        this.selectedParent = null;
        /** The new item's name */
        this.newItemName = '';
        /** The selection for checklist */
        this.checklistSelection = new SelectionModel(true /* multiple */);
        this.agents = [];
        this.assumptesArray = [];
        this.notify = new EventEmitter();
        //#region MatTree functions
        this.getLevel = function (node) { return node.level; };
        this.isExpandable = function (node) { return node.expandable; };
        this.getChildren = function (node) { return node.children; };
        this.hasChild = function (_, _nodeData) { return _nodeData.expandable; };
        this.hasNoContent = function (_, _nodeData) { return _nodeData.item === null; };
        /**
         * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
         */
        this.transformer = function (node, level) {
            var existingNode = _this.nestedNodeMap.get(node);
            var flatNode = existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
            flatNode.item = node.item;
            flatNode.level = level;
            flatNode.expandable = !!node.children;
            _this.flatNodeMap.set(flatNode, node);
            _this.nestedNodeMap.set(node, flatNode);
            return flatNode;
        };
        this.filter = data.filter;
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        _database.dataChange.subscribe(function (dataResult) {
            _this.dataSource.data = dataResult;
        });
    }
    AvisosFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Carreguem els agents per a passar-les al dialog
        this.agentsService.getAllCombo().subscribe(function (result) {
            _this.agents = [];
            if (result !== null) {
                var i_1;
                i_1 = 0;
                result.forEach(function (element) {
                    _this.agents[i_1] = element;
                    i_1++;
                });
            }
            _this.agents.unshift({ 'codi': '' });
        });
        this.subscription = Observable.fromEvent(document, 'keypress').subscribe(function (e) {
            if (e.key === 'Enter') {
                _this.apply();
            }
        });
    };
    AvisosFilterComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    AvisosFilterComponent.prototype.ToggleAviso = function (value) {
        if (value.checked === false) {
            this.assumptesArray.push(value.id);
            this.filter.idTipusAvisList = this.assumptesArray.toString();
            value.checked = true;
        }
        else {
            this.assumptesArray.splice(value.id);
            this.filter.idTipusAvisList = this.assumptesArray.toString();
            value.checked = false;
        }
    };
    AvisosFilterComponent.prototype.reset = function () {
        this.filter.clear();
        this.apply();
    };
    AvisosFilterComponent.prototype.apply = function () {
        this.filter.updated();
        this.notify.emit(this.filter);
        this.thisDialogRef.close();
    };
    AvisosFilterComponent.prototype.dismiss = function () {
        this.thisDialogRef.close();
    };
    /* Get the parent node of a node */
    AvisosFilterComponent.prototype.getParentNode = function (node) {
        var currentLevel = this.getLevel(node);
        if (currentLevel < 1) {
            return null;
        }
        var startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
        for (var i = startIndex; i >= 0; i--) {
            var currentNode = this.treeControl.dataNodes[i];
            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    };
    return AvisosFilterComponent;
}());
export { AvisosFilterComponent };
