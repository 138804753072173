/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cookie-policy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./cookie-policy.component";
var styles_CookiePolicyComponent = [i0.styles];
var RenderType_CookiePolicyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CookiePolicyComponent, data: {} });
export { RenderType_CookiePolicyComponent as RenderType_CookiePolicyComponent };
export function View_CookiePolicyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pol\u00EDtica de cookies"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["pol\u00EDtica de cookies"]))], null, null); }
export function View_CookiePolicyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cookie-policy", [], null, null, null, View_CookiePolicyComponent_0, RenderType_CookiePolicyComponent)), i1.ɵdid(1, 114688, null, 0, i2.CookiePolicyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CookiePolicyComponentNgFactory = i1.ɵccf("app-cookie-policy", i2.CookiePolicyComponent, View_CookiePolicyComponent_Host_0, {}, {}, []);
export { CookiePolicyComponentNgFactory as CookiePolicyComponentNgFactory };
