import { FormControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Dictionary } from 'path-shared/models/dictionary';
import { Observable, BehaviorSubject } from 'rxjs';
import {startWith} from 'rxjs/operators/startWith';
import { map } from 'rxjs/operators/map';
import { ToastrService } from 'ngx-toastr';


export class State {
  constructor(public name: string, public population: string, public flag: string) { }
}
@Component({
  selector: 'app-combo-box-input-autocomplete',
  templateUrl: './combo-box-input-autocomplete.component.html',
  styleUrls: ['./combo-box-input-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboBoxInputAutocompleteComponent implements OnInit {

  /*_valorAutoComplete: any;
  @Input() get valorAutocomplete() {
    return this._valorAutoComplete;
  } set valorAutoComplete(val) {
    this._valorAutoComplete = val;
  }*/

  /*_valorAutocomplete: any;
  @Input() get valorAutocomplete() {
    return this._valorAutocomplete;
  } set valorAutocomplete(val) {
    this._valorAutocomplete = val;
  }*/
  @Input() valorAutocomplete: BehaviorSubject<string>;
  _options: Dictionary[];
  @Input() get options() {
    return this._options;
  } set options(val) {
    this._options = val;
    this.defineOptions();
  }
  @Input() label: string;
  @Input() required: boolean;
  @Input() showColor: boolean;
  @Input() functions: Dictionary[];
  @Input() elementsDisableds: string[];
  _disableComponent: boolean;
  @Input() get disableComponent() {
    return this._disableComponent;
  } set disableComponent(val) {
    this._disableComponent = val;
    if (val === undefined || val === null || !val ) {
      this.control.enable();
    } else {
      this.control.disable();
    }
  }

  @Input() colorsKey: Dictionary[];
  /*
    elementsDisableds
    És l'array amb les KEYS dels items que quedaràn deshabilitats
  */
  @Input() mensajeDeshabilitado: string;
/*
    mensajeDeshabilitado
    És el missatge que sortirà quan es fa click a un element deshabilitat
  */
  @Output() valorSeleccionado: EventEmitter<any>;
  @Output() funcionSeleccionada: EventEmitter<any>;

  filteredOptions: Observable<any[]>;

  control: FormControl = new FormControl();

  constructor(
    private toastr: ToastrService
  ) {
    this.required = false;
    this.valorSeleccionado = new EventEmitter<any>();
    this.funcionSeleccionada = new EventEmitter<any>();
    this.valorAutocomplete = new BehaviorSubject<string>('');
  }

  ngOnInit() {
    this.defineOptions();
    if (this.disableComponent !== undefined && this.disableComponent !== null && this.disableComponent) {
      this.control.disable();
    }

  }

  defineOptions() {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  valueMapper = (key) => {
    if ( this.options !== undefined && this.options !== null && this.options.length > 0 ) {
      const selection = this.options.find(c => c.key === key);
      if (selection) {
        return selection.value;
      }
    }
  }

  valueSelected(optionSelected: any): void {
    this.valorSeleccionado.emit(optionSelected.value);
  }
  valueSelectedDirect(optionSelected: any): void {
    this.valorSeleccionado.emit(optionSelected);
  }

  functionPressed(functionKey: any): void {
    this.funcionSeleccionada.emit(functionKey);
  }

  private _filter(option: string): any {
    if (option !== undefined && option !== null && option !== '' && this.options  !== undefined && this.options  !== null) {
      const filterValue = option.toLowerCase();
      return this.options.filter(x => x.value.toLowerCase().includes(filterValue));
    } else {
      return this.options;
    }
  }

  isRequired() {
    return this.required;
  }

  isDisabled(key: string): boolean {
    let disabled = false;
    if ( this.elementsDisableds !== undefined && this.elementsDisableds !== null && this.elementsDisableds.length > 0 ) {
      const value = this.elementsDisableds.find( x => x === key);
      if (value !== undefined && value !== null) {
        disabled = true;
      }
    }

    return disabled;
  }

  getColor(key: string): string {
    let color = '#000000';
    if (this.colorsKey !== undefined && this.colorsKey !== null) {
      const index = this.colorsKey.findIndex(x => x.key === key);
      if ( index !== -1) {
        color = this.colorsKey[index].value;
      }
    }
    return color;
  }

  clickOption(key: string) {
    if ( this.isDisabled(key) && this.mensajeDeshabilitado !== undefined && this.mensajeDeshabilitado !== null) {
      this.toastr.warning(this.mensajeDeshabilitado);
    }
    const lowerCaseValue = this.control.value.toLowerCase();
    if (this.options.filter(x => x.key.toLowerCase().includes(lowerCaseValue)).length === 1) {
      if ( this.control.value !== this.valorAutocomplete.value) {
        const selectedOption = this.options.filter(x => x.key.toLowerCase().includes(lowerCaseValue))[0];
      //  this.setValueComb(selectedOption.value);
        this.valueSelectedDirect(selectedOption.key);
      }
    }
  }

  selectedOption(key: string) {
    let selectedOption: Dictionary;

    if ( this.isDisabled(key) && this.mensajeDeshabilitado !== undefined && this.mensajeDeshabilitado !== null) {
      this.toastr.warning(this.mensajeDeshabilitado);
    }

    if (this.options.filter(x => x.key.toLowerCase().includes(key.toLowerCase())).length === 1) {
      selectedOption = this.options.filter(x => x.key.toLowerCase().includes(key.toLowerCase()))[0];
      this.valueSelectedDirect(selectedOption.key);
    } else if (this.options.filter(x => x.key.toLowerCase() === key.toLowerCase()).length === 1) {
      selectedOption = this.options.filter(x => x.key.toLowerCase().includes(key.toLowerCase()))[0];
      this.valueSelectedDirect(selectedOption.key);
    }
  }

  setValueComb(option: string): any {
    if (option !== '') {
     // const filterValue = option.toLowerCase();
      this.control.setValue(option);
      // this.valorAutocomplete = new BehaviorSubject<string>(option);
      this.valorAutocomplete.next( Object.assign(new BehaviorSubject<string>(option)));
      // return this.options.filter(x => x.value.toLowerCase().includes(filterValue));
    } /*else {
      return this.options;
    }*/
  }
  setValueCombAux(option: string): any {
    if (option !== '') {
     // const filterValue = option.toLowerCase();
      this.control.setValue(option);
      this.valorAutocomplete = new BehaviorSubject<string>(option);
      // this.valorAutocomplete.next( Object.assign(new BehaviorSubject<string>(option)));
      // return this.options.filter(x => x.value.toLowerCase().includes(filterValue));
    } /*else {
      return this.options;
    }*/
  }
  setForcedValueComb(option: string): any {
      this.control.setValue(option);
      this.valorAutocomplete = new BehaviorSubject<string>(option);
      // Object.assign(this.valorAutocomplete, new BehaviorSubject<string>(option));

      this.valorAutocomplete.next(option);
      this.control.setValue(option);
      this.control.markAsTouched();
  }

  checkSelected() {
    if (this.control.value !== undefined && this.control.value !== null && this.control.value !== '') {
      const lowerCaseValue = this.control.value.toLowerCase();
      if (this.options.filter(x => x.value.toString().toLowerCase().includes(lowerCaseValue)).length === 1) {
        if ( this.control.value !== this.valorAutocomplete.value) {
          const selectedOption = this.options.filter(x => x.value.toLowerCase().includes(lowerCaseValue))[0];
          this.setValueComb(selectedOption.value);
          this.valueSelectedDirect(selectedOption.key);
        } else {

        }
      } else {
        this.resetCombo();
      }
    } else {
      this.resetCombo();
      this.valorSeleccionado.emit('');
    }
  }
  resetCombo() {
    this.control.setValue(null);
    this.defineOptions();
  }
}
