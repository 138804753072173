import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';


export class DesplacatsGruaFilter extends FilterData {

  matricula: string;
  marca: string;
  modelo: string;
  somIdCalle: string;
  dataInici: Date;
  dataFi: Date;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.matricula = '';
    this.marca = '';
    this.modelo = '';
    this.somIdCalle = '';
    this.dataInici = null;
    this.dataFi = null;

    this.labels['matricula'] = this.i18n('Matrícula');
    this.labels['marca'] = this.i18n('Marca');
    this.labels['modelo'] = this.i18n('Model');
    this.labels['somIdCalle'] = this.i18n('Carrer');
    this.labels['dataInici'] = this.i18n('Data inici');
    this.labels['dataFi'] = this.i18n('Data fi');

  }

}
