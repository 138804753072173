import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ExpedientsComponent } from 'app/sections/expedients/expedients.component';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
var IncidenciesBancsTableComponent = /** @class */ (function () {
    function IncidenciesBancsTableComponent(denunciesService, dialog) {
        this.denunciesService = denunciesService;
        this.dialog = dialog;
        /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
        this.displayedColumns = ['exp', 'numdenuncia', 'data', 'hora', 'recibo', 'mensaje'];
        this.highlightedRows = [];
        if (this.dataSource === undefined || this.dataSource === null) {
            this.dataSource = [];
        }
    }
    Object.defineProperty(IncidenciesBancsTableComponent.prototype, "value", {
        get: function () {
            return this.dataSource;
        },
        set: function (val) {
            this.dataSource = val;
        },
        enumerable: true,
        configurable: true
    });
    IncidenciesBancsTableComponent.prototype.ngOnInit = function () {
    };
    IncidenciesBancsTableComponent.prototype.getExpedients = function (expedient, numDenuncia) {
        var _this = this;
        this.denunciesService.getExpedient(expedient).subscribe(function (expedientsData) {
            _this.denunciesService.getDenunciaImages(numDenuncia).subscribe(function (images) {
                _this.expedientsDialog = _this.dialog.open(ExpedientsComponent, {
                    height: '80%',
                    width: '52%',
                    closeOnNavigation: false,
                    autoFocus: true,
                    data: { 'expedient': expedientsData, 'numDenuncia': numDenuncia, 'imatges': images }
                });
            });
        });
    };
    IncidenciesBancsTableComponent.prototype.setDataSource = function (ds) {
        this.dataSource = ds;
    };
    return IncidenciesBancsTableComponent;
}());
export { IncidenciesBancsTableComponent };
