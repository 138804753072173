var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
import * as i0 from "@angular/core";
var defaultSortParam = 'data';
var defaultSortDirection = 'desc';
var DeteccioMatriculesFilterService = /** @class */ (function (_super) {
    __extends(DeteccioMatriculesFilterService, _super);
    function DeteccioMatriculesFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DeteccioMatriculesFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('matricula', filter.matricula ? filter.matricula : '')
            .append('dataInici', Reflection.empty(filter.dataInici) ? null : filter.dataInici.toJSON())
            .append('dataFi', Reflection.empty(filter.dataFi) ? null : filter.dataFi.toJSON())
            .append('idTipusMatricula', Reflection.hasValue(filter.idTipusMatricula) ? filter.idTipusMatricula.toString() : null)
            .append('any', filter.any ? filter.any.toString() : null)
            .append('mes', filter.mes ? filter.mes.toString() : null)
            .append('dia', filter.dia ? filter.dia.toString() : null)
            .append('agent', filter.agent)
            .append('PDA', filter.PDA)
            .append('aparell', filter.aparell)
            .append('comprovacio', filter.comprovacio)
            .append('idDenuncia', filter.idDenuncia ? filter.idDenuncia.toString() : null)
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    DeteccioMatriculesFilterService.prototype.createLoadParams = function (content) {
        return new HttpParams()
            .append('content', content ? content : '');
    };
    DeteccioMatriculesFilterService.ngInjectableDef = i0.defineInjectable({ factory: function DeteccioMatriculesFilterService_Factory() { return new DeteccioMatriculesFilterService(); }, token: DeteccioMatriculesFilterService, providedIn: "root" });
    return DeteccioMatriculesFilterService;
}(CustomFilterService));
export { DeteccioMatriculesFilterService };
