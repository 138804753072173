import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { DipositGruaFilter } from '../Models/diposit-filter';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class DipositGruaFilterService extends CustomFilterService {
  public createFilterParams(filter: DipositGruaFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
if (filter.dataEntradaFinal !== undefined && filter.dataEntradaFinal !== null) {
  filter.dataEntradaFinal.setHours(23, 59, 59);
}
if (filter.dataSortidaFinal !== undefined && filter.dataSortidaFinal !== null) {
  filter.dataSortidaFinal.setHours(23, 59, 59);
}
    return new HttpParams()
    .append('idTipusVehicleFK', (filter.idTipusVehicleFK ? filter.idTipusVehicleFK.toString() : ''))
    .append('idMotiuEntradaFK', (filter.idMotiuEntradaFK ? filter.idMotiuEntradaFK.toString() : ''))
    .append('idTecnicFK', (filter.idTecnicFK ? filter.idTecnicFK.toString() : ''))
    .append('dataEntradaInici',  Reflection.empty(filter.dataEntradaInici) ? null :  typeof(filter.dataEntradaInici) === 'string' ?
    filter.dataEntradaInici : filter.dataEntradaInici.toDateString())
    .append('dataEntradaFinal',  Reflection.empty(filter.dataEntradaFinal) ? null :  typeof(filter.dataEntradaFinal) === 'string' ?
    filter.dataEntradaFinal : filter.dataEntradaFinal.toDateString())
    .append('dataSortidaInici',  Reflection.empty(filter.dataSortidaInici) ? null :  typeof(filter.dataSortidaInici) === 'string' ?
    filter.dataSortidaInici : filter.dataSortidaInici.toDateString())
    .append('dataSortidaFinal',  Reflection.empty(filter.dataSortidaFinal) ? null :  typeof(filter.dataSortidaFinal) === 'string' ?
    filter.dataSortidaFinal : filter.dataSortidaFinal.toDateString())
    .append('matricula', (filter.matricula ? filter.matricula.toString() : ''))
    .append('idDipositList', filter.diposits !== undefined && filter.diposits !== null ? filter.diposits.map(s => s.item_id).toString() : '')
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
