import { config } from "path-shared/configuration/config";
import { api } from "path-shared/configuration/urls";
import { AuthGuardService } from "path-shared/services/authentication/auth-guard.service";
import { HttpSecureClient } from "path-shared/services/http/httpSecureClient";
import { RespondreAlegacio } from "../models/respondre-alegacions.model";
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../shared/services/authentication/auth-guard.service";
var AlegacionsService = /** @class */ (function () {
    function AlegacionsService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
    }
    AlegacionsService.prototype.respondreAlegacions = function (exp, resultat, situacio, tipo) {
        var data = new RespondreAlegacio();
        data.exp = exp;
        data.resultat = resultat;
        data.situacio = situacio;
        data.tipo = tipo;
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlrespondrealegacions'], data);
        }
    };
    AlegacionsService.ngInjectableDef = i0.defineInjectable({ factory: function AlegacionsService_Factory() { return new AlegacionsService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: AlegacionsService, providedIn: "root" });
    return AlegacionsService;
}());
export { AlegacionsService };
