import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/i18n-polyfill";
import * as i2 from "ngx-toastr";
var MessagingService = /** @class */ (function () {
    function MessagingService(i18n, toastr) {
        this.i18n = i18n;
        this.toastr = toastr;
    }
    MessagingService.prototype.noRecordsFound = function (noRecordsFoundText) {
        if (noRecordsFoundText !== undefined && noRecordsFoundText !== null) {
            this.toastr.warning(noRecordsFoundText);
        }
        else {
            this.toastr.warning(this.i18n('No s\'han trobat registres'));
        }
    };
    Object.defineProperty(MessagingService.prototype, "Accept", {
        get: function () {
            return this.i18n('Acceptar');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessagingService.prototype, "Cancel", {
        get: function () {
            return this.i18n('Cancel·lar');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessagingService.prototype, "CancelGeneration", {
        get: function () {
            return this.i18n('Cancel·lar generació');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessagingService.prototype, "GeneratingExcel", {
        get: function () {
            return this.i18n('Generant Excel');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessagingService.prototype, "generatingExcelMessage", {
        get: function () {
            return this.i18n('S\'està generant l\'arxiu excel. Aquesta operació pot tardar una moments. Si us plau, esperi.');
        },
        enumerable: true,
        configurable: true
    });
    MessagingService.ngInjectableDef = i0.defineInjectable({ factory: function MessagingService_Factory() { return new MessagingService(i0.inject(i1.I18n), i0.inject(i2.ToastrService)); }, token: MessagingService, providedIn: "root" });
    return MessagingService;
}());
export { MessagingService };
