import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { MatriculaFilter } from './../models/matricula-filter';
import { Injectable, EventEmitter } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { api } from 'path-shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { Observable } from 'rxjs';
import { Matricula } from '../models/matricula.model';
import { MatriculaFilterService } from './matricules-filter.service';
import { inherits } from 'util';
import { MatriculaLoader } from '../models/matricula-loader.model';
import { HttpParams } from '@angular/common/http';
import { AddMatricula } from '../models/addMatricula.model';


const defaultSortParam = 'tipus';
const defaultSortDirection = 'asc';

@Injectable()
export class MatriculesService implements ExcelGenerator {
    displayedItems = [];

    constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
      this.displayedItems['checkedValues'] = 0;
      this.displayedItems['checkedItems'] = Array();
    }

    getMatricula(secure_id): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMatricula'].replace('{secure_id}', secure_id), null);
      }
    }

    setMatricula(matricula: Matricula): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetMatricula'], matricula);
      }
    }
    createMatricula(matricula: AddMatricula): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlCreateMatricula'], matricula);
      }
    }
    getReports(page): Observable<any> {
      if (this.authGuard.canActivate()) {
        page = page.replace('.', 'xXx');
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlReports'].replace('{page}', page), null);
      }
    }

    generateReport(matriculaID, reportID): Observable<any> {
      const params = new HttpParams()
      .append('matricula', matriculaID)
      .append('report', reportID);

      if (this.authGuard.canActivate()) {
        return this.http.getFileResponse(config.urls['apiUrl'] + api.endpoints['urlGenerateReport'], params );
      }
    }

    deleteMatricula(secure_id): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteMatricula'].replace('{secure_id}', secure_id), null);
      }
    }

    generateExcel(filter: MatriculaFilter, sortDir: string, sortField: string): Observable<any> {
      const start = 0;
      const pageSize = 1000;

      sortField = sortField === undefined ? defaultSortParam : sortField;
      sortDir = sortDir === '' ? defaultSortDirection : sortDir;
      const params = new MatriculaFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

      if (this.authGuard.canActivate()) {
        return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlMatriculesExcel'],  params );
      }
    }

    loadMatricules(data: MatriculaLoader): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlMatriculesLoad'], data );
      }
    }

}
