import { FormBuilder, Validators } from '@angular/forms';
import { OnInit, EventEmitter } from '@angular/core';
import { Dictionary } from 'path-shared/models/dictionary';
var ComboBoxInputComponent = /** @class */ (function () {
    function ComboBoxInputComponent(formBuilder) {
        this.formBuilder = formBuilder;
        this.optionChanged = new EventEmitter();
        this.required = false;
        this.select = this.formBuilder.group({
            privilegeSelect: ''
        });
    }
    Object.defineProperty(ComboBoxInputComponent.prototype, "value", {
        get: function () {
            return this.currentoption.key;
        },
        set: function (val) {
            if (val !== undefined && val !== null) {
                this.setSelect(val);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ComboBoxInputComponent.prototype, "showingOptions", {
        get: function () {
            return this._showingOptions;
        },
        set: function (val) {
            this._showingOptions = val;
            if (this._showingOptions !== undefined && this._showingOptions !== null && this.presetCurrentOption !== undefined) {
                this.setSelect(this.presetCurrentOption);
                this.presetCurrentOption = undefined;
            }
        },
        enumerable: true,
        configurable: true
    });
    ComboBoxInputComponent.prototype.ngOnInit = function () {
        if (this.isRequired()) {
            this.select = this.formBuilder.group({
                privilegeSelect: ['', Validators.required]
            });
        }
        if (this.showingOptions === null || this.showingOptions === undefined) {
            this.showingOptions = new Array();
        }
        this.currentoption = new Dictionary('', '');
    };
    ComboBoxInputComponent.prototype.selectedOption = function (option) {
        this.currentoption = option;
        this.optionChanged.emit(option.key);
    };
    ComboBoxInputComponent.prototype.resetSelecteds = function () {
        this.select.get('privilegeSelect').patchValue([]);
        this.currentoption = null;
    };
    ComboBoxInputComponent.prototype.resetShowingOptions = function () {
        this.resetSelecteds();
        this.presetCurrentOption = undefined;
        this.showingOptions = undefined;
    };
    ComboBoxInputComponent.prototype.setSelect = function (value) {
        this.resetSelecteds();
        if (this.showingOptions !== undefined && this.showingOptions.length > 0) {
            var option = this.showingOptions.find(function (x) {
                return x.key === value;
            });
            this.currentoption = option;
            this.select.get('privilegeSelect').setValue(option);
        }
        else {
            this.presetCurrentOption = value;
        }
    };
    ComboBoxInputComponent.prototype.isRequired = function () {
        return this.required !== false;
    };
    ComboBoxInputComponent.prototype.markAsTouched = function () {
        this.select.get('privilegeSelect').markAsTouched();
    };
    ComboBoxInputComponent.prototype.resetValidation = function () {
        this.select.get('privilegeSelect').markAsPristine();
        this.select.get('privilegeSelect').markAsUntouched();
        this.select.get('privilegeSelect').updateValueAndValidity();
    };
    return ComboBoxInputComponent;
}());
export { ComboBoxInputComponent };
