import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { TerminalsMissatge } from '../models/terminals-missatge.model';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class ComunicacioPdaService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  getAllTerminals(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTerminals'],  null);
    }
  }

  getTerminalsChat(terminals: string[], ultimIdChat?: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      ultimIdChat ? ultimIdChat = ultimIdChat : ultimIdChat = '0';
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetTerminalsChat'].replace('{ultimIdChat}', ultimIdChat), terminals);
    }
  }

  getTerminalChatMissatgesNous(terminalId: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetTerminalChatMissatgesNous'].replace('{terminalId}', terminalId), null);
    }
  }

  setMissatgeTerminals(terminalsMissatge: TerminalsMissatge): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTerminalsChatMissatge'], terminalsMissatge);
    }
  }

  uploadFitxers(formData: FormData): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlUploadFitxersTerminal'], formData);
    }
  }

  getFitxer(idFitxer: number): Observable<HttpResponse<Blob>> {
    if (this.authGuard.canActivate()) {
      return this.http.getFileResponse(config.urls['apiUrl'] + api.endpoints['urlGetFitxerTerminal'].replace('{idFitxer}', idFitxer.toString()), null);
    }
  }

  setMissatgesTerminalsLlegits(terminals: string[]): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetMissatgesTerminalsLlegits'], terminals);
    }
  }
}
