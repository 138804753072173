
export class InfoTancaments {
  Tancaments: number;
  MaxDataTancament: Date;

  constructor() {
    this.Tancaments = 0;
    this.MaxDataTancament = null;
  }
}


