import { PhotoBoxViewerComponent } from 'path-shared/components/photo-box/components/photo-box-viewer/photo-box-viewer.component';
import { OnInit, Component, Input, Output, EventEmitter, AfterViewInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ImagesService } from '../../../shared/services/images/images.service';
import { Proposta } from 'app/sections/propostes/models/proposta.model';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { SOM_FICHEROS } from 'path-models/SOM_FICHEROS';
import { of } from 'rxjs';
import { SugerenciaMatricula } from 'app/sections/propostes/models/SugerenciaMatricula';
import { DOCUMENT } from '@angular/common';
//import { NgxImageCompressService } from 'ngx-image-compress-legacy';

declare const SVG: any;
let draw;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-photo-box',
  templateUrl: './photo-box.component.html',
  styleUrls: ['./_photo-box.component.scss']
})

export class PhotoBoxComponent implements OnInit, AfterViewInit {

  @Input() showLupa: boolean;
  @Input() numDenuncia: string;
  @Input() somId: string;
  @Input() nom: string;
  @Input() proposta: Proposta;
  @Input() botoMatricula: boolean;
  @Input() llistaImatges: SOM_FICHEROS[];
  @Input() imageDiv: string;
  @Input() magnifierRight: number = 25;
  @Input() magnifierTop: number = 100;
  @Output() updated: EventEmitter<any> = new EventEmitter();
  @Input() drawList: any[];
  @Output() drawListChange = new EventEmitter();
  imageSource: string;
  downloadable: boolean;
  fileSource: string;
  video: boolean;
  pdf: boolean;
  llistaImatgesFiltrat: any[];
  group: any;
  scale = 1;
  image: any;

  viewerDialog: MatDialogRef<PhotoBoxViewerComponent>;

  enableZoom: Boolean = true;
  previewImageSrc = '';
  zoomImageSrc = '';
  
  public widthImg = 600;
  public heightImg = 400;
  myImageId = '';
  myResultId = '';
  styleStr = 'width:' + this.widthImg + 'px; height:' + this.heightImg + 'px; bottom:0;  left:0;  right:0;  top:0;  margin:0 auto; border:1px solid #dcdcdc;';//'width:480; height:640; bottom:0;  left:0;  right:0;  top:0;  margin:0 auto; border:1px solid #dcdcdc;'

  //public innerHtml: SafeHtml;
  constructor(
    private dialog: MatDialog,
    private imagesService: ImagesService,
    public sanitizer: DomSanitizer,
    //private imageCompress: NgxImageCompressService,
    @Inject(DOCUMENT) private _document: Document) {
  }

  ngOnInit() {
    if (this.showLupa === undefined || this.showLupa === null) {
      this.showLupa = false;
    }
    this.downloadable = this.nom.toLowerCase().endsWith('.pdf') || this.nom.toLowerCase().endsWith('.txt') || this.nom.toLowerCase().endsWith('.xlsx');
    this.video = this.nom.toLowerCase().endsWith('.mp4');
    this.pdf = this.nom.toLowerCase().endsWith('.pdf');

    this.myImageId = 'myImage' + this.somId;
    this.myResultId = 'myResult' + this.somId;
    this.setStyleZoom();

    if (!this.downloadable && !this.video && !this.pdf) {
      this.imageSource = this.imageUrl(this.somId, this.video ? null : 'M');
      this.zoomImageSrc = this.imageSource;
      this.previewImageSrc = this.imageSource;
    } 
    else {
      this.fileSource = this.fileUrl(this.somId);
    }

    if (this.pdf) {
      this.setInnerHtml(this.fileSource);
    }

    // filtrem les que podem obrir amb showImages()
    this.filtrarImatgesShow();

    if (this.drawList === null || this.drawList === undefined) {
      this.drawList = [];
    }
  }
  cleanURL(oldURL: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustUrl(oldURL);
  }

  setStyleZoom() {  
    let self = this;
    var img = new Image();
    img.src = this.previewImageSrc;
    img.onload = function () {
      img.style.visibility = 'hidden';
    }
  }

  ngAfterViewInit(): void {
    if (this.proposta !== undefined) {
      draw = SVG(this.imageDiv.toString());
      draw.id('draw_' + this.imageDiv.toString());
      draw.addClass('draw_imageDiv');
      this.drawList.push(draw);
      this.initImage(draw);
    }
  }

  public innerHtml: SafeHtml;
  public setInnerHtml(pdfurl: string) {
    this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(
      "<object data='" + pdfurl + "' type='application/pdf' width='420' height='322'>" +
      "Object " + pdfurl + " failed" +
      "</object>");
  }

  imageUrl(somId, suffix = ''): string {

    let url;
    const myObservable = of(this.imagesService.getImageUrl(somId, suffix) + '?' + new Date().getTime());
    const Observer = myObservable.subscribe(data => {
      url = data;
    });

    return url;

  }

  fileUrl(somId): string {
    let url;
    const myObservable = of(this.imagesService.getFileUrl(somId) + '?' + new Date().getTime());
    const Observer = myObservable.subscribe(data => {
      url = data;
    });

    return url;
  }

  showImage() {
    // Tornem a refrescar per si hem afegir imatges al fer crop
    this.filtrarImatgesShow();

    this.viewerDialog = this.dialog.open(PhotoBoxViewerComponent,
    {
      closeOnNavigation: false,
      autoFocus: true,
      width: '90%',
      maxWidth: '1000px',
      data: { NumDenuncia: this.numDenuncia, SomId: this.somId, Name: this.nom, Proposta: this.proposta, botoMatricula: this.botoMatricula, llistaImatges: this.llistaImatgesFiltrat }
    });

    this.viewerDialog.afterClosed().subscribe(result => {
      this.updated.next(result);
      if (this.viewerDialog.componentInstance.cropCara.brightness !== 1) {
        this.imagesService.setBrightness(this.somId, this.viewerDialog.componentInstance.cropCara.brightness).subscribe(() => {
          this.imageSource = this.imageUrl(this.somId, this.video ? null : 'M');

          if (this.proposta !== undefined) {
            const drawAux = SVG.get('#draw_imageDiv_' + this.somId);
            drawAux.image(this.imageSource);
          }
        });
      } 
      else {
        this.imageSource = this.imageUrl(this.somId, this.video ? null : 'M');

        if (this.proposta !== undefined) {
          const drawAux = SVG.get('#draw_imageDiv_' + this.somId);
          drawAux.image(this.imageSource);
        }
      }

      this.marcarMatriculesTrobadesTotes();
    });
  }

  initImage(drawImage: any) {
    this.loadBackgroundImage(drawImage);
    this.group.scale(this.scale);
    drawImage.attr('height', 650 * this.scale);
    drawImage.attr('width', 910 * this.scale);
  }

  loadBackgroundImage(drawImage: any) {
    drawImage.each(function (i, children) {
      if (this.type === 'image') {
        this.remove();
      }
    });
    this.group = drawImage.group(this.image = drawImage
      .image(this.imageUrl(this.somId, 'M'))
      .loaded(
        function (loader) {
          drawImage.size('420', '100%');
          drawImage.viewbox({ x: 0, y: 0, width: loader.width, height: loader.height });
          drawImage.attr('style', 'max-height: 38vh !important; width: auto !important;');

          if (this.proposta !== undefined) {
            this.marcarMatriculesTrobades(drawImage);
          }
        }.bind(this)
      ));
  }

  // filtrem les que podem obrir amb showImages()
  filtrarImatgesShow() {
    this.llistaImatgesFiltrat = Object.assign([], this.llistaImatges);
  }

  //#region Tractar matrícules trobades per l'OCR

  marcarMatriculesTrobadesTotes() {
    const contador = 0;
    const OCRImages = this.llistaImatgesFiltrat.filter(x => x.imatge.SUGMATRICULA !== null);
    if (OCRImages.length === 1) {
      const indexOCRImage = this.llistaImatgesFiltrat.findIndex(x => x.imatge.SUGMATRICULA !== null);
      this.marcarMatriculesTrobades(this.drawList[indexOCRImage]);
    } else {
      this.drawList.forEach(drawImage => {
        this.marcarMatriculesTrobades(drawImage);
      });
    }
  }

  /*
    A partir de la informació que ens ha tornat l'OCR, enquadrem les matricules:
      - Vermell: totes les matrícules si encara no en tenim cap d'informada
      - Taronja: aquelles matrícules que no coincideixen amb la que tenim informada
      - Verd: aquella matrícula que coincideix amb la que tenim informada
  */
  marcarMatriculesTrobades(drawImage: any) {
    const colorMatricula = 'red';
    let strokeWidth: number;

    const OCRImages = this.llistaImatgesFiltrat.filter(x => x.imatge.SUGMATRICULA !== null);
    if (OCRImages.length === 1) {
      if (OCRImages[0].imatge.NOMBRE_FICHERO === this.nom) {
        this.auxMarcarMatriculaTrobada(colorMatricula, strokeWidth, drawImage);
      }
    } else {
      this.auxMarcarMatriculaTrobada(colorMatricula, strokeWidth, drawImage);

    }


  }

  auxMarcarMatriculaTrobada(colorMatricula, strokeWidth, drawImage) {
    //
    //aqui pixela!!
    let somIdImg = Number.parseFloat(drawImage.node.id.replace('draw_imageDiv_', ''));
    let indxImg = this.proposta.IMATGES.findIndex(x => x.SOM_ID === somIdImg);
    if (indxImg !== -1 &&
      this.proposta.IMATGES[indxImg].SUGMATRICULA !== undefined &&
      this.proposta.IMATGES[indxImg].SUGMATRICULA !== null &&
      this.proposta.IMATGES[indxImg].SUGMATRICULA !== '') {
      this.pixelaPorDatosImagen(colorMatricula, strokeWidth, drawImage, this.proposta.IMATGES[indxImg].sugMatriculesCoordenades);
    } else {
      //cojemos las sugerencias de la propuesta
      this.pixelaPorDatosPropuesta(colorMatricula, strokeWidth, drawImage);
    }
  }
  pixelaPorDatosImagen(colorMatricula, strokeWidth, drawImage, sugrenecias: SugerenciaMatricula[]) {
    if (sugrenecias.length > 1) {
      sugrenecias.forEach((sugMatriculaCoordenada: SugerenciaMatricula) => {
        const top = sugMatriculaCoordenada.coordenadas[0];
        const left = sugMatriculaCoordenada.coordenadas[1];
        const width = sugMatriculaCoordenada.coordenadas[2] - sugMatriculaCoordenada.coordenadas[1];
        const height = sugMatriculaCoordenada.coordenadas[3] - sugMatriculaCoordenada.coordenadas[0];

        if (this.proposta.MATRICULA !== undefined && this.proposta.MATRICULA !== null && this.proposta.MATRICULA !== '') {
          if (sugMatriculaCoordenada.matricula === this.proposta.MATRICULA) {
            colorMatricula = 'green';
          } else {
            colorMatricula = 'orange';
          }
        } else {
          colorMatricula = 'red';
        }

        // Si tenim un rectangle d'area petita, li baixem el gruix de la linia
        if ((width * height) < 1500) {
          strokeWidth = 3;
        } else {
          strokeWidth = 4;
        }

        const group = drawImage.group();
        const polyline = group.polyline([[0, 0], [0, height], [width, height], [width, 0], [0, 0]])
          .fill('none').stroke({ color: colorMatricula, width: strokeWidth });

        group.move(left, top);
        polyline.scale(1.5); // Afegim marges a les coordenades inicials que ens passa l'OCR
      });
    }
  }
  pixelaPorDatosPropuesta(colorMatricula, strokeWidth, drawImage) {
    this.proposta.sugMatriculesCoordenades.forEach((sugMatriculaCoordenada: SugerenciaMatricula) => {
      const top = sugMatriculaCoordenada.coordenadas[0];
      const left = sugMatriculaCoordenada.coordenadas[1];
      const width = sugMatriculaCoordenada.coordenadas[2] - sugMatriculaCoordenada.coordenadas[1];
      const height = sugMatriculaCoordenada.coordenadas[3] - sugMatriculaCoordenada.coordenadas[0];

      if (this.proposta.MATRICULA !== undefined && this.proposta.MATRICULA !== null && this.proposta.MATRICULA !== '') {
        if (sugMatriculaCoordenada.matricula === this.proposta.MATRICULA) {
          colorMatricula = 'green';
        } else {
          colorMatricula = 'orange';
        }
      } else {
        colorMatricula = 'red';
      }

      // Si tenim un rectangle d'area petita, li baixem el gruix de la linia
      if ((width * height) < 1500) {
        strokeWidth = 3;
      } else {
        strokeWidth = 4;
      }

      const group = drawImage.group();
      const polyline = group.polyline([[0, 0], [0, height], [width, height], [width, 0], [0, 0]])
        .fill('none').stroke({ color: colorMatricula, width: strokeWidth });

      group.move(left, top);
      polyline.scale(1.5); // Afegim marges a les coordenades inicials que ens passa l'OCR
    });
  }
  //#endregion
}
