import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Injectable } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Carrer } from '../models/carrer.model';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { CarrerDetail } from '../models/carrerDetail.model';
import { CarrersFilterService } from './carrers-filter-service';
import { CarrersFilter } from '../models/carrers-filter';


const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class CarrersService {
  displayedItems = [];


  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  getAll(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new CarrersFilterService().createFilterParams(new CarrersFilter('nomCarrer', 'asc'), 0, 99999, 'nomCarrer', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetCarrers'],  params);
    }
  }

  getAllForCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetCarrersForCombo'],  null);
    }
  }

  getAllForComboZona(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetCarrersForComboZona'],  null);
    }
  }

  setCarrer(carrer: CarrerDetail): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetCarrer'], carrer);
    }
  }

  removeCarrer(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteCarrer'].replace('{secure_id}', id), null);
    }
  }

  getCarrer(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetCarrer'].replace('{secure_id}', id), null);
    }
  }
  getCarrersZona(zona: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      zona = zona.replace('/', 'xxx');
      let re = /\./gi;
      zona = zona.replace(re, 'yyy',);
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetCarrersZona'].replace('{zona}', zona), null);
    }
  }

  getZonaByCarrer(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetZonaByCarrer'].replace('{id}', id), null);
    }
  }
}
