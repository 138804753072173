var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
import * as i0 from "@angular/core";
var defaultSortParam = 'data';
var defaultSortDirection = 'desc';
var AlarmesFilterService = /** @class */ (function (_super) {
    __extends(AlarmesFilterService, _super);
    function AlarmesFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AlarmesFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        var datafi = null;
        if (filter.data !== null) {
            datafi = new Date();
            var copy = new Date(filter.data);
            copy.setHours(23);
            copy.setMinutes(59);
            copy.setSeconds(59);
            datafi = copy;
        }
        return new HttpParams()
            .append('id', filter.id ? filter.id.toString() : '')
            .append('text', filter.text ? filter.text.toString() : '')
            .append('dades', filter.dades ? filter.dades.toString() : '')
            .append('dataFi', filter.data === null ? null : datafi.toJSON())
            .append('dataInici', filter.data === null ? null : filter.data.toJSON())
            .append('idUsuariACK', Reflection.hasValue(filter.IdUsuariACK) ? filter.IdUsuariACK : '')
            .append('idTipusAlarma', Reflection.hasValue(filter.idTipusAlarma) ? filter.idTipusAlarma : '')
            .append('usuari', filter.usuari ? filter.usuari : '')
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('matricula', filter.matricula ? filter.matricula.toString() : '')
            .append('tradesegur_id', filter.tradesegur_id ? filter.tradesegur_id.toString() : '')
            .append('data_ack', filter.data_ack === null ? null : filter.data_ack.toJSON())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    AlarmesFilterService.prototype.getTristateValue = function (value) {
        if (value === null) {
            return '';
        }
        else {
            return value.toString();
        }
    };
    AlarmesFilterService.ngInjectableDef = i0.defineInjectable({ factory: function AlarmesFilterService_Factory() { return new AlarmesFilterService(); }, token: AlarmesFilterService, providedIn: "root" });
    return AlarmesFilterService;
}(CustomFilterService));
export { AlarmesFilterService };
