var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
var defaultSortParam = 'id';
var defaultSortDirection = 'desc';
var ReservesFilterService = /** @class */ (function (_super) {
    __extends(ReservesFilterService, _super);
    function ReservesFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ReservesFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        var httpParams = new HttpParams()
            .append('id', filter.id ? filter.id.toString() : '')
            .append('matricula', filter.matricula)
            .append('email', filter.email)
            .append('fechaInicioDia', filter.fechaInicio ? this.formatDateToDecimal(new Date(filter.fechaInicio)) : '')
            .append('fechaFinDia', filter.fechaInicio ? this.formatDateToDecimal(new Date(filter.fechaInicio), false) : '')
            .append('tipoVehiculo', filter.tipoVehiculo ? filter.tipoVehiculo.toString() : '')
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
        return httpParams;
    };
    ReservesFilterService.prototype.formatDateToDecimal = function (date, isFechaIni) {
        if (isFechaIni === void 0) { isFechaIni = true; }
        var month;
        var day;
        var hhmmss;
        month = (date.getMonth() + 1).toString();
        if (month.length === 1) {
            month = '0' + month;
        }
        day = date.getDate().toString();
        if (day.length === 1) {
            day = '0' + day;
        }
        if (isFechaIni) {
            hhmmss = '000000';
        }
        else {
            hhmmss = '235959';
        }
        return date.getFullYear().toString()
            + month
            + day
            + hhmmss;
    };
    return ReservesFilterService;
}(CustomFilterService));
export { ReservesFilterService };
