/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./zb-horarios.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i3 from "../../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i4 from "../../../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i5 from "../../../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i6 from "./zb-horarios-table/zb-horarios-table.component.ngfactory";
import * as i7 from "./zb-horarios-table/zb-horarios-table.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../../../../shared/services/authorization/authorization-service";
import * as i10 from "./services/zb-horarios.service";
import * as i11 from "./zb-horarios.component";
var styles_ZbHorariosComponent = [i0.styles];
var RenderType_ZbHorariosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ZbHorariosComponent, data: {} });
export { RenderType_ZbHorariosComponent as RenderType_ZbHorariosComponent };
export function View_ZbHorariosComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { ZbHorariosTable: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "app-toolbar", [], null, null, null, i2.View_ToolbarComponent_0, i2.RenderType_ToolbarComponent)), i1.ɵdid(2, 114688, null, 0, i3.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 4, "div", [["style", "padding-top: 25px; padding-left: 25px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-tool-button", [["color", "has-color-green"], ["icon", "fa-plus-square"], ["text", "Nou"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.create() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(5, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-tool-button", [["color", "has-color-blue"], ["icon", "fa-sync"], ["text", "Actualitzar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(7, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-zb-horarios-table", [], null, null, null, i6.View_ZbHorariosTableComponent_0, i6.RenderType_ZbHorariosTableComponent)), i1.ɵdid(9, 4308992, [[1, 4]], 0, i7.ZbHorariosTableComponent, [i8.MatDialog, i9.AuthorizationService, i10.ZbHorariosService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); var currVal_0 = "Nou"; var currVal_1 = "has-color-green"; var currVal_2 = "fa-plus-square"; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Actualitzar"; var currVal_4 = "has-color-blue"; var currVal_5 = "fa-sync"; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5); _ck(_v, 9, 0); }, null); }
export function View_ZbHorariosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-zb-horarios", [], null, null, null, View_ZbHorariosComponent_0, RenderType_ZbHorariosComponent)), i1.ɵdid(1, 114688, null, 0, i11.ZbHorariosComponent, [i8.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ZbHorariosComponentNgFactory = i1.ɵccf("app-zb-horarios", i11.ZbHorariosComponent, View_ZbHorariosComponent_Host_0, {}, {}, []);
export { ZbHorariosComponentNgFactory as ZbHorariosComponentNgFactory };
