import { SortDirection } from '@angular/material';
import { FilterData } from 'path-shared/models/filter-data';

export class TemporadaFilter extends FilterData {
    id: string;
    descripcion: string;
    fechaInicio: string;
    fechaFin: string;
    activa: boolean;

    constructor(sortField: string, sortDir: SortDirection) {
        super(sortField, sortDir);
        this.id = '';
        this.fechaInicio = '';
        this.fechaFin = '';
        this.descripcion = '';
        this.activa = null;

        this.labels['id'] = this.i18n('Id');
        this.labels['descripcion'] = this.i18n('Descripció');
        this.labels['fechaInicio'] = this.i18n('Data inici');
        this.labels['fechaFin'] = this.i18n('Data final');
        this.labels['activa'] = this.i18n('Activa');
    }
}
