/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tancaments.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i4 from "@angular/material/card";
import * as i5 from "../../../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i6 from "@angular/material/list";
import * as i7 from "./tancaments.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../services/tramitar.service";
import * as i10 from "@ngx-translate/i18n-polyfill";
import * as i11 from "ngx-toastr";
var styles_TancamentsComponent = [i0.styles];
var RenderType_TancamentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TancamentsComponent, data: {} });
export { RenderType_TancamentsComponent as RenderType_TancamentsComponent };
export function View_TancamentsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 19, "mat-card", [["class", "mat-card"]], null, null, null, i3.View_MatCard_0, i3.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i4.MatCard, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-card-subtitle", [["class", "mat-card-subtitle"], ["title", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i4.MatCardSubtitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["Data \u00FAltim tancament"])), (_l()(), i1.ɵeld(6, 0, null, 0, 14, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i4.MatCardContent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 12, "mat-list", [["class", "mat-list"]], null, null, null, i5.View_MatList_0, i5.RenderType_MatList)), i1.ɵdid(9, 49152, null, 0, i6.MatList, [], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 10, "mat-list-item", [["class", "row mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._handleFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11)._handleBlur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_MatListItem_0, i5.RenderType_MatListItem)), i1.ɵdid(11, 1097728, null, 3, i6.MatListItem, [i1.ElementRef, [2, i6.MatNavList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵeld(15, 0, null, 2, 2, "div", [["class", "col-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵppd(17, 2), (_l()(), i1.ɵeld(18, 0, null, 2, 2, "div", [["class", "col-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn btn-primary col-12"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.OpenPopUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(20, null, ["Exp. a cerrar (", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 11)._avatar || i1.ɵnov(_v, 11)._icon); var currVal_1 = (i1.ɵnov(_v, 11)._avatar || i1.ɵnov(_v, 11)._icon); _ck(_v, 10, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v, 0), _co.infoTancaments.MaxDataTancament, "dd/MM/yyyy")); _ck(_v, 16, 0, currVal_2); var currVal_3 = _co.infoTancaments.Tancaments; _ck(_v, 20, 0, currVal_3); }); }
export function View_TancamentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tancaments", [], null, null, null, View_TancamentsComponent_0, RenderType_TancamentsComponent)), i1.ɵdid(1, 114688, null, 0, i7.TancamentsComponent, [i8.MatDialog, i9.TramitarService, i10.I18n, i11.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TancamentsComponentNgFactory = i1.ɵccf("app-tancaments", i7.TancamentsComponent, View_TancamentsComponent_Host_0, {}, {}, []);
export { TancamentsComponentNgFactory as TancamentsComponentNgFactory };
