
<div class="row justify-content-between">
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.currentFilter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]"
      (page)="paginatorChanged($event)">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.currentFilter.paginator.sortField" [matSortDirection]="this.currentFilter.paginator.sortDir" class="is-header-sticky">

      <ng-container matColumnDef="tiquet">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tiquet</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.tiquet}}</mat-cell>
        </ng-container>

      <ng-container matColumnDef="data">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.data}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="client">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Client</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.client}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Email</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.email}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="matricula">
        <mat-header-cell *matHeaderCellDef i18n>Matrícula</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.matricula}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="zona">
        <mat-header-cell *matHeaderCellDef i18n>Zona</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.zona}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="tarifa">
        <mat-header-cell *matHeaderCellDef i18n>Tarifa</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.tarifa}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="importDevolucio">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Import devolució</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.importDevolucio}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="importTiquetBono">
        <mat-header-cell *matHeaderCellDef i18n>Imp. Bono</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.importTiquetBono}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="importTiquetMetalic">
        <mat-header-cell *matHeaderCellDef i18n>Imp. Metàl·lic</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.importTiquetMetalic}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="importTiquet">
        <mat-header-cell *matHeaderCellDef i18n>Import Tiquet</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.importTiquet}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
</div>


