import { SortDirection } from '@angular/material';
import { FilterData } from 'path-shared/models/filter-data';

export class GenericFileFilter extends FilterData {

    id: string;
    nombre: string;
    fechaGravacion: string;
    eliminado: string;
    tipoRecurso: string;
    idRegistroAsociado: string;

    constructor(sortField: string, sortDir: SortDirection) {
        super(sortField, sortDir);
        this.id = '';
        this.nombre = '';
        this.fechaGravacion = '';
        this.eliminado = '';
        this.tipoRecurso = '';
        this.idRegistroAsociado = '';
    }
}
