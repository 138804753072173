var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'descripcion';
var defaultSortDirection = 'asc';
var TarifasGruaFilterService = /** @class */ (function (_super) {
    __extends(TarifasGruaFilterService, _super);
    function TarifasGruaFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TarifasGruaFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('somId', filter.somId)
            .append('descripcion', filter.descripcion)
            .append('importeTarifa', Reflection.empty(filter.importeTarifa) ? null : filter.importeTarifa.toString())
            .append('tipoEstancia', filter.tipoEstancia)
            .append('tipoTarifa', filter.tipoTarifa)
            .append('tipoVehiculo', filter.tipoVehiculo)
            .append('fechaDesde', Reflection.empty(filter.fechaDesde) ? null : typeof (filter.fechaDesde) === 'string' ?
            filter.fechaDesde : filter.fechaDesde.toString())
            .append('fechaHasta', Reflection.empty(filter.fechaHasta) ? null : typeof (filter.fechaHasta) === 'string' ?
            filter.fechaHasta : filter.fechaHasta.toString())
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return TarifasGruaFilterService;
}(CustomFilterService));
export { TarifasGruaFilterService };
