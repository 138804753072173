import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { AjudaService } from '../services/ajuda.service';
import { Ajuda } from '../models/ajuda.model';
import { FormService } from 'path-shared/services/form/form.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ajuda-page',
  templateUrl: './ajuda-page.component.html',
  styleUrls: ['./ajuda-page.component.scss']
})
export class AjudaPageComponent implements OnInit, AfterViewInit {

  @ViewChild('mainForm') private mainForm: ElementRef;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  titol: string;
  cosMissatge: string;
  emailInfo: Ajuda;

  constructor(
    public authService: AuthorizationService,
    private not: NotificationService,
    private toastr: ToastrService,
    private i18n: I18n,
    private ajudaService: AjudaService,
    private formService: FormService,
  ) {
    this.emailInfo = Builder.newAjuda();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Ajuda'));
    }
  }

  enviar() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      this.emailInfo.subject = this.titol;
      this.emailInfo.body = this.cosMissatge;

      this.ajudaService.sendHelpEmail(this.emailInfo).subscribe(res => {
        this.toastr.info(this.i18n('Consulta enviada correctament.'));
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

}
