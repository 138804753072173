import { BonosResidentesFilterService } from './bonos-residentes-filter.service';
import { BonosResidentesFilter } from './../models/bonos-residentes-filter';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = 'data';
const defaultSortDirection = 'asc';

@Injectable({
  providedIn: 'root'
})
export class GestioResidentsService {
  displayedItems = [];

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    // this.displayedItems['checkedValues'] = 0;
    // this.displayedItems['checkedItems'] = Array();
  }

  getCitas(date: Date) {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGestioResidentsCites'],  new HttpParams().append('date', date.toJSON()) );
    }
  }

  getBonosFilterInfo() {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGestioResidentsBonosFilterInfo'], null);
    }
  }

  comprovarPadro(matricula: string, dni: string) {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGestioResidentsComprovarPadro'],
      new HttpParams().
        append('matricula', matricula).
        append('dni', dni));
    }
  }

  generateExcel(filter: BonosResidentesFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new BonosResidentesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlBonosResidentesExcel'],  params );
    }

  }
}
