/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tramitar-dates.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i3 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i4 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i5 from "../../../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i6 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i7 from "./tramitar-dates.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../services/tramitar.service";
import * as i10 from "../../../../../../shared/services/notification/notification.service";
import * as i11 from "ngx-toastr";
import * as i12 from "@ngx-translate/i18n-polyfill";
import * as i13 from "../../../../../../shared/services/authorization/authorization-service";
var styles_TramitarDatesComponent = [i0.styles];
var RenderType_TramitarDatesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TramitarDatesComponent, data: {} });
export { RenderType_TramitarDatesComponent as RenderType_TramitarDatesComponent };
export function View_TramitarDatesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 3, "app-form-date-edit", [["label", "Data inici"], ["type", "calendar"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.DataInici = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormDateEditComponent_0, i2.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i3.DateTimeAdapter, i4.MomentDateTimeAdapter, [i3.OWL_DATE_TIME_LOCALE]), i1.ɵdid(4, 114688, null, 0, i5.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], type: [2, "type"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i6.OWL_DATE_TIME_FORMATS, i5.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 3, "app-form-date-edit", [["label", "Data fi"], ["type", "calendar"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.DataFi = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormDateEditComponent_0, i2.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i3.DateTimeAdapter, i4.MomentDateTimeAdapter, [i3.OWL_DATE_TIME_LOCALE]), i1.ɵdid(9, 114688, null, 0, i5.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], type: [2, "type"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i6.OWL_DATE_TIME_FORMATS, i5.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "row"], ["style", "text-align:center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn-sq-lg btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Impressio() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Data inici"; var currVal_1 = _co.DataInici; var currVal_2 = "calendar"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Data fi"; var currVal_4 = _co.DataFi; var currVal_5 = "calendar"; _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_TramitarDatesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tramitar-dates", [], null, null, null, View_TramitarDatesComponent_0, RenderType_TramitarDatesComponent)), i1.ɵdid(1, 114688, null, 0, i7.TramitarDatesComponent, [i8.MatDialog, i9.TramitarService, i10.NotificationService, i11.ToastrService, i12.I18n, i13.AuthorizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TramitarDatesComponentNgFactory = i1.ɵccf("app-tramitar-dates", i7.TramitarDatesComponent, View_TramitarDatesComponent_Host_0, {}, {}, []);
export { TramitarDatesComponentNgFactory as TramitarDatesComponentNgFactory };
