<div class="row justify-content-between">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
        <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.id}}</a></mat-cell>
      </ng-container>

      <ng-container matColumnDef="codiCarrega">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi Càrrega</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.codiCarrega}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="descripcioCarrega">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripció càrrega</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.descripcioCarrega}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="nomFitxer">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom fitxer</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.nomFitxer}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="directoriFitxer">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Directori fitxer</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.directoriFitxer}}</mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="situacio">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Situació</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.situacio}}</mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="situacioClass">
        <mat-header-cell *matHeaderCellDef i18n>Situació</mat-header-cell>
        <mat-cell *matCellDef="let row"><i class="{{row.situacioClass}}" title="{{row.situacioTitle}}" i18n-title></i></mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
</div>

