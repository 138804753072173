export class LeyendaOpcionSeleccionada {

    opcionSeleccionada: String;
    valor: boolean;

    constructor(opcionSeleccionada: String, valor: boolean) {
        this.opcionSeleccionada = opcionSeleccionada;
        this.valor = valor;
    }
}
