import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { config } from 'path-shared/configuration/config';
import * as i0 from "@angular/core";
import * as i1 from "../../services/http/httpSecureClient";
var TestService = /** @class */ (function () {
    function TestService(http) {
        this.http = http;
    }
    TestService.prototype.getPages = function () {
        return this.http.get(config.urls['apiUrl'] + '/pages/77821', null);
    };
    TestService.ngInjectableDef = i0.defineInjectable({ factory: function TestService_Factory() { return new TestService(i0.inject(i1.HttpSecureClient)); }, token: TestService, providedIn: "root" });
    return TestService;
}());
export { TestService };
