import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { OnInit, EventEmitter } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { ReservesFilter, TipoVehiculo } from 'app/sections/tiquets/reserves/models/reserves-filter';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs/internal/observable/merge';
import { tap } from 'rxjs/internal/operators/tap';
import { ReservesService } from '../../services/reserves.service';
import { ReservesFilterService } from '../../services/reserves-filter.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
var ReservesMotosTableComponent = /** @class */ (function () {
    //#region INICIALIZACION
    function ReservesMotosTableComponent(i18n, reservasService, toastr, dialog, confirmationDialogService) {
        this.i18n = i18n;
        this.reservasService = reservasService;
        this.toastr = toastr;
        this.dialog = dialog;
        this.confirmationDialogService = confirmationDialogService;
        this.currentFilter = new ReservesFilter('id', 'desc', TipoVehiculo.VehiculoMoto); // Filtro concreto para esta tabla.
        this.highlightedRows = [];
        this.displayedColumns = ['tiquet', 'client', 'email', 'matricula', 'persones', 'personesAnulat', 'zona', 'tarifa', 'importTiquet', 'importDevolucio', 'horaCompra', 'horaInici', 'horaFinal', 'btnAnular'];
        this.currentCount = new EventEmitter();
    }
    Object.defineProperty(ReservesMotosTableComponent.prototype, "sharedFilter", {
        get: function () {
            return this._sharedFilter;
        },
        set: function (val) {
            this._sharedFilter = val;
            this.currentFilter.matricula = val.matricula;
            this.currentFilter.email = val.email;
            this.currentFilter.fechaInicio = val.fechaInicio;
            this.currentFilter.tipoVehiculo = TipoVehiculo.VehiculoMoto;
        },
        enumerable: true,
        configurable: true
    });
    //#endregion
    //#region CICLO DE VIDA
    ReservesMotosTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sort.disableClear = true;
        // Descargamos los registros por primera vez.
        this.dataSource = CustomDataSource.create(this.paginator, new ReservesFilterService(), 'urlGetReserves', 'urlGetReservesCount');
        // Nos suscribimos al evento de getContador para detectar cambios y devolver el valor al componente parent.
        this.dataSource.countSubject.subscribe(function (newValue) {
            _this.currentCount.emit(newValue);
        });
    };
    ReservesMotosTableComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // Nos suscribimos al evento para detectar cambios en la ordenacion de la tabla.
        this.sort.sortChange.subscribe(function () {
            // Cuando cambia la ordenacion, actualizamos la informacion.
            _this.paginator.pageIndex = 0;
            _this.load();
        });
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(tap(function () { return _this.currentFilter.updated(); }))
            .subscribe();
        // Cuando la vista se ha cargado, actuallizamos la informacion.
        this.load();
    };
    //#endregion
    //#region EVENTS
    ReservesMotosTableComponent.prototype.paginatorChanged = function (value) {
        // Actualizamos la informacion al detectar un cambio en la paginacion.
        this.load();
    };
    ReservesMotosTableComponent.prototype.updateFilterFromParent = function (val) {
        // Cuando el componente parent nos pasa un nuevo filtro compartido, actualizamos.
        this.sharedFilter = val;
        this.load();
    };
    //#endregion
    //#region OTHERS
    ReservesMotosTableComponent.prototype.load = function () {
        this.currentFilter.paginator.set(this.sort, this.paginator);
        this.dataSource.loadFilter(this.currentFilter);
    };
    ReservesMotosTableComponent.prototype.anularReserva = function (row) {
        var _this = this;
        this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la reserva amb número de tiquet ') + row.id + this.i18n('?'))
            .then(function (confirmed) {
            if (confirmed) {
                _this.reservasService.anularReserva(row.secureId).subscribe(function (result) {
                    _this.toastr.success(_this.i18n('Reserva anul·lada correctament'));
                    _this.dialog.closeAll();
                    _this._sharedFilter.updated(true);
                });
            }
        });
    };
    return ReservesMotosTableComponent;
}());
export { ReservesMotosTableComponent };
