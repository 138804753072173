import { GruaTecnicsComponent } from './../../../configuracio/grua/grua-tecnics/grua-tecnics.component';
import { GruaPendentsService } from './../../services/grua-pendents.service';
import { AliesGruaService } from './../../services/alies-grua.service';
import { PropostaGruaDto } from './../../Models/proposta-grua.model';
import { Component, OnInit, Inject, ViewChild, Input, AfterViewInit, EventEmitter, Output, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Instituciones } from 'path-shared/models/instituciones';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { Parametros } from 'path-shared/models/parametros';
import { InfraccioService } from 'app/sections/configuracio/Infraccions/services/infraccions-service.service';
import { Dictionary } from 'path-shared/models/dictionary';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { PropostesService } from 'path-propostes-services/propostes.service';
import { PhotoBoxGalleryComponent } from 'path-shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { CarrersComponent } from 'app/sections/configuracio/carrers/pages/carrers/carrers.component';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { Router } from '@angular/router';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { FormService } from 'path-shared/services/form/form.service';

@Component({
  selector: 'app-nueva-propuesta-grua',
  templateUrl: './nueva-propuesta-grua.component.html',
  styleUrls: ['./nueva-propuesta-grua.component.scss']
})
export class NuevaPropuestaGruaComponent implements OnInit, AfterViewInit {

  public currentLocation: {
    latitude: number,
    longitude: number
  };
  public zoom: number;
  public latitudMarker: number;
  public longitudMarker: number;
  public isProposta: boolean;
  public isDialog: boolean;
  aliasGruaList = [];
  infraccionsList = [];
  agentsList = [];
  motiusDesestimarList = [];

  aliasGruaDictionary: Dictionary[];
  valorAutoCompleteAliasGrua;

  infraccionsDictionary: Dictionary[];
  valorAutoCompleteInfraccions;

  agentsDictionary: Dictionary[];
  valorAutoCompleteAgent;

  motiusDesestimarDictionary: Dictionary[];
  valorAutoCompleteMotiusDesestimar;

  missatgeAcceptarDisabled = this.i18n("Debe rellenar los campos obligatorios para poder solicitar una retirada");
  @Output() refreshComponent: EventEmitter<boolean> = new EventEmitter<boolean>();

  //@ViewChild('pbg') pbg: PhotoBoxGalleryComponent;
  @ViewChild('comboAliasGrua') comboAliasGrua: ComboBoxInputAutocompleteComponent;
  @ViewChild('comboInfraccions') comboInfraccions: ComboBoxInputAutocompleteComponent;
  @ViewChild('comboAgent') comboAgent: ComboBoxInputAutocompleteComponent;
  @ViewChild('comboMotiuDesestimar') comboMotiuDesestimar: ComboBoxInputAutocompleteComponent;
  //public proposta: PropostaGruaDto;
  @Input() proposta: PropostaGruaDto;
  @ViewChild('mainForm') private mainForm: ElementRef;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private parametersService: ParametersService,
  private aliesGruaSerevice: AliesGruaService,
  private infraccioService: InfraccioService,
  private propostesService: PropostesService, //utilizamos els servicio de propuestas para obtener imagenes
  private agentsService: AgentsService,
  private gruaSerevice: GruaPendentsService,
  private toastr: ToastrService,
  private dialog: MatDialog,
  private i18n: I18n,
  private not: NotificationService,
  private router: Router,
  public auth: AuthorizationService,
  public formService: FormService,
    ) {
    // carreguem les dades per centrar el mapa
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      const institution: Instituciones = JSON.parse(inst);
    this.currentLocation = {latitude: institution.Latitud, longitude: institution.Longitud};
    }
    this.valorAutoCompleteAliasGrua = '';
    this.valorAutoCompleteInfraccions = '';
    this.valorAutoCompleteAgent = '';
    this.valorAutoCompleteMotiusDesestimar = '';
    this.isProposta = false;
    this.proposta = Builder.newPropostaGrua();
    /*if (data !== undefined && data !== null && Object.keys(data).length > 0 ) {
      if (data.propostaGrua !== undefined && data.propostaGrua !== null ) {
        this.proposta = Builder.newPropostaGrua();
        this.isDialog = true;
      }
        this.isProposta = data.isProposta;
    } else {
      this.isProposta = true;
      this.isDialog = false;
    }*/
  }

  ngOnInit() {
    this.auth.CheckUserPermission(this.auth.Pages.GenerarProposta);
    this.getParameters();
    this.loadAliasGrua();
    this.loadInfraccions();
    this.loadAgents();
    this.loadMotiusDesestimacio();
   
  }
  ngAfterViewInit() {
    this.not.titleChange(this.i18n('Nova sol·licitud retirada'));
  }
  // loadImatges() {
  //   if ( this.proposta !== undefined && this.proposta !== null) {
  //     this.latitudMarker = this.proposta.latitud;
  //     this.longitudMarker = this.proposta.longitud;
  //     this.propostesService
  //     .getImages(this.proposta.codi).subscribe(images => {
  //       if ( images !== undefined &&  images !== null && images.length > 0 ) {
  //         this.proposta.IMATGES = images;
  //         this.pbg.updateImatges(images);
  //       } else {
  //         this.proposta.IMATGES = [];
  //         this.pbg.updateImatges([]);
  //       }
  //     });
  //   }
  // }

  // loadImatgesByCodi(codi) {
  //     this.propostesService
  //     .getImages(codi).subscribe(images => {
  //       if ( images !== undefined &&  images !== null && images.length > 0 ) {
  //         this.proposta.IMATGES = images;
  //         this.pbg.updateImatges(images);
  //       } else {
  //         this.proposta.IMATGES = [];
  //         this.pbg.updateImatges([]);
  //       }
  //     });
  // }

  seleccionarCarrer(current_id = null) {
    if (current_id === null) {

      const data: ComponentDialog = Builder.newComponentDialog(true, false, current_id);
      const ref = this.dialog.open(CarrersComponent, {
        width: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });
      ref.afterClosed().subscribe(res => {
           if (res.nomCarrer !== undefined && res.nomCarrer !== null && res.nomCarrer !== '') {
              this.proposta.nombreCalle = res.nomCarrer;
              this.proposta.secureIdCarrer = res.secure_id;
           }
        });
    }

  }

  seleccionarTecnic(current_id = null) {
    if (current_id === null) {

      const data: ComponentDialog = Builder.newComponentDialog(true, false, current_id);
      const ref = this.dialog.open(GruaTecnicsComponent, {
        width: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });
      ref.afterClosed().subscribe(res => {
           if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
              this.proposta.tecnicGrua = res.codi;
              this.proposta.idTecnicFK = res.idTecnic;
           }
        });
    }

  }
  setDefalutValuesCombosAgent() {
    //agent 
    if(this.proposta.idAgentFk !== undefined && this.proposta.idAgentFk !== null && this.proposta.idAgentFk !== ''
     && this.agentsDictionary.length > 0 ){
      //const agentSelected = this.agentsList.find(x => x.codi === this.proposta.idAgentFk);
      this.comboAgent.setForcedValueComb(this.proposta.idAgentFk);
    }
  }

  setDefalutValuesCombosInfraccio() {
    //infraccio
    if(this.proposta.idInfraccioFK !== undefined && this.proposta.idInfraccioFK !== null && this.proposta.idInfraccioFK !== ''
     && this.infraccionsDictionary.length > 0 ){
      const infraccioSelected = this.infraccionsList.find(x => x.id === this.proposta.idInfraccioFK);
      this.comboInfraccions.setForcedValueComb(infraccioSelected.descCurta);
    }
  }

  loadAliasGrua() {
    this.aliasGruaDictionary = new Array<Dictionary>();
    this.aliesGruaSerevice.getAllForCombo().subscribe( x => {
      this.aliasGruaList = x;
      x.forEach(element => {
        this.aliasGruaDictionary.push(new Dictionary(element.secureId.toString(), element.descripcio));
      });
    });
  }
  loadInfraccions() {
    this.infraccionsDictionary = new Array<Dictionary>();
    //this.infraccioService.getAllForCombo().subscribe( x => {
      this.infraccioService.getAllForComboInfraccionsGrua().subscribe( x => {
      this.infraccionsList = x;
      x.forEach(element => {
        this.infraccionsDictionary.push(new Dictionary(element.secureId.toString(), element.descCurta));
      });
      //this.setDefalutValuesCombosInfraccio();
    });
  }

  loadAgents() {
    this.agentsDictionary = new Array<Dictionary>();
    this.agentsService.getAllCombo().subscribe( x => {
      this.agentsList = x;
      x.forEach(element => {
        this.agentsDictionary.push(new Dictionary(element.SECURE_ID.toString(), element.codi));
      });
      //this.setDefalutValuesCombosAgent();
    });
  }
  loadMotiusDesestimacio() {
    this.motiusDesestimarDictionary = new Array<Dictionary>();
    this.gruaSerevice.getAllMotiusDesestimar().subscribe( x => {
      this.motiusDesestimarList = x;
      x.forEach(element => {
        this.motiusDesestimarDictionary.push(new Dictionary(element.secureId.toString(), element.motiu));
      });
    });
  }

  getParameters() {

    this.zoom = Number.parseInt(this.parametersService.getParameter(Parametros.GPSZOMINICIAL));
  }

  disabled(): boolean {
    return !(this.proposta.idAgentFk !== undefined && this.proposta.idAgentFk !== null  && this.proposta.idAgentFk !== '' &&
            this.proposta.idMotiuDesestimada !== undefined && this.proposta.idMotiuDesestimada !== null);
  }
  disabledButtonAccept(): boolean {

    return !(this.formService.allFieldsValid(this.mainForm) && 
    (!this.isNullOrEmpty(this.proposta.idInfraccioFK) || 
    ((this.isNullOrEmpty(this.proposta.idInfraccioFK) && 
       (this.isNullOrEmpty(this.proposta.implicaDenuncia)  || this.proposta.implicaDenuncia === false )))));
  }
  implicaDenunciaChange(e: any){
    if(this.isNullOrEmpty(this.proposta.implicaDenuncia)){
      this.proposta.implicaDenuncia = true;
    }else{
      this.proposta.implicaDenuncia = ! this.proposta.implicaDenuncia;
    }
  }
  sinCargoChange(e: any){
    if(this.isNullOrEmpty(this.proposta.sinCargo)){
      this.proposta.sinCargo = true;
    }else{
      this.proposta.sinCargo = ! this.proposta.sinCargo;
    }
  }
  private isNullOrEmpty(value): boolean {
    return value === undefined || value === null || value === '';
  }

  /*rejectProposta() {
    this.gruaSerevice.rejectPropostaGrua(this.proposta).subscribe(result => {
      if (result !== undefined && result !== null ) {
        if ( result === 1) {
          this.toastr.success(this.i18n('La proposta s\'ha acceptat i s\'ha creat la denúncia!'));
          this.dialog.closeAll();
          this.refreshComponent.emit(true);
        } else if (result === 2 ) {
          this.toastr.success(this.i18n('La proposta s\'ha acceptat i no s\'ha creat la denúncia!'));
          this.dialog.closeAll();
          this.refreshComponent.emit(true);
        } else if (result === 3 ) {
          this.toastr.success(this.i18n('La proposta s\'ha rebutjat!'));
          this.dialog.closeAll();
          this.refreshComponent.emit(true);
        }
      }
    } );
  }*/
  createProposta() {
    if (this.formService.allFieldsValid(this.mainForm)) {

      this.gruaSerevice.CreatePropostaGrua(this.proposta).subscribe(result => {
            this.toastr.success(this.i18n('La sol·licitud s\'ha realitzat correctament!'));
            this.router.navigate(['/grua/nova-solicitud']);
      } );
    }else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  aliasGruaSeleccionado(idAliasGrua: string){
    this.proposta.idAliasGrua =Number.parseFloat(idAliasGrua);
    const aliasSelected = this.aliasGruaList.find(x => x.secureId === idAliasGrua);
    this.infraccionsDictionary = new Array<Dictionary>();
    const infraccionsFiltrades = this.infraccionsList.filter( x => x.aliasGrua === aliasSelected.codi.toString() );
    infraccionsFiltrades.forEach(element => {
      this.infraccionsDictionary.push(new Dictionary(element.secureId.toString(), element.descCurta));
    });

    //reset del combo
    this.comboInfraccions.setForcedValueComb('');
  }

  infraccionsSeleccionado(idInfraccio: string){
  if(idInfraccio !== undefined && idInfraccio !== null && idInfraccio !== ''){
    const infraccioSelected = this.infraccionsList.find(x => x.secureId === idInfraccio);
    this.proposta.infraccion = infraccioSelected;
    this.proposta.idInfraccioFK = infraccioSelected.id;
  } else {
    this.proposta.idInfraccioFK = '';
  }
  }

  agentSeleccionado(secureIdAgent: string){
    const agentSelected = this.agentsList.find(x => x.SECURE_ID === secureIdAgent);
    this.proposta.agent = agentSelected;
    this.proposta.idAgentFk = agentSelected.codi;
  }

  motiuDesestimarSeleccionado(secureIdMotiu: string){
    const motiuSelected = this.motiusDesestimarList.find(x => x.secureId === secureIdMotiu);
    this.proposta.idMotiuDesestimada = motiuSelected;
  }
}
