import { Injectable } from "@angular/core";
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";
import { MaquinaIncidenciaFilter } from "../models/maquinaIncidencia-filter";
import { HttpParams } from "@angular/common/http";

const defaultSortParam = 'CODI_MAQUINA';
const defaultSortDirection = 'asc';

@Injectable()
export class MaquinaIncidenciesFilterService extends CustomFilterService {
  public createFilterParams(filter: MaquinaIncidenciaFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    return new HttpParams()
    .append('codiMaquina', (filter.codiMaquina ? filter.codiMaquina.toString() : ''))
    .append('somId', (filter.somId ? filter.somId.toString() : ''))
    .append('dataCreacioInici', (filter.dataCreacioInici ? filter.dataCreacioInici.toDateString() : ''))
    .append('dataCreacioFi', (filter.dataCreacioFi ? filter.dataCreacioFi.toDateString() : ''))
    .append('dataResolucioInici', (filter.dataResolucioInici ? filter.dataResolucioInici.toDateString() : ''))
    .append('dataResolucioFi', (filter.dataResolucioFi ? filter.dataResolucioFi.toDateString() : ''))

    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
