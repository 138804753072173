var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var TarifesFilter = /** @class */ (function (_super) {
    __extends(TarifesFilter, _super);
    function TarifesFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.codi = null;
        _this.descripcio = '';
        _this.dataInici = null;
        _this.dataFi = null;
        _this.tipusCalcul = null;
        _this.valor = null;
        _this.valorMinim = null;
        _this.valorMaxim = null;
        _this.carenciaDenuncia = null;
        _this.increment = null;
        _this.anulSenseTiquet = null;
        _this.tempsSenseTiquet = null;
        _this.anulExhaurit = null;
        _this.tempsExhaurit = null;
        _this.desde1 = '';
        _this.iniciPausa1 = '';
        _this.fiPausa1 = '';
        _this.hasta1 = '';
        _this.desde2 = '';
        _this.iniciPausa2 = '';
        _this.fiPausa2 = '';
        _this.hasta2 = '';
        _this.desde3 = '';
        _this.iniciPausa3 = '';
        _this.fiPausa3 = '';
        _this.hasta3 = '';
        _this.desde4 = '';
        _this.iniciPausa4 = '';
        _this.fiPausa4 = '';
        _this.hasta4 = '';
        _this.desde5 = '';
        _this.iniciPausa5 = '';
        _this.fiPausa5 = '';
        _this.hasta5 = '';
        _this.desde6 = '';
        _this.iniciPausa6 = '';
        _this.fiPausa6 = '';
        _this.hasta6 = '';
        _this.desde7 = '';
        _this.iniciPausa7 = '';
        _this.fiPausa7 = '';
        _this.hasta7 = '';
        _this.idZona = null;
        _this.idResident = null;
        _this.idTiquet = null;
        _this.labels['codi'] = _this.i18n('Codi');
        _this.labels['descripcio'] = _this.i18n('Descripció');
        _this.labels['dataInici'] = _this.i18n('Data inici');
        _this.labels['dataFi'] = _this.i18n('Data fi');
        _this.labels['tipusCalcul'] = _this.i18n('Tipus Càlcul');
        _this.labels['valor'] = _this.i18n('Valor');
        _this.labels['valorMinim'] = _this.i18n('Valor mínim');
        _this.labels['valorMaxim'] = _this.i18n('Valor màxim');
        _this.labels['carenciaDenuncia'] = _this.i18n('Carencia denúncia');
        _this.labels['increment'] = _this.i18n('increment');
        _this.labels['anulSenseTiquet'] = _this.i18n('Preu sense tiquet');
        _this.labels['tempsSenseTiquet'] = _this.i18n('Temps sense tiquet');
        _this.labels['anulExhaurit'] = _this.i18n('Preu exhaurit');
        _this.labels['tempsExhaurit'] = _this.i18n('Temps exhaurit');
        _this.labels['desde1'] = _this.i18n('Dilluns des de');
        _this.labels['iniciPausa1'] = _this.i18n('Dilluns inici pausa');
        _this.labels['fiPausa1'] = _this.i18n('Dilluns fi pausa');
        _this.labels['hasta1'] = _this.i18n('Dilluns fins');
        _this.labels['desde2'] = _this.i18n('Dimarts des de');
        _this.labels['iniciPausa2'] = _this.i18n('Dimarts inici pausa');
        _this.labels['fiPausa2'] = _this.i18n('Dimarts fi pausa');
        _this.labels['hasta2'] = _this.i18n('Dimarts fins');
        _this.labels['desde3'] = _this.i18n('Dimecres des de');
        _this.labels['iniciPausa3'] = _this.i18n('Dimecres inici pausa');
        _this.labels['fiPausa3'] = _this.i18n('Dimecres fi pausa');
        _this.labels['hasta3'] = _this.i18n('Dimecres fins');
        _this.labels['desde4'] = _this.i18n('Dijous des de');
        _this.labels['iniciPausa4'] = _this.i18n('Dijous inici pausa');
        _this.labels['fiPausa4'] = _this.i18n('Dijous fi pausa');
        _this.labels['hasta4'] = _this.i18n('Dijous fins');
        _this.labels['desde5'] = _this.i18n('Divendres des de');
        _this.labels['iniciPausa5'] = _this.i18n('Divendres inici pausa');
        _this.labels['fiPausa5'] = _this.i18n('Divendres fi pausa');
        _this.labels['hasta5'] = _this.i18n('Divendres fins');
        _this.labels['desde6'] = _this.i18n('Dissabte des de');
        _this.labels['iniciPausa6'] = _this.i18n('Dissabte inici pausa');
        _this.labels['fiPausa6'] = _this.i18n('Dissabte fi pausa');
        _this.labels['hasta6'] = _this.i18n('Dissabte fins');
        _this.labels['desde7'] = _this.i18n('Diumenge des de');
        _this.labels['iniciPausa7'] = _this.i18n('Diumenge inici pausa');
        _this.labels['fiPausa7'] = _this.i18n('Diumenge fi pausa');
        _this.labels['hasta7'] = _this.i18n('Diumenge fins');
        _this.labels['idZona'] = _this.i18n('Zona');
        _this.labels['idResident'] = _this.i18n('Resident');
        _this.labels['idTiquet'] = _this.i18n('Tiquet');
        return _this;
    }
    return TarifesFilter;
}(FilterData));
export { TarifesFilter };
