

<form [formGroup]="HorarioForm" (ngSubmit)="this.submit()">
  <label for="Nombre" i18n>Nombre</label>
  <input type="text" formControlName="Nombre" id="Nombre" class="form-control" required />
  <div *ngIf="HorarioForm.controls['Nombre'].invalid && (HorarioForm.controls['Nombre'].dirty || HorarioForm.controls['Nombre'].touched)" class="alert alert-danger">
    <div *ngIf="HorarioForm.controls['Nombre'].errors.required" i18n>
      El nombre es un campo obligatorio.
    </div>
</div>
  <label for="Descripcion" i18n>Descripción</label>
  <input type="text" formControlName="Descripcion" id="Descripcion" class="form-control" required/>
  <div *ngIf="HorarioForm.controls['Descripcion'].invalid && (HorarioForm.controls['Descripcion'].dirty || HorarioForm.controls['Descripcion'].touched)" class="alert alert-danger">
    <div *ngIf="HorarioForm.controls['Descripcion'].errors.required" i18n>
      La descripcion es un campo obligatorio.
    </div>
</div>
  <hr/>
  <div style="display: flex;justify-content: center;">
  <button type='button' mat-button color="primary" (click)="this.addHour()">
    <mat-icon>add</mat-icon>
  </button>
  <button type='button' mat-button color="primary" (click)="this.removeHour()">
    <mat-icon>remove</mat-icon>
  </button>
</div>
  <div *ngFor="let hour of HorarioForm.get('Horas')['controls']">
   <div [formGroup]="hour">
      <label for="HoraInicio" i18n>Hora Inicio</label>
      <input type="time" formControlName="HoraInicio" class="form-control" required />
      <div *ngIf="hour.controls['HoraInicio'].invalid && (hour.controls['HoraInicio'].dirty || hour.controls['HoraInicio'].touched)" class="alert alert-danger">
        <div *ngIf="hour.controls['HoraInicio'].errors.required" i18n>
          La hora de inicio es un campo obligatorio.
        </div>
    </div>
      <label for="HoraFin" i18n>Hora Fin</label>
      <input type="time" formControlName="HoraFin" class="form-control" />
      <div *ngIf="hour.controls['HoraFin'].invalid && (hour.controls['HoraFin'].dirty || hour.controls['HoraFin'].touched)" class="alert alert-danger">
        <div *ngIf="hour.controls['HoraFin'].errors.required" i18n>
          La hora de salida es un campo obligatorio.
        </div>
    </div>
   </div>
  </div>
  <div style="display: flex; justify-content: center;">
  <button type='submit' mat-button color="primary"   [disabled]="HorarioForm.pristine || HorarioForm.invalid" >
    <mat-icon>save</mat-icon>
  </button>
</div>
</form>

