var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var AnulacionZonaAzulAutoFilter = /** @class */ (function (_super) {
    __extends(AnulacionZonaAzulAutoFilter, _super);
    function AnulacionZonaAzulAutoFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        var date = new Date();
        var dateCurrentDay = new Date(date.getTime());
        var datePastWeek = new Date(date.getTime());
        datePastWeek.setDate(date.getDate() - 7);
        _this.fechaInicio = datePastWeek;
        _this.fechaFin = dateCurrentDay;
        return _this;
    }
    return AnulacionZonaAzulAutoFilter;
}(FilterData));
export { AnulacionZonaAzulAutoFilter };
