<div class="row justify-content-between">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="numColecta">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Núm. Colecta</mat-header-cell>
        <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link">{{row.numColecta}}</a></mat-cell>
      </ng-container>

      <ng-container matColumnDef="fecha">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.fecha | shortDate}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="maquina">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expenedor</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.maquinaCodi}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="zona">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Zona</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.zonaDesc}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="acumuladoMoneda">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Acumulat moneda</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.acumuladoMoneda | currency:'EUR'}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>

</div>

