<app-dialog-header *ngIf="this.dataDialog.isDialog" label="Detecció Matrícules" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Consultar" i18n-text (click)="refresh();" color="has-color-blue" icon="fa-sync" [disabled]="disabled()"></app-tool-button>
  <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.Matricules"></app-tool-button-permissions>
</app-toolbar>

<div class="row" style="max-width: 170vh">
  <app-filter-text-edit id="deteccio-matricules-matricula" autofocus class="col-md-2" i18n-label label="Matrícula" [(value)]="this.filterData.matricula" (keyEnter)="refresh()"></app-filter-text-edit>
  <app-form-date-edit id="deteccio-matricules-dataIni" class="col-md-2" i18n-label label="Data Inicial" [(value)]="filterData.dataInici"></app-form-date-edit>
  <app-form-date-edit id="deteccio-matricules-dataFi" class="col-md-2" i18n-label label="Data Final" [(value)]="filterData.dataFi"></app-form-date-edit>
  <app-form-combo-edit  class="col-md-2" i18n-label label="Tipus" [(value)]="filterData.idTipusMatricula"  [items]="this.tipusMatricules" valueField="codi" textField="descripcio"></app-form-combo-edit>
  <app-form-combo-edit  class="col-md-1" i18n-label label="Agent" [(value)]="filterData.agent"  [items]="this.agents" valueField="codi" textField="codi"></app-form-combo-edit>
  <app-form-combo-edit  class="col-md-2" i18n-label label="Aparell" [(value)]="filterData.aparell"  [items]="this.aparells" valueField="id" textField="descripcion"></app-form-combo-edit>
  <app-form-combo-edit class="col-md-1" i18n-label label="PDA" [(value)]="filterData.PDA"  [items]="this.PDAs" valueField="id" textField="id"></app-form-combo-edit>
  <app-form-combo-edit class="col-md-2" i18n-label label="Comprovació denúncia" [(value)]="filterData.comprovacio" [items]="this.comprovacioDenuncies" valueField="id" textField="id"></app-form-combo-edit> 

</div>

<app-deteccio-matricules-table (itemClicked)="this.selectRow($event);"></app-deteccio-matricules-table>

