import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { ExpedienteFilter } from '../models/expediente.filter';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = 'Expedient';
const defaultSortDirection = 'asc';


@Injectable()
export class ExpedienteFilterService extends CustomFilterService {
  public createFilterParams(filter: ExpedienteFilter, start: number, pageSize: number, sortField: string, sortDir: string ): HttpParams {
    return new HttpParams()
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir)
    ;
  }
}
