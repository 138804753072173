import { OnInit, AfterViewInit } from '@angular/core';
import { EstadistiquesService } from '../../services/estadistiques.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { BaseChartDirective } from 'ng2-charts';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { Builder } from 'path-shared/models/builder';
var EstadistiquesMensualsComponent = /** @class */ (function () {
    function EstadistiquesMensualsComponent(estadistiquesService, i18n, auth, not) {
        this.estadistiquesService = estadistiquesService;
        this.i18n = i18n;
        this.auth = auth;
        this.not = not;
        this.d = new Date();
        this.dateSelected = new Date(this.d.setDate(this.d.getDate() - 7));
        this.dataDialog = Builder.newComponentDialog();
    }
    EstadistiquesMensualsComponent.prototype.ngOnInit = function () {
        this.createChart();
    };
    EstadistiquesMensualsComponent.prototype.ngAfterViewInit = function () {
        if (!this.dataDialog.isDialog) {
            this.not.titleChange(this.i18n('Estadístiques mensuals'));
        }
    };
    EstadistiquesMensualsComponent.prototype.getAparells = function () {
        var _this = this;
        if (this.dateSelected) {
            this.estadistiquesService.getAparells(this.dateSelected).subscribe(function (res) {
                _this.aparells = [];
                _this.aparells.push({ label: _this.i18n('Tots'), value: '0' });
                res.forEach(function (i) {
                    var label = '';
                    if (i.modelo !== null) {
                        label += i.modelo + ' - ';
                    }
                    if (i.marca !== null) {
                        label += i.marca;
                    }
                    if (i.calle !== null) {
                        label += ' - ' + i.calle;
                    }
                    _this.aparells.push({ label: label, value: i.id });
                });
                if (_this.aparellSelected === '0') {
                    _this.reload(_this.aparellSelected);
                }
                else {
                    if (_this.aparells.length > 0) {
                        _this.aparellSelected = _this.aparells[0].value;
                    }
                }
            });
        }
    };
    EstadistiquesMensualsComponent.prototype.reload = function (v) {
        var _this = this;
        if (v && this.dateSelected) {
            this.estadistiquesService.getEstadistiquesMensuals(v, this.dateSelected).subscribe(function (res) {
                var _a;
                if (!_this.barChartLabels) {
                    _this.barChartLabels = [];
                }
                _this.barChartLabels.length = 0;
                (_a = _this.barChartLabels).push.apply(_a, Object.values(res.ValorsX));
                _this.barChartData = [];
                var dataNumInfraccions = [];
                var dataVelMitjana = [];
                // tslint:disable-next-line: forin
                for (var key in res.Valors) {
                    dataNumInfraccions.push(res.Valors[key].NumInfraccions);
                    dataVelMitjana.push(res.Valors[key].VelocitatMitjana);
                }
                _this.barChartData.push({
                    data: dataNumInfraccions, label: _this.i18n('Num. infraccions')
                });
                _this.barChartData.push({
                    data: dataVelMitjana, label: _this.i18n('Vel. mitjana'), type: 'line'
                });
                _this.title =
                    dataNumInfraccions.reduce(function (a, b) { return a + b; }) +
                        dataVelMitjana.reduce(function (a, b) { return a + b; }) > 0 ? res.Titol :
                        _this.i18n('MES SENSE DADES');
            });
        }
    };
    EstadistiquesMensualsComponent.prototype.createChart = function () {
        this.barChartType = 'bar';
        this.barChartLegend = true;
        this.barChartOptions = {
            responsive: true,
            scales: { xAxes: [{ ticks: { autoSkip: false } }], yAxes: [{}] },
            plugins: {
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                }
            }
        };
    };
    EstadistiquesMensualsComponent.prototype.onDateChange = function () {
        this.getAparells();
    };
    return EstadistiquesMensualsComponent;
}());
export { EstadistiquesMensualsComponent };
