import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { MarquesVehiclesTableComponent } from '../components/marques-vehicles-table/marques-vehicles-table.component';
import { MarquesVehiclesFilterComponent } from '../components/marques-vehicles-filter/marques-vehicles-filter.component';
import { MarquesVehiclesFilter } from '../models/marques-vehicles-filter';
import { MarquesVehiclesEditorComponent } from '../components/marques-vehicles-editor/marques-vehicles-editor.component';

@Component({
  selector: 'app-marques-vehicles',
  templateUrl: './marques-vehicles.component.html',
  styleUrls: ['./marques-vehicles.component.scss']
})
export class MarquesVehiclesComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: MarquesVehiclesFilter;
  somIdFoo: number;
  tipusMatricules = Array();
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(MarquesVehiclesTableComponent) marquesVehiclesTable: MarquesVehiclesTableComponent;

  constructor(private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<MarquesVehiclesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new MarquesVehiclesFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.MarquesVehicles);
    this.marquesVehiclesTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.marquesVehiclesTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Marques vehicles'));
    }
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

    const ref = this.dialog.open(MarquesVehiclesEditorComponent, {
      width: '40%',
      height: '80%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.marquesVehiclesTable.load(); }
    });
  }

  refresh() {
    this.marquesVehiclesTable.load();
  }

  onFilterClicked() {
      const ref = this.dialog.open(MarquesVehiclesFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.marquesVehiclesTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: MarquesVehiclesFilter) => {
      this.marquesVehiclesTable.filter.set(filter);
      this.marquesVehiclesTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.marquesVehiclesTable.generateExcel();
  }

}
