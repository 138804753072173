<div class="form-group">
  <mat-form-field>
    <textarea type="text" #input [placeholder]="this.label"  aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto" [required]="this.required" 
    (keyup.enter)="onEnter()" [readonly]="this.isReadOnly" (focusout)="focusOut()"></textarea>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getDisplay()" [class]="this.getClass()" (optionSelected)="desFocus()">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{this.getText(option)}}
      </mat-option>
    </mat-autocomplete>
    <button tabindex="-1" (click)="togglePanel($event)" class="btn-xs btn-primary-outline" [disabled]="this.isReadOnly"><i class="fa fa-caret-down"></i></button>
  </mat-form-field>
  <div [hidden]="this.valid()" class="text-danger alert-field-errors">
      <div [hidden]="!this.errorRequired()" i18n>Camp obligatori!</div>
  </div>
</div>