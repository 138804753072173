/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./dropdown-institucions.component";
import * as i3 from "./dropdown-institucions.service";
import * as i4 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i5 from "../../services/authentication/auth-guard.service";
import * as i6 from "../../../sections/login/services/login.service";
var styles_DropdownInstitucionsComponent = [];
var RenderType_DropdownInstitucionsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DropdownInstitucionsComponent, data: {} });
export { RenderType_DropdownInstitucionsComponent as RenderType_DropdownInstitucionsComponent };
function View_DropdownInstitucionsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeInstitucio(_v.context.$implicit.IdInstitucion) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.NombreInstitucion; _ck(_v, 2, 0, currVal_0); }); }
function View_DropdownInstitucionsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "nav-item dropdown"], ["style", "margin-right: 20px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "nav-link dropdown-toggle"], ["data-toggle", "dropdown"], ["role", "button"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "dropdown-menu scrollable-menu dropdown-menu-right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DropdownInstitucionsComponent_2)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.institucions; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentInstitutionName; _ck(_v, 2, 0, currVal_0); }); }
function View_DropdownInstitucionsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "static-label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentInstitutionName; _ck(_v, 2, 0, currVal_0); }); }
function View_DropdownInstitucionsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DropdownInstitucionsComponent_4)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.institucions == null) ? null : _co.institucions.length) == 1); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DropdownInstitucionsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DropdownInstitucionsComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["blank", 2]], null, 0, null, View_DropdownInstitucionsComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.institucions == null) ? null : _co.institucions.length) > 1); var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_DropdownInstitucionsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dropdown-institucions", [], null, null, null, View_DropdownInstitucionsComponent_0, RenderType_DropdownInstitucionsComponent)), i0.ɵdid(1, 114688, null, 0, i2.DropdownInstitucionsComponent, [i3.DropdownInstitucionsService, i4.CookieService, i5.AuthGuardService, i6.LoginService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DropdownInstitucionsComponentNgFactory = i0.ɵccf("app-dropdown-institucions", i2.DropdownInstitucionsComponent, View_DropdownInstitucionsComponent_Host_0, {}, {}, []);
export { DropdownInstitucionsComponentNgFactory as DropdownInstitucionsComponentNgFactory };
