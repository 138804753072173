import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class TramosFilter extends FilterData {
  SomId: number;
  descripcio: string;
  numPlazas: string;
  temporadaSomId: number;


  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.SomId = null;
    this.descripcio = '';
    this.numPlazas = null;
    this.temporadaSomId = null;

    this.labels['SomId'] = this.i18n('Id');
    this.labels['descripcio'] = this.i18n('Descripció');
    this.labels['numPlazas'] = this.i18n('numPlazas');
    this.labels['temporadaSomId'] = this.i18n('temporada');
  }
}
