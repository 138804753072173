var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var AparellFilter = /** @class */ (function (_super) {
    __extends(AparellFilter, _super);
    function AparellFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.id = '';
        _this.descripcion = '';
        _this.tipoRadar = '';
        _this.fechaRevisionInicio = null;
        _this.fechaRevisionFin = null;
        _this.fechaCaducidadInicio = null;
        _this.fechaCaducidadFin = null;
        _this.margenErrorInicio = null;
        _this.margenErrorFin = null;
        _this.marca = '';
        _this.modelo = '';
        _this.numeroSerie = '';
        _this.codigoCEM = '';
        _this.numeroAntena = '';
        _this.numeroBastidor = '';
        _this.labels['id'] = _this.i18n('Id');
        _this.labels['descripcion'] = _this.i18n('Descripció');
        _this.labels['tipoRadar'] = _this.i18n('Tipus radar');
        _this.labels['fechaRevisionInicio'] = _this.i18n('Data revisió inici');
        _this.labels['fechaRevisionFin'] = _this.i18n('Data revisió fi');
        _this.labels['fechaCaducidadInicio'] = _this.i18n('Data caducitat inici');
        _this.labels['fechaCaducidadFin'] = _this.i18n('Data caducitat fi');
        _this.labels['margenErrorInicio'] = _this.i18n('Marge error inici');
        _this.labels['margenErrorFin'] = _this.i18n('Marge error fi');
        _this.labels['marca'] = _this.i18n('Marca');
        _this.labels['modelo'] = _this.i18n('Model');
        _this.labels['numeroSerie'] = _this.i18n('Número sèrie');
        _this.labels['codigoCEM'] = _this.i18n('Codi CEM');
        _this.labels['numeroAntena'] = _this.i18n('Número antena');
        _this.labels['numeroBastidor'] = _this.i18n('Número bastidor');
        return _this;
    }
    return AparellFilter;
}(FilterData));
export { AparellFilter };
