import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})


export class ReportsComponent implements OnInit {

  reports;
  displayedColumns: ['report'];
  downloadSelected: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public thisDialogRef: MatDialogRef<ReportsComponent>,
  ) {
    this.reports = data;

   }

  ngOnInit() {
  }

  SelectReport(report) {
    this.thisDialogRef.close(report);
  }
}
