/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./display-text-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../dialog/dialog-header/dialog-header.component";
import * as i4 from "@angular/common";
import * as i5 from "./display-text-dialog.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "ngx-toastr";
import * as i8 from "@ngx-translate/i18n-polyfill";
var styles_DisplayTextDialogComponent = [i0.styles];
var RenderType_DisplayTextDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DisplayTextDialogComponent, data: {} });
export { RenderType_DisplayTextDialogComponent as RenderType_DisplayTextDialogComponent };
function View_DisplayTextDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DisplayTextDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["    ", "\n  "])), i1.ɵpid(0, i4.JsonPipe, [])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.text)); _ck(_v, 1, 0, currVal_0); }); }
function View_DisplayTextDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
function View_DisplayTextDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 0, 0, currVal_0); }); }
function View_DisplayTextDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "is-link"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.text.split("\\").pop(); _ck(_v, 2, 0, currVal_1); }); }
export function View_DisplayTextDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DisplayTextDialogComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["style", "margin-top: 4vh;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DisplayTextDialogComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DisplayTextDialogComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DisplayTextDialogComponent_4)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DisplayTextDialogComponent_5)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.label != null); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.type == "json"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.type != "json") && (_co.type != "link")) && (_co.type != "html")); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.type == "html"); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.type == "link"); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_DisplayTextDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-display-text-dialog", [], null, null, null, View_DisplayTextDialogComponent_0, RenderType_DisplayTextDialogComponent)), i1.ɵdid(1, 114688, null, 0, i5.DisplayTextDialogComponent, [i6.MatDialogRef, i7.ToastrService, i8.I18n, i6.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DisplayTextDialogComponentNgFactory = i1.ɵccf("app-display-text-dialog", i5.DisplayTextDialogComponent, View_DisplayTextDialogComponent_Host_0, { text: "text", type: "type", label: "label" }, {}, []);
export { DisplayTextDialogComponentNgFactory as DisplayTextDialogComponentNgFactory };
