import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class SeguretatUsuarisFilter extends FilterData {
  codiUser: number;
  nomUsuari: string;
  descripcio: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.codiUser = null;
    this.nomUsuari = '';
    this.descripcio = '';

    this.labels['codiUser'] = this.i18n('Codi usuari');
    this.labels['nomUsuari'] = this.i18n('Nom usuari');
    this.labels['descripcio'] = this.i18n('Descripció');
  }
}
