var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var DeteccioMatriculaFilter = /** @class */ (function (_super) {
    __extends(DeteccioMatriculaFilter, _super);
    function DeteccioMatriculaFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.matricula = '';
        _this.dataFi = null;
        _this.dataInici = null;
        _this.any = null;
        _this.mes = null;
        _this.dia = null;
        _this.idTipusMatricula = null;
        _this.agent = '';
        _this.PDA = '';
        _this.aparell = '';
        _this.comprovacio = '';
        _this.labels['dataFi'] = _this.i18n('Data Fi');
        _this.labels['dataInici'] = _this.i18n('Data Inici');
        _this.labels['matricula'] = _this.i18n('Matrícula');
        _this.labels['any'] = _this.i18n('Any');
        _this.labels['mes'] = _this.i18n('Mes');
        _this.labels['dia'] = _this.i18n('Dia');
        _this.labels['idTipusMatricula'] = _this.i18n('Tipus Matrícula');
        _this.labels['agent'] = _this.i18n('Agent');
        _this.labels['PDA'] = _this.i18n('PDA');
        _this.labels['aparell'] = _this.i18n('Aparell');
        _this.labels['idDenuncia'] = _this.i18n('Id Denúncia');
        _this.labels['comprovacio'] = _this.i18n('Comprovació denúncies');
        _this.formatOutput['dataInici'] = 'shortDate';
        _this.formatOutput['dataFi'] = 'shortDate';
        return _this;
    }
    return DeteccioMatriculaFilter;
}(FilterData));
export { DeteccioMatriculaFilter };
