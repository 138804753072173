<app-dialog-header i18n-label label="Configurar filtros"></app-dialog-header>
<app-dialog-body>
  <p i18n style="margin-top:1vh">Indique los distintos filtros que desea que aparezcan</p>
  <div class="card card-checks">
    <app-form-check-edit i18n-label label="Tipo de denuncia" [(value)]="filtro.tipo"></app-form-check-edit>
    <app-form-check-edit i18n-label label="Legislación" [(value)]="filtro.legislacion"></app-form-check-edit>
    <app-form-check-edit i18n-label label="Origen" [(value)]="filtro.origen"></app-form-check-edit>
    <app-form-check-edit i18n-label label="Tipo de matrícula" [(value)]="filtro.matricula"></app-form-check-edit>
    <app-form-check-edit i18n-label label="Imágenes" [(value)]="filtro.fotos"></app-form-check-edit>
  </div>
  <div style="text-align: center;">
    <button class="btn btn-primary" (click)="saveChanges()" i18n>Guardar cambios</button>
  </div>

</app-dialog-body>