import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnInit {

  _isReadOnly: boolean;
  @Input() get isReadOnly() {
    return this._isReadOnly;
  } set isReadOnly(val) {
    this._isReadOnly = val;
  }
  @Input() placeHolderText: String;
  @Input() textoFormatado: string;
  @Output() textoFormatadoChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('editorView') editorView: QuillEditorComponent;

  constructor() {
    if (this.placeHolderText === undefined) {
      this.placeHolderText = '';
    }
    this.isReadOnly = true;
  }

  ngOnInit() { }

  contentChanged(newTextValue: any): void {
    this.textoFormatado = newTextValue.html;
    this.textoFormatadoChanged.emit(this.textoFormatado);
  }
}
