import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { DesplacatsGruaFilter } from 'app/sections/grua/Models/desplacats-filter';
import { DipositGruaFilter } from 'app/sections/grua/Models/diposit-filter';
import { CombosService } from 'path-shared/services/form/combos.service';

@Component({
  selector: 'app-desplacats-filter',
  templateUrl: './desplacats-filter.component.html',
  styleUrls: ['./desplacats-filter.component.scss']
})
export class DesplacatsFilterComponent implements OnInit, OnDestroy {
  filter: DesplacatsGruaFilter;
  @Output() notify: EventEmitter<DesplacatsGruaFilter> = new EventEmitter<DesplacatsGruaFilter>();

  calles = [];
  constructor(
    public thisDialogRef: MatDialogRef<DipositGruaFilter>,
    private combosService: CombosService,
    @Inject(MAT_DIALOG_DATA) public data, ) {
      this.filter = data.filter;
    }

  async ngOnInit() {
    this.calles = await this.combosService.GetCalles();
  }


  ngOnDestroy(): void {
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }
}
