<app-dialog-header label="Respuestas" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Respostes) || !this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Respostes) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Respostes) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="respostes-editor-codi" class="col-sm-2" i18n-label label="Cód. Respuesta" [(value)]="this.resposta.codi" required ></app-form-text-edit>
      <app-form-text-edit id="respostes-editor-descripcio" class="col-sm-8" i18n-label label="Descripción" [(value)]="this.resposta.descripcio" ></app-form-text-edit>
      <app-form-check-edit id="respostes-editor-acceptacio" class="col-sm-2" i18n-label label="Aceptación" [(value)]="this.resposta.acceptacio" required ></app-form-check-edit>

    </div>
    
  </form>
</app-dialog-body>
