import { StartupService } from '../startup/startup.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { config } from 'path-shared/configuration/config';
import { AuthGuardService } from '../authentication/auth-guard.service';
import { api } from 'path-shared/configuration/urls';
import { Reflection } from '../reflection/reflection';
import * as i0 from "@angular/core";
import * as i1 from "../startup/startup.service";
import * as i2 from "../http/httpSecureClient";
import * as i3 from "../authentication/auth-guard.service";
var ParametersService = /** @class */ (function () {
    function ParametersService(startupService, http, authGuard) {
        this.startupService = startupService;
        this.http = http;
        this.authGuard = authGuard;
    }
    ParametersService.prototype.getParameter = function (name) {
        var value = '';
        var param = this.startupService.GetParametres().find(function (x) { return x.PARAMETRO === name; });
        if (param !== undefined) {
            value = param.VALOR;
        }
        return value;
    };
    // Este parametro está a parte porque no se requiere authorization!!
    ParametersService.prototype.getDiasCaducidadPassword = function (idInstit) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDiasCaducidadPassword'].replace('{idInstit}', idInstit), null);
    };
    // Este parametro está a parte porque no se requiere authorization!!
    ParametersService.prototype.getForzarContraseñaSuperSegura = function (idInstit) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetForzarContraseñaSuperSegura'].replace('{idInstit}', idInstit), null);
    };
    ParametersService.prototype.getPDAs = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetPDAs'], null);
        }
    };
    ParametersService.prototype.setParameter = function (parametroActualizado) {
        var json = sessionStorage.getItem('parameters');
        if (!Reflection.empty(json)) {
            var values = JSON.parse(json.toString());
            values.find(function (g) { return g.PARAMETRO === parametroActualizado.PARAMETRO; }).VALOR = parametroActualizado.VALOR;
            var jsonFinal = JSON.stringify(values);
            sessionStorage.setItem('parameters', jsonFinal);
        }
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetParametro'], parametroActualizado);
    };
    ParametersService.ngInjectableDef = i0.defineInjectable({ factory: function ParametersService_Factory() { return new ParametersService(i0.inject(i1.StartupService), i0.inject(i2.HttpSecureClient), i0.inject(i3.AuthGuardService)); }, token: ParametersService, providedIn: "root" });
    return ParametersService;
}());
export { ParametersService };
