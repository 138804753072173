/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ajuda-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i4 from "../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i5 from "../../../shared/services/validation/validation.service";
import * as i6 from "../../../shared/components/form/form-text-area/form-text-area.component.ngfactory";
import * as i7 from "../../../shared/components/form/form-text-area/form-text-area.component";
import * as i8 from "./ajuda-page.component";
import * as i9 from "../../../shared/services/authorization/authorization-service";
import * as i10 from "../../../shared/services/notification/notification.service";
import * as i11 from "ngx-toastr";
import * as i12 from "@ngx-translate/i18n-polyfill";
import * as i13 from "../services/ajuda.service";
import * as i14 from "../../../shared/services/form/form.service";
var styles_AjudaPageComponent = [i0.styles];
var RenderType_AjudaPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AjudaPageComponent, data: {} });
export { RenderType_AjudaPageComponent as RenderType_AjudaPageComponent };
export function View_AjudaPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mainForm: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["mainForm", 1]], null, 16, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(4, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-form-text-edit", [["id", "ajuda-titol"], ["label", "T\u00EDtol"], ["maxLength", "50"], ["required", ""]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.titol = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FormTextEditComponent_0, i3.RenderType_FormTextEditComponent)), i1.ɵdid(10, 4308992, null, 0, i4.FormTextEditComponent, [i5.ValidationService], { label: [0, "label"], required: [1, "required"], value: [2, "value"], maxLength: [3, "maxLength"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "row fixed-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-form-text-area", [["class", "col cosMissatge"], ["label", "Cos del missatge"], ["maxLength", "5000"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.cosMissatge = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FormTextAreaComponent_0, i6.RenderType_FormTextAreaComponent)), i1.ɵdid(14, 4308992, null, 0, i7.FormTextAreaComponent, [i5.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], type: [3, "type"], maxLength: [4, "maxLength"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "row mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "col-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.enviar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Enviar"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "T\u00EDtol"; var currVal_8 = ""; var currVal_9 = _co.titol; var currVal_10 = "50"; var currVal_11 = "ajuda-titol"; _ck(_v, 10, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = "Cos del missatge"; var currVal_13 = _co.cosMissatge; var currVal_14 = "col cosMissatge"; var currVal_15 = "text"; var currVal_16 = "5000"; _ck(_v, 14, 0, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_AjudaPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ajuda-page", [], null, null, null, View_AjudaPageComponent_0, RenderType_AjudaPageComponent)), i1.ɵdid(1, 4308992, null, 0, i8.AjudaPageComponent, [i9.AuthorizationService, i10.NotificationService, i11.ToastrService, i12.I18n, i13.AjudaService, i14.FormService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AjudaPageComponentNgFactory = i1.ɵccf("app-ajuda-page", i8.AjudaPageComponent, View_AjudaPageComponent_Host_0, {}, {}, []);
export { AjudaPageComponentNgFactory as AjudaPageComponentNgFactory };
