<div class="row justify-content-between">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
      <ng-container matColumnDef="codiGrup">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi Grup</mat-header-cell>
        <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.codiGrup}}</a></mat-cell>
        </ng-container>

      <ng-container matColumnDef="descripcio">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripció</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.descripcio}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="inici">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Inici</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.inici | shortDate}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="fi">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fi</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.fi | shortDate}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actiu">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Actiu</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index"><mat-checkbox [checked]="row.actiu" [disabled]='true'></mat-checkbox></mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
</div>


