<app-filter-header title="Filtrar aparells" i18n-title (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
    <!-- TODO  Primera columna. -->


    <div class="row border border-secondary fondo ">

        <app-filter-text-edit id="aparells-filter-codi" autofocus class="col-sm-3" i18n-label label="Codi" [(value)]="filter.id" maxLenght="5"></app-filter-text-edit>
        <app-filter-text-edit id="aparells-filter-descripcio" class="col-sm-6" i18n-label label="Descripció" [(value)]="filter.descripcion" maxLenght="50"></app-filter-text-edit>
        <app-filter-text-edit id="aparells-filter-tipus" class="col-sm-3" i18n-label label="tipus" [(value)]="filter.tipoRadar"></app-filter-text-edit>
    </div>

    <!-- TODO  Segunda columna. -->

    <div class="row border border-secondary fondo  ">
        <div class="col-md-1"></div>
        <div class="col-sm-1 data"> <span class="badge badge-secondary text-mute labelCobrament " i18n>Data revisió</span> </div>
        <div class="col-md-2">
            <app-form-date-edit id="aparells-filter-dataRevisioIni" [(value)]="filter.fechaRevisionInicio" type="calendar"></app-form-date-edit>
        </div>
        <div class="col-md-2 like">
            <app-form-date-edit id="aparells-filter-dataRevisioFi" [(value)]="filter.fechaRevisionFin" type="calendar"></app-form-date-edit>
        </div>
        <div class="col-md-1"></div>

        <div class="col-sm-1 data" i18n> <span class="badge badge-secondary text-mute labelCobrament labelCobrament1 ">Data caducitat</span> </div>

        <div class="col-md-2">
            <app-form-date-edit id="aparells-filter-dataCaducitatIni" [(value)]="filter.fechaCaducidadInicio" type="calendar"></app-form-date-edit>
        </div>
        <div class="col-md-2 like">
            <app-form-date-edit id="aparells-filter-dataCaducitatFi" [(value)]="filter.fechaCaducidadFin" type="calendar"></app-form-date-edit>
        </div>
    </div>

    <!-- TODO  Tercera columna. -->

    <div class="row border border-secondary fondo  ">

        <div class="col-md-3"></div>
        <div class="col-sm-1 data" i18n> <span class="badge badge-secondary text-mute labelCobrament pocedntaje ">Percentatge error</span> </div>

        <div class="col-md-2">
            <app-form-text-edit id="aparells-filter-percentatgeErrorIni" i18n-label [(value)]="filter.margenErrorInicio" maxLenght="7"></app-form-text-edit>
        </div>
        <div class="col-md-2 ">
            <app-form-text-edit id="aparells-filter-percentatgeErrorFi" i18n-label [(value)]="filter.margenErrorFin" maxLenght="7"></app-form-text-edit>
        </div>
    </div>
    <!-- TODO  Cuarta columna. -->

    <div class="row border border-secondary fondo  ">
        <app-filter-text-edit id="aparells-filter-marca" class="col-sm-4" i18n-label label="Marca" [(value)]="filter.marca" maxLenght="30"></app-filter-text-edit>
        <app-filter-text-edit id="aparells-filter-model" class="col-sm-4" i18n-label label="Model" [(value)]="filter.modelo" maxLenght="30"></app-filter-text-edit>
        <app-filter-text-edit id="aparells-filter-numeroSerie" class="col-sm-4" i18n-label label="Número sèrie" [(value)]="filter.numeroSerie" maxLenght="12"></app-filter-text-edit>
    </div>
    <!-- TODO  Quinta columna. -->

    <div class="row border border-secondary fondo  ">
        <app-filter-text-edit id="aparells-filter-codiCEM" class="col-sm-4" i18n-label label="Codi CEM" [(value)]="filter.codigoCEM" maxLenght="30"></app-filter-text-edit>
        <app-filter-text-edit id="aparells-filter-numeroAntena" class="col-sm-4" i18n-label label="Número antena" [(value)]="filter.numeroAntena" maxLenght="10"></app-filter-text-edit>
        <app-filter-text-edit id="aparells-filter-numeroBastidor" class="col-sm-4" i18n-label label="Número bastidor" [(value)]="filter.numeroBastidor" maxLenght="21"></app-filter-text-edit>
    </div>
</div>