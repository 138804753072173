import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { MultipleDatePickerComponent } from 'multiple-date-picker-angular/dist';
import { MatDialog } from '@angular/material';
import { CalendarMultiselectInputModalComponent } from './calendar-multiselect-input-modal/calendar-multiselect-input-modal.component';


@Component({
  selector: 'app-calendar-multiselect-input',
  templateUrl: './calendar-multiselect-input.component.html',
  styleUrls: ['./calendar-multiselect-input.component.scss']
  
})

export class CalendarMultiselectInputComponent implements OnInit {

  val: Array<Date>;
  private dates: Date[];

  @Input() label: string;
  @Input() get value() {
    return this.val;
  } set value(val) {
    if (val !== null && val !== undefined) {
      // this.val = val;
      this.val = this.stringArrayToDate(val);
      if (this.multipleDatePickerComponent !== undefined) {
        this.multipleDatePickerComponent.writeValue(this.val);
      }
      setTimeout(() => {
        this.valueChange.emit(this.val);
      }, 10);
    }
  }
  @Output() valueChange = new EventEmitter();


  constructor(
    private dialog: MatDialog
  ) {
    this.val = new Array<Date>();
  }

  @ViewChild('inputCalendar') input: ElementRef;
  @ViewChild(MultipleDatePickerComponent) private multipleDatePickerComponent: MultipleDatePickerComponent;
  @ViewChild('inputCalendar') calendarInput: MultipleDatePickerComponent;

  ngOnInit(): void {  }

   isDate(value: Date) {
    return value instanceof Date;
  }

  stringToDate(value: Date) {
    if (value !== null && !this.isDate(value)) {
      value = new Date(value);
    }
    return value;
  }

  stringArrayToDate(value: Array<any>): Array<Date> {
    value.forEach((date, index) => {
      value[index] = this.stringToDate(date);
    });

    return value;
  }

  getInputValue(): string {
    let ret: string = '';

    const arr = this.val.sort((a, b) => a > b ? 1 : -1);

    for (let i = 0; i < arr.length; i++) {
      const d = new Date(arr[i]);
      const str = d.getDate().toString().padStart(2, '0') + '/' + (d.getMonth() + 1).toString().padStart(2, '0') + '/' + d.getFullYear();
      if (i < 2) {
        ret += ret.length > 0 ? ', ' + str : str;
      } else {
        ret += '...';
        break;
      }
    }

    return ret;
  }

  openModal() {
    const dialogData = {
      top: this.input.nativeElement.getBoundingClientRect().top,
      left : this.input.nativeElement.getBoundingClientRect().left,
      initData: this.value
    };

    const dialogRef = this.dialog.open(CalendarMultiselectInputModalComponent, {
      width: 'auto',
      height: '280px',
      data: dialogData,
      hasBackdrop: true,
      panelClass: 'calendar-multiselect-dialog'      
    });

    dialogRef.afterClosed().subscribe(
      res => {
        this.value = res;
      }
    );

  }

  
}
