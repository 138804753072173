<app-dialog-header *ngIf="this.dataDialog.isDialog" label="Alarmes" i18n-label></app-dialog-header>

<app-toolbar>
  <!--<app-tool-button text="Editar" i18n-text (click)="editAgent();" color="has-color-orange" icon="fa-edit" [disabled]="selectCheckedItems() == 1 ? false : true"></app-tool-button>-->
  <app-tool-button text="Actualizar" i18n-text (click)="refresh();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
  <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
  <app-tool-button text="Accepta Totes" i18n-text (click)="acceptAll();" color="has-color-aqua" icon="fa-check-double"></app-tool-button>
  <app-tool-button text="Històric" i18n-text (click)="irHistorico();" color="has-color-orange" icon="fa-history" ></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.AlarmSistema"></app-tool-button-permissions>
  
<!--
   <app-tool-button-permissions [permission]="this.auth.Pages.Agents"></app-tool-button-permissions>
  -->
</app-toolbar>
<app-alarmes-table [selectButton]="this.dataDialog.isDialog && !this.dataDialog.isReadOnly" [updatedFilterData]="filterData" (notify)="onRowSelected($event)" [isHistoric]="false"></app-alarmes-table>

