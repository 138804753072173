import { Injectable, ElementRef } from '@angular/core';
import { CarrersService } from 'app/sections/configuracio/carrers/services/carrers-service.service';
import { SenseRetiradaGruaService } from 'app/sections/grua/services/sense-retirada-grua-service';
import { BehaviorSubject, observable, Observable } from 'rxjs';



class Combo {
  codi: number;
  descripcio: string;

  constructor(codi, descripcio) {
    this.codi = codi;
    this.descripcio = descripcio;
  }
}
@Injectable()
export class CombosService {

  constructor(
    private callesService: CarrersService,
    private senseRetiradGruaService: SenseRetiradaGruaService
  ) { }

  GetCalles(): Promise<Combo[]> {
    return new Promise(resolve => {

      this.callesService.getAllForCombo().subscribe(res => {
        const comboList = [];
        res.forEach(element => {
          comboList.push(new Combo(element.somId, (element.sigles !== null ? element.sigles + ' ' : '') + element.nomCarrer));
        });
        resolve(comboList);
      });

    });
  }

  GetMotivosNoRetirada(): Promise<Combo[]> {
    return new Promise(resolve => {

      this.senseRetiradGruaService.getMotivosNoRetirada().subscribe(res => {
        resolve(res);
      });

    });
  }
}
