import { OnInit, EventEmitter, ViewContainerRef } from '@angular/core';
import { OwlDateTimeFormats } from 'ng-pick-datetime';
export var MY_CUSTOM_FORMATS = {
    parseInput: 'DD/MM/YYYY',
    fullPickerInput: 'DD/MM/YYYY HH:mm:ss',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: 'HH:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};
var FormDateEditComponent = /** @class */ (function () {
    function FormDateEditComponent(cr, locale) {
        this.cr = cr;
        this.locale = locale;
        this.validVal = false;
        this.validClass = 'ng-valid';
        this.valueChange = new EventEmitter();
        this.focusOut = new EventEmitter();
        this.type = 'calendar';
        this.initialValue = undefined;
        this.isReadOnly = false;
        this.view = 'month';
        this.mode = 'single';
        this.minDate = undefined;
        this._maxDate = undefined;
        this.val = new Date();
        this.lang = this.locale;
        this.required = false;
    }
    Object.defineProperty(FormDateEditComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            var _this = this;
            if (this.isMoment(val) && ((typeof (val._i) !== 'string') || (typeof (val._i) === 'string' && val._i.length === 10))) {
                val = val.toDate();
            }
            var emmit = true;
            if (new Date(val) === new Date(this.val)) {
                emmit = false;
            }
            this.val = val;
            this.valid();
            setTimeout(function () {
                if (emmit) {
                    _this.valueChange.emit(_this.val);
                }
            }, 10);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormDateEditComponent.prototype, "maxDate", {
        get: function () {
            return this._maxDate;
        },
        set: function (val) {
            this._maxDate = val;
        },
        enumerable: true,
        configurable: true
    });
    FormDateEditComponent.prototype.modelChanged = function (event) {
        // 
        // this.valueChange.emit(this.val);
    };
    FormDateEditComponent.prototype.isMoment = function (val) {
        return (val !== undefined && val !== null) && val._isAMomentObject !== null && val._isAMomentObject === true;
    };
    FormDateEditComponent.prototype.ngOnInit = function () {
        if (this.initialValue !== undefined) {
            this.value = this.initialValue;
        }
        if (this.view === 'year') {
            this.dtp.dateTimeFormats.datePickerInput = 'MM/YYYY';
        }
        else {
            this.dtp.dateTimeFormats.datePickerInput = 'DD/MM/YYYY';
        }
        this.dtp.firstDayOfWeek = 1;
        this.dtp.dateTimeAdapter.setLocale(this.lang);
        this.valid();
    };
    FormDateEditComponent.prototype.getName = function () {
        return '#' + this.id;
    };
    FormDateEditComponent.prototype.onKeyEnter = function (value) {
        var val = value.replace('/', '');
        if (val.length === 6) {
            this.value = this.splitDate(val);
        }
        else if (val.length === 8) {
            this.value = this.splitDateFullYear(val);
        }
        else {
            this.value = this.splitFullDate(value);
        }
    };
    FormDateEditComponent.prototype.splitDateFullYear = function (value) {
        var day = parseInt(value.substr(0, 2), 10);
        var month = parseInt(value.substr(2, 2), 10) - 1;
        var year = parseInt(value.substr(4, 4), 10);
        return new Date(year, month, day, 0, 0, 0, 0);
    };
    FormDateEditComponent.prototype.splitDate = function (value) {
        var day = parseInt(value.substr(0, 2), 10);
        var month = parseInt(value.substr(2, 2), 10) - 1;
        var year = parseInt(value.substr(4, 2), 10) + 2000;
        return new Date(year, month, day, 0, 0, 0, 0);
    };
    FormDateEditComponent.prototype.splitFullDate = function (value) {
        var parts = value.split('/');
        if (parts.length === 3) {
            var day = parseInt(parts[0], 10);
            var month = parseInt(parts[1], 10) - 1;
            var year = parseInt(parts[2], 10);
            return new Date(year, month, day, 0, 0, 0, 0);
        }
        return null;
    };
    FormDateEditComponent.prototype.change = function (event) {
        this.onKeyEnter(event.target.value);
    };
    FormDateEditComponent.prototype.valid = function () {
        var ok = !this.errorRequired();
        this.validVal = ok;
        return ok;
    };
    FormDateEditComponent.prototype.isRequired = function () {
        return this.required !== false;
    };
    FormDateEditComponent.prototype.errorRequired = function () {
        return this.isRequired() && (this.value === null);
    };
    FormDateEditComponent.prototype.chosenMonthHandler = function (normalizedMonth) {
        if (this.view === 'year') {
            this.value = normalizedMonth;
            this.dtp.close();
        }
    };
    FormDateEditComponent.prototype.onFocusOutEvent = function (event) {
        this.focusOut.emit(event);
    };
    return FormDateEditComponent;
}());
export { FormDateEditComponent };
