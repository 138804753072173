<app-toolbar>
  <app-tool-button text="Guardar" id="filtreDropDown" i18n-text (click)="saveDenuncia();" color="has-color-green"
    icon="fa-plus-square"></app-tool-button>
  <app-tool-button text="Cancel·lar" id="filtreDropDown" i18n-text (click)="cancel();" color="has-color-red"
    icon="fa-times"></app-tool-button>
</app-toolbar>

<app-page-title i18n>Nova denúncia</app-page-title>

<app-dialog-body style="max-height: 100% !important">
  <form #mainForm>
    <div class="row">
      <!-- Meitat esquerra formulari -->
      <div class="col-md-6">

        <div class="form-group row">
          <!-- Data -->
          <app-form-date-edit id="denuncies-new-data" class="col-sm-4" i18n-label label="Data"
            [(value)]="this.denunciaDetail.data" (valueChange)="this.calcularDataDescompte()" type="calendar" required>
          </app-form-date-edit>
          <!-- Hora -->
          <app-form-text-edit id="denuncies-new-hora" class="col-sm-4" type="time" i18n-label label="Hora"
            [(value)]="this.denunciaDetail.hora" required></app-form-text-edit>
          <!-- Exp. Extern -->
          <app-form-text-edit id="denuncies-new-expExt" class="col-sm-4" i18n-label label="Exp. extern"
            [(value)]="this.denunciaDetail.expedientExtern"></app-form-text-edit>
        </div>

        <div class="row">
          <!-- Carrer -->
          <div class="col-sm-8">
            <!--<app-form-text-edit i18n-label label="Carrer" [(value)]="this.denunciaDetail.carrer" [readonly]="true" required></app-form-text-edit>-->
            <app-combo-box-input-autocomplete #comboCalle class="center-horizontal" i18n-label label="Carrer"
              [options]="this.callesDictionary" (valorSeleccionado)="this.calleSeleccionado($event)"
              [(valorAutocomplete)]="this.valorAutoCompleteCalleDictionary">
            </app-combo-box-input-autocomplete>
            <!-- <app-view-list-icon (listClick)="seleccionarCarrer()" [hideView]="true"></app-view-list-icon> -->
          </div>

          <!-- Número carrer -->
          <app-form-text-edit id="denuncies-new-numero" class="col-xl-3" i18n-label label="Número"
            [(value)]="this.denunciaDetail.numero" maxLenght="4" required></app-form-text-edit>
        </div>

        <div class="form-group row" *ngIf="this.denunciaDetail.tipusIdInfraccio !== 'S'">
          <!-- Matrícula -->
          <div class="col-sm-3">
            <app-form-text-edit id="denuncies-new-matricula" i18n-label label="Matrícula"
              [(value)]="this.denunciaDetail.matricula" [readonly]="true" required></app-form-text-edit>
            <app-view-list-icon (listClick)="seleccionarVehicle()" [hideView]="true"></app-view-list-icon>
          </div>

          <!-- País -->
          <app-form-text-edit id="denuncies-new-pais" class="col-sm-3" i18n-label label="País"
            [(value)]="this.denunciaDetail.paisDesc" [disabled]="true"></app-form-text-edit>
          <!-- Marca -->
          <app-form-text-edit id="denuncies-new-marca" class="col-sm-3" i18n-label label="Marca"
            [(value)]="this.denunciaDetail.marca" [disabled]="true"></app-form-text-edit>
          <!-- Model -->
          <app-form-text-edit id="denuncies-new-model" class="col-sm-3" i18n-label label="Model"
            [(value)]="this.denunciaDetail.modelo" [disabled]="true"></app-form-text-edit>
        </div>


        <!-- Caixa tabs esquerra Conductor/Propietari -->
        <mat-tab-group class="has-frame">
          <!-- Conductor -->
          <mat-tab>
            <ng-template mat-tab-label i18n-label>{{this.denunciaDetail.tipusIdInfraccio !== 'S' ? 'Conductor':
              'Infractor'}}</ng-template>
            <div class="row">
              <app-form-text-edit id="denuncies-new-dni" class="col-xl-9" i18n-label label="DNI/PASP"
                [(value)]="this.denunciaDetail.dni_conductor" (keyup.enter)="this.consultarConductor()"
                (valueChange)="HandleChange('conductor')"></app-form-text-edit>
              <app-view-list-icon (listClick)="this.consultarConductor()" hideView="true"></app-view-list-icon>
            </div>

            <div class="row">
              <app-form-text-edit disabled id="denuncies-new-nom" class="col-xl-12" i18n-label label="Nom"
                [(value)]="this.denunciaDetail.nom_conductor"></app-form-text-edit>
            </div>

            <div class="form-group">
              <app-combo-box-input #comboDomConductor i18n-label label="Domicili"
                [showingOptions]="this.domicilisConductorDictionary.value"
                (optionChanged)="this.domicilioConductorSeleccionado($event)"
                [disabled]="this.domicilisConductorDictionary.value.length === 0 || this.domicilisConductorDictionary.value.length === 1">
              </app-combo-box-input>
            </div>
          </mat-tab>

          <!-- Propietari -->
          <mat-tab i18n-label label="Propietari" *ngIf="this.denunciaDetail.tipusIdInfraccio !== 'S'">
            <div class="row">
              <app-form-text-edit id="denuncies-new-dniProp" class="col-xl-9" i18n-label label="DNI/PASP"
                [(value)]="this.denunciaDetail.dni_propietari" (keyup.enter)="this.consultarPropietari()"
                (valueChange)="HandleChange('propietari')"></app-form-text-edit>
              <app-view-list-icon class="col-xl-2" (listClick)="this.consultarPropietari()" hideView="true">
              </app-view-list-icon>

              <mat-checkbox class="col-xl-1" [checked]="this.denunciaDetail.juridica_propietari"
                (input)="this.denunciaDetail.juridica_propietari=$event.target.value">PJ</mat-checkbox>
            </div>

            <div class="row">
              <app-form-text-edit id="denuncies-new-nomProp" class="col-xl-12" i18n-label label="Nom"
                [(value)]="this.denunciaDetail.nom_propietari" disabled></app-form-text-edit>
            </div>

            <div class="form-group">
              <app-combo-box-input #comboDomPropietari [label]="'Domicili'"
                [showingOptions]="this.domicilisPropietariDictionary.value"
                (optionChanged)="this.domicilioPropietarioSeleccionado($event)"
                [disabled]="this.domicilisPropietariDictionary.value.length === 0 || this.domicilisPropietariDictionary.value.length === 1">
              </app-combo-box-input>
            </div>

          </mat-tab>

          <!-- Establiment -->
          <mat-tab i18n-label label="Establecimiento" *ngIf="this.denunciaDetail.tipusIdInfraccio === 'S'">
            <div class="row">
              <app-form-text-edit id="denuncies-new-cifEstablecimiento" class="col-xl-9" i18n-label label="DNI/PASP"
                [(value)]="this.denunciaDetail.EstablecimientoCIF" (keyup.enter)="this.consultarEstabliment()"
                (valueChange)="HandleChange('establiment')">
              </app-form-text-edit>
              <app-view-list-icon (listClick)="this.consultarEstabliment()" hideView="true"></app-view-list-icon>
            </div>
            <div class="row">
              <app-form-text-edit disabled id="denuncies-new-nomEstablecimiento" class="col-xl-12" i18n-label
                label="Nom" [(value)]="this.denunciaDetail.EstablecimientoRazonSocial">
              </app-form-text-edit>
            </div>
          </mat-tab>

          <!-- Treballador Establiment -->
          <mat-tab i18n-label label="Trabajador" *ngIf="this.denunciaDetail.tipusIdInfraccio === 'S'">
            <div class="row">
              <app-form-text-edit id="denuncies-new-nifTrabajador" class="col-xl-9" i18n-label label="DNI/PASP"
                [(value)]="this.denunciaDetail.EstablecimientoTrabajadorNIE" (keyup.enter)="this.consultarTreballador()"
                (valueChange)="HandleChange('treballador')">
              </app-form-text-edit>
              <app-view-list-icon (listClick)="this.consultarTreballador()" hideView="true"></app-view-list-icon>
            </div>
            <div class="row">
              <app-form-text-edit disabled id="denuncies-new-nomTrabajador" class="col-xl-12" i18n-label label="Nom"
                [(value)]="this.denunciaDetail.EstablecimientoTrabajadorNombre">
              </app-form-text-edit>
            </div>
          </mat-tab>

        </mat-tab-group>
        <div class="row" *ngIf="this.denunciaDetail.tipusIdInfraccio !== 'S'">
          <app-form-check-edit class="col-sm-2 mr-3" i18n-label label="Judicial"
            [(value)]="this.denunciaDetail.judicial" [disabled]="true"></app-form-check-edit>
          <app-form-check-edit class="col-sm-2" i18n-label label="DGT" [(value)]="this.denunciaDetail.jpt">
          </app-form-check-edit>
          <app-form-check-edit class="col-sm-2" i18n-label label="SCT" [(value)]="this.denunciaDetail.sct">
          </app-form-check-edit>
        </div>
      </div>

      <!-- Meitat dreta formulari -->
      <div class="col-md-6">

        <div class="form-group row">
          <!-- Agent -->
          <div class="col-sm-3">
            <app-form-text-edit id="denuncies-new-agent" i18n-label label="Agent"
              [(value)]="this.denunciaDetail.codiAgent" [readonly]="true" required></app-form-text-edit>
            <app-view-list-icon (listClick)="this.seleccionarAgent()" [hideView]="true"></app-view-list-icon>
          </div>

          <div class="col-sm-3">
            <app-form-combo-edit id="denuncies-new-notificacion" i18n-label label="Motivo no notificación"
              [(value)]="this.denunciaDetail.codiNoNotificacio" required [items]="this.motiusNoNotificacio"
              textField="descripcio" valueField="codi"></app-form-combo-edit>
          </div>

        </div>



        <div class="form-group">
          <!-- Zona -->
          <app-combo-box-input #comboZona [label]="'Zona'" [showingOptions]="this.zonesDictionary.value"
            (optionChanged)="this.zonaCarrerSeleccionado($event)" [(value)]="this.denunciaDetail.secure_id_zona"
            [disabled]="this.zonesDictionary.value.length === 0">
          </app-combo-box-input>
        </div>

        <!-- Caixa tabs dreta Infraccions/Observacions/Al·lagacions -->
        <mat-tab-group class="has-frame">
          <mat-tab>
            <ng-template mat-tab-label i18n-label label="Infracció">
              <span i18n>Infracció</span>
              <app-view-list-icon (listClick)="this.seleccionarLegislacio()" [hideView]="true"></app-view-list-icon>
            </ng-template>
            <div class="form-group row">
              <!-- Llei -->
              <div class="col-sm-5">
                <app-form-text-edit id="denuncies-new-llei" i18n-label label="Llei"
                  [(value)]="this.denunciaDetail.lleiDesc" [disabled]="true" required></app-form-text-edit>

              </div>

              <!-- Article -->
              <app-form-text-edit id="denuncies-new-article" class="col-sm-2" i18n-label label="Article"
                [(value)]="this.denunciaDetail.article" [disabled]="true"></app-form-text-edit>

              <!-- Tipus de denúncia -->
              <app-combo-box-input #comboTipusDenuncia class="col-sm-5" i18n-label label="Tipus de denúncia"
                [showingOptions]="this.tipusDenunciesDictionary.value"
                (optionChanged)="this.tipusDenunciaSeleccionado($event)" [(value)]="this.denunciaDetail.tipusDenuncia"
                [disabled]="true" required>
              </app-combo-box-input>
            </div>

            <!-- Infraccio descripcio -->
            <div class="form-group">
              <!-- <app-form-text-edit [(value)]="this.denunciaDetail.descripcioInfraccio" [disabled]="true"></app-form-text-edit> -->
              <app-combo-box-input-autocomplete #comboInfraccion class="center-horizontal" i18n-label label="Infracció"
                [options]="this.infraccionesDictionary" (valorSeleccionado)="this.infraccioSeleccionado($event)"
                [valorAutocomplete]="this.valorAutoCompleteInfraccionesDictionary"></app-combo-box-input-autocomplete>
            </div>

            <div class="row">
              <!-- Import -->
              <app-form-text-edit id="denuncies-new-import" class="col-xl-4" i18n-label label="Import"
                [(value)]="this.denunciaDetail.import" (valueChange)="this.calcularImportDescompte()"
                [disabled]="this.denunciaDetail.tipusIdInfraccio !== 'S'"></app-form-text-edit>
              <!-- Qualificació -->
              <app-combo-box-input #comboTipusGravetat class="col-xl-4" i18n-label label="Qualificació"
                [showingOptions]="this.tipusGravetatDictionary.value"
                (optionChanged)="this.tipusGravetatSeleccionado($event)" [(value)]="this.denunciaDetail.qualificacio"
                [disabled]="true">
              </app-combo-box-input>
              <!-- Punts -->
              <app-form-text-edit id="denuncies-new-punts" class="col-xl-4"
                *ngIf="this.denunciaDetail.tipusIdInfraccio !== 'S'" i18n-label label="Punts"
                [(value)]="this.denunciaDetail.punts" [disabled]="true"></app-form-text-edit>
            </div>

            <div class="row">
              <!-- Import descompte -->
              <app-form-text-edit id="denuncies-new-impDesc" class="col-xl-6" i18n-label label="Imp. descompte"
                [(value)]="this.denunciaDetail.importDescompte" [disabled]="true"></app-form-text-edit>
              <!-- Data descompte -->
              <app-form-date-edit id="denuncies-new-dataDesc" class="col-xl-6" i18n-label label="Data"
                [(value)]="this.denunciaDetail.dataDescompte" type="calendar" disabled="true"></app-form-date-edit>
            </div>
          </mat-tab>

          <mat-tab i18n-label label="Observacions">
            <div class="form-group">
              <mat-form-field>
                <textarea cols="20" matInput [value]="this.denunciaDetail.observacions"
                  (input)="this.denunciaDetail.observacions=$event.target.value"></textarea>
              </mat-form-field>
            </div>
          </mat-tab>

          <mat-tab i18n-label label="Al·legacions">
            <div class="form-group">
              <mat-form-field>
                <textarea cols="20" matInput [value]="this.denunciaDetail.alegacio"
                  (input)="this.denunciaDetail.alegacio=$event.target.value"></textarea>
              </mat-form-field>
            </div>
          </mat-tab>

        </mat-tab-group>

      </div>
    </div>
  </form>
</app-dialog-body>