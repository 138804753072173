import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FechasNotificacionDto } from '../../../../models/notificar/fechas-notificacion.dto';

@Component({
  selector: 'app-selector-fechas-notificar',
  templateUrl: './selector-fechas-notificar.component.html',
  styleUrls: ['./selector-fechas-notificar.component.scss']
})
export class SelectorFechasNotificarComponent implements OnInit {

  @Output() notify: EventEmitter<FechasNotificacionDto> = new EventEmitter<FechasNotificacionDto>();
  denunciesNotificarFiltre: FechasNotificacionDto;
  teDescompte : boolean;
  constructor(
    public thisDialogRef: MatDialogRef<SelectorFechasNotificarComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      if (data != null) {
        this.denunciesNotificarFiltre = data.info;
        this.teDescompte=data.teDescompte;
      }
  }

  ngOnInit() {
  }

  checkFecha(fecha: Date)
  {
    let fechaCorrecta=false;
    if(fecha != undefined && fecha != null){
      fechaCorrecta=true;
    }
    return fechaCorrecta;
  }

  apply() {
    // Formateamos las fechas.
    let correctes=0;

    if (this.checkFecha(this.denunciesNotificarFiltre.fechaPrimeraNotificacion))
    {    
      this.denunciesNotificarFiltre.fechaPrimeraNotificacion = new Date(this.denunciesNotificarFiltre.fechaPrimeraNotificacion);

      correctes+=1;
    }
    else if(this.data.teDescompte==false){

      this.denunciesNotificarFiltre.fechaPrimeraNotificacion = new Date("01/01/1900");

      correctes+=1;
    }
    if(this.checkFecha(this.denunciesNotificarFiltre.fechaSegundaNotificacion))
    {
      this.denunciesNotificarFiltre.fechaSegundaNotificacion = new Date(this.denunciesNotificarFiltre.fechaSegundaNotificacion);

      correctes+=1;
    }
    if(this.checkFecha(this.denunciesNotificarFiltre.fechaTerceraNotificacion))
    {
      this.denunciesNotificarFiltre.fechaTerceraNotificacion = new Date(this.denunciesNotificarFiltre.fechaTerceraNotificacion);

      correctes+=1;
    }
    else if(this.data.teDescompte==false){

      this.denunciesNotificarFiltre.fechaTerceraNotificacion = new Date("01/01/1900");

      correctes+=1;
    }
    if(this.checkFecha(this.denunciesNotificarFiltre.fechaCuartaNotificacion))
    {
      this.denunciesNotificarFiltre.fechaCuartaNotificacion = new Date(this.denunciesNotificarFiltre.fechaCuartaNotificacion);
      correctes+=1;
    }
      

    if(correctes==4)
    {
      this.notify.emit(this.denunciesNotificarFiltre);
      this.thisDialogRef.close();
    }

    else{
      alert('Faltan fechas');
    }
      
    
  }
}