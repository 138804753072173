import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { MessagingService } from 'path-shared/services/messaging/messaging.service';
import { MatDialog } from '@angular/material';
import { WaitDialogComponent } from 'path-shared/components/dialogs/wait-dialog/wait-dialog.component';
import { WaitDialogData } from 'path-shared/components/dialogs/wait-dialog/wait-dialog-data.model';
import * as i0 from "@angular/core";
import * as i1 from "../messaging/messaging.service";
import * as i2 from "@angular/material/dialog";
var ExcelDownloadService = /** @class */ (function () {
    function ExcelDownloadService(msg, dialog) {
        this.msg = msg;
        this.dialog = dialog;
        this.windowTimeoutMSeconds = 2000;
    }
    ExcelDownloadService.prototype.downloadExcel = function (data) {
        var _this = this;
        this.openTime = new Date().getTime();
        var dlg = null;
        dlg = this.dialog.open(WaitDialogComponent, {
            closeOnNavigation: false,
            autoFocus: true,
            width: '60%',
            data: new WaitDialogData(this.msg.GeneratingExcel, this.msg.generatingExcelMessage, this.msg.CancelGeneration, 'fa fa-file-excel has-color-green')
        });
        dlg.afterClosed().subscribe(function (result) {
            if (result !== 'autoClose') {
                generated.unsubscribe();
            }
        });
        var generated = data.subscribe(function (result) {
            FileDownloader.downLoadFile([result], 'application/vnd.ms-excel;charset=utf-8', 'MatriculesEspecials.xlsx');
            if (dlg !== null) {
                var mseconds = (new Date().getTime() - _this.openTime);
                if (mseconds < _this.windowTimeoutMSeconds) {
                    setTimeout(function () {
                        dlg.close('autoClose');
                    }, _this.windowTimeoutMSeconds - mseconds);
                }
                else {
                    dlg.close('autoClose');
                }
            }
        });
    };
    ExcelDownloadService.ngInjectableDef = i0.defineInjectable({ factory: function ExcelDownloadService_Factory() { return new ExcelDownloadService(i0.inject(i1.MessagingService), i0.inject(i2.MatDialog)); }, token: ExcelDownloadService, providedIn: "root" });
    return ExcelDownloadService;
}());
export { ExcelDownloadService };
