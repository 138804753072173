import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { DipositGruaFilter } from '../Models/diposit-filter';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { DesplacatsGruaFilter } from '../Models/desplacats-filter';
import { SenseRetiradaGruaFilter } from '../Models/sense-retirada-filter';

const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class SenseRetiradaGruaFilterService extends CustomFilterService {
  public createFilterParams(filter: SenseRetiradaGruaFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('matricula', filter.matricula)
    .append('marca', filter.marca)
    .append('modelo', filter.modelo)
    .append('idNoRetirada', filter.idNoRetirada)
    .append('dataInici', filter.dataInici ? filter.dataInici.toUTCString() : null)
    .append('dataFi', filter.dataFi ? filter.dataFi.toUTCString() : null)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
