export class NotificarRequestDto {
    fechaPrimeraNotificacion: Date;
    fechaSegundaNotificacion: Date;
    fechaTerceraNotificacion: Date;
    fechaCuartaNotificacion: Date;

    sicerActivo: boolean;

    constructor(
        fechaPrimeraNotificacion: Date,
        fechaSegundaNotificacion: Date,
        fechaTerceraNotificacion: Date,
        fechaCuartaNotificacion: Date,
        sicerActivo: boolean
    ) {
        this.fechaPrimeraNotificacion = fechaPrimeraNotificacion;
        this.fechaSegundaNotificacion = fechaSegundaNotificacion;
        this.fechaTerceraNotificacion = fechaTerceraNotificacion;
        this.fechaCuartaNotificacion = fechaCuartaNotificacion;

        this.sicerActivo = sicerActivo;
    }
}
