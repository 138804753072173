/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dash-square.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./dash-square.component";
var styles_DashSquareComponent = [i0.styles];
var RenderType_DashSquareComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashSquareComponent, data: {} });
export { RenderType_DashSquareComponent as RenderType_DashSquareComponent };
export function View_DashSquareComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "a", [], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "card-body p-3 d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "text-muted text-uppercase font-weight-bold small"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 3, 0, _co.link); _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.linkLabel, ""); var currVal_1 = i1.ɵnov(_v, 2).target; var currVal_2 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵinlineInterpolate(2, "", _co.icon, " ", ("bg-" + _co.iconClass), " p-3 font-2xl mr-3"); _ck(_v, 5, 0, currVal_4); var currVal_5 = i1.ɵinlineInterpolate(1, "text-value-sm ", ("text-" + _co.iconClass), ""); _ck(_v, 7, 0, currVal_5); var currVal_6 = _co.text; _ck(_v, 8, 0, currVal_6); var currVal_7 = _co.title; _ck(_v, 10, 0, currVal_7); }); }
export function View_DashSquareComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dash-square", [], null, null, null, View_DashSquareComponent_0, RenderType_DashSquareComponent)), i1.ɵdid(1, 114688, null, 0, i4.DashSquareComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashSquareComponentNgFactory = i1.ɵccf("app-dash-square", i4.DashSquareComponent, View_DashSquareComponent_Host_0, { title: "title", text: "text", link: "link", linkLabel: "linkLabel", number: "number", iconClass: "iconClass", icon: "icon" }, {}, []);
export { DashSquareComponentNgFactory as DashSquareComponentNgFactory };
