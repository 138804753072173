var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var SeguretatUsuarisFilter = /** @class */ (function (_super) {
    __extends(SeguretatUsuarisFilter, _super);
    function SeguretatUsuarisFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.codiUser = null;
        _this.nomUsuari = '';
        _this.descripcio = '';
        _this.labels['codiUser'] = _this.i18n('Codi usuari');
        _this.labels['nomUsuari'] = _this.i18n('Nom usuari');
        _this.labels['descripcio'] = _this.i18n('Descripció');
        return _this;
    }
    return SeguretatUsuarisFilter;
}(FilterData));
export { SeguretatUsuarisFilter };
