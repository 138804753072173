import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { TiquetsFilter } from '../models/tiquets-filter';


const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class TiquetsFilterService extends CustomFilterService {
  public createFilterParams(filter: TiquetsFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    let fechaTicketInicio = '';
    let fechaTicketFin = '';
    let fechaBancoInicio = '';
    let fechaBancoFin = ''; 

    if (filter.fechaTicketInicio !== null) {
      fechaTicketInicio = this.formatDateToDecimal(this.stringToDate(filter.fechaTicketInicio));
    }
    if (filter.fechaTicketFin !== null) {
      fechaTicketFin = this.formatDateToDecimal(this.stringToDate(filter.fechaTicketFin), false);
    }
    if (filter.fechaBancoInicio !== null) {
      fechaBancoInicio = this.formatDateToDecimal(this.stringToDate(filter.fechaBancoInicio));
    }
    if (filter.fechaBancoFin !== null) {
      fechaBancoFin = this.formatDateToDecimal(this.stringToDate(filter.fechaBancoFin), false);
    }

    let httpParams =  new HttpParams()
      .append('id', filter.id ? filter.id.toString() : '')
      .append('matricula', filter.matricula)
      .append('email', filter.email)
      .append('zonaSecureId', filter.zonaSecureId)
      .append('proveedorExterno', filter.proveidors !== undefined && filter.proveidors !== null ? filter.proveidors.map(s => s).toString() : '')
      .append('start', start.toString())
      .append('size', pageSize.toString())
      .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
      .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    httpParams = httpParams
                      .append('fechaTicketInicio', fechaTicketInicio)
                      .append('fechaTicketFin', fechaTicketFin)
                      .append('fechaBancoInicio', fechaBancoInicio)
                      .append('fechaBancoFin', fechaBancoFin);

    return httpParams;
  }

  getBooleanValue(value: boolean) {
    if (value === null) {
      return '';
    } else {
      return value.toString();
    }
  }

  isDate (value) {
    return value instanceof Date;
  }

  stringToDate (value) {
    if (!this.isDate (value)) {
      value = new Date(value);
    }
    return value;
  }

  formatDateToDecimal(date: Date, isFechaIni: boolean = true): string {
    let month: string;
    let day: string;
    let hhmmss: string;

    month = (date.getMonth() + 1).toString();
    if (month.length === 1) {
      month = '0' + month;
    }

    day = date.getDate().toString();
    if (day.length === 1) {
      day = '0' + day;
    }

    if (isFechaIni) {
      hhmmss = '000000';
    } else {
      hhmmss = '235959';

      // vvarela: De moment treiem el filtre per tallar els tiquets als d'ara (aixó es va fer per reserves)
      // const hoy = new Date();
      // hoy.setHours(0, 0, 0, 0);
      // if (date < hoy) {
      //   hhmmss = '235959';
      // } else {
      //   const fechaActual = new Date();
      //   let hours = fechaActual.getHours().toString();
      //   let minutes = fechaActual.getMinutes().toString();
      //   let seconds = fechaActual.getSeconds().toString();
      //   if (hours.length === 1) {
      //     hours = '0' + hours;
      //   }
      //   if (minutes.length === 1) {
      //     minutes = '0' + minutes;
      //   }
      //   if (seconds.length === 1) {
      //     seconds = '0' + seconds;
      //   }
      //   hhmmss = hours + minutes + seconds;
      // }
    }

    return date.getFullYear().toString()
            + month
            + day
            + hhmmss;
  }

}
