import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-c60-fecha-cobro',
  templateUrl: './c60-fecha-cobro.component.html',
  styleUrls: ['./c60-fecha-cobro.component.scss']
})
export class C60FechaCobroComponent implements OnInit {
  fecha: Date;
  options: number[] = [1, 2];
  optionSelected: number;
  constructor(
    public thisDialogRef: MatDialogRef<C60FechaCobroComponent>,
  ) { }

  ngOnInit() {
  }
  save() {
    if (this.optionSelected === 1) {
      this.thisDialogRef.close(null);
    } else {
    this.thisDialogRef.close(this.fecha);
    }
  }
  cancel() {
    this.thisDialogRef.close(false);
  }
}
