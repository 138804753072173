import { MotiusDesestimarService } from './../../services/motius-desestimar.service';
import { MotiusDesestimar } from './../../models/motius-desestimar.model';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';

@Component({
  selector: 'app-motius-desestimar-editor',
  templateUrl: './motius-desestimar-editor.component.html',
  styleUrls: ['./motius-desestimar-editor.component.scss']
})
export class MotiusDesestimarEditorComponent implements OnInit {
  secure_id: string;
  motiuDesestimar: MotiusDesestimar;
  dialogData: ComponentDialog;
  closeButtonText: string;

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private motiusDesestimarService: MotiusDesestimarService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<MotiusDesestimarEditorComponent>,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.motiuDesestimar = Builder.newMotiuDesestimar();
      this.dialogData = data;
      this.secure_id = this.dialogData.id;
      if (this.dialogData.isReadOnly) {
        this.closeButtonText = this.i18n('Tancar');
      } else {
        this.closeButtonText = this.i18n('Llista');
      }
   }

  ngOnInit() {
    if (this.secure_id !== null) {
      this.motiusDesestimarService.getMotiusDesestimar(this.secure_id).subscribe(data => {
        this.motiuDesestimar = data;
      });
    }
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {

      this.motiusDesestimarService.setMotiusDesestimar(this.motiuDesestimar).subscribe(result => {
        this.toastr.info(this.i18n('Motiu guardat correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar el motiu?'))
    .then((confirmed) => {
        if (confirmed) {
          this.motiusDesestimarService.removeMotiusDesestimar(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Motiu esborrat correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

}
