import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';


export class CarrersFilter extends FilterData {

  id: number;
  sigles: string;
  nomCarrer: string;
  idMunicipi: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.id = null;
    this.sigles = '';
    this.nomCarrer = '';
    this.idMunicipi = '';

    this.labels['id'] = this.i18n('Id');
    this.labels['sigles'] = this.i18n('Descripció');
    this.labels['nomCarrer'] = this.i18n('NomCarrer');
    this.labels['idMunicipi'] = this.i18n('Municipi');
  }

}
