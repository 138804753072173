var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
var defaultSortParam = 'element';
var defaultSortDirection = 'asc';
var NotificacionsFilterService = /** @class */ (function (_super) {
    __extends(NotificacionsFilterService, _super);
    function NotificacionsFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NotificacionsFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('element', (filter.element ? filter.element.toString() : ''))
            .append('tipus', filter.tipus ? filter.tipus : '')
            .append('notificacio', filter.notificacio ? filter.notificacio.toString() : '')
            .append('enviament', filter.enviament ? filter.enviament.toString() : '')
            .append('acceptacio', filter.acceptacio ? filter.acceptacio.toString() : '')
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return NotificacionsFilterService;
}(CustomFilterService));
export { NotificacionsFilterService };
