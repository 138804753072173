import { RespuestasService } from './../../../services/respuestas-service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { NotificacionsComponent } from 'app/sections/notificacions/components/notificacions.component';
import { RespostesComponent } from 'app/sections/notificacions/components/respostes/respostes.component';

@Component({
  selector: 'app-respuestas',
  templateUrl: './respuestas.component.html',
  styleUrls: ['./respuestas.component.scss']
})
export class RespuestasComponent implements OnInit, AfterViewInit {
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  nombreFichero: string='';
  private files: File[] = [];
  public ficheros: any;
  displayedColumns=['select','nombre'];
  constructor(public authService: AuthorizationService,
    private not: NotificationService,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private toastr: ToastrService,
    private respuestasService: RespuestasService,) { }

  ngOnInit() {
    this.cargarFicheros();
  }
  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Respuestas'));
    }
  }
  cargarFicheros(){
  this.respuestasService.getRespuestasPendientes().subscribe(x =>{
    this.ficheros=[];
    if(x !== undefined && x !== null && x.length>0 ){
        x.forEach(element => {
          this.ficheros.push({selected: false, fichero:element});
        });
    }
  });
}
canProces(): boolean{
  let found: boolean=false;
  let x=0;
   if(this.ficheros !== undefined && this.ficheros !== null){
     while(found === false && x<=this.ficheros.length-1){
       if(this.ficheros[x]!== undefined && this.ficheros[x]!== null 
         && this.ficheros[x].selected !== undefined && this.ficheros[x].selected !== null 
         && this.ficheros[x].selected){
           found = true;
         }
         x=x+1;
     }
   }
  return found;
}
processar(){
  if(this.canProces()){
    let ficheros ={'nombreFicheros':this.ficheros.filter(x=> x.selected=== true).map(y => y.fichero)};
    this.respuestasService.procesarFicheros(ficheros).subscribe(x =>{
      const ref = this.dialog.open(RespostesComponent, {
        width: '90%',
        height: '90%',
        data: {'noficiaciones': x}
  
      }).afterClosed().subscribe(report => {
          this.cargarFicheros();
      });
    });
  }
}
  // fileReady(files) {
  //   this.files = Array.from(files);
  // }
  // processar() {
  //   if (this.files === undefined || this.files === null) {
  //     this.toastr.warning(this.i18n('Cal seleccionar un fitxer per carregar!'));
  //   } else {
  //       this.sendFile(this.files[0]);
  //   }
  // }

  // sendFile(file) {
  //   if (file !== undefined) {
  //     const formData: FormData = new FormData();
  //     formData.append('file', file, file.name);
  //     this.respuestasService.sendFile(formData).subscribe(() => {
  //       this.toastr.info(this.i18n('Importación respuestas cargada correctamente'));
  //     });
  //   } 

  // }
  seleccionarFila(row){
    if(row.selected  === undefined || row.selected  === null){
        row.selected = true;
    }else{
      row.selected = !row.selected;
    }
  }
}
