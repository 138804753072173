import { ResponseDgtVehiculo } from './../../sections/utilitats/dgt/models/vehiculoDgt.model';
import { TextoInformativo } from './../../sections/aparcare/text-informatiu/model/texto-informativo';
import { TarifaGruaDto } from './../../sections/configuracio/grua/tarifas-grua/models/tarifaGruaDto.model';
import { SenalVertical } from './../../sections/configuracio/senales-verticales/models/senal-vertical';
import { MotiusDesestimar } from './../../sections/configuracio/motius-desestimar/models/motius-desestimar.model';
import { AlarmGroup } from 'path-shared/models/alarm-group';
import { TipusMatricula } from './../../sections/ciutat/tipus-matricules/models/tipus-matricula.model';
import { Agent } from 'app/sections/configuracio/agents/models/agent.model';
import { ComponentDialog } from './component-dialog.model';
import { Matricula } from 'app/sections/ciutat/matricules/models/matricula.model';
import { MatriculaLoader } from 'app/sections/ciutat/matricules/models/matricula-loader.model';
import { AparellDetail } from 'app/sections/configuracio/aparells/models/aparellDetail.model';
import { LegislacioDetail } from 'app/sections/configuracio/legislacions/Models/legislacioDetail.model';
import { Legislacio } from 'app/sections/configuracio/legislacions/Models/legislacio.model';
import { MotiusAnulacio } from 'app/sections/configuracio/motius-anulacio/models/motius-anulacio.model';
import { Usuari } from 'app/sections/usuaris/models/usuari.model';
import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { FilterData } from './filter-data';
import { DownloadExcelDialogData } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog-data.model';
import { Siglas } from 'app/sections/configuracio/siglas/models/siglas.model';
import { TipusVehicles } from 'app/sections/configuracio/tipus-vehicles/models/tipus-vehicles.model';
import { Colors } from 'app/sections/configuracio/colors/models/color.model';
import { CarrerDetail } from 'app/sections/configuracio/carrers/models/carrerDetail.model';
import { MarquesVehicles } from 'app/sections/configuracio/marques-vehicles/models/marques-vehicles.model';
import { InfraccioDetail } from 'app/sections/configuracio/Infraccions/models/infraccioDetail.model';
import { Paisos } from 'app/sections/configuracio/paisos/models/paisos.model';
import { ZonesModel } from 'app/sections/ciutat/zones/models/zones.model';
import { ɵNOT_FOUND_CHECK_ONLY_ELEMENT_INJECTOR } from '@angular/core';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { AvisosConf } from 'app/sections/configuracio/avisos/models/avisos.model';
import { Persona } from 'app/sections/ciutat/persones/models/persona.model';
import { Festiu } from 'app/sections/tiquets/festius/models/festiu.model';
import { TipusTiquets } from 'app/sections/tiquets/tipus-tiquets/models/tipus-tiquets.model';
import { VehicleResumen } from 'app/sections/ciutat/vehicles/models/vehicleDto';
import { SeguretatGrups } from 'app/sections/seguretat/seguretat-grups/models/seguretat-grups.model';
import { SeguretatUsuaris } from 'app/sections/seguretat/seguretat-usuaris/models/seguretat-usuaris.model';
import { SeguretatPantallaUsuario } from 'app/sections/seguretat/seguretat-grups/models/seguretat-pantalla-usuario.model';
import { SeguretatFormsUsuari } from 'app/sections/seguretat/seguretat-usuaris/models/seguretat-form-usuario.model';
import { Recaudacio } from 'app/sections/zona-blava/recaudacio/models/recaudacio.model';
import { DenunciaDetailModel } from 'app/sections/denuncies/models/denuncia-detail.model';
import { MotiusDestimacioGrua } from 'app/sections/grua/Models/motius-desestimacio.model';
import { GruaTecnics } from 'app/sections/configuracio/grua/grua-tecnics/models/grua-tecnics.model';
import { MotiusEntradaGrua } from 'app/sections/grua/Models/motius-entrada.model';
import { AliesGrua } from 'app/sections/grua/Models/alies-grua.model';
import { Tarifa } from 'app/sections/tiquets/tarifes/models/tarifa.model';
import { TarifaTimeFrame } from 'app/sections/tiquets/tarifes/models/tarifaTimeFrame.model';
import { VehicleDipositDto } from 'app/sections/grua/Models/diposit-vehicle.model';
import { PropostaGruaDto } from 'app/sections/grua/Models/proposta-grua.model';
import { BancCarreguesC60 } from 'app/sections/bancs/carregues-c60/models/banc-carregues-c60.model';
import { DenunciaCobrarModel } from 'app/sections/denuncies/models/denuncia-cobrar.model';
import { CargaProcesarDto } from 'app/sections/bancs/carregues-c60/models/cargaProcesarDto';
import { Turno } from 'app/sections/configuracio/grua/turnos/models/turnos-model';
import { RegistroTurno } from 'app/sections/configuracio/grua/turnos/models/registro-turno';
import { TipoSenalVertical } from 'app/sections/configuracio/tipos-senales-verticales/models/tipo-senal-vertical';
import { TextoAviso } from 'app/sections/aparcare/text-avis/model/texto-aviso';
import { ResponseDgtPersona } from 'app/sections/utilitats/dgt/models/personaDgt.model';
import { BancIncidenciesRequestDto } from 'app/sections/bancs/incidencies/models/banc-inicidencies-requestDto';
import { MotiuNoNotificacio } from 'app/sections/configuracio/motius-notificacio/models/motius-notificacio.model';
import { TipusEnviament } from 'app/sections/tramitar/tipus-enviaments/models/tipus-enviament.model';
import { Resposta } from 'app/sections/tramitar/respostes/models/resposta.model';
import { ObservacioPda } from 'app/sections/configuracio/observacions-pda/models/observacions-pda.model';
import { AddMatricula } from 'app/sections/ciutat/matricules/models/addMatricula.model';
import { Ajuda } from 'app/sections/ajuda/models/ajuda.model';
import { DenunciaExpedienteAcuse } from 'app/sections/denuncies/models/denuncia-expediente-acuse';
import { CFG_TEXTOS_PREDEFINIDOS } from 'path-models/CFG_TEXTOS_PREDEFINIDOS';
import { DenunciaExpedienteExterno } from 'app/sections/denuncies/models/denuncia-expediente-externo';
import { ZbRuta } from 'app/sections/configuracio/agents/pages/gestionar/zb-rutas/models/zb-ruta';
import { TemporadaDto } from 'app/sections/zona-blava/estacionament/temporada/models/temporadaDto';
import { EscalaOcupacion } from 'app/sections/zona-blava/estacionament/escala-ocupacio/model/escala-ocupacion';
import { Maquina } from 'app/sections/zona-blava/maquinas/models/maquina.model';
import { MaquinaTramo } from 'app/sections/zona-blava/maquinas/models/maquinaTramo.model';
import { TramoTipo } from 'app/sections/zona-blava/estacionament/tramos/tramos-tipos/models/tramo-tipo.model';
import { PositonCoord } from 'app/sections/zona-blava/estacionament/tramos/tramos/models/position';
import { Tramo } from 'app/sections/zona-blava/estacionament/tramos/tramos/models/tramo.model';
import { TramoQr } from 'app/sections/zona-blava/estacionament/tramos/tramos/models/tramoQr.model';

export class Builder {
  public static newComponentDialog(isDialog = false, isReadOnly = false, id = '', filter = null): ComponentDialog {
    return <ComponentDialog> {
      isDialog: isDialog,
      isReadOnly: isReadOnly,
      id: id,
      filter: filter,
      editable: true
    };
  }
  public static newBancIncidenciesRequestDto(): BancIncidenciesRequestDto {
    return <BancIncidenciesRequestDto> {
      desde: null,
      hasta: null,
      tramitados: true,
      noTramitados: false };
    }
  public static newTemporada() {
    return <TemporadaDto> {
      Som_id : null,
      descripcion : null,
      ANO_MES_DIA_INICIO : null,
      ANO_MES_DIA_FIN : null,
      ACTIVO: true,
    };
  }
public static newResponseDgtVehiculo(): ResponseDgtVehiculo {
  return <ResponseDgtVehiculo> {
    matricula: '',
    marca: '',
    modelo: '',
    domicilioVehiculo: '',
    cpVehiculo: '',
    municipioVehiculo: '',
    provinciaVehiculo: '',
    conductorNombre: '',
    conductorApellido1: '',
    conductorApellido2: '',
    conductorNif: '',
    fechaNacimientoConductor: null,
    personaFisica: null,
    empresaCif: '',
    empresaRazonSocial: '',
    domicilio: '',
    codigoPostal: '',
    municipio: '',
    provincia: '',
    seguroDescripcion: '',
    seguroFechaInicio: null,
    seguroFechaFin: null,
    seguroEnVigor: null,
    fechaCaducidadItv: null,
    fechaAnterirorItv: null,
    fechaItv: null,
    resultadoItv: '',
    indicadores: []
  };
}
public static newResponseDgtPersona(): ResponseDgtPersona {
  return <ResponseDgtPersona> {
    nombre: '',
    apellido1: '',
    apellido2: '',
    nif: '',
    sexo: '',
    fechaNacimiento: null,
    calle: '',
    codPostal: '',
    municipio: '',
    provincia: '',
    listaPermisosVigentes: []
  };
}
  public static newAvis(): AvisosConf {
    return <AvisosConf> {
      id : null,
      nom : null,
      mail : null,
      pareId : null,
      alarmas : {'zb': true, 'pol': true, 'gr': true}
    };
  }

  public static newTramoTipo(): TramoTipo {
    return <TramoTipo> {
      descripcion: null,
      Som_id: -1,
      color: null,
      id_logica: null
    };
  }
  public static newTramo(): Tramo {
    return <Tramo> {
      descripcion: null,
      Som_id: -1,
      numPlazas: null,
      poligon: [],
    };
  }
  public static newTramoQr(): TramoQr {
    return <TramoQr> {
      ZonaSomId: '',
      PoligonoSomId: ''
    };
  }
  public static newMaquina(): Maquina {
    return <Maquina> {
      codiMaquina: null,
      codi: -1,
      maquinaTramos: [],
    };
  }
  public static newMaquinaTramo(maquina: Maquina, tramo: Tramo, somIdTemporada: number): MaquinaTramo {
    return <MaquinaTramo> {
      SomId: -1,
      SomIdMaquina: maquina.codi,
      SomIdTramo: tramo.Som_id,
      SomIdTemporada: somIdTemporada,
      tramo: tramo,
      afectacionManual: 100,
      afectacionCalculada: 100
    };
  }

  public static newPosition(lat, lng): PositonCoord {
    return <PositonCoord> {
      lat: lat,
      lng: lng,
    };
  }

  public static newObservacioPda(): ObservacioPda {
    return <ObservacioPda> {
      codi: -1,
      descripcio: '',
      tipus: ''
    };
  }

  public static newAliesGrua(): AliesGrua {
    return <AliesGrua> {
      descripcio: null,
      codi: -1,
    };
  }

  public static newTurno(): Turno {
    return <Turno>{
      descripcion: null,
      hora_inicio: null,
      hora_fin: null,
      Som_id: -1,
    };
  }

  public static newTarifaGrua(): TarifaGruaDto {
    return <TarifaGruaDto>{
      SomId: -1,
      tipoTarifa: null,
      tipoVehiculo: null,
      tipoVehiculoModel: null,
      importeTarifa: null,
      tipoEstancia: null,
      descripcion: null,
      fechaDesde: null,
      fechaHasta: null,
      secureId: ''
    };
  }

  public static newRegistroTurno(): RegistroTurno {
    return <RegistroTurno>{
      ID_TURNO: null,
      ID_USUARIO_INICIO: null,
      TIPO_USUARIO: 1,
      ID_USUARIO_FIN: null,
      FECHA_INICIO: null,
      FECHA_FIN: null,
      Som_id: -1,
    };
  }

  public static newMotiusEntradaGrua(): MotiusEntradaGrua {
    return <MotiusEntradaGrua> {
      descripcio: null,
      avis: null
    };
  }

  public static newMotiusDesestimacioGrua(): MotiusDestimacioGrua {
    return <MotiusDestimacioGrua> {
      motiu: null,
      secureId: null
    };
  }

  public static newGruaTecnics(): GruaTecnics {
    return <GruaTecnics> {
      somId : -1,
      codi : null,
      nom : null,
      DNI : null,
    };
  }

  public static newDipositGrua(): VehicleDipositDto {
    let horaEnt = new Date().getHours().toString();
    if (horaEnt.length === 1) {
      horaEnt = '0' + horaEnt;
    }
    let minEnt = new Date().getMinutes().toString();
    if (minEnt.length === 1) {
      minEnt = '0' + minEnt;
    }
    return <VehicleDipositDto> {
      idDipositVehicle: null,
      idDipositFK: '',
      idVehicleFk: '',
      dataEntrada: new Date(),
      horaEntrada: horaEnt + ':' + minEnt,
      dataSortida: null,
      horaSortida: '',
      motiuEntrada: '',
      motiuSortida: '',
      secureIdMotiuSortida: '',
      importCobrat: null,
      formapagament: '',
      observacions: '',
      dataCalcul: null,
      horaCalcul: '',
      llocRetirada: '',
      idInstit: '',
      idtipusvehicleFK: '',
      idVehiclesFK: '',
      tipusCalcul: '',
      titularnom: '',
      titularnif: '',
      titulardireccio: '',
      titularCP: '',
      titularpoblacio: '',
      titularTelefon: '',
      importenganche: null,
      importcustodia: null,
      importMovilizaciones: null,
      importOtros: null,
      importcalculat: null,
      datacobrament: null,
      horaCobrament: '',
      iddenuncia: '',
      txtCustodia: '',
      tecnicGrua: '',
      sinCargo: false,
      idMotiuEntradaFK: null,
      secureId: '',
      IMATGES: null
    };
  }

  public static newPropostaGrua(): PropostaGruaDto {
    return <PropostaGruaDto> {
      codi: '',
    dataPropostaPda: null,
    data:  null,
    hora: '',
    dataFinalitzacio:  null,
    idVehiclesFK: '',
    vehicle: null,
    idAgentFk: '',
    agent: null,
    idCalleFK: '',
    calle:  null,
    numCarrer: '',
    nombreCalle: '',
    valida:  null,
    observacions: '',
    idInfraccioFK: '',
    infraccion:  null,
    latitud: null,
    longitud: null,
    finalitzada:  null,
    numPDA: null,
    origen: '',
    revisada:  null,
    implicaDenuncia: null,
    idTecnicFK: '',
    tecnicGrua:  null,
    idMotiuDesestimada:  null,
    fechaAcepDeneg:  null,
    numFotos: null,
    solicitud_lastUpdate: null,
    propuesta_lastUpdate: null,
    sinCargo:  null,
    IMATGES:  null,
    secureId: '',
    secureIdCarrer: '',
    };
  }
  public static newSeguretatPantallaUsuario(): SeguretatPantallaUsuario {
    return <SeguretatPantallaUsuario> {
      secureId: null,
      som_id: null,
      idSegPantallas: null,
      idSegGrupos: null,
      pantalla: null,
      lectura: null,
      alta: null,
      modif: null,
      borrar: null,
    };
  }

  public static newGrup(): SeguretatGrups {
    return <SeguretatGrups> {
      secureId: null,
      codiGrup: null,
      descripcio: null,
      inici: null,
      fi: null,
      SOM_ID: null

    };
  }

  public static newFestiu(): Festiu {
    return <Festiu> {
      id: null,
      dia: null,
      descripcio: null,
      tipus: null
    };
  }

  public static newResposta(): Resposta {
    return <Resposta> {
      somId: null,
      codi: null,
      acceptacio: false,
      descripcio: null,
      secureId: null
    };
  }

  public static newTarifa(): Tarifa {
    return <Tarifa> {
      id: null,
      codi: null,
      descripcio: null,
      dataInici: null,
      dataFi: null,
      tarifaTimeFrames: [],
      desde1: '00:00',
      desde2: '00:00',
      desde3: '00:00',
      desde4: '00:00',
      desde5: '00:00',
      desde6: '00:00',
      desde7: '00:00',
      iniciPausa1: '00:00',
      iniciPausa2: '00:00',
      iniciPausa3: '00:00',
      iniciPausa4: '00:00',
      iniciPausa5: '00:00',
      iniciPausa6: '00:00',
      iniciPausa7: '00:00',
      fiPausa1: '00:00',
      fiPausa2: '00:00',
      fiPausa3: '00:00',
      fiPausa4: '00:00',
      fiPausa5: '00:00',
      fiPausa6: '00:00',
      fiPausa7: '00:00',
      hasta1: '00:00',
      hasta2: '00:00',
      hasta3: '00:00',
      hasta4: '00:00',
      hasta5: '00:00',
      hasta6: '00:00',
      hasta7: '00:00',


    };
  }

  public static newTarifaTimeFrame(): TarifaTimeFrame {
    return <TarifaTimeFrame> {
      somId: null,
      tipus: null,
      minInici: 0,
      minFi: 0,
      preuMaxFixe: 0,
      incrementMin: 0,
      preuPerIncrement: 0,
    };
  }

  public static newTipusTiquets(): TipusTiquets {
    return <TipusTiquets> {
      codi: null,
      descripcio: null,
      dataInici: null,
      dataFi: null
    };
  }

  public static newAgent(): Agent {
    return <Agent> {
      Id: 0,
      codi: null,
      categoria: null,
      nom: null,
      cognom1: '',
      cognom2: null,
      dni: null,
      letraDni: null,
      obsolet: false
    };
  }

  public static newZbRuta():ZbRuta{
    return<ZbRuta>{
      SomId:0,
      Nombre:'',
      Descripcion:'',
      Puntos:[]
    };
  }

  public static newAparell(): AparellDetail {
    return <AparellDetail> {
      id: '',
      descripcion: '',
      fechaCaducidad: null,
      fechaRevision: null,
      marca: '',
      modelo: '',
      numeroSerie: '',
      numeroAntena: '',
      numeroBastidor: '',
      margenErrorNombre: null,
      margenErrorPorc: null,
      tipoRadar: '',
      codiCEM: '',
      idTipus: null
    };
  }

  public static newLegislacio(): LegislacioDetail {
    return <LegislacioDetail> {
      codi: '',
      descripcio: '',
      obsoleta: false,
      trafic: false,
      mesesSL: null,
      mesesSG: null,
      mesesSMG: null
    };
  }

  public static newMatricula(): Matricula {
    return <Matricula> {
      matricula: null,
      marca: '',
      model: '',
      observacions: '',
      hora: null
    };
  }
  public static newAddMatricula(): AddMatricula {
    return <AddMatricula> {
      matricula: null,
      marca: '',
      model: '',
      observacions: '',
      hora: null
    };
  }

  public static newZona(): ZonesModel {
    return <ZonesModel> {
      idZona: null,
      tipo_ticket: null,
      descripcio: '',
      fechaInici: new Date(),
      fechaFin: new Date(2100,1,1),
      id_tarifa: null,
    };
  }

  public static newTipusMatricula(): TipusMatricula {
    return <TipusMatricula> {
      descripcio: '',
      codi: null,
      denunciaPOL: false,
      denunciaZB: false,
      guardaLOG: false,
      reincidents: false,
      generarAlarma: false,
      alarmas: {'zb': true, 'pol': true, 'gr': true}
    };
  }
  static newMatriculaLoader(): MatriculaLoader {
    return <MatriculaLoader> {
      name: null,
      size: 0,
      content: null,
      delete: false,
      somintec: true,
      institution: '',
      type: null
    };
  }

  static newMotiuAnulacio(): MotiusAnulacio {
    return <MotiusAnulacio> {
      id: 0,
      descripcio: ''
    };
  }

  static newMotiuDesestimar(): MotiusDesestimar {
    return <MotiusDesestimar> {
      id: 0,
      descripcio: '',
      canviMatricula: false,
    };
  }

  static newUsuari(): Usuari {
    return <Usuari> {
      UserID: null,
      Name: '',
      UserName: ''
    };
  }

  static newTipusAlarma(): AlarmGroup {
    return <AlarmGroup> {
      count: 0,
      idTipusAlarma: null,
      tipusAlarma: '',
    };
  }

  static newDownloadExcelDialogData(generator: ExcelGenerator, filter: FilterData, fileName: string, sortDirection: string, sortField: string): DownloadExcelDialogData {
    return <DownloadExcelDialogData> {
      generator: generator,
      filter: filter,
      fileName: fileName,
      sortDirection: sortDirection,
      sortField: sortField
    };
  }

  static newDownloadExcelDialogDataGeneral(generator: ExcelGenerator, filter: any, fileName: string): DownloadExcelDialogData {
    return <DownloadExcelDialogData> {
      generator: generator,
      filter: filter,
      fileName: fileName
    };
  }

  static newSigla(): Siglas {
    return <Siglas> {
      id: '',
      descripcio: ''
    };
  }
  static cfgTexto():CFG_TEXTOS_PREDEFINIDOS{
    return <CFG_TEXTOS_PREDEFINIDOS> {
      SOM_ID: null,
      DESCRIPCION: '',
      ALIAS:'',
      CODI:''
    };
  }

  static newTipusVehicle(): TipusVehicles {
    return <TipusVehicles> {
      id: null,
      descripcio: '',
      matriculaObligatoria: false
    };
  }

  static newTipoSenalVertical(): TipoSenalVertical {
    return <TipoSenalVertical> {
      id: null,
      descripcion: '',
      secureId: ''
    };
  }

  static newColor(): Colors {
    return <Colors> {
      id: null,
      descripcio: '',
      codiPropi: ''
    };
  }

  static newTipusEnviament(): TipusEnviament {
    return <TipusEnviament> {
      somId: null,
      tipoEnvio: '',
      descripcion: '',
      bop: false,
      producto: '',
      cliente: '',
      codigoAdmision: '',
      secureId: ''
    };
  }

  static newMotiuNoNotificacio(): MotiuNoNotificacio {
    return <MotiuNoNotificacio> {
      somId: null,
      descripcio: '',
    };
  }
  static newCarrerDetail(): CarrerDetail {
    return <CarrerDetail> {
      id: null,
      sigles: null,
      nomCarrer: null,
      referenciaIntegracio: null,
      zones: Array<FormMultiSelect>()
    };
  }

  static newMarcaVehicle(): MarquesVehicles {
    return <MarquesVehicles> {
      id: '',
      descripcio: '',
      referenciaIntegracion: ''
    };
  }

  static NewInfraccio(): InfraccioDetail {
    return <InfraccioDetail>{
      id: '0',
      legislacio: '',
      grua: '',
      article: '',
      apartat: '',
      opcio: '',
      gravetat: '',
      import: 0,
      fins: 0,
      descompte: 50,
      codiPropi: 0,
      conduc: '',
      numFotosPda: 0,
      tipus: '',
      punts: 0,
      tipusDenuncia: 0,
      zona: '',
      tempAnul: 0,
      preuAnul: 0,
      infracParkeon: 0,
      inici: new Date(),
      final: new Date('2100-12-31'),
      partidaCompt: '',
      aliasPda: '',
      fav: null,
      obligResol: null,
      retirada: null,
      noAplicarAbreviat: null,
      obsoleta: null,
      nomrativaDgt: '',
      articleDgt: '',
      apartatDgt: '',
      opicoDgt: '',
      articleLSVDgt: '',
      apartatLSVDgt: '',
      opcioLSVDgt: '',
      descCurta: '',
      secureId: null,
      datavehreq: true,
      zones: Array<FormMultiSelect>()
    };
  }

  static newPais(): Paisos {
    return <Paisos> {
      id: null,
      codi: null,
      descripcio: '',
      provCodificada: false,
      paisActiu: false,
      sigles: ''
    };
  }

  static newSeguretatUsuari(): SeguretatUsuaris {
    return <SeguretatUsuaris> {
      secureId: null,
      codiUser: null,
      nomUsuari: '',
      descUser: '',
      email: '',
      tipus: null,
      actiu: false,
      dataInici: null,
      dataFi: null,
      ultimaConexio: null,
      zonaBlava: false,
      policia: false,
      convivencia: false,
      institucionId: null,
      rangDatesDate: new Array<Date>()

    };
  }

  public static newSeguretatFormsUsuari(): SeguretatFormsUsuari {
    return <SeguretatFormsUsuari> {
      secureId: null,
      som_id: null,
      idSegPantallas: null,
      idSegUsuarios: null,
      pantalla: null,
      lectura: null,
      alta: null,
      modif: null,
      borrar: null,
    };
  }

  public static newPersona(): Persona {
    return <Persona> {
      id: -1,
      nom: '',
      cog1: '',
      cog2: '',
      nomComplet: '',
      telefon1: '',
      telefon2: '',
      dni_pasp: '',
      juridica: null,
      id_extern: '',
      data_naixament: null,
      lloc_naixament: '',
      provincia: '',
      nom_pare: '',
      nom_mare: '',
      buscat: null,
      antecedents: null,
      observacions: '',
      secure_id: ''
    };
  }
  static newVehicle(): VehicleResumen {
    return <VehicleResumen>{
      id_vehicles: -1,
      matricula: '',
      marca: '',
      model: '',
      color: '',
      secure_id_persona: '',
      asseguranca: '',
      poliza: '',
      proposta: new Date(),
      requisitoria: null,
      observacions: '',
      origen: '',
      tipus_vehicle: '',
      SECURE_ID: ''
    };
  }
  static newRecaudacio(): Recaudacio {
    return <Recaudacio> {
      secure_id: '',
      id: null,
      fecha: null,
      zonaFk: null,
      maquinaParkeonFk: null,
      numColecta: null,
      numColectaJustificante: null,
      importeMonedaD: null,
      importeMonedaComercial: 0,
      importeMonedaLegal: 0,
      importeTarjetaPayone: null,
      importeAnulacionTarjetaPayOne: null,
      importeTarjetaPayonePlus: null,
      importeAnulacionTarjetaPayOnePlus: null,
      importeTarjetaTotal: 0,
      importeRecargaTarjetaPayone: null,
      importeRecargaTarjetaPayonePlus: null,
      importeRecargaTarjetaTotal: 0,
      anulacionMoneda: null,
      anulacionImporteTotal: 0,
      importeRealAparcamiento: 0,
      numeroTicketsMoneda: null,
      numeroTicketsTarjetaPayone: null,
      numeroTicketsTarjetaPayonePlus: null,
      numeroTicketsTotal: 0,
      numeroTicketsMonedaComercial: null,
      importeRecuento: null,
      diferenciaRecuentoTicket: 0,
      acumuladoMoneda: null,
      acumuladoMonedaJustificacion: null
    };
  }

  public static newDenunciaDetail(): DenunciaDetailModel {
    return <DenunciaDetailModel> {
      secure_id_denuncia: '',
      expedient: '',
      expedientExtern: '',
      numeroDenuncia: '',
      data: new Date(),
      hora: null,
      matricula: null,
      codiAnulacio: '',
      tipusDenuncia: '',
      traspasado: false,
      judicial: false,
      jpt: false,
      sct: false,
      tancat: false,
      secure_id_agent: '',
      codiAgent: null,
      secure_id_infraccio: '',
      article: '',
      llei: '',
      lleiDesc: null,
      import: null,
      descripcioInfraccio: '',
      alegacio: null,
      observacions: null,
      qualificacio: '',
      punts: null,
      dataDescompte: null,
      importDescompte: null,
      secure_id_carrer: '',
      carrer: null,
      numero: null,
      pais: '',
      descZona: '',
      secure_id_conductor: '',
      dni_conductor: '',
      nom_conductor: '',
      juridica_conductor: false,
      secure_id_domiciliConductor: '',
      domicili_conductor: '',
      secure_id_vehicle: '',
      modelo: '',
      marca: '',
      secure_id_propietari: '',
      dni_propietari: '',
      nom_propietari: '',
      juridica_propietari: false,
      secure_id_domiciliPropietari: '',
      domicili_propietari: '',
      idTipoDenunciante: null,
      codiAgent2: '',
      dni_denunciante: '',
      profesion_denunciante: '',
      calle_denunciante: '',
      poblacion_denunciante: '',
      codigoPostal_denunciante: '',
      provincia_denunciante: '',
      pais_denunciante: '',
      notificadoEnMano: null,
      fechaAnulacion: null,
      fechaCobro: null,
      fechaTraspaso: null,
      importeCobrado: null,
      importeRecargo: null,
      porcentajeRecargo: null,
      importeIntereses: null,
      fechaInicioIntereses: null,
      fechaFinIntereses: null,
      importeCostas: null,
      origenDenuncia: null,
      numRetirada: null,
      retiradaImporte: null,
      retiradaImporteDeposito: null,
      fechaRetirada: null,
      retiradaImporteCobrado: null,
      retiradaLiquidacion: null,
      Operaciones: [],
      SOM_C60: null,
      SOM_EXPREL: [],
      SOM_FICHEROS: null,
      numRecibo: '',
      dataPlec: null,
      dataPlecResolucio: null
    };
  }

  static bancCarreguesC60(): BancCarreguesC60 {
    return <BancCarreguesC60> {
      secureId: '',
      id: null,
      codiCarrega: '',
      descripcioCarrega: '',
      nomFitxer: '',
      directoriFitxer: '',
      situacio: '',
      situacioClass: '',
      dataComptabilitzacio: null
    };
  }
  static newDenunciaCobrar(): DenunciaCobrarModel {
    return <DenunciaCobrarModel> {
      expedient: '',
      fecha: null,
      formaPagoId: null,
      rangoFechaIntereses: [],
      importeBase: 0,
      importeAumentoPorcentaje: 0,
      importeAumento: 0,
      importeInteresesPorcentaje: 0,
      importeIntereses: 0,
      importeAnadido: 0,
      importeTotal: 0,
      parcialCobrarITancar: false,
      motiuCobrarITancar: '',
      fechaCobroByUser: null
    };
  }
  static newCargaProcesarDto(): CargaProcesarDto {
    return <CargaProcesarDto> {
      registro: null,
      cobro: null };
    }
  public static newSenalVertical(): SenalVertical {
    return <SenalVertical> {
      id: null,
      secureId: null,
      nombre: '',
      tipoFk: null,
      tipoDescripcion: '',
      observaciones: '',
      latitud: null,
      longitud: null
    };
  }

  public static newTextoInformativo(): TextoInformativo {
    return <TextoInformativo> {
      secureId: null,
      descripcion: '',
      titulo: '',
      fechaInicio: null,
      fechaFin: null,
      idioma: '',
      htmlValue: ''
    };
  }

  public static newTextoAviso(): TextoAviso {
    return <TextoAviso> {
      secureId: null,
      titulo: '',
      fechaInicio: null,
      fechaFin: null,
      idioma: '',
      htmlValue: '',
      permiteLogIn: true
    };
  }

  public static newEscalaOcupacion(): EscalaOcupacion {
    return <EscalaOcupacion> {
      secureId: null,
      id: null,
      color: '',
      rangoSuperior: null
    };
  }

  public static newAjuda(): Ajuda {
    return <Ajuda> {
      subject: null,
      body: null
    };
  }

  static newDenunciaExpedienteAcuse(): DenunciaExpedienteAcuse {
    return <DenunciaExpedienteAcuse> {
      name: null,
      size: 0,
      content: null
    };
  }

  static newDenunciaExpedienteExterno(): DenunciaExpedienteExterno {
    return <DenunciaExpedienteExterno> {
      name: null,
      value: null
    };
  }

}
