import {AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';

@Directive({
  selector: '[appMatDialogDraggable]'
})
export class DialogDraggableDirective implements AfterViewInit, OnDestroy {

  @Input() dragHandle: string;
  @Input() dragTarget: string;

  // Element to be dragged
  private target: HTMLElement;
  // Drag handle
  private handle: HTMLElement;
  private delta = {x: 0, y: 0};
  private offset = {x: 0, y: 0};

  private destroy$ = new Subject<void>();

  constructor(private elementRef: ElementRef, private zone: NgZone) {
  }

  public ngAfterViewInit(): void {
    this.handle = this.dragHandle ? document.querySelector(this.dragHandle) as HTMLElement :
                                    this.elementRef.nativeElement;
    this.target = this.handle.parentElement;
    while (this.target !== null && this.target.nodeName !== 'MAT-DIALOG-CONTAINER') {
      this.target = this.target.parentElement;
    }

    // this.target = document.querySelector(this.dragTarget) as HTMLElement;
    this.setupEvents();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  private setupEvents() {
    this.zone.runOutsideAngular(() => {
      const mousedown$ = Observable.fromEvent(this.handle, 'mousedown');
      const mousemove$ = Observable.fromEvent(document, 'mousemove');
      const mouseup$ = Observable.fromEvent(document, 'mouseup');

      const mousedrag$ = mousedown$.switchMap((event: MouseEvent) => {
      const startX = event.clientX;
      const startY = event.clientY;

        return mousemove$
          .map((evt: MouseEvent) => {
            evt.preventDefault();
            this.delta = {
              x: evt.clientX - startX,
              y: evt.clientY - startY
            };
          })
          .takeUntil(mouseup$);
      }).takeUntil(this.destroy$);

      mousedrag$.subscribe(() => {
        if (this.delta.x === 0 && this.delta.y === 0) {
          return;
        }

        this.translate();
      });

      mouseup$.takeUntil(this.destroy$).subscribe(() => {
        this.offset.x += this.delta.x;
        this.offset.y += this.delta.y;
        this.delta = {x: 0, y: 0};
      });
    });
  }

  private translate() {
    requestAnimationFrame(() => {
      this.target.style.transform = `
        translate(${this.offset.x + this.delta.x}px,
                  ${this.offset.y + this.delta.y}px)
      `;
    });
  }
}
