<app-dialog-header label="Tipo de envío" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Colors) || !this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Colors) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Colors) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="tipus-enviament-editor-tipoEnvio" class="col-sm-4" i18n-label label="Tipo envío" [(value)]="this.tipusEnviament.tipoEnvio" required ></app-form-text-edit>
      <app-form-text-edit id="tipus-enviament-editor-descripcion" class="col-sm-6" i18n-label label="Descripción" [(value)]="this.tipusEnviament.descripcion" ></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-check-edit id="tipus-enviament-editor-bop" class="col-sm-4" i18n-label label="BOP" [(value)]="this.tipusEnviament.bop" required></app-form-check-edit>
      <app-form-text-edit id="tipus-enviament-editor-producto" class="col-sm-6" i18n-label label="Producto" [(value)]="this.tipusEnviament.producto" ></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-text-edit id="tipus-enviament-editor-cliente" class="col-sm-4" i18n-label label="Cliente" [(value)]="this.tipusEnviament.cliente"></app-form-text-edit>
      <app-form-text-edit id="tipus-enviament-editor-codigoAdmision" class="col-sm-6" i18n-label label="Código admisión" [(value)]="this.tipusEnviament.codigoAdmision" ></app-form-text-edit>
    </div>
  </form>
</app-dialog-body>
