import { saveAs } from 'file-saver';
import { PuntosDgtFilter } from '../../models/puntos-dt-filter';
import { AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificacionsPuntsDGTFilterService } from '../../services/notificacions-punts-dgt-filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EnvioPuntosDGTService } from '../../services/envio-puntos-dgt.service';
import { GenericFile } from 'path-shared/components/generic-file-displayer/model/generic-file';
import { NOTIFICACION_PUNTOSDGT } from '../../models/notificacion_puntosdgt.model';

@Component({
  selector: 'app-envio-puntos-dgt-table',
  templateUrl: './envio-puntos-dgt-table.component.html',
  styleUrls: ['./envio-puntos-dgt-table.component.scss']
})
export class EnvioPuntosDgtTableComponent implements OnInit , AfterViewInit, OnChanges{

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: NOTIFICACION_PUNTOSDGT[];
  dataSource: CustomDataSource<NOTIFICACION_PUNTOSDGT, PuntosDgtFilter>;
  @Input() filter: PuntosDgtFilter;
  displayedItems = [];
  highlightedRows = [];

  displayedColumns = ['EXP', 'NUMDENUNCIA','DATA','IPRPUNTOS','IPRDESCRI'];
  constructor( 
    private dgtService: EnvioPuntosDGTService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n) 
    { }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<NOTIFICACION_PUNTOSDGT, PuntosDgtFilter>(this.paginator, new NotificacionsPuntsDGTFilterService(), 'urlGetNotificacionesPuntosDGT', 'urlGetNotificacionesPuntosDGTCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.EXP] = {
          EXP: item.EXP,
          NUMDENUNCIA: item.NUMDENUNCIA,
          DATA: item.DATA,
          IPRPUNTOS: item.IPRPUNTOS,
          IPRDESCRI: item.IPRDESCRI
        };
      });
    });

  this.filter.filterUpdated.subscribe(updated => {
    if (updated) {
      this.load();
      this.filterService.saveFilter('notificacionsPuntosDGT-filter', this.filter);
    }
  });

  this.filterService.loadFilter('notificacionsPuntosDGT-filter', this.filter);
  this.filter.paginator.init(this.sort, this.paginator);
  }
  
  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.filter != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  seleccionarFila(row){
    // if(row.selected  === undefined || row.selected  === null){
    //     row.selected = true;
    // }else{
    //   row.selected = !row.selected;
    // }
    // if(row.selected){
    //   this.dataSource.subject.subscribe((data) => {
    //     data.forEach(item => {
    //       if(item.EXP !== row.EXP && item.selected){
    //         item.selected = false;
    //       }
    //     });
    //   });
    // }
  }
  uploadFile(file){
    // this.getBase64(file).then((data: string) => {
    //   let fileToSend ={'fileString':data,'nomFitxer':file.name, 'cabecera':this.detail.cabecera};
    //   this.dgtService.sendResponseFileAndParse(fileToSend).subscribe(x =>{
    //     this.loadDetail(this.detail.cabecera.secureId);
    //   });
    // });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let formattedResult = reader.result.toString();
          const indexTo = formattedResult.indexOf('base64,');
          formattedResult = formattedResult.substr(indexTo + 'base64,'.length);
          resolve(formattedResult);
        };
        reader.onerror = error => reject(error);
    });
  }

  downloadFile(file: GenericFile): void {

    const bytearray: Uint8Array = this.getDisplayable(file.fichero);
    const blob = new Blob([bytearray]);
    saveAs(blob, file.nombre);
  
  }
  getDisplayable(dataURI: string): Uint8Array {
    const raw = window.atob(dataURI);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    let i = 0;
    for (i; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
}
