/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./permission-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./permission-status.component";
var styles_PermissionStatusComponent = [i0.styles];
var RenderType_PermissionStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PermissionStatusComponent, data: {} });
export { RenderType_PermissionStatusComponent as RenderType_PermissionStatusComponent };
export function View_PermissionStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-check"], ["style", "color: green"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-times"], ["style", "color: red"]], [[8, "hidden", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.status; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.status; _ck(_v, 1, 0, currVal_1); }); }
export function View_PermissionStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-permission-status", [], null, null, null, View_PermissionStatusComponent_0, RenderType_PermissionStatusComponent)), i1.ɵdid(1, 114688, null, 0, i2.PermissionStatusComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PermissionStatusComponentNgFactory = i1.ɵccf("app-permission-status", i2.PermissionStatusComponent, View_PermissionStatusComponent_Host_0, { status: "status" }, {}, []);
export { PermissionStatusComponentNgFactory as PermissionStatusComponentNgFactory };
