import { OnInit, EventEmitter } from '@angular/core';
import { PersonesService } from 'app/sections/ciutat/persones/services/persones.service';
var DipositEditorQuiRecullComponent = /** @class */ (function () {
    function DipositEditorQuiRecullComponent(personesService) {
        this.personesService = personesService;
        this.valueChange = new EventEmitter();
    }
    Object.defineProperty(DipositEditorQuiRecullComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            var _this = this;
            this.val = val;
            setTimeout(function () {
                _this.valueChange.emit(_this.val);
            }, 10);
        },
        enumerable: true,
        configurable: true
    });
    DipositEditorQuiRecullComponent.prototype.ngOnInit = function () {
    };
    DipositEditorQuiRecullComponent.prototype.consultarConductor = function () {
        var _this = this;
        if (this.value.titularnif !== undefined
            && this.value.titularnif !== null
            && this.value.titularnif !== '') {
            this.personesService.findPersonaByDniNif(this.value.titularnif).subscribe(function (x) {
                if (x !== undefined && x !== null && x.length > 0) {
                    if (x.length === 1) {
                        _this.value.titularnom = x[0].nomComplet;
                        _this.value.titularTelefon = x[0].telefon1;
                        // TODO: Domicili
                        _this.getDomicilis(x[0].secure_id, false, x[0].SECURE_ID_DOMICILI_ACTUAL);
                    }
                    //  else if (x.length  > 1 ) {
                    //   const filter: PersonaFilter = new PersonaFilter('id', 'asc');
                    //   filter.dni_pasp = this.denunciaDetail.dni_conductor;
                    //   // this.filterService.saveFilter('persona-filter', filter);
                    //   // Carraguem la taula filtrada per dni
                    //   const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, '', filter);
                    //   const ref = this.dialog.open(PersonesComponent, {
                    //     width: '80%',
                    //     height: '90%',
                    //     closeOnNavigation: false,
                    //     autoFocus: true,
                    //     data: data
                    //   });
                    //   ref.afterClosed().subscribe(async data => {
                    //     if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                    //       this.denunciaDetail.secure_id_conductor = data.codi;
                    //       this.denunciaDetail.secure_id_conductor = data.secure_id;
                    //       this.denunciaDetail.nom_conductor = data.nomComplet;
                    //       this.denunciaDetail.dni_conductor = data.dni_pasp;
                    //       this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
                    //       //: nomComplet, dni_pasp: dni_pasp
                    //       this.getDomicilis(this.denunciaDetail.secure_id_conductor, false, data.secureIdDom);
                    //         this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
                    //     }
                    //   });
                    // }
                }
                // else {
                //  // Obrim dialeg per crear persona
                //     this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
                //     .then((confirmed) => {
                //         if (confirmed) {
                //           const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, null);
                //           const ref = this.dialog.open(PersonesEditorComponent, {
                //             width: '80%',
                //             height: '90%',
                //             closeOnNavigation: false,
                //             autoFocus: true,
                //             data: data
                //           });
                //           ref.afterClosed().subscribe(async data => {
                //             if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                //               this.denunciaDetail.secure_id_conductor = data.codi;
                //               this.denunciaDetail.secure_id_conductor = data.secure_id;
                //               this.denunciaDetail.nom_conductor = data.nomComplet;
                //               this.denunciaDetail.dni_conductor = data.dni_pasp;
                //               this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
                //               //: nomComplet, dni_pasp: dni_pasp
                //               this.getDomicilis(this.denunciaDetail.secure_id_conductor, false, data.secureIdDom);
                //                 this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
                //             }
                //           });
                //         } else {
                //           this.denunciaDetail.nom_conductor = '';
                //           this.domicilisConductorDictionary =  new BehaviorSubject(new Array<Dictionary>());
                //         }
                //       });
                // }
            });
        }
        //  else {
        //   // Obrim dialeg per crear persona
        //   this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
        //   .then((confirmed) => {
        //       if (confirmed) {
        //         const data: ComponentDialog = Builder.newComponentDialog(true, this.isReadOnly, null);
        //         const ref = this.dialog.open(PersonesEditorComponent, {
        //           width: '80%',
        //           height: '90%',
        //           closeOnNavigation: false,
        //           autoFocus: true,
        //           data: data
        //         });
        //         ref.afterClosed().subscribe(async data => {
        //           if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
        //             this.denunciaDetail.secure_id_conductor = data.codi;
        //             this.denunciaDetail.secure_id_conductor = data.secure_id;
        //             this.denunciaDetail.nom_conductor = data.nomComplet;
        //             this.denunciaDetail.dni_conductor = data.dni_pasp;
        //             this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
        //             //: nomComplet, dni_pasp: dni_pasp
        //             this.getDomicilis(this.denunciaDetail.secure_id_conductor, false, data.secureIdDom);
        //               this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
        //           }
        //         });
        //       } else {
        //         this.denunciaDetail.nom_conductor = '';
        //         this.domicilisConductorDictionary =  new BehaviorSubject(new Array<Dictionary>());
        //       }
        //     });
        // }
    };
    DipositEditorQuiRecullComponent.prototype.getDomicilis = function (secureIdPersona, propietari, secureIdDom) {
        var _this = this;
        this.personesService.getDomicilis(secureIdPersona).subscribe(function (dom) {
            // if (propietari) {
            // this.comboDomPropietari.resetShowingOptions();
            if (dom === undefined || dom === null || dom.length > 0) {
                _this.value.titularCP = dom[0].codiPostal;
                _this.value.titularpoblacio = dom[0].localitat;
                _this.value.titulardireccio = dom[0].domicili;
            }
            // } else {
            //   this.comboDomConductor.resetShowingOptions();
            //   if (dom === undefined || dom === null || dom.length === 0) {
            //     this.domicilisConductorDictionary =  new BehaviorSubject(new Array<Dictionary>());
            //   } else {
            //   this.domicilisConductorDictionary =  new BehaviorSubject(new Array<Dictionary>());
            //   const dict = new Array<Dictionary>();
            //       dom.forEach(element => {
            //         dict.push(new Dictionary(element.secure_id, element.domicili));
            //       });
            //       this.domicilisConductorDictionary.next(dict);
            //       if (secureIdDom !== undefined) {
            //         this.comboDomConductor.markAsTouched();
            //         this.comboDomConductor.setSelect(secureIdDom);
            //       }
            //     }
            //   }
        });
    };
    return DipositEditorQuiRecullComponent;
}());
export { DipositEditorQuiRecullComponent };
