import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Dictionary } from 'path-shared/models/dictionary';


@Component({
  selector: 'app-combo-box-input',
  templateUrl: './combo-box-input.component.html',
  styleUrls: ['./combo-box-input.component.scss']
})
export class ComboBoxInputComponent implements OnInit {

  select: FormGroup;

  presetCurrentOption: any;
  currentoption: Dictionary;

  @Output() optionChanged: EventEmitter<string> = new EventEmitter<string>();

  @Input() required: boolean;
  @Input() label: string;
  @Input() get value() {
    return this.currentoption.key;
  } set value(val) {
    if (val !== undefined && val !==  null) {
     this.setSelect(val);
    }
  }
  _showingOptions: Array<Dictionary>;
  @Input() get showingOptions() {
    return this._showingOptions;
  } set showingOptions(val) {
    this._showingOptions = val;
    if (this._showingOptions !== undefined && this._showingOptions !== null && this.presetCurrentOption !== undefined) {
      this.setSelect(this.presetCurrentOption);
      this.presetCurrentOption = undefined;
    }
  }

  @Input() disabled: boolean;

  constructor(private formBuilder: FormBuilder) {
    this.required = false;
    this.select = this.formBuilder.group({
      privilegeSelect: ''
    });
   }

  ngOnInit() {
    if (this.isRequired()) {
      this.select = this.formBuilder.group({
        privilegeSelect: ['', Validators.required]
      });
    }

    if (this.showingOptions === null || this.showingOptions === undefined) {
      this.showingOptions = new Array<Dictionary>();
    }
    this.currentoption = new Dictionary('', '');
  }

  selectedOption(option: Dictionary) {
    this.currentoption = option;

    this.optionChanged.emit(option.key);
  }

  resetSelecteds() {
    this.select.get('privilegeSelect').patchValue([]);
    this.currentoption = null;
  }

  resetShowingOptions() {
    this.resetSelecteds();
    this.presetCurrentOption = undefined;
    this.showingOptions = undefined;
  }

  setSelect(value: any) {
    this.resetSelecteds();
    if (this.showingOptions !== undefined && this.showingOptions.length > 0) {
      const option = this.showingOptions.find(x => {
        return x.key === value;
      });
      this.currentoption = option;
      this.select.get('privilegeSelect').setValue(option);
    } else {
      this.presetCurrentOption = value;
    }
  }

  isRequired() {
    return this.required !== false;
  }

  markAsTouched() {
    this.select.get('privilegeSelect').markAsTouched();
  }

  resetValidation() {
    this.select.get('privilegeSelect').markAsPristine();
    this.select.get('privilegeSelect').markAsUntouched();
    this.select.get('privilegeSelect').updateValueAndValidity();
  }

}
