export class TiquetsLeyenda {
  numTiquets: string = '-';
  numDevolucions: string = '-';
  numAnulacions: string = '-';

  sumImportTiquets: number = 0;
  sumImportBonus: number = 0;
  sumImportMetalic: number = 0;
  sumImportDevolucions: number = 0;
  sumImportAnulacions: number = 0;
  recaptacio: number = 0;
}
