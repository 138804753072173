/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-tool-button-component.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./upload-tool-button-component.component";
var styles_UploadToolButtonComponentComponent = [i0.styles];
var RenderType_UploadToolButtonComponentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadToolButtonComponentComponent, data: {} });
export { RenderType_UploadToolButtonComponentComponent as RenderType_UploadToolButtonComponentComponent };
export function View_UploadToolButtonComponentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["class", "actions-bar__button"], ["onclick", "document.getElementById('fileInput').click();"], ["type", "button"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], [[8, "className", 0], [8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "actions-bar__button-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "input", [["id", "fileInput"], ["style", "display:none;"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.badgeClass + " ") + _co.badgeColor); var currVal_2 = !_co.badgeActive; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.badge; _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.colorClass; _ck(_v, 3, 0, currVal_4); var currVal_5 = _co.iconClass; _ck(_v, 4, 0, currVal_5); var currVal_6 = _co.text; _ck(_v, 6, 0, currVal_6); }); }
export function View_UploadToolButtonComponentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-tool-button-component", [], null, null, null, View_UploadToolButtonComponentComponent_0, RenderType_UploadToolButtonComponentComponent)), i1.ɵdid(1, 114688, null, 0, i2.UploadToolButtonComponentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadToolButtonComponentComponentNgFactory = i1.ɵccf("app-upload-tool-button-component", i2.UploadToolButtonComponentComponent, View_UploadToolButtonComponentComponent_Host_0, { disabled: "disabled", text: "text", color: "color", icon: "icon", badge: "badge", badgeActive: "badgeActive", badgeColor: "badgeColor", propagate: "propagate", isFile: "isFile" }, { uploadFile: "uploadFile" }, []);
export { UploadToolButtonComponentComponentNgFactory as UploadToolButtonComponentComponentNgFactory };
