var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'codi';
var defaultSortDirection = 'asc';
var ConfirmacionBoeFilterService = /** @class */ (function (_super) {
    __extends(ConfirmacionBoeFilterService, _super);
    function ConfirmacionBoeFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConfirmacionBoeFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        if (filter.fechaCreacionFinal !== undefined && filter.fechaCreacionFinal !== null) {
            filter.fechaCreacionFinal.setHours(23, 59, 59);
        }
        if (filter.fechaEdictoFinal !== undefined && filter.fechaEdictoFinal !== null) {
            filter.fechaEdictoFinal.setHours(23, 59, 59);
        }
        return new HttpParams()
            .append('lote', (filter.lote ? filter.lote.toString() : ''))
            .append('fechaCreacionInici', Reflection.empty(filter.fechaCreacionInici) ? null : typeof (filter.fechaCreacionInici) === 'string' ?
            filter.fechaCreacionInici : filter.fechaCreacionInici.toDateString())
            .append('fechaCreacionFinal', Reflection.empty(filter.fechaCreacionFinal) ? null : typeof (filter.fechaCreacionFinal) === 'string' ?
            filter.fechaCreacionFinal : filter.fechaCreacionFinal.toDateString())
            .append('fechaCreacionInici', Reflection.empty(filter.fechaCreacionInici) ? null : typeof (filter.fechaCreacionInici) === 'string' ?
            filter.fechaCreacionInici : filter.fechaCreacionInici.toDateString())
            .append('fechaEdictoFinal', Reflection.empty(filter.fechaEdictoFinal) ? null : typeof (filter.fechaEdictoFinal) === 'string' ?
            filter.fechaEdictoFinal : filter.fechaEdictoFinal.toDateString())
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return ConfirmacionBoeFilterService;
}(CustomFilterService));
export { ConfirmacionBoeFilterService };
