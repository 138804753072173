import { OnInit, AfterViewInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ElementRef } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { CobrosService } from './services/cobros.service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { CobrosFilter } from './models/cobros-filter';
import { CobrosFiltreComponent } from './components/cobros-filtre/cobros-filtre.component';
import { Builder } from 'path-shared/models/builder';
import { TipoCobroEnum } from './models/tipoCobroEnum.model';
var CobrosComponent = /** @class */ (function () {
    function CobrosComponent(cobrosService, i18n, dialog, auth, not) {
        this.cobrosService = cobrosService;
        this.i18n = i18n;
        this.dialog = dialog;
        this.auth = auth;
        this.not = not;
        this.dataSource = null;
        this.init = true;
        this.filter = new CobrosFilter('fechas', 'asc');
        this.displayedColumns = ['FechaCobro', 'ImporteCobro', 'Origen', 'EXP', 'Aparato', 'Màquina', 'TipoCobro'];
        this.keyCounts = [];
        this.dataDialog = Builder.newComponentDialog();
        var date = new Date();
        date.setDate(date.getDate() - 14);
        this.filter.fechas = [date, new Date()];
    }
    CobrosComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filter.filterUpdated.subscribe(function (updated) {
            if (updated) {
                if (!_this.dataDialog.isDialog) {
                    _this.buscar();
                }
            }
        });
    };
    CobrosComponent.prototype.ngAfterViewInit = function () {
        this.not.titleChange(this.i18n('Cobros'));
    };
    CobrosComponent.prototype.buscar = function () {
        var _this = this;
        this.cobrosService.getCobros(this.filter).subscribe(function (res) {
            _this.dataSource = new MatTableDataSource(res);
            _this.dataSource.sort = _this.sort;
            _this.init = false;
            _this.keyCountOrigen(res);
        });
    };
    CobrosComponent.prototype.onFilterClicked = function () {
        this.cobrosFilterDialogRef = this.dialog.open(CobrosFiltreComponent, {
            width: '90%',
            height: 'auto',
            closeOnNavigation: false,
            autoFocus: true,
            data: this.filter
        });
    };
    CobrosComponent.prototype.keyCountOrigen = function (res) {
        this.keyCounts = [];
        var map = new Map();
        var _loop_1 = function (item) {
            if (!map.has(item.Origen)) {
                map.set(item.Origen, true);
                var key = item.Origen;
                var elements = res.filter(function (x) { return x.Origen === item.Origen; });
                var suma_1 = 0;
                elements.forEach(function (element) {
                    suma_1 += element.ImporteCobro;
                });
                this_1.keyCounts.push({ 'key': key, 'suma': suma_1 });
            }
        };
        var this_1 = this;
        for (var _i = 0, res_1 = res; _i < res_1.length; _i++) {
            var item = res_1[_i];
            _loop_1(item);
        }
        this.keyCounts.sort(function (s) { return s.key; });
    };
    CobrosComponent.prototype.generateExcel = function () {
        this.cobrosService.generateExcel(this.filter).subscribe(function (result) {
            var fileName = 'Cobros';
            fileName += '.xlsx';
            FileDownloader.downLoadFile([result], 'application/vnd.ms-excel;charset=utf-8', fileName);
        });
    };
    CobrosComponent.prototype.getTipoCobroById = function (id) {
        return Object.keys(TipoCobroEnum)[Object.values(TipoCobroEnum).indexOf(id)];
    };
    return CobrosComponent;
}());
export { CobrosComponent };
