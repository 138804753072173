import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { timer } from 'rxjs';
import { ValidationService } from 'path-shared/services/validation/validation.service';

@Component({
  selector: 'app-form-text-edit',
  templateUrl: './form-text-edit.component.html',
  styleUrls: ['./form-text-edit.component.scss']
})
export class FormTextEditComponent implements OnInit, AfterViewInit {
  val: any;
  validClass = 'ng-valid';
  timeout: any;
  validVal = false;

  @Input() label: string;
  @Input() required: boolean;
  @Input() get value() {
    return this.val;
  } set value(val) {
    if (this.val !== val) {
      this.val = val;
      this.valueChange.emit(this.val);
      this.valid();
      // if (this.validVal) {
      //   // setTimeout(() => {
      //   // }, 10);
      // }
    }
  }

  @Output() valueChange = new EventEmitter();
  @Input() class: string;
  @Input() type: string;
  @Input() disabled: boolean;
  @Input() autofocus;
  @Input() name: string;
  @Input() number: boolean;
  @Input() maxLength: number;
  @Input() matricula: boolean;
  @Input() isReadOnly: boolean;
  @Input() tabindex: number;
  @Input() maxNumberValue: number;
  @Input() tooltip: string;
  @Input() id: string;
  @ViewChild('inputEdit') private elementRef: ElementRef;

  constructor(
    public validator: ValidationService
  ) {
    this.val = null;
    this.disabled = false;
    this.required = false;
    this.number = false;
    this.matricula = false;
    this.isReadOnly = false;
    this.tabindex = 0;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.autofocus !== undefined) {
      timer(500).subscribe(() => {
        this.elementRef.nativeElement.focus();
      });
    }
  }

  valid(): boolean {
    const ok =  !this.errorRequired() && !this.errorNumber() && !this.errorLength() && !this.errorMatricula() && !this.errorNumberMaxValue();
    this.validVal = ok;
    return ok;
  }

  isRequired() {
    return this.required !== false;
  }

  isRequiredNumber() {
    return this.number !== false;
  }

  isRequiredMatricula() {
    return this.matricula !== false;
  }

  hasMaxLength() {
    return this.validator.isNatural(this.maxLength.toString());
  }

  errorRequired() {
    return this.isRequired() && (this.value === null || this.value === '' || this.value === undefined);
  }

  errorNumber() {
    // if (this.value != null && this.value.toString().length > 0 ) {
    //   return true;
    // } else {
      return false;
    // }
  }

  errorNumberMaxValue() {
   if (this.maxNumberValue !== undefined && this.maxNumberValue !== null) {
      if ( this.maxNumberValue < this.value) {
        return true;
      } else {
        return false;
      }
   } else {
     return false;
    }
  }

  errorMatricula() {
    return this.isRequiredMatricula() && !this.validator.isMatricula(this.value);
  }

  errorLength() {
    if (this.maxLength === undefined) {
      return false;
    } else {
      return !this.validator.checkLength(this.value, this.maxLength);
    }
  }

}
