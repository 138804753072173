import { OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
var EnvioBoeTableComponent = /** @class */ (function () {
    function EnvioBoeTableComponent(i18n) {
        this.i18n = i18n;
        this.displayedColumns = ['expedient', 'tipusAnuncio', 'data', 'hora', 'article', 'llei', 'import', 'lloc', 'infraccio'];
    }
    Object.defineProperty(EnvioBoeTableComponent.prototype, "value", {
        get: function () {
            return this.dataSource;
        },
        set: function (val) {
            this.dataSource = val;
        },
        enumerable: true,
        configurable: true
    });
    EnvioBoeTableComponent.prototype.ngOnInit = function () {
    };
    EnvioBoeTableComponent.prototype.filtrar2 = function () {
        var a = '';
    };
    EnvioBoeTableComponent.prototype.getTipolabel = function (id) {
        switch (id) {
            case 1: {
                return this.i18n('Denuncia');
            }
            case 2: {
                return this.i18n('Alegación');
            }
            case 3: {
                return this.i18n('Sanción firme');
            }
            case 4: {
                return this.i18n('Recurso');
            }
            case 0: {
                return this.i18n('Ejecutiva');
            }
            default: {
                return '';
            }
        }
    };
    return EnvioBoeTableComponent;
}());
export { EnvioBoeTableComponent };
