import { TarifasGruaFilterService } from './tarifa-grua-filter-service';
import { TarifaGruaDto } from './../models/tarifaGruaDto.model';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { HttpParams } from '@angular/common/http';
import { TarifasGruaFilter } from '../models/tarifa-grua-filter';
import { TurnosFilterService } from '../../turnos/services/turnos-filter-service';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class TarifasGruaService {
  
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setTarifa(tarifa: TarifaGruaDto): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTarifaGrua'], tarifa);
    }
  }

  removeTarifa(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteTarifaGrua'].replace('{secure_id}', secure_id), null);
    }
  }

  getTarifa(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTarifaGrua'].replace('{secure_id}', secure_id), null);
    }
  }


  generateExcel(filter: TarifasGruaFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new TarifasGruaFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlTarifaGruaExcel'],  params );
    }
  }
}
