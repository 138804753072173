/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./predefinides-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i6 from "../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i7 from "../../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i8 from "../../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i9 from "../../components/pedefinides-table/pedefinides-table.component.ngfactory";
import * as i10 from "../../components/pedefinides-table/pedefinides-table.component";
import * as i11 from "../../services/tramitar-predefinidas.service";
import * as i12 from "../../../../../shared/services/filter/filter.service";
import * as i13 from "@angular/material/dialog";
import * as i14 from "@ngx-translate/i18n-polyfill";
import * as i15 from "./predefinides-page.component";
import * as i16 from "../../../../../shared/services/notification/notification.service";
var styles_PredefinidesPageComponent = [i0.styles];
var RenderType_PredefinidesPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PredefinidesPageComponent, data: {} });
export { RenderType_PredefinidesPageComponent as RenderType_PredefinidesPageComponent };
function View_PredefinidesPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["label", "Operacions Massives"]], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Operacions Massives"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PredefinidesPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { predefinidesTable: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PredefinidesPageComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "app-toolbar", [], null, null, null, i5.View_ToolbarComponent_0, i5.RenderType_ToolbarComponent)), i1.ɵdid(4, 114688, null, 0, i6.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "app-tool-button", [["color", "has-color-blue"], ["icon", "fa-sync"], ["text", "Actualitzar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(6, 114688, null, 0, i8.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "app-tool-button", [["color", "has-color-green"], ["icon", "fa-check-double"], ["text", "Executar operaci\u00F3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ejecutarOperacionMassivo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(8, 114688, null, 0, i8.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-pedefinides-table", [], null, [[null, "ejecutarOperacionEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ejecutarOperacionEvent" === en)) {
        var pd_0 = (_co.ejecutarOperacion($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_PedefinidesTableComponent_0, i9.RenderType_PedefinidesTableComponent)), i1.ɵdid(10, 4833280, [[1, 4], ["predefinidesTable", 4]], 0, i10.PedefinidesTableComponent, [i11.TramitarPredefinidasService, i12.FilterService, i13.MatDialog, i14.I18n], null, { ejecutarOperacionEvent: "ejecutarOperacionEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataDialog.isDialog; _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); var currVal_1 = "Actualitzar"; var currVal_2 = "has-color-blue"; var currVal_3 = "fa-sync"; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "Executar operaci\u00F3"; var currVal_5 = "has-color-green"; var currVal_6 = "fa-check-double"; _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6); _ck(_v, 10, 0); }, null); }
export function View_PredefinidesPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-predefinides-page", [], null, null, null, View_PredefinidesPageComponent_0, RenderType_PredefinidesPageComponent)), i1.ɵdid(1, 4308992, null, 0, i15.PredefinidesPageComponent, [i11.TramitarPredefinidasService, i13.MatDialogRef, i16.NotificationService, i14.I18n, i13.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PredefinidesPageComponentNgFactory = i1.ɵccf("app-predefinides-page", i15.PredefinidesPageComponent, View_PredefinidesPageComponent_Host_0, {}, {}, []);
export { PredefinidesPageComponentNgFactory as PredefinidesPageComponentNgFactory };
