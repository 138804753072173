import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { ConfirmacionBOEFilter } from '../models/confirmacion-boe-filter.model';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { ConfirmacionDetalleBOEFilter } from '../models/confirmacion-detalle-boe-filter.model';

const defaultSortParam = 'expediente';
const defaultSortDirection = 'asc';

@Injectable()
export class ConfirmacionDetalleBoeFilterService extends CustomFilterService {
  public createFilterParams(filter: ConfirmacionDetalleBOEFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('secureId', (filter.secureId ? filter.secureId.toString() : ''))
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
