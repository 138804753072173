import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var LoaderService = /** @class */ (function () {
    function LoaderService() {
        this.loaderSubject = new Subject();
        this.loaderState = this.loaderSubject.asObservable();
        this.contador = 0;
        this.background = false;
    }
    LoaderService.prototype.show = function (contador) {
        if (contador) {
            this.contador++;
        }
        if (!this.background) {
            this.loaderSubject.next({ show: true });
        }
    };
    LoaderService.prototype.hide = function (contador) {
        if (contador) {
            this.contador--;
        }
        if (this.contador === 0) {
            if (!this.background) {
                this.loaderSubject.next({ show: false });
            }
        }
    };
    // tslint:disable-next-line: member-ordering
    LoaderService.getInstance = function () {
        if (this.instancia === undefined || this.instancia === null) {
            this.instancia = new LoaderService();
        }
        return this.instancia;
    };
    LoaderService.prototype.setBackgroundMode = function (background) {
        var _this = this;
        this.background = background;
        if (background) {
            // nos cubrimos disgustos haciendo que al cabo de 5 segundos quite el estado de background siempre
            setTimeout(function () { return _this.background = false; }, 5000);
        }
    };
    LoaderService.ngInjectableDef = i0.defineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
    return LoaderService;
}());
export { LoaderService };
