import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpSecureClient } from '../../../shared/services/http/httpSecureClient';
import { AuthGuardService } from '../../../shared/services/authentication/auth-guard.service';

import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { ExpedientsModel } from 'app/sections/expedients/models/expedients.model';
import { DenunciaDetailModel } from '../models/denuncia-detail.model';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { WaitDialogComponent } from 'path-shared/components/dialogs/wait-dialog/wait-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { WaitDialogData } from 'path-shared/components/dialogs/wait-dialog/wait-dialog-data.model';
import { DenunciaExpedientModel } from '../models/denuncia-expedient.model';
import { DenunciaCobrarComponent } from '../components/denuncies-editor/components/denuncia-cobrar/denuncia-cobrar.component';
import { Subject } from 'rxjs';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { Instituciones } from 'path-shared/models/instituciones';
import { DenunciaCobrarByC60Component } from '../components/denuncies-editor/components/denuncia-cobrar-by-c60/denuncia-cobrar-by-c60.component';
import { DenunciaCobrarSmartPolComponent } from '../components/denuncies-editor/components/denuncia-cobrar-smart-pol/denuncia-cobrar-smart-pol.component';

@Injectable()
export class DenunciesCobrarService {

    denunciesCobrarRef: MatDialogRef<DenunciaCobrarComponent>;
    denunciesCobrarC60Ref: MatDialogRef<DenunciaCobrarByC60Component>;
    denunciesCobrarSmartPolRef: MatDialogRef<DenunciaCobrarSmartPolComponent>;
    denunciaDetail: DenunciaDetailModel;
    cerrarPagosParciales: boolean;

    constructor(
        private http: HttpSecureClient,
        private authGuard: AuthGuardService,
        private i18n: I18n,
        private dialog: MatDialog,
        private confirmationDialogService: ConfirmationDialogService,
        private parametersService: ParametersService
        ) {}

    getCerrarPagosParciales(): Observable<any> {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlGetCerrarPagosParciales'];
            return this.http.get(url, null);
        }
    }

    //#region Validacions a fer abans d'obrir el formulari de Cobrar

    cobrarDenuncia(detailsdata: DenunciaDetailModel, expedientsData: ExpedientsModel, denunciaExpedient: DenunciaExpedientModel): Observable<boolean> {
        const subject = new Subject<boolean>();
        this.denunciaDetail = detailsdata;
        const inst = sessionStorage.getItem('currentInst');
        if (inst !== '' && inst !== undefined && inst !== 'undefined') {
            const institution: Instituciones = JSON.parse(inst);
            if(institution.tipoCobro===0){ //seguim amb el tipus de cobrament normal
                if (this.denunciaDetail.numRecibo !== undefined && this.denunciaDetail.numRecibo !== null && this.denunciaDetail.numRecibo !== '') {
                    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('ATENCIÓ: Ja exsisteix un rebut a recaptació per aquesta denúncia. Està segur que vol continuar?'))
                    .then((confirmed) => {
                        if (confirmed) {
                            this.ValidacionDenunciaCobrar(expedientsData, denunciaExpedient).subscribe(result => {
                                subject.next(result);
                            });
                        }
                    });
                } else {
                    this.ValidacionDenunciaCobrar(expedientsData, denunciaExpedient).subscribe(result => {
                        subject.next(result);
                    });
                }
            }else if(institution.tipoCobro ===1){// via c60
                if (this.denunciaDetail.numRecibo !== undefined && this.denunciaDetail.numRecibo !== null && this.denunciaDetail.numRecibo !== '') {
                    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('ATENCIÓ: Ja exsisteix un rebut a recaptació per aquesta denúncia. Està segur que vol continuar?'))
                    .then((confirmed) => {
                        if (confirmed) {
                            this.ValidacionDenunciaCobrarPorC60(expedientsData, denunciaExpedient).subscribe(result => {
                                subject.next(result);
                            });
                        }
                    });
                } else {
                    this.ValidacionDenunciaCobrarPorC60(expedientsData, denunciaExpedient).subscribe(result => {
                        subject.next(result);
                    });
                }
            }
            else if(institution.tipoCobro ===2){//tipo smartpol, pueden hacer lo que quieran sin validaciones
                if (this.denunciaDetail.numRecibo !== undefined && this.denunciaDetail.numRecibo !== null && this.denunciaDetail.numRecibo !== '') {
                    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('ATENCIÓ: Ja exsisteix un rebut a recaptació per aquesta denúncia. Està segur que vol continuar?'))
                    .then((confirmed) => {
                        if (confirmed) {
                            this.ValidacionDenunciaCobrarSmartPol(expedientsData, denunciaExpedient).subscribe(result => {
                                subject.next(result);
                            });
                        }
                    });
                } else {
                    this.ValidacionDenunciaCobrarSmartPol(expedientsData, denunciaExpedient).subscribe(result => {
                        subject.next(result);
                    });
                }
            }
            
        }
        

        return subject.asObservable();
    }

    /*
    1.- Revisem si l'expedient (XPROCEDIMENT) té warnings a mostrar
    2.- Altres comprovacions
    3.- Si tot va bé, mostrem la finestra modal per Cobrar
    */
   ValidacionDenunciaCobrar(expedientsData: ExpedientsModel, denunciaExpedient: DenunciaExpedientModel): Observable<boolean> {
        const subject = new Subject<boolean>();

        /* ********** 1.- Revisem si l'expedient (XPROCEDIMENT) té warnings a mostrar ********** */
        const expedient = this.denunciaDetail.expedient;
        let puedeCobrarDenuncia = false;

        const warningText = this.WarningsDenunciaCobrar(expedientsData);

        /* ********* Si hi ha warnings els mostrem en un modal informatiu ********* */
        if (warningText !== '') {
            const waitDialog = this.OpenModalWarnings(warningText);

            waitDialog.afterClosed().subscribe(() => {
                /* ********** 2.- Altres comprovacions ********** */
                puedeCobrarDenuncia = this.AltresComprovacions(expedient, denunciaExpedient);

                /* ********** 3.- Si tot va bé, mostrem la finestra modal per Cobrar ********** */
                if (puedeCobrarDenuncia) {
                    this.OpenModalDenunciaCobrar().subscribe(result => {
                        subject.next(result);
                    });
                }
            });
        } else {
            /* ********** 2.- Altres comprovacions ********** */
            puedeCobrarDenuncia = this.AltresComprovacions(expedient, denunciaExpedient);

            /* ********** 3.- Si tot va bé, mostrem la finestra modal per Cobrar ********** */
            if (puedeCobrarDenuncia) {
                this.OpenModalDenunciaCobrar().subscribe(result => {
                    subject.next(result);
                });
            }
        }

        return subject.asObservable();
    }

    WarningsDenunciaCobrar(expedientsData: ExpedientsModel): string {
    let warningText = '';

    // RESULTAT
    if (expedientsData.alegacioEstimada !== null && expedientsData.alegacioEstimada === true) {
        warningText = warningText + this.i18n('<li>Una ale·legació acceptada</li>');
    }

    // RESULTATPLECPROPOSTA
    if (expedientsData.alegacionsEstimades !== null && expedientsData.alegacionsEstimades === true) {
        warningText = warningText + this.i18n('<li>Una proposta acceptada</li>');
    }

    // RESULTATPLECREPOSICIO
    if (expedientsData.recursEstimat !== null && expedientsData.recursEstimat === true) {
        warningText = warningText + this.i18n('<li>Un recurs acceptat</li>');
    }

    if (warningText !== '') {
        warningText = this.i18n('<span>L\'expedient {{expedient}} té aquests avisos:</span><span><ul>{{warningText}}</ul></span>', { expedient: this.denunciaDetail.expedient, warningText: warningText });
    }

    return warningText;
    }

    ValidacionDenunciaCobrarPorC60(expedientsData: ExpedientsModel, denunciaExpedient: DenunciaExpedientModel): Observable<boolean> {
        const subject = new Subject<boolean>();

        /* ********** 1.- Revisem si l'expedient (XPROCEDIMENT) té warnings a mostrar ********** */
        const expedient = this.denunciaDetail.expedient;
        let puedeCobrarDenuncia = false;

        const warningText = this.WarningsDenunciaCobrar(expedientsData);

        /* ********* Si hi ha warnings els mostrem en un modal informatiu ********* */
        if (warningText !== '') {
            const waitDialog = this.OpenModalWarnings(warningText);

            waitDialog.afterClosed().subscribe(() => {
                /* ********** 2.- Altres comprovacions ********** */
                puedeCobrarDenuncia = this.AltresComprovacions(expedient, denunciaExpedient);

                /* ********** 3.- Si tot va bé, mostrem la finestra modal per Cobrar ********** */
                if (puedeCobrarDenuncia) {
                    this.OpenModalDenunciaCobrarC60().subscribe(result => {
                        subject.next(result);
                    });
                }
            });
        } else {
            /* ********** 2.- Altres comprovacions ********** */
            puedeCobrarDenuncia = this.AltresComprovacions(expedient, denunciaExpedient);

            /* ********** 3.- Si tot va bé, mostrem la finestra modal per Cobrar ********** */
            if (puedeCobrarDenuncia) {
                this.OpenModalDenunciaCobrarC60().subscribe(result => {
                    subject.next(result);
                });
            }
        }

        return subject.asObservable();
    }
    ValidacionDenunciaCobrarSmartPol(expedientsData: ExpedientsModel, denunciaExpedient: DenunciaExpedientModel): Observable<boolean> {
        const subject = new Subject<boolean>();

        /* ********** 1.- Revisem si l'expedient (XPROCEDIMENT) té warnings a mostrar ********** */
        const expedient = this.denunciaDetail.expedient;
        let puedeCobrarDenuncia = false;

        const warningText = this.WarningsDenunciaCobrar(expedientsData);

        /* ********* Si hi ha warnings els mostrem en un modal informatiu ********* */
        if (warningText !== '') {
            const waitDialog = this.OpenModalWarnings(warningText);

            waitDialog.afterClosed().subscribe(() => {
                /* ********** 2.- Altres comprovacions ********** */
                puedeCobrarDenuncia = this.AltresComprovacions(expedient, denunciaExpedient);

                /* ********** 3.- Si tot va bé, mostrem la finestra modal per Cobrar ********** */
                if (puedeCobrarDenuncia) {
                    this.OpenModalDenunciaCobrarSmartPol().subscribe(result => {
                        subject.next(result);
                    });
                }
            });
        } else {
            /* ********** 2.- Altres comprovacions ********** */
            puedeCobrarDenuncia = this.AltresComprovacions(expedient, denunciaExpedient);

            /* ********** 3.- Si tot va bé, mostrem la finestra modal per Cobrar ********** */
            if (puedeCobrarDenuncia) {
                this.OpenModalDenunciaCobrarSmartPol().subscribe(result => {
                    subject.next(result);
                });
            }
        }

        return subject.asObservable();
    }


    OpenModalWarnings(warningText: string): MatDialogRef<WaitDialogComponent, any> {
    const waitDailogData = new WaitDialogData(this.i18n('Avisos Expedient'), warningText, this.i18n('Acceptar'), 'fa fa-info has-color-blue', false);
    const waitDialog: MatDialogRef<WaitDialogComponent> = this.dialog.open(WaitDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        width: '40%',
        data: waitDailogData
    });

    return waitDialog;
    }

    AltresComprovacions(expedient: string, denunciaExpedient: DenunciaExpedientModel): boolean {
    let isOpenModalDenunciaCobrar = false;

    if (this.denunciaDetail.secure_id_infraccio === null || this.denunciaDetail.secure_id_infraccio === ''
        || this.denunciaDetail.import === null
        || this.denunciaDetail.importDescompte === null) {
        // Sin Infracción
        this.OpenModalSinInfraccion(expedient);
    } else if (this.denunciaDetail.fechaCobro != null && this.denunciaDetail.importeCobrado != null
        && (this.denunciaDetail.importeCobrado >= +this.denunciaDetail.importDescompte && this.denunciaDetail.fechaCobro <= this.denunciaDetail.dataDescompte)
        || (this.denunciaDetail.importeCobrado >= +this.denunciaDetail.import && this.denunciaDetail.fechaCobro > this.denunciaDetail.dataDescompte)) {
        // Cobradas totalmente
        // Sergio 11/11/2021 miramos si se puede volver a cobrar por parámetro (pedido por jero)
        let cobrosExtra = this.parametersService.getParameter("COBROS_EXTRA");
        if (cobrosExtra !== 'Y') {
            this.OpenModalCobrada(expedient);
        } else {
            isOpenModalDenunciaCobrar = true;
        }   
    } else if (this.denunciaDetail.fechaAnulacion != null) {
        // Anuladas
        this.OpenModalAnulada(expedient);
    } else if (this.EnExecutiva(denunciaExpedient)) {
        // En Executiva
        this.OpenModalEnExecutiva(expedient);
    } else {
        isOpenModalDenunciaCobrar = true;
    }

    return isOpenModalDenunciaCobrar;
    }

    EnExecutiva(denunciaExpedient: DenunciaExpedientModel): boolean {
    if (denunciaExpedient !== undefined && denunciaExpedient !== null && denunciaExpedient.dataExecut !== null) {
        return true;
    } else {
        return false;
    }
    }

    OpenModalSinInfraccion(expedient: string): MatDialogRef<WaitDialogComponent, any> {
    const modalText = this.i18n('L\'expedient {{expedient}} no té ben identificada la infracció. Actualitza la infracció i torna a intentar el cobrament.', {expedient: expedient});
    const waitDailogData = new WaitDialogData(this.i18n('Cobrament'), modalText, this.i18n('Acceptar'), 'fa fa-info has-color-blue', false);
    const waitDialog: MatDialogRef<WaitDialogComponent> = this.dialog.open(WaitDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        width: '40%',
        data: waitDailogData
    });

    return waitDialog;
    }

    OpenModalCobrada(expedient: string): MatDialogRef<WaitDialogComponent, any> {
    const modalText = this.i18n('L\'expedient {{expedient}} ja es troba cobrat i no es pot tornar a cobrar.', {expedient: expedient});
    const waitDailogData = new WaitDialogData(this.i18n('Cobrament'), modalText, this.i18n('Acceptar'), 'fa fa-info has-color-blue', false);
    const waitDialog: MatDialogRef<WaitDialogComponent> = this.dialog.open(WaitDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        width: '40%',
        data: waitDailogData
    });

    return waitDialog;
    }

    OpenModalAnulada(expedient: string): MatDialogRef<WaitDialogComponent, any> {
    const modalText = this.i18n('L\'expedient {{expedient}} està anul·lat i no es pot cobrar.', {expedient: expedient});
    const waitDailogData = new WaitDialogData(this.i18n('Cobrament'), modalText, this.i18n('Acceptar'), 'fa fa-info has-color-blue', false);
    const waitDialog: MatDialogRef<WaitDialogComponent> = this.dialog.open(WaitDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        disableClose: true,
        width: '40%',
        data: waitDailogData
    });

    return waitDialog;
    }

    OpenModalEnExecutiva(expedient: string): MatDialogRef<WaitDialogComponent, any> {
    const modalText = this.i18n('L\'expedient {{expedient}} no es pot cobrar per trobar-se en fase d\'executiva.', {expedient: expedient});
    const waitDailogData = new WaitDialogData(this.i18n('Cobrament'), modalText, this.i18n('Acceptar'), 'fa fa-info has-color-blue', false);
    const waitDialog: MatDialogRef<WaitDialogComponent> = this.dialog.open(WaitDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        width: '40%',
        data: waitDailogData
    });

    return waitDialog;
    }

    //#endregion

    //#region Formulari de Cobrar

    OpenModalDenunciaCobrar(): Observable<boolean> {
        const subject = new Subject<boolean>();

        this.getCerrarPagosParciales().subscribe((cerrarPagosParciales: boolean) => {
            this.cerrarPagosParciales = cerrarPagosParciales;

            // Calcular els camps del formulari abans d'obrir el modal
            this.denunciesCobrarRef = this.dialog.open(DenunciaCobrarComponent,
            {
                height: '70%',
                width: '40%',
                closeOnNavigation: false,
                autoFocus: true,
                data: {
                    'data': this.denunciaDetail,
                    'cerrarPagosParciales': this.cerrarPagosParciales
                },
            });

            this.denunciesCobrarRef.afterClosed().subscribe(result => {
                subject.next(result);
            });
        });

        return subject.asObservable();
    }

    OpenModalDenunciaCobrarC60(): Observable<boolean> {
        const subject = new Subject<boolean>();

        this.getCerrarPagosParciales().subscribe((cerrarPagosParciales: boolean) => {
            this.cerrarPagosParciales = cerrarPagosParciales;

            // Calcular els camps del formulari abans d'obrir el modal
            this.denunciesCobrarC60Ref = this.dialog.open(DenunciaCobrarByC60Component,
            {
                height: '70%',
                width: '40%',
                closeOnNavigation: false,
                autoFocus: true,
                data: {
                    'data': this.denunciaDetail,
                    'cerrarPagosParciales': this.cerrarPagosParciales
                },
            });

            this.denunciesCobrarC60Ref.afterClosed().subscribe(result => {
                subject.next(result);
            });
        });

        return subject.asObservable();
    }

    OpenModalDenunciaCobrarSmartPol(): Observable<boolean> {
        const subject = new Subject<boolean>();

        this.getCerrarPagosParciales().subscribe((cerrarPagosParciales: boolean) => {
            this.cerrarPagosParciales = cerrarPagosParciales;

            // Calcular els camps del formulari abans d'obrir el modal
            this.denunciesCobrarSmartPolRef = this.dialog.open(DenunciaCobrarSmartPolComponent,
            {
                height: '70%',
                width: '40%',
                closeOnNavigation: false,
                autoFocus: true,
                data: {
                    'data': this.denunciaDetail,
                    'cerrarPagosParciales': this.cerrarPagosParciales
                },
            });

            this.denunciesCobrarSmartPolRef.afterClosed().subscribe(result => {
                subject.next(result);
            });
        });

        return subject.asObservable();
    }

    //#endregion

}
