import { TarifasGruaFilter } from './../models/tarifa-grua-filter';
import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';

import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'descripcion';
const defaultSortDirection = 'asc';

@Injectable()
export class TarifasGruaFilterService extends CustomFilterService {
  public createFilterParams(filter: TarifasGruaFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    return new HttpParams()
    .append('somId',filter.somId)
    .append('descripcion', filter.descripcion)
    .append('importeTarifa', Reflection.empty(filter.importeTarifa) ? null : filter.importeTarifa.toString())
    .append('tipoEstancia', filter.tipoEstancia)
    .append('tipoTarifa', filter.tipoTarifa)
    .append('tipoVehiculo', filter.tipoVehiculo)
    .append('fechaDesde',  Reflection.empty(filter.fechaDesde) ? null :  typeof(filter.fechaDesde) === 'string' ?
    filter.fechaDesde : filter.fechaDesde.toString())
    .append('fechaHasta',  Reflection.empty(filter.fechaHasta) ? null :  typeof(filter.fechaHasta) === 'string' ?
    filter.fechaHasta : filter.fechaHasta.toString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
