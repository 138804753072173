import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {


  constructor(private loaderService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const method = req.method;
    const urlcontar = req.url.includes('count');
    if (req.headers.keys().indexOf('hideProgress') === -1) {
      req.headers.delete('void');
      this.showLoader();
    } else {
      req.headers.delete('hideProgress');
    }

      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (method !== 'OPTIONS' && !urlcontar) {

            this.onEnd();
          }
        }
      },
        (err: any) => {
          this.onEnd();
      }));


  }

  private onEnd(): void {
    this.hideLoader();
  }

  public showLoader(): void {
    this.loaderService.show(false);
  }

  public hideLoader(): void {
    this.loaderService.hide(false);
  }

}
