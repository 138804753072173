var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var ModelsVehiclesComponent = /** @class */ (function () {
    function ModelsVehiclesComponent(dialogRef, 
    //@Optional() is used to prevent error if no data is passed
    data) {
        this.dialogRef = dialogRef;
        this.data = data;
        console.log(data);
        this.local_data = __assign({}, data);
        this.action = this.local_data.action;
    }
    ModelsVehiclesComponent.prototype.ngOnInit = function () {
    };
    ModelsVehiclesComponent.prototype.doAction = function () {
        this.dialogRef.close({ event: this.action, data: this.local_data });
    };
    ModelsVehiclesComponent.prototype.closeDialog = function () {
        this.dialogRef.close({ event: 'Cancel' });
    };
    return ModelsVehiclesComponent;
}());
export { ModelsVehiclesComponent };
