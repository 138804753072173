import { ExcelGenerator } from './../../../shared/interfaces/excel-generator';
import { DenunciesFilterService } from './denuncies-filter.service';
import { Injectable } from '@angular/core';
import { Denuncia } from '../models/denuncia.model';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { HttpSecureClient } from '../../../shared/services/http/httpSecureClient';
import { DenunciaFilter } from '../models/denuncia.filter';
import { PaginationFilter } from '../../../shared/models/pagination.filter';
import { AuthGuardService } from '../../../shared/services/authentication/auth-guard.service';
import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { ExpedientsModel } from 'app/sections/expedients/models/expedients.model';
import { DenunciaCobrarModel } from '../models/denuncia-cobrar.model';
import { DenunciaExpedienteAcuse } from '../models/denuncia-expediente-acuse';
import { DenunciaExpedienteExterno } from '../models/denuncia-expediente-externo';

@Injectable()
export class DenunciesService implements ExcelGenerator {
    displayedItems = [];

    constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
      this.displayedItems['checkedValues'] = 0;
    }

    findDenuncies(filter: DenunciaFilter, paginator: PaginationFilter): Observable<Denuncia[]> {
        const start = paginator.pageNumber * paginator.pageSize;
        const params = new DenunciesFilterService().createFilterPaginator(filter, start, paginator);

        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urldenunciesquery'], params
            ).pipe(
                map(res => res['payload'])
            );
        }
    }

    getDenunciaDetails(somid: number): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urldenunciesquery'] + '/' + somid.toString(), null);
        }
    }
    getVDenunciaIndividual(secureId: number): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlVdenunciaIndividual'] + '/' + secureId.toString(), null);
        }
    }

    getDenunciesDetails(somIds: number[]): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urldenunciesDetails'], somIds);
        }
    }

    getDenunciaImages(numDenuncia: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urldenunciesImages'];
            const params = new HttpParams().append('numDenuncia', numDenuncia);
            return this.http.get(url, params);
        }
    }
    getDenunciaImagesBySomId(somId: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urldenunciesImagesBySomId'];
            const params = new HttpParams().append('somId', somId);
            return this.http.get(url, params);
        }
    }


    getDenunciaImagesNumDen(numDenuncia: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urldenunciesImagesNumDen'] + '/' + numDenuncia;

            return this.http.get(url, null);
        }
    }

    getImage(somid: number, imageName: string): Observable<any> {

        if (this.authGuard.canActivate()) {

            const url = config.urls['apiUrl'] + api.endpoints['urlgetImage'] + '/' + somid + '/' + imageName + '/';

            return this.http.get(url, null);
        }
    }

    deleteImage(secureId: string): Observable<any> {
        if (this.authGuard.canActivate()) {

            const url = config.urls['apiUrl'] + api.endpoints['urlDeleteImage'] + '/' + secureId;

            return this.http.delete(url, null);
        }
    }

    afegirImatges(formData: FormData, numDenuncia: string): Observable<any> {

        if (this.authGuard.canActivate()) {

            const url = config.urls['apiUrl'] + api.endpoints['urlAddImages'] + '/' + numDenuncia;

            return this.http.post(url, formData);
        }
    }
    afegirImatgesBySomId(formData: FormData, somId: string): Observable<any> {

        if (this.authGuard.canActivate()) {

            const url = config.urls['apiUrl'] + api.endpoints['urlAddImagesBySomId'] + '/' + somId;

            return this.http.post(url, formData);
        }
    }

    afegirImatgesWithContenedor(formData: FormData, numDenuncia: string, contendor: string): Observable<any> {

        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlAddImagesWithContenedor'].replace('{somId}', numDenuncia).replace('{contenedor}', contendor);

            return this.http.post(url, formData);
        }
    }
    afegirImatgesGrua(formData: FormData, numDenuncia: string): Observable<any> {

        if (this.authGuard.canActivate()) {

            const url = config.urls['apiUrl'] + api.endpoints['urlAddImagesGrua'].replace('{numDenuncia}', numDenuncia);

            return this.http.post(url, formData);
        }
    }
    afegirImatgesGruaWithContenedor(formData: FormData, numDenuncia: string, contendor: string): Observable<any> {

        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlAddImagesGruaWithContenedor'].replace('{numDenuncia}', numDenuncia).replace('{contenedor}', contendor);

            return this.http.post(url, formData);
        }
    }
    getImagesWithContenedor(contenedor: string, numDenuncia: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlGetImages'].replace('{somId}', numDenuncia).replace('{contenedor}', contenedor);

            return this.http.get(url, null);
        }
    }

    saveDenuncia(secureId: string, dataJson: string): Observable<any> {

        if (this.authGuard.canActivate()) {

            // const base64 = btoa(dataJson);
            const url = config.urls['apiUrl'] + api.endpoints['urldenunciesquery'];
            return this.http.put(url, dataJson);
        }
    }

    generateExcel(filter: DenunciaFilter, sortDir: string, sortField: string): Observable<any> {
        const start = 0; // paginator.pageNumber * paginator.pageSize;
        const pageSize = 1000;
        const params = new DenunciesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

        if (this.authGuard.canActivate()) {
          return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlgenerateExcel'],  params );
        }
    }

    removeDenuncia(somId: number): Observable<any> {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urldenunciesquery'] + '/' + somId.toString();
            return this.http.delete(url, null);
        }
    }

    getTipoOperacions(): Observable<any> {

        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlgetTipoOperacions'];

            return this.http.get(url, null);
        }
    }
    getOperacionsForCombo(): Observable<any> {

        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlgetOperacionsForCombo'];

            return this.http.get(url, null);
        }
    }

    getUsuarisOperacionsForCombo(): Observable<any> {

        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlgetUsuarisOperacionsForCombo'];

            return this.http.get(url, null);
        }
    }

    createOpearcio(idDenuncia: any, operacio: any, data: any): Observable<any> {

        if (this.authGuard.canActivate()) {
            const dataJson = JSON.stringify(data);
            const url = config.urls['apiUrl'] + api.endpoints['urlStoreOperacio'].replace('{idDenuncia}', idDenuncia).replace('{idOperacion}', operacio);
            return this.http.postJSON(url, dataJson);
        }
    }

    createOpearcioMasiva(idDenuncies: any, operacio: any, data: any): Observable<any> {

        if (this.authGuard.canActivate()) {
            data.denunciesIds = idDenuncies;
            const dataJson = JSON.stringify(data);
            const url = config.urls['apiUrl'] + api.endpoints['urlStoreOperacioMasiva'].replace('{idOperacion}', operacio);
            return this.http.postJSON(url, dataJson);
        }
    }

    createOpearcioMasivaBySomIds(somIdsDenuncies: any, operacio: any, data: any): Observable<any> {

        if (this.authGuard.canActivate()) {
            data.denunciesIds = somIdsDenuncies;
            const dataJson = JSON.stringify(data);
            const url = config.urls['apiUrl'] + api.endpoints['urlStoreOperacioMasivaBySomId'].replace('{idOperacion}', operacio);
            return this.http.postJSON(url, dataJson);
        }
    }
    anularOperacio(idDenuncia: any) {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlAnularOperacio'].replace('{idDenuncia}', idDenuncia);
            return this.http.delete(url, null);
        }
    }
    anularSingleOperacio(idDenuncia: any, idOperacio: any, somIdDenuncia: any) {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlAnularSingleOperacio'].replace('{idDenuncia}', idDenuncia).replace('{idOperacio}', idOperacio).replace('{somIdDenuncia}', somIdDenuncia);
            return this.http.delete(url, null);
        }
    }

    getOperacionsLlista(cod_ope: string) {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlGetOperacionsLlista'].replace('{cod_ope}', cod_ope);
            return this.http.get(url, null);
        }
    }

    createDenuncia(): Observable<any> {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlCreateDenuncia'];
            return this.http.get(url, null);
        }
    }

    // TODO: Change this when needed
    getExpedient(id: string): Observable<any> {

      if (this.authGuard.canActivate()) {
        const url = config.urls['apiUrl'] + api.endpoints['urlGetExpedient'].replace('{id}', id);

        return this.http.get(url, null);
      }
    }

    saveProcediment(procediment: ExpedientsModel): Observable<any> {

        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlSaveProcediment'];
            return this.http.post(url, procediment);
        }
    }

    removeProcediment(expedientId: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlRemoveProcediment'].replace('{id}', expedientId);
            return this.http.delete(url, null);
        }
    }

    // TODO: Change this when needed
    getCarrers(): Observable<any> {

      if (this.authGuard.canActivate()) {
        const url = config.urls['apiUrl'] + api.endpoints['urlgetTipoOperacions'];

        return this.http.get(url, null);
      }
    }

    //#region COBRAR DENUNCIA

    setDenunciaCobrar(denunciaCobrar: DenunciaCobrarModel): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetDenunciaCobrar'], denunciaCobrar);
        }
    }

    setDenunciaCobrarByC60(denunciaCobrar: DenunciaCobrarModel): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetDenunciaCobrarByC60'], denunciaCobrar);
        }
    }

    setDenunciaCobroSmartPol(denunciaCobrar: DenunciaCobrarModel): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetDenunciaCobrarSmartPol'], denunciaCobrar);
        }
    }

    getFormasPago(): Observable<any> {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlGetDenunciesFormaPago'];
            return this.http.get(url, null);
          }
    }

    getCobros(exp: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlGetDenunciesCobros'].replace('{exp}', exp);
            return this.http.get(url, null);
        }
    }

    getDenunciesPagoPorcentajeAumento(): Observable<any> {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlGetDenunciesPagoPorcentajeAumento'];
            return this.http.get(url, null);
          }
    }

    getSomExpedient(id: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlGetSomExpedient'].replace('{id}', id);

            return this.http.get(url, null);
        }
    }

    //#endregion

    getSomIdDenunciaByExp(exp: number): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetIdDenunByExp'] + '/' + exp, null);
        }
    }

    getOrigensForCombo(): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetOrigensDenuncia'], null);
        }
    }

    cargarExpedienteAcuse(data: DenunciaExpedienteAcuse): Observable<any> {
        if (this.authGuard.canActivate()) {
          return this.http.post(config.urls['apiUrl'] + api.endpoints['urlCarregarExpedientsJustificant'], data );
        }
    }
    cargarExpedienteExerno(data: DenunciaExpedienteExterno): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlCarregarExpedientsExterns'], data);
        }
    }
    OperacionGenerarC60(somIdsDenuncies: any, data): Observable<any> {
        if (this.authGuard.canActivate()) {
            data.denunciesIds = somIdsDenuncies[0];
            data.estadoOperacion = data.estadoOperacion;
            data.dataDescompte = data.fecha;
            const dataJson = JSON.stringify(data);
            return this.http.postJSON(config.urls['apiUrl'] + api.endpoints['urlOperacionGenerarC60'], dataJson );
        }
    }
    solicitarRetiradaGrua(secureIdDenuncia: string): Observable<any> {
        if (this.authGuard.canActivate()) {
          return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSolicitarRetiradaGrua'].replace('{secureId}', secureIdDenuncia), null );
        }
    }

    hasGruaAgent(): Observable<any> {
        if (this.authGuard.canActivate()) {
          return this.http.get(config.urls['apiUrl'] + api.endpoints['urlHasGruaAgent'], null );
        }
    }
    getEstasGruaForCombo(): Observable<any> {
        if (this.authGuard.canActivate()) {
            const url = config.urls['apiUrl'] + api.endpoints['urlallestasGrua'];

            return this.http.get(url, null);
        }
    }
}
