/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tarifes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i6 from "../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i7 from "../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i8 from "../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i9 from "../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component.ngfactory";
import * as i10 from "../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component";
import * as i11 from "../../../shared/services/authorization/authorization-service";
import * as i12 from "@angular/material/dialog";
import * as i13 from "./components/tarifes-table/tarifes-table.component.ngfactory";
import * as i14 from "./components/tarifes-table/tarifes-table.component";
import * as i15 from "./services/tarifes.service";
import * as i16 from "../../../shared/services/filter/filter.service";
import * as i17 from "@ngx-translate/i18n-polyfill";
import * as i18 from "./tarifes.component";
import * as i19 from "../../../shared/services/notification/notification.service";
import * as i20 from "../../../shared/components/confirmation-dialog/confirmation-dialog.service";
import * as i21 from "ngx-toastr";
var styles_TarifesComponent = [i0.styles];
var RenderType_TarifesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TarifesComponent, data: {} });
export { RenderType_TarifesComponent as RenderType_TarifesComponent };
function View_TarifesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["label", "Tarifes"]], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Tarifes"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TarifesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { tarifesTable: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TarifesComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 11, "app-toolbar", [], null, null, null, i5.View_ToolbarComponent_0, i5.RenderType_ToolbarComponent)), i1.ɵdid(4, 114688, null, 0, i6.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "app-tool-button", [["color", "has-color-blue"], ["icon", "fa-sync"], ["text", "Actualitzar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(6, 114688, null, 0, i8.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "app-tool-button", [["color", "has-color-orange"], ["icon", "fa-filter"], ["text", "Filtre"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFilterClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(8, 114688, null, 0, i8.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-tool-button-permissions", [], null, null, null, i9.View_ToolButtonPermissionsComponent_0, i9.RenderType_ToolButtonPermissionsComponent)), i1.ɵdid(10, 114688, null, 0, i10.ToolButtonPermissionsComponent, [i11.AuthorizationService, i12.MatDialog], { permission: [0, "permission"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "app-tool-button", [["color", "has-color-aqua"], ["icon", "fa-cloud-download-alt"], ["text", "Descarregar dades"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.descargarDatos() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(12, 114688, null, 0, i8.ToolButtonComponent, [], { disabled: [0, "disabled"], text: [1, "text"], color: [2, "color"], icon: [3, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "app-tool-button", [["color", "has-color-aqua"], ["icon", "fa-cloud-upload-alt"], ["text", "Publicar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.publicar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(14, 114688, null, 0, i8.ToolButtonComponent, [], { disabled: [0, "disabled"], text: [1, "text"], color: [2, "color"], icon: [3, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-tarifes-table", [], null, [[null, "notify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("notify" === en)) {
        var pd_0 = (_co.onRowSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_TarifesTableComponent_0, i13.RenderType_TarifesTableComponent)), i1.ɵdid(16, 4833280, [[1, 4]], 0, i14.TarifesTableComponent, [i15.TarifesService, i16.FilterService, i12.MatDialog, i17.I18n], { selectButton: [0, "selectButton"], updatedFilterData: [1, "updatedFilterData"] }, { notify: "notify" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataDialog.isDialog; _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); var currVal_1 = "Actualitzar"; var currVal_2 = "has-color-blue"; var currVal_3 = "fa-sync"; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "Filtre"; var currVal_5 = "has-color-orange"; var currVal_6 = "fa-filter"; _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.auth.Pages.Tarifes; _ck(_v, 10, 0, currVal_7); var currVal_8 = !_co.auth.UserCanCreate(_co.auth.Pages.Tarifes); var currVal_9 = "Descarregar dades"; var currVal_10 = "has-color-aqua"; var currVal_11 = "fa-cloud-download-alt"; _ck(_v, 12, 0, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = (!_co.auth.UserCanCreate(_co.auth.Pages.Tarifes) || !_co.publicarAvailable); var currVal_13 = "Publicar"; var currVal_14 = "has-color-aqua"; var currVal_15 = "fa-cloud-upload-alt"; _ck(_v, 14, 0, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_16 = (_co.dataDialog.isDialog && !_co.dataDialog.isReadOnly); var currVal_17 = _co.filterData; _ck(_v, 16, 0, currVal_16, currVal_17); }, null); }
export function View_TarifesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tarifes", [], null, null, null, View_TarifesComponent_0, RenderType_TarifesComponent)), i1.ɵdid(1, 4308992, null, 0, i18.TarifesComponent, [i12.MatDialog, i11.AuthorizationService, i17.I18n, i19.NotificationService, i12.MatDialogRef, i15.TarifesService, i20.ConfirmationDialogService, i21.ToastrService, i12.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TarifesComponentNgFactory = i1.ɵccf("app-tarifes", i18.TarifesComponent, View_TarifesComponent_Host_0, {}, {}, []);
export { TarifesComponentNgFactory as TarifesComponentNgFactory };
