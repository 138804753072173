var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from "@angular/common/http";
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";
var defaultSortParam = 'descripcio';
var defaultSortDirection = 'asc';
var TextosPredefinidosFilterService = /** @class */ (function (_super) {
    __extends(TextosPredefinidosFilterService, _super);
    function TextosPredefinidosFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TextosPredefinidosFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('descripcion', filter.descripcion)
            .append('id', (filter.id ? filter.id.toString() : ''))
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return TextosPredefinidosFilterService;
}(CustomFilterService));
export { TextosPredefinidosFilterService };
