var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'id';
var defaultSortDirection = 'asc';
var InfraccioFilterService = /** @class */ (function (_super) {
    __extends(InfraccioFilterService, _super);
    function InfraccioFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InfraccioFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('id', filter.id === null ? '' : filter.id.toString())
            .append('legislacio', filter.legislacio)
            .append('article', filter.article)
            .append('apartat', filter.apartat)
            .append('opcio', filter.opcio)
            .append('gravetat', filter.gravetat)
            .append('descCurta', filter.descCurta)
            .append('import', filter.import === null ? '' : filter.import.toString())
            .append('fins', filter.fins)
            .append('punts', filter.punts)
            .append('inici', Reflection.empty(filter.inici) ? '' : typeof (filter.inici) === 'string' ?
            filter.inici : filter.inici.toDateString())
            .append('final', Reflection.empty(filter.final) ? '' : typeof (filter.final) === 'string' ?
            filter.final : filter.final.toDateString())
            .append('numFotosPda', filter.numFotosPda === null ? '' : filter.numFotosPda.toString())
            .append('obsoleta', this.getTristateValue(filter.obsoleta))
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return InfraccioFilterService;
}(CustomFilterService));
export { InfraccioFilterService };
