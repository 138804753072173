<div class="row justify-content-between" >
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
    <ng-container matColumnDef="Nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nombre</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.Nombre}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Descripcion">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripcion</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.Descripcion}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Puntos">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Puntos</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.Puntos}}</mat-cell>
      <!-- {{row.Horas.join(';')}} -->
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="flex-container">
          <button mat-icon-button color="primary" (click)="editZbRuta(row)"><mat-icon>edit</mat-icon></button>
        </div>
    </mat-cell>
    </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
