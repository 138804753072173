import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ExpedientsModel } from './models/expedients.model';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DenunciesImagesComponent } from '../denuncies/components/denuncies-editor/components/denuncies-images/denuncies-images.component';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-expedients',
  templateUrl: './expedients.component.html'
})
export class ExpedientsComponent implements OnInit {

  expedient: ExpedientsModel;
  numeroDenuncia;
  denunciesImages;
  imatgesCount;
  constructor(
    public thisDialogRef: MatDialogRef<ExpedientsComponent>,
    private denunciesService: DenunciesService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private i18n: I18n,
    private confirmationDialogService: ConfirmationDialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.expedient = data.expedient;
    this.numeroDenuncia = data.numDenuncia;
    this.denunciesImages = data.imatges;
    this.imatgesCount = data.imatges.length;
  }

  ngOnInit() {
  }

  cancel() {
    this.thisDialogRef.close(false);
  }
  save() {
    this.denunciesService.saveProcediment(this.expedient).subscribe(() => {
      this.toastr.success(this.i18n('Procediment actualitzat correctament'));
    });
  }

  remove() {
    if (this.expedient.dataTraspasExecutiva !== null || this.expedient.tancatOfici !== null ) {
      this.toastr.error(this.i18n('No es pot esborrar les notificacions, està traspassada a Executiva o tancada d\'ofici'));
    } else {
      this.confirmationDialogService.confirm(this.i18n('Avis'), this.i18n('ATENCIÓ està segur que voleu eliminar les notificacions?'))
      .then((confirmed) => {
          if (confirmed) {
            this.denunciesService.removeProcediment(this.expedient.expedient).subscribe(() => {
              this.denunciesService.getExpedient(this.expedient.expedient).subscribe( expedientActualitzat => {
                this.expedient = expedientActualitzat;
                this.toastr.success(this.i18n('Procediment eliminat correctament'));
              });
            });
          }
        });
    }
  }

  onImagesClick() {

    this.dialog.open(DenunciesImagesComponent,
    {
      minWidth: '70%',
      minHeight: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {'ficheros': this.denunciesImages, 'numDenuncia': this.numeroDenuncia},
    });


}



}
