import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-multi-select',
  templateUrl: './modal-multi-select.component.html',
  styleUrls: ['./modal-multi-select.component.scss']
})
export class ModalMultiSelectComponent implements OnInit {

  title: string;
  multiSelectData: any[];
  selectedValues:any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public thisDialogRef: MatDialogRef<ModalMultiSelectComponent>,

    
  ) {    
    this.title = this.data.title;
    this.multiSelectData = this.data.multiSelectData;
    this.selectedValues = this.data.selectedValues;
   }

  ngOnInit() {
  }

  save(){
    this.thisDialogRef.close(this.selectedValues);
  }

}
