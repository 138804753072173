export class CustomMapMarker {

    sortable1: any;
    sortable2: any;
    marker: any;
    infoWindow: any;

    constructor(sortable1: any, sortable2: any, marker: any, infoWindow: any) {
        this.sortable1 = sortable1;
        this.sortable2 = sortable2;
        this.marker = marker;
        this.infoWindow = infoWindow;
    }

}
