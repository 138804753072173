import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ConfiguracionBOEDto } from '../../models/config-boe.model';
import { BoeService } from '../../services/boe.service';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.scss']
})
export class ConfiguracionComponent implements OnInit {

  public configuracion: ConfiguracionBOEDto;
  constructor(
    public auth: AuthorizationService,
    private boeService: BoeService
  ) { 
    this.configuracion = 
    {secureId:'',
    id: 1,
    rutaP12: '',
    textoLegalDen: '',
    textoLegalAlegacion: '',
    textoLegalSancionFirme: '',
    textoLegalResolucion: '',
    activo: true,
    dir3Code: '',
    dir3Nombre: '',
    camposMostrarTabla: '',
    notificarDenuncia: true,
    notificarAlegaciones: true,
    notificarSancionFirme: true,
    notificarResolucion: true,
    tipoExportacion: 1,
    rutaExportacion: '',
    firmaFirmante:'',
    firmaLugar:''
    };
    this.boeService.getConfiguracionBoe().subscribe(x =>{
      this.configuracion = x;
    });
  }

  ngOnInit() {
  }
  save(){
    this.boeService.setConfiguracionBoe(this.configuracion).subscribe(x =>{
      this.configuracion = x;
    });
  }
}
