import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { BonosFilterService } from './bonos-filter.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../../shared/services/authentication/auth-guard.service";
var defaultSortParam = 'fechaFin';
var defaultSortDirection = 'desc';
var BonosService = /** @class */ (function () {
    function BonosService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
    }
    BonosService.prototype.generateExcel = function (filter, sortDir, sortField) {
        var start = 0;
        var pageSize = 1000;
        sortField = sortField === undefined ? defaultSortParam : sortField;
        sortDir = sortDir === '' ? defaultSortDirection : sortDir;
        var params = new BonosFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);
        if (this.authGuard.canActivate()) {
            return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlBonosExcel'], params);
        }
    };
    BonosService.ngInjectableDef = i0.defineInjectable({ factory: function BonosService_Factory() { return new BonosService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: BonosService, providedIn: "root" });
    return BonosService;
}());
export { BonosService };
