import { CargaProcesarDto } from './../models/cargaProcesarDto';
import { BancCarreguesC60Detall } from './../models/banc-carregues-c60-detall.model';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { BancCarreguesC60 } from '../models/banc-carregues-c60.model';
import { BancCarreguesC60Filter } from '../models/banc-carregues-c60-filter';
import { BancCarreguesC60FilterService } from './banc-carregues-c60-filter.service';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class BancCarreguesC60Service {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  getBancCarregaC60(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetBancCarregaC60'].replace('{secure_id}', secure_id), null);
    }
  }

  getBancCarregaDetallResumC60(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetBancCarregaDetallResumC60Count'].replace('{secure_id}', secure_id), null);
    }
  }

  setBancCarregaC60(element: BancCarreguesC60): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetBancCarregaC60'], element);
    }
  }

  // removeColor(secure_id): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteColor'].replace('{secure_id}', secure_id), null);
  //   }
  // }

  generateExcel(filter: BancCarreguesC60Filter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new BancCarreguesC60FilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlBancCarreguesC60Excel'],  params );
    }
  }

  /*cargarFichero(registro: File): Observable<any>{
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlBancCarreguesProcesarRegistro'],  registro );
    }
  }*/
  getAllBancsCargas(secureId: string ): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllBancCarregaC60'].replace('{secure_id}', secureId), null);
    }
  }
  procesarRegistro(element: CargaProcesarDto) {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlBancCarreguesProcesarRegistro'],  element );
    }
  }

  procesarV2(secureId: string, fechaCobro:string) {
    if (this.authGuard.canActivate()) {
      let info = {'secureId':secureId, 'fechaCobro':fechaCobro}
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlBancCarreguesProcesarV2'], info );
    }
  }

  comprovarEstadoFichero(element: BancCarreguesC60): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlComprovarEstadoFichero'], element);
    }
  }
  checkRegistrosErorres(element: BancCarreguesC60): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlcheckRegistrosErorresC60'], element);
    }
  }
  FinalizarCargac60(element: BancCarreguesC60): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlFinalizarCargaC60'], element);
    }
  }

  getAllBancsCargasParaReporcesar(secureId: string ): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllBancCarregaParaReprocesarC60'].replace('{secure_id}', secureId), null);
    }
  }
  ReprocesarRegistro(element: CargaProcesarDto) {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlBancCarreguesReprocesarRegistro'],  element );
    }
  }

  ReprocesarCerradoRegistro(element: CargaProcesarDto) {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlBancCarreguesReprocesarRegistroCerrado'],  element );
    }
  }
  getAllBancsCargasParaReporcesarCerrados(secureId: string ): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllBancCarregaParaReprocesarCerradosC60'].replace('{secure_id}', secureId), null);
    }
  }
}
