import { Component, OnInit, ViewChild, ElementRef, Inject, ViewContainerRef } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { Maquina } from '../../models/maquina.model';
import { MaquinasService } from '../../services/maquinas.service';
import { Instituciones } from 'path-shared/models/instituciones';
import { BehaviorSubject } from 'rxjs';
import { Dictionary } from 'path-shared/models/dictionary';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { GenericFileEventsService } from 'path-shared/components/generic-file-displayer/services/generic-file-events.service';
import { GenericFileService } from 'path-shared/components/generic-file-displayer/services/generic-file.service';
import { GenericFile } from 'path-shared/components/generic-file-displayer/model/generic-file';
import { GenericFileDisplayerPageComponent } from 'path-shared/components/generic-file-displayer/components/generic-file-displayer-page/generic-file-displayer-page.component';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { Parametros } from 'path-shared/models/parametros';
import { ZonesModel } from 'app/sections/ciutat/zones/models/zones.model';
import { ZonesService } from 'app/sections/ciutat/zones/services/zones.service';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { AutocompleteComboComponent } from 'path-shared/components/form/autocomplete-combo/autocomplete-combo.component';
import { Tramo } from 'app/sections/zona-blava/estacionament/tramos/tramos/models/tramo.model';
import { MaquinaTramo } from '../../models/maquinaTramo.model';
import { TemporadaDto } from 'app/sections/zona-blava/estacionament/temporada/models/temporadaDto';
import { TramosService } from 'app/sections/zona-blava/estacionament/tramos/tramos/services/tramos.service';
import { TramosEditorComponent } from 'app/sections/zona-blava/estacionament/tramos/tramos/components/tramos-editor/tramos-editor.component';
import { TemporadaService } from 'app/sections/zona-blava/estacionament/temporada/services/temporada.service';

declare var google: any;

@Component({
  selector: 'app-maquinas-editor',
  templateUrl: './maquinas-editor.component.html',
  styleUrls: ['./maquinas-editor.component.scss']
})
export class MaquinasEditorComponent implements OnInit {
  public styleMap = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];
  secure_id: string;
  maquina: Maquina;
  dialogData: ComponentDialog;
  closeButtonText: string;
  allTramos: Tramo[];
  allViewTramos: Tramo[];
  public allTramosBehaviorSubject: BehaviorSubject<Tramo[]>;
  selectedTramos: BehaviorSubject<MaquinaTramo[]>;
  /*public get selectedTramos() {
    return this._selectedTramos.
  } set selectedTramos(val) {
    this._selectedTramos = val;
  }*/
  selectedTemporadaSomId: number;
  public currentLocation: {
    latitude: number,
    longitude: number
  };

  public afectacionCalculadaActiva: boolean;
  map: any;
  markerPosition: any;

  dictMarcas: Dictionary[];
  valorAutoCompleteMarcas;
  marcasList = [];
  @ViewChild('comboMarca') comboMarca: AutocompleteComboComponent;
  @ViewChild('tabGroup') tabGroup;

  displayedColumns: string[] = ['id','Descripcio', 'Afectacio', 'AfectacioCalc', 'ocupacion'];
  @ViewChild('mainForm') private mainForm: ElementRef;
  genericFilesPageComponent: MatDialogRef<GenericFileDisplayerPageComponent>;
  fileEvents: GenericFileEventsService;

  //autocomplete combo Infracciones
  zonas: ZonesModel[];
  temporadasDictionary: Array<Dictionary>;
  valorAutoCompleteTemporada;
  @ViewChild('comboTemporada') comboTemporada: ComboBoxInputAutocompleteComponent;


  isPositionSet: boolean = false;
  temporadesList = Array<FormMultiSelect>();
  temporadas: TemporadaDto[];
  
  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private maquinesService: MaquinasService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<MaquinasEditorComponent>,
    private formService: FormService,
    public vcRef: ViewContainerRef,
    private tramosService: TramosService,
    private genericFileService: GenericFileService,
    private parametersService: ParametersService,
    private temporadaService: TemporadaService,
    private zonesService: ZonesService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
  ) {
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      const institution: Instituciones = JSON.parse(inst);
      this.currentLocation = {latitude: institution.Latitud, longitude: institution.Longitud};
    }
    this.selectedTramos = new BehaviorSubject(new Array <MaquinaTramo>());
    this.allTramosBehaviorSubject = new BehaviorSubject(new Array <Tramo>());
    this.maquina = Builder.newMaquina();
    this.dialogData = data;
    this.secure_id = this.dialogData.id;
    if (this.dialogData.isReadOnly) {
      this.closeButtonText = this.i18n('Tancar');
    } else {
      this.closeButtonText = this.i18n('Llista');
    }
    this.valorAutoCompleteMarcas = '';
    this.valorAutoCompleteTemporada = '';
    this.afectacionCalculadaActiva = false;
  }

  ngOnInit() {
    let self = this;
    if (this.secure_id !== null) {
      this.temporadaService.GetAllForCombo().subscribe( result => {
        if (result !== null) {
          this.zonas = result;
          this.temporadas = result;
          this.temporadesList = [];
         
        }

      this.tramosService.getAllTramos().subscribe( data =>{
        let tempTramos = data;

        this.maquinesService.getMaquina(this.secure_id).subscribe(data => {
          this.maquina = data;
          this.setDefalutValuesCombosMarca();
          //this.loadTemporadas();
          this.loadZonas();
          if (this.maquina !== undefined && this.maquina !== null && this.maquina.position !== undefined && this.maquina.position !== null){
            this.currentLocation = {latitude: this.maquina.position.lat, longitude: this.maquina.position.lng};
            if (this.maquina !== undefined && this.maquina !== null && this.maquina.position !== undefined && this.maquina.position !== null){
              this.markerPosition = new google.maps.Marker ({
                animation: google.maps.Animation.BOUNCE,
                map: this.map,
                label: {
                  fontFamily: "'Font Awesome 5 Free'",
                  text: '\uf540',
                  color: '#497eba',
                  fontWeight: '900',
                  fontSize: 'xx-large'
                },
                icon: ' ',
                clickable: false,
                draggable: true,
                position: new google.maps.LatLng(this.maquina.position.lat,  this.maquina.position.lng),
              });
              this.markerPosition.addListener('dragend', function(e) {
                const position = Builder.newPosition(e.latLng.lat(), e.latLng.lng());
                self.maquina.position = position;
              });

              this.isPositionSet = true;
            }
            if ( this.maquina.maquinaTramos !== undefined && this.maquina.maquinaTramos !== null) {
              const tramosAct: MaquinaTramo[] = Object.assign([], this.maquina.maquinaTramos);
              this.selectedTramos.next(tramosAct);
            }
          }

          // const zonaSelected = this.zonas[this.tabGroup.selectedIndex];
          // if (zonaSelected !== undefined && zonaSelected !== null &&   this.maquina.maquinaTramos !== undefined && this.maquina.maquinaTramos !== null) {
          //   this.maquina.maquinaTramos.forEach(t =>{
          //     if (t.SomIdZona ===  zonaSelected.SOM_ID) {
          //       const index: number = tempTramos.findIndex(x => x.Som_id === t.SomIdTramo);
          //       if (index !== -1) {
          //         tempTramos[index].strokeColor = '#000000';
          //       }
          //     }
          //   });
          this.temporadaService.GetAllForCombo().subscribe( result => {
            if (result !== null) {
              this.zonas = result;
              this.temporadas = result;
              this.temporadesList = [];
              this.temporadas.forEach(temp => {
        
                if (this.maquina.temporadas !== undefined && this.maquina.temporadas !== null && this.maquina.temporadas.find(x => x.item_id === temp.Som_id)) {
                  this.temporadesList.unshift(new FormMultiSelect(temp.Som_id, temp.descripcion));
                } else {
                  this.temporadesList.push(new FormMultiSelect(temp.Som_id, temp.descripcion));
                }
              });
            }
          });
            // if(this.temporadas !== undefined  && this.temporadas !== null ){
            //   this.temporadas.forEach(temp => {
                
            //     if (this.maquina.temporadas !== undefined && this.maquina.temporadas !== null && this.maquina.temporadas.find(x => x.item_id === temp.Som_id)) {
            //       this.temporadesList.unshift(new FormMultiSelect(temp.Som_id, temp.descripcion));
            //     } else {
            //       this.temporadesList.push(new FormMultiSelect(temp.Som_id, temp.descripcion));
            //     }
            //   });
            // }
            
            /*const tramosAll: Tramo[] = Object.assign([], this.allTramos);
            this.allTramosBehaviorSubject.next(tramosAll);*/
            this.allTramos =  Object.assign([], tempTramos);
            this.refreshPolygons();
         // }
        });
      });
    });
    
    } else {
      this.loadTemporadas();
      this.loadZonas();
    }
   

    this.loadMarcas();

    this.loadParameters();
  }

  // getCurrentTemporadas(): TemporadaDto[]{
  //   let currentTemporadas= [];
  //   this.maquina.temporadas.forEach(element => {
  //     let temp = this.temporadas.find(x => x.Som_id == item_id)
  //     currentTemporadas.push
  //   });
  //   return currentTemporadas; 
  // }
  loadParameters() {
    let parameterValue = this.parametersService.getParameter(Parametros.AFECTACION_CALCULADA);
    if (parameterValue !== null && parameterValue !== undefined && parameterValue !== '' && parameterValue !== 'N' ) {
     this.afectacionCalculadaActiva = (parameterValue === '1');
    }
  }
  loadMarcas() {
    this.dictMarcas = new Array<Dictionary>();
    this.maquinesService.getAllMarcasMaquinas().subscribe( x => {
      this.marcasList = x;
      x.forEach(element => {
        this.dictMarcas.push(new Dictionary(element.secureId.toString(), element.descripcion.toString()));
      });
    });
  }
  setDefalutValuesCombosMarca() {
    //marca 
    if (this.maquina.secureIdMarcaMaquina !== undefined && this.maquina.secureIdMarcaMaquina !== null && this.maquina.secureIdMarcaMaquina !== '') {
      this.dictMarcas = new Array<Dictionary>();
      this.maquinesService.getAllMarcasMaquinas().subscribe( x => {
        this.marcasList = x;
        x.forEach(element => {
          this.dictMarcas.push(new Dictionary(element.secureId.toString(), element.descripcion.toString()));
        });
      const marcaSelcted = this.dictMarcas.find(x => x.key === this.maquina.secureIdMarcaMaquina.toString());
      this.comboMarca.setForcedValueComb(marcaSelcted.value);
    });
    }
    else {
      this.loadMarcas();
    }
  }

  marcaSeleccionado(secureIdMarca: string) {
    //const tipusSelected = this.dictTipusCobro.find(x => x.key === idTipusCobro);
    this.maquina.secureIdMarcaMaquina = secureIdMarca;
    //this.value.idTipoPago = tipusSelected.key;
  }

  getSelectedTramos(somIdTemporada: number) {

    const somIdNumber =  Number(somIdTemporada);
    return this.selectedTramos.value.filter(s => s.SomIdTemporada === somIdNumber);
  }
  selectedTemporada() {
    this.refreshPolygons();
  }
  loadZonas() {
    this.zonesService.getZones().subscribe(result => {
      if (result !== null) {
        this.zonas = result;
      }
    });
  }
  loadTemporadas() {
    this.temporadaService.GetAllForCombo().subscribe( result => {
      if (result !== null) {
        this.zonas = result;
        this.temporadas = result;
        this.temporadesList = [];
        this.temporadas.forEach(temp => {
  
          if (this.maquina.temporadas !== undefined && this.maquina.temporadas !== null && this.maquina.temporadas.find(x => x.item_id === temp.Som_id)) {
            this.temporadesList.unshift(new FormMultiSelect(temp.Som_id, temp.descripcion));
          } else {
            this.temporadesList.push(new FormMultiSelect(temp.Som_id, temp.descripcion));
          }
        });
      }
    });
  }
  temporadaAddedOrDelted(){
    let somIdTemporadasBorrar = [];
    this.maquina.maquinaTramos.forEach(element => {
      if(this.maquina.temporadas.findIndex(x => x.item_id.toString() ===element.SomIdTemporada.toString()) === -1){
        somIdTemporadasBorrar.push(element.SomIdTemporada);
      }
    });
   somIdTemporadasBorrar.forEach(somIdTemporadaABorrar =>{
     //reasignamos los tramos con las temporadas filtradas

    this.maquina.maquinaTramos = this.maquina.maquinaTramos.filter(x=> x.SomIdTemporada != somIdTemporadaABorrar);
   });
    // let indexBase =0;
    // let indexesToDelete =[];
    // this.maquina.maquinaTramos.forEach(x =>{
    //   const index = this.maquina.temporadas.findIndex(y => y.item_id ===x.SomIdTemporada);
    //   if(index === -1 ){
    //     indexesToDelete.push(indexBase);
    //   }
    //   indexBase=indexBase+1;
    // });
    // //borrem les maquines trams associades a les temporades que ja no hi son
    // if(indexesToDelete.length>0){
    //   indexesToDelete.forEach(x =>{
    //     this.maquina.maquinaTramos.splice(x,1);
    //   });
    // }
  }
  setDefaultValueTemporada(){
    if (this.maquina.secureIdTemporada !== undefined && this.maquina.secureIdTemporada  !== null && this.temporadasDictionary.length > 0 ) {
      const temporadaSelected = this.temporadasDictionary.find(x => x.key === this.maquina.secureIdTemporada.toString());
      this.comboTemporada.setForcedValueComb(temporadaSelected.value);
    }
  }

  temporadaSeleccionado(secureIdTemporada: string) {
    /*var res = this.zona.find(x => x.secureId === secureIdTemporada);
    if (res !== undefined && res !== null) {
      this.maquina.temporada = res;
      this.maquina.secureIdTemporada = res.secureId;
    }*/
  }
  save() {
    if (!this.isPositionSet) {
      this.toastr.warning(this.i18n('És necessari marcar una posició al mapa!'));
    }
    else if (this.formService.allFieldsValid(this.mainForm)) {
      this.maquinesService.setMaquina(this.maquina).subscribe(result => {
        this.toastr.info(this.i18n('Màquina guardada correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la màquina?'))
    .then((confirmed) => {
        if (confirmed) {
          this.maquinesService.removeMaquina(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Màquina esborrada correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

  polyClicked(tramo: Tramo){
    if(this.maquina.maquinaTramos === undefined || this.maquina.maquinaTramos === null){
      //validamos que este incializado
      this.maquina.maquinaTramos = [];
    }
    const temporadaSelected = this.temporadas.find(x => x.Som_id.toString() === this.maquina.temporadas[this.tabGroup.selectedIndex].item_id.toString() );
    const index: number = this.maquina.maquinaTramos.findIndex(x => x.SomIdTramo === tramo.Som_id && x.SomIdTemporada === temporadaSelected.Som_id);
    if (index !== -1) {
        //quitamos tramo de la lista
        this.maquina.maquinaTramos.splice(index, 1);
        tramo.strokeColor = tramo.zona.color;
    }else{
      const maquinaTramo = Builder.newMaquinaTramo(this.maquina, tramo, temporadaSelected.Som_id);
      this.maquina.maquinaTramos.push(maquinaTramo);
      tramo.strokeColor = '#000000';
    }
    const tramosAct: MaquinaTramo[] = Object.assign([], this.maquina.maquinaTramos);
    this.selectedTramos.next(tramosAct);
  }
  placeMarker($event){
    let self = this;
    if (this.markerPosition !== undefined && this.markerPosition !== null){
      this.markerPosition.setMap(null);
    }
    const position = Builder.newPosition($event.coords.lat, $event.coords.lng);
    this.maquina.position = position;
    this.markerPosition = new google.maps.Marker ({
      animation: google.maps.Animation.BOUNCE,
      map: this.map,
      label: {
        fontFamily: "'Font Awesome 5 Free'",
        text: '\uf540',
        color: '#497eba',
        fontWeight: '900',
        fontSize: 'xx-large'
      },
      icon: ' ',
      clickable: false,
      draggable: true,
      position: new google.maps.LatLng(position.lat, position.lng)
    });
    // this.markerPosition.setAnimation(null);
    // this.markerPosition.setAnimation(google.maps.Animation.BOUNCE);
    this.markerPosition.addListener('dragend', function(e) {
      const position = Builder.newPosition(e.latLng.lat(), e.latLng.lng());
      self.maquina.position = position;
    });
    if (this.currentLocation !== undefined) { this.isPositionSet = true; }
  }
 
  mapReady(map: any) {
    this.map = map;
    let self = this;
    if (this.maquina !== undefined && this.maquina !== null && this.maquina.position !== undefined && this.maquina.position !== null){
      this.markerPosition = new google.maps.Marker ({
        animation: google.maps.Animation.BOUNCE,
        map: this.map,
        label: {
          fontFamily: "'Font Awesome 5 Free'",
          text: '\uf540',
          color: '#497eba',
          fontWeight: '900',
          fontSize: 'xx-large'
        },
        icon: ' ',
        clickable: false,
        draggable: true,
        position: new google.maps.LatLng(this.maquina.position.lat,  this.maquina.position.lng)
      });
    //  this.markerPosition.setAnimation(null);
     // this.markerPosition.setAnimation(google.maps.Animation.BOUNCE);
      google.maps.event.addListener(this.markerPosition, 'dragend', function(e) {
        const position = Builder.newPosition(e.latLng.lat(), e.latLng.lng());
        self.maquina.position = position;
    });
    }
  }


  fitxers(): void {
    // Nos suscribimos a los eventos de ficheros (solo la primera vez).
    this.fileEvents = new GenericFileEventsService();
    this.fileEvents.deleteFileCommand.subscribe(idsToDelete => {
      this.maquinesService.deleteFicheros(idsToDelete).subscribe(result => {
        // Ya hemos eliminado, indicamos al generic files component que updatee la lista.
        this.fileEvents.deleteFilesComplete.emit(idsToDelete);
      });
    });
    this.fileEvents.uploadFileCommand.subscribe((file: File) => {
      this.genericFileService.getBase64(file).then(data => {
        const ficheroGenerico = <GenericFile> {
            nombre: file.name,
            fichero: data,
            idRegistroAsociado: this.maquina.codi
        };
        // Tenemos el fichero para enviar al back.
        this.maquinesService.uploadMaquinaFichero(ficheroGenerico).subscribe(result => {
          // Ya hemos subido el fichero, ahora lo mostramos en el listado.
          this.fileEvents.uploadFileComplete.emit(ficheroGenerico);
        });
      });
    });

    // Descargamos los ficheros y abrimos el componente para mostrarlos.
    this.maquinesService.getFicherosMaquina(this.maquina.codi).subscribe((result: GenericFile[]) => {
      // Si hemos obtenido los ficheros, podemos abrir la galeria para mostrarlos.
      if (result !== null && result !== undefined) {
        this.genericFilesPageComponent = this.dialog.open(
          GenericFileDisplayerPageComponent,
          {
            minWidth: '50%',
            minHeight: '90%',
            width: '50%',
            height: '90%',
            maxWidth: '50%',
            maxHeight: '90%',
            closeOnNavigation: false,
            data: {
              'files': result,
              'fileEvents': this.fileEvents
            },
          }
        );
      }
    });
  }

  openTramo(element: MaquinaTramo) {
    let data = Builder.newComponentDialog(true, false, element.tramo.secureId);
    const ref = this.dialog.open(TramosEditorComponent, {
      width: '70%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'data' : data,
        'isMaquina': true
      }
    });

    ref.afterClosed().subscribe(tramo => {
      if(tramo  !== undefined && tramo  !== null){
        element.tramo = tramo;
        this.allTramos.find(x => x.secureId === tramo.secureId).poligon = tramo.poligon;
        this.allTramos.find(x => x.secureId === tramo.secureId).zona = tramo.zona;
      }
    });
  }


  refreshPolygons(){
    if(this.temporadas !== undefined && this.temporadas !== null && this.allTramos !== undefined 
      && this.allTramos !== null && this.tabGroup.selectedIndex !== -1){

      const temporadaSelected = this.temporadas.find(x => x.Som_id.toString() === this.maquina.temporadas[ this.tabGroup.selectedIndex].item_id.toString());
      let tempTramos = this.allTramos.filter(x=> x.secureIdTemporada === temporadaSelected.secureId);
       //const zonaSelected = this.zonas[this.tabGroup.selectedIndex];
        if ( this.allTramos !== undefined && this.allTramos !== null && this.maquina.maquinaTramos !== undefined && this.maquina.maquinaTramos !== null) {
          this.maquina.maquinaTramos.forEach(t =>{
            const index: number = tempTramos.findIndex(x => x.Som_id === t.SomIdTramo);
            if (t.SomIdTemporada ===temporadaSelected.Som_id) {
              if (index !== -1) {
                tempTramos[index].strokeColor = '#000000';
                
              }else{
              tempTramos[index].strokeColor = tempTramos[index].zona.color;
              }
            }
          });
          this.allViewTramos =  Object.assign([], tempTramos);
        }
    }

  }
}
