import { AppComponent } from './../../../../app.component';
import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { TipusMatriculaFilter } from '../models/tipus-matricula-filter';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Router } from '@angular/router';
import { TipusMatriculesTableComponent } from './../components/tipus-matricules-table/tipus-matricules-table.component';
import { TipusMatriculesService } from '../services/tipus-matricules.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { TipusMatriculesFilterComponent } from './../components/tipus-matricules-filter/tipus-matricules-filter.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { TipusMatriculesEditorComponent } from '../components/tipus-matricules-editor/tipus-matricules-editor.component';
import { NotificationService } from 'path-shared/services/notification/notification.service';



@Component({
  selector: 'app-tipus-matricules',
  templateUrl: './tipus-matricules.component.html',
  styleUrls: ['./tipus-matricules.component.scss']
})
export class TipusMatriculesComponent implements OnInit, AfterViewInit {
  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: TipusMatriculaFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(TipusMatriculesTableComponent) tipusMatriculesTable: TipusMatriculesTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private router: Router,
    private tipusMatriculaService: TipusMatriculesService,
    private i18n: I18n,
    private toastr: ToastrService,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<TipusMatriculesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new TipusMatriculaFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.TipusMatricules);
    this.tipusMatriculesTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.tipusMatriculesTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });

  }

  ngAfterViewInit(): void {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Tipus de matrícules'));
    }
  }

  edit() {
    const idSeleccionat: string = this.tipusMatriculaService.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);
    const ref = this.dialog.open(TipusMatriculesEditorComponent, {
      width: '70%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.tipusMatriculesTable.load(); }
    });

  }

  refresh() {
    this.tipusMatriculesTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(TipusMatriculesFilterComponent, {
      width: '70%',
      height: '80%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.tipusMatriculesTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: TipusMatriculaFilter) => {
      this.tipusMatriculesTable.filter.set(filter);
      this.tipusMatriculesTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  viewMatricules() {
    this.router.navigate(['/ciutat/matricules']);
  }

  generateExcel() {
    this.tipusMatriculesTable.generateExcel();
  }

}
