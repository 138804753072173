import { Component, Inject, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { PedefinidesTableComponent } from '../../components/pedefinides-table/pedefinides-table.component';
import { TramitarPredefinidasService } from '../../services/tramitar-predefinidas.service';


@Component({
  selector: 'app-predefinides-page',
  templateUrl: './predefinides-page.component.html',
  styleUrls: ['./predefinides-page.component.scss']
})
export class PredefinidesPageComponent implements OnInit, AfterViewInit {

  @ViewChild('predefinidesTable') predefinidesTable: PedefinidesTableComponent;

  dataDialog: ComponentDialog = Builder.newComponentDialog();
  tramitacionesActuales = 0;

  constructor(
    private predefinidasService: TramitarPredefinidasService,
    public thisDialogRef: MatDialogRef<PredefinidesPageComponent>,
    private not: NotificationService,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog
  ) {
    this.dataDialog = data;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.not.titleChange(this.i18n('No informar conductor'));
  }

  close() {
    this.thisDialogRef.close();
  }

  refresh() {
    this.predefinidesTable.load();
  }

  ejecutarOperacion(somIds: string[]) {
    if (somIds !== undefined && somIds.length > 0) {
      this.tramitacionesActuales = somIds.length;
      somIds.forEach(id => {
        this.predefinidasService.procesarTramitacionMasivaSingle(id).subscribe(result => {
          // Se ha podido tramitar la denuncia.
          this.tramitacionesActuales--;
          this.refreshWhenPossible();
        }, error => {
          // No se ha podido tramitar la denuncia.
          this.tramitacionesActuales--;
          this.refreshWhenPossible();
        });
      });
    }
  }
  ejecutarOperacionMassivo(){
    const ids :string[] = this.predefinidesTable.getSomIDs();
    if(ids !== undefined && ids !== null && ids.length > 0 ){
      this.ejecutarOperacion(ids);
    }
  }
  refreshWhenPossible() {
    if (this.tramitacionesActuales === 0) {
      this.refresh();
    }
  }

}
