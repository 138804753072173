import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { Injectable, EventEmitter } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { api } from 'path-shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { Observable } from 'rxjs';
import { AlarmaGps } from '../models/alarma-gps.model';
import { inherits } from 'util';
import { map } from 'rxjs/operators';
import { AlarmeGpsFiltrat } from '../models/alarmes-gps-filtrat';


const defaultSortParam = 'tipus';
const defaultSortDirection = 'asc';

@Injectable()
export class AlarmesGpsService  {
    displayedItems = [];

    constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
      this.displayedItems['checkedValues'] = 0;
      this.displayedItems['checkedItems'] = Array();
    }

    getAlarmesDay(data: string): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAlarmesGps'].replace('{data}', data), null);
      }
    }

    getLeidoAlarma(id: string): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetLeidoAlarma'].replace('{id}', id), null);
      }
    }

    getAlarmesDayAndType(data: AlarmeGpsFiltrat): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetAlarmesGps'], data);
      }
    }

    getTipusAlarmes(): Observable<any> {

      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTipusAlarmesWithGps'], null);
      }
    }

    setAlarmaVista(data: AlarmaGps) {
      if (this.authGuard.canActivate()) {
        delete data.selected;
        delete data.SECURE_ID;
        data.DataAcus = new Date();
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetAlarmaVista'], data);
      }
    }

    setAlarmaGpsVista(id: string) {
      if (this.authGuard.canActivate()) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetAlarmaVista'] + '/' + id, null);
      }
    }

    setAlarmaEmail(emails: string) {
      if (this.authGuard.canActivate()) {
        return this.http.postJSON(config.urls['apiUrl'] + api.endpoints['urlAlarmaEmail'], JSON.stringify(emails));
      }
    }

    getAlarmaEmail() {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlAlarmaEmail'], null);
      }
    }

}
