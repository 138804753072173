/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./dialog-list.component";
var styles_DialogListComponent = [];
var RenderType_DialogListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogListComponent, data: {} });
export { RenderType_DialogListComponent as RenderType_DialogListComponent };
export function View_DialogListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "actions-bar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "actions-bar__wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "button", [["class", "actions-bar__button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "actions-bar__button-icon has-color-orange"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-edit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "actions-bar__button-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Editar"])), (_l()(), i0.ɵeld(7, 0, null, null, 4, "button", [["class", "actions-bar__button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "actions-bar__button-icon has-color-green"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "i", [["class", "fas fa-save"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "actions-bar__button-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Nou"])), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "div", [["class", "col-md-8"]], null, null, null, null, null))], null, null); }
export function View_DialogListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dialog-list", [], null, null, null, View_DialogListComponent_0, RenderType_DialogListComponent)), i0.ɵdid(1, 114688, null, 0, i1.DialogListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogListComponentNgFactory = i0.ɵccf("app-dialog-list", i1.DialogListComponent, View_DialogListComponent_Host_0, {}, {}, []);
export { DialogListComponentNgFactory as DialogListComponentNgFactory };
