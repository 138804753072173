/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./avisos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i4 from "@angular/common";
import * as i5 from "../component/avisos-table/avisos-table.component.ngfactory";
import * as i6 from "../component/avisos-table/avisos-table.component";
import * as i7 from "../services/avisosConf.service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@ngx-translate/i18n-polyfill";
import * as i10 from "ngx-toastr";
import * as i11 from "../../../../shared/services/authorization/authorization-service";
import * as i12 from "./avisos.component";
import * as i13 from "../../../../shared/services/notification/notification.service";
var styles_AvisosConfComponent = [i0.styles];
var RenderType_AvisosConfComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AvisosConfComponent, data: {} });
export { RenderType_AvisosConfComponent as RenderType_AvisosConfComponent };
function View_AvisosConfComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["label", "Avisos"]], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Avisos"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AvisosConfComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvisosConfComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-avisosconf-table", [], null, null, null, i5.View_AvisosTableComponent_0, i5.RenderType_AvisosTableComponent)), i1.ɵprd(512, null, i6.ChecklistDatabase, i6.ChecklistDatabase, [i7.AvisosConfService]), i1.ɵdid(4, 49152, null, 0, i6.AvisosTableComponent, [i6.ChecklistDatabase, i8.MatDialog, i1.ChangeDetectorRef, i9.I18n, i8.MatDialogRef, i7.AvisosConfService, i10.ToastrService, i11.AuthorizationService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataDialog.isDialog; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AvisosConfComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-avisosconf", [], null, null, null, View_AvisosConfComponent_0, RenderType_AvisosConfComponent)), i1.ɵdid(1, 4308992, null, 0, i12.AvisosConfComponent, [i8.MatDialog, i11.AuthorizationService, i9.I18n, i13.NotificationService, i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AvisosConfComponentNgFactory = i1.ɵccf("app-avisosconf", i12.AvisosConfComponent, View_AvisosConfComponent_Host_0, {}, {}, []);
export { AvisosConfComponentNgFactory as AvisosConfComponentNgFactory };
