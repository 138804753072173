import { CarrersService } from './../../services/carrers-service.service';
import { TipusMatricula } from 'app/sections/ciutat/tipus-matricules/models/tipus-matricula.model';
import { CarrersTableComponent } from './../../components/carrers-table/carrers-table.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { CarrersFilter } from '../../models/carrers-filter';
import { CarrersFilterComponent } from '../../components/carrers-filter/carrers-filter.component';
import { SiglasService } from 'app/sections/configuracio/siglas/services/siglas.service';
import { CalleEditorComponent } from '../../components/calle-editor/calle-editor.component';

@Component({
  selector: 'app-carrers',
  templateUrl: './carrers.component.html',
  styleUrls: ['./carrers.component.scss']
})
export class CarrersComponent implements OnInit, AfterViewInit {
  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: CarrersFilter;
  somId: number;
  sigles = Array();
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(CarrersTableComponent) carrersTable: CarrersTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private siglasService: SiglasService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<CarrersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {

      this.filterData = new CarrersFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.Carrers);
    this.carrersTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.carrersTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });

    this.siglasService.getAll().subscribe(result => {
      this.sigles = [];
      if (result !== null) {
        result.forEach(element => {
          this.sigles.push(element);
        });
      }
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Carrers'));
    }
  }

  edit() {
    const idSeleccionat: string = this.carrersTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {

    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);
    data.tag = this.sigles;
    const ref = this.dialog.open(CalleEditorComponent, {
      width: '60%',
      height: '56vh',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.carrersTable.load(); }
    });
  }

  refresh() {
    this.carrersTable.load();
  }

  onFilterClicked() {
    const data: ComponentDialog = Builder.newComponentDialog();
    const ref = this.dialog.open(CarrersFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.carrersTable.filter,
        'sigles': this.sigles
      }
    });

    ref.componentInstance.notify.subscribe((filter: CarrersFilter) => {
      this.carrersTable.filter.set(filter);
      this.carrersTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }
}
