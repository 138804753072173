<mat-card>
  <mat-card-subtitle title i18n>Fecha último cierre</mat-card-subtitle>
  <mat-card-content>
    <mat-list>
      <mat-list-item class="row">
        <div class="col-2">
          {{this.infoTancaments.MaxDataTancament | date: 'dd/MM/yyyy'}}
        </div>
        <div class="col-2">
          <button class="btn btn-primary col-12" (click)="this.OpenPopUp()">Exp. a cerrar ({{this.infoTancaments.Tancaments}})</button>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
