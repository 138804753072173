var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var TancamentsFilter = /** @class */ (function (_super) {
    __extends(TancamentsFilter, _super);
    function TancamentsFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        var inst = sessionStorage.getItem('currentInst');
        if (inst !== '' && inst !== undefined && inst !== 'undefined') {
            var institution = JSON.parse(inst);
            _this.Id_Instit = institution.IdInstitucion;
        }
        else {
            _this.Id_Instit = '';
        }
        return _this;
    }
    return TancamentsFilter;
}(FilterData));
export { TancamentsFilter };
