import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { ReservesFilterComponent } from 'app/sections/tiquets/reserves/components/reserves-filter/reserves-filter.component';
import { ReservesFilter, TipoVehiculo } from 'app/sections/tiquets/reserves/models/reserves-filter';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs/internal/observable/merge';
import { tap } from 'rxjs/internal/operators/tap';
import { Builder } from 'path-shared/models/builder';
import { Reserves } from '../../models/reserves.model';
import { ReservesService } from '../../services/reserves.service';
import { ReservesFilterService } from '../../services/reserves-filter.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-reserves-motos-table',
  templateUrl: './reserves-motos-table.component.html',
  styleUrls: ['./reserves-motos-table.component.scss']
})
export class ReservesMotosTableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  currentFilter: ReservesFilter = new ReservesFilter('id', 'desc', TipoVehiculo.VehiculoMoto); // Filtro concreto para esta tabla.
  _sharedFilter: ReservesFilter; // Filtro compartido por todas las tablas (mismos parametros de filtrado en todas)
  @Input() get sharedFilter() {
    return this._sharedFilter;
  } set sharedFilter(val) {
    this._sharedFilter = val;
    this.currentFilter.matricula = val.matricula;
    this.currentFilter.email = val.email;
    this.currentFilter.fechaInicio = val.fechaInicio;
    this.currentFilter.tipoVehiculo = TipoVehiculo.VehiculoMoto;
  }
  @Output() currentCount: EventEmitter<number>; // Evento que indica el contador de registros totales en esta tabla.

  dataSource: CustomDataSource<Reserves, ReservesFilter>;

  highlightedRows = [];
displayedColumns = ['tiquet', 'client', 'email', 'matricula', 'persones', 'personesAnulat', 'zona', 'tarifa', 'importTiquet', 'importDevolucio', 'horaCompra', 'horaInici', 'horaFinal', 'btnAnular'];

  //#region INICIALIZACION

  constructor(
    private i18n: I18n,
    private reservasService: ReservesService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService
    ) {
    this.currentCount = new EventEmitter<number>();
  }

  //#endregion

  //#region CICLO DE VIDA

  ngOnInit() {
    this.sort.disableClear = true;

    // Descargamos los registros por primera vez.
    this.dataSource = CustomDataSource.create<Reserves, ReservesFilter>(this.paginator, new ReservesFilterService(), 'urlGetReserves', 'urlGetReservesCount');
    // Nos suscribimos al evento de getContador para detectar cambios y devolver el valor al componente parent.
    this.dataSource.countSubject.subscribe(newValue => {
      this.currentCount.emit(newValue);
    });
  }

  ngAfterViewInit() {
    // Nos suscribimos al evento para detectar cambios en la ordenacion de la tabla.
    this.sort.sortChange.subscribe(() => {
      // Cuando cambia la ordenacion, actualizamos la informacion.
      this.paginator.pageIndex = 0;
      this.load();
    });
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.currentFilter.updated()))
    .subscribe();

    // Cuando la vista se ha cargado, actuallizamos la informacion.
    this.load();
  }

  //#endregion

  //#region EVENTS

  paginatorChanged(value: any) {
    // Actualizamos la informacion al detectar un cambio en la paginacion.
    this.load();
  }

  updateFilterFromParent(val) {
    // Cuando el componente parent nos pasa un nuevo filtro compartido, actualizamos.
    this.sharedFilter = val;
    this.load();
  }

  //#endregion

  //#region OTHERS

  private load() {
    this.currentFilter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.currentFilter);
  }

  anularReserva(row: Reserves) {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la reserva amb número de tiquet ') + row.id + this.i18n('?'))
    .then((confirmed) => {
        if (confirmed) {
          this.reservasService.anularReserva(row.secureId).subscribe(result => {
                this.toastr.success(this.i18n('Reserva anul·lada correctament'));
                this.dialog.closeAll();
                this._sharedFilter.updated(true);
              });
            }
      });
  }

  //#endregion

}
