import { OnInit } from '@angular/core';
import { EventEmitter } from '@angular/core';
var FormRadioGroupComponent = /** @class */ (function () {
    function FormRadioGroupComponent() {
        this.valueChange = new EventEmitter();
        this.val = null;
        this.horizontal = false;
    }
    Object.defineProperty(FormRadioGroupComponent.prototype, "value", {
        get: function () { return this.val; },
        set: function (val) {
            if (val !== this.val) {
                this.val = val;
                this.valueChange.emit(this.val);
            }
        },
        enumerable: true,
        configurable: true
    });
    FormRadioGroupComponent.prototype.ngOnInit = function () {
    };
    return FormRadioGroupComponent;
}());
export { FormRadioGroupComponent };
