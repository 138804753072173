var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var BonosFilter = /** @class */ (function (_super) {
    __extends(BonosFilter, _super);
    function BonosFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.matricula = '';
        _this.fechaInicio = null;
        _this.fechaFin = null;
        _this.tipo = '';
        _this.labels['matricula'] = _this.i18n('Matrícula');
        _this.labels['fechaInicio'] = _this.i18n('Fecha Inicio');
        _this.labels['fechaFin'] = _this.i18n('Fecha fin');
        _this.labels['tipo'] = _this.i18n('Tipo');
        _this.formatOutput['fechaInicio'] = 'shortDate';
        _this.formatOutput['fechaFin'] = 'shortDate';
        return _this;
    }
    return BonosFilter;
}(FilterData));
export { BonosFilter };
