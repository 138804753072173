<app-toolbar>
  <app-tool-button text="Consultar" i18n-text (click)="consultar();" color="has-color-blue" icon="fa-search"></app-tool-button>
  <app-tool-button text="Exportar" i18n-text (click)="exportar();" color="has-color-green" icon="fa-file-export"></app-tool-button>
  <!-- <app-tool-button-permissions [permission]="this.auth.Pages.Aparells"></app-tool-button-permissions> -->
</app-toolbar>

<h5 i18n>Filtro</h5>
<div class="row" style="margin-left:2vh">  
  <div class="col-4 card">
    <div class="row">
      <div class="col">
        <div >
          <app-form-date-edit id="incidencias-c60-desde" i18n-label label="Desde"  [(value)]="this.requestConsulta.desde"></app-form-date-edit>  
        </div>
        <div >
          <app-form-date-edit id="incidencias-c60-hasta" i18n-label label="Hasta"  [(value)]="this.requestConsulta.hasta"></app-form-date-edit>  
        </div>    
      </div>
      <div class="col" style="margin-left: 2vh;border-left-style: outset;">
        <mat-radio-group 
          [(ngModel)]="this.tipusTramitatSelected">
          <div>
            <mat-radio-button [value]="1" style="margin-top: 2vh;">
              <label i18n>Tramitados</label>
            </mat-radio-button>
          </div>
          <div >
            <mat-radio-button [value]="2">
              <label i18n>No Tramitados (Error)</label>
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
    </div>    
  </div>     
</div>
  
<div class="row">
    <app-incidencies-bancs-table style="width: 98%;" [(value)]="this.dataSource"></app-incidencies-bancs-table>
</div>
