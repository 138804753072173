/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./texto-aviso-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i3 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i4 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i5 from "../../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i6 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i7 from "../../../../../shared/components/form/combo-box-input/combo-box-input.component.ngfactory";
import * as i8 from "../../../../../shared/components/form/combo-box-input/combo-box-input.component";
import * as i9 from "@angular/forms";
import * as i10 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i11 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i12 from "../../../../../shared/services/validation/validation.service";
import * as i13 from "../../../../../shared/components/form/form-check-edit/form-check-edit.component.ngfactory";
import * as i14 from "../../../../../shared/components/form/form-check-edit/form-check-edit.component";
import * as i15 from "../../../../../shared/components/html-editor/components/html-editor/html-editor.component.ngfactory";
import * as i16 from "../../../../../shared/components/html-editor/components/html-editor/html-editor.component";
import * as i17 from "./texto-aviso-editor.component";
var styles_TextoAvisoEditorComponent = [i0.styles];
var RenderType_TextoAvisoEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextoAvisoEditorComponent, data: {} });
export { RenderType_TextoAvisoEditorComponent as RenderType_TextoAvisoEditorComponent };
export function View_TextoAvisoEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "editorForm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "col-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 3, "app-form-date-edit", [["class", "col-12"], ["id", "texto-aviso-editor-dataIni"], ["label", "Data Inici"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.currentTexto.fechaInicio = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormDateEditComponent_0, i2.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i3.DateTimeAdapter, i4.MomentDateTimeAdapter, [i3.OWL_DATE_TIME_LOCALE]), i1.ɵdid(6, 114688, null, 0, i5.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"], id: [3, "id"], isReadOnly: [4, "isReadOnly"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i6.OWL_DATE_TIME_FORMATS, i5.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 3, "app-form-date-edit", [["class", "col-12"], ["id", "texto-aviso-editor-dataFi"], ["label", "Data Fi"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.currentTexto.fechaFin = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormDateEditComponent_0, i2.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i3.DateTimeAdapter, i4.MomentDateTimeAdapter, [i3.OWL_DATE_TIME_LOCALE]), i1.ɵdid(11, 114688, null, 0, i5.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"], id: [3, "id"], isReadOnly: [4, "isReadOnly"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i6.OWL_DATE_TIME_FORMATS, i5.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(13, 0, null, null, 10, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-combo-box-input", [], null, [[null, "optionChanged"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("optionChanged" === en)) {
        var pd_0 = (_co.siglaSeleccionada($event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.currentTexto.idioma = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_ComboBoxInputComponent_0, i7.RenderType_ComboBoxInputComponent)), i1.ɵdid(17, 114688, [["comboSiglas", 4]], 0, i8.ComboBoxInputComponent, [i9.FormBuilder], { label: [0, "label"], value: [1, "value"], showingOptions: [2, "showingOptions"], disabled: [3, "disabled"] }, { optionChanged: "optionChanged" }), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "col-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-form-text-edit", [["id", "texto-aviso-editor-titulo"], ["label", "T\u00EDtol"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.currentTexto.titulo = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_FormTextEditComponent_0, i10.RenderType_FormTextEditComponent)), i1.ɵdid(20, 4308992, null, 0, i11.FormTextEditComponent, [i12.ValidationService], { label: [0, "label"], value: [1, "value"], isReadOnly: [2, "isReadOnly"], id: [3, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "app-form-check-edit", [["class", "col-xl-6"], ["label", "Bloqueja Log In"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.changeLogInBlock($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_FormCheckEditComponent_0, i13.RenderType_FormCheckEditComponent)), i1.ɵdid(23, 114688, null, 0, i14.FormCheckEditComponent, [i12.ValidationService], { label: [0, "label"], value: [1, "value"], disabled: [2, "disabled"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(24, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "app-html-editor", [["class", "col-12"]], null, [[null, "textoFormatadoChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textoFormatadoChanged" === en)) {
        var pd_0 = (_co.textoFormatadoChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_HtmlEditorComponent_0, i15.RenderType_HtmlEditorComponent)), i1.ɵdid(26, 114688, null, 0, i16.HtmlEditorComponent, [], { isReadOnly: [0, "isReadOnly"], textoFormatado: [1, "textoFormatado"] }, { textoFormatadoChanged: "textoFormatadoChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Data Inici"; var currVal_1 = _co.currentTexto.fechaInicio; var currVal_2 = "col-12"; var currVal_3 = "texto-aviso-editor-dataIni"; var currVal_4 = !_co.currentEditing; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "Data Fi"; var currVal_6 = _co.currentTexto.fechaFin; var currVal_7 = "col-12"; var currVal_8 = "texto-aviso-editor-dataFi"; var currVal_9 = !_co.currentEditing; _ck(_v, 11, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = "Idioma"; var currVal_11 = _co.currentTexto.idioma; var currVal_12 = _co.idiomaDictionary; var currVal_13 = !_co.currentEditing; _ck(_v, 17, 0, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = "T\u00EDtol"; var currVal_15 = _co.currentTexto.titulo; var currVal_16 = !_co.currentEditing; var currVal_17 = "texto-aviso-editor-titulo"; _ck(_v, 20, 0, currVal_14, currVal_15, currVal_16, currVal_17); var currVal_18 = "Bloqueja Log In"; var currVal_19 = ((_co.currentTexto !== undefined) ? !_co.currentTexto.permiteLogIn : false); var currVal_20 = !_co.currentEditing; _ck(_v, 23, 0, currVal_18, currVal_19, currVal_20); var currVal_21 = !_co.currentEditing; var currVal_22 = _co.currentTexto.htmlValue; _ck(_v, 26, 0, currVal_21, currVal_22); }, null); }
export function View_TextoAvisoEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-texto-aviso-editor", [], null, null, null, View_TextoAvisoEditorComponent_0, RenderType_TextoAvisoEditorComponent)), i1.ɵdid(1, 114688, null, 0, i17.TextoAvisoEditorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextoAvisoEditorComponentNgFactory = i1.ɵccf("app-texto-aviso-editor", i17.TextoAvisoEditorComponent, View_TextoAvisoEditorComponent_Host_0, { currentEditing: "currentEditing", currentTexto: "currentTexto", idiomaDictionary: "idiomaDictionary" }, {}, []);
export { TextoAvisoEditorComponentNgFactory as TextoAvisoEditorComponentNgFactory };
