import { CustomMapMarker } from '../../../../shared/models/custom-map-marker';
import { OnInit, EventEmitter } from '@angular/core';
import { Dictionary } from 'path-shared/models/dictionary.js';
import { CustomMapPoligon } from 'path-shared/models/custom-map-poligon';
var MapComponent = /** @class */ (function () {
    function MapComponent() {
        this.styleMap = [
            {
                "featureType": "administrative",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            }
        ];
        var inst = sessionStorage.getItem('currentInst');
        if (inst !== '' && inst !== undefined && inst !== 'undefined') {
            // No tenemos ningun punto, intentamos definir como centro el municipio actual.
            var institution = JSON.parse(inst);
            if (institution.Latitud !== undefined && institution.Longitud !== undefined) {
                this.currentLocation = { latitude: institution.Latitud, longitude: institution.Longitud };
            }
        }
        this.permanentShow = false;
        this.markers = new Array();
        this.poligons = new Array();
        this.zoom = 0;
        this.markerPositionUpdate = new EventEmitter(undefined);
        this.markerRouteEvent = new EventEmitter(undefined);
    }
    Object.defineProperty(MapComponent.prototype, "overridedLocation", {
        get: function () {
            return this._overridedLocation;
        },
        set: function (val) {
            this._overridedLocation = val;
            if (this._overridedLocation !== undefined) {
                this.currentLocation = this._overridedLocation;
            }
        },
        enumerable: true,
        configurable: true
    });
    MapComponent.prototype.ngOnInit = function () {
    };
    MapComponent.prototype.mapReady = function (map) {
        this.map = map;
    };
    MapComponent.prototype.appendNewPoligons = function (dataPoligons) {
        var _this = this;
        dataPoligons.forEach(function (dataPoligon) {
            var createdPoligon = _this.createCustomPoligon(dataPoligon);
            _this.poligons.push(new CustomMapPoligon(dataPoligon.associatedId, createdPoligon));
        });
    };
    MapComponent.prototype.removePolygonsOfType = function (polygonTypeId) {
        var _this = this;
        // Obtenemos los poligonos a eliminar.
        var polygonsToRemove = this.poligons.filter(function (p) { return p.key === polygonTypeId; });
        // Definimos el mapa a null para estos poligonos.
        polygonsToRemove.forEach(function (p) {
            p.poligon.setMap(null);
            // Tambien buscamos cual es su indice en la lista original y lo eliminamos de esta.
            var index = _this.poligons.indexOf(p);
            if (index > -1) {
                _this.poligons.splice(index, 1);
            }
        });
    };
    MapComponent.prototype.appendNewMarkers = function (dataMarkers) {
        var _this = this;
        dataMarkers.forEach(function (dataMarker) {
            var equalMarker = _this.markers.find(function (m) {
                return m.sortable1 === dataMarker.internalId &&
                    m.sortable2 === dataMarker.associatedResourceId &&
                    m.marker.LatLng === m.marker.LatLng;
            });
            if (equalMarker === undefined) {
                var createdMarker = _this.createCustomMarker(dataMarker);
                var marker = createdMarker.createdMarked;
                var infoWindow = createdMarker.associatedPopup;
                _this.markers.push(new CustomMapMarker(dataMarker.internalId, dataMarker.associatedResourceId, marker, infoWindow));
            }
        });
    };
    MapComponent.prototype.removeMarkersOfType = function (markerTypeId) {
        var _this = this;
        // Obtenemos los markers a eliminar.
        var markersToRemove = this.markers.filter(function (m) { return m.sortable1 === markerTypeId; });
        // Los usamos y actualizamos el mapa a null para eliminarlos del mapa actual.
        markersToRemove.forEach(function (m) {
            m.marker.setMap(null);
            // A la vez buscamos cual es su indice en la lista original y lo eliminamos de esta.
            var index = _this.markers.indexOf(m);
            if (index > -1) {
                _this.markers.splice(index, 1);
            }
        });
    };
    MapComponent.prototype.removeMarkersOfIds = function (markersIds) {
        var _this = this;
        markersIds.forEach(function (mId) {
            var index = _this.markers.findIndex(function (mR) { return mR.sortable2 === mId; });
            if (index !== -1) {
                // Debemos eliminar el marker.
                _this.markers[index].marker.setMap(null);
                _this.markers.splice(index, 1);
            }
        });
    };
    MapComponent.prototype.createCustomPoligon = function (dataPoligon) {
        // Creamos el poligono.
        var poligon = new google.maps.Polygon({
            paths: dataPoligon.area,
            strokeColor: dataPoligon.color,
            strokeOpacity: dataPoligon.alpha,
            strokeWeight: 1,
            fillColor: dataPoligon.color,
            fillOpacity: dataPoligon.alpha
        });
        poligon.setMap(this.map);
        return poligon;
    };
    MapComponent.prototype.createCustomMarker = function (dataMarker) {
        // Creamos el marker.
        var marker = new google.maps.Marker({
            map: this.map,
            label: {
                fontFamily: "'Font Awesome 5 Free'",
                text: dataMarker.innerIcon,
                color: dataMarker.iconFillColor,
                transition: 'all .25s',
                fontWeight: '900',
                fontSize: 'x-large'
            },
            icon: ' ',
            /*icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 17,
              strokeColor: 'white',
              strokeWeight: 1,
              fillColor: dataMarker.iconFillColor,
              fillOpacity: dataMarker.iconFillOpacity
            },*/
            clickable: dataMarker.showHover ? dataMarker.showHover : false,
            draggable: dataMarker.dragable,
            animation: dataMarker.animable ? google.maps.Animation.DROP : '',
            position: new google.maps.LatLng(dataMarker.latitude, dataMarker.longitude),
        });
        // Definimos el evento dragEnd del marker.
        var updatesEventEmitter = this.markerPositionUpdate;
        if (dataMarker.dragable) {
            google.maps.event.addListener(marker, 'dragend', function (event) {
                var latitude = event.latLng.lat();
                var longitude = event.latLng.lng();
                if (updatesEventEmitter !== undefined) {
                    updatesEventEmitter.emit(new Dictionary(latitude, longitude));
                }
            });
        }
        // Creamos el hover (InfoWindow) y lo asignamos al mapa y al marker.
        var infoWindow;
        if (dataMarker.showHover) {
            infoWindow = this.crearInfoWindow(dataMarker.text, marker, dataMarker.internalId, dataMarker.associatedResourceId);
        }
        return { createdMarked: marker, associatedPopup: infoWindow };
    };
    MapComponent.prototype.showHoversPermanentlly = function () {
        var _this = this;
        // Ahora seguro que los markers tienen un infoWindow asociado. Los abrimos todos.
        this.permanentShow = true;
        this.markers.forEach(function (m) {
            m.infoWindow.open(_this.map, m.marker);
        });
    };
    MapComponent.prototype.hideHoversPermanently = function () {
        // Cerramos los infoWindows i los eliminamos de la lista actual.
        this.permanentShow = false;
        this.markers.forEach(function (m) {
            m.infoWindow.close();
        });
    };
    MapComponent.prototype.crearInfoWindow = function (content, marker, mapFeature, resourceId) {
        var noScrollContent = '<div style="overflow: hidden; white-space: nowrap;">' + content + '</div>';
        var infoWindow = new google.maps.InfoWindow({
            content: noScrollContent
        });
        infoWindow.setOptions({ disableAutoPan: true, maxWidth: 700 });
        // Anadimos el evento que abre el popover (si es que la opcion @showHover lo indica).
        marker.addListener('mouseover', function () {
            infoWindow.open(this.map, marker);
        });
        marker.addListener('mouseout', function () {
            infoWindow.close();
        });
        var markerNavigateEvent = this.markerRouteEvent;
        // Anadimos el evento que nos abre el mantenimiento/listado relacionado.
        marker.addListener('click', function () {
            markerNavigateEvent.emit({ mapFeature: mapFeature, resourceId: resourceId });
        });
        // Abrimos el popup directamente si se da el caso.
        if (this.permanentShow) {
            infoWindow.open(this.map, marker);
        }
        return infoWindow;
    };
    return MapComponent;
}());
export { MapComponent };
