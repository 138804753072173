import { EventEmitter, OnInit } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
var HtmlEditorComponent = /** @class */ (function () {
    function HtmlEditorComponent() {
        this.textoFormatadoChanged = new EventEmitter();
        if (this.placeHolderText === undefined) {
            this.placeHolderText = '';
        }
        this.isReadOnly = true;
    }
    Object.defineProperty(HtmlEditorComponent.prototype, "isReadOnly", {
        get: function () {
            return this._isReadOnly;
        },
        set: function (val) {
            this._isReadOnly = val;
        },
        enumerable: true,
        configurable: true
    });
    HtmlEditorComponent.prototype.ngOnInit = function () { };
    HtmlEditorComponent.prototype.contentChanged = function (newTextValue) {
        this.textoFormatado = newTextValue.html;
        this.textoFormatadoChanged.emit(this.textoFormatado);
    };
    return HtmlEditorComponent;
}());
export { HtmlEditorComponent };
