import { CustomDataPoligon } from 'app/sections/livemap/models/custom-poligon';

export class CustomMapPoligon {

    key: string;
    poligon: any;

    constructor(key: string, poligon: any) {
        this.key = key;
        this.poligon = poligon;
    }

}
