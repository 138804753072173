<app-dialog-header [label]="displayTitle()" i18n-label iconClass="has-color-orange"></app-dialog-header>

<app-toolbar>
  <app-tool-button text="Enviar" i18n-text (click)="this.save()" color="has-color-black" icon="fa-save"></app-tool-button>
</app-toolbar>

<div style="display: flex; flex-wrap: nowrap; width: 100%; white-space: nowrap; overflow-x: scroll">
  <mat-chip-list>
    <mat-chip *ngFor="let acus of this.acusos" matChipRemove (removed)="removeChip(acus)">
      {{acus.expedient}}
      <mat-icon>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</div>

<app-form-date-edit class="col-sm-3" i18n-label label="Data" [(value)]="this.date" type="calendar"></app-form-date-edit>