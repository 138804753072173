<div class="row justify-content-between">
  <div class="col">
      <app-filter-selector [filter]="this.filter" exclude="['tipoFk']"></app-filter-selector>
  </div>
  <div class="col">
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>
  </div>
</div>


<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

       <!--Codi-->
       <ng-container matColumnDef="codi">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.id}}</a></mat-cell>
      </ng-container>

      <!--Categoria-->
      <ng-container matColumnDef="nombre">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.nombre}}</mat-cell>
      </ng-container>       

      <!--Nom Complert-->
      <ng-container matColumnDef="tipus">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipus</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.tipoDescripcion}}</mat-cell>
      </ng-container>

      <!--DNI-->
      <ng-container matColumnDef="observacions">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Observacions</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.observaciones}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>