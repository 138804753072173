import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActuacioMatricula } from '../../models/actuacioMatricula.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatPaginator, MatSort } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { TipusActuacio } from 'app/sections/agents/actuacions-agents/models/TipusActuacio.model';
import { ThrowStmt } from '@angular/compiler';
import { Instituciones } from 'path-shared/models/instituciones';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { ActuacioMatriculaFilter } from '../../models/actuacioMatricula-filter';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActuacioMatriculaFilterService } from '../../services/ActuacioMatricula-filter.service';

@Component({
  selector: 'app-actuacions-mapa-component',
  templateUrl: './actuacions-mapa-component.component.html',
  styleUrls: ['./actuacions-mapa-component.component.scss']
})
export class ActuacionsMapaComponentComponent implements OnInit {
  datainici: Date;
  datafi: Date;
  filter: ActuacioMatriculaFilter = new ActuacioMatriculaFilter('matricula', 'asc');
  zoomMapa: number;
  markersArray:  Array<Marker>;
  map: any;
  displayedItems: ActuacioMatricula[] = [];
  somId: number;
  matricula: string;
  latitud: number;
  longitud: number;
  tipuActuacioIcon: TipusActuacio;
  fecha: Date;
  dataSource: CustomDataSource<ActuacioMatricula, ActuacioMatriculaFilter>;
  displayedColumns: string[] = ['MAPA','SOM_ID', 'matricula','Latitud','Longitud','Data','Tipus_Actuacio'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  public styleMap = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];

  constructor(
    public thisDialogRef: MatDialogRef<ActuacionsMapaComponentComponent>,
    private dialog: MatDialog,
    private filterService: FilterService,    
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    this.filter.datainici = data.datainici;
    this.filter.datafi = data.datafi;
    this.filter.matricula = data.matricula;
    this.zoomMapa = 8;

    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      const institution: Instituciones = JSON.parse(inst);
      this.currentLocation = {latitude: institution.Latitud, longitude: institution.Longitud};
    }
    
    this.zoomMapa = 8;
  }


  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<ActuacioMatricula, ActuacioMatriculaFilter>(this.paginator, new ActuacioMatriculaFilterService(), 'urlGetActuacionsMatriculas', 'urlGetActuacionsMatriculasCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach((item, index) => {
        this.displayedItems[index] = {
          MATRICULA: item.MATRICULA,
          SOM_ID: item.SOM_ID,
          LATITUD: item.LATITUD,
          LONGITUD: item.LONGITUD,
          DATA: item.DATA,
          TIPUS_ACTUACIO: item.TIPUS_ACTUACIO,
          ID_INSTIT: item.ID_INSTIT
        };
      });
    });
    
    
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        this.filterService.saveFilter('matriculas-filter', this.filter);
      }
    });

    this.filterService.loadFilter('matriculas-filter', this.filter);
    this.filter.paginator.init(this.sort, this.paginator);

    this.load();

  }
  
  public currentLocation: {
    latitude: number,
    longitude: number
  };
  
  
  

toggleAllCheckbox($event) {
  this.displayedItems.filter(x => x.MATRICULA != null).forEach(item => 
    this.loadMarker(item.LATITUD, item.LONGITUD, item.SOM_ID, item.TIPUS_ACTUACIO),
    this
  )
}

  mapReady(map: any) {
    this.markersArray = [];
    this.map = map;
    this.loadMarker(this.latitud,this.longitud,this.somId,this.tipuActuacioIcon)
    // this.loadMarkers();
  }
  loadMarker(LAT, LON, codi, tipusActuacio) {
    if (LAT != null && LON != null) {
      const marker: Marker = {
        lat: LAT,
        lng: LON,
        id: codi,
        label: codi.toString(),
        iconPath: tipusActuacio.ICON_ROUTE
      };
      if (this.markersArray.find(x => x.id == marker.id) !== undefined) {
        this.markersArray = this.markersArray.filter(x => x.id != codi)
      }
      else {
        this.markersArray.push(marker);
      }
      
      var bounds = new google.maps.LatLngBounds();
      this.markersArray.forEach(customMarker => {
        bounds.extend(new google.maps.LatLng(customMarker.lat, customMarker.lng));
      })
      this.map.fitBounds(bounds)
    }
  }


  
  // private initDataSource() {
  //   this.sort.disableClear = true;
  //   this.dataSource.subject.subscribe((data) => {
  //     data.forEach((item, index) => {
  //       this.displayedItems[index] = {
  //         MATRICULA: item.MATRICULA,
  //         SOM_ID: item.SOM_ID,
  //         LATITUD: item.LATITUD,
  //         LONGITUD: item.LONGITUD,
  //         DATA: item.DATA,
  //         TIPUS_ACTUACIO: item.TIPUS_ACTUACIO,
  //         ID_INSTIT: item.ID_INSTIT
  //       };
  //     });
  //   });
  //   this.filter.filterUpdated.subscribe(updated => {
  //     if (updated) {
  //       this.load();
  //       this.filterService.saveFilter('actuacions-filter', this.filter);
  //     }
  //   });
  
    // this.filterService.loadFilter('actuacions-filter', this.filter);
  //   this.filter.paginator.init(this.sort, this.paginator);
  // }
  
  
  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
  
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
      
    });
  
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });
  
    this.paginator.page.subscribe(data => {
      this.paginator.pageIndex = data.pageIndex;
      this.paginator.pageSize = data.pageSize;
      console.log('paginator size: ' + data.pageSize);
    });
  }
  
  load() {
    if (this.dataSource !== undefined) 
    {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }
  }
  public getMarkerIconColor(codi:any) {
    if (this.markersArray.find(customMarker => customMarker.id == codi)) {
      return "blue";
    }
    else {
      return "gray";
    }
  }
}

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  id?:string;
  iconPath?: string;
}