<app-dialog-header label="Temporada" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Temporada) ||!this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Temporada)||this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Temporada) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <app-form-text-edit class="col-sm-6" i18n-label label="Codi" [(value)]="this.temporada.Som_id" [isReadOnly]="true"></app-form-text-edit>
          <app-form-text-edit class="col-sm-6" i18n-label label="Descripció" [(value)]="this.temporada.descripcion" required maxLength="300"></app-form-text-edit>
        </div>
      <div class="row">
        <app-form-multi-select class="col-xl-12" titulo="Zones" [data]="zones" [(selectedValues)]="this.temporada.zones" i18n-titulo tituloMultiSelect="Selecciona una o més" i18n-tituloMultiSelect ></app-form-multi-select>
      </div>
        <div class="row">
          <app-form-check-edit class="col-xl-6" i18n-label label="Repetició periòdica" [(value)]="this.temporada.REPITICION_PERIODICA" [disabled]="this.dialogData.isReadOnly"></app-form-check-edit>
          <app-form-check-edit class="col-xl-6" i18n-label label="Activa" [(value)]="this.temporada.ACTIVO" [disabled]="this.dialogData.isReadOnly"></app-form-check-edit>
        </div>
        <div class="row">
           <app-form-text-edit class="col-sm-3" i18n-label label="Dia inici" [(value)]="this.diaInicio"  [required]="this.temporada.REPITICION_PERIODICA" [type]="'number'"
            [isReadOnly]="this.dialogData.isReadOnly || (!this.dialogData.isReadOnly && !this.temporada.REPITICION_PERIODICA)"  maxLength="2" maxNumberValue="31"></app-form-text-edit>
            <app-form-text-edit class="col-sm-3" i18n-label label="Mes inici" [(value)]="this.mesInicio" [required]="this.temporada.REPITICION_PERIODICA" [type]="'number'"
            [isReadOnly]="this.dialogData.isReadOnly || (!this.dialogData.isReadOnly && !this.temporada.REPITICION_PERIODICA)"  maxLength="2"  maxNumberValue="12"></app-form-text-edit>
            <app-form-text-edit class="col-sm-3" i18n-label label="Dia fi" [(value)]="this.diaFin" [required]="this.temporada.REPITICION_PERIODICA" [type]="'number'"
            [isReadOnly]="this.dialogData.isReadOnly || (!this.dialogData.isReadOnly && !this.temporada.REPITICION_PERIODICA)"  maxLength="2"  maxNumberValue="31"></app-form-text-edit>
            <app-form-text-edit class="col-sm-3" i18n-label label="Mes fi" [(value)]="this.mesFin" [required]="this.temporada.REPITICION_PERIODICA" [type]="'number'"
             [isReadOnly]="this.dialogData.isReadOnly || (!this.dialogData.isReadOnly && !this.temporada.REPITICION_PERIODICA)" maxLength="2"  maxNumberValue="12"></app-form-text-edit>
        </div>

        <div class="row">
          <app-form-date-edit class="col-sm-3" i18n-label label="Data inici" [(value)]="this.fechaInicio" type="calendar" 
            [isReadOnly]="this.dialogData.isReadOnly || (!this.dialogData.isReadOnly && this.temporada.REPITICION_PERIODICA )" [required]="!this.temporada.REPITICION_PERIODICA"></app-form-date-edit>
            <app-form-date-edit class="col-sm-3" i18n-label label="Data fi" [(value)]="this.fechaFin"  type="calendar" 
            [isReadOnly]="this.dialogData.isReadOnly || (!this.dialogData.isReadOnly && this.temporada.REPITICION_PERIODICA )" [required]="!this.temporada.REPITICION_PERIODICA"></app-form-date-edit>
        </div>
      </div>    
    </div>
    
  </form>
</app-dialog-body>
