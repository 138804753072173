import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor(private i18n: I18n, private toastr: ToastrService) {}

  noRecordsFound(noRecordsFoundText?: string) {
    if (noRecordsFoundText !== undefined && noRecordsFoundText !== null) {
      this.toastr.warning(noRecordsFoundText);
    } else {
      this.toastr.warning(this.i18n('No s\'han trobat registres'));
    }
  }

  get Accept() {
    return this.i18n('Acceptar');
  }

  get Cancel() {
    return this.i18n('Cancel·lar');
  }

  get CancelGeneration() {
    return this.i18n('Cancel·lar generació');
  }

  get GeneratingExcel() {
    return this.i18n('Generant Excel');
  }

  get generatingExcelMessage() {
    return this.i18n('S\'està generant l\'arxiu excel. Aquesta operació pot tardar una moments. Si us plau, esperi.');
  }

}
