/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../component/aparcare.component.ngfactory";
import * as i2 from "../component/aparcare.component";
import * as i3 from "./aparcare-page.component";
import * as i4 from "../../../shared/services/authorization/authorization-service";
var styles_AparcarePageComponent = [];
var RenderType_AparcarePageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AparcarePageComponent, data: {} });
export { RenderType_AparcarePageComponent as RenderType_AparcarePageComponent };
export function View_AparcarePageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-aparcare", [], null, null, null, i1.View_AparcareComponent_0, i1.RenderType_AparcareComponent)), i0.ɵdid(1, 114688, null, 0, i2.AparcareComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AparcarePageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-aparcare-page", [], null, null, null, View_AparcarePageComponent_0, RenderType_AparcarePageComponent)), i0.ɵdid(1, 114688, null, 0, i3.AparcarePageComponent, [i4.AuthorizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AparcarePageComponentNgFactory = i0.ɵccf("app-aparcare-page", i3.AparcarePageComponent, View_AparcarePageComponent_Host_0, {}, {}, []);
export { AparcarePageComponentNgFactory as AparcarePageComponentNgFactory };
