import { Component, OnInit, EventEmitter, Output, Inject, ViewChild, ElementRef } from '@angular/core';
import { ZoneFilter } from '../../models/zones-filtar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'app-zones-filter',
  templateUrl: './zones-filter.component.html',
  styles: []
})
export class ZonesFilterComponent implements OnInit {

  filter: ZoneFilter;
  tipusMatricules = [];

  @Output() notify: EventEmitter<ZoneFilter> = new EventEmitter<ZoneFilter>();
  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    public thisDialogRef: MatDialogRef<ZonesFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    // this.tipusMatricules = [];
    // this.tipusMatricules.push({'codi': '', 'descripcio': ''});
       this.filter = data.filter;
    // data.tipusMatricules.forEach(function(value, key) {
    //   this.push({'codi': key, 'descripcio': value});
    // }, this.tipusMatricules);
  }

  ngOnInit() {
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }
}
