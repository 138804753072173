var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var EnviosPagedRequestDto = /** @class */ (function (_super) {
    __extends(EnviosPagedRequestDto, _super);
    function EnviosPagedRequestDto(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.idEnvio = null;
        _this.tipoEnvio = '';
        _this.fechaCreacionInicio = null;
        _this.fechaCreacionFin = null;
        _this.usuarioCreacion = '';
        _this.fechaEnvioInicio = null;
        _this.fechaEnvioFin = null;
        _this.usuarioEnvio = '';
        _this.pdfBOE = '';
        _this.fechaNotificacionBOEInicio = null;
        _this.fechaNotificacionBOEFin = null;
        _this.numeroPublicacionBOE = '';
        _this.labels['idEnvio'] = _this.i18n('ID Envio');
        _this.labels['tipoEnvio'] = _this.i18n('Tipo envio');
        _this.labels['fechaCreacionInicio'] = _this.i18n('Fecha creación inicio');
        _this.labels['fechaCreacionFin'] = _this.i18n('Fecha creación fin');
        _this.labels['usuarioCreacion'] = _this.i18n('Usuario creación');
        _this.labels['fechaEnvioInicio'] = _this.i18n('Fecha envio incio');
        _this.labels['fechaEnvioFin'] = _this.i18n('Fecha envio fin');
        _this.labels['usuarioEnvio'] = _this.i18n('Usuario envio');
        _this.labels['pdfBOE'] = _this.i18n('PDF BOE');
        _this.labels['fechaNotificacionBOEInicio'] = _this.i18n('Fecha notificación BOE inicio');
        _this.labels['fechaNotificacionBOEFin'] = _this.i18n('Fecha notificación BOE fin');
        _this.labels['numeroPublicacionBOE'] = _this.i18n('Número publicación BOE');
        _this.formatOutput['fechaCreacion'] = 'shortDate';
        _this.formatOutput['fechaEnvio'] = 'shortDate';
        return _this;
    }
    return EnviosPagedRequestDto;
}(FilterData));
export { EnviosPagedRequestDto };
