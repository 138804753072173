import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TravelMode2LabelMapping, TravelModeEnum } from '../models/travel-mode';
import { GoogleMapsService } from 'path-shared/services/startup/google-maps.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Instituciones } from 'path-shared/models/instituciones';
import { MapService } from 'app/sections/livemap/services/map.service';
import { Body } from '@angular/http/src/body';
import { AgmMap, AgmPolyline } from '@agm/core';
import { ZbRuta, marker } from '../models/zb-ruta';
import { ZbRutasService } from '../services/zb-rutas.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-zb-ruta-editor',
  templateUrl: './zb-ruta-editor.component.html',
  styleUrls: ['./zb-ruta-editor.component.scss']
})
export class ZbRutaEditorComponent implements OnInit {

  public TravelMode2LabelMapping = TravelMode2LabelMapping;

  public travelModes = Object.values(TravelModeEnum);
  RutaForm: FormGroup;
  ruta: any;//TODO: definir modelo
  public zoomMapa: number;

  public currentLocation: {
    latitude: number,
    longitude: number
  };
  @ViewChild(AgmMap) map: any;

  onMapReady(map) {
    this.map = map;
  }
  polyline: AgmPolyline;

  public markers: marker[];

  constructor(
    mapsService: GoogleMapsService,
    private toastr: ToastrService,
    private i18n: I18n,
    private zbRutasService: ZbRutasService,
    @Inject(MAT_DIALOG_DATA) public data: ZbRuta,
    public thisDialogRef: MatDialogRef<ZbRutaEditorComponent>,
  ) {

    this.zoomMapa = 16;
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      // No tenemos ningun punto, intentamos definir como centro el municipio actual.
      const institution: Instituciones = JSON.parse(inst);
      if (institution.Latitud !== undefined && institution.Longitud !== undefined) {
        this.currentLocation = { latitude: institution.Latitud, longitude: institution.Longitud };
      }
    }
  }

  ngOnInit() {
    if(this.data!==null && this.data!==undefined && this.data.SomId!==undefined && this.data.SomId!==null){
      this.createForm(this.data.Nombre,this.data.Descripcion);
      debugger;

     this.markers=this.data.Puntos.map(p => {
        return {
          label: p.split(':')[0],
          lat: parseFloat(p.split(':')[1].split(';')[0].replace(',','.')),
          lng: parseFloat(p.split(':')[1].split(';')[1].replace(',','.')),
          draggable: true
        };});
      debugger;
    }
    else{
    this.markers = [{
      lat: this.currentLocation.latitude,
      lng: this.currentLocation.longitude,
      label: 'A',
      draggable: true
    },
    {
      //sumamos aprox 50 metros a la latitud
      lat: this.currentLocation.latitude + (50 / 111320.0),
      lng: this.currentLocation.longitude,
      label: 'B',
      draggable: true
    }];
      this.createForm('','');

    }
  }

  createForm(name:string, desc:string): void {
    this.RutaForm = new FormGroup({
      Nombre: new FormControl(name, Validators.required),
      Descripcion: new FormControl(desc, Validators.required),
    });
  }
  addTramo() {
    debugger;
    this.markers.sort((a, b) => (a.label > b.label ? 1 : (b.label > a.label) ? -1 : 0));
    const lastMarker= this.markers[this.markers.length - 1];

    this.markers.push({
      lat: lastMarker.lat + (50 / 111320.0),
      lng: lastMarker.lng + (50 / 111320.0),
      label: String.fromCharCode(lastMarker.label.charCodeAt(0) + 1),
      draggable: true
    });

  }
  removeTramo() {

    if (this.markers.length > 2) {
      this.markers.pop();
    }
    else
      this.toastr.warning(this.i18n('No se puede eliminar mas marcadores de ruta'));
  }

  submit(): void {
    let somId=0;
    if(this.data!==null && this.data!==undefined){
      somId=this.data.SomId;
    }
    let ruta: ZbRuta={
      SomId:somId,
      Nombre:"",
      Descripcion:"",
      Puntos:Array<string>()
    };
    debugger;
    ruta.Nombre = this.RutaForm.get("Nombre").value;
    ruta.Descripcion = this.RutaForm.get("Descripcion").value;
    ruta.Puntos = this.markers.map(x=>`${x.label}:${x.lat};${x.lng}`);
    this.zbRutasService.setZbRuta(ruta).subscribe((res:any)=>
    {
      if(res=="OK"){
        this.toastr.success(this.i18n('Cambios realizados correctamente'));
      }
      else{
        this.toastr.error(res);
      }
    },
    error=>{
      this.toastr.error(error);
    },
    ()=>{
      this.RutaForm.reset();
      this.thisDialogRef.close(true);
    });

  }




  markerDragEnd(m: marker, $event: MouseEvent) {
    const mark = this.markers.find(x => x.label == m.label);
    this.markers = this.markers.filter(x => x.label != m.label);

    mark.lat = $event['coords'].lat;
    mark.lng = $event['coords'].lng;

    const tmp_markers = this.markers.concat(mark);
    this.markers = [];

    tmp_markers.sort((a, b) => (a.label > b.label ? 1 : (b.label > a.label) ? -1 : 0))

    for (let i = 0; i < tmp_markers.length; i++) {
      this.markers.push({
        lat: tmp_markers[i].lat,
        lng: tmp_markers[i].lng,
        label: tmp_markers[i].label,
        draggable: tmp_markers[i].draggable
      });
    }
  }
}

