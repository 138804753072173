import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { InfraccioFilter } from '../models/infraccio-filter';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'id';
const defaultSortDirection = 'asc';

@Injectable()
export class InfraccioFilterService extends CustomFilterService {
  public createFilterParams(filter: InfraccioFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    return new HttpParams()
    .append('id', filter.id === null ? '' : filter.id.toString())
    .append('legislacio', filter.legislacio)
    .append('article', filter.article)
    .append('apartat', filter.apartat)
    .append('opcio', filter.opcio)
    .append('gravetat', filter.gravetat)
    .append('descCurta', filter.descCurta)
    .append('import', filter.import === null ? '' : filter.import.toString())
    .append('fins', filter.fins)
    .append('punts', filter.punts)
    .append('inici',  Reflection.empty(filter.inici) ? '' :  typeof(filter.inici) === 'string' ?
    filter.inici : filter.inici.toDateString())
    .append('final', Reflection.empty(filter.final) ? '' :  typeof(filter.final) === 'string' ?
    filter.final : filter.final.toDateString())
    .append('numFotosPda', filter.numFotosPda === null ? '' : filter.numFotosPda.toString())
    .append('obsoleta', this.getTristateValue(filter.obsoleta))
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
