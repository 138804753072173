<app-toolbar>
  <app-tool-button-permissions [permission]="this.auth.Pages.AnulacionsManuals"></app-tool-button-permissions>
  <app-tool-button text="Pujar Fitxer" i18n-text (click)="this.loadFile();" color="has-color-blue" icon="fas fa-upload">
  </app-tool-button>
</app-toolbar>

<div id="Bloquear" *ngIf="pantalla">
  <div class="row tops">
    <div class="col-md-6"></div>
    <div class="col-md-5">
      <img class="img" src="assets/images/busy.gif" width="50">
    </div>
  </div>
</div>

<div class="">

  <div class="row input">

    <div class="col-md-2">
      <mat-form-field>
        <input #inputDenuncia id="denuncias" [disabled]="bloquear" i18n-placeholder
          placeholder="Num de denúncia o anul·lació zona blava" autocomplete="off" autofocus #denuncias
          (keyup.enter)="enviarCodigo(nDenuncia)" matInput ngModel name="nDenuncia" [(ngModel)]="nDenuncia">
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <button *ngIf="denunciesDades.value.length <= 0" type="button" class="btn btn-primary">
        Total Denuncia: <span class="badge badge-light"> 0</span>
      </button>
      <button *ngIf="denunciesDades.value.length  > 0" type="button" class="btn btn-primary">
        Total Denuncies: <span class="badge badge-light"> {{ denunciesDades.value.length }}</span>
      </button>
    </div>
    <div class="col-md-3" *ngIf="bloquear">
      <button type="button" (click)="reload()" class="btn btn-brand btn-behance">
        <i class="fa fa-sync"></i><span I18n>Tornar</span>
      </button>
    </div>
    <div class="col-md-4">
      <button class="btn btn-sq-lg btn-success" style="float: right" *ngIf="denunciesDades.value.length  > 0"
        (click)="this.SendDenuncies()"><i class="fa fa-share-square green"></i><span i18n>Anul·lar
          manualment</span></button>
      <!--<app-tool-button text="test" style="float: right" i18n-text *ngIf="denunciesDades.value.length  > 0" (click)="this.SendDenuncies()" color="has-color-green" icon="fa-share-square"></app-tool-button>-->
      <!--<button type="button" *ngIf="denunciesDades.value.length  > 0" (click)="reload()" class="btn btn-brand btn-behance">
                  <i class="fa fa-share-square"></i><span I18n>Anul·lar denúncies</span>
                </button>-->
    </div>
  </div>


  <div class="card-body table-responsive">
    <div class="top"></div>
    <mat-table #table [dataSource]="listadenunciesDades" matSort aria-label="Elements">

      <ng-container matColumnDef="Eliminar" class="col-sm-2">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Eliminar</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            <app-tool-button [text]="'Eliminar'" i18n-text (click)="this.deleteRow(row)" color="has-color-red"
              icon="fa-times"></app-tool-button>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Expedient" class="col-sm-2">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expedient</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            {{row.Expedient}}
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="NUMDENUNCIA" class="col-sm-2">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Denúncia</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            {{row.NumDenuncia}}
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="MATRICULA" class="col-sm-2">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            {{row.Matricula}}
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="FECHA" class="col-sm-2">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data denúncia</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            {{row.DataExp}}
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="HORA" class="col-sm-2">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora denúncia</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            {{row.HoraExp}}
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="DataAnul" class="col-sm-2">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data anul·lació</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            <!--{{row.DataAnul}}-->
            <app-form-text-edit id="anulacion-zona-azul-manual-data" [(value)]="row.DataAnul"
              (valueChange)="recalculateAmount(row)"></app-form-text-edit>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="HoraAnul" class="col-sm-2">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora anul·lació</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            <!--{{row.HoraAnul}}-->
            <app-form-text-edit id="anulacion-zona-azul-manual-hora" [(value)]="row.HoraAnul"
              (valueChange)="recalculateAmount(row)"></app-form-text-edit>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ObservacionsAnulacions" class="col-sm-2">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Observació anul·lació</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            <app-form-text-edit id="anulacion-zona-azul-manual-observac" [(value)]="row.ObservacionesAnulaciones"
              (valueChange)="recalculateAmount(row)"></app-form-text-edit>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CodAnul" class="col-sm-2">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi anul·lació</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            {{row.CodAnul}}
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Import" class="col-sm-2">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Import</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            {{row.Import}}
            <!-- <app-form-text-edit id="anulacion-zona-azul-manual-import" [(value)]="row.Import" ></app-form-text-edit> -->
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Resultat" class="col-sm-2">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Resultat</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container>
            {{row.Resultat}}
          </ng-container>
        </mat-cell>
      </ng-container>
      <!-- <ng-container matColumnDef="NUMDENUNCIA" class="col-sm-2">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Import</mat-header-cell>
                  <mat-cell *matCellDef="let row" >
                    <ng-container >
                      {{row.NUMDENUNCIA}}
                    </ng-container>
                  </mat-cell>                 
            </ng-container> -->


      <!-- Pda NO Actives ALWAYS -->
      <mat-header-row *matHeaderRowDef="columnesTaula; sticky: true" style="position: sticky"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnesTaula;" class="has-iconset"></mat-row>
    </mat-table>
    <!--<form action="" #denunciaManual="ngForm">
            <table class="table  table-sm is-header-sticky" *ngIf="sinDenuncias">
                <thead class="bg-secondary1">
                    <tr>
                        <td *ngIf="!bloquear" class="text-center"> X</td>
                        <td>Expedient</td>
                        <td>Denúncia</td>
                        <td>Matrícula</td>
                        <td>Data denúncia</td>
                        <td>Hora denúncia</td>
                        <td>Data anul·lació</td>
                        <td>Hora anul·lació</td>
                        <td>Codi anul·lació</td>
                        <td>Import</td>
                        <td class="text-center">Resultat</td>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of denunciasDatos ;let i = index">
                        <td *ngIf="!bloquear">
                            <i class="btn btn-outline-danger" (click)="list( i )">X</i>
                            
                            <input type="hidden" name="{{item.EXP}}" ngModel>
                        </td>
                        <td>{{ item.EXP}}</td>
                        <td>{{ item.NUMDENUNCIA}}</td>
                        <td>{{ item.MATRICULA}}</td>
                        <td>{{ item.FECHA | date : 'd/MM/yyyy'}}</td>
                        <td>{{ item.HORA}}</td>
                        <td>{{ item.NOTIFICADAENMA | date : 'd/MM/yyyy'}}</td>
                        <td>{{ item.HORA}}</td>
                        <td>{{ item.ARTICLE}}</td>
                        <td width="90"> <input type="text" [disabled]="bloquear" class="form-control" name="{{item.NUMDENUNCIA}}" [(ngModel)]="item.IMPORT"></td>
                        <td class="text-success" width="400">{{ resultadoSucces }}</td>
                        
                    </tr>
                </tbody>
            </table>
        </form>-->

    <div class="card" *ngIf="!sinDenuncias">
      <div class="card-header1" i18n>denúncies manuals.</div>
      <div class="card-body">
        <blockquote class="blockquote mb-0">
          <p i18n>Escaneja els codis de barres de les butlletes per realitzar l'anul·lació. O bé introdueix els codis
            manualment</p>
          <footer class="blockquote-footer" i18n>Per introduïr manualment pressiona la tecla INTRO quan hagis escrit el
            codi</footer>
        </blockquote>
      </div>
    </div>
  </div>
</div>


<!-- Modal para dar aviso de denuncia no encontrada -->
<div class="modal fade animated zoomInUp" id="sinDenunciasModel" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">

        <button (click)="cerrarModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger text-center" role="alert">
          <p i18n>no s'han trobat denúncies amb el codi: </p> <strong>{{nDenuncia}}</strong>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="cerrarModal()" data-dismiss="modal"
          i18n>Sortir</button>

      </div>
    </div>
  </div>
</div>

<!-- Modal de denuncia repetida -->
<div class="modal fade animated rotateIn" id="denunciaRepetida" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">

        <button (click)="cerrarModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger text-center" role="alert" I18n>Aquesta denúncia amb el codi :
          <strong>{{nDenuncia}}</strong> ja s’ha enviat
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="cerrarModal()" data-dismiss="modal"
          I18n>Sortir</button>

      </div>
    </div>
  </div>
</div>