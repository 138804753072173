import { CFG_PARAMETROS } from 'path-models/CFG_PARAMETROS';
import { GoogleMapsService } from './shared/services/startup/google-maps.service';
import { config } from './shared/configuration/config';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { Parametros } from 'path-shared/models/parametros';
import { api } from 'path-shared/configuration/urls';
import { HttpClient } from '@angular/common/http';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

export function agmConfigFactory(googleMapsService: GoogleMapsService, lazyMapsConfig: LazyMapsAPILoaderConfigLiteral) {

    return () =>
        lazyMapsConfig.apiKey = environment.APIKEYGOOGLEMAPS;
        return '';
}
