var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter, ElementRef } from '@angular/core';
import { ConfirmationDialogService } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.service';
import { MatDialog } from '@angular/material';
import { StartupService } from '../../../../shared/services/startup/startup.service';
import { Router } from '@angular/router';
import { Builder } from 'path-shared/models/builder';
import { CarrersComponent } from 'app/sections/configuracio/carrers/pages/carrers/carrers.component';
import { ZonesService } from 'app/sections/ciutat/zones/services/zones.service';
import { AgentsComponent } from 'app/sections/configuracio/agents/pages/agents/agents.component';
import { VehiclesComponent } from 'app/sections/ciutat/vehicles/pages/vehicles/vehicles.component';
import { BehaviorSubject } from 'rxjs';
import { Dictionary } from 'path-shared/models/dictionary';
import { InfraccioComponent } from 'app/sections/configuracio/Infraccions/pages/infraccio.component';
import { PersonesComponent } from 'app/sections/ciutat/persones/pages/persones/persones.component';
import { InfraccioService } from 'app/sections/configuracio/Infraccions/services/infraccions-service.service';
import { PersonesService } from 'app/sections/ciutat/persones/services/persones.service';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';
import { PersonaFilter } from 'app/sections/ciutat/persones/models/persona-filter';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PersonesEditorComponent } from 'app/sections/ciutat/persones/components/persones-editor/persones-editor.component';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { ToastrService } from 'ngx-toastr';
import { FormService } from 'path-shared/services/form/form.service';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { CarrersService } from 'app/sections/configuracio/carrers/services/carrers-service.service';
import { MotiusNoNotificacioService } from 'app/sections/configuracio/motius-notificacio/services/motius-notificacio.service';
var DenunciesNewComponent = /** @class */ (function () {
    /* #endregion */
    /* #region  INITIALIZERS */
    function DenunciesNewComponent(dialog, zonesService, router, confirmationDialogService, startupService, infraccioService, personesService, denunciesService, formService, toastr, i18n, carrersService, infraccionsService, motiusNoNotificacioService) {
        this.dialog = dialog;
        this.zonesService = zonesService;
        this.router = router;
        this.confirmationDialogService = confirmationDialogService;
        this.startupService = startupService;
        this.infraccioService = infraccioService;
        this.personesService = personesService;
        this.denunciesService = denunciesService;
        this.formService = formService;
        this.toastr = toastr;
        this.i18n = i18n;
        this.carrersService = carrersService;
        this.infraccionsService = infraccionsService;
        this.motiusNoNotificacioService = motiusNoNotificacioService;
        /* #region  ATTRIBUTES */
        this.created = new EventEmitter();
        this.motiusNoNotificacio = [];
        this.conductorPreviousDNI = '';
        this.propietariPreviousDNI = '';
        this.EstablecimientoTrabajadorPreviousNIE = '';
        this.EstablecimientoPreviousCIF = '';
        this.skip = false;
        this.denunciaDetail = Builder.newDenunciaDetail();
        this.tipusDenunciesDictionary = new BehaviorSubject(new Array());
        this.zonesDictionary = new BehaviorSubject(new Array());
        this.tipusGravetatDictionary = new BehaviorSubject(new Array());
        this.domicilisConductorDictionary = new BehaviorSubject(new Array());
        this.domicilisPropietariDictionary = new BehaviorSubject(new Array());
        this.valorAutoCompleteCalleDictionary = '';
        this.valorAutoCompleteInfraccionesDictionary = '';
    }
    /* #endregion */
    /* #region  COMPONENT LIFECYCLE */
    DenunciesNewComponent.prototype.ngOnInit = function () {
        this.loadTipusDenuncies();
        this.loadTipusGravetat();
        this.loadDiesDescompte();
        this.loadCalles();
        this.loadInfracciones();
        this.loadMotiusNoNotificacio();
    };
    /* #endregion */
    /* #region  EVENTS */
    DenunciesNewComponent.prototype.saveDenuncia = function () {
        var _this = this;
        if (this.formService.allFieldsValid(this.mainForm)) {
            this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Vol crear la denúncia?'))
                .then(function (confirmed) {
                if (confirmed) {
                    var dataJson = JSON.stringify(_this.denunciaDetail);
                    _this.denunciesService.saveDenuncia(_this.denunciaDetail.secure_id_denuncia, dataJson).subscribe(function () {
                        _this.toastr.success(_this.i18n('Denúncia creada'));
                        _this.dialog.closeAll();
                        _this.router.navigate(['denuncies']);
                    });
                }
            })
                .catch(function () { return console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'); });
        }
        else {
            this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
            if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
                this.mainForm.nativeElement.classList.add('displayErrors');
            }
        }
    };
    DenunciesNewComponent.prototype.cancel = function () {
        this.router.navigate(['denuncies']);
    };
    /* #endregion */
    /* #region  DATA GETTERS && SETTERS */
    DenunciesNewComponent.prototype.loadTipusDenuncies = function () {
        var tipusDenunciaAux = new Array();
        this.startupService.GetTiposDenuncia().forEach(function (tipusDenuncia) {
            tipusDenunciaAux.push(new Dictionary(tipusDenuncia.CODI, tipusDenuncia.DESCRIPCIO));
        });
        this.tipusDenunciesDictionary.next(tipusDenunciaAux);
    };
    DenunciesNewComponent.prototype.loadTipusGravetat = function () {
        var tipusGravetatAux = new Array();
        this.startupService.GetInfraccioTipusGravetat().forEach(function (gravetat) {
            tipusGravetatAux.push(new Dictionary(gravetat.CODI, gravetat.DESCRIPCIO));
        });
        this.tipusGravetatDictionary.next(tipusGravetatAux);
    };
    DenunciesNewComponent.prototype.loadDiesDescompte = function () {
        var _this = this;
        this.infraccioService.GetInfraccioDiesDescompte().subscribe(function (dies) {
            _this.diesDescompte = dies;
        });
    };
    DenunciesNewComponent.prototype.loadCalles = function () {
        var _this = this;
        var json;
        var carrers;
        if (sessionStorage.getItem('carrersForCombo')) {
            json = sessionStorage.getItem('carrersForCombo');
            carrers = JSON.parse(json.toString());
            this.setCalles(carrers);
        }
        else {
            this.carrersService.getAllForCombo().subscribe(function (carrersForCombo) {
                json = JSON.stringify(carrersForCombo);
                sessionStorage.setItem('carrersForCombo', json);
                _this.setCalles(carrersForCombo);
            });
        }
    };
    DenunciesNewComponent.prototype.setCalles = function (result) {
        var _this = this;
        if (result !== null) {
            this.callesDictionary = new Array();
            result.forEach(function (element) {
                if (element.SECURE_ID !== undefined && element.SECURE_ID !== null &&
                    element.nomCarrer !== undefined && element.nomCarrer !== null) {
                    _this.callesDictionary.push(new Dictionary(element.SECURE_ID, element.nomCarrer));
                }
            });
        }
    };
    DenunciesNewComponent.prototype.loadInfracciones = function () {
        var _this = this;
        this.infraccionsService.getAllForCombo().subscribe(function (result) {
            if (result !== null) {
                _this.infracciones = result;
                _this.infraccionesDictionary = new Array();
                result.forEach(function (element) {
                    if (element.secureId !== undefined && element.secureId !== null &&
                        element.descCurta !== undefined && element.descCurta !== null) {
                        _this.infraccionesDictionary.push(new Dictionary(element.secureId, element.descCurta));
                    }
                });
            }
        });
    };
    DenunciesNewComponent.prototype.loadMotiusNoNotificacio = function () {
        var _this = this;
        this.motiusNoNotificacioService.getAllForCombo().subscribe(function (res) {
            _this.motiusNoNotificacio = res;
        });
    };
    ;
    DenunciesNewComponent.prototype.loadZonaCarrer = function (secure_id_carrer) {
        var _this = this;
        if (secure_id_carrer !== null && secure_id_carrer !== undefined) {
            this.zonesService.getZonesByCarrer(secure_id_carrer).subscribe(function (zones) {
                var zonesComboAux = new Array();
                zones.forEach(function (zona) {
                    zonesComboAux.push(new Dictionary(zona.SECURE_ID, zona.descripcio));
                });
                _this.zonesDictionary.next(zonesComboAux);
            });
        }
    };
    DenunciesNewComponent.prototype.tipusDenunciaSeleccionado = function (tipusDenunciaId) {
        this.denunciaDetail.tipusDenuncia = tipusDenunciaId;
    };
    DenunciesNewComponent.prototype.zonaCarrerSeleccionado = function (zonaSecureId) {
        this.denunciaDetail.secure_id_zona = zonaSecureId;
    };
    DenunciesNewComponent.prototype.tipusGravetatSeleccionado = function (gravetatId) {
        this.denunciaDetail.qualificacio = gravetatId;
    };
    DenunciesNewComponent.prototype.domicilioConductorSeleccionado = function (secure_id) {
        this.denunciaDetail.secure_id_domiciliConductor = secure_id;
    };
    DenunciesNewComponent.prototype.domicilioPropietarioSeleccionado = function (secure_id) {
        this.denunciaDetail.secure_id_domiciliPropietari = secure_id;
    };
    DenunciesNewComponent.prototype.calleSeleccionado = function (secureIdCalle) {
        console.log(secureIdCalle);
        this.denunciaDetail.secure_id_carrer = secureIdCalle;
        this.denunciaDetail.carrer = this.callesDictionary.find(function (x) { return x.key === secureIdCalle; }).value;
        this.valorAutoCompleteCalleDictionary = this.denunciaDetail.carrer;
        // Carreguem les zones associades al carrer sel·leccionat
        console.log(this.denunciaDetail.carrer);
        // this.loadZonaCarrer(this.denunciaDetail.secure_id_carrer);
    };
    DenunciesNewComponent.prototype.infraccioSeleccionado = function (secureIdInfraccion) {
        var res = this.infracciones.find(function (x) { return x.secureId === secureIdInfraccion; });
        if (res !== undefined && res !== null) {
            // Les infraccions de tipus velocitat no les podem denúnciar des d'aquí
            if (res.tipusId === 'V') {
                this.toastr.warning(this.i18n('No es poden carregar infraccions de velocitat!'));
            }
            else {
                this.denunciaDetail.llei = res.legislacio;
                this.denunciaDetail.article = res.article;
                this.denunciaDetail.descripcioInfraccio = res.descCurta;
                this.denunciaDetail.secure_id_infraccio = res.secureId;
                this.denunciaDetail.import = res.import;
                this.denunciaDetail.importDescompte = res.importDescompte;
                this.denunciaDetail.lleiDesc = res.lleiDesc;
                this.denunciaDetail.tipusIdInfraccio = res.tipusId;
                this.denunciaDetail.porcentajeDescuento = res.descompte;
                this.denunciaDetail.punts = Number(res.punts);
                this.denunciaDetail.judicial = res.judicial;
                this.denunciaDetail.tipusDenuncia = res.tipusDenunciaId; // combo tipus denuncies
                this.comboTipusDenuncia.setSelect(this.denunciaDetail.tipusDenuncia);
                this.comboTipusDenuncia.markAsTouched();
                this.denunciaDetail.qualificacio = res.gravetat; // combo tipus gravetats
                this.comboTipusGravetat.setSelect(this.denunciaDetail.qualificacio);
                this.comboTipusGravetat.markAsTouched();
                this.comboInfracciones.disableComponent = true;
                this.calcularDataDescompte();
            }
        }
    };
    DenunciesNewComponent.prototype.seleccionarCarrer = function () {
        var _this = this;
        var data = Builder.newComponentDialog(true);
        var ref = this.dialog.open(CarrersComponent, {
            width: '90%',
            closeOnNavigation: false,
            autoFocus: true,
            data: data
        });
        ref.afterClosed().subscribe(function (resp) {
            if (resp.nomCarrer !== undefined && resp.nomCarrer !== null && resp.nomCarrer !== '') {
                // this.loadZonaCarrer(this.denunciaDetail.secure_id_carrer);
                console.log(resp.secure_id);
                //this.denunciaDetail.carrer = resp.nomCarrer;
                _this.denunciaDetail.secure_id_carrer = resp.secure_id;
                _this.denunciaDetail.carrer = _this.callesDictionary.find(function (x) { return x.key === resp.secure_id; }).value;
                _this.valorAutoCompleteCalleDictionary = resp.nomCarrer;
                console.log(_this.denunciaDetail.carrer);
                // Carreguem les zones associades al carrer sel·leccionat
                //this.loadZonaCarrer(this.denunciaDetail.secure_id_carrer);
            }
        });
    };
    DenunciesNewComponent.prototype.seleccionarAgent = function () {
        var _this = this;
        var data = Builder.newComponentDialog(true);
        var ref = this.dialog.open(AgentsComponent, {
            width: '90%',
            closeOnNavigation: false,
            autoFocus: true,
            data: data
        });
        ref.afterClosed().subscribe(function (res) {
            if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
                _this.denunciaDetail.codiAgent = res.codi;
                _this.denunciaDetail.secure_id_agent = res.secure_id;
            }
        });
    };
    DenunciesNewComponent.prototype.seleccionarVehicle = function () {
        var _this = this;
        var data = Builder.newComponentDialog(true);
        var ref = this.dialog.open(VehiclesComponent, {
            width: '80%',
            closeOnNavigation: false,
            autoFocus: true,
            data: data
        });
        ref.afterClosed().subscribe(function (res) {
            if (res.matricula !== undefined && res.matricula !== null && res.matricula !== '') {
                _this.denunciaDetail.secure_id_vehicle = res.secure_id;
                _this.denunciaDetail.matricula = res.matricula;
                _this.denunciaDetail.modelo = res.model;
                _this.denunciaDetail.marca = res.marca;
                _this.denunciaDetail.pais = res.pais;
                _this.denunciaDetail.paisDesc = res.paisDesc;
            }
        });
    };
    DenunciesNewComponent.prototype.seleccionarLegislacio = function () {
        var _this = this;
        var data = Builder.newComponentDialog(true);
        var ref = this.dialog.open(InfraccioComponent, {
            width: '90%',
            closeOnNavigation: false,
            autoFocus: true,
            data: data
        });
        ref.afterClosed().subscribe(function (res) {
            if (res !== undefined && res !== null) {
                // Les infraccions de tipus velocitat no les podem denúnciar des d'aquí
                if (res.infraccio.tipusId === 'V') {
                    _this.toastr.warning(_this.i18n('No es poden carregar infraccions de velocitat!'));
                }
                else {
                    _this.denunciaDetail.llei = res.infraccio.legislacio;
                    _this.denunciaDetail.article = res.infraccio.article;
                    _this.denunciaDetail.descripcioInfraccio = res.infraccio.descCurta;
                    _this.denunciaDetail.secure_id_infraccio = res.infraccio.secureId;
                    _this.denunciaDetail.import = res.infraccio.import;
                    _this.denunciaDetail.importDescompte = res.infraccio.importDescompte;
                    _this.denunciaDetail.lleiDesc = res.infraccio.lleiDesc;
                    _this.denunciaDetail.tipusIdInfraccio = res.infraccio.tipusId;
                    _this.denunciaDetail.porcentajeDescuento = res.infraccio.descompte;
                    _this.denunciaDetail.punts = res.infraccio.punts;
                    _this.denunciaDetail.judicial = res.infraccio.judicial;
                    _this.denunciaDetail.tipusDenuncia = res.infraccio.tipusDenunciaId; // combo tipus denuncies
                    _this.comboTipusDenuncia.setSelect(_this.denunciaDetail.tipusDenuncia);
                    _this.comboTipusDenuncia.markAsTouched();
                    _this.denunciaDetail.qualificacio = res.infraccio.gravetat; // combo tipus gravetats
                    _this.comboTipusGravetat.setSelect(_this.denunciaDetail.qualificacio);
                    _this.comboTipusGravetat.markAsTouched();
                    _this.calcularDataDescompte();
                    _this.comboInfracciones.disableComponent = true;
                    _this.setDefaultValueInfraccion();
                }
            }
        });
    };
    DenunciesNewComponent.prototype.setDefaultValueInfraccion = function () {
        var _this = this;
        if (this.denunciaDetail.secure_id_infraccio !== undefined && this.denunciaDetail.secure_id_infraccio !== null && this.infraccionesDictionary.length > 0) {
            var infraccioSelected = this.infraccionesDictionary.find(function (x) { return x.key === _this.denunciaDetail.secure_id_infraccio.toString(); });
            this.comboInfracciones.setForcedValueComb(infraccioSelected.value);
        }
    };
    DenunciesNewComponent.prototype.seleccionarConductor = function () {
        var _this = this;
        var data = Builder.newComponentDialog(true);
        var ref = this.dialog.open(PersonesComponent, {
            width: '80%',
            height: '90%',
            closeOnNavigation: false,
            autoFocus: true,
            data: data,
            disableClose: true
        });
        ref.afterClosed().subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
                    this.denunciaDetail.secure_id_conductor = res.codi;
                    this.denunciaDetail.secure_id_conductor = res.secure_id;
                    this.denunciaDetail.nom_conductor = res.nomComplet;
                    this.denunciaDetail.dni_conductor = res.dni_pasp;
                    this.denunciaDetail.secure_id_domiciliConductor = res.secureIdDom;
                    this.getDomicilis(this.denunciaDetail.secure_id_conductor, false, res.secureIdDom);
                }
                return [2 /*return*/];
            });
        }); });
    };
    DenunciesNewComponent.prototype.seleccionarPropietari = function () {
        var _this = this;
        var data = Builder.newComponentDialog(true);
        var ref = this.dialog.open(PersonesComponent, {
            width: '80%',
            height: '90%',
            closeOnNavigation: false,
            autoFocus: true,
            data: data,
            disableClose: true
        });
        ref.afterClosed().subscribe(function (res) {
            if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
                _this.denunciaDetail.secure_id_propietari = res.secure_id;
                _this.denunciaDetail.nom_propietari = res.nomComplet;
                _this.denunciaDetail.dni_propietari = res.dni_pasp;
                _this.denunciaDetail.secure_id_domiciliPropietari = res.secureIdDom;
                _this.getDomicilis(_this.denunciaDetail.secure_id_propietari, true, res.secureIdDom);
            }
        });
    };
    DenunciesNewComponent.prototype.getDomicilis = function (secureIdPersona, propietari, secureIdDom) {
        var _this = this;
        this.personesService.getDomicilis(secureIdPersona).subscribe(function (dom) {
            if (propietari) {
                _this.comboDomPropietari.resetShowingOptions();
                if (dom === undefined || dom === null || dom.length === 0) {
                    _this.domicilisPropietariDictionary = new BehaviorSubject(new Array());
                }
                else {
                    _this.domicilisPropietariDictionary = new BehaviorSubject(new Array());
                    var dict_1 = new Array();
                    dom.forEach(function (element) {
                        dict_1.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
                    });
                    _this.domicilisPropietariDictionary.next(dict_1);
                    if (secureIdDom !== undefined) {
                        _this.comboDomPropietari.markAsTouched();
                        _this.comboDomPropietari.setSelect(secureIdDom);
                    }
                }
            }
            else {
                _this.comboDomConductor.resetShowingOptions();
                if (dom === undefined || dom === null || dom.length === 0) {
                    _this.domicilisConductorDictionary = new BehaviorSubject(new Array());
                }
                else {
                    _this.domicilisConductorDictionary = new BehaviorSubject(new Array());
                    var dict_2 = new Array();
                    dom.forEach(function (element) {
                        dict_2.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
                    });
                    _this.domicilisConductorDictionary.next(dict_2);
                    if (secureIdDom !== undefined) {
                        _this.comboDomConductor.markAsTouched();
                        _this.comboDomConductor.setSelect(secureIdDom);
                    }
                }
            }
        });
    };
    DenunciesNewComponent.prototype.consultarConductor = function () {
        var _this = this;
        if (this.denunciaDetail.dni_conductor !== undefined
            && this.denunciaDetail.dni_conductor !== null
            && this.denunciaDetail.dni_conductor !== '') {
            this.personesService.findPersonaByDniNif(this.denunciaDetail.dni_conductor).subscribe(function (x) {
                if (x !== undefined && x !== null && x.length > 0) {
                    if (x.length === 1) {
                        _this.denunciaDetail.secure_id_conductor = x[0].secure_id;
                        _this.denunciaDetail.dni_conductor = x[0].dni_pasp;
                        _this.denunciaDetail.nom_conductor = x[0].nomComplet;
                        _this.skip = true;
                        // TODO: Domicili
                        _this.getDomicilis(x[0].secure_id, false, x[0].SECURE_ID_DOMICILI_ACTUAL);
                    }
                    else if (x.length > 1) {
                        var filter = new PersonaFilter('id', 'asc');
                        filter.dni_pasp = _this.denunciaDetail.dni_conductor;
                        // Carraguem la taula filtrada per dni
                        var data = Builder.newComponentDialog(true, false, '', filter);
                        var ref = _this.dialog.open(PersonesComponent, {
                            width: '80%',
                            height: '90%',
                            closeOnNavigation: false,
                            autoFocus: true,
                            data: data
                        });
                        ref.afterClosed().subscribe(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                                    this.denunciaDetail.secure_id_conductor = resp.codi;
                                    this.denunciaDetail.secure_id_conductor = resp.secure_id;
                                    this.denunciaDetail.nom_conductor = resp.nomComplet;
                                    this.denunciaDetail.dni_conductor = resp.dni_pasp;
                                    this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                                    // : nomComplet, dni_pasp: dni_pasp
                                    this.getDomicilis(this.denunciaDetail.secure_id_conductor, false, resp.secureIdDom);
                                    this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                                    this.skip = true;
                                }
                                else {
                                    this.denunciaDetail.secure_id_conductor = null;
                                    this.denunciaDetail.secure_id_conductor = null;
                                    this.denunciaDetail.nom_conductor = null;
                                    this.denunciaDetail.dni_conductor = null;
                                    this.denunciaDetail.secure_id_domiciliConductor = null;
                                    // : nomComplet, dni_pasp: dni_pasp
                                    // this.getDomicilis(this.denunciaDetail.secure_id_conductor, false, resp.secureIdDom);
                                    this.domicilisConductorDictionary = new BehaviorSubject(new Array());
                                    this.denunciaDetail.secure_id_domiciliConductor = null;
                                }
                                return [2 /*return*/];
                            });
                        }); });
                    }
                }
                else {
                    // Obrim dialeg per crear persona
                    _this.confirmationDialogService.confirm(_this.i18n('Confirmi'), _this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), _this.i18n('Si'), _this.i18n('No'))
                        .then(function (confirmed) {
                        if (confirmed) {
                            // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
                            var ref = _this.dialog.open(PersonesEditorComponent, {
                                width: '80%',
                                height: '90%',
                                closeOnNavigation: false,
                                autoFocus: true,
                                data: {
                                    isDialog: true,
                                    isReadOnly: false,
                                    id: null,
                                    filter: null,
                                    dni: _this.denunciaDetail.dni_conductor
                                }
                            });
                            ref.afterClosed().subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                                        this.denunciaDetail.secure_id_conductor = data.codi;
                                        this.denunciaDetail.secure_id_conductor = data.secure_id;
                                        this.denunciaDetail.nom_conductor = data.nomComplet;
                                        this.denunciaDetail.dni_conductor = data.dni_pasp;
                                        this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
                                        // : nomComplet, dni_pasp: dni_pasp
                                        this.getDomicilis(this.denunciaDetail.secure_id_conductor, false, data.secureIdDom);
                                        this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
                                        this.skip = true;
                                    }
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        else {
                            _this.denunciaDetail.nom_conductor = '';
                            _this.domicilisConductorDictionary = new BehaviorSubject(new Array());
                        }
                    });
                }
            });
        }
        else {
            // Obrim dialeg per crear persona
            // this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
            // .then((confirmed) => {
            //     if (confirmed) {
            // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
            var ref = this.dialog.open(PersonesComponent, {
                width: '80%',
                height: '90%',
                closeOnNavigation: false,
                autoFocus: true,
                data: {
                    isDialog: true,
                    isReadOnly: false,
                    id: null,
                    filter: null
                }
                //,dni: this.denunciaDetail.dni_conductor}
            });
            ref.afterClosed().subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                        this.denunciaDetail.secure_id_conductor = data.codi;
                        this.denunciaDetail.secure_id_conductor = data.secure_id;
                        this.denunciaDetail.nom_conductor = data.nomComplet;
                        this.denunciaDetail.dni_conductor = data.dni_pasp;
                        this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
                        // : nomComplet, dni_pasp: dni_pasp
                        this.getDomicilis(this.denunciaDetail.secure_id_conductor, false, data.secureIdDom);
                        this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
                        this.skip = true;
                    }
                    return [2 /*return*/];
                });
            }); });
            // } else {
            //   this.denunciaDetail.nom_conductor = '';
            //   this.domicilisConductorDictionary =  new BehaviorSubject(new Array<Dictionary>());
            // }
            //});
        }
    };
    DenunciesNewComponent.prototype.consultarPropietari = function () {
        var _this = this;
        if (this.denunciaDetail.dni_propietari !== undefined
            && this.denunciaDetail.dni_propietari !== null
            && this.denunciaDetail.dni_propietari !== '') {
            this.personesService.findPersonaByDniNif(this.denunciaDetail.dni_propietari).subscribe(function (x) {
                if (x !== undefined && x !== null && x.length > 0) {
                    if (x.length === 1) {
                        _this.denunciaDetail.secure_id_propietari = x[0].secure_id;
                        _this.denunciaDetail.dni_propietari = x[0].dni_pasp;
                        _this.denunciaDetail.nom_propietari = x[0].nomComplet;
                        _this.skip = true;
                        _this.getDomicilis(x[0].secure_id, true, x[0].SECURE_ID_DOMICILI_ACTUAL);
                    }
                    else if (x.length > 1) {
                        var filter = new PersonaFilter('id', 'asc');
                        filter.dni_pasp = _this.denunciaDetail.dni_propietari;
                        // Carraguem la taula filtrada per dni
                        var data = Builder.newComponentDialog(true, false, '', filter);
                        var ref = _this.dialog.open(PersonesComponent, {
                            width: '80%',
                            height: '90%',
                            closeOnNavigation: false,
                            autoFocus: true,
                            data: data
                        });
                        ref.afterClosed().subscribe(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                                    this.denunciaDetail.secure_id_propietari = resp.codi;
                                    this.denunciaDetail.secure_id_propietari = resp.secure_id;
                                    this.denunciaDetail.nom_propietari = resp.nomComplet;
                                    this.denunciaDetail.dni_propietari = resp.dni_pasp;
                                    this.denunciaDetail.secure_id_domiciliPropietari = resp.secureIdDom;
                                    // : nomComplet, dni_pasp: dni_pasp
                                    this.getDomicilis(this.denunciaDetail.secure_id_conductor, true, resp.secureIdDom);
                                    this.denunciaDetail.secure_id_domiciliPropietari = resp.secureIdDom;
                                    this.skip = true;
                                }
                                return [2 /*return*/];
                            });
                        }); });
                    }
                }
                else {
                    // Obrim dialeg per crear persona
                    _this.confirmationDialogService.confirm(_this.i18n('Confirmi'), _this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), _this.i18n('Si'), _this.i18n('No'))
                        .then(function (confirmed) {
                        if (confirmed) {
                            // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
                            var ref = _this.dialog.open(PersonesEditorComponent, {
                                width: '80%',
                                height: '90%',
                                closeOnNavigation: false,
                                autoFocus: true,
                                data: {
                                    isDialog: true,
                                    isReadOnly: false,
                                    id: null,
                                    filter: null,
                                    dni: _this.denunciaDetail.dni_propietari
                                }
                            });
                            ref.afterClosed().subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                                        this.denunciaDetail.secure_id_propietari = data.codi;
                                        this.denunciaDetail.secure_id_propietari = data.secure_id;
                                        this.denunciaDetail.nom_propietari = data.nomComplet;
                                        this.denunciaDetail.dni_propietari = data.dni_pasp;
                                        this.denunciaDetail.secure_id_domiciliPropietari = data.secureIdDom;
                                        // : nomComplet, dni_pasp: dni_pasp
                                        this.getDomicilis(this.denunciaDetail.secure_id_domiciliPropietari, true, data.secureIdDom);
                                        this.denunciaDetail.secure_id_domiciliPropietari = data.secureIdDom;
                                        this.skip = true;
                                    }
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        else {
                            _this.denunciaDetail.nom_propietari = '';
                            _this.domicilisPropietariDictionary = new BehaviorSubject(new Array());
                        }
                    });
                }
            });
        }
        else {
            // Obrim dialeg per crear persona
            // this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
            // .then((confirmed) => {
            //   if (confirmed) {
            // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
            var ref = this.dialog.open(PersonesComponent, {
                width: '80%',
                height: '90%',
                closeOnNavigation: false,
                autoFocus: true,
                data: {
                    isDialog: true,
                    isReadOnly: false,
                    id: null,
                    filter: null
                }
                //,dni: this.denunciaDetail.dni_propietari}
            });
            ref.afterClosed().subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                        this.denunciaDetail.secure_id_propietari = data.codi;
                        this.denunciaDetail.secure_id_propietari = data.secure_id;
                        this.denunciaDetail.nom_propietari = data.nomComplet;
                        this.denunciaDetail.dni_propietari = data.dni_pasp;
                        this.denunciaDetail.secure_id_domiciliPropietari = data.secureIdDom;
                        // : nomComplet, dni_pasp: dni_pasp
                        this.getDomicilis(this.denunciaDetail.secure_id_propietari, true, data.secureIdDom);
                        this.denunciaDetail.secure_id_domiciliPropietari = data.secureIdDom;
                        this.skip = true;
                    }
                    return [2 /*return*/];
                });
            }); });
            // } else {
            //   this.denunciaDetail.nom_propietari = '';
            //   this.domicilisPropietariDictionary =  new BehaviorSubject(new Array<Dictionary>());
            // }
            //});
        }
    };
    DenunciesNewComponent.prototype.consultarEstabliment = function () {
        var _this = this;
        if (this.denunciaDetail.EstablecimientoCIF !== undefined
            && this.denunciaDetail.EstablecimientoCIF !== null
            && this.denunciaDetail.EstablecimientoCIF !== '') {
            this.personesService.findEmpresaByCif(this.denunciaDetail.EstablecimientoCIF).subscribe(function (x) {
                if (x !== undefined && x !== null && x.length > 0) {
                    if (x.length === 1) {
                        _this.denunciaDetail.secure_id_Establecimiento = x[0].secure_id;
                        _this.denunciaDetail.EstablecimientoCIF = x[0].dni_pasp;
                        _this.denunciaDetail.EstablecimientoRazonSocial = x[0].nomComplet;
                        _this.skip = true;
                    }
                    else if (x.length > 1) {
                        var filter_1 = new PersonaFilter('id', 'asc');
                        filter_1.dni_pasp = _this.denunciaDetail.EstablecimientoCIF;
                        filter_1.juridica = true;
                        // Carraguem la taula filtrada per dni
                        var data = Builder.newComponentDialog(true, false, '', filter_1);
                        var ref = _this.dialog.open(PersonesComponent, {
                            width: '80%',
                            height: '90%',
                            closeOnNavigation: false,
                            autoFocus: true,
                            data: data
                        });
                        ref.afterClosed().subscribe(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                                    this.denunciaDetail.secure_id_Establecimiento = resp.codi;
                                    this.denunciaDetail.secure_id_Establecimiento = resp.secure_id;
                                    this.denunciaDetail.EstablecimientoRazonSocial = resp.nomComplet;
                                    this.denunciaDetail.EstablecimientoCIF = resp.dni_pasp;
                                    this.skip = true;
                                }
                                return [2 /*return*/];
                            });
                        }); });
                    }
                }
                else {
                    // Obrim dialeg per crear persona
                    _this.confirmationDialogService.confirm(_this.i18n('Confirmi'), _this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), _this.i18n('Si'), _this.i18n('No'))
                        .then(function (confirmed) {
                        if (confirmed) {
                            // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
                            var ref = _this.dialog.open(PersonesEditorComponent, {
                                width: '80%',
                                height: '90%',
                                closeOnNavigation: false,
                                autoFocus: true,
                                data: {
                                    isDialog: true,
                                    isReadOnly: false,
                                    id: null,
                                    filter: null,
                                    dni: _this.denunciaDetail.EstablecimientoCIF,
                                    juridica: true
                                }
                            });
                            ref.afterClosed().subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                                        this.denunciaDetail.secure_id_Establecimiento = data.codi;
                                        this.denunciaDetail.secure_id_Establecimiento = data.secure_id;
                                        this.denunciaDetail.EstablecimientoRazonSocial = data.nomComplet;
                                        this.denunciaDetail.EstablecimientoCIF = data.dni_pasp;
                                        this.skip = true;
                                    }
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        else {
                            _this.denunciaDetail.EstablecimientoRazonSocial = '';
                        }
                    });
                }
            });
        }
        else {
            // Obrim dialeg per crear persona
            // this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
            // .then((confirmed) => {
            //   if (confirmed) {
            // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
            var filter = new PersonaFilter('id', 'asc');
            filter.juridica = true;
            var ref = this.dialog.open(PersonesComponent, {
                width: '80%',
                height: '90%',
                closeOnNavigation: false,
                autoFocus: true,
                data: {
                    isDialog: true,
                    isReadOnly: false,
                    id: null,
                    filter: filter
                }
            });
            ref.afterClosed().subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                        this.denunciaDetail.secure_id_Establecimiento = data.codi;
                        this.denunciaDetail.secure_id_Establecimiento = data.secure_id;
                        this.denunciaDetail.EstablecimientoRazonSocial = data.nomComplet;
                        this.denunciaDetail.EstablecimientoCIF = data.dni_pasp;
                        this.skip = true;
                    }
                    return [2 /*return*/];
                });
            }); });
            //   } else {
            //     this.denunciaDetail.EstablecimientoRazonSocial = '';            
            //   }
            // });
        }
    };
    DenunciesNewComponent.prototype.consultarTreballador = function () {
        var _this = this;
        debugger;
        if (this.denunciaDetail.EstablecimientoTrabajadorNIE !== undefined
            && this.denunciaDetail.EstablecimientoTrabajadorNIE !== null
            && this.denunciaDetail.EstablecimientoTrabajadorNIE !== '') {
            this.personesService.findPersonaByDniNif(this.denunciaDetail.EstablecimientoTrabajadorNIE).subscribe(function (x) {
                if (x !== undefined && x !== null && x.length > 0) {
                    if (x.length === 1) {
                        _this.denunciaDetail.secure_id_EstablecimientoTrabajador = x[0].secure_id;
                        _this.denunciaDetail.EstablecimientoTrabajadorNIE = x[0].dni_pasp;
                        _this.denunciaDetail.EstablecimientoTrabajadorNombre = x[0].nomComplet;
                        _this.skip = true;
                    }
                    else if (x.length > 1) {
                        var filter = new PersonaFilter('id', 'asc');
                        filter.dni_pasp = _this.denunciaDetail.EstablecimientoTrabajadorNIE;
                        // Carraguem la taula filtrada per dni
                        var data = Builder.newComponentDialog(true, false, '', filter);
                        var ref = _this.dialog.open(PersonesComponent, {
                            width: '80%',
                            height: '90%',
                            closeOnNavigation: false,
                            autoFocus: true,
                            data: data
                        });
                        ref.afterClosed().subscribe(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                                    this.denunciaDetail.secure_id_EstablecimientoTrabajador = resp.codi;
                                    this.denunciaDetail.secure_id_EstablecimientoTrabajador = resp.secure_id;
                                    this.denunciaDetail.EstablecimientoTrabajadorNombre = resp.nomComplet;
                                    this.denunciaDetail.EstablecimientoTrabajadorNIE = resp.dni_pasp;
                                    this.skip = true;
                                }
                                return [2 /*return*/];
                            });
                        }); });
                    }
                }
                else {
                    // Obrim dialeg per crear persona
                    _this.confirmationDialogService.confirm(_this.i18n('Confirmi'), _this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), _this.i18n('Si'), _this.i18n('No'))
                        .then(function (confirmed) {
                        if (confirmed) {
                            // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
                            var ref = _this.dialog.open(PersonesEditorComponent, {
                                width: '80%',
                                height: '90%',
                                closeOnNavigation: false,
                                autoFocus: true,
                                data: {
                                    isDialog: true,
                                    isReadOnly: false,
                                    id: null,
                                    filter: null,
                                    dni: _this.denunciaDetail.EstablecimientoTrabajadorNIE
                                }
                            });
                            ref.afterClosed().subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                                        this.denunciaDetail.secure_id_EstablecimientoTrabajador = data.codi;
                                        this.denunciaDetail.secure_id_EstablecimientoTrabajador = data.secure_id;
                                        this.denunciaDetail.EstablecimientoTrabajadorNombre = data.nomComplet;
                                        this.denunciaDetail.EstablecimientoTrabajadorNIE = data.dni_pasp;
                                        this.skip = true;
                                    }
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        else {
                            _this.denunciaDetail.EstablecimientoTrabajadorNombre = '';
                        }
                    });
                }
            });
        }
        else {
            // Obrim dialeg per crear persona
            // this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
            // .then((confirmed) => {
            //   if (confirmed) {
            // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
            var ref = this.dialog.open(PersonesComponent, {
                width: '80%',
                height: '90%',
                closeOnNavigation: false,
                autoFocus: true,
                data: {
                    isDialog: true,
                    isReadOnly: false,
                    id: null,
                    filter: null
                }
            });
            ref.afterClosed().subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                        this.denunciaDetail.secure_id_EstablecimientoTrabajador = data.codi;
                        this.denunciaDetail.secure_id_EstablecimientoTrabajador = data.secure_id;
                        this.denunciaDetail.EstablecimientoTrabajadorNombre = data.nomComplet;
                        this.denunciaDetail.EstablecimientoTrabajadorNIE = data.dni_pasp;
                        this.skip = true;
                    }
                    return [2 /*return*/];
                });
            }); });
            //   } else {
            //     this.denunciaDetail.EstablecimientoTrabajadorNombre = '';            
            //   }
            // });
        }
    };
    /* #endregion */
    /* #region  OTHERS */
    // Calculem la data de descompte de la denuncia
    DenunciesNewComponent.prototype.calcularDataDescompte = function () {
        if (this.denunciaDetail.data !== null && this.denunciaDetail.data !== undefined) {
            this.denunciaDetail.dataDescompte = new Date();
            this.denunciaDetail.dataDescompte.setDate(this.denunciaDetail.data.getDate() + this.diesDescompte);
        }
    };
    DenunciesNewComponent.prototype.calcularImportDescompte = function () {
        if (this.denunciaDetail.import !== undefined && this.denunciaDetail.import !== null
            && this.denunciaDetail.porcentajeDescuento !== undefined && this.denunciaDetail.porcentajeDescuento !== null) {
            this.denunciaDetail.importDescompte = (this.denunciaDetail.import * (1 - (this.denunciaDetail.porcentajeDescuento / 100)));
        }
    };
    /* #region */
    DenunciesNewComponent.prototype.HandleChange = function (persona) {
        if (!this.skip) {
            if (persona === 'conductor' && this.denunciaDetail.dni_conductor !== this.conductorPreviousDNI) {
                this.denunciaDetail.secure_id_conductor = null;
                this.denunciaDetail.nom_conductor = null;
                this.denunciaDetail.secure_id_domiciliConductor = null;
                this.domicilisConductorDictionary = new BehaviorSubject(new Array());
                this.denunciaDetail.secure_id_domiciliConductor = null;
                this.conductorPreviousDNI = this.denunciaDetail.dni_conductor;
            }
            else if (persona === 'propietari' && this.denunciaDetail.dni_propietari !== this.propietariPreviousDNI) {
                this.denunciaDetail.secure_id_propietari = null;
                this.denunciaDetail.nom_propietari = null;
                this.denunciaDetail.secure_id_domiciliPropietari = null;
                this.domicilisPropietariDictionary = new BehaviorSubject(new Array());
                this.denunciaDetail.secure_id_domiciliPropietari = null;
            }
            else if (persona === 'treballador' && this.denunciaDetail.EstablecimientoTrabajadorNIE !== this.EstablecimientoTrabajadorPreviousNIE) {
                this.denunciaDetail.secure_id_EstablecimientoTrabajador = null;
                this.denunciaDetail.EstablecimientoTrabajadorNombre = null;
                this.EstablecimientoTrabajadorPreviousNIE = this.denunciaDetail.EstablecimientoTrabajadorNIE;
            }
            else if (persona === 'establiment' && this.denunciaDetail.EstablecimientoCIF !== this.EstablecimientoPreviousCIF) {
                this.denunciaDetail.secure_id_Establecimiento = null;
                this.EstablecimientoPreviousCIF = this.denunciaDetail.EstablecimientoCIF;
                this.denunciaDetail.EstablecimientoRazonSocial = null;
            }
            this.skip = false;
        }
    };
    return DenunciesNewComponent;
}());
export { DenunciesNewComponent };
