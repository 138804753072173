import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AgentsFilterService } from 'app/sections/configuracio/agents/services/agents-filter-service';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { AgentFilter } from 'app/sections/configuracio/agents/models/agent-filter';
import { Agent } from 'app/sections/configuracio/agents/models/agent.model';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ExcelDownloadService } from 'path-shared/services/excel/excel-download.service';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';

@Component({
  selector: 'app-agents-table',
  templateUrl: './agents-table.component.html',
  styleUrls: ['./agents-table.component.scss']
})
export class AgentsTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: Agent[];
  dataSource: CustomDataSource<Agent, AgentFilter>;
  highlightedRows = [];
  filter: AgentFilter = new AgentFilter('codi', 'asc', new Date());
  displayedItems = [];

  @Input() updatedFilterData: AgentFilter;
  @Input() selectButton: boolean;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['codi', 'categoria', 'nomComplert', 'dni', 'letraDni', 'obsolet', 'zonaBlava', 'agentPDA', 'dadoAlta', 'festivoHoy'];

  constructor(
    private agentsService: AgentsService,
    private filterService: FilterService,
    private excel: ExcelDownloadService,
    private dialog: MatDialog,
    private i18n: I18n
    ) {
      this.selectButton = false;
  }

  ngOnInit() {
    if (this.selectButton) {
      this.displayedColumns = ['incorpora'].concat(this.displayedColumns);
    }
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Agent, AgentFilter>(this.paginator, new AgentsFilterService(), 'urlGetAgents', 'urlAgentsPaginationCount');
    this.dataSource.subject.subscribe((data) => {
        data.forEach(item => {
          this.agentsService.displayedItems[item.SECURE_ID] = {
            id: item.SECURE_ID,
            checked: false
          };
        });

      this.displayedItems = this.agentsService.displayedItems;
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('agents-filter', this.filter); }
      }
    });
    if (!this.selectButton) {
      this.filterService.loadFilter('agents-filter', this.filter);
      if (this.filter.fechaActualConsulta === undefined || this.filter.fechaActualConsulta === null) {
        this.filter.fechaActualConsulta = new Date();
      }
    }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    } );
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row.SECURE_ID);
  }

  selectCurrent(codi, secure_id) {
       this.itemSelected.next({codi: codi, secure_id: secure_id});
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.agentsService, filter, this.i18n('Agents'), this.sort.direction, this.sort.active)
    });
  }

  estaDeAlta(agente: Agent) {
    let deAlta = false;

    deAlta = (
                (agente.fechaInicio === null || agente.fechaInicio === undefined) &&
                ((agente.fechaFin === null || agente.fechaFin === undefined) || (agente.fechaFin >= this.filter.fechaActualConsulta))
             )
             ||
             (
                ((agente.fechaFin === null || agente.fechaFin === undefined) && (agente.fechaInicio <= this.filter.fechaActualConsulta)) ||
                (agente.fechaInicio <= this.filter.fechaActualConsulta && agente.fechaFin >= this.filter.fechaActualConsulta)
             );
    return deAlta;
  }

  estaDeFiesta(agente: Agent): boolean {
    return agente.diasFestivos !== null &&
           agente.diasFestivos !== undefined &&
           agente.diasFestivos.find(a => new Date(a).getTime() === new Date(this.filter.fechaActualConsulta).getTime()) !== undefined;
  }
}
