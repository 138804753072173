<div class="row justify-content-between">
  <div class="col">
      <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  <div class="col">
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">
  
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements"  class="is-header-sticky">

      <!-- codi Column -->
      <ng-container matColumnDef="codi">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.codi}}</a></mat-cell>
      </ng-container>

      <!-- entidad Column -->
      <ng-container matColumnDef="entidad">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Entidad</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.entidad }}</mat-cell>
      </ng-container>

      <!-- idEnvio Column -->
      <ng-container matColumnDef="idEnvio">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>ID Envio</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.idEnvio }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="tipoEnvio">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipo envio</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.tipoEnvio }}</mat-cell>
          </ng-container>

         <!-- fechaCreacion Column -->
      <ng-container matColumnDef="fechaCreacion">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha creación</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.fechaCreacion | longDate }}</mat-cell>
      </ng-container>
        
      <ng-container matColumnDef="usuarioCreacion">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Usuario creación</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.usuarioCreacion }}</mat-cell>
      </ng-container>

      <!-- fechaEnvio Column -->
      <ng-container matColumnDef="fechaEnvio">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha envio</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.fechaEnvio | longDate}}</mat-cell>
      </ng-container>

      <!-- usuarioEnvio Column -->
      <ng-container matColumnDef="usuarioEnvio">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Usuario envio</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.usuarioEnvio}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="pdfBoe">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>PDF/BOE</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.pdfBoe}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="fechaNotiBoe">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha noti BOE</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.fechaNotiBoe}}</mat-cell>
      </ng-container>

      <!-- usuarioBOE Column -->
      <ng-container matColumnDef="usuarioBOE">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Usuario BOE</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.usuarioBOE}}</mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="pubBOE">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Publicación BOE</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.pubBOE}}</mat-cell>
    </ng-container>   

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>
