import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tool-button',
  templateUrl: './tool-button.component.html',
  styleUrls: ['./tool-button.component.scss']
})
export class ToolButtonComponent implements OnInit {
  @Output() public click: EventEmitter<MouseEvent> = new EventEmitter();
  @Input()
  set disabled(dis: boolean) {
    this.disabledValue = dis;
    this.color = this.colorValue;
  }
  get disabled() {
    return this.disabledValue;
  }
  @Input() text: string;
  @Input()
    set color(col: string) {
      this.colorValue = col;
      this.colorClass = 'actions-bar__button-icon';
      if (this.colorValue !== undefined) {
        if (!this.disabled) {
          this.colorClass += ' ' + this.colorValue;
        } else {
          this.colorClass += ' has-color-gray';
        }
      }
    } get color() {
      return this.colorValue;
    }
  @Input() icon: string;
  @Input() badge: string;
  @Input() badgeActive: boolean;
  @Input() badgeColor: string;
  @Input() propagate: boolean;
  @Input() isFile: boolean;
  @Input() src: string;
  /*set isFile(val: boolean) {

    if (val === null || val === undefined || !val) {
      this.itsFile = false;
    } else {
      this.itsFile = true;
    }
  }
  get isFile() {
    return this.disabledValue;
  }*/

  //itsFile: boolean;
  disabledValue: boolean;
  colorClass: string;
  iconClass = 'fa fa-question';
  badgeClass: string;
  colorValue: string;
  tipuCamp: string;

  constructor() {
    this.propagate = false;
    this.badgeColor = 'badge-primary';
    this.badge = '';
    this.badgeActive = false;
    this.isFile = false;
   }

  ngOnInit() {
    this.badgeClass = 'badge badge-pill badge-pill-floating-right ';
    if (this.iconClass !== undefined) {
      this.iconClass = 'fa ' + this.icon;
    }
    if (this.isFile === null || this.isFile === undefined || this.isFile === false) {
      this.tipuCamp = 'hidden';
    } else {
      this.tipuCamp = 'file';
    }
  }

  clickEvent(event) {
    if (!this.isFile) {
      if (!this.propagate) {
          event.stopPropagation();
      }
      if (!this.disabled) {
        this.click.emit(event);
      }
    }
  }

  fileReady(event) {
    if (!this.disabled) {
      this.click.emit(event);
    }
  }
}
