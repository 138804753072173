import { AuthGuardService } from './../../../../shared/services/authentication/auth-guard.service';
import { Component, OnInit, Input, Output, EventEmitter, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { AlarmaGps } from 'app/sections/alarmes/models/alarma-gps.model';
import { AlarmesGpsService } from '../../services/alarmesGps.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AlarmGroup } from 'path-shared/models/alarm-group';
import { AlarmeGpsFiltrat } from '../../models/alarmes-gps-filtrat';
import { StartupService } from 'path-shared/services/startup/startup.service';
import { Instituciones } from 'path-shared/models/instituciones';
import { Subscription } from 'rxjs';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { Parametros } from 'path-shared/models/parametros';
import { GoogleMapsService } from 'path-shared/services/startup/google-maps.service';
import { CFG_PARAMETROS } from 'path-models/CFG_PARAMETROS';
import { AudioService } from 'path-shared/services/audio/audio.service';
import { AlarmConfigurationComponent } from '../alarm-configuration/alarm-configuration.component';

export interface GroupBy {
  tipusLabel: string;
  isGroupBy: boolean;
}

@Component({
  selector: 'app-visor-alarmes',
  templateUrl: './visor-alarmes.component.html',
  styleUrls: ['./visor-alarmes.component.scss']
})


export class VisorAlarmesComponent implements OnInit, AfterViewInit, OnDestroy  {

  @Input() selectedAlarm: string;
  dialogData: ComponentDialog;
  private httpSubscription: Subscription;
  private alarmes: AlarmaGps [];
  ELEMENT_DATA: (AlarmaGps | GroupBy)[];
  private tipusAlarmes: AlarmGroup[];
  public mapPoints = [];
  public radius: number;
  public currentLocation: {
    latitude: number,
    longitude: number
  };
  public zoom: number;
  dataOld: Date;
  dataActual: Date;
  pdaChecked: boolean;
  altresChecked: boolean;
  carregantDefault: boolean;
  dataSourceTipus: AlarmGroup[];
  dataSource: (AlarmaGps | GroupBy)[];
  displayedColumns = ['incorpora', 'Smartphone', 'Agente', 'Data', 'accion'];
  displayedColumnsTipus = ['incorpora', 'tipusAlarma'];
  showTable: boolean;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  constructor(
    private googleMapsService: GoogleMapsService,
    private authGuard: AuthGuardService,
    private alarmesGpsService: AlarmesGpsService,
    private route: ActivatedRoute,
    public auth: AuthorizationService,
    private not: NotificationService,
    private parametersService: ParametersService,
    private audioService: AudioService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    private i18n: I18n) {
      this.dataActual = new Date();
      this.dataOld = this.dataActual;
      this.httpSubscription = this.alarmesGpsService.getTipusAlarmes().subscribe(tipus => {
      this.tipusAlarmes = tipus;
      this.dataSourceTipus = this.tipusAlarmes;
      this.selectedAlarm = this.route.snapshot.params.id;
      if (this.selectedAlarm !== undefined) {
        this.SetSelectedAlarm();
      } else {
        this.getAlarmesGps();
      }

      const inst = sessionStorage.getItem('currentInst');
      if (inst !== '' && inst !== undefined && inst !== 'undefined') {
        const institution: Instituciones = JSON.parse(inst);
        this.currentLocation = {latitude: institution.Latitud, longitude: institution.Longitud};
      }

      if (this.data !== undefined && this.data.id !== undefined) {
        this.dialogData = data;
        this.selectedAlarm = data.id;
        this.SetSelectedAlarm(true);
      }
    });
  }

  ngOnDestroy() {
    this.httpSubscription.unsubscribe();
  }


  ngOnInit() {

    /*if (this.dataActual === undefined) {
      this.dataActual = new Date();
    }*/
    this.zoom = Number.parseInt(this.parametersService.getParameter(Parametros.GPSZOMINICIAL));
    if (this.alarmes !== null && this.alarmes !== undefined ) {
      this.dataSource = this.alarmes;
      this.generateMapPoints(this.currentLocation, this.radius, 50);
    }
  }

  ngAfterViewInit() {
    console.log('alarmes after view init');
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Alarmes'));
    }
  }

  public generateMapPoints(centerpoint, distance, amount) {
    if ( !isNullOrUndefined(this.alarmes)) {
      this.mapPoints = [];

      this.alarmes.filter(g => g.selected === true).forEach(obj => {
        if (!isNullOrUndefined(obj.Latitud) && !isNullOrUndefined(obj.Longitud)) {

          if (obj.Id === this.selectedAlarm) {
            const res = {
              'latitude': obj.Latitud,
              'longitude': obj.Longitud,
              'radius': +0,
              'angle': +0,
              'animation': 'BOUNCE',
            };

          this.mapPoints.push(res);
          } else {
            const res = {
              'latitude': obj.Latitud,
              'longitude': obj.Longitud,
              'radius': +0,
              'angle': +0,
              'animation': 'DROP',
            };

          this.mapPoints.push(res);
          }


        }

      });
    }
  }

  checkPda(event) {
    if (!event) {
      this.pdaChecked = !this.pdaChecked;
    }
  }

  checkAltres(event) {
    if (!event) {
      this.altresChecked = !this.altresChecked;
    }
  }

  onDateChange(): void {
    if (this.dataOld === undefined  || this.dataActual.valueOf() !== this.dataOld.valueOf()) {
      this.getAlarmesGps();
      this.dataOld = this.dataActual;
    }
  }
  getAlarmesGps() {
    if ( this.carregantDefault === undefined || this.carregantDefault === false) {
      if (!isNullOrUndefined(this.dataActual) ) { // && this.tipusAlarmes.filter(g => g.selected === true).length > 0
        this.mapPoints = [];

        const data = this.GetData();

        const filtre = new AlarmeGpsFiltrat();
        filtre.data = data;
        filtre.tipus = this.tipusAlarmes.filter(g => g.idTipusAlarma === -1);

        this.alarmesGpsService.getAlarmesDayAndType(filtre).subscribe(result => {
          this.ELEMENT_DATA = new Array <GroupBy | AlarmaGps>();
          this.alarmes = result;
          this.tipusAlarmes.filter(val => val.idTipusAlarma === -1).forEach(tip => {
          // const gr: GroupBy = {tipusLabel: tip.tipusAlarma, isGroupBy: true};
          // this.ELEMENT_DATA.push(gr);
          this.alarmes.filter(al => al.TipusNum.toString() === tip.idTipusAlarma.toString()).forEach(element => {
              element.selected = false;
              this.ELEMENT_DATA.push(element);
              }
            );
          });

          this.dataSource = this.ELEMENT_DATA;
          if (this.alarmes === undefined   || this.alarmes.length < 1) {
              this.showTable = false;
          } else {
            this.showTable = true;
          }
        });
      }
    }
  }
  GetData(): string {
      let data = this.dataActual.getFullYear().toString();
      let month = (this.dataActual.getMonth() + 1).toString();
      if (month.length === 1) {
        month = '0' + month;
      }
      let day = this.dataActual.getDate().toString();
      if (day.length === 1) {
        day = '0' + day;
      }
      data += month + day;
      data += '00';
      data += '00';
      data += '00';
      return data;
  }

  Checked(row: any) {
    this.alarmes.filter(g => g.Id === row.id).forEach(element => {
      element.selected = row.selected;
    });
    this.generateMapPoints(this.currentLocation, this.radius, 50);
    this.currentLocation.latitude = row.Latitud;
    this.currentLocation.longitude = row.Longitud;
  }

  CheckedTipus(row: any) {
    if (!this.carregantDefault) {

      this.getAlarmesGps();
    }
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  SetSelectedAlarm(onlySelected: boolean = false) {
    // En cas que hi hagi una alarma seleccionada
    this.carregantDefault = true;
    this.tipusAlarmes.find(item => item.idTipusAlarma.toString() === '-1' ).selected = true;
    const filtre = new AlarmeGpsFiltrat();
    if (onlySelected) {
      filtre.id = this.selectedAlarm;
    }
    filtre.data = this.GetData();
    filtre.tipus = this.tipusAlarmes.filter(g => g.selected === true);

    this.alarmesGpsService.getAlarmesDayAndType(filtre).subscribe(result => {
          this.ELEMENT_DATA = new Array <GroupBy | AlarmaGps>();
      this.alarmes = result;
      this.tipusAlarmes.filter(val => val.selected === true).forEach(tip => {
        // const gr: GroupBy = {tipusLabel: tip.tipusAlarma, isGroupBy: true};
        // this.ELEMENT_DATA.push(gr);
        this.alarmes.filter(al => al.TipusNum.toString() === tip.idTipusAlarma.toString()).forEach(element => {
          if (element.Id === this.selectedAlarm) {
            this.currentLocation.latitude = element.Latitud;
          this.currentLocation.longitude = element.Longitud;
            element.selected = true;
          } else {
            element.selected = false;
          }
          this.ELEMENT_DATA.push(element);
          }
        );
        this.dataSource = this.ELEMENT_DATA;
        if (this.alarmes === undefined   || this.alarmes.length < 1) {
            this.showTable = false;
        } else {
          this.showTable = true;
        }
        this.carregantDefault = false;
        this.generateMapPoints(this.currentLocation, this.radius, 50);
      });
    });
  }

  AlarmRead(secure_id) {
    this.alarmesGpsService.setAlarmaGpsVista(secure_id).subscribe(() => {
      this.audioService.stop();
      const res = {data : 'null'};
      this.not.onAlarmesGpsPendentsChange.emit(res);
      this.getAlarmesGps();
    });
  }

  openModal()
  {
    this.dialog.open(AlarmConfigurationComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      width: '30%',
    });
    
  }
}
