var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var ShortDatePipe = /** @class */ (function (_super) {
    __extends(ShortDatePipe, _super);
    function ShortDatePipe(i18n) {
        var _this = _super.call(this, 'ca') || this;
        _this.i18n = i18n;
        return _this;
    }
    ShortDatePipe.prototype.transform = function (value, args) {
        if (value === null) {
            return '';
        }
        else {
            var date = new Date(value);
            if (date.getFullYear() >= 2100 || date.getFullYear() <= 1900) {
                return this.i18n('sense data');
            }
            else {
                return _super.prototype.transform.call(this, value, 'dd/MM/yyyy');
            }
        }
    };
    return ShortDatePipe;
}(DatePipe));
export { ShortDatePipe };
