import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { BonosResidentesFilter } from '../models/bonos-residentes-filter';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class BonosResidentesFilterService extends CustomFilterService {
  public createFilterParams(filter: BonosResidentesFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('id',  Reflection.hasValue(filter.id) ? filter.id.toString() : '')
    .append('importe', Reflection.hasValue(filter.importe) ? filter.importe.toString() : '')
    .append('nombre', filter.nombre ? filter.nombre : '')
    .append('apellidos', filter.apellidos ? filter.apellidos : '')
    .append('zona', filter.zona ? filter.zona : '')
    .append('telefono', filter.telefono ? filter.telefono : '')
    .append('matricula', filter.matricula)
    .append('dni', filter.dni ? filter.dni : '')
    .append('tipResPrepago', filter.tipResPrepago !== null ? filter.tipResPrepago.toString() : '')
    .append('tipBono', filter.tipBono !== null ? filter.tipBono.toString() : '')
    .append('pago', filter.pago ? filter.pago : '')
    .append('dataIni', filter.dataIni !== null ? filter.dataIni.toDateString() : '')
    .append('dataFi', filter.dataFi !== null ? filter.dataFi.toDateString() : '')
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
