/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./empty-row.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./empty-row.component";
var styles_EmptyRowComponent = [i0.styles];
var RenderType_EmptyRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmptyRowComponent, data: {} });
export { RenderType_EmptyRowComponent as RenderType_EmptyRowComponent };
export function View_EmptyRowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null))], null, null); }
export function View_EmptyRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-row", [], null, null, null, View_EmptyRowComponent_0, RenderType_EmptyRowComponent)), i1.ɵdid(1, 114688, null, 0, i2.EmptyRowComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmptyRowComponentNgFactory = i1.ɵccf("app-empty-row", i2.EmptyRowComponent, View_EmptyRowComponent_Host_0, {}, {}, []);
export { EmptyRowComponentNgFactory as EmptyRowComponentNgFactory };
