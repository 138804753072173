import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { RespostesFilter } from '../models/respostes-filter';

const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class RespostesFilterService extends CustomFilterService {
  public createFilterParams(filter: RespostesFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('codi', filter.codi)
    .append('descripcio', filter.descripcio)
    .append('acceptacio', filter.acceptacio !== null ? filter.acceptacio.toString() : '')
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
