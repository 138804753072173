import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class GruaTecnicsFilter extends FilterData {
  codi: number;
  nom: string;
  DNI: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.codi = null;
    this.nom = '';
    this.DNI = '';

    this.labels['codi'] = this.i18n('Codi');
    this.labels['nom'] = this.i18n('Nom');
    this.labels['DNI'] = this.i18n('DNI');
  }
}
