import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { DenunciaCobrarModel } from 'app/sections/denuncies/models/denuncia-cobrar.model';

@Component({
  selector: 'app-denuncia-pagament-parcial',
  templateUrl: './denuncia-pagament-parcial.component.html'
})
export class DenunciaPagamentParcialComponent implements OnInit {

  denunciaCobrar: DenunciaCobrarModel;

  message: string;
  motiu: string;
  cobrarTancarExpedient: string;
  cobrarMantenirExpedient: string;

  constructor(
    public auth: AuthorizationService,
    public thisDialogRef: MatDialogRef<DenunciaPagamentParcialComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    this.denunciaCobrar = modalData.data;

    this.message = this.i18n('L\'import cobrat és inferior al de l\'expedient. Vol cobrar i tancar l\'expedient?');
    this.cobrarTancarExpedient = this.i18n('Cobrar i tancar expedient');
    this.cobrarMantenirExpedient = this.i18n('Cobrar i mantenir expedient obert');
  }

  ngOnInit() {

  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  cobrarITancarExpedient() {
    this.denunciaCobrar.parcialCobrarITancar = true;
    this.denunciaCobrar.motiuCobrarITancar = this.motiu;
    this.thisDialogRef.close(this.denunciaCobrar);
  }

  cobrarIMantenirExpedientObert() {
    this.denunciaCobrar.parcialCobrarITancar = false;
    this.thisDialogRef.close(this.denunciaCobrar);
  }

}
