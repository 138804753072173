import { Injector } from '@angular/core';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AuthGuardService } from '../authentication/auth-guard.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
var GoogleMapsService = /** @class */ (function () {
    function GoogleMapsService(injector) {
        this.injector = injector;
    }
    GoogleMapsService.prototype.getMapsApiKey = function () {
        var idInstitucion = localStorage.getItem('IdInstitucion');
        var token = localStorage.getItem('access_token');
        if (idInstitucion !== undefined && idInstitucion !== null && token !== undefined && token !== null) {
            // Ya se habia iniciado la web, tenemos las credenciales para actualizar la key de googleMaps.
            if (this.authGuard.canActivate()) {
                return this.get(config.urls['apiUrl'] + api.endpoints['urlConfigMapsApiKey'], null);
            }
            else {
                return undefined;
            }
        }
        else {
            return undefined;
        }
    };
    GoogleMapsService.prototype.get = function (url, params, hide) {
        if (hide === void 0) { hide = false; }
        return this.http.get(url.replace('{hostname}', window.location.hostname), { params: params, headers: this.createAuthorizationHeader(hide) });
    };
    GoogleMapsService.prototype.createAuthorizationHeader = function (hide) {
        if (hide === void 0) { hide = false; }
        var token = localStorage.getItem('access_token');
        var idInstitucion = localStorage.getItem('IdInstitucion');
        return new HttpHeaders()
            .append('Authorization', 'Bearer ' + token)
            .append('X-Origin', 'SomDenWeb')
            .append('Allow-Control-Allow-Origin', '*')
            .append('Institucio', idInstitucion)
            .append(hide ? 'hideProgress' : 'void', '');
    };
    Object.defineProperty(GoogleMapsService.prototype, "http", {
        get: function () {
            return this.injector.get(HttpClient);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GoogleMapsService.prototype, "cookieService", {
        get: function () {
            return this.injector.get(CookieService);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GoogleMapsService.prototype, "authGuard", {
        get: function () {
            return this.injector.get(AuthGuardService);
        },
        enumerable: true,
        configurable: true
    });
    return GoogleMapsService;
}());
export { GoogleMapsService };
