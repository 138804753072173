import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { BancCarreguesC60 } from '../../models/banc-carregues-c60.model';
import { BancCarreguesC60Service } from '../../services/banc-carregues-c60.service';
import { BancCarreguesC60ResumDetall } from '../../models/banc-carregues-c60-resum-detall.model';
import { BancCarreguesC60DetallTableComponent } from '../banc-carregues-c60-table-detall/banc-carregues-c60-detall-table.component';

@Component({
  selector: 'app-banc-carregues-c60-editor',
  templateUrl: './banc-carregues-c60-editor.component.html',
  styleUrls: ['./banc-carregues-c60-editor.component.scss']
})
export class BancCarreguesC60EditorComponent implements OnInit {

  secure_id: string;
  bancCarreguesC60: BancCarreguesC60;
  bancCarreguesC60Detall: BancCarreguesC60ResumDetall;
  dialogData: ComponentDialog;
  closeButtonText: string;
  private file: File;
  YaProcesado: boolean;

  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild(BancCarreguesC60DetallTableComponent) bancCarreguesC60DetallTable: BancCarreguesC60DetallTableComponent;
  @ViewChild('fileInput') fileInput: any;
  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private bancCarreguesC60Service: BancCarreguesC60Service,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<BancCarreguesC60EditorComponent>,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.bancCarreguesC60 = Builder.bancCarreguesC60();
      this.dialogData = data;
      this.secure_id = this.dialogData.id;
      if (this.dialogData.isReadOnly) {
        this.closeButtonText = this.i18n('Tancar');
      } else {
        this.closeButtonText = this.i18n('Llista');
      }
   }

  ngOnInit() {
    if (this.secure_id !== null) {
      this.bancCarreguesC60Service.getBancCarregaC60(this.secure_id).subscribe(data => {
        this.bancCarreguesC60 = data;
        this.YaProcesado = (this.bancCarreguesC60.situacio === 'F');
      });
    }
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {

      this.bancCarreguesC60Service.setBancCarregaC60(this.bancCarreguesC60).subscribe(result => {
        this.toastr.info(this.i18n('Càrrega C60 guardada correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la càrrega C60?'))
    .then((confirmed) => {
        if (confirmed) {
          // TODO
          // this.bancCarreguesC60Service.removeColor(this.secure_id).subscribe(result => {
          //   this.toastr.success(this.i18n('Càrrega C60 esborrada correctament'));
          //   this.dialog.closeAll();
          //   this.thisDialogRef.close(true);
          // });
        }
      });
  }

  carregaDetall() {
      // const data: ComponentDialog = Builder.newComponentDialog(true, false, this.bancCarreguesC60.id.toString());

      const ref = this.dialog.open(BancCarreguesC60DetallTableComponent, {
        width: '70%',
        height: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          secureId: this.bancCarreguesC60.secureId,
          id: this.bancCarreguesC60.id.toString(),
          isReadOnly: this.dialogData.isReadOnly,
          carga: this.bancCarreguesC60,
        }
      });

      ref.afterClosed().subscribe(updated => {
        if (updated) { this.bancCarreguesC60DetallTable.load(); }
      });
  }

  fileReady(file) {
    this.file = file;
  }
  Afegir() {
    const event = new MouseEvent('click', {bubbles: false});
    this.fileInput.file.nativeElement.dispatchEvent(event);
  }
  uploadFile(file) {
    // if(this.bancCarreguesC60.secureId !== undefined && this.bancCarreguesC60 !== null && this.bancCarreguesC60.secureId !== '') {
    //   const reader = new FileReader();
    //   const fitxer: File = file;
    //   this.bancCarreguesC60Service.cargarFichero(fitxer).subscribe( x => {
    //     var result = x;
    //   });
    // }else{
       this.getBase64(file).then((data: string) => {
        this.bancCarreguesC60.fileString = data;
        this.bancCarreguesC60.nomFitxer = file.name;
      });
   // }
    // return this.fileService.setFile(config.urls['apiUrl'] + api.endpoints['urlUpladFileAgent'], this.contenedor, file, fitxer.name).subscribe(data => {
    //   const  nouFitxer = data;
    //   if (this.llistaFitxers === null) {
    //     this.llistaFitxers = new Array<FitxerModel>();
    //   }
    //   this.llistaFitxers.push(nouFitxer);
    //   this.dataSource.data  =  this.llistaFitxers;
    //   const exts = nouFitxer.NOMBRE_FICHERO.substr(nouFitxer.NOMBRE_FICHERO.lastIndexOf('.') + 1).toLowerCase();
    //     if ( exts === 'jpg' || exts === 'jpeg' || exts === 'png') {
    //       const image = this.fileService.getImage(nouFitxer.SOM_ID, 'L').subscribe(
    //         detailsdata => {
    //           this.dict[nouFitxer.SOM_ID] = this.buildImage(detailsdata);
    //         });
    //     } else {
    //       const image = this.fileService.getPdf(nouFitxer.SOM_ID).subscribe(
    //         detailsdata => {
    //           this.dict[nouFitxer.SOM_ID] = this.buildPdf(detailsdata);
    //         });
    //     }
    // });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let formattedResult = reader.result.toString();
          const indexTo = formattedResult.indexOf('base64,');
          formattedResult = formattedResult.substr(indexTo + 'base64,'.length);
          resolve(formattedResult);
        };
        reader.onerror = error => reject(error);
    });
  }
}
