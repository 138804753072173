import { ContentTab } from './content-tab.model';

export class Tab {
    id: string;
    contingut: Array<ContentTab>;
    pendentsLlegir: number;

    constructor(id: string, contentTab: Array<ContentTab>) {
        this.id = id;
        contentTab.forEach( content => {
            this.contingut.push(new ContentTab(content.idContingut, content.isMe, content.fechaHora, content.mensaje, content.fitxers));
        });
    }
}