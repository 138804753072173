var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var FormatDatePipe = /** @class */ (function (_super) {
    __extends(FormatDatePipe, _super);
    function FormatDatePipe(i18n) {
        var _this = _super.call(this, 'ca') || this;
        _this.i18n = i18n;
        return _this;
    }
    FormatDatePipe.prototype.transform = function (value, includeHours) {
        if (value === null) {
            return '';
        }
        else {
            var formato = 'dd/MM/yyyy';
            var date = new Date(value);
            var test = value.toString().substr(0, 4) + '/'
                + value.toString().substr(4, 2) + '/' + value.toString().substr(6, 2);
            if (includeHours !== undefined && includeHours === true) {
                test += ' ' + value.toString().substr(8, 2) + ':' + value.toString().substr(10, 2) + ':' + value.toString().substr(12, 2);
                formato = 'dd/MM/yyyy HH:mm:ss';
            }
            return _super.prototype.transform.call(this, test, formato);
        }
    };
    return FormatDatePipe;
}(DatePipe));
export { FormatDatePipe };
