var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'codi';
var defaultSortDirection = 'asc';
var AparellsFilterService = /** @class */ (function (_super) {
    __extends(AparellsFilterService, _super);
    function AparellsFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AparellsFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('id', filter.id)
            .append('descripcion', filter.descripcion)
            .append('tipoRadar', filter.tipoRadar)
            .append('fechaRevisionInicio', Reflection.empty(filter.fechaRevisionInicio) ? null : filter.fechaRevisionInicio.toDateString())
            .append('fechaRevisionFin', Reflection.empty(filter.fechaRevisionFin) ? null : filter.fechaRevisionFin.toDateString())
            .append('fechaCaducidadInicio', Reflection.empty(filter.fechaCaducidadInicio) ? null : filter.fechaCaducidadInicio.toDateString())
            .append('fechaCaducidadFin', Reflection.empty(filter.fechaCaducidadFin) ? null : filter.fechaCaducidadFin.toDateString())
            .append('margenErrorInicio', Reflection.empty(filter.margenErrorInicio) ? null : filter.margenErrorInicio.toString())
            .append('margenErrorFin', Reflection.empty(filter.margenErrorFin) ? null : filter.margenErrorFin.toString())
            .append('modelo', filter.modelo)
            .append('marca', filter.marca)
            .append('numeroSerie', filter.numeroSerie)
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return AparellsFilterService;
}(CustomFilterService));
export { AparellsFilterService };
