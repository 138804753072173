<!-- Toolbar con los botones de acciones sobre los elementos de la tabla -->
<app-toolbar>
  <app-upload-tool-button-component text="Afegir" i18n-text (uploadFile)="this.uploadFile($event)" color="has-color-blue" icon="fas fa-file-medical">
  </app-upload-tool-button-component>
  <app-tool-button text="Descarregar" i18n-text (click)="this.downloadFiles();" color="has-color-brown" icon="fas fa-file-download" 
    [disabled]="this.currentSelects.length <= 0">
  </app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.deleteFiles();" color="has-color-red" icon="fa-trash-alt"
    [disabled]="this.currentSelects.length <= 0">
  </app-tool-button>
</app-toolbar>

<!-- Tabla con los nombres de los ficheros (y opcion de seleccionarlos) -->
<div class="table-sdw is-fixed"  style="margin-bottom: 5%;">
  <mat-table #tablaFicherosView [dataSource]="this.filesDataSource" matSort aria-label="Elements"  class="is-header-sticky">
    
    <!-- Checkbox seleccion fichero -->
    <ng-container matColumnDef="select">
      <!-- Titulo columna (checkbox para seleccionar todos los registros) -->
      <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="this.selectAll()" [checked]="this.isAllSelected()">
          </mat-checkbox>
      </mat-header-cell>
      <!-- Valores de la columna (checkboxes para seleccion individual de cada registro) -->
      <mat-cell *matCellDef="let row">
        <mat-checkbox (change)="this.selectRow(row)" [checked]="this.isRowSelected(row.key)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <!-- Nombre Fichero -->
    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom fitxer</mat-header-cell>
      <mat-cell *matCellDef="let row"><a (click)="this.clickRow(row)" class="is-link">{{row.value}}</a></mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected': this.isRowSelected(row.Key)}">
    </mat-row>
  </mat-table>
</div>