import { Injectable } from "@angular/core";
import { ZbRutasFilter } from "./zb-rutas-filter";
import { HttpParams } from "@angular/common/http";
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";

@Injectable()
export class ZbRutasFilterService extends CustomFilterService {
    public createFilterParams(filter: ZbRutasFilter, start: number,pageSize:number, sortField:string,sortDir:string) {
        return new HttpParams()
            .append('somId',filter.somId ? filter.somId.toString() : '')
            .append('nombre',filter.nombre ? filter.nombre.toString() : '')
            .append('descripcion', filter.descripcion ? filter.descripcion.toString() : '')
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField)
            .append('sortdir', sortDir);
    }
}