<div class="row justify-content-between">
  <app-form-text-edit id="diposit-table-matricula" class="col-xl-2" i18n-label label="Matrícula" [(value)]="this.matriculaFilter" (focusout)="matriculaFilterChange($event)"  [isReadOnly]="false" ></app-form-text-edit>
  <app-form-multi-select style="width:20%" [data]="diposits" [(selectedValues)]="this.filter.diposits" i18n-titulo (selectedValuesChange)="load()" tituloMultiSelect="Dipòsits" i18n-tituloMultiSelect></app-form-multi-select>

  <div class="col">
      <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  <div class="col">
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">
  
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements"  class="is-header-sticky">

      <!-- codi Column -->
      <ng-container matColumnDef="idDipositVehicle">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.idDipositVehicle}}</a></mat-cell>
      </ng-container>

      <!-- dataPropostaPda Column -->
      <ng-container matColumnDef="idvehicleFK">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.idVehicleFk }}</mat-cell>
      </ng-container>

      <!-- data Column -->
      <ng-container matColumnDef="dataEntrada">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data entrada</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.dataEntrada | shortDate }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="horaEntrada">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora entrada</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.horaEntrada }}</mat-cell>
          </ng-container>

         <!-- fechaAcepDeneg Column -->
      <ng-container matColumnDef="dataSortida">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data sortida</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.dataSortida | shortDate }}</mat-cell>
      </ng-container>
        
      <ng-container matColumnDef="horaSortida">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora sortida</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.horaSortida }}</mat-cell>
      </ng-container>

      <!-- dataFinalitzacio Column -->
      <ng-container matColumnDef="importCobrat">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Import cobrat</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.importCobrat}}</mat-cell>
      </ng-container>

      <!-- idVehiclesFK Column -->
      <ng-container matColumnDef="motiuEntrada">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Motiu entrada</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.motiuEntrada}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="llocRetirada">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Lloc</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.llocRetirada}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="formapagament">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Mètode pagament</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.formapagament}}</mat-cell>
      </ng-container>

      <!-- idAgentFk Column -->
      <ng-container matColumnDef="tecnicGrua">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tècnic</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.tecnicGrua}}</mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="numAlbara">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Albarà</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.numAlbara}}</mat-cell>
    </ng-container>

        <!-- Imatges Column -->
        <ng-container matColumnDef="imatges">
            <mat-header-cell *matHeaderCellDef mat-sort-header><i class="fa fa-images" text="Imatges" i18n-text></i></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div *ngIf="row.numImatges > 0">
                    <a (click)="this.viewImages(row.iddenuncia, row.somid)" class="is-link">
                        <i class="imatges-n fa fa-images"></i> {{row.numImatges}}
                    </a>
                </div>
                <div *ngIf="row.numImatges === 0">
                    <i class="imatges-0 fa fa-images"></i>
                </div>
            </mat-cell>
        </ng-container>

    

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>
