import { CookieService } from 'ngx-cookie-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { FormatDatePipe } from 'path-shared/pipes/format-date-pipe';
import { FilterData } from 'path-shared/models/filter-data';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { Observable, Subscription } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ProgressBarTramitacionDto } from 'app/sections/tramitar/expedients/models/ProgressBarTramitacionDto';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-download-excel-dialog',
  templateUrl: './download-excel-dialog.component.html',
  styleUrls: ['./download-excel-dialog.component.scss']
})
export class DownloadExcelDialogComponent implements OnInit {
  private generator: ExcelGenerator;
  private filter: FilterData;
  private generated: Subscription;
  private fileName: string;
  addDate: boolean;
  private sortDir = '';
  private sortField = '';

  missatgeProgresExcel: string = '';
  percentatgeExcel: number;

  constructor(
    public thisDialogRef: MatDialogRef<DownloadExcelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    private i18n: I18n,
    private not: NotificationService,
    private cookie: CookieService
    ) {
      this.addDate = true;
      this.fileName = 'Excel';
      this.generated = null;
      this.generator = data['generator'];
      this.filter = data['filter'];
      this.fileName = data['fileName'];
      this.sortDir = data['sortDirection'];
      this.sortField = data['sortField'];
    }

  ngOnInit() {
    this.addDate = this.cookie.get('DownloadExcelDialogComponent.addDate') !== 'false';
  }

  close(ok) {
    this.cookie.set('DownloadExcelDialogComponent.addDate', this.addDate.toString(), 99999, '/');
    if (!ok && this.generated !== null) {
      this.generated.unsubscribe();
    }
    this.thisDialogRef.close(ok);
  }

  download() {
    this.initProgressBar();
    this.generated = this.generator.generateExcel(this.filter, this.sortDir, this.sortField).subscribe((result) => {
      if(this.fileName == this.i18n('DeteccioMatricules')) {
        let fileName = this.fileName + '.zip'; // Change file extension to .zip
  
        const blob = new Blob([result], { type: 'application/zip' });
    
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute('download', fileName);
    
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    
        this.close(true); 
      }
      else {
        // const date = new FormatDatePipe(this.i18n).transform( this.data,  'dd/MM/yyyy');
      let fileName = this.fileName;
      // if (this.addDate) {
      //   fileName += '-' + date;
      // }
      var extension:string=".xlsx";
      var type:string= 'application/vnd.ms-excel;charset=utf-8';
      if(result && result.body && result.body.type && result.body.type=="text/csv"){
        extension=".csv";
        type="text/csv;charset=utf-8";
        result=result.body;
      }
      fileName += extension;
  
      FileDownloader.downLoadFile([result], type, fileName);
      this.close(true);
      }
    }, error => {
      this.generated = null;
    });
    this.thisDialogRef.updatePosition();
  }

  isDownloading() {
    return this.generated !== null;
  }

  initProgressBar() {
    // region Progress Bar
    this.not.onProgressBarChange.pipe(
      filter((ProgressBar) =>
          ProgressBar.token === sessionStorage.getItem('access_token')
      )
    ).subscribe((progressBar) => {
        this.missatgeProgresExcel = progressBar.text;
        this.percentatgeExcel = progressBar.value;
      });
  }
}
