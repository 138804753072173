import { SEG_USUARIOS } from 'path-models/SEG_USUARIOS';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PermissionItem } from '../../models/permissionItem.model';
import { SEG_GRUPOS } from 'path-models/SEG_GRUPOS';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PagesPermissions } from 'path-shared/models/pagesPermissions';
import { Usuari } from 'app/sections/usuaris/models/usuari.model';

@Component({
  selector: 'app-permission-editor',
  templateUrl: './permission-editor.component.html',
  styleUrls: ['./permission-editor.component.scss']
})
export class PermissionEditorComponent implements OnInit {
  item: PermissionItem;
  groups: SEG_GRUPOS[];
  users: Usuari[];
  permission: PagesPermissions;
  selectedItemCode: string;

  constructor(
    public thisDialogRef: MatDialogRef<PermissionEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PermissionItem,
    private toastr: ToastrService,
    private i18n: I18n
  ) {
    this.groups = data['groups'];
    this.users = data['users'];
    this.permission = data['permission'];
    if (data['item'] !== null) {
      this.item = data['item'];
      this.selectedItemCode = this.generateCode(this.item.RoleCode, this.item.Type);
    } else {
      this.item = {SECURE_ID: '', PermissionCode: this.permission.Code, PermissionName: '',
        RoleCode: undefined, RoleName: '', Read: false, Create: false, Update: false, Delete: false, Type: 0};
    }
  }


  mostrar:boolean=false;

  ngOnInit() {
    if (this.groups.length === 0 && this.users.length === 0) {
      this.toastr.warning(
        this.i18n('Ja has assignat permisos a tots els usuaris i grups!')
      );
    }
  }

  public cancel() {
    this.thisDialogRef.close(null);
  }

  cambiar(){
    this.mostrar=true;
  }

  public accept() {
    if (this.item.RoleCode === undefined) {
      this.toastr.warning(
        this.i18n('S\'ha de seleccionar un grup per assignar permisos!')
      );
    } else {
      this.thisDialogRef.close(this.item);
    }
  }

  assigneeSelected(event) {
    this.item.Type = parseInt(event.target.selectedOptions[0].attributes['type'].value, 10);
    this.item.RoleCode = this.getCode(event.target.selectedOptions[0].value);
    this.selectedItemCode = this.generateCode(this.item.RoleCode, this.item.Type);
  }


  private getCode(generatedCode: string): number {
    return parseInt(generatedCode.split('|')[0], 10);
  }

  public generateCode(code, type) {
    return code + '|' + type;
  }
}
