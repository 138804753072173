import { OnInit, EventEmitter } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Dictionary } from 'path-shared/models/dictionary';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { DipositGruaService } from '../../services/diposit-grua-service';
import { VehiclesService } from 'app/sections/ciutat/vehicles/services/vehicles.service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { GruaPendentsService } from '../../services/grua-pendents.service';
import { Builder } from 'path-shared/models/builder';
import { MatDialog } from '@angular/material';
import { VehiclesComponent } from 'app/sections/ciutat/vehicles/pages/vehicles/vehicles.component';
import { TramitadesComponent } from '../tramitades/tramitades.component';
import { BehaviorSubject } from 'rxjs';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { formatDate } from '@angular/common';
var DipositEditorGeneralComponent = /** @class */ (function () {
    function DipositEditorGeneralComponent(i18n, dipositService, vehiculoService, confirmationDialogService, toastr, dialog, gruaSerevice, auth) {
        var _this = this;
        this.i18n = i18n;
        this.dipositService = dipositService;
        this.vehiculoService = vehiculoService;
        this.confirmationDialogService = confirmationDialogService;
        this.toastr = toastr;
        this.dialog = dialog;
        this.gruaSerevice = gruaSerevice;
        this.auth = auth;
        this.cobrarDesctivat = true;
        this.dictTipusCobro = [];
        this.dictFormaPagament = [];
        this.tecnicsList = [];
        this.valueChange = new EventEmitter();
        this.valorAutoCompleteTipusCobro = new BehaviorSubject('');
        this.valorAutoCompleteFormaPagament = new BehaviorSubject('');
        this.valorAutoCompleteTipoVehiculoDictionary = new BehaviorSubject('');
        this.valorAutoCompleteMotiuEntradaDictionary = new BehaviorSubject('');
        this.valorAutoCompleteDipositsDictionary = new BehaviorSubject('');
        this.valorAutoCompleteTecnic = new BehaviorSubject('');
        this.dipositService.getReports(this.auth.Pages.DipositGrua).subscribe(function (res) {
            _this.report = res.filter(function (x) { return x.nom == 'DIPOSITVEHICLE_CARTA_PAGAMENT_CASTELLDEFELS'; })[0];
        });
    }
    Object.defineProperty(DipositEditorGeneralComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            var _this = this;
            this.val = val;
            setTimeout(function () {
                _this.valueChange.emit(_this.val);
            }, 10);
        },
        enumerable: true,
        configurable: true
    });
    DipositEditorGeneralComponent.prototype.ngOnInit = function () {
        this.createDicTipusCobro();
        this.createDicFormaPagament();
        this.getTiposVehiculos();
        this.getMotivosEntrada();
        this.getDiposits();
        this.loadTecnics();
        this.refsC60 = this.value.somC60;
    };
    DipositEditorGeneralComponent.prototype.getDiposits = function () {
        var _this = this;
        this.dipositsDictionary = new Array();
        this.dipositService.getDipositsCombo().subscribe(function (x) {
            x.forEach(function (element) {
                _this.dipositsDictionary.push(new Dictionary(element.idDiposit.toString(), element.carrer));
            });
            _this.setDefalutDiposit();
        });
    };
    DipositEditorGeneralComponent.prototype.getMotivosEntrada = function () {
        var _this = this;
        this.motisEntradaDictionary = new Array();
        this.dipositService.getAllMotiusEntradaGrua().subscribe(function (x) {
            x.forEach(function (element) {
                _this.motisEntradaDictionary.push(new Dictionary(element.somId.toString(), element.descripcio));
            });
            _this.setDefalutValuesCombosMotivosEntrada();
        });
    };
    DipositEditorGeneralComponent.prototype.getTiposVehiculos = function () {
        var _this = this;
        this.tipoVehiculoDictionary = new Array();
        this.vehiculoService.getAllTipusVehciles().subscribe(function (x) {
            x.forEach(function (element) {
                _this.tipoVehiculoDictionary.push(new Dictionary(element.id.toString(), element.descripcio));
            });
            _this.setDefalutValuesCombosTipusVehiculo();
        });
    };
    DipositEditorGeneralComponent.prototype.createDicTipusCobro = function () {
        var _this = this;
        this.dictTipusCobro = new Array();
        // this.dictTipusCobro.push(new Dictionary('1',  this.i18n('A cobrar tot')));
        // this.dictTipusCobro.push(new Dictionary('2',  this.i18n('Només enganxament')));
        // this.dictTipusCobro.push(new Dictionary('3',  this.i18n('Només custòdia')));
        // this.dictTipusCobro.push(new Dictionary('4',  this.i18n('Sense càrrec')));
        this.dipositService.getAllTiposCobro().subscribe(function (x) {
            x.forEach(function (element) {
                _this.dictTipusCobro.push({ 'id': element.idTipoPago.toString(), 'descripcio': element.descripcion });
            });
            _this.setDefalutValuesCombosTipusCobro();
        });
    };
    // tipusCobroSeleccionado(idTipusCobro: string) {
    //   const tipusSelected = this.dictTipusCobro.find(x => x.key === idTipusCobro);
    //   this.value.tipusCalcul =
    //   this.value.idTipoPago = tipusSelected.value;
    //   return this.value.idTipoPago = tipusSelected.key;
    // }
    DipositEditorGeneralComponent.prototype.tipoVehiculoSeleccionado = function (idTipoVehiculo) {
        var tipusSelected = this.tipoVehiculoDictionary.find(function (x) { return x.key === idTipoVehiculo; });
        this.value.idtipusvehicleFK = tipusSelected.key;
        this.value.txtTipusVehicle = tipusSelected.value;
    };
    // formaPagamentSeleccionado(idFormaPagament: string) {
    //   const tipusSelected = this.dictFormaPagament.find(x => x.key === idFormaPagament);
    //   return this.value.formapagament = tipusSelected.value;
    // }
    DipositEditorGeneralComponent.prototype.motiuEntradaSeleccionado = function (idMotivoEntrada) {
        var motivoEntradaSelected = this.motisEntradaDictionary.find(function (x) { return x.key === idMotivoEntrada; });
        this.value.idMotiuEntradaFK = motivoEntradaSelected.key;
    };
    DipositEditorGeneralComponent.prototype.dipositSeleccionado = function (idDiposit) {
        var dipositSelected = this.dipositsDictionary.find(function (x) { return x.key === idDiposit; });
        this.value.idDipositFK = dipositSelected.key;
    };
    DipositEditorGeneralComponent.prototype.setDefalutValuesCombosTipusVehiculo = function () {
        var _this = this;
        if (this.value.idtipusvehicleFK !== undefined && this.value.idtipusvehicleFK !== null && this.value.idtipusvehicleFK !== ''
            && this.tipoVehiculoDictionary.length > 0) {
            var tipusVehicleSelcted = this.tipoVehiculoDictionary.find(function (x) { return x.key === _this.value.idtipusvehicleFK.toString(); });
            this.comboTipoVehiculo.setForcedValueComb(tipusVehicleSelcted.value);
        }
    };
    DipositEditorGeneralComponent.prototype.setDefalutValuesCombosMotivosEntrada = function () {
        var _this = this;
        if (this.value.idMotiuEntradaFK !== undefined && this.value.idMotiuEntradaFK !== null && this.motisEntradaDictionary.length > 0) {
            var motiEntradaSelected = this.motisEntradaDictionary.find(function (x) { return x.key === _this.value.idMotiuEntradaFK.toString(); });
            this.comboMotiuEntrada.setForcedValueComb(motiEntradaSelected.value);
        }
    };
    DipositEditorGeneralComponent.prototype.setDefalutValuesCombosTipusCobro = function () {
        var _this = this;
        if (this.value.idTipoPago !== undefined && this.value.idTipoPago !== null && this.dictTipusCobro.length > 0) {
            var tipusCalculSelcted = this.dictTipusCobro.find(function (x) { return x.key === _this.value.idTipoPago.toString(); });
            this.comboTipusCobro.setForcedValueComb(tipusCalculSelcted.value);
        }
    };
    DipositEditorGeneralComponent.prototype.setDefalutDiposit = function () {
        var _this = this;
        if (this.value.idDipositFK !== undefined && this.value.idDipositFK !== null && this.value.idDipositFK !== ''
            && this.dipositsDictionary.length > 0) {
            var dipositSelcted = this.dipositsDictionary.find(function (x) { return x.key === _this.value.idDipositFK.toString(); });
            this.comboDiposits.setForcedValueComb(dipositSelcted.value);
        }
    };
    DipositEditorGeneralComponent.prototype.createDicFormaPagament = function () {
        this.dictFormaPagament = new Array();
        this.dictFormaPagament.push({ 'id': '10', 'descripcio': this.i18n('Efectiu') });
        this.dictFormaPagament.push({ 'id': '20', 'descripcio': this.i18n('Targeta') });
        // this.dictFormaPagament.push({'id': '40',  'descripcio': this.i18n('Carta de pago')});
        this.dictFormaPagament.push({ 'id': '50', 'descripcio': this.i18n('App Movil') });
        this.setDefalutValuesCombosFormaPagament();
    };
    DipositEditorGeneralComponent.prototype.setDefalutValuesCombosFormaPagament = function () {
        var _this = this;
        if (this.value.formapagament !== undefined && this.value.formapagament !== null && this.value.formapagament !== ''
            && this.dictFormaPagament.length > 0) {
            var formaPagamentSelected = this.dictFormaPagament.find(function (x) { return x.value === _this.value.formapagament; });
            if (formaPagamentSelected !== undefined && formaPagamentSelected !== null) {
                this.comboFormaPagament.setForcedValueComb(formaPagamentSelected.key);
            }
        }
    };
    DipositEditorGeneralComponent.prototype.loadTecnics = function () {
        var _this = this;
        this.tecnicsDictionary = new Array();
        this.gruaSerevice.getAllTecnicsCombo().subscribe(function (x) {
            _this.tecnicsList = x;
            x.forEach(function (element) {
                _this.tecnicsDictionary.push(new Dictionary(element.somId.toString(), element.somId.toString()));
            });
            _this.setDefalutValuesCombosTecnic();
        });
    };
    DipositEditorGeneralComponent.prototype.tecnicSeleccionado = function (secureIdTecnic) {
        // const tecnicSelected = this.tecnicsList.find(x => x.somId === secureIdTecnic);
        this.value.tecnicGrua = secureIdTecnic;
    };
    DipositEditorGeneralComponent.prototype.setDefalutValuesCombosTecnic = function () {
        // agent
        if (this.value.tecnicGrua !== undefined && this.value.tecnicGrua !== null && this.value.tecnicGrua !== ''
            && this.tecnicsDictionary.length > 0) {
            this.comboTecnics.setForcedValueComb(this.value.tecnicGrua.toString());
        }
    };
    DipositEditorGeneralComponent.prototype.calcular = function () {
        var _this = this;
        if (this.value.importCobrat != null) {
            this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Si executa aquest càlcul es perdran les dades del cobrament generat el dia '
                + formatDate(this.value.dataCalcul, 'yyyy-MM-dd', 'es_ES')
                + ' a les ' + this.value.horaCalcul +
                ', està segur que vol continuar?'))
                .then(function (confirmed) {
                if (confirmed) {
                    _this.setTxtTipusVehiclePreventSave();
                    _this.dipositService.calculatePrice(_this.value).subscribe(function (x) {
                        if (x !== undefined && x !== null) {
                            _this.value = x;
                        }
                    });
                    _this.cobrarDesctivat = false;
                    _this.refsC60 = _this.value.somC60;
                    _this.generateReportCastelldefels();
                }
            });
        }
        else {
            this.setTxtTipusVehiclePreventSave();
            this.dipositService.calculatePrice(this.value).subscribe(function (x) {
                if (x !== undefined && x !== null) {
                    _this.value = x;
                }
            });
            this.cobrarDesctivat = false;
            this.refsC60 = this.value.somC60;
            this.generateReportCastelldefels();
        }
    };
    DipositEditorGeneralComponent.prototype.generateReportCastelldefels = function () {
        var _this = this;
        if (this.value.formapagament == '40') {
            this.dipositService.generateReport(this.value.secureId, this.report.id).subscribe(function (file) {
                var name = _this.report.nom;
                var contentType = file.headers.get('Content-Type');
                if (contentType !== null && contentType !== undefined) {
                    FileDownloader.downLoadFile([file.body], contentType + ';charset=utf-8', name.toString());
                }
                else {
                    _this.toastr.error(_this.i18n('No s\'ha pogut generar el fitxer'));
                }
                // if (name === 'DIPOSITVEHICLE_CARTA_PAGAMENT_CASTELLDEFELS') {
                //   this.update.next(true);
                // }
            });
        }
    };
    DipositEditorGeneralComponent.prototype.isCalcularButtonDisabled = function () {
        return this.value.idtipusvehicleFK === undefined ||
            this.value.idtipusvehicleFK === '' ||
            this.value.idTipoPago === undefined ||
            this.value.idTipoPago === null ||
            (this.value.numFactura !== undefined &&
                this.value.numFactura !== null &&
                this.value.numFactura !== '');
    };
    DipositEditorGeneralComponent.prototype.cobrarDesactivat = function () {
        if (this.value.formapagament == '40' && this.value.importCobrat !== null && this.value.importCobrat !== undefined)
            this.cobrarDesctivat = false;
        if (this.cobrarDesctivat)
            return true;
        else if (this.value !== undefined && this.value !== null && this.value.titularnom !== undefined
            && this.value.titularnom !== null && this.value.titularnom !== ''
            && this.value.titularnif !== undefined && this.value.titularnif !== null
            && this.value.titularnif !== ''
            && this.value.titulardireccio !== undefined && this.value.titulardireccio !== null
            && this.value.titulardireccio !== ''
            && this.value.titularpoblacio !== undefined && this.value.titularpoblacio !== null
            && this.value.titularpoblacio !== ''
            && this.value.titularCP !== undefined && this.value.titularCP !== null
            && this.value.titularCP !== '') {
            return false;
        }
        else
            return true;
    };
    DipositEditorGeneralComponent.prototype.cobrar = function () {
        var _this = this;
        this.dipositService.cobrarDepositoVehiculo(this.value).subscribe(function (x) {
            if (x !== undefined && x !== null) {
                _this.toastr.success(_this.i18n('COBRAMENT FET!'));
                _this.value = x;
            }
        });
    };
    DipositEditorGeneralComponent.prototype.seleccionarVehicle = function () {
        var _this = this;
        var data = Builder.newComponentDialog(true);
        var ref = this.dialog.open(VehiclesComponent, {
            width: '80%',
            closeOnNavigation: false,
            autoFocus: true,
            data: data
        });
        ref.afterClosed().subscribe(function (res) {
            if (res.matricula !== undefined && res.matricula !== null && res.matricula !== '') {
                // this.denunciaDetail.secure_id_vehicle = res.secure_id;
                _this.value.idVehicleFk = res.matricula;
            }
        });
    };
    DipositEditorGeneralComponent.prototype.seleccionarProposta = function () {
        var _this = this;
        var data = Builder.newComponentDialog(true);
        var ref = this.dialog.open(TramitadesComponent, {
            width: '80%',
            closeOnNavigation: false,
            autoFocus: true,
            data: data
        });
        ref.afterClosed().subscribe(function (res) {
            if (res !== undefined && res !== null) {
                var prop = res;
                _this.value.iddenuncia = prop.codi;
                _this.value.idVehicleFk = prop.idVehiclesFK;
                if (prop.nombreCalle !== undefined && prop.nombreCalle !== null) {
                    _this.value.llocRetirada = prop.nombreCalle;
                }
                else {
                    _this.value.llocRetirada = ' ';
                }
                if (prop.numCarrer !== undefined && prop.numCarrer !== null) {
                    _this.value.llocRetirada = _this.value.llocRetirada + prop.numCarrer;
                }
                if (prop.idTecnicFK !== undefined && prop.idTecnicFK !== null) {
                    _this.value.tecnicGrua = prop.idTecnicFK;
                }
                if (prop.sinCargo !== undefined && prop.sinCargo !== null) {
                    _this.value.sinCargo = prop.sinCargo;
                }
                _this.comboTecnics.setForcedValueComb(_this.value.tecnicGrua.toString());
                if (prop.vehicle !== undefined && prop.vehicle !== null
                    && prop.vehicle.tipus_vehicle !== undefined && prop.vehicle.tipus_vehicle !== null
                    && prop.vehicle.tipus_vehicle !== '') {
                    _this.value.idtipusvehicleFK = prop.vehicle.tipus_vehicle;
                    var tipusVehicleSelcted = _this.tipoVehiculoDictionary.find(function (x) { return x.key === _this.value.idtipusvehicleFK.toString(); });
                    if (tipusVehicleSelcted !== undefined && tipusVehicleSelcted !== null) {
                        _this.comboTipoVehiculo.setForcedValueComb(tipusVehicleSelcted.value);
                        _this.value.txtTipusVehicle = tipusVehicleSelcted.value;
                    }
                }
                // volen que seleccionem el primer diposit quan es carrega una proposta...
                if ((_this.value.idDipositFK === undefined || _this.value.idDipositFK === null || _this.value.idDipositFK === '')
                    && _this.dipositsDictionary !== undefined && _this.dipositsDictionary !== null && _this.dipositsDictionary.length > 0) {
                    var dipositSelcted = _this.dipositsDictionary[0];
                    _this.value.idDipositFK = dipositSelcted.key;
                    _this.comboDiposits.setForcedValueComb(dipositSelcted.value);
                }
                // this.value.idtipusvehicleFK = prop
            }
        });
    };
    DipositEditorGeneralComponent.prototype.consultarVehicle = function () {
        var _this = this;
        if (this.value.idVehicleFk !== undefined && this.value.idVehicleFk !== null && this.value.idVehicleFk !== '') {
            this.vehiculoService.checkExistByMatricula(this.value.idVehicleFk).subscribe(function (x) {
                if (x === undefined || x === null || x === false) {
                    _this.toastr.warning(_this.i18n('ATENCIÓ: Vehicle no existent, primer s\' ha de donar d\'alta o seleccionar un existent!'));
                    _this.value.idVehicleFk = '';
                    _this.seleccionarVehicle();
                }
            });
        }
    };
    DipositEditorGeneralComponent.prototype.setTxtTipusVehiclePreventSave = function () {
        var _this = this;
        if (this.value.idtipusvehicleFK !== undefined && this.value !== null
            && this.value.idtipusvehicleFK !== undefined && this.value.idtipusvehicleFK !== null
            && this.value.idtipusvehicleFK !== '') {
            var tipusVehicleSelcted = this.tipoVehiculoDictionary.find(function (x) { return x.key === _this.value.idtipusvehicleFK.toString(); });
            if (tipusVehicleSelcted !== undefined && tipusVehicleSelcted !== null) {
                this.value.txtTipusVehicle = tipusVehicleSelcted.value;
            }
        }
    };
    DipositEditorGeneralComponent.prototype.getCanvi = function () {
        var canvi = '0';
        var entregat = 0;
        if (typeof this.value.importEntregat === 'string') {
            var importEntregat = this.value.importEntregat;
            entregat = Number(importEntregat.replace(',', '.'));
        }
        else {
            entregat = this.value.importEntregat;
        }
        if (this.value !== undefined && this.value !== null &&
            this.value.importcalculat !== undefined && this.value.importcalculat !== null && this.value.importcalculat > 0 &&
            entregat !== undefined && entregat !== null && entregat > 0) {
            // let entregat: number = Number(this.value.importEntregat.toFixed(2));
            // let calculat: number = Number(this.value.importcalculat.toFixed(2));
            var canviNumber = (entregat - this.value.importcalculat);
            canvi = canviNumber.toFixed(2);
        }
        return canvi;
    };
    return DipositEditorGeneralComponent;
}());
export { DipositEditorGeneralComponent };
