var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var TipusEnviamentsFilter = /** @class */ (function (_super) {
    __extends(TipusEnviamentsFilter, _super);
    function TipusEnviamentsFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.tipoEnvio = '';
        _this.descripcion = '';
        _this.bop = null;
        _this.producto = '';
        _this.cliente = '';
        _this.codigoAdmision = '';
        _this.labels['tipoEnvio'] = _this.i18n('Tipo envío');
        _this.labels['descripcion'] = _this.i18n('Descripción');
        _this.labels['bop'] = _this.i18n('BOP');
        _this.labels['producto'] = _this.i18n('Producto');
        _this.labels['cliente'] = _this.i18n('Cliente');
        _this.labels['codigoAdmision'] = _this.i18n('Codigo Admisión');
        return _this;
    }
    return TipusEnviamentsFilter;
}(FilterData));
export { TipusEnviamentsFilter };
