var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var GenericFileFilter = /** @class */ (function (_super) {
    __extends(GenericFileFilter, _super);
    function GenericFileFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.id = '';
        _this.nombre = '';
        _this.fechaGravacion = '';
        _this.eliminado = '';
        _this.tipoRecurso = '';
        _this.idRegistroAsociado = '';
        return _this;
    }
    return GenericFileFilter;
}(FilterData));
export { GenericFileFilter };
