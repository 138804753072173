import { VehicleFilter } from '../../models/vehicle-filter';
import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-vehicles-filter',
  templateUrl: './vehicles-filter.component.html',
  styleUrls: ['./vehicles-filter.component.scss']
})
export class VehiclesFilterComponent implements OnInit {

  subscription: Subscription;
  filter: VehicleFilter;
  tipusLegislacions = [];
  @Output() notify: EventEmitter<VehicleFilter> = new EventEmitter<VehicleFilter>();

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(public thisDialogRef: MatDialogRef<VehiclesFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data,) { 
      this.filter = data.filter;
    //this.tipusLegislacions = data.tipusLegislacions;
    }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
      this.filter.updated();
      this.notify.emit(this.filter);
      this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
