<app-filter-header title="Filtrar zonas" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-filter-text-edit class="col-2" id="zones-aparcare-filter-codi" i18n-label label="Codi" [(value)]="this.filter.codi"></app-filter-text-edit>
    <app-filter-text-edit class="col-6" id="zones-aparcare-filter-descripcio" i18n-label label="Descripció" [(value)]="this.filter.descripcio"></app-filter-text-edit>
    <app-form-date-edit class="col-2" id="zones-aparcare-filter-dataIni" i18n-label label="Data inici" [(value)]="this.filter.dataInici" ></app-form-date-edit>
    <app-form-date-edit class="col-2" id="zones-aparcare-filter-dataFi" i18n-label label="Data fi" [(value)]="this.filter.dataFi"></app-form-date-edit>   
      
  </div>
</div>
