<div class="row">
<div class="col-md-4">
  <app-form-date-edit i18n-label label="Fecha inicio" [(value)]="DataInici" type="calendar"></app-form-date-edit>
</div>
<div class="col-md-4">
  <app-form-date-edit i18n-label label="Fecha fin" [(value)]="DataFi" type="calendar"></app-form-date-edit>
</div>
</div>
<div class="row" style="text-align:center">
  <button class="btn btn-sq-lg btn-success" (click)="this.Impressio()">
    <span i18n>OK</span>
  </button>  
</div>