import { GoogleMapsService } from 'path-shared/services/startup/google-maps.service';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MapFeatures } from './../models/map-features';
import { PropostaGruaDto } from './../../grua/Models/proposta-grua.model';
import { CustomDataMarker } from './../models/custom-data-marker';
import { MapComponent } from './../components/map/map.component';
import { LeyendaOpcionSeleccionada } from './../models/leyenda-opcion-seleccionada';
import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { MapService } from '../services/map.service';
import { Alarma } from 'app/sections/alarmes/models/alarma.model';
import { AlarmaGps } from 'app/sections/alarmes/models/alarma-gps.model';
import { DenunciaResumenDto } from 'app/sections/denuncies/models/denuncia-resumen-dto';
import { PropostaGruaFilter } from 'app/sections/grua/Models/proposta-grua-filter';
import { PropostaResumenDto } from 'app/sections/grua/Models/proposta-resumen-dto';
import { Tracking } from 'app/sections/utilitats/tracking/models/tracking.model';
import { LiveUpdatesService } from '../services/live-updates.service';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { Parametros } from 'path-shared/models/parametros';
import { DenunciesEditorComponent } from 'app/sections/denuncies/components/denuncies-editor/denuncies-editor.component';
import { Builder } from 'path-shared/models/builder';
import { AlarmesComponent } from 'app/sections/alarmes/pages/alarmes/alarmes.component';
import { AlarmaFilter } from 'app/sections/alarmes/models/alarma-filter';
import { PendentsComponent } from 'app/sections/grua/components/pendents/pendents.component';
import { VisorAlarmesComponent } from 'app/sections/alarmes/components/visor-alarmes/visor-alarmes.component';
import { GruaPendentsService } from 'app/sections/grua/services/grua-pendents.service';
import { CustomDataPoligon } from '../models/custom-poligon';
import { EscalaOcupacionService } from 'app/sections/zona-blava/estacionament/escala-ocupacio/services/escala-ocupacion.service';
import { EscalaOcupacion } from 'app/sections/zona-blava/estacionament/escala-ocupacio/model/escala-ocupacion';
import { PositonCoord } from 'app/sections/zona-blava/estacionament/tramos/tramos/models/position';
import { Tramo } from 'app/sections/zona-blava/estacionament/tramos/tramos/models/tramo.model';

@Component({
  selector: 'app-livemap-page',
  templateUrl: './livemap-page.component.html',
  styleUrls: ['./livemap-page.component.scss']
})
export class LivemapPageComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('map') mapComponent: MapComponent;

  private liveUpdates: LiveUpdatesService;
  public zoomMapa: number;
  private alwaysVisible: boolean;

  private currentUpdatesAlarmesSistema = false;
  private currentUpdatesAlarmesSmartphones = false;
  private currentUpdatesTrackingSmartphones = false;
  private currentUpdatesTrackingGrua = false;
  private currentUpdatesPropostesPendentsGrua = false;
  private currentUpdatesPropostesAcceptadesGrua = false;
  private currentUpdatesPropostesRebutjadesGrua = false;
  private currentUpdatesPropostesRetiradesGrua = false;
  private currentUpdatesDenunciesPolicia = false;
  private currentUpdatesDenunciesZonaBlava = false;
  private currentUpdatesOcupacion = false;

  constructor(
    public dialog: MatDialog,
    private not: NotificationService,
    private i18n: I18n,
    private mapService: MapService,
    private parametersService: ParametersService,
    private propostesService: GruaPendentsService,
    private authGuard: AuthGuardService,
    private googleMapsService: GoogleMapsService,
    private escalaOcupacionService: EscalaOcupacionService
  ) {
    this.liveUpdates = LiveUpdatesService.getInstance(this.not);
    this.zoomMapa = 0;
    this.alwaysVisible = false;
  }

  ngOnInit() {
    this.zoomMapa = Number.parseInt(this.parametersService.getParameter(Parametros.GPSZOMINICIAL));
  }

  ngAfterViewInit() {
    this.not.titleChange(this.i18n('Live Map'));
  }

  ngOnDestroy(): void {
    // Cuando salimos del map nos desuscribimos SIEMPRE de los eventos.
    this.descargaAlarmasSistema(false);
    this.descargaAlarmasSmartphones(false);
    this.descargaTrackingPda(false);
    this.descargaTrackingGrua(false);
    this.descargaDenunciasPolicia(false);
    this.descargaDenunciasZonaAzul(false);
    this.descargarPropuestasGruaAceptadas(false);
    this.descargarPropuestasGruaPendientes(false);
    this.descargarPropuestasGruaRechazadas(false);
    this.descargarPropuestasGruaRetiradas(false);
    this.descargarOcupacionCalor(false);
    this.descargarOcupacionGeneral(false);
  }

  updateMap(valor: LeyendaOpcionSeleccionada) {
    switch (valor.opcionSeleccionada) {
      case MapFeatures.DENUNCIES_POLICIA:
        this.descargaDenuncias(valor.valor, valor.opcionSeleccionada);
        break;
      case MapFeatures.DENUNCIES_ZONA_BLAVA:
        this.descargaDenuncias(valor.valor, valor.opcionSeleccionada);
        break;
      case MapFeatures.ALARMES_SISTEMA:
        this.descargaAlarmasSistema(valor.valor);
        break;
      case MapFeatures.ALARMES_SMARTPHONES:
        this.descargaAlarmasSmartphones(valor.valor);
        break;
      case MapFeatures.TRACKING_PDA:
        this.descargaTrackingPda(valor.valor);
        break;
      case MapFeatures.TRACKING_GRUA:
        this.descargaTrackingGrua(valor.valor);
        break;
      // case MapFeatures.MAQUINES:
      //   this.descargaMaquinas(valor.valor);
      //   break;
      // case MapFeatures.OCUPACIO:
      //   this.descargaOcupacion(valor.valor);
      //   break;
      // case MapFeatures.OCUPACIO_PARKING:
      //   this.descargaOcupacionParking(valor.valor);
      //   break;
      // case MapFeatures.RETIRADES_GRUA:
      //   this.descargaRetiradasGrua(valor.valor);
      //   break;
      case MapFeatures.PROPOSTES_GRUA_ACCEPTADES:
        this.descargaPropuestasGrua(valor.valor, valor.opcionSeleccionada);
        break;
      case MapFeatures.PROPOSTES_GRUA_PENDENTS:
        this.descargaPropuestasGrua(valor.valor, valor.opcionSeleccionada);
        break;
      case MapFeatures.PROPOSTES_GRUA_REBUTJADES:
        this.descargaPropuestasGrua(valor.valor, valor.opcionSeleccionada);
        break;
      case MapFeatures.PROPOSTES_GRUA_RETIRADES:
        this.descargaPropuestasGrua(valor.valor, valor.opcionSeleccionada);
        break;
      case MapFeatures.OCUPACIO_GENERAL:
        this.descargaOcupacion(valor.valor, valor.opcionSeleccionada);
        break;
      case MapFeatures.OCUPACIO_CALOR:
        this.descargaOcupacion(valor.valor, valor.opcionSeleccionada);
        break;
      // case MapFeatures.SENYALS_VERTICALS:
      //   this.descargaSenalesVerticales(valor.valor);
      //   break;
    }
  }

  descargaAlarmasSistema(descarga: Boolean) {
    if (descarga) {
      // Descargamos la informacion base.
      this.mapService.descargaAlarmasSistema().subscribe((result: Alarma[]) => {
        this.currentUpdatesAlarmesSistema = true;
        result.forEach(info => {
          const marker = this.crearMarker(info.id, MapFeatures.ALARMES_SISTEMA, info.text, info.latitud, info.longitud, '#fc0303', '\uf0f3');
          this.liveUpdates.markersAlarmasSistema.push(marker);
        });
        this.mapComponent.appendNewMarkers(this.liveUpdates.markersAlarmasSistema);

        // Nos suscribimos a los cambios.
        this.liveUpdates.subscribeToAlarmasSistema(
          this.liveUpdates.onAlarmasSistemaChange.subscribe((newAlarmas: Alarma[]) => {
            if (newAlarmas !== undefined && newAlarmas !== null && newAlarmas.length > 0) {
              // Hemos recibido las alarmas actuales. Comprovamos si hay alguna nueva.
              const newMarkes = new Array<CustomDataMarker>();
              newAlarmas.forEach(a => {
                if (this.liveUpdates.markersAlarmasSistema.find(dM => dM.associatedResourceId === a.id) === undefined) {
                  // Es una nueva alarma.
                  const marker = this.crearMarker(a.id, MapFeatures.ALARMES_SISTEMA, a.text, a.latitud, a.longitud, '#fc0303', '\uf0f3');
                  newMarkes.push(marker);
                }
              });
              if (newMarkes.length > 0) {
                this.mapComponent.appendNewMarkers(newMarkes);
                this.liveUpdates.markersAlarmasSistema = this.liveUpdates.markersAlarmasSistema.concat(newMarkes);
              }
            }
          })
        );
      });
    } else {
      if (this.currentUpdatesAlarmesSistema) {
        this.mapService.desactivaAlarmasSistema().subscribe(result => {
          this.currentUpdatesAlarmesSistema = false;
          // Eliminamos los markers y nos desuscribimos de los updates.
          this.mapComponent.removeMarkersOfType(MapFeatures.ALARMES_SISTEMA);
          this.liveUpdates.unsubscribeAlarmasSistema();
        });
      }
    }
  }

  descargaAlarmasSmartphones(descarga: Boolean) {
    if (descarga) {
      this.mapService.descargaAlarmasPda().subscribe((result: AlarmaGps[]) => {
        this.currentUpdatesAlarmesSmartphones = true;
        result.forEach(info => {
          let infoWindowContent = '<div class="row col-12"><span i18n>PDA: %pda%</span></div><div class="row col-12"><span i18n>Agent: %agent%</span></div>';
          infoWindowContent = infoWindowContent.replace('%pda%', info.Text).replace('%agent%', info.Usuari);
          const marker = this.crearMarker(info.Id, MapFeatures.ALARMES_SMARTPHONES, infoWindowContent, info.Latitud, info.Longitud, '#f803fc', '\uf0f3');
          this.liveUpdates.markersAlarmasSmartphone.push(marker);
          this.mapComponent.appendNewMarkers(this.liveUpdates.markersAlarmasSmartphone);
        });

        // Nos suscribimos a los cambios.
        this.liveUpdates.subscribeToAlarmasSmartphones(
          this.liveUpdates.onAlarmasSmartphoneChange.subscribe((newAlarmas: AlarmaGps[]) => {
            if (newAlarmas !== undefined && newAlarmas !== null && newAlarmas.length > 0) {
              // Hemos recibido las alarmas actuales. Comprovamos si hay alguna nueva.
              const newMarkes = new Array<CustomDataMarker>();
              newAlarmas.forEach(a => {
                if (this.liveUpdates.markersAlarmasSmartphone.find(dM => dM.associatedResourceId === a.Id) === undefined) {
                  // Es una nueva alarma. Creamos un layout para mostrar la info en el marker.
                  let infoWindowContent = '<div class="row"><span i18n>PDA: %pda%</span></div><div class="row"><span i18n>Agent: %agent%</span></div>';
                  infoWindowContent = infoWindowContent.replace('%pda%', a.Text).replace('%agent%', a.Usuari);
                  const marker = this.crearMarker(a.Id, MapFeatures.ALARMES_SMARTPHONES, infoWindowContent, a.Latitud, a.Longitud, '#f803fc', '\uf0f3');
                  newMarkes.push(marker);
                }
              });
              if (newMarkes.length > 0) {
                this.mapComponent.appendNewMarkers(newMarkes);
                this.liveUpdates.markersAlarmasSmartphone = this.liveUpdates.markersAlarmasSmartphone.concat(newMarkes);
              }
            }
          })
        );
      });
    } else {
      if (this.currentUpdatesAlarmesSmartphones) {
        this.mapService.desactivaAlarmasSpartphones().subscribe(result => {
          this.currentUpdatesAlarmesSmartphones = false;
          // Eliminamos los markers y nos desuscribimos de los updates.
          this.mapComponent.removeMarkersOfType(MapFeatures.ALARMES_SMARTPHONES);
          this.liveUpdates.unsubscribeAlarmasSmartphones();
        });
      }
    }
  }

  descargaTrackingPda(descarga: Boolean) {
    if (descarga) {
      this.mapService.descargaTrackingPda().subscribe((result: Tracking[]) => {
        this.currentUpdatesTrackingSmartphones = true;
        result.forEach(element => {
          let infoWindowContent = '<div class="row col-12"><span i18n>PDA: %pda%</span></div><div class="row col-12"><span i18n>Agent: %agent%</span></div>';
          infoWindowContent = infoWindowContent.replace('%pda%', element.PDA).replace('%agent%', element.Agente);
          const marker = this.crearMarker(element.Agente, MapFeatures.TRACKING_PDA, infoWindowContent, element.LatitudInici, element.LongitudInici, '#0CC0EC', '\uf10b');
          this.liveUpdates.markersTrackingPda.push(marker);
          this.mapComponent.appendNewMarkers(this.liveUpdates.markersTrackingPda);
        });
        // Nos suscribimos a los cambios.
        this.liveUpdates.subscribeToTrackingPda(
          this.liveUpdates.onTrackingPdaChange.subscribe((newTracking: Tracking[]) => {
            if (newTracking !== undefined && newTracking !== null && newTracking.length > 0) {
              // Hemos recibido el tracking actual. Comprovamos si hay algun valor nuevo.
              const newMarkers = new Array<CustomDataMarker>();
              const removingMarkersIds = new Array<CustomDataMarker>();
              // Comprovamos los nuevos valores con los antiguos.
              newTracking.forEach(t => {
                const markerToFind = this.liveUpdates.markersTrackingPda.find(dM => dM.associatedResourceId === t.Agente);
                if (markerToFind !== undefined) {
                  // Ya teniamos la posicion del agente, debemos ver si se ha movido.
                  if (markerToFind.latitude !== t.LatitudInici || markerToFind.longitude !== t.LongitudInici) {
                    // La pda se ha movido, añadimos el nuevo marker y eliminamos el anterior.
                    let infoWindowContent = '<div class="row col-12"><span i18n>PDA: %pda%</span></div><div class="row col-12"><span i18n>Agent: %agent%</span></div>';
                    infoWindowContent = infoWindowContent.replace('%pda%', t.PDA).replace('%agent%', t.Agente);
                    const marker = this.crearMarker(t.Agente, MapFeatures.TRACKING_PDA, infoWindowContent, t.LatitudInici, t.LongitudInici, '#0CC0EC', '\uf10b');
                    newMarkers.push(marker);
                    removingMarkersIds.push(markerToFind.associatedResourceId);
                  } // No hay mas casos en los que debamos hacer nada en esta rama de ejecucion.
                } else {
                  // No estaba pintado, debemos añadir el marker.
                  let infoWindowContent = '<div class="row col-12"><span i18n>PDA: %pda%</span></div><div class="row col-12"><span i18n>Agent: %agent%</span></div>';
                  infoWindowContent = infoWindowContent.replace('%pda%', t.PDA).replace('%agent%', t.Agente);
                  const marker = this.crearMarker(t.Agente, MapFeatures.TRACKING_PDA, infoWindowContent, t.LatitudInici, t.LongitudInici, '#0CC0EC', '\uf10b');
                  newMarkers.push(marker);
                }
              });
              if (removingMarkersIds.length > 0) {
                this.mapComponent.removeMarkersOfIds(removingMarkersIds);
                removingMarkersIds.forEach(id => {
                  const index = this.liveUpdates.markersTrackingPda.findIndex(i => i.associatedResourceId === id);
                  if (index !== -1) {
                    this.liveUpdates.markersTrackingPda.splice(index, 1);
                  }
                });
              }
              if (newMarkers.length > 0) {
                this.mapComponent.appendNewMarkers(newMarkers);
                this.liveUpdates.markersTrackingPda = this.liveUpdates.markersTrackingPda.concat(newMarkers);
              }
            }
          })
        );
      });
    } else {
      if (this.currentUpdatesTrackingSmartphones) {
        this.mapService.desactivaTrackingPda().subscribe(result => {
          this.currentUpdatesTrackingSmartphones = false;
          this.mapComponent.removeMarkersOfType(MapFeatures.TRACKING_PDA);
          this.liveUpdates.unsubscribeTrackingPda();
        });
      }
    }
  }

  descargaTrackingGrua(descarga: Boolean) {
    if (descarga) {
      this.mapService.descargaTrackingGrua().subscribe((result: Tracking[]) => {
        this.currentUpdatesTrackingGrua = true;
        result.forEach(element => {
          let infoWindowContent = '<div class="row col-12"><span i18n>Agent: %agent%</span></div>';
          infoWindowContent = infoWindowContent.replace('%agent%', element.Agente);
          const marker = this.crearMarker(element.Agente, MapFeatures.TRACKING_GRUA, infoWindowContent, element.LatitudInici, element.LongitudInici, '#06C832', '\uf63c');
          this.liveUpdates.markersTrackingGrua.push(marker);
          this.mapComponent.appendNewMarkers(this.liveUpdates.markersTrackingGrua);
        });

        // Nos suscribimos a los cambios.
        this.liveUpdates.subscribeToTrackingGrua(
          this.liveUpdates.onTrackingGruaChange.subscribe((newTracking: Tracking[]) => {
            if (newTracking !== undefined && newTracking !== null && newTracking.length > 0) {
              // Hemos recibido el tracking actual. Comprovamos si hay algun valor nuevo.
              const newMarkers = new Array<CustomDataMarker>();
              const removingMarkersIds = new Array<CustomDataMarker>();
              // Comprovamos los valores nuevos con los antiguos.
              newTracking.forEach(t => {
                const markerToFind = this.liveUpdates.markersTrackingGrua.find(dM => dM.associatedResourceId === t.Agente);
                if (markerToFind !== undefined) {
                  // Ya teniamos la posicion del agente, debemos ver si se ha movido.
                  if (markerToFind.latitude !== t.LatitudInici || markerToFind.longitude !== t.LongitudInici) {
                    // La pda se ha movido, añadimos el nuevo marker y eliminamos el anterior.
                    let infoWindowContent = '<div class="row col-12"><span i18n>Agent: %agent%</span></div>';
                    infoWindowContent = infoWindowContent.replace('%agent%', t.Agente);
                    const marker = this.crearMarker(t.Agente, MapFeatures.TRACKING_GRUA, infoWindowContent, t.LatitudInici, t.LongitudInici, '#06C832', '\uf63c');
                    newMarkers.push(marker);
                    removingMarkersIds.push(markerToFind.associatedResourceId);
                  } // No hay mas casos en los que debamos hacer nada en esta rama de ejecucion.
                } else {
                  // No estaba pintado, debemos añadir el marker.
                  let infoWindowContent = '<div class="row col-12"><span i18n>Agent: %agent%</span></div>';
                  infoWindowContent = infoWindowContent.replace('%agent%', t.Agente);
                  const marker = this.crearMarker(t.Agente, MapFeatures.TRACKING_GRUA, infoWindowContent, t.LatitudInici, t.LongitudInici, '#06C832', '\uf63c');
                  newMarkers.push(marker);
                }
              });
              if (removingMarkersIds.length > 0) {
                this.mapComponent.removeMarkersOfIds(removingMarkersIds);
                removingMarkersIds.forEach(id => {
                  const index = this.liveUpdates.markersTrackingGrua.findIndex(i => i.associatedResourceId === id);
                  if (index !== -1) {
                    this.liveUpdates.markersTrackingGrua.splice(index, 1);
                  }
                });
              }
              if (newMarkers.length > 0) {
                this.mapComponent.appendNewMarkers(newMarkers);
                this.liveUpdates.markersTrackingGrua = this.liveUpdates.markersTrackingGrua.concat(newMarkers);
              }
            }
          })
        );
      });
    } else {
      if (this.currentUpdatesTrackingGrua) {
        this.mapService.desactivaTrackingGrua().subscribe(result => {
          this.currentUpdatesTrackingGrua = false;
          this.mapComponent.removeMarkersOfType(MapFeatures.TRACKING_GRUA);
          this.liveUpdates.unsubscribeTrackingGrua();
        });
      }
    }
  }

  descargaDenunciasPolicia(descarga: Boolean) {
    if (descarga) {
      this.mapService.descargaDenuncias(true).subscribe((result: DenunciaResumenDto[]) => {
        this.currentUpdatesDenunciesPolicia = true;
        result.forEach(info => {
          let infoWindowContent =
          '<div class="row col-12"><span i18n>Agent: %agent%</span></div>' +
          '<div class="row col-12"><span i18n>Matrícula: %matricula%</span></div>' +
          '<div class="row col-12"><span i18n>Infracció: %infraccio%</span></div>';
          infoWindowContent = infoWindowContent.replace('%agent%', info.agente);
          infoWindowContent = infoWindowContent.replace('%matricula%', info.matricula);
          infoWindowContent = infoWindowContent.replace('%infraccio%', info.descripcionInfraccion);
          const marker = this.crearMarker(info.somid, MapFeatures.DENUNCIES_POLICIA, infoWindowContent, info.latitud, info.longitud, '#F77C10', '\uf518');
          this.liveUpdates.markersDenunciasPolicia.push(marker);
        });
        this.mapComponent.appendNewMarkers(this.liveUpdates.markersDenunciasPolicia);
        // Nos suscribios a los cambios.
        this.liveUpdates.subscribeToDenunciasPolicia(
          this.liveUpdates.onDenunciasPoliciaChange.subscribe((newDenuncias: DenunciaResumenDto[]) => {
            if (newDenuncias !== undefined && newDenuncias !== null && newDenuncias.length > 0) {
              // Hemos recibido las denuncias actuales. Comprovamos si hay alguna nueva.
              const newMarkers = new Array<CustomDataMarker>();
              newDenuncias.forEach(d => {
                if (this.liveUpdates.markersDenunciasPolicia.find(dM => dM.associatedResourceId === d.somid) === undefined) {
                  // Es una nueva denuncia.
                  let infoWindowContent =
                  '<div class="row col-12"><span i18n>Agent: %agent%</span></div>' +
                  '<div class="row col-12"><span i18n>Matrícula: %matricula%</span></div>' +
                  '<div class="row col-12"><span i18n>Infracció: %infraccio%</span></div>';
                  infoWindowContent = infoWindowContent.replace('%agent%', d.agente);
                  infoWindowContent = infoWindowContent.replace('%matricula%', d.matricula);
                  infoWindowContent = infoWindowContent.replace('%infraccio%', d.descripcionInfraccion);
                  const marker = this.crearMarker(d.somid, MapFeatures.DENUNCIES_POLICIA, infoWindowContent, d.latitud, d.longitud, '#F77C10', '\uf518');
                  newMarkers.push(marker);
                }
              });
              if (newMarkers.length > 0) {
                this.mapComponent.appendNewMarkers(newMarkers);
                this.liveUpdates.markersDenunciasPolicia = this.liveUpdates.markersDenunciasPolicia.concat(newMarkers);
              }
            }
          })
        );
      });
    } else {
      if (this.currentUpdatesDenunciesPolicia) {
        this.mapService.desactivaDenuncias(true).subscribe(result => {
          this.currentUpdatesDenunciesPolicia = false;
          // Eliminamos los markers y nos desuscribimos de los updates.
          this.mapComponent.removeMarkersOfType(MapFeatures.DENUNCIES_POLICIA);
          this.liveUpdates.unsubscribeDenunciasPolicia();
        });
      }
    }
  }

  descargaDenunciasZonaAzul(descarga: Boolean) {
    if (descarga) {
      this.mapService.descargaDenuncias(false).subscribe((result: DenunciaResumenDto[]) => {
        this.currentUpdatesDenunciesZonaBlava = true;
        result.forEach(info => {
          let infoWindowContent =
          '<div class="row col-12"><span i18n>Agent: %agent%</span></div>' +
          '<div class="row col-12"><span i18n>Matrícula: %matricula%</span></div>' +
          '<div class="row col-12"><span i18n>Infracció: %infraccio%</span></div>';
          infoWindowContent = infoWindowContent.replace('%agent%', info.agente);
          infoWindowContent = infoWindowContent.replace('%matricula%', info.matricula);
          infoWindowContent = infoWindowContent.replace('%infraccio%', info.descripcionInfraccion);
          const marker = this.crearMarker(info.somid, MapFeatures.DENUNCIES_ZONA_BLAVA, infoWindowContent, info.latitud, info.longitud, '#109CF7', '\uf518');
          this.liveUpdates.markersDenunciasZonaAzul.push(marker);
        });
        this.mapComponent.appendNewMarkers(this.liveUpdates.markersDenunciasZonaAzul);

        // Nos suscribimos a los cambios.
        this.liveUpdates.subscribeToDenunciasZonaAzul(
          this.liveUpdates.onDenunciasZonaAzulChange.subscribe((newDenuncias: DenunciaResumenDto[]) => {
            if (newDenuncias !== undefined && newDenuncias !== null && newDenuncias.length > 0) {
              // Hemos recibido las denuncias actuales. Comprovamos si hay alguna nueva.
              const newMarkers = new Array<CustomDataMarker>();
              newDenuncias.forEach(d => {
                if (this.liveUpdates.markersDenunciasZonaAzul.find(dM => dM.associatedResourceId === d.somid) === undefined) {
                  // Es una nueva denuncia.
                  let infoWindowContent =
                  '<div class="row col-12"><span i18n>Agent: %agent%</span></div>' +
                  '<div class="row col-12"><span i18n>Matrícula: %matricula%</span></div>' +
                  '<div class="row col-12"><span i18n>Infracció: %infraccio%</span></div>';
                  infoWindowContent = infoWindowContent.replace('%agent%', d.agente);
                  infoWindowContent = infoWindowContent.replace('%matricula%', d.matricula);
                  infoWindowContent = infoWindowContent.replace('%infraccio%', d.descripcionInfraccion);
                  const marker = this.crearMarker(d.somid, MapFeatures.DENUNCIES_ZONA_BLAVA, infoWindowContent, d.latitud, d.longitud, '#109CF7', '\uf518');
                  newMarkers.push(marker);
                }
              });
              if (newMarkers.length > 0) {
                this.mapComponent.appendNewMarkers(newMarkers);
                this.liveUpdates.markersDenunciasZonaAzul = this.liveUpdates.markersDenunciasZonaAzul.concat(newMarkers);
              }
            }
          })
        );
      });
    } else {
      if (this.currentUpdatesDenunciesZonaBlava) {
        this.mapService.desactivaDenuncias(false).subscribe(result => {
          this.currentUpdatesDenunciesZonaBlava = false;
          // Eliminamos los markers y nos desuscribimos de los updates.
          this.mapComponent.removeMarkersOfType(MapFeatures.DENUNCIES_ZONA_BLAVA);
          this.liveUpdates.unsubscribeDenunciasZonaAzul();
        });
      }
    }
  }

  descargaDenuncias(descarga: Boolean, opcion: String) {
    if (opcion === MapFeatures.DENUNCIES_POLICIA) {
      this.descargaDenunciasPolicia(descarga);
    } else if (opcion === MapFeatures.DENUNCIES_ZONA_BLAVA) {
      this.descargaDenunciasZonaAzul(descarga);
    }
  }

  descargarPropuestasGruaAceptadas(descarga: Boolean) {
    let filter: PropostaGruaFilter;
    filter = new PropostaGruaFilter(false, true, false, false, new Date());
    if (descarga) {
      this.mapService.descargaPropuestasGrua(filter).subscribe((result: PropostaResumenDto[]) => {
        this.currentUpdatesPropostesAcceptadesGrua = true;
        result.forEach(info => {
          let infoWindowContent =
          '<div class="row col-12"><span i18n>Agent: %agent%</span></div>' +
          '<div class="row col-12"><span i18n>Matrícula: %matricula%</span></div>' +
          '<div class="row col-12"><span i18n>Infracció: %infraccio%</span></div>';
          infoWindowContent = infoWindowContent.replace('%agent%', info.agente);
          infoWindowContent = infoWindowContent.replace('%matricula%', info.matricula);
          infoWindowContent = infoWindowContent.replace('%infraccio%', info.descripcionInfraccion);
          const marker = this.crearMarker(info.id, MapFeatures.PROPOSTES_GRUA_ACCEPTADES, infoWindowContent, info.latitud, info.longitud, '#4BCA11', '\uf5ae');
          this.liveUpdates.markersPropuestasGruaAceptadas.push(marker);
        });
        this.mapComponent.appendNewMarkers(this.liveUpdates.markersPropuestasGruaAceptadas);

        // Nos suscribimos a los cambios.
        this.liveUpdates.subscribeToPropuestasGruaAceptadas(
          this.liveUpdates.onPropuestasGruaAceptadasChange.subscribe((newPropuestas: PropostaResumenDto[]) => {
            if (newPropuestas !== undefined && newPropuestas !== null && newPropuestas.length > 0) {
              // Hemos recibido las alarmas actuales. Comprovamos si hay alguna nueva.
              const newMarkers = new Array<CustomDataMarker>();
              newPropuestas.forEach(p => {
                if (this.liveUpdates.markersPropuestasGruaAceptadas.find(dM => dM.associatedResourceId === p.id) === undefined) {
                  // Es una nueva propuesta.
                  let infoWindowContent =
                  '<div class="row col-12"><span i18n>Agent: %agent%</span></div>' +
                  '<div class="row col-12"><span i18n>Matrícula: %matricula%</span></div>' +
                  '<div class="row col-12"><span i18n>Infracció: %infraccio%</span></div>';
                  infoWindowContent = infoWindowContent.replace('%agent%', p.agente);
                  infoWindowContent = infoWindowContent.replace('%matricula%', p.matricula);
                  infoWindowContent = infoWindowContent.replace('%infraccio%', p.descripcionInfraccion);
                  const marker = this.crearMarker(p.id, MapFeatures.PROPOSTES_GRUA_ACCEPTADES, infoWindowContent, p.latitud, p.longitud, '#4BCA11', '\uf5ae');
                  newMarkers.push(marker);
                }
              });
              if (newMarkers.length > 0) {
                this.mapComponent.appendNewMarkers(newMarkers);
                this.liveUpdates.markersPropuestasGruaAceptadas = this.liveUpdates.markersPropuestasGruaAceptadas.concat(newMarkers);
              }
            }
          })
        );
      });
    } else {
      if (this.currentUpdatesPropostesAcceptadesGrua) {
        this.mapService.desactivaPropuestasGrua(filter).subscribe(result => {
          this.currentUpdatesPropostesAcceptadesGrua = false;
          // Eliminamos los markers y nos desuscribimos de los updates.
          this.mapComponent.removeMarkersOfType(MapFeatures.PROPOSTES_GRUA_ACCEPTADES);
          this.liveUpdates.unsubscribePropuestasGruaAceptadas();
        });
      }
    }
  }

  descargarPropuestasGruaPendientes(descarga: Boolean) {
    let filter: PropostaGruaFilter;
    filter = new PropostaGruaFilter(true, false, false, false, new Date());
    if (descarga) {
      this.mapService.descargaPropuestasGrua(filter).subscribe((result: PropostaResumenDto[]) => {
        this.currentUpdatesPropostesPendentsGrua = true;
        result.forEach(info => {
          let infoWindowContent =
          '<div class="row col-12"><span i18n>Agent: %agent%</span></div>' +
          '<div class="row col-12"><span i18n>Matrícula: %matricula%</span></div>' +
          '<div class="row col-12"><span i18n>Infracció: %infraccio%</span></div>';
          infoWindowContent = infoWindowContent.replace('%agent%', info.agente);
          infoWindowContent = infoWindowContent.replace('%matricula%', info.matricula);
          infoWindowContent = infoWindowContent.replace('%infraccio%', info.descripcionInfraccion);
          const marker = this.crearMarker(info.id, MapFeatures.PROPOSTES_GRUA_PENDENTS, infoWindowContent, info.latitud, info.longitud, '#E0870F', '\uf5ae');
          this.liveUpdates.markersPropuestasGruaPendientes.push(marker);
        });
        this.mapComponent.appendNewMarkers(this.liveUpdates.markersPropuestasGruaPendientes);

        // Nos suscribimos a los cambios.
        this.liveUpdates.subscribeToPropuestasGruaPendientes(
          this.liveUpdates.onPropuestasGruaPendientesChange.subscribe((newPropuestas: PropostaResumenDto[]) => {
            if (newPropuestas !== undefined && newPropuestas !== null && newPropuestas.length > 0) {
              // Hemos recibido las alarmas actuales. Comprovamos si hay alguna nueva.
              const newMarkers = new Array<CustomDataMarker>();
              newPropuestas.forEach(p => {
                if (this.liveUpdates.markersPropuestasGruaPendientes.find(dM => dM.associatedResourceId === p.id) === undefined) {
                  // Es una nueva propuesta.
                  let infoWindowContent =
                  '<div class="row col-12"><span i18n>Agent: %agent%</span></div>' +
                  '<div class="row col-12"><span i18n>Matrícula: %matricula%</span></div>' +
                  '<div class="row col-12"><span i18n>Infracció: %infraccio%</span></div>';
                  infoWindowContent = infoWindowContent.replace('%agent%', p.agente);
                  infoWindowContent = infoWindowContent.replace('%matricula%', p.matricula);
                  infoWindowContent = infoWindowContent.replace('%infraccio%', p.descripcionInfraccion);
                  const marker = this.crearMarker(p.id, MapFeatures.PROPOSTES_GRUA_PENDENTS, infoWindowContent, p.latitud, p.longitud, '#E0870F', '\uf5ae');
                  newMarkers.push(marker);
                }
              });
              if (newMarkers.length > 0) {
                this.mapComponent.appendNewMarkers(newMarkers);
                this.liveUpdates.markersPropuestasGruaPendientes = this.liveUpdates.markersPropuestasGruaPendientes.concat(newMarkers);
              }
            }
          })
        );
      });
    } else {
      if (this.currentUpdatesPropostesPendentsGrua) {
        this.mapService.desactivaPropuestasGrua(filter).subscribe(result => {
          this.currentUpdatesPropostesPendentsGrua = false;
          // Eliminamos los markers y nos desuscribimos de los updates.
          this.mapComponent.removeMarkersOfType(MapFeatures.PROPOSTES_GRUA_PENDENTS);
          this.liveUpdates.unsubscribePropuestasGruaPendientes();
        });
      }
    }
  }

  descargarPropuestasGruaRechazadas(descarga: Boolean) {
    let filter: PropostaGruaFilter;
    filter = new PropostaGruaFilter(false, false, true, false, new Date());
    if (descarga) {
      this.mapService.descargaPropuestasGrua(filter).subscribe((result: PropostaResumenDto[]) => {
        this.currentUpdatesPropostesRebutjadesGrua = true;
        result.forEach(info => {
          let infoWindowContent =
          '<div class="row col-12"><span i18n>Agent: %agent%</span></div>' +
          '<div class="row col-12"><span i18n>Matrícula: %matricula%</span></div>' +
          '<div class="row col-12"><span i18n>Infracció: %infraccio%</span></div>';
          infoWindowContent = infoWindowContent.replace('%agent%', info.agente);
          infoWindowContent = infoWindowContent.replace('%matricula%', info.matricula);
          infoWindowContent = infoWindowContent.replace('%infraccio%', info.descripcionInfraccion);
          const marker = this.crearMarker(info.id, MapFeatures.PROPOSTES_GRUA_REBUTJADES, infoWindowContent, info.latitud, info.longitud, '#C4190B', '\uf5ae');
          this.liveUpdates.markersPropuestasGruaRechazadas.push(marker);
        });
        this.mapComponent.appendNewMarkers(this.liveUpdates.markersPropuestasGruaRechazadas);

        // Nos suscribimos a los cambios.
        this.liveUpdates.subscribeToPropuestasGruaRechazadas(
          this.liveUpdates.onPropuestasGruaRechazadasChange.subscribe((newPropuestas: PropostaResumenDto[]) => {
            if (newPropuestas !== undefined && newPropuestas !== null && newPropuestas.length > 0) {
              // Hemos recibido las alarmas actuales. Comprovamos si hay alguna nueva.
              const newMarkers = new Array<CustomDataMarker>();
              newPropuestas.forEach(p => {
                if (this.liveUpdates.markersPropuestasGruaRechazadas.find(dM => dM.associatedResourceId === p.id) === undefined) {
                  // Es una nueva propuesta.
                  let infoWindowContent =
                  '<div class="row col-12"><span i18n>Agent: %agent%</span></div>' +
                  '<div class="row col-12"><span i18n>Matrícula: %matricula%</span></div>' +
                  '<div class="row col-12"><span i18n>Infracció: %infraccio%</span></div>';
                  infoWindowContent = infoWindowContent.replace('%agent%', p.agente);
                  infoWindowContent = infoWindowContent.replace('%matricula%', p.matricula);
                  infoWindowContent = infoWindowContent.replace('%infraccio%', p.descripcionInfraccion);
                  const marker = this.crearMarker(p.id, MapFeatures.PROPOSTES_GRUA_REBUTJADES, infoWindowContent, p.latitud, p.longitud, '#C4190B', '\uf5ae');
                  newMarkers.push(marker);
                }
              });
              if (newMarkers.length > 0) {
                this.mapComponent.appendNewMarkers(newMarkers);
                this.liveUpdates.markersPropuestasGruaRechazadas = this.liveUpdates.markersPropuestasGruaRechazadas.concat(newMarkers);
              }
            }
          })
        );
      });
    } else {
      if (this.currentUpdatesPropostesRebutjadesGrua) {
        this.mapService.desactivaPropuestasGrua(filter).subscribe(result => {
          this.currentUpdatesPropostesRebutjadesGrua = false;
          // Eliminamos los markers y nos desuscribimos de los updates.
          this.mapComponent.removeMarkersOfType(MapFeatures.PROPOSTES_GRUA_REBUTJADES);
          this.liveUpdates.unsubscribePropuestasGruaRechazadas();
        });
      }
    }
  }

  descargarPropuestasGruaRetiradas(descarga: Boolean) {
    let filter: PropostaGruaFilter;
    filter = new PropostaGruaFilter(false, false, false, true, new Date());
    if (descarga) {
      this.mapService.descargaPropuestasGrua(filter).subscribe((result: PropostaResumenDto[]) => {
        this.currentUpdatesPropostesRetiradesGrua = true;
        result.forEach(info => {
          let infoWindowContent =
          '<div class="row col-12"><span i18n>Agent: %agent%</span></div>' +
          '<div class="row col-12"><span i18n>Matrícula: %matricula%</span></div>' +
          '<div class="row col-12"><span i18n>Infracció: %infraccio%</span></div>';
          infoWindowContent = infoWindowContent.replace('%agent%', info.agente);
          infoWindowContent = infoWindowContent.replace('%matricula%', info.matricula);
          infoWindowContent = infoWindowContent.replace('%infraccio%', info.descripcionInfraccion);
          const marker = this.crearMarker(info.id, MapFeatures.PROPOSTES_GRUA_RETIRADES, infoWindowContent, info.latitud, info.longitud, '#0B8EC4', '\uf5ae');
          this.liveUpdates.markersPropuestasGruaRetiradas.push(marker);
        });
        this.mapComponent.appendNewMarkers(this.liveUpdates.markersPropuestasGruaRetiradas);

        // Nos suscribimos a los cambios.
        this.liveUpdates.subscribeToPropuestasGruaRetiradas(
          this.liveUpdates.onPropuestasGruaRetiradasChange.subscribe((newPropuestas: PropostaResumenDto[]) => {
            if (newPropuestas !== undefined && newPropuestas !== null && newPropuestas.length > 0) {
              // Hemos recibido las alarmas actuales. Comprovamos si hay alguna nueva.
              const newMarkers = new Array<CustomDataMarker>();
              newPropuestas.forEach(p => {
                if (this.liveUpdates.markersPropuestasGruaRetiradas.find(dM => dM.associatedResourceId === p.id) === undefined) {
                  // Es una nueva propuesta.
                  let infoWindowContent =
                  '<div class="row col-12"><span i18n>Agent: %agent%</span></div>' +
                  '<div class="row col-12"><span i18n>Matrícula: %matricula%</span></div>' +
                  '<div class="row col-12"><span i18n>Infracció: %infraccio%</span></div>';
                  infoWindowContent = infoWindowContent.replace('%agent%', p.agente);
                  infoWindowContent = infoWindowContent.replace('%matricula%', p.matricula);
                  infoWindowContent = infoWindowContent.replace('%infraccio%', p.descripcionInfraccion);
                  const marker = this.crearMarker(p.id, MapFeatures.PROPOSTES_GRUA_RETIRADES, infoWindowContent, p.latitud, p.longitud, '#0B8EC4', '\uf5ae');
                  newMarkers.push(marker);
                }
              });
              if (newMarkers.length > 0) {
                this.mapComponent.appendNewMarkers(newMarkers);
                this.liveUpdates.markersPropuestasGruaRetiradas = this.liveUpdates.markersPropuestasGruaRetiradas.concat(newMarkers);
              }
            }
          })
        );
      });
    } else {
      if (this.currentUpdatesPropostesRetiradesGrua) {
        this.mapService.desactivaPropuestasGrua(filter).subscribe(result => {
          this.currentUpdatesPropostesRetiradesGrua = false;
          // Eliminamos los markers y nos desuscribimos de los updates.
          this.mapComponent.removeMarkersOfType(MapFeatures.PROPOSTES_GRUA_RETIRADES);
          this.liveUpdates.unsubscribePropuestasGruaRetiradas();
        });
      }
    }
  }

  descargaPropuestasGrua(descarga: Boolean, opcion: String) {
    if (opcion === MapFeatures.PROPOSTES_GRUA_ACCEPTADES) {
      this.descargarPropuestasGruaAceptadas(descarga);
    } else if (opcion === MapFeatures.PROPOSTES_GRUA_PENDENTS) {
      this.descargarPropuestasGruaPendientes(descarga);
    } else if (opcion === MapFeatures.PROPOSTES_GRUA_REBUTJADES) {
      this.descargarPropuestasGruaRechazadas(descarga);
    } else if (opcion === MapFeatures.PROPOSTES_GRUA_RETIRADES) {
      this.descargarPropuestasGruaRetiradas(descarga);
    }
  }

  descargarOcupacionGeneral(descarga: Boolean) {
    if (descarga) {
      // Primero descargamos los tramos.
      this.mapService.descargaPoligonosActuales().subscribe((result: Tramo[]) => {
        this.currentUpdatesOcupacion = true;
        result.forEach(info => {
          // Creamos el poligono con la informacion recibida.
          const poligon = this.crearPoligono(MapFeatures.OCUPACIO_GENERAL, info.descripcion, info.poligon, info.initialPosition, info.zona.color);
          this.liveUpdates.poligonsOcupacion.push(poligon);
        });
        this.mapComponent.appendNewPoligons(this.liveUpdates.poligonsOcupacion);

        // Ahora descargamos los valores actuales de la ocupacion.

        // Nos suscribimos a los eventos de cambios de ocupacion.
      });
    } else {
      if (this.currentUpdatesOcupacion) {
        // this.mapService.desactivaOcupacion().subscribe(result => {
          this.currentUpdatesOcupacion = false;
          // Eliminamos los polígonos y nos desuscribimos de los updates.
          this.mapComponent.removePolygonsOfType(MapFeatures.OCUPACIO_GENERAL);
          this.liveUpdates.unsubscribeOcupacion();
        // })
      }
    }
  }

  descargarOcupacionCalor(descarga: Boolean) {
    if (descarga) {
      // Primero descargamos los tramos.
      this.mapService.descargaPoligonosActuales().subscribe((result: Tramo[]) => {
        // Hemos recibido la info de los tramos, ahora obtenemos los colores.
        this.escalaOcupacionService.getAllEscalas().subscribe((escalasOcupacion: EscalaOcupacion[]) => {
          // Aqui ya tenemos toda la informacion.
          this.currentUpdatesOcupacion = true;
          result.forEach(info => {
            // Creamos el poligono con la informacion recibida.
            const escala = escalasOcupacion[0];
            // todo -->>> SUSTITUIR POR EL COLOR QUE TOQUE.
            const poligon = this.crearPoligono(MapFeatures.OCUPACIO_CALOR, info.descripcion, info.poligon, info.initialPosition, escala.color);
            this.liveUpdates.poligonsOcupacion.push(poligon);
          });
          this.mapComponent.appendNewPoligons(this.liveUpdates.poligonsOcupacion);

          // Ahora descargamos los valores actuales de la ocupacion.

          // Nos suscribimos a los eventos de cambios de ocupacion.
        });
      });
    } else {
      if (this.currentUpdatesOcupacion) {
        // this.mapService.desactivaOcupacion().subscribe(result => {
          this.currentUpdatesOcupacion = false;
          // Eliminamos los polígonos y nos desuscribimos de los updates.
          this.mapComponent.removePolygonsOfType(MapFeatures.OCUPACIO_CALOR);
          this.liveUpdates.unsubscribeOcupacion();
        // })
      }
    }
  }

  descargaOcupacion(descarga: Boolean, opcion: String) {
    if (opcion === MapFeatures.OCUPACIO_GENERAL) {
      this.descargarOcupacionGeneral(descarga);
    } else if (opcion === MapFeatures.OCUPACIO_CALOR) {
      this.descargarOcupacionCalor(descarga);
    }
  }

  crearPoligono(secureId: string, descripcion: string, area: PositonCoord[], center: PositonCoord, color: string): CustomDataPoligon {
    return new CustomDataPoligon(
      secureId,
      descripcion,
      area,
      center,
      color
    );
  }

  crearMarker(associatedId: any, featureType: string, text: String, latitude: number, longitude: number, color: String, icon: String): CustomDataMarker {
    return new CustomDataMarker(
      associatedId,
      featureType,
      text,
      latitude,
      longitude,
      'google.maps.SymbolPath.CIRCLE',
      color,
      0.15,
      icon,
      false,
      false,
      true);
  }

  changeHoversVisibility(alwaysVisible: boolean) {
    this.alwaysVisible = alwaysVisible;
    this.alwaysVisible ? this.mapComponent.showHoversPermanentlly() : this.mapComponent.hideHoversPermanently();
  }

  navigateToDetail(item: any) {
    const feature = item.mapFeature;
    const id = item.resourceId;

    switch (feature) {
      case MapFeatures.ALARMES_SISTEMA:
        this.abrirAlarmasSistemaDetalle(id);
        break;
      case MapFeatures.ALARMES_SMARTPHONES:
        this.abrirAlarmasSmartphonesDetalle(id);
        break;
      case MapFeatures.PROPOSTES_GRUA_PENDENTS:
      case MapFeatures.PROPOSTES_GRUA_ACCEPTADES:
      case MapFeatures.PROPOSTES_GRUA_REBUTJADES:
      case MapFeatures.PROPOSTES_GRUA_RETIRADES:
        this.abrirPropuestasGruaDetalle(id);
        break;
      case MapFeatures.DENUNCIES_POLICIA:
      case MapFeatures.DENUNCIES_ZONA_BLAVA:
        this.abrirDenunciaDetalle(id);
        break;
    }
  }

  abrirAlarmasSistemaDetalle(id: any) {
    const filter: AlarmaFilter = new AlarmaFilter('id', 'asc');
    const data: ComponentDialog = Builder.newComponentDialog(true, true, id, filter);
    const ref = this.dialog.open(AlarmesComponent, {
      width: '80%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });
  }

  abrirAlarmasSmartphonesDetalle(id: any) {
    // todo alarma visor
    const data: ComponentDialog = Builder.newComponentDialog(true, true, id);
    const ref = this.dialog.open(VisorAlarmesComponent, {
      width: '80%',
      height: '90%',
      maxHeight: '100vh',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });
  }

  abrirDenunciaDetalle(id: any) {
    const data: ComponentDialog = Builder.newComponentDialog(true, true, id);
      const ref = this.dialog.open(DenunciesEditorComponent, {
        width: '80%',
        height: '90%',
        maxHeight: '100vh',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
    });
  }

  abrirPropuestasGruaDetalle(id: any) {
    // todo -> s'ha darreglar aquest component.
    this.propostesService.getProposta(id).subscribe((result: PropostaGruaDto) => {
      const ref = this.dialog.open(PendentsComponent, {
        width: '80%',
        height: '90%',
        closeOnNavigation: true,
        autoFocus: true,
        data: {
          'isDialog': true,
          'isReadOnly': !result.revisada,
          'propostaGrua': result,
          'isProposta': !result.revisada,
          'idProposta': id
        }
      });
    });
  }

}
