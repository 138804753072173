import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';
import { Injectable } from '@angular/core';

export class NotificacionsDenunciaFilter extends FilterData {
    element: number;
    judicial: boolean;
    dataIni: Date;
    dataFi: Date;
    constructor(sortField: string, sortDir: SortDirection) {
        super(sortField, sortDir);
        this.element = null;
        this.judicial = null;
        this.dataIni=null;
        this.dataFi=null;
    
        this.labels['element'] = this.i18n('Expedient');
        this.labels['judicial'] = this.i18n('Judicial');
        this.labels['dataIni']=this.i18n['DataIni'];
        this.labels['dataFi']=this.i18n['DataFi'];
    }
}