/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./matricula.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./matricula.component";
import * as i4 from "../../../services/startup/startup.service";
var styles_MatriculaComponent = [i0.styles];
var RenderType_MatriculaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MatriculaComponent, data: {} });
export { RenderType_MatriculaComponent as RenderType_MatriculaComponent };
function View_MatriculaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "dropdown-item"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setPais(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_MatriculaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "dropdown sugPais"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 0, "button", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "btn btn-secondary dropdown-toggle button"], ["data-toggle", "dropdown"], ["id", "dropdownMenuPais"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["aria-labelledby", "dropdownMenuPais"], ["class", "dropdown-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatriculaComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dropdown sugPais"; var currVal_1 = _ck(_v, 2, 0, (_co.validPais() ? "" : "empty")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.sugPais; _ck(_v, 6, 0, currVal_2); }, null); }
function View_MatriculaComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "dropdown-item"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setMatricula(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_MatriculaComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "btn btn-secondary dropdown-toggle button"], ["data-toggle", "dropdown"], ["id", "dropdownMenuMatricula"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "seleccionar mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Seleccioni"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["aria-labelledby", "dropdownMenuMatricula"], ["class", "dropdown-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatriculaComponent_4)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sugMatricula; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_MatriculaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "matricula"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "pais"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatriculaComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "nummatricula"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatriculaComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "pais"; var currVal_1 = _ck(_v, 3, 0, (_co.validPais() ? "" : "empty")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = (_co.activarSugerencias && (_co.sugPais != null)); _ck(_v, 6, 0, currVal_3); var currVal_5 = (((_co.activarSugerencias && (_co.sugMatricula != null)) && (_co.sugMatricula != "")) && ((_co.matricula === null) || (_co.matricula === ""))); _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.pais; _ck(_v, 4, 0, currVal_2); var currVal_4 = _co.matricula; _ck(_v, 8, 0, currVal_4); }); }
export function View_MatriculaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-matricula", [], null, null, null, View_MatriculaComponent_0, RenderType_MatriculaComponent)), i1.ɵdid(1, 114688, null, 0, i3.MatriculaComponent, [i4.StartupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MatriculaComponentNgFactory = i1.ɵccf("app-matricula", i3.MatriculaComponent, View_MatriculaComponent_Host_0, { matricula: "matricula", pais: "pais", vehicle: "vehicle", sugPais: "sugPais", sugMatricula: "sugMatricula", activarSugerencias: "activarSugerencias", marca: "marca", model: "model" }, { canvioPais: "canvioPais", canvioMatricula: "canvioMatricula" }, []);
export { MatriculaComponentNgFactory as MatriculaComponentNgFactory };
