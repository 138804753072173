import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { TarifesFilter } from './models/tarifes-filter';
import { TarifesFilterComponent } from './components/tarifes-filter/tarifes-filter.component';
import { ConfirmationDialogComponent } from 'path-shared/components/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { TarifesTableComponent } from './components/tarifes-table/tarifes-table.component';
import { TarifesEditorComponent } from './components/tarifes-editor/tarifes-editor.component';
import { TarifesService } from './services/tarifes.service';

@Component({
  selector: 'app-tarifes',
  templateUrl: './tarifes.component.html',
  styleUrls: ['./tarifes.component.scss']
})
export class TarifesComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: TarifesFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  publicarAvailable = false;

  @ViewChild(TarifesTableComponent) tarifesTable: TarifesTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<TarifesComponent>,
    private tarifesService: TarifesService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new TarifesFilter('descripcio', 'asc');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.Tarifes);

    this.tarifesTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.tarifesTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Tarifes'));
    }
  }


  edit() {
    const idSeleccionat: string = this.tarifesTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, !this.publicarAvailable, secure_id);

    const ref = this.dialog.open(TarifesEditorComponent, {
      width: '70%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data,
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.tarifesTable.load(); }
    });
  }

  refresh() {
    this.tarifesTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(TarifesFilterComponent, {
      width: '70%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.tarifesTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: TarifesFilter) => {
      this.tarifesTable.filter.set(filter);
      this.tarifesTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  // generateExcel() {
  //   this.colorsTable.generateExcel();
  // }


  publicar() {
    // this.tarifesService.publicar();

    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol publicar aquestes tarifes?'))
    .then((confirmed) => {
        if (confirmed) {
          this.tarifesService.publicar().subscribe(result => {
            this.toastr.success(this.i18n('Tarifes publicades correctament'));
          });
        }
      });
  }

  descargarDatos() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('¿Seguro que desea descargar datos? Este proceso eliminará la información aquí mostrada y obtendrá la información que hay actualmente en la aplicación'))
    .then((confirmed) => {
        if (confirmed) {
          this.tarifesService.descargarDatos().subscribe(result => {
            this.toastr.success(this.i18n('Tarifas descargadas correctamente'));
            this.refresh();
            this.publicarAvailable = true;
          });
        }
      });
  }
}
