<mat-checkbox [ngModel]="this.showLupa" (change)="usarLupaChange($event)">Usar lupa</mat-checkbox>
<div class="drag-scroll" [style.height]="this.height" style="min-height:40vh; margin-bottom: 1vh;">
  <div class="loader" [hidden]="this.hasImages || (!this.hasImages && minTimeLoad)"></div>
  <div *ngIf="!this.hasImages && minTimeLoad">
    <i class="fas fa-image"></i>&nbsp;Propuesta sin imágenes
  </div>
  <drag-scroll drag-scroll-disabled="true" (reachesLeftBound)="leftBoundStat($event)"
    (reachesRightBound)="rightBoundStat($event)" #dragScroll [ngStyle]="getScrollStyle()">
    <div class="drag-scroll__items-container" *ngIf="this.selectedImages!== undefined && this.selectedImages!== null"
      style="display: flex; align-items: center; overflow-x:auto;">
      <div drag-scroll-item *ngFor="let element of this.selectedImages">
        <div class="text-center">
          <i *ngIf="this.proposta !== undefined && this.proposta!==null  && this.proposta.proposta==1"
            (click)="OCR(element)" style="border-style: solid;border-width: thin;"
            class="cursor fas fa-search-plus btn-outline-info btn-sm">OCR</i>

          <i *ngIf="this.proposta !== undefined && this.proposta!==null  && this.proposta.proposta==1"
            (click)="removeImage(element)" class="cursor fa fa-trash-alt btn btn-outline-danger btn-sm"></i>
          &nbsp;
          <div *ngIf="this.allowSelect === undefined || this.allowSelect === null || this.allowSelect"
            style="display:inline">
            <mat-checkbox
              *ngIf="( this.proposta === undefined ||this.proposta==null || validarFotosProposta)  && !this.pendentValidacio() "
              (change)="Toggle(element)" [(ngModel)]="element.checked" [checked]="element.checked"></mat-checkbox>
            <span
              *ngIf="this.proposta !== undefined && this.proposta!=null && this.proposta.proposta==1 && validarFotosProposta">
              Validat</span>

          </div>


        </div>
        <div [style.padding-bottom]="this.showLupa ? '150px': ''" [style.padding-right]="(this.showLupa && this.magnifierRight < 0) ? '200px': ''">
          <app-photo-box style="overflow: initial;" [proposta]="this.proposta" [numDenuncia]="this.numDenuncia" [somId]="element.imatge.SOM_ID"
          [nom]="element.imatge.NOMBRE_FICHERO" [botoMatricula]="this.botoMatricula"
          [llistaImatges]="this.selectedImages" [imageDiv]="this.getImageDiv(element.imatge.SOM_ID)"
          [showLupa]="this.showLupa" [(drawList)]="this.drawList" (updated)="updated($event)"
          [magnifierRight]="this.magnifierRight" [magnifierTop]="this.magnifierTop"></app-photo-box>
        </div>
        
        <div class="text-center"
          *ngIf="enviarFotosDenuncia && this.proposta!=null && this.proposta.proposta==1 && (this.allowSelect === undefined || this.allowSelect === null || this.allowSelect )">
          <mat-checkbox [checked]="element.checked" (change)="Toggle(element)" [(ngModel)]="element.checked">
          </mat-checkbox>
          <span i18n> Seleccionar </span>
        </div>
      </div>
    </div>
  </drag-scroll>

  <!-- <div class="drag-scroll__actions">
    <button class="action-button__prev" (click)="moveLeft()"><i class="fa fa-angle-left"></i></button>
    <button class="action-button__next" (click)="moveRight()"><i class="fa fa-angle-right"></i></button>
  </div> -->
</div>