import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { PropostesAnulacioFilter,  } from '../models/propostes-anulacio-filter';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { DatePipe } from '@angular/common';
const defaultSortParam = 'id';
const defaultSortDirection = 'asc';

@Injectable()
export class PropostesAnulacioFilterService extends CustomFilterService {

  public createFilterParams(filter: PropostesAnulacioFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    const datepipe: DatePipe = new DatePipe('es-ES');
    // if (filter.dataAceptDengFi !== undefined && filter.dataAceptDengFi !== null) {
    //   filter.dataAceptDengFi.setHours(23, 59, 59);
    // }
    // if (filter.dataDenunciaFi !== undefined && filter.dataDenunciaFi !== null) {
    //   filter.dataDenunciaFi.setHours(23, 59, 59);
    // }
    // if (filter.dataPropFi !== undefined && filter.dataPropFi !== null) {
    //   filter.dataPropFi.setHours(23, 59, 59);
    // }
    return new HttpParams()
    .append('exp', (filter.exp ? filter.exp.toString() : ''))
    .append('matricula', (filter.matricula ? filter.matricula.toString() : ''))
    //.append('acceptada', (filter.acceptada ? filter.acceptada.toString() : ''))
    .append('acceptada',this.getTristateValue(filter.acceptada))
    .append('dataAceptDengIni', Reflection.empty(filter.dataAceptDengFi) ? null : datepipe.transform(filter.dataAceptDengFi, 'yyyy-MM-ddT00:00:00'))
    .append('dataAceptDengFi', Reflection.empty(filter.dataAceptDengIni) ? null : datepipe.transform(filter.dataAceptDengIni, 'yyyy-MM-ddT23:59:59'))
    .append('dataDenunciaIni', Reflection.empty(filter.dataDenunciaIni) ? null : datepipe.transform(filter.dataDenunciaIni, 'yyyy-MM-ddT00:00:00'))
    .append('dataDenunciaFi', Reflection.empty(filter.dataDenunciaFi) ? null : datepipe.transform(filter.dataDenunciaFi, 'yyyy-MM-ddT23:59:59'))
    .append('dataPropIni', Reflection.empty(filter.dataPropIni) ? null : datepipe.transform(filter.dataPropIni, 'yyyy-MM-ddT00:00:00'))
    .append('dataPropFi', Reflection.empty(filter.dataPropFi) ? null : datepipe.transform(filter.dataPropFi, 'yyyy-MM-ddT23:59:59'))
    // .append('dataAceptDengFi',  Reflection.empty(filter.dataAceptDengFi) ? null :  typeof(filter.dataAceptDengFi) === 'string' ?
    // filter.dataAceptDengFi : filter.dataAceptDengFi.toDateString())
    // .append('dataAceptDengIni',  Reflection.empty(filter.dataAceptDengIni) ? null :  typeof(filter.dataAceptDengIni) === 'string' ?
    // filter.dataAceptDengIni : filter.dataAceptDengIni.toDateString())
    // .append('dataDenunciaIni',  Reflection.empty(filter.dataDenunciaIni) ? null :  typeof(filter.dataDenunciaIni) === 'string' ?
    // filter.dataDenunciaIni : filter.dataDenunciaIni.toDateString())
    // .append('dataDenunciaFi',  Reflection.empty(filter.dataDenunciaFi) ? null :  typeof(filter.dataDenunciaFi) === 'string' ?
    // filter.dataDenunciaFi : filter.dataDenunciaFi.toString())
    // .append('dataPropIni',  Reflection.empty(filter.dataPropIni) ? null :  typeof(filter.dataPropIni) === 'string' ?
    // filter.dataPropIni : filter.dataPropIni.toDateString())
    
    // .append('dataPropFi',  Reflection.empty(filter.dataPropFi) ? null :  typeof(filter.dataPropFi) === 'string' ?
    // filter.dataPropFi : filter.dataPropFi.toDateString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
