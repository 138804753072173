import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { LoaderState } from './loader-state';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private static instancia: LoaderService;

  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();
  private contador = 0;
  private background = false;
  constructor() { }

  show(contador?: boolean) {
    if (contador) {
      this.contador++;
    }
    if (!this.background) {
      this.loaderSubject.next(<LoaderState>{ show: true });
    }
  }

  hide(contador?: boolean) {
    if (contador) {
      this.contador--;
    }

    if (this.contador === 0) {
      if (!this.background) {
        this.loaderSubject.next(<LoaderState>{ show: false });

      }
    }
  }

  // tslint:disable-next-line: member-ordering
  public static getInstance() {
    if (this.instancia === undefined || this.instancia === null) {
        this.instancia = new LoaderService();
    }
    return this.instancia;
  }

  public setBackgroundMode(background: boolean) {
    this.background = background;
    if (background) {
      // nos cubrimos disgustos haciendo que al cabo de 5 segundos quite el estado de background siempre
      setTimeout(() => this.background = false, 5000);
    }
  }

}
