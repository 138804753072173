var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var MatriculaHistoricFilter = /** @class */ (function (_super) {
    __extends(MatriculaHistoricFilter, _super);
    function MatriculaHistoricFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.dataFi = null;
        _this.dataInici = null;
        _this.dataCreacio = null;
        _this.marca = '';
        _this.matricula = '';
        _this.model = '';
        _this.observacions = '';
        _this.tipus = '';
        _this.labels['dataFi'] = _this.i18n('Data Fi');
        _this.labels['dataInici'] = _this.i18n('Data Inici');
        _this.labels['dataCreacio'] = _this.i18n('Data Creació');
        _this.labels['marca'] = _this.i18n('Marca');
        _this.labels['matricula'] = _this.i18n('Matrícula');
        _this.labels['model'] = _this.i18n('Model');
        _this.labels['observacions'] = _this.i18n('Observacions');
        _this.labels['tipus'] = _this.i18n('Tipus');
        _this.formatOutput['dataInici'] = 'shortDate';
        _this.formatOutput['dataFi'] = 'shortDate';
        _this.formatOutput['dataCreacio'] = 'shortDate';
        return _this;
    }
    return MatriculaHistoricFilter;
}(FilterData));
export { MatriculaHistoricFilter };
