import { Component, OnInit, EventEmitter, Output, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';
import { TurnosFilter } from '../../models/turnos-filter';

@Component({
  selector: 'app-turnos-filter',
  templateUrl: './turnos-filter.component.html',
  styleUrls: ['./turnos-filter.component.scss']
})
export class TurnosFilterComponent implements OnInit, OnDestroy {
  filter: TurnosFilter;
  subscription: Subscription;
  @Output() notify: EventEmitter<TurnosFilter> = new EventEmitter<TurnosFilter>();
  constructor(public thisDialogRef: MatDialogRef<TurnosFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {

    this.filter = data.filter; }
    ngOnInit() {
      this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
        if (e.key === 'Enter') {
          this.apply();
        }
      });
    }
    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }
    reset() {
      this.filter.clear();
      this.apply();
    }
  
    apply() {
      this.filter.updated();
      this.notify.emit(this.filter);
      this.thisDialogRef.close();
    }
  
    dismiss() {
      this.thisDialogRef.close();
    }

}
