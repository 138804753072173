/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comprovar-padro.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i3 from "../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i4 from "../../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component.ngfactory";
import * as i5 from "../../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component";
import * as i6 from "../../../../../shared/services/authorization/authorization-service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/forms";
import * as i9 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i10 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i11 from "../../../../../shared/services/validation/validation.service";
import * as i12 from "@angular/common";
import * as i13 from "./comprovar-padro.component";
import * as i14 from "@ngx-translate/i18n-polyfill";
import * as i15 from "../../../../../shared/services/notification/notification.service";
import * as i16 from "../../services/gestio-residents.service";
import * as i17 from "../../../../../shared/services/form/form.service";
import * as i18 from "ngx-toastr";
var styles_ComprovarPadroComponent = [i0.styles];
var RenderType_ComprovarPadroComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ComprovarPadroComponent, data: {} });
export { RenderType_ComprovarPadroComponent as RenderType_ComprovarPadroComponent };
function View_ComprovarPadroComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "alert alert-success col-sm-5 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "far fa-thumbs-up"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Empadronat"]))], null, null); }
function View_ComprovarPadroComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "alert alert-danger col-sm-5 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "far fa-thumbs-down"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No empadronat"]))], null, null); }
export function View_ComprovarPadroComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mainForm: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-toolbar", [], null, null, null, i2.View_ToolbarComponent_0, i2.RenderType_ToolbarComponent)), i1.ɵdid(2, 114688, null, 0, i3.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "app-tool-button-permissions", [], null, null, null, i4.View_ToolButtonPermissionsComponent_0, i4.RenderType_ToolButtonPermissionsComponent)), i1.ɵdid(4, 114688, null, 0, i5.ToolButtonPermissionsComponent, [i6.AuthorizationService, i7.MatDialog], { permission: [0, "permission"] }, null), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["mainForm", 1]], null, 12, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i8.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(7, 4210688, null, 0, i8.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i8.ControlContainer, null, [i8.NgForm]), i1.ɵdid(9, 16384, null, 0, i8.NgControlStatusGroup, [[4, i8.ControlContainer]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-2 col-md-2 col-sm-2"], ["id", "xml-tree-viewer-matricula"], ["label", "Matr\u00EDcula:"], ["maxLength", "15"], ["required", ""]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.matricula = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_FormTextEditComponent_0, i9.RenderType_FormTextEditComponent)), i1.ɵdid(12, 4308992, null, 0, i10.FormTextEditComponent, [i11.ValidationService], { label: [0, "label"], required: [1, "required"], value: [2, "value"], class: [3, "class"], maxLength: [4, "maxLength"], id: [5, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-2 col-md-2  col-sm-2"], ["id", "xml-tree-viewer-persona"], ["label", "DNI:"], ["maxLength", "15"], ["required", ""]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.dni = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_FormTextEditComponent_0, i9.RenderType_FormTextEditComponent)), i1.ɵdid(14, 4308992, null, 0, i10.FormTextEditComponent, [i11.ValidationService], { label: [0, "label"], required: [1, "required"], value: [2, "value"], class: [3, "class"], maxLength: [4, "maxLength"], id: [5, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "p-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-sq-lg btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.consultarPadro() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Consultar"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ComprovarPadroComponent_1)), i1.ɵdid(19, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ComprovarPadroComponent_2)), i1.ɵdid(21, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.auth.Pages.GestioResidents; _ck(_v, 4, 0, currVal_0); var currVal_8 = "Matr\u00EDcula:"; var currVal_9 = ""; var currVal_10 = _co.matricula; var currVal_11 = "col-xl-2 col-md-2 col-sm-2"; var currVal_12 = "15"; var currVal_13 = "xml-tree-viewer-matricula"; _ck(_v, 12, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = "DNI:"; var currVal_15 = ""; var currVal_16 = _co.dni; var currVal_17 = "col-xl-2 col-md-2  col-sm-2"; var currVal_18 = "15"; var currVal_19 = "xml-tree-viewer-persona"; _ck(_v, 14, 0, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19); var currVal_20 = (_co.resultat === "OK"); _ck(_v, 19, 0, currVal_20); var currVal_21 = (_co.resultat === "NO_OK"); _ck(_v, 21, 0, currVal_21); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 9).ngClassValid; var currVal_6 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ComprovarPadroComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-comprovar-padro", [], null, null, null, View_ComprovarPadroComponent_0, RenderType_ComprovarPadroComponent)), i1.ɵdid(1, 4308992, null, 0, i13.ComprovarPadroComponent, [i14.I18n, i6.AuthorizationService, i15.NotificationService, i16.GestioResidentsService, i17.FormService, i18.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ComprovarPadroComponentNgFactory = i1.ɵccf("app-comprovar-padro", i13.ComprovarPadroComponent, View_ComprovarPadroComponent_Host_0, {}, {}, []);
export { ComprovarPadroComponentNgFactory as ComprovarPadroComponentNgFactory };
