/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/utilitats.component.ngfactory";
import * as i2 from "../components/utilitats.component";
import * as i3 from "./utilitats-page.component";
var styles_UtilitatsPageComponent = [];
var RenderType_UtilitatsPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UtilitatsPageComponent, data: {} });
export { RenderType_UtilitatsPageComponent as RenderType_UtilitatsPageComponent };
export function View_UtilitatsPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-utilitats", [], null, null, null, i1.View_UtilitatsComponent_0, i1.RenderType_UtilitatsComponent)), i0.ɵdid(1, 114688, null, 0, i2.UtilitatsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_UtilitatsPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-utilitats-page", [], null, null, null, View_UtilitatsPageComponent_0, RenderType_UtilitatsPageComponent)), i0.ɵdid(1, 114688, null, 0, i3.UtilitatsPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UtilitatsPageComponentNgFactory = i0.ɵccf("app-utilitats-page", i3.UtilitatsPageComponent, View_UtilitatsPageComponent_Host_0, {}, {}, []);
export { UtilitatsPageComponentNgFactory as UtilitatsPageComponentNgFactory };
