import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { FormService } from 'path-shared/services/form/form.service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { GestioResidentsCites } from '../../models/gestio-residents-cites.model';
import { GestioResidentsService } from '../../services/gestio-residents.service';


@Component({
  selector: 'app-comprovar-padro',
  templateUrl: './comprovar-padro.component.html',
  styleUrls: ['./comprovar-padro.component.scss']
})


export class ComprovarPadroComponent implements OnInit, AfterViewInit {
  @ViewChild('mainForm') private mainForm: ElementRef;

  matricula = null;
  dni = null;
  resultat = null;
  constructor(
    private i18n: I18n,
    public auth: AuthorizationService,
    private not: NotificationService,
    private gestioResidentsService: GestioResidentsService,
    private formService: FormService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.not.titleChange(this.i18n('Consultar padrón'));

  }

  consultarPadro(): void {

    if (this.formService.allFieldsValid(this.mainForm)) {
      this.gestioResidentsService.comprovarPadro(this.matricula, this.dni).subscribe(res => {
        this.resultat = res;
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }

  }



}
