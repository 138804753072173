/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carga-matriculas-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/components/dialog/filter-header/filter-header.component.ngfactory";
import * as i3 from "../../../../../../shared/components/dialog/filter-header/filter-header.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../../../shared/components/form/form-radio-group/form-radio-group.component.ngfactory";
import * as i6 from "../../../../../../shared/components/form/form-radio-group/form-radio-group.component";
import * as i7 from "./carga-matriculas-filter.component";
import * as i8 from "../../../../../../shared/components/loader/loader.service";
var styles_CargaMatriculasFilterComponent = [i0.styles];
var RenderType_CargaMatriculasFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CargaMatriculasFilterComponent, data: {} });
export { RenderType_CargaMatriculasFilterComponent as RenderType_CargaMatriculasFilterComponent };
export function View_CargaMatriculasFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-header", [["title", "Filtrar"]], null, [[null, "apply"], [null, "reset"], [null, "dismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("apply" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (_co.reset() !== false);
        ad = (pd_1 && ad);
    } if (("dismiss" === en)) {
        var pd_2 = (_co.dismiss() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_FilterHeaderComponent_0, i2.RenderType_FilterHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.FilterHeaderComponent, [i4.MatDialogRef], { title: [0, "title"] }, { apply: "apply", reset: "reset", dismiss: "dismiss" }), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-form-radio-group", [["title", "Matr\u00EDcules"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.vellesONoves = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FormRadioGroupComponent_0, i5.RenderType_FormRadioGroupComponent)), i1.ɵdid(5, 114688, null, 0, i6.FormRadioGroupComponent, [], { title: [0, "title"], labels: [1, "labels"], values: [2, "values"], value: [3, "value"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Filtrar"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "Matr\u00EDcules"; var currVal_2 = _co.labelsMatriculas; var currVal_3 = _co.matriculasValues; var currVal_4 = _co.vellesONoves; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_CargaMatriculasFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carga-matriculas-filter", [], null, null, null, View_CargaMatriculasFilterComponent_0, RenderType_CargaMatriculasFilterComponent)), i1.ɵdid(1, 245760, null, 0, i7.CargaMatriculasFilterComponent, [i4.MatDialogRef, i8.LoaderService, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CargaMatriculasFilterComponentNgFactory = i1.ɵccf("app-carga-matriculas-filter", i7.CargaMatriculasFilterComponent, View_CargaMatriculasFilterComponent_Host_0, {}, { notify: "notify" }, []);
export { CargaMatriculasFilterComponentNgFactory as CargaMatriculasFilterComponentNgFactory };
