<app-filter-header title="Filtrar tipus de tiquet" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-form-text-edit id="tipus-tiquets-filter-codi" class="col-sm-3" i18n-label label="Codi" [(value)]="filter.codi"></app-form-text-edit>
    <app-filter-text-edit id="tipus-tiquets-filter-descripcio" class="col-sm-9" i18n-label label="Descripció" [(value)]="filter.descripcio"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-form-date-edit id="tipus-tiquets-filter-dataIni" class="col-sm-5" i18n-label label="Data Inici" [(value)]="filter.dataInici"></app-form-date-edit> 
    <app-form-date-edit id="tipus-tiquets-filter-dataFi" class="col-sm-5" i18n-label label="Data Fi" [(value)]="filter.dataFi"></app-form-date-edit> 
  </div>

</div>
