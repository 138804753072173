import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AlegacioFilter } from '../../models/alegaciones.filter';
import { Observable, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-alegacions-filter',
  templateUrl: './alegacions-filter.component.html',
  styleUrls: ['./alegacions-filter.component.scss']
})

export class AlegacionsFilterComponent implements OnInit {
  subscription: Subscription;
  filter: AlegacioFilter;

  @Output() notify: EventEmitter<AlegacioFilter> = new EventEmitter<AlegacioFilter>();
  constructor(
    public thisDialogRef: MatDialogRef<AlegacioFilter>,
    @Inject(MAT_DIALOG_DATA) public data) { 
    this.filter = data.filter;
  }


  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
