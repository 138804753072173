import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-permission-status',
  templateUrl: './permission-status.component.html',
  styleUrls: ['./permission-status.component.scss']
})
export class PermissionStatusComponent implements OnInit {
  @Input() status: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
