import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { DesplacatsGruaFilter } from 'app/sections/grua/Models/desplacats-filter';
import { DipositGruaFilter } from 'app/sections/grua/Models/diposit-filter';
import { SenseRetiradaGruaFilter } from 'app/sections/grua/Models/sense-retirada-filter';
import { CombosService } from 'path-shared/services/form/combos.service';

@Component({
  selector: 'app-sense-retirada-filter',
  templateUrl: './sense-retirada-filter.component.html',
  styleUrls: ['./sense-retirada-filter.component.scss']
})
export class SenseRetiradaGruaFilterComponent implements OnInit, OnDestroy {
  filter: SenseRetiradaGruaFilter;
  @Output() notify: EventEmitter<SenseRetiradaGruaFilter> = new EventEmitter<SenseRetiradaGruaFilter>();
  motiusNoRetirada = [];
  @ViewChild('mainForm') private mainForm: ElementRef;
  constructor(public thisDialogRef: MatDialogRef<SenseRetiradaGruaFilter>,
    private combosService: CombosService,
    @Inject(MAT_DIALOG_DATA) public data, ) {
      this.filter = data.filter;
    }

  async ngOnInit() {
    this.motiusNoRetirada = await this.combosService.GetMotivosNoRetirada();
  }
  ngOnDestroy(): void {
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
