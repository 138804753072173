var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var EjecutivaFilterImpressio = /** @class */ (function (_super) {
    __extends(EjecutivaFilterImpressio, _super);
    function EjecutivaFilterImpressio(DataInici, DataFi, sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        if (DataInici !== null) {
            _this.fechaInicio = DataInici;
            _this.fechaFin = DataFi;
        }
        else {
            var date = new Date();
            var dateCurrentDay = new Date(date.getTime());
            var dateYesterday = new Date(date.getTime());
            dateYesterday.setDate(date.getDate() - 1);
            _this.fechaInicio = dateYesterday;
            _this.fechaFin = dateCurrentDay;
        }
        return _this;
    }
    return EjecutivaFilterImpressio;
}(FilterData));
export { EjecutivaFilterImpressio };
