import { TurnosFilterService } from './../../services/turnos-filter-service';
import { TurnosFilter } from './../../models/turnos-filter';
import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { Turno } from '../../models/turnos-model';
import { TurnosService } from '../../services/turnos-service';

@Component({
  selector: 'app-turnos-table-component',
  templateUrl: './turnos-table-component.component.html',
  styleUrls: ['./turnos-table-component.component.scss']
})
export class TurnosTableComponentComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: Turno[];
  dataSource: CustomDataSource<Turno, TurnosFilter>;
  filter: TurnosFilter = new TurnosFilter('descripcion', 'asc');
  displayedItems = [];
  highlightedRows = [];

  @Input() updatedFilterData: TurnosFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = ['id', 'descripcio'];
  constructor(   private turnosService: TurnosService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n) { }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Turno, TurnosFilter>(this.paginator, new TurnosFilterService(), 'urlGetTurnos', 'urlGetTurnosCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        this.filterService.saveFilter('turnos-filter', this.filter);
      }
    });

    this.filterService.loadFilter('turnos-filter', this.filter);
    this.filter.paginator.init(this.sort, this.paginator);
  }
  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row.secureId);
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.turnosService, filter, this.i18n('Torns'), this.sort.direction, this.sort.active)
    });
  }
}
