var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";
import { HttpParams } from "@angular/common/http";
var defaultSortParam = 'CODI_MAQUINA';
var defaultSortDirection = 'asc';
var MaquinaIncidenciesFilterService = /** @class */ (function (_super) {
    __extends(MaquinaIncidenciesFilterService, _super);
    function MaquinaIncidenciesFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MaquinaIncidenciesFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('codiMaquina', (filter.codiMaquina ? filter.codiMaquina.toString() : ''))
            .append('somId', (filter.somId ? filter.somId.toString() : ''))
            .append('dataCreacioInici', (filter.dataCreacioInici ? filter.dataCreacioInici.toDateString() : ''))
            .append('dataCreacioFi', (filter.dataCreacioFi ? filter.dataCreacioFi.toDateString() : ''))
            .append('dataResolucioInici', (filter.dataResolucioInici ? filter.dataResolucioInici.toDateString() : ''))
            .append('dataResolucioFi', (filter.dataResolucioFi ? filter.dataResolucioFi.toDateString() : ''))
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return MaquinaIncidenciesFilterService;
}(CustomFilterService));
export { MaquinaIncidenciesFilterService };
