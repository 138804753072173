import { PropostaGruaFilter } from './../../grua/Models/proposta-grua-filter';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { api } from 'path-shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class MapService {

    constructor(private http: HttpSecureClient) { }

    //#region ALARMAS SISTEMA

    public descargaAlarmasSistema(): Observable<any> {
        return this.http.get(
            config.urls['apiUrl'] + api.endpoints['urlGetAlarmasSistemaBase'].replace('{fechaConsulta}',
            this.formatDateToLocal(new Date())),
            null);
    }

    public desactivaAlarmasSistema(): Observable<any> {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlDesactivaAlarmasSistemaBase'], null);
    }

    //#endregion

    //#region ALARMAS SMARTPHONE

    public descargaAlarmasPda(): Observable<any> {
        return this.http.get(
            config.urls['apiUrl'] + api.endpoints['urlGetAlarmasGpsBase'].replace('{fechaConsulta}',
            this.formatDateToLocal(new Date())),
            null);
    }

    public desactivaAlarmasSpartphones(): Observable<any> {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlDesactivaAlarmasGpsBase'], null);
    }

    //#endregion

    //#region TRACKING SMARTPHONES

    public descargaTrackingPda(): Observable<any> {
        return this.http.get(
            config.urls['apiUrl'] + api.endpoints['urlGetTrackingBase']
            .replace('{fechaConsulta}', this.formatDateToLocal(new Date()))
            .replace('{isPda}', 'true'),
            null
        );
    }

    public desactivaTrackingPda(): Observable<any> {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlDesactivaTrackingBase'].replace('{isPda}', 'true'), null);
    }

    //#endregion

    //#region TRACKING GRUA

    public descargaTrackingGrua(): Observable<any> {
        return this.http.get(
            config.urls['apiUrl'] + api.endpoints['urlGetTrackingBase']
            .replace('{fechaConsulta}', this.formatDateToLocal(new Date()))
            .replace('{isPda}', 'false'),
            null
        );
    }

    public desactivaTrackingGrua(): Observable<any> {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlDesactivaTrackingBase'].replace('{isPda}', 'false'), null);
    }

    //#endregion

    //#region PROPUESTAS GRUA

    public descargaPropuestasGrua(filter: PropostaGruaFilter): Observable<any> {

        const params: HttpParams = new HttpParams()
            .append('pendiente', filter.pendiente ? filter.pendiente.toString() : 'false')
            .append('aceptada', filter.aceptada ? filter.aceptada.toString() : 'false')
            .append('rechazada', filter.rechazada ? filter.rechazada.toString() : 'false')
            .append('retirada', filter.retirada ? filter.retirada.toString() : 'false')
            .append('fechaConsulta', filter.fechaConsulta ? this.formatDateToLocal(filter.fechaConsulta) : this.formatDateToLocal(new Date()));

        return this.http.get(
            config.urls['apiUrl'] + api.endpoints['urlGetPropuestasGruaBase'], params);
    }

    public desactivaPropuestasGrua(filter: PropostaGruaFilter): Observable<any> {

        const params: HttpParams = new HttpParams()
        .append('pendiente', filter.pendiente ? filter.pendiente.toString() : 'false')
        .append('aceptada', filter.aceptada ? filter.aceptada.toString() : 'false')
        .append('rechazada', filter.rechazada ? filter.rechazada.toString() : 'false')
        .append('retirada', filter.retirada ? filter.retirada.toString() : 'false');

        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlDesactivaPropuestasGruaBase'], params);
    }

    //#endregion

    //#region DENUNCIAS

    public descargaDenuncias(esPolicia: boolean): Observable<any> {
        return this.http.get(
            config.urls['apiUrl'] + api.endpoints['urlGetDenunciasBase']
            .replace('{fechaConsulta}', this.formatDateToLocal(new Date()))
            .replace('{esPolicia}', String(esPolicia)),
            null);
    }

    public desactivaDenuncias(esPolicia: boolean): Observable<any> {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlDesactivaDenunciasBase'].replace('{esPolicia}', String(esPolicia)), null);
    }

    //#endregion

    //#region OCUPACION

    public descargaPoligonosActuales(): Observable<any> {
        return this.http.get(
            config.urls['apiUrl'] + api.endpoints['urlGetPoligonosOcupacionByFecha'].replace('{fechaHora}',
            this.formatDateToLocal(new Date())),
            null);
    }

    //#endregion

    //#region SUPPORT METHODS

    private formatDateToLocal(fecha: Date): string {
        fecha = new Date(fecha);
        const dateString =
            fecha.getFullYear() + '-' +
            ('0' + (fecha.getMonth() + 1)).slice(-2) + '-' +
            ('0' + fecha.getDate()).slice(-2);
        return dateString;
    }

    private formatDateToLocalNoSeparators(fecha: Date): string {
        fecha = new Date(fecha);
        const dateString =
            fecha.getFullYear() +
            ('0' + (fecha.getMonth() + 1)).slice(-2) +
            ('0' + fecha.getDate()).slice(-2) +
            ('0' + fecha.getHours()).slice(-2) +
            ('0' + fecha.getMinutes()).slice(-2) +
            ('0' + fecha.getSeconds()).slice(-2);
        return dateString;
    }

    //#endregion

}
