import { Component, OnInit, Input } from '@angular/core';
import { RefC60Model } from '../../../../models/ref-c60.model';

@Component({
  selector: 'app-ref-c60',
  templateUrl: './ref-c60.component.html'
})
export class RefC60Component implements OnInit {

  @Input() data: RefC60Model[];
  @Input() singleImport: boolean;

  constructor() {
   }

  ngOnInit() {
  }

}
