export enum FiltroAparcareTipo {
    Todos= 'Todos',
    Ventas = 'Ventas',
    Devoluciones = 'Devoluciones',
    Anulaciones = 'Anulaciones'
  }


  export enum FiltroAparcareTipoValor {
    Importe= 'Importe',
    Cantidad = 'Cantidad'
  }