var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var PersonaFilter = /** @class */ (function (_super) {
    __extends(PersonaFilter, _super);
    function PersonaFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.id = '';
        _this.nomComplet = '';
        _this.dni_pasp = '';
        _this.nom_pare = '';
        _this.nom_mare = '';
        _this.data_naixament = null;
        _this.lloc_naixament = '';
        _this.provincia = '';
        _this.nacionalitat = '';
        _this.telefon1 = '';
        _this.telefon2 = '';
        _this.buscat = null;
        _this.antecedents = null;
        _this.juridica = null;
        _this.id_extern = '';
        _this.labels['id'] = _this.i18n('id');
        _this.labels['nomComplet'] = _this.i18n('Nom complet');
        _this.labels['dni_pasp'] = _this.i18n('DNI/PASP');
        _this.labels['nom_pare'] = _this.i18n('Nom pare');
        _this.labels['nom_mare'] = _this.i18n('Nom mare');
        _this.labels['data_naixament'] = _this.i18n('Data naixament');
        _this.labels['lloc_naix'] = _this.i18n('Lloc naixament');
        _this.labels['provincia'] = _this.i18n('Provincia');
        _this.labels['nacionalitat'] = _this.i18n('Nacionalitat');
        _this.labels['telefon1'] = _this.i18n('Telefono');
        _this.labels['telefon2'] = _this.i18n('Telefon 2');
        _this.labels['buscado'] = _this.i18n('Buscat');
        _this.labels['antecedentes'] = _this.i18n('Antecedents');
        _this.labels['juridica'] = _this.i18n('Juridica');
        _this.labels['id_extern'] = _this.i18n('Id extern');
        return _this;
    }
    return PersonaFilter;
}(FilterData));
export { PersonaFilter };
