var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var BancCarreguesC60DetallFilter = /** @class */ (function (_super) {
    __extends(BancCarreguesC60DetallFilter, _super);
    function BancCarreguesC60DetallFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.id = null;
        _this.codiCarrega = '';
        _this.labels['id'] = _this.i18n('Id');
        _this.labels['codiCarrega'] = _this.i18n('Codi càrrega');
        return _this;
    }
    return BancCarreguesC60DetallFilter;
}(FilterData));
export { BancCarreguesC60DetallFilter };
