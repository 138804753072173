import { SortDirection } from "@angular/material";
import { FormMultiSelect } from "path-shared/components/form/form-multi-select/model/form-multi-select.model";
import { FilterData } from "path-shared/models/filter-data";

export class TiquetsClientFilter extends FilterData{
    id: number;
    fechaTicketInicio: string;
    fechaTicketFin: string;
    matricula: string;
    email: string;
    zona: number;
    proveidors: string;

    constructor(sortField: string, sortDir: SortDirection){
        super(sortField, sortDir);
    this.id = null;
    this.matricula = '';
    this.email = '';
    this.fechaTicketInicio = null;
    this.fechaTicketFin = null;
    this.zona = null;
    this.proveidors = '';

    this.labels['id'] = this.i18n('Id');
    this.labels['matricula'] = this.i18n('Matrícula');
    this.labels['email'] = this.i18n('Email');
    this.labels['fechaTicketInicio'] = this.i18n('Data Tiquet Inici');
    this.labels['fechaTicketFin'] = this.i18n('Data Tiquet Fi');
    this.labels['zona'] = this.i18n('Zona');
    this.labels['proveidors'] = this.i18n('Proveidors');
    }
}