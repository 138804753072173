import { SOM_FICHEROS } from "path-models/SOM_FICHEROS";

export class DesperfectosV2Dto {
    DesperfectosAntes: any[] = [];
    DesperfectosDespues: any[] = [];
    DesperfectosOtros:any[] = [];
    ComentarioAntes: string = '';
    ComentarioDespues: string = '';
    ComentarioOtros: string = '';

  }
