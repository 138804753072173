import { Injectable } from '@angular/core';
import { config } from 'path-shared/configuration/config';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Observable } from 'rxjs';
import { HeatMapResponse } from '../models/heat-map-response';
import { api } from 'path-shared/configuration/urls';
import { HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class HeatMapService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) { }

  public getHeatMapData(filtroTickets:FiltroTickets):Observable<any> {
    return this.http.getWithType<any>(config.urls['apiUrl']+api.endpoints['urlHeatMap'],
    new HttpParams()
      .append('Dia', filtroTickets.Dia.toJSON())
      .append('HoraIni', filtroTickets.HoraIni.toString())
      .append('HoraFin', filtroTickets.HoraFin.toString()))
  }
  
}
