import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { ObservacionsPdaFilter } from '../../models/observacions-pda-filter';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';
import { StartupService } from 'path-shared/services/startup/startup.service';
import { CFG_TIPODENUNCIA } from 'path-models/CFG_TIPODENUNCIA';
import { Dictionary } from 'path-shared/models/dictionary';

@Component({
  selector: 'app-observacions-pda-filter',
  templateUrl: './observacions-pda-filter.component.html',
  styleUrls: ['./observacions-pda-filter.component.scss']
})
export class ObservacionsPdaFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: ObservacionsPdaFilter;
  tipusDenuncia = [];
  @Output() notify: EventEmitter<ObservacionsPdaFilter> = new EventEmitter<ObservacionsPdaFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<ObservacionsPdaFilterComponent>,
    private i18n: I18n,
    private startupService: StartupService,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter;
    this.startupService.GetTiposDenuncia().forEach((tipusDenuncia: CFG_TIPODENUNCIA) => {
      this.tipusDenuncia.push(new Dictionary(tipusDenuncia.CODI, tipusDenuncia.DESCRIPCIO));
    });
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }


}
