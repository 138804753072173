import { Dictionary } from 'path-shared/models/dictionary';
import { TextoInformativo } from './../../model/texto-informativo';
import { Component, Input, OnInit } from '@angular/core';
import { Builder } from 'path-shared/models/builder';

@Component({
  selector: 'app-texto-informativo-editor',
  templateUrl: './texto-informativo-editor.component.html',
  styleUrls: ['./texto-informativo-editor.component.scss']
})
export class TextoInformativoEditorComponent implements OnInit {

  _currentEditing: boolean;
  @Input() get currentEditing() {
    return this._currentEditing;
  } set currentEditing(val) {
    this._currentEditing = val;
  }

  _currentTexto: TextoInformativo;
  @Input() get currentTexto() {
    return this._currentTexto;
  } set currentTexto(val) {
    if (val !== undefined && val !== null) {
      this._currentTexto = val;
    } else {
      this.currentTexto = Builder.newTextoInformativo();
    }
  }

  @Input() idiomaDictionary: Array<Dictionary>;

  constructor() {
    this.currentTexto = Builder.newTextoInformativo();
    this.currentEditing = false;
    this.idiomaDictionary = new Array();
  }

  ngOnInit() { }

  textoFormatadoChanged(newText: string) {
    this.currentTexto.htmlValue = newText;
  }

  siglaSeleccionada(sigla: any) {
    this.currentTexto.idioma = sigla;
  }

}
