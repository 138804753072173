import { GoogleMapsService } from './../../../shared/services/startup/google-maps.service';
import { AuthGuardService } from './../../../shared/services/authentication/auth-guard.service';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Instituciones } from 'path-shared/models/instituciones';
import { TrackingDetail } from './models/trackingDetail.model';
import {  Tracking} from './models/tracking.model';
import { TrackingService } from './services/tracking.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AppInjectorModule } from 'app/app-injector/app-injector.module';
import { PdaConfig } from './models/pdaConfig.model';
import { TravelMarker } from 'travel-marker';
import { Parametros } from 'path-shared/models/parametros';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { CFG_PARAMETROS } from 'path-models/CFG_PARAMETROS';
import { ParameterDto } from 'path-shared/services/parameters/model/ParametroDto';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { MatSliderChange, MatSlider } from '@angular/material';


declare var google: any;
declare var require: any;
@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit, AfterViewInit {

  public currentLocation: {
    latitude: number,
    longitude: number
  };

  // public colors  = [ 5740288 , 14558464 , 26324 , 16744013, 16738999, 9790463, 54210, 1947392, 16771846, 16514837];
  public colors  = [ require('./resources/red-circle-lv.png')
                    , require('./resources/purple-circle-lv.png')
                    , require('./resources/grn-circle-lv.png')
                    , require('./resources/wht-circle-lv.png')
                  , require('./resources/blu-circle-lv.png')];
public icons  = [ require('./resources/red-blank.png')
, require('./resources/purple-blank.png')
, require('./resources/grn-blank.png')
, require('./resources/wht-blank.png')
, require('./resources/blu-blank.png')];
public iconsParada  = [ require('./resources/marker_yellow.png')
, require('./resources/marker_orange.png')
, require('./resources/marker_green.png')
, require('./resources/marker_white.png')
, require('./resources/marker_blue.png')];

public iconsGpsDesactivado  = [ require('./resources/red-stars_maps.png')
, require('./resources/ylw-stars_maps.png')
, require('./resources/grn-stars_maps.png')
, require('./resources/wht-stars_maps.png')
, require('./resources/blu-stars_maps.png')];

public detectionIcon = require('./resources/detection.png');
public complaintIcon = require('./resources/complaint.png');

  public zoom: number;
  // config animacio
  marker: TravelMarker = null;
  map: any;
  line: any;
  directionsService: any;
  speedMultiplier = 1;

  displayedColumns = ['incorpora', 'pda', 'LabelHoraInici', 'LabelHoraFinal', 'Agente', 'GpsDisabledAlarm']; // , 'PlayAnimacio'
  displayedColumnsPdaNotTracked = ['pda'];
  displayedColumnsConfig = [ 'pda', 'incorpora', 'incorporaNoActiva', 'pdaNoActiva', 'incorporaActivaAlways', 'incorporaPdaAlways', 'pdaAlways'];
  protected i18n: I18n;
  public pdas: Tracking[];
  public dataSource = [];
  public pdaNotTracked: string[];
  public pdaConfigList: PdaConfig[];
  public activedPDa: PdaConfig[];
  public unActivedPDa: PdaConfig[];
  public activedTrackAlwaysPDa: PdaConfig[];
  private idInst: number;
  public posVelocitatMarker: number;
  public velocitatsMarker: [4, 3, 2];
  public numeroPdaShowing: string;
  public labelMarkerName: string;
  public actualPdaTrack: Tracking;
  public positionArrayTracking = 0;
  public actualPositionTrack: TrackingDetail;
  public labelHoraActual: string;
  public isPlaying: boolean;
  isPlayedFirstTime: boolean;
  @ViewChild('slider') slider: MatSlider;

  interval;
  dataDialog: ComponentDialog = Builder.newComponentDialog();


  /* private _timeOut: number;
  public get timeOut() {
    return this._timeOut;
  }
  public set timeOut(val) {
    if (val !== undefined && val !== null && this._timeOut !== val) {

      const time: CFG_PARAMETROS ={
        CODINSTIT: Number.parseInt(sessionStorage.getItem('currentInst')),
        DESCRIPCION: 'GPSTIMEOUT',
        PARAMETRO: 'GPSTIMEOUT',
        VALOR: val.toString()
      };
      this.parametersService.setParameter(time);
    }
    this._timeOut = val;
  }
  private _refresc: number;
  public get refresc() {
    return this._refresc;
  }
  public set refresc(val) {
    if (val !== undefined && val !== null && this._refresc !== val) {

      const gpsTrack: CFG_PARAMETROS ={
        CODINSTIT: Number.parseInt(sessionStorage.getItem('currentInst')),
        DESCRIPCION: 'GPSTRACKING',
        PARAMETRO: 'GPSTRACKING',
        VALOR: val.toString()
      };
      this.parametersService.setParameter(gpsTrack);
    }
    this._refresc = val;
  } */
public timeOut: number;
public refresc: number;
public minutosSinActividad: number;
_timeOut: number;
_refresc: number;
_minutosSinActividad: number;

  dataActual: Date;
  constructor(
    private authGuard: AuthGuardService,
    private googleMapsService: GoogleMapsService,
    private trackingService: TrackingService,
    private parametersService: ParametersService,
    private not: NotificationService,
    public auth: AuthorizationService) {
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      const institution: Instituciones = JSON.parse(inst);
      this.currentLocation = {latitude: institution.Latitud, longitude: institution.Longitud};
      this.idInst =  Number.parseInt(institution.IdInstitucion);
    }
    const parmSess: CFG_PARAMETROS[] = JSON.parse(sessionStorage.getItem('parameters'));
    if (parmSess !== undefined && parmSess !== null && parmSess.length > 0 && parmSess[0] !== null && parmSess[0] !== undefined) {
      this.idInst = parmSess[0].CODINSTIT;
    }
    this.i18n = AppInjectorModule.injector.get(I18n);
    this.posVelocitatMarker = 0;
    this.velocitatsMarker = [4, 3, 2];
  }

  ngOnInit() {

    this.loadDataPdas();
    this.loadPdaConfigData();

    this.getParameters();

    this.CarregarNovesDades();
    /*this.marker.event.onEvent((event: EventType, data: TravelData) => {
      if(event === 'finished'){
        alert('finalitza');
      }
       });*/

    this.GetGoogleMapsApiKey();
  }
  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Tracking'));
    }
  }

  GetGoogleMapsApiKey() {
    const tokenSession = sessionStorage.getItem('access_token');
    const token = localStorage.getItem('access_token');
    const idInstitucion = localStorage.getItem('IdInstitucion');
    const idInstitucionSession = sessionStorage.getItem('IdInstitucion');
    if ((token === null || token === undefined) && (tokenSession !== undefined && tokenSession !== null)) {
        localStorage.setItem('access_token', tokenSession);
    }
    if ((idInstitucion === null || idInstitucion === undefined) && (idInstitucionSession !== undefined && idInstitucionSession !== null)) {
        localStorage.setItem('IdInstitucion', idInstitucionSession);
    }

    if (this.authGuard.canActivate()) {
      this.googleMapsService.getMapsApiKey().subscribe((result: CFG_PARAMETROS) => {
        if (result !== undefined) {
          if (localStorage.getItem('apikeyGoogleMaps') === null || localStorage.getItem('apikeyGoogleMaps') !== result.VALOR) {
            // O bien no teniamos una key asignada o bien el valor de la key recibida es nuevo.
            localStorage.removeItem('apikeyGoogleMaps');
            localStorage.setItem('apikeyGoogleMaps', result.VALOR);

            window.location.reload();
          }
        }
      });
    }
  }

  CarregarNovesDades() {
    this.not.onNovesDadesGpsChange.subscribe(result => {
      const pdasPendentsLlegir: Array<Tracking> = JSON.parse(result.data);
      if (pdasPendentsLlegir !== undefined && pdasPendentsLlegir !== null) {
          if (this.pdas === undefined || this.pdas === null) {
            this.pdas = [];
          }

          pdasPendentsLlegir.forEach(nova => {
            const dataSelected = this.GetData().substring(0, 8);
            if (nova.DataHoraInici.toString().substring(0, 8) === dataSelected) {
              if (this.pdas.filter(x => x.PDA === nova.PDA).length > 0) {
                this.pdas.filter(x => x.PDA === nova.PDA).forEach( pdaAActualitzar => {
                  const newValue = pdaAActualitzar;
                  const index = this.pdas.indexOf(pdaAActualitzar);
                  this.pdas.splice(index, 1);
                  newValue.Coordenades = nova.Coordenades;
                  newValue.DataHoraFinal = nova.DataHoraFinal;
                  newValue.LabelHoraFinal = nova.LabelHoraFinal;
                  newValue.LatitudFinal = nova.LatitudFinal;
                  newValue.LongitudFinal = nova.LongitudFinal;
                  this.pdas.push(newValue);
                });
              } else {
                this.pdas.push(nova);
              }
            }
          });
      }
      /*if (this.pdasPendentsLlegir !== pdasPendentsLlegir.length) {
        this.pdasPendentsLlegir = pdasPendentsLlegir.length;
      }*/
    });
  }


  GetData(): string {
    // const dataActual = new Date();
    if (this.dataActual === undefined || this.dataActual === null ) {
      this.dataActual = new Date();
    }
    let data = this.dataActual.getFullYear().toString();
      let month = (this.dataActual.getMonth() + 1).toString();
      if (month.length === 1) {
        month = '0' + month;
      }
      let day = this.dataActual.getDate().toString();
      if (day.length === 1) {
        day = '0' + day;
      }
      data += month + day;
      data += '00';
      data += '00';
      data += '00';
      return data;
}

Checked(row: any) {

  this.currentLocation.latitude = row.LatitudFinal;
  this.currentLocation.longitude = row.LongitudFinal;

  this.SeleccionarFila(row);
  // this.dataSource = this.pdas;
  /*
  this.pdas.filter(g => g.PDA === row.PDA).forEach(element =>{
    element.Selected = row.selected;
  });*/
}

loadDataPdas(): void {
  const dat = this.GetData();
  if ( dat !== undefined && dat !== null) {
    this.trackingService.getAllByDay(dat).subscribe(result => {
     this.pdas = result;
     const pos = 0;
     if ( this.pdas !== undefined && this.pdas !== null) {

        this.pdas.forEach( element => {
          element.Selected = false;
          // element.ColorHexa='https://mt.google.com/vt/icon/name=icons/onion/SHARED-mymaps-container-bg_4x.png,icons/onion/SHARED-mymaps-container_4x.png,icons/onion/1731-walking-pedestrian_4x.png&amp;highlight=ff000000,0288D1,ff000000&amp;scale=2.0';
         // element.ColorHexa='';
          element.LabelInici = element.PDA; // this.i18n('Inici') +' ' +
          element.LabelFinal = element.PDA; // this.i18n('Final')  +' '+  element.PDA;

          element.ColorHexa =  this.colors[0];
          element.ColorHexaParada =  this.iconsParada[0];
          element.ColorHexaGpsDesactivado =  this.iconsGpsDesactivado[0];
          element.IconMarker = this.icons[0];

          element.detectionIcon = this.detectionIcon;
          element.complaintIcon = this.complaintIcon;
          // if (pos < 5 ) {
          //   element.ColorHexa =  this.colors[pos];
          //   element.ColorHexaParada =  this.iconsParada[pos];
          //   element.IconMarker = this.icons[pos];
          //   // element.IconMarker= "{url: '"+this.icons[pos]+"',scaledSize: {width: 50,height: 30}}";
          // } else {
          //   pos = pos - 5;
          //  element.ColorHexa = this.colors[ pos];
          //  element.ColorHexaParada =  this.iconsParada[pos];
          //  element.IconMarker = this.icons[pos];
          //  // element.IconMarker= "{url: '"+this.icons[pos]+"',scaledSize: {width: 50,height: 30}}";
          // }
          // pos++;
        });
      }
    });

    // this.trackingService.GetPdaNotTracked(dat).subscribe( resultNotTracked => {

    //   this.pdaNotTracked = resultNotTracked;
    // });
  }

}
loadDataPdasAndSelect(): void {
  const dat = this.GetData();
  if ( dat !== undefined && dat !== null) {
    this.trackingService.getAllByDay(dat).subscribe(result => {
     this.pdas = result;
    //  const pos = 0;
     if ( this.pdas !== undefined && this.pdas !== null) {

        this.pdas.forEach( element => {
          element.Selected = this.numeroPdaShowing === element.PDA;
          element.LabelInici = element.PDA; // this.i18n('Inici') +' ' +
          element.LabelFinal = element.PDA; // this.i18n('Final')  +' '+  element.PDA;

          element.ColorHexa =  this.colors[0];
          element.ColorHexaParada =  this.iconsParada[0];
          element.ColorHexaGpsDesactivado =  this.iconsGpsDesactivado[0];
          element.IconMarker = this.icons[0];
        });
      }
    });
  }

}

onDateChange(event): void {
    if (this.dataActual !== undefined && this.dataActual !== event ) {
      this.dataActual = event;
      this.loadDataPdas();
      this.positionArrayTracking = 0;
      this.actualPdaTrack = undefined;
      this.actualPositionTrack = undefined;
      this.numeroPdaShowing = null;
    }
}

// Configuracio PDA
loadPdaConfigData() {
  this.trackingService.GetPdaConfig(this.GetDataToday()).subscribe( result => {

    this.pdaConfigList = result;

    if (this.pdaConfigList !== null) {
      this.activedPDa = this.pdaConfigList.filter(x => x.Activa === true && x.ActivaSempre === false);
      this.unActivedPDa = this.pdaConfigList.filter(x => x.Activa === false  && x.ActivaSempre === false);
      this.activedTrackAlwaysPDa = this.pdaConfigList.filter(x => x.ActivaSempre === true);
    }
  });
}

GetDataToday(): string {
  const dat = new Date();


  let data = dat.getFullYear().toString();
    let month = (dat.getMonth() + 1).toString();
    if (month.length === 1) {
      month = '0' + month;
    }
    let day = dat.getDate().toString();
    if (day.length === 1) {
      day = '0' + day;
    }
    data += month + day;
    data += '00';
    data += '00';
    data += '00';
    return data;
}
CheckedConfig(row: any) {

  this.activedPDa = this.pdaConfigList.filter(x => x.Activa === true  && x.ActivaSempre === false);
    this.unActivedPDa = this.pdaConfigList.filter(x => x.Activa === false  && x.ActivaSempre === false);
    this.activedTrackAlwaysPDa = this.pdaConfigList.filter(x => x.ActivaSempre === true );
    if (row !== null && row !== undefined && row.PDA !== null) {
      this.trackingService.SetEstatPda(row).subscribe( result => {
      });
    }
}

// configuracio pdas
UnTrackPdaAlways(row: any) {
  this.pdaConfigList.filter(x => x.PDA === row.PDA).forEach(pda => {
    pda.ActivaSempre = false;
    pda.Activa = false;
    this.trackingService.UnTrackPdaAlways(row).subscribe( result => {
    });
  });

  this.loadDataPdas();
}

TrackPdaAlways(row: any) {
  this.pdaConfigList.filter(x => x.PDA === row.PDA).forEach(pda => {
    pda.ActivaSempre = true;
    pda.Activa = false;
    this.trackingService.TrackPdaAlways(row).subscribe( result => {
    });
  });

  this.loadDataPdas();
}


TrackPdaManual(row: any) {

  this.pdaConfigList.filter(x => x.PDA === row.PDA).forEach(pda => {
    pda.ActivaSempre = false;
    pda.Activa = true;
    this.trackingService.TrackPdaManual(row).subscribe( result => {
    });
  });

  this.loadDataPdas();
}


UnTrackPdaManual(row: any) {
  this.pdaConfigList.filter(x => x.PDA === row.PDA).forEach(pda => {
    pda.ActivaSempre = false;
    pda.Activa = false;
    this.trackingService.TrackPdaManual(row).subscribe( result => {
    });
  });

  this.loadDataPdas();
}


// configuracio pdas

ConfigAnimacioMarker() {

}

onMapReady(map: any) {
  // console.log(map);
  this.map = map;
}

// ShowTrackPda(row: any) {

//   this.pdas.filter(g => g.PDA === row.PDA).forEach(element => {
//     // define marker
//     // const route = this.line.getPath().j;
//     // options
//     // eliminem punt anterior
//     if (this.marker !== undefined && this.marker !== null) {
//       this.marker.setMap(null);
//     }
//     const options: TravelMarkerOptions = {
//       map: this.map,  // map object
//       speed: 250,  // default 10 , animation speed
//       interval: 10, // default 10, marker refresh time
//       speedMultiplier: this.speedMultiplier,
//       markerOptions: {
//         title: 'Travel Marker',
//         label: this.labelMarkerName,
//         animation: google.maps.Animation.DROP,
//         icon: {
//           src: 'src/assets/images/markerUser.png',
//           // This marker is 20 pixels wide by 32 pixels high.
//           animation: google.maps.Animation.DROP,
//           // size: new google.maps.Size(256, 256),
//           scaledSize: new google.maps.Size(128, 128),
//           // The origin for this image is (0, 0).
//           origin: new google.maps.Point(element.LatitudInici, element.LongitudInici),
//           // The anchor for this image is the base of the flagpole at (0, 32).
//           anchor: new google.maps.Point(element.LatitudFinal, element.LongitudFinal)
//         }
//       },
//     };

//     element.Selected = true;
//     this.labelMarkerName = element.DataHoraInici.toString();
//     this.currentLocation.latitude = element.LatitudFinal;
//     this.currentLocation.longitude = element.LongitudFinal;
//     this.marker = new TravelMarker(options);

//     // add locations from direction service
//     const locationArray: LatLng[] = [];
//     element.Coordenades.forEach(cord => {
//       let o: LatLng =   new google.maps.LatLng(cord.Latitud , cord.Longitud);
//       o.name = cord.Data;
//       locationArray.push(o);
//     });
//     this.marker.addLocation(locationArray);

//     this.numeroPdaShowing = row.PDA;
//     this.marker.play();
// let numero=0;
//     this.marker.event.onEvent((event: EventType, data: TravelData) => {
//       if ( data.status === 'finished') {
//         this.numeroPdaShowing = '';
//         this.marker.setMap(null);
//       }
//       if (event === 'checkpoint') {
//         let t3 =  this.marker.path[data.index];
//         this.labelMarkerName = t3.name;
//        this.marker.setMarkerOptions(  {
//           title: 'Travel Marker',
//           label: this.labelMarkerName,
//           animation: google.maps.Animation.DROP,
//           icon: {
//             src: 'src/assets/images/markerUser.png',
//             // This marker is 20 pixels wide by 32 pixels high.
//             animation: google.maps.Animation.DROP,
//             // size: new google.maps.Size(256, 256),
//             scaledSize: new google.maps.Size(128, 128),

//             // The origin for this image is (0, 0).
//             origin: new google.maps.Point(t3.latitud, t3.longitud),
//             // The anchor for this image is the base of the flagpole at (0, 32).
//             anchor: new google.maps.Point(element.LatitudFinal, element.LongitudFinal)
//           }
//         }
//        );
//       }

//       //this.marker.setMarkerOptions({label: numero.toString()} )
//       //numero++;
//     });

//     /*this.marker.event.onEvent((event: 'checkpoint', data: TravelData) => {
//        let t1 = this.marker.getOptions();
//        let t2 = this.marker.getPosition();
//        let t3 =  this.marker.path[this.marker.deltaIndex].name;
//        this.marker.setMarkerOptions({label: t3});
//        });*/
//     // this.marker.event.addListener(x => {
//      // this.marker.marker.path[this.marker.deltaIndex].name;
//     // })
//   });
// }


ShowTrackPda(row: any) {
// this.SeleccionarFila(row);
this.numeroPdaShowing = row.PDA;
this.pdas.filter(g => g.PDA === row.PDA).forEach(element => {
  element.Selected = true;
  this.currentLocation.latitude = element.LatitudInici;
  this.currentLocation.longitude = element.LongitudInici;
  this.actualPdaTrack = element;
  this.interval = setInterval(() => { this.nextMarker(); }, this.velocitatsMarker[this.posVelocitatMarker] * 500);
  this.isPlaying = true;
  });
}

nextMarker() {
  this.actualPositionTrack = this.actualPdaTrack.Coordenades[this.positionArrayTracking];
  if (this.actualPositionTrack === undefined || this.actualPositionTrack === null ) {
    this.positionArrayTracking = 0;
    clearInterval(this.interval);
    this.isPlaying = false;
    this.isPlayedFirstTime = false;
  }

  this.labelHoraActual = this.actualPositionTrack.Hora;
  this.positionArrayTracking++;
  if (this.isPlayedFirstTime !== undefined && this.isPlayedFirstTime !== null && (this.actualPositionTrack === undefined || this.actualPositionTrack === null )) {
    this.isPlayedFirstTime = false;
  }
}
getParameters() {

  this.refresc = Number.parseInt( this.parametersService.getParameter(Parametros.GPSTRACKING));
  this.timeOut = Number.parseInt( this.parametersService.getParameter(Parametros.GPSTIMEOUT));
  this.zoom = Number.parseInt(this.parametersService.getParameter(Parametros.GPSZOMINICIAL));
  this._refresc = Number.parseInt( this.parametersService.getParameter(Parametros.GPSTRACKING));
  this._timeOut = Number.parseInt( this.parametersService.getParameter(Parametros.GPSTIMEOUT));

  this._minutosSinActividad = Number.parseInt( this.parametersService.getParameter(Parametros.MINUTOSSINACTIVIDAD));
  this.minutosSinActividad = Number.parseInt( this.parametersService.getParameter(Parametros.MINUTOSSINACTIVIDAD));
}
canviTimeOut(e: any) {

  if (this.timeOut !== this._timeOut ) {
    const time: ParameterDto = {
      CODINSTIT: this.idInst,
      DESCRIPCION: 'GPSTIMEOUT',
      PARAMETRO: 'GPSTIMEOUT',
      VALOR: this.timeOut.toString()
    };
    this.parametersService.setParameter(time).subscribe( result => {
    });
    this._timeOut = this.timeOut;
  }
}

canviMinutoSinActividad(e: any) {

  if (this.minutosSinActividad !== this._minutosSinActividad ) {
    const time: ParameterDto = {
      CODINSTIT: this.idInst,
      DESCRIPCION: 'MINUTOSSINACTIVIDAD',
      PARAMETRO: 'MINUTOSSINACTIVIDAD',
      VALOR: this.minutosSinActividad.toString()
    };
    this.parametersService.setParameter(time).subscribe( result => {
    });
    this.minutosSinActividad = this.minutosSinActividad;

    if ( this.numeroPdaShowing !== undefined && this.numeroPdaShowing !== null && this.numeroPdaShowing !== '' ) {
      this.loadDataPdasAndSelect();
    } else {
      this.loadDataPdas();
    }
  }
}

  canviRefresc(e: any) {

    if (this.refresc !== this._refresc ) {
      const time: ParameterDto = {
        CODINSTIT: this.idInst,
        DESCRIPCION: 'GPSTRACKING',
        PARAMETRO: 'GPSTRACKING',
        VALOR: this.refresc.toString()
      };
      this.parametersService.setParameter(time).subscribe( result => {
      });
      this._refresc = this.refresc;
    }
  }


  DesmarcarTotesManuals() {
    this.pdaConfigList.filter(x => x.Activa === true).forEach(pda => {
      pda.ActivaSempre = false;
      pda.Activa = false;
      this.trackingService.UnTrackPdaManual(pda).subscribe( result => {
      });
    });
    this.loadDataPdas();
  }
  MarcarTotesManuals() {
    this.pdaConfigList.filter(x => x.Activa === false && x.ActivaSempre === false).forEach(pda => {
      pda.ActivaSempre = false;
      pda.Activa = true;
      this.trackingService.TrackPdaManual(pda).subscribe( result => {
      });
    });
    this.loadDataPdas();
  }

  TrackPdaAlwaysTotes() {
    this.pdaConfigList.filter(x => x.Activa === false && x.ActivaSempre === false).forEach(pda => {
      pda.ActivaSempre = true;
      pda.Activa = false;
      this.trackingService.TrackPdaAlways(pda).subscribe( result => {
      });
    });
    this.loadDataPdas();
  }
  UnTrackPdaAlwaysTotes() {
    this.pdaConfigList.filter(x => x.Activa === false && x.ActivaSempre === true).forEach(pda => {
      pda.ActivaSempre = false;
      pda.Activa = false;
      this.trackingService.UnTrackPdaAlways(pda).subscribe( result => {
      });
    });
    this.loadDataPdas();
  }

  /*onMouseOver(infoWindow, gm) {

    if (gm.lastOpen && gm.lastOpen.isOpen) {
      gm.lastOpen.close();
    }

    gm.lastOpen = infoWindow;

    infoWindow.open();
  }
  mouseOut(infoWindow, gm) {
    infoWindow.close();
  }*/
  canviPestanya() {
    if (this.pdaConfigList.filter(x => x.Activa === true).length > 0) {
      this.pdaConfigList.filter(x => x.Activa === true).forEach(pda => {
        if (this.pdas === undefined || this.pdas === null) {
          this.pdas = [];
        }
        if (this.pdas.filter(j => j.PDA === pda.PDA).length === 0) {
          const nouElement = {}  as any;
          nouElement.PDA = pda.PDA;
          this.pdas.push(nouElement);
        }
      });
    }
  }

  SeleccionarFila(row: any) {
    if (!row.Selected) { // en caso de seleccionar primero desmarcamos todas las otras opciones
      this.pdas.filter(j => j.Selected).forEach( x => {
        x.Selected = false;
      });
    }
    row.Selected = !row.Selected;
    if (row.Selected) {
      this.pause();
      this.positionArrayTracking = 0;
      this.currentLocation.latitude = row.LatitudFinal;
      this.currentLocation.longitude = row.LongitudFinal;
      this.actualPdaTrack = row;
      this.actualPositionTrack = undefined;
      this.numeroPdaShowing = row.PDA;
    }
  }
  PositionChanged(event: MatSliderChange) {
    // this.slider.thumbLabel = this.labelHoraActual;
    // PositionChanged
    // this.marker.setSpeedMultiplier(event.value);
    this.actualPositionTrack = this.actualPdaTrack.Coordenades[this.positionArrayTracking];
  }
  play() {
    this.interval = setInterval(() => { this.nextMarker(); }, this.velocitatsMarker[this.posVelocitatMarker] * 500);
    if (this.isPlayedFirstTime !== undefined && this.isPlayedFirstTime !== null && this.isPlayedFirstTime !== false) {
      this.next();
    }
    this.isPlaying = true;
  }
  pause() {
    clearInterval(this.interval);
    this.isPlaying = false;
  }
  stop() {
    this.positionArrayTracking = 0;
    clearInterval(this.interval);
    this.isPlaying = false;
  }
  next() {
   this.positionArrayTracking++;
   this.actualPositionTrack = this.actualPdaTrack.Coordenades[this.positionArrayTracking];
  }
  prev() {
    this.positionArrayTracking--;
    this.actualPositionTrack = this.actualPdaTrack.Coordenades[this.positionArrayTracking];
  }
  increaseSpeed() {
    // this.velocitatMarker = this.velocitatMarker * 2;
    if (this.posVelocitatMarker === 2) {
      this.posVelocitatMarker = 0;
    } else {
      this.posVelocitatMarker++;
    }
    clearInterval(this.interval);
    this.interval = setInterval(() => { this.nextMarker(); }, this.velocitatsMarker[this.posVelocitatMarker] * 500);
  }

 /* reduceSpeed() {
    this.velocitatMarker  = this.velocitatMarker * 0.5;
    clearInterval(this.interval);
    this.interval = setInterval(() => { this.nextMarker()}, this.velocitatMarker * 500);
  }*/

  getUrlIcon(point: any): string {
    if (point !== undefined && point !== null
       &&  point.AlarmaGpsDesactivado !== undefined && point.AlarmaGpsDesactivado !== null) {
        return point.ColorHexaGpsDesactivado;
    } else if (point.DiferenciaParada) {
      return point.ColorHexaParada;
    } else {
      return point.ColorHexa;
    }
  }

  clickMarker(point: any) {
    this.pause();
    const position = this.actualPdaTrack.Coordenades.findIndex(x => x.secure_id === point.secure_id);
    if (position !== undefined && position !== null) {
      this.positionArrayTracking = position;
      this.actualPositionTrack = this.actualPdaTrack.Coordenades[this.positionArrayTracking];
      this.actualPdaTrack.Coordenades[position].Clicked = true;
      this.trackingService.GetInfoByPoint(point).subscribe( x => {
        this.actualPdaTrack.Coordenades[position].NumDenuncias = x.NumDenuncias;
        this.actualPdaTrack.Coordenades[position].NumDetecciones = x.NumDetecciones;
        this.actualPdaTrack.Coordenades[position].NumPropostesGrua = x.NumPropostesGrua;
        this.actualPdaTrack.Coordenades[position].Agente = x.Agente;
      });
    }
  }

  clickMarkerOnMovment($event: Event) {
    this.pause();
    const position = this.actualPdaTrack.Coordenades.findIndex(x => x.secure_id === this.actualPdaTrack.Coordenades[this.positionArrayTracking].secure_id);
    if (position !== undefined && position !== null) {
      this.positionArrayTracking = position;
      this.actualPositionTrack = this.actualPdaTrack.Coordenades[this.positionArrayTracking];
      this.actualPdaTrack.Coordenades[position].Clicked = true;
      this.trackingService.GetInfoByPoint(position).subscribe( x => {
        this.actualPdaTrack.Coordenades[position].NumDenuncias = x.NumDenuncias;
        this.actualPdaTrack.Coordenades[position].NumDetecciones = x.NumDetecciones;
        this.actualPdaTrack.Coordenades[position].NumPropostesGrua = x.NumPropostesGrua;
        this.actualPdaTrack.Coordenades[position].Agente = x.Agente;
      });
    }
    }

  // formatLabel(value: any): string  {
  //   //return this.labelHoraActual;
  //   return 'hi1';
  //   /*if(this.actualPositionTrack !== undefined) {
  //     return this.actualPositionTrack.Hora;
  //   }*/
  // }
}
