import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { TramitarEjecutivasFilter } from '../models/TramitarEjecutivas-filter.model';

const defaultSortParam = 'EXP';
const defaultSortDirection = 'asc';

@Injectable()

export class TramitarEjecutivasFilterService extends CustomFilterService {
  public createFilterParams(filter: TramitarEjecutivasFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }


  getTristateValue(value) {
    if (value === null) {
      return '';
    } else {
      return value.toString();
    }
  }
}
