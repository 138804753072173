<app-filter-header title="Filtrar DeltaCar Histórico" i18n-title (apply)="this.apply()" (reset)="this.reset()"
  (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">

  <div class="row">
    <app-form-text-edit id="deltacar-historico-filter-matricula" class="col-6" i18n-label label="Matrícula"
      [(value)]="filter.matricula"></app-form-text-edit>
    <app-form-combo-edit class="col-6" i18n-label label="Estado" [items]="estados" valueField="descripcio"
      textField="descripcio" [(value)]="filter.estado"></app-form-combo-edit>

  </div>
  <div class="row">
    <app-form-date-edit id="deltacar-historico-filter-dataIni" class="col-6" i18n-label label="Fecha inicio"
      [(value)]="filter.dataInici" type="calendar"></app-form-date-edit>
    <app-form-date-edit id="deltacar-historico-filter-dataFi" class="col-6" i18n-label label="Fecha fin"
      [(value)]="filter.dataFi" type="calendar"></app-form-date-edit>
  </div>

</div>