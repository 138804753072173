import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var EventEmitterService = /** @class */ (function () {
    function EventEmitterService() {
        this.invokeFirstComponentFunction = new EventEmitter();
    }
    EventEmitterService.prototype.onFirstComponentButtonClick = function () {
        this.invokeFirstComponentFunction.emit();
    };
    EventEmitterService.ngInjectableDef = i0.defineInjectable({ factory: function EventEmitterService_Factory() { return new EventEmitterService(); }, token: EventEmitterService, providedIn: "root" });
    return EventEmitterService;
}());
export { EventEmitterService };
