var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
export var TipoVehiculo;
(function (TipoVehiculo) {
    TipoVehiculo[TipoVehiculo["SinVehiculo"] = 1] = "SinVehiculo";
    TipoVehiculo[TipoVehiculo["VehiculoCoche"] = 2] = "VehiculoCoche";
    TipoVehiculo[TipoVehiculo["VehiculoMoto"] = 3] = "VehiculoMoto";
})(TipoVehiculo || (TipoVehiculo = {}));
var ReservesFilter = /** @class */ (function (_super) {
    __extends(ReservesFilter, _super);
    function ReservesFilter(sortField, sortDir, tipoVehiculo) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.id = null;
        _this.matricula = '';
        _this.email = '';
        _this.fechaInicio = new Date();
        _this.fechaInicio.setHours(0, 0, 0, 0);
        _this.tipoVehiculo = tipoVehiculo;
        _this.labels['id'] = _this.i18n('Id');
        _this.labels['matricula'] = _this.i18n('Matrícula');
        _this.labels['email'] = _this.i18n('Email');
        _this.labels['fechaInicio'] = _this.i18n('Data Reserva');
        _this.labels['tipoVehiculo'] = _this.i18n('Tipus Vehicle');
        _this.formatOutput['fechaInicio'] = 'shortDate';
        _this.formatOutput['fechaFin'] = 'shortDate';
        return _this;
    }
    return ReservesFilter;
}(FilterData));
export { ReservesFilter };
