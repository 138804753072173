import { IFilterData } from './../../models/i-filter-data';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  constructor(
    // private localStorage: LocalSt
  ) { }

  saveFilter(name: string, data: IFilterData) {
    localStorage.setItem(name, data.toJSON());
  }

  loadFilter(name: string, data: IFilterData) {
    const json = localStorage.getItem(name);
    data.fromJSON(json);
  }

}
