<app-dialog-header label="Desplaçats grua" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow"
    icon="fa-th-list"></app-tool-button>

</app-toolbar>
<div class="row">
  <mat-tab-group #tabGroup class="col-xl-12">
    <mat-tab i18n-label label="Fotos">
      <app-photo-box-grua #pbg [numDenuncia]="this.vehicleDiposit.iddenuncia"
        [imagesInput]="this.vehicleDiposit?.IMATGES" [isDialog]="false" height="auto" [botoMatricula]="false"
        [allowSelect]="false"></app-photo-box-grua>
    </mat-tab>
    <mat-tab i18n-label label="Desperfectes">
      <app-diposit-grua-desperfectos-v2 [codiSomPolGrua]="this.vehicleDiposit.iddenuncia">
      </app-diposit-grua-desperfectos-v2>
    </mat-tab>
  </mat-tab-group>

</div>