import { OnInit } from '@angular/core';
import { ActuacioIncidenciaFilter } from '../../models/actuacioFilter-filter';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { MaquinasService } from '../../services/maquinas.service';
import { ImagesService } from 'path-shared/services/images/images.service';
import { IncidenciesImagesComponent } from '../incidencies-images/incidencies-images.component';
var ActuacionsTableComponent = /** @class */ (function () {
    function ActuacionsTableComponent(maquinesService, imagesService, auth, dialog, filterService, i18n) {
        this.maquinesService = maquinesService;
        this.imagesService = imagesService;
        this.auth = auth;
        this.dialog = dialog;
        this.filterService = filterService;
        this.i18n = i18n;
        this.displayedItems = [];
        this.displayedColumns = ['somId', 'DataInici', 'DataFi', 'Agent', 'Observacions', 'imatges'];
        this.images = [];
        this.incidenciaId = null;
        this.dataSource = null;
        this.init = true;
        this.filter = new ActuacioIncidenciaFilter('somId', 'asc');
    }
    Object.defineProperty(ActuacionsTableComponent.prototype, "inputIncidenciaId", {
        get: function () {
            return this.incidenciaId;
        },
        set: function (val) {
            this.incidenciaId = val;
            this.buscar();
        },
        enumerable: true,
        configurable: true
    });
    ActuacionsTableComponent.prototype.ngOnInit = function () {
    };
    ActuacionsTableComponent.prototype.ngOnChanges = function (changes) {
        this.buscar();
    };
    ActuacionsTableComponent.prototype.buscar = function () {
        var _this = this;
        if (this.incidenciaId != null) {
            this.maquinesService.getActuacionsIncidencies(this.incidenciaId).subscribe(function (res) {
                _this.dataSource = new MatTableDataSource(res);
                _this.dataSource.sort = _this.sort;
                _this.init = false;
            });
            // this.dataSource.subject.subscribe((data) => {
            //   data.forEach(item => {
            //     this.displayedItems[item.somId] = {
            //       id: item.somId
            //     };
            //   });
            // });
        }
    };
    ActuacionsTableComponent.prototype.buscarByRow = function (row) {
        var _this = this;
        this.maquinesService.getActuacionsIncidencies(row.somId).subscribe(function (res) {
            _this.dataSource = new MatTableDataSource(res);
            _this.dataSource.sort = _this.sort;
            _this.init = false;
        });
        this.dataSource.subject.subscribe(function (data) {
            data.forEach(function (item) {
                _this.displayedItems[item.somId] = {
                    id: item.somId
                };
            });
        });
        return this.displayedItems;
    };
    ActuacionsTableComponent.prototype.viewImages = function (contenedor) {
        var _this = this;
        // afegirImatgesWithContenedor
        this.imagesService.getImagesByContenedor(contenedor).subscribe(function (images) {
            var img = [];
            img.push(images[0]);
            _this.images = images;
            _this.images.forEach(function (element) {
                element.CONTENEDOR = contenedor;
            });
            _this.incidenciesImagesDialogRef = _this.dialog.open(IncidenciesImagesComponent, {
                minWidth: '50%',
                maxWidth: '90%',
                maxHeight: '70%',
                closeOnNavigation: false,
                autoFocus: true,
                data: { 'ficheros': images, 'contenedor': contenedor, origen: 'INCI_ACTU' },
            });
            _this.incidenciesImagesDialogRef.componentInstance.somId = contenedor;
            _this.incidenciesImagesDialogRef.afterClosed().subscribe(function () {
                _this.imagesService.getImagesByContenedor(contenedor).subscribe(function (newImages) {
                    _this.dataSource.getData().find(function (x) { return x.somId === contenedor; }).imatges = newImages.length;
                    _this.images = newImages;
                    _this.images.forEach(function (element) {
                        element.CONTENEDOR = contenedor;
                    });
                });
            });
        });
    };
    return ActuacionsTableComponent;
}());
export { ActuacionsTableComponent };
