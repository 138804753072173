<form #mainForm>
  <div class ="border border-secondary fondo" style="padding:10px;">
    <div class="row">
      <app-form-date-edit label="Fecha de la presentación de la alegación" i18n-label [(value)]="this.dto.fechaAlegacion" required class="col-8"></app-form-date-edit>
    </div>
    <div class="row">
      <app-form-text-edit label="Registro entrada" i18n-label [(value)]="this.registroEntrada" required class="col-8"></app-form-text-edit>
    </div>
    <div class="row">
      <button class="btn btn-sq-lg btn-primary" style="width: 100%">
        <input type="file" accept=".pdf" #fileUpload id="fileUpload" name="fileUpload" (change)="onFileSelected($event.target.files)"/>
        <i class="fas fa-edit"></i><br/><span i18n>Subir PDF</span>
      </button>
    </div>
  </div>
</form>