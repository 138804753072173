import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../../../../../shared/services/authentication/auth-guard.service";
var ZbRutasService = /** @class */ (function () {
    function ZbRutasService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
    }
    ZbRutasService.prototype.setZbRuta = function (zbRuta) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetZbRuta'], zbRuta);
        }
    };
    ZbRutasService.prototype.getAllZbRutas = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllZbRutas'], null);
        }
    };
    ZbRutasService.ngInjectableDef = i0.defineInjectable({ factory: function ZbRutasService_Factory() { return new ZbRutasService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: ZbRutasService, providedIn: "root" });
    return ZbRutasService;
}());
export { ZbRutasService };
