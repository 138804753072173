import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { LegislacioService } from '../../services/legislacions-service.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { LegislacioDetail } from '../../models/legislacioDetail.model';

@Component({
  selector: 'app-legislacions-editor',
  templateUrl: './legislacions-editor.component.html',
  styleUrls: ['./legislacions-editor.component.scss']
})
export class LegislacionsEditorComponent implements OnInit {

  secure_id: string;
  legislacio: LegislacioDetail;
  dialogData: ComponentDialog;
  closeButtonText: string;

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private legislacioService: LegislacioService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<LegislacionsEditorComponent>,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.legislacio = Builder.newLegislacio();
      this.dialogData = data;
      this.secure_id = this.dialogData.id;
      if (this.dialogData.isReadOnly) {
        this.closeButtonText = this.i18n('Tancar');
      } else {
        this.closeButtonText = this.i18n('Llista');
      }
   }

  ngOnInit() {
    if (this.secure_id !== null) {
      this.legislacioService.getLegislacio(this.secure_id).subscribe(data => {
        this.legislacio = data;
      });
    }
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {

      this.legislacioService.setLegislacio(this.legislacio).subscribe(result => {
        this.toastr.info(this.i18n('Legislació guardada correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la legislació?'))
    .then((confirmed) => {
        if (confirmed) {
          this.legislacioService.removeLegislacio(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Legislació esborrada correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

}
