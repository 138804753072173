import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';

@Injectable()
export class NotificacionsService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  GetNotificacio(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetNotificacio'].replace('{secureId}', secure_id), null);
    }
  }

  GetImages(somId): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlImagesNotificacio'].replace('{somId}', somId), null);
    }
  }

  GetRespostes(notificacioId, element): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlRespostesNotificacio'].replace('{notificacioId}', notificacioId).replace('{element}', element), null);
    }
  }

}
