import { HttpParams } from '@angular/common/http';
import { TipoSenalVertical } from './../models/tipo-senal-vertical';
import { TipoSenalesVerticalesFilter } from './../models/tipo-senales-verticales.filter';
import { HttpSecureClient } from './../../../../shared/services/http/httpSecureClient';
import { Injectable } from "@angular/core";
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { TipoSenalesVerticalesFilterService } from './tipo-senales-verticales-filter-service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { Observable } from 'rxjs';


const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class TipoSenalesVerticalesService {
    constructor(
        private http: HttpSecureClient,
        private authGuard: AuthGuardService) { }

    getAll(): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllTipoSenalesVerticales'], null);
        }
    }

    generateExcel(filter: TipoSenalesVerticalesFilter, sortDir: string, sortField: string) {
        const start = 0;
        const pageSize = 1000;

        sortField = sortField === undefined ? defaultSortParam : sortField;
        sortDir = sortDir === '' ? defaultSortDirection : sortDir;
        const params = new TipoSenalesVerticalesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

        if (this.authGuard.canActivate()) {
            return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlTipoSenalVerticalExcel'], params);
        }
    }

    removeTipoSenalVertical(secureId: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteTipoSenalVertical'].replace('{secureId}', secureId), null);
        }
    }

    setTipoSenalVertical(senalVertical: TipoSenalVertical): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTipoSenalVertical'], senalVertical);
        }
    }

    getTipoSenalVertical(secureId: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTipoSenalVertical'].replace('{secureId}', secureId), null);
        }
    }

}
