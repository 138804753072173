import { DipositGruaFilter } from './../Models/diposit-filter';
import { DesperfectosDto } from './../Models/desperfectosDto';
import { VehicleDipositDto } from './../Models/diposit-vehicle.model';
import { PropostaGruaDto } from './../Models/proposta-grua.model';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { AliesGrua } from '../Models/alies-grua.model';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AliesGruaFilter } from '../Models/alies-grua-filter';
import { AliesGruaFilterService } from './alies-grua-filter-service';
import { HttpParams } from '@angular/common/http';
import { DipositGruaFilterService } from './diposit-grua-filter-service';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class DipositGruaService {


  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) { }

  getAll(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetPropostesPendents'], null);
    }
  }

  calculatePrice(dipositVehicle: any): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['calculatePriceDeposito'], dipositVehicle);
    }
  }

  getAllMotiusEntradaGrua(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllGruaMotiusEntrada'], null);
    }
  }

  setDipositVehicle(vehiculoDeposito: VehicleDipositDto) {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetVehiculoDepositoDto'], vehiculoDeposito);
    }
  }

  cobrarDepositoVehiculo(vehiculoDeposito: VehicleDipositDto): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlCobrarVehiculoDepositoDto'], vehiculoDeposito);
    }
  }

  getDipositsCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDiposits'], null);
    }
  }

  getAllTiposCobro(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTiposCobro'], null);
    }
  }

  getReports(page: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      do {
        page = page.replace('.', 'xXx');
      } while (page.includes('.'));

      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlReports'].replace('{page}', page), null);
    }
  }

  generateReport(secureId: string, reportId: string): Observable<any> {

    if (this.authGuard.canActivate()) {
      return this.http.postFileResponse(config.urls['apiUrl'] + api.endpoints['urlGenerateReportDeposito'].replace('{secureId}', secureId).replace('{reportId}', reportId), null, new HttpParams());
    }
  }

  getDesperfectos(secureId: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDesperfectos'].replace('{secureId}', secureId), null);
    }
  }

  getDesperfectosV2(codi: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDesperfectosV2'].replace('{codi}', codi), null);
    }
  }

  setDesperfectos(obj: DesperfectosDto): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlsetDesperfectos'], obj);
    }
  }

  deleteDesperfecto(obj: DesperfectosDto): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeletetDesperfectos'].replace('{secureId}', obj.secureId), null);
    }
  }
  getImages(contenedor: string, numDenuncia: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlGetImages'].replace('{somId}', numDenuncia).replace('{contenedor}', contenedor);

      return this.http.get(url, null);
    }
  }

  saveComentarioDesperfecto(codiSomPolGrua: string, categoria: string, comentario: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlGruaSaveComentarioDesperfecto'];
      let obj = {
        CodiSomPolGrua: codiSomPolGrua,
        Categoria: categoria,
        Comentario: comentario
      }
      return this.http.post(url, obj);
    }
  }

  generateExcel(filter: DipositGruaFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new DipositGruaFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGruaDipositExcel'], params);
    }
  }
}
