import { OnInit, EventEmitter } from '@angular/core';
import { ValidationService } from 'path-shared/services/validation/validation.service';
var FormCheckEditComponent = /** @class */ (function () {
    function FormCheckEditComponent(validation) {
        this.validation = validation;
        this.valueChange = new EventEmitter();
        this.clicked = new EventEmitter();
        this.required = false;
    }
    Object.defineProperty(FormCheckEditComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            this.val = val;
            this.valueChange.emit(this.val);
        },
        enumerable: true,
        configurable: true
    });
    FormCheckEditComponent.prototype.ngOnInit = function () {
    };
    FormCheckEditComponent.prototype.valid = function () {
        if (this.isRequired()) {
            return this.validation.isBoolean(this.value);
        }
        else {
            return true;
        }
    };
    FormCheckEditComponent.prototype.isRequired = function () {
        return this.required !== false;
    };
    FormCheckEditComponent.prototype.errorRequired = function () {
        return this.isRequired() && this.validation.isEmpty(this.value);
    };
    FormCheckEditComponent.prototype.onClick = function (event) {
        this.clicked.emit(this.value);
    };
    return FormCheckEditComponent;
}());
export { FormCheckEditComponent };
