import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { TipusVehiclesFilter } from '../models/tipus-vehicles-filter';
import { TipusVehicles } from '../models/tipus-vehicles.model';
import { TipusVehiclesFilterService } from './tipus-vehicles-filter.service';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class TipusVehiclesService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setTipusVehicle(tv: TipusVehicles): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTipusVehicle'], tv);
    }
  }

  removeTipusVehicle(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteTipusVehicle'].replace('{secure_id}', secure_id), null);
    }
  }

  getTipusVehicle(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTipusVehicle'].replace('{secure_id}', secure_id), null);
    }
  }

  generateExcel(filter: TipusVehiclesFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new TipusVehiclesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlTipusVehiclesExcel'],  params );
    }
  }
}
