import { saveAs } from 'file-saver';
import { PuntosDgtFilter } from '../../models/puntos-dt-filter';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { PuntosDgtSimple } from '../../models/puntos-dgt-simple.model';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EnvioPuntosDGTService } from '../../services/envio-puntos-dgt.service';
import { PuntosDgtDetail } from '../../models/puntos-dgt-detail.model';
import { GenericFile } from 'path-shared/components/generic-file-displayer/model/generic-file';
import { Router } from '@angular/router';
import { EnvioPuntsDGTFilterService } from '../../services/envio-punts-dgt-filter-service';

@Component({
  selector: 'app-envio-puntos-dgt-historico',
  templateUrl: './envio-puntos-dgt-historico.component.html',
  styleUrls: ['./envio-puntos-dgt-historico.component.scss']
})
export class EnvioPuntosDgtHistoricoComponent implements OnInit , AfterViewInit, OnChanges{

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: PuntosDgtSimple[];
  dataSource: CustomDataSource<PuntosDgtSimple, PuntosDgtFilter>;
  filter: PuntosDgtFilter = new PuntosDgtFilter('codi', 'asc');
  displayedItems = [];
  highlightedRows = [];

  @Input() selectButton: boolean;
  @Input() updatedFilterData: PuntosDgtFilter;

  detail: PuntosDgtDetail = undefined;
  displayedColumns = ['select', 'remesa', 'fecha'];
  displayedColumnsDetail = ['expediente','estado', 'incidencia'];
  constructor( 
    private dgtService: EnvioPuntosDGTService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private router: Router,
    private i18n: I18n) { }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<PuntosDgtSimple, PuntosDgtFilter>(this.paginator, new EnvioPuntsDGTFilterService(), 'urlGetEnviosPuntosDGT', 'urlGetEnviosPuntosDGTCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('envioPuntosDGT-filter', this.filter); }
      }
    });

    if (!this.selectButton) { this.filterService.loadFilter('envioPuntosDGT-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);
  }
  
  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }
  seleccionarFila(row){
    if(row.selected  === undefined || row.selected  === null){
        row.selected = true;
    }else{
      row.selected = !row.selected;
    }
    if(row.selected){
     this.loadDetail(row.secureId);
      this.dataSource.subject.subscribe((data) => {
        data.forEach(item => {
          if(item.remesa !== row.remesa && item.selected){
            item.selected = false;
          }
        });
      });
    }else{
      this.detail = undefined;
    }
  }
loadDetail(secureId: string){
  this.dgtService.getDetalleEnvio(secureId).subscribe(x =>{
    if(x !== undefined && x !== null){
      this.detail = x;
    }
  });
}
  uploadFile(file){
    this.getBase64(file).then((data: string) => {
      let fileToSend ={'fileString':data,'nomFitxer':file.name, 'cabecera':this.detail.cabecera};
      this.dgtService.sendResponseFileAndParse(fileToSend).subscribe(x =>{
        this.loadDetail(this.detail.cabecera.secureId);
      });
    });
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let formattedResult = reader.result.toString();
          const indexTo = formattedResult.indexOf('base64,');
          formattedResult = formattedResult.substr(indexTo + 'base64,'.length);
          resolve(formattedResult);
        };
        reader.onerror = error => reject(error);
    });
  }

  reenviarPuntos(){
    this.dgtService.reenviarPuntos(this.detail.cabecera).subscribe((file:GenericFile) => {
      this.downloadFile(file);
      this.load();

    });
  }

  downloadFile(file: GenericFile): void {

    const bytearray: Uint8Array = this.getDisplayable(file.fichero);
    const blob = new Blob([bytearray]);
    saveAs(blob, file.nombre);
  
  }
  getDisplayable(dataURI: string): Uint8Array {
    const raw = window.atob(dataURI);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    let i = 0;
    for (i; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  goLlista() {
    this.router.navigate(['tramitar/envioPuntosDGT']);
  }
}
