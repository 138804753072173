import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { EscalaOcupacionService } from './../../services/escala-ocupacion.service';
import { EscalaOcupacionFilter } from './../../model/escala-ocupacion.filter';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { EscalaOcupacionPageComponent } from '../../pages/escala-ocupacion-page/escala-ocupacion-page.component';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EscalaOcupacion } from '../../model/escala-ocupacion';
import { EscalaOcupacionFilterService } from '../../services/escala-ocupacion.filter.service';

@Component({
  selector: 'app-escala-ocupacion-table',
  templateUrl: './escala-ocupacion-table.component.html',
  styleUrls: ['./escala-ocupacion-table.component.scss']
})
export class EscalaOcupacionTableComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() updatedFilterData: EscalaOcupacionFilter;
  @Input() selectButton: boolean;
  @Output() filterUpdated = new EventEmitter<any>();
  @Output() itemSelected = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  currentSelect: string;

  dataSource: CustomDataSource<EscalaOcupacion, EscalaOcupacionFilter>;
  filter: EscalaOcupacionFilter = new EscalaOcupacionFilter('rangoSuperior', 'desc');

  displayedItems = [];
  displayedColumns = ['select', 'rangoInferior', 'rangoSuperior', 'color'];

  constructor(
    private escalaOcupacionService: EscalaOcupacionService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n
  ) { }


  /* #region  COMPONENT LIFECYCLE */

  ngOnInit(): void {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<EscalaOcupacion, EscalaOcupacionFilter>(
      this.paginator,
      new EscalaOcupacionFilterService(),
      'urlGetEscalaOcupacion',
      'urlGetEscalaOcupacionCount');

    this.dataSource.subject.subscribe(data => {
      this.displayedItems = this.escalaOcupacionService.displayedItems;
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('escala-ocupacion-filter', this.filter); }
      }
    });
    if (!this.selectButton) {
      this.filterService.loadFilter('escala-ocupacion-filter', this.filter);
    }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
      this.filterUpdated.emit(true);
    });
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
    this.load();
  }

  ngOnChanges(): void {
    if (this.updatedFilterData !== undefined) {
      this.update();
    }
  }

  /* #endregion */

  load(): void {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  update(): void {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  selectRow(row: any): void {
    if (row.secureId !== this.currentSelect) {
      // Deseleccionamos los otros elementos. Solo podemos tener uno activo.
      this.dataSource.getData().forEach(element => {
        if (element.secureId !== row.secureId) {
          element.selected = false;
        }
      });
      this.currentSelect = row.secureId;
      this.itemSelected.next(Object.assign({}, row));
    } else {
      this.currentSelect = undefined;
      this.itemSelected.next(undefined);
    }
  }

  resetSelection() {
    this.dataSource.getData().forEach(element => {
      element.selected = false;
    });
    this.currentSelect = undefined;
  }

}
