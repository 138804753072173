import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { SenalesVerticalesService } from './../../services/senales-verticales.service';
import { SenalesVerticalesFilter } from './../../models/senales-verticales.filter';
import { CustomDataSource } from './../../../../../shared/services/datasource/custom.datasource';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ExcelDownloadService } from 'path-shared/services/excel/excel-download.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs/internal/observable/merge';
import { tap } from 'rxjs/operators';
import { SenalVertical } from '../../models/senal-vertical';
import { SenalesVerticalesFilterService } from '../../services/senales-verticales-filter-service';
import { Builder } from 'path-shared/models/builder';

@Component({
  selector: 'app-senales-verticales-table',
  templateUrl: './senales-verticales-table.component.html',
  styleUrls: ['./senales-verticales-table.component.scss']
})
export class SenalesVerticalesTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: SenalVertical[];
  dataSource: CustomDataSource<SenalVertical, SenalesVerticalesFilter>;
  highlightedRows = [];
  filter: SenalesVerticalesFilter = new SenalesVerticalesFilter('id', 'asc');
  displayedItems = [];

  @Input() updatedFilterData: SenalesVerticalesFilter;
  @Input() selectButton: boolean;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['codi', 'nombre', 'tipus', 'observacions'];

  constructor(
    private senalesVerticalesService: SenalesVerticalesService,
    private filterService: FilterService,
    private excel: ExcelDownloadService,
    private dialog: MatDialog,
    private i18n: I18n
  ) {
    this.selectButton = false;
  }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<SenalVertical, SenalesVerticalesFilter>(
      this.paginator,
      new SenalesVerticalesFilterService(),
      'urlGetSenalesVerticales',
      'urlGetSenalesVerticalesCount');
    this.dataSource.subject.subscribe(data => {
      this.displayedItems = this.senalesVerticalesService.displayedItems;
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('senales-verticales-filter', this.filter); }
      }
    });
    if (!this.selectButton) {
      this.filterService.loadFilter('senales-verticales-filter', this.filter);
    }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
    this.load();
  }

  ngOnChanges() {
    if (this.updatedFilterData !== null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row.secureId);
  }

  selectCurrent(id, secureId) {
    this.itemSelected.next({id: id, secureId: secureId});
  }

  generateExcel() {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: Builder.newDownloadExcelDialogData(
        this.senalesVerticalesService,
        filter,
        this.i18n('Senyals Verticals'),
        this.sort.direction,
        this.sort.active)
    });
  }

}
