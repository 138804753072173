<!-- TERCERO -->
<div *ngIf="data.idTipoDenunciante === 2"> 
  <div class="row">
    <app-form-combo-edit [items]="tipusDenunciants" textField="descripcio" valueField="codi" class="col-xl-2" i18n-label label="Tipus de denunciant" [(value)]="data.idTipoDenunciante" [isReadOnly]="this.isReadOnly"></app-form-combo-edit>
    <!-- <app-form-text-edit id="denunciant-tipus" class="col-xl-2" i18n-label label="Tipus de denunciant" [(value)]="data.tipoDenunciante" [isReadOnly]="this.isReadOnly"></app-form-text-edit> -->
    <app-form-text-edit required id="denunciant-nomDenunciant" class="col-xl-6" i18n-label label="Nombre denunciante" [(value)]="data.nombreDenunciante" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
    <app-form-text-edit id="denunciant-dni" class="col-xl-4" i18n-label label="NIF" [(value)]="data.dni_denunciante" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
  </div>
  
  <div class="row">
    <app-form-text-edit id="denunciant-professio" class="col-xl-4" i18n-label label="Professió" [(value)]="data.profesion_denunciante" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
    <app-form-text-edit id="denunciant-carrer" class="col-xl-4" i18n-label label="Carrer" [(value)]="data.calle_denunciante" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
    <app-form-text-edit id="denunciant-poblacio" class="col-xl-4" i18n-label label="Població" [(value)]="data.poblacion_denunciante" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
  </div>
  
  <div class="row">
    <app-form-text-edit id="denunciant-codPostal" class="col-xl-4" i18n-label label="Cod.postal" [(value)]="data.codigoPostal_denunciante" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
    <app-form-text-edit id="denunciant-provincia" class="col-xl-4" i18n-label label="Província" [(value)]="data.provincia_denunciante" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
    <app-form-text-edit id="denunciant-pais" class="col-xl-4" i18n-label label="País" [(value)]="data.pais_denunciante" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
  </div>
</div>

<!-- AGENTE|OTRO AGENTE -->
<div class="row" *ngIf="data.idTipoDenunciante === 0 || data.idTipoDenunciante === 1">
  <app-form-combo-edit [items]="tipusDenunciants" textField="descripcio" valueField="codi" class="col-xl-2" i18n-label label="Tipus de denunciant" [(value)]="data.idTipoDenunciante" [isReadOnly]="this.isReadOnly"></app-form-combo-edit>

  <!-- <app-form-text-edit id="denunciant-tipus" class="col-2" i18n-label label="Tipus de denunciant" [(value)]="data.tipoDenunciante" [isReadOnly]="this.isReadOnly"></app-form-text-edit> -->
  <app-form-text-edit *ngIf="data.idTipoDenunciante === 0" id="denunciant-altreAgent" class="col-3" i18n-label label="Agente" [(value)]="data.codiAgent" isReadOnly=true></app-form-text-edit>
  <app-form-text-edit required *ngIf="data.idTipoDenunciante === 1" id="denunciant-altreAgent" class="col-3" i18n-label label="Otro agente" [(value)]="data.otroAgente" [isReadOnly]="this.isReadOnly"></app-form-text-edit>
</div>

<!-- SIN DATOS -->
<div class="row" *ngIf="data.idTipoDenunciante === 99">
  <app-form-combo-edit [items]="tipusDenunciants" textField="descripcio" valueField="codi" class="col-xl-2" i18n-label label="Tipus de denunciant" [(value)]="data.idTipoDenunciante" [isReadOnly]="this.isReadOnly"></app-form-combo-edit>

  <!-- <app-form-text-edit id="denunciant-tipus" class="col-2" i18n-label label="Tipus de denunciant" [(value)]="data.tipoDenunciante" [isReadOnly]="this.isReadOnly"></app-form-text-edit> -->
</div>

