import { DashboardFileDuplicate } from './../models/dashboard-fileDuplicate.model';
import { Observable } from 'rxjs/Observable';
import { HttpSecureClient } from './../../../shared/services/http/httpSecureClient';
import { Injectable } from '@angular/core';
import { Dashboard } from '../models/dashboard.model';
import { AuthGuardService } from '../../../shared/services/authentication/auth-guard.service';
import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  firstTime = true;
  constructor(
    private http: HttpSecureClient,
    private authGuard: AuthGuardService
  ) { }

  getDashboard(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlDashboard'];
      return this.http.get(url, null);
    }
  }
  getDashboardTramitacion(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlDashboardTramitacion'];
      return this.http.get(url, null);
    }
  }
  getDashboardTramitacionResumen(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlDashboardResumenTramitacion'];
      return this.http.get(url, null);
    }
  }
  recalcularDashTramitacion(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlRecalcularDashboardTramitacion'];
      return this.http.get(url, null);
    }
  }
  getFilesContadoresDup(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlDashBoardContadoresDup'];
      return this.http.get(url, null);
    }
  }
  getErrorFiles(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlDashBoardErrorFiles'];
      return this.http.get(url, null);
    }
  }
  reloadFilesContadoresDup(files: string[]): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlDashBoardMoveContadoresDup'];
      return this.http.post(url, files);
    }
  }

  getUnreadReleasedNotes(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlUnreadReleasedNotes'];
      return this.http.get(url, null);
    }
  }

  updateLastReleasedNoteRead(SomId): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlUpdateLastReleasedNote'].replace('{SomId}', SomId);
      return this.http.post(url, null);
    }
  }

  getFicherosPendienteImagen(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlDashBoardFicherosPendienteImagen'];
      return this.http.get(url, null);
    }
  }

  reloadFicherosPendienteImagen(files: string[]): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlDashBoardMoveFicherosPendienteImagen'];
      return this.http.post(url, files);
    }
  }
}
