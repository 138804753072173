<div class="top"></div>
<div class="row justify-content-between">
    <div class="col">
        <app-filter-selector [filter]="this.filter"></app-filter-selector>
    </div>
    <div class="col">
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
        </mat-paginator>
    </div>
</div>



<div class="table-sdw is-fixed">
    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

        <ng-container matColumnDef="idZona">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Zona ID</mat-header-cell>
            <mat-cell *matCellDef="let row"><a class="is-link cursor detalle" (click)="onRowClicked(row)"> {{row.idZona}} </a></mat-cell>
        </ng-container>

        <ng-container matColumnDef="descripcio">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripció</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.descripcio}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="fechaInici">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data inici</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.fechaInici | formatDate : 'yyyy/MM/dd' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="fechaFin">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Data fi</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.fechaFin | formatDate : 'yyyy/MM/dd' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="color">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Color</mat-header-cell>
              <mat-cell *matCellDef="let row" [style.background-color]="row.color" ></mat-cell>
          </ng-container>
        <ng-container matColumnDef="temporada">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Temporada</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.temporada ? row.temporada.descripcion: ''}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
</div>