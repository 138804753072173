import { OnInit } from '@angular/core';
import { Builder } from 'path-shared/models/builder';
var TextoInformativoEditorComponent = /** @class */ (function () {
    function TextoInformativoEditorComponent() {
        this.currentTexto = Builder.newTextoInformativo();
        this.currentEditing = false;
        this.idiomaDictionary = new Array();
    }
    Object.defineProperty(TextoInformativoEditorComponent.prototype, "currentEditing", {
        get: function () {
            return this._currentEditing;
        },
        set: function (val) {
            this._currentEditing = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextoInformativoEditorComponent.prototype, "currentTexto", {
        get: function () {
            return this._currentTexto;
        },
        set: function (val) {
            if (val !== undefined && val !== null) {
                this._currentTexto = val;
            }
            else {
                this.currentTexto = Builder.newTextoInformativo();
            }
        },
        enumerable: true,
        configurable: true
    });
    TextoInformativoEditorComponent.prototype.ngOnInit = function () { };
    TextoInformativoEditorComponent.prototype.textoFormatadoChanged = function (newText) {
        this.currentTexto.htmlValue = newText;
    };
    TextoInformativoEditorComponent.prototype.siglaSeleccionada = function (sigla) {
        this.currentTexto.idioma = sigla;
    };
    return TextoInformativoEditorComponent;
}());
export { TextoInformativoEditorComponent };
