/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reserves-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/dialog/filter-header/filter-header.component.ngfactory";
import * as i3 from "../../../../../shared/components/dialog/filter-header/filter-header.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i6 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i7 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i8 from "../../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i9 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i10 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i11 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i12 from "../../../../../shared/services/validation/validation.service";
import * as i13 from "./reserves-filter.component";
var styles_ReservesFilterComponent = [i0.styles];
var RenderType_ReservesFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReservesFilterComponent, data: {} });
export { RenderType_ReservesFilterComponent as RenderType_ReservesFilterComponent };
export function View_ReservesFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-header", [["title", "Filtrar Reserves"]], null, [[null, "apply"], [null, "reset"], [null, "dismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("apply" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (_co.reset() !== false);
        ad = (pd_1 && ad);
    } if (("dismiss" === en)) {
        var pd_2 = (_co.dismiss() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_FilterHeaderComponent_0, i2.RenderType_FilterHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.FilterHeaderComponent, [i4.MatDialogRef], { title: [0, "title"] }, { apply: "apply", reset: "reset", dismiss: "dismiss" }), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "col-7"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 3, "app-form-date-edit", [["class", "col-sm-4"], ["id", "fechaTicketInicio"], ["label", "Data tiquet"], ["type", "calendar"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.fechaInicio = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FormDateEditComponent_0, i5.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i6.DateTimeAdapter, i7.MomentDateTimeAdapter, [i6.OWL_DATE_TIME_LOCALE]), i1.ɵdid(8, 114688, null, 0, i8.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"], type: [3, "type"], id: [4, "id"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i9.OWL_DATE_TIME_FORMATS, i8.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-form-text-edit", [["class", "col-6"], ["id", "reserves-filter-matricula"], ["label", "Matr\u00EDcula"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.matricula = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_FormTextEditComponent_0, i10.RenderType_FormTextEditComponent)), i1.ɵdid(12, 4308992, null, 0, i11.FormTextEditComponent, [i12.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], id: [3, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-form-text-edit", [["class", "col-6"], ["id", "reserves-filter-email"], ["label", "Email"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.email = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_FormTextEditComponent_0, i10.RenderType_FormTextEditComponent)), i1.ɵdid(14, 4308992, null, 0, i11.FormTextEditComponent, [i12.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], id: [3, "id"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Filtrar Reserves"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "Data tiquet"; var currVal_2 = _co.filter.fechaInicio; var currVal_3 = "col-sm-4"; var currVal_4 = "calendar"; var currVal_5 = "fechaTicketInicio"; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "Matr\u00EDcula"; var currVal_7 = _co.filter.matricula; var currVal_8 = "col-6"; var currVal_9 = "reserves-filter-matricula"; _ck(_v, 12, 0, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = "Email"; var currVal_11 = _co.filter.email; var currVal_12 = "col-6"; var currVal_13 = "reserves-filter-email"; _ck(_v, 14, 0, currVal_10, currVal_11, currVal_12, currVal_13); }, null); }
export function View_ReservesFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reserves-filter", [], null, null, null, View_ReservesFilterComponent_0, RenderType_ReservesFilterComponent)), i1.ɵdid(1, 245760, null, 0, i13.ReservesFilterComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReservesFilterComponentNgFactory = i1.ɵccf("app-reserves-filter", i13.ReservesFilterComponent, View_ReservesFilterComponent_Host_0, {}, { notify: "notify" }, []);
export { ReservesFilterComponentNgFactory as ReservesFilterComponentNgFactory };
