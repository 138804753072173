import { Builder } from 'path-shared/models/builder';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseDgtPersona } from '../models/personaDgt.model';
import { PermisosVigentesDgt } from '../models/permisosVigentesDgtmodel';

@Injectable()
export class PersonaParserService {
    persona: ResponseDgtPersona;
    constructor() {
        this.inicializeObject();
    }
    inicializeObject() {
        this.persona = Builder.newResponseDgtPersona();
    }


    public parsebjectPersona(data: any): ResponseDgtPersona {

        this.inicializeObject();
        const datosPersona = data.find(x => x.nomOriginal === 'datosPersona');
        if (datosPersona !== undefined && datosPersona !== null ) {
            //#region datos persona
          const personaFisica = datosPersona.fills.find(x => x.nomOriginal === 'personaFisica');
          const personaJuridica = datosPersona.fills.find(x => x.nomOriginal === 'personaJuridica');

          if (personaFisica !== undefined && personaFisica !== null ) {
            const apellido1 = personaFisica.fills.find(x => x.nomOriginal === 'apellido1');
            if ( apellido1 !== undefined  && apellido1 !== null ) {
                this.persona.apellido1 = apellido1.valor;
            }
            const apellido2 = personaFisica.fills.find(x => x.nomOriginal === 'apellido2');
            if ( apellido2 !== undefined  && apellido2 !== null ) {
                this.persona.apellido2 = apellido2.valor;
            }
            const fechaNacimiento = personaFisica.fills.find(x => x.nomOriginal === 'fechaNacimiento');
            if ( fechaNacimiento !== undefined  && fechaNacimiento !== null ) {
                this.persona.fechaNacimiento = fechaNacimiento.valor;
            }
            const idDocumento = personaFisica.fills.find(x => x.nomOriginal === 'idDocumento');
            if ( idDocumento !== undefined  && idDocumento !== null ) {
                this.persona.nif = idDocumento.valor;
            }

            let nombre = personaFisica.fills.find(x => x.nomOriginal === 'nombre');
            if (personaJuridica.fills.find(x => x.nomOriginal === 'tipoPersona').valor === 'PJ') { // Si la persona es jurídica
                this.persona.personaFisica = false;
                this.persona.nif = personaJuridica.fills.find(x => x.nomOriginal === 'cif').valor;
                nombre = personaJuridica.fills.find(x => x.nomOriginal === 'razonSocial');
            }
            if ( nombre !== undefined  && nombre !== null ) {
                this.persona.nombre = nombre.valor;
            }
            const sexo = personaFisica.fills.find(x => x.nomOriginal === 'sexo');
            if ( sexo !== undefined  && sexo !== null ) {
                this.persona.sexo = sexo.valor;
            }
          }
          //#endregion
        }

        // region Datos generales
        const datosGenerales = data.find(x => x.nomOriginal === 'datosGenerales');
        if (datosGenerales !== undefined && datosGenerales !== null ) {
            // region domicilio persona
          const domicilio = datosGenerales.fills.find(x => x.nomOriginal === 'domicilio');
          if (domicilio !== undefined && domicilio !== null ) {
            const calle = domicilio.fills.find(x => x.nomOriginal === 'calle');
            if ( calle !== undefined  && calle !== null ) {
                this.persona.calle = calle.valor;
            }
            const codPostal = domicilio.fills.find(x => x.nomOriginal === 'codPostal');
            if ( codPostal !== undefined  && codPostal !== null ) {
                this.persona.codPostal = codPostal.valor;
            }
            const municipio = domicilio.fills.find(x => x.nomOriginal === 'municipio');
            if ( municipio !== undefined  && municipio !== null ) {
                this.persona.municipio = municipio.valor;
            }

            const provincia = domicilio.fills.find(x => x.nomOriginal === 'provincia');
            if ( provincia !== undefined  && provincia !== null ) {
                const descripcion = provincia.fills.find(x => x.nomOriginal === 'descripcion');
                if ( descripcion !== undefined  && descripcion !== null ) {
                    this.persona.provincia = descripcion.valor;
                }
            }
          }


          // region permisoso
          const listaPermisosVigentes = datosGenerales.fills.find(x => x.nomOriginal === 'listaPermisosVigentes');
          if (listaPermisosVigentes !== undefined && listaPermisosVigentes !== null && listaPermisosVigentes.fills !== null) {
            listaPermisosVigentes.fills.forEach(element => {
                const permisoActual = new PermisosVigentesDgt();
                const fechaFin = element.fills.find(x => x.nomOriginal === 'fechaFin');
                if ( fechaFin !== undefined  && fechaFin !== null ) {
                    permisoActual.fechaFin = fechaFin.valor;
                }
                const fechaInicio = element.fills.find(x => x.nomOriginal === 'fechaInicio');
                if ( fechaInicio !== undefined  && fechaInicio !== null ) {
                    permisoActual.fechaInicio = fechaInicio.valor;
                }
                const tipoPermiso = element.fills.find(x => x.nomOriginal === 'tipoPermiso');
                if ( tipoPermiso !== undefined  && tipoPermiso !== null ) {
                    const descripcion = tipoPermiso .fills.find(x => x.nomOriginal === 'descripcion');
                    if ( descripcion !== undefined  && descripcion !== null ) {
                        permisoActual.tipoPermiso = descripcion.valor;
                        if (permisoActual.tipoPermiso.startsWith('A')) {
                            permisoActual.icono = 'motorcycle';
                        } else if (permisoActual.tipoPermiso.startsWith('B')) {
                            permisoActual.icono = 'car';
                        } else if (permisoActual.tipoPermiso.startsWith('C')) {
                            permisoActual.icono = 'bus';
                        } else if (permisoActual.tipoPermiso.startsWith('D')) {
                            permisoActual.icono = 'truck';
                        } else {
                            permisoActual.icono = 'car';
                        }
                    }
                }
                this.persona.listaPermisosVigentes.push(permisoActual);
            });
            }
        }


      return this.persona;
    }
}
