import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { SomPolGruaFilter } from '../Models/som-pol-grua-filter';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class SomPolGruaFilterService extends CustomFilterService {
  public createFilterParams(filter: SomPolGruaFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    if (filter.fechaAcepDenegFinal !== undefined && filter.fechaAcepDenegFinal !== null) {
      filter.fechaAcepDenegFinal.setHours(23, 59, 59);
    }
    if (filter.dataFinal !== undefined && filter.dataFinal !== null) {
      filter.dataFinal.setHours(23, 59, 59);
    }

    return new HttpParams()
    .append('codi', (filter.codi ? filter.codi.toString() : ''))
    .append('idVehiclesFK', (filter.idVehiclesFK ? filter.idVehiclesFK.toString() : ''))
    .append('nombreCalle', (filter.nombreCalle ? filter.nombreCalle.toString() : ''))
    .append('numCarrer', (filter.numCarrer ? filter.numCarrer.toString() : ''))
    .append('idAgentFk', (filter.idAgentFk ? filter.idAgentFk.toString() : ''))
    .append('idTecnicFK', (filter.idTecnicFK ? filter.idTecnicFK.toString() : ''))
    .append('idMotiuDesestimada', (filter.idMotiuDesestimada ? filter.idMotiuDesestimada.toString() : ''))
    .append('dataInici',  Reflection.empty(filter.dataInici) ? '' :  typeof(filter.dataInici) === 'string' ?
    filter.dataInici : filter.dataInici.toDateString())
    .append('dataFinal',  Reflection.empty(filter.dataFinal) ? '' :  typeof(filter.dataFinal) === 'string' ?
    filter.dataFinal : filter.dataFinal.toDateString())
    .append('fechaAcepDenegInici',  Reflection.empty(filter.fechaAcepDenegInici) ? '' :  typeof(filter.fechaAcepDenegInici) === 'string' ?
    filter.fechaAcepDenegInici : filter.fechaAcepDenegInici.toDateString())
    .append('fechaAcepDenegFinal',  Reflection.empty(filter.fechaAcepDenegFinal) ? '' :  typeof(filter.fechaAcepDenegFinal) === 'string' ?
    filter.fechaAcepDenegFinal : filter.fechaAcepDenegFinal.toDateString())
    .append('implicaDenuncia', this.getTristateValue(filter.implicaDenuncia))
    .append('sinCargo', this.getTristateValue(filter.sinCargo))
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
