import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class SeguretatGrupsFilter extends FilterData {
  codiGrup: number;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.codiGrup = null;

    // this.labels['codiUser'] = this.i18n('Codi usuari');
  }
}
