import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';

const defaultSortParam = 'id';
const defaultSortDirection = 'asc';

export class TextoInformativoFilterService extends CustomFilterService {
    public createFilterParams(filter: any, start: number, pageSize: number, sortField: string, sortDir: string): HttpParams {
        return new HttpParams()
        .append('descripcion', filter.descripcion ? filter.descripcion : '')
        .append('fechaInicio', filter.fechaInicio ? this.formatDateToLocal(filter.fechaInicio) : '')
        .append('fechaFin', filter.fechaFin ? this.formatDateToLocal(filter.fechaFin) : '')
        .append('idioma', filter.idioma ? filter.idioma : '')
        .append('start', start.toString())
        .append('size', pageSize.toString())
        .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
        .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    }

    formatDateToLocal(fecha: Date): string {
        fecha = new Date(fecha);
        const dateString =
            fecha.getFullYear() + '-' +
            ('0' + (fecha.getMonth() + 1)).slice(-2) + '-' +
            ('0' + fecha.getDate()).slice(-2);
        return dateString;
    }
}
