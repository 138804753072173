/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report-displayer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./report-displayer.component";
import * as i3 from "../shared/services/report/report.service";
import * as i4 from "@angular/platform-browser";
var styles_ReportDisplayerComponent = [i0.styles];
var RenderType_ReportDisplayerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportDisplayerComponent, data: {} });
export { RenderType_ReportDisplayerComponent as RenderType_ReportDisplayerComponent };
export function View_ReportDisplayerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["id", "outerContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "pdf-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "pdf-viewer", [["style", "width: 100%; height: 600px;"]], [[8, "src", 0], [8, "rotation", 0], [8, "original-size", 0], [8, "show-all", 0], [8, "fit-to-page", 0], [8, "zoom", 0], [8, "zoom-scale", 0], [8, "stick-to-page", 0], [8, "render-text", 0], [8, "external-link-target", 0], [8, "autoresize", 0], [8, "show-borders", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; var currVal_1 = 0; var currVal_2 = false; var currVal_3 = true; var currVal_4 = false; var currVal_5 = 1; var currVal_6 = "page-width"; var currVal_7 = false; var currVal_8 = true; var currVal_9 = "blank"; var currVal_10 = true; var currVal_11 = false; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }); }
export function View_ReportDisplayerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-report-displayer", [], null, null, null, View_ReportDisplayerComponent_0, RenderType_ReportDisplayerComponent)), i1.ɵdid(1, 114688, null, 0, i2.ReportDisplayerComponent, [i3.ReportService, i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportDisplayerComponentNgFactory = i1.ɵccf("app-report-displayer", i2.ReportDisplayerComponent, View_ReportDisplayerComponent_Host_0, {}, {}, []);
export { ReportDisplayerComponentNgFactory as ReportDisplayerComponentNgFactory };
