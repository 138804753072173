var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MatPaginatorIntl } from '@angular/material/paginator';
import { I18n } from '@ngx-translate/i18n-polyfill';
var CustomMatPaginatorIntl = /** @class */ (function (_super) {
    __extends(CustomMatPaginatorIntl, _super);
    function CustomMatPaginatorIntl(i18n) {
        var _this = _super.call(this) || this;
        _this.i18n = i18n;
        _this.escatRangeLabel = function (page, pageSize, length) {
            if (length === 0 || pageSize === 0) {
                return "0 de " + length;
            }
            length = Math.max(length, 0);
            var startIndex = page * pageSize;
            // If the start index exceeds the list length, do not try and fix the end index to the end.
            var endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
            return startIndex + 1 + " - " + endIndex + " de " + length;
        };
        _this.getAndInitTranslations();
        return _this;
    }
    CustomMatPaginatorIntl.prototype.getAndInitTranslations = function () {
        this.itemsPerPageLabel = this.i18n('Items per pàgina:');
        this.nextPageLabel = this.i18n('Pàgina següent');
        this.previousPageLabel = this.i18n('Pàgina anterior');
        this.changes.next();
    };
    return CustomMatPaginatorIntl;
}(MatPaginatorIntl));
export { CustomMatPaginatorIntl };
