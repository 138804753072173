var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { OperacionCambioMatriculaDto } from 'app/sections/denuncies/models/operacionCambioMatriculaDto.model';
import { FormService } from 'path-shared/services/form/form.service';
import { VehiclesService } from 'app/sections/ciutat/vehicles/services/vehicles.service';
import { VehicleFilter } from 'app/sections/ciutat/vehicles/models/vehicle-filter';
import { VehiclesTableComponent } from 'app/sections/ciutat/vehicles/components/vehicles-table/vehicles-table.component';
var CambioMatriculaComponent = /** @class */ (function () {
    function CambioMatriculaComponent(vehiclesService, dialog, confirmationDialogService, i18n, toastr, formService) {
        this.vehiclesService = vehiclesService;
        this.dialog = dialog;
        this.confirmationDialogService = confirmationDialogService;
        this.i18n = i18n;
        this.toastr = toastr;
        this.formService = formService;
        this.dto = new OperacionCambioMatriculaDto();
        this.reset = true;
        this.marcas = [];
        this.modelos = [];
        this.modelosFull = [];
        this.colores = [];
    }
    CambioMatriculaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.vehiclesService.getMarquesCombo().subscribe(function (res) {
            _this.marcas = res;
            _this.marcas.unshift({ id: 0, descripcio: '' });
        });
        this.vehiclesService.getModelosCombo().subscribe(function (res) {
            _this.modelosFull = res;
        });
        this.vehiclesService.getColoresCombo().subscribe(function (res) {
            _this.colores = res;
            _this.colores.unshift({ id: 0, descripcio: '' });
        });
    };
    CambioMatriculaComponent.prototype.consultarMatricula = function () {
        var _this = this;
        if (this.dto.matricula !== undefined
            && this.dto.matricula !== null
            && this.dto.matricula !== '') {
            this.vehiclesService.getVehiclesByMatricula(this.dto.matricula).subscribe(function (x) {
                if (x !== undefined && x !== null && x.length > 0) {
                    if (x.length === 1) {
                        _this.dto.marca = x[0].MARCA;
                        _this.dto.modelo = x[0].MODEL;
                        _this.dto.color = x[0].COLOR;
                    }
                    else if (x.length > 1) {
                        var filter = new VehicleFilter('MATRICULA', 'asc');
                        filter.matricula = _this.dto.matricula;
                        // this.filterService.saveFilter('persona-filter', filter);
                        // Carraguem la taula filtrada per dni
                        var data = Builder.newComponentDialog(true, false, '', filter);
                        var ref = _this.dialog.open(VehiclesTableComponent, {
                            width: '80%',
                            closeOnNavigation: false,
                            autoFocus: true,
                            data: data
                        });
                        ref.afterClosed().subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (res !== undefined && res !== null && res !== '') {
                                    this.dto.matricula = res.matricula;
                                    this.dto.marca = res.marca;
                                    this.dto.modelo = res.model;
                                    this.dto.color = res.color;
                                    this.reset = false;
                                }
                                return [2 /*return*/];
                            });
                        }); });
                    }
                }
            });
        }
        else {
            var filter = new VehicleFilter('MATRICULA', 'asc');
            filter.matricula = this.dto.matricula;
            // this.filterService.saveFilter('persona-filter', filter);
            // Carraguem la taula filtrada per dni
            var data = Builder.newComponentDialog(true, false, '', filter);
            var ref = this.dialog.open(VehiclesTableComponent, {
                width: '80%',
                closeOnNavigation: false,
                autoFocus: true,
                data: data
            });
            ref.afterClosed().subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (res !== undefined && res !== null && res !== '') {
                        this.dto.matricula = res.matricula;
                        this.dto.marca = res.marca;
                        this.dto.modelo = res.model;
                        this.dto.color = res.color;
                        this.reset = false;
                    }
                    return [2 /*return*/];
                });
            }); });
        }
        //   } else {
        //    // Obrim dialeg per crear persona
        //       this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
        //       .then((confirmed) => {
        //           if (confirmed) {
        //             const data = Builder.newComponentDialog(true, false, null);
        //             const ref = this.dialog.open(PersonesEditorComponent, {
        //               width: '80%',
        //               height: '90%',
        //               closeOnNavigation: false,
        //               autoFocus: true,
        //               data:  {  isDialog: true,
        //                 isReadOnly: getMatIconFailedToSanitizeUrlError,
        //                 id: null,
        //                 filter: null,
        //                 dni: this.dniInfractor}
        //             });
        //             ref.afterClosed().subscribe(async data => {
        //               if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
        //                 this.sidInfractor = data.secure_id;
        //                 this.dto.nom = data.nomComplet;
        //                 this.dto.idInfractor = data.codi;
        //                 this.dniInfractor = data.dni_pasp;
        //                 this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
        //                 this.sidDomiciliInfractor = data.secureIdDom;
        //                 this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];
        //               }
        //             });
        //           } else {
        //             this.dto.nom = '';
        //             this.domicilisInfractorDictionary =  new BehaviorSubject(new Array<Dictionary>());
        //           }
        //         });
        //   }
        // });
        // } else {
        //   const data = Builder.newComponentDialog(true, false, null);
        //   const ref = this.dialog.open(PersonesComponent, {
        //     width: '80%',
        //     height: '90%',
        //     closeOnNavigation: false,
        //     autoFocus: true,
        //     data: data,
        //     disableClose: true
        //   });
        //   ref.afterClosed().subscribe(async data => {
        //     if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
        //       this.sidInfractor = data.secure_id;
        //       this.dto.nom = data.nomComplet;
        //       this.dto.idInfractor = data.codi;
        //       this.dniInfractor = data.dni_pasp;
        //       this.sidDomiciliInfractor = data.secureIdDom;
        //       this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
        //       this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];
        //     }
        //   });
    };
    CambioMatriculaComponent.prototype.Reset = function () {
        if (this.reset) {
            this.dto.marca = '';
            this.dto.modelo = '';
            this.dto.color = '';
        }
        else {
            this.reset = !this.reset;
        }
    };
    //   seleccionarConductor(secureId = null) {
    //     if (secureId !== null) {
    //   } else {
    //     if (Reflection.empty(this.sidInfractor)) {
    //       const data = Builder.newComponentDialog(true, false, secureId);
    //       const ref = this.dialog.open(PersonesComponent, {
    //         width: '80%',
    //         height: '90%',
    //         closeOnNavigation: false,
    //         autoFocus: true,
    //         data: data,
    //         disableClose: true
    //       });
    //       ref.afterClosed().subscribe(async data => {
    //         if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
    //           this.sidInfractor = data.secure_id;
    //           this.dto.nom = data.nomComplet;
    //           this.dto.idInfractor = data.codi;
    //           this.dniInfractor = data.dni_pasp;
    //           this.sidDomiciliInfractor = data.secureIdDom;
    //           this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
    //           this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];
    //         }
    //       });
    //     } else {
    //       const data = Builder.newComponentDialog(true, false, secureId);
    //       const ref = this.dialog.open(PersonesComponent, {
    //         width: '80%',
    //         height: '90%',
    //         closeOnNavigation: false,
    //         autoFocus: true,
    //         data: data,
    //         disableClose: true
    //       });
    //       ref.afterClosed().subscribe(async data => {
    //         if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
    //           this.sidInfractor = data.secure_id;
    //           this.dto.nom = data.nomComplet;
    //           this.dto.idInfractor = data.codi;
    //           this.dniInfractor = data.dni_pasp;
    //           this.sidDomiciliInfractor = data.secureIdDom;
    //           this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
    //           this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];
    //         }
    //       });
    //     }
    //   }
    // }
    // getDomicilis(secureIdPersona, propietari, secureIdDom) {
    //   this.personesService.getDomicilis(secureIdPersona).subscribe(dom => {
    //     this.comboDomPropietari.resetShowingOptions();
    //     if (dom === undefined || dom === null || dom.length === 0) {
    //       this.domicilisInfractorDictionary =  new BehaviorSubject(new Array<Dictionary>());
    //     } else {
    //       // this.domicilisConductor = dom;
    //       // this.comboDomConductor.resetCombo();
    //       this.domicilisInfractorDictionary =  new BehaviorSubject(new Array<Dictionary>());
    //       const dict = new Array<Dictionary>();
    //           dom.forEach(element => {
    //             dict.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
    //           });
    //           this.domicilisInfractorDictionary.next(dict);
    //           if (secureIdDom !== undefined) {
    //             this.comboDomPropietari.markAsTouched();
    //             this.comboDomPropietari.setSelect(secureIdDom);
    //           }
    //         }
    //   });
    // }
    // domicilioConductorSeleccionado(secure_id: string) {
    //   this.sidDomiciliInfractor = secure_id;
    // }
    CambioMatriculaComponent.prototype.correcte = function () {
        if (!this.formService.allFieldsValid(this.mainForm)) {
            this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
            if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
                this.mainForm.nativeElement.classList.add('displayErrors');
            }
            return false;
        }
        else {
            return true;
        }
    };
    CambioMatriculaComponent.prototype.marcaSeleccionado = function (event) {
        var marca = this.marcas.find(function (x) { return x.descripcio === event; });
        var id = marca !== undefined ? marca.id : undefined;
        this.modelos = this.modelosFull.filter(function (x) { return x.codMarca === id.toString(); });
        this.modelos.unshift({ id: 0, descripcio: '' });
    };
    return CambioMatriculaComponent;
}());
export { CambioMatriculaComponent };
