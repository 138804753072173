import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../../shared/services/authentication/auth-guard.service";
var SincronitzacioService = /** @class */ (function () {
    function SincronitzacioService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
    }
    SincronitzacioService.prototype.actualitzar = function (completa) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSincronitzar'], completa ? 1 : 0);
        }
    };
    /**
     * Llamada al backend que obtiene las fechas en las que se sincronizó por última vez las PDAs y DIBA (si no es cliente tipo "DIBA WS" no devuelve esta última fecha).
     * Devuelve un array con las fechas con el orden [PDA, DIBA = null]
     */
    SincronitzacioService.prototype.getLastSyncDate = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlLastSyncDate'], null);
        }
    };
    /**Llamada al backend que sincroniza nuestra BBDD con la de DIBA */
    SincronitzacioService.prototype.syncDyba = function () {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSyncDiba'], null);
        }
    };
    /**Llamada al backend que sincroniza nuestra BBDD con la de GTWIN */
    SincronitzacioService.prototype.syncGtwin = function () {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSyncGtwin'], null);
        }
    };
    SincronitzacioService.ngInjectableDef = i0.defineInjectable({ factory: function SincronitzacioService_Factory() { return new SincronitzacioService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: SincronitzacioService, providedIn: "root" });
    return SincronitzacioService;
}());
export { SincronitzacioService };
