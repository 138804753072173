<app-toolbar>  
    <app-tool-button-permissions [permission]="this.auth.Pages.ComunicacioPda"></app-tool-button-permissions>
  </app-toolbar>

<div class="row glutter">
    <div class="table-sdw is-fixed col-2 fixed-height fixed-height-scroll">
        <mat-table #table [dataSource]="terminalsData" matSort aria-label="Elements" class="is-header-sticky">

            <!-- Check Column -->
            <ng-container matColumnDef="check">
                <mat-header-cell *matHeaderCellDef id="check-all" class="is-checkbox">
                    <mat-checkbox (change)="toggleAllCheckbox()"></mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-checkbox (change)="toggleItem(row.idTerminal, false)"
                        [checked]="displayedItems[row.idTerminal].checked"></mat-checkbox>
                </mat-cell>
            </ng-container>

            <!-- Codi PDA-->
            <ng-container matColumnDef="codiPda" class="col-sm-10">
                <mat-header-cell *matHeaderCellDef i18n class="col-sm-10">Codi PDA</mat-header-cell>
                <mat-cell *matCellDef="let row" class="col-sm-10 cursorPointer" (click)="obrirPestanya(row.idTerminal)">
                    {{row.nameTerminal}}
                    <span class="badge badge-danger" *ngIf="row.pendentsLlegir > 0">{{row.pendentsLlegir}}</span>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" class="fons-gris"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"
                [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
        </mat-table>
    </div>
    <div class="col-10 fixed-height">
        <div class="row no-gutters fons-gris">
            <label class="punts-suspensius" i18n>Missatges a: </label>
            <label class="punts-suspensius">
                <ng-container  *ngFor="let item of this.displayedItems['checkedItems']; let isLast=last">
                    {{ displayedItems[item].tabName }}{{isLast ? '' : ', '}}
                </ng-container>
            </label>
        </div>
        <div class="row h90">
            <app-form-text-area class="col cosMissatge" i18n-label label="Cos del missatge" [type]="'text'" [(value)]="this.cosMissatge" maxLength="5000"></app-form-text-area>
        </div>
    </div>
</div>

<div class="row justify-content-end">

    <div class="col-10">
        <div class="row">
            <div class="col-1"><button class="btn btn-primary" i18n (click)="enviar($event)">Enviar</button></div>
            <div class="col-9">
                <app-form-file-multiple-upload #fitxer label="Fitxer" i18n-label labelHidden="true"
                    (fileReady)="fileReady($event)"></app-form-file-multiple-upload>
                <div i18n>Selecciona un o varis fitxers per pujar</div>
            </div>
        </div>
    </div>
</div>

<hr class="hr">

<div class="row col-12">
    <app-form-tab-chat-dynamic [tabLabel]="label" [tabs]="tabsList" (recuperaMissatgesNous)="recuperaMissatgesNous($event)"
        (recuperaMissatgesAntics)="recuperaMissatgesAntics($event)" (downloadFitxer)="getFitxer($event)"></app-form-tab-chat-dynamic>
</div>