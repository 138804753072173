/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../dialog/dialog-header/dialog-header.component.ngfactory";
import * as i2 from "../dialog/dialog-header/dialog-header.component";
import * as i3 from "../dialog/dialog-body/dialog-body.component.ngfactory";
import * as i4 from "../dialog/dialog-body/dialog-body.component";
import * as i5 from "@angular/common";
import * as i6 from "../dialog/dialog-footer/dialog-footer.component.ngfactory";
import * as i7 from "../dialog/dialog-footer/dialog-footer.component";
import * as i8 from "./confirmation-dialog.component";
import * as i9 from "@angular/material/dialog";
var styles_ConfirmationDialogComponent = [];
var RenderType_ConfirmationDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmationDialogComponent, data: {} });
export { RenderType_ConfirmationDialogComponent as RenderType_ConfirmationDialogComponent };
function View_ConfirmationDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 2, 0, currVal_0); }); }
function View_ConfirmationDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "col"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
export function View_ConfirmationDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["icon", "fa fa-question"], ["iconClass", "has-color-blue"]], null, null, null, i1.View_DialogHeaderComponent_0, i1.RenderType_DialogHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.DialogHeaderComponent, [], { label: [0, "label"], icon: [1, "icon"], iconClass: [2, "iconClass"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 5, "app-dialog-body", [], null, null, null, i3.View_DialogBodyComponent_0, i3.RenderType_DialogBodyComponent)), i0.ɵdid(3, 114688, null, 0, i4.DialogBodyComponent, [], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ConfirmationDialogComponent_1)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ConfirmationDialogComponent_2)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 5, "app-dialog-footer", [], null, null, null, i6.View_DialogFooterComponent_0, i6.RenderType_DialogFooterComponent)), i0.ɵdid(9, 114688, null, 0, i7.DialogFooterComponent, [], null, null), (_l()(), i0.ɵeld(10, 0, null, 0, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.accept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), (_l()(), i0.ɵeld(12, 0, null, 0, 1, "button", [["class", "btn btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(13, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = "fa fa-question"; var currVal_2 = "has-color-blue"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 3, 0); var currVal_3 = !_co.html; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.html; _ck(_v, 7, 0, currVal_4); _ck(_v, 9, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.btnOkText; _ck(_v, 11, 0, currVal_5); var currVal_6 = _co.btnCancelText; _ck(_v, 13, 0, currVal_6); }); }
export function View_ConfirmationDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirmation-dialog", [], null, null, null, View_ConfirmationDialogComponent_0, RenderType_ConfirmationDialogComponent)), i0.ɵdid(1, 114688, null, 0, i8.ConfirmationDialogComponent, [i9.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationDialogComponentNgFactory = i0.ɵccf("app-confirmation-dialog", i8.ConfirmationDialogComponent, View_ConfirmationDialogComponent_Host_0, { title: "title", message: "message", btnOkText: "btnOkText", btnCancelText: "btnCancelText" }, {}, []);
export { ConfirmationDialogComponentNgFactory as ConfirmationDialogComponentNgFactory };
