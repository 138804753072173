var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from './../../../../shared/models/filter-data';
var TipoSenalesVerticalesFilter = /** @class */ (function (_super) {
    __extends(TipoSenalesVerticalesFilter, _super);
    function TipoSenalesVerticalesFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.id = '';
        _this.descripcion = '';
        _this.labels['id'] = _this.i18n('Id');
        _this.labels['descripcion'] = _this.i18n('Descripció');
        return _this;
    }
    return TipoSenalesVerticalesFilter;
}(FilterData));
export { TipoSenalesVerticalesFilter };
