import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { OnInit, AfterViewInit, EventEmitter, ElementRef } from '@angular/core';
import { ValidationService } from 'path-shared/services/validation/validation.service';
import { timer } from 'rxjs';
var FormTextAreaComponent = /** @class */ (function () {
    function FormTextAreaComponent(validator) {
        this.validator = validator;
        this.valueChange = new EventEmitter();
        this.resize = false;
        this.val = null;
        this.disabled = false;
        this.required = false;
        this.number = false;
        this.matricula = false;
    }
    Object.defineProperty(FormTextAreaComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            var _this = this;
            if (this.val !== val) {
                this.val = val;
                setTimeout(function () {
                    _this.valueChange.emit(_this.val);
                }, 10);
            }
        },
        enumerable: true,
        configurable: true
    });
    FormTextAreaComponent.prototype.ngOnInit = function () {
    };
    FormTextAreaComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.autosize.enabled = this.resize;
        if (this.autofocus !== undefined) {
            timer(500).subscribe(function () {
                _this.elementRef.nativeElement.focus();
            });
        }
    };
    FormTextAreaComponent.prototype.valid = function () {
        return !this.errorRequired() && !this.errorNumber() && !this.errorLength() && !this.errorMatricula();
    };
    FormTextAreaComponent.prototype.isRequired = function () {
        return this.required !== false;
    };
    FormTextAreaComponent.prototype.isRequiredNumber = function () {
        return this.number !== false;
    };
    FormTextAreaComponent.prototype.isRequiredMatricula = function () {
        return this.matricula !== false;
    };
    FormTextAreaComponent.prototype.hasMaxLength = function () {
        return this.validator.isNatural(this.maxLength.toString());
    };
    FormTextAreaComponent.prototype.errorRequired = function () {
        return this.isRequired() && (this.value === null || this.value.length === 0);
    };
    FormTextAreaComponent.prototype.errorNumber = function () {
        return this.isRequiredNumber() && !this.validator.isNumeric(this.value);
    };
    FormTextAreaComponent.prototype.errorMatricula = function () {
        return this.isRequiredMatricula() && !this.validator.isMatricula(this.value);
    };
    FormTextAreaComponent.prototype.errorLength = function () {
        if (this.maxLength === undefined) {
            return false;
        }
        else {
            return !this.validator.checkLength(this.value, this.maxLength);
        }
    };
    return FormTextAreaComponent;
}());
export { FormTextAreaComponent };
