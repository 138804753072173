var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MessagingService } from '../messaging/messaging.service';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, of } from 'rxjs';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { config } from 'path-shared/configuration/config';
import { catchError, finalize } from 'rxjs/operators';
import { AppInjectorModule } from 'app/app-injector/app-injector.module';
import { api } from 'path-shared/configuration/urls';
/// T -> tipus de dades , F -> tipus del filtre
var CustomDataSource = /** @class */ (function (_super) {
    __extends(CustomDataSource, _super);
    function CustomDataSource(paginator, filterService, urlData, urlCount) {
        var _this = _super.call(this) || this;
        _this.paginator = paginator;
        _this.filterService = filterService;
        _this.urlData = urlData;
        _this.urlCount = urlCount;
        _this.subject = new BehaviorSubject([]);
        _this.countSubject = new BehaviorSubject(0);
        _this.loadingSubject = new BehaviorSubject(false);
        _this.loading$ = _this.loadingSubject.asObservable();
        _this.loading = false;
        _this.http = AppInjectorModule.injector.get(HttpSecureClient);
        _this.msg = AppInjectorModule.injector.get(MessagingService);
        return _this;
    }
    CustomDataSource.create = function (paginator, filterService, endPointData, endPointCount) {
        console.log(paginator);
        console.log(filterService);
        console.log(config.urls['apiUrl'] + api.endpoints[endPointData]);
        console.log(config.urls['apiUrl'] + api.endpoints[endPointCount]);
        return new CustomDataSource(paginator, filterService, config.urls['apiUrl'] + api.endpoints[endPointData], config.urls['apiUrl'] + api.endpoints[endPointCount]);
    };
    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    CustomDataSource.prototype.connect = function () {
        return this.subject.asObservable();
        // return this.getPagedData(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
    };
    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    CustomDataSource.prototype.disconnect = function () {
        this.subject.complete();
        this.loadingSubject.complete();
    };
    /**
     * Paginate the data (client-side). If you're using server-side pagination,
     * this would be replaced by requesting the appropriate data from the server.
     */
    CustomDataSource.prototype.load = function (filter, pageIndex, pageSize, sortField, sortDir, noRecordsFoundText) {
        var _this = this;
        if (!this.loading) {
            this.loading = true;
            this.loadingSubject.next(true);
            var start = pageIndex * pageSize;
            var params = this.filterService.createFilterParams(filter, start, pageSize, sortField, sortDir);
            this.http.get(this.urlCount, params)
                .subscribe(function (x) {
                (_this.paginator.length = x);
                _this.countSubject.next(_this.paginator.length);
            });
            this.http.get(this.urlData, params)
                .pipe(catchError(function () {
                _this.loading = false;
                return of([]);
            }), finalize(function () {
                _this.loadingSubject.next(false);
            }))
                .subscribe(function (items) {
                if (items === null || (items.length === 0)) {
                    items = [];
                    _this.msg.noRecordsFound(noRecordsFoundText);
                }
                _this.subject.next(items);
                _this.loading = false;
            });
        }
    };
    CustomDataSource.prototype.loadFilter = function (filter, noRecordsFoundText) {
        var _this = this;
        if (!this.loading) {
            this.loading = true;
            this.loadingSubject.next(true);
            var params = this.filterService.createParamsFromFilter(filter);
            this.http.get(this.urlData, params)
                .pipe(catchError(function () {
                _this.loading = false;
                return of([]);
            }), finalize(function () {
                _this.loadingSubject.next(false);
                _this.loading = false;
            }))
                .subscribe(function (items) {
                if (items === null || (items.length === 0)) {
                    items = [];
                    _this.msg.noRecordsFound(noRecordsFoundText);
                }
                _this.subject.next(items);
                _this.loading = false;
            });
            this.http.get(this.urlCount, params)
                .subscribe(function (x) {
                _this.paginator.length = x;
                // this.loading = false;
                _this.countSubject.next(_this.paginator.length);
            });
        }
    };
    CustomDataSource.prototype.getData = function () {
        return this.subject.value;
    };
    CustomDataSource.prototype.updateData = function (items) {
        this.subject.next(items);
    };
    CustomDataSource.prototype.sort = function (field, direction) {
        var _this = this;
        var data = this.subject.value.sort(function (a, b) {
            if (a[field] === b[field]) {
                return 0;
            }
            else {
                return _this.compare(a[field], b[field], direction);
            }
        });
        this.subject.next(data);
    };
    CustomDataSource.prototype.set = function (findField, findValue, Field, Value) {
        var item = this.subject.value.find(function (x) { return x[findField] === findValue; });
        if (item != null) {
            item[Field] = Value;
        }
    };
    CustomDataSource.prototype.get = function (findField, findValue) {
        return this.subject.value.find(function (x) { return x[findField] === findValue; });
    };
    CustomDataSource.prototype.setUrlData = function (urlData) {
        this.urlData = urlData;
    };
    CustomDataSource.prototype.setUrlCount = function (urlCount) {
        this.urlCount = urlCount;
    };
    CustomDataSource.prototype.compare = function (a, b, direction) {
        if (direction === 'desc') {
            if (b === undefined) {
                return -1;
            }
            else {
                return a > b ? -1 : 1;
            }
        }
        else {
            if (a === undefined) {
                return -1;
            }
            else {
                return a < b ? -1 : 1;
            }
        }
    };
    return CustomDataSource;
}(DataSource));
export { CustomDataSource };
