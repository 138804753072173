import { OnInit, ChangeDetectorRef } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll/lib';
import { MatDialog, MatDialogRef, MatTableDataSource } from '@angular/material';
import { FileBoxService } from './file-box-service';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { SelectionModel } from '@angular/cdk/collections';
import { saveAs } from 'file-saver';
var FileBoxComponent = /** @class */ (function () {
    function FileBoxComponent(fileService, domSanitizer, changeDetectorRefs, confirmationDialogService, i18n, dialog, data) {
        this.fileService = fileService;
        this.domSanitizer = domSanitizer;
        this.changeDetectorRefs = changeDetectorRefs;
        this.confirmationDialogService = confirmationDialogService;
        this.i18n = i18n;
        this.dialog = dialog;
        this.data = data;
        this.imageNames = [];
        this.dict = {};
        this.newDict = {};
        this.selection = new SelectionModel(true, []);
        this.leftNavDisabled = false;
        this.rightNavDisabled = false;
        this.displayedColumns = ['CheckBox', 'NOMBRE_FICHERO'];
        this.dialogData = data;
        this.llistaFitxers = this.data.llistaFitxers;
        this.dataSource = new MatTableDataSource(this.llistaFitxers);
        this.contenedor = this.data.contenedor;
    }
    FileBoxComponent.prototype.ngOnInit = function () {
        this.getImages();
        this.dataSource.data = this.llistaFitxers;
    };
    FileBoxComponent.prototype.getImages = function () {
        var _this = this;
        if (this.llistaFitxers != null && this.llistaFitxers.length > 0) {
            var _loop_1 = function (i) {
                var exts = this_1.getExtension(i); // this.llistaFitxers[i].NOMBRE_FICHERO.substr(this.llistaFitxers[i].NOMBRE_FICHERO.lastIndexOf('.') + 1).toLowerCase();
                if (exts === 'jpg' || exts === 'jpeg' || exts === 'png') {
                    var image = this_1.fileService.getImage(this_1.llistaFitxers[i].SOM_ID, 'L').subscribe(function (detailsdata) {
                        _this.dict[_this.llistaFitxers[i].SOM_ID] = _this.buildImage(detailsdata);
                        _this.newDict[_this.llistaFitxers[i].SOM_ID] = detailsdata;
                        if (_this.imatgeActual === undefined && _this.llistaFitxers != null && _this.llistaFitxers[0] != null && i === 0) {
                            _this.imatgeActual = _this.dict[_this.llistaFitxers[0].SOM_ID];
                            _this.somid = _this.llistaFitxers[0].SOM_ID;
                        }
                    });
                }
                else {
                    var image = this_1.fileService.getPdf(this_1.llistaFitxers[i].SOM_ID).subscribe(function (detailsdata) {
                        _this.dict[_this.llistaFitxers[i].SOM_ID] = _this.buildPdf(detailsdata);
                        _this.newDict[_this.llistaFitxers[i].SOM_ID] = detailsdata;
                    });
                }
            };
            var this_1 = this;
            for (var i = 0; i < this.llistaFitxers.length; i++) {
                _loop_1(i);
            }
        }
    };
    FileBoxComponent.prototype.getPdfUrl = function () {
        return this.pdfActual;
    };
    FileBoxComponent.prototype.onRowClicked = function (row) {
        var idAct = this.llistaFitxers.findIndex(function (g) { return g.SOM_ID === row.SOM_ID; });
        var exts = this.getExtension(idAct); // this.llistaFitxers[idAct].NOMBRE_FICHERO.substr(this.llistaFitxers[idAct].NOMBRE_FICHERO.lastIndexOf('.') + 1).toLowerCase();
        if (exts === 'jpg' || exts === 'jpeg' || exts === 'png') {
            this.mostrarImatgeNew(idAct);
        }
        else {
            this.mostrarPdfNew(idAct);
        }
    };
    FileBoxComponent.prototype.moveLeft = function () {
        var _this = this;
        var idAct = this.llistaFitxers.findIndex(function (g) { return g.SOM_ID === _this.somid; });
        var exts = this.getExtension(idAct - 1); // this.llistaFitxers[idAct - 1].NOMBRE_FICHERO.substr(this.llistaFitxers[idAct - 1].NOMBRE_FICHERO.lastIndexOf('.') + 1).toLowerCase();
        if (exts === 'jpg' || exts === 'jpeg' || exts === 'png') {
            this.mostrarImatgeNew(idAct - 1);
        }
        else {
            this.mostrarPdfNew(idAct - 1);
        }
    };
    FileBoxComponent.prototype.moveRight = function () {
        var _this = this;
        var idAct = this.llistaFitxers.findIndex(function (g) { return g.SOM_ID === _this.somid; });
        var exts = this.getExtension(idAct + 1); // this.llistaFitxers[idAct + 1].NOMBRE_FICHERO.substr(this.llistaFitxers[idAct + 1].NOMBRE_FICHERO.lastIndexOf('.') + 1).toLowerCase();
        if (exts === 'jpg' || exts === 'jpeg' || exts === 'png') {
            this.mostrarImatgeNew(idAct + 1);
        }
        else {
            this.mostrarPdfNew(idAct + 1);
        }
    };
    FileBoxComponent.prototype.leftBoundStat = function (reachesLeftBound) {
        this.leftNavDisabled = reachesLeftBound;
    };
    FileBoxComponent.prototype.rightBoundStat = function (reachesRightBound) {
        this.rightNavDisabled = reachesRightBound;
    };
    FileBoxComponent.prototype.getScrollStyle = function () {
        var styles = {
            'min-height': this.height ? (this.height + 'px') : '300px'
        };
        return styles;
    };
    FileBoxComponent.prototype.uploadFile = function (file) {
        var _this = this;
        var reader = new FileReader();
        var fitxer = file;
        return this.fileService.setFile(config.urls['apiUrl'] + api.endpoints['urlUpladFileAgent'], this.contenedor, file, fitxer.name).subscribe(function (data) {
            var nouFitxer = data;
            if (_this.llistaFitxers === null) {
                _this.llistaFitxers = new Array();
            }
            _this.llistaFitxers.push(nouFitxer);
            _this.dataSource.data = _this.llistaFitxers;
            var exts = nouFitxer.NOMBRE_FICHERO.substr(nouFitxer.NOMBRE_FICHERO.lastIndexOf('.') + 1).toLowerCase();
            if (exts === 'jpg' || exts === 'jpeg' || exts === 'png') {
                var image = _this.fileService.getImage(nouFitxer.SOM_ID, 'L').subscribe(function (detailsdata) {
                    _this.dict[nouFitxer.SOM_ID] = _this.buildImage(detailsdata);
                });
            }
            else {
                var image = _this.fileService.getPdf(nouFitxer.SOM_ID).subscribe(function (detailsdata) {
                    _this.dict[nouFitxer.SOM_ID] = _this.buildPdf(detailsdata);
                });
            }
        });
    };
    FileBoxComponent.prototype.fileReady = function (file) {
        var files = file;
        var i = 0;
        i++;
    };
    FileBoxComponent.prototype.eliminarFitxers = function () {
        var _this = this;
        if (this.selection.selected.length !== null && this.selection.selected.length !== undefined && this.selection.selected.length > 0) {
            this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar el fitxers seleccionats ?'))
                .then(function (confirmed) {
                if (confirmed) {
                    _this.selection.selected.forEach(function (element) {
                        _this.remove(element.SOM_ID);
                    });
                    _this.selection.clear();
                }
            });
        }
    };
    FileBoxComponent.prototype.remove = function (id) {
        var _this = this;
        var idAct = this.llistaFitxers.findIndex(function (g) { return g.SOM_ID === id; });
        var fitxer = this.llistaFitxers[idAct];
        this.fileService.removeFile(fitxer.SECURE_ID).subscribe(function (data) {
            _this.llistaFitxers.splice(idAct, 1);
            _this.dataSource.data = _this.llistaFitxers;
            if (_this.llistaFitxers.length > 0) {
                var exts = _this.getExtension(idAct - 1); // this.llistaFitxers[idAct - 1].NOMBRE_FICHERO.substr(this.llistaFitxers[idAct - 1].NOMBRE_FICHERO.lastIndexOf('.') + 1);
                if (exts === 'jpg' || exts === 'jpeg' || exts === 'png') {
                    _this.mostrarImatgeNew(idAct - 1);
                }
                else {
                    _this.mostrarPdfNew(idAct - 1);
                }
            }
            else {
                _this.pdfActual = null;
                _this.somid = null;
            }
        });
    };
    FileBoxComponent.prototype.buildImage = function (detailsdata) {
        return 'data:image/png;base64,' + btoa(new Uint8Array(detailsdata).reduce(function (datas, byte) {
            return datas + String.fromCharCode(byte);
        }, ''));
    };
    FileBoxComponent.prototype.buildPdf = function (detailsdata) {
        return 'data:image/pdf;base64,' + btoa(new Uint8Array(detailsdata).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
        }, ''));
    };
    FileBoxComponent.prototype.getExtension = function (n) {
        return this.llistaFitxers[n].NOMBRE_FICHERO.substr(this.llistaFitxers[n].NOMBRE_FICHERO.lastIndexOf('.') + 1).toLowerCase();
    };
    FileBoxComponent.prototype.mostrarImatgeNew = function (n) {
        this.imatgeActual = this.dict[this.llistaFitxers[n].SOM_ID];
        this.somid = this.llistaFitxers[n].SOM_ID;
        this.pdfActual = null;
        document.getElementById('pdfViewer').setAttribute('style', 'height: 100%; align-content: center; display: none;');
        document.getElementById('imatgeViewer').setAttribute('style', 'height: 100%; align-content: center;  display: inline;');
    };
    FileBoxComponent.prototype.mostrarPdfNew = function (n) {
        this.pdfActual = this.dict[this.llistaFitxers[n].SOM_ID];
        this.somid = this.llistaFitxers[n].SOM_ID;
        this.imatgeActual = null;
        document.getElementById('imatgeViewer').setAttribute('style', 'height: 100%; align-content: center; display: none;');
        document.getElementById('pdfViewer').setAttribute('style', ' overflow-y: scroll; align-content: center; height: 500px');
    };
    FileBoxComponent.prototype.showImage = function () {
        var _this = this;
        if (this.imatgeActual != null) {
            var idAct = this.llistaFitxers.findIndex(function (g) { return g.SOM_ID === _this.somid; });
            var fitxer = this.llistaFitxers[idAct];
            this.viewerDialog = this.dialog.open(FileViewerComponent, {
                closeOnNavigation: false,
                autoFocus: true,
                maxWidth: '90%',
                data: { SomId: this.somid, NomFitxer: fitxer.NOMBRE_FICHERO }
            });
        }
    };
    // checkbox
    FileBoxComponent.prototype.isAllSelected = function () {
        var numSelected = this.selection.selected.length;
        var numRows = this.dataSource.data.length;
        return numSelected === numRows;
    };
    // checkbox
    FileBoxComponent.prototype.masterToggle = function () {
        var _this = this;
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(function (row) { return _this.selection.select(row); });
    };
    FileBoxComponent.prototype.download = function () {
        var _this = this;
        if (this.selection.selected.length !== null && this.selection.selected.length !== undefined && this.selection.selected.length > 0) {
            if (this.selection.selected.length === 1) {
                this.selection.selected.forEach(function (element) {
                    var fitxer = _this.newDict[element.SOM_ID];
                    var byteArray = new Uint8Array(fitxer);
                    var blob = new Blob([byteArray]);
                    saveAs(blob, element.NOMBRE_FICHERO);
                });
            }
            else {
                var fecha = new Date();
                // alert("Día: "++"\nMes: "+()+"\nAño: "+fecha.getFullYear());
                var NomFitxer_1 = this.i18n('Fitxers_') + (fecha.getFullYear() + (fecha.getMonth() + 1).toString() + fecha.getDate().toString());
                NomFitxer_1 += '_' + (fecha.getHours().toString() + fecha.getMinutes().toString() + fecha.getSeconds().toString()) + '.zip';
                var JSZip = require('jszip');
                var zip_1 = new JSZip();
                this.selection.selected.forEach(function (element) {
                    var idAct = _this.llistaFitxers.findIndex(function (g) { return g.SOM_ID === element.SOM_ID; });
                    var str = _this.newDict[element.SOM_ID];
                    zip_1.file(element.NOMBRE_FICHERO, str, { binary: true });
                });
                zip_1.generateAsync({ type: 'blob' })
                    .then(function (content) {
                    saveAs(content, NomFitxer_1);
                });
            }
        }
    };
    return FileBoxComponent;
}());
export { FileBoxComponent };
