import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent implements OnInit {
  @Input() label: string;
  @Input() icon: string;
  @Input() iconClass: string;
  @Input() isCloseButton: boolean;

  constructor() {
    this.label = '';
    this.icon = '';
    this.iconClass = '';
    this.isCloseButton = true;
  }

  ngOnInit() {
  }
}
