import { CookieService } from 'ngx-cookie-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private i18n: I18n,
    private cookie: CookieService
    ) {}
  // ...
  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('access_token');
    const expiration_date = sessionStorage.getItem('expiration_date');

    const timestamp = +expiration_date;
    const dd = new Date(timestamp);
    const currentdd = new Date();

    return (dd > currentdd && token.length > 0);
  }

  public hasToken() {
    const token = sessionStorage.getItem('access_token');
    return (token !== null && token !== 'null');
  }

  public logOut(message = true): void {
    const inst = sessionStorage.getItem('IdInstitucion');
    sessionStorage.clear();
    this.router.navigate(['/login/' + inst]);
    if (message) {
      this.toastr.info(this.i18n('S\'ha tancat la sessió correctament'));
    }
  }
}
