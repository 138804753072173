import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';

import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { CobrosFilter } from '../models/cobros-filter';

const defaultSortParam = 'fechaCobro';
const defaultSortDirection = 'desc';
@Injectable({
  providedIn: 'root'
})
export class CobrosService {

  constructor(
    private http: HttpSecureClient,
    private authGuard: AuthGuardService
  ) { }

  getCobros(filter: CobrosFilter): Observable<any> {
    if ( this.authGuard.canActivate()) {
      let data = {
        'fechas': filter.fechas,
        'aparatos': filter.aparatos
      };
      return this.http.postJSON(config.urls['apiUrl'] + api.endpoints['urlGetCobros'], JSON.stringify(data));
    }
  }

  generateExcel(filter: CobrosFilter): Observable<any> {
    if (filter.aparatos == null) {
      filter.aparatos = new Array()
    }
    if (this.authGuard.canActivate()) {
      let data = {
        'fechas': filter.fechas,
        'aparatos': filter.aparatos
      };
      return this.http.postFileArrayResponse(config.urls['apiUrl'] + api.endpoints['urlCobrosExcel'],  data, null);
    }
  }
}
