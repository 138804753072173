import { PropostaGruaDto } from './../Models/proposta-grua.model';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { AliesGrua } from '../Models/alies-grua.model';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AliesGruaFilter } from '../Models/alies-grua-filter';
import { AliesGruaFilterService } from './alies-grua-filter-service';
import { SomPolGruaFilterService } from './som-pol-grua-filter-service';
import { SomPolGruaFilter } from '../Models/som-pol-grua-filter';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class GruaPendentsService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  getAll(orden:string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetPropostesPendents'].replace('{orden}',orden), null);
    }
  }
  acceptPropostaGrua(proposta: PropostaGruaDto){
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetAcceptProposta'], proposta);
    }
  }
  rejectPropostaGrua(proposta: PropostaGruaDto){
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetRejectProposta'], proposta);
    }
  }

  SaveRegistroGrua(proposta: PropostaGruaDto){
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSaveRegistroGrua'], proposta);
    }
  }
  removePropostaGrua(proposta: PropostaGruaDto){
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteRegistroGrua'].replace('{secure_id}', proposta.secureId), null);
    }
  }
  getAllMotiusDesestimar(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMotiusDesestimarGrua'], null);
    }
  }

  getAllTecnicsCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllTecnics'], null);
    }
  }

  getAllMotiusDesestimarCombo():  Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllMotius'], null);
    }
  }

  getAllTipusVehiclesCombo():  Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllTipusVehicle'], null);
    }
  }
  CreatePropostaGrua(proposta: PropostaGruaDto){
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetCreateProposta'], proposta);
    }
  }

  getProposta(id: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetProposta'].replace('{id}', id), null);
    }
  }

  generateExcel(filter: SomPolGruaFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new SomPolGruaFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGruaTramitadesExcel'],  params );
    }
  }
 /* generateExcel(filter: MarquesVehiclesFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new MarquesVehiclesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);
    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlMarcaExcel'],  params );
    }
  }*/
}
