import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AliesGruaFilter } from 'app/sections/grua/Models/alies-grua-filter';
import { GruaAliesTableComponent } from './components/grua-alies-table/grua-alies-table.component';
import { GruaAliesEditorComponent } from './components/grua-alies-editor/grua-alies-editor.component';
import { GruaAliesFilterComponent } from './components/grua-alies-filter/grua-alies-filter.component';

@Component({
    selector: 'app-grua-alies',
    templateUrl: 'grua-alies.component.html',
    styleUrls: ['grua-alies.component.scss']
})
export class GruaAliesComponent implements OnInit, AfterViewInit {
    filterData: AliesGruaFilter;
    @ViewChild(GruaAliesTableComponent) gruaAliesTable: GruaAliesTableComponent;

    constructor(
      private dialog: MatDialog,
      public auth: AuthorizationService,
      private i18n: I18n,
      private not: NotificationService
      ) {
        this.filterData = new AliesGruaFilter('descripcio', 'asc');
      }

    ngOnInit(): void {
      this.auth.UserCanSeePage(this.auth.Pages.GruaAlies);
      this.gruaAliesTable.itemClicked.subscribe(secure_id => {
        this.update(secure_id);
      });
    }

    ngAfterViewInit() {
      this.not.titleChange(this.i18n('Alies grua'));
    }

    edit() {
      const idSeleccionat: string = this.gruaAliesTable.displayedItems['checkedItems'][0];
      this.update(idSeleccionat);
    }

    create() {
      this.update(null);
    }

    update(secure_id: string) {
      const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

      const ref = this.dialog.open(GruaAliesEditorComponent, {
        width: '40%',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });

      ref.afterClosed().subscribe(updated => {
        if (updated) { this.gruaAliesTable.load(); }
      });
    }

    refresh() {
      this.gruaAliesTable.load();
    }

    onFilterClicked() {
      const ref = this.dialog.open(GruaAliesFilterComponent, {
        width: '40%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          'filter': this.gruaAliesTable.filter
        }
      });

      ref.componentInstance.notify.subscribe((filter: AliesGruaFilter) => {
        this.gruaAliesTable.filter.set(filter);
        this.gruaAliesTable.load();
      });
    }

    onRowSelected(somid) {
      this.update(somid);
    }

    generateExcel() {
      this.gruaAliesTable.generateExcel();
    }
}
