/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./envio-puntos-dgt.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i3 from "../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i4 from "../../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i5 from "../../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i6 from "../../components/envio-puntos-dgt-table/envio-puntos-dgt-table.component.ngfactory";
import * as i7 from "../../components/envio-puntos-dgt-table/envio-puntos-dgt-table.component";
import * as i8 from "../../services/envio-puntos-dgt.service";
import * as i9 from "../../../../../shared/services/filter/filter.service";
import * as i10 from "@angular/material/dialog";
import * as i11 from "@ngx-translate/i18n-polyfill";
import * as i12 from "./envio-puntos-dgt.component";
import * as i13 from "ngx-toastr";
import * as i14 from "@angular/router";
import * as i15 from "../../../../../shared/services/notification/notification.service";
var styles_EnvioPuntosDgtComponent = [i0.styles];
var RenderType_EnvioPuntosDgtComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnvioPuntosDgtComponent, data: {} });
export { RenderType_EnvioPuntosDgtComponent as RenderType_EnvioPuntosDgtComponent };
export function View_EnvioPuntosDgtComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { puntosDGTTable: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "app-toolbar", [], null, null, null, i2.View_ToolbarComponent_0, i2.RenderType_ToolbarComponent)), i1.ɵdid(2, 114688, null, 0, i3.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "app-tool-button", [["color", "has-color-blue"], ["icon", "fa-sync"], ["text", "Actualitzar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(4, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "app-tool-button", [["color", "has-color-orange"], ["icon", "fa-filter"], ["text", "Filtre"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFilterClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(6, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "app-tool-button", [["color", "has-color-darkblue"], ["src", "assets/images/dgtBig.png"], ["text", "Enviar punts"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.enviarPuntos() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(8, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], src: [2, "src"] }, { click: "click" }), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-tool-button", [["color", "has-color-orange"], ["icon", "fa-history"], ["text", "Hist\u00F2ric"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.irHistorico() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(10, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-envio-puntos-dgt-table", [], null, null, null, i6.View_EnvioPuntosDgtTableComponent_0, i6.RenderType_EnvioPuntosDgtTableComponent)), i1.ɵdid(12, 4833280, [[1, 4]], 0, i7.EnvioPuntosDgtTableComponent, [i8.EnvioPuntosDGTService, i9.FilterService, i10.MatDialog, i11.I18n], { filter: [0, "filter"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = "Actualitzar"; var currVal_1 = "has-color-blue"; var currVal_2 = "fa-sync"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Filtre"; var currVal_4 = "has-color-orange"; var currVal_5 = "fa-filter"; _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = "Enviar punts"; var currVal_7 = "has-color-darkblue"; var currVal_8 = "assets/images/dgtBig.png"; _ck(_v, 8, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = "Hist\u00F2ric"; var currVal_10 = "has-color-orange"; var currVal_11 = "fa-history"; _ck(_v, 10, 0, currVal_9, currVal_10, currVal_11); var currVal_12 = _co.filterData; _ck(_v, 12, 0, currVal_12); }, null); }
export function View_EnvioPuntosDgtComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-envio-puntos-dgt", [], null, null, null, View_EnvioPuntosDgtComponent_0, RenderType_EnvioPuntosDgtComponent)), i1.ɵdid(1, 4308992, null, 0, i12.EnvioPuntosDgtComponent, [i10.MatDialog, i8.EnvioPuntosDGTService, i11.I18n, i13.ToastrService, i14.Router, i15.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnvioPuntosDgtComponentNgFactory = i1.ɵccf("app-envio-puntos-dgt", i12.EnvioPuntosDgtComponent, View_EnvioPuntosDgtComponent_Host_0, {}, {}, []);
export { EnvioPuntosDgtComponentNgFactory as EnvioPuntosDgtComponentNgFactory };
