import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MaquinaIncidenciaFilter } from '../../models/maquinaIncidencia-filter';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LoaderService } from 'path-shared/components/loader/loader.service';

@Component({
  selector: 'app-incidencies-filter',
  templateUrl: './incidencies-filter.component.html',
  styleUrls: ['./incidencies-filter.component.scss']
})
export class IncidenciesFilterComponent implements OnInit {

  subscription: Subscription;
  filter: MaquinaIncidenciaFilter;

  @Output() notify: EventEmitter<MaquinaIncidenciaFilter> = new EventEmitter<MaquinaIncidenciaFilter>();
  constructor(
    public thisDialogRef: MatDialogRef<MaquinaIncidenciaFilter>,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: MaquinaIncidenciaFilter) { 
    this.filter = data;
  }


  ngOnInit() {
    this.loaderService.setBackgroundMode(true);
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

  ngOnDestroy(): void {
    this.loaderService.setBackgroundMode(false);
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated(true)
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }


}
