<app-toolbar>
  <app-tool-button-permissions [permission]="this.auth.Pages.Avisos"></app-tool-button-permissions>
</app-toolbar>
<div style="height: 80vh; overflow-y: auto">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <button class="btn btn-sm btn-link" mat-icon-button matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{node.item.isFolder==1 ? (node.item.children != undefined ? (treeControl.isExpanded(node) ? 'folder_open' : 'folder') : 'folder_open') : 'crop_portrait'}}
          </mat-icon>
          </button>
          <button class="btn btn-link" *ngIf="this.auth.UserCanWrite(this.auth.Pages.Avisos)" (click)="editItem(node)">{{node.item.nom}}</button>
          <button *ngIf="node.item.isFolder==1 && this.auth.UserCanWrite(this.auth.Pages.Avisos)" class="btn btn-sm btn-link" mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button>
          <button class="btn btn-sm btn-link"  *ngIf="this.auth.UserCanDelete(this.auth.Pages.Avisos)" mat-icon-button (click)="removeItem(node)"><mat-icon>remove</mat-icon></button>
        </mat-tree-node>
      
        <!-- <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
          <mat-form-field class="ml-4">
            <input matInput #itemValue i18n placeholder="Nou element...">
          </mat-form-field>
          <button class="btn btn-sm btn-primary ml-2" mat-button (click)="saveNode(node, itemValue.value)">Save</button>
        </mat-tree-node> -->
      
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
          <button class="btn btn-sm btn-link" mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'toggle ' + node.filename">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'folder_open' : 'folder'}}
            </mat-icon>
          </button>
          <button class="btn btn-link" (click)="editItem(node)">{{node.item.nom}}</button>
          <button *ngIf="node.item.isFolder==1 && this.auth.UserCanWrite(this.auth.Pages.Avisos)" class="btn btn-sm btn-link" mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button>
          <button class="btn btn-sm btn-link" *ngIf="this.auth.UserCanDelete(this.auth.Pages.Avisos) && node.item.id!=0" mat-icon-button (click)="removeItem(node)"><mat-icon>remove</mat-icon></button>
        </mat-tree-node>
      </mat-tree>
</div>
