<app-dialog-header label="Tarifes" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Festius)  || !this.dialogData.isReadOnly || !this.publicarAvailable"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Festius) || this.dialogData.isReadOnly || !this.publicarAvailable"></app-tool-button>
  <!-- <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Festius) ||this.dialogData.isReadOnly"></app-tool-button> -->
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <div class="col-3">
        <app-form-text-edit id="tarifes-editor-codi" i18n-label label="Codi" [(value)]="this.tarifa.codi" required disabled></app-form-text-edit>
        <app-form-text-edit id="tarifes-editor-descripcio" i18n-label label="Descripció" [(value)]="this.tarifa.descripcio" required [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
        <app-form-date-edit id="tarifes-editor-dataIni" i18n-label label="Data inici" [(value)]="this.tarifa.dataInici" required [isReadOnly]="this.dialogData.isReadOnly"></app-form-date-edit>
        <app-form-date-edit id="tarifes-editor-dataFi" i18n-label label="Data fi" [(value)]="this.tarifa.dataFi" required [isReadOnly]="this.dialogData.isReadOnly"></app-form-date-edit>

        <div class="border border-secundary" style="padding:5px">
          <h5 i18n>CÀLCUL</h5>
          <app-form-text-edit id="tarifes-editor-tipusCalcul" i18n-label label="Tipus de càlcul" [(value)]="this.tarifa.tipusCalcul" required disabled></app-form-text-edit>
          <app-form-text-edit id="tarifes-editor-valor" i18n-label label="Valor" [(value)]="this.tarifa.valor" required disabled></app-form-text-edit>
          <app-form-text-edit id="tarifes-editor-valorMinim" i18n-label label="Valor mínim" [(value)]="this.tarifa.valorMinim" required disabled></app-form-text-edit>
          <app-form-text-edit id="tarifes-editor-valorMaxim" i18n-label label="Valor màxim" [(value)]="this.tarifa.valorMaxim" disabled></app-form-text-edit>
          <app-form-text-edit id="tarifes-editor-carenciaDenuncia" i18n-label label="Carència de denúncia" [(value)]="this.tarifa.carenciaDenuncia" required disabled></app-form-text-edit>
          <app-form-text-edit id="tarifes-editor-increment" i18n-label label="Increment" [(value)]="this.tarifa.increment" required disabled></app-form-text-edit> 
        </div>

        <div class="border border-secundary" style="padding:5px; margin-top: 5px;">
          <h5 i18n>DADES</h5>
          <app-form-combo-edit i18n-label label="Codi zona" [(value)]="this.tarifa.idZona" [items]="this.zones" valueField="codi" textField="descripcio" isReadOnly></app-form-combo-edit>
          <app-form-combo-edit i18n-label label="Resident" [(value)]="this.tarifa.idResident" [items]="this.residents" valueField="codi" textField="descripcio" isReadOnly></app-form-combo-edit>
          <app-form-combo-edit i18n-label label="Tipus tiquet" [(value)]="this.tarifa.idTiquet" [items]="this.tipusTiquets" valueField="codi" textField="descripcio" isReadOnly></app-form-combo-edit>
        </div>

        <div class="border border-secundary" style="padding:5px; margin-top: 5px;">
          <h5 i18n>ANUL·LACIÓ</h5>
          <div class="row" style="margin:auto">
            <div class="col border border-secundary" style="padding:5px; margin-top: 5px;">
              <h6 i18n>SENSE TIQUET</h6>
              <app-form-text-edit id="tarifes-editor-preu" i18n-label label="Preu" [(value)]="this.tarifa.anulSenseTiquet" disabled ></app-form-text-edit>
              <app-form-text-edit id="tarifes-editor-temps" i18n-label label="Temps" [(value)]="this.tarifa.tempsSenseTiquet" disabled ></app-form-text-edit>
            </div>
            <div class="col border border-secundary" style="padding:5px; margin-top: 5px;">
              <h6 i18n>TIQUET EXHAURIT</h6>
              <app-form-text-edit id="tarifes-editor-preu" i18n-label label="Preu" [(value)]="this.tarifa.anulExhaurit" disabled></app-form-text-edit>
              <app-form-text-edit id="tarifes-editor-temps" i18n-label label="temps" [(value)]="this.tarifa.tempsExhaurit" disabled></app-form-text-edit>
            </div>
          </div>  
        </div>    

      </div>
      <div class="col">
        <div class="row">
          <div class="col-1">

          </div>
          <div class="col-2" i18n>INICI TARIFA</div>
          <div class="col-2" i18n>INICI PAUSA</div>
          <div class="col-2" i18n>FI PAUSA</div>
          <div class="col-2" i18n>FI TARIFA</div>
        </div>
        
        <div class="row" >
          <div class="col-1" i18n>DILLUNS</div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.desde1" (input)="this.tarifa.desde1=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.iniciPausa1" (input)="this.tarifa.iniciPausa1=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.fiPausa1" (input)="this.tarifa.fiPausa1=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.hasta1" (input)="this.tarifa.hasta1=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
        </div>

        <div class="row" >
          <div class="col-1" i18n>DIMARTS</div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.desde2" (input)="this.tarifa.desde2=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.iniciPausa2" (input)="this.tarifa.iniciPausa2=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.fiPausa2" (input)="this.tarifa.fiPausa2=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.hasta2" (input)="this.tarifa.hasta2=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
        </div>

        <div class="row" >
          <div class="col-1" i18n>DIMECRES</div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.desde3" (input)="this.tarifa.desde3=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.iniciPausa3" (input)="this.tarifa.iniciPausa3=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.fiPausa3" (input)="this.tarifa.fiPausa3=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.hasta3" (input)="this.tarifa.hasta3=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
        </div>

        <div class="row" >
          <div class="col-1" i18n>DIJOUS</div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.desde4" (input)="this.tarifa.desde4=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.iniciPausa4" (input)="this.tarifa.iniciPausa4=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.fiPausa4" (input)="this.tarifa.fiPausa4=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.hasta4" (input)="this.tarifa.hasta4=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
        </div>

        <div class="row" >
          <div class="col-1" i18n>DIVENDRES</div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.desde5" (input)="this.tarifa.desde5=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.iniciPausa5" (input)="this.tarifa.iniciPausa5=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.fiPausa5" (input)="this.tarifa.fiPausa5=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.hasta5" (input)="this.tarifa.hasta5=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
        </div>

        <div class="row" >
          <div class="col-1" i18n>DISSABTE</div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.desde6" (input)="this.tarifa.desde6=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.iniciPausa6" (input)="this.tarifa.iniciPausa6=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.fiPausa6" (input)="this.tarifa.fiPausa6=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.hasta6" (input)="this.tarifa.hasta6=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
        </div>

        <div class="row" >
          <div class="col-1" i18n>DIUMENGE</div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.desde7" (input)="this.tarifa.desde7=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.iniciPausa7" (input)="this.tarifa.iniciPausa7=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.fiPausa7" (input)="this.tarifa.fiPausa7=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field>
              <input type="time" matInput  [value]="this.tarifa.hasta7" (input)="this.tarifa.hasta7=$event.target.value" required [readonly]="this.dialogData.isReadOnly">
            </mat-form-field>
          </div>
        </div>

        <div class="row" style="margin: auto">
          <div class="table-sdw is-fixed" style="max-height: 55vh">
            <mat-table #table [dataSource]="dataSource" matSort #sortPantallas="matSort" class="is-header-sticky">
                <ng-container matColumnDef="tipus">
                  <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipus</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <app-form-text-edit [(value)]="row.tipus" disabled></app-form-text-edit>
                    <!-- <app-form-combo-edit [(value)]="row.tipus" (valueChange)="novaFila($event)" [items]="tipus" valueField="id" textField="descripcio" disabled></app-form-combo-edit> -->

                  </mat-cell>
                </ng-container>
          
                <ng-container matColumnDef="minInici">
                  <mat-header-cell *matHeaderCellDef i18n>Min. Inici</mat-header-cell>
                  <mat-cell *matCellDef="let row;">
                    <app-form-text-edit id="tarifes-editor-minInici" [(value)]="this.row.minInici" disabled></app-form-text-edit>
                  </mat-cell>
                </ng-container>
          
                <ng-container matColumnDef="minFi">
                  <mat-header-cell *matHeaderCellDef i18n>Min. Fi</mat-header-cell>
                  <mat-cell *matCellDef="let row;">
                    <app-form-text-edit id="tarifes-editor-minFi" [(value)]="this.row.minFi" disabled></app-form-text-edit>
                  </mat-cell>
                </ng-container>
          
                <ng-container matColumnDef="preuMaxFixe">
                  <mat-header-cell *matHeaderCellDef i18n>Preu màx./fixe</mat-header-cell>
                  <mat-cell *matCellDef="let row;">  
                    <app-form-text-edit id="tarifes-editor-preuMaxFixe" [(value)]="this.row.preuMaxFixe" disabled></app-form-text-edit>                  
                  </mat-cell>
                </ng-container>
          
                <ng-container matColumnDef="incrementMin">
                  <mat-header-cell *matHeaderCellDef i18n>Increment min.</mat-header-cell>
                  <mat-cell *matCellDef="let row;">
                    <app-form-text-edit id="tarifes-editor-incrementMin" [(value)]="this.row.incrementMin" disabled></app-form-text-edit>
                  </mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="preuPerIncrement">
                  <mat-header-cell *matHeaderCellDef i18n>Preu per increment</mat-header-cell>
                  <mat-cell *matCellDef="let row;">
                    <app-form-text-edit id="tarifes-editor-preuPerIncrement" [(value)]="this.row.preuPerIncrement" disabled></app-form-text-edit>
                  </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="eliminar">
                  <mat-header-cell *matHeaderCellDef i18n>Eliminar</mat-header-cell>
                  <mat-cell *matCellDef="let row;">
                    <i *ngIf="row.tipus!==null" (click)="removeTimeFrame(row)" class="cursor fa fa-trash-alt btn btn-outline-danger btn-sm" disabled></i>
                  </mat-cell>
                </ng-container> -->
          
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
            </div>
          </div>    
      </div>

    </div>
  </form>
</app-dialog-body>