<app-dialog-header label="Tipus Vehicles" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.TipusVehicles) ||!this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.TipusVehicles)||this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.TipusVehicles) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="tipus-vehicles-editor-codi" class="col-sm-3" i18n-label label="Codi" [(value)]="this.tipusVehicle.id" required maxLength="3" number></app-form-text-edit>
      <app-form-text-edit id="tipus-vehicles-editor-descripcio" class="col-sm-9" i18n-label label="Descripcio" [(value)]="this.tipusVehicle.descripcio" required maxLength="30"></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-check-edit class="col-sm-9" i18n-label label="Matricula obligatòria" [(value)]="this.tipusVehicle.matriculaObligatoria"></app-form-check-edit>
    </div>
  </form>
</app-dialog-body>
