import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../../../../../shared/services/authentication/auth-guard.service";
var PlanificacioService = /** @class */ (function () {
    function PlanificacioService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
    }
    PlanificacioService.prototype.setPlanificacion = function (Planificacio) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetPlanificacio'], Planificacio);
        }
    };
    PlanificacioService.ngInjectableDef = i0.defineInjectable({ factory: function PlanificacioService_Factory() { return new PlanificacioService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: PlanificacioService, providedIn: "root" });
    return PlanificacioService;
}());
export { PlanificacioService };
