import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-list',
  templateUrl: './dialog-list.component.html'
})
export class DialogListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  test() {
    alert("OK");
  }

}
