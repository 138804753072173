import { XRETIDADA } from './../../../../../../models/XRETIDADA';
import { Component, OnInit, Input } from '@angular/core';
import { XDENUNCIES } from 'path-models/XDENUNCIES';
import { DenunciaDetailModel } from 'app/sections/denuncies/models/denuncia-detail.model';

@Component({
  selector: 'app-retirada',
  templateUrl: './retirada.component.html'
})
export class RetiradaComponent implements OnInit {

  @Input() data: DenunciaDetailModel;
  @Input() isReadOnly: boolean;

  constructor() {}

  ngOnInit() {
  }

}
