import { config } from 'path-shared/configuration/config';
import { Injectable } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { TextoInformativo } from '../model/texto-informativo';
import { api } from 'path-shared/configuration/urls';
import { Observable } from 'rxjs';


@Injectable()
export class TextoInformativoService {
    displayedItems = [];

    constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
        this.displayedItems['checkedValues'] = 0;
        this.displayedItems['checkedItems'] = Array();
    }

    setTextoInformativo(nuevoTexto: TextoInformativo): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTextoInformativo'], nuevoTexto);
        }
    }

    getSiglasTextos(): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetSiglasTextos'], null);
        }
    }

}
