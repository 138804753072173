import { Infraccio } from './../../../configuracio/Infraccions/models/infraccio.model';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ConfirmationDialogService } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.service';
import { MatDialog } from '@angular/material';
import { DenunciaDetailModel } from '../../models/denuncia-detail.model';
import { CFG_TIPODENUNCIA } from '.././../../././../models/CFG_TIPODENUNCIA';
import { StartupService } from '../../../../shared/services/startup/startup.service';

import { Router } from '@angular/router';
import { Builder } from 'path-shared/models/builder';
import { CarrersComponent } from 'app/sections/configuracio/carrers/pages/carrers/carrers.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { ZonesService } from 'app/sections/ciutat/zones/services/zones.service';
import { ZonesModel } from 'app/sections/ciutat/zones/models/zones.model';
import { AgentsComponent } from 'app/sections/configuracio/agents/pages/agents/agents.component';
import { VehiclesComponent } from 'app/sections/ciutat/vehicles/pages/vehicles/vehicles.component';
import { BehaviorSubject } from 'rxjs';
import { Dictionary } from 'path-shared/models/dictionary';
import { InfraccioComponent } from 'app/sections/configuracio/Infraccions/pages/infraccio.component';
import { PersonesComponent } from 'app/sections/ciutat/persones/pages/persones/persones.component';
import { SOM_DEN_INFRACCIONES_GRAVEDAD } from 'path-models/SOM_DEN_INFRACCIONES_GRAVEDAD';
import { InfraccioService } from 'app/sections/configuracio/Infraccions/services/infraccions-service.service';
import { PersonesService } from 'app/sections/ciutat/persones/services/persones.service';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';
import { Persona } from 'app/sections/ciutat/persones/models/persona.model';
import { PersonaFilter } from 'app/sections/ciutat/persones/models/persona-filter';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PersonesEditorComponent } from 'app/sections/ciutat/persones/components/persones-editor/persones-editor.component';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { ToastrService } from 'ngx-toastr';
import { FormService } from 'path-shared/services/form/form.service';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { CarrersService } from 'app/sections/configuracio/carrers/services/carrers-service.service';
import { PersonesTableComponent } from 'app/sections/ciutat/persones/components/persones-table/persones-table.component';
import { PersonesFilterComponent } from 'app/sections/ciutat/persones/components/persones-filter/persones-filter.component';
import { MotiusNoNotificacioService } from 'app/sections/configuracio/motius-notificacio/services/motius-notificacio.service';


@Component({
  selector: 'app-denuncies-new',
  templateUrl: './denuncies-new.component.html'
})
export class DenunciesNewComponent implements OnInit {

  /* #region  ATTRIBUTES */

  @Output() created: EventEmitter<number> = new EventEmitter<number>();
  denunciaDetail: DenunciaDetailModel;

  diesDescompte: number;
  tipusDenunciesDictionary: BehaviorSubject<Array<Dictionary>>;
  zonesDictionary: BehaviorSubject<Array<Dictionary>>;
  tipusGravetatDictionary: BehaviorSubject<Array<Dictionary>>;
  domicilisConductorDictionary: BehaviorSubject<Array<Dictionary>>;
  domicilisPropietariDictionary: BehaviorSubject<Array<Dictionary>>;
  motiusNoNotificacio = [];
  conductorPreviousDNI = '';
  propietariPreviousDNI = '';
  EstablecimientoTrabajadorPreviousNIE = '';
  EstablecimientoPreviousCIF = '';

  skip = false;

  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild('comboDomConductor') comboDomConductor: ComboBoxInputComponent;
  @ViewChild('comboDomPropietari') comboDomPropietari: ComboBoxInputComponent;
  @ViewChild('comboTipusDenuncia') comboTipusDenuncia: ComboBoxInputComponent;
  @ViewChild('comboTipusGravetat') comboTipusGravetat: ComboBoxInputComponent;


  // autocomplete combo
  callesDictionary: Array<Dictionary>;
  valorAutoCompleteCalleDictionary;
  @ViewChild('comboCalle') comboCalle: ComboBoxInputAutocompleteComponent;

  // autocomplete combo Infracciones
  infracciones: Infraccio[];
  infraccionesDictionary: Array<Dictionary>;
  valorAutoCompleteInfraccionesDictionary;
  @ViewChild('comboInfraccion') comboInfracciones: ComboBoxInputAutocompleteComponent;

  /* #endregion */

  /* #region  INITIALIZERS */

  constructor(
    private dialog: MatDialog,
    private zonesService: ZonesService,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogService,
    private startupService: StartupService,
    private infraccioService: InfraccioService,
    private personesService: PersonesService,
    private denunciesService: DenunciesService,
    private formService: FormService,
    private toastr: ToastrService,
    private i18n: I18n,
    private carrersService: CarrersService,
    private infraccionsService: InfraccioService,
    private motiusNoNotificacioService: MotiusNoNotificacioService
  ) {
    this.denunciaDetail = Builder.newDenunciaDetail();
    this.tipusDenunciesDictionary = new BehaviorSubject(new Array<Dictionary>());
    this.zonesDictionary = new BehaviorSubject(new Array<Dictionary>());
    this.tipusGravetatDictionary = new BehaviorSubject(new Array<Dictionary>());
    this.domicilisConductorDictionary = new BehaviorSubject(new Array<Dictionary>());
    this.domicilisPropietariDictionary = new BehaviorSubject(new Array<Dictionary>());
    this.valorAutoCompleteCalleDictionary = '';
    this.valorAutoCompleteInfraccionesDictionary = '';
  }

  /* #endregion */

  /* #region  COMPONENT LIFECYCLE */

  ngOnInit() {
    this.loadTipusDenuncies();
    this.loadTipusGravetat();
    this.loadDiesDescompte();
    this.loadCalles();
    this.loadInfracciones();
    this.loadMotiusNoNotificacio();
  }

  /* #endregion */

  /* #region  EVENTS */

  saveDenuncia() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Vol crear la denúncia?'))
        .then((confirmed) => {
          if (confirmed) {
            const dataJson = JSON.stringify(this.denunciaDetail);
            this.denunciesService.saveDenuncia(this.denunciaDetail.secure_id_denuncia, dataJson).subscribe(() => {
              this.toastr.success(this.i18n('Denúncia creada'));
              this.dialog.closeAll();
              this.router.navigate(['denuncies']);
            });
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.router.navigate(['denuncies']);
  }

  /* #endregion */

  /* #region  DATA GETTERS && SETTERS */

  loadTipusDenuncies() {
    const tipusDenunciaAux = new Array<Dictionary>();
    this.startupService.GetTiposDenuncia().forEach((tipusDenuncia: CFG_TIPODENUNCIA) => {
      tipusDenunciaAux.push(new Dictionary(tipusDenuncia.CODI, tipusDenuncia.DESCRIPCIO));
    });

    this.tipusDenunciesDictionary.next(tipusDenunciaAux);
  }

  loadTipusGravetat() {
    const tipusGravetatAux = new Array<Dictionary>();
    this.startupService.GetInfraccioTipusGravetat().forEach((gravetat: SOM_DEN_INFRACCIONES_GRAVEDAD) => {
      tipusGravetatAux.push(new Dictionary(gravetat.CODI, gravetat.DESCRIPCIO));
    });

    this.tipusGravetatDictionary.next(tipusGravetatAux);
  }

  loadDiesDescompte() {
    this.infraccioService.GetInfraccioDiesDescompte().subscribe((dies: number) => {
      this.diesDescompte = dies;
    });
  }

  loadCalles() {
    let json: string;
    let carrers: any[];

    if (sessionStorage.getItem('carrersForCombo')) {
      json = sessionStorage.getItem('carrersForCombo');
      carrers = JSON.parse(json.toString());
      this.setCalles(carrers);
    } else {
      this.carrersService.getAllForCombo().subscribe(carrersForCombo => {
        json = JSON.stringify(carrersForCombo);
        sessionStorage.setItem('carrersForCombo', json);
        this.setCalles(carrersForCombo);
      });
    }
  }

  setCalles(result: any[]) {
    if (result !== null) {
      this.callesDictionary = new Array<Dictionary>();
      result.forEach(element => {
        if (element.SECURE_ID !== undefined && element.SECURE_ID !== null &&
          element.nomCarrer !== undefined && element.nomCarrer !== null) {
          this.callesDictionary.push(new Dictionary(element.SECURE_ID, element.nomCarrer));
        }
      });
    }
  }

  loadInfracciones() {
    this.infraccionsService.getAllForCombo().subscribe(result => {
      if (result !== null) {
        this.infracciones = result;
        this.infraccionesDictionary = new Array<Dictionary>();
        result.forEach(element => {
          if (element.secureId !== undefined && element.secureId !== null &&
            element.descCurta !== undefined && element.descCurta !== null) {
            this.infraccionesDictionary.push(new Dictionary(element.secureId, element.descCurta));
          }

        });
      }
    });
  }

  loadMotiusNoNotificacio() {
    this.motiusNoNotificacioService.getAllForCombo().subscribe(res => {
      this.motiusNoNotificacio = res;
    });
  };



  loadZonaCarrer(secure_id_carrer: string) {
    if (secure_id_carrer !== null && secure_id_carrer !== undefined) {
      this.zonesService.getZonesByCarrer(secure_id_carrer).subscribe((zones: ZonesModel[]) => {
        const zonesComboAux = new Array<Dictionary>();
        zones.forEach(zona => {
          zonesComboAux.push(new Dictionary(zona.SECURE_ID, zona.descripcio));
        });

        this.zonesDictionary.next(zonesComboAux);
      });
    }
  }

  tipusDenunciaSeleccionado(tipusDenunciaId: string) {
    this.denunciaDetail.tipusDenuncia = tipusDenunciaId;
  }

  zonaCarrerSeleccionado(zonaSecureId: string) {
    this.denunciaDetail.secure_id_zona = zonaSecureId;
  }

  tipusGravetatSeleccionado(gravetatId: string) {
    this.denunciaDetail.qualificacio = gravetatId;
  }

  domicilioConductorSeleccionado(secure_id: string) {
    this.denunciaDetail.secure_id_domiciliConductor = secure_id;
  }

  domicilioPropietarioSeleccionado(secure_id: string) {
    this.denunciaDetail.secure_id_domiciliPropietari = secure_id;
  }

  calleSeleccionado(secureIdCalle: string) {
    console.log(secureIdCalle);
    this.denunciaDetail.secure_id_carrer = secureIdCalle;
    this.denunciaDetail.carrer = this.callesDictionary.find(x => x.key === secureIdCalle).value;
    this.valorAutoCompleteCalleDictionary = this.denunciaDetail.carrer;
    // Carreguem les zones associades al carrer sel·leccionat
    console.log(this.denunciaDetail.carrer);
    // this.loadZonaCarrer(this.denunciaDetail.secure_id_carrer);
  }
  infraccioSeleccionado(secureIdInfraccion: string) {
    const res = this.infracciones.find(x => x.secureId === secureIdInfraccion);
    if (res !== undefined && res !== null) {
      // Les infraccions de tipus velocitat no les podem denúnciar des d'aquí
      if (res.tipusId === 'V') {
        this.toastr.warning(this.i18n('No es poden carregar infraccions de velocitat!'));
      } else {
        this.denunciaDetail.llei = res.legislacio;
        this.denunciaDetail.article = res.article;
        this.denunciaDetail.descripcioInfraccio = res.descCurta;
        this.denunciaDetail.secure_id_infraccio = res.secureId;
        this.denunciaDetail.import = res.import;
        this.denunciaDetail.importDescompte = res.importDescompte;
        this.denunciaDetail.lleiDesc = res.lleiDesc;
        this.denunciaDetail.tipusIdInfraccio = res.tipusId;
        this.denunciaDetail.porcentajeDescuento = res.descompte;
        this.denunciaDetail.punts = Number(res.punts);
        this.denunciaDetail.judicial = res.judicial;

        this.denunciaDetail.tipusDenuncia = res.tipusDenunciaId; // combo tipus denuncies
        this.comboTipusDenuncia.setSelect(this.denunciaDetail.tipusDenuncia);
        this.comboTipusDenuncia.markAsTouched();
        this.denunciaDetail.qualificacio = res.gravetat; // combo tipus gravetats
        this.comboTipusGravetat.setSelect(this.denunciaDetail.qualificacio);
        this.comboTipusGravetat.markAsTouched();

        this.comboInfracciones.disableComponent = true;
        this.calcularDataDescompte();
      }
    }
  }

  seleccionarCarrer() {
    const data: ComponentDialog = Builder.newComponentDialog(true);
    const ref = this.dialog.open(CarrersComponent, {
      width: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(resp => {
      if (resp.nomCarrer !== undefined && resp.nomCarrer !== null && resp.nomCarrer !== '') {


        // this.loadZonaCarrer(this.denunciaDetail.secure_id_carrer);
        console.log(resp.secure_id);
        //this.denunciaDetail.carrer = resp.nomCarrer;
        this.denunciaDetail.secure_id_carrer = resp.secure_id;
        this.denunciaDetail.carrer = this.callesDictionary.find(x => x.key === resp.secure_id).value;
        this.valorAutoCompleteCalleDictionary = resp.nomCarrer;
        console.log(this.denunciaDetail.carrer);
        // Carreguem les zones associades al carrer sel·leccionat
        //this.loadZonaCarrer(this.denunciaDetail.secure_id_carrer);
      }
    });
  }

  seleccionarAgent() {
    const data: ComponentDialog = Builder.newComponentDialog(true);
    const ref = this.dialog.open(AgentsComponent, {
      width: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(res => {
      if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
        this.denunciaDetail.codiAgent = res.codi;
        this.denunciaDetail.secure_id_agent = res.secure_id;
      }
    });
  }

  seleccionarVehicle() {
    const data: ComponentDialog = Builder.newComponentDialog(true);
    const ref = this.dialog.open(VehiclesComponent, {
      width: '80%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(res => {
      if (res.matricula !== undefined && res.matricula !== null && res.matricula !== '') {
        this.denunciaDetail.secure_id_vehicle = res.secure_id;
        this.denunciaDetail.matricula = res.matricula;
        this.denunciaDetail.modelo = res.model;
        this.denunciaDetail.marca = res.marca;
        this.denunciaDetail.pais = res.pais;
        this.denunciaDetail.paisDesc = res.paisDesc;
      }
    });
  }

  seleccionarLegislacio() {
    const data: ComponentDialog = Builder.newComponentDialog(true);
    const ref = this.dialog.open(InfraccioComponent, {
      width: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });
    ref.afterClosed().subscribe(res => {
      if (res !== undefined && res !== null) {
        // Les infraccions de tipus velocitat no les podem denúnciar des d'aquí
        if (res.infraccio.tipusId === 'V') {
          this.toastr.warning(this.i18n('No es poden carregar infraccions de velocitat!'));
        } else {
          this.denunciaDetail.llei = res.infraccio.legislacio;
          this.denunciaDetail.article = res.infraccio.article;
          this.denunciaDetail.descripcioInfraccio = res.infraccio.descCurta;
          this.denunciaDetail.secure_id_infraccio = res.infraccio.secureId;
          this.denunciaDetail.import = res.infraccio.import;
          this.denunciaDetail.importDescompte = res.infraccio.importDescompte;
          this.denunciaDetail.lleiDesc = res.infraccio.lleiDesc;
          this.denunciaDetail.tipusIdInfraccio = res.infraccio.tipusId;
          this.denunciaDetail.porcentajeDescuento = res.infraccio.descompte;
          this.denunciaDetail.punts = res.infraccio.punts;
          this.denunciaDetail.judicial = res.infraccio.judicial;

          this.denunciaDetail.tipusDenuncia = res.infraccio.tipusDenunciaId; // combo tipus denuncies
          this.comboTipusDenuncia.setSelect(this.denunciaDetail.tipusDenuncia);
          this.comboTipusDenuncia.markAsTouched();
          this.denunciaDetail.qualificacio = res.infraccio.gravetat; // combo tipus gravetats
          this.comboTipusGravetat.setSelect(this.denunciaDetail.qualificacio);
          this.comboTipusGravetat.markAsTouched();

          this.calcularDataDescompte();
          this.comboInfracciones.disableComponent = true;
          this.setDefaultValueInfraccion();
        }
      }
    });
  }
  setDefaultValueInfraccion() {
    if (this.denunciaDetail.secure_id_infraccio !== undefined && this.denunciaDetail.secure_id_infraccio !== null && this.infraccionesDictionary.length > 0) {
      const infraccioSelected = this.infraccionesDictionary.find(x => x.key === this.denunciaDetail.secure_id_infraccio.toString());
      this.comboInfracciones.setForcedValueComb(infraccioSelected.value);
    }
  }
  seleccionarConductor() {
    const data: ComponentDialog = Builder.newComponentDialog(true);
    const ref = this.dialog.open(PersonesComponent, {
      width: '80%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data,
      disableClose: true
    });

    ref.afterClosed().subscribe(async res => {
      if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
        this.denunciaDetail.secure_id_conductor = res.codi;
        this.denunciaDetail.secure_id_conductor = res.secure_id;
        this.denunciaDetail.nom_conductor = res.nomComplet;
        this.denunciaDetail.dni_conductor = res.dni_pasp;
        this.denunciaDetail.secure_id_domiciliConductor = res.secureIdDom;

        this.getDomicilis(this.denunciaDetail.secure_id_conductor, false, res.secureIdDom);
      }
    });
  }

  seleccionarPropietari() {
    const data: ComponentDialog = Builder.newComponentDialog(true);
    const ref = this.dialog.open(PersonesComponent, {
      width: '80%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data,
      disableClose: true
    });

    ref.afterClosed().subscribe(res => {
      if (res.codi !== undefined && res.codi !== null && res.codi !== '') {
        this.denunciaDetail.secure_id_propietari = res.secure_id;
        this.denunciaDetail.nom_propietari = res.nomComplet;
        this.denunciaDetail.dni_propietari = res.dni_pasp;
        this.denunciaDetail.secure_id_domiciliPropietari = res.secureIdDom;
        this.getDomicilis(this.denunciaDetail.secure_id_propietari, true, res.secureIdDom);
      }
    });
  }

  getDomicilis(secureIdPersona, propietari, secureIdDom) {
    this.personesService.getDomicilis(secureIdPersona).subscribe(dom => {
      if (propietari) {
        this.comboDomPropietari.resetShowingOptions();

        if (dom === undefined || dom === null || dom.length === 0) {
          this.domicilisPropietariDictionary = new BehaviorSubject(new Array<Dictionary>());
        } else {
          this.domicilisPropietariDictionary = new BehaviorSubject(new Array<Dictionary>());
          const dict = new Array<Dictionary>();
          dom.forEach(element => {
            dict.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
          });
          this.domicilisPropietariDictionary.next(dict);
          if (secureIdDom !== undefined) {
            this.comboDomPropietari.markAsTouched();
            this.comboDomPropietari.setSelect(secureIdDom);
          }
        }
      } else {
        this.comboDomConductor.resetShowingOptions();
        if (dom === undefined || dom === null || dom.length === 0) {
          this.domicilisConductorDictionary = new BehaviorSubject(new Array<Dictionary>());
        } else {
          this.domicilisConductorDictionary = new BehaviorSubject(new Array<Dictionary>());
          const dict = new Array<Dictionary>();
          dom.forEach(element => {
            dict.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
          });
          this.domicilisConductorDictionary.next(dict);
          if (secureIdDom !== undefined) {
            this.comboDomConductor.markAsTouched();
            this.comboDomConductor.setSelect(secureIdDom);
          }
        }

      }
    });
  }

  consultarConductor() {
    if (this.denunciaDetail.dni_conductor !== undefined
      && this.denunciaDetail.dni_conductor !== null
      && this.denunciaDetail.dni_conductor !== '') {
      this.personesService.findPersonaByDniNif(this.denunciaDetail.dni_conductor).subscribe((x: Persona[]) => {
        if (x !== undefined && x !== null && x.length > 0) {
          if (x.length === 1) {
            this.denunciaDetail.secure_id_conductor = x[0].secure_id;
            this.denunciaDetail.dni_conductor = x[0].dni_pasp;
            this.denunciaDetail.nom_conductor = x[0].nomComplet;
            this.skip = true;
            // TODO: Domicili
            this.getDomicilis(x[0].secure_id, false, x[0].SECURE_ID_DOMICILI_ACTUAL);
          } else if (x.length > 1) {
            const filter: PersonaFilter = new PersonaFilter('id', 'asc');
            filter.dni_pasp = this.denunciaDetail.dni_conductor;

            // Carraguem la taula filtrada per dni
            const data: ComponentDialog = Builder.newComponentDialog(true, false, '', filter);
            const ref = this.dialog.open(PersonesComponent, {
              width: '80%',
              height: '90%',
              closeOnNavigation: false,
              autoFocus: true,
              data: data
            });

            ref.afterClosed().subscribe(async resp => {
              if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                this.denunciaDetail.secure_id_conductor = resp.codi;
                this.denunciaDetail.secure_id_conductor = resp.secure_id;
                this.denunciaDetail.nom_conductor = resp.nomComplet;
                this.denunciaDetail.dni_conductor = resp.dni_pasp;
                this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                // : nomComplet, dni_pasp: dni_pasp
                this.getDomicilis(this.denunciaDetail.secure_id_conductor, false, resp.secureIdDom);
                this.denunciaDetail.secure_id_domiciliConductor = resp.secureIdDom;
                this.skip = true;
              } else {
                this.denunciaDetail.secure_id_conductor = null;
                this.denunciaDetail.secure_id_conductor = null;
                this.denunciaDetail.nom_conductor = null;
                this.denunciaDetail.dni_conductor = null;
                this.denunciaDetail.secure_id_domiciliConductor = null;
                // : nomComplet, dni_pasp: dni_pasp
                // this.getDomicilis(this.denunciaDetail.secure_id_conductor, false, resp.secureIdDom);
                this.domicilisConductorDictionary = new BehaviorSubject(new Array<Dictionary>());
                this.denunciaDetail.secure_id_domiciliConductor = null;
              }
            });

          }

        } else {
          // Obrim dialeg per crear persona
          this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
            .then((confirmed) => {
              if (confirmed) {
                // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
                const ref = this.dialog.open(PersonesEditorComponent, {
                  width: '80%',
                  height: '90%',
                  closeOnNavigation: false,
                  autoFocus: true,
                  data: {
                    isDialog: true,
                    isReadOnly: false,
                    id: null,
                    filter: null,
                    dni: this.denunciaDetail.dni_conductor
                  }
                });

                ref.afterClosed().subscribe(async data => {
                  if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                    this.denunciaDetail.secure_id_conductor = data.codi;
                    this.denunciaDetail.secure_id_conductor = data.secure_id;
                    this.denunciaDetail.nom_conductor = data.nomComplet;
                    this.denunciaDetail.dni_conductor = data.dni_pasp;
                    this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
                    // : nomComplet, dni_pasp: dni_pasp
                    this.getDomicilis(this.denunciaDetail.secure_id_conductor, false, data.secureIdDom);
                    this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
                    this.skip = true;

                  }
                });

              } else {
                this.denunciaDetail.nom_conductor = '';
                this.domicilisConductorDictionary = new BehaviorSubject(new Array<Dictionary>());
              }
            });
        }
      });
    } else {
      // Obrim dialeg per crear persona
      // this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
      // .then((confirmed) => {
      //     if (confirmed) {
      // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
      const ref = this.dialog.open(PersonesComponent, {
        width: '80%',
        height: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          isDialog: true,
          isReadOnly: false,
          id: null,
          filter: null
        }
        //,dni: this.denunciaDetail.dni_conductor}
      });
      ref.afterClosed().subscribe(async data => {
        if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
          this.denunciaDetail.secure_id_conductor = data.codi;
          this.denunciaDetail.secure_id_conductor = data.secure_id;
          this.denunciaDetail.nom_conductor = data.nomComplet;
          this.denunciaDetail.dni_conductor = data.dni_pasp;
          this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
          // : nomComplet, dni_pasp: dni_pasp
          this.getDomicilis(this.denunciaDetail.secure_id_conductor, false, data.secureIdDom);
          this.denunciaDetail.secure_id_domiciliConductor = data.secureIdDom;
          this.skip = true;

        }
      });
      // } else {
      //   this.denunciaDetail.nom_conductor = '';
      //   this.domicilisConductorDictionary =  new BehaviorSubject(new Array<Dictionary>());
      // }
      //});
    }

  }

  consultarPropietari() {
    if (this.denunciaDetail.dni_propietari !== undefined
      && this.denunciaDetail.dni_propietari !== null
      && this.denunciaDetail.dni_propietari !== '') {
      this.personesService.findPersonaByDniNif(this.denunciaDetail.dni_propietari).subscribe((x: Persona[]) => {
        if (x !== undefined && x !== null && x.length > 0) {
          if (x.length === 1) {
            this.denunciaDetail.secure_id_propietari = x[0].secure_id;
            this.denunciaDetail.dni_propietari = x[0].dni_pasp;
            this.denunciaDetail.nom_propietari = x[0].nomComplet;
            this.skip = true;
            this.getDomicilis(x[0].secure_id, true, x[0].SECURE_ID_DOMICILI_ACTUAL);
          } else if (x.length > 1) {
            const filter: PersonaFilter = new PersonaFilter('id', 'asc');
            filter.dni_pasp = this.denunciaDetail.dni_propietari;
            // Carraguem la taula filtrada per dni
            const data: ComponentDialog = Builder.newComponentDialog(true, false, '', filter);
            const ref = this.dialog.open(PersonesComponent, {
              width: '80%',
              height: '90%',
              closeOnNavigation: false,
              autoFocus: true,
              data: data
            });

            ref.afterClosed().subscribe(async resp => {
              if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                this.denunciaDetail.secure_id_propietari = resp.codi;
                this.denunciaDetail.secure_id_propietari = resp.secure_id;
                this.denunciaDetail.nom_propietari = resp.nomComplet;
                this.denunciaDetail.dni_propietari = resp.dni_pasp;
                this.denunciaDetail.secure_id_domiciliPropietari = resp.secureIdDom;
                // : nomComplet, dni_pasp: dni_pasp
                this.getDomicilis(this.denunciaDetail.secure_id_conductor, true, resp.secureIdDom);
                this.denunciaDetail.secure_id_domiciliPropietari = resp.secureIdDom;
                this.skip = true;

              }
            });
          }
        } else {
          // Obrim dialeg per crear persona
          this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
            .then((confirmed) => {
              if (confirmed) {
                // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
                const ref = this.dialog.open(PersonesEditorComponent, {
                  width: '80%',
                  height: '90%',
                  closeOnNavigation: false,
                  autoFocus: true,
                  data: {
                    isDialog: true,
                    isReadOnly: false,
                    id: null,
                    filter: null,
                    dni: this.denunciaDetail.dni_propietari
                  }
                });

                ref.afterClosed().subscribe(async data => {
                  if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                    this.denunciaDetail.secure_id_propietari = data.codi;
                    this.denunciaDetail.secure_id_propietari = data.secure_id;
                    this.denunciaDetail.nom_propietari = data.nomComplet;
                    this.denunciaDetail.dni_propietari = data.dni_pasp;
                    this.denunciaDetail.secure_id_domiciliPropietari = data.secureIdDom;
                    // : nomComplet, dni_pasp: dni_pasp
                    this.getDomicilis(this.denunciaDetail.secure_id_domiciliPropietari, true, data.secureIdDom);
                    this.denunciaDetail.secure_id_domiciliPropietari = data.secureIdDom;
                    this.skip = true;

                  }
                });
              } else {
                this.denunciaDetail.nom_propietari = '';
                this.domicilisPropietariDictionary = new BehaviorSubject(new Array<Dictionary>());
              }
            });
        }
      });
    } else {
      // Obrim dialeg per crear persona
      // this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
      // .then((confirmed) => {
      //   if (confirmed) {
      // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
      const ref = this.dialog.open(PersonesComponent, {
        width: '80%',
        height: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          isDialog: true,
          isReadOnly: false,
          id: null,
          filter: null
        }
        //,dni: this.denunciaDetail.dni_propietari}
      });
      ref.afterClosed().subscribe(async data => {
        if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
          this.denunciaDetail.secure_id_propietari = data.codi;
          this.denunciaDetail.secure_id_propietari = data.secure_id;
          this.denunciaDetail.nom_propietari = data.nomComplet;
          this.denunciaDetail.dni_propietari = data.dni_pasp;
          this.denunciaDetail.secure_id_domiciliPropietari = data.secureIdDom;
          // : nomComplet, dni_pasp: dni_pasp
          this.getDomicilis(this.denunciaDetail.secure_id_propietari, true, data.secureIdDom);
          this.denunciaDetail.secure_id_domiciliPropietari = data.secureIdDom;
          this.skip = true;

        }
      });

      // } else {
      //   this.denunciaDetail.nom_propietari = '';
      //   this.domicilisPropietariDictionary =  new BehaviorSubject(new Array<Dictionary>());
      // }
      //});
    }
  }

  consultarEstabliment() {
    if (this.denunciaDetail.EstablecimientoCIF !== undefined
      && this.denunciaDetail.EstablecimientoCIF !== null
      && this.denunciaDetail.EstablecimientoCIF !== '') {
      this.personesService.findEmpresaByCif(this.denunciaDetail.EstablecimientoCIF).subscribe((x: Persona[]) => {
        if (x !== undefined && x !== null && x.length > 0) {
          if (x.length === 1) {
            this.denunciaDetail.secure_id_Establecimiento = x[0].secure_id;
            this.denunciaDetail.EstablecimientoCIF = x[0].dni_pasp;
            this.denunciaDetail.EstablecimientoRazonSocial = x[0].nomComplet;
            this.skip = true;
          } else if (x.length > 1) {
            const filter: PersonaFilter = new PersonaFilter('id', 'asc');
            filter.dni_pasp = this.denunciaDetail.EstablecimientoCIF;
            filter.juridica = true;
            // Carraguem la taula filtrada per dni
            const data: ComponentDialog = Builder.newComponentDialog(true, false, '', filter);
            const ref = this.dialog.open(PersonesComponent, {
              width: '80%',
              height: '90%',
              closeOnNavigation: false,
              autoFocus: true,
              data: data
            });

            ref.afterClosed().subscribe(async resp => {
              if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                this.denunciaDetail.secure_id_Establecimiento = resp.codi;
                this.denunciaDetail.secure_id_Establecimiento = resp.secure_id;
                this.denunciaDetail.EstablecimientoRazonSocial = resp.nomComplet;
                this.denunciaDetail.EstablecimientoCIF = resp.dni_pasp;
                this.skip = true;

              }
            });
          }
        } else {
          // Obrim dialeg per crear persona
          this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
            .then((confirmed) => {
              if (confirmed) {
                // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
                const ref = this.dialog.open(PersonesEditorComponent, {
                  width: '80%',
                  height: '90%',
                  closeOnNavigation: false,
                  autoFocus: true,
                  data: {
                    isDialog: true,
                    isReadOnly: false,
                    id: null,
                    filter: null,
                    dni: this.denunciaDetail.EstablecimientoCIF,
                    juridica: true
                  }
                });

                ref.afterClosed().subscribe(async data => {
                  if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                    this.denunciaDetail.secure_id_Establecimiento = data.codi;
                    this.denunciaDetail.secure_id_Establecimiento = data.secure_id;
                    this.denunciaDetail.EstablecimientoRazonSocial = data.nomComplet;
                    this.denunciaDetail.EstablecimientoCIF = data.dni_pasp;
                    this.skip = true;

                  }
                });
              } else {
                this.denunciaDetail.EstablecimientoRazonSocial = '';
              }
            });
        }
      });
    } else {
      // Obrim dialeg per crear persona
      // this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
      // .then((confirmed) => {
      //   if (confirmed) {
      // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
      var filter = new PersonaFilter('id', 'asc')
      filter.juridica = true;
      const ref = this.dialog.open(PersonesComponent, {
        width: '80%',
        height: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          isDialog: true,
          isReadOnly: false,
          id: null,
          filter: filter
        }
      });
      ref.afterClosed().subscribe(async data => {
        if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
          this.denunciaDetail.secure_id_Establecimiento = data.codi;
          this.denunciaDetail.secure_id_Establecimiento = data.secure_id;
          this.denunciaDetail.EstablecimientoRazonSocial = data.nomComplet;
          this.denunciaDetail.EstablecimientoCIF = data.dni_pasp;
          this.skip = true;

        }
      });

      //   } else {
      //     this.denunciaDetail.EstablecimientoRazonSocial = '';            
      //   }
      // });
    }
  }


  consultarTreballador() {
    debugger;
    if (this.denunciaDetail.EstablecimientoTrabajadorNIE !== undefined
      && this.denunciaDetail.EstablecimientoTrabajadorNIE !== null
      && this.denunciaDetail.EstablecimientoTrabajadorNIE !== '') {
      this.personesService.findPersonaByDniNif(this.denunciaDetail.EstablecimientoTrabajadorNIE).subscribe((x: Persona[]) => {
        if (x !== undefined && x !== null && x.length > 0) {
          if (x.length === 1) {
            this.denunciaDetail.secure_id_EstablecimientoTrabajador = x[0].secure_id;
            this.denunciaDetail.EstablecimientoTrabajadorNIE = x[0].dni_pasp;
            this.denunciaDetail.EstablecimientoTrabajadorNombre = x[0].nomComplet;
            this.skip = true;
          } else if (x.length > 1) {
            const filter: PersonaFilter = new PersonaFilter('id', 'asc');
            filter.dni_pasp = this.denunciaDetail.EstablecimientoTrabajadorNIE;
            // Carraguem la taula filtrada per dni
            const data: ComponentDialog = Builder.newComponentDialog(true, false, '', filter);
            const ref = this.dialog.open(PersonesComponent, {
              width: '80%',
              height: '90%',
              closeOnNavigation: false,
              autoFocus: true,
              data: data
            });

            ref.afterClosed().subscribe(async resp => {
              if (resp.codi !== undefined && resp.codi !== null && resp.codi !== '') {
                this.denunciaDetail.secure_id_EstablecimientoTrabajador = resp.codi;
                this.denunciaDetail.secure_id_EstablecimientoTrabajador = resp.secure_id;
                this.denunciaDetail.EstablecimientoTrabajadorNombre = resp.nomComplet;
                this.denunciaDetail.EstablecimientoTrabajadorNIE = resp.dni_pasp;
                this.skip = true;

              }
            });
          }
        } else {
          // Obrim dialeg per crear persona
          this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
            .then((confirmed) => {
              if (confirmed) {
                // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
                const ref = this.dialog.open(PersonesEditorComponent, {
                  width: '80%',
                  height: '90%',
                  closeOnNavigation: false,
                  autoFocus: true,
                  data: {
                    isDialog: true,
                    isReadOnly: false,
                    id: null,
                    filter: null,
                    dni: this.denunciaDetail.EstablecimientoTrabajadorNIE
                  }
                });

                ref.afterClosed().subscribe(async data => {
                  if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                    this.denunciaDetail.secure_id_EstablecimientoTrabajador = data.codi;
                    this.denunciaDetail.secure_id_EstablecimientoTrabajador = data.secure_id;
                    this.denunciaDetail.EstablecimientoTrabajadorNombre = data.nomComplet;
                    this.denunciaDetail.EstablecimientoTrabajadorNIE = data.dni_pasp;
                    this.skip = true;

                  }
                });
              } else {
                this.denunciaDetail.EstablecimientoTrabajadorNombre = '';
              }
            });
        }
      });
    } else {
      // Obrim dialeg per crear persona
      // this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
      // .then((confirmed) => {
      //   if (confirmed) {
      // const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
      const ref = this.dialog.open(PersonesComponent, {
        width: '80%',
        height: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          isDialog: true,
          isReadOnly: false,
          id: null,
          filter: null
        }
      });
      ref.afterClosed().subscribe(async data => {
        if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
          this.denunciaDetail.secure_id_EstablecimientoTrabajador = data.codi;
          this.denunciaDetail.secure_id_EstablecimientoTrabajador = data.secure_id;
          this.denunciaDetail.EstablecimientoTrabajadorNombre = data.nomComplet;
          this.denunciaDetail.EstablecimientoTrabajadorNIE = data.dni_pasp;
          this.skip = true;

        }
      });

      //   } else {
      //     this.denunciaDetail.EstablecimientoTrabajadorNombre = '';            
      //   }
      // });
    }
  }

  /* #endregion */

  /* #region  OTHERS */

  // Calculem la data de descompte de la denuncia
  calcularDataDescompte() {
    if (this.denunciaDetail.data !== null && this.denunciaDetail.data !== undefined) {
      this.denunciaDetail.dataDescompte = new Date();
      this.denunciaDetail.dataDescompte.setDate(this.denunciaDetail.data.getDate() + this.diesDescompte);
    }
  }

  calcularImportDescompte() {
    if (this.denunciaDetail.import !== undefined && this.denunciaDetail.import !== null
      && this.denunciaDetail.porcentajeDescuento !== undefined && this.denunciaDetail.porcentajeDescuento !== null) {
      this.denunciaDetail.importDescompte = (this.denunciaDetail.import * (1 - (this.denunciaDetail.porcentajeDescuento / 100)));
    }
  }

  /* #region */

  HandleChange(persona: string) {
    if (!this.skip) {
      if (persona === 'conductor' && this.denunciaDetail.dni_conductor !== this.conductorPreviousDNI) {
        this.denunciaDetail.secure_id_conductor = null;
        this.denunciaDetail.nom_conductor = null;
        this.denunciaDetail.secure_id_domiciliConductor = null;
        this.domicilisConductorDictionary = new BehaviorSubject(new Array<Dictionary>());
        this.denunciaDetail.secure_id_domiciliConductor = null;
        this.conductorPreviousDNI = this.denunciaDetail.dni_conductor;
      } else if (persona === 'propietari' && this.denunciaDetail.dni_propietari !== this.propietariPreviousDNI) {
        this.denunciaDetail.secure_id_propietari = null;
        this.denunciaDetail.nom_propietari = null;
        this.denunciaDetail.secure_id_domiciliPropietari = null;
        this.domicilisPropietariDictionary = new BehaviorSubject(new Array<Dictionary>());
        this.denunciaDetail.secure_id_domiciliPropietari = null;
      } else if (persona === 'treballador' && this.denunciaDetail.EstablecimientoTrabajadorNIE !== this.EstablecimientoTrabajadorPreviousNIE) {
        this.denunciaDetail.secure_id_EstablecimientoTrabajador = null;
        this.denunciaDetail.EstablecimientoTrabajadorNombre = null;
        this.EstablecimientoTrabajadorPreviousNIE = this.denunciaDetail.EstablecimientoTrabajadorNIE;
      } else if (persona === 'establiment' && this.denunciaDetail.EstablecimientoCIF !== this.EstablecimientoPreviousCIF) {
        this.denunciaDetail.secure_id_Establecimiento = null;
        this.EstablecimientoPreviousCIF = this.denunciaDetail.EstablecimientoCIF;
        this.denunciaDetail.EstablecimientoRazonSocial = null;
      }
      this.skip = false;
    }
  }
}
