import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { GruaTecnicsFilter } from './models/grua-tecnics-filter';
import { GruaTecnicsTableComponent } from './components/grua-tecnics-table/grua-tecnics-table.component';
import { GruaTecnicsEditorComponent } from './components/grua-tecnics-editor/grua-tecnics-editor.component';
import { GruaTecnicsFilterComponent } from './components/grua-tecnics-filter/grua-tecnics-filter.component';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { CarrersComponent } from '../../carrers/pages/carrers/carrers.component';

@Component({
    selector: 'app-grua-tecnics',
    templateUrl: 'grua-tecnics.component.html',
    styleUrls: ['grua-tecnics.component.scss']
})
export class GruaTecnicsComponent implements OnInit, AfterViewInit {
    filterData: GruaTecnicsFilter;
    @ViewChild(GruaTecnicsTableComponent) gruaTecnicsTable: GruaTecnicsTableComponent;

    dataDialog: ComponentDialog = Builder.newComponentDialog();
    constructor(
      private dialog: MatDialog,
      public auth: AuthorizationService,
      private i18n: I18n,
      private not: NotificationService,
      public thisDialogRef: MatDialogRef<CarrersComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
      ) {
        this.filterData = new GruaTecnicsFilter('codi', 'asc');
        if (data !== undefined) {
          this.dataDialog = data;
        }
      }

    ngOnInit(): void {
      this.auth.UserCanSeePage(this.auth.Pages.GruaTecnics);
      this.gruaTecnicsTable.itemClicked.subscribe(secure_id => {
        this.update(secure_id);
      });
      this.gruaTecnicsTable.itemSelected.subscribe(codi => {
        this.thisDialogRef.close(codi);
      });
    }

    ngAfterViewInit() {
      this.not.titleChange(this.i18n('Tècnics'));
    }

    edit() {
      const idSeleccionat: string = this.gruaTecnicsTable.displayedItems['checkedItems'][0];
      this.update(idSeleccionat);
    }

    create() {
      this.update(null);
    }

    update(secure_id: string) {
      const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

      const ref = this.dialog.open(GruaTecnicsEditorComponent, {
        width: '40%',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });

      ref.afterClosed().subscribe(updated => {
        if (updated) { this.gruaTecnicsTable.load(); }
      });
    }

    refresh() {
      this.gruaTecnicsTable.load();
    }

    onFilterClicked() {
      const ref = this.dialog.open(GruaTecnicsFilterComponent, {
        width: '40%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          'filter': this.gruaTecnicsTable.filter
        }
      });

      ref.componentInstance.notify.subscribe((filter: GruaTecnicsFilter) => {
        this.gruaTecnicsTable.filter.set(filter);
        this.gruaTecnicsTable.load();
      });
    }

    onRowSelected(somid) {
      this.update(somid);
    }

    generateExcel() {
      this.gruaTecnicsTable.generateExcel();
    }
}
