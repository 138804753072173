var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { DatePipe } from '@angular/common';
import * as i0 from "@angular/core";
var defaultSortParam = 'data';
var defaultSortDirection = 'desc';
var BonosFilterService = /** @class */ (function (_super) {
    __extends(BonosFilterService, _super);
    function BonosFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BonosFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        var datepipe = new DatePipe('es-ES');
        return new HttpParams()
            .append('matricula', filter.matricula)
            .append('fechaInicio', Reflection.empty(filter.fechaInicio) ? null : datepipe.transform(filter.fechaInicio, 'yyyyMMdd000000'))
            .append('fechaFin', Reflection.empty(filter.fechaFin) ? null : datepipe.transform(filter.fechaFin, 'yyyyMMdd235959'))
            .append('tipo', filter.tipo)
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    BonosFilterService.prototype.createLoadParams = function (content) {
        return new HttpParams()
            .append('content', content ? content : '');
    };
    BonosFilterService.ngInjectableDef = i0.defineInjectable({ factory: function BonosFilterService_Factory() { return new BonosFilterService(); }, token: BonosFilterService, providedIn: "root" });
    return BonosFilterService;
}(CustomFilterService));
export { BonosFilterService };
