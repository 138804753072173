import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs-compat';
import { PuntosDgtFilter } from '../../models/puntos-dt-filter';

@Component({
  selector: 'app-envio-puntos-dgt-filter',
  templateUrl: './envio-puntos-dgt-filter.component.html',
  styleUrls: ['./envio-puntos-dgt-filter.component.scss']
})
export class EnvioPuntosDgtFilterComponent implements OnInit {
  subscription: Subscription;
  filter: PuntosDgtFilter;

  @Output() notify: EventEmitter<PuntosDgtFilter> = new EventEmitter<PuntosDgtFilter>();
  constructor( 
    public thisDialogRef: MatDialogRef<EnvioPuntosDgtFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter;
   }

  
   ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }
}
