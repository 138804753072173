import { MotiusAnulacioFilter } from './../../models/motius-anulacio-filter';
import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-motius-anulacio-filter',
  templateUrl: './motius-anulacio-filter.component.html',
  styleUrls: ['./motius-anulacio-filter.component.scss']
})
export class MotiusAnulacioFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: MotiusAnulacioFilter;

  @Output() notify: EventEmitter<MotiusAnulacioFilter> = new EventEmitter<MotiusAnulacioFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<MotiusAnulacioFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }
}
