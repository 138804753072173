/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tipus-tiquets-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/dialog/filter-header/filter-header.component.ngfactory";
import * as i3 from "../../../../../shared/components/dialog/filter-header/filter-header.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i6 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i7 from "../../../../../shared/services/validation/validation.service";
import * as i8 from "../../../../../shared/components/filter/filter-text-edit/filter-text-edit.component.ngfactory";
import * as i9 from "../../../../../shared/components/filter/filter-text-edit/filter-text-edit.component";
import * as i10 from "../../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i11 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i12 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i13 from "../../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i14 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i15 from "./tipus-tiquets-filter.component";
import * as i16 from "@ngx-translate/i18n-polyfill";
var styles_TipusTiquetsFilterComponent = [i0.styles];
var RenderType_TipusTiquetsFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TipusTiquetsFilterComponent, data: {} });
export { RenderType_TipusTiquetsFilterComponent as RenderType_TipusTiquetsFilterComponent };
export function View_TipusTiquetsFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-header", [["title", "Filtrar tipus de tiquet"]], null, [[null, "apply"], [null, "reset"], [null, "dismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("apply" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (_co.reset() !== false);
        ad = (pd_1 && ad);
    } if (("dismiss" === en)) {
        var pd_2 = (_co.dismiss() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_FilterHeaderComponent_0, i2.RenderType_FilterHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.FilterHeaderComponent, [i4.MatDialogRef], { title: [0, "title"] }, { apply: "apply", reset: "reset", dismiss: "dismiss" }), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-form-text-edit", [["class", "col-sm-3"], ["id", "tipus-tiquets-filter-codi"], ["label", "Codi"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.codi = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FormTextEditComponent_0, i5.RenderType_FormTextEditComponent)), i1.ɵdid(5, 4308992, null, 0, i6.FormTextEditComponent, [i7.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], id: [3, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-filter-text-edit", [["class", "col-sm-9"], ["id", "tipus-tiquets-filter-descripcio"], ["label", "Descripci\u00F3"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.descripcio = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FilterTextEditComponent_0, i8.RenderType_FilterTextEditComponent)), i1.ɵdid(7, 4308992, null, 0, i9.FilterTextEditComponent, [i7.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], id: [3, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 3, "app-form-date-edit", [["class", "col-sm-5"], ["id", "tipus-tiquets-filter-dataIni"], ["label", "Data Inici"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.dataInici = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_FormDateEditComponent_0, i10.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i11.DateTimeAdapter, i12.MomentDateTimeAdapter, [i11.OWL_DATE_TIME_LOCALE]), i1.ɵdid(11, 114688, null, 0, i13.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"], id: [3, "id"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i14.OWL_DATE_TIME_FORMATS, i13.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(13, 16777216, null, null, 3, "app-form-date-edit", [["class", "col-sm-5"], ["id", "tipus-tiquets-filter-dataFi"], ["label", "Data Fi"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.dataFi = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_FormDateEditComponent_0, i10.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i11.DateTimeAdapter, i12.MomentDateTimeAdapter, [i11.OWL_DATE_TIME_LOCALE]), i1.ɵdid(15, 114688, null, 0, i13.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"], id: [3, "id"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i14.OWL_DATE_TIME_FORMATS, i13.MY_CUSTOM_FORMATS, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Filtrar tipus de tiquet"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "Codi"; var currVal_2 = _co.filter.codi; var currVal_3 = "col-sm-3"; var currVal_4 = "tipus-tiquets-filter-codi"; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "Descripci\u00F3"; var currVal_6 = _co.filter.descripcio; var currVal_7 = "col-sm-9"; var currVal_8 = "tipus-tiquets-filter-descripcio"; _ck(_v, 7, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = "Data Inici"; var currVal_10 = _co.filter.dataInici; var currVal_11 = "col-sm-5"; var currVal_12 = "tipus-tiquets-filter-dataIni"; _ck(_v, 11, 0, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = "Data Fi"; var currVal_14 = _co.filter.dataFi; var currVal_15 = "col-sm-5"; var currVal_16 = "tipus-tiquets-filter-dataFi"; _ck(_v, 15, 0, currVal_13, currVal_14, currVal_15, currVal_16); }, null); }
export function View_TipusTiquetsFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tipus-tiquets-filter", [], null, null, null, View_TipusTiquetsFilterComponent_0, RenderType_TipusTiquetsFilterComponent)), i1.ɵdid(1, 245760, null, 0, i15.TipusTiquetsFilterComponent, [i4.MatDialogRef, i16.I18n, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TipusTiquetsFilterComponentNgFactory = i1.ɵccf("app-tipus-tiquets-filter", i15.TipusTiquetsFilterComponent, View_TipusTiquetsFilterComponent_Host_0, {}, { notify: "notify" }, []);
export { TipusTiquetsFilterComponentNgFactory as TipusTiquetsFilterComponentNgFactory };
