import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';

import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class BoeService {
  

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

   getConfiguracionBoe(): Observable<any> {
     if (this.authGuard.canActivate()) {
       return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetConfiguracionBoe'], null);
     }
   }
   

   setConfiguracionBoe(configBoe: any): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetConfiguracionBoe'], configBoe);
    }
  }
  getDenuncias(filter: any): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDenunciasBoe'], filter);
    }
  }
  generateFileBoe(filter: any): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGeneratePublishBoe'], filter);
    }
  }
  getDetail(secureId: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDetailConfirmacion'].replace('{secureId}',secureId), null);
    }
  }
}
