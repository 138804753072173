import { HttpSecureClient } from '../http/httpSecureClient';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuardService } from '../authentication/auth-guard.service';
import { api } from 'path-shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import * as i0 from "@angular/core";
import * as i1 from "../http/httpSecureClient";
import * as i2 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i3 from "../authentication/auth-guard.service";
var InstitucionsService = /** @class */ (function () {
    function InstitucionsService(http, cookieService, authGuard) {
        this.http = http;
        this.cookieService = cookieService;
        this.authGuard = authGuard;
    }
    InstitucionsService.prototype.getInstitucions = function () {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlInstitucionesByUserAuthenticated'];
            return this.http.get(url, null);
        }
    };
    InstitucionsService.prototype.getAllInstitucions = function () {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlInstituciones'];
            return this.http.get(url, null);
        }
    };
    InstitucionsService.prototype.setNewIdInstitution = function (id) {
        sessionStorage.setItem('IdInstitucion', id);
        localStorage.setItem('IdInstitucion', id);
        this.cookieService.set('InstitucioSeleccionada', id);
        location.reload();
    };
    InstitucionsService.prototype.getCurrentInstitution = function () {
        var id = sessionStorage.getItem('IdInstitucion');
        return id;
    };
    InstitucionsService.prototype.getInstitution = function () {
        var inst = sessionStorage.getItem('currentInst');
        if (inst !== '' && inst !== undefined && inst !== null) {
            var institution = JSON.parse(inst);
            return institution;
        }
    };
    InstitucionsService.ngInjectableDef = i0.defineInjectable({ factory: function InstitucionsService_Factory() { return new InstitucionsService(i0.inject(i1.HttpSecureClient), i0.inject(i2.CookieService), i0.inject(i3.AuthGuardService)); }, token: InstitucionsService, providedIn: "root" });
    return InstitucionsService;
}());
export { InstitucionsService };
