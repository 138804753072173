<app-filter-header title="Filtrar bonos residentes" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body" *ngIf="this.info!==undefined">
  <div class="row">
    <app-form-text-edit id="bonos-filter-matricula" class="col-sm-3" i18n-label label="Matrícula" [(value)]="filter.matricula" ></app-form-text-edit>
    <app-form-date-edit id="bonos-filter-dataIni" class="col-sm-3" i18n-label label="Data Inici" [(value)]="filter.dataIni" type="calendar"></app-form-date-edit>
    <app-form-date-edit id="bonos-filter-dataFi" class="col-sm-3" i18n-label label="Data Fi"  [(value)]="filter.dataFi" type="calendar"></app-form-date-edit>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title" i18n>Prepago</h5>
          <app-form-combo-edit label="Tipo resultado prepago" i18n-label class="col-sm-4" [items]="this.info.tipoResultadoPrepago" [(value)]="filter.tipResPrepago" valueField="id" textField="descripcio"></app-form-combo-edit>
          <app-form-combo-edit label="Tipo bono" i18n-label class="col-sm-4" [items]="this.info.tiposBono" [(value)]="filter.tipBono" valueField="id" textField="descripcio"></app-form-combo-edit>
        </div>
      </div>
    </div>
    <div class="col-6">
      <app-form-radio-group title="Pago" i18n-title [labels]="this.pagoLabels" [values]="this.pagoLabels" [(value)]="this.filter.pago"></app-form-radio-group>
    </div>
  </div>  
</div>