import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild, SimpleChanges, AfterViewInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatSort, MatDialogRef, MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { ActivatedRoute, Router } from '@angular/router';
import { Matricula } from 'app/sections/ciutat/matricules/models/matricula.model';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatriculaFilter } from 'app/sections/ciutat/matricules/models/matricula-filter';
import { MatriculesService } from 'app/sections/ciutat/matricules/services/matricules.service';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { api } from 'path-shared/configuration/urls';
import { MatriculaFilterService } from 'app/sections/ciutat/matricules/services/matricules-filter.service';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { MessagingService } from 'path-shared/services/messaging/messaging.service';
import { ExcelDownloadService } from 'path-shared/services/excel/excel-download.service';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { TipusMatriculesService } from 'app/sections/ciutat/tipus-matricules/services/tipus-matricules.service';

@Component({
  selector: 'app-matricules-table',
  templateUrl: './matricules-table.component.html',
  styleUrls: ['./matricules-table.component.scss']
})

export class MatriculesTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: Matricula[];
  dataSource: CustomDataSource<Matricula, MatriculaFilter>;
  highlightedRows = [];
  filter: MatriculaFilter = new MatriculaFilter('tipus', 'asc');
  filterPage: MatriculaFilter;
  displayedItems = [];
  public tipusMatricules = Array();

  @Input() selectButton: boolean;
  @Input() updatedFilterData: MatriculaFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['matricula', 'tipus',  'marca', 'model', 'observacions', 'dataInici', 'dataFi'];

  constructor(
    private matriculesService: MatriculesService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n,
    private tipusMatriculesService: TipusMatriculesService,
  ) {
  }

  ngOnInit() {
    if (this.selectButton) {
      this.displayedColumns = ['incorpora'].concat(this.displayedColumns);
    }
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Matricula, MatriculaFilter>(this.paginator, new MatriculaFilterService(), 'urlgetmatriculas', 'urlmatriculapaginationcount');
    this.dataSource.subject.subscribe((data) => {

        data.forEach(item => {
          this.matriculesService.displayedItems[item.SECURE_ID] = {
            id: item.SECURE_ID,
            checked: false
          };
        });

      this.displayedItems = this.matriculesService.displayedItems;
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('matricules-filter', this.filter); }
      }
    });

    if (!this.selectButton) { this.filterService.loadFilter('matricules-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);

    this.tipusMatriculesService.getAll().subscribe(data => {
      data.forEach(element => {
        this.tipusMatricules[element.codi] = element.descripcio;

      });
    });
  }

  ngAfterViewInit() {
      // reset the paginator after sorting
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.filter.paginator.set(this.sort, this.paginator);
        this.filter.updated();
      } );
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row.SECURE_ID);
  }

  selectCurrent(codi) {
    this.itemSelected.next(codi);
  }

  getTipusMatricula(id) {

    if (id === null || id === undefined) {
      return '';
    } else {
      return this.tipusMatricules[id];
    }
  }

  generateExcel(): void {
    const filter = this.filter;
    this.dialog.open(DownloadExcelDialogComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: Builder.newDownloadExcelDialogData(this.matriculesService, filter, this.i18n('Matrícules'), this.sort.direction, this.sort.active)
    });
  }

}
