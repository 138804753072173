/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./iniciar-turno.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component.ngfactory";
import * as i4 from "../../../../../../shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component";
import * as i5 from "ngx-toastr";
import * as i6 from "./iniciar-turno.component";
import * as i7 from "../../services/turnos-service";
import * as i8 from "../../../../../usuaris/services/usuaris.service";
import * as i9 from "../../../../../../shared/services/eventEmitter/event-emitter-service";
import * as i10 from "@angular/material/dialog";
var styles_IniciarTurnoComponent = [i0.styles];
var RenderType_IniciarTurnoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IniciarTurnoComponent, data: {} });
export { RenderType_IniciarTurnoComponent as RenderType_IniciarTurnoComponent };
function View_IniciarTurnoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dialogData.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_IniciarTurnoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { comboTecnics: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IniciarTurnoComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-combo-box-input-autocomplete", [["class", "center-horizontal col-sm-2"], ["label", "Torn"], ["required", "true"]], null, [[null, "valorSeleccionado"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valorSeleccionado" === en)) {
        var pd_0 = (_co.turnoSeleccionado($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ComboBoxInputAutocompleteComponent_0, i3.RenderType_ComboBoxInputAutocompleteComponent)), i1.ɵdid(4, 114688, [[1, 4], ["comboTurno", 4]], 0, i4.ComboBoxInputAutocompleteComponent, [i5.ToastrService], { valorAutocomplete: [0, "valorAutocomplete"], options: [1, "options"], label: [2, "label"], required: [3, "required"] }, { valorSeleccionado: "valorSeleccionado" }), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "button", [["class", "btn btn-success button-not-allowed btn-lg"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.iniciarTurno() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fas fa-hourglass-start"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Iniciar torn"])), (_l()(), i1.ɵted(-1, null, [" \u00A0 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((((_co.dialogData !== undefined) && (_co.dialogData !== null)) && (_co.dialogData.title !== undefined)) && (_co.dialogData.title !== null)) && (_co.dialogData.title !== "")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.valorAutoCompleteTurno; var currVal_2 = _co.turnosDictionary; var currVal_3 = "Torn"; var currVal_4 = "true"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = ((_co.registroTurno.ID_TURNO === undefined) || (_co.registroTurno.ID_TURNO === null)); _ck(_v, 6, 0, currVal_5); }); }
export function View_IniciarTurnoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-iniciar-turno", [], null, null, null, View_IniciarTurnoComponent_0, RenderType_IniciarTurnoComponent)), i1.ɵdid(1, 114688, null, 0, i6.IniciarTurnoComponent, [i7.TurnosService, i8.UsuarisService, i9.EventEmitterService, i10.MatDialogRef, i10.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IniciarTurnoComponentNgFactory = i1.ɵccf("app-iniciar-turno", i6.IniciarTurnoComponent, View_IniciarTurnoComponent_Host_0, {}, {}, []);
export { IniciarTurnoComponentNgFactory as IniciarTurnoComponentNgFactory };
