import { DashboardFileDuplicate } from './../../../models/dashboard-fileDuplicate.model';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DashboardService } from 'app/sections/dash/services/dashboard.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { AppInjectorModule } from 'app/app-injector/app-injector.module';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dash-contadores-duplicados',
  templateUrl: './dash-contadores-duplicados.component.html',
  styleUrls: ['./dash-contadores-duplicados.component.scss']
})
export class DashContadoresDuplicadosComponent implements OnInit, AfterViewInit {
  protected i18n: I18n;
  public files: DashboardFileDuplicate[];
  public isContadorDup: boolean;
  sub: any;
  displayedColumns = [ 'nombre'];
  tipoFicheroActual: String;

  constructor(
    private dashService: DashboardService,
    private not: NotificationService,
    private route: ActivatedRoute) {
      this.i18n = AppInjectorModule.injector.get(I18n);
    }

  ngOnInit() {
    this.tipoFicheroActual = '';

    this.sub = this.route
    .data
    .subscribe(v => {
      this.tipoFicheroActual = v.tipoFichero;

      switch (v.tipoFichero) {
        case 'DUPLICADO': {
          this.isContadorDup = true;
          this.displayedColumns = [ 'nombre'];
          this.loadDups();
          break;
        }
        case 'ERRONEO': {
          this.isContadorDup = false;
          this.displayedColumns = ['incorpora', 'nombre'];
          this.loadErr();
          break;
        }
        case 'PENDIENTE_IMG': {
          this.isContadorDup = false;
          this.displayedColumns = [ 'incorpora', 'nombre'];
          this.loadFicherosPendienteImagen();
          break;
        }
        default: {
           // res
           break;
        }
     }
    });

  }
  ngAfterViewInit() {

  }

  SeleccionarFila(row: any) {
    row.selected = !row.selected;
  }

  areFilesSelected(): Boolean{
     return this.files !== undefined && this.files !== null && this.files.filter(x => x.selected).length > 0;
  }

  moveFiles() {
    switch (this.tipoFicheroActual) {
      case 'DUPLICADO': {
        // res
        break;
      }
      case 'ERRONEO': {
        this.dashService.reloadFilesContadoresDup(this.files.filter(x => x.selected).map(x => x.nombre)).subscribe(result => {
          this.files = [];
          result.forEach(element => {
            const f = {
              nombre: element,
              selected: false
            };

            this.files.push(f);
          });
        });
        break;
      }
      case 'PENDIENTE_IMG': {
        this.dashService.reloadFicherosPendienteImagen(this.files.filter(x => x.selected).map(x => x.nombre)).subscribe(result => {
          this.files = [];
          result.forEach(element => {
            const f = {
              nombre: element,
              selected: false
            };

            this.files.push(f);
          });
        });
        break;
      }
      default: {
        // res
        break;
      }
  }
  }

  toggleAllCheckbox(){
    this.files.forEach(x => {
      x.selected = !x.selected;
    });
  }

  loadDups(){
    this.dashService.getFilesContadoresDup().subscribe(result => {
      this.files = [];
      result.forEach(element => {
       const f= {
          nombre: element,
          selected: false
        };

        this.files.push(f);
      });
    });
  }

  loadErr(){
    this.dashService.getErrorFiles().subscribe(result => {
      this.files = [];
      result.forEach(element => {
       const f= {
          nombre: element,
          selected: false
        };

        this.files.push(f);
      });
    });
  }

  // Ficheros pendiente imágen para DeltaCar
  loadFicherosPendienteImagen() {
    this.dashService.getFicherosPendienteImagen().subscribe(result => {
      this.files = [];
      result.forEach(element => {
       const f = {
          nombre: element,
          selected: false
        };

        this.files.push(f);
      });
    });
  }

}
