import { Injectable } from '@angular/core';

@Injectable()
export class DateUtils {

    stringToDate(value: Date) {
        if (value !== null && !this.isDate (value)) {
          value = new Date(value);
        }
        return value;
    }

    isDate(value: Date) {
    return value instanceof Date;
    }
}