import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { SeguretatGrupsFilter } from '../models/seguretat-grups-filter';
import { SeguretatGrupsFilterService } from './seguretat-grups-filter.service';
import { SeguretatGrups } from '../models/seguretat-grups.model';


const defaultSortParam = 'codiGrup';
const defaultSortDirection = 'asc';

@Injectable()
export class SeguretatGrupsService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  getSeguretatGrup(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetSeguretatGrup'].replace('{secure_id}', secure_id), null);
    }
  }

  setSeguretatGrup(grup: SeguretatGrups): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetSeguretatGrup'], grup);
    }
  }

  removeSeguretatGrup(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteSeguretatGrup'].replace('{secure_id}', secure_id), null);
    }
  }

  removeSeguretatPantallaUsuario(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteSeguretatPantallaUsuario'].replace('{secure_id}', secure_id), null);
    }
  }


  getSeguretatPantallas(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetSeguretatPantallas'], null);
    }
  }

  generateExcel(filter: SeguretatGrupsFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new SeguretatGrupsFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlSeguretatGrupExcel'],  params );
    }
  }
}
