<div class="row justify-content-between">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
      
      <!-- Codi Usuari -->
      <ng-container matColumnDef="codiUser">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.codiUser}}</a></mat-cell>
      </ng-container>

      <!-- Nom Usuari -->
      <ng-container matColumnDef="nomUsuari">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom Usuari</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.nomUsuari}}</mat-cell>
      </ng-container>

      <!-- Descripcio -->
      <ng-container matColumnDef="descripcio">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripció</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.descUser}}</mat-cell>
      </ng-container>

      <!-- Email -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Email</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.email}}</mat-cell>
      </ng-container>

      <!-- Tipus -->
      <ng-container matColumnDef="tipus">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipus</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.tipus}}</mat-cell>
      </ng-container>

      <!-- Actiu -->
      <ng-container matColumnDef="actiu">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Actiu</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index"><mat-checkbox [checked] = "row.actiu" [disabled] = 'true'></mat-checkbox></mat-cell>
      </ng-container>

      <!-- Data inici -->
      <ng-container matColumnDef="data_inici">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data inici</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.dataInici | formatDate }}</mat-cell>
      </ng-container>

      <!-- Data fi -->
      <ng-container matColumnDef="data_fi">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data fi</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.dataFi | formatDate }}</mat-cell>
      </ng-container>

      <!-- ultimaConexio -->
      <ng-container matColumnDef="ultima_conexio">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Última connexió</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.ultimaConexio | longDate}}</mat-cell>
      </ng-container>

      <!-- zonaBlava -->
      <ng-container matColumnDef="zonaBlava">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Zona blava</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index"><mat-checkbox [checked] = "row.zonaBlava" [disabled] = 'true'></mat-checkbox></mat-cell>
      </ng-container>

      <!-- policia -->
      <ng-container matColumnDef="policia">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Policia</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index"><mat-checkbox [checked] = "row.policia" [disabled] = 'true'></mat-checkbox></mat-cell>
      </ng-container>

      <!-- convivencia -->
      <ng-container matColumnDef="convivencia">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Convivencia</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index"><mat-checkbox [checked] = "row.convivencia" [disabled] = 'true'></mat-checkbox></mat-cell>
      </ng-container>

      <!-- Institució -->
      <ng-container matColumnDef="institucionDesc">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Institució</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.institucionDesc}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
</div>

