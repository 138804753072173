var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var AvisosFilter = /** @class */ (function (_super) {
    __extends(AvisosFilter, _super);
    function AvisosFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.id = null;
        _this.idTipusAvis = null;
        _this.data = null;
        _this.fechaAvisoInicio = null;
        _this.fechaAvisoFin = null;
        _this.agent = '';
        _this.pda = '';
        _this.carpetes = '';
        _this.idTipusAvisList = '';
        _this.labels['id'] = _this.i18n('Id');
        _this.labels['idTipusAvis'] = _this.i18n('IdTipusAvis');
        _this.labels['data'] = _this.i18n('Data');
        _this.labels['fechaAvisoInicio'] = _this.i18n('Data Inici');
        _this.labels['fechaAvisoFin'] = _this.i18n('Data Fi');
        _this.labels['agent'] = _this.i18n('Agent');
        _this.labels['pda'] = _this.i18n('PDA');
        _this.labels['carpetes'] = _this.i18n('Assumpte');
        _this.labels['idTipusAvisList'] = _this.i18n('Tipus avís');
        _this.formatOutput['fechaAvisoInicio'] = 'shortDate';
        _this.formatOutput['fechaAvisoFin'] = 'shortDate';
        return _this;
    }
    return AvisosFilter;
}(FilterData));
export { AvisosFilter };
