var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
var defaultSortParam = 'descripcio';
var defaultSortDirection = 'asc';
var TipusMatriculaFilterService = /** @class */ (function (_super) {
    __extends(TipusMatriculaFilterService, _super);
    function TipusMatriculaFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TipusMatriculaFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('codi', filter.codi ? filter.codi : '')
            .append('descripcio', filter.descripcio ? filter.descripcio : '')
            .append('codInsti', filter.codInsti ? filter.codInsti : '')
            .append('denunciaPOL', this.getTristateValue(filter.denunciaPOL))
            .append('denunciaZB', this.getTristateValue(filter.denunciaZB))
            .append('generarAlarma', this.getTristateValue(filter.generarAlarma))
            .append('guardarLOG', this.getTristateValue(filter.guardarLOG))
            .append('reincidents', this.getTristateValue(filter.reincidents))
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return TipusMatriculaFilterService;
}(CustomFilterService));
export { TipusMatriculaFilterService };
