import { OnInit, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { timer } from 'rxjs';
import { ValidationService } from 'path-shared/services/validation/validation.service';
var FormTextEditComponent = /** @class */ (function () {
    function FormTextEditComponent(validator) {
        this.validator = validator;
        this.validClass = 'ng-valid';
        this.validVal = false;
        this.valueChange = new EventEmitter();
        this.val = null;
        this.disabled = false;
        this.required = false;
        this.number = false;
        this.matricula = false;
        this.isReadOnly = false;
        this.tabindex = 0;
    }
    Object.defineProperty(FormTextEditComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            if (this.val !== val) {
                this.val = val;
                this.valueChange.emit(this.val);
                this.valid();
                // if (this.validVal) {
                //   // setTimeout(() => {
                //   // }, 10);
                // }
            }
        },
        enumerable: true,
        configurable: true
    });
    FormTextEditComponent.prototype.ngOnInit = function () {
    };
    FormTextEditComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.autofocus !== undefined) {
            timer(500).subscribe(function () {
                _this.elementRef.nativeElement.focus();
            });
        }
    };
    FormTextEditComponent.prototype.valid = function () {
        var ok = !this.errorRequired() && !this.errorNumber() && !this.errorLength() && !this.errorMatricula() && !this.errorNumberMaxValue();
        this.validVal = ok;
        return ok;
    };
    FormTextEditComponent.prototype.isRequired = function () {
        return this.required !== false;
    };
    FormTextEditComponent.prototype.isRequiredNumber = function () {
        return this.number !== false;
    };
    FormTextEditComponent.prototype.isRequiredMatricula = function () {
        return this.matricula !== false;
    };
    FormTextEditComponent.prototype.hasMaxLength = function () {
        return this.validator.isNatural(this.maxLength.toString());
    };
    FormTextEditComponent.prototype.errorRequired = function () {
        return this.isRequired() && (this.value === null || this.value === '' || this.value === undefined);
    };
    FormTextEditComponent.prototype.errorNumber = function () {
        // if (this.value != null && this.value.toString().length > 0 ) {
        //   return true;
        // } else {
        return false;
        // }
    };
    FormTextEditComponent.prototype.errorNumberMaxValue = function () {
        if (this.maxNumberValue !== undefined && this.maxNumberValue !== null) {
            if (this.maxNumberValue < this.value) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    FormTextEditComponent.prototype.errorMatricula = function () {
        return this.isRequiredMatricula() && !this.validator.isMatricula(this.value);
    };
    FormTextEditComponent.prototype.errorLength = function () {
        if (this.maxLength === undefined) {
            return false;
        }
        else {
            return !this.validator.checkLength(this.value, this.maxLength);
        }
    };
    return FormTextEditComponent;
}());
export { FormTextEditComponent };
