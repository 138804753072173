import { AlegacioFilter } from 'app/sections/tramitar/models/alegaciones.filter';
import { DenunciaDetailModel } from 'app/sections/denuncies/models/denuncia-detail.model';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { AlegacionesFilterService } from '../services/alegaciones-filter.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FilesService } from 'path-shared/services/files/files.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { UsuarisService } from 'app/sections/usuaris/services/usuaris.service';
import { RespondreAlegacionsComponent } from './respondre-alegacions/respondre-alegacions.component';
import { Alegacio } from '../models/alegaciones.model';
import { AlegacionsFilterComponent } from './alegacions-filter/alegacions-filter.component';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'app-alegacions',
  templateUrl: './alegacions.component.html',
  styleUrls: ['./alegacions.component.scss']
})
export class AlegacionsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  alegacionsFilterDialogRef: MatDialogRef<AlegacionsFilterComponent>;
  respondreAlegacionsDialogRef: MatDialogRef<RespondreAlegacionsComponent>;
  dataSource: CustomDataSource<Alegacio, AlegacioFilter>;
  displayedItems = [];
  denunciaDetail: DenunciaDetailModel;
  filter: AlegacioFilter = new AlegacioFilter('EXP', 'desc');
  filterPage: PaginationFilter;
  id: number;
  @ViewChild(MatSort) sort: MatSort;
  @Input() isReadOnly: boolean;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  displayedColumns = ['button','fitxer','exp','tipo','numdenuncia','dataplec','agent','infraccio'];

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private filesService: FilesService,
    private filterService: FilterService,
    public auth: AuthorizationService,
    private usuarisService: UsuarisService,
    ) { }

  ngOnInit() {
    this.usuarisService.getUsuari().subscribe(data => this.filter.codUser = data.UserId);
    this.refrescar();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    } );

    this.paginator.page.subscribe(data => {
      this.paginator.pageIndex = data.pageIndex;
      this.paginator.pageSize = data.pageSize;
      this.loadAlegacionesPage();
      console.log('paginator size: ' + data.pageSize);
    });

    this.loadAlegacionesPage();
  }

  refrescar() {
    this.getAlegacions();
  }

  private getAlegacions() {
    this.dataSource = CustomDataSource.create<Alegacio, AlegacioFilter>(this.paginator, new AlegacionesFilterService(), 'urlalegacions', 'urlalegacionspaginationcount');

    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.EXP] = {
          button: '',
          fitxer: item.FICHERO_SOM_ID,
          exp: item.EXP,
          tipo: item.TIPO,
          numdenuncia: item.NUMDENUNCIA,
          dataplec: item.DATAPLEC,
          infracció: item.INFRACCIO,
          IsAgenteAutorizadoResponder: item.IsAgenteAutorizadoResponder
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.paginator.pageIndex = 0;
        this.filterService.saveFilter('alegacions-filter', this.filter);
        this.loadAlegacionesPage();
      }
    });

    this.filter.filterCleared.subscribe(updated => {
      if (updated) {
        this.paginator.pageIndex = 0;
        this.filterService.saveFilter('alegacions-filter', this.filter);
        this.loadAlegacionesPage();
      }
    });
    if (this.dataDialog !== undefined && this.dataDialog.filter !== null) {
      this.filter.set(this.dataDialog.filter);
      this.displayedColumns.splice(0, 1);
    } else {
       this.filterService.loadFilter('alegacions-filter', this.filter);
    }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  loadAlegacionesPage() {
    if (this.dataSource !== undefined) {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }
  }

  respondreAlegacio(row: Alegacio) {
    this.respondreAlegacionsDialogRef = this.dialog.open(RespondreAlegacionsComponent, {
      width: '40%',
      height: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: row
    });

    this.respondreAlegacionsDialogRef.afterClosed().subscribe(res => { if (res) this.refrescar() });
  }

  onFilterClicked() {
    this.alegacionsFilterDialogRef = this.dialog.open(AlegacionsFilterComponent, {
      width: '50%',
      height: 'auto',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.filter
      }
    });
  }

  getFilePath(ficheroSomId) {
    this.filesService.getFileSomId(ficheroSomId).subscribe(
      result=>{ 
        const objectUrl=URL.createObjectURL(result);
        window.open(objectUrl);},
      error => console.log(error),
      ()=> console.log('completed'));
  }

  parseTipo(tipo: string) {
    if (tipo == 'PRIMERAS_ALEGACIONES') return this.i18n('Primera');
    else if (tipo == 'SEGUNDAS_ALEGACIONES') return this.i18n('Segona');
  }
}
