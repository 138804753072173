<div class="row justify-content-between">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
    
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n style="width:25%">Codi</mat-header-cell>
      <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.somId}}</a></mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="motiu">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Motiu</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.motiu}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>

