import { TextListFillInterface } from '../models/textlistfillinterface';

export class CustomListFill implements TextListFillInterface {

    options: String[];

    constructor(stringArray: string[]) {

        this.options = new Array(stringArray.length);

        let i = 0;
        for (i; i < stringArray.length; i++) {
            this.options[i] = stringArray[i];
        }
    }

}
