/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dash-input-files-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./dash-input-files-status.component";
import * as i4 from "@angular/router";
var styles_DashInputFilesStatusComponent = [i0.styles];
var RenderType_DashInputFilesStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashInputFilesStatusComponent, data: {} });
export { RenderType_DashInputFilesStatusComponent as RenderType_DashInputFilesStatusComponent };
function View_DashInputFilesStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"], ["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDuplicados() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Comptadors duplicats"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "badge badge-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contadoresDuplicados; _ck(_v, 6, 0, currVal_0); }); }
function View_DashInputFilesStatusComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"], ["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPendentsImatge() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pendents imatge"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "badge badge-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pendingFilesImg; _ck(_v, 6, 0, currVal_0); }); }
export function View_DashInputFilesStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "card-body p-3 d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 20, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "text-muted text-uppercase font-weight-bold small"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "row justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pendents"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "badge badge-secondary"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "row"], ["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openErroneos() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Error"])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "badge badge-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashInputFilesStatusComponent_1)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashInputFilesStatusComponent_2)), i1.ɵdid(23, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = (((_co.contadoresDuplicados !== undefined) && (_co.contadoresDuplicados !== null)) && (_co.nom === "PDA")); _ck(_v, 21, 0, currVal_4); var currVal_5 = (((_co.pendingFilesImg !== undefined) && (_co.pendingFilesImg !== null)) && (_co.nom === "Delta Car")); _ck(_v, 23, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.icon, " bg-primary p-3 font-2xl mr-3"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.pendingFiles; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.errorFiles; _ck(_v, 19, 0, currVal_3); }); }
export function View_DashInputFilesStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dash-input-files-status", [], null, null, null, View_DashInputFilesStatusComponent_0, RenderType_DashInputFilesStatusComponent)), i1.ɵdid(1, 114688, null, 0, i3.DashInputFilesStatusComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashInputFilesStatusComponentNgFactory = i1.ɵccf("app-dash-input-files-status", i3.DashInputFilesStatusComponent, View_DashInputFilesStatusComponent_Host_0, { pendingFiles: "pendingFiles", errorFiles: "errorFiles", contadoresDuplicados: "contadoresDuplicados", pendingFilesImg: "pendingFilesImg", label: "label", nom: "nom", icon: "icon" }, {}, []);
export { DashInputFilesStatusComponentNgFactory as DashInputFilesStatusComponentNgFactory };
