// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { HttpSecureClient } from '../http/httpSecureClient';
import { api } from '../../configuration/urls';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { AuthGuardService } from '../authentication/auth-guard.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ReportService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  getReports(page: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      page = page.replace('.', 'xXx');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlReports'].replace('{page}', page), null);
    }
  }

  getReportsHTML(isComuna: boolean): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetReportsPDF'].replace('{isComuna}', isComuna.toString()), null);
    }
  }

  generateReport(reportInfo: any, reportUrl: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.postFileResponse(config.urls['apiUrl'] + api.endpoints[reportUrl], reportInfo, new HttpParams());
    }
  }

}
