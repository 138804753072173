<app-toolbar>
  <app-tool-button text="Actualitzar"  i18n-text (click)="referesh();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
</app-toolbar>
<div class="row">
  <div class="col-6">
    <app-confirmacion-listado #listado [updatedFilterData]="filter" (notify)="rowSelected($event)"></app-confirmacion-listado>
  </div>
  <div class="col-6">
    <!-- app-rows-detalle-confirmacion -->
    <app-rows-detalle-confirmacion #listadoRows [updatedFilterData]="filterRows" [detalle]="this.rowSelectedObj"  ></app-rows-detalle-confirmacion>
    <!-- <app-confirmacion-boe-detalle #listadoDetalle [updatedFilterData]="filterDetalle" [detalle]="this.rowSelectedObj" ></app-confirmacion-boe-detalle> -->
  </div>
</div>