import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { FileBoxService } from '../file-box-service';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {

  dialogData: ComponentDialog;
  SomId: string;
  imgData: any;
  NomFitxer: string;
  constructor(
    private fileService: FileBoxService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
      this.dialogData = data;
      this.SomId = this.data.SomId;
      this.NomFitxer = this.data.NomFitxer;
   }

  ngOnInit() {
    this.carregarImatge();
  }

  carregarImatge() {

    const image = this.fileService.getImage(this.SomId, null).subscribe(
      detailsdata => {
                this.imgData = this.buildImage(detailsdata);
      });
  }
  buildImage(detailsdata: any): any {
    return 'data:image/png;base64,' + btoa(new Uint8Array(detailsdata).reduce(function (datas, byte) {
      return datas + String.fromCharCode(byte);
    }, '')) ;
  }

}
