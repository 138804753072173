import { TemporadaFilter } from './../models/temporada.filter';

import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = 'id';
const defaultSortDirection = 'asc';

export class TemporadaFilterService extends CustomFilterService {

    public createFilterParams(filter: TemporadaFilter, start: number, pageSize: number, sortField: string, sortDir: string) {
        return new HttpParams()
        .append('id', filter.id ? filter.id : '')
        .append('descripcion', filter.descripcion ? filter.descripcion : '')
        .append('fechaInicio', filter.fechaInicio ? filter.fechaInicio.toString() : '')
        .append('fechaFin', filter.fechaFin ? filter.fechaFin.toString() : '')
        .append('activa', this.getTristateValue(filter.activa))
        .append('start', start.toString())
        .append('size', pageSize.toString())
        .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
        .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);

    }

    public createFilesFilterParams(ids: string[]) {
        return new HttpParams()
        .append('ids', JSON.stringify(ids));
    }
}
