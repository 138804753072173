import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { PuntosDgtFilter } from '../models/puntos-dt-filter';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'codi';
const defaultSortDirection = 'asc';

@Injectable()
export class EnvioPuntsDGTFilterService extends CustomFilterService {
  public createFilterParams(filter: PuntosDgtFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    if (filter.fechaInicio !== undefined && filter.fechaInicio !== null) {
      filter.fechaInicio.setHours(0, 0, 1);
    }
   
    if (filter.fechaFin !== undefined && filter.fechaFin !== null) {
      filter.fechaFin.setHours(23, 59, 59);
    }
   
    return new HttpParams()
    .append('remesa', filter.remesa ? filter.remesa.toString() : '')
    .append('fechaInicio',  Reflection.empty(filter.fechaInicio) ? null :  typeof(filter.fechaInicio) === 'string' ?
    filter.fechaInicio : filter.fechaInicio.toDateString())
    .append('fechaFin',  Reflection.empty(filter.fechaFin) ? null :  typeof(filter.fechaFin) === 'string' ?
    filter.fechaFin : filter.fechaFin.toDateString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
