import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-released-notes',
    templateUrl: 'released-notes.component.html',
    styleUrls: ['released-notes.component.scss']
})
export class ReleasedNotesComponent {
    releasedNotesList: null;
    leido = false;
    constructor(
        private dialogRef: MatDialogRef<ReleasedNotesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
      ) {
        this.releasedNotesList = data;
      }

    Close() {
    this.dialogRef.close(this.leido);
    }

}
