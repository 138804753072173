<app-dialog-header label="Imatges" i18n-label></app-dialog-header>
<app-toolbar>
    <app-tool-button text="Afegir" (click)="Afegir();" i18n-text color="has-color-green" icon="fa-plus-square"></app-tool-button>
    <app-form-file-multiple-upload label="Fitxer" i18n-label labelHidden="true" (fileReady)="fileReady($event)" hidden #fileInput></app-form-file-multiple-upload>  
    <app-tool-button [disabled]="this.numSelected()===0" text="Eliminar" i18n-text (click)="Eliminar()" color="has-color-red" icon="fa-trash" ></app-tool-button>
</app-toolbar>
<div class="table-sdw is-fixed">

    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" class="is-header-sticky">


        <ng-container matColumnDef="incorpora"> 
            <mat-header-cell fxFlex="10%" *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><mat-checkbox #checkbox [(ngModel)]="this.allSelected" (change)="SeleccionarTotes()" ></mat-checkbox></mat-header-cell>
            <mat-cell fxFlex="10%" *matCellDef="let row"  ><mat-checkbox  [(ngModel)]="this.items[rowIndex(row)].checked"></mat-checkbox></mat-cell>
        </ng-container>

        <ng-container matColumnDef="NOMBRE_FICHERO">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom arxiu</mat-header-cell>
            <mat-cell *matCellDef="let row"><a class="is-link cursor detalle" (click)="this.OpenImage(row)">{{row.NOMBRE_FICHERO}}</a></mat-cell>
        </ng-container>

        <ng-container matColumnDef="DataNew">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data creació</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.DataNew}}</mat-cell>
        </ng-container>  

        <ng-container matColumnDef="UserNew">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Usuari creació</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.UserNew}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="UBICACION_FICHERO">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Ubicació</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.UBICACION_FICHERO}}</mat-cell>
        </ng-container>
        

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
    </mat-table>
</div>