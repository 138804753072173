import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DenunciesEditorComponent } from 'app/sections/denuncies/components/denuncies-editor/denuncies-editor.component';
import { ToastrService } from 'ngx-toastr';
import { ReportsComponent } from 'path-shared/components/reports/reports.component';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EjecutivaFilterImpressio } from '../../../models/Ejecutiva-filter-impressio';
import { Ejecutivas } from '../../../models/Ejecutivas.models';
import { TramitarEjecutivasFilter } from '../../../models/TramitarEjecutivas-filter.model';
import { TramitarEjecutivasFilterService } from '../../../services/tramitar-ejecutivas-filter.service';
import { TramitarService } from '../../../services/tramitar.service';

@Component({
  selector: 'app-table-tramitar-executiva',
  templateUrl: './table-tramitar-executiva.component.html',
  styleUrls: ['./table-tramitar-executiva.component.scss']
})
export class TableTramitarExecutivaComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  reports = [];  
  data: Ejecutivas[];
  dataSource: CustomDataSource<Ejecutivas, TramitarEjecutivasFilter>;    
  allSelected: boolean;
  filter: TramitarEjecutivasFilter = new TramitarEjecutivasFilter('EXP', 'asc');
  filterimpressio: EjecutivaFilterImpressio = new EjecutivaFilterImpressio(null, null, 'EXP', 'asc');
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();

  displayedColumns = ['Check', 'EXP', 'Matricula', 'PERSONA', 'Resultat'];

  constructor(private dialog: MatDialog,
              private tramitarService: TramitarService,
              private filterService: FilterService,
              private not: NotificationService,
              private toastr: ToastrService,
              private i18n: I18n,
              public auth: AuthorizationService) { }

  ngOnInit() {
    this.tramitarService.getReports(this.auth.Pages.TramitarExecutiva).subscribe( res => {
      this.reports = res;
    });
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);    
  }

  loadInici() {
    this.dataSource = CustomDataSource.create<Ejecutivas, TramitarEjecutivasFilter>(this.paginator, new TramitarEjecutivasFilterService(), 'urlGetEjecutivas', 'urlGetEjecutivasCount');
    
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        item.Check = false;
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
      }
    });
    

    this.not.EjecutivaResultats.subscribe(value => {
      const item = this.dataSource.get('EXP', value.EXP);
      if (item !== null) {
        item.Mensaje = value.Response;
      }
    });

  }
  

  toggleAllCheckbox() {
    this.dataSource.getData().forEach(item => {
        item.Check = this.allSelected;
    });    
  }
  
  SelectedRow(row: Ejecutivas) {
    row.Check = !row.Check;    
    }

    onRowClicked(row: Ejecutivas) {
      const data: ComponentDialog = Builder.newComponentDialog(true, true, row.SomIdDenuncia);
        const ref = this.dialog.open(DenunciesEditorComponent, {
          width: '80%',
          height: '80%',
          maxHeight: '100vh',
          closeOnNavigation: false,
          autoFocus: true,
          data: data
        });
  }

  Traspassar() {
    let taula: Ejecutivas[];
    taula = [];
    if (this.dataSource.getData().filter(x => x.Check === true).length > 0) {
      this.tramitarService.getTipusEnvio().subscribe(x => {
        if (x !== undefined && x !== null) {
          switch (x) {
            case '': //informe
              this.tramitarService.urlEnviarEjecutivasInforme(this.dataSource.getData().filter(y => y.Check === true)).subscribe(y => {
                if (y === undefined || y === null || y !== true) {
                  this.toastr.error(this.i18n('Algo ha fallado'));
                }
                });
              break;
            case ('E'): //Excel
              this.tramitarService.urlEnviarEjecutivasExcel(this.dataSource.getData().filter(y => y.Check === true)).subscribe(y => {
                if (y === undefined || y === null || y !== true) {
                this.toastr.error(this.i18n('Algo ha fallado'));
                }
                });
              break;

            default: //Recaptacio
                this.tramitarService.urlEnviarEjecutivasRecaptacio(this.dataSource.getData().filter(y => y.Check === true)).subscribe(y => {
                  if (y === undefined || y === null || y !== true) {
                    this.toastr.error(this.i18n('Algo ha fallado'));
                  }
                  });
              break;
            }
          } else {
            this.toastr.info(this.i18n('Error Tipo Envío'));
          }
        });
    } else {
      this.toastr.info(this.i18n('Ningún Seleccionado.'));
    }
  }

   //#region REPORTS

   Impressio() {
    const ref = this.dialog.open(ReportsComponent, {

      width: '30%',
      height: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: this.reports
    }).afterClosed().subscribe(report => {
      if (report !== '') {
        this.tramitarService.generateReport(this.filterimpressio, report.id).subscribe(file => {
          const name = report.nom;
          const contentType = file.headers.get('Content-Type');
          if (contentType !== null && contentType !== undefined) {
              FileDownloader.downLoadFile([file.body],  contentType + ';charset=utf-8', name.toString());
          } else {
            this.toastr.error(this.i18n('No se ha podido generar el fichero'));
          }
        });

      }

    });
  }

  //#endregion

}
