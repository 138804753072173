/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alarmes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i6 from "../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i7 from "../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i8 from "../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i9 from "../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component.ngfactory";
import * as i10 from "../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component";
import * as i11 from "../../../../shared/services/authorization/authorization-service";
import * as i12 from "@angular/material/dialog";
import * as i13 from "../../components/alarmes-table/alarmes-table.component.ngfactory";
import * as i14 from "../../components/alarmes-table/alarmes-table.component";
import * as i15 from "../../services/alarmes.service";
import * as i16 from "../../../../shared/services/filter/filter.service";
import * as i17 from "../../../../shared/services/excel/excel-download.service";
import * as i18 from "@ngx-translate/i18n-polyfill";
import * as i19 from "@angular/router";
import * as i20 from "../../../../shared/services/parameters/parameters.service";
import * as i21 from "./alarmes.component";
import * as i22 from "../../../../shared/services/startup/google-maps.service";
import * as i23 from "../../../../shared/services/authentication/auth-guard.service";
import * as i24 from "ngx-toastr";
import * as i25 from "../../../../shared/services/notification/notification.service";
import * as i26 from "../../../../shared/components/permission-dialog/permission.service";
import * as i27 from "../../../../shared/components/confirmation-dialog/confirmation-dialog.service";
var styles_AlarmesComponent = [i0.styles];
var RenderType_AlarmesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlarmesComponent, data: {} });
export { RenderType_AlarmesComponent as RenderType_AlarmesComponent };
function View_AlarmesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["label", "Alarmes"]], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Alarmes"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AlarmesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { alarmesTable: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlarmesComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 13, "app-toolbar", [], null, null, null, i5.View_ToolbarComponent_0, i5.RenderType_ToolbarComponent)), i1.ɵdid(4, 114688, null, 0, i6.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "app-tool-button", [["color", "has-color-blue"], ["icon", "fa-sync"], ["text", "Actualitzar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(6, 114688, null, 0, i8.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "app-tool-button", [["color", "has-color-orange"], ["icon", "fa-filter"], ["text", "Filtre"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFilterClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(8, 114688, null, 0, i8.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-tool-button", [["color", "has-color-darkgreen"], ["icon", "fa-file-excel"], ["text", "Excel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.generateExcel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(10, 114688, null, 0, i8.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "app-tool-button", [["color", "has-color-aqua"], ["icon", "fa-check-double"], ["text", "Accepta Totes"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(12, 114688, null, 0, i8.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "app-tool-button", [["color", "has-color-orange"], ["icon", "fa-history"], ["text", "Hist\u00F2ric"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.irHistorico() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(14, 114688, null, 0, i8.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(15, 0, null, 0, 1, "app-tool-button-permissions", [], null, null, null, i9.View_ToolButtonPermissionsComponent_0, i9.RenderType_ToolButtonPermissionsComponent)), i1.ɵdid(16, 114688, null, 0, i10.ToolButtonPermissionsComponent, [i11.AuthorizationService, i12.MatDialog], { permission: [0, "permission"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "app-alarmes-table", [], null, [[null, "notify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("notify" === en)) {
        var pd_0 = (_co.onRowSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_AlarmesTableComponent_0, i13.RenderType_AlarmesTableComponent)), i1.ɵdid(18, 4833280, [[1, 4]], 0, i14.AlarmesTableComponent, [i15.AlarmesService, i16.FilterService, i17.ExcelDownloadService, i12.MatDialog, i18.I18n, i19.Router, i20.ParametersService, i12.MAT_DIALOG_DATA], { updatedFilterData: [0, "updatedFilterData"], selectButton: [1, "selectButton"], isHistoric: [2, "isHistoric"] }, { notify: "notify" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataDialog.isDialog; _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); var currVal_1 = "Actualitzar"; var currVal_2 = "has-color-blue"; var currVal_3 = "fa-sync"; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "Filtre"; var currVal_5 = "has-color-orange"; var currVal_6 = "fa-filter"; _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = "Excel"; var currVal_8 = "has-color-darkgreen"; var currVal_9 = "fa-file-excel"; _ck(_v, 10, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = "Accepta Totes"; var currVal_11 = "has-color-aqua"; var currVal_12 = "fa-check-double"; _ck(_v, 12, 0, currVal_10, currVal_11, currVal_12); var currVal_13 = "Hist\u00F2ric"; var currVal_14 = "has-color-orange"; var currVal_15 = "fa-history"; _ck(_v, 14, 0, currVal_13, currVal_14, currVal_15); var currVal_16 = _co.auth.Pages.AlarmSistema; _ck(_v, 16, 0, currVal_16); var currVal_17 = _co.filterData; var currVal_18 = (_co.dataDialog.isDialog && !_co.dataDialog.isReadOnly); var currVal_19 = false; _ck(_v, 18, 0, currVal_17, currVal_18, currVal_19); }, null); }
export function View_AlarmesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alarmes-historic", [], null, null, null, View_AlarmesComponent_0, RenderType_AlarmesComponent)), i1.ɵdid(1, 4308992, null, 0, i21.AlarmesComponent, [i22.GoogleMapsService, i23.AuthGuardService, i19.ActivatedRoute, i12.MatDialog, i11.AuthorizationService, i19.Router, i15.AlarmesService, i18.I18n, i24.ToastrService, i25.NotificationService, i12.MatDialogRef, i12.MAT_DIALOG_DATA, i26.PermissionService, i27.ConfirmationDialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlarmesComponentNgFactory = i1.ɵccf("app-alarmes-historic", i21.AlarmesComponent, View_AlarmesComponent_Host_0, {}, {}, []);
export { AlarmesComponentNgFactory as AlarmesComponentNgFactory };
