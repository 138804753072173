/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tramos-tipos-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../shared/components/dialog/filter-header/filter-header.component.ngfactory";
import * as i3 from "../../../../../../../shared/components/dialog/filter-header/filter-header.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i6 from "../../../../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i7 from "../../../../../../../shared/services/validation/validation.service";
import * as i8 from "../../../../../../../shared/components/filter/filter-text-edit/filter-text-edit.component.ngfactory";
import * as i9 from "../../../../../../../shared/components/filter/filter-text-edit/filter-text-edit.component";
import * as i10 from "./tramos-tipos-filter.component";
import * as i11 from "@ngx-translate/i18n-polyfill";
var styles_TramosTiposFilterComponent = [i0.styles];
var RenderType_TramosTiposFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TramosTiposFilterComponent, data: {} });
export { RenderType_TramosTiposFilterComponent as RenderType_TramosTiposFilterComponent };
export function View_TramosTiposFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-header", [["title", "Filtrar tipus trams"]], null, [[null, "apply"], [null, "reset"], [null, "dismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("apply" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (_co.reset() !== false);
        ad = (pd_1 && ad);
    } if (("dismiss" === en)) {
        var pd_2 = (_co.dismiss() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_FilterHeaderComponent_0, i2.RenderType_FilterHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.FilterHeaderComponent, [i4.MatDialogRef], { title: [0, "title"] }, { apply: "apply", reset: "reset", dismiss: "dismiss" }), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "row col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-form-text-edit", [["class", "col-sm-3"], ["id", "tramos-tipos-filter-codi"], ["label", "Codi"], ["number", ""]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.SomId = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FormTextEditComponent_0, i5.RenderType_FormTextEditComponent)), i1.ɵdid(5, 4308992, null, 0, i6.FormTextEditComponent, [i7.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], number: [3, "number"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-filter-text-edit", [["class", "col-sm-9"], ["contains", ""], ["id", "tramos-tipos-filter-descripcio"], ["label", "Descripci\u00F3"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.descripcio = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FilterTextEditComponent_0, i8.RenderType_FilterTextEditComponent)), i1.ɵdid(7, 4308992, null, 0, i9.FilterTextEditComponent, [i7.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], contains: [3, "contains"], id: [4, "id"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Filtrar tipus trams"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "Codi"; var currVal_2 = _co.filter.SomId; var currVal_3 = "col-sm-3"; var currVal_4 = ""; var currVal_5 = "tramos-tipos-filter-codi"; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "Descripci\u00F3"; var currVal_7 = _co.filter.descripcio; var currVal_8 = "col-sm-9"; var currVal_9 = ""; var currVal_10 = "tramos-tipos-filter-descripcio"; _ck(_v, 7, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, null); }
export function View_TramosTiposFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tramos-tipos-filter", [], null, null, null, View_TramosTiposFilterComponent_0, RenderType_TramosTiposFilterComponent)), i1.ɵdid(1, 114688, null, 0, i10.TramosTiposFilterComponent, [i4.MatDialogRef, i11.I18n, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TramosTiposFilterComponentNgFactory = i1.ɵccf("app-tramos-tipos-filter", i10.TramosTiposFilterComponent, View_TramosTiposFilterComponent_Host_0, {}, { notify: "notify" }, []);
export { TramosTiposFilterComponentNgFactory as TramosTiposFilterComponentNgFactory };
