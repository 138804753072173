import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FormControl} from '@angular/forms';
import { Tab } from './model/tab.model';
import { ContentTab } from './model/content-tab.model';

@Component({
  selector: 'app-form-tab-chat-dynamic',
  templateUrl: './form-tab-chat-dynamic.component.html',
  styleUrls: ['./form-tab-chat-dynamic.component.scss']
})
export class FormTabChatDynamicComponent implements OnInit {

  @Input() tabLabel: string;
  @Input() tabs: Array<Tab>;
  @Input() selected: FormControl;
  @Output() recuperaMissatgesAntics: EventEmitter<Tab> = new EventEmitter<Tab>();
  @Output() recuperaMissatgesNous: EventEmitter<Tab> = new EventEmitter<Tab>();
  @Output() downloadFitxer: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
    this.selected = new FormControl(0);
  }

  ngOnInit() { }

  addTab(tabId: string, selectAfterAdding: boolean) {
    const tab = new Tab(tabId, new Array<ContentTab>());
    this.tabs.push(tab);

    if (selectAfterAdding) {
      this.selectLastTab();
    }
  }

  removeTab(index: number) {
    this.tabs.splice(index, 1);
  }

  getTabs(): Array<Tab> {
    return this.tabs;
  }

  getTabsId(): Array<string> {
    const tabsId: Array<string> = new Array<string>();

    this.tabs.forEach( element => {
      tabsId.push(element.id);
    });

    return tabsId;
  }

  selectTab(tab: Tab) {
    const index = this.getTabs().indexOf(tab);
    this.selected.setValue(this.tabs.length - index);
  }

  selectLastTab() {
    this.selected.setValue(this.tabs.length);
  }

  recuperaMissatgesAntic() {
    // Enviem el tab actual
    this.recuperaMissatgesAntics.emit(this.tabs[this.selected.value]);
  }

  recuperaMissatgesNou() {
    // Enviem el tab actual
    this.recuperaMissatgesNous.emit(this.tabs[this.selected.value]);
  }

  getFitxer(idFitxer: number) {
    this.downloadFitxer.emit(idFitxer);
  }

}
