import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../../shared/services/authentication/auth-guard.service";
var defaultSortParam = 'fechaCobro';
var defaultSortDirection = 'desc';
var CobrosService = /** @class */ (function () {
    function CobrosService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
    }
    CobrosService.prototype.getCobros = function (filter) {
        if (this.authGuard.canActivate()) {
            var data = {
                'fechas': filter.fechas,
                'aparatos': filter.aparatos
            };
            return this.http.postJSON(config.urls['apiUrl'] + api.endpoints['urlGetCobros'], JSON.stringify(data));
        }
    };
    CobrosService.prototype.generateExcel = function (filter) {
        if (filter.aparatos == null) {
            filter.aparatos = new Array();
        }
        if (this.authGuard.canActivate()) {
            var data = {
                'fechas': filter.fechas,
                'aparatos': filter.aparatos
            };
            return this.http.postFileArrayResponse(config.urls['apiUrl'] + api.endpoints['urlCobrosExcel'], data, null);
        }
    };
    CobrosService.ngInjectableDef = i0.defineInjectable({ factory: function CobrosService_Factory() { return new CobrosService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: CobrosService, providedIn: "root" });
    return CobrosService;
}());
export { CobrosService };
