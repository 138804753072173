var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
var defaultSortParam = 'id';
var defaultSortDirection = 'asc';
var TextoAvisoFilterService = /** @class */ (function (_super) {
    __extends(TextoAvisoFilterService, _super);
    function TextoAvisoFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TextoAvisoFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('titulo', filter.titulo ? filter.titulo : '')
            .append('fechaInicio', filter.fechaInicio ? this.formatDateToDecimal(filter.fechaInicio) : '')
            .append('fechaFin', filter.fechaFin ? this.formatDateToDecimal(filter.fechaFin) : '')
            .append('permiteLogIn', filter.permiteLogIn ? filter.permiteLogIn.toString() : '')
            .append('idioma', filter.idioma ? filter.idioma : '')
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    TextoAvisoFilterService.prototype.formatDateToLocal = function (fecha) {
        fecha = new Date(fecha);
        var dateString = fecha.getFullYear() + '-' +
            ('0' + (fecha.getMonth() + 1)).slice(-2) + '-' +
            ('0' + fecha.getDate()).slice(-2);
        return dateString;
    };
    TextoAvisoFilterService.prototype.formatDateToDecimal = function (date) {
        var dateString = this.formatDateToLocal(date);
        var formatedDate = dateString.replace('-', '').replace('-', '').replace('/', '').replace(' ', '');
        return formatedDate;
    };
    return TextoAvisoFilterService;
}(CustomFilterService));
export { TextoAvisoFilterService };
