var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
var defaultSortParam = 'id';
var defaultSortDirection = 'asc';
var TextoInformativoFilterService = /** @class */ (function (_super) {
    __extends(TextoInformativoFilterService, _super);
    function TextoInformativoFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TextoInformativoFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('descripcion', filter.descripcion ? filter.descripcion : '')
            .append('fechaInicio', filter.fechaInicio ? this.formatDateToLocal(filter.fechaInicio) : '')
            .append('fechaFin', filter.fechaFin ? this.formatDateToLocal(filter.fechaFin) : '')
            .append('idioma', filter.idioma ? filter.idioma : '')
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    TextoInformativoFilterService.prototype.formatDateToLocal = function (fecha) {
        fecha = new Date(fecha);
        var dateString = fecha.getFullYear() + '-' +
            ('0' + (fecha.getMonth() + 1)).slice(-2) + '-' +
            ('0' + fecha.getDate()).slice(-2);
        return dateString;
    };
    return TextoInformativoFilterService;
}(CustomFilterService));
export { TextoInformativoFilterService };
