/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tool-button-permissions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../tool-button/tool-button.component.ngfactory";
import * as i3 from "../tool-button/tool-button.component";
import * as i4 from "@angular/common";
import * as i5 from "./tool-button-permissions.component";
import * as i6 from "../../../services/authorization/authorization-service";
import * as i7 from "@angular/material/dialog";
var styles_ToolButtonPermissionsComponent = [i0.styles];
var RenderType_ToolButtonPermissionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToolButtonPermissionsComponent, data: {} });
export { RenderType_ToolButtonPermissionsComponent as RenderType_ToolButtonPermissionsComponent };
function View_ToolButtonPermissionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tool-button", [["color", "has-color-orange"], ["icon", "fa-lock"], ["text", "Permisos"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPermissionClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToolButtonComponent_0, i2.RenderType_ToolButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" })], function (_ck, _v) { var currVal_0 = "Permisos"; var currVal_1 = "has-color-orange"; var currVal_2 = "fa-lock"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ToolButtonPermissionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToolButtonPermissionsComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.auth.IsSuperUser(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ToolButtonPermissionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tool-button-permissions", [], null, null, null, View_ToolButtonPermissionsComponent_0, RenderType_ToolButtonPermissionsComponent)), i1.ɵdid(1, 114688, null, 0, i5.ToolButtonPermissionsComponent, [i6.AuthorizationService, i7.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolButtonPermissionsComponentNgFactory = i1.ɵccf("app-tool-button-permissions", i5.ToolButtonPermissionsComponent, View_ToolButtonPermissionsComponent_Host_0, { permission: "permission" }, {}, []);
export { ToolButtonPermissionsComponentNgFactory as ToolButtonPermissionsComponentNgFactory };
