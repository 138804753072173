<app-dialog-header *ngIf="this.label!=null" [label]="this.label" i18n-label></app-dialog-header>
<div style="margin-top: 4vh;">
  <pre *ngIf="type=='json'"  >
    {{text | json}}
  </pre>
  <p *ngIf="type!='json' && type!='link' && type != 'html'">
    {{text}}
  </p>
  <p *ngIf="type == 'html'" [innerHTML]="text"></p>
  <p *ngIf="type=='link'">
    <a class="is-link" [href]="this.text" i18n> {{ this.text.split('\\').pop() }} </a>
  </p>
 
</div>
