import { Component, OnInit, EventEmitter, Output, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable,Subscription } from 'rxjs';
import { TipusVehiclesFilter } from '../../models/tipus-vehicles-filter';

@Component({
  selector: 'app-tipus-vehicles-filter',
  templateUrl: './tipus-vehicles-filter.component.html',
  styleUrls: ['./tipus-vehicles-filter.component.scss']
})
export class TipusVehiclesFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: TipusVehiclesFilter;

  @Output() notify: EventEmitter<TipusVehiclesFilter> = new EventEmitter<TipusVehiclesFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<TipusVehiclesFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
