import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { map } from 'rxjs/operators';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../shared/services/authentication/auth-guard.service";
var AnulacionZonaServiceManual = /** @class */ (function () {
    function AnulacionZonaServiceManual(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
    }
    AnulacionZonaServiceManual.prototype.getDenuncia = function (nDenuncia) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAnulacio'].replace('{nDenuncia}', nDenuncia.toString()), null)
            .pipe(map((function (e) {
            return e;
        })));
    };
    AnulacionZonaServiceManual.prototype.getDenunciasFromFile = function (fileString) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetAnulacionesFromFile'], fileString);
        }
    };
    AnulacionZonaServiceManual.prototype.processarAnulacio = function (anul) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetAnulacio'], anul);
        }
    };
    AnulacionZonaServiceManual.prototype.processarAnulacioValorada = function (valorarAnulacion) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlAnulacionValorada'], valorarAnulacion);
        }
    };
    AnulacionZonaServiceManual.prototype.recalculateAmount = function (numDenuncia, cancelationDate) {
        if (this.authGuard.canActivate()) {
            var httpParams = new HttpParams()
                .append('numDenuncia', numDenuncia)
                .append('cancelationDate', cancelationDate.toISOString());
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlRecalculateAmount'], httpParams);
        }
    };
    AnulacionZonaServiceManual.ngInjectableDef = i0.defineInjectable({ factory: function AnulacionZonaServiceManual_Factory() { return new AnulacionZonaServiceManual(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: AnulacionZonaServiceManual, providedIn: "root" });
    return AnulacionZonaServiceManual;
}());
export { AnulacionZonaServiceManual };
