import { TextosPredefinidosFilterService } from './textos-prefedefinidos-filter.service';
import { CFG_TEXTOS_PREDEFINIDOS } from './../../../../models/CFG_TEXTOS_PREDEFINIDOS';
import { Injectable } from "@angular/core";
import { AuthGuardService } from "path-shared/services/authentication/auth-guard.service";
import { HttpSecureClient } from "path-shared/services/http/httpSecureClient";
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { Observable } from 'rxjs-compat/Observable';
import { TextosPredefinidosFilter } from '../models/textos-predefinidos-filter.model';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';


@Injectable()
export class TextosPredefindiosService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setTexto(text: CFG_TEXTOS_PREDEFINIDOS): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTextoPredefinido'], text);
    }
  }

  removeTexto(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteTextoPredefinido'].replace('{secure_id}', secure_id), null);
    }
  }

  getTexto(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTextoPredefinido'].replace('{secure_id}', secure_id), null);
    }
  }

//   getMotiusDesestimarCanviMatricula(): Observable<any> {
//     if (this.authGuard.canActivate()) {
//       return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMotiuDesestimarCanviMatricula'], null);
//     }
//   }
   generateExcel(filter: TextosPredefinidosFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new TextosPredefinidosFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlTextosPredefinitsExcel'],  params );
    }
  }
}
