import { DataSource } from '@angular/cdk/table';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ExpedientsComponent } from 'app/sections/expedients/expedients.component';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { BancIncidenciesDetallDto } from '../../models/banc-incidenices-detallDto';

@Component({
  selector: 'app-incidencies-bancs-table',
  templateUrl: './incidencies-bancs-table.component.html',
  styleUrls: ['./incidencies-bancs-table.component.scss']
})
export class IncidenciesBancsTableComponent implements OnInit {
  dataSource: BancIncidenciesDetallDto[];
    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['exp', 'numdenuncia','data', 'hora', 'recibo', 'mensaje'];
    highlightedRows = [];
  expedientsDialog: MatDialogRef<ExpedientsComponent>;


  @Input() get value() {
    return this.dataSource;
  } set value(val) {
    this.dataSource = val;
  }
  constructor(
    private denunciesService: DenunciesService,
    private dialog: MatDialog
  ) {
    if(this.dataSource === undefined || this.dataSource === null) {
      this.dataSource = [];
    }
   }

  ngOnInit() {
  }
  
  getExpedients(expedient, numDenuncia) {
    this.denunciesService.getExpedient(expedient).subscribe( expedientsData => {
      this.denunciesService.getDenunciaImages(numDenuncia).subscribe( images => {
      this.expedientsDialog = this.dialog.open(ExpedientsComponent,
        {
          height: '80%',
          width: '52%',
          closeOnNavigation: false,
          autoFocus: true,
          data: {'expedient': expedientsData, 'numDenuncia': numDenuncia, 'imatges': images}

        });
      });
    });
  }

  setDataSource(ds){
    this.dataSource = ds;
  }

}
