import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Proposta } from 'app/sections/propostes/models/proposta.model';
import { PropostesService } from 'path-propostes-services/propostes.service';

@Component({
  selector: 'app-propostes-viewer-autologin',
  templateUrl: './propostes-viewer-autologin.component.html',
  styleUrls: ['./propostes-viewer-autologin.component.scss']
})
export class PropostesViewerAutologinComponent implements OnInit {
  recarregar: boolean;
  imagesLoaded: Promise<boolean>;
  constructor(
    private propostesService: PropostesService,
    private thisDialogRef: MatDialogRef<PropostesViewerAutologinComponent>,
    @Inject(MAT_DIALOG_DATA) public proposta: Proposta,
    private i18n: I18n
  ) {
    this.recarregar = false;
    if (sessionStorage.getItem('propostaAutologin')) {
      const json = sessionStorage.getItem('propostaAutologin');
      const values = JSON.parse(json.toString());
      this.proposta = values.proposta;
    }
  }

  ngOnInit() {
    debugger;
    if(this.proposta === undefined || this.proposta === null){
      if (sessionStorage.getItem('propostaAutologin')) {
        const json = sessionStorage.getItem('propostaAutologin');
        const values = JSON.parse(json.toString());
      this.proposta = values.proposta;
      }
    }
      if (this.proposta.IMATGES == null || this.proposta.IMATGES === undefined) {
        this.propostesService
          .getImagesByNumDenInstit(this.proposta.NUMDENUNCIA)
          .subscribe(images => {
            this.proposta.IMATGES = images;
            this.imagesLoaded = Promise.resolve(true);
          });
      } else {
        this.imagesLoaded = Promise.resolve(true);
      }
  }

  ngOnDestroy() {
  //  this.thisDialogRef.close(this.proposta);
  }

  cancel() {
    //  this.thisDialogRef.close(false);
  }

  update(init) {
    document.location.href = '/';
  }



  getTitle() {
    return this.i18n('Veure proposta') + ' ' + this.proposta.NUMDENUNCIA;
  }


}
