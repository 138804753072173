export enum Parametros {
  AGRUPAR_PROPUESTAS_PAIS = 'AGRUPAR_PROPUESTAS_PAIS',
  GPSTIMEOUT = 'GPSTIMEOUT',
  GPSTRACKING = 'GPSTRACKING',
  GPSZOMINICIAL = 'GPSZOMINICIAL',
  AVISOSMAPA = 'AVISOSMAPA',
  MINUTOSSINACTIVIDAD = 'MINUTOSSINACTIVIDAD',
  APIKEYGOOGLEMAPS = 'APIKEYGOOGLEMAPS',
  AFECTACION_CALCULADA = 'AFECTACION_CALCULADA',
  PAIS_DEFECTO_PROPUESTAS='PAIS_DEFECTO_PROPUESTAS',
  SHOWLUPA = 'SHOWLUPA',
}
