/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tipus-matricules-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/dialog/filter-header/filter-header.component.ngfactory";
import * as i3 from "../../../../../shared/components/dialog/filter-header/filter-header.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i6 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i7 from "../../../../../shared/services/validation/validation.service";
import * as i8 from "../../../../../shared/components/form/form-tri-state/form-tri-state.component.ngfactory";
import * as i9 from "../../../../../shared/components/form/form-tri-state/form-tri-state.component";
import * as i10 from "./tipus-matricules-filter.component";
import * as i11 from "@ngx-translate/i18n-polyfill";
var styles_TipusMatriculesFilterComponent = [i0.styles];
var RenderType_TipusMatriculesFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TipusMatriculesFilterComponent, data: {} });
export { RenderType_TipusMatriculesFilterComponent as RenderType_TipusMatriculesFilterComponent };
export function View_TipusMatriculesFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-header", [["title", "Filtrar Tipus matr\u00EDcules"]], null, [[null, "apply"], [null, "reset"], [null, "dismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("apply" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (_co.reset() !== false);
        ad = (pd_1 && ad);
    } if (("dismiss" === en)) {
        var pd_2 = (_co.dismiss() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_FilterHeaderComponent_0, i2.RenderType_FilterHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.FilterHeaderComponent, [i4.MatDialogRef], { title: [0, "title"] }, { apply: "apply", reset: "reset", dismiss: "dismiss" }), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-form-text-edit", [["autofocus", ""], ["class", "col-sm-12"], ["id", "tipus-matricules-filter-descripcio"], ["label", "Descripci\u00F3"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.descripcio = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FormTextEditComponent_0, i5.RenderType_FormTextEditComponent)), i1.ɵdid(5, 4308992, null, 0, i6.FormTextEditComponent, [i7.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], autofocus: [3, "autofocus"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-form-tri-state", [["label", "Denunciable en zona blava"], ["labelNo", "No"], ["labelSi", "Si"], ["labelTodos", "Tots"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.denunciaZB = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormTriStateComponent_0, i8.RenderType_FormTriStateComponent)), i1.ɵdid(9, 4308992, null, 0, i9.FormTriStateComponent, [i7.ValidationService], { label: [0, "label"], labelSi: [1, "labelSi"], labelNo: [2, "labelNo"], labelTodos: [3, "labelTodos"], value: [4, "value"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-form-tri-state", [["label", "Denunciable policia"], ["labelNo", "No"], ["labelSi", "Si"], ["labelTodos", "Tots"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.denunciaPOL = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormTriStateComponent_0, i8.RenderType_FormTriStateComponent)), i1.ɵdid(11, 4308992, null, 0, i9.FormTriStateComponent, [i7.ValidationService], { label: [0, "label"], labelSi: [1, "labelSi"], labelNo: [2, "labelNo"], labelTodos: [3, "labelTodos"], value: [4, "value"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-form-tri-state", [["label", "\u00C9s reincident"], ["labelNo", "No"], ["labelSi", "Si"], ["labelTodos", "Tots"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.reincidents = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormTriStateComponent_0, i8.RenderType_FormTriStateComponent)), i1.ɵdid(13, 4308992, null, 0, i9.FormTriStateComponent, [i7.ValidationService], { label: [0, "label"], labelSi: [1, "labelSi"], labelNo: [2, "labelNo"], labelTodos: [3, "labelTodos"], value: [4, "value"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-form-tri-state", [["label", "Guarda LOG"], ["labelNo", "No"], ["labelSi", "Si"], ["labelTodos", "Tots"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.guardarLOG = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormTriStateComponent_0, i8.RenderType_FormTriStateComponent)), i1.ɵdid(15, 4308992, null, 0, i9.FormTriStateComponent, [i7.ValidationService], { label: [0, "label"], labelSi: [1, "labelSi"], labelNo: [2, "labelNo"], labelTodos: [3, "labelTodos"], value: [4, "value"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-form-tri-state", [["label", "Generar Alarma"], ["labelNo", "No"], ["labelSi", "Si"], ["labelTodos", "Tots"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.generarAlarma = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormTriStateComponent_0, i8.RenderType_FormTriStateComponent)), i1.ɵdid(17, 4308992, null, 0, i9.FormTriStateComponent, [i7.ValidationService], { label: [0, "label"], labelSi: [1, "labelSi"], labelNo: [2, "labelNo"], labelTodos: [3, "labelTodos"], value: [4, "value"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Filtrar Tipus matr\u00EDcules"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "Descripci\u00F3"; var currVal_2 = _co.filter.descripcio; var currVal_3 = "col-sm-12"; var currVal_4 = ""; var currVal_5 = "tipus-matricules-filter-descripcio"; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "Denunciable en zona blava"; var currVal_7 = "Si"; var currVal_8 = "No"; var currVal_9 = "Tots"; var currVal_10 = _co.filter.denunciaZB; _ck(_v, 9, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "Denunciable policia"; var currVal_12 = "Si"; var currVal_13 = "No"; var currVal_14 = "Tots"; var currVal_15 = _co.filter.denunciaPOL; _ck(_v, 11, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_16 = "\u00C9s reincident"; var currVal_17 = "Si"; var currVal_18 = "No"; var currVal_19 = "Tots"; var currVal_20 = _co.filter.reincidents; _ck(_v, 13, 0, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20); var currVal_21 = "Guarda LOG"; var currVal_22 = "Si"; var currVal_23 = "No"; var currVal_24 = "Tots"; var currVal_25 = _co.filter.guardarLOG; _ck(_v, 15, 0, currVal_21, currVal_22, currVal_23, currVal_24, currVal_25); var currVal_26 = "Generar Alarma"; var currVal_27 = "Si"; var currVal_28 = "No"; var currVal_29 = "Tots"; var currVal_30 = _co.filter.generarAlarma; _ck(_v, 17, 0, currVal_26, currVal_27, currVal_28, currVal_29, currVal_30); }, null); }
export function View_TipusMatriculesFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tipus-matricules-filter", [], null, null, null, View_TipusMatriculesFilterComponent_0, RenderType_TipusMatriculesFilterComponent)), i1.ɵdid(1, 245760, null, 0, i10.TipusMatriculesFilterComponent, [i4.MatDialogRef, i11.I18n, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TipusMatriculesFilterComponentNgFactory = i1.ɵccf("app-tipus-matricules-filter", i10.TipusMatriculesFilterComponent, View_TipusMatriculesFilterComponent_Host_0, {}, { notify: "notify" }, []);
export { TipusMatriculesFilterComponentNgFactory as TipusMatriculesFilterComponentNgFactory };
