<li class="nav-item">
  <a
    [class]="this.getClass()"
    [routerLink]="[this.link]"
    (click)='itemClicked($event)'
    id="{{this.getId()}}"
  >
    <img *ngIf="this.src !== undefined" class="navbar-brand-full" [src]="this.src"  height="14,55px" >
    <i *ngIf="this.src === undefined" class="nav-icon {{this.icon}}"></i> {{ this.text }}
  </a>
  <span [hidden]="!this.hasBadge()" class="badge {{this.badgeClass}}">{{this.badge}}</span>
</li>
