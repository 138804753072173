import { Component, OnInit, ViewChild } from '@angular/core';
import { PlanificacioTableComponent } from './planificacio-table/planificacio-table.component';
import { MatDialog } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { PlanificacioEditorComponent } from './planificacio-editor/planificacio-editor.component';

@Component({
  selector: 'app-planificacio',
  templateUrl: './planificacio.component.html',
  styleUrls: ['./planificacio.component.scss']
})
export class PlanificacioComponent implements OnInit {

  
  @ViewChild(PlanificacioTableComponent) PlanificacioTable: PlanificacioTableComponent;
  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  refresh():void{
    this.PlanificacioTable.load();
  }

  create():void{
    const data: ComponentDialog= Builder.newComponentDialog(true, false,null);
    const ref= this.dialog.open(PlanificacioEditorComponent, {
      width: '60%',
      maxHeight: '60vh',
      data: data,

    });
  }
  
}
