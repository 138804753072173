/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@agm/core/agm-core.ngfactory";
import * as i3 from "@agm/core";
import * as i4 from "./map.component";
var styles_MapComponent = [i0.styles];
var RenderType_MapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapComponent, data: {} });
export { RenderType_MapComponent as RenderType_MapComponent };
export function View_MapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "agm-map", [], [[2, "sebm-google-map-container", null]], [[null, "mapReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mapReady" === en)) {
        var pd_0 = (_co.mapReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AgmMap_0, i2.RenderType_AgmMap)), i1.ɵprd(4608, null, i3.CircleManager, i3.CircleManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i3.DataLayerManager, i3.DataLayerManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i3.MarkerManager, i3.MarkerManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i3.InfoWindowManager, i3.InfoWindowManager, [i3.GoogleMapsAPIWrapper, i1.NgZone, i3.MarkerManager]), i1.ɵprd(4608, null, i3.KmlLayerManager, i3.KmlLayerManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i3.LayerManager, i3.LayerManager, [i3.GoogleMapsAPIWrapper]), i1.ɵprd(4608, null, i3.PolygonManager, i3.PolygonManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i3.PolylineManager, i3.PolylineManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i3.RectangleManager, i3.RectangleManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(512, null, i3.GoogleMapsAPIWrapper, i3.GoogleMapsAPIWrapper, [i3.MapsAPILoader, i1.NgZone]), i1.ɵprd(512, null, i3.ɵa, i3.ɵa, [i3.MapsAPILoader]), i1.ɵdid(12, 770048, null, 0, i3.AgmMap, [i1.ElementRef, i3.GoogleMapsAPIWrapper, i1.PLATFORM_ID, i3.ɵa, i1.NgZone], { longitude: [0, "longitude"], latitude: [1, "latitude"], zoom: [2, "zoom"], styles: [3, "styles"] }, { mapReady: "mapReady" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.currentLocation.longitude; var currVal_2 = _co.currentLocation.latitude; var currVal_3 = _co.zoom; var currVal_4 = _co.styleMap; _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_MapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map-component", [], null, null, null, View_MapComponent_0, RenderType_MapComponent)), i1.ɵdid(1, 114688, null, 0, i4.MapComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapComponentNgFactory = i1.ɵccf("app-map-component", i4.MapComponent, View_MapComponent_Host_0, { overridedLocation: "overridedLocation", zoom: "zoom" }, { markerRouteEvent: "markerRouteEvent", markerPositionUpdate: "markerPositionUpdate" }, []);
export { MapComponentNgFactory as MapComponentNgFactory };
