import { PositonCoord } from "app/sections/zona-blava/estacionament/tramos/tramos/models/position";

export class CustomDataPoligon {

    associatedId: string;
    text: string;

    area: PositonCoord[];
    center: PositonCoord;

    color: string;
    alpha: number;

    constructor(associatedId: string, text: string, area: PositonCoord[], center: PositonCoord, color: string) {
        this.associatedId = associatedId;
        this.text = text;
        this.area = area;
        this.center = center;

        // El color lo podemos recibir directamente sin alpha como codigo hexadecimal o bien con alpha.
        if (color.startsWith('#')) {
            // Recibimos solo el color sin alpha.
            this.color = color;
            this.alpha = 1;
        } else {
            // Debemos parsear el color y obtener el alpha y el valor de color.
            const rgba: string[] = color.replace('rgba(', '').replace(')', '').split(',');
            if (rgba !== undefined && rgba !== null && rgba.length === 4) {
                this.alpha = Number(rgba[3]);
                this.color = this.convertToHexaColor(rgba[0], rgba[1], rgba[2]);
            } else {
                // No hemos podido parsear bien... Definimos el color negro.
                this.color = '#000000';
                this.alpha = 1;
            }
        }
    }

    convertToHexaColor(r: string, g: string, b: string): string {
        const rHex = this.convertToHexaNumber(r);
        const gHex = this.convertToHexaNumber(g);
        const bHex = this.convertToHexaNumber(b);

        return  '#' + rHex + gHex + bHex;
    }

    convertToHexaNumber(val: string): string {
        let hex = Number(val).toString(16);
        if (hex.length < 2) {
            hex = '0' + hex.toString();
        }
        return hex.toString();
    }

}
