import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef, ChangeDetectionStrategy, ViewChild, Injector, Inject, LOCALE_ID} from '@angular/core';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeComponent, OwlDateTimeFormats } from 'ng-pick-datetime';
import { OwlDateTime } from 'ng-pick-datetime/date-time/date-time.class';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { TranslateService } from '@ngx-translate/core';


export const MY_CUSTOM_FORMATS: OwlDateTimeFormats = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'DD/MM/YYYY HH:mm:ss',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'HH:mm:ss',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-form-date-edit',
  templateUrl: './form-date-edit.component.html',
  styleUrls: ['./form-date-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
  ]
})


export class FormDateEditComponent implements OnInit {
  val: any;
  validVal = false;
  validClass = 'ng-valid';

  @Input() label: string;
  @Input() get value() {
    return this.val;
  } set value(val) {
    if (this.isMoment(val) && ((typeof(val._i) !== 'string') ||  (typeof(val._i) === 'string' && val._i.length === 10))) {
        val = val.toDate();
    }
    let emmit=true;
    if(new Date(val) === new Date(this.val)){emmit=false;}
    this.val = val;
    this.valid();
    setTimeout(() => {
      if(emmit){this.valueChange.emit(this.val);}      
    }, 10);
  }
  @Output() valueChange = new EventEmitter();
  @Output() focusOut = new EventEmitter();
  @Input() class: string;
  @Input() type = 'calendar';
  @Input() id: string;
  @Input() disabled: boolean;
  @Input() initialValue = undefined;
  @Input() isReadOnly = false;
  @Input() required: boolean;
  @Input() view = 'month';
  @Input() mode = 'single';
  @Input() minDate = undefined;
  _maxDate: Date = undefined;
  @Input() get maxDate() {
    return this._maxDate;
  } set maxDate(val) {
    this._maxDate = val;
  }
  @ViewChild('dtp') dtp: any;

  lang: string;
  constructor(public cr: ViewContainerRef,
    @Inject(LOCALE_ID) public locale: string) {
    this.val = new Date();
    this.lang = this.locale;
    this.required = false;
  }
  modelChanged(event) {
    // 
    // this.valueChange.emit(this.val);
  }

  isMoment(val) {
    return ( val !== undefined && val !== null) && val._isAMomentObject !== null && val._isAMomentObject === true;
  }

  ngOnInit() {
    if (this.initialValue !== undefined) {
      this.value = this.initialValue;
    }
    if (this.view === 'year') {
      this.dtp.dateTimeFormats.datePickerInput = 'MM/YYYY';
    } else {
      this.dtp.dateTimeFormats.datePickerInput = 'DD/MM/YYYY';
    }
    this.dtp.firstDayOfWeek = 1;
    this.dtp.dateTimeAdapter.setLocale(this.lang);
    this.valid();
  }

  getName() {
    return '#' + this.id;
  }

  onKeyEnter(value: string) {
    const val = value.replace('/', '');
    if (val.length === 6) {
      this.value = this.splitDate(val);
    } else if (val.length === 8) {
      this.value = this.splitDateFullYear(val);
    } else {
      this.value = this.splitFullDate(value);
    }
  }

  private splitDateFullYear(value: string): Date {
    const day = parseInt(value.substr(0, 2), 10);
    const month = parseInt(value.substr(2, 2), 10) - 1;
    const year = parseInt(value.substr(4, 4), 10);
    return new Date(year, month, day, 0, 0, 0, 0);
  }
  private splitDate(value: string): Date {
    const day = parseInt(value.substr(0, 2), 10);
    const month = parseInt(value.substr(2, 2), 10) - 1;
    const year = parseInt(value.substr(4, 2), 10) + 2000;
    return new Date(year, month, day, 0, 0, 0, 0);
  }

  private splitFullDate(value: string): Date {
    const parts = value.split('/');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day, 0, 0, 0, 0);
    }
    return null;
  }

  change(event) {
    this.onKeyEnter(event.target.value);
  }

  valid(): boolean {
    const ok =  !this.errorRequired();
    this.validVal = ok;
    return ok;
  }


  isRequired() {
    return this.required !== false;
  }

  errorRequired() {
    return this.isRequired() && (this.value === null );
  }

  chosenMonthHandler(normalizedMonth) {
    if (this.view === 'year') {
      this.value = normalizedMonth;
      this.dtp.close();
    }
  }
  onFocusOutEvent(event: any){
    this.focusOut.emit(event);
  }
}
