import { EventEmitter, Injectable } from "@angular/core";
import { config } from "path-shared/configuration/config";
import { api } from "path-shared/configuration/urls";
import { AuthGuardService } from "path-shared/services/authentication/auth-guard.service";
import { HttpSecureClient } from "path-shared/services/http/httpSecureClient";
import { RespondreAlegacio } from "../models/respondre-alegacions.model";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
export class AlegacionsService {
    
    constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    }

    respondreAlegacions(exp: string, resultat: boolean, situacio: string, tipo: string): Observable<any> {
        let data = new RespondreAlegacio();
        data.exp = exp;
        data.resultat = resultat;
        data.situacio = situacio;
        data.tipo = tipo;
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlrespondrealegacions'], data);
        }
    }

}