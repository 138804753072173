<header class="row app-header navbar">
  <div class="header-logo">
    <button class="navbar-toggler sidebar-toggler d-lg-none mr-auto" type="button" data-toggle="sidebar-show">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" href="#">
      <img class="navbar-brand-full" src="assets/images/logo.png" width="140"  alt="SomDenWeb">
      <img class="navbar-brand-minimized" src="assets/images/logo.png" width="30" alt="SomDenWeb">
    </a>
    <span class="version-text"> {{this.version}} </span>
    <button class="navbar-toggler sidebar-toggler d-md-down-none" type="button" data-toggle="sidebar-lg-show" (click)="toggleHeader($event)">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- <a href="https://demociudadreal.trdcontrolcenter.com:8006/">
      <img class="navbar-brand-minimized" src="assets/images/docLogo.png" width="30" alt="trdControlCenter">
    </a> -->
   
  </div>
  <ul class="nav navbar-nav d-md-down-none">&nbsp;
      <li class="nav-item px-3">
          <h4>{{this.pageTitle}}</h4>
      </li>
      <!--
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Dashboard</a>
    </li>
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Users</a>
    </li>
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Settings</a>
    </li>
  -->
  </ul>
  <ul class="nav navbar-nav ml-auto">
    <li *ngIf="this.registroTurno !== undefined &&  this.registroTurno !== null " class="nav-item px-3">
      <span class="static-label">
        <div><span i18n>Torn grua: </span>{{ this.registroTurno.DESC_TURNO }}</div>
      </span>
    </li>
    <!-- Sincronització -->
    <li *ngIf="gtwinSync" class="nav-item dropdown d-md-down-none" i18n-title title="Sincronització necessària!">
      <a class="nav-link" [routerLink]="['/configuracio/sincronitzacio']" role="button">
        
        <i id="syncGtwinRequired" class="fas fa-sync sync-gtwin" ></i>
        <span style="margin-left: 0.5vh;" class="badge badge-pill badge-primary">!</span>
      </a>
  </li>
    <!-- Pda's pendents llegir -->
    <li class="nav-item dropdown d-md-down-none">
        <!-- link="/comunicacioPda" -->
        <a class="nav-link" [routerLink]="['/comunicacioPda']" role="button" aria-haspopup="true" aria-expanded="false" text="Pda's pendents llegir" i18n-text>
          <span [class]="[pdasPendentsLlegir === 0 ? '' : 'colorVermell']">
            <i [class]="[pdasPendentsLlegir === 0 ? 'far fa-comment' : 'far fa-comment-dots']"></i>
          </span>
        </a>
    </li>
    <!-- Alarmes -->
    <li class="nav-item dropdown d-md-down-none ">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" text="Alarmes" i18n-text>
        <i [class]="[alarmesPendents === 0 ? 'far fa-bell' : 'fa fa-bell']"></i>
        <span class="badge badge-pill badge-danger" *ngIf="alarmesPendents > 0">{{alarmesPendents}}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right scrollable-menu dropdown-menu-lg">
        <div class="dropdown-header text-center">
          <a routerLink="/alarmes"><strong><span i18n>Hi ha</span>&nbsp;{{alarmesPendents}}&nbsp;<span i18n>alarmes</span></strong></a>
        </div>
          <app-alarm-header-menu  *ngFor="let alarm of this.alarms" [alarm]="alarm"></app-alarm-header-menu>
      </div>
    </li>
    <!-- Alarmes GPS -->
    <li class="nav-item dropdown d-md-down-none ">
      <a  routerLink="/alarmes/visor" class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" text="Alarmes GPS" i18n-text>
        <i [class]="[alarmesGpsPendentsLlegir ? 'fa fa-exclamation-triangle a' : 'fa fa-exclamation-triangle']"></i>
        <!-- <span class="badge badge-pill badge-danger" *ngIf="alarmesGpsPendentsLlegir"></span> -->
      </a>
      <!-- <div class="dropdown-menu dropdown-menu-right scrollable-menu dropdown-menu-lg">
        <div class="dropdown-header text-center">
          <a routerLink="/alarmes/visor"><strong><span i18n>Hi ha</span>&nbsp;{{this.alarmesGpsPendentsLlegir }}&nbsp;<span i18n>alarmes</span></strong></a>
        </div>
          <app-alarm-header-menu  *ngFor="let alarm of this.alarms" [alarm]="alarm"></app-alarm-header-menu>
      </div> -->
    </li>
    <!-- Institucions -->
    <app-dropdown-institucions class="d-none d-sm-block d-md-block"></app-dropdown-institucions>
    <!-- Usuari-->
    <li class="nav-item dropdown" style="margin: 0 20px;">
      <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
        <a>
          {{ getFullName() }}
        </a>        
        <!--<img class="img-avatar" src="img/avatars/6.jpg" alt="{{ getFullName() }}">-->
      </a>
      <p class="ultCon" *ngIf="userLastCon !== null">
        Últ. con: {{ userLastCon | longDate}}
      </p>
      <div class="dropdown-menu dropdown-menu-right">
        <!-- <div class="dropdown-header text-center">
          <strong>Account</strong>
        </div>
        <a class="dropdown-item" href="#">
          <i class="fa fa-bell-o"></i> Updates
          <span class="badge badge-info">42</span>
        </a>
        <a class="dropdown-item" href="#">
          <i class="fa fa-envelope-o"></i> Messages
          <span class="badge badge-success">42</span>
        </a>
        <a class="dropdown-item" href="#">
          <i class="fa fa-tasks"></i> Tasks
          <span class="badge badge-danger">42</span>
        </a>
        <a class="dropdown-item" href="#">
          <i class="fa fa-comments"></i> Comments
          <span class="badge badge-warning">42</span>
        </a> -->
        <!-- <div class="dropdown-header text-center">
          <strong>Settings</strong>
        </div>
        <a class="dropdown-item" href="#">
          <i class="fa fa-user"></i> Profile</a> -->

        
          
        <!-- <a class="dropdown-item" href="#"> -->
          <!-- <i class="fa fa-usd"></i> Payments
          <span class="badge badge-secondary">42</span>
        </a>
        <a class="dropdown-item" href="#">
          <i class="fa fa-file"></i> Projects
          <span class="badge badge-primary">42</span>
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#">
          <i class="fa fa-shield"></i> Lock Account</a> -->
        <a class="dropdown-item" (click)="ChangePwd()"> 
          <i class="fa fa-key"></i><span i18n>Canviar contrasenya</span>
        </a>
        <a class="dropdown-item" (click)="Open2FA()"> 
          <i class="fa fa-shield-alt"></i><span i18n>Autentificació de doble factor</span>
        </a>
        <a class="dropdown-item" (click)="logout()">
          <i class="fa fa-lock"></i> Logout
        </a>
      </div>
    </li>
    </ul>
    <!--
  <button class="navbar-toggler aside-menu-toggler d-sm-down-none" type="button" data-toggle="aside-menu-sm-show">
    <span class="navbar-toggler-icon"></span>
  </button>
  <button class="navbar-toggler aside-menu-toggler d-sm-none" type="button" data-toggle="aside-menu-show-sm">
      <span class="navbar-toggler-icon"></span>
    </button>-->
</header>
<!--
  <aside class="aside-menu" style="margin-top: 29px;">
      <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#timeline" role="tab">
              <i class="icon-list"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#messages" role="tab">
              <i class="icon-speech"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#settings" role="tab">
              <i class="icon-settings"></i>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active" id="timeline" role="tabpanel">
            <div class="list-group list-group-accent">
            </div>
          </div>
        </div>
  </aside>
-->
