/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cobros-filtre.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/dialog/filter-header/filter-header.component.ngfactory";
import * as i3 from "../../../../../shared/components/dialog/filter-header/filter-header.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../../shared/components/form/form-date-range-edit/form-date-range-edit.component.ngfactory";
import * as i7 from "ng-pick-datetime/date-time/date-time-picker-intl.service";
import * as i8 from "../../../../../shared/services/form/timepicker-intl";
import * as i9 from "../../../../../shared/components/form/form-date-range-edit/form-date-range-edit.component";
import * as i10 from "../../../../../shared/components/form/form-multi-select/form-multi-select/form-multi-select.component.ngfactory";
import * as i11 from "../../../../../shared/components/form/form-multi-select/form-multi-select/form-multi-select.component";
import * as i12 from "@ngx-translate/i18n-polyfill";
import * as i13 from "./cobros-filtre.component";
import * as i14 from "../../../../../shared/components/loader/loader.service";
import * as i15 from "../../../../configuracio/aparells/services/aparells-service.service";
var styles_CobrosFiltreComponent = [i0.styles];
var RenderType_CobrosFiltreComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CobrosFiltreComponent, data: {} });
export { RenderType_CobrosFiltreComponent as RenderType_CobrosFiltreComponent };
export function View_CobrosFiltreComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-header", [["title", "Filtrar"]], null, [[null, "apply"], [null, "reset"], [null, "dismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("apply" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (_co.reset() !== false);
        ad = (pd_1 && ad);
    } if (("dismiss" === en)) {
        var pd_2 = (_co.dismiss() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_FilterHeaderComponent_0, i2.RenderType_FilterHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.FilterHeaderComponent, [i4.MatDialogRef], { title: [0, "title"] }, { apply: "apply", reset: "reset", dismiss: "dismiss" }), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "col-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [["mainForm", 1]], null, 13, "form", [["class", "row filter"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i5.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(5, 4210688, null, 0, i5.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.NgForm]), i1.ɵdid(7, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h5", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Filtre"])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "col-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 16777216, null, null, 2, "app-form-date-range-edit", [["label1", "Data inici"], ["label2", "Fecha fin"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.fechas = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DateRangeInputComponent_0, i6.RenderType_DateRangeInputComponent)), i1.ɵprd(4608, null, i7.OwlDateTimeIntl, i8.TimePickerIntl, []), i1.ɵdid(13, 114688, null, 0, i9.DateRangeInputComponent, [i1.ViewContainerRef], { label1: [0, "label1"], label2: [1, "label2"], value: [2, "value"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "col-12"], ["style", "padding: inherit;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-form-multi-select", [["titulo", "Equips"], ["tituloMultiSelect", "Selecciona'n un o m\u00E9s"]], null, [[null, "selectedValuesChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedValuesChange" === en)) {
        var pd_0 = ((_co.filter.aparatos = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_FormMultiSelectComponent_0, i10.RenderType_FormMultiSelectComponent)), i1.ɵdid(16, 114688, null, 0, i11.FormMultiSelectComponent, [i12.I18n], { tituloMultiSelect: [0, "tituloMultiSelect"], titulo: [1, "titulo"], data: [2, "data"], selectedValues: [3, "selectedValues"] }, { selectedValuesChange: "selectedValuesChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Filtrar"; _ck(_v, 1, 0, currVal_0); var currVal_8 = "Data inici"; var currVal_9 = "Fecha fin"; var currVal_10 = _co.filter.fechas; _ck(_v, 13, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = "Selecciona'n un o m\u00E9s"; var currVal_12 = "Equips"; var currVal_13 = _co.aparatos; var currVal_14 = _co.filter.aparatos; _ck(_v, 16, 0, currVal_11, currVal_12, currVal_13, currVal_14); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 7).ngClassValid; var currVal_6 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_CobrosFiltreComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cobros-filtre", [], null, null, null, View_CobrosFiltreComponent_0, RenderType_CobrosFiltreComponent)), i1.ɵdid(1, 245760, null, 0, i13.CobrosFiltreComponent, [i4.MatDialogRef, i14.LoaderService, i15.AparellService, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CobrosFiltreComponentNgFactory = i1.ɵccf("app-cobros-filtre", i13.CobrosFiltreComponent, View_CobrosFiltreComponent_Host_0, {}, { notify: "notify" }, []);
export { CobrosFiltreComponentNgFactory as CobrosFiltreComponentNgFactory };
