import { ValidationService } from './../../../services/validation/validation.service';
import { OnInit, EventEmitter, AfterViewInit } from '@angular/core';
var FormTriStateComponent = /** @class */ (function () {
    function FormTriStateComponent(validation) {
        this.validation = validation;
        this.valueChange = new EventEmitter();
        this.triStateDisabled = false;
        this.val = true;
        this.disabled = false;
        this.required = false;
    }
    Object.defineProperty(FormTriStateComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            this.val = val;
            this.setSelected();
            this.valueChange.emit(this.val);
        },
        enumerable: true,
        configurable: true
    });
    FormTriStateComponent.prototype.ngOnInit = function () {
        this.setSelected();
    };
    FormTriStateComponent.prototype.setSelected = function () {
        switch (this.value) {
            case null:
            case undefined:
                this.selected = 'option3';
                break;
            case true:
                this.selected = 'option1';
                break;
            case false:
                this.selected = 'option2';
                break;
        }
    };
    FormTriStateComponent.prototype.ngAfterViewInit = function () {
    };
    FormTriStateComponent.prototype.clickedOption = function (option, value) {
        if (!this.disabled) {
            this.selected = option;
            this.value = value;
        }
    };
    FormTriStateComponent.prototype.valid = function () {
        if (this.isRequired()) {
            return this.validation.isBoolean(this.value);
        }
        else {
            return true;
        }
    };
    FormTriStateComponent.prototype.isRequired = function () {
        return this.required !== false;
    };
    FormTriStateComponent.prototype.errorRequired = function () {
        return this.isRequired() && !this.validation.isBoolean(this.value);
    };
    return FormTriStateComponent;
}());
export { FormTriStateComponent };
