/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./models-vehicles.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i3 from "../../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i4 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i5 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i6 from "../../../../../shared/services/validation/validation.service";
import * as i7 from "../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i8 from "../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i9 from "@angular/common";
import * as i10 from "@angular/material/dialog";
import * as i11 from "./models-vehicles.component";
var styles_ModelsVehiclesComponent = [i0.styles];
var RenderType_ModelsVehiclesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModelsVehiclesComponent, data: {} });
export { RenderType_ModelsVehiclesComponent as RenderType_ModelsVehiclesComponent };
function View_ModelsVehiclesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tool-button", [["color", "has-color-green"], ["icon", "fa-save"], ["text", "Guardar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToolButtonComponent_0, i2.RenderType_ToolButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" })], function (_ck, _v) { var currVal_0 = "Guardar"; var currVal_1 = "has-color-green"; var currVal_2 = "fa-save"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ModelsVehiclesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tool-button", [["color", "has-color-red"], ["icon", "fa-trash-alt"], ["text", "Eliminar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToolButtonComponent_0, i2.RenderType_ToolButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" })], function (_ck, _v) { var currVal_0 = "Eliminar"; var currVal_1 = "has-color-red"; var currVal_2 = "fa-trash-alt"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ModelsVehiclesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-6"], ["id", "models-vehicles-nom"], ["label", "Nom"], ["maxLenght", "150"], ["required", ""]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.local_data.nombre = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FormTextEditComponent_0, i4.RenderType_FormTextEditComponent)), i1.ɵdid(2, 4308992, null, 0, i5.FormTextEditComponent, [i6.ValidationService], { label: [0, "label"], required: [1, "required"], value: [2, "value"], class: [3, "class"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-6"], ["id", "models-vehicles-REFiNT"], ["label", "Refer\u00E8ncia Integraci\u00F3"], ["maxLenght", "50"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.local_data.referenciaIntegracion = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FormTextEditComponent_0, i4.RenderType_FormTextEditComponent)), i1.ɵdid(4, 4308992, null, 0, i5.FormTextEditComponent, [i6.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], id: [3, "id"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Nom"; var currVal_1 = ""; var currVal_2 = _co.local_data.nombre; var currVal_3 = "col-xl-6"; var currVal_4 = "models-vehicles-nom"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "Refer\u00E8ncia Integraci\u00F3"; var currVal_6 = _co.local_data.referenciaIntegracion; var currVal_7 = "col-xl-6"; var currVal_8 = "models-vehicles-REFiNT"; _ck(_v, 4, 0, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_ModelsVehiclesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["style", "justify-content: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Est\u00E0s segur que vols borrar el registre "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["? "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.local_data.nombre; _ck(_v, 3, 0, currVal_0); }); }
export function View_ModelsVehiclesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "app-toolbar", [], null, null, null, i7.View_ToolbarComponent_0, i7.RenderType_ToolbarComponent)), i1.ɵdid(1, 114688, null, 0, i8.ToolbarComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ModelsVehiclesComponent_1)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ModelsVehiclesComponent_2)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "app-tool-button", [["color", "has-color-red"], ["icon", "fa fa-times "], ["text", "Cancel\u00B7lar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToolButtonComponent_0, i2.RenderType_ToolButtonComponent)), i1.ɵdid(7, 114688, null, 0, i3.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i10.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModelsVehiclesComponent_3)), i1.ɵdid(11, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModelsVehiclesComponent_4)), i1.ɵdid(13, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = (_co.action !== "Delete"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.action === "Delete"); _ck(_v, 5, 0, currVal_1); var currVal_2 = "Cancel\u00B7lar"; var currVal_3 = "has-color-red"; var currVal_4 = "fa fa-times "; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = (_co.action !== "Delete"); _ck(_v, 11, 0, currVal_5); var currVal_6 = (_co.action === "Delete"); _ck(_v, 13, 0, currVal_6); }, null); }
export function View_ModelsVehiclesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-models-vehicles", [], null, null, null, View_ModelsVehiclesComponent_0, RenderType_ModelsVehiclesComponent)), i1.ɵdid(1, 114688, null, 0, i11.ModelsVehiclesComponent, [i10.MatDialogRef, [2, i10.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModelsVehiclesComponentNgFactory = i1.ɵccf("app-models-vehicles", i11.ModelsVehiclesComponent, View_ModelsVehiclesComponent_Host_0, {}, {}, []);
export { ModelsVehiclesComponentNgFactory as ModelsVehiclesComponentNgFactory };
