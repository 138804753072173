/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-file-viewer-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../dialog/dialog-header/dialog-header.component";
import * as i4 from "./generic-file-viewer-dialog.component";
import * as i5 from "@angular/material/dialog";
var styles_GenericFileViewerDialogComponent = [i0.styles];
var RenderType_GenericFileViewerDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericFileViewerDialogComponent, data: {} });
export { RenderType_GenericFileViewerDialogComponent as RenderType_GenericFileViewerDialogComponent };
export function View_GenericFileViewerDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "img-fluid"], ["style", "vertical-align: middle;"]], [[1, "src", 4]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.fileName, ""); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imageData; _ck(_v, 3, 0, currVal_1); }); }
export function View_GenericFileViewerDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-file-viewer-dialog", [], null, null, null, View_GenericFileViewerDialogComponent_0, RenderType_GenericFileViewerDialogComponent)), i1.ɵdid(1, 114688, null, 0, i4.GenericFileViewerDialogComponent, [i5.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericFileViewerDialogComponentNgFactory = i1.ɵccf("app-generic-file-viewer-dialog", i4.GenericFileViewerDialogComponent, View_GenericFileViewerDialogComponent_Host_0, {}, {}, []);
export { GenericFileViewerDialogComponentNgFactory as GenericFileViewerDialogComponentNgFactory };
