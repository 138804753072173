export class InfoNotificacio {
    MaxDataEnviamentDenuncies: Date;
    MaxDataPropostes: Date;
    MaxDataPropostesRepo: Date;
    MaxDataResolucions: Date;
    MaxDataReposicio: Date;

    EnvioDenunciesCurrent: number;
    EnvioDenunciesAll: number;
    PropostesCurrent: number;
    PropostesAll: number;
    Resolucions: number;
    NoResolucionsCurrent: number;
    NoResolucionsAll: number;
    RecursosCurrent: number;
    RecursosAll: number;

    SicerActivo: boolean;

    constructor() {
        this.SicerActivo = false;
        this.EnvioDenunciesCurrent = 0;
        this.EnvioDenunciesAll = 0;
        this.MaxDataEnviamentDenuncies = null;
        this.PropostesCurrent = 0;
        this.PropostesAll = 0;
        this.MaxDataPropostes = null;
        this.MaxDataPropostesRepo = null;
        this.Resolucions = 0;
        this.NoResolucionsCurrent = 0;
        this.NoResolucionsAll = 0;
        this.RecursosCurrent = 0;
        this.RecursosAll = 0;
        this.MaxDataReposicio = null;
    }
}
