/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./escala-ocupacion-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i3 from "../../../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i4 from "../../../../../../shared/services/validation/validation.service";
import * as i5 from "@angular/common";
import * as i6 from "./escala-ocupacion-editor.component";
var styles_EscalaOcupacionEditorComponent = [i0.styles];
var RenderType_EscalaOcupacionEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EscalaOcupacionEditorComponent, data: {} });
export { RenderType_EscalaOcupacionEditorComponent as RenderType_EscalaOcupacionEditorComponent };
function View_EscalaOcupacionEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "input", [["class", "form-check-input"], ["style", "color: transparent; position:inherit; margin-left: 0px; height: 200px; width: 100px; cursor: pointer;"]], [[4, "background", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeColor(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getColor(_v.context.$implicit); var currVal_1 = !_co.currentEditing; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_EscalaOcupacionEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"], ["role", "alert"], ["style", "margin-bottom: 0px; margin-top: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Has de seleccionar un color i definir un rang superior! "]))], null, null); }
export function View_EscalaOcupacionEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-form-text-edit", [["class", "col-4"], ["id", "escala-ocupacion-editor-rang"], ["label", "Rang superior"], ["number", ""]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.currentEscala.rangoSuperior = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormTextEditComponent_0, i2.RenderType_FormTextEditComponent)), i1.ɵdid(2, 4308992, null, 0, i3.FormTextEditComponent, [i4.ValidationService], { label: [0, "label"], required: [1, "required"], value: [2, "value"], class: [3, "class"], number: [4, "number"], isReadOnly: [5, "isReadOnly"], id: [6, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row"], ["style", "justify-content: center;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EscalaOcupacionEditorComponent_1)), i1.ɵdid(5, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "row"], ["style", "justify-content: center;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EscalaOcupacionEditorComponent_2)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Rang superior"; var currVal_1 = _co.isError; var currVal_2 = _co.currentEscala.rangoSuperior; var currVal_3 = "col-4"; var currVal_4 = ""; var currVal_5 = !_co.currentEditing; var currVal_6 = "escala-ocupacion-editor-rang"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.coloresBase; _ck(_v, 5, 0, currVal_7); var currVal_8 = _co.isError; _ck(_v, 8, 0, currVal_8); }, null); }
export function View_EscalaOcupacionEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-escala-ocupacion-editor", [], null, null, null, View_EscalaOcupacionEditorComponent_0, RenderType_EscalaOcupacionEditorComponent)), i1.ɵdid(1, 114688, null, 0, i6.EscalaOcupacionEditorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EscalaOcupacionEditorComponentNgFactory = i1.ɵccf("app-escala-ocupacion-editor", i6.EscalaOcupacionEditorComponent, View_EscalaOcupacionEditorComponent_Host_0, { currentEditing: "currentEditing", currentEscala: "currentEscala", coloresBase: "coloresBase" }, {}, []);
export { EscalaOcupacionEditorComponentNgFactory as EscalaOcupacionEditorComponentNgFactory };
