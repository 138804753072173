import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { ExpRelModel } from '../../../../models/expRel.model';

@Component({
  selector: 'app-exp-relacionats',
  templateUrl: './exp-relacionats.component.html'
})
export class ExpRelacionatsComponent implements OnInit {

  @Input() data: ExpRelModel[];

  constructor(
    private denunciesService: DenunciesService,
    private router: Router
    ) { }

  ngOnInit() {
  }

  onExpClicked(exp) {
    this.denunciesService.getSomIdDenunciaByExp(exp).subscribe(res => {
      this.router.navigate(['denuncies/editar-denuncia', res]);
    });
  }
}
