import { ValidationService } from '../../../services/validation/validation.service';
import { OnInit, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Reflection } from 'path-shared/services/reflection/reflection';
var FormLargeComboEditComponent = /** @class */ (function () {
    function FormLargeComboEditComponent(validation) {
        this.validation = validation;
        this.myControl = new FormControl();
        this.filteredOptions = new Subject();
        this.valueChange = new EventEmitter();
        this.required = false;
        this.isReadOnly = false;
    }
    Object.defineProperty(FormLargeComboEditComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            var _this = this;
            if (this.val !== val) {
                this.val = val;
                this.setCurrentValue();
                setTimeout(function () {
                    _this.valueChange.emit(_this.val);
                }, 10);
            }
        },
        enumerable: true,
        configurable: true
    });
    FormLargeComboEditComponent.prototype.autosize = function () {
        var el = document.querySelector('textarea');
        setTimeout(function () {
            el.style.cssText = 'height:auto; padding:0';
            // for box-sizing other than "content-box" use:
            // el.style.cssText = '-moz-box-sizing:content-box';
            el.style.cssText = 'height:' + el.scrollHeight + 'px';
        }, 0);
    };
    FormLargeComboEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.myControl.valueChanges.subscribe(function (value) {
            if (typeof value !== 'string') {
                _this.setValue(value);
            }
            if (value !== undefined && value !== '') {
                if (value[_this.textField] === undefined) {
                    _this.filteredOptions.next(_this._filter(value));
                }
                else {
                    _this.filteredOptions.next(_this._filter(value[_this.textField]));
                }
            }
            else {
                _this.filteredOptions.next(_this._filter(''));
            }
        });
        this.setFilterOptions();
    };
    FormLargeComboEditComponent.prototype.setFilterOptions = function () {
        var _this = this;
        if (Reflection.empty(this.items)) {
            setTimeout(function () { return _this.setFilterOptions(); }, 200);
        }
        else {
            this.filteredOptions.subscribe(function (items) {
                if (items.length > 0) {
                    _this.first = items[0];
                }
                else {
                    _this.first = undefined;
                }
            });
        }
    };
    FormLargeComboEditComponent.prototype.ngAfterViewInit = function () {
    };
    FormLargeComboEditComponent.prototype._filter = function (value) {
        var _this = this;
        if (Reflection.empty(this.items)) {
            setTimeout(function () { return _this._filter(value); }, 100);
            return [];
        }
        else {
            if (value === null || value === undefined) {
                value = '';
            }
            if (value === '') {
                return this.items;
            }
            else {
                var filterValue_1 = value.toLowerCase();
                return this.items.filter(function (x) { return x[_this.textField].toString().toLowerCase().includes(filterValue_1); }).sort(function (a, b) { return a.length - b.length; });
            }
        }
    };
    FormLargeComboEditComponent.prototype.setValue = function (option) {
        if (option !== undefined) {
            this.value = this.getValue(option);
            this.autosize();
        }
    };
    FormLargeComboEditComponent.prototype.setCurrentValue = function () {
        var _this = this;
        if (this.items !== undefined) {
            this.selectedValue = this.items.find(function (x) { return x[_this.valueField] !== undefined && x[_this.valueField] !== null && _this.value !== null && x[_this.valueField].toString() === _this.value.toString(); });
            this.myControl.setValue(this.selectedValue);
        }
        else {
            setTimeout(function () { return _this.setCurrentValue(); }, 100);
        }
    };
    FormLargeComboEditComponent.prototype.getValue = function (option) {
        if (this.valueField === undefined) {
            return null;
        }
        else {
            if (option === null || option === undefined) {
                return null;
            }
            else {
                return option[this.valueField];
            }
        }
    };
    FormLargeComboEditComponent.prototype.getText = function (option) {
        if (this.textField === undefined) {
            return null;
        }
        else {
            if (option === null || option === undefined) {
                return null;
            }
            else {
                return option[this.textField];
            }
        }
    };
    FormLargeComboEditComponent.prototype.getDisplay = function () {
        var _this = this;
        return function (val) { return _this.getText(val); };
    };
    FormLargeComboEditComponent.prototype.valid = function () {
        return !this.errorRequired();
    };
    FormLargeComboEditComponent.prototype.isRequired = function () {
        return this.required !== false;
    };
    FormLargeComboEditComponent.prototype.errorRequired = function () {
        return this.isRequired() && this.validation.isEmpty(this.value);
    };
    FormLargeComboEditComponent.prototype.togglePanel = function (evt) {
        evt.stopPropagation();
        if (this.inputAutoComplit.panelOpen) {
            this.inputAutoComplit.closePanel();
        }
        else {
            this.filteredOptions.next(this.items);
            this.inputAutoComplit.openPanel();
            this.input.nativeElement.focus();
        }
    };
    FormLargeComboEditComponent.prototype.getClass = function () {
        var ret = '';
        if (this.isRequired()) {
            if (this.errorRequired()) {
                ret = 'ng-invalid';
            }
        }
        return ret;
    };
    FormLargeComboEditComponent.prototype.onEnter = function () {
        if (this.first !== undefined) {
            this.myControl.setValue(this.first);
            this.inputAutoComplit.closePanel();
        }
    };
    FormLargeComboEditComponent.prototype.desFocus = function () {
        this.input.nativeElement.blur();
    };
    FormLargeComboEditComponent.prototype.focusOut = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.myControl.value !== undefined) {
                if (_this.myControl.value !== '' && _this.first !== undefined) {
                    try {
                        _this.myControl.setValue(_this.myControl.value);
                        _this.setValue(_this.myControl.value);
                    }
                    catch (_a) {
                        _this.myControl.setValue(_this.first);
                        _this.setValue(_this.first);
                    }
                    _this.inputAutoComplit.closePanel();
                }
                else {
                    _this.myControl.setValue(undefined);
                    _this.setValue(undefined);
                    _this.inputAutoComplit.closePanel();
                }
            }
        }, 150);
    };
    FormLargeComboEditComponent.prototype.setSelect = function (value) {
        if (value !== undefined) {
            this.myControl.patchValue([]);
            this.myControl.setValue(value);
        }
    };
    FormLargeComboEditComponent.prototype.resetCombo = function () {
        this.myControl.patchValue([]);
    };
    return FormLargeComboEditComponent;
}());
export { FormLargeComboEditComponent };
