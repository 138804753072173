<div class="row justify-content-between">
    <div class="col">
        <app-filter-selector [filter]="this.filter"></app-filter-selector>
    </div>
    <div class="col">
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
        </mat-paginator>
    </div>
</div>

<div class="table-sdw is-fixed">

    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

        <ng-container matColumnDef="incorpora" *ngIf="this.selectButton">
            <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
            <mat-cell *matCellDef="let row" class="text-center"><a (click)="this.selectCurrent(row.SECURE_ID, row.sigles, row.nomCarrer)" title="Incorporar" i18n-title><i class="fa fa-plus-square has-color-aqua"></i></a></mat-cell>
        </ng-container>


        <!-- Id Column -->
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
            <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.id}}</a></mat-cell>
        </ng-container>

        <!-- Sigles Column -->
        <ng-container matColumnDef="sigles">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Sigles</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.sigles}}</mat-cell>
        </ng-container>

        <!-- Descripcio Column -->
        <ng-container matColumnDef="nomCarrer">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom Carrer</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.nomCarrer}}</mat-cell>
        </ng-container>

        <!-- Descripcio Column -->
        <ng-container matColumnDef="codigoExterno">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi extern</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.codigoExterno}}</mat-cell>
        </ng-container>

        <!-- Municipio -->
        <ng-container matColumnDef="nomMunicipi">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Municipi</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.nomMunicipi}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
</div>
