import { saveAs } from 'file-saver';
import { GenericFileDisplayerFrameComponent } from './../generic-file-displayer-frame/generic-file-displayer-frame.component';
import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { Dictionary } from 'path-shared/models/dictionary';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { MAT_DIALOG_DATA } from '@angular/material';
import { GenericFile } from '../../model/generic-file';
import { GenericFileEventsService } from '../../services/generic-file-events.service';

declare var require: any;

@Component({
  selector: 'app-generic-file-displayer-page',
  templateUrl: './generic-file-displayer-page.component.html',
  styleUrls: ['./generic-file-displayer-page.component.scss']
})
export class GenericFileDisplayerPageComponent implements OnInit {

  // Atributos relacionados con la lista de ficheros.
  _files: GenericFile[];
  @Input() get files() {
    return this._files;
  } set files(val) {
    this._files = val;
    const filesDic = new Array<Dictionary>();
    this.files.forEach(element => {
      filesDic.push(new Dictionary(element.id, element.nombre));
    });
    this.filesDictionary.next(filesDic);
  }
  filesDictionary: BehaviorSubject<Dictionary[]>;

  fileEvents: GenericFileEventsService;

  // Atributos de la vista.
  @ViewChild('fileDisplayer') fileDisplayer: GenericFileDisplayerFrameComponent;
  // @ViewChild('fileUploader') fileUploader: GenericFileUploader;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private i18n: I18n
  ) {
    this.filesDictionary = new BehaviorSubject<Dictionary[]>(undefined);
    this.files = data.files;
    this.fileEvents = data.fileEvents;

    // Nos suscribimos a los eventos de ficheros (comunicacion con el componente que lo haya llamado)
    this.fileEvents.deleteFilesComplete.subscribe((ids: any[]) => {
      // El componente parent ha eliminado ficheros, updateamos la lista.
      const newFiles = [];
      this.files.forEach(element => {
        if (ids.find(id => element.id === id) === undefined) {
          // Este no se ha borrado, lo mantenemos.
          newFiles.push(element);
        }
      });
      this.files = newFiles;
    });

    this.fileEvents.uploadFileComplete.subscribe((newFile: GenericFile) => {
      const newFiles = this.files;
      newFiles.push(newFile);
      this.files = newFiles;
    });
  }

  ngOnInit() {
  }

  deleteFiles(fileIds: any[]): void {
    this.fileEvents.deleteFileCommand.emit(fileIds);
  }

  downloadFiles(fileIds: any[]): void {
    if (fileIds.length > 1) {
      const fecha = new Date();
      let NomFitxer = this.i18n('Fitxers_') + ( fecha.getFullYear() + (fecha.getMonth() + 1).toString() + fecha.getDate().toString());
      NomFitxer += '_' + ( fecha.getHours().toString() + fecha.getMinutes().toString() + fecha.getSeconds().toString()  ) + '.zip';
      const JSZip = require( 'jszip' );
      const zip = new JSZip();

      fileIds.forEach(id => {
        const file: GenericFile = this.files.find(f => f.id === id);
        const bytearray: Uint8Array = this.getDisplayablePdf(file.fichero);
        const blob = new Blob([bytearray]);
        zip.file(file.nombre, bytearray, {binary: true});
      });
      zip.generateAsync({type: 'blob' }).then(function(content) {
        saveAs(content, NomFitxer);
      });
    } else if (fileIds.length === 1) {
      const id = fileIds[0];
      const file: GenericFile = this.files.find(f => f.id === id);
      const bytearray: Uint8Array = this.getDisplayablePdf(file.fichero);
      const blob = new Blob([bytearray]);
      saveAs(blob, file.nombre);
    }
  }

  uploadFile(file: any): void {
    this.fileEvents.uploadFileCommand.next(file);
  }

  showFile(fileId: any): void {
    // Determinamos si es un pdf o una imagen.
    const fileToShow = this.files.find(f => f.id === fileId);
    const extension = fileToShow.nombre.substring(fileToShow.nombre.lastIndexOf('.') + 1).toLowerCase();
    if (!fileToShow.fileFound || extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'jfif') {
      // Es una imagen.
      this.fileDisplayer.showImage(fileToShow);
    } else {
      // Es un pdf.
      this.fileDisplayer.showPdf(fileToShow);
    }
  }

  changeShowingFile(orientation: string, fileId: any) {
    const currentPosition = this.files.findIndex(f => f.id === fileId);
    if (currentPosition !== -1) {
      if (orientation === 'previous') {
        // Buscamos el id anterior al fileId pasado.
        const previousPosition = currentPosition - 1;
        if (previousPosition >= 0 && previousPosition !== currentPosition) {
          // Tenemos algun fichero anterior que podemos mostrar.
          const newFileId = this.files[previousPosition].id;
          this.showFile(newFileId);
        }
      } else {
        // Buscamos el id posterior al fileId pasado.
        const nextPosition = currentPosition + 1;
        if (nextPosition <= this.files.length - 1 && nextPosition !== currentPosition) {
          // Tenemos algun fichero posterior que podemos mostrar.
          const newFileId = this.files[nextPosition].id;
          this.showFile(newFileId);
        }
      }
    }
  }

  getDisplayablePdf(dataURI: string): Uint8Array {
    const raw = window.atob(dataURI);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    let i = 0;
    for (i; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

}
