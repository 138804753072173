import { Injectable, EventEmitter, Output, Input } from '@angular/core';


export class GenericFileService {


    constructor() {
    }

  
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              let formattedResult = reader.result.toString();
              const indexTo = formattedResult.indexOf('base64,');
              formattedResult = formattedResult.substr(indexTo + 'base64,'.length);
              resolve(formattedResult);
            };
            reader.onerror = error => reject(error);
        });
      }
}
