var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
var defaultSortParam = 'id';
var defaultSortDirection = 'asc';
var GenericFileFilterService = /** @class */ (function (_super) {
    __extends(GenericFileFilterService, _super);
    function GenericFileFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GenericFileFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('id', filter.id ? filter.id : '')
            .append('nombre', filter.nombre ? filter.nombre : '')
            .append('fechaGravacion', filter.fechaGravacion ? filter.fechaGravacion : '')
            .append('eliminado', filter.eliminado ? filter.eliminado : '')
            .append('tipoRecurso', filter.tipoRecurso ? filter.tipoRecurso : '')
            .append('idRegistroAsociado', filter.idRegistroAsociado ? filter.idRegistroAsociado : '')
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return GenericFileFilterService;
}(CustomFilterService));
export { GenericFileFilterService };
