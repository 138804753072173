/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alegacions-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/dialog/filter-header/filter-header.component.ngfactory";
import * as i3 from "../../../../shared/components/dialog/filter-header/filter-header.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i6 from "../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i7 from "../../../../shared/services/validation/validation.service";
import * as i8 from "../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i9 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i10 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i11 from "../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i12 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i13 from "./alegacions-filter.component";
var styles_AlegacionsFilterComponent = [i0.styles];
var RenderType_AlegacionsFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlegacionsFilterComponent, data: {} });
export { RenderType_AlegacionsFilterComponent as RenderType_AlegacionsFilterComponent };
export function View_AlegacionsFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-header", [["title", "Filtrar alegacions"]], null, [[null, "apply"], [null, "reset"], [null, "dismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("apply" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (_co.reset() !== false);
        ad = (pd_1 && ad);
    } if (("dismiss" === en)) {
        var pd_2 = (_co.dismiss() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_FilterHeaderComponent_0, i2.RenderType_FilterHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.FilterHeaderComponent, [i4.MatDialogRef], { title: [0, "title"] }, { apply: "apply", reset: "reset", dismiss: "dismiss" }), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-form-text-edit", [["class", "col-sm-3"], ["label", "Agent"], ["maxLenght", "20"], ["number", "true"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.AGENTE = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FormTextEditComponent_0, i5.RenderType_FormTextEditComponent)), i1.ɵdid(5, 4308992, null, 0, i6.FormTextEditComponent, [i7.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], number: [3, "number"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(6, 16777216, null, null, 3, "app-form-date-edit", [["class", "col-sm-3"], ["label", "Data d'entrada de l'alegaci\u00F3"], ["type", "calendar"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.DATAPLEC = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormDateEditComponent_0, i8.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i9.DateTimeAdapter, i10.MomentDateTimeAdapter, [i9.OWL_DATE_TIME_LOCALE]), i1.ɵdid(8, 114688, null, 0, i11.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"], type: [3, "type"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i12.OWL_DATE_TIME_FORMATS, i11.MY_CUSTOM_FORMATS, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Filtrar alegacions"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "Agent"; var currVal_2 = _co.filter.AGENTE; var currVal_3 = "col-sm-3"; var currVal_4 = "true"; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "Data d'entrada de l'alegaci\u00F3"; var currVal_6 = _co.filter.DATAPLEC; var currVal_7 = "col-sm-3"; var currVal_8 = "calendar"; _ck(_v, 8, 0, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_AlegacionsFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alegacions-filter", [], null, null, null, View_AlegacionsFilterComponent_0, RenderType_AlegacionsFilterComponent)), i1.ɵdid(1, 114688, null, 0, i13.AlegacionsFilterComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlegacionsFilterComponentNgFactory = i1.ɵccf("app-alegacions-filter", i13.AlegacionsFilterComponent, View_AlegacionsFilterComponent_Host_0, {}, { notify: "notify" }, []);
export { AlegacionsFilterComponentNgFactory as AlegacionsFilterComponentNgFactory };
