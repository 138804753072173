import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnvioBOEFilterDto } from '../../../models/envio-boe-filter.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'app-envio-boe-page',
  templateUrl: './envio-boe-page.component.html',
  styleUrls: ['./envio-boe-page.component.scss']
})
export class EnvioBoePageComponent implements OnInit {
  
  dropdownSettings = {
    idField: 'item_id',
    textField: 'item_text',
    itemsShowLimit: 2,
    singleSelection: true
  };
  dropdownEnvios = [
    { 'item_id': 0, 'item_text': this.i18n('Denuncias')},
    { 'item_id': 1, 'item_text': this.i18n('Alegaciones') },
    { 'item_id': 2, 'item_text': this.i18n('Recurso alegaciones') },
    { 'item_id': 3, 'item_text': this.i18n('Resoluciones') },
    { 'item_id': 4, 'item_text': this.i18n('Sanciones firmes') }
  ]

  val: EnvioBOEFilterDto;
  @Input() get value() {
    return this.val;
  } set value(val) {
    this.val = val;
    setTimeout(() => {
      this.valueChange.emit(this.val);
    }, 10);
  }
  @Output() valueChange = new EventEmitter();
  @Output() applyFilter= new EventEmitter();
  @Output() publish= new EventEmitter();
  @Output() historico= new EventEmitter();

  valueResumen: any;
  @Input() get resumen() {
    return this.valueResumen;
  } set resumen(valueResumen) {
    this.valueResumen = valueResumen;
  }
  constructor(
    public auth: AuthorizationService,
    private i18n: I18n) { }

  ngOnInit() {
  }
  filtrar(){
    this.applyFilter.emit();
  }
  publicar(){
    this.publish.emit();
  }
  history(){
    this.historico.emit();
  }

  onDropdownItemSelect(item: any) {
    this.value.envioDenuncias = false;
    this.value.envioAlegaciones = false;
    this.value.envioRecursoAlegaciones = false;
    this.value.envioSancionesFirmes = false;
    this.value.envioResoluciones = false;
    switch (item.item_id) {
      case 0:
        this.value.envioDenuncias = true;
        break;
      case 1:
        this.value.envioAlegaciones = true;
        break;
      case 2:
        this.value.envioRecursoAlegaciones = true;
        break;
      case 3:
        this.value.envioResoluciones = true;
        break;
      case 4:
        this.value.envioSancionesFirmes = true;
        break;
    }
    this.filtrar();
  }
}
