import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SingleEditDialogComponent } from '../single-edit-dialog/single-edit-dialog.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { WaitDialogData } from './wait-dialog-data.model';

@Component({
  selector: 'app-wait-dialog',
  templateUrl: './wait-dialog.component.html',
  styleUrls: ['./wait-dialog.component.scss']
})
export class WaitDialogComponent implements OnInit {
  options: WaitDialogData;

  constructor(
    public thisDialogRef: MatDialogRef<SingleEditDialogComponent>,
    private i18n: I18n,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: WaitDialogData,
    ) {
      this.options = data;
    }


  ngOnInit() {
  }

}
