import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { NotificacionsFilter } from '../models/notificacions-filter';


const defaultSortParam = 'element';
const defaultSortDirection = 'asc';

@Injectable()
export class NotificacionsFilterService extends CustomFilterService {
  public createFilterParams(filter: NotificacionsFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('element', (filter.element ? filter.element.toString() : ''))
    .append('tipus', filter.tipus ? filter.tipus : '')
    .append('notificacio', filter.notificacio ? filter.notificacio.toString() : '')
    .append('enviament', filter.enviament ? filter.enviament.toString() : '')
    .append('acceptacio', filter.acceptacio ? filter.acceptacio.toString() : '')
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
