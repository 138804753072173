<app-dialog-header label="Proposta grua" *ngIf="this.isDialog"i18n-label></app-dialog-header>
<div class="row">
  <form #mainForm>
    <div class="col-xl-12">
      <div class="row">
        <!--<app-form-combo-edit class="col-xl-3" i18n-label label="Legislació" [items]="this.tipusLegislacions" [(value)]="this.infraccio.legislacio" valueField="codi" textField="descripcio" [isReadOnly]="this.dialogData.isReadOnly" required></app-form-combo-edit>-->
        <!--<app-form-text-edit class="col-xl-6" i18n-label label="Tecnic" [(value)]="this.proposta.tecnicGrua"  [isReadOnly]="false" ></app-form-text-edit>-->
        <div class="col-sm-4">
          <app-form-text-edit id="nueva-propuesta-grua-tecnic" i18n-label label="Tècnic" [(value)]="this.proposta.tecnicGrua" [isReadOnly]="false"></app-form-text-edit>
          <app-view-list-icon (listClick)="seleccionarTecnic()" (viewClick)="seleccionarTecnic(this.proposta.idTecnicFK)" [hideView]="true" [hideList]="false"></app-view-list-icon>
      </div>
        <!-- <app-form-text-edit class="col-xl-6" i18n-label label="Id retirada" [(value)]="this.proposta.codi"  [isReadOnly]="true" ></app-form-text-edit> -->
      <!-- <app-form-text-edit class="col-xl-6" i18n-label label="Data" [(value)]="this.proposta.data"  [isReadOnly]="false" ></app-form-text-edit>-->
      <app-form-date-edit id="nueva-propuesta-grua-data" i18n-label class="col-sm-3" label="Data" [(value)]="this.proposta.data" required></app-form-date-edit>  
        <app-form-text-edit id="nueva-propuesta-grua-hora" class="col-sm-3" type="time" i18n-label label="Hora" [(value)]="this.proposta.hora" required [readonly]="false" ></app-form-text-edit>
        <app-form-text-edit id="nueva-propuesta-grua-matricula" class="col-xl-4" i18n-label label="Matricula" [(value)]="this.proposta.idVehiclesFK"  required [isReadOnly]="false" ></app-form-text-edit>

          <div class="col-xl-4">
              <app-form-text-edit id="nueva-propuesta-grua-carrer" i18n-label label="Carrer" [(value)]="this.proposta.nombreCalle" [isReadOnly]="false" required></app-form-text-edit>
              <app-view-list-icon (listClick)="seleccionarCarrer()" (viewClick)="seleccionarCarrer(this.proposta.secureIdCarrer)"  [hideView]="true" [hideList]="false"></app-view-list-icon>
          </div>
          
          <app-form-text-edit id="nueva-propuesta-grua-numero" class="col-xl-2" i18n-label label="Número" [(value)]="this.proposta.numCarrer" maxLenght="4" [isReadOnly]="false" required></app-form-text-edit>

        <app-form-text-edit id="nueva-propuesta-grua-numPDA" class="col-xl-2" i18n-label label="PDA" [(value)]="this.proposta.numPDA"  [isReadOnly]="false" ></app-form-text-edit>
        
        <!-- <app-form-text-edit class="col-xl-6" i18n-label label="Matricula" [(value)]="this.proposta.idVehiclesFK"  [isReadOnly]="true" ></app-form-text-edit> -->
        <!-- <app-form-text-edit class="col-xl-6" i18n-label label="Marca"  [isReadOnly]="true" ></app-form-text-edit>

        <app-form-text-edit class="col-xl-6" i18n-label label="Marca"   [isReadOnly]="true" ></app-form-text-edit>
        <app-form-text-edit class="col-xl-6" i18n-label label="Color"   [isReadOnly]="true" ></app-form-text-edit> -->
        
        <div class="col-xl-4" >
          <app-combo-box-input-autocomplete #comboAliasGrua class="center-horizontal" i18n-label label="Àlies Grua" [options]="this.aliasGruaDictionary"
          (valorSeleccionado)="this.aliasGruaSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteAliasGrua"  required="true" [disableComponent]="false">
        </app-combo-box-input-autocomplete>
      </div>  
        <!--<app-form-combo-edit class="col-xl-12" i18n-label label="Alias Grua" [items]="this.aliasGruaList" [(value)]="this.proposta.secureId" valueField="secureId" textField="descripcio"  required></app-form-combo-edit>-->
        
        <div class="col-xl-8" >
          <app-combo-box-input-autocomplete #comboInfraccions class="center-horizontal" i18n-label label="Infracció" [options]="this.infraccionsDictionary" [disableComponent]="false"
          (valorSeleccionado)="this.infraccionsSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteInfraccions"   >
        </app-combo-box-input-autocomplete>
      </div> 
        <!--<app-form-combo-edit class="col-xl-12" i18n-label label="Infracció" [items]="this.infraccionsList" [(value)]="this.proposta.secureId" valueField="secureId" textField="descCurta"  required></app-form-combo-edit>-->
      <div class="col-xl-6" >
        <app-combo-box-input-autocomplete #comboAgent class="center-horizontal" i18n-label label="Agent" [options]="this.agentsDictionary" [disableComponent]="false"
        (valorSeleccionado)="this.agentSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteAgent"  required="true" >
      </app-combo-box-input-autocomplete>
      </div> 
        <!--<app-form-combo-edit class="col-xl-12" i18n-label label="Agent" [items]="this.aliasGruaList" [(value)]="this.proposta.secureId" valueField="codi" textField="descripcio"  ></app-form-combo-edit>-->
        <!-- <div class="col-xl-6" >
          <app-combo-box-input-autocomplete #comboMotiuDesestimar class="center-horizontal" i18n-label label="Motiu desestimar" [options]="this.motiusDesestimarDictionary" [disableComponent]="!this.isProposta"
          (valorSeleccionado)="this.motiuDesestimarSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteMotiusDesestimar"  required="false" >
        </app-combo-box-input-autocomplete>
        </div>  -->
        <!--<app-form-combo-edit class="col-xl-12" i18n-label label="Motiu desestimar" [items]="this.aliasGruaList" [(value)]="this.proposta.secureId" valueField="codi" textField="descripcio"  ></app-form-combo-edit>-->
        
        <mat-checkbox class="col-xl-3" [checked]="this.proposta.implicaDenuncia" (change)="implicaDenunciaChange($event)" i18n  [disabled]="false">Crear denúncia &nbsp;</mat-checkbox>
        <mat-checkbox class="col-xl-3" [checked]="this.proposta.sinCargo"  i18n  (change)="sinCargoChange($event)" [disabled]="false">Sense càrrec &nbsp;</mat-checkbox>
      
        <!--<textarea cols="12"  matInput [(ngModel)]="this.proposta.observacions" ></textarea>-->
        <!-- <app-form-text-area class="col-xl-12" i18n-label label="Observacions" [(value)]="this.proposta.observacions" maxLength="5000" [disabled]="!this.isProposta"></app-form-text-area> -->
        <!--<textarea matInput ng-model="this.proposta.observacions" ></textarea>-->
        <div class="row proposta-buttons">
          <div class="col-md-8 offset-md-2 d-flex">
            <span [matTooltip]="this.missatgeAcceptarDisabled" matTooltipClass="my-custom-tooltip" [matTooltipDisabled]="!disabledButtonAccept()">            
              <button [disabled]="disabledButtonAccept()" [style.cursor]="disabledButtonAccept()?'not-allowed':'pointer'"
                class="btn btn-success offset-md-1 button-not-allowed" type="button" (click)="this.createProposta()">
              <i class="fa fa-check-circle"></i>&nbsp;<span i18n>Sol·licitar</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

