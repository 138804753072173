var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OwlDateTimeIntl } from 'ng-pick-datetime';
var TimePickerIntl = /** @class */ (function (_super) {
    __extends(TimePickerIntl, _super);
    function TimePickerIntl() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /** A label for the up second button (used by screen readers).  */
        _this.upSecondLabel = 'Add a second';
        /** A label for the down second button (used by screen readers).  */
        _this.downSecondLabel = 'Minus a second';
        /** A label for the up minute button (used by screen readers).  */
        _this.upMinuteLabel = 'Add a minute';
        /** A label for the down minute button (used by screen readers).  */
        _this.downMinuteLabel = 'Minus a minute';
        /** A label for the up hour button (used by screen readers).  */
        _this.upHourLabel = 'Add a hour';
        /** A label for the down hour button (used by screen readers).  */
        _this.downHourLabel = 'Minus a hour';
        /** A label for the previous month button (used by screen readers). */
        _this.prevMonthLabel = 'Previous month';
        /** A label for the next month button (used by screen readers). */
        _this.nextMonthLabel = 'Next month';
        /** A label for the previous year button (used by screen readers). */
        _this.prevYearLabel = 'Previous year';
        /** A label for the next year button (used by screen readers). */
        _this.nextYearLabel = 'Next year';
        /** A label for the previous multi-year button (used by screen readers). */
        _this.prevMultiYearLabel = 'Previous 21 years';
        /** A label for the next multi-year button (used by screen readers). */
        _this.nextMultiYearLabel = 'Next 21 years';
        /** A label for the 'switch to month view' button (used by screen readers). */
        _this.switchToMonthViewLabel = 'Change to month view';
        /** A label for the 'switch to year view' button (used by screen readers). */
        _this.switchToMultiYearViewLabel = 'Choose month and year';
        /** A label for the cancel button */
        _this.cancelBtnLabel = 'Cancel·la';
        /** A label for the set button */
        _this.setBtnLabel = 'Aplica';
        /** A label for the range 'from' in picker info */
        _this.rangeFromLabel = 'Des de';
        /** A label for the range 'to' in picker info */
        _this.rangeToLabel = 'Fins a';
        /** A label for the hour12 button (AM) */
        _this.hour12AMLabel = 'AM';
        /** A label for the hour12 button (PM) */
        _this.hour12PMLabel = 'PM';
        return _this;
    }
    return TimePickerIntl;
}(OwlDateTimeIntl));
export { TimePickerIntl };
