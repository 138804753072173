import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../shared/services/authentication/auth-guard.service";
var AcusosService = /** @class */ (function () {
    function AcusosService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
        this.displayedItems = [];
        this.displayedItems['checkedValues'] = 0;
    }
    AcusosService.prototype.getAcusesTable = function (somid) {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAcusos'] + '/' + somid.toString(), null);
        }
    };
    AcusosService.prototype.informarAcusos = function (acusos) {
        if (this.authGuard.canActivate()) {
            var dataJson = JSON.stringify(acusos);
            var url = config.urls['apiUrl'] + api.endpoints['urlInformarAcusos'];
            return this.http.postJSON(url, dataJson);
        }
    };
    AcusosService.ngInjectableDef = i0.defineInjectable({ factory: function AcusosService_Factory() { return new AcusosService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: AcusosService, providedIn: "root" });
    return AcusosService;
}());
export { AcusosService };
