import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { Recaudacio } from '../models/recaudacio.model';
import { RecaudacioFilterService } from './recaudacio-filter.service';
import { RecaudacioFilter } from '../models/recaudacio-filter';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class RecaudacioService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  getRecaudacio(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetRecaudacio'].replace('{secure_id}', secure_id), null);
    }
  }

  getExpenedorInfo(secure_id, expenedor): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetRecaudacioExpenedorInfo'].replace('{secure_id}', secure_id).replace('{expenedor}', expenedor), null);
    }
  }

  getZones(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetRecaudacioZones'], null);
    }
  }

  getMaquinesParkeon(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetRecaudacioMaquinesParkeon'], null);
    }
  }

  setRecaudacio(recaudacio: Recaudacio): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetRecaudacio'], recaudacio);
    }
  }

  removeRecaudacio(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteRecaudacio'].replace('{secure_id}', secure_id), null);
    }
  }

  generateExcel(filter: RecaudacioFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new RecaudacioFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlRecaudacioExcel'],  params );
    }
  }
}
