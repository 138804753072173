import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class ZoneFilter extends FilterData {
  idZona: string;
  descripcio: string;
  fechaInici: string;
  fechaFin: string;
 

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.descripcio = '';
    this.fechaInici = null;
    this.fechaFin = null;
    this.idZona = '';
   
  }
}
