import { Observable } from 'rxjs/Observable';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tool-dropdown-button',
  templateUrl: './tool-dropdown-button.component.html',
  styleUrls: ['./tool-dropdown-button.component.scss']
})
export class ToolDropdownButtonComponent implements OnInit {
  @Output() public click1: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public click2: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public click3: EventEmitter<MouseEvent> = new EventEmitter();
  @Input() label1: string;
  @Input() label2: string;
  @Input() label3: string;
  @Input() numeroSubmenus: number;
  @Input()
  set disabled(dis: boolean) {
    this.disabledValue = dis;
    this.color = this.colorValue;
  }
  get disabled() {
    return this.disabledValue;
  }
  @Input() text: string;
  @Input()
    set color(col: string) {
      this.colorValue = col;
      this.colorClass = 'actions-bar__button-icon';
      if (this.colorValue !== undefined) {
        if (!this.disabled) {
          this.colorClass += ' ' + this.colorValue;
        } else {
          this.colorClass += ' has-color-gray';
        }
      }
    } get color() {
      return this.colorValue;
    }
  @Input() icon: string;
  @Input() badge: string;
  @Input() badgeActive: boolean;
  @Input() badgeColor: string;
  @Input() propagate: boolean;
  @Input() isFile: boolean;
  @Input() src: string;
  /*set isFile(val: boolean) {

    if (val === null || val === undefined || !val) {
      this.itsFile = false;
    } else {
      this.itsFile = true;
    }
  }
  get isFile() {
    return this.disabledValue;
  }*/

  //itsFile: boolean;
  disabledValue: boolean;
  colorClass: string;
  iconClass = 'fa fa-question';
  badgeClass: string;
  colorValue: string;
  tipuCamp: string;

  constructor() {
    this.propagate = false;
    this.badgeColor = 'badge-primary';
    this.badge = '';
    this.badgeActive = false;
    this.isFile = false;
    this.numeroSubmenus = 0;
   }

  ngOnInit() {
    this.badgeClass = 'badge badge-pill badge-pill-floating-right ';
    if (this.iconClass !== undefined) {
      this.iconClass = 'fa ' + this.icon;
    }
    if (this.isFile === null || this.isFile === undefined || this.isFile === false) {
      this.tipuCamp = 'hidden';
    } else {
      this.tipuCamp = 'file';
    }
  }

  clickEvent(event, boto) {
    if (!this.propagate) {
        event.stopPropagation();
    }
    if (!this.disabled) {
      if (this.numeroSubmenus >= 1 && boto === 'boto1') {
        this.click1.emit(event);
      }
      if (this.numeroSubmenus >= 2 && boto === 'boto2') {
        this.click2.emit(event);
      }
      if (this.numeroSubmenus >= 3 && boto === 'boto3') {
        this.click3.emit(event);
      }
    }
  }
}
