<div class="form-group">
    <mat-form-field>
        <input id={{id}} #inputEdit matInput placeholder="{{this.label}}" [(ngModel)]="this.value"
            (change)="this.change($event)" (focusout)="onFocusOutEvent($event)" (ngModelChange)="modelChanged($event)"
            [(disabled)]="this.disabled" [owlDateTime]="dtp" [readonly]="this.isReadOnly" [required]="this.isRequired()"
            [ngClass]="[this.validVal  ? 'ng-valid' : 'ng-invalid']" [selectMode]="mode" [max]="this.maxDate"
            [min]="this.minDate"/>
        <span *ngIf="!this.isReadOnly" class="date-picker-trigger" [owlDateTimeTrigger]="dtp">
            <i class="fas fa-calendar-day"></i>
        </span>
        <owl-date-time #dtp [startView]="view" (monthSelected)="chosenMonthHandler($event)" [startAt]="this.value"
            [pickerType]="this.type" [disabled]="this.disabled" [readonly]="this.isReadOnly"></owl-date-time>
    </mat-form-field>
    <div [hidden]="this.validVal" class="text-danger alert-field-errors">
        <div [hidden]="!this.errorRequired()" i18n>Aquest camp és obligatori!</div>
    </div>
</div>