<div class="row justify-content-between">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

    <ng-container matColumnDef="incorpora" *ngIf="this.selectButton">
      <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
      <mat-cell *matCellDef="let row" class="text-center"><a (click)="this.selectCurrent(row.codi)" title="Incorporar" i18n-title><i class="fa fa-plus-square has-color-aqua"></i></a></mat-cell>
    </ng-container>

    <!-- Tipus Column -->
      <ng-container matColumnDef="tipus">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipus</mat-header-cell>
          <mat-cell *matCellDef="let row">{{getTipusMatricula(row.tipus)}}</mat-cell>
        </ng-container>

      <!-- Matricula Column -->
      <ng-container matColumnDef="matricula">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matricula</mat-header-cell>
        <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.matricula}}</a></mat-cell>
      </ng-container>

      <!-- Marca Column -->
      <ng-container matColumnDef="marca">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Marca</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.marca}}</mat-cell>
      </ng-container>

      <!-- Model Column -->
      <ng-container matColumnDef="model">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Model</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.model}}</mat-cell>
      </ng-container>

        <!-- Observacions Column -->
      <ng-container matColumnDef="observacions">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Observacions</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.observacions}}</mat-cell>
      </ng-container>

      <!-- Data inici Column -->
      <ng-container matColumnDef="dataInici">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data inici</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.hora}}&nbsp;{{row.dataInici| shortDate }}</mat-cell>
      </ng-container>

      <!-- Data fi Column -->
      <ng-container matColumnDef="dataFi">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data fi</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.dataFi | shortDate }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
</div>
