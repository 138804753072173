import { Component, OnInit, Inject, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { getMatIconFailedToSanitizeUrlError, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NullAstVisitor } from '@angular/compiler';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ShortDatePipe } from 'path-shared/pipes/short-date-pipe';
import { PersonesService } from 'app/sections/ciutat/persones/services/persones.service';
import { PersonaFilter } from 'app/sections/ciutat/persones/models/persona-filter';
import { Persona } from 'app/sections/ciutat/persones/models/persona.model';
import { Builder } from 'path-shared/models/builder';
import { PersonesComponent } from 'app/sections/ciutat/persones/pages/persones/persones.component';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { PersonesEditorComponent } from 'app/sections/ciutat/persones/components/persones-editor/persones-editor.component';
import { Dictionary } from 'path-shared/models/dictionary';
import { BehaviorSubject } from 'rxjs-compat';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';
import { OperacionCambioMatriculaDto } from 'app/sections/denuncies/models/operacionCambioMatriculaDto.model';
import { FormService } from 'path-shared/services/form/form.service';
import { VehiclesService } from 'app/sections/ciutat/vehicles/services/vehicles.service';
import { VEHICLE } from 'path-models/VEHICLE';
import { VehicleFilter } from 'app/sections/ciutat/vehicles/models/vehicle-filter';
import { VehiclesComponent } from 'app/sections/ciutat/vehicles/pages/vehicles/vehicles.component';
import { VehiclesTableComponent } from 'app/sections/ciutat/vehicles/components/vehicles-table/vehicles-table.component';


@Component({
  selector: 'app-cambio-matricula',
  templateUrl: './cambio-matricula.component.html',
  styleUrls: ['./cambio-matricula.component.scss']
})
export class CambioMatriculaComponent implements OnInit {

  dto = new OperacionCambioMatriculaDto();
  @ViewChild('mainForm') private mainForm: ElementRef;
  reset = true;

  marcas = [];
  modelos = [];
  modelosFull = [];
  colores = [];

  constructor(
    private vehiclesService: VehiclesService,
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    private i18n: I18n,
    private toastr: ToastrService,
    private formService: FormService,

  ) {
  }

  ngOnInit() {
    this.vehiclesService.getMarquesCombo().subscribe(res => {
      this.marcas = res;
      this.marcas.unshift({id: 0, descripcio: ''});
    });
    this.vehiclesService.getModelosCombo().subscribe(res => {
      this.modelosFull = res;
    });
    this.vehiclesService.getColoresCombo().subscribe(res => {
      this.colores = res;
      this.colores.unshift({id: 0, descripcio: ''});
    });
  }

  consultarMatricula() {
    if (this.dto.matricula !== undefined
      && this.dto.matricula !== null
      && this.dto.matricula !==  '') {
        this.vehiclesService.getVehiclesByMatricula(this.dto.matricula).subscribe( (x: VEHICLE[])  => {
            if ( x !== undefined && x !== null && x.length > 0 ) {
              if (x.length === 1) {
              this.dto.marca = x[0].MARCA;
              this.dto.modelo = x[0].MODEL;
              this.dto.color = x[0].COLOR;

              } else if (x.length  > 1 ) {
              const filter: VehicleFilter = new VehicleFilter('MATRICULA', 'asc');
              filter.matricula = this.dto.matricula;
              // this.filterService.saveFilter('persona-filter', filter);
              // Carraguem la taula filtrada per dni
              const data = Builder.newComponentDialog(true, false, '', filter);
              const ref = this.dialog.open(VehiclesTableComponent, {
                width: '80%',
                closeOnNavigation: false,
                autoFocus: true,
                data: data
              });

              ref.afterClosed().subscribe(async res => {
                if (res !== undefined && res !== null && res !== '') {
                  this.dto.matricula = res.matricula;
                  this.dto.marca = res.marca;
                  this.dto.modelo = res.model;
                  this.dto.color = res.color;
                  this.reset = false;
                }
              });

              }
            }
        });
      } else {
        const filter: VehicleFilter = new VehicleFilter('MATRICULA', 'asc');
        filter.matricula = this.dto.matricula;
        // this.filterService.saveFilter('persona-filter', filter);
        // Carraguem la taula filtrada per dni
        const data = Builder.newComponentDialog(true, false, '', filter);
        const ref = this.dialog.open(VehiclesTableComponent, {
          width: '80%',
          closeOnNavigation: false,
          autoFocus: true,
          data: data
        });

        ref.afterClosed().subscribe(async res => {
          if (res !== undefined && res !== null && res !== '') {
            this.dto.matricula = res.matricula;
            this.dto.marca = res.marca;
            this.dto.modelo = res.model;
            this.dto.color = res.color;
            this.reset = false;
          }
        });
      }

        //   } else {
        //    // Obrim dialeg per crear persona
        //       this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), this.i18n('Si'), this.i18n('No'))
        //       .then((confirmed) => {
        //           if (confirmed) {
        //             const data = Builder.newComponentDialog(true, false, null);
        //             const ref = this.dialog.open(PersonesEditorComponent, {
        //               width: '80%',
        //               height: '90%',
        //               closeOnNavigation: false,
        //               autoFocus: true,
        //               data:  {  isDialog: true,
        //                 isReadOnly: getMatIconFailedToSanitizeUrlError,
        //                 id: null,
        //                 filter: null,
        //                 dni: this.dniInfractor}
        //             });

        //             ref.afterClosed().subscribe(async data => {
        //               if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
        //                 this.sidInfractor = data.secure_id;
        //                 this.dto.nom = data.nomComplet;
        //                 this.dto.idInfractor = data.codi;
        //                 this.dniInfractor = data.dni_pasp;
        //                 this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
        //                 this.sidDomiciliInfractor = data.secureIdDom;
        //                 this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];


        //               }
        //             });

        //           } else {
        //             this.dto.nom = '';
        //             this.domicilisInfractorDictionary =  new BehaviorSubject(new Array<Dictionary>());
        //           }
        //         });
        //   }
        // });
      // } else {
      //   const data = Builder.newComponentDialog(true, false, null);
      //   const ref = this.dialog.open(PersonesComponent, {
      //     width: '80%',
      //     height: '90%',
      //     closeOnNavigation: false,
      //     autoFocus: true,
      //     data: data,
      //     disableClose: true
      //   });

      //   ref.afterClosed().subscribe(async data => {
      //     if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
      //       this.sidInfractor = data.secure_id;
      //       this.dto.nom = data.nomComplet;
      //       this.dto.idInfractor = data.codi;
      //       this.dniInfractor = data.dni_pasp;
      //       this.sidDomiciliInfractor = data.secureIdDom;
      //       this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
      //       this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];

      //     }
      //   });

    }
  Reset() {
    if (this.reset) {
      this.dto.marca = '';
      this.dto.modelo = '';
      this.dto.color = '';
    } else {
      this.reset = !this.reset;
    }


  }

//   seleccionarConductor(secureId = null) {
//     if (secureId !== null) {

//   } else {
//     if (Reflection.empty(this.sidInfractor)) {
//       const data = Builder.newComponentDialog(true, false, secureId);
//       const ref = this.dialog.open(PersonesComponent, {
//         width: '80%',
//         height: '90%',
//         closeOnNavigation: false,
//         autoFocus: true,
//         data: data,
//         disableClose: true
//       });

//       ref.afterClosed().subscribe(async data => {
//         if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
//           this.sidInfractor = data.secure_id;
//           this.dto.nom = data.nomComplet;
//           this.dto.idInfractor = data.codi;
//           this.dniInfractor = data.dni_pasp;
//           this.sidDomiciliInfractor = data.secureIdDom;
//           this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
//           this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];

//         }
//       });
//     } else {
//       const data = Builder.newComponentDialog(true, false, secureId);
//       const ref = this.dialog.open(PersonesComponent, {
//         width: '80%',
//         height: '90%',
//         closeOnNavigation: false,
//         autoFocus: true,
//         data: data,
//         disableClose: true
//       });

//       ref.afterClosed().subscribe(async data => {
//         if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
//           this.sidInfractor = data.secure_id;
//           this.dto.nom = data.nomComplet;
//           this.dto.idInfractor = data.codi;
//           this.dniInfractor = data.dni_pasp;
//           this.sidDomiciliInfractor = data.secureIdDom;
//           this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
//           this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];

//         }
//       });
//     }
//   }
// }

  // getDomicilis(secureIdPersona, propietari, secureIdDom) {
  //   this.personesService.getDomicilis(secureIdPersona).subscribe(dom => {
  //     this.comboDomPropietari.resetShowingOptions();

  //     if (dom === undefined || dom === null || dom.length === 0) {
  //       this.domicilisInfractorDictionary =  new BehaviorSubject(new Array<Dictionary>());
  //     } else {
  //       // this.domicilisConductor = dom;
  //       // this.comboDomConductor.resetCombo();

  //       this.domicilisInfractorDictionary =  new BehaviorSubject(new Array<Dictionary>());
  //       const dict = new Array<Dictionary>();
  //           dom.forEach(element => {
  //             dict.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
  //           });
  //           this.domicilisInfractorDictionary.next(dict);
  //           if (secureIdDom !== undefined) {
  //             this.comboDomPropietari.markAsTouched();
  //             this.comboDomPropietari.setSelect(secureIdDom);
  //           }
  //         }

  //   });
  // }

  // domicilioConductorSeleccionado(secure_id: string) {
  //   this.sidDomiciliInfractor = secure_id;
  // }

  public correcte(): boolean {
    if (!this.formService.allFieldsValid(this.mainForm)) {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
      return false;
    } else {
      return true;
    }

  }

  public marcaSeleccionado(event) {
    const marca = this.marcas.find( x => x.descripcio === event);
    const id = marca !== undefined ? marca.id : undefined;
    this.modelos = this.modelosFull.filter(x => x.codMarca === id.toString());
    this.modelos.unshift({id: 0, descripcio: ''});

  }

}
