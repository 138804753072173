var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var TextoInformativoFilter = /** @class */ (function (_super) {
    __extends(TextoInformativoFilter, _super);
    function TextoInformativoFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.descripcion = '';
        _this.fechaInicio = null;
        _this.fechaFin = null;
        _this.idioma = '';
        _this.labels['descripcion'] = _this.i18n('Descripció');
        _this.labels['fechaInicio'] = _this.i18n('Data Inici');
        _this.labels['fechaFin'] = _this.i18n('Data Fi');
        _this.labels['idioma'] = _this.i18n('Idioma');
        return _this;
    }
    return TextoInformativoFilter;
}(FilterData));
export { TextoInformativoFilter };
