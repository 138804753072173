import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { FiltroTiempoEstadisticas } from '../../models/filtro-tiempo.model';
import { EstadistiquesService } from '../../services/estadistiques.service';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { AparellService } from 'app/sections/configuracio/aparells/services/aparells-service.service';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
var EstadisticasVariosComponent = /** @class */ (function () {
    function EstadisticasVariosComponent(estadistiquesService, authService, aparellsService, agentsService, dialog, i18n, auth) {
        var _this = this;
        this.estadistiquesService = estadistiquesService;
        this.authService = authService;
        this.aparellsService = aparellsService;
        this.agentsService = agentsService;
        this.dialog = dialog;
        this.i18n = i18n;
        this.auth = auth;
        //config grafico
        this.barChartOptions = {
            responsive: true,
        };
        this.colors = [
            {
                backgroundColor: 'rgba(0, 204, 102, 0.8)'
            },
            {
                backgroundColor: 'rgba(255, 99, 132, 0.8)'
            },
            {
                backgroundColor: 'rgba(54, 162, 235, 0.8)'
            }
        ];
        this.colorsDevol = [
            {
                backgroundColor: 'rgba(255, 99, 132, 0.8)'
            }
        ];
        this.colorsAnul = [
            {
                backgroundColor: 'rgba(54, 162, 235, 0.8)'
            }
        ];
        this.barChartType = 'bar';
        this.dataRecived = false;
        this.dateSelected = new Date();
        this.showMultiLine = false;
        this.tiposTemporizacion = [{ label: FiltroTiempoEstadisticas.Anual }, { label: FiltroTiempoEstadisticas.Mensual },
            { label: FiltroTiempoEstadisticas.Semanal }, { label: FiltroTiempoEstadisticas.Diario }];
        this.tipoTemporizacionSelected = FiltroTiempoEstadisticas.Mensual;
        this.datos = { chartDataSets: [], chartLabels: [], chartColors: [] };
        this.agents = new Array();
        this.aparells = new Array();
        this.tipos = [{ codi: 'DENUNCIAS' }, { codi: 'DETECCIONES' }, { codi: 'PROPUESTAS' }, { codi: 'TICKETS' }];
        this.estadosPropuesta = [{ codi: 0, descripcio: this.i18n('Aceptadas') }, { codi: 1, descripcio: this.i18n('Pendientes') },
            { codi: 2, descripcio: this.i18n('Desestimadas') }];
        this.estadoPropuestaSelected = 1;
        this.agentesSelected = new Array();
        this.aparellsSelected = new Array();
        this.tipoSelected = 'DENUNCIAS';
        this.anuladaItems = [];
        this.barChartLabels = [];
        this.barChartData = [];
        this.barChartDataMultiLine = [];
        // Carreguem els agents
        this.agentsService.getAllCombo().subscribe(function (result) {
            _this.agents = result.map(function (s) { return new FormMultiSelect(s.codi, s.codi); });
        });
        // Carreguem els aparells
        this.aparellsService.getAllCombo().subscribe(function (result) {
            _this.aparells = result.map(function (s) { return new FormMultiSelect(s.id, s.descripcion); });
        });
    }
    EstadisticasVariosComponent.prototype.ngOnInit = function () {
        //this.reload();
    };
    EstadisticasVariosComponent.prototype.reload = function () {
        var _this = this;
        var filter = this.getFilter();
        this.estadistiquesService.getEstadistiquesVarios(filter).subscribe(function (x) {
            var _a;
            _this.dataRecived = true;
            _this.datos = x;
            _this.barChartData = [];
            Object.assign([], _this.barChartData);
            _this.barChartDataMultiLine = [];
            Object.assign([], _this.barChartDataMultiLine);
            if (!_this.barChartLabels) {
                _this.barChartLabels = [];
            }
            _this.barChartLabels.length = 0;
            (_a = _this.barChartLabels).push.apply(_a, Object.values(_this.datos.chartLabels.barChartLabels));
            _this.barChartData.length = 0;
            _this.barChartDataMultiLine.length = 0;
            if (x.chartDataSets.length > 1) {
                _this.showMultiLine = true;
                x.chartDataSets.forEach(function (element) {
                    _this.barChartDataMultiLine.push({
                        data: element.data, label: element.label
                    });
                });
            }
            else {
                _this.showMultiLine = false;
                _this.barChartData.push({ data: x.chartDataSets[0].data, label: x.chartDataSets[0].label });
            }
        });
    };
    EstadisticasVariosComponent.prototype.onDateChange = function () {
        this.reload();
    };
    EstadisticasVariosComponent.prototype.getFilter = function () {
        var filter = {
            fecha: this.dateSelected, tipoTemporizacion: this.tipoTemporizacionSelected,
            agente: this.agentesSelected, aparell: this.aparellsSelected.map(function (m) { return m.item_id; }), tipo: this.tipoSelected, estadoPropuesta: this.estadoPropuestaSelected
        };
        return filter;
    };
    EstadisticasVariosComponent.prototype.generateExcel = function () {
        var filter = this.getFilter();
        this.dialog.open(DownloadExcelDialogComponent, {
            closeOnNavigation: false,
            autoFocus: true,
            data: Builder.newDownloadExcelDialogDataGeneral(this.estadistiquesService, filter, this.i18n('Denuncias'))
        });
    };
    EstadisticasVariosComponent.prototype.restartFilter = function () {
        this.agentesSelected = null;
    };
    return EstadisticasVariosComponent;
}());
export { EstadisticasVariosComponent };
