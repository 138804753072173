<app-filter-header title="Filtrar Tiquets" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-form-date-edit id="tiquets-filter-dataIni" class="col-sm-4" i18n-label label="Data tiquet inici" id="fechaTicketInicio" 
    [(value)]="filter.fechaTicketInicio" type="calendar" [maxDate]="this.fechaHoraActual" [isReadOnly]="!this.tipusData"></app-form-date-edit>
  <app-form-date-edit id="tiquets-filter-dataFi" class="col-sm-4" i18n-label label="Data tiquet fi" id="fechaTicketFin" 
    [(value)]="filter.fechaTicketFin" type="calendar" [maxDate]="this.fechaHoraActual" [isReadOnly]="!this.tipusData"></app-form-date-edit>
  </div>
  <div class="row">
    <app-form-text-edit id="tiquets-filter-tiquet" class="col-4" i18n-label label="Tiquet" [(value)]="filter.id"></app-form-text-edit>
    <app-form-text-edit id="tiquets-filter-zona" class="col-4" i18n-label label="Zona" [(value)]="filter.zona"></app-form-text-edit>
    <app-form-text-edit id="tiquets-filter-email" class="col-4" i18n-label label="Email usuari" [(value)]="filter.email"></app-form-text-edit>
    <app-form-text-edit id="tiquets-filter-matricula" class="col-4" i18n-label label="Matrícula" [(value)]="filter.matricula"></app-form-text-edit>
    <app-form-text-edit id="tiquets-filter-proveidors" class="col-4" i18n-label label="Proveïdor" [(value)]="filter.proveidors"></app-form-text-edit>
  </div>
</div>