<app-toolbar>
  <!-- <app-tool-button text="Impressió" i18n-text (click)="this.downloadPDF();" color="has-color-blue" icon="fa-print" *ngIf="this.pdfActual.value !== undefined " [disabled]="this.pdfActual.value === undefined || this.pdfActual.value === null || this.pdfActual.value === ''"></app-tool-button> -->
  <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
  <app-tool-button text="Cancelar" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>

<!-- <app-form-text-edit class="col-sm-3" type="text" i18n-label label="Data Inici" [(value)]="{{this.registroTurno.horaSortida | longDate}}" [readonly]="true" ></app-form-text-edit> -->
<div class="form-group">

  <div class="row">
    <app-form-combo-edit class="col-2" i18n-label label="Agrupación" [(value)]="this.filtreAgentsEstadistiques.tipo"
    [items]="tiposEstadisticasAgentes" valueField="label" textField="label"></app-form-combo-edit>
    <app-form-check-edit class="col-xl-2" i18n-label label="Mensual" [(value)]="this.filtreAgentsEstadistiques.mensual" ></app-form-check-edit>
<!--    
    <div class="col-sm-6">
      <div> -->
        <app-form-date-edit id="estadistiques-mensuals-agents-dataIni" *ngIf="this.filtreAgentsEstadistiques.mensual"   [view]="'year'" class="col-2" i18n-label label="Fecha incial período" [(value)]="this.filtreAgentsEstadistiques.dataInici"></app-form-date-edit>
        <div class="col-sm-2" *ngIf="this.filtreAgentsEstadistiques.mensual" ><p></p></div>
      <!-- </div>
    </div> -->
    <!-- <div *ngIf="!this.filtreAgentsEstadistiques.mensual" > -->
      
        <app-form-date-edit id="estadistiques-mensuals-agents-dataIni"   *ngIf="!this.filtreAgentsEstadistiques.mensual"  class="col-sm-2" i18n-label label="Fecha incial período" [(value)]="this.filtreAgentsEstadistiques.dataInici"></app-form-date-edit>
 
        <app-form-date-edit id="estadistiques-mensuals-agents-dataFi"  *ngIf="!this.filtreAgentsEstadistiques.mensual"    class="col-sm-2"  i18n-label label="Fecha final período" [(value)]="this.filtreAgentsEstadistiques.dataFi"></app-form-date-edit>
      
    <!-- </div> -->
   
  <!-- MULTI-SELECT AGENTES -->
  <app-form-multi-select tituloMultiSelect="Agente"
  class="col-2" [data]="agents" [(selectedValues)]="agentesSelected" maxItems="3"></app-form-multi-select>

  <button class="btn btn-primary button-not-allowed btn-lg col-2" type="button" (click)="this.getDatos()">
    <i class="fas fa-sync-alt"></i> &nbsp;<span i18n>Consultar</span>  &nbsp;
  </button>

     
</div>
<!-- <div class="row  justify-content-md-center">
  <div class="col-sm-2">
    <button (click)="incrementZoom(-0.1)" mat-button type="button" [disabled]="this.pdfActual.value === undefined || this.pdfActual.value === null || this.pdfActual.value === ''">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        <line x1="8" y1="11" x2="14" y2="11"></line>
      </svg>
    </button>
  </div>
 
  <div class="col-sm-2">
    <button (click)="incrementZoom(0.1)" mat-button type="button" [disabled]="this.pdfActual.value === undefined || this.pdfActual.value === null || this.pdfActual.value === ''">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          <line x1="11" y1="8" x2="11" y2="14"></line>
          <line x1="8" y1="11" x2="14" y2="11"></line>
        </svg>
      </button>
    </div> 
</div> -->
</div>

<div style="max-height: 80vh; overflow-y: auto !important; overflow-x: auto !important;">
  <div id="pdfViewer" >
    <!-- <pdf-viewer style= "overflow: scroll"
    delegate-handle="relativity-special-general-theory" [zoom]= "zoom" [fit-to-page]="false" [original-size]="true"
    src="{{ this.pdfActual.value }}" class="col-xl-12" max-height='50%'
    scale="1"
    show-toolbar="true" [show-all]="true"></pdf-viewer> -->
    <!-- <img  src='{{this.pdfActual.value   }}' class="col-xl-12"  style="vertical-align: middle;" /> -->
    <!-- <img [src]="imagePath" class="col-xl-12"> -->
    <!--  [src]="sanitize('data:image/jpg;base64, ' + _arrayBufferToBase64(blob))"> -->
    
    <img  src='{{this.imgData}}' class="img-fluid col-xl-12"  style="vertical-align: middle;" />
   </div>
  
</div>
