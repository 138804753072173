export class ChipListElement {
  key: any;
  value: any;
  counter: string;
  constructor(key: any, value: any, counter: number) {
    this.key = key;
    this.value = value;
    this.counter = counter !== undefined ? counter.toString() : '';
  }
}
