<div class="sidebar ">
    <nav class="sidebar-nav ps ps--active-y bg-dark">
        <div class="scroll-outer" #inner>
            <div class="scroll-inner" #scrollInner>
                <ul class="nav">
                    <app-nav-item link="/" text="Dashboard" i18n-text icon="fa fa-tachometer-alt"></app-nav-item>
                    <hr class="hr">
                    <app-nav-item link="/livemap" text="Live Map" i18n-text icon="fa fa-map"></app-nav-item>

                    <hr class="hr" *ngIf="this.auth.UserCanSeePage(auth.Pages.Denuncies)">
                    <app-nav-dropdown-item text="Denúncies" i18n-text icon="fa fa-exclamation-triangle" *ngIf="this.auth.UserCanSeePage(auth.Pages.Denuncies)">
                        <app-nav-item link="/denuncies/importacio" text="Importació" i18n-text icon="fa fa-folder-open" *ngIf="this.auth.UserCanSeePage(auth.Pages.ImportacioDenuncies)"></app-nav-item>
                        <app-nav-item link="/denuncies/tramitador" text="Tramitador" i18n-text icon="fa fa-folder-open" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tramitador)" [badge]="this.tramitadorCount" badgeClass="badge-danger"></app-nav-item>
                        <app-nav-item link="/denuncies/importar-matriculas" text="Cargar matrículas" i18n-text src="assets/images/dgt6.png" *ngIf="this.auth.UserCanSeePage(auth.Pages.IMPORTAR_MATRICULES)"></app-nav-item>
                        <app-nav-item link="/denuncies" text="Veure denuncies" i18n-text icon="fa fa-th-list"></app-nav-item>
                        <app-nav-dropdown-item text="Bancs" i18n-text icon="fa fa-university" *ngIf="this.auth.UserCanSeePage(auth.Pages.Banc)">
                            <app-nav-item link="/denuncies/banc/carregues-c60" text="Càrregues C60" i18n-text icon="fa fa-piggy-bank" *ngIf="this.auth.UserCanSeePage(auth.Pages.Banc)"></app-nav-item>
                            <app-nav-item link="/denuncies/banc/incidencies" text="Incidències" i18n-text icon="fa fa-exclamation-circle" *ngIf="this.auth.UserCanSeePage(auth.Pages.Banc)"></app-nav-item>
                            <app-nav-item link="/denuncies/banc/cobraments" text="Cobros" i18n-text icon="fa fa-coins" *ngIf="this.auth.UserCanSeePage(auth.Pages.Banc)"></app-nav-item>

                        </app-nav-dropdown-item>
                        <!--<app-nav-dropdown-item text="Bancs" i18n-text icon="fa fa-university" *ngIf="this.auth.UserCanSeePage(auth.Pages.Banc)">
                            <app-nav-item link="/denuncies/banc/carregues-c60" text="Càrregues C60" i18n-text icon="fa fa-piggy-bank" *ngIf="this.auth.UserCanSeePage(auth.Pages.Banc)"></app-nav-item>
                            <app-nav-item link="/denuncies/banc/incidencies" text="Incidències" i18n-text icon="fa fa-exclamation-circle" *ngIf="this.auth.UserCanSeePage(auth.Pages.Banc)"></app-nav-item>
                        </app-nav-dropdown-item>-->
                    </app-nav-dropdown-item>
                    
                    <hr class="hr" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tramitar)">
                    <app-nav-dropdown-item text="Tramitar" i18n-text icon="fa fa-exclamation-triangle" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tramitar)">
                        <app-nav-item link="/tramitar/acusos" text="Acusos de rebut" i18n-text icon="fa fa-th-list"></app-nav-item>
                        <app-nav-item link="/tramitar/expedients" text="Expedients" i18n-text icon="fa fa-folder-open"></app-nav-item>
                        <app-nav-item link="/tramitar/predefinides" text="No informar conductor" i18n-text icon="fa fa-folder-open"></app-nav-item>
                        <app-nav-item link="/tramitar/alegacions" text="Alegacions" i18n-text icon="fa fa-th-list" [badge]="this.alegacionsCount"></app-nav-item>
                        <app-nav-dropdown-item text="Notificacions" i18n-text icon="fa fa-envelope-open-text" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tramitar)">
                            <app-nav-dropdown-item text="Gestionar" i18n-text icon="fa fa-folder-open" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tramitar)">
                                <app-nav-item link="/tramitar/gestionar/envios" text="Envios" i18n-text icon="fa fa-check"></app-nav-item>
                                <app-nav-item link="/tramitar/gestionar/respuestas" text="Respuestas" i18n-text icon="fa fa-check-double"></app-nav-item>
                            </app-nav-dropdown-item>
                            <app-nav-dropdown-item text="Parametritzar" i18n-text icon="fa fa-cogs" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tramitar)">
                                <app-nav-item link="/tramitar/tipusEnviaments" text="Tipus Enviaments" i18n-text icon="fa fa-tasks"></app-nav-item>
                                <app-nav-item link="/tramitar/respostes" text="Respostes" i18n-text icon="fa fa-stamp"></app-nav-item>
                            </app-nav-dropdown-item>
                            <app-nav-dropdown-item text="Tablero editctorial único" i18n-text icon="fa fa-folder-open" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tramitar)">
                                <app-nav-item link="/tramitar/boe-configuracion" text="Configuración" i18n-text icon="fa fa-envelope-open"></app-nav-item>
                                <app-nav-item link="/tramitar/boe-envio" text="Envio TEU" i18n-text icon="fa fa-envelope-open"></app-nav-item>
                                <app-nav-item link="/tramitar/boe-confirmacion" text="Confirmación TEU" i18n-text icon="fa fa-check-double"></app-nav-item>
                            </app-nav-dropdown-item>
                        </app-nav-dropdown-item>
                        <app-nav-item link="/tramitar/envioPuntosDGT" text="Envio puntos DGT" i18n-text src="assets/images/dgt6.png"></app-nav-item>
                    </app-nav-dropdown-item>
                    
                    <hr class="hr" *ngIf="this.auth.UserCanSeePage(auth.Pages.Propostes)">
                    <app-nav-dropdown-item text="Propostes" i18n-text icon="fa fa-file-powerpoint" *ngIf="this.auth.UserCanSeePage(auth.Pages.Propostes)">
                        <app-nav-item link="/propostes/1" text="Classificar" i18n-text icon="fa fa-clock" linkActive="/propostes/" [badge]="this.propostesCount" badgeClass="badge-primary"></app-nav-item>
                        <app-nav-item link="/propostes-list" text="Llista" i18n-text icon="fa fa-th-list"></app-nav-item>
                        <app-nav-item link="/propostes-estat-carregats" text="Estado cargados" i18n-text icon="fa fa-history"></app-nav-item>
                    </app-nav-dropdown-item>
                    <hr class="hr" *ngIf="this.auth.UserCanSeePage(auth.Pages.AnulacioZBAuto) || this.auth.UserCanSeePage(auth.Pages.AnulacionsManuals) || this.auth.UserCanSeePage(auth.Pages.ComprovacioTiquets) || this.auth.UserCanSeePage(auth.Pages.Recaudacio) || this.auth.UserCanSeePage(auth.Pages.PropuestasAnulacionZB)" >
                    <app-nav-dropdown-item text="Zona blava" i18n-text icon="fa fa-parking" *ngIf="this.auth.UserCanSeePage(auth.Pages.AnulacioZBAuto) || this.auth.UserCanSeePage(auth.Pages.AnulacionsManuals) || this.auth.UserCanSeePage(auth.Pages.ComprovacioTiquets) || this.auth.UserCanSeePage(auth.Pages.Recaudacio)">
                        <app-nav-item link="/anulacion-zona-azul-auto" text="Anul·lació automàtica" i18n-text icon="fa fa-magic" *ngIf="this.auth.UserCanSeePage(auth.Pages.AnulacioZBAuto)"></app-nav-item>
                        <app-nav-item link="/anulacion-zona-azul-manual" text="Anul·lació manual" i18n-text icon="fa fa-folder" *ngIf="this.auth.UserCanSeePage(auth.Pages.AnulacionsManuals)"></app-nav-item>
                        <app-nav-item link="/zonaBlava/comprovacio-tiquets" text="Comprovació tiquets" i18n-text icon="fa fa-search" *ngIf="this.auth.UserCanSeePage(auth.Pages.ComprovacioTiquets)"></app-nav-item>
                        <app-nav-item link="/zonaBlava/comprovacio-carrers" text="Comprovació carrers" i18n-text icon="fa fa-search" *ngIf="this.auth.UserCanSeePage(auth.Pages.None)"></app-nav-item>
                        <app-nav-item link="/zonaBlava/recaudacio" text="Recaptació" i18n-text icon="fa fa-receipt" *ngIf="this.auth.UserCanSeePage(auth.Pages.Recaudacio)"></app-nav-item>
                        <app-nav-item link="/zonaBlava/propuestasAnulacion" text="Propuestas anulación" i18n-text icon="fa fa-swatchbook" *ngIf="this.auth.UserCanSeePage(auth.Pages.PropuestasAnulacionZB)"></app-nav-item>
                        <app-nav-item link="/zonaBlava/deltacar-historico" text="Histórico DeltaCar" i18n-text icon="fa fa-car" *ngIf="this.auth.UserCanSeePage(auth.Pages.DeltacarHistorico)"></app-nav-item>
                        
                        <app-nav-item link="/zonaBlava/maquines-ZB" text="Màquines ZB" *ngIf="(this.auth.UserCanSeePage(auth.Pages.Maquinas) || this.auth.UserCanSeePage(auth.Pages.Tramos))" i18n-text icon="fa fa-prescription-bottle"></app-nav-item>
                        <app-nav-item link="/zonaBlava/incidencies" text="Incidències" *ngIf="(this.auth.UserCanSeePage(auth.Pages.Maquinas) || this.auth.UserCanSeePage(auth.Pages.Tramos))" i18n-text icon="fa fa-exclamation"></app-nav-item>
                        
                        <app-nav-dropdown-item text="Estacionament" i18n-text icon="fa fa-parking" *ngIf="(this.auth.UserCanSeePage(auth.Pages.Maquinas) || this.auth.UserCanSeePage(auth.Pages.Tramos))">
                            <!--<app-nav-item link="/configuracio/zb/tramos-tipos" text="Tipus trams" i18n-text icon="fa fa-border-style" *ngIf="this.auth.UserCanSeePage(auth.Pages.GruaTecnics) || this.auth.UserCanSeePage(auth.Pages.GruaMotiusDesestimar) || this.auth.UserCanSeePage(auth.Pages.GruaMotiusEntrada) || this.auth.UserCanSeePage(auth.Pages.GruaAlies)"></app-nav-item>-->
                            <app-nav-item link="/zonaBlava/estacionament/temporadas" text="Temporadas" i18n-text icon="fa fa-calendar-week" *ngIf="this.auth.UserCanSeePage(auth.Pages.Temporada)"></app-nav-item>
                            <app-nav-item link="/zonaBlava/estacionament/tramos" text="Areas" i18n-text icon="fa fa-parking" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tramos) "></app-nav-item>
                            <app-nav-item link="/zonaBlava/estacionament/escala-ocupacion" text="Escala ocupació" i18n-text icon="fa fa-ruler-combined" *ngIf="this.auth.UserCanSeePage(auth.Pages.EscalaOcupacion) "></app-nav-item> 
                            <!-- <app-nav-item link="/zonaBlava/heat-map" text="Mapa de calor" i18n-text icon="fa fa-fire" *ngIf="this.auth.UserCanSeePage(auth.Pages.MapaCalor) "></app-nav-item> -->
                        </app-nav-dropdown-item>
                    </app-nav-dropdown-item>   

                    <hr class="hr"  *ngIf="this.auth.UserCanSeePage(auth.Pages.Tiquets) || this.auth.UserCanSeePage(auth.Pages.Festius) || this.auth.UserCanSeePage(auth.Pages.Tarifes) || this.auth.UserCanSeePage(auth.Pages.Reserves)">
                    <app-nav-dropdown-item  [text]="this.aparcareText" i18n-text [src]="this.institutionService.getCurrentInstitution() === '100105229425938306122' ? 'assets/images/aparquem.png':'assets/images/aparcare.png'" *ngIf=" (this.auth.UserCanSeePage(auth.Pages.Tiquets) || this.auth.UserCanSeePage(auth.Pages.Festius) || this.auth.UserCanSeePage(auth.Pages.Tarifes))">
                        <app-nav-item link="/tiquets" text="Tiquets" i18n-text icon="fa fa-sticky-note" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tiquets)"></app-nav-item>
                        <app-nav-item link="/reserves" text="Reserves" i18n-text icon="fa fa-receipt" *ngIf="this.auth.UserCanSeePage(auth.Pages.Reserves)"></app-nav-item>
                        <app-nav-item link="/bonos" text="Bonos" i18n-text icon="fa fa-ticket-alt" *ngIf="this.auth.UserCanSeePage(auth.Pages.Bonos)"></app-nav-item>
                        <app-nav-dropdown-item text="Configuración" i18n-text icon="fa fa-cog">
                            <app-nav-item link="/festius" text="Festius" i18n-text icon="fa fa-calendar" *ngIf="this.auth.UserCanSeePage(auth.Pages.Festius)"></app-nav-item>
                            <app-nav-item link="/tarifes" text="Tarifes" i18n-text icon="fa fa-euro-sign" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tarifes)"></app-nav-item>
                            <app-nav-item link="/zones-aparcare" text="Zones" i18n-text icon="fa fa-road" *ngIf="this.auth.UserCanSeePage(auth.Pages.Zonas)"></app-nav-item>
                            <app-nav-item link="/textos-informativos" text="Text Informatiu" i18n-text icon="fa fa-info" *ngIf="this.auth.UserCanSeePage(auth.Pages.TextoInformativoAparcare)"></app-nav-item>
                            <app-nav-item link="/textos-aviso" text="Text Avís" i18n-text icon="fa fa-exclamation-triangle" *ngIf="this.auth.UserCanSeePage(auth.Pages.TextoAvisoAparcare)"></app-nav-item>
                        </app-nav-dropdown-item>                        
                    </app-nav-dropdown-item>      

                    <hr class="hr" *ngIf="this.auth.UserCanSeePage(auth.Pages.Agents) && !xaloc">
                    <app-nav-dropdown-item text="Agents" i18n-text icon="fa fa-user-shield" *ngIf="this.auth.UserCanSeePage(auth.Pages.Agents) && !xaloc">
                        <app-nav-item link="/agents/agents" text="Agents" i18n-text icon="fa fa-user-shield" *ngIf="this.auth.UserCanSeePage(auth.Pages.Agents) && !xaloc"></app-nav-item>
                        <app-nav-item link="/agents/gestio" icon="fa fa-users" text="Gestionar" i18n-text *ngIf="this.auth.UserCanSeePage(auth.Pages.Agents) && !xaloc"></app-nav-item>
                        <app-nav-item link="/agents/actuacions-agents" text="Actuacions agents" *ngIf="this.auth.UserCanSeePage(auth.Pages.Agents)" i18n-text icon="fa fa-user-friends"></app-nav-item>
                    </app-nav-dropdown-item>
                    <!-- <app-nav-dropdown-item  [text]="this.aparcareText" i18n-text src="assets/images/aparcare.png" *ngIf="this.institutionService.getCurrentInstitution() !== '100105229425938306122' && (this.auth.UserCanSeePage(auth.Pages.Tiquets) || this.auth.UserCanSeePage(auth.Pages.Festius) || this.auth.UserCanSeePage(auth.Pages.Tarifes))">
                        <app-nav-item link="/tiquets" text="Tiquets" i18n-text icon="fa fa-sticky-note" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tiquets)"></app-nav-item>
                        <app-nav-item link="/reserves" text="Reserves" i18n-text icon="fa fa-receipt" *ngIf="this.auth.UserCanSeePage(auth.Pages.Reserves)"></app-nav-item>
                        <app-nav-item link="/festius" text="Festius" i18n-text icon="fa fa-calendar" *ngIf="this.auth.UserCanSeePage(auth.Pages.Festius)"></app-nav-item>
                        <app-nav-item link="/tarifes" text="Tarifes" i18n-text icon="fa fa-euro-sign" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tarifes)"></app-nav-item>
                        <app-nav-item link="/textos-informativos" text="Text Informatiu" i18n-text icon="fa fa-info" *ngIf="this.auth.UserCanSeePage(auth.Pages.TextoInformativoAparcare)"></app-nav-item>
                        <app-nav-item link="/textos-aviso" text="Text Avís" i18n-text icon="fa fa-exclamation-triangle" *ngIf="this.auth.UserCanSeePage(auth.Pages.TextoAvisoAparcare)"></app-nav-item>
                    </app-nav-dropdown-item>                 -->
                    <hr class="hr" *ngIf="this.auth.UserCanSeePage(auth.Pages.Estadistiques)">
                    <app-nav-dropdown-item text="Estadístiques" i18n-text icon="fa fa-chart-bar" *ngIf="this.auth.UserCanSeePage(auth.Pages.Estadistiques)">
                        <app-nav-dropdown-item text="Aparatos" i18n-text icon="fa fa-digital-tachograph">
                            <app-nav-item link="/estadistiques/mensuals" text="Mensual" i18n-text icon="fa fa-calendar-alt"></app-nav-item>
                            <app-nav-item link="/estadistiques/setmanals" text="Setmanal" i18n-text icon="fa fa-calendar-week"></app-nav-item>
                        </app-nav-dropdown-item>
                        <app-nav-item link="/estadistiques/denuncias" text="Denúncies" i18n-text icon="fa fa-th-list"></app-nav-item>
                        <app-nav-item link="/estadistiques/aparcare" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tiquets)" [text]="this.aparcareText" i18n-text [src]="this.institutionService.getCurrentInstitution() === '100105229425938306122' ? 'assets/images/aparquem.png':'assets/images/aparcare.png'"></app-nav-item>
                        <app-nav-item link="/estadistiques/aparcare/reserves" text="Reserves" i18n-text icon="fa fa-receipt" *ngIf="this.auth.UserCanSeePage(auth.Pages.Reserves)"></app-nav-item>
                        <app-nav-item link="/estadistiques/reincidents" text="Reincidentes" i18n-text icon="fa fa-exclamation-triangle" *ngIf="this.auth.UserCanSeePage(auth.Pages.Reincidents)"></app-nav-item>
                        <app-nav-item link="/estadistiques/varios" text="Varios" i18n-text icon="fa fa-th-list"></app-nav-item>
                        <app-nav-item link="/estadistiques/agents" text="Agentes" i18n-text icon="fa fa-users"></app-nav-item>

                    </app-nav-dropdown-item>
                    <hr class="hr"  *ngIf="this.auth.UserCanSeePage(auth.Pages.GenerarProposta) || this.auth.UserCanSeePage(auth.Pages.TramitadesGrua) ||this.auth.UserCanSeePage(auth.Pages.GruaPendents) || this.auth.UserCanSeePage(auth.Pages.DipositGrua)">
                    <app-nav-dropdown-item text="Grua" i18n-text icon="fa fa-truck-pickup" *ngIf="this.auth.UserCanSeePage(auth.Pages.GenerarProposta) || this.auth.UserCanSeePage(auth.Pages.TramitadesGrua) ||this.auth.UserCanSeePage(auth.Pages.GruaPendents) || this.auth.UserCanSeePage(auth.Pages.DipositGrua)">
                        <app-nav-item   (onClickNavItem)="this.stopAudio()" link="/grua/pedents-table/" text="Pendents" i18n-text icon="fa fa-clipboard-list" [badge]="this.propostesGrua"  badgeClass="badge-danger" *ngIf="this.auth.UserCanSeePage(auth.Pages.GruaPendents)"></app-nav-item>
                        <app-nav-item   (onClickNavItem)="this.stopAudio()" link="/grua/pedents-list/" text="Llista pendents" i18n-text icon="fa fa-clipboard-list" [badge]="this.propostesGrua"  badgeClass="badge-danger" *ngIf="this.auth.UserCanSeePage(auth.Pages.GruaPendents)"></app-nav-item>
                        <app-nav-item link="/grua/nova-solicitud" text="Nova sol·licitud" i18n-text icon="fa fa-notes-medical" *ngIf="this.auth.UserCanSeePage(auth.Pages.GenerarProposta)"></app-nav-item>
                        <app-nav-item link="/grua/tramitades" text="Tramitades" i18n-text icon="fa fa-clipboard-check" *ngIf="this.auth.UserCanSeePage(auth.Pages.TramitadesGrua)"></app-nav-item>
                        <app-nav-dropdown-item text="Finalització" i18n-text icon="fas fa-check-circle" *ngIf="this.auth.UserCanSeePage(auth.Pages.DipositGrua)">
                            <app-nav-item link="/grua/diposit" text="Diposit" i18n-text icon="fa fa-layer-group" [badge]="this.alarmCount" badgeClass="badge-danger" *ngIf="this.auth.UserCanSeePage(auth.Pages.DipositGrua)"></app-nav-item>
                            <app-nav-item link="/grua/sortides" text="Sortides" i18n-text icon="fa fa-shipping-fast"  badgeClass="badge-danger" *ngIf="this.auth.UserCanSeePage(auth.Pages.DipositGrua)"></app-nav-item>
                            <app-nav-item link="/grua/desplacats" text="Desplaçats" i18n-text icon="fa fa-shipping-fast" *ngIf="this.auth.UserCanSeePage(auth.Pages.DipositGrua)"></app-nav-item>
                            <app-nav-item link="/grua/sense-retirada" text="Sense retirada" i18n-text icon="fa fa-shipping-fast" *ngIf="this.auth.UserCanSeePage(auth.Pages.DipositGrua)"></app-nav-item>
                        </app-nav-dropdown-item> 
                        <app-nav-dropdown-item text="Torn" i18n-text icon="fa fa-file-signature" *ngIf="this.auth.UserCanSeePage(auth.Pages.DipositGrua)">
                            <app-nav-item (onClickNavItem)="this.inciarTurno()"text="Iniciar torn" i18n-text icon="fa fa-hourglass-start" *ngIf="this.auth.UserCanSeePage(auth.Pages.DipositGrua)" ></app-nav-item>
                            <app-nav-item (onClickNavItem)="this.finalizarTurno()"text="Finalitzar torn" i18n-text icon="fa fa-hourglass-end" *ngIf="this.auth.UserCanSeePage(auth.Pages.DipositGrua)"></app-nav-item>
                        </app-nav-dropdown-item>   
                        <!-- shipping-fast <app-nav-item link="/alarmes/visor" text="Alarmes smartphones" i18n-text icon="fa fa-bell" [badge]="this.alarmCount" badgeClass="badge-danger" *ngIf="this.auth.UserCanSeePage(auth.Pages.AlarmPDA)"></app-nav-item>-->
                    </app-nav-dropdown-item>
                    <hr class="hr" *ngIf="this.auth.UserCanSeePage(auth.Pages.ComunicacioPda) || this.auth.UserCanSeePage(auth.Pages.Tracking) || this.auth.UserCanSeePage(auth.Pages.DeteccioMatricules) || this.auth.UserCanSeePage(auth.Pages.DGT) ">
                    <app-nav-dropdown-item text="Utilitats" i18n-text icon="fa fa-wrench" *ngIf="this.auth.UserCanSeePage(auth.Pages.ComunicacioPda) || this.auth.UserCanSeePage(auth.Pages.Tracking) || this.auth.UserCanSeePage(auth.Pages.DeteccioMatricules) || this.auth.UserCanSeePage(auth.Pages.DGT)">
                        <app-nav-item link="/avisos" text="Avisos" i18n-text icon="fas fa-exclamation" *ngIf="this.auth.UserCanSeePage(auth.Pages.Avisos)"></app-nav-item>
                        <app-nav-item link="/comunicacioPda" text="Missatgeria" i18n-text icon="fa fa-mail-bulk" *ngIf="this.auth.UserCanSeePage(auth.Pages.ComunicacioPda)"></app-nav-item>
                        <app-nav-item link="/ciutat/deteccio-matricules" text="Detecció matrícules" i18n-text icon="fa fa-camera " *ngIf="this.auth.UserCanSeePage(auth.Pages.DeteccioMatricules)"></app-nav-item>
                        <app-nav-item link="/dgt" text="DGT" i18n-text src="assets/images/dgt6.png" *ngIf="this.auth.UserCanSeePage(auth.Pages.DGT)"></app-nav-item>
                        <app-nav-item link="/tracking" text="Tracking" i18n-text icon="fa fa-map-marker-alt" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tracking)"></app-nav-item>
                        <app-nav-item link="/utilitats/tiquets-client" text="Tiquets" i18n-text icon="fa fa-sticky-note" *ngIf="this.auth.UserCanSeePage(auth.Pages.Tiquets)"></app-nav-item>
                    </app-nav-dropdown-item>
                    <hr class="hr"  *ngIf="this.auth.UserCanSeePage(auth.Pages.AlarmPDA) || this.auth.UserCanSeePage(auth.Pages.AlarmSistema)">
                    <app-nav-dropdown-item text="Alarmes" i18n-text icon="fa fa-cogs" *ngIf="this.auth.UserCanSeePage(auth.Pages.AlarmPDA) || this.auth.UserCanSeePage(auth.Pages.AlarmSistema)">
                        <app-nav-item link="/alarmes" text="Alarmes sistema" i18n-text icon="fa fa-bell" [badge]="this.alarmCount" badgeClass="badge-danger" *ngIf="this.auth.UserCanSeePage(auth.Pages.AlarmSistema)"></app-nav-item>
                        <app-nav-item link="/alarmes/visor" text="Alarmes smartphones" i18n-text icon="fa fa-bell"  badgeClass="badge-danger" *ngIf="this.auth.UserCanSeePage(auth.Pages.AlarmPDA)"></app-nav-item>
                    </app-nav-dropdown-item>
                    <hr class="hr" *ngIf="this.auth.UserCanSeePage(auth.Pages.Matricules)">
                    <app-nav-dropdown-item text="Ciutat" i18n-text icon="fa fa-city" *ngIf="this.auth.UserCanSeePage(auth.Pages.Matricules)">
                        <app-nav-item link="/ciutat/matricules" text="Matrícules especials" i18n-text icon="fa fa-th-list" linkActive="/ciutat/matricules-historic" *ngIf="this.auth.UserCanSeePage(auth.Pages.Matricules)"></app-nav-item>
                        <app-nav-item link="/ciutat/tipus-matricules" text="Tipus matrícules" i18n-text icon="fa fa-list-ul" *ngIf="this.auth.UserCanSeePage(auth.Pages.TipusMatricules)"></app-nav-item>
                        <app-nav-item link="/ciutat/persones" text="Persones" i18n-text icon="fa fa-user-friends" *ngIf="this.auth.UserCanSeePage(auth.Pages.Persones)"></app-nav-item>
                        <app-nav-item link="/ciutat/vehicles" text="Vehicles" i18n-text icon="fa fa-car" *ngIf="this.auth.UserCanSeePage(auth.Pages.Vehicles)"></app-nav-item>
                        <app-nav-dropdown-item text="Gestión residentes" i18n-text icon="fa fa-city" *ngIf="this.auth.UserCanSeePage(auth.Pages.GestioResidents)">
                            <app-nav-item link="/ciutat/residentes/citas" text="Citas" i18n-text icon="fa fa-car" *ngIf="this.auth.UserCanSeePage(auth.Pages.GestioResidents)"></app-nav-item>
                            <app-nav-item link="/ciutat/residentes/bonos" text="Gestión bonos" i18n-text icon="fa fa-car" *ngIf="this.auth.UserCanSeePage(auth.Pages.GestioResidents)"></app-nav-item>
                            <app-nav-item link="/ciutat/residentes/comprovar-padro" text="Comprobar padrón" i18n-text icon="fa fa-car" *ngIf="this.auth.UserCanSeePage(auth.Pages.GestioResidents)"></app-nav-item>

                        </app-nav-dropdown-item> 

                    </app-nav-dropdown-item>                    
                    <hr class="hr" *ngIf="this.auth.UserCanSeePage(auth.Pages.Configuracio) ">
                    <app-nav-dropdown-item text="Configuració" i18n-text icon="fa fa-cog" *ngIf="this.auth.UserCanSeePage(auth.Pages.Configuracio) ">
                        <app-nav-item link="/configuracio/aparells" text="Aparells" i18n-text icon="fa fa-digital-tachograph" *ngIf="this.auth.UserCanSeePage(auth.Pages.Aparells) && !xaloc"></app-nav-item>
                        <app-nav-item link="/configuracio/automatitzacio" text="Automatización" i18n-text icon="fa fa-magic" *ngIf="this.auth.UserCanSeePage(auth.Pages.Automatitzacio) && !xaloc"></app-nav-item>
                        <app-nav-item link="/configuracio/avisosconf" text="Avisos" i18n-text icon="fas fa-exclamation" *ngIf="this.auth.UserCanSeePage(auth.Pages.Avisos) && !xaloc"></app-nav-item>
                        <app-nav-item link="/configuracio/carrers" text="Carrers" i18n-text icon="fa fa-road" *ngIf="this.auth.UserCanSeePage(auth.Pages.Carrers) && !xaloc"></app-nav-item>
                        <app-nav-item link="/configuracio/colors" text="Colors" i18n-text icon="fa fa-paint-brush" *ngIf="this.auth.UserCanSeePage(auth.Pages.Colors)&& !xaloc"></app-nav-item>
                        <app-nav-dropdown-item text="Grua" i18n-text icon="fa fa-truck-pickup" *ngIf="this.auth.UserCanSeePage(auth.Pages.GruaTecnics) && !xaloc">
                            <app-nav-item link="/configuracio/grua/tecnics" text="Tècnics" i18n-text icon="fa fa-user" *ngIf="!xaloc &&( this.auth.UserCanSeePage(auth.Pages.GruaTecnics) || this.auth.UserCanSeePage(auth.Pages.GruaMotiusDesestimar) || this.auth.UserCanSeePage(auth.Pages.GruaMotiusEntrada) || this.auth.UserCanSeePage(auth.Pages.GruaAlies))"></app-nav-item>
                            <app-nav-item link="/configuracio/grua/motius-desestimacio" text="Motius desestimació" i18n-text icon="fa fa-thumbs-down" *ngIf="this.auth.UserCanSeePage(auth.Pages.GruaMotiusDesestimar) && !xaloc"></app-nav-item>
                            <app-nav-item link="/configuracio/grua/motius-entrada" text="Motius entrada" i18n-text icon="fa fa-file-import" *ngIf="this.auth.UserCanSeePage(auth.Pages.GruaMotiusEntrada) && !xaloc"></app-nav-item>
                            <app-nav-item link="/configuracio/grua/alies" text="Alies" i18n-text icon="fa fa-font" *ngIf="this.auth.UserCanSeePage(auth.Pages.GruaAlies) && !xaloc"></app-nav-item>
                            <app-nav-item link="/configuracio/grua/turnos" text="Torns" i18n-text icon="fa fa-file-signature" *ngIf="this.auth.UserCanSeePage(auth.Pages.GruaAlies) && !xaloc"></app-nav-item>
                            <app-nav-item link="/configuracio/grua/tarifas" text="Tarifas" i18n-text icon="fa fa-coins" *ngIf="this.auth.UserCanSeePage(auth.Pages.TarifesGrua) && !xaloc"></app-nav-item>

                        </app-nav-dropdown-item>
                        <app-nav-item link="/configuracio/infraccions" text="Infraccions" i18n-text icon="fa fa-traffic-light" *ngIf="this.auth.UserCanSeePage(auth.Pages.Infraccions) && !xaloc"></app-nav-item>
                        <app-nav-item link="/configuracio/textos-predefinits" text="Textos Predefinidos" i18n-text icon="fa fa-traffic-light" *ngIf="this.auth.UserCanSeePage(auth.Pages.Configuracio) && !xaloc"></app-nav-item>
                        <app-nav-item link="/configuracio/legislacions" text="Legislacions" i18n-text icon="fa fa-gavel" *ngIf="this.auth.UserCanSeePage(auth.Pages.Legislacions) && !xaloc"></app-nav-item>
                        <app-nav-item link="/configuracio/marques-vehicles" text="Marques Vehicles" i18n-text icon="fa fa-car" *ngIf="this.auth.UserCanSeePage(auth.Pages.MarquesVehicles) && !xaloc"></app-nav-item>
                        <app-nav-dropdown-item text="Motius" i18n-text icon="fa fa-wrench" *ngIf="(this.auth.UserCanSeePage(auth.Pages.MotiusAnulacio) || this.auth.UserCanSeePage(auth.Pages.MotiusDesestimar)) && !xaloc">
                            <app-nav-item link="/configuracio/motius-notificacio" text="Motivos No Notificación" i18n-text icon="fa fa-pen" *ngIf="this.auth.UserCanSeePage(auth.Pages.MotiusNoNotificacio) && !xaloc"></app-nav-item>
                            <app-nav-item link="/configuracio/motius-anulacio" text="Motius Anul·lació" i18n-text icon="fa fa-times-circle" *ngIf="this.auth.UserCanSeePage(auth.Pages.MotiusAnulacio)&& !xaloc"></app-nav-item>
                            <app-nav-item link="/configuracio/motius-desestimar" text="Motius Desestimar" i18n-text icon="fa fa-thumbs-down" *ngIf="this.auth.UserCanSeePage(auth.Pages.MotiusDesestimar) && !xaloc"></app-nav-item>
                        </app-nav-dropdown-item>
                        <app-nav-item link="/configuracio/observacions-pda" text="Observaciones PDA" i18n-text icon="fa fa-mobile-alt" *ngIf="this.auth.UserCanSeePage(auth.Pages.ObservacionsPDA) && !xaloc"></app-nav-item>
                        <app-nav-item link="/configuracio/paisos" text="Paisos" i18n-text icon="fa fa-globe-europe" *ngIf="this.auth.UserCanSeePage(auth.Pages.Paisos) && !xaloc"></app-nav-item>
                        <app-nav-item link="/configuracio/siglas" text="Sigles" i18n-text icon="fa fa-bold" *ngIf="this.auth.UserCanSeePage(auth.Pages.Siglas) && !xaloc"></app-nav-item>
                        <app-nav-item link="/configuracio/tipus-vehicles" text="Tipus Vehicles" i18n-text icon="fa fa-shuttle-van" *ngIf="this.auth.UserCanSeePage(auth.Pages.TipusVehicles) && !xaloc"></app-nav-item>
                        <app-nav-item link="/configuracio/zones" text="Zones" i18n-text icon="fa fa-road" *ngIf="this.auth.UserCanSeePage(auth.Pages.Zonas)"></app-nav-item>
                        <app-nav-item link="/configuracio/tipus-senals-verticals" text="Tipus Senyals Verticals" i18n-text icon="fa fa-podcast" *ngIf="this.auth.UserCanSeePage(auth.Pages.SenyalsVerticals) && !xaloc"></app-nav-item>
                        <app-nav-item link="/configuracio/senals-verticals" text="Senyals Verticals" i18n-text icon="fa fa-map-signs" *ngIf="this.auth.UserCanSeePage(auth.Pages.SenyalsVerticals)&& !xaloc"></app-nav-item>                        
                        <app-nav-item link="/configuracio/sincronitzacio"  text="Sincronització"  i18n-text icon="fa fa-sync" *ngIf="this.auth.UserCanSeePage(auth.Pages.Sincronitzacio) "> </app-nav-item>

                    </app-nav-dropdown-item>
                    
                    
                    <hr class="hr"  *ngIf="this.auth.UserCanSeePage(auth.Pages.SeguretatUsuaris) || this.auth.UserCanSeePage(auth.Pages.SeguretatGrups)">
                    <app-nav-dropdown-item text="Seguretat" i18n-text icon="fa fa-unlock-alt" *ngIf="this.auth.UserCanSeePage(auth.Pages.SeguretatUsuaris) || this.auth.UserCanSeePage(auth.Pages.SeguretatGrups)">
                        <app-nav-item link="/seguretatUsuaris" text="Manteniment usuaris" i18n-text icon="fa fa-user" *ngIf="this.auth.UserCanSeePage(auth.Pages.SeguretatUsuaris)"></app-nav-item>
                        <app-nav-item link="/seguretatGrups" text="Manteniment grups" i18n-text icon="fa fa-users" *ngIf="this.auth.UserCanSeePage(auth.Pages.SeguretatGrups)"></app-nav-item>
                    </app-nav-dropdown-item>

                    <hr class="hr">
                    <app-nav-item link="/ajuda" text="Ajuda" i18n-text icon="fa fa-question-circle" *ngIf="this.auth.UserCanSeePage(auth.Pages.Ajuda)"></app-nav-item>
                </ul>
            </div>
        </div>
    </nav>
</div>
