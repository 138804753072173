import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';
import { Instituciones } from 'path-shared/models/instituciones';


export class TancamentsFilter extends FilterData {
  Id_Instit: string;

    constructor(sortField: string, sortDir: SortDirection) {
      super(sortField, sortDir);
      const inst = sessionStorage.getItem('currentInst');
      if (inst !== '' && inst !== undefined && inst !== 'undefined') {
        const institution: Instituciones = JSON.parse(inst);        
        this.Id_Instit =  institution.IdInstitucion;
      } else {
        this.Id_Instit = '';
      }
      
    }
  }
  