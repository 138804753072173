import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { TarifasFilter } from '../models/tarifas-filters';
import { TarifaFilterService } from './tarifa-filter.service';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';


@Injectable()
export class TarifaService {
  displayedItems = [];

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  getAll(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new TarifaFilterService().createFilterParams(new TarifasFilter('DESC_TARIFA', 'asc'), 0, 99999, 'DESC_TARIFA', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTarifas'],  params);
    }
  }

}
