import { ReservesResum } from './../models/reserves-resum';
import { ReservesService } from './../services/reserves.service';
import { ReservesPersonesTableComponent } from './../components/reserves-persones-table/reserves-persones-table.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { FormControl } from '@angular/forms';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { MatDialog } from '@angular/material';
import { ReservesFilter, TipoVehiculo } from '../models/reserves-filter';
import { ReservesFilterComponent } from '../components/reserves-filter/reserves-filter.component';
import { ReservesCotxesTableComponent } from '../components/reserves-cotxes-table/reserves-cotxes-table.component';
import { ReservesMotosTableComponent } from '../components/reserves-motos-table/reserves-motos-table.component';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { NotificationService } from 'path-shared/services/notification/notification.service';

@Component({
  selector: 'app-reserves',
  templateUrl: './reserves.component.html',
  styleUrls: ['./reserves.component.scss']
})
export class ReservesComponent implements OnInit {

  selected = new FormControl(0);
  filter: ReservesFilter = new ReservesFilter('id', 'desc', TipoVehiculo.VehiculoCoche);
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  resumenEstadoActual = new ReservesResum();

  @ViewChild('reservesCotxes') reservesCotxesTable: ReservesCotxesTableComponent;
  @ViewChild('reservesMotos') reservesMotosTable: ReservesMotosTableComponent;
  @ViewChild('reservesPersones') reservesPersonesTable: ReservesPersonesTableComponent;

  currentCotxes = 0;
  currentMotos = 0;
  currentPersones = 0;

  constructor(
    public authService: AuthorizationService,
    private i18n: I18n,
    private filterService: FilterService,
    private dialog: MatDialog,
    private reservasService: ReservesService,
    private not: NotificationService) { }

  ngOnInit() {
    this.authService.CheckUserPermission(this.authService.Pages.Reserves);
    this.filterService.loadFilter('reserves-filter', this.filter);
    if (this.filter.fechaInicio === null || this.filter.fechaInicio === undefined || this.filter.fechaInicio.toString() === '') {
      this.filter.fechaInicio = new Date();
      this.filter.fechaInicio.setHours(0, 0, 0, 0);
      this.filter.set(this.filter);
      this.filter.updated();
    }
    this.reservasService.getResumenEstado(this.filter).subscribe(result => {
      this.getResultadoResumenEstado(result);
    });
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.filterService.saveFilter('reserves-filter', this.filter);
        this.reservesCotxesTable.updateFilterFromParent(this.filter);
        this.reservesMotosTable.updateFilterFromParent(this.filter);
        this.reservesPersonesTable.updateFilterFromParent(this.filter);
        if (this.filter.fechaInicio !== undefined && this.filter.fechaInicio !== null && this.filter.fechaInicio.toString() !== '') {
          this.reservasService.getResumenEstado(this.filter).subscribe(result => {
            this.getResultadoResumenEstado(result);
          });
        } else {
          this.resumenEstadoActual = new ReservesResum();
        }
      }
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Reserves'));
    }
  }

//#region Toolbar events

  refresh() {
    this.reservesCotxesTable.updateFilterFromParent(this.filter);
    this.reservesMotosTable.updateFilterFromParent(this.filter);
    this.reservesPersonesTable.updateFilterFromParent(this.filter);
    if (this.filter.fechaInicio !== undefined && this.filter.fechaInicio !== null && this.filter.fechaInicio.toString() !== '') {
      this.reservasService.getResumenEstado(this.filter).subscribe(result => {
        this.getResultadoResumenEstado(result);
      });
    } else {
      this.resumenEstadoActual = new ReservesResum();
    }
  }

  onFilterClicked() {
    const ref = this.dialog.open(ReservesFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.filter
      }
    });
    ref.componentInstance.notify.subscribe((filter: ReservesFilter) => {
      this.filter.set(filter);
    });
  }

  generateExcel() {
    // forcem que el tipoVehiculo sigui null per poder pintar la informació en 3 tabs de l'excel
    const filterExcel = Object.assign(this.filter);
    filterExcel.tipoVehiculo = null;

    this.dialog.open(DownloadExcelDialogComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: Builder.newDownloadExcelDialogData(this.reservasService, filterExcel, this.i18n('Reserves'), 'desc', 'id')
    });
  }

//#endregion

  getResultadoResumenEstado(value: any): void {
    this.resumenEstadoActual = value;
  }

  setBadgeCount(value: number, tipoVehiculo: TipoVehiculo) {
    switch (tipoVehiculo) {
      case TipoVehiculo.SinVehiculo:
        this.currentPersones = value;
        break;
      case TipoVehiculo.VehiculoCoche:
        this.currentCotxes = value;
        break;
      case TipoVehiculo.VehiculoMoto:
        this.currentMotos = value;
        break;
    }
  }

}
