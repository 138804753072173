import { ValidationService } from './../../../services/validation/validation.service';
import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-form-tri-state',
  templateUrl: './form-tri-state.component.html',
  styleUrls: ['./form-tri-state.component.scss']
})
export class FormTriStateComponent implements OnInit, AfterViewInit {
  val: boolean;
  selected: string;
  @Input() label: string;
  @Input() labelSi: string;
  @Input() labelNo: string;
  @Input() labelTodos: String;
  @Input() get value() {
    return this.val;
  } set value(val) {
    this.val = val;
    this.setSelected();
    this.valueChange.emit(this.val);
  }
  @Input() triStateDisabled: boolean;
  @Output() valueChange = new EventEmitter();
  @Input() disabled: boolean;
  @Input() required: boolean;


  constructor(
    private validation: ValidationService
  ) {
    this.triStateDisabled = false;
    this.val = true;
    this.disabled = false;
    this.required = false;
  }

  ngOnInit() {
    this.setSelected();
  }

  private setSelected() {
    switch (this.value) {
      case null: case undefined:
        this.selected = 'option3';
        break;
      case true:
        this.selected = 'option1';
        break;
      case false:
        this.selected = 'option2';
        break;
    }
  }

  ngAfterViewInit() {
  }

  clickedOption(option: string, value: boolean): void {
    if (!this.disabled) {
      this.selected = option;
      this.value = value;
    }
  }

  valid(): boolean {
    if (this.isRequired()) {
      return this.validation.isBoolean(this.value);
    } else {
      return true;
    }
  }

  isRequired() {
    return this.required !== false;
  }

  errorRequired() {
    return this.isRequired() && !this.validation.isBoolean(this.value);
  }


}
