<app-dialog-header i18n-label label="Cobrar"></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" *ngIf="this.auth.UserCanWrite(this.auth.Pages.Denuncies)"></app-tool-button>
  <app-tool-button text="Cancel·lar" i18n-text (click)="this.cancel()" color="has-color-red" icon="fa-times"></app-tool-button>
</app-toolbar>

<app-dialog-body>
  <form #mainForm>
    <div class="container">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <!-- Data -->
            <app-form-date-edit class="col-sm-3" i18n-label label="Data" [(value)]="this.denunciaCobrar.fecha"
              (valueChange)="this.recalcularImportes()" [maxDate]="this.dataMax" type="calendar" required></app-form-date-edit>

            <!-- Forma de pagament -->
            <app-combo-box-input #comboFormaPago class="col-sm-5" i18n-label label="Forma de pagament" [showingOptions]="this.formasPagoDictionary.value"
              (optionChanged)="this.formaPagoSeleccionado($event)" [(value)]="this.denunciaCobrar.formaPagoId" required>
            </app-combo-box-input>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Interessos (de moment disabled) -->
        <div class="col-sm-5">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title" i18n>Interessos</h5>
                <!-- Data inici i fi -->
                <app-form-date-range-edit class="col-sm-12" label1="Data inicio" label2="Data fin" [(value)]="this.denunciaCobrar.rangoFechaIntereses" [disabled]="true"></app-form-date-range-edit>
            </div>
          </div>
        </div>

        <!-- Facturació -->
        <div class="col-sm-7">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title" i18n>Facturació</h5>
              <div class="row">
                <!-- Import base --> 
                <!-- Sergio 11/11/2021: quitamos el limitador máximo por orden de jero... [maxNumberValue]="this.denunciaCobrar.importeMaximo" -->
                <app-form-text-edit class="col-sm-6"  i18n-label label="Import a cobrar" type="number" [(value)]="this.denunciaCobrar.importeCobrar"  (valueChange)="this.calcularImporteTotal()" required ></app-form-text-edit>
                <span *ngIf="this.denunciaDetail.importeCobrado>0" class="col-5 bg-info card" style="text-align:center; justify-content:center;padding: 0.5vh; display: block;">Import cobrat: {{this.denunciaDetail.importeCobrado}} €</span>
              </div>
              <div class="row">
                <!-- Augment: combo (0%, 5%, 10%, 20%) i input eur. (disable) -->
                <app-combo-box-input [disabled]="this.denunciaDetail.importeCobrado!==null" #comboImporteAumentoPorcentaje class="col-sm-6" i18n-label label="Augment %" [showingOptions]="this.importeAumentoPorcentajes.value"
                [(value)]="this.denunciaCobrar.importeAumentoPorcentaje" (optionChanged)="this.aumentoPorcentajeChange($event)" >
                </app-combo-box-input>
                <app-form-currency-text-edit class="col-sm-6" i18n-label label="Augment" [(value)]="this.denunciaCobrar.importeAumento" [disabled]="true" required></app-form-currency-text-edit>
              </div>
              <div class="row">
                <!-- Interessos: % i input eur. (disable) -->
                <app-combo-box-input #comboImporteAumentoPorcentaje class="col-sm-6" i18n-label label="Interessos %" [showingOptions]="this.importeInteresesPorcentajes.value"
                  (optionChanged)="this.aumentoPorcentajeChange($event)" [(value)]="this.denunciaCobrar.importeInteresesPorcentaje" [disabled]="true">
                </app-combo-box-input>
                <app-form-currency-text-edit class="col-sm-6" i18n-label label="Interessos" [(value)]="this.denunciaCobrar.importeIntereses" [disabled]="true"></app-form-currency-text-edit>
              </div>
              <div class="row">
                <!-- Afegit (disable) -->
                <app-form-currency-text-edit class="col-sm-12" i18n-label label="Afegit" [(value)]="this.denunciaCobrar.importeAnadido" [disabled]="true" required></app-form-currency-text-edit>
              </div>
              <div class="row">
                <!-- Import total (disable) -->
                <app-form-currency-text-edit class="col-sm-6" i18n-label label="Import total" [(value)]="this.denunciaCobrar.importeTotal" [disabled]="true" required></app-form-currency-text-edit>
                <span class="col-5 bg-warning card" style="text-align:center; justify-content:center;padding: 0.5vh; display: block;"><span i18n>Import restant: </span>{{Abs(this.denunciaCobrar.importeMaximo + this.denunciaCobrar.importeAumento)}} €</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-dialog-body>
