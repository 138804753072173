
<div class="row">

  <app-form-text-edit id="escala-ocupacion-editor-rang" class="col-4" [isReadOnly]="!this.currentEditing" [(value)]="this.currentEscala.rangoSuperior" 
    i18n-label label="Rang superior" number [required]="this.isError">
  </app-form-text-edit>
</div>
  
<div class="row" style="justify-content: center;">
  <input class="col-8" class="form-check-input" style="color: transparent; position:inherit; margin-left: 0px; height: 200px; width: 100px; cursor: pointer;"
          [style.background]="this.getColor(this.item)" [disabled]="!this.currentEditing" *ngFor="let item of this.coloresBase" (click)="this.changeColor(this.item)">
  
</div>

<div class="row" style="justify-content: center;">
  <div class="alert alert-danger" style="margin-bottom: 0px; margin-top: 10px;" role="alert" *ngIf="this.isError">
    Has de seleccionar un color i definir un rang superior!
  </div>  
</div>
