/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./qr-viewer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../dialog/dialog-header/dialog-header.component";
import * as i4 from "../../toolbar/toolbar/toolbar.component.ngfactory";
import * as i5 from "../../toolbar/toolbar/toolbar.component";
import * as i6 from "../../toolbar/tool-button/tool-button.component.ngfactory";
import * as i7 from "../../toolbar/tool-button/tool-button.component";
import * as i8 from "./qr-viewer.component";
import * as i9 from "@angular/material/dialog";
var styles_QrViewerComponent = [i0.styles];
var RenderType_QrViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QrViewerComponent, data: {} });
export { RenderType_QrViewerComponent as RenderType_QrViewerComponent };
export function View_QrViewerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "app-toolbar", [], null, null, null, i4.View_ToolbarComponent_0, i4.RenderType_ToolbarComponent)), i1.ɵdid(3, 114688, null, 0, i5.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "app-tool-button", [["color", "has-color-green"], ["icon", "fa-file-download"], ["text", "Descarregar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downolad(i1.ɵnov(_v, 7)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ToolButtonComponent_0, i6.RenderType_ToolButtonComponent)), i1.ɵdid(5, 114688, null, 0, i7.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [["qrImage", 1]], null, 0, "qrcode", [], [[8, "qrdata", 0], [8, "width", 0], [8, "errorCorrectionLevel", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); var currVal_1 = "Descarregar"; var currVal_2 = "has-color-green"; var currVal_3 = "fa-file-download"; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.qrData; var currVal_5 = _co.width; var currVal_6 = "M"; _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_QrViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-qr-viewer", [], null, null, null, View_QrViewerComponent_0, RenderType_QrViewerComponent)), i1.ɵdid(1, 114688, null, 0, i8.QrViewerComponent, [i9.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QrViewerComponentNgFactory = i1.ɵccf("app-qr-viewer", i8.QrViewerComponent, View_QrViewerComponent_Host_0, {}, {}, []);
export { QrViewerComponentNgFactory as QrViewerComponentNgFactory };
