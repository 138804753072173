
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { CargaMatriculaInfo } from '../models/CargaMatriculaInfo.model';
import { HttpHeaders } from '@angular/common/http';
import { ErrorsMatriculaFilter } from '../models/errorsMatriculaFilter';

const defaultSortParam = 'exp';
const defaultSortDirection = 'desc';

@Injectable()
export class CargaMatriculasService {
  displayedItems = [];

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checked'] = 0;
  }

  // getExpedientesSinMatricula(numRegistrosConsultar: number, nomesNoves: boolean, exp: string, matricula: string): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetExpedientesMatriculaSinProp']
  //       .replace('{numRegistrosConsultar}', numRegistrosConsultar.toString())
  //       .replace('{nomesNoves}', nomesNoves ? 'true':'false')
  //       .replace('{exp}', exp ? exp: null)
  //       .replace('{matricula}', matricula ? matricula:null)
  //       , null);
  //   }
  // }
  cargaExpedientesDgt(carga) {
    if (this.authGuard.canActivate()) {
      return this.http.postJSON(config.urls['apiUrl'] + api.endpoints['urlCargaExpedientesMatriculaSinProp'], carga);
    }
  }

  getDgtErrorsMatricules() : Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDgtErrorsMatricules'], null);
    }
  }
}
