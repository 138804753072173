import { Component, OnInit, Input } from '@angular/core';
import { AlarmGroup } from 'path-shared/models/alarm-group';

@Component({
  selector: 'app-alarm-header-menu',
  templateUrl: './alarm-header-menu.component.html',
  styleUrls: ['./alarm-header-menu.component.scss']
})
export class AlarmHeaderMenuComponent implements OnInit {
  @Input() alarm: AlarmGroup;

  constructor() { }

  ngOnInit() {
  }

}
