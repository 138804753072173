import { MotiusDesestimarFilterComponent } from './../../components/motius-desestimar-filter/motius-desestimar-filter.component';
import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MotiusDesestimarFilter } from '../../models/motius-desestimar-filter';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { MotiusDesestimarTableComponent } from '../../components/motius-desestimar-table/motius-desestimar-table.component';
import { MotiusDesestimarEditorComponent } from '../../components/motius-desestimar-editor/motius-desestimar-editor.component';

@Component({
  selector: 'app-motius-desestimar',
  templateUrl: './motius-desestimar.component.html',
  styleUrls: ['./motius-desestimar.component.scss']
})
export class MotiusDesestimarComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: MotiusDesestimarFilter;
  somIdFoo: number;
  tipusMatricules = Array();
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(MotiusDesestimarTableComponent) motiusDesestimarTable: MotiusDesestimarTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<MotiusDesestimarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new MotiusDesestimarFilter('id', 'desc');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.MotiusDesestimar);
    this.motiusDesestimarTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.motiusDesestimarTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Motius Desestimar'));
    }
  }

  edit() {
    const idSeleccionat: string = this.motiusDesestimarTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

    const ref = this.dialog.open(MotiusDesestimarEditorComponent, {
      width: '70%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.motiusDesestimarTable.load(); }
    });
  }

  refresh() {
    this.motiusDesestimarTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(MotiusDesestimarFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.motiusDesestimarTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: MotiusDesestimarFilter) => {
      this.motiusDesestimarTable.filter.set(filter);
      this.motiusDesestimarTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.motiusDesestimarTable.generateExcel();
  }

}
