/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sincronitzacio.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/form/form-check-edit/form-check-edit.component.ngfactory";
import * as i3 from "../../../../../shared/components/form/form-check-edit/form-check-edit.component";
import * as i4 from "../../../../../shared/services/validation/validation.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../shared/pipes/long-date-pipe";
import * as i7 from "@ngx-translate/i18n-polyfill";
import * as i8 from "../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i9 from "../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i10 from "../../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component.ngfactory";
import * as i11 from "../../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component";
import * as i12 from "../../../../../shared/services/authorization/authorization-service";
import * as i13 from "@angular/material/dialog";
import * as i14 from "../../../../../shared/components/dialog/dialog-body/dialog-body.component.ngfactory";
import * as i15 from "../../../../../shared/components/dialog/dialog-body/dialog-body.component";
import * as i16 from "./sincronitzacio.component";
import * as i17 from "../../../../../shared/services/notification/notification.service";
import * as i18 from "../../services/sincronitzacio.service";
import * as i19 from "ngx-toastr";
var styles_SincronitzacioComponent = [i0.styles];
var RenderType_SincronitzacioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SincronitzacioComponent, data: {} });
export { RenderType_SincronitzacioComponent as RenderType_SincronitzacioComponent };
function View_SincronitzacioComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "card h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "card-header card-header-sdw"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["style", "display: inline-block;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PDA"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fas fa-mobile-alt card-header-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 14, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h5", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sincronitzaci\u00F3 PDA"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "card-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Prem el bot\u00F3 \"Actualitzar\" per a qu\u00E8 les PDAs rebin la configuraci\u00F3 m\u00E9s actualitzada."])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-form-check-edit", [["label", "Sincronitzaci\u00F3 Completa?"], ["style", "margin:auto; display: inline-block;"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.completa = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormCheckEditComponent_0, i2.RenderType_FormCheckEditComponent)), i1.ɵdid(12, 114688, null, 0, i3.FormCheckEditComponent, [i4.ValidationService], { label: [0, "label"], value: [1, "value"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵted(-1, null, [" \u00A0 "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fas fa-info-circle"], ["style", "color: cornflowerblue; display: inline-block;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0 "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "p", [["class", "card-text info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Actualitza tamb\u00E9 les dades de configuraci\u00F3 de Municipis"])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["class", "btn btn-sq-lg btn-primary btn-sync"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.actualitzar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Actualitzar"])), (_l()(), i1.ɵeld(21, 0, null, null, 3, "div", [["class", "card-footer"], ["style", "text-align: end;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 2, "small", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(23, null, ["\u00DAltima actualitzaci\u00F3: ", ""])), i1.ɵppd(24, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Sincronitzaci\u00F3 Completa?"; var currVal_1 = _co.completa; _ck(_v, 12, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 23, 0, _ck(_v, 24, 0, i1.ɵnov(_v.parent, 0), _co.lastSyncDatePdas)); _ck(_v, 23, 0, currVal_2); }); }
function View_SincronitzacioComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-pill badge-primary badge-sync-attention scale-animation"], ["title", "Sincronitzaci\u00F3 necess\u00E0ria!"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["!"]))], null, null); }
function View_SincronitzacioComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SincronitzacioComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 16, "div", [["class", "card h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "card-header card-header-sdw"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [["style", "display: inline-block;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["GTWIN"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fas fa-download card-header-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h5", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sincronitzaci\u00F3 GTWIN"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [["class", "card-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Prem el bot\u00F3 \"Sincronitzar\" per a descarregar la configuraci\u00F3 m\u00E9s actualitzada desde GTWIN. En acabar la sincronitzaci\u00F3 amb GTWIN s'actualitzar\u00E0n les PDAs autom\u00E0ticament."])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-sq-lg btn-primary btn-sync"], ["style", "margin-top: 1.5vh;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sincronitzarGtwin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sincronitzar"])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "card-footer"], ["style", "text-align: end;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "small", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, ["\u00DAltima actualitzaci\u00F3: ", ""])), i1.ɵppd(19, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.attention; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i1.ɵnov(_v.parent, 0), _co.lastSyncDateDiba)); _ck(_v, 18, 0, currVal_1); }); }
export function View_SincronitzacioComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.LongDatePipe, [i7.I18n]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-toolbar", [], null, null, null, i8.View_ToolbarComponent_0, i8.RenderType_ToolbarComponent)), i1.ɵdid(2, 114688, null, 0, i9.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "app-tool-button-permissions", [], null, null, null, i10.View_ToolButtonPermissionsComponent_0, i10.RenderType_ToolButtonPermissionsComponent)), i1.ɵdid(4, 114688, null, 0, i11.ToolButtonPermissionsComponent, [i12.AuthorizationService, i13.MatDialog], { permission: [0, "permission"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "app-dialog-body", [], null, null, null, i14.View_DialogBodyComponent_0, i14.RenderType_DialogBodyComponent)), i1.ɵdid(6, 114688, null, 0, i15.DialogBodyComponent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 4, "div", [["class", "row"], ["style", "max-width: 85%; margin-top: 3.5vh;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SincronitzacioComponent_1)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SincronitzacioComponent_2)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.auth.Pages.Sincronitzacio; _ck(_v, 4, 0, currVal_0); _ck(_v, 6, 0); var currVal_1 = (_co.lastSyncDatePdas !== undefined); _ck(_v, 9, 0, currVal_1); var currVal_2 = (_co.attention && (_co.lastSyncDateDiba !== null)); _ck(_v, 11, 0, currVal_2); }, null); }
export function View_SincronitzacioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sincronitzacio", [], null, null, null, View_SincronitzacioComponent_0, RenderType_SincronitzacioComponent)), i1.ɵdid(1, 114688, null, 0, i16.SincronitzacioComponent, [i7.I18n, i17.NotificationService, i18.SincronitzacioService, i19.ToastrService, i12.AuthorizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SincronitzacioComponentNgFactory = i1.ɵccf("app-sincronitzacio", i16.SincronitzacioComponent, View_SincronitzacioComponent_Host_0, {}, {}, []);
export { SincronitzacioComponentNgFactory as SincronitzacioComponentNgFactory };
