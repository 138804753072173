var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var VehicleFilter = /** @class */ (function (_super) {
    __extends(VehicleFilter, _super);
    function VehicleFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.id_vehicles = '';
        _this.matricula = '';
        _this.marca = '';
        _this.model = '';
        _this.color = '';
        _this.secure_id_persona = '';
        _this.proposta = null;
        _this.asseguranca = '';
        _this.poliza = '';
        _this.observacions = '';
        _this.origen = '';
        _this.tipus_vehicle = '';
        _this.requisitoria = null;
        _this.SECURE_ID = '';
        _this.labels['id'] = _this.i18n('Id');
        _this.labels['matricula'] = _this.i18n('Matricula');
        _this.labels['marca'] = _this.i18n('Marca');
        _this.labels['model'] = _this.i18n('Model');
        _this.labels['color'] = _this.i18n('Color');
        _this.labels['proposta'] = _this.i18n('Proposta');
        _this.labels['asseguranca'] = _this.i18n('ASsegurança');
        _this.labels['poliza'] = _this.i18n('Polissa');
        _this.labels['observacions'] = _this.i18n('Observacions');
        _this.labels['origen'] = _this.i18n('Origen');
        _this.labels['tipus_vehicle'] = _this.i18n('Tipus vehicle');
        _this.labels['requisitoria'] = _this.i18n('Requisitoria');
        return _this;
    }
    return VehicleFilter;
}(FilterData));
export { VehicleFilter };
