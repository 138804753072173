/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./listado-resoluciones-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../shared/components/dialog/filter-header/filter-header.component.ngfactory";
import * as i3 from "../../../../../../../../shared/components/dialog/filter-header/filter-header.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i6 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i7 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i8 from "../../../../../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i9 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i10 from "./listado-resoluciones-filter.component";
var styles_ListadoResolucionesFilterComponent = [i0.styles];
var RenderType_ListadoResolucionesFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListadoResolucionesFilterComponent, data: {} });
export { RenderType_ListadoResolucionesFilterComponent as RenderType_ListadoResolucionesFilterComponent };
export function View_ListadoResolucionesFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-header", [["title", "Filtrar resolucions"]], null, [[null, "apply"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("apply" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (_co.reset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_FilterHeaderComponent_0, i2.RenderType_FilterHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.FilterHeaderComponent, [i4.MatDialogRef], { title: [0, "title"] }, { apply: "apply", reset: "reset" }), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 3, "app-form-date-edit", [["class", "col-sm-6"], ["label", "DataIni"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.dataIni = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FormDateEditComponent_0, i5.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i6.DateTimeAdapter, i7.MomentDateTimeAdapter, [i6.OWL_DATE_TIME_LOCALE]), i1.ɵdid(6, 114688, null, 0, i8.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i9.OWL_DATE_TIME_FORMATS, i8.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(8, 16777216, null, null, 3, "app-form-date-edit", [["class", "col-sm-6"], ["label", "DataFi"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.dataFi = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FormDateEditComponent_0, i5.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i6.DateTimeAdapter, i7.MomentDateTimeAdapter, [i6.OWL_DATE_TIME_LOCALE]), i1.ɵdid(10, 114688, null, 0, i8.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i9.OWL_DATE_TIME_FORMATS, i8.MY_CUSTOM_FORMATS, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Filtrar resolucions"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "DataIni"; var currVal_2 = _co.filter.dataIni; var currVal_3 = "col-sm-6"; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "DataFi"; var currVal_5 = _co.filter.dataFi; var currVal_6 = "col-sm-6"; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6); }, null); }
export function View_ListadoResolucionesFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-listado-resoluciones-filter", [], null, null, null, View_ListadoResolucionesFilterComponent_0, RenderType_ListadoResolucionesFilterComponent)), i1.ɵdid(1, 114688, null, 0, i10.ListadoResolucionesFilterComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListadoResolucionesFilterComponentNgFactory = i1.ɵccf("app-listado-resoluciones-filter", i10.ListadoResolucionesFilterComponent, View_ListadoResolucionesFilterComponent_Host_0, {}, { notify: "notify" }, []);
export { ListadoResolucionesFilterComponentNgFactory as ListadoResolucionesFilterComponentNgFactory };
