import { OnInit, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { timer } from 'rxjs';
import { ValidationService } from 'path-shared/services/validation/validation.service';
var FormTextCurrencyEditComponent = /** @class */ (function () {
    function FormTextCurrencyEditComponent(validator) {
        this.validator = validator;
        this.validVal = false;
        this.valueChange = new EventEmitter();
        this.val = null;
        this.disabled = false;
        this.required = false;
        this.isReadOnly = false;
        this.tabindex = 0;
    }
    Object.defineProperty(FormTextCurrencyEditComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            var _this = this;
            if (this.val !== val) {
                this.val = val;
                this.valid();
                if (this.validVal) {
                    setTimeout(function () {
                        _this.valueChange.emit(_this.val);
                    }, 10);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    FormTextCurrencyEditComponent.prototype.ngOnInit = function () {
    };
    FormTextCurrencyEditComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.autofocus !== undefined) {
            timer(500).subscribe(function () {
                _this.elementRef.nativeElement.focus();
            });
        }
    };
    FormTextCurrencyEditComponent.prototype.valid = function () {
        var ok = !this.errorRequired() && !this.errorCurrency();
        this.validVal = ok;
        return ok;
    };
    FormTextCurrencyEditComponent.prototype.isRequired = function () {
        return this.required !== false;
    };
    FormTextCurrencyEditComponent.prototype.errorRequired = function () {
        return this.isRequired() && this.empty();
    };
    FormTextCurrencyEditComponent.prototype.empty = function () {
        return (this.value === null || this.value === '');
    };
    FormTextCurrencyEditComponent.prototype.errorCurrency = function () {
        return !this.validator.isNumeric(this.value) && !this.empty();
    };
    FormTextCurrencyEditComponent.prototype.valueChangeCurrency = function (currency) {
        if (!this.validator.isEmpty(currency)) {
            currency = currency.replace('.', '').replace(',', '.').replace('€', '').trim();
        }
        if (this.validator.isNumeric(currency)) {
            return parseFloat(currency.replace(/[^0-9.-]+/g, '')).toFixed(2);
        }
        else {
            return currency;
        }
    };
    return FormTextCurrencyEditComponent;
}());
export { FormTextCurrencyEditComponent };
