import { FilterData } from './../../../../shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class TarifasFilter extends FilterData {
    COD_TARIFA: string;
    DESC_TARIFA: string;


  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.DESC_TARIFA = '';
    this.COD_TARIFA = '';

  }


    // this.labels['descripcio'] = this.i18n('Descripció');
    // this.labels['denunciaZB'] = this.i18n('Zona blava');
    // this.labels['denunciaPOL'] = this.i18n('Policia');
    // this.labels['reincidents'] = this.i18n('Reincidents');
    // this.labels['guardarLOG'] = this.i18n('Guarda LOG');
    // this.labels['generarAlarma'] = this.i18n('Generar Alarma');

}
