<div class="row">
  <div class="col-sm-4" style="margin-top: auto;">
    <agm-map [latitude]="this.latitud" [longitude]="this.longitud" [styles]="this.styleMap" (mapReady)="this.mapReady($event)">
      <agm-marker *ngFor="let row of this.markersArray; let i = index"
        [latitude]="row.lat"
        (markerClick)="markerClick(row)"
        [longitude]="row.lng"
        [iconUrl]="this.markerIconRoute"
      ></agm-marker>
    </agm-map>
  </div>
  <div class="col-sm-8">
    <div class="row justify-content-between">
      <div class="col">
        <mat-paginator #paginator
          [pageIndex]="0"
          [pageSize]="this.filter.paginator.pageSize"
          [pageSizeOptions]="[20, 40, 60, 100]">
        </mat-paginator>
      </div>
    </div>

    <div #table class="table-sdw is-fixed" style="max-height: 70vh;">
      <mat-table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

        <ng-container matColumnDef="incorpora" *ngIf="this.selectButton"> 
          <mat-header-cell fxFlex="10%" *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><mat-checkbox #checkbox [(ngModel)]="this.allSelected" (change)="SeleccionarTotes()" ></mat-checkbox></mat-header-cell>
          <mat-cell fxFlex="10%" *matCellDef="let row">
            <mat-checkbox  [(ngModel)]="this.displayedItems[row.id].checked"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <!--Id-->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Id</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
        </ng-container>

        <!--Categoria-->
        <ng-container matColumnDef="matricula">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.matricula}}</mat-cell>
        </ng-container>

        <!--Codi-->
        <ng-container matColumnDef="data">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.data | longDate}}</mat-cell>
        </ng-container>

        <!--Nom Complert-->
        <ng-container matColumnDef="llista">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipus Matrícula</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.tipus_matricula}}</mat-cell>
        </ng-container>

        <!--Agent-->
        <ng-container matColumnDef="agent">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Agent</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.agent}}</mat-cell>
        </ng-container>
        <!-- PDA
        <ng-container matColumnDef="PDA">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>PDA</mat-header-cell>
          <mat-cell *matCellDef="let row"></mat-cell>
        </ng-container> -->
        <!--aparell-->
        <ng-container matColumnDef="aparell">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Aparell</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.aparell}} {{row.PDA}}</mat-cell>
        </ng-container>
        <!--zona-->
        <ng-container matColumnDef="zona">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Zona</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.zona}}</mat-cell>
        </ng-container>

        <!--comprovacio resposta-->
        <ng-container matColumnDef="comprovacio">
          <mat-header-cell *matHeaderCellDef i18n>Comprovació Denúncia</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.comprovacio}}</mat-cell>
        </ng-container>

        <!--tiquet-->
        <ng-container matColumnDef="tiquet">
          <mat-header-cell *matHeaderCellDef i18n>Tiquet</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="showTiquet(row.tiquet)"><i *ngIf="row.tiquet !== ''" [class]="'far fa-file-alt'" style="color: mediumslateblue;"></i></a></mat-cell>
        </ng-container>

        <!--error-->
        <ng-container matColumnDef="error">
          <mat-header-cell *matHeaderCellDef i18n>Error</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.error}}</mat-cell>
        </ng-container>

        <!--resposta-->
        <ng-container matColumnDef="resposta">
          <mat-header-cell *matHeaderCellDef i18n>Resposta</mat-header-cell>
          <!-- <mat-cell *matCellDef="let row">{{row.resposta}}</mat-cell> -->
          <mat-cell *matCellDef="let row"><a (click)="showResposta(row)"><i *ngIf="row.resposta!==null" [class]="'far fa-file-alt'" style="color: steelblue;"></i></a></mat-cell>
        </ng-container>

        <ng-container matColumnDef="imatges">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Imatges</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="showImages(row)" [ngClass]="[ this.hasImages(row.imatges) ? '' : 'empty']"><i [class]="getImageClass(row.imatges)"></i></a></mat-cell>
        </ng-container>
        <ng-container matColumnDef="dgt">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>DGT</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="showDGT(row)"><img class="navbar-brand-full" src="/assets/images/dgt6.png"  height="14,55px" ></a></mat-cell>
        </ng-container>
        
        <!--id denuncia-->
        <ng-container matColumnDef="idDenuncia">
          <mat-header-cell *matHeaderCellDef i18n>Id Denúncia</mat-header-cell>
          <mat-cell *matCellDef="let row" (click)="showDenuncia(row.idDenuncia)">{{row.idDenuncia}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked(row)"></mat-row>
      </mat-table>
    </div>
  </div>
</div>  