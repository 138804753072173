import { NotificationService } from 'path-shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { ViewContainerRef, OnInit, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
var AppComponent = /** @class */ (function () {
    function AppComponent(toastr, vcr, auth, translate, notifications, _document, localeId) {
        this.toastr = toastr;
        this.auth = auth;
        this.translate = translate;
        this.notifications = notifications;
        this._document = _document;
        this.localeId = localeId;
        this.title = '';
        this.onLangChange = undefined;
        this._document.documentElement.lang = this.localeId;
        var inst = sessionStorage.getItem('currentInst');
        if (inst !== '' && inst !== undefined && inst !== 'undefined') {
            AppComponent.institution = JSON.parse(inst);
        }
    }
    Object.defineProperty(AppComponent.prototype, "pageTitle", {
        get: function () {
            return this.title;
        },
        set: function (val) {
            var _this = this;
            timer(100).subscribe(function () { return _this.title = val; });
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.setInstitution = function (inst) {
        AppComponent.institution = inst;
        sessionStorage.setItem('currentInst', JSON.stringify(inst));
        // Limpiamos información de la institucion antigua almacenada en el session storage
        sessionStorage.removeItem('carrersForCombo');
    };
    AppComponent.getInstitution = function () {
        return AppComponent.institution;
    };
    AppComponent.prototype.ngOnInit = function () {
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        this.notifications.configure();
    };
    AppComponent.prototype.userLogged = function () {
        return this.auth.userLogged();
    };
    return AppComponent;
}());
export { AppComponent };
