import { ReservesResum } from './../models/reserves-resum';
import { config } from 'path-shared/configuration/config';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Injectable } from '@angular/core';
import { ReservesFilter } from '../models/reserves-filter';
import { api } from 'path-shared/configuration/urls';
import { Observable } from 'rxjs';
import { ReservesFilterService } from './reserves-filter.service';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';


@Injectable()
export class ReservesService {
    constructor(
        private http: HttpSecureClient,
        private authGuard: AuthGuardService
    ) { }

    generateExcel(filter: ReservesFilter, sortDir: string, sortField: string): Observable<any> {
        const start = 0;
        const pageSize = 1000;

        sortField = sortField === undefined ? defaultSortParam : sortField;
        sortDir = sortDir === '' ? defaultSortDirection : sortDir;
        const params = new ReservesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

        if (this.authGuard.canActivate()) {
            return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlReservesExcel'], params);
        }
    }

    getResumenEstado(filter: ReservesFilter): Observable<any> {
        return this.http.get(
            config.urls['apiUrl'] + api.endpoints['urlGetResumenReservas'].replace('{fechaConsulta}',
            filter.fechaInicio ? this.formatDateToLocal(filter.fechaInicio) : ''),
            null);
    }

    anularReserva(secure_id: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlAnularReserva'].replace('{secure_id}', secure_id), null);
        }
    }

    formatDateToLocal(fecha: Date): string {
        fecha = new Date(fecha);
        const dateString =
            fecha.getFullYear() + '-' +
            ('0' + (fecha.getMonth() + 1)).slice(-2) + '-' +
            ('0' + fecha.getDate()).slice(-2);
        return dateString;
    }
}
