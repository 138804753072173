<app-dialog-header label="DGT" i18n-label *ngIf="this.isDialog"></app-dialog-header>
<app-toolbar>
 <!--<app-tool-button-permissions [permission]="this.auth.Pages.Avisos"></app-tool-button-permissions>-->
  <app-tool-button text="Exportar XML" i18n-text (click)="exportarXml();" color="has-color-purple" icon="fa-file-code" [disabled]="!this.allowDownload"></app-tool-button>
  <app-tool-button text="Incorpora" i18n-text (click)="incorpora();" color="has-color-blue" icon="fa-download" [disabled]="!this.allowDownload" *ngIf="!this.isReadOnly && (this.incorporaData || this.matriculaLoad)" i18n-title title={{this.message}}></app-tool-button>
</app-toolbar>
<div class="row">
  <app-form-text-edit id="xml-tree-viewer-matricula" *ngIf="viewInputMatricula()" (keypress)="keyPressMatricula($event)"  class="col-xl-2 col-md-2 col-sm-2" i18n-label label="Matricula:" [(value)]="this.matricula" [disabled]="this.incorporaData"  maxLength="15"></app-form-text-edit>  
  <div class="alert alert-warning" *ngIf="!this.consulta && this.message!==''">
    <b i18n>Atención!</b>
    {{this.message}}
  </div>

  <div class="p-1" *ngIf="viewInputMatricula()" ><button class="btn btn-sq-lg btn-primary" i18n (click)="dgtMatricula()" [disabled]="!matriculaDgtEnabled()">Consultar</button></div>
  <app-form-text-edit id="xml-tree-viewer-persona" *ngIf="viewInputPersona()" (keypress)="keyPressPersona($event)"class="col-xl-2 col-md-2  col-sm-2" i18n-label label="Persona:" [(value)]="this.persona" [disabled]="this.incorporaData"    maxLength="15"></app-form-text-edit>  
  <div class="p-1" *ngIf="viewInputPersona()"><button class="btn btn-sq-lg btn-primary" i18n (click)="dgtPersona()"  [disabled]="!personaDgtEnabled()">Consultar</button></div>
  <app-form-text-edit id="xml-tree-viewer-eucari" *ngIf="viewInputEucaris()" (keypress)="keyPressEucaris($event)" class="col-xl-2 col-md-2  col-sm-2" i18n-label label="Eucaris:" [(value)]="this.eucaris" [disabled]="this.incorporaData"    maxLength="15"></app-form-text-edit>  
  <div class="p-1"   *ngIf="viewInputEucaris()"><button class="btn btn-sq-lg btn-primary" i18n (click)="dgtEucaris()" [disabled]="!eucarisDgtEnabled()">Consultar</button></div>
  <app-form-text-edit id="xml-tree-viewer-propietari" *ngIf="viewInputPropietari()" (keypress)="keyPressPropietario($event)" class="col-xl-2 col-md-2  col-sm-2" i18n-label label="Propietari:" [(value)]="this.propietario"  [disabled]="this.incorporaData"   maxLength="15"></app-form-text-edit>  
  <div class="p-1" *ngIf="viewInputPropietari()"><button class="btn btn-sq-lg btn-primary" i18n (click)="dgtPropietario()" [disabled]="!propietarioDgtEnabled()">Consultar</button></div>
</div>
<div class="row">
  <div style="height: 70vh; width: 100%; overflow-y: auto">
    <mat-tab-group >
      <mat-tab  *ngFor="let tab of tabs; let index = index" [label]="getLabel(tab)"  class="tab-title">
        <div *ngIf="this.allLoad && tab ==='matricula' && this.vehiculoDgt !== undefined && this.vehiculoDgt !== null" style="padding-top: 15px;">
          <div class="row" style="padding-left: 15px; padding-right: 15px;"> 
            <div class="card col-xl-6"  style="padding-left: 15px; padding-right: 15px; margin-bottom: 0rem !important;">
                <div class="card-body p-3 d-flex align-items-center">
                  <div class="row" style="width: 100%;">
                    <div class="col-xl-1 align-middle align-items-center" >
                      <i class="fa fa-car fa-3x has-color-blue" style="padding-right: 15px;"></i>
                    </div>
                    
                    <div class="col-xl-9">
                      <div class="text-value-sm row"><span i18n class="col-xl-3">Vehicle</span><span class="text-uppercase col-xl-9">{{vehiculoDgt.matricula}}</span></div>
                      <!-- <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Matricula: </span> {{vehiculoDgt.matricula}} </div> -->
                      <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Marca:</span> <span class="col-xl-9">{{vehiculoDgt.marca}}</span> </div>
                      <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Model:</span> <span class="col-xl-9">{{vehiculoDgt.modelo}} </span></div>
                      <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Tipus:</span> <span class="col-xl-9">{{vehiculoDgt.tipoVehiculo}} </span></div>
                      <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Domicili:</span> <span class="col-xl-9">{{vehiculoDgt.domicilioVehiculo}}</span> </div>
                      <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Municipi:</span> <span class="col-xl-9">{{vehiculoDgt.municipioVehiculo}},&nbsp; {{vehiculoDgt.cpVehiculo}} ,&nbsp;{{vehiculoDgt.provinciaVehiculo}}</span> </div>
                      <!-- <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Codi postal vehicle: </span> {{vehiculoDgt.cpVehiculo}} </div>
                      <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Provincia vehicle: </span> {{vehiculoDgt.provinciaVehiculo}} </div> -->
                    </div>
                    <div class="col-xl-2 align-left align-items-center" >
                      <img *ngIf="vehiculoDgt.pathImagenEtiqueta !== undefined && vehiculoDgt.pathImagenEtiqueta !== null 
                      && vehiculoDgt.pathImagenEtiqueta !== ''" [src]="vehiculoDgt.pathImagenEtiqueta">
                      <div *ngIf="vehiculoDgt.pathImagenEtiqueta === undefined || vehiculoDgt.pathImagenEtiqueta === null 
                      || vehiculoDgt.pathImagenEtiqueta === ''" >
                      <i class="fa fa-times-circle fa-3x has-color-red" style="padding-right: 15px;"></i>  
                      <span i18n>Vehicle sense distintiu.</span>
                       </div>
                    </div>
                  </div>
                
                </div>
            </div>
            <div class  ="col-xl-6"  style="padding-left: 15px; padding-right: 15px;margin-bottom: 0rem !important;">
              <div class=" row"  style="margin-bottom: 0rem !important;">
                <div class="card col-xl-12"  style="padding-left: 15px; padding-right: 15px; padding-top: 15px;    margin-bottom: 0rem !important;"> 
                <div class="card-body p-3 d-flex align-items-center">
                  <div class="row" style="width: 100%;">
                    <div class="col-xl-1 align-middle align-items-center" >
                      <i id="iconoITV" class="fa fa-wrench fa-3x has-color-blue" style="padding-right: 15px;"></i>
                    </div>
                      
                <div class="col-xl-10">
                    <div class="text-value-sm row"><span class="col-xl-3" i18n>I.T.V.</span></div>
                    <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold  col-xl-3" i18n>Data:</span><span class="col-xl-9"> {{vehiculoDgt.fechaItv | date: 'dd/MM/yyyy'}} </span></div>
                    <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold  col-xl-3" i18n>Resultat:</span> <span class="col-xl-9">{{vehiculoDgt.resultadoItv }}</span> </div>
                    <div id="fechaCaducidadITV" class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold  col-xl-3" i18n>Data caducitat:</span><span class="col-xl-9">{{vehiculoDgt.fechaCaducidadItv | date: 'dd/MM/yyyy'}}</span>  </div>
                    <!-- <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold  col-xl-3" i18n>Data anterior:</span> <span class="col-xl-9">{{vehiculoDgt.fechaAnterirorItv | date: 'dd/MM/yyyy HH:mm'}} </span></div> -->
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div class="row"  style="margin-bottom: 0rem !important;">
              <div class="card col-xl-12 "  style="padding-right: 15px; margin-bottom: 0rem !important;">
                <div class="card-body p-3 d-flex align-items-center">
                <div class="row" style="width: 100%;">
                  <div class="col-xl-1 align-middle align-items-center" >
                    <i id="iconoSeguro" class="fa fa-car-crash fa-3x has-color-blue" style="padding-right: 15px;"></i>
                  </div>

                <div class="col-xl-10">
                  <div class="text-value-sm  row"><span i18n class="col-xl-3" >Seguro</span></div>
                  <div class="text-muted text-uppercase font-weight small  row"> <span class="font-weight-bold  col-xl-3" i18n>Data inicio: </span><span class="col-xl-9"> {{vehiculoDgt.seguroFechaInicio | date: 'dd/MM/yyyy'}}</span> </div>
                  <div id="fechaCaducidadSeguro" class="text-muted text-uppercase font-weight small  row" *ngIf="this.vehiculoDgt.seguroEnVigor === undefined || this.vehiculoDgt.seguroEnVigor === null || this.vehiculoDgt.seguroEnVigor === false "> 
                    <span class="font-weight-bold col-xl-3" i18n>Data fi: </span><span class="col-xl-9"> {{vehiculoDgt.seguroFechaFin | date: 'dd/MM/yyyy'}} </span>
                  </div>
                  <div class="text-muted text-uppercase font-weight small  row"> <span class="font-weight-bold col-xl-3" i18n>Entitat: </span> <span class="col-xl-9">{{vehiculoDgt.seguroDescripcion}}</span> </div>
                </div>
              </div>
                </div>
              </div>
            </div>
          </div>
          
          </div> 
          <div class="row" style="padding-left: 15px; padding-right: 15px; padding-top: 15px;">
            <div class="card col-xl-6"  style="padding-left: 15px; padding-right: 15px; padding-top: 15px;" 
            *ngIf="!this.vehiculoDgt.personaFisica && this.vehiculoDgt.arrendatario !== undefined && this.vehiculoDgt.arrendatario !== null
             && this.personaDgt !== undefined && this.personaDgt !== null">
              <!-- <div class="card-body p-3 d-flex align-items-center">
                <i class="fa fa-building fa-3x has-color-blue" style="padding-right: 15px;"></i>
                <div> -->
              <div class="card-body p-3 d-flex align-items-center">
              <div class="row" style="width: 100%;">
                <div class="col-xl-1 align-middle align-items-center" >
                  <i class="fa fa-user fa-3x has-color-blue" style="padding-right: 15px;"></i>
                </div>
                
                <div class="col-xl-10"> 
                  <div class="text-value-sm row"><span i18n class="col-xl-3" >Arrendatari</span><span class="col-xl-9">{{personaDgt.nif }}</span></div>
                      
                  <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold  col-xl-3" i18n>Nom i cognoms: </span><span class="col-xl-9">{{personaDgt.nombre}},&nbsp;{{personaDgt.apellido1}} &nbsp; {{ personaDgt.apellido2 }} </span>  </div>
                <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Data naixament: </span> <span class="col-xl-9">{{personaDgt.fechaNacimiento |  date: 'dd/MM/yyyy'}} </span>  </div>
                <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Domicili: </span> <span class="col-xl-9">{{personaDgt.calle}} </span> </div>
                <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Municipi: </span> <span class="col-xl-9">{{personaDgt.municipio}},&nbsp; {{personaDgt.codPostal}} ,&nbsp;{{personaDgt.provincia}}   </span> </div>
                </div>
                </div>
              </div>
            </div>
            <div class="card col-xl-6"  style="padding-left: 15px; padding-right: 15px; padding-top: 15px;" *ngIf="!this.vehiculoDgt.personaFisica">
              <!-- <div class="card-body p-3 d-flex align-items-center">
                <i class="fa fa-building fa-3x has-color-blue" style="padding-right: 15px;"></i>
                <div> -->
              <div class="card-body p-3 d-flex align-items-center">
              <div class="row" style="width: 100%;">
                <div class="col-xl-1 align-middle align-items-center" >
                  <i class="fa fa-building fa-3x has-color-blue" style="padding-right: 15px;"></i>
                </div>
                
                <div class="col-xl-10"> 
                  <div class="text-value-sm row"><span i18n class="col-xl-3">Empresa</span><span class="text-uppercase col-xl-9">{{vehiculoDgt.empresaCif}}</span></div>
                  <!-- <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Cif: </span> {{vehiculoDgt.empresaCif}} </div> -->
                  <!-- <div class="text-muted"> <div class=" text-uppercase font-weight-bold small"> <span>Cif: </span><div class="text-muted font-weight small">{{vehiculoDgt.empresaCif}} </div></div></div>  -->
                  <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold  col-xl-3" i18n>Raó social: </span><span class="col-xl-9"> {{vehiculoDgt.empresaRazonSocial}} </span></div>
                  <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Domicili: </span> <span class="col-xl-9">{{vehiculoDgt.domicilio}} </span></div>
                  <!-- <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Codi postal: </span> {{vehiculoDgt.codigoPostal}} </div> -->
                  <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold  col-xl-3" i18n>Municipi: </span> <span class="col-xl-9">{{vehiculoDgt.municipio}},&nbsp; {{vehiculoDgt.codigoPostal}} ,&nbsp;{{vehiculoDgt.provincia}}  </span> </div>
                  <!-- <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Provincia: </span> {{vehiculoDgt.provincia}} </div> -->
                </div>
                </div>
              </div>
            </div>
            <div class="card col-xl-6"  style="padding-left: 15px; padding-right: 15px; padding-top: 15px;" *ngIf="this.vehiculoDgt.personaFisica">
              <div class="card-body p-3 d-flex align-items-center">
                <div class="row" style="width: 100%;">
                  <div class="col-xl-1 align-middle align-items-center" >
                    <i class="fa fa-user fa-3x has-color-blue" style="padding-right: 15px;"></i>
                  </div>
                  
                  <div class="col-xl-10"> 
                    <div class="text-value-sm row"><span i18n class="col-xl-3">Titular</span><span class="text-uppercase  col-xl-9">{{vehiculoDgt.conductorNif}}</span></div>
                    <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold  col-xl-3" i18n>Nom i cognoms: </span><span class="col-xl-9">{{vehiculoDgt.conductorNombre}},&nbsp;{{vehiculoDgt.conductorApellido1}} &nbsp; {{vehiculoDgt.conductorApellido2}} </span>  </div>
                    <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Data naixament: </span> <span class="col-xl-9">{{vehiculoDgt.fechaNacimientoConductor |  date: 'dd/MM/yyyy'}} </span>  </div>
                    <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Domicili: </span> <span class="col-xl-9">{{vehiculoDgt.domicilio}} </span> </div>
                    <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Municipi: </span> <span class="col-xl-9">{{vehiculoDgt.municipio}},&nbsp; {{vehiculoDgt.codigoPostal}} ,&nbsp;{{vehiculoDgt.provincia}}   </span> </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="card col-xl-6"  style="padding-left: 15px; padding-right: 15px; padding-top: 15px;" *ngIf="vehiculoDgt.indicadores.length>0">
            <div class="card-body p-3 d-flex align-items-center">
              <div class="row" style="width: 100%;">
                <div class="col-xl-1 align-middle align-items-center" >
                  <i id="icono_Indicador" class="fa fa-exclamation-triangle fa-3x has-color-blue" style="padding-right: 15px;"></i>
                </div>
                
                <div class="col-xl-10"> 
                  <div class="text-value-sm row"><span i18n class="col-xl-3">Indicadors</span></div>
                  <div   *ngFor="let ind of vehiculoDgt.indicadores"  class="text-muted text-uppercase font-weight small row"> 
                    <span class="font-weight-bold col-xl-3">{{ind.indicador}}:</span> <span class="col-xl-9">{{ind.valor}}</span>
                   </div>
                </div>
            </div>
          </div>
        </div>
        </div>
        <div class="row" *ngIf="vehiculoDgt !== undefined && vehiculoDgt !== null && (vehiculoDgt.personaFisica ||  (vehiculoDgt.arrendatario !== undefined && vehiculoDgt.arrendatario !== null) )
                                && personaDgt !== undefined && personaDgt !== null && personaDgt.listaPermisosVigentes.length>0 " style="padding-left: 15px; padding-right: 15px; margin-bottom: 0rem !important;">
          <div class="col-xl-6">
            <div class="card col-xl-12  " *ngFor="let carnet of personaDgt.listaPermisosVigentes"  style="padding-right: 15px; margin-bottom: 0rem !important;">
              <div class="card-body p-3 d-flex align-items-center">
              <div class="row" style="width: 100%;">
                <div class="col-xl-1 align-middle align-items-center" >
                  <i id="icono_{{this.carnet.tipoPermiso}}" class=" fa fa-{{ this.carnet.icono }} fa-3x has-color-blue"
                style="padding-right: 15px;"></i>
                </div>

              <div class="col-xl-10">
                <div class="text-value-sm  row"><span i18n class="col-xl-3" >Permís</span><span class="col-xl-9">{{carnet.tipoPermiso }}</span></div>
                <div class="text-muted text-uppercase font-weight small  row"> <span class="font-weight-bold  col-xl-3" i18n>Data inici:</span><span class="col-xl-9"> {{carnet.fechaInicio | date: 'dd/MM/yyyy'}}</span> </div>
                <div id="fecha_{{this.carnet.tipoPermiso}}" class="text-muted text-uppercase font-weight small row" i18n> <span class="font-weight-bold col-xl-3">Data fi: </span><span class="col-xl-9">  {{carnet.fechaFin | date: 'dd/MM/yyyy'}}</span> </div>
              </div>
            </div>
              </div>
            </div>
            </div>
        </div>
      </div>

        <div *ngIf="this.allLoad && tab ==='persona' && this.personaDgt !== undefined && this.personaDgt !== null"  style="padding-left: 15px; padding-right: 15px; padding-top: 15px;" >
          <div class="row">
            <div class="card col-xl-6" >
                <div class="card-body p-3 d-flex align-items-center">
                  <div class="row" style="width: 100%;">
                    <div class="col-xl-1 align-middle align-items-center" >
                      <i class="fa fa-user fa-3x has-color-blue"   style="padding-right: 15px;"></i>
                    </div>
                    <div class="col-xl-10">
                      <div class="text-value-sm row"><span i18n class="col-xl-3" >Persona</span><span class="col-xl-9">{{personaDgt.nif }}</span></div>
                      
                      <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold  col-xl-3" i18n>Nom i cognoms: </span><span class="col-xl-9">{{personaDgt.nombre}},&nbsp;{{personaDgt.apellido1}} &nbsp; {{ personaDgt.apellido2 }} </span>  </div>
                    <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Data naixament: </span> <span class="col-xl-9">{{personaDgt.fechaNacimiento |  date: 'dd/MM/yyyy'}} </span>  </div>
                    <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Domicili: </span> <span class="col-xl-9">{{personaDgt.calle}} </span> </div>
                    <div class="text-muted text-uppercase font-weight small row"> <span class="font-weight-bold col-xl-3" i18n>Municipi: </span> <span class="col-xl-9">{{personaDgt.municipio}},&nbsp; {{personaDgt.codPostal}} ,&nbsp;{{personaDgt.provincia}}   </span> </div>

                      <!-- <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Nom: </span> {{personaDgt.nombre}} </div>
                      <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Cognoms: </span> {{personaDgt.apellido1 }} &nbsp; {{ personaDgt.apellido2 }} </div>
                      <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Data naixament: </span> {{personaDgt.fechaNacimiento | date: 'dd/MM/yyyy' }} </div>
                      <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Domicili: </span> {{personaDgt.calle}} </div>
                      <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Municipi: </span> {{personaDgt.municipio}} </div>
                      <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Codi postal: </span> {{personaDgt.codPostal}} </div>
                      <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Provinica: </span> {{personaDgt.provincia}} </div> -->
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div  *ngFor="let carnet of personaDgt.listaPermisosVigentes" class="row" style="padding-right: 15px; margin-bottom: 0rem !important;">
            <div  class="card col-xl-6 align-self-start" style="padding-right: 15px; margin-bottom: 0rem !important;" >
              <div class="card-body p-3 d-flex align-items-center">
                <div class="row" style="width: 100%;">
                  <div class="col-xl-1 align-middle align-items-center" >
                    <i id="icono_{{this.carnet.tipoPermiso}}" class=" fa fa-{{ this.carnet.icono }} fa-3x has-color-blue"
                  style="padding-right: 15px;"></i>
                  </div>

                <div class="col-xl-10">
                  <div class="text-value-sm  row"><span i18n class="col-xl-3" >Permís</span><span class="col-xl-9">{{carnet.tipoPermiso }}</span></div>
                  <div class="text-muted text-uppercase font-weight small  row"> <span class="font-weight-bold  col-xl-3" i18n>Data inici:</span><span class="col-xl-9"> {{carnet.fechaInicio | date: 'dd/MM/yyyy'}}</span> </div>
                  <div id="fecha_{{this.carnet.tipoPermiso}}" class="text-muted text-uppercase font-weight small row" i18n> <span class="font-weight-bold col-xl-3">Data fi: </span><span class="col-xl-9">  {{carnet.fechaFin | date: 'dd/MM/yyyy'}}</span> </div>
                </div>
              </div>
                </div>
              <!-- <div class="card col-xl-6" > -->
                <!-- <div class="card-body p-3 d-flex align-items-center">
                  <i id="icono_{{this.carnet.tipoPermiso}}" class=" fa fa-{{ this.carnet.icono }} fa-3x has-color-blue"
                  style="padding-right: 15px;"></i>
                  <div>
                    <div class="text-value-sm"><span i18n>Permís</span></div>
                    <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Data inici: </span> {{carnet.fechaInicio | date: 'dd/MM/yyyy'}} </div>
                    <div id="fecha_{{this.carnet.tipoPermiso}}" class="text-muted text-uppercase font-weight small" i18n> <span class="font-weight-bold">Fecha fin: </span> {{carnet.fechaFin | date: 'dd/MM/yyyy'}} </div>
                    <div class="text-muted text-uppercase font-weight small"> <span class="font-weight-bold" i18n>Tipus: </span> {{carnet.tipoPermiso }}</div>
                  </div>
                </div> -->
            <!-- </div> -->
            </div>
          </div>
        </div>


        <div  *ngIf=" this.allLoad &&  tab ==='tree'">
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
              <h7 class="titleTree">{{node.name}}</h7>
                <h5 class="nodeValue">
                  &nbsp; {{node.valor}}
              </h5>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
              <button  class="btn btn-sm btn-link" mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'toggle ' + node.name">
                <mat-icon>
                  {{treeControl.isExpanded(node) ? 'toggle_off' : 'toggle_on'}}
                </mat-icon>
              </button>
              <h5 class="titleTree"> &nbsp; {{node.name}} </h5>
            </mat-tree-node>
          </mat-tree>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
