<app-dialog-header label="Marca" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Aparells) || !this.dialogData.isReadOnly" ></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Aparells) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Aparells) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="marques-vehicles-editor-id" class="col-sm-3" i18n-label label="id" [(value)]="this.marcaVehicle.id" [disabled]="this.marcaVehicle.secureId != null" required maxLength="50"></app-form-text-edit>
      <app-form-text-edit id="marques-vehicles-editor-descripcio" class="col-sm-5" i18n-label label="Descripcio" [(value)]="this.marcaVehicle.descripcio" required  maxLength="150"></app-form-text-edit>
      <app-form-text-edit id="marques-vehicles-editor-refInt" class="col-sm-4" i18n-label label="Referencia Integració" [(value)]="this.marcaVehicle.referenciaIntegracion"  maxLength="50"></app-form-text-edit>
    </div>

    <div *ngIf="this.secure_id!==null">
      <app-tool-button text="Nou" i18n-text (click)="this.openDialog('Add',{})" style="height: 10vmax" color="has-color-green" icon="fa-plus-square" ></app-tool-button>
      <div class="row container text-center">
   
        <!--<button mat-button (click)="openDialog('Add',{})" mat-flat-button color="primary">Add Row</button>-->
        
        <mat-table #table [dataSource]="this.modelos.value" matSort aria-label="Elements" class="is-header-sticky" style="max-height: 200;">
          <!--[dataSource]="this.modelos.value" #mytable mdbTableScroll scrollY="true"   maxHeight="200"  class="is-header-sticky" >class="my-table mat-elevation-z8"-->
          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID</th>
            <td mat-cell *matCellDef="let element"> {{element.som_id}} </td>
          </ng-container>
       
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nom </th>
            <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
          </ng-container>
       
          <ng-container matColumnDef="referenciaIntegracion">
            <th mat-header-cell *matHeaderCellDef> Referencia Integració </th>
            <td mat-cell *matCellDef="let element"> {{element.referenciaIntegracion}} </td>
          </ng-container>
          <!-- Action Column -->
         <!----> <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element" class="action-link"> 
              <app-tool-button (click)="this.openDialog('Update',element)" style="height: 10vmax" color="has-color-green" icon="fa-edit"></app-tool-button>
              <app-tool-button (click)="this.openDialog('Delete',element)" style="height: 10vmax" color="has-color-red" icon="fa-trash-alt"></app-tool-button>
              <!--<a (click)="openDialog('Update',element)">Edit</a> | 
              <a (click)="openDialog('Delete',element)">Delete</a>  -->
            </td>
          </ng-container>-->
       
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </mat-table>
       
      </div>
    </div>
    <div *ngIf="this.secure_id===null" class="alert alert-warning" i18n>No es poden afegir models fins que la marca s'hagi creat correctament.</div>

  </form>
</app-dialog-body>

