import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";
import { PlanificacioFilter } from "./planificacio-filter";

@Injectable()
export class PlanificacioFilterService extends CustomFilterService {
    public createFilterParams(filter: PlanificacioFilter, start: number, pageSize: number, sortField: string, sortDir: string): HttpParams {
        console.log('filterDia:'+filter.Dia);        
        return new HttpParams()
            .append('somId', filter.somId ? filter.somId.toString() : '')
            .append('Dia', filter.Dia ? filter.Dia.toDateString() : '')
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField)
            .append('sortdir', sortDir);
    }

}
