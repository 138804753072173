import { Component, OnInit, Inject, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { Builder } from 'path-shared/models/builder';
import { BehaviorSubject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { FormService } from 'path-shared/services/form/form.service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { VehicleResumen } from '../../../vehicles/models/vehicleDto';
import { VehiclesService } from '../../services/vehicles.service';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';
import { MarquesVehiclesService } from 'app/sections/configuracio/marques-vehicles/services/marques-vehicles-service.service';
import { Dictionary } from 'path-shared/models/dictionary';
@Component({
  selector: 'app-vehicles-editor',
  templateUrl: './vehicles-editor.component.html',
  styleUrls: ['./vehicles-editor.component.scss']
})
export class VehiclesEditorComponent implements OnInit {

  matricula: string;
  dialogData: ComponentDialog;
  vehicle: VehicleResumen;
  marquesDictionary: BehaviorSubject<Array<Dictionary>>;
  tipusVehicleDictionary: BehaviorSubject<Array<Dictionary>>;
  paisDictionary: BehaviorSubject<Array<Dictionary>>;
  editable = true;
  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild('comboMarca') comboMarca: ComboBoxInputComponent;
  @ViewChild('comboTipusVehicle') comboTipusVehicle: ComboBoxInputComponent;
  @ViewChild('comboPais') comboPais: ComboBoxInputComponent;

  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private dialog: MatDialog,
    private i18n: I18n,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    private vehiclesService: VehiclesService,
    private marquesService: MarquesVehiclesService,
    private formService: FormService,
    public thisDialogRef: MatDialogRef<VehiclesEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.matricula = data.id;
    this.dialogData = data;
    this.vehicle = Builder.newVehicle();
    this.editable = data.editable;
  }

  ngOnInit() {
    this.marquesDictionary = new BehaviorSubject(new Array<Dictionary>());
    this.tipusVehicleDictionary = new BehaviorSubject(new Array<Dictionary>());
    this.paisDictionary = new BehaviorSubject(new Array<Dictionary>());

    if (this.matricula !== undefined && this.matricula !== null) {
      this.loadDataVehicle();
    } else {
      this.loadMarques();
      this.loadTipusVehicle();
      this.loadPaisos();
    }
  }

  loadDataVehicle() {
    this.vehiclesService.getVehicle(this.matricula).subscribe(result => {
      this.vehicle = result;

      this.loadMarques();
      this.loadTipusVehicle();
      this.loadPaisos();
    });
  }

  save() {

    if (this.formService.allFieldsValid(this.mainForm)) {
      this.vehiclesService.setVehicle(this.vehicle).subscribe((x: VehicleResumen) => {
        this.toastr.info(this.i18n('Vehicle guardat correctament'));
       this.matricula = x.matricula;
       this.thisDialogRef.close({secure_id: x.SECURE_ID, matricula: x.matricula, marca: x.marca, model: x.model, pais: x.pais, paisDesc: x.paisDesc});
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    if (this.vehicle !== undefined && this.vehicle !== null && this.vehicle.SECURE_ID !== undefined && this.vehicle.SECURE_ID !== null && this.vehicle.SECURE_ID !== '') {
      this.vehiclesService.getVehicle(this.matricula).subscribe(result => {
        this.thisDialogRef.close({secure_id: result.SECURE_ID, matricula: result.matricula, marca: result.marca, model: result.model, pais: result.pais});
      });
    } else {
      this.thisDialogRef.close(false);
    }
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar el vehicle?'))
    .then((confirmed) => {
        if (confirmed) {
          // const saved = this.denunciesService.saveDenuncia(dataJson).subscribe();
          this.vehiclesService.deleteVehicle(this.matricula).subscribe(result => {
            this.toastr.success(this.i18n('Vehicle esborrat correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

  loadMarques() {
    this.marquesService.getAllMarques().subscribe( marques => {
      this.comboMarca.resetShowingOptions();
      if (marques === undefined || marques === null || marques.length === 0) {
        this.marquesDictionary = new BehaviorSubject(new Array<Dictionary>());
      } else {
        this.marquesDictionary = new BehaviorSubject(new Array<Dictionary>());
          const dict = new Array<Dictionary>();
          marques.forEach(element => {
              dict.push(new Dictionary(element.descripcio, element.descripcio));
            });
          this.marquesDictionary.next(dict);
          if (this.vehicle.marca !== undefined && this.vehicle.marca !== null && this.vehicle.marca !== '') {
            this.comboMarca.markAsTouched();
            this.comboMarca.setSelect(this.vehicle.marca);
          }
      }
    });
  }
  marcaSeleccionado(marca: string) {
    this.vehicle.marca = marca;
  }

  loadTipusVehicle() {
    this.vehiclesService.getAllTipusVehciles().subscribe( tipusVehicles => {
      this.comboTipusVehicle.resetShowingOptions();
      if (tipusVehicles === undefined || tipusVehicles === null || tipusVehicles.length === 0) {
        this.tipusVehicleDictionary =  new BehaviorSubject(new Array<Dictionary>());
      } else {
        this.tipusVehicleDictionary =  new BehaviorSubject(new Array<Dictionary>());
          const dict = new Array<Dictionary>();
          tipusVehicles.forEach(element => {
              dict.push(new Dictionary(element.id, element.descripcio));
            });
          this.tipusVehicleDictionary.next(dict);
          if (this.vehicle.tipus_vehicle !== undefined && this.vehicle.tipus_vehicle !== null && this.vehicle.tipus_vehicle !== '') {
            this.comboTipusVehicle.markAsTouched();
            this.comboTipusVehicle.setSelect(this.vehicle.tipus_vehicle);
          }
      }
    });
  }

  tipusVehicleSeleccionado(id: string) {
    this.vehicle.tipus_vehicle = id;
  }
  loadPaisos() {
    this.vehiclesService.getPaisesVehciles().subscribe( paises => {
      this.comboPais.resetShowingOptions();
      if (paises === undefined || paises === null || paises.length === 0) {
        this.paisDictionary =  new BehaviorSubject(new Array<Dictionary>());
      } else {
        this.paisDictionary =  new BehaviorSubject(new Array<Dictionary>());
          const dict = new Array<Dictionary>();
          paises.forEach(element => {
              dict.push(new Dictionary(element.sigla, element.descripcio));
            });
          this.paisDictionary.next(dict);
          if (this.vehicle.pais !== undefined && this.vehicle.pais !== null && this.vehicle.pais !== '') {
            this.comboPais.markAsTouched();
            this.comboPais.setSelect(this.vehicle.pais);
          }
      }
    });
  }
  paisSeleccionado(sigla: string) {
    this.vehicle.pais = sigla;
  }
}
