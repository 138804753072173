/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./intro-alegaciones.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i4 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i5 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i6 from "../../../../../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i7 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i8 from "../../../../../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i9 from "../../../../../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i10 from "../../../../../../../../shared/services/validation/validation.service";
import * as i11 from "./intro-alegaciones.component";
import * as i12 from "@ngx-translate/i18n-polyfill";
import * as i13 from "ngx-toastr";
import * as i14 from "../../../../../../../../shared/services/form/form.service";
import * as i15 from "@angular/material/dialog";
import * as i16 from "../../../../../../../../shared/services/institucions/institucions.service";
import * as i17 from "../../../../../../../../shared/services/files/files.service";
var styles_IntroAlegacionesComponent = [i0.styles];
var RenderType_IntroAlegacionesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntroAlegacionesComponent, data: {} });
export { RenderType_IntroAlegacionesComponent as RenderType_IntroAlegacionesComponent };
export function View_IntroAlegacionesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mainForm: 0 }), i1.ɵqud(402653184, 2, { fileUpload: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["mainForm", 1]], null, 20, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(4, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 15, "div", [["class", "border border-secondary fondo"], ["style", "padding:10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 3, "app-form-date-edit", [["class", "col-8"], ["label", "Data de la presentaci\u00F3 de l'alegaci\u00F3"], ["required", ""]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.dto.fechaAlegacion = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FormDateEditComponent_0, i3.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i4.DateTimeAdapter, i5.MomentDateTimeAdapter, [i4.OWL_DATE_TIME_LOCALE]), i1.ɵdid(11, 114688, null, 0, i6.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"], required: [3, "required"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i7.OWL_DATE_TIME_FORMATS, i6.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-form-text-edit", [["class", "col-8"], ["label", "Registre entrada"], ["required", ""]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.registroEntrada = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormTextEditComponent_0, i8.RenderType_FormTextEditComponent)), i1.ɵdid(15, 4308992, null, 0, i9.FormTextEditComponent, [i10.ValidationService], { label: [0, "label"], required: [1, "required"], value: [2, "value"], class: [3, "class"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(16, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 5, "button", [["class", "btn btn-sq-lg btn-primary"], ["style", "width: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, [[2, 0], ["fileUpload", 1]], null, 0, "input", [["accept", ".pdf"], ["id", "fileUpload"], ["name", "fileUpload"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileSelected($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "i", [["class", "fas fa-edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pujar PDF"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "Data de la presentaci\u00F3 de l'alegaci\u00F3"; var currVal_8 = _co.dto.fechaAlegacion; var currVal_9 = "col-8"; var currVal_10 = ""; _ck(_v, 11, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "Registre entrada"; var currVal_12 = ""; var currVal_13 = _co.registroEntrada; var currVal_14 = "col-8"; _ck(_v, 15, 0, currVal_11, currVal_12, currVal_13, currVal_14); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_IntroAlegacionesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-intro-alegaciones", [], null, null, null, View_IntroAlegacionesComponent_0, RenderType_IntroAlegacionesComponent)), i1.ɵdid(1, 114688, null, 0, i11.IntroAlegacionesComponent, [i12.I18n, i13.ToastrService, i14.FormService, i15.MatDialog, i16.InstitucionsService, i17.FilesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntroAlegacionesComponentNgFactory = i1.ɵccf("app-intro-alegaciones", i11.IntroAlegacionesComponent, View_IntroAlegacionesComponent_Host_0, { exp: "exp" }, { operationResult: "operationResult" }, []);
export { IntroAlegacionesComponentNgFactory as IntroAlegacionesComponentNgFactory };
