import { OnInit, EventEmitter } from '@angular/core';
var ChipsListInputComponent = /** @class */ (function () {
    function ChipsListInputComponent() {
        this.valueChange = new EventEmitter();
        this.selectable = true;
    }
    Object.defineProperty(ChipsListInputComponent.prototype, "valuesList", {
        get: function () {
            return this._valuesList;
        },
        set: function (val) {
            this._valuesList = val;
            if (this.preselectedOption !== undefined) {
                this.selectManually(this.preselectedOption);
            }
        } // Lista de elementos base (todos)
        ,
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipsListInputComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            this.val = val;
            this.valueChange.emit(this.val[0]);
        },
        enumerable: true,
        configurable: true
    });
    ChipsListInputComponent.prototype.ngOnInit = function () {
        this.value = [];
    };
    ChipsListInputComponent.prototype.handleClick = function (index) {
        var elementToPush = this.valuesList.find(function (element) {
            return element.key === index;
        });
        var indexOf = this.value.findIndex(function (p) { return p.key === elementToPush.key && p.value === elementToPush.value; });
        this.valAux = Object.assign([], []);
        if (indexOf !== -1) {
            this.valAux.splice(indexOf, 1);
            if (this.valAux.length > 0) {
                this.value = this.valAux.slice();
                this.preselectedOption = undefined;
            }
        }
        else if (elementToPush.counter.toString() !== '0') {
            this.valAux.push(elementToPush);
            this.value = this.valAux.slice();
            this.preselectedOption = undefined;
        }
    };
    ChipsListInputComponent.prototype.isSelected = function (index) {
        var object = this.valuesList.find(function (element) {
            return element.key === index;
        });
        var isInChipList = object !== undefined;
        var isInSelectedList = false;
        if (this.value !== undefined && object !== undefined && object.counter.toString() !== '0') {
            isInSelectedList = this.value.find(function (element) {
                return element.key === index;
            }) !== undefined;
        }
        return isInChipList && isInSelectedList;
    };
    ChipsListInputComponent.prototype.isSelectable = function (option) {
        return this.selectable && option.counter !== undefined && option.counter !== '0';
    };
    ChipsListInputComponent.prototype.getOptionCounter = function (option) {
        return option.counter;
    };
    ChipsListInputComponent.prototype.selectManually = function (optionKey) {
        var elementoBuscar = this.valuesList.find(function (o) { return o.key.toString() === optionKey.toString(); });
        if (elementoBuscar !== undefined) {
            this.value.push(elementoBuscar);
            this.preselectedOption = undefined;
        }
        else {
            this.preselectedOption = optionKey;
        }
    };
    return ChipsListInputComponent;
}());
export { ChipsListInputComponent };
