import { FilterData } from './../../../../shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class TipusMatriculaFilter extends FilterData {
  codi: string;
  descripcio: string;
  denunciaZB: boolean;
  denunciaPOL: boolean;
  guardarLOG: boolean;
  reincidents: boolean;
  codInsti: string;
  generarAlarma: boolean;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.descripcio = '';
    this.denunciaZB = null;
    this.denunciaPOL = null;
    this.guardarLOG = null;
    this.reincidents = null;
    this.codInsti = '';
    this.codi = '';
    this.generarAlarma = null;

    this.labels['descripcio'] = this.i18n('Descripció');
    this.labels['denunciaZB'] = this.i18n('Zona blava');
    this.labels['denunciaPOL'] = this.i18n('Policia');
    this.labels['reincidents'] = this.i18n('Reincidents');
    this.labels['guardarLOG'] = this.i18n('Guarda LOG');
    this.labels['generarAlarma'] = this.i18n('Generar Alarma');

  }
}
