import { constructDependencies } from "@angular/core/src/di/reflective_provider";

export class PropostesPendentsCount {
  prepropostes: number;
  propostes: number;

  static new(propostes, prepropostes) {
    prepropostes = prepropostes;
    propostes = propostes;
  }
  static Parse (data: string): PropostesPendentsCount {
    const json = JSON.parse(data);
    const aux = new PropostesPendentsCount();
    aux.prepropostes = json.find(x => x.Key == 4) !== undefined ? json.find(x => x.Key == 4).Count : 0;
    aux.propostes = json.find(x => x.Key == 1) !== undefined ? json.find(x => x.Key == 1).Count : 0;
    return aux;
  }
}
