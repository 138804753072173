import { FinalizarTurnoComponent } from './../../../sections/configuracio/grua/turnos/components/finalizar-turno/finalizar-turno.component';
import { IniciarTurnoComponent } from './../../../sections/configuracio/grua/turnos/components/iniciar-turno/iniciar-turno.component';
import { AuthorizationService } from './../../services/authorization/authorization-service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { InstitucionsService } from 'path-shared/services/institucions/institucions.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { MatDialog } from '@angular/material';
import { PropostesPendentsCount } from 'app/sections/propostes/models/proposta-pendents-count.model';

let scrollTimer: any;

/** @title Responsive sidenav */
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit, AfterViewInit {

  alarmCount = 0;
  propostesCount = 0;
  tramitadorCount = 0;
  propostesGrua = 0;
  alegacionsCount = 0;
  timer: any;
  audio: any;
  xaloc = false;
  public aparcareText: string;


  @ViewChild('scrollInner') scrollInnerElement!: ElementRef;

  constructor(
    public auth: AuthorizationService,
    private not: NotificationService,
    private renderer: Renderer2,
    private router: Router,
    private dialog: MatDialog,
    public institutionService: InstitucionsService,
    private titleService: Title,
    private i18n: I18n,
    ) {
      this.propostesGrua = -1;
      const institution = this.institutionService.getInstitution();
      if (institution !== null && institution !== undefined) {
        this.xaloc = institution.xaloc;

        if (institution.IdInstitucion === '100105229425938306122') {
          this.aparcareText = 'Apparquem';
        } else {
          this.aparcareText = 'Aparcare';
        }
      }
    }

  ngOnInit() {
    // if (this.institutionService.getCurrentInstitution() === '100105229425938306122') {
    //   this.aparcareText = 'Apparquem';
    // } else {
    //   this.aparcareText = 'Aparcare';
    // }

    this.not.onAlegacionsPendentsChange.subscribe(alegacions => {
      this.alegacionsCount = alegacions;
    });

    this.not.onAlarmesPendentsChange.subscribe(alarmes => {
      this.alarmCount = alarmes;
    });
    this.not.onPropostesPendentsChange.subscribe((value: PropostesPendentsCount) => {
      if (this.auth.UserCanSeePage(this.auth.Pages.Prepropostes) && !this.auth.IsSuperUser()) {
        this.propostesCount = value.prepropostes;
      } else {
        this.propostesCount = value.propostes;
      }

    });
    this.not.onTramitacioPendentsChange.subscribe(tramitar => {
      this.tramitadorCount = tramitar;
    });
    this.not.onPropostesGruaPendentsChange.subscribe(propostesGrua => {
      if (this.propostesGrua !== undefined && this.propostesGrua !== null
        && this.propostesGrua < propostesGrua && this.propostesGrua !== -1 && this.auth.UserCanSeePage(this.auth.Pages.GruaPendents)) {
          this.audio = new Audio('assets/when.mp3');
          this.audio.loop = true;
          this.audio.play();
          this.titleService.setTitle(  this.i18n('Nova proposta grua!') );
      }
      this.propostesGrua = propostesGrua;
    });
  }
  stopAudio() {
    if (this.audio !== undefined && this.audio !== null) {
      this.audio.pause();
    }
    this.titleService.setTitle( 'SomDen Web' );
    // this.router.navigateByUrl('grua/pedents-table/');
    // event.stopPropagation();
  }


  inciarTurno() {
    event.stopPropagation();
    const data: ComponentDialog = Builder.newComponentDialog(true);
    const ref = this.dialog.open(IniciarTurnoComponent, {
      width: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });
      ref.afterClosed().subscribe(idRegistroTurno => {
        sessionStorage.setItem('idRegistroTurno', idRegistroTurno);
      });
    // this.router.navigateByUrl('grua/pedents-table/');
    // event.stopPropagation();
  }

  finalizarTurno() {
  event.stopPropagation();
  const data: ComponentDialog = Builder.newComponentDialog(true);
  const ref = this.dialog.open(FinalizarTurnoComponent, {
    width: '40%',
    height: '95%',
    closeOnNavigation: false,
    autoFocus: true,
    data: data
  });
  }
  // this.router.navigateByUrl('grua/pedents-table/');
  // event.stopPropagation();

  ngAfterViewInit(): void {
    setTimeout(() => {
      const scrollInner = this.scrollInnerElement.nativeElement;
      this.renderer.listen(scrollInner, 'scroll', (event: Event) => {
        this.handleScrollEvent(event);
      });
    }, 300);
  }

  private handleScrollEvent(event: Event): void {
    clearTimeout(scrollTimer);
    const element = event.target as HTMLElement;
    this.renderer.addClass(element, 'scroll');
    scrollTimer = setTimeout(() => {
      this.renderer.removeClass(element, 'scroll');
    }, 500);
  }
}
