import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class AgentFilter extends FilterData {
  codi: string;
  categoria: string;
  nomComplert: string;
  dni: string;
  letraDni: string;
  obsolet: boolean;
  zonaBlava: boolean;
  agentPDA: boolean;
  fechaActualConsulta: Date;
  deFiesta: boolean;
  deAlta: boolean;

  constructor(sortField: string, sortDir: SortDirection, fechaConsulta: Date) {
    super(sortField, sortDir);
    this.codi = '';
    this.categoria = '';
    this.nomComplert = '';
    this.dni = '';
    this.letraDni = '';
    this.obsolet = null;
    this.zonaBlava = null;
    this.agentPDA = null;
    this.fechaActualConsulta = fechaConsulta;
    this.deFiesta = null;
    this.deAlta = null;

    this.labels['codi'] = this.i18n('Codi');
    this.labels['zonaBlava'] = this.i18n('Zona blava');
    this.labels['agentPDA'] = this.i18n('Agent PDA');
    this.labels['nomComplert'] = this.i18n('Nom');
    this.labels['letraDni'] = this.i18n('Lletra DNI');
    this.labels['categoria'] = this.i18n('Categoria');
    this.labels['dni'] = this.i18n('DNI');
    this.labels['obsolet'] = this.i18n('Obsolet');
    this.labels['deFiesta'] = this.i18n('De festa');
    this.labels['deAlta'] = this.i18n('D\'alta');
  }
}
