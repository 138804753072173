var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var PaisosFilter = /** @class */ (function (_super) {
    __extends(PaisosFilter, _super);
    function PaisosFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.codi = null;
        _this.descripcio = '';
        _this.paisActiu = null;
        _this.provCodificada = null;
        _this.sigles = '';
        _this.labels['codi'] = _this.i18n('Codi');
        _this.labels['descripcio'] = _this.i18n('Descripció');
        _this.labels['paisActiu'] = _this.i18n('Pais Actiu');
        _this.labels['provCodificada'] = _this.i18n('Provincia Codificada');
        _this.labels['sigles'] = _this.i18n('Sigles');
        return _this;
    }
    return PaisosFilter;
}(FilterData));
export { PaisosFilter };
