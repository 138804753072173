/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./public-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./public-header.component";
var styles_PublicHeaderComponent = [i0.styles];
var RenderType_PublicHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PublicHeaderComponent, data: {} });
export { RenderType_PublicHeaderComponent as RenderType_PublicHeaderComponent };
export function View_PublicHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "header", [["class", "app-header navbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "header-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["class", "navbar-brand"], ["href", "/"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "SomDenWeb"], ["class", "navbar-brand-full"], ["src", "assets/images/logo.png"], ["width", "140"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "SomDenWeb"], ["class", "navbar-brand-minimized"], ["src", "assets/images/logo.png"], ["width", "30"]], null, null, null, null, null))], null, null); }
export function View_PublicHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-public-header", [], null, null, null, View_PublicHeaderComponent_0, RenderType_PublicHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.PublicHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PublicHeaderComponentNgFactory = i1.ɵccf("app-public-header", i2.PublicHeaderComponent, View_PublicHeaderComponent_Host_0, {}, {}, []);
export { PublicHeaderComponentNgFactory as PublicHeaderComponentNgFactory };
