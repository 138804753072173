var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
var defaultSortParam = 'data';
var defaultSortDirection = 'asc';
var AvisosFilterService = /** @class */ (function (_super) {
    __extends(AvisosFilterService, _super);
    function AvisosFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AvisosFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        var dateMin = new Date(1900, 0, 0);
        var dateMax = new Date(2100, 0, 0);
        return new HttpParams()
            .append('id', filter.id === null ? '' : filter.id.toString())
            .append('idTipusAvis', filter.idTipusAvis === null ? '' : filter.idTipusAvis.toString())
            .append('DataInici', filter.fechaAvisoInicio === null ? dateMin.toDateString() : typeof (filter.fechaAvisoInicio) === 'string' ?
            filter.fechaAvisoInicio : filter.fechaAvisoInicio.toDateString())
            .append('DataFi', filter.fechaAvisoFin === null ? dateMax.toDateString() : typeof (filter.fechaAvisoFin) === 'string' ?
            filter.fechaAvisoFin : filter.fechaAvisoFin.toDateString())
            .append('Carpetes', filter.carpetes)
            .append('Agent', filter.agent)
            .append('PDA', filter.pda)
            .append('IdTipusAvisList', filter.idTipusAvisList)
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return AvisosFilterService;
}(CustomFilterService));
export { AvisosFilterService };
