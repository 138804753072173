/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-multi-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../dialog/dialog-header/dialog-header.component";
import * as i4 from "../form/form-multi-select/form-multi-select/form-multi-select.component.ngfactory";
import * as i5 from "../form/form-multi-select/form-multi-select/form-multi-select.component";
import * as i6 from "@ngx-translate/i18n-polyfill";
import * as i7 from "./modal-multi-select.component";
import * as i8 from "@angular/material/dialog";
var styles_ModalMultiSelectComponent = [i0.styles];
var RenderType_ModalMultiSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalMultiSelectComponent, data: {} });
export { RenderType_ModalMultiSelectComponent as RenderType_ModalMultiSelectComponent };
export function View_ModalMultiSelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-form-multi-select", [["maxItems", "100"], ["style", "margin:1vh"]], null, [[null, "selectedValuesChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedValuesChange" === en)) {
        var pd_0 = ((_co.selectedValues = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FormMultiSelectComponent_0, i4.RenderType_FormMultiSelectComponent)), i1.ɵdid(4, 114688, null, 0, i5.FormMultiSelectComponent, [i6.I18n], { tituloMultiSelect: [0, "tituloMultiSelect"], maxItems: [1, "maxItems"], data: [2, "data"], selectedValues: [3, "selectedValues"] }, { selectedValuesChange: "selectedValuesChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["style", "float: right;margin: 1vh;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Guardar"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.title, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.title, ""); var currVal_2 = "100"; var currVal_3 = _co.multiSelectData; var currVal_4 = _co.selectedValues; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ModalMultiSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-multi-select", [], null, null, null, View_ModalMultiSelectComponent_0, RenderType_ModalMultiSelectComponent)), i1.ɵdid(1, 114688, null, 0, i7.ModalMultiSelectComponent, [i8.MAT_DIALOG_DATA, i8.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalMultiSelectComponentNgFactory = i1.ɵccf("app-modal-multi-select", i7.ModalMultiSelectComponent, View_ModalMultiSelectComponent_Host_0, {}, {}, []);
export { ModalMultiSelectComponentNgFactory as ModalMultiSelectComponentNgFactory };
