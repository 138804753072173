import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { BonosFilter } from '../models/bonos-filter';
import { DatePipe } from '@angular/common';

const defaultSortParam = 'data';
const defaultSortDirection = 'desc';

@Injectable({
  providedIn: 'root'
})
export class BonosFilterService  extends CustomFilterService {
  public createFilterParams(filter: BonosFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    const datepipe: DatePipe = new DatePipe('es-ES');
    return new HttpParams()
    .append('matricula', filter.matricula)
    .append('fechaInicio', Reflection.empty(filter.fechaInicio) ? null : datepipe.transform(filter.fechaInicio, 'yyyyMMdd000000'))
    .append('fechaFin', Reflection.empty(filter.fechaFin) ? null : datepipe.transform(filter.fechaFin, 'yyyyMMdd235959'))
    .append('tipo', filter.tipo)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir)
    ;
  }

  public createLoadParams(content: string) {
    return new HttpParams()
    .append('content', content ? content : '')
    ;
  }


}
