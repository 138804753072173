import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DesplacatsGruaFilter } from 'app/sections/grua/Models/desplacats-filter';
import { DesplacatsTableComponent } from '../desplacats-table/desplacats-table.component';
import { DesplacatsFilterComponent } from '../desplacats-filter/desplacats-filter.component';


@Component({
  selector: 'app-desplacats',
  templateUrl: './desplacats.component.html',
  styleUrls: ['./desplacats.component.scss']
})
export class DesplacatsGruaComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selected = new FormControl(0);
  filter: DesplacatsGruaFilter = new DesplacatsGruaFilter('', '');
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild('desplacats') desplacatsTable: DesplacatsTableComponent;

  constructor( public authService: AuthorizationService,
    private not: NotificationService,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n) {
      this.filter = new DesplacatsGruaFilter('', '');
    }

  ngOnInit() {
    this.authService.CheckUserPermission(this.authService.Pages.DipositGrua);
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Desplaçats'));
    }
  }

  onFilterClicked() {
    this.dialog.open(DesplacatsFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.desplacatsTable.filter
      }
    });

  }

  refresh() {
    this.desplacatsTable.load();
  }
  generateExcel() {
    this.desplacatsTable.generateExcel();
  }

}
