/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i2 from "../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i3 from "../../../../shared/components/dialog/dialog-body/dialog-body.component.ngfactory";
import * as i4 from "../../../../shared/components/dialog/dialog-body/dialog-body.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i7 from "../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i8 from "../../../../shared/services/validation/validation.service";
import * as i9 from "../../../../shared/components/dialog/dialog-footer/dialog-footer.component.ngfactory";
import * as i10 from "../../../../shared/components/dialog/dialog-footer/dialog-footer.component";
import * as i11 from "./denuncia-pagament-parcial.component";
import * as i12 from "../../../../shared/services/authorization/authorization-service";
import * as i13 from "@angular/material/dialog";
import * as i14 from "@ngx-translate/i18n-polyfill";
var styles_DenunciaPagamentParcialComponent = [];
var RenderType_DenunciaPagamentParcialComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DenunciaPagamentParcialComponent, data: {} });
export { RenderType_DenunciaPagamentParcialComponent as RenderType_DenunciaPagamentParcialComponent };
export function View_DenunciaPagamentParcialComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["label", "Accions expedient"]], null, null, null, i1.View_DialogHeaderComponent_0, i1.RenderType_DialogHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.DialogHeaderComponent, [], { label: [0, "label"], isCloseButton: [1, "isCloseButton"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 12, "app-dialog-body", [], null, null, null, i3.View_DialogBodyComponent_0, i3.RenderType_DialogBodyComponent)), i0.ɵdid(3, 114688, null, 0, i4.DialogBodyComponent, [], null, null), (_l()(), i0.ɵeld(4, 0, [["mainForm", 1]], 0, 10, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i5.ɵangular_packages_forms_forms_bg, [], null, null), i0.ɵdid(6, 4210688, null, 0, i5.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i5.ControlContainer, null, [i5.NgForm]), i0.ɵdid(8, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, [" ", " "])), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "app-form-text-edit", [["class", "col-sm-12"], ["id", "denuncia-pagament-motiu"], ["label", "Motiu"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.motiu = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FormTextEditComponent_0, i6.RenderType_FormTextEditComponent)), i0.ɵdid(14, 4308992, null, 0, i7.FormTextEditComponent, [i8.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], id: [3, "id"] }, { valueChange: "valueChange" }), (_l()(), i0.ɵeld(15, 0, null, null, 5, "app-dialog-footer", [], null, null, null, i9.View_DialogFooterComponent_0, i9.RenderType_DialogFooterComponent)), i0.ɵdid(16, 114688, null, 0, i10.DialogFooterComponent, [], null, null), (_l()(), i0.ɵeld(17, 0, null, 0, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cobrarITancarExpedient() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(18, null, ["", ""])), (_l()(), i0.ɵeld(19, 0, null, 0, 1, "button", [["class", "btn btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cobrarIMantenirExpedientObert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(20, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Accions expedient"; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 3, 0); var currVal_10 = "Motiu"; var currVal_11 = _co.motiu; var currVal_12 = "col-sm-12"; var currVal_13 = "denuncia-pagament-motiu"; _ck(_v, 14, 0, currVal_10, currVal_11, currVal_12, currVal_13); _ck(_v, 16, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 8).ngClassValid; var currVal_7 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.message; _ck(_v, 11, 0, currVal_9); var currVal_14 = _co.cobrarTancarExpedient; _ck(_v, 18, 0, currVal_14); var currVal_15 = _co.cobrarMantenirExpedient; _ck(_v, 20, 0, currVal_15); }); }
export function View_DenunciaPagamentParcialComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-denuncia-pagament-parcial", [], null, null, null, View_DenunciaPagamentParcialComponent_0, RenderType_DenunciaPagamentParcialComponent)), i0.ɵdid(1, 114688, null, 0, i11.DenunciaPagamentParcialComponent, [i12.AuthorizationService, i13.MatDialogRef, i14.I18n, i13.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DenunciaPagamentParcialComponentNgFactory = i0.ɵccf("app-denuncia-pagament-parcial", i11.DenunciaPagamentParcialComponent, View_DenunciaPagamentParcialComponent_Host_0, {}, {}, []);
export { DenunciaPagamentParcialComponentNgFactory as DenunciaPagamentParcialComponentNgFactory };
