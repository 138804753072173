/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./actuacions-new.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i4 from "../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i5 from "../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i6 from "../../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i7 from "../../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i8 from "../../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i9 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i10 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i11 from "../../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i12 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i13 from "../../../../../shared/components/form/form-text-area/form-text-area.component.ngfactory";
import * as i14 from "../../../../../shared/components/form/form-text-area/form-text-area.component";
import * as i15 from "../../../../../shared/services/validation/validation.service";
import * as i16 from "./actuacions-new.component";
import * as i17 from "@ngx-translate/i18n-polyfill";
import * as i18 from "../../services/maquinas.service";
import * as i19 from "@angular/material/dialog";
import * as i20 from "ngx-toastr";
var styles_ActuacionsNewComponent = [i0.styles];
var RenderType_ActuacionsNewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActuacionsNewComponent, data: {} });
export { RenderType_ActuacionsNewComponent as RenderType_ActuacionsNewComponent };
export function View_ActuacionsNewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["iconClass", "has-color-orange"]], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"], iconClass: [1, "iconClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "app-toolbar", [], null, null, null, i4.View_ToolbarComponent_0, i4.RenderType_ToolbarComponent)), i1.ɵdid(3, 114688, null, 0, i5.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "app-tool-button", [["color", "has-color-green"], ["icon", "fa-save"], ["text", "Afegir"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.guardar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ToolButtonComponent_0, i6.RenderType_ToolButtonComponent)), i1.ɵdid(5, 114688, null, 0, i7.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 3, "app-form-date-edit", [["class", "col form-text-area"], ["label", "Data inici"], ["required", ""]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.dataInici = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormDateEditComponent_0, i8.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i9.DateTimeAdapter, i10.MomentDateTimeAdapter, [i9.OWL_DATE_TIME_LOCALE]), i1.ɵdid(9, 114688, null, 0, i11.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"], required: [3, "required"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i12.OWL_DATE_TIME_FORMATS, i11.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(11, 16777216, null, null, 3, "app-form-date-edit", [["class", "col form-text-area"], ["label", "Data fi"], ["required", ""]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.dataFi = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormDateEditComponent_0, i8.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i9.DateTimeAdapter, i10.MomentDateTimeAdapter, [i9.OWL_DATE_TIME_LOCALE]), i1.ɵdid(13, 114688, null, 0, i11.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"], required: [3, "required"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i12.OWL_DATE_TIME_FORMATS, i11.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-form-text-area", [["class", "col form-text-area"], ["draggable", "false"], ["label", "Observacions"], ["maxLength", "5000"], ["required", ""], ["resize", "true"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.observacions = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_FormTextAreaComponent_0, i13.RenderType_FormTextAreaComponent)), i1.ɵdid(16, 4308992, null, 0, i14.FormTextAreaComponent, [i15.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], required: [3, "required"], maxLength: [4, "maxLength"], resize: [5, "resize"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_co.i18n("Nova actuaci\u00F3 per a la incid\u00E8ncia") + "\u00A0") + _co.data.somId), ""); var currVal_1 = "has-color-orange"; _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 3, 0); var currVal_2 = "Afegir"; var currVal_3 = "has-color-green"; var currVal_4 = "fa-save"; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = "Data inici"; var currVal_6 = _co.dataInici; var currVal_7 = "col form-text-area"; var currVal_8 = ""; _ck(_v, 9, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = "Data fi"; var currVal_10 = _co.dataFi; var currVal_11 = "col form-text-area"; var currVal_12 = ""; _ck(_v, 13, 0, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = "Observacions"; var currVal_14 = _co.observacions; var currVal_15 = "col form-text-area"; var currVal_16 = ""; var currVal_17 = "5000"; var currVal_18 = "true"; _ck(_v, 16, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); }, null); }
export function View_ActuacionsNewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-actuacions-new", [], null, null, null, View_ActuacionsNewComponent_0, RenderType_ActuacionsNewComponent)), i1.ɵdid(1, 114688, null, 0, i16.ActuacionsNewComponent, [i17.I18n, i18.MaquinasService, i19.MatDialogRef, i19.MAT_DIALOG_DATA, i20.ToastrService, i19.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActuacionsNewComponentNgFactory = i1.ɵccf("app-actuacions-new", i16.ActuacionsNewComponent, View_ActuacionsNewComponent_Host_0, {}, {}, []);
export { ActuacionsNewComponentNgFactory as ActuacionsNewComponentNgFactory };
