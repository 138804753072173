import * as i0 from "@angular/core";
var FormService = /** @class */ (function () {
    function FormService() {
    }
    FormService.prototype.allFieldsValid = function (form) {
        return form.nativeElement.getElementsByClassName('ng-invalid').length === 0;
    };
    FormService.ngInjectableDef = i0.defineInjectable({ factory: function FormService_Factory() { return new FormService(); }, token: FormService, providedIn: "root" });
    return FormService;
}());
export { FormService };
