import { Injectable } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { ZbHorario } from '../models/zb-horarios';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZbHorariosService {
  
  constructor(
    private http: HttpSecureClient,
    private authGuard:AuthGuardService) { }

  setZbHorario(zbHorario: ZbHorario): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetZbHorario'],zbHorario);
    }
  }
  removeZbHorario(name:string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlDeleteZbHorario'],name);
    }
  }

  getAllZbHorarios(): Observable<any> {
    if(this.authGuard.canActivate()){
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllZbHorarios'],null);
    }
  }

}
