import { OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FilterTypes } from 'path-shared/models/filter-types';
import { Reflection } from 'path-shared/services/reflection/reflection';
var FilterTypeComponent = /** @class */ (function () {
    function FilterTypeComponent(cdRef) {
        this.cdRef = cdRef;
        this.replacableItems = ['=', '%', '!=', '!%', '>=', '>', '<=', '<'];
        this.Types = FilterTypes;
        this.operator = '=';
        this.inputChange = new EventEmitter();
        this.valueChange = new EventEmitter();
        this.operator = '=';
    }
    Object.defineProperty(FilterTypeComponent.prototype, "input", {
        get: function () { return this.in; },
        set: function (val) {
            if (this.in !== val) {
                if (val === undefined || val === null || val === '') {
                    this.in = '';
                    this.value = '';
                }
                else {
                    this.in = val;
                    this.value = this.operator + val;
                }
                this.inputChange.next(this.in);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterTypeComponent.prototype, "value", {
        get: function () { return this.val; },
        set: function (val) {
            var _this = this;
            if (this.val !== val) {
                setTimeout(function () {
                    if (val !== null && val !== undefined) {
                        _this.val = val;
                    }
                    else {
                        _this.val = '';
                    }
                    _this.operator = _this.operatorFromString(_this.val);
                    _this.input = _this.fromString(_this.val);
                    _this.valueChange.next(_this.val);
                }, 20);
            }
        },
        enumerable: true,
        configurable: true
    });
    FilterTypeComponent.prototype.ngOnInit = function () {
        var _this = this;
        //
        if (this.isNumber != null && this.isNumber) {
            this.type = FilterTypes.NUMERO;
            this.defaultComparsion = '>=';
        }
        else {
            this.type = FilterTypes.TEXT;
        }
        setTimeout(function () {
            if (_this.defaultComparsion !== _this.operator && Reflection.empty(_this.value)) {
                _this.operator = _this.defaultComparsion;
            }
        }, 100);
    };
    FilterTypeComponent.prototype.selectedChange = function () {
        this.value = this.operator + this.input;
    };
    FilterTypeComponent.prototype.setValue = function (val) {
        this.operator = val;
        this.selectedChange();
    };
    FilterTypeComponent.prototype.fromString = function (val) {
        if (val !== null && val !== undefined && val !== '') {
            var filter = this.getFilter(val);
            if (filter.length === 0) {
                return val;
            }
            else {
                return val.substr(filter.length);
            }
        }
        else {
            return val;
        }
    };
    FilterTypeComponent.prototype.operatorFromString = function (val) {
        if (val !== null && val !== undefined && val !== '') {
            var filter = this.getFilter(val);
            if (filter.length === 0) {
                return this.operator;
            }
            else {
                return filter;
            }
        }
        else {
            return this.operator;
        }
    };
    FilterTypeComponent.prototype.hasFilter = function (val) {
        return this.getFilter(val).length > 0;
    };
    FilterTypeComponent.prototype.getFilter = function (val) {
        var n = 0;
        var found = false;
        while (n < this.replacableItems.length && !found) {
            found = val.startsWith(this.replacableItems[n]);
            if (!found) {
                n++;
            }
        }
        if (found) {
            return this.replacableItems[n];
        }
        else {
            return '';
        }
    };
    return FilterTypeComponent;
}());
export { FilterTypeComponent };
