var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DataSource } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs-compat';
import { of } from 'rxjs/observable/of';
import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { EventEmitter } from '@angular/core';
var defaultSortParam = 'Exp';
var defaultSortDirection = 'desc';
/**
 * Data source for the PropostesTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
var PropostesDataSource = /** @class */ (function (_super) {
    __extends(PropostesDataSource, _super);
    function PropostesDataSource(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.propostesSubject = new BehaviorSubject([]);
        _this.loadingSubject = new BehaviorSubject(false);
        _this.loading$ = _this.loadingSubject.asObservable();
        _this.countChange = new EventEmitter();
        _this.loading = false;
        _this.count = 0;
        return _this;
    }
    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    PropostesDataSource.prototype.connect = function () {
        return this.propostesSubject.asObservable();
        // return this.getPagedData(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
    };
    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    PropostesDataSource.prototype.disconnect = function () {
        this.propostesSubject.complete();
        this.loadingSubject.complete();
    };
    /**
     * Paginate the data (client-side). If you're using server-side pagination,
     * this would be replaced by requesting the appropriate data from the server.
     */
    PropostesDataSource.prototype.loadPropostes = function (filter, pageIndex, pageSize, sortField, sortDir) {
        var _this = this;
        if (!this.loading) {
            this.loading = true;
            // loading spinner
            this.loadingSubject.next(true);
            var start = pageIndex * pageSize;
            var params = new HttpParams()
                .append('Proposta', filter.proposta)
                .append('Pais', filter.pais ? filter.pais : '')
                .append('TipusProposta', filter.tipusproposta);
            this.http.get(config.urls['apiUrl'] + api.endpoints['urlPropostesCount'], params).subscribe(function (x) {
                _this.count = x;
                _this.countChange.emit(x.toString());
            });
            params = params.append('start', start.toString())
                .append('size', pageSize.toString())
                .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
                .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
            this.http.get(config.urls['apiUrl'] + api.endpoints['urlpropostesquery'], params).pipe(catchError(function () { return of([]); }), finalize(function () {
                _this.loadingSubject.next(false);
                _this.loading = false;
            })).subscribe(function (items) {
                _this.propostesSubject.next(items);
            });
            this.loading = false;
        }
    };
    PropostesDataSource.prototype.loadTipus = function () {
    };
    PropostesDataSource.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };
    return PropostesDataSource;
}(DataSource));
export { PropostesDataSource };
