<app-toolbar>
  <app-tool-button *ngIf="action !== 'Delete'" text="Guardar" i18n-text (click)="doAction()" color="has-color-green" icon="fa-save" ></app-tool-button>
  <app-tool-button *ngIf="action === 'Delete'" text="Eliminar" i18n-text (click)="doAction();" color="has-color-red" icon="fa-trash-alt" ></app-tool-button>
  <app-tool-button  text="Cancelar" i18n-text (click)="closeDialog()" color="has-color-red" icon="fa fa-times "></app-tool-button>
</app-toolbar>
<div mat-dialog-content >
  <!-- <mat-form-field *ngIf="action != 'Delete'; "> -->
    <div class="row"  *ngIf="action !== 'Delete'">
    <!--<input placeholder="Nom" matInput [(ngModel)]="local_data.nombre">-->
    <app-form-text-edit id="models-vehicles-nom" i18n-label class="col-xl-6" label="Nom" [(value)]="this.local_data.nombre"  maxLenght="150"  required></app-form-text-edit>
      <!--<input placeholder="Nom" matInput [(ngModel)]="local_data.nombre">-->
      <app-form-text-edit id="models-vehicles-REFiNT" i18n-label class="col-xl-6" label="Referencia Integració" [(value)]="this.local_data.referenciaIntegracion"  maxLenght="50" ></app-form-text-edit>
    </div>
  
    <!-- </mat-form-field> -->
 <!-- <mat-form-field *ngIf="action != 'Delete'; else elseTemplate">
    <div class="row">
      <input placeholder="Referencia Integració" matInput [(ngModel)]="local_data.referenciaIntegracion">
    </div>
  </mat-form-field>-->
  <!-- <ng-template *ngIf="action === 'Delete'"> -->
    <div *ngIf="action === 'Delete'" style="justify-content: center;">

        Estàs segur que vols borrar el registre <b>{{local_data.nombre}}</b>?
      <br/>
      <!-- <app-tool-button  text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" ></app-tool-button> -->

    </div>
  <!-- </ng-template> -->
</div>
<!--<div mat-dialog-actions>
  <button mat-button (click)="doAction()">{{action}}</button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancelar</button>
</div>-->