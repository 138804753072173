import { config } from 'path-shared/configuration/config';
import { TextoAviso } from '../model/texto-aviso';
import { Injectable } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { api } from 'path-shared/configuration/urls';
import { Observable } from 'rxjs';


@Injectable()
export class TextoAvisoService {
    displayedItems = [];

    constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
        this.displayedItems['checkedValues'] = 0;
        this.displayedItems['checkedItems'] = Array();
    }

    setTextoAviso(nuevoTexto: TextoAviso): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTextoAviso'], nuevoTexto);
        }
    }

}
