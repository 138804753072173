import { SortDirection } from '@angular/material';
import { FilterData } from 'path-shared/models/filter-data';

export class SenalesVerticalesFilter extends FilterData {
    id: string;
    nombreSenal: string;
    tipoFk: string;

    tipoNombre: string;

    constructor(sortField: string, sortDir: SortDirection) {
        super(sortField, sortDir);
        this.id = '';
        this.nombreSenal = '';
        this.tipoFk = '';

        this.labels['id'] = this.i18n('Id');
        this.labels['nombreSenal'] = this.i18n('Nom');
        this.labels['tipoNombre'] = this.i18n('Tipus');
        this.labels['tipoFk'] = this.i18n('TipusFK');
    }
}
