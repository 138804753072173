/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../matricules/pages/matricules/matricules.component.ngfactory";
import * as i2 from "../matricules/pages/matricules/matricules.component";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../../shared/services/authorization/authorization-service";
import * as i5 from "@angular/router";
import * as i6 from "../matricules/services/matricules.service";
import * as i7 from "@ngx-translate/i18n-polyfill";
import * as i8 from "ngx-toastr";
import * as i9 from "../tipus-matricules/services/tipus-matricules.service";
import * as i10 from "../../configuracio/marques-vehicles/services/marques-vehicles-service.service";
import * as i11 from "../../../shared/services/notification/notification.service";
import * as i12 from "./ciutat-page.component";
var styles_CiutatPageComponent = [];
var RenderType_CiutatPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CiutatPageComponent, data: {} });
export { RenderType_CiutatPageComponent as RenderType_CiutatPageComponent };
export function View_CiutatPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-matricules", [], null, null, null, i1.View_MatriculesComponent_0, i1.RenderType_MatriculesComponent)), i0.ɵdid(1, 4308992, null, 0, i2.MatriculesComponent, [i3.MatDialog, i4.AuthorizationService, i5.Router, i6.MatriculesService, i7.I18n, i8.ToastrService, i9.TipusMatriculesService, i10.MarquesVehiclesService, i11.NotificationService, i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_CiutatPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ciutat-page", [], null, null, null, View_CiutatPageComponent_0, RenderType_CiutatPageComponent)), i0.ɵdid(1, 114688, null, 0, i12.CiutatPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CiutatPageComponentNgFactory = i0.ɵccf("app-ciutat-page", i12.CiutatPageComponent, View_CiutatPageComponent_Host_0, {}, {}, []);
export { CiutatPageComponentNgFactory as CiutatPageComponentNgFactory };
