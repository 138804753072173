import { EventEmitter } from '@angular/core';

export class CustomButtonInfo {
    buttonName: string;
    event: EventEmitter<any>;
    color: string;
    icon: string;
    disabled: boolean;

    constructor(buttonName: string, color: string, icon: string, disabled: boolean, event: EventEmitter<any>) {
        this.buttonName = buttonName;
        this.color = color;
        this.icon = icon;
        this.disabled = disabled;
        this.event = event;
    }
}
