import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';


export class DipositGruaFilter extends FilterData {
    dataEntradaInici: Date;
    dataEntradaFinal: Date;
    dataSortidaInici: Date;
    dataSortidaFinal: Date;
    idTipusVehicleFK: string;
    idMotiuEntradaFK: string;
    idTecnicFK: string;
    matricula: string;
    diposits: Array<FormMultiSelect>;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.dataEntradaInici = null;
    this.dataEntradaFinal = null;
    this.dataSortidaInici = null;
    this.dataSortidaFinal = null;
    this.idTipusVehicleFK = '';
    this.idMotiuEntradaFK = '';
    this.idTecnicFK = '';
    this.matricula = '';
    this.diposits = null;

    this.labels['dataEntradaInici'] = this.i18n('Data entrada inici');
    this.labels['dataEntradaFinal'] = this.i18n('Data entrada final');
    this.labels['dataSortidaInici'] = this.i18n('Data sortida inici');
    this.labels['dataSortidaFinal'] = this.i18n('Data sortida final');
    this.labels['idTipusVehicleFK'] = this.i18n('Tipus vehicle');
    this.labels['idMotiuEntradaFK'] = this.i18n('Motiu entrada');
    this.labels['idTecnicFK'] = this.i18n('Tècnic');
    this.labels['matricula'] = this.i18n('Matricula');
    this.labels['diposits'] = this.i18n('Dipòsits');


    this.formatOutput['dataEntradaInici'] = 'shortDate';
    this.formatOutput['dataEntradaFinal'] = 'shortDate';
    this.formatOutput['dataSortidaInici'] = 'shortDate';
    this.formatOutput['dataSortidaFinal'] = 'shortDate';
    this.formatOutput['diposits'] = 'multiSelect';

  }

}
