import { config } from 'path-shared/configuration/config';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { api } from 'path-shared/configuration/urls';
import { HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../../../shared/services/authentication/auth-guard.service";
var HeatMapService = /** @class */ (function () {
    function HeatMapService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
    }
    HeatMapService.prototype.getHeatMapData = function (filtroTickets) {
        return this.http.getWithType(config.urls['apiUrl'] + api.endpoints['urlHeatMap'], new HttpParams()
            .append('Dia', filtroTickets.Dia.toJSON())
            .append('HoraIni', filtroTickets.HoraIni.toString())
            .append('HoraFin', filtroTickets.HoraFin.toString()));
    };
    HeatMapService.ngInjectableDef = i0.defineInjectable({ factory: function HeatMapService_Factory() { return new HeatMapService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: HeatMapService, providedIn: "root" });
    return HeatMapService;
}());
export { HeatMapService };
