import { SortDirection } from '@angular/material';
import { FilterData } from 'path-shared/models/filter-data';


export class TextoInformativoFilter extends FilterData {
    descripcion: string;
    fechaInicio: Date;
    fechaFin: Date;
    idioma: string;

    constructor(sortField: string, sortDir: SortDirection) {
        super(sortField, sortDir);
        this.descripcion = '';
        this.fechaInicio = null;
        this.fechaFin = null;
        this.idioma = '';

        this.labels['descripcion'] = this.i18n('Descripció');
        this.labels['fechaInicio'] = this.i18n('Data Inici');
        this.labels['fechaFin'] = this.i18n('Data Fi');
        this.labels['idioma'] = this.i18n('Idioma');
    }
}
