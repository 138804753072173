<div class="row justify-content-between">
  <div class="col">
    <app-filter-selector [filter]="this.filter" exclude="['estat']" [hidden]="!this.userReincidentsDataSource"></app-filter-selector>
  </div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" matSortActive="Data" matSortDirection="asc" class="is-header-sticky">

    <!-- Check Column -->
    <ng-container matColumnDef="Check">
      <mat-header-cell *matHeaderCellDef class="is-checkbox">
          <mat-checkbox (change)="toggleAllCheckbox()"></mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
         <mat-checkbox *ngIf="this.propostesService.displayedItems[row.SECURE_ID]!==undefined" (change)="toggleItem(row.SECURE_ID)" [checked]="this.propostesService.displayedItems[row.SECURE_ID].checked"></mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Estat">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Estat</mat-header-cell>
      <mat-cell *matCellDef="let row"><app-propostes-table-estat [proposta]="row.proposta" [tipus]="row.TIPO_PROPUESTA"></app-propostes-table-estat></mat-cell>
    </ng-container>

    <ng-container matColumnDef="NUMDENUNCIA">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Número</mat-header-cell>
      <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link">{{row.NUMDENUNCIA}}</a></mat-cell>
    </ng-container>

    <ng-container matColumnDef="MATRICULA">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matricula</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.MATRICULA}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Data">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.DATA_HORA | longDate}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="imatges">
        <mat-header-cell *matHeaderCellDef i18n>Imatges</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.NUMERO_IMATGES}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Pais">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>País</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.pais}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="LLOC">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Lloc</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.LLOC}}&nbsp;{{row.NUMERO}}</mat-cell>
          </ng-container>

              <ng-container matColumnDef="INFRACCIO">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Infraccio</mat-header-cell>
        <mat-cell *matCellDef="let row"><span i18n>Art.</span>&nbsp;{{row.ARTICLE}}&nbsp;{{row.INFRACCIO}}</mat-cell>
      </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>
