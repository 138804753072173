<app-toolbar>
  <app-tool-button text=Processador i18n-text (click)="this.goProcessador()" color="has-color-green" icon="fa-plus-square"></app-tool-button>
</app-toolbar>


<div class="row">
  <!-- #region TABLA PRINCIPAL -->

  <div class="col-sm-6 ">
    <div class="row" class="text-center"><h4 i18n>Històric</h4></div>
    <div class="d-flex justify-content-between">
      <div class="flex-grow-1"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
      <div class="">
        <mat-paginator #paginator
          [pageIndex]="0"
          [pageSize]="this.filter.paginator.pageSize"
          [pageSizeOptions]="[20, 40, 60, 100]">
        </mat-paginator>
      </div>
    </div>
    <div class="row" style="margin-left: 4px;">
      <div class="table-sdw is-fixed">
        <mat-table #table="matSort" [dataSource]="dataSource" matSort aria-label="Elements" matSortActive="Som_Id" matSortDirection="asc" class="is_header_sticky">
          <ng-container matColumnDef="Fecha">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.Fecha }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Operacion">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Operació</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.operacio }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="FechaInicio">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data inicial</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.FechaInicio }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="FechaFin">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data final</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.FechaFin }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Usuario">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Usuari</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.Usuario}}</mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let i = index; let row; columns: displayedColumns;" class="has-iconset" (click)="this.loadDetallPage(row.Som_Id)"
            [ngClass]="this.selectedItem === row.Som_Id ? 'is-active' : ''"></mat-row>
        </mat-table>
        </div>
    </div>
  </div>

<!-- #endregion -->

<!-- #region TABLA DETALLE HISTORICO -->

<div class="col-sm-6">
  <div class="row" class="text-center"><h4 i18n>Detall</h4></div>
  <div class="row">
    <div class="d-flex justify-content-between">
      <div class="flex-grow-1">&nbsp;</div>
      <div class="">
        <mat-paginator #paginatorDetail
          [pageIndex]="0"
          [pageSize]="this.filter.paginator.pageSize"
          [pageSizeOptions]="[20, 40, 100]">
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="row">
      <div class="table-sdw is-fixed">
      <mat-table #tableDetail="matSort" [dataSource]="this.detailedDataSource" matSort aria-label="Elements" matSortActive="Parquimetre" matSortDirection="asc" class="is_header_sticky" style="margin-left: 10px; overflow-y: scroll">
        <ng-container matColumnDef="Parquimetre">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Parquímetre</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.parquimetre}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Anul">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Anul·lació</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.anulacio}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="CodAnul">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi anul·lació</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.codAnulacio}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Msg">
          <mat-header-cell *matHeaderCellDef i18n>Msg</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.msg}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="DataCons">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data cons.</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.dataCons}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Data">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.data}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Hora">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.hora}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Import">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Import</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.import}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Processada">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Processada</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox [checked]="row.processada" [disabled]='true'></mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ExpedientAnul">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expedient Anul·lació</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.expedientAnul}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsDetail"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsDetail;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
      </mat-table>
    </div>
  </div>
</div>

<!-- #endregion -->

</div>
