
<h4 *ngIf="this.dialogData !== undefined && this.dialogData !== null && this.dialogData.title !== undefined && this.dialogData.title !== null && this.dialogData.title !== ''">
{{this.dialogData.title}}  
</h4>
<app-combo-box-input-autocomplete #comboTurno class="center-horizontal col-sm-2" i18n-label label="Torn" [options]="this.turnosDictionary"
(valorSeleccionado)="this.turnoSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteTurno"  required="true" >
</app-combo-box-input-autocomplete>

<div  class="text-center">
    <button 
      class="btn btn-success button-not-allowed btn-lg" type="button" (click)="this.iniciarTurno()"
      [disabled]="this.registroTurno.ID_TURNO === undefined || this.registroTurno.ID_TURNO === null ">           
      <i class="fas fa-hourglass-start"></i> &nbsp;<span i18n>Iniciar torn</span>  &nbsp;
    </button>
  </div> 