import { EventEmitter, OnInit } from '@angular/core';
var BOEGeneralComponent = /** @class */ (function () {
    function BOEGeneralComponent() {
        this.valueChange = new EventEmitter();
    }
    Object.defineProperty(BOEGeneralComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            var _this = this;
            this.val = val;
            setTimeout(function () {
                _this.valueChange.emit(_this.val);
            }, 10);
        },
        enumerable: true,
        configurable: true
    });
    BOEGeneralComponent.prototype.ngOnInit = function () {
    };
    BOEGeneralComponent.prototype.changeTipoExportacion = function (tipo) {
        this.value.tipoExportacion = tipo;
    };
    return BOEGeneralComponent;
}());
export { BOEGeneralComponent };
