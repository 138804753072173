<app-filter-header title="Filtrar" i18n-title (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-form-text-edit id="incidencia-filter-codigo" class="col-sm-3" i18n-label label="Codi" [(value)]="filter.somId" ></app-form-text-edit>
    <app-form-text-edit id="incidencia-filter-codigo-maquina" class="col-sm-3" i18n-label label="Codi màquina" [(value)]="filter.codiMaquina" ></app-form-text-edit>
  </div>
  <div class="row">
    <app-form-date-edit id="incidencia-filter-dataCreacioIni" class="col-sm-3" i18n-label label="Data Creació Inici" [(value)]="filter.dataCreacioInici" type="calendar"></app-form-date-edit>
    <app-form-date-edit id="incidencia-filter-dataCreacioFi" class="col-sm-3" i18n-label label="Data Creació Fi"  [(value)]="filter.dataCreacioFi" type="calendar"></app-form-date-edit>
    <app-form-date-edit id="incidencia-filter-dataResolucioIni" class="col-sm-3" i18n-label label="Data Resolució Inici" [(value)]="filter.dataResolucioInici" type="calendar"></app-form-date-edit>
    <app-form-date-edit id="incidencia-filter-dataResolucioFi" class="col-sm-3" i18n-label label="Data Resolució Fi"  [(value)]="filter.dataResolucioFi" type="calendar"></app-form-date-edit>
  </div>
</div>