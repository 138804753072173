/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav-dropdown-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./nav-dropdown-item.component";
var styles_NavDropdownItemComponent = [i0.styles];
var RenderType_NavDropdownItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavDropdownItemComponent, data: {} });
export { RenderType_NavDropdownItemComponent as RenderType_NavDropdownItemComponent };
function View_NavDropdownItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "nav-icon navbar-brand-full"], ["height", "14,55px"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; _ck(_v, 0, 0, currVal_0); }); }
function View_NavDropdownItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "nav-icon ", _co.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_NavDropdownItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { dd: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["dd", 1]], null, 8, "li", [["class", "nav-item nav-dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "a", [["class", "nav-link nav-dropdown-toggle"], ["id", "nav-link-space"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavDropdownItemComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavDropdownItemComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(7, null, ["\u00A0", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "ul", [["class", "nav-dropdown-items"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.src !== undefined); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.src === undefined); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.text; _ck(_v, 7, 0, currVal_2); }); }
export function View_NavDropdownItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav-dropdown-item", [], null, null, null, View_NavDropdownItemComponent_0, RenderType_NavDropdownItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavDropdownItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavDropdownItemComponentNgFactory = i1.ɵccf("app-nav-dropdown-item", i3.NavDropdownItemComponent, View_NavDropdownItemComponent_Host_0, { text: "text", icon: "icon", src: "src" }, {}, ["*"]);
export { NavDropdownItemComponentNgFactory as NavDropdownItemComponentNgFactory };
