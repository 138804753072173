import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../../../../../shared/services/authentication/auth-guard.service";
var ZbHorariosService = /** @class */ (function () {
    function ZbHorariosService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
    }
    ZbHorariosService.prototype.setZbHorario = function (zbHorario) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetZbHorario'], zbHorario);
        }
    };
    ZbHorariosService.prototype.removeZbHorario = function (name) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlDeleteZbHorario'], name);
        }
    };
    ZbHorariosService.prototype.getAllZbHorarios = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllZbHorarios'], null);
        }
    };
    ZbHorariosService.ngInjectableDef = i0.defineInjectable({ factory: function ZbHorariosService_Factory() { return new ZbHorariosService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: ZbHorariosService, providedIn: "root" });
    return ZbHorariosService;
}());
export { ZbHorariosService };
