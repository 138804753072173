import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class RecaudacioFilter extends FilterData {
  id: string;
  numColecta: string;
  fechaInicio: Date;
  fechaFin: Date;
  zonaFk: number;
  zonaDesc: string;
  maquinaParkeonFk: number;
  maquinaCodi: string;

  importeMonedaD: string;
  importeMonedaComercial: string;
  importeMonedaLegal: string;

  importeTarjetaPayone: string;
  importeTarjetaPayonePlus: string;
  importeTarjetaTotal: string;

  numeroTicketsTarjetaPayone: number;
  importeAnulacionTarjetaPayOne: number;
  numeroTicketsTarjetaPayonePlus: number;
  importeAnulacionTarjetaPayOnePlus: number;

  importeRecargaTarjetaPayone: string;
  importeRecargaTarjetaPayonePlus: string;
  importeRecargaTarjetaTotal: string;

  anulacionMoneda: string;
  anulacionImporteTotal: string;

  importeRealAparcamiento: string;

  numeroTicketsMoneda: string;
  numeroTicketsTotal: string;
  numeroTicketsMonedaComercial: string;

  importeRecuento: string;
  diferenciaRecuentoTicket: string;

  acumuladoMoneda: string;
  acumuladoTarjetaPayone: string;
  acumuladoTarjetaPayonePlus: string;
  acumuladoTarjetaTotal: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.id = null;
    this.numColecta = null;
    this.fechaInicio = null;
    this.fechaFin = null;
    this.maquinaParkeonFk = null;
    this.maquinaCodi = '';
    this.zonaFk = null;
    this.zonaDesc = '';

    this.importeMonedaD = null;
    this.importeMonedaComercial = null;
    this.importeMonedaLegal = null;

    this.importeTarjetaPayone = null;
    this.importeTarjetaPayonePlus = null;
    this.importeTarjetaTotal = null;

    this.numeroTicketsTarjetaPayone = null;
    this.importeAnulacionTarjetaPayOne = null;
    this.numeroTicketsTarjetaPayonePlus = null;
    this.importeAnulacionTarjetaPayOnePlus = null;

    this.importeRecargaTarjetaPayone = null;
    this.importeRecargaTarjetaPayonePlus = null;
    this.importeRecargaTarjetaTotal = null;

    this.anulacionMoneda = null;
    this.anulacionImporteTotal = null;
    this.importeRealAparcamiento = null;

    this.numeroTicketsMoneda = null;
    this.numeroTicketsTotal = null;
    this.numeroTicketsMonedaComercial = null;

    this.importeRecuento = null;
    this.diferenciaRecuentoTicket = null;

    this.acumuladoMoneda = null;
    this.acumuladoTarjetaPayone = null;
    this.acumuladoTarjetaPayonePlus = null;
    this.acumuladoTarjetaTotal = null;

    this.labels['id'] = this.i18n('Id');
    this.labels['numColecta'] = this.i18n('Núm.Colecta');
    this.labels['fechaInicio'] = this.i18n('Desde');
    this.labels['fechaFin'] = this.i18n('Fins');
    this.labels['zonaDesc'] = this.i18n('Zona');
    this.labels['maquinaCodi'] = this.i18n('Expenedor');

    this.labels['importeMonedaD'] = this.i18n('Import moneda D');
    this.labels['importeMonedaComercial'] = this.i18n('Import moneda comercial');
    this.labels['importeMonedaLegal'] = this.i18n('Import moneda legal');

    this.labels['importeTarjetaPayone'] = this.i18n('Import targeta PayOne');
    this.labels['importeTarjetaPayonePlus'] = this.i18n('Import targeta PayOnePlus');
    this.labels['importeTarjetaTotal'] = this.i18n('Import total targeta');

    this.labels['numeroTicketsTarjetaPayone'] = this.i18n('Nº Tiquets targeta PayOne');
    this.labels['importeAnulacionTarjetaPayOne'] = this.i18n('Import anul·lació targeta PayOne');
    this.labels['numeroTicketsTarjetaPayonePlus'] = this.i18n('Nº Tiquets targeta PayOnePlus');
    this.labels['importeAnulacionTarjetaPayOnePlus'] = this.i18n('Import anul·lació targeta PayOnePlus');

    this.labels['importeRecargaTarjetaPayone'] = this.i18n('Import recàrrega Payone');
    this.labels['importeRecargaTarjetaPayonePlus'] = this.i18n('Import recàrrega Payone Plus');
    this.labels['importeRecargaTarjetaTotal'] = this.i18n('Import total recàrrega targeta');

    this.labels['anulacionMoneda'] = this.i18n('Anul·lació moneda');
    this.labels['anulacionImporteTotal'] = this.i18n('Import total anul·lacions');
    this.labels['importeRealAparcamiento'] = this.i18n('Import real aparcament');

    this.labels['numeroTicketsMoneda'] = this.i18n('Nº Tiquets moneda');
    this.labels['numeroTicketsTotal'] = this.i18n('Nº Total tiquets');

    this.labels['numeroTicketsMonedaComercial'] = this.i18n('Nº Moneda comercial');
    this.labels['importeRecuento'] = this.i18n('Import recompte');
    this.labels['diferenciaRecuentoTicket'] = this.i18n('Diferència recompte/tiquet');

    this.labels['acumuladoMoneda'] = this.i18n('Acumulat moneda');
    this.labels['acumuladoTarjetaPayone'] = this.i18n('Acumulat targeta PayOne');
    this.labels['acumuladoTarjetaPayonePlus'] = this.i18n('Acumulat targeta PayOnePlus');
    this.labels['acumuladoTarjetaTotal'] = this.i18n('Total acumulat targeta');
  }
}
