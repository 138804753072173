import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class BancCarreguesC60Filter extends FilterData {
  id: number;
  codiCarrega: string;
  descripcioCarrega: string;
  nomFitxer: string;
  directoriFitxer: string;
  situacio: string;
  situacioClass: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.id = null;
    this.codiCarrega = '';
    this.descripcioCarrega = '';
    this.nomFitxer = '';
    this.directoriFitxer = '';
    this.situacio = '';

    this.labels['id'] = this.i18n('Id');
    this.labels['codiCarrega'] = this.i18n('Codi càrrega');
    this.labels['descripcioCarrega'] = this.i18n('Descripció càrrega');
    this.labels['nomFitxer'] = this.i18n('Nom fitxer');
    this.labels['directoriFitxer'] = this.i18n('Directori fitxer');
    this.labels['situacio'] = this.i18n('Situació');
  }
}
