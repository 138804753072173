import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { ZbRuta } from '../../zb-rutas/models/zb-ruta';
import { ZbHorario } from '../../zb-horarios/models/zb-horarios';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { ZbRutasService } from '../../zb-rutas/services/zb-rutas.service';
import { ZbHorariosService } from '../../zb-horarios/services/zb-horarios.service';
import { Agent } from 'app/sections/configuracio/agents/models/agent.model';
import { Planificacio } from '../planificacio';
import { PlanificacioService } from '../services/planificacio.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';



@Component({
  selector: 'app-planificacio-editor',
  templateUrl: './planificacio-editor.component.html',
  styleUrls: ['./planificacio-editor.component.scss']
})
export class PlanificacioEditorComponent implements OnInit {

  Agents = Array<FormMultiSelect>();
  Rutes: ZbRuta[];
  Horaris: ZbHorario[];
  @ViewChild('mainForm') private mainForm: ElementRef;
  RutaSelected;
  HorariSelected;
  SelectedAgents= Array<FormMultiSelect>();
  SelectedDate: Date;  
  private PlanificacioActual:Planificacio;
  

  PlanificacioForm: FormGroup;
  constructor(
    private toastr: ToastrService,
    private i18n: I18n,
    private AgentsService: AgentsService,
    private ZbRutasService: ZbRutasService,
    private ZbHorariosService: ZbHorariosService,
    private PlanificacioService: PlanificacioService,
    public thisDialogRef: MatDialogRef<PlanificacioEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Planificacio
  ) { }

  ngOnInit() {
    
    this.ZbRutasService.getAllZbRutas().subscribe((dataRutes) => {
      this.Rutes = dataRutes;
    });
    this.ZbHorariosService.getAllZbHorarios().subscribe((dataHoraris) => {
      this.Horaris = dataHoraris;
    });
    if(this.data!==null && this.data!==undefined && this.data.SomId!==null && this.data.SomId!==undefined){
      this.PlanificacioActual=this.data;
      this.RutaSelected=this.data.IdRuta;
      this.HorariSelected=this.data.idHorario;
     
      this.SelectedDate=this.data.Dia;
    }
    this.AgentsService.getAllCombo().subscribe((dataAgents) => {
      this.Agents=[];
      dataAgents.forEach(item => {
        this.Agents.push(new FormMultiSelect(item.id, item.nomComplert+'-'+item.codi));
      });
      this.SelectedAgents=this.Agents.filter(x=> this.data.idAgentes.indexOf(x.item_id)>-1);
    });
  }

  getAgents() {
    this.AgentsService.getAllCombo().subscribe((data) => {
      debugger;
      console.log(data);
    }
   );
  }

  save(){
    debugger;
    if(this.RutaSelected===null || this.RutaSelected===undefined){
      this.toastr.warning(this.i18n('Selecciona una ruta'));
    }
    else{
      if(this.HorariSelected===null || this.HorariSelected===undefined){
        this.toastr.warning(this.i18n('Selecciona un horari'));
      }
      else{
        if(this.SelectedAgents.length===0){
          this.toastr.warning(this.i18n('Selecciona un o mes agents'));
        }
        else{
          debugger;
          let SomId=0;
          if(this.PlanificacioActual!==undefined && this.PlanificacioActual!==null && this.PlanificacioActual.SomId!==null && this.PlanificacioActual.SomId!==undefined){
            SomId=this.PlanificacioActual.SomId;
          }
         this.PlanificacioActual={
            SomId:SomId,
            IdRuta: this.RutaSelected,
            idHorario:this.HorariSelected,
            idAgentes:this.SelectedAgents.map(x=>x.item_id),
            Dia:this.SelectedDate,
            NombreRuta:'',
            NombreHorario:'',
            NombreAgentes:this.SelectedAgents.map(x=>x.item_text)
          }
          this.PlanificacioService.setPlanificacion(this.PlanificacioActual).subscribe((res:any)=>{
              if(res=="OK"){
                this.toastr.success(this.i18n('Cambios realizados correctamente'));
              }
              else{
                this.toastr.error(res);
              }
            },
            error=>{
              this.toastr.error(error);
            },
            ()=>{
             this.thisDialogRef.close();
            }
          );
        }
      } 
    }
   

  }
}
