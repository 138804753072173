import { MotiusAnulacio } from './../models/motius-anulacio.model';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { MotiusAnulacioFilter } from '../models/motius-anulacio-filter';
import { MotiusAnulacioFilterService } from './motius-anulacio-filter-service';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';


@Injectable()
export class MotiusAnulacioService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setMotiusAnulacio(aparell: MotiusAnulacio): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetMotiuAnulacio'], aparell);
    }
  }

  removeMotiusAnulacio(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteMotiuAnulacio'].replace('{secure_id}', secure_id), null);
    }
  }

  getMotiusAnulacio(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMotiuAnulacio'].replace('{secure_id}', secure_id), null);
    }
  }
  getAllForCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMotiuAnulacioCombo'], null);
    }
  }


  generateExcel(filter: MotiusAnulacioFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new MotiusAnulacioFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlMotiusAnulacioExcel'],  params );
    }
  }
}
