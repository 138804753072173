import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatPaginator } from '@angular/material';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { AlarmaFilter } from '../../../../models/alarma-filter';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Usuari } from 'app/sections/usuaris/models/usuari.model';
import { AlarmGroup } from 'path-shared/models/alarm-group';
import { AlarmesTableComponent } from '../../../alarmes-table/alarmes-table.component';
import { GoogleMapsService } from 'path-shared/services/startup/google-maps.service';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { AlarmesService } from '../../../../services/alarmes.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { AlarmesComponent } from '../../../../pages/alarmes/alarmes.component';
import { PermissionService } from 'path-shared/components/permission-dialog/permission.service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { Builder } from 'path-shared/models/builder';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { AlarmesFilterComponent } from '../../../alarmes-filter/alarmes-filter.component';

@Component({
  selector: 'app-alarmes-historic',
  templateUrl: './alarmes-historic.component.html',
  styleUrls: ['./alarmes-historic.component.scss']
})
export class AlarmesHistoricComponent implements OnInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: AlarmaFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  private users: Usuari[];
  private tipusAlarmes: AlarmGroup[];
  private timeout = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(AlarmesTableComponent) alarmesTable: AlarmesTableComponent;

  constructor(
    private googleMapsService: GoogleMapsService,
    private authGuard: AuthGuardService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private router: Router,
    private alarmesService: AlarmesService,
    private i18n: I18n,
    private toastr: ToastrService,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<AlarmesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    private permissionService: PermissionService,
    private confirmationDialogService: ConfirmationDialogService,
    ) {
      this.filterData = new AlarmaFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;
      }
      this.permissionService.getUsers().subscribe(users => {
        this.users = users;
      });
      this.alarmesService.getTipus().subscribe(tipus => {
        this.tipusAlarmes = tipus;
      });
    }

  ngOnInit() {
    console.log('alarmes init');
    const automatitzacio = this.route.snapshot.paramMap.get('id');
  }

  ngAfterViewInit() {
    console.log('alarmes after view init');
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Alarmes'));
    } else {
      this.filterData.id = +this.dataDialog.id;
      this.alarmesTable.updatedFilterData.id = +this.dataDialog.id;
      this.alarmesTable.updatedFilterData.updated(true);
    }

    this.route.params.subscribe(params => {
      if (!Reflection.empty(params.id)) {
        if (this.timeout !== null) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          if (params.id === 'automatitzacio') {
            this.filterData.dades = 'AUTOMATITZACIO';
            this.alarmesTable.updatedFilterData.dades = 'AUTOMATITZACIO';
          } else {
            this.filterData.idTipusAlarma = params.id;
            this.alarmesTable.updatedFilterData.idTipusAlarma = params.id;
          }
          this.alarmesTable.updatedFilterData.updated(true);
          this.timeout = null;

        }, 50);
      } else {
        this.alarmesTable.load();
      }
    });
  }


  refresh() {
    this.alarmesTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(AlarmesFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.alarmesTable.updatedFilterData,
        'users': this.users,
        'tipus': this.tipusAlarmes
      }
    });

    ref.componentInstance.notify.subscribe((filter: AlarmaFilter) => {
      this.alarmesTable.updatedFilterData.set(filter);
      this.alarmesTable.load();
    });
  }

  selectCheckedItems() {
    return this.alarmesService.displayedItems['checkedValues'];
  }

  onRowSelected(somid) {
  }

  generateExcel() {
    this.alarmesTable.generateExcel();
  }

  close() {
    this.thisDialogRef.close();
  }

  acceptAll() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol confirmar totes les alarmes pendents?'))
    .then((confirmed) => {
      if (confirmed) {
        this.alarmesService.ackAlarms(this.alarmesTable.data).subscribe(() => {
          this.refresh();
        });
      }
    });
  }

  goLlista() {
    this.router.navigate(['alarmes']);
  }
}
