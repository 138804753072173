/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pendents.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "./pendents.component";
import * as i5 from "@angular/material/dialog";
import * as i6 from "../../services/tramitar.service";
import * as i7 from "@ngx-translate/i18n-polyfill";
import * as i8 from "ngx-toastr";
var styles_PendentsExpedientsComponent = [i0.styles];
var RenderType_PendentsExpedientsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PendentsExpedientsComponent, data: {} });
export { RenderType_PendentsExpedientsComponent as RenderType_PendentsExpedientsComponent };
export function View_PendentsExpedientsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-primary col-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.OpenPopUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["Respuestas Pendientes ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.infoPendents.Pendents; _ck(_v, 5, 0, currVal_0); }); }
export function View_PendentsExpedientsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pendentsexpedients", [], null, null, null, View_PendentsExpedientsComponent_0, RenderType_PendentsExpedientsComponent)), i1.ɵdid(1, 114688, null, 0, i4.PendentsExpedientsComponent, [i5.MatDialog, i6.TramitarService, i7.I18n, i8.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PendentsExpedientsComponentNgFactory = i1.ɵccf("app-pendentsexpedients", i4.PendentsExpedientsComponent, View_PendentsExpedientsComponent_Host_0, {}, {}, []);
export { PendentsExpedientsComponentNgFactory as PendentsExpedientsComponentNgFactory };
