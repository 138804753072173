import { Component, OnInit, EventEmitter, Output, Inject , OnDestroy} from '@angular/core';
import { AgentFilter } from 'app/sections/configuracio/agents/models/agent-filter';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-agents-filter',
  templateUrl: './agents-filter.component.html',
  styleUrls: ['./agents-filter.component.scss']
})
export class AgentsFilterComponent implements OnInit, OnDestroy{
  subscription: Subscription;
  filter: AgentFilter;
  agents = [];

  @Output() notify: EventEmitter<AgentFilter> = new EventEmitter<AgentFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<AgentsFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.agents = [];
    this.agents.push({'codi': '', 'nom': ''});
    this.filter = data.filter;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
