import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";
import { TextosPredefinidosFilter } from "../models/textos-predefinidos-filter.model";

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class TextosPredefinidosFilterService extends CustomFilterService {
  public createFilterParams(filter: TextosPredefinidosFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('descripcion', filter.descripcion)
    .append('id',  (filter.id ? filter.id.toString() : ''))
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
