import { DataSource } from '@angular/cdk/collections';
import { catchError, finalize } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Proposta } from '../models/proposta.model';
import { PropostaFilter } from '../models/proposta.filter';
import { PropostaTipus } from '../models/proposta-tipus.model';
import { HttpParams, HttpErrorResponse } from '@angular/common/http';
import { HttpSecureClient } from '../../../shared/services/http/httpSecureClient';
import { BehaviorSubject } from 'rxjs-compat';
import { of } from 'rxjs/observable/of';
import { PropostesService } from './propostes.service';
import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { EventEmitter } from '@angular/core';

const defaultSortParam = 'Exp';
const defaultSortDirection = 'desc';


/**
 * Data source for the PropostesTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class PropostesDataSource extends DataSource<Proposta> {

  private propostesSubject = new BehaviorSubject<Proposta[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public countChange = new EventEmitter();
  public data: Proposta[];
  public count: number;
  public tipus: PropostaTipus[];
  loading = false;

  constructor(private http: HttpSecureClient) {
    super();
    this.count = 0;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<Proposta[]> {
    return this.propostesSubject.asObservable();
    // return this.getPagedData(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.propostesSubject.complete();
    this.loadingSubject.complete();
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  loadPropostes(filter: PropostaFilter, pageIndex: number, pageSize: number, sortField: string, sortDir: string) {
    if (!this.loading) {
      this.loading = true;
      // loading spinner
      this.loadingSubject.next(true);
      const start = pageIndex * pageSize;

      let params = new HttpParams()
      .append('Proposta', filter.proposta)
      .append('Pais', filter.pais ? filter.pais : '')
      .append('TipusProposta', filter.tipusproposta);

      this.http.get(config.urls['apiUrl'] + api.endpoints['urlPropostesCount'], params).subscribe(x => {
        this.count = x as number;
        this.countChange.emit(x.toString());
      });

      params = params.append('start', start.toString())
        .append('size', pageSize.toString())
        .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
        .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);

      this.http.get(config.urls['apiUrl'] + api.endpoints['urlpropostesquery'], params).pipe(
        catchError(() => of([])),
        finalize(() => {
          this.loadingSubject.next(false);
          this.loading = false;
        })
      ).subscribe(items => {
        this.propostesSubject.next(items as Proposta[]);
      });
      this.loading = false;
    }
  }

  loadTipus() {

  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}



