import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ChipListElement } from './chip-list-element';


@Component({
  selector: 'app-chips-list-input',
  templateUrl: './chips-list-input.component.html',
  styleUrls: ['./chips-list-input.component.scss']
})
export class ChipsListInputComponent implements OnInit {

  val: ChipListElement[];  // Lista de elementos seleccionados
  valAux: ChipListElement[];

  preselectedOption: String;

  @Input() multiple: boolean;
  _valuesList: ChipListElement[];
  @Input() get valuesList() {
    return this._valuesList;
  } set valuesList(val: any) {
    this._valuesList = val;
    if (this.preselectedOption !== undefined) {
      this.selectManually(this.preselectedOption);
    }
  } // Lista de elementos base (todos)
  @Input() label: string;
  @Input() selectable: boolean;
  @Input() get value() {
    return this.val;
  } set value(val) {
    this.val = val;
    this.valueChange.emit(this.val[0]);
  }
  @Output() valueChange = new EventEmitter();


  constructor() {
    this.selectable = true;
  }

  ngOnInit() {
    this.value = [];
  }

  handleClick(index: number): void {
    const elementToPush: ChipListElement = this.valuesList.find(function(element: ChipListElement) {
      return element.key === index;
    });

    const indexOf = this.value.findIndex(p => p.key === elementToPush.key && p.value === elementToPush.value);
    this.valAux = Object.assign([], []);
    if (indexOf !== -1) {
      this.valAux.splice(indexOf, 1);
      if (this.valAux.length > 0) {
        this.value = [...this.valAux];
        this.preselectedOption = undefined;
      }

    } else if (elementToPush.counter.toString() !== '0') {
      this.valAux.push(elementToPush);
      this.value = [...this.valAux];
      this.preselectedOption = undefined;
    }
  }

  isSelected(index: number): boolean {
    const object =  this.valuesList.find(function(element) {
      return element.key === index;
    });
    const isInChipList = object !== undefined;

    let isInSelectedList = false;
    if (this.value !== undefined && object !== undefined && object.counter.toString() !== '0') {
      isInSelectedList = this.value.find(function(element) {
        return element.key === index;
      }) !== undefined;
    }

    return isInChipList && isInSelectedList;
  }

  isSelectable(option: ChipListElement) {
    return this.selectable && option.counter !== undefined && option.counter !== '0';
  }

  getOptionCounter(option: ChipListElement) {
    return option.counter;
  }

  selectManually(optionKey: String) {
    const elementoBuscar: ChipListElement = this.valuesList.find(o => o.key.toString() === optionKey.toString());
    if (elementoBuscar !== undefined) {
      this.value.push(elementoBuscar);
      this.preselectedOption = undefined;
    } else {
      this.preselectedOption = optionKey;
    }
  }


}
