<app-dialog-header label="Càrrega de matrícules" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Carregar" i18n-text (click)="loadMatricules()" color="has-color-blue" icon="fa-upload"></app-tool-button>
  <app-tool-button text="Cancel·lar" i18n-text (click)="cancel()" color="has-color-red" icon="fa-times"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm class="container">
    <div class="row">
      <div class="col-6">
        <app-form-radio-group title="Tipus d'importació" i18n-title [labels]="this.deleteLabels" [values]="this.deleteValues" [(value)]="this.data.delete"></app-form-radio-group>
      </div>
      <div class="col-6">
        <app-form-radio-group title="Tipus de fitxer" i18n-title [labels]="this.typeLabels" [values]="this.typeValues" [(value)]="this.data.somintec"></app-form-radio-group>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3" i18n>Seleciona un fitxer per pujar</div>
      <div class="col-md-8">
        <app-form-file-upload label="Fitxer" i18n-label labelHidden="true" (fileReady)="fileReady($event)"></app-form-file-upload>
      </div>
    </div>
    <div class="row">
        <div class="col-md-3" i18n>Institució</div>
        <app-form-combo-edit label="Institució" i18n-label class="col-sm-8" [items]="this.institucions" [(value)]="this.data.institution" valueField="IdInstitucion" textField="NombreInstitucion" (valueChange)="this.institutionSelected()" required></app-form-combo-edit>
    </div>
    <div class="row">
        <div class="col-md-3" i18n>Tipus de matrícula</div>
        <app-form-combo-edit label="Tipus" i18n-label class="col-sm-8" [items]="this.tipusMatricules" [(value)]="this.data.type" valueField="codi" textField="descripcio" required></app-form-combo-edit>
    </div>
    <div class="row">
      <app-progress-bar class="col-11" i18n-label label="Progrés" pantalla="CargaMatriculas"></app-progress-bar>
    </div>
  </form>
</app-dialog-body>
