var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { FilesService } from 'path-shared/services/files/files.service';
import { AlegacionsService } from '../../services/alegacions.service';
import { Builder } from 'path-shared/models/builder';
import { TextosPredefinidosComponent } from 'app/sections/configuracio/textos-predefinidos/pages/textos-predefinidos/textos-predefinidos.component';
import { OperacionAlegacionDto } from 'app/sections/denuncies/models/operacionAlegacionDto';
import { ToastrService } from 'ngx-toastr';
var RespondreAlegacionsComponent = /** @class */ (function () {
    function RespondreAlegacionsComponent(i18n, thisDialogRef, data, filesService, toastr, alegacionsService, dialog) {
        this.i18n = i18n;
        this.thisDialogRef = thisDialogRef;
        this.data = data;
        this.filesService = filesService;
        this.toastr = toastr;
        this.alegacionsService = alegacionsService;
        this.dialog = dialog;
        this.dto = new OperacionAlegacionDto();
        this.situacio = '';
        this.typeLabels = ['Estimada', 'Desestimada'];
        this.typeValues = [true, false];
        this.alegacio = data;
    }
    RespondreAlegacionsComponent.prototype.ngOnInit = function () {
    };
    RespondreAlegacionsComponent.prototype.decline = function () {
        this.thisDialogRef.close(false);
    };
    RespondreAlegacionsComponent.prototype.accept = function () {
        this.thisDialogRef.close(true);
    };
    RespondreAlegacionsComponent.prototype.dismiss = function () {
        this.thisDialogRef.close(false);
    };
    RespondreAlegacionsComponent.prototype.getFilePath = function () {
        var _this = this;
        this.filesService.getFileSomId(this.alegacio.FICHERO_SOM_ID).subscribe(function (result) {
            var objectUrl = URL.createObjectURL(result);
            window.open(objectUrl);
        }, function (error) { return _this.toastr.error(error); }, function () { return console.log('completed'); });
    };
    RespondreAlegacionsComponent.prototype.guardar = function (resultat) {
        var _this = this;
        if (resultat != null && this.situacio != null) {
            this.alegacionsService.respondreAlegacions(this.alegacio.EXP, resultat, this.situacio, this.alegacio.TIPO).subscribe({
                next: function () { return _this.accept(); }
            });
        }
    };
    RespondreAlegacionsComponent.prototype.buscarAlegacions = function () {
        var _this = this;
        // Carraguem la taula filtrada per dni
        var data = Builder.newComponentDialog(true, false, '', null);
        var ref = this.dialog.open(TextosPredefinidosComponent, {
            width: '80%',
            height: '90%',
            closeOnNavigation: false,
            autoFocus: true,
            data: data
        });
        ref.afterClosed().subscribe(function (resp) { return __awaiter(_this, void 0, void 0, function () {
            var texto;
            return __generator(this, function (_a) {
                texto = resp;
                if (this.situacio !== undefined && this.situacio !== null && this.situacio !== '') {
                    this.situacio = this.situacio + ' ' + texto.DESCRIPCION;
                }
                else {
                    this.situacio = texto.DESCRIPCION;
                }
                return [2 /*return*/];
            });
        }); });
    };
    return RespondreAlegacionsComponent;
}());
export { RespondreAlegacionsComponent };
