import { ComponentDialog } from '../../../../../shared/models/component-dialog.model';
import { NotificationService } from '../../../../../shared/services/notification/notification.service';
import { AgentsEditorComponent } from '../../components/agents-editor/agents-editor.component';
import { AppComponent } from '../../../../../app.component';
import { Component, OnInit, ViewChild, Input, Inject, AfterViewInit } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import {  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { AgentsTableComponent } from '../../components/agents-table/agents-table.component';
import { AgentFilter } from '../../models/agent-filter';
import { AgentsService } from '../../services/agents-service.service';
import { AgentsFilterComponent } from '../../components/agents-filter/agents-filter.component';
import { Builder } from 'path-shared/models/builder';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss']
})

export class AgentsComponent implements OnInit, AfterViewInit {
  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: AgentFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(AgentsTableComponent) agentsTable: AgentsTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private router: Router,
    private agentsService: AgentsService,
    private i18n: I18n,
    private toastr: ToastrService,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<AgentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new AgentFilter('', '', new Date());
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.Agents);
    this.agentsTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.agentsTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Agents'));
    }

  }

  edit() {
    const idSeleccionat: string = this.agentsService.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);
    const ref = this.dialog.open(AgentsEditorComponent, {
      width: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.agentsTable.load(); }
    });

  }

  refresh() {
    this.agentsTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(AgentsFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.agentsTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: AgentFilter) => {
      this.agentsTable.filter.set(filter);
      this.agentsTable.load();
    });
  }

  selectCheckedItems() {
    return this.agentsService.displayedItems['checkedValues'];
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.agentsTable.generateExcel();
  }

  close() {
    this.thisDialogRef.close();
  }
}
