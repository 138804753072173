import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { DeltacarHistoricoFilter } from '../../models/deltacar-historico-filter';

@Component({
  selector: 'app-deltacar-historico-filter',
  templateUrl: './deltacar-historico-filter.component.html',
  styleUrls: ['./deltacar-historico-filter.component.scss']
})
export class DeltacarHistoricoFilterComponent implements OnInit {
  estados = [{descripcio: ''}, {descripcio: 'CADUCADA'},{descripcio: 'ACEPTADA'},{descripcio: 'DESESTIMADA'}]

  subscription: Subscription;
  filter: DeltacarHistoricoFilter;
  @Output() notify: EventEmitter<DeltacarHistoricoFilter> = new EventEmitter<DeltacarHistoricoFilter>();

  constructor(public thisDialogRef: MatDialogRef<DeltacarHistoricoFilter>, 
    @Inject(MAT_DIALOG_DATA) public data,
) {
  this.filter = data.filter;
 }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }
}
