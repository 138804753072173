var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var AlarmaFilter = /** @class */ (function (_super) {
    __extends(AlarmaFilter, _super);
    function AlarmaFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.id = undefined;
        _this.text = '';
        _this.data = null;
        _this.IdUsuariACK = null;
        _this.idTipusAlarma = null;
        _this.usuari = '';
        _this.matricula = '';
        _this.tradesegur_id = '';
        _this.data_ack = null;
        _this.dades = null;
        _this.labels['id'] = _this.i18n('Id');
        _this.labels['text'] = _this.i18n('Text');
        _this.labels['data'] = _this.i18n('Data');
        _this.labels['idTipusAlarma'] = _this.i18n('Tipus');
        _this.labels['IdUsuariACK'] = _this.i18n('Usuari');
        _this.labels['usuari'] = _this.i18n('Usuari');
        _this.labels['matricula'] = _this.i18n('Matricula');
        _this.labels['tradesegur_id'] = _this.i18n('TradesegurId');
        _this.labels['data_ack'] = _this.i18n('data_ack');
        _this.labels['dades'] = _this.i18n('Datos');
        _this.formatOutput['data'] = 'shortDate';
        return _this;
    }
    return AlarmaFilter;
}(FilterData));
export { AlarmaFilter };
