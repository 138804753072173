export class KeyCounter  {

    public Key: string;
    public Count: number;

    constructor(Key: string) {
        this.Key = Key;
        this.Count = 0;
    }

    public static create(Keys: string[]) {
        const counters = [];
        Keys.forEach(element => {
            counters.push(new KeyCounter(element));
        });
        return counters;
    }

}
