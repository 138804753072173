// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';
import { UserPermissionsByPage } from '../../models/userPermissionsByPage';
import { HttpSecureClient } from '../http/httpSecureClient';
import { api } from '../../configuration/urls';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { debug } from 'util';
import { SOM_CARAS } from 'path-models/SOM_CARAS';
import { HttpResponse, HttpParams } from '@angular/common/http';

@Injectable()
export class AuditoriaService {
  constructor(private router: Router, private http: HttpSecureClient ) {}

  public getTabla(pantalla, sort, paginator): Observable<any> {

    const url = config.urls['apiUrl'] + api.endpoints['UrlGetTablaAuditoria'];
    return this.http.get(url,
      new HttpParams()
      .append('name', pantalla )
      .append('property', sort === undefined ? '' : sort.active)
      .append('dir', sort === undefined ? '' : sort.direction)
      .append('pageIndex', paginator === undefined ? '' : paginator.pageIndex)
      .append('pageSize', paginator === undefined ? '' : paginator.pageSize));

  }

  public getTablaCount(pantalla): Observable<any> {

    const url = config.urls['apiUrl'] + api.endpoints['UrlGetTablaAuditoriaCount'];
    return this.http.get(url,
      new HttpParams()
      .append('name', pantalla));
  }

  public getTablasAsociadas(pantalla): Observable<any> {

    const url = config.urls['apiUrl'] + api.endpoints['UrlGetTablasAsociadasAuditoria'];
    return this.http.get(url, new HttpParams().append('name', pantalla ));

  }

  public GetActivaAuditoria(PermissionName): Observable<any> {
    return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAuditoriaActiva'], new HttpParams().append('name', PermissionName));
  }

  public SetActivaAuditoria(PermissionName) {
    return this.http.get(config.urls['apiUrl'] + api.endpoints['urlSetAuditoriaActiva'], new HttpParams().append('name', PermissionName));
  }

}
