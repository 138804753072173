
<div class="row justify-content-between">
  
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.currentFilter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]"
      (page)="paginatorChanged($event)">
    </mat-paginator>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.currentFilter.paginator.sortField" [matSortDirection]="this.currentFilter.paginator.sortDir" class="is-header-sticky">
      <ng-container matColumnDef="tiquet">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tiquet</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.numTiquet}}</mat-cell>
        </ng-container>

      <ng-container matColumnDef="matricula">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.matricula}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="ZONA_TICKET">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Zona</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.zona}}</mat-cell>
      </ng-container>  

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Email</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.email}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="importTiquet">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Import Tiquet</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.importTiquet | currency:'EUR': 'symbol'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="FECHA_TICKET">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data Tiquet</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.data}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="FECHA_FIN_TICKET">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data Tiquet Final</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.dataFinal}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="MINUTOS_DIF">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Diferencia Minuts</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.minutsDif}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="ID_EXTERNO">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>ID Extern</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.idExterno}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="PROVEEDOR_EXTERNO">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Proveidor</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.proveidor}}</mat-cell>
      </ng-container>
      

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
</div>