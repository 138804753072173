import { CustomMapMarker } from '../../../../shared/models/custom-map-marker';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Instituciones } from 'path-shared/models/instituciones';
import { CustomDataMarker } from '../../models/custom-data-marker.js';
import { Dictionary } from 'path-shared/models/dictionary.js';
import { CustomDataPoligon } from '../../models/custom-poligon';
import { CustomMapPoligon } from 'path-shared/models/custom-map-poligon';

declare var google: any;

@Component({
  selector: 'app-map-component',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  _overridedLocation: {
    latitude: number,
    longitude: number
  };
  @Input() get overridedLocation() {
    return this._overridedLocation;
  } set overridedLocation(val) {
    this._overridedLocation = val;
    if (this._overridedLocation !== undefined) {
      this.currentLocation = this._overridedLocation;
    }
  }

  @Output() markerRouteEvent: EventEmitter<any>;
  @Output() markerPositionUpdate: EventEmitter<any>;
  @Input() zoom: number;

  poligons: Array<CustomMapPoligon>;
  markers: Array<CustomMapMarker>;
  permanentShow: boolean;
  map: any;
  public currentLocation: {
    latitude: number,
    longitude: number
  };

  public styleMap = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ];

  constructor() {
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      // No tenemos ningun punto, intentamos definir como centro el municipio actual.
      const institution: Instituciones = JSON.parse(inst);
      if (institution.Latitud !== undefined && institution.Longitud !== undefined) {
        this.currentLocation = {latitude: institution.Latitud, longitude: institution.Longitud};
      }
    }

    this.permanentShow = false;
    this.markers = new Array();
    this.poligons = new Array();
    this.zoom = 0;
    this.markerPositionUpdate = new EventEmitter<any>(undefined);
    this.markerRouteEvent = new EventEmitter<any>(undefined);
  }

  ngOnInit() {
  }

  mapReady(map: any) {
    this.map = map;
  }

  appendNewPoligons(dataPoligons: CustomDataPoligon[]) {
    dataPoligons.forEach(dataPoligon => {
      const createdPoligon = this.createCustomPoligon(dataPoligon);
      this.poligons.push(new CustomMapPoligon(dataPoligon.associatedId, createdPoligon));
    });
  }

  removePolygonsOfType(polygonTypeId: string) {
    // Obtenemos los poligonos a eliminar.
    const polygonsToRemove = this.poligons.filter(p => p.key === polygonTypeId);
    // Definimos el mapa a null para estos poligonos.
    polygonsToRemove.forEach(p => {
      p.poligon.setMap(null);
      // Tambien buscamos cual es su indice en la lista original y lo eliminamos de esta.
      const index = this.poligons.indexOf(p);
      if (index > -1) {
        this.poligons.splice(index, 1);
      }
    });
  }

  appendNewMarkers(dataMarkers: CustomDataMarker[]) {
    dataMarkers.forEach(dataMarker => {
      const equalMarker = this.markers.find(m =>
        m.sortable1 === dataMarker.internalId &&
        m.sortable2 === dataMarker.associatedResourceId &&
        m.marker.LatLng === m.marker.LatLng);
      if (equalMarker === undefined) {
        const createdMarker = this.createCustomMarker(dataMarker);
        const marker = createdMarker.createdMarked;
        const infoWindow = createdMarker.associatedPopup;
        this.markers.push(new CustomMapMarker(dataMarker.internalId, dataMarker.associatedResourceId, marker, infoWindow));
      }
    });
  }

  removeMarkersOfType(markerTypeId: String) {
    // Obtenemos los markers a eliminar.
    const markersToRemove = this.markers.filter(m => m.sortable1 === markerTypeId);
    // Los usamos y actualizamos el mapa a null para eliminarlos del mapa actual.
    markersToRemove.forEach(m => {
      m.marker.setMap(null);
      // A la vez buscamos cual es su indice en la lista original y lo eliminamos de esta.
      const index = this.markers.indexOf(m);
      if (index > -1) {
        this.markers.splice(index, 1);
      }
    });
  }

  removeMarkersOfIds(markersIds: Array<any>) {
    markersIds.forEach(mId => {
      const index = this.markers.findIndex(mR => mR.sortable2 === mId);
      if (index !== -1) {
        // Debemos eliminar el marker.
        this.markers[index].marker.setMap(null);
        this.markers.splice(index, 1);
      }
    });
  }

  createCustomPoligon(dataPoligon: CustomDataPoligon) {
    // Creamos el poligono.
    const poligon = new google.maps.Polygon ({
      paths: dataPoligon.area,
      strokeColor: dataPoligon.color,
      strokeOpacity: dataPoligon.alpha,
      strokeWeight: 1,
      fillColor: dataPoligon.color,
      fillOpacity: dataPoligon.alpha
    });
    poligon.setMap(this.map);
    return poligon;
  }

  createCustomMarker(dataMarker: CustomDataMarker) {
    // Creamos el marker.
    const marker = new google.maps.Marker ({
      map: this.map,
      label: {
        fontFamily: "'Font Awesome 5 Free'",
        text: dataMarker.innerIcon,
        color: dataMarker.iconFillColor,
        transition: 'all .25s',
        fontWeight: '900',
        fontSize: 'x-large'
      },
      icon: ' ',
      /*icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 17,
        strokeColor: 'white',
        strokeWeight: 1,
        fillColor: dataMarker.iconFillColor,
        fillOpacity: dataMarker.iconFillOpacity
      },*/
      clickable: dataMarker.showHover ? dataMarker.showHover : false,
      draggable: dataMarker.dragable,
      animation: dataMarker.animable ? google.maps.Animation.DROP : '',
      position: new google.maps.LatLng(dataMarker.latitude, dataMarker.longitude),
    });

    // Definimos el evento dragEnd del marker.
    const updatesEventEmitter = this.markerPositionUpdate;
    if (dataMarker.dragable) {
      google.maps.event.addListener(marker, 'dragend', function(event) {
        const latitude = event.latLng.lat();
        const longitude = event.latLng.lng();

        if (updatesEventEmitter !== undefined) {
          updatesEventEmitter.emit(new Dictionary(latitude, longitude));
        }
      });
    }

    // Creamos el hover (InfoWindow) y lo asignamos al mapa y al marker.
    let infoWindow;
    if (dataMarker.showHover) {
      infoWindow = this.crearInfoWindow(dataMarker.text, marker, dataMarker.internalId, dataMarker.associatedResourceId);
    }

    return { createdMarked: marker, associatedPopup: infoWindow };
  }

  showHoversPermanentlly() {
    // Ahora seguro que los markers tienen un infoWindow asociado. Los abrimos todos.
    this.permanentShow = true;
    this.markers.forEach(m => {
      m.infoWindow.open(this.map, m.marker);
    });
  }

  hideHoversPermanently() {
    // Cerramos los infoWindows i los eliminamos de la lista actual.
    this.permanentShow = false;
    this.markers.forEach(m => {
      m.infoWindow.close();
    });
  }

  crearInfoWindow(content: String, marker: any, mapFeature: string, resourceId: any) {
    const noScrollContent = '<div style="overflow: hidden; white-space: nowrap;">' + content + '</div>';
    const infoWindow = new google.maps.InfoWindow({
      content: noScrollContent
    });
    infoWindow.setOptions({disableAutoPan: true, maxWidth: 700});

    // Anadimos el evento que abre el popover (si es que la opcion @showHover lo indica).
    marker.addListener('mouseover', function() {
      infoWindow.open(this.map, marker);
    });

    marker.addListener('mouseout', function() {
      infoWindow.close();
    });

    const markerNavigateEvent = this.markerRouteEvent;
    // Anadimos el evento que nos abre el mantenimiento/listado relacionado.
    marker.addListener('click', function() {
      markerNavigateEvent.emit( {mapFeature: mapFeature, resourceId: resourceId} );
    });

    // Abrimos el popup directamente si se da el caso.
    if (this.permanentShow) {
      infoWindow.open(this.map, marker);
    }

    return infoWindow;
  }

}
