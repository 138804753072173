var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var PropostesAnulacioFilter = /** @class */ (function (_super) {
    __extends(PropostesAnulacioFilter, _super);
    function PropostesAnulacioFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.matricula = null;
        _this.exp = null;
        _this.acceptada = null;
        _this.dataAceptDengIni = null;
        _this.dataAceptDengFi = null;
        _this.dataDenunciaIni = null;
        _this.dataDenunciaFi = null;
        _this.dataPropIni = null;
        _this.dataPropFi = null;
        _this.labels['matricula'] = _this.i18n('Matricula');
        _this.labels['exp'] = _this.i18n('Exp');
        _this.labels['acceptada'] = _this.i18n('Acceptada');
        _this.labels['dataAceptDengIni'] = _this.i18n('Fecha Acep/Deng inicio');
        _this.labels['dataAceptDengFi'] = _this.i18n('Fecha Acep/Deng fin');
        _this.labels['dataDenunciaIni'] = _this.i18n('Fecha denuncia inicio');
        _this.labels['dataDenunciaFi'] = _this.i18n('Fecha denuncia fin');
        _this.labels['dataPropIni'] = _this.i18n('Fecha propuesta inicio');
        _this.labels['dataPropFi'] = _this.i18n('Fecha propuesta fin');
        return _this;
    }
    return PropostesAnulacioFilter;
}(FilterData));
export { PropostesAnulacioFilter };
