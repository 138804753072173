<app-filter-header title="Filtrar legislacions" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-filter-text-edit id="legislacions-filter-codi" autofocus class="col-sm-3" i18n-label label="codi" [(value)]="filter.codi"></app-filter-text-edit>
    <app-filter-text-edit id="legislacions-filter-descripcio" class="col-sm-9" i18n-label label="Descripció" [(value)]="filter.descripcio"></app-filter-text-edit>
  </div>
  <div class="row">
      <app-form-text-edit id="legislacions-filter-mesesSLIni" class="col-sm-4" i18n-label label="Mesos sanció lleu inici" [(value)]="filter.mesesSLInici"></app-form-text-edit>
      <app-form-text-edit id="legislacions-filter-mesesSGIni" class="col-sm-4" i18n-label label="Mesos sanció greu inici" [(value)]="filter.mesesSGInici"></app-form-text-edit>
      <app-form-text-edit id="legislacions-filter-mesesSMGIni" class="col-sm-4" i18n-label label="Mesos sanció molt greu inici" [(value)]="filter.mesesSMGInici"></app-form-text-edit>
  </div>
  <div class="row">
      <app-form-text-edit id="legislacions-filter-mesesSLFi" class="col-sm-4" i18n-label label="Mesos sanció lleu fi" [(value)]="filter.mesesSLFi"></app-form-text-edit>
      <app-form-text-edit id="legislacions-filter-mesesSGFi" class="col-sm-4" i18n-label label="Mesos sanció greu fi" [(value)]="filter.mesesSGFi"></app-form-text-edit>
      <app-form-text-edit id="legislacions-filter-mesesSMGFi" class="col-sm-4" i18n-label label="Mesos sanció molt greu fi" [(value)]="filter.mesesSMGFi"></app-form-text-edit>
  </div>
  <div class="row">
      <app-form-tri-state label="Legislació de trànsit" i18n-label i18n-labelSi labelSi="Si" i18n-labelNo labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.trafic"></app-form-tri-state>
 </div>
 <div class="row">
    <app-form-tri-state label="Obsoleta" i18n-label i18n-labelSi labelSi="Si" i18n-labelNo labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.obsoleta"></app-form-tri-state>
 </div>
</div>
