<div class="row" style="padding-top: 2%;padding-left: 15px;">
  <div class="clearfix hidden-xs hidden-sm"> </div>
  <app-form-text-edit id="diposit-editor-general-codi" class="col-xl-2" i18n-label label="Codi" [(value)]="this.value.idDipositVehicle"  [isReadOnly]="true" ></app-form-text-edit>
  <div class="col-xl-2">
    <app-form-text-edit id="diposit-editor-general-idDen" i18n-label label="Id Retirada/Den" [(value)]="this.value.iddenuncia"  [isReadOnly]="true" ></app-form-text-edit>
    <app-view-list-icon *ngIf="this.value.iddenuncia ===undefined || this.value.iddenuncia === null|| this.value.iddenuncia === ''"(listClick)="seleccionarProposta()" [hideView]="true"></app-view-list-icon>
  </div>
  <app-form-text-edit id="diposit-editor-general-llocRetirada" class="col-xl-2" i18n-label label="Lloc retirada" [(value)]="this.value.llocRetirada"  [isReadOnly]="false" ></app-form-text-edit>
  <!-- <app-form-text-edit class="col-xl-2" *ngIf="this.value.idDipositVehicle !== null" i18n-label label="Matricula" [(value)]="this.value.idVehicleFk"  [isReadOnly]="false" ></app-form-text-edit> -->
  <div class="col-xl-1" >
    <app-form-text-edit id="diposit-editor-general-matricula" i18n-label label="Matrícula" [(value)]="this.value.idVehicleFk" (change)="this.consultarVehicle()" [readonly]="true" required></app-form-text-edit>
    <app-view-list-icon (listClick)="seleccionarVehicle()" [hideView]="true"></app-view-list-icon>
  </div> 
  <!--<app-form-text-edit class="col-xl-3" i18n-label label="Tipo vehiculo" [(value)]="this.value.idtipusvehicleFK"  [isReadOnly]="false" ></app-form-text-edit>-->
  <app-combo-box-input-autocomplete #comboDiposits class="center-horizontal col-sm-2" i18n-label label="Dipòsit" [options]="this.dipositsDictionary"
      (valorSeleccionado)="this.dipositSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteDipositsDictionary"  required="true" >
    </app-combo-box-input-autocomplete>
  <app-combo-box-input-autocomplete #comboTipoVehiculo class="center-horizontal col-sm-2" i18n-label label="Tipo vehiculo" [options]="this.tipoVehiculoDictionary"
      (valorSeleccionado)="this.tipoVehiculoSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteTipoVehiculoDictionary"  required="false" >
    </app-combo-box-input-autocomplete>
  <!--<app-form-text-edit class="col-xl-3" i18n-label label="Tècnic grua" [(value)]="this.value.tecnicGrua"  [isReadOnly]="false" ></app-form-text-edit>-->
  <app-combo-box-input-autocomplete #comboTecnics class="center-horizontal col-sm-1" i18n-label label="Tècnic grua" [options]="this.tecnicsDictionary"
        (valorSeleccionado)="this.tecnicSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteTecnic"  required="false" >
      </app-combo-box-input-autocomplete>

  <app-form-date-edit id="diposit-editor-general-dataEntr" i18n-label label="Data entrada" class="col-sm-2" [(value)]="this.value.dataEntrada"></app-form-date-edit>  
  <app-form-text-edit id="diposit-editor-general-horaEntr" class="col-sm-1" type="time" i18n-label label="Hora entrada" [(value)]="this.value.horaEntrada" [readonly]="false" ></app-form-text-edit>
  <app-form-text-edit id="diposit-editor-general-motiuEntr" class="col-xl-3" i18n-label label="Motiu entrada" [(value)]="this.value.motiuEntrada"  [isReadOnly]="false" ></app-form-text-edit>
  <!--<app-form-text-edit class="col-xl-3" i18n-label label="A disposició de" [(value)]="this.value.motiuEntrada"  [isReadOnly]="false" ></app-form-text-edit>-->
  <app-combo-box-input-autocomplete #comboMotiuEntrada class="center-horizontal col-sm-3" i18n-label label="A disposició de" [options]="this.motisEntradaDictionary"
      (valorSeleccionado)="this.motiuEntradaSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteMotiuEntradaDictionary"  required="false" >
    </app-combo-box-input-autocomplete>
  <app-form-date-edit id="diposit-editor-general-dataSort" i18n-label label="Data sortida" class="col-sm-2" [(value)]="this.value.dataSortida"></app-form-date-edit>  
  <app-form-text-edit id="diposit-editor-general-horaSort" class="col-sm-1" type="time" i18n-label label="Hora sortida" [(value)]="this.value.horaSortida" [readonly]="false" ></app-form-text-edit>
  <app-form-text-edit id="diposit-editor-general-motiuSort" class="col-xl-3" i18n-label label="Motiu sortida" [(value)]="this.value.motiuSortida"  [isReadOnly]="false" ></app-form-text-edit>
  <app-form-text-edit id="diposit-editor-general-numAlbara" class="col-xl-3" i18n-label label="Núm albarà" [(value)]="this.value.numAlbara"  [isReadOnly]="false" ></app-form-text-edit> 
  <mat-checkbox class="col-xl-3" [(ngModel)]="this.value.sinCargo" [checked]="this.value.sinCargo" i18n >Sense càrrec &nbsp;</mat-checkbox>
</div>
<div>
  <mat-tab-group [selectedIndex]="0" class="col-xl-12"><!--(selectedIndexChange)="selected.setValue($event)"-->
    <mat-tab i18n-label label="Càlcul" >
      <div>
        <div class="row" style="margin-top: 1vh;">
          <app-form-currency-text-edit id="diposit-editor-general-impEng" class="col-xl-2" i18n-label label="Import enganxament" [(value)]="this.value.importenganche"  [isReadOnly]="false" ></app-form-currency-text-edit>
          <app-form-currency-text-edit id="diposit-editor-general-impCust" class="col-xl-2" i18n-label label="Import custodia" [(value)]="this.value.importcustodia"  [isReadOnly]="false" ></app-form-currency-text-edit>
          <div class="col-xl-5">
            <span>
              <div  class="text-center">
                <button 
                  class="btn btn-success button-not-allowed btn-lg " type="button" (click)="this.calcular()"
                  [disabled]="isCalcularButtonDisabled()">           
                  <i class="fas fa-calculator"></i>&nbsp;<span i18n>Calcular</span>
                </button>
              </div>  
            </span>   
          </div>
        </div>
        
        <div class="row">
          <app-form-combo-edit #comboTipusCobro class="center-horizontal col-sm-2" i18n-label label="A cobrar" [items]="this.dictTipusCobro"
          [(value)]="this.value.idTipoPago" required="false" valueField="id" textField="descripcio">
          </app-form-combo-edit>
          <app-form-combo-edit #comboFormaPagament class="center-horizontal col-sm-2" i18n-label label="Forma pagament" [items]="this.dictFormaPagament"
            [(value)]="this.value.formapagament" valueField="id" textField="descripcio">
          </app-form-combo-edit>
    
          <div class="col-xl-5">
            <span>
              <div  class="text-center">
                <button 
                  class="btn btn-success button-not-allowed btn-lg" type="button" 
                  (click)="this.cobrar()" [disabled]="cobrarDesactivat()">           
                  <i class="fas fa-euro-sign"></i> &nbsp;<span i18n>Cobrar</span>  &nbsp;
                </button>
              </div>  
            </span>   
          </div>
        </div>
        
        <div class="row">
          <!--<app-form-text-edit class="col-xl-3" i18n-label label="Forma pagament" [(value)]="this.value.tipusCalcul"  [isReadOnly]="false" ></app-form-text-edit>-->

          <app-form-currency-text-edit id="diposit-editor-general-impCalc" class="col-xl-3" i18n-label label="Import calculat" isReadOnly="true" [(value)]="this.value.importcalculat" ></app-form-currency-text-edit>
          <!--<app-form-text-edit class="col-xl-3" i18n-label label="Data càlcul" [(value)]="this.value.dataCalcul"  [isReadOnly]="false" ></app-form-text-edit>-->
          <app-form-date-edit id="diposit-editor-general-dataCalc" i18n-label label="Data càlcul" [(value)]="this.value.dataCalcul"></app-form-date-edit>  
          <app-form-text-edit id="diposit-editor-general-horaCalc" class="col-sm-1" type="time" i18n-label label="Hora càlcul" [(value)]="this.value.horaCalcul" ></app-form-text-edit>
          <!--<app-form-text-edit class="col-xl-3" i18n-label label="Hora càlcul" [(value)]="this.value.horaCalcul"  [isReadOnly]="false" ></app-form-text-edit>-->
          <app-form-currency-text-edit id="diposit-editor-general-impCobr" class="col-xl-2" i18n-label label="A cobrar" [(value)]="this.value.importACobrar" ></app-form-currency-text-edit>
        </div>
        <div class="row">
          <app-form-currency-text-edit id="diposit-editor-general-impEntregat" number class="col-xl-2" i18n-label label="Entregat" [(value)]="this.value.importEntregat"  ></app-form-currency-text-edit>
          <app-form-currency-text-edit id="diposit-editor-general-canvi" class="col-xl-1" i18n-label label="Canvi" [value]="this.getCanvi()"  [isReadOnly]="true" ></app-form-currency-text-edit>
        
          <!--<app-form-text-edit class="col-xl-3" i18n-label label="Data pagament" [(value)]="this.value.datacobrament"  [isReadOnly]="false" ></app-form-text-edit>-->
          <app-form-date-edit id="diposit-editor-general-dataPag" i18n-label label="Data pagament" [(value)]="this.value.datacobrament"></app-form-date-edit>  
          <app-form-text-edit id="diposit-editor-general-horaPag" class="col-sm-1" type="time" i18n-label label="Hora pagament" [(value)]="this.value.horaCobrament"  ></app-form-text-edit>
          <app-form-text-edit id="diposit-editor-general-numFactura" class="col-xl-2" i18n-label label="Núm factura" [(value)]="this.value.numFactura"></app-form-text-edit> 
          <!--<app-form-text-edit class="col-xl-12" i18n-label label="IMPORT TOTAL" [(value)]="this.value.i"  [isReadOnly]="false" ></app-form-text-edit>-->

        </div>

      </div>     
    </mat-tab>
    <mat-tab i18n-label label="Observacions">
      <app-form-text-area class="col-lg-10" i18n-label label="Observacions"  [(value)]="this.value.observacions"  maxLength="250"></app-form-text-area>
    </mat-tab>
    <mat-tab i18n-label label="Referències C60">
      <app-ref-c60 [data]="this.refsC60" [singleImport]="true"></app-ref-c60>
    </mat-tab>
  </mat-tab-group>
</div>