<div class="row justify-content-between">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
    
    <ng-container matColumnDef="Idtarifa">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n style="width:25%">Codi</mat-header-cell>
      <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.SomId}}</a></mat-cell>
    </ng-container>

    <ng-container matColumnDef="Descripcion">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripció</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.descripcion}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Tipustarifa">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipus tarifa</mat-header-cell>
      <mat-cell *matCellDef="let row">{{this.tipoTarifaLabel(row.tipoTarifa)}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="TipusVehicle">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipus vehicle</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.tipoVehiculoModel.descripcio}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="importtarifa">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Import</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.importeTarifa}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="TipusEstancia">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipus estància</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ this.tipoEstanciaLabel(row.tipoEstancia) }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Fecha_desde">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data inici</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.fechaDesde | date: 'dd/MM/yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Fecha_hasta">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data fi</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.fechaHasta | date: 'dd/MM/yyyy'}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>

