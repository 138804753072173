<app-dialog-header label="Tècnics" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.GruaTecnics) || !this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.GruaTecnics) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.GruaTecnics) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="grua-tecnics-editor-codi" class="col-sm-9" i18n-label label="Codi" [(value)]="this.gruaTecnics.codi" required></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-text-edit id="grua-tecnics-editor-nom" class="col-sm-9" i18n-label label="Nom" [(value)]="this.gruaTecnics.nom"></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-text-edit id="grua-tecnics-editor-dni" class="col-sm-9" i18n-label label="DNI" [(value)]="this.gruaTecnics.DNI"></app-form-text-edit>
    </div>
  </form>
</app-dialog-body>
