import { OnInit, EventEmitter } from '@angular/core';
var UploadToolButtonComponentComponent = /** @class */ (function () {
    function UploadToolButtonComponentComponent() {
        this.uploadFile = new EventEmitter();
        this.iconClass = 'fa fa-question';
        this.propagate = false;
        this.badgeColor = 'badge-primary';
        this.badge = '';
        this.badgeActive = false;
        this.isFile = false;
    }
    Object.defineProperty(UploadToolButtonComponentComponent.prototype, "disabled", {
        get: function () {
            return this.disabledValue;
        },
        set: function (dis) {
            this.disabledValue = dis;
            this.color = this.colorValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UploadToolButtonComponentComponent.prototype, "color", {
        get: function () {
            return this.colorValue;
        },
        set: function (col) {
            this.colorValue = col;
            this.colorClass = 'actions-bar__button-icon';
            if (this.colorValue !== undefined) {
                if (!this.disabled) {
                    this.colorClass += ' ' + this.colorValue;
                }
                else {
                    this.colorClass += ' has-color-gray';
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    UploadToolButtonComponentComponent.prototype.ngOnInit = function () {
        console.log('upload file');
        this.badgeClass = 'badge badge-pill badge-pill-floating-right ';
        if (this.iconClass !== undefined) {
            this.iconClass = 'fa ' + this.icon;
        }
        if (this.isFile === null || this.isFile === undefined || this.isFile === false) {
            this.tipuCamp = 'hidden';
        }
        else {
            this.tipuCamp = 'file';
        }
    };
    UploadToolButtonComponentComponent.prototype.fileReady = function (event) {
        if (!this.disabled) {
            this.uploadFile.emit(event);
        }
    };
    UploadToolButtonComponentComponent.prototype.onFileChange = function (event) {
        if (event.target.files && event.target.files.length) {
            var file = event.target.files[0];
            this.uploadFile.next(file);
            //this.name = file.name;
            //this.size = file.size;
        }
    };
    return UploadToolButtonComponentComponent;
}());
export { UploadToolButtonComponentComponent };
