<li #dd class="nav-item nav-dropdown">
<!--  <a class="nav-link nav-dropdown-toggle" (click)="this.click($event.target)" >-->
    <a id="nav-link-space" class="nav-link nav-dropdown-toggle">
      <img *ngIf="this.src !== undefined" class="nav-icon navbar-brand-full" [src]="this.src"  height="14,55px" >
      <i  *ngIf="this.src === undefined"  class="nav-icon {{this.icon}}"></i>&nbsp;{{ this.text }}
  </a>
  <ul class="nav-dropdown-items">
    <ng-content></ng-content>
  </ul>
</li>
