import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { TipusEnviamentsFilterComponent } from '../components/tipus-enviaments-filter/tipus-enviaments-filter.component';
import { TipusEnviamentsFilter } from '../models/tipus-enviaments-filter';
import { TipusEnviamentsEditorComponent } from '../components/tipus-enviaments-editor/tipus-enviaments-editor.component';
import { TipusEnviamentsTableComponent } from '../components/tipus-enviaments-table/tipus-enviaments-table.component';


@Component({
  selector: 'app-tipus-enviaments',
  templateUrl: './tipus-enviaments.component.html',
  styleUrls: ['./tipus-enviaments.component.scss']
})
export class TipusEnviamentsComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: TipusEnviamentsFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(TipusEnviamentsTableComponent) tipusEnviamentsTable: TipusEnviamentsTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<TipusEnviamentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new TipusEnviamentsFilter('id', 'desc');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.TipusEnviaments);
    this.tipusEnviamentsTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.tipusEnviamentsTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Tipo de envíos'));
    }
  }

  edit() {
    const idSeleccionat: string = this.tipusEnviamentsTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

    const ref = this.dialog.open(TipusEnviamentsEditorComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.tipusEnviamentsTable.load(); }
    });
  }

  refresh() {
    this.tipusEnviamentsTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(TipusEnviamentsFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.tipusEnviamentsTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: TipusEnviamentsFilter) => {
      this.tipusEnviamentsTable.filter.set(filter);
      this.tipusEnviamentsTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.tipusEnviamentsTable.generateExcel();
  }

}
