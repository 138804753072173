import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MarquesVehiclesFilter } from '../../models/marques-vehicles-filter';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-marques-vehicles-filter',
  templateUrl: './marques-vehicles-filter.component.html',
  styleUrls: ['./marques-vehicles-filter.component.scss']
})
export class MarquesVehiclesFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: MarquesVehiclesFilter;

  @Output() notify: EventEmitter<MarquesVehiclesFilter> = new EventEmitter<MarquesVehiclesFilter>();

  @ViewChild('mainForm') private mainForm: ElementRef;
  constructor(
    public thisDialogRef: MatDialogRef<MarquesVehiclesFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.filter = data.filter;
  }


  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
      this.filter.updated();
      this.notify.emit(this.filter);
      this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
