import { I18n } from '@ngx-translate/i18n-polyfill';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { Aparell } from '../../models/aparell.model';
import { AparellFilter } from '../../models/aparell-filter';
import { AparellsFilterService } from '../../services/aparells-filter-service';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { AparellService } from '../../services/aparells-service.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';


@Component({
  selector: 'app-aparells-table',
  templateUrl: './aparells-table.component.html',
  styleUrls: ['./aparells-table.component.scss'],
})
export class AparellsTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;




  dataSource: CustomDataSource<Aparell, AparellFilter>;
  filter: AparellFilter = new AparellFilter('codi', 'asc');
  displayedItems = [];
  highlightedRows = [];

  @Input() selectButton: boolean;
  @Input()  datas: Aparell[];
  @Input() updatedFilterData: AparellFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = ['codi', 'descripcio', 'marca', 'model', 'numeroSerie', 'tipus', 'dataRevisio', 'dataCaducitat'];

  constructor(
    private aparellService: AparellService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n
  ) {
  }


  ngOnInit() {
    this.getAparatos();
  }



  getAparatos() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Aparell, AparellFilter>(this.paginator, new AparellsFilterService(), 'urlGetAparells', 'urlGetCountAparell');
    this.dataSource.subject.subscribe((data) => {
      this.datas = data;
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('aparells-filter', this.filter); }
      }
    });

    if (!this.selectButton) { this.filterService.loadFilter('aparells-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);

  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row.secureId);
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.aparellService, filter, this.i18n('Aparells'), this.sort.direction, this.sort.active)
    });
  }

}
