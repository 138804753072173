import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { DeltacarHistoricoFilterService } from './deltacar-historico-filter.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../../shared/services/authentication/auth-guard.service";
var defaultSortParam = 'fecha';
var defaultSortDirection = 'desc';
var DeltacarHistoricoService = /** @class */ (function () {
    function DeltacarHistoricoService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
        this.displayedItems = [];
        this.displayedItems['checkedValues'] = 0;
        this.displayedItems['checkedItems'] = Array();
    }
    DeltacarHistoricoService.prototype.generateExcel = function (filter, sortDir, sortField) {
        var start = 0;
        var pageSize = 1000;
        sortField = sortField === undefined ? defaultSortParam : sortField;
        sortDir = sortDir === '' ? defaultSortDirection : sortDir;
        var params = new DeltacarHistoricoFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);
        if (this.authGuard.canActivate()) {
            return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlDeltacarHistoricoExcel'], params);
        }
    };
    DeltacarHistoricoService.ngInjectableDef = i0.defineInjectable({ factory: function DeltacarHistoricoService_Factory() { return new DeltacarHistoricoService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: DeltacarHistoricoService, providedIn: "root" });
    return DeltacarHistoricoService;
}());
export { DeltacarHistoricoService };
