<app-dialog-header *ngIf="this.dataDialog.isDialog" label="Errores Proceso de Notificación" i18n-label></app-dialog-header>

<div class="table-sdw is-fixed">
      
  <mat-table class="adjust" #table [dataSource]="this.dataSource" matSort aria-label="Elements" class="is-header-sticky">

    <ng-container matColumnDef="expediente">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expediente</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.expediente }}</mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="mensaje">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Mensaje</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.mensaje }}</mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="codigoPostal">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Código Postal</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.codigoPostal }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
  </mat-table>
</div>