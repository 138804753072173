import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { Observable } from 'rxjs';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';

@Injectable({
  providedIn: 'root'
})
export class SincronitzacioService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) { }

  actualitzar (completa: boolean): Observable<any> {

    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSincronitzar'], completa ? 1 : 0);
    }
  }
  /**
   * Llamada al backend que obtiene las fechas en las que se sincronizó por última vez las PDAs y DIBA (si no es cliente tipo "DIBA WS" no devuelve esta última fecha).
   * Devuelve un array con las fechas con el orden [PDA, DIBA = null]
   */
  getLastSyncDate() {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlLastSyncDate'], null);
    }
  }
  /**Llamada al backend que sincroniza nuestra BBDD con la de DIBA */
  syncDyba(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSyncDiba'], null);
    }
  }

  /**Llamada al backend que sincroniza nuestra BBDD con la de GTWIN */
  syncGtwin(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSyncGtwin'], null);
    }
  }
}
