/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/dash/dash.component.ngfactory";
import * as i2 from "../components/dash/dash.component";
import * as i3 from "../services/dashboard.service";
import * as i4 from "@ngx-translate/i18n-polyfill";
import * as i5 from "../../../shared/services/notification/notification.service";
import * as i6 from "../../../app.component";
import * as i7 from "../../../shared/services/authorization/authorization-service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../services/matriculas-service.service";
import * as i10 from "../../../shared/services/filter/filter.service";
import * as i11 from "./dashboardpage.component";
var styles_DashboardPageComponent = [];
var RenderType_DashboardPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DashboardPageComponent, data: {} });
export { RenderType_DashboardPageComponent as RenderType_DashboardPageComponent };
export function View_DashboardPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dash", [], null, null, null, i1.View_DashComponent_0, i1.RenderType_DashComponent)), i0.ɵdid(1, 114688, null, 0, i2.DashComponent, [i3.DashboardService, i4.I18n, i5.NotificationService, i6.AppComponent, i7.AuthorizationService, i8.MatDialog, i9.ActuacioMatriculasService, i10.FilterService, i8.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_DashboardPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dashboardpage", [], null, null, null, View_DashboardPageComponent_0, RenderType_DashboardPageComponent)), i0.ɵdid(1, 114688, null, 0, i11.DashboardPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardPageComponentNgFactory = i0.ɵccf("app-dashboardpage", i11.DashboardPageComponent, View_DashboardPageComponent_Host_0, {}, {}, []);
export { DashboardPageComponentNgFactory as DashboardPageComponentNgFactory };
