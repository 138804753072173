/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./photo-box-grua.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i6 from "../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i7 from "../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i8 from "../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i9 from "../../../../shared/components/form/form-file-multiple-upload/form-file-multiple-upload.component.ngfactory";
import * as i10 from "../../../../shared/components/form/form-file-multiple-upload/form-file-multiple-upload.component";
import * as i11 from "../../../../shared/components/dialog/dialog-body/dialog-body.component.ngfactory";
import * as i12 from "../../../../shared/components/dialog/dialog-body/dialog-body.component";
import * as i13 from "../../../../shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component.ngfactory";
import * as i14 from "../../../../shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component";
import * as i15 from "../../../../shared/services/parameters/parameters.service";
import * as i16 from "@angular/material/dialog";
import * as i17 from "../../../../shared/services/images/images.service";
import * as i18 from "@ngx-translate/i18n-polyfill";
import * as i19 from "../../../../shared/components/confirmation-dialog/confirmation-dialog.service";
import * as i20 from "./photo-box-grua.component";
import * as i21 from "../../../denuncies/services/denuncies.service";
import * as i22 from "ngx-toastr";
var styles_PhotoBoxGruaComponent = [i0.styles];
var RenderType_PhotoBoxGruaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhotoBoxGruaComponent, data: {} });
export { RenderType_PhotoBoxGruaComponent as RenderType_PhotoBoxGruaComponent };
function View_PhotoBoxGruaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["label", "Imatges"]], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Imatges"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PhotoBoxGruaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { photoBoxGallery: 0 }), i1.ɵqud(402653184, 2, { fileInput: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoBoxGruaComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["style", "padding-left: 24px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "app-toolbar", [], null, null, null, i5.View_ToolbarComponent_0, i5.RenderType_ToolbarComponent)), i1.ɵdid(6, 114688, null, 0, i6.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "app-tool-button", [["color", "has-color-green"], ["icon", "fa-plus-square"], ["text", "Afegir"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Afegir() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(8, 114688, null, 0, i8.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-form-file-multiple-upload", [["hidden", ""], ["label", "Fitxer"], ["labelHidden", "true"]], null, [[null, "fileReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fileReady" === en)) {
        var pd_0 = (_co.fileReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_FormFileMultipleUploadComponent_0, i9.RenderType_FormFileMultipleUploadComponent)), i1.ɵdid(10, 114688, [[2, 4], ["fileInput", 4]], 0, i10.FormFileMultipleUploadComponent, [], { label: [0, "label"], labelHidden: [1, "labelHidden"] }, { fileReady: "fileReady" }), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "app-tool-button", [["color", "has-color-red"], ["icon", "fa-trash"], ["text", "Eliminar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Eliminar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(12, 114688, null, 0, i8.ToolButtonComponent, [], { disabled: [0, "disabled"], text: [1, "text"], color: [2, "color"], icon: [3, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(13, 0, null, null, 3, "app-dialog-body", [], null, null, null, i11.View_DialogBodyComponent_0, i11.RenderType_DialogBodyComponent)), i1.ɵdid(14, 114688, null, 0, i12.DialogBodyComponent, [], null, null), (_l()(), i1.ɵeld(15, 0, null, 0, 1, "app-photo-box-gallery", [], null, [[null, "notify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("notify" === en)) {
        var pd_0 = (_co.getChildNumSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_PhotoBoxGalleryComponent_0, i13.RenderType_PhotoBoxGalleryComponent)), i1.ɵdid(16, 245760, [[1, 4], ["photoBoxGallery", 4]], 0, i14.PhotoBoxGalleryComponent, [i15.ParametersService, i16.MatDialogRef, i17.ImagesService, i18.I18n, i19.ConfirmationDialogService], { numDenuncia: [0, "numDenuncia"], imatges: [1, "imatges"], allowSelect: [2, "allowSelect"] }, { notify: "notify" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDialog; _ck(_v, 3, 0, currVal_0); _ck(_v, 6, 0); var currVal_1 = "Afegir"; var currVal_2 = "has-color-green"; var currVal_3 = "fa-plus-square"; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "Fitxer"; var currVal_5 = "true"; _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = (_co.numSelected == 0); var currVal_7 = "Eliminar"; var currVal_8 = "has-color-red"; var currVal_9 = "fa-trash"; _ck(_v, 12, 0, currVal_6, currVal_7, currVal_8, currVal_9); _ck(_v, 14, 0); var currVal_10 = _co.numDenuncia; var currVal_11 = _co.images; var currVal_12 = false; _ck(_v, 16, 0, currVal_10, currVal_11, currVal_12); }, null); }
export function View_PhotoBoxGruaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-photo-box-grua", [], null, null, null, View_PhotoBoxGruaComponent_0, RenderType_PhotoBoxGruaComponent)), i1.ɵdid(1, 114688, null, 0, i20.PhotoBoxGruaComponent, [i16.MatDialogRef, i16.MAT_DIALOG_DATA, i21.DenunciesService, i17.ImagesService, i19.ConfirmationDialogService, i22.ToastrService, i18.I18n], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhotoBoxGruaComponentNgFactory = i1.ɵccf("app-photo-box-grua", i20.PhotoBoxGruaComponent, View_PhotoBoxGruaComponent_Host_0, { imagesInput: "imagesInput", isDialog: "isDialog", contenedor: "contenedor", numDenuncia: "numDenuncia" }, {}, []);
export { PhotoBoxGruaComponentNgFactory as PhotoBoxGruaComponentNgFactory };
