import { CFG_TEXTOS_PREDEFINIDOS } from './../../../../../../../../models/CFG_TEXTOS_PREDEFINIDOS';
import { TextosPredefinidosComponent } from './../../../../../../../configuracio/textos-predefinidos/pages/textos-predefinidos/textos-predefinidos.component';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { FormService } from 'path-shared/services/form/form.service';
import { OperacionAlegacionDto } from 'app/sections/denuncies/models/operacionAlegacionDto';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-form-alegaciones',
  templateUrl: './form-alegaciones.component.html',
  styleUrls: ['./form-alegaciones.component.scss']
})
export class FormAlegacionesComponent implements OnInit {

  dto = new OperacionAlegacionDto();
  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    private i18n: I18n,
    private toastr: ToastrService,
    private formService: FormService,
    private dialog: MatDialog,

  ) {
  }

  ngOnInit() {
    
  }  

  public correcte(): boolean {
    if (!this.formService.allFieldsValid(this.mainForm)) {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
      return false;
    } else {
      return true;
    }

  }

  buscarAlegacions(){
     // Carraguem la taula filtrada per dni
     const data: ComponentDialog = Builder.newComponentDialog(true, false, '', null);
     const ref = this.dialog.open(TextosPredefinidosComponent, {
       width: '80%',
       height: '90%',
       closeOnNavigation: false,
       autoFocus: true,
       data: data
     });

     ref.afterClosed().subscribe(async resp => {
      var texto: CFG_TEXTOS_PREDEFINIDOS = resp;
      if(this.dto.alegacion !== undefined && this.dto.alegacion !== null && this.dto.alegacion !== ''){
        this.dto.alegacion =  this.dto.alegacion +' '+texto.DESCRIPCION;
      }else{
        this.dto.alegacion = texto.DESCRIPCION;
      }
      
     });
  }

}
