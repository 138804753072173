import { QrViewerComponent } from 'path-shared/components/helpers/qr-viewer/qr-viewer.component';
import { TemporadaDto } from './../../../../temporada/models/temporadaDto';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Tramo } from '../../models/tramo.model';
import { TramosFilter } from '../../models/tramo-filter';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { TramosService } from '../../services/tramos.service';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { TramosFilterService } from '../../services/tramos-filter.service';
import { BehaviorSubject, merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { Instituciones } from 'path-shared/models/instituciones';
import { Parametros } from 'path-shared/models/parametros';
import { ChipListElement } from 'path-shared/components/form/chips-list-input/chip-list-element';
import { KeyCounter } from 'path-shared/models/keycounter';
import { TemporadaService } from 'app/sections/zona-blava/estacionament/temporada/services/temporada.service';

@Component({
  selector: 'app-tramos-table',
  templateUrl: './tramos-table.component.html',
  styleUrls: ['./tramos-table.component.scss']
})
export class TramosTableComponent implements OnInit {
  CONST_ID_ALL = '-999';

  temporadas: TemporadaDto[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  map: any;
  data: Tramo[];
  dataSource: CustomDataSource<Tramo, TramosFilter>;
  filter: TramosFilter = new TramosFilter('descripcion', 'asc');
  displayedItems = [];
  highlightedRows = [];

  @Input() selectButton: boolean;
  @Input() updatedFilterData: TramosFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  zoomMapa: number = 0;


  temporadasSource: BehaviorSubject<ChipListElement[]>;

  temporadasCounters: KeyCounter[];

  displayedColumns = ['incorpora', 'Som_id', 'numPlazas', 'descripcio','temporada', 'zona', 'colorZona','qr'];
  public currentLocation: {
    latitude: number,
    longitude: number
  };
  constructor(  private tramosService: TramosService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n,
    private parametersService: ParametersService,
    private temporadasService: TemporadaService,) {
     this.temporadasCounters = KeyCounter.create([]);
    this.temporadasSource = new BehaviorSubject(undefined);
      const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      const institution: Instituciones = JSON.parse(inst);
      this.currentLocation = {latitude: institution.Latitud, longitude: institution.Longitud};
    }
     }

    ngOnInit() {
      this.temporadasService.GetAllForCombo().subscribe(result => {
      this.temporadas = result;
      this.temporadas.forEach(temp => {
        this.temporadasCounters.push(new KeyCounter(temp.Som_id.toString()));
      });
      this.temporadasSource.next(this.getTemporadas());
    });

      this.sort.disableClear = true;
      this.zoomMapa = Number.parseInt(this.parametersService.getParameter(Parametros.GPSZOMINICIAL));
      this.dataSource = CustomDataSource.create<Tramo, TramosFilter>(this.paginator, new TramosFilterService(), 'urlGetTramos', 'urlGetTramosCount');
      this.dataSource.subject.subscribe((data) => {
        data.forEach(item => {
          this.displayedItems[item.secureId] = {
            id: item.secureId
          };
        });
      });
  
      this.filter.filterUpdated.subscribe(updated => {
        if (updated) {
          this.load();
          if (!this.selectButton) { this.filterService.saveFilter('tramos-filter', this.filter); }
        }
      });
  
      if (!this.selectButton) { this.filterService.loadFilter('tramos-filter', this.filter); }
      this.filter.paginator.init(this.sort, this.paginator);
    }
    getTemporadas() {
      let countersTotals = 0;
      const tiposValidos = new Array<ChipListElement>();
      if (this.temporadas !== undefined) {
        this.temporadas.forEach(element => {
          //this.temporadasService.getTramosByTemporadaCount(element.secureId).subscribe(x =>{
            const counter: number = element.numeroTramos;
              if (counter !== undefined && counter !== null && counter > 0) {
                tiposValidos.push(new ChipListElement(element.Som_id, element.descripcion, counter));
                countersTotals += counter;
              }
            //});
          });
      }
      tiposValidos.push(new ChipListElement(this.CONST_ID_ALL, this.i18n('Totes'), countersTotals));
      return tiposValidos;
    }

    

    temporadaSeleccionado(option: ChipListElement) {
      if (option !== undefined && option.key !== undefined) {
        if (option.key === this.CONST_ID_ALL) {
          this.filter.temporadaSomId = null;
          this.updatedFilterData.temporadaSomId = null;
          //this.selectedAll = true;
        } else {
          this.filter.temporadaSomId = option.key;
          this.updatedFilterData.temporadaSomId = option.key;
        }
        this.update();
      }
    }
    ngAfterViewInit() {
      // reset the paginator after sorting
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.filter.paginator.set(this.sort, this.paginator);
        this.filter.updated();
      });
  
      merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();

      this.load();
    }
    ngOnChanges(changes: SimpleChanges) {
      if (this.updatedFilterData != null) {
        this.update();
      }
    }

    update() {
      if (this.dataSource !== undefined) {
        this.updatedFilterData.paginator.set(this.sort, this.paginator);
        this.dataSource.loadFilter(this.updatedFilterData);
      }
    }

    load() {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }

    onRowClicked(row) {
      this.itemClicked.next(row.secureId);
    }
    selectRow(row: Tramo){
      this.currentLocation  = {latitude: row.initialPosition.lat, longitude: row.initialPosition.lng};
      if(row.strokeColor === '#000000' ){
        row.strokeColor = row.zona.color;
      }else{
        row.strokeColor = '#000000';
      }
    }
    generateExcel(): void {
      let filter = this.filter;
      if (this.updatedFilterData != null) {
        filter = this.updatedFilterData;
      }
      this.dialog.open(DownloadExcelDialogComponent, {
          closeOnNavigation: false,
          autoFocus: true,
          data: Builder.newDownloadExcelDialogData (this.tramosService, filter, this.i18n('Trams'), this.sort.direction, this.sort.active)
      });
    }
    selectPoligono(row: Tramo){
      if(row.Selected === undefined || row.Selected === null){
        row.Selected = true;
      }else{
        row.Selected = !row.Selected;
      }
      this.currentLocation  = {latitude: row.initialPosition.lat, longitude: row.initialPosition.lng};
      if(row.strokeColor === '#000000' ){
        row.strokeColor = row.zona.color;
      }else{
        row.strokeColor = '#000000';
      }
    }

    viewQR(somId: number){
      let xmlData='';
      const tramo = this.dataSource.getData().find(x => x.Som_id === somId);
    //   xmlData='<temporada>'+tramo.temporada.Som_id+'</temporada>'+'<zona>'+tramo.zona.SOM_ID+'</zona>'+'<poligon>'+tramo.Som_id+'</poligon>';
    let tramoqr = Builder.newTramoQr();
    tramoqr.PoligonoSomId = tramo.Som_id.toString();
    tramoqr.ZonaSomId = tramo.zona.SOM_ID.toString();
    xmlData = JSON.stringify(tramoqr); 
    
      this.dialog.open(QrViewerComponent,
        {
          minWidth: '30%',
          maxWidth: '90%',
          maxHeight: '70%',
          closeOnNavigation: false,
          autoFocus: true,
          data: {'qrData': xmlData,'fileName': tramo.descripcion ,'isDialog': true},
        });
    }

}
