import { Component, OnInit, Inject, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MultipleDatePickerComponent } from 'multiple-date-picker-angular/dist';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-calendar-multiselect-input-modal',
  templateUrl: './calendar-multiselect-input-modal.component.html',
  styleUrls: ['./calendar-multiselect-input-modal.component.scss']
})

export class CalendarMultiselectInputModalComponent implements OnInit, OnDestroy, AfterViewInit {

  value: Date[] = [];

  modalData: any;

  constructor(
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CalendarMultiselectInputModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  @ViewChild('picker') calendarInput: MultipleDatePickerComponent;

  ngOnInit(): void {
    this.modalData = this.data;
    const topPos = Math.min(window.innerHeight - 290, this.modalData.top);
    this.dialogRef.updatePosition({ top: `${topPos}px`,
    left: `${this.modalData.left}px`});
    this.value = this.data.initData;
  }

  ngOnDestroy(): void {
    this.dialogRef.close(this.value);
  }

  ngAfterViewInit(): void {
    this.calendarInput.daysOfWeek = this.DiesSetmana();
    this.calendarInput.monthToDisplay = this.Mes();
    const test = this;
    this.calendarInput.changeMonth = function(event, disable, add) {
      if (disable) {
          return;
      }
      event.preventDefault();
      let prevented = false;
      event.preventDefault = () => {
          // console.log('entered into preventDefault *****'); // for testing
          prevented = true;
      };
      const monthTo = moment(this.month).add(add, 'month');
      if (!prevented) {
          this.month = monthTo;
          this.generate();
          this.monthToDisplay = test.Mes(monthTo);
      }
    };
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  DiesSetmana() {
    /*To display days of week names in moment.lang*/
    const momentDaysOfWeek = moment().locale('ca').localeData().weekdaysMin();
    const daysOfWeek = [];
    for (let i = 1; i < 7; i++) {
        daysOfWeek.push(momentDaysOfWeek[i]);
    }
    if (this.calendarInput.sundayFirstDay) {
        daysOfWeek.splice(0, 0, momentDaysOfWeek[0]);
    } else {
        daysOfWeek.push(momentDaysOfWeek[0]);
    }
    return daysOfWeek;
  }

  Mes(mes:any = undefined) {
    if (mes === undefined) {
      const month = moment().locale('ca').startOf('day').format('MMMM');
      return month.charAt(0).toUpperCase() + month.slice(1);
    } else {
      const month = mes.locale('ca').format('MMMM');
      return month.charAt(0).toUpperCase() + month.slice(1);
    }
  }


}
