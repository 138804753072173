<ng-template #myDialogC60 class="col-12">
    <!-- <app-input-progress-bar [(value)]="this.porcentajeTramitados"  class="col-12" i18n-label label="Progrés"></app-input-progress-bar> -->
    <div *ngIf="this.porcentajeC60 !== null" class="d-flex justify-content-center">
      <div>
        <h2> <span i18n >Creando C60 {{this.mensajePorcentajeC60}} </span></h2> 
      </div>
    </div>
    <section *ngIf="this.porcentajeC60 !== null" class="progress-bar-section">
        <div class="col-12">
            <mat-progress-bar mode="determinate" value={{this.porcentajeC60}}></mat-progress-bar>
        </div>
        
        
    </section>
    <div *ngIf="this.porcentajeC60 !== null" class="d-flex justify-content-center">
      <div style=" color:  #1E457C;" >
        <div> 
          <h3> {{this.porcentajeC60 | number:'1.2-2'}}% </h3>
        </div>    
      </div>
    </div>
  </ng-template>
  

<app-envio-boe-page [(value)]="this.filter" (applyFilter)="this.filtrar()" (publish)="this.publish()" [resumen]="this.resumen"></app-envio-boe-page>
<app-envio-boe-table [value]="this.denuncias"></app-envio-boe-table>