import { OnInit, AfterViewInit, EventEmitter } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { TiquetsClientFilter } from '../tiquets-client-filter/tiquets-client-filter';
import { TiquetsClientFilterService } from '../../services/tiquets-client-filter.service';
import { TiquetsClientService } from '../../services/tiquets-client.sevice';
var TiquetsClientTableComponent = /** @class */ (function () {
    function TiquetsClientTableComponent(tiquetsService, dialog, i18n) {
        this.tiquetsService = tiquetsService;
        this.dialog = dialog;
        this.i18n = i18n;
        this.currentFilter = new TiquetsClientFilter('id', 'desc'); // Filtro concreto para esta tabla.
        this.highlightedRows = [];
        this.displayedColumns = ['tiquet', 'matricula', 'ZONA_TICKET', 'email', 'importTiquet', 'FECHA_TICKET', 'FECHA_FIN_TICKET', 'MINUTOS_DIF', 'ID_EXTERNO', 'PROVEEDOR_EXTERNO'];
        this.currentCount = new EventEmitter();
    }
    Object.defineProperty(TiquetsClientTableComponent.prototype, "sharedFilter", {
        get: function () {
            return this._sharedFilter;
        },
        set: function (val) {
            if (val !== undefined && val !== null) {
                this._sharedFilter = val;
                this.currentFilter.id = val.id;
                this.currentFilter.matricula = val.matricula;
                this.currentFilter.email = val.email;
                this.currentFilter.fechaTicketInicio = val.fechaTicketInicio;
                this.currentFilter.fechaTicketFin = val.fechaTicketFin;
                this.currentFilter.zona = val.zona;
                this.currentFilter.proveidors = val.proveidors;
            }
        },
        enumerable: true,
        configurable: true
    });
    //#region CICLO DE VIDA
    TiquetsClientTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sort.disableClear = true;
        this.dataSource = CustomDataSource.create(this.paginator, new TiquetsClientFilterService(), 'urlGetTiquetsClient', 'urlGetTiquetsClientCount');
        this.dataSource.countSubject.subscribe(function (newCount) {
            _this.currentCount.emit(newCount);
        });
    };
    TiquetsClientTableComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // reset the paginator after sorting
        this.sort.sortChange.subscribe(function () {
            _this.paginator.pageIndex = 0;
            _this.load();
        });
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(tap(function () { return _this.currentFilter.updated(); }))
            .subscribe();
        this.load();
    };
    //#endregion
    //#region EVENTOS
    TiquetsClientTableComponent.prototype.paginatorChanged = function (value) {
        // Actualizamos la informacion al detectar un cambio en la paginacion.
        this.load();
    };
    TiquetsClientTableComponent.prototype.updateFilterFromParent = function (val) {
        // Cuando el componente parent nos pasa un nuevo filtro compartido, actualizamos.
        this.sharedFilter = val;
        this.load();
    };
    //#endregion
    //#region OTHERS
    TiquetsClientTableComponent.prototype.load = function () {
        this.currentFilter.paginator.set(this.sort, this.paginator);
        this.dataSource.loadFilter(this.currentFilter, this.i18n('No s\'han trobat registres per tiquets'));
    };
    TiquetsClientTableComponent.prototype.generateExcel = function () {
        var filter = this.currentFilter;
        this.dialog.open(DownloadExcelDialogComponent, {
            closeOnNavigation: false,
            autoFocus: true,
            data: Builder.newDownloadExcelDialogData(this.tiquetsService, filter, this.i18n('TiquetsClient'), this.sort.direction, this.sort.active)
        });
    };
    return TiquetsClientTableComponent;
}());
export { TiquetsClientTableComponent };
