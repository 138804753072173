
export class Reflection {

  public static deepCopy<T>(obj) {
    const ret = {} as T;
    for (const property of Object.getOwnPropertyNames(obj)) {
      ret[property] = obj[property];
    }
    return ret;
  }

  public static copy(source, dest) {
    for (const property of Object.getOwnPropertyNames(source)) {
      if (dest[property] !== source[property]) {
        dest[property] = source[property];
      }
    }
  }

  public static isNull(obj) {
    return obj === null || obj === undefined;
  }

  public static empty(obj) {
    if (Reflection.isNull(obj)) {
      return true;
    } else {
      return obj.length === 0;
    }
  }

  public static hasValue(obj) {
    return !Reflection.empty(obj);
  }

}
