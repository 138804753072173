<div class="form-group">
  <mat-form-field>
    <mat-label>{{this.label}}</mat-label>
    
    <input #inputEdit matInput name="inputCurrency" type="text" [placeholder]="this.label" 
      [ngModel]="this.validator.isNumeric(this.value) ? (this.value | currency:'EUR') : this.value"
      [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="this.value=valueChangeCurrency($event)"
      [disabled]="this.disabled" [required]="this.isRequired()"
      [readonly]="this.isReadOnly" [ngClass]="[this.validVal ? 'ng-valid' : 'ng-invalid']" [tabIndex]="this.tabindex">
  </mat-form-field>

  <div #divEditCurrency *ngIf="!this.validVal" class="ng-invalid text-danger alert-field-errors">
      <div *ngIf="this.errorRequired()" i18n>Aquest camp és obligatori!</div>
      <div *ngIf="this.errorCurrency()" i18n>Aquest camp ha de ser un número sencer o decimal vàlid!</div>
  </div>
</div>
