import { AuthorizationService } from './../../../../shared/services/authorization/authorization-service';
import { Component, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { NotificationService } from 'path-shared/services/notification/notification.service';
// import { TiquetsFilterComponent } from '../component/tiquets-filter/tiquets-filter.component';
import { MatDialog, MatDialogRef, MatPaginator, MatSort, MAT_DIALOG_DATA } from '@angular/material';
// import { TiquetsTiquetsTableComponent } from '../component/tiquets-table/tiquets-tiquets-table.component';
// import { TiquetsFilter } from '../models/tiquets-filter';
import { FormControl } from '@angular/forms';
// import { TiquetsDevolucionesTableComponent } from '../component/tiquets-devoluciones-table/tiquets-devoluciones-table.component';
// import { TiquetsAnulacionesTableComponent } from '../component/tiquets-anulaciones-table/tiquets-anulaciones-table.component';
// import { TiquetsService } from '../services/tiquets.service';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { SomPolGruaFilter } from '../../Models/som-pol-grua-filter';
import { TramitadesTableComponent } from '../tramitades-table/tramitades-table.component';
import { TramitadesFilterComponent } from '../tramitades-filter/tramitades-filter.component';

@Component({
  selector: 'app-tramitades',
  templateUrl: './tramitades.component.html',
  styleUrls: ['./tramitades.component.scss']
})
export class TramitadesComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selected = new FormControl(0);
  filter: SomPolGruaFilter = new SomPolGruaFilter('', '');
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild('tramitades') TramitadesTable: TramitadesTableComponent;

  constructor( public authService: AuthorizationService,
    private not: NotificationService,
    private dialog: MatDialog,
    private i18n: I18n,
    public thisDialogRef: MatDialogRef<TramitadesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog, ) {
      this.filter = new SomPolGruaFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit() {
    this.authService.CheckUserPermission(this.authService.Pages.TramitadesGrua);
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Grua'));
    }

    this.TramitadesTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }
  onFilterClicked() {
    const ref = this.dialog.open(TramitadesFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.TramitadesTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: TramitadesFilterComponent) => {
      this.TramitadesTable.filter.set(filter);
      this.TramitadesTable.filter.updated();
      this.TramitadesTable.load();
    });
  }
  refresh() {
    this.TramitadesTable.load();
  }
generateExcel() {
    this.TramitadesTable.generateExcel();
  }

}

