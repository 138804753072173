<div class="form-group mat-chip-list">
  <div class="mat-chip-list-title">
      <span class="col-12">
          {{this.label}}
      </span>
  </div>
  <mat-chip-list [multiple]="false" >
      <mat-chip *ngFor="let option of this.valuesList;" 
        [selectable]="this.isSelectable(option)"
        [matTooltip]="option.counter" [matTooltipDisabled]="option.counter < 1000"
        selected="{{ this.isSelected(option.key) }}" (click)="this.handleClick(option.key)" style="font-size: smaller;">
          {{option.value}}
          <mat-icon *ngIf="option.counter !== '' && option.counter !== '0'" [inline]="true" style="padding-left: 20px; margin-left: 1vh;" [matBadge]="option.counter" 
          matBadgePosition="above after" >
          </mat-icon>
      </mat-chip>
  </mat-chip-list>
</div>
