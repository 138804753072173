import { ExcelGenerator } from './../../../shared/interfaces/excel-generator';
import { Injectable } from '@angular/core';
import { Proposta } from '../models/proposta.model';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { HttpSecureClient } from '../../../shared/services/http/httpSecureClient';
import { PropostaFilter } from '../models/proposta.filter';
import { PaginationFilter } from '../../../shared/models/pagination.filter';
import { AuthGuardService } from '../../../shared/services/authentication/auth-guard.service';

import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { PropostesFilterService } from './propostes-filter.service';

const defaultSortParam = 'Exp';
const defaultSortDirection = 'desc';

@Injectable()
export class PropostesService implements ExcelGenerator {
  displayedItems = [];
  constructor(
    private http: HttpSecureClient,
    private authGuard: AuthGuardService
  ) {
    this.displayedItems['checkedValues'] = 0;
  }

  findPropostes(
    filter: PropostaFilter,
    paginator: PaginationFilter
  ): Observable<Proposta[]> {
    const start = paginator.pageNumber * paginator.pageSize;
    const params = new HttpParams()
    .append('proposta', filter.proposta)
    .append('pais', filter.pais ? filter.pais : '')
    .append('tipus_proposta', filter.tipusproposta ? filter.tipusproposta : '')
    .append('start', start.toString())
    .append('size', paginator.pageSize.toString())
    .append(
      'sortparam',
      paginator.sortField === undefined
        ? defaultSortParam
        : paginator.sortField
    )
    .append(
      'sortdir',
      paginator.sortDir === '' ? defaultSortDirection : paginator.sortDir
    );

  if (this.authGuard.canActivate()) {
    return this.http
      .get(config.urls['apiUrl'] + api.endpoints['urlpropostesquery'], params)
      .pipe(map(res => res['payload']));
  }
}

  getTipusPropostes(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlGetTipusPropostes'];
      return this.http.get(url, null);
    }
  }

ValidarMatriculaAutorizada(matricula, secureId): Observable<any> {
  if (this.authGuard.canActivate()) {
    const url = config.urls['apiUrl'] + api.endpoints['urlPropostesValidarMatriculaAutorizada'].replace('{matricula}', matricula).replace('{secureId}', secureId);
    return this.http.get(url, null);
  }
}

acceptProposta(num, userId): Observable<any> {
  if (this.authGuard.canActivate()) {
    const url = config.urls['apiUrl'] + api.endpoints['urlPropostesAccept'] + '/' + num + '/' + userId;
      return this.http.post(url, null);
    }
  }

  rejectProposta(num, rejectData): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlPropostesReject'] + '/' + num;

      return this.http.post(url, rejectData);
    }
  }
  rejectPropostes(rejectData): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlPropostesReject'];

      return this.http.post(url, rejectData);
    }
  }

  anulDesestPropostes(rejectData): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlPropostesAnulDesest'];

      return this.http.post(url, rejectData);
    }
  }

  removeImages(num, imatgesIds: string[]): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlPropostesRemoveImages'].replace('{secure_id}', num);

      return this.http.post(url, imatgesIds);
    }
  }

  getImages(num): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url =
        config.urls['apiUrl'] +
        api.endpoints['urlPropostesImages'].replace('{num}', num);
      return this.http.get(url, null);
    }
  }
  
  getImagesByNumDenInstit(num): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url =
        config.urls['apiUrl'] + api.endpoints['urldenunciesImagesNumDenIns'];
        const params = new HttpParams().append('somId', num);
        return this.http.get(url, params);
    }
  }

  getCounters(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlPropostesCounters'];
      return this.http.get(url, null);
    }
  }

  getTipusCounters(filter: PropostaFilter): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new HttpParams()
      .append('proposta', filter.proposta)
      .append('pais', filter.pais ? filter.pais : '')
      .append('tipus_proposta', filter.tipusproposta);

      const url =
        config.urls['apiUrl'] +
        api.endpoints['urlPropostesTipusCounters'];
      return this.http.get(url, params);
    }
  }

  getPaisosCounters(filter: PropostaFilter): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new HttpParams()
      .append('proposta', filter.proposta)
    //    .append('pais', filter.pais ? filter.pais : '')
      .append('tipus_proposta', filter.tipusproposta);

      const url =
        config.urls['apiUrl'] +
        api.endpoints['urlPropostesPaisosCounters'];
      return this.http.get(url, params);
    }
  }
  getRejectSubjects(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlPropostesMotiusDesestimar'];
      return this.http.get(url, null);
    }
  }

  canviMatricula(secure_id, matricula): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlPropostesCanviMatricula'].replace('{secure_id}', secure_id);
      return this.http.postJSON(url, {'Matricula': matricula});
    }

  }

  generateExcel(filter: PropostaFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0; // paginator.pageNumber * paginator.pageSize;
    const pageSize = 1000;
    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new PropostesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlPropostesExcel'],  params );
    }
  }

  saveProposta(proposta: Proposta) {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlPropostesSave'].replace('{secure_id}', proposta.SECURE_ID), proposta);
    }
  }

  notificadaEnMa(info: any) {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlPropostesNotificadaEnMa'], info);
    }
  }

  getPropostesRelacionades(proposta: Proposta): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlGetPropostesRelacionades'].replace('{secure_id}', proposta.SECURE_ID);
      return this.http.get(url, null);
    }
  }

  getPropostaReincidencies(proposta: Proposta): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlGetPropostaReincidencies'].replace('{secure_id}', proposta.SECURE_ID);
      return this.http.get(url, null);
    }
  }

  getAllTipusPropostes(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlPropostesTipus'], null);
    }
  }

}
