/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reports.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../dialog/dialog-header/dialog-header.component";
import * as i4 from "@angular/common";
import * as i5 from "./reports.component";
import * as i6 from "@angular/material/dialog";
var styles_ReportsComponent = [i0.styles];
var RenderType_ReportsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportsComponent, data: {} });
export { RenderType_ReportsComponent as RenderType_ReportsComponent };
function View_ReportsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.SelectReport(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.nom; _ck(_v, 2, 0, currVal_0); }); }
export function View_ReportsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["label", "Seleccionar report"]], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "reports"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsComponent_1)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Seleccionar report"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.reports; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ReportsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reports", [], null, null, null, View_ReportsComponent_0, RenderType_ReportsComponent)), i1.ɵdid(1, 114688, null, 0, i5.ReportsComponent, [i6.MAT_DIALOG_DATA, i6.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportsComponentNgFactory = i1.ɵccf("app-reports", i5.ReportsComponent, View_ReportsComponent_Host_0, {}, {}, []);
export { ReportsComponentNgFactory as ReportsComponentNgFactory };
