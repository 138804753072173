<div class="row justify-content-between">
  <div class="col">
      <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  <div class="col">
      <!--<mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>-->
      <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

      <ng-container matColumnDef="incorpora" *ngIf="this.selectButton">
          <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-center"><a (click)="this.selectCurrent(row.matricula, row.SECURE_ID, row.marca, row.model, row.pais, row.paisDesc, row.color)" title="Incorporar" i18n-title><i class="fa fa-plus-square has-color-aqua"></i></a></mat-cell>
      </ng-container>

      <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>ID</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.id_vehicles}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="matricula">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matricula</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link">{{row.matricula}}</a></mat-cell>
      </ng-container>

      <ng-container matColumnDef="marca">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Marca</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.marca}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="model">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Model</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.model}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="color">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Color</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.color}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="asseguranca">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Assegurança</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.asseguranca}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="poliza">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Polissa</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.poliza}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="proposta">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Proposta</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.proposta}}</mat-cell>
    </ng-container>
      
      <ng-container matColumnDef="requisitoria">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Requisitoria</mat-header-cell>
          <mat-cell *matCellDef="let row">
              <mat-checkbox [checked]="row.requisitoria" [disabled]='true'></mat-checkbox>
          </mat-cell>
      </ng-container>
 
      <ng-container matColumnDef="observacions">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Observacions</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.observacions}}</mat-cell>
    </ng-container>
 
    <ng-container matColumnDef="origen">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Origen</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.origen}}</mat-cell>
  </ng-container>
   
    <ng-container matColumnDef="tipus_vehicle">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipus vehicle</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.tipus_vehicle}}</mat-cell>
  </ng-container>
 

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div> 