import { AparellTipusFilter } from './../models/aparellTipus-filter';
import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = 'descTipus';
const defaultSortDirection = 'asc';

@Injectable()
export class AparellTipusFilterService extends CustomFilterService {
  public createFilterParams(filter: AparellTipusFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('id', filter.id === null ? '' : filter.id.toString())
    .append('codiTipus', filter.codiTipus)
    .append('descTipus', filter.descTipus)
    .append('numLocalitzacions', filter.numLocalitzacions === null ? '' : filter.numLocalitzacions.toString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
