/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./incidencies-images.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i4 from "../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i5 from "../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i6 from "../../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i7 from "../../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i8 from "../../../../../shared/components/form/form-file-multiple-upload/form-file-multiple-upload.component.ngfactory";
import * as i9 from "../../../../../shared/components/form/form-file-multiple-upload/form-file-multiple-upload.component";
import * as i10 from "../../../../../shared/components/dialog/dialog-body/dialog-body.component.ngfactory";
import * as i11 from "../../../../../shared/components/dialog/dialog-body/dialog-body.component";
import * as i12 from "../../../../../shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component.ngfactory";
import * as i13 from "../../../../../shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component";
import * as i14 from "../../../../../shared/services/parameters/parameters.service";
import * as i15 from "@angular/material/dialog";
import * as i16 from "../../../../../shared/services/images/images.service";
import * as i17 from "@ngx-translate/i18n-polyfill";
import * as i18 from "../../../../../shared/components/confirmation-dialog/confirmation-dialog.service";
import * as i19 from "./incidencies-images.component";
import * as i20 from "../../../../denuncies/services/denuncies.service";
import * as i21 from "ngx-toastr";
var styles_IncidenciesImagesComponent = [i0.styles];
var RenderType_IncidenciesImagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IncidenciesImagesComponent, data: {} });
export { RenderType_IncidenciesImagesComponent as RenderType_IncidenciesImagesComponent };
export function View_IncidenciesImagesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { photoBoxGallery: 0 }), i1.ɵqud(402653184, 2, { fileInput: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-dialog-header", [["label", "Imatges"]], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(3, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "app-toolbar", [], null, null, null, i4.View_ToolbarComponent_0, i4.RenderType_ToolbarComponent)), i1.ɵdid(5, 114688, null, 0, i5.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "app-tool-button", [["color", "has-color-green"], ["icon", "fa-plus-square"], ["text", "Afegir"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Afegir() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ToolButtonComponent_0, i6.RenderType_ToolButtonComponent)), i1.ɵdid(7, 114688, null, 0, i7.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "app-form-file-multiple-upload", [["hidden", ""], ["label", "Fitxer"], ["labelHidden", "true"]], null, [[null, "fileReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fileReady" === en)) {
        var pd_0 = (_co.fileReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormFileMultipleUploadComponent_0, i8.RenderType_FormFileMultipleUploadComponent)), i1.ɵdid(9, 114688, [[2, 4], ["fileInput", 4]], 0, i9.FormFileMultipleUploadComponent, [], { label: [0, "label"], labelHidden: [1, "labelHidden"] }, { fileReady: "fileReady" }), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "app-tool-button", [["color", "has-color-red"], ["icon", "fa-trash"], ["text", "Eliminar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Eliminar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ToolButtonComponent_0, i6.RenderType_ToolButtonComponent)), i1.ɵdid(11, 114688, null, 0, i7.ToolButtonComponent, [], { disabled: [0, "disabled"], text: [1, "text"], color: [2, "color"], icon: [3, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(12, 0, null, null, 3, "app-dialog-body", [], null, null, null, i10.View_DialogBodyComponent_0, i10.RenderType_DialogBodyComponent)), i1.ɵdid(13, 114688, null, 0, i11.DialogBodyComponent, [], null, null), (_l()(), i1.ɵeld(14, 0, null, 0, 1, "app-photo-box-gallery", [], null, [[null, "notify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("notify" === en)) {
        var pd_0 = (_co.getChildNumSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_PhotoBoxGalleryComponent_0, i12.RenderType_PhotoBoxGalleryComponent)), i1.ɵdid(15, 245760, [[1, 4], ["photoBoxGallery", 4]], 0, i13.PhotoBoxGalleryComponent, [i14.ParametersService, i15.MatDialogRef, i16.ImagesService, i17.I18n, i18.ConfirmationDialogService], { numDenuncia: [0, "numDenuncia"], imatges: [1, "imatges"], magnifierRight: [2, "magnifierRight"], magnifierTop: [3, "magnifierTop"], showLupa: [4, "showLupa"] }, { notify: "notify" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Imatges"; _ck(_v, 3, 0, currVal_0); _ck(_v, 5, 0); var currVal_1 = "Afegir"; var currVal_2 = "has-color-green"; var currVal_3 = "fa-plus-square"; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "Fitxer"; var currVal_5 = "true"; _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = (_co.numSelected == 0); var currVal_7 = "Eliminar"; var currVal_8 = "has-color-red"; var currVal_9 = "fa-trash"; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8, currVal_9); _ck(_v, 13, 0); var currVal_10 = _co.contenedor; var currVal_11 = _co.images; var currVal_12 = 25; var currVal_13 = 100; var currVal_14 = false; _ck(_v, 15, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }, null); }
export function View_IncidenciesImagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-incidencies-images", [], null, null, null, View_IncidenciesImagesComponent_0, RenderType_IncidenciesImagesComponent)), i1.ɵdid(1, 114688, null, 0, i19.IncidenciesImagesComponent, [i15.MatDialogRef, i15.MAT_DIALOG_DATA, i20.DenunciesService, i16.ImagesService, i18.ConfirmationDialogService, i21.ToastrService, i17.I18n], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IncidenciesImagesComponentNgFactory = i1.ɵccf("app-incidencies-images", i19.IncidenciesImagesComponent, View_IncidenciesImagesComponent_Host_0, {}, {}, []);
export { IncidenciesImagesComponentNgFactory as IncidenciesImagesComponentNgFactory };
