import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import * as i0 from "@angular/core";
import * as i1 from "../../services/authentication/auth-guard.service";
import * as i2 from "../../services/authorization/authorization-service";
import * as i3 from "../../services/http/httpSecureClient";
var PermissionService = /** @class */ (function () {
    function PermissionService(authGuard, authService, http) {
        this.authGuard = authGuard;
        this.authService = authService;
        this.http = http;
    }
    PermissionService.prototype.getPermission = function (code) {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlGetPermissionItems'].replace('{code}', code.toString());
            return this.http.get(url, null);
        }
    };
    PermissionService.prototype.setPermission = function (item) {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlSetPermissionItems'];
            return this.http.put(url, JSON.stringify(item));
        }
    };
    PermissionService.prototype.deletePermission = function (item) {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlDeletePermissionItems'].replace('{secure_id}', item.SECURE_ID);
            return this.http.delete(url, null);
        }
    };
    PermissionService.prototype.getGroups = function () {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlGetGroups'];
            return this.http.get(url, null);
        }
    };
    PermissionService.prototype.getUsers = function () {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlGetUsers'];
            return this.http.get(url, null);
        }
    };
    PermissionService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.inject(i1.AuthGuardService), i0.inject(i2.AuthorizationService), i0.inject(i3.HttpSecureClient)); }, token: PermissionService, providedIn: "root" });
    return PermissionService;
}());
export { PermissionService };
