import { Component, OnInit, Input, Output, ViewChild, EventEmitter, SimpleChanges } from '@angular/core';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { MatPaginator, MatDialog, MatSort } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TramoTipo } from '../../models/tramo-tipo.model';
import { TramosTiposFilter } from '../../models/tramos-tipos-filter';
import { TramosTiposService } from '../../services/tramos-tipos.service';
import { TramosTiposFilterService } from '../../services/tramos-tipos-filter.service';

@Component({
  selector: 'app-tramos-tipos-table',
  templateUrl: './tramos-tipos-table.component.html',
  styleUrls: ['./tramos-tipos-table.component.scss']
})
export class TramosTiposTableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: TramoTipo[];
  dataSource: CustomDataSource<TramoTipo, TramosTiposFilter>;
  filter: TramosTiposFilter = new TramosTiposFilter('descripcion', 'asc');
  displayedItems = [];
  highlightedRows = [];

  @Input() selectButton: boolean;
  @Input() updatedFilterData: TramosTiposFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = ['Som_id', 'descripcio', 'color'];

  constructor(  private tramosTiposService: TramosTiposService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n) { }

    ngOnInit() {
      this.sort.disableClear = true;
      this.dataSource = CustomDataSource.create<TramoTipo, TramosTiposFilter>(this.paginator, new TramosTiposFilterService(), 'urlGetTramosTipos', 'urlGetTramosTiposCount');
      this.dataSource.subject.subscribe((data) => {
        data.forEach(item => {
          this.displayedItems[item.secureId] = {
            id: item.secureId
          };
        });
      });
  
      this.filter.filterUpdated.subscribe(updated => {
        if (updated) {
          this.load();
          if (!this.selectButton) { this.filterService.saveFilter('tramos-tipos-filter', this.filter); }
        }
      });
  
      if (!this.selectButton) { this.filterService.loadFilter('tramos-tipos-filter', this.filter); }
      this.filter.paginator.init(this.sort, this.paginator);
    }
  
    ngAfterViewInit() {
      // reset the paginator after sorting
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.filter.paginator.set(this.sort, this.paginator);
        this.filter.updated();
      });
  
      merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();

      this.load();
    }
    ngOnChanges(changes: SimpleChanges) {
      if (this.updatedFilterData != null) {
        this.update();
      }
    }

    update() {
      if (this.dataSource !== undefined) {
        this.updatedFilterData.paginator.set(this.sort, this.paginator);
        this.dataSource.loadFilter(this.updatedFilterData);
      }
    }

    load() {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }

    onRowClicked(row) {
      this.itemClicked.next(row.secureId);
    }

    generateExcel(): void {
      let filter = this.filter;
      if (this.updatedFilterData != null) {
        filter = this.updatedFilterData;
      }
      this.dialog.open(DownloadExcelDialogComponent, {
          closeOnNavigation: false,
          autoFocus: true,
          data: Builder.newDownloadExcelDialogData (this.tramosTiposService, filter, this.i18n('Tramos tipos'), this.sort.direction, this.sort.active)
      });
    }


}
