<app-dialog-header label="Notificació" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Imatges" i18n-text (click)="OpenImatges()" color="has-color-darkblue" icon="fa-images"></app-tool-button>
  <app-tool-button text="Respostes" i18n-text (click)="OpenRespostes()" color="has-color-aqua" icon="fa-envelope"></app-tool-button>  

</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row" style="margin-top:15px">
      <app-form-text-edit id="notificacions-editor-notificacio" class="col-4" i18n-label label="Notificacio" [(value)]="this.notificacio.notificacio" [isReadOnly]="true"></app-form-text-edit>
      <app-form-text-edit id="notificacions-editor-enviament" class="col-4" i18n-label label="Enviament" [(value)]="this.notificacio.enviament" [isReadOnly]="true"></app-form-text-edit>
      <app-form-text-edit id="notificacions-editor-expedient" class="col-4" i18n-label label="Expedient" [(value)]="this.notificacio.element" [isReadOnly]="true"></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-text-edit id="notificacions-editor-tipus" class="col-4" i18n-label label="Tipus" [(value)]="this.notificacio.tipus" [isReadOnly]="true"></app-form-text-edit>
      <app-form-date-edit id="notificacions-editor-dataEnv" class="col-4" i18n-label label="Data env." [(value)]="this.notificacio.dataEnv" [isReadOnly]="true"></app-form-date-edit>
      <app-form-check-edit class="col-4" i18n-label label="Acceptació" [(value)]="this.notificacio.acceptacio" [isReadOnly]="true"></app-form-check-edit>

    </div>
    <div class="row">
      <app-form-date-edit id="notificacions-editor-dataCobr" class="col-4" i18n-label label="Data cobr." [(value)]="this.notificacio.dataCobr" [isReadOnly]="true"></app-form-date-edit>
      <app-form-text-edit id="notificacions-editor-impCobr" class="col-4" i18n-label label="Imp. cobr." [(value)]="this.notificacio.impCobr" [isReadOnly]="true"></app-form-text-edit>
      <app-form-date-edit id="notificacions-editor-dataAnul" class="col-4" i18n-label label="Data anul." [(value)]="this.notificacio.dataAnul" [isReadOnly]="true"></app-form-date-edit>

    </div>
    <div class="row">
      <app-form-date-edit id="notificacions-editor-dataDesc" class="col-6" i18n-label label="Data AMB desc." [(value)]="this.notificacio.dataAmbDesc" [isReadOnly]="true"></app-form-date-edit>
      <app-form-text-edit id="notificacions-editor-impDesc" class="col-6" i18n-label label="Imp. AMB desc." [(value)]="this.notificacio.impAmbDesc" [isReadOnly]="true"></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-date-edit id="notificacions-editor-dataNoDesc" class="col-6" i18n-label label="Data SENSE desc." [(value)]="this.notificacio.dataSenseDesc" [isReadOnly]="true"></app-form-date-edit>
      <app-form-text-edit id="notificacions-editor-impNoDesc" class="col-6" i18n-label label="Imp. SENSE desc." [(value)]="this.notificacio.impSenseDesc" [isReadOnly]="true"></app-form-text-edit>
    </div>

    <div class="border border-secondary" style="padding:5px">
      <h5>SICER</h5>
      <div class="row">
        <app-form-date-edit id="notificacions-editor-dataResp" class="col-4" i18n-label label="Data resposta" [(value)]="this.notificacio.dataResp" [isReadOnly]="true"></app-form-date-edit>
        <app-form-date-edit id="notificacions-editor-dataResult" class="col-4" i18n-label label="Data result." [(value)]="this.notificacio.dataResult" [isReadOnly]="true"></app-form-date-edit>
        <app-form-text-edit id="notificacions-editor-usuariResult" class="col-4" i18n-label label="Usuari result." [(value)]="this.notificacio.usuariResult" [isReadOnly]="true"></app-form-text-edit>
      </div>
      <div class="row">
        <app-form-text-edit id="notificacions-editor-result" class="col-8" i18n-label label="Result." [(value)]="this.notificacio.result" [isReadOnly]="true"></app-form-text-edit>
      </div>
      <div class="row">
        <app-form-date-edit id="notificacions-editor-anuladaEn" class="col-4" i18n-label label="Anul·lada en." [(value)]="this.notificacio.anuladaEn" [isReadOnly]="true"></app-form-date-edit>
        <app-form-text-edit id="notificacions-editor-motiu" class="col-8" i18n-label label="Motiu." [(value)]="this.notificacio.motiu" [isReadOnly]="true"></app-form-text-edit>
      </div>
      <div class="row">
        <app-form-text-edit id="notificacions-editor-fitxer" class="col-8" i18n-label label="Fitxer resultat" [(value)]="this.notificacio.fitxerResultat" [isReadOnly]="true"></app-form-text-edit>
        <app-form-date-edit id="notificacions-editor-dataImg" class="col-4" i18n-label label="Data Imatge" [(value)]="this.notificacio.dataImatge" [isReadOnly]="true"></app-form-date-edit>
      </div>
      <div class="row">
        <app-form-text-edit id="notificacions-editor-nomFitxerEnv" class="col-12" i18n-label label="Nom fitxer enviament" [(value)]="this.notificacio.nomFitxerEnviament" [isReadOnly]="true"></app-form-text-edit>
      </div>
      <div class="row">
        <app-form-text-edit id="notificacions-editor-nomImgAcus" class="col-12" i18n-label label="Nom imatge acus" [(value)]="this.notificacio.nomImatgeAcus" [isReadOnly]="true"></app-form-text-edit>
      </div>
      <div class="row">
        <app-form-text-edit id="notificacions-editor-usuariCarregaImg" class="col-12" i18n-label label="Usuari càrrega imatge" [(value)]="this.notificacio.usuariCarregaImatge" [isReadOnly]="true"></app-form-text-edit>
      </div>
      <div class="row">
        <app-form-text-edit id="notificacions-editor-usuariAnul" class="col-12" i18n-label label="Usuari anul·lació" [(value)]="this.notificacio.usuariAnulacio" [isReadOnly]="true"></app-form-text-edit>
      </div>
      <div class="row">
        <app-form-text-edit id="notificacions-editor-msgError"class="col-12" i18n-label label="Msg error" [(value)]="this.notificacio.msgError" [isReadOnly]="true"></app-form-text-edit>
      </div>
    </div>
    <div *ngIf="this.notificacio.tipus==='DEV'" class="border border-secondary" style="padding:5px">
      <h5>DEV</h5>
      <div class="row">
        <app-form-date-edit id="notificacions-editor-dataAdmissio" class="col-4" i18n-label label="Data admissió" [(value)]="this.notificacio.dataAdmissio" [isReadOnly]="true"></app-form-date-edit>
      </div>
      <div class="row">
        <app-form-date-edit id="notificacions-editor-dataDisposicio" class="col-4" i18n-label label="Data disposició" [(value)]="this.notificacio.dataDisposicio" [isReadOnly]="true"></app-form-date-edit>
      </div>
      <div class="row">
        <app-form-date-edit id="notificacions-editor-dataLlegida" class="col-4" i18n-label label="Data llegida" [(value)]="this.notificacio.dataLlegida" [isReadOnly]="true"></app-form-date-edit>
      </div>
      <div class="row">
        <app-form-date-edit id="notificacions-editor-dataCaducitat" class="col-4" i18n-label label="Data caducitat" [(value)]="this.notificacio.dataCaducitat" [isReadOnly]="true"></app-form-date-edit>
      </div>
      <div class="row">
        <app-form-date-edit id="notificacions-editor-dataRebutjada" class="col-4" i18n-label label="Data rebutjada" [(value)]="this.notificacio.dataRebutjada" [isReadOnly]="true"></app-form-date-edit>
      </div>
      <div class="row">
        <app-form-text-edit id="notificacions-editor-dataError" class="col-4" i18n-label label="Data error" [(value)]="this.notificacio.dataError" [isReadOnly]="true"></app-form-text-edit>
      </div>
    </div>

  </form>
</app-dialog-body>
