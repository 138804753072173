import { SortDirection } from '@angular/material';
import { FilterData } from 'path-shared/models/filter-data';

export class TextoAvisoFilter extends FilterData {
    titulo: string;
    fechaInicio: Date;
    fechaFin: Date;
    idioma: string;
    permiteLogIn: boolean;

    constructor(sortField: string, sortDir: SortDirection) {
        super(sortField, sortDir);
        this.titulo = '';
        this.fechaInicio = null;
        this.fechaFin = null;
        this.idioma = '';
        this.permiteLogIn = null;

        this.labels['titulo'] = this.i18n('Títol');
        this.labels['fechaInicio'] = this.i18n('Data Inici');
        this.labels['fechaFin'] = this.i18n('Data Fi');
        this.labels['idioma'] = this.i18n('Idioma');
        this.labels['permiteLogIn'] = this.i18n('Permet Log In');
    }
}
