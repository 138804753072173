import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges, AfterViewChecked } from '@angular/core';
import { MatPaginator, MatSort, MatPaginatorModule, MatTableDataSource, MatDialog } from '@angular/material';

import { TipusMatriculaFilter } from 'app/sections/ciutat/tipus-matricules/models/tipus-matricula-filter';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { ActivatedRoute, Router } from '@angular/router';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TipusMatriculesService } from 'app/sections/ciutat/tipus-matricules/services/tipus-matricules.service';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { TipusMatriculaFilterService } from 'app/sections/ciutat/tipus-matricules/services/tipus-matricules-filter.service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ExcelDownloadService } from 'path-shared/services/excel/excel-download.service';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { Builder } from 'path-shared/models/builder';
import { ZonesModel } from '../../models/zones.model';
import { ZoneFilter } from '../../models/zones-filtar';
import { ZonesService } from '../../services/zones.service';
import { ZonesFilterService } from '../../services/zones-filter.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { ZonesEditarComponent } from '../zones-editar/zones-editar.component';


@Component({
  selector: 'app-zones-table',
  templateUrl: './zones-table.component.html',
  styleUrls: ['./zones-table.component.scss']
})
export class ZonesTableComponent implements OnInit , AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: ZonesModel[];
  dataSource: CustomDataSource<ZonesModel, ZoneFilter>;
  highlightedRows = [];
  filter: ZoneFilter = new ZoneFilter('descripcio', 'asc');
  filterPage: ZoneFilter;
  displayedItems = [];


  @Input() selectButton: boolean;
  @Input() updatedFilterData: ZoneFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['idZona', 'descripcio', 'fechaInici', 'fechaFin', 'color','temporada'];



  constructor(
    private zonesServices: ZonesService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n,
    private router: Router
  ) {
  }

  list: any = [];
 fechaInici: any;
  ngOnInit() {
    if (this.selectButton) {
      this.displayedColumns = ['incorpora'].concat(this.displayedColumns);
    }
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<ZonesModel, ZoneFilter>(this.paginator,
      new ZonesFilterService(), 'urlGetZones', 'urlGetZonesPaginationCount');
    this.dataSource.subject.subscribe((datas) => {

      this.displayedItems = this.zonesServices.displayedItems;
    });

    // Guardamos el valor del filtro en la cookie cada vez que varia.
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('zones-filter', this.filter); }
      }
    });

    // Cargamos el valor del filtro des de la cookie (solo si no estamos en modo dialog/popup)
    if (!this.selectButton) { this.filterService.loadFilter('zones-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);
  }


   ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    } );

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
    this.load();
   }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.notify.emit(row.SECURE_ID);
  }

  selectCurrent(codi) {
    this.itemSelected.next(codi);
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      width: '60%',
      data: Builder.newDownloadExcelDialogData (this.zonesServices, filter, this.i18n('Zones'), this.sort.direction, this.sort.active)
    });
  }

}
