var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
var defaultSortParam = 'id';
var defaultSortDirection = 'desc';
var TiquetsFilterService = /** @class */ (function (_super) {
    __extends(TiquetsFilterService, _super);
    function TiquetsFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TiquetsFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        var fechaTicketInicio = '';
        var fechaTicketFin = '';
        var fechaBancoInicio = '';
        var fechaBancoFin = '';
        if (filter.fechaTicketInicio !== null) {
            fechaTicketInicio = this.formatDateToDecimal(this.stringToDate(filter.fechaTicketInicio));
        }
        if (filter.fechaTicketFin !== null) {
            fechaTicketFin = this.formatDateToDecimal(this.stringToDate(filter.fechaTicketFin), false);
        }
        if (filter.fechaBancoInicio !== null) {
            fechaBancoInicio = this.formatDateToDecimal(this.stringToDate(filter.fechaBancoInicio));
        }
        if (filter.fechaBancoFin !== null) {
            fechaBancoFin = this.formatDateToDecimal(this.stringToDate(filter.fechaBancoFin), false);
        }
        var httpParams = new HttpParams()
            .append('id', filter.id ? filter.id.toString() : '')
            .append('matricula', filter.matricula)
            .append('email', filter.email)
            .append('zonaSecureId', filter.zonaSecureId)
            .append('proveedorExterno', filter.proveidors !== undefined && filter.proveidors !== null ? filter.proveidors.map(function (s) { return s; }).toString() : '')
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
        httpParams = httpParams
            .append('fechaTicketInicio', fechaTicketInicio)
            .append('fechaTicketFin', fechaTicketFin)
            .append('fechaBancoInicio', fechaBancoInicio)
            .append('fechaBancoFin', fechaBancoFin);
        return httpParams;
    };
    TiquetsFilterService.prototype.getBooleanValue = function (value) {
        if (value === null) {
            return '';
        }
        else {
            return value.toString();
        }
    };
    TiquetsFilterService.prototype.isDate = function (value) {
        return value instanceof Date;
    };
    TiquetsFilterService.prototype.stringToDate = function (value) {
        if (!this.isDate(value)) {
            value = new Date(value);
        }
        return value;
    };
    TiquetsFilterService.prototype.formatDateToDecimal = function (date, isFechaIni) {
        if (isFechaIni === void 0) { isFechaIni = true; }
        var month;
        var day;
        var hhmmss;
        month = (date.getMonth() + 1).toString();
        if (month.length === 1) {
            month = '0' + month;
        }
        day = date.getDate().toString();
        if (day.length === 1) {
            day = '0' + day;
        }
        if (isFechaIni) {
            hhmmss = '000000';
        }
        else {
            hhmmss = '235959';
            // vvarela: De moment treiem el filtre per tallar els tiquets als d'ara (aixó es va fer per reserves)
            // const hoy = new Date();
            // hoy.setHours(0, 0, 0, 0);
            // if (date < hoy) {
            //   hhmmss = '235959';
            // } else {
            //   const fechaActual = new Date();
            //   let hours = fechaActual.getHours().toString();
            //   let minutes = fechaActual.getMinutes().toString();
            //   let seconds = fechaActual.getSeconds().toString();
            //   if (hours.length === 1) {
            //     hours = '0' + hours;
            //   }
            //   if (minutes.length === 1) {
            //     minutes = '0' + minutes;
            //   }
            //   if (seconds.length === 1) {
            //     seconds = '0' + seconds;
            //   }
            //   hhmmss = hours + minutes + seconds;
            // }
        }
        return date.getFullYear().toString()
            + month
            + day
            + hhmmss;
    };
    return TiquetsFilterService;
}(CustomFilterService));
export { TiquetsFilterService };
