export class TablasAuditoria {
  Name: string;
  Columns: string[];
  Rows: string[];

  constructor() {
    this.Name = '';
    this.Columns = [];
    this.Rows = [];
  }
}
