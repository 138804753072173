import { OnInit, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
var GenericFileDisplayerTableComponent = /** @class */ (function () {
    function GenericFileDisplayerTableComponent() {
        this.displayedColumns = ['select', 'nombre'];
        this.files = new Array();
        this.currentSelects = new Array();
        this.show = new EventEmitter(undefined);
        this.delete = new EventEmitter(undefined);
        this.upload = new EventEmitter(undefined);
        this.download = new EventEmitter(undefined);
    }
    Object.defineProperty(GenericFileDisplayerTableComponent.prototype, "files", {
        get: function () {
            return this._files;
        },
        set: function (val) {
            this._files = val;
            this.filesDataSource = new MatTableDataSource(this.files);
        },
        enumerable: true,
        configurable: true
    });
    GenericFileDisplayerTableComponent.prototype.ngOnInit = function () {
    };
    GenericFileDisplayerTableComponent.prototype.selectAll = function () {
        var _this = this;
        // Selecciona todos los registros siempre y cuando quede alguno por seleccionar.
        // Solo cuando estan TODOS los registros marcados, en vex de seleccionar, deseleccoinará.
        if (this.isAllSelected()) {
            this.currentSelects = new Array();
        }
        else {
            this.files.forEach(function (element) {
                if (_this.currentSelects.findIndex(function (x) { return x === element.key; }) === -1) {
                    // No esta seleccionado, lo seleccionamos.
                    _this.currentSelects.push(element.key);
                }
            });
        }
    };
    GenericFileDisplayerTableComponent.prototype.selectRow = function (row) {
        if (this.isRowSelected(row.key)) {
            // La deseleccionamos.
            var index = this.currentSelects.findIndex(function (x) { return x === row.key; });
            this.currentSelects.splice(index, 1);
        }
        else {
            // La seleccionamos.
            this.currentSelects.push(row.key);
        }
    };
    GenericFileDisplayerTableComponent.prototype.clickRow = function (row) {
        // Debemos mostrar la imagen.
        this.show.emit(row.key);
    };
    GenericFileDisplayerTableComponent.prototype.isAllSelected = function () {
        var _this = this;
        var i = 0;
        var allSelected = true;
        while (i < this.files.length && allSelected) {
            if (this.currentSelects.find(function (e) { return e === _this.files[i].key; }) === undefined) {
                allSelected = false;
            }
            i++;
        }
        return allSelected && this.files.length > 0;
    };
    GenericFileDisplayerTableComponent.prototype.isRowSelected = function (id) {
        return id !== undefined && this.currentSelects !== undefined && this.currentSelects.find(function (e) { return e === id; }) !== undefined;
    };
    GenericFileDisplayerTableComponent.prototype.uploadFile = function (file) {
        this.upload.emit(file);
    };
    GenericFileDisplayerTableComponent.prototype.downloadFiles = function () {
        this.download.emit(this.currentSelects);
    };
    GenericFileDisplayerTableComponent.prototype.deleteFiles = function () {
        this.delete.emit(this.currentSelects);
    };
    return GenericFileDisplayerTableComponent;
}());
export { GenericFileDisplayerTableComponent };
