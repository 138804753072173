var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'descripcio';
var defaultSortDirection = 'asc';
var VehiclesFilterService = /** @class */ (function (_super) {
    __extends(VehiclesFilterService, _super);
    function VehiclesFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VehiclesFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('id_vehicles', Reflection.hasValue(filter.id_vehicles) ? filter.id_vehicles.toString() : '')
            .append('matricula', filter.matricula ? filter.matricula : '')
            .append('marca', filter.marca ? filter.marca : '')
            .append('model', filter.model ? filter.model : '')
            .append('color', filter.color ? filter.color : '')
            .append('asseguranca', filter.asseguranca ? filter.asseguranca : '')
            .append('poliza', filter.poliza ? filter.poliza : '')
            .append('nacionalitat', filter.observacions ? filter.observacions : '')
            .append('telefon1', filter.origen ? filter.origen : '')
            .append('id_extern', filter.tipus_vehicle ? filter.tipus_vehicle : '')
            .append('buscat', this.getTristateValue(filter.requisitoria))
            .append('proposta', Reflection.empty(filter.proposta) ? null : typeof (filter.proposta) === 'string' ?
            filter.proposta : filter.proposta.toDateString())
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    VehiclesFilterService.prototype.getTristateValue = function (value) {
        if (value === null) {
            return '';
        }
        else {
            return value.toString();
        }
    };
    return VehiclesFilterService;
}(CustomFilterService));
export { VehiclesFilterService };
