<app-toolbar>  
  <app-tool-button-permissions [permission]="this.auth.Pages.AlarmPDA"></app-tool-button-permissions>
</app-toolbar>

<mat-tab-group (selectedIndexChange)="canviPestanya()">
  <mat-tab label="Tracking"> 
    <br/>
    <div class="row" >
      <div class="col-sm-8 " style="background-color: #f4f4f4;">
        <div class="row" style="height: 100%;" *ngIf="actualPdaTrack !== undefined" >
          <div class="col-sm-3 center-block align-middle text-center" style="padding-left: 20px;padding-right: 0px;">
              <button class="btn btn-secondary mr-1" *ngIf="isPlaying"  type="button"  [disabled]="!isPlaying" (click)="pause()" >
                <i class="fa fa-pause-circle fa-lg"></i>  
              </button>        
            <button *ngIf="!isPlaying" class="btn btn-secondary mr-1" type="button" [disabled]="isPlaying" (click)="play()" >
                <i class="fa fa-play-circle fa-lg"></i>  
              </button>
              <button class="btn btn-secondary mr-1" type="button" (click)="increaseSpeed()" >
                <i class="fa fa-lg">{{this.posVelocitatMarker+1}}x</i>   
                </button>
                &nbsp;
                &nbsp;
                &nbsp;
              <button class="btn btn-secondary mr-1" type="button"  [disabled]="positionArrayTracking === 0" (click)="prev()" >
                <i class="fa fa-arrow-circle-left fa-lg"></i>  
              </button>
            <button class="btn btn-secondary mr-1" type="button"  [disabled]="!(positionArrayTracking < actualPdaTrack.Coordenades.length)" (click)="next()" >
              <i class="fa fa-arrow-circle-right fa-lg"></i>  
              </button>
              
              <!--<button class="btn btn-secondary " type="button"   (click)="reduceSpeed()" >
                x 0.5
                </button>-->
          </div>
          <div class="col-sm-9 center-block align-middle text-center " >
            <mat-slider *ngIf="actualPdaTrack !== undefined" style="width: 100%;"    class="cdk-focused"  #slider min="0" [(ngModel)]="positionArrayTracking" max="{{actualPdaTrack.Coordenades.length}}" (input)="PositionChanged(labelHoraActual)">
            </mat-slider>  
          </div>         
        </div>      
       
             
        
      </div>
      <div class="col-sm-2">
          <app-form-date-edit id="tracking-data" i18n-label label="Data" (valueChange)="onDateChange($event)" (value)="this.dataActual" type="calendar"></app-form-date-edit>  
          <br/>
      </div>
      <div class="col-sm-2" *ngIf="actualPdaTrack !== undefined" >
        <div  style="padding-left: 15px;" >
          <app-form-text-edit id="tracking-alerta" i18n-label   label="Alerta minutos sin actividad" [(value)]="this.minutosSinActividad" number (focusout)="canviMinutoSinActividad($event)" ></app-form-text-edit> 
        </div>        
      </div>
      
      
    </div>
    <div class="row" >
        <div class="col-xl-8" style="min-height: 50vh; height: 65vh; padding-right: 0px; ">
          <!-- <div class="card leyenda-mapa">leyenda</div> -->
          <!-- [ngStyle]="this.getWidthBrillantor()" -->
          <agm-map [latitude]="currentLocation.latitude" [longitude]="currentLocation.longitude"  (mapReady)="onMapReady($event)" [zoom]="zoom" style="padding-right: 0px;width: 100%;">
            
            <ng-container *ngFor="let pda of pdas">                  
              <ng-container *ngIf=" pda.Selected">                    
                <agm-marker  [latitude]="pda.LatitudInici" [longitude]="pda.LongitudInici"  [iconUrl]="pda.ColorHexa" [label]="{color: 'black',fontWeight: 'bold'}">                      
                  <agm-info-window [disableAutoPan]="true">                          
                    <strong>{{pda.DataHoraInici}}</strong>                        
                  </agm-info-window>                    
                </agm-marker>
                <agm-marker  [latitude]="pda.LatitudFinal" [longitude]="pda.LongitudFinal"  [iconUrl]="pda.ColorHexa" [label]="{color: 'black',fontWeight: 'bold'}">                      
                  <agm-info-window [disableAutoPan]="true">                          
                    <strong>{{pda.DataHoraFinal}}</strong>                        
                  </agm-info-window>                    
                </agm-marker>                      
                <agm-polyline [strokeColor]="pda.ColorHexa">                          
                  <agm-polyline-point  *ngFor="let point of pda.Coordenades;"  [latitude]="point.Latitud" [longitude]="point.Longitud">                          
                  </agm-polyline-point>                      
                </agm-polyline>
                      <!--<agm-circle  *ngFor="let point of pda.Coordenades;" [latitude]="point.Latitud" [longitude]="point.Longitud"
                      [radius]="0"
                      [fillColor]="pda.ColorHexa"
                      [circleDraggable]="true"
                      [editable]="true"
                      >
                  </agm-circle>--> 
                  
                  <agm-marker  #gm *ngFor="let point of pda.Coordenades;" 
                              [latitude]="point.Latitud" [longitude]="point.Longitud" 
                              [iconUrl] ="{ url:  point.AlarmaGpsDesactivado !== undefined && point.AlarmaGpsDesactivado !== null ? 
                                pda.ColorHexaGpsDesactivado :  point.DiferenciaParada ? pda.ColorHexaParada : 
                                (point.tipoMovimiento === 'DETECCION' ? pda.detectionIcon : 
                              (point.tipoMovimiento === 'DENUNCIA' ? pda.complaintIcon : pda.ColorHexa)) }"
                               (markerClick)="clickMarker(point)"
                  ><!-- (mouseOver)="onMouseOver(infoWindow,gm)" (mouseOut)="mouseOut(infoWindow,gm)"
                    [iconUrl]="{'pda.ColorHexa' : point.DiferenciaParada , 'pda.ColorHexaParada':!point.DiferenciaParada }" (mouseOver)="onMouseOver(infoWindow,gm)"-->
                      
                    <agm-info-window [disableAutoPan]="true" [isOpen]="point.Clicked" #infoWindow>
                      <span i18n>Agent:</span> &nbsp; <strong>{{point.Agente}}</strong>  &nbsp; &nbsp;  <span i18n>PDA:</span> &nbsp; <strong>{{pda.PDA}}</strong> - {{point.Hora}}
                      <div *ngIf="point.AlarmaGpsDesactivado !== undefined && point.AlarmaGpsDesactivado !== null"> <br>
                        <strong><span i18n>ATENCIÓN:</span></strong><span i18n> GPS DESACTIVADO!</span>
                      </div>
                      <div *ngIf="!pda.IsGrua">
                        <br>
                        <span i18n>Núm Denúncies:</span><strong>{{point.NumDenuncias}}</strong>
                        <br>
                        <span i18n>Núm Deteccions:</span><strong>{{point.NumDetecciones}}</strong>
                      </div> 
                      <div *ngIf="pda.IsGrua">
                        <br>
                        <span i18n>Núm Propostes Grua:</span><strong>{{point.NumPropostesGrua}}</strong>
                      </div>   
                      
                      </agm-info-window>
                  </agm-marker>

                  <!-- <agm-marker *ngIf="actualPdaTrack !== undefined && actualPositionTrack !== undefined" [latitude]="actualPositionTrack.Latitud" [longitude]="actualPositionTrack.Longitud"  [label]="{color: 'black', text: actualPositionTrack.Hora}" [iconUrl]="{ url: pda.IconMarker, labelOrigin:{x:32,y:17},  scaledSize: {height: 40, width: 40}}" ></agm-marker> -->
                  <agm-marker *ngIf="actualPdaTrack !== undefined && actualPositionTrack !== undefined" [latitude]="actualPositionTrack.Latitud" [longitude]="actualPositionTrack.Longitud"  
                   [iconUrl]="{ url: this.getUrlIcon(actualPositionTrack) , labelOrigin:{x:32,y:17},  scaledSize: actualPositionTrack.DiferenciaParada ? {height: 40, width: 40}:  {height: 25, width: 25}}" 
                   (markerClick)="clickMarkerOnMovment()">
                    <agm-info-window  *ngIf="this.isPlaying" [disableAutoPan]="true" [isOpen]="true"   #infoWindow>
                      <span i18n>Agent:</span> &nbsp; <strong>{{pda.Agente}}</strong>  &nbsp; &nbsp; <strong>{{pda.PDA}}</strong> - {{actualPositionTrack.Hora}}
                    </agm-info-window>
                  </agm-marker>
                
                  <agm-marker *ngIf="numeroPdaShowing !== pda.PDA" [latitude]="pda.LatitudFinal" [longitude]="pda.LongitudFinal"  [label]="{color: 'black', text: pda.LabelFinal}" [iconUrl]="{ url: pda.IconMarker, labelOrigin:{x:32,y:17}}" >
                      <!--<agm-info-window [disableAutoPan]="true">
                          <strong>{{pda.DataHoraFinal}}</strong>
                        </agm-info-window>-->
                  </agm-marker>
                </ng-container>
              </ng-container>

            </agm-map>
          </div>

      <div class="col-xl-4" style="padding-right: 0px; padding-left: 30px;">
          <div class="row" style="max-height: 80%;">
            <div  class="col-xl-12" style="max-height: 550px;overflow: auto;">
              <p class="text-justify"><span i18n>Dispositus localitzats</span></p>
              <mat-table #table [dataSource]="pdas" matSort aria-label="Elements"  class="is-header-sticky"  >
                <ng-container matColumnDef="incorpora" >
                    <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
                    <mat-cell *matCellDef="let row"  ><mat-checkbox #checkbox [(ngModel)]="row.Selected" [disabled]="true"   ></mat-checkbox></mat-cell><!--(change)="this.Checked(row)"-->
                  </ng-container>
            
                  <ng-container matColumnDef="pda">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>PDA</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.PDA}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="LabelHoraInici">
                      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora inici</mat-header-cell>
                      <mat-cell *matCellDef="let row">{{row.LabelHoraInici}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="LabelHoraFinal">
                      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora final</mat-header-cell>
                      <mat-cell *matCellDef="let row">{{row.LabelHoraFinal}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Agente">
                      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Agente</mat-header-cell>
                      <mat-cell *matCellDef="let row">{{row.Agente}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="GpsDisabledAlarm">
                      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>GPS Desactivado</mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <img *ngIf="row.GpsDisabledAlarm"  [src]="row.ColorHexaGpsDesactivado"/>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="PlayAnimacio">
                      <mat-header-cell  *matHeaderCellDef i18n > Visualitzar </mat-header-cell>
                      <mat-cell *matCellDef="let row" >  <app-tool-button [text]="'Play'" i18n-text (click)="this.ShowTrackPda(row)" color="has-color-green" icon="fa-play"></app-tool-button>
                      </mat-cell>
                    </ng-container>
        
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" (click)="SeleccionarFila(row)" [ngClass]="{'rowSelectedClass': row.PDA === this.numeroPdaShowing}"></mat-row><!--[ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"-->
                </mat-table>
        
            </div>
            <!--<div class="col-xl-6">
                <div >
                  <p class="text-justify"><span i18n>Dispositus sense localitzar</span></p>
                  <div style="max-height: 550px;overflow: auto;"> 
                  <mat-table #table [dataSource]="pdaNotTracked" matSort aria-label="Elements"   >
                        <ng-container matColumnDef="pda">
                            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>PDA</mat-header-cell>
                            <mat-cell *matCellDef="let row">{{row}}</mat-cell>
                          </ng-container>
                
                          
                          <mat-header-row *matHeaderRowDef="displayedColumnsPdaNotTracked; sticky: true" style="position: sticky"></mat-header-row>
                          <mat-row *matRowDef="let row; columns: displayedColumnsPdaNotTracked;" class="has-iconset" ></mat-row>
                        
                      </mat-table>
                  </div>
                </div>
            </div>-->
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab i18n-label label="Configuració">
      <br/>
      <div>

     
   <div style="height: 100%">
    <div class=" row col-xl-4">
       <app-form-text-edit id="tracking-timeout" class="col-xl-6" i18n-label label="TimeOut (min)" [(value)]="this.timeOut" number (focusout)="canviTimeOut($event)" ></app-form-text-edit> 
      <app-form-text-edit id="tracking-refresc" class="col-xl-6" i18n-label label="Refresc (seg)" [(value)]="this.refresc" number (focusout)="canviRefresc($event)"></app-form-text-edit> 
    </div>     
  </div>
    <div class="row col-sm-11" style="max-height: 650px; overflow: auto;">

        <mat-table #table [dataSource]="pdaConfigList" matSort aria-label="Elements"  class="is_header_sticky"  >
          
            <!-- Pda Actives manuals-->  
         
            <ng-container matColumnDef="pda" class="col-sm-2">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Actives Manuals</mat-header-cell>
                  <mat-cell *matCellDef="let row" >
                    <ng-container *ngIf="(row.Activa && !row.ActivaSempre )" >
                      {{row.PDA}}
                    </ng-container>
                  </mat-cell>                 
            </ng-container> 
            <ng-container matColumnDef="incorpora"  class="col-sm-1">
                <mat-header-cell *matHeaderCellDef title="Desmarcar" i18n-title class="text-center"><app-tool-button [text]="'Play'" i18n-text (click)="this.DesmarcarTotesManuals()" color="has-color-red" icon="fa-arrow-right"></app-tool-button></mat-header-cell>
                <mat-cell *matCellDef="let row" >
                    <ng-container *ngIf="(row.Activa && !row.ActivaSempre )" >
                      <app-tool-button [text]="'Play'" i18n-text (click)="this.UnTrackPdaManual(row)" style="height: 10vmax" color="has-color-red" icon="fa-arrow-right"></app-tool-button>
                      <!--<mat-checkbox #checkbox [(ngModel)]="row.Activa" (change)="this.CheckedConfig(row)"  ></mat-checkbox>-->
                  </ng-container>
                </mat-cell>
              </ng-container>
            <!-- Pda Actives manuals-->            
            <!-- Pda NO Actives -->  
          <ng-container matColumnDef="incorporaNoActiva"  class="col-sm-1">
              <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><app-tool-button [text]="'Play'" i18n-text (click)="this.MarcarTotesManuals()" color="has-color-green" icon="fa-arrow-left"></app-tool-button></mat-header-cell>
              <mat-cell *matCellDef="let row" >
                  <ng-container *ngIf="(!row.Activa && !row.ActivaSempre )" >
                    <!--<mat-checkbox #checkbox [(ngModel)]="row.Activa" (change)="this.CheckedConfig(row)"  ></mat-checkbox>-->
                    <app-tool-button [text]="'Play'" i18n-text (click)="this.TrackPdaManual(row)" color="has-color-green" icon="fa-arrow-left"></app-tool-button>
                </ng-container>
              </mat-cell>
            </ng-container>
          <ng-container matColumnDef="pdaNoActiva" class="col-sm-2">
              <mat-header-cell *matHeaderCellDef mat-sort-header i18n>No Actives</mat-header-cell>
                <mat-cell *matCellDef="let row" >
                  <ng-container *ngIf="(!row.Activa && !row.ActivaSempre )" >
                    {{row.PDA}}
                  </ng-container>
                </mat-cell>                 
          </ng-container>
          <ng-container matColumnDef="incorporaActivaAlways"  class="col-sm-1">
              <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><app-tool-button [text]="'Play'" i18n-text (click)="this.TrackPdaAlwaysTotes()" color="has-color-green" icon="fa-arrow-right"></app-tool-button></mat-header-cell>
              <mat-cell *matCellDef="let row" >
                  <ng-container *ngIf="(!row.Activa && !row.ActivaSempre )" >
                    <!--<mat-checkbox #checkbox [(ngModel)]="row.Activa" (change)="this.CheckedConfig(row)"  ></mat-checkbox>-->
                    <app-tool-button [text]="'Play'" i18n-text (click)="this.TrackPdaAlways(row)" color="has-color-green" icon="fa-arrow-right"></app-tool-button>
                </ng-container>
              </mat-cell>
            </ng-container>
          <!-- Pda NO Actives -->

           <!-- Pda Actives ALWAYS -->  
           <ng-container matColumnDef="incorporaPdaAlways" class="col-sm-1" >
              <mat-header-cell *matHeaderCellDef title="Desmarcar" i18n-title class="text-center"><app-tool-button [text]="'Play'" i18n-text (click)="this.UnTrackPdaAlwaysTotes()" color="has-color-red" icon="fa-arrow-left"></app-tool-button></mat-header-cell>
              <mat-cell *matCellDef="let row" >
                  <ng-container *ngIf="(row.ActivaSempre )" >
                      <app-tool-button [text]="'Play'" i18n-text (click)="this.UnTrackPdaAlways(row)" color="has-color-red" icon="fa-arrow-left"></app-tool-button>
                    <!--<mat-checkbox #checkbox [(ngModel)]="row.Activa" (change)="this.CheckedConfig(row)"  ></mat-checkbox>-->
                </ng-container>
              </mat-cell>
          </ng-container>
          <ng-container matColumnDef="pdaAlways" class="col-sm-2">
              <mat-header-cell *matHeaderCellDef mat-sort-header i18n>PDA Actives</mat-header-cell>
                <mat-cell *matCellDef="let row" >
                  <ng-container *ngIf="(row.ActivaSempre )" >
                    {{row.PDA}}
                  </ng-container>
                </mat-cell>                 
          </ng-container>
          <!-- Pda NO Actives ALWAYS -->
            <mat-header-row *matHeaderRowDef="displayedColumnsConfig;sticky: true" class="mat-header-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsConfig;" class="has-iconset" ></mat-row>
        </mat-table>
<!-- Antic grid        <div class="col-sm-4 map-icon" style="min-height: 90vh;">
            <mat-table #table [dataSource]="activedPDa" matSort aria-label="Elements"   >
                  <ng-container matColumnDef="incorpora" >
                      <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
                      <mat-cell *matCellDef="let row"  ><mat-checkbox #checkbox [(ngModel)]="row.Activa" (change)="this.CheckedConfig(row)"  ></mat-checkbox></mat-cell>
                    </ng-container>
                  <ng-container matColumnDef="pda">
                      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>PDA actives</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.PDA}}</mat-cell>
                  </ng-container>
        
                  
                  <mat-header-row *matHeaderRowDef="displayedColumnsConfig; sticky: true" style="position: sticky"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsConfig;" class="has-iconset" ></mat-row>
              </mat-table>
        </div>
        <div class="col-sm-4 map-icon" style="min-height: 90vh;">
            <mat-table #table [dataSource]="unActivedPDa" matSort aria-label="Elements"   >
                  <ng-container matColumnDef="incorpora" >
                      <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
                      <mat-cell *matCellDef="let row"  ><mat-checkbox #checkbox [(ngModel)]="row.Activa" (change)="this.CheckedConfig(row)"  ></mat-checkbox></mat-cell>
                    </ng-container>
                  <ng-container matColumnDef="pda">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>PDA no actives</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.PDA}}</mat-cell>
                  </ng-container>          
                  <mat-header-row *matHeaderRowDef="displayedColumnsConfig; sticky: true" style="position: sticky"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsConfig;" class="has-iconset" ></mat-row>
              </mat-table>
        </div>
        <div class="col-sm-4 map-icon" style="min-height: 90vh;">
          <mat-table #table [dataSource]="activedTrackAlwaysPDa" matSort aria-label="Elements"   >
            <ng-container matColumnDef="incorpora" >
                <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
                <mat-cell *matCellDef="let row"  ><mat-checkbox #checkbox [(ngModel)]="row.Activa" (change)="this.CheckedConfig(row)"  ></mat-checkbox></mat-cell>
              </ng-container>
            <ng-container matColumnDef="pda">
              <mat-header-cell *matHeaderCellDef mat-sort-header i18n>PDA Actives Sempre</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.PDA}}</mat-cell>
            </ng-container>          
            <mat-header-row *matHeaderRowDef="displayedColumnsConfig; sticky: true" style="position: sticky"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsConfig;" class="has-iconset" ></mat-row>
        </mat-table>
      </div>-->
    </div>
  </div>
  </mat-tab>  
</mat-tab-group>