import { NotificarRequestDto } from '../../../models/notificar/notificar-request.dto';
import { SelectorFechasNotificarComponent } from './../components/selector-fechas-notificar/selector-fechas-notificar.component';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { InfoNotificacio } from '../../../models/info-notificacio.model';
import { TramitarService } from '../../../services/tramitar.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NotificarService } from '../../../services/notificar/notificar.service';
import { FechasNotificacionDto } from '../../../models/notificar/fechas-notificacion.dto';
import { NotificarInicioRequestDto } from '../../../models/notificar/notificar-inicio-request.dto';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ListadoNotificacionesComponent } from '../components/listado-notificaciones/listado-notificaciones.component';
import { ResultadoNotificacionDenunciasCurrent } from '../../../models/notificar/resultado-notificacion-denuncias-current.dto';
import { ListadoErroresNotificacionesComponent } from '../components/listado-errores-notificaciones/listado-errores-notificaciones.component';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ProgressBarTramitacionDto } from '../../../models/ProgressBarTramitacionDto';
import { ListadoResolucionesComponent } from '../components/listado-resoluciones/listado-resoluciones.component';
import { filter } from 'rxjs/operators';
import { ListadoNotificacionesDenunciaComponent } from '../components/listado-notificaciones-denuncia/listado-notificaciones-denuncia.component';
import { NotificacionsDenunciaFilter } from 'app/sections/notificacions/models/NotificacionDenuncia/notificacions-denuncia-filter';
import { ReportsComponent } from 'path-shared/components/reports/reports.component';
import { Observable } from 'rxjs';
import { Toast, ToastrService } from 'ngx-toastr';
import { ResolucionFilter } from 'app/sections/notificacions/models/NotificacionDenuncia/resolucion-filter';

@Component({
  selector: 'app-notificar',
  templateUrl: './notificar.component.html',
  styleUrls: ['./notificar.component.scss']
})
export class NotificarComponent implements OnInit {

  infoNotificar: InfoNotificacio = new InfoNotificacio();
  porcentajeC60: number;
  mensajePorcentajeC60: string = '';
  porcentajeNotificaciones: number;
  mensajePorcentajeNotificaciones: string = '';
  infoNotificacioLoaded: boolean = false;

  /* #region  INICIALIZACION */
  @ViewChild('myDialogNotificaciones') myDialogNotificaciones: TemplateRef<any>;
  @ViewChild('myDialogC60') myDialogC60: TemplateRef<any>;
  constructor(
    private notificarService: NotificarService,
    private tramitarService: TramitarService,
    public auth: AuthorizationService,
    private dialog: MatDialog,
    private not: NotificationService,
    private toastr: ToastrService,
  ) { }
  ngOnInit() {

    this.refresh();
  }

  /* #endregion */


  /* #region  EVENTOS BOTONES */

  //TODO: Clean this
  procesarDenuncias(): void {
    //filtramos las Notificaciones de denuncia
    const dialogNotificacionesDenuncia=this.ShowNotificacionesDenuncia(); 
    const filtroNotificacionDenunciasATramitar$ = this.getFilterNotificacionesDenuncias(dialogNotificacionesDenuncia);

    //cojer fechas limite descuento desde el back
    const fechasLimiteDefault$:Observable<FechasNotificacionDto> = this.notificarService.inicioProcesoNotificacion(new NotificarInicioRequestDto(this.infoNotificar.SicerActivo,true))
    //las mostramos por pantalla y las dejamos modificar
    fechasLimiteDefault$.subscribe((fechasLimiteDefault) => {
      filtroNotificacionDenunciasATramitar$.subscribe((filtroNotificacionDenunciasATramitar) => {
        const dialogFechas = this.ShowFechasSelector(fechasLimiteDefault);
        const fechasValidadasUsuario$ = this.getFechasSeleccionadas(dialogFechas);
        fechasValidadasUsuario$.subscribe((fechasValidadasUsuario) => {
          const fechas= new NotificarRequestDto(
            fechasValidadasUsuario.fechaPrimeraNotificacion,
            fechasValidadasUsuario.fechaSegundaNotificacion,
            fechasValidadasUsuario.fechaTerceraNotificacion,
            fechasValidadasUsuario.fechaCuartaNotificacion,
            this.infoNotificar.SicerActivo
          );
          this.initProgressBar();
          const tramitacionResultadoProcesoResponseDto$ = this.notificarService.iniciaProcesoNotificarDenuncias(fechas, filtroNotificacionDenunciasATramitar);
          tramitacionResultadoProcesoResponseDto$.subscribe((result:ResultadoNotificacionDenunciasCurrent)=>{
            dialogNotificacionesDenuncia.close();
            const dialogListadoNotificaciones$= this.ShowListadoNotificacionesComponent(result)
            dialogListadoNotificaciones$.componentInstance.notify.subscribe(finalizar => {
              if (finalizar) {
                // Enviamos peticion para finalizar el envio.
                this.notificarService.finalizaProcesoNotificarDenuncias(result.notificacionesPendientes).subscribe(() => {
                  // Hemos podido finalizar el proceso de notificacion de denuncias.
                  this.refresh();
                  //dialogNotificacionesDenuncia.close();
                  this.toastr.success("Finalizado correctamente");
                }, error => {
                  // Ha habido error pero debemos refrescar igualmente para ver que informacion tenemos ahora.
                  this.refresh();
                  this.toastr.error("Ha habido un error");
                });
              }
            });  
          });
         
        });
      });
    });
  }

  private ShowListadoNotificacionesComponent(result: ResultadoNotificacionDenunciasCurrent): MatDialogRef<ListadoNotificacionesComponent, any> {
    return this.dialog.open(ListadoNotificacionesComponent, {
      width: '70%',
      height: '75%',
      autoFocus: true,
      data: { 'result': result, 'isSicer': this.infoNotificar.SicerActivo, 'tipoNoti': 0 }
    });
  }

  private ShowFechasSelector(fechasLimiteDefault: FechasNotificacionDto): MatDialogRef<SelectorFechasNotificarComponent, any> {
    const dialogFechas= this.dialog.open(SelectorFechasNotificarComponent, {
      width: '45%',
      closeOnNavigation: false,
      autoFocus: true,
      data: { 'info': fechasLimiteDefault, 'teDescompte': true }
    });
    return dialogFechas;
  }
  private getFechasSeleccionadas(dialogFechas: MatDialogRef<SelectorFechasNotificarComponent, any>): Observable<FechasNotificacionDto> {
    return dialogFechas.componentInstance.notify;
  }

  getFilterNotificacionesDenuncias(dialogNotificacionesDenuncia: MatDialogRef<ListadoNotificacionesDenunciaComponent, any>): Observable<NotificacionsDenunciaFilter> {
    return dialogNotificacionesDenuncia.componentInstance.notify;
  }
  ShowNotificacionesDenuncia():MatDialogRef<ListadoNotificacionesDenunciaComponent,any>{ 
    const dialogNotificacionesDenuncia = this.dialog.open(ListadoNotificacionesDenunciaComponent, {
      width: '75%',
      height: '80%',
      autoFocus: true,
    });
    return dialogNotificacionesDenuncia;
    
  }

  procesarPropuestasResolucion(): void {
    // Iniciamos el proceso de generacion de c60 y obtenemos informacion necesaria para continuar.
    this.notificarService.inicioProcesoNotificacion(new NotificarInicioRequestDto(false, false)).subscribe(info => {
      if (info) {
        /* Ya se ha iniciado el proceso de notificacion de propuestas de resolucion. Podemos proceder... */
        // Ahora mostramos un popup de seleccion de fechas con la informacion recibida.
        const ref = this.dialog.open(SelectorFechasNotificarComponent, {
          width: '45%',
          closeOnNavigation: false,
          autoFocus: true,
          data: { 'info': info, 'teDescompte': false }
        });
        ref.componentInstance.notify.subscribe((newFechas: FechasNotificacionDto) => {
          // Hemos modificado/o aceptado las fechas. Podemos realizar el proceso de notificacion de denuncias.
          const request = new NotificarRequestDto(
            newFechas.fechaPrimeraNotificacion,
            newFechas.fechaSegundaNotificacion,
            newFechas.fechaTerceraNotificacion,
            newFechas.fechaCuartaNotificacion,
            this.infoNotificar.SicerActivo);

          this.initProgressBar();

          this.notificarService.iniciaProcesoNotificarPropuestasResolucion(request).subscribe((result: ResultadoNotificacionDenunciasCurrent) => {
            // Recibimos el resultado, dependera de si sicert esta activo o no.
            if (this.infoNotificar.SicerActivo) {
              result.notificacionesPendientes.map(d => d.selected = true);
              // Mostramos los errores y la pantalla de listado de notificaciones.
              const refNotificacionesAle = this.dialog.open(ListadoNotificacionesComponent, {
                width: '70%',
                height: '75%',
                autoFocus: true,
                closeOnNavigation: false,
                data: { 'result': result, 'isSicer': this.infoNotificar.SicerActivo, 'tipoNoti': 1 },
                // position: { top: '25%' }
              });
              refNotificacionesAle.componentInstance.notify.subscribe(finalizar => {
                if (finalizar) {
                  // Enviamos peticion para finalizar el envio.
                  this.notificarService.finalizaProcesoNotificarPropuestasResolucion(result.notificacionesPendientes).subscribe(result => {
                    // Recibimos el resultado, dependera de si sicert esta activo o no.
                    if (this.infoNotificar.SicerActivo) {
                      // Mostramos los errores y la pantalla de listado de notificaciones.
                    }
                    else {
                      // Mostramos directamente la pantalla de seleccion de reports.
                    }
                    this.refresh();
                  }, error => {
                    // Ha habido error pero debemos refrescar igualmente para ver que informacion tenemos ahora.
                    this.refresh();
                  });
                }
              });
              if (result.errors !== undefined && result.errors !== null && result.errors.length > 0) {
                const refErroresAle = this.dialog.open(ListadoErroresNotificacionesComponent, {
                  width: '45%',
                  closeOnNavigation: false,
                  autoFocus: true,
                  data: result.errors,
                  position: { top: '25%' }
                });
              }
            }
            else {
              // Mostramos directamente la pantalla de seleccion de reports.
              // TODO -> MANUAL AUN NO.
              // this.refresh();
            }
          });

          // this.refresh();
        });
      }
    });
  }

  procesarResoluciones(): void {
    // Procesamos las resoluciones y generamos los informes.
    // this.initProgressBar();
    // this.notificarService.procesarResoluciones().subscribe(result => {
    //   if (result) {
    //     result.notificacionesPendientes.map(d => d.selected = true);
    //     // Hemos podido procesar la notifificacion de resolucion. Descargamos el report.
    //     // todo descargar reports.
    //     const refNotificaciones = this.dialog.open(ListadoNotificacionesComponent, {
    //       width: '70%',
    //       height: '75%',
    //       autoFocus: true,
    //       closeOnNavigation: false,
    //       data: { 'result': result, 'isSicer': this.infoNotificar.SicerActivo, 'tipoNoti': 2 }
    //       // position: { top: '25%' }
    //     });
    //     refNotificaciones.componentInstance.notify.subscribe(finalizar => {
    //       if (finalizar) {
    //         this.notificarService.finalizaResoluciones(result.notificacionesPendientes).subscribe(result => {
    //           // Hemos podido procesar la notificacion de resoluciones. Refrescamos la informacion.
    //           this.refresh();
    //         }, error => {
    //           // Ha habido error pero debemos refrescar igualmente para ver que informacion tenemos ahora.
    //           this.refresh();
    //         });
    //       }
    //     });
    //   }
    // });
  }

  procesarResolucionesv2(){
    const dialogResoluciones=this.ShowResoluciones();
    const filtroResoluciones$=this.getFilterResoluciones(dialogResoluciones);

    filtroResoluciones$.subscribe((filtroResoluciones)=>{ 
      this.initProgressBar();
      const resultadoResolucionesDto$=this.notificarService.procesarResoluciones(filtroResoluciones).subscribe(result=>{
        const refNotificaciones = this.dialog.open(ListadoNotificacionesComponent, {
          width: '70%',
          height: '75%',
          autoFocus: true,
          closeOnNavigation: false,
          data: { 'result': result, 'isSicer': this.infoNotificar.SicerActivo, 'tipoNoti': 2 }
        });
        dialogResoluciones.close();
        refNotificaciones.componentInstance.notify.subscribe(finalizar => {
          if (finalizar) {
              this.notificarService.finalizaResoluciones(result.notificacionesPendientes).subscribe(result => {
                // Hemos podido procesar la notificacion de resoluciones. Refrescamos la informacion.
                this.refresh();
              }, error => {
                // Ha habido error pero debemos refrescar igualmente para ver que informacion tenemos ahora.
                this.refresh();
              });
          }
        });

      });
    })
  }

  ShowResoluciones() {
    const dialogResoluciones= this.dialog.open(ListadoResolucionesComponent,{
      width: '75%',
      height: '80%',
      autoFocus: true,
    });
    return dialogResoluciones;
  }
  getFilterResoluciones(dialogResoluciones:MatDialogRef<ListadoResolucionesComponent,any>): Observable<ResolucionFilter> {
    return dialogResoluciones.componentInstance.notify;
  }

  procesarNotificacionesResoluciones(): void {
    // Iniciamos el proceso de generacion de c60 y obtenemos informacion necesaria para continuar.
    this.notificarService.inicioProcesoNotificacion(new NotificarInicioRequestDto(false, false)).subscribe(info => {
      if (info) {
        /* Ya se ha iniciado el proceso de notificacion de propuestas de resolucion. Podemos proceder... */
        // Ahora mostramos un popup de seleccion de fechas con la informacion recibida.
        const ref = this.dialog.open(SelectorFechasNotificarComponent, {
          width: '45%',
          closeOnNavigation: false,
          autoFocus: true,
          data: { 'info': info, 'teDescompte': false }
        });
        ref.componentInstance.notify.subscribe((newFechas: FechasNotificacionDto) => {
          const request = new NotificarRequestDto(
            newFechas.fechaPrimeraNotificacion,
            newFechas.fechaSegundaNotificacion,
            newFechas.fechaTerceraNotificacion,
            newFechas.fechaCuartaNotificacion,
            this.infoNotificar.SicerActivo);

          this.initProgressBar();
          this.notificarService.iniciaProcesoNotificarNotificacionesResoluciones(request).subscribe((result: ResultadoNotificacionDenunciasCurrent) => {
            // Recibimos el resultado, dependera de si sicert esta activo o no.
            if (this.infoNotificar.SicerActivo) {
              // Mostramos los errores y la pantalla de listado de notificaciones.
              const refNotificaciones = this.dialog.open(ListadoNotificacionesComponent, {
                width: '70%',
                height: '75%',
                autoFocus: true,
                closeOnNavigation: false,
                data: { 'result': result, 'isSicer': this.infoNotificar.SicerActivo, 'tipoNoti': 4 },
                // position: { top: '25%' }
              });
              refNotificaciones.componentInstance.notify.subscribe(finalizar => {
                if (finalizar) {
                  this.notificarService.finalizaProcesoNotificarNotificacionesResoluciones(result.notificacionesPendientes).subscribe(result => {
                    // Hemos podido procesar la notificacion de resoluciones. Refrescamos la informacion.
                    this.refresh();
                  }, error => {
                    // Ha habido error pero debemos refrescar igualmente para ver que informacion tenemos ahora.
                    this.refresh();
                  });
                }
              });
              if (result.errors !== undefined && result.errors !== null && result.errors.length > 0) {
                const refErrores = this.dialog.open(ListadoErroresNotificacionesComponent, {
                  width: '45%',
                  closeOnNavigation: false,
                  autoFocus: true,
                  data: result.errors,
                  position: { top: '25%' }
                });
              }
            } else {
              // Mostramos directamente la pantalla de seleccion de reports.
              // TODO -> MANUAL AUN NO.
              // this.refresh();
            }
          });
          // Hemos modificado/o aceptado las fechas. Podemos realizar el proceso de notificacion de resoluciones.


        });
      }
    });
  }

  procesarRecursoReposicion(): void {
    if (this.infoNotificar.SicerActivo) {
      // Si tienen sicer activo, generamos la c60 e ininciamos el procedimiento.
      // Iniciamos el proceso de generacion de c60 y obtenemos informacion necesaria para continuar.
      this.notificarService.inicioProcesoNotificacion(new NotificarInicioRequestDto(false, false)).subscribe(info => {
        if (info) {
          /* Ya se ha iniciado el proceso de notificacion de propuestas de resolucion. Podemos proceder... */
          // Ahora mostramos un popup de seleccion de fechas con la informacion recibida.
          const ref = this.dialog.open(SelectorFechasNotificarComponent, {
            width: '45%',
            closeOnNavigation: false,
            autoFocus: true,
            data: { 'info': info, 'teDescompte': false }
          });
          ref.componentInstance.notify.subscribe((newFechas: FechasNotificacionDto) => {
            const request = new NotificarRequestDto(
              newFechas.fechaPrimeraNotificacion,
              newFechas.fechaSegundaNotificacion,
              newFechas.fechaTerceraNotificacion,
              newFechas.fechaCuartaNotificacion,
              this.infoNotificar.SicerActivo);

            this.initProgressBar();
            this.notificarService.iniciaProcesoNotifcarRecursosReposicion(request).subscribe((result: ResultadoNotificacionDenunciasCurrent) => {
              // Recibimos el resultado, dependera de si sicert esta activo o no.
              if (this.infoNotificar.SicerActivo) {
                // Mostramos los errores y la pantalla de listado de notificaciones.
                result.notificacionesPendientes.forEach(element => {
                  element.selected = true;
                });
                const refNotificaciones = this.dialog.open(ListadoNotificacionesComponent, {
                  width: '70%',
                  height: '75%',
                  autoFocus: true,
                  closeOnNavigation: false,
                  data: { 'result': result, 'tipoNoti': 3 },
                  // position: { top: '25%' }
                });
                refNotificaciones.componentInstance.notify.subscribe(finalizar => {
                  if (finalizar) {
                    this.notificarService.finalizaProcesoNotificarRecursosReposicion(result.notificacionesPendientes).subscribe(result => {
                      // Hemos podido procesar la notificacion de resoluciones. Refrescamos la informacion.
                      this.refresh();
                    }, error => {
                      // Ha habido error pero debemos refrescar igualmente para ver que informacion tenemos ahora.
                      this.refresh();
                    });
                  }
                });
                if (result.errors !== undefined && result.errors !== null && result.errors.length > 0) {
                  const refErrores = this.dialog.open(ListadoErroresNotificacionesComponent, {
                    width: '45%',
                    closeOnNavigation: false,
                    autoFocus: true,
                    data: result.errors,
                    position: { top: '25%' }
                  });
                }
              } else {
                // Mostramos directamente la pantalla de seleccion de reports.
                // TODO -> MANUAL AUN NO.
                // this.refresh();
              }
            });
            // Hemos modificado/o aceptado las fechas. Podemos realizar el proceso de notificacion de resoluciones.


          });
        }
      });
    } else {
      // No tienen licencia de sicer. Generamos el informe y lo descargamos.
      this.notificarService.procesarRecursosReposiciones().subscribe(result => {
        if (result) {
          // Descargamos los informes.
        }
      });
    }
  }


  procesarPropuestasReposicion(): void {
    // Iniciamos el proceso de generacion de c60 y obtenemos informacion necesaria para continuar.
    this.notificarService.inicioProcesoNotificacion(null).subscribe(info => {
      if (info) {
        /* Ya se ha iniciado el proceso de notificacion de propuestas de resolucion. Podemos proceder... */
        // Ahora mostramos un popup de seleccion de fechas con la informacion recibida.
        const ref = this.dialog.open(SelectorFechasNotificarComponent, {
          width: '45%',
          closeOnNavigation: false,
          autoFocus: true,
          data: { 'info': info, 'teDescompte': false }
        });
        ref.componentInstance.notify.subscribe((newFechas: FechasNotificacionDto) => {
          // Hemos modificado/o aceptado las fechas. Podemos realizar el proceso de notificacion de propuestas de reposicion.
          this.notificarService.finalizaProcesoNotificarPropuestasReposicion().subscribe(result => {
            // Hemos podido procesar la notificacion de propuestas de reposicion. Refrescamos la informacion.
            this.refresh();
          });
        });
      }
    });
  }

  consultar(): void {
    // no esta fet al SOMDEN??¿
  }

  /* #endregion */

  /* #region  SUPPORT */

  refresh() {
    this.tramitarService.getInfoNotificar().subscribe(x => {
      if (x !== undefined && x !== null) {
        this.infoNotificar = x;


      }
      this.infoNotificacioLoaded = true;
    });
   
  }
  initProgressBar() {
    // region Progress Bar
    this.not.onTramitacionC60Change.pipe(
      filter(
        (ProgressBar: ProgressBarTramitacionDto) =>
          ProgressBar.token === sessionStorage.getItem('access_token')
      )
    )
      .subscribe((progressBar: ProgressBarTramitacionDto) => {
        if (this.porcentajeC60 === undefined || this.porcentajeC60 === null) {
          this.dialog.open(this.myDialogC60, { disableClose: true, width: '25%', height: '20%', id: 'porcentajeC60', position: { bottom: '15%' } });
        }
        this.mensajePorcentajeC60 = progressBar.mensaje;
        this.porcentajeC60 = progressBar.porcentaje;
        if (this.porcentajeC60 === 100) {
          this.porcentajeC60 = null;
          this.dialog.getDialogById('porcentajeC60').close();
        }
      });

    this.not.onTramitacionNotificacionesChange.pipe(
      filter(
        (ProgressBar: ProgressBarTramitacionDto) =>
          ProgressBar.token === sessionStorage.getItem('access_token')
      )
    ).subscribe((progressBar: ProgressBarTramitacionDto) => {
      if (this.porcentajeNotificaciones === undefined || this.porcentajeNotificaciones === null) {
        this.dialog.open(this.myDialogNotificaciones, { disableClose: true, width: '25%', height: '20%', id: 'porcentajeNotificaciones', position: { bottom: '15%' } });
      }
      this.mensajePorcentajeNotificaciones = progressBar.mensaje;
      this.porcentajeNotificaciones = progressBar.porcentaje;
      if (this.porcentajeNotificaciones === 100) {
        this.porcentajeNotificaciones = null;
        this.dialog.getDialogById('porcentajeNotificaciones').close();
      }
    })
      ;

    // end region Progress Bar
  }
  /* #endregion */
}
