var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'codi';
var defaultSortDirection = 'asc';
var EnvioPuntsDGTFilterService = /** @class */ (function (_super) {
    __extends(EnvioPuntsDGTFilterService, _super);
    function EnvioPuntsDGTFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EnvioPuntsDGTFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        if (filter.fechaInicio !== undefined && filter.fechaInicio !== null) {
            filter.fechaInicio.setHours(0, 0, 1);
        }
        if (filter.fechaFin !== undefined && filter.fechaFin !== null) {
            filter.fechaFin.setHours(23, 59, 59);
        }
        return new HttpParams()
            .append('remesa', filter.remesa ? filter.remesa.toString() : '')
            .append('fechaInicio', Reflection.empty(filter.fechaInicio) ? null : typeof (filter.fechaInicio) === 'string' ?
            filter.fechaInicio : filter.fechaInicio.toDateString())
            .append('fechaFin', Reflection.empty(filter.fechaFin) ? null : typeof (filter.fechaFin) === 'string' ?
            filter.fechaFin : filter.fechaFin.toDateString())
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return EnvioPuntsDGTFilterService;
}(CustomFilterService));
export { EnvioPuntsDGTFilterService };
