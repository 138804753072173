/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./diposit-editor-qui-recull.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i3 from "../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i4 from "../../../../shared/services/validation/validation.service";
import * as i5 from "./diposit-editor-qui-recull.component";
import * as i6 from "../../../ciutat/persones/services/persones.service";
var styles_DipositEditorQuiRecullComponent = [i0.styles];
var RenderType_DipositEditorQuiRecullComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DipositEditorQuiRecullComponent, data: {} });
export { RenderType_DipositEditorQuiRecullComponent as RenderType_DipositEditorQuiRecullComponent };
export function View_DipositEditorQuiRecullComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-4"], ["id", "diposit-editor-qui-recull-nom"], ["label", "Nom i cognoms"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.value.titularnom = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormTextEditComponent_0, i2.RenderType_FormTextEditComponent)), i1.ɵdid(2, 4308992, null, 0, i3.FormTextEditComponent, [i4.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-4"], ["id", "diposit-editor-qui-recull-dni"], ["label", "DNI/PASP"]], null, [[null, "valueChange"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.value.titularnif = $event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.consultarConductor() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_FormTextEditComponent_0, i2.RenderType_FormTextEditComponent)), i1.ɵdid(4, 4308992, null, 0, i3.FormTextEditComponent, [i4.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], id: [3, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-4"], ["id", "diposit-editor-qui-recull-direccio"], ["label", "Direcci\u00F3"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.value.titulardireccio = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormTextEditComponent_0, i2.RenderType_FormTextEditComponent)), i1.ɵdid(6, 4308992, null, 0, i3.FormTextEditComponent, [i4.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-4"], ["id", "diposit-editor-qui-recull-cp"], ["label", "Codi postal"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.value.titularCP = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormTextEditComponent_0, i2.RenderType_FormTextEditComponent)), i1.ɵdid(8, 4308992, null, 0, i3.FormTextEditComponent, [i4.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-4"], ["id", "diposit-editor-qui-recull-poblacio"], ["label", "Poblaci\u00F3"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.value.titularpoblacio = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormTextEditComponent_0, i2.RenderType_FormTextEditComponent)), i1.ɵdid(10, 4308992, null, 0, i3.FormTextEditComponent, [i4.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-4"], ["id", "diposit-editor-qui-recull-telefon"], ["label", "Tel\u00E8fon"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.value.titularTelefon = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormTextEditComponent_0, i2.RenderType_FormTextEditComponent)), i1.ɵdid(12, 4308992, null, 0, i3.FormTextEditComponent, [i4.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"], id: [4, "id"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Nom i cognoms"; var currVal_1 = _co.value.titularnom; var currVal_2 = "col-xl-4"; var currVal_3 = false; var currVal_4 = "diposit-editor-qui-recull-nom"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "DNI/PASP"; var currVal_6 = _co.value.titularnif; var currVal_7 = "col-xl-4"; var currVal_8 = "diposit-editor-qui-recull-dni"; _ck(_v, 4, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = "Direcci\u00F3"; var currVal_10 = _co.value.titulardireccio; var currVal_11 = "col-xl-4"; var currVal_12 = false; var currVal_13 = "diposit-editor-qui-recull-direccio"; _ck(_v, 6, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = "Codi postal"; var currVal_15 = _co.value.titularCP; var currVal_16 = "col-xl-4"; var currVal_17 = false; var currVal_18 = "diposit-editor-qui-recull-cp"; _ck(_v, 8, 0, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); var currVal_19 = "Poblaci\u00F3"; var currVal_20 = _co.value.titularpoblacio; var currVal_21 = "col-xl-4"; var currVal_22 = false; var currVal_23 = "diposit-editor-qui-recull-poblacio"; _ck(_v, 10, 0, currVal_19, currVal_20, currVal_21, currVal_22, currVal_23); var currVal_24 = "Tel\u00E8fon"; var currVal_25 = _co.value.titularTelefon; var currVal_26 = "col-xl-4"; var currVal_27 = false; var currVal_28 = "diposit-editor-qui-recull-telefon"; _ck(_v, 12, 0, currVal_24, currVal_25, currVal_26, currVal_27, currVal_28); }, null); }
export function View_DipositEditorQuiRecullComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-diposit-editor-qui-recull", [], null, null, null, View_DipositEditorQuiRecullComponent_0, RenderType_DipositEditorQuiRecullComponent)), i1.ɵdid(1, 114688, null, 0, i5.DipositEditorQuiRecullComponent, [i6.PersonesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DipositEditorQuiRecullComponentNgFactory = i1.ɵccf("app-diposit-editor-qui-recull", i5.DipositEditorQuiRecullComponent, View_DipositEditorQuiRecullComponent_Host_0, { value: "value" }, { valueChange: "valueChange" }, []);
export { DipositEditorQuiRecullComponentNgFactory as DipositEditorQuiRecullComponentNgFactory };
