import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-tramitar-executiva-olot',
  templateUrl: './table-tramitar-executiva-olot.component.html',
  styleUrls: ['./table-tramitar-executiva-olot.component.scss']
})
export class TableTramitarExecutivaOlotComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
