<div class="row justify-content-between" style="align-items: baseline;">
  
  <!-- <div class="col">
    <button class="btn btn-outline-primary btn-xs" style="max-height: 100%; max-width: 100%; font-size: medium !important;" title="Executar Operació" i18n-title (click)="this.ejecutarOperacion()">
      {{ 'Executar Operació 11.1.A' }}
      <i style="padding-left: 4%; font-size: x-large;" class="fa fa-check-double"></i>
    </button>
  </div> -->
  
  <div class="col" [hidden]="true">
      <app-filter-selector [filter]="this.filter" exclude="['tipoFk']"></app-filter-selector>
  </div>
  <div class="col" [hidden]="true">
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>
  </div>
</div><br><br>

<div class="row table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" 
                    [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

      <!-- Seleccionar -->
      <ng-container matColumnDef="select" >
        <mat-header-cell  *matHeaderCellDef title="Seleccionat" i18n-title class="text-center">
          <mat-checkbox #checkbox [ngModel]="this.allSelected()" (change)="selectAll()" ></mat-checkbox>
        </mat-header-cell>
        <mat-cell  *matCellDef="let row; let i= index;"  class="text-center">
          <mat-checkbox #checkbox [ngModel]="this.rowSelected(i)" (change)="selectRow(i)"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <!--Expediente -->
      <ng-container matColumnDef="expediente">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expedient</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.exp}}</mat-cell>
      </ng-container>

      <!--Persona Juridica -->
      <ng-container matColumnDef="pj">
          <mat-header-cell *matHeaderCellDef i18n>PJ</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox #checkbox [(ngModel)]="row.personaJuridica" [disabled]="true"></mat-checkbox>
          </mat-cell>
      </ng-container>       

      <!-- Titular -->
      <ng-container matColumnDef="titular">
          <mat-header-cell *matHeaderCellDef i18n>Titular</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.titular}}</mat-cell>
      </ng-container>

      <!-- Matricula -->
      <ng-container matColumnDef="matricula">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.matricula}}</mat-cell>
      </ng-container>

      <!-- Boletín -->
      <ng-container matColumnDef="boletin">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Boletín</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.boletin}}</mat-cell>
      </ng-container>

      <!-- Fecha Notificación -->
      <ng-container matColumnDef="fechaNotificacion">
        <mat-header-cell *matHeaderCellDef  i18n>Fecha Notificacion</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.fechaNotificacion | shortDate }}</mat-cell>
      </ng-container>

      <!-- Días Notificacion -->
      <ng-container matColumnDef="diasNotificacion">
        <mat-header-cell *matHeaderCellDef  i18n>Días Notificacion</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.diasNotificacion}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>