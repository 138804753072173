import { GenericFileViewerDialogComponent } from './../generic-file-viewer-dialog/generic-file-viewer-dialog.component';
import { GenericFile } from './../../model/generic-file';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MatDialog } from '@angular/material';

@Component({
  selector: 'app-generic-file-displayer-frame',
  templateUrl: './generic-file-displayer-frame.component.html',
  styleUrls: ['./generic-file-displayer-frame.component.scss']
})
export class GenericFileDisplayerFrameComponent implements OnInit {

  currentlyShowingPdf = false;
  currentlyShowingImage = false;

  @Output() previousFile: EventEmitter<any>;
  @Output() nextFile: EventEmitter<any>;

  file: GenericFile;
  displayImage: any;
  displayPdf: String;

  imageViewerDialog: MatDialogRef<GenericFileViewerDialogComponent>;

  constructor(
    public domSanitizationService: DomSanitizer,
    public dialog: MatDialog
  ) {
    this.previousFile = new EventEmitter<any>(undefined);
    this.nextFile = new EventEmitter<any>(undefined);
  }

  ngOnInit() {
  }

  expandImage(): void {
    this.imageViewerDialog = this.dialog.open(GenericFileViewerDialogComponent,
      {
        closeOnNavigation: false,
        autoFocus: true,
        maxWidth: '90%',
        data: {
          fileName: this.file.nombre,
          imageData: this.displayImage
        }
      });
  }

  moveLeft(): void {
    this.previousFile.emit(this.file.id);
  }

  moveRight(): void {
    this.nextFile.emit(this.file.id);
  }

  showImage(image: GenericFile): void {
    const formattedImage = image.fichero.includes('data:image/') ? image.fichero : 'data:image/*;base64,' + image.fichero;
    this.displayImage = this.domSanitizationService.bypassSecurityTrustUrl(formattedImage);
    this.file = image;
    this.currentlyShowingImage = true;
    this.currentlyShowingPdf = false;
    this.displayPdf = undefined;
  }

  showPdf(pdf: GenericFile): void {
    const formattedPdf = pdf.fichero.includes('data:application/') ? pdf.fichero : 'data:application/pdf;base64,' + pdf.fichero;
    this.displayPdf = formattedPdf; // this.getDisplayablePdf(formattedPdf);
    this.file = pdf;

    this.currentlyShowingImage = false;
    this.currentlyShowingPdf = true;
    this.displayImage = undefined;
  }

  getDisplayablePdf(dataURI: string): Uint8Array {
    const raw = dataURI;
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    let i = 0;
    for (i; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

}
