
  <div class="row">
    <div class="col-sm-4 map-icon" style="min-height: 30rem; margin-top:2rem">
        <agm-map [latitude]="currentLocation.latitude" [longitude]="currentLocation.longitude"  (mapReady)="onMapReady($event)" [zoom]="zoom"> 
            <ng-container *ngFor="let alarma of data">
                <ng-container *ngIf=" alarma.selected">
                  <agm-marker  [latitude]="alarma.latitud" [longitude]="alarma.longitud"  [iconUrl]="alarma.ColorHexa">
                  </agm-marker>                    
                  
                </ng-container>
            </ng-container>

        </agm-map>
    </div>

    <div class="col-sm">
      <div class="row justify-content-between">
        <div class="col"><app-filter-selector [filter]="this.updatedFilterData"></app-filter-selector></div>
        <div class="col">
          <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.updatedFilterData.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]"></mat-paginator>
        </div>
      </div>
      
      <div class="table-sdw is-fixed">
      
        <mat-table class="adjust" #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.updatedFilterData.paginator.sortField" [matSortDirection]="this.updatedFilterData.paginator.sortDir" class="is-header-sticky">
      
          <ng-container matColumnDef="incorpora" >
              <mat-header-cell  *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><mat-checkbox #checkbox [(ngModel)]="this.allSelected" (change)="SeleccionarTotes()" ></mat-checkbox></mat-header-cell>
              <mat-cell  *matCellDef="let row"  ><mat-checkbox #checkbox [(ngModel)]="row.selected" (click)="ClickCheckbox(row)"></mat-checkbox></mat-cell>
          </ng-container>

          <ng-container matColumnDef="estat">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Estat</mat-header-cell>
            <mat-cell *matCellDef="let row"><span class="badge badge-danger" [hidden]="acceptada(row)" i18n>nou</span><span class="badge" [hidden]="!acceptada(row)" i18n>acceptada</span></mat-cell>
          </ng-container>
          <ng-container matColumnDef="text">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Text</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <span *ngIf="!hasPantalla(row)">{{row.text}}</span>
                <span *ngIf="hasPantalla(row)"><a (click)="onClick(row)" class="is-link">{{row.text}}</a></span>
            </mat-cell>
          </ng-container>
      
            <ng-container matColumnDef="tipusAlarma">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipus</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.tipusAlarma}}</mat-cell>
            </ng-container>
      
            <ng-container matColumnDef="usuari">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Usuari</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.usuari}}</mat-cell>
            </ng-container>
      
            <ng-container matColumnDef="data">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.data | longDate}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="matricula">
              <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matricula</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.matricula}}</mat-cell>
            </ng-container>
      
            <ng-container matColumnDef="botons">
              <mat-header-cell *matHeaderCellDef i18n>Acció</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <!-- <button class="btn btn-outline-primary btn-xs" *ngIf="automatitzacio(row)" title="Ir a detalle" i18n-title (click)="openHistorico(row)"><i class="fa fa-location-arrow"></i></button> -->
                <button class="btn btn-outline-primary btn-xs" [hidden]="acceptada(row)" title="Marcar com llegida" i18n-title (click)="checkAlarm(row.secure_id)"><i class="fa fa-check-double"></i></button>
              </mat-cell>

            </ng-container>
      
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" (click)="SeleccionarFilaAux(row)" [ngClass]="[highlightedRows.indexOf(row) != -1 ? 'is-active' : '', acceptada(row) ? 'accepted' : 'not-accepted']"></mat-row>
          </mat-table>
      </div>
      
    </div>
  </div>

