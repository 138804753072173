import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { InfraccionsTableComponent } from '../components/infraccions-table/infraccions-table.component';
import { InfraccionsFilterComponent } from '../components/infraccions-filter/infraccions-filter.component';
import { InfraccioFilter } from '../models/infraccio-filter';
import { InfraccionsEditorComponent } from '../components/infraccions-editor/infraccions-editor.component';
import { LegislacioService } from '../../legislacions/services/legislacions-service.service';
import { AliesGruaService } from '../../../grua/services/alies-grua.service';

// import {  } from '../components/infraccio-editor/infraccio-editor.component';

@Component({
  selector: 'app-infraccio',
  templateUrl: './infraccio.component.html',
  styleUrls: ['./infraccio.component.scss']
})
export class InfraccioComponent implements OnInit, AfterViewInit {
  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: InfraccioFilter;
  somIdFoo: number;
  tipusLegislacions = Array();
  aliesGrues = Array();
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(InfraccionsTableComponent) infraccionsTable: InfraccionsTableComponent;

  constructor(private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    private legislacioService: LegislacioService,
    private aliesGruaService: AliesGruaService,
    public thisDialogRef: MatDialogRef<InfraccioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new InfraccioFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

    ngOnInit() {
      // Carreguem les legisalcions per a passar-les al dialog
      this.legislacioService.getAllForCombo().subscribe(result => {
        this.tipusLegislacions = [];
        if (result !== null) {
          let i: number;
          i = 0;
          result.forEach(element => {
            this.tipusLegislacions[i] = element;
            i++;
          });
          this.infraccionsTable.tipusLegislacions = this.tipusLegislacions;
        }
      });

      // Carreguem els alies Grua per passar-les al dialog
      this.aliesGruaService.getAllForCombo().subscribe(result => {
        this.aliesGrues = [];
        if (result != null) {
          let i: number;
          i = 0;
          result.forEach(element => {
            this.aliesGrues[i] = element;
            i++;
          });
        }
      });
      this.auth.UserCanSeePage(this.auth.Pages.Infraccions);
      this.infraccionsTable.itemClicked.subscribe(secure_id => {
        this.update(secure_id);
      });
      this.infraccionsTable.itemSelected.subscribe(codi => {
        this.thisDialogRef.close(codi);
      });

  }

    ngAfterViewInit() {
      if (!this.dataDialog.isDialog) {
        this.not.titleChange(this.i18n('Infraccions'));
      }
    }
    create() {
      this.update(null);
    }

    update(secure_id: string) {
      const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);
      data.tag = this.tipusLegislacions;
      const ref = this.dialog.open(InfraccionsEditorComponent, {
        width: '70%',
        closeOnNavigation: false,
        autoFocus: true,
        data: { 'data': data,
                'id': secure_id,
                'tipusLegislacions': this.tipusLegislacions,
                'aliesGrues': this.aliesGrues
        },
      });

      ref.afterClosed().subscribe(updated => {
        if (updated) { this.infraccionsTable.load(); }
      });
    }

    refresh() {
      this.infraccionsTable.load();
    }

    onFilterClicked() {
        const ref = this.dialog.open(InfraccionsFilterComponent, {
        width: '50%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          'filter': this.infraccionsTable.filter,
          'tipusLegislacions': this.tipusLegislacions
        }
      });

      ref.componentInstance.notify.subscribe((filter: InfraccioFilter) => {
        this.infraccionsTable.filter.set(filter);
        this.infraccionsTable.load();
      });
    }

    onRowSelected(somid) {
      this.update(somid);
    }

    generateExcel() {
      this.infraccionsTable.generateExcel();
    }

}
