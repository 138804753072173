import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { api } from 'path-shared/configuration/urls';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { ComprovacioTiquetFilter } from '../pages/comprovacio-tiquets.component';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ComprovacioTiquetsService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
  }

  Get(filter: ComprovacioTiquetFilter): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new HttpParams()
        .append('matricula', filter.matricula)
        .append('data', filter.data === null ? null : filter.data.toDateString())
        .append('numDenuncia', filter.numDenuncia);

      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetComprovacioTiquets'], params);
    }
  }

  GenerateExcel(data: any): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.postFileArrayResponse(config.urls['apiUrl'] + api.endpoints['urlExcelConsutaTickets'],  data , new HttpParams());
    }
  }

}
