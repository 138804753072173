<div class="nav-item dropdown" *ngIf="institucions?.length > 1; else blank" style="margin-right: 20px;">
  <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    {{ currentInstitutionName }}
  </a>
  <div class="dropdown-menu scrollable-menu dropdown-menu-right">
    <ng-container *ngFor="let institucio of institucions">
      <a class="dropdown-item" (click)="changeInstitucio(institucio.IdInstitucion)">{{institucio.NombreInstitucion}}</a>
    </ng-container>
  </div>
</div>

<ng-template #blank>
  <span class="static-label" *ngIf="institucions?.length == 1">
    <div>{{ currentInstitutionName }}</div>
  </span>
</ng-template>
