import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Injectable, Injector } from '@angular/core';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { Observable } from 'rxjs/Observable';
import { AuthGuardService } from '../authentication/auth-guard.service';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';



@Injectable()
export class GoogleMapsService {

    constructor(private injector: Injector) { }

    getMapsApiKey(): Observable<any> {
        const idInstitucion = localStorage.getItem('IdInstitucion');
        const token = localStorage.getItem('access_token');
        if (idInstitucion !== undefined && idInstitucion !== null && token !== undefined && token !== null) {
            // Ya se habia iniciado la web, tenemos las credenciales para actualizar la key de googleMaps.
            if (this.authGuard.canActivate()) {
                return this.get(config.urls['apiUrl'] + api.endpoints['urlConfigMapsApiKey'], null);
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    get(url: string, params: HttpParams, hide = false) {
        return this.http.get(url.replace('{hostname}', window.location.hostname), {params: params, headers: this.createAuthorizationHeader(hide) });
    }

    createAuthorizationHeader(hide = false): HttpHeaders {
        const token = localStorage.getItem('access_token');
        const idInstitucion = localStorage.getItem('IdInstitucion');
        return new HttpHeaders()
        .append('Authorization', 'Bearer ' + token)
        .append('X-Origin', 'SomDenWeb')
        .append('Allow-Control-Allow-Origin', '*')
        .append('Institucio', idInstitucion)
        .append(hide ? 'hideProgress' : 'void', '');
    }

    public get http(): HttpClient {
        return this.injector.get(HttpClient);
    }

    public get cookieService(): CookieService {
        return this.injector.get(CookieService);
    }

    public get authGuard(): AuthGuardService {
        return this.injector.get(AuthGuardService);
    }
}
