import { Injectable, EventEmitter, Output, Input } from '@angular/core';


export class GenericFileEventsService {


    constructor() {
        this.deleteFileCommand = new EventEmitter<any[]>();
        this.deleteFilesComplete = new EventEmitter<any[]>();

        this.uploadFileCommand = new EventEmitter<any>();
        this.uploadFileComplete = new EventEmitter<any>();
    }

    //#region Eventos de eliminacion de ficheros.

    @Output() deleteFileCommand: EventEmitter<any[]>; // Indica al componente parent que debe eliminar ficheros.
    @Input() deleteFilesComplete: EventEmitter<any[]>;  // Indica al GenericFilePageComponent que el parent ya ha eliminado los ficheros.

    //#endregion

    //#region

    @Output() uploadFileCommand: EventEmitter<any>; // Indica al componente paren que debe subir un fichero.
    @Input() uploadFileComplete: EventEmitter<any>; // Indica al GenericFilePageComponent que el paren acaba de subir un nuevo fichero.

    //#endregion
}
