import { I18n } from '@ngx-translate/i18n-polyfill';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';


import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material';

@Injectable()
export class ConfirmationDialogService {
  result = false;
  private modalRef;

  constructor(private modalService: MatDialog, private i18n: I18n) { }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = this.i18n('Confirmar'),
    btnCancelText: string = this.i18n('Cancel·lar'),
    html: Boolean = false
    ): Promise<boolean> {

    this.modalRef = this.modalService.open(ConfirmationDialogComponent);
    this.modalRef.componentInstance.title = title;
    this.modalRef.componentInstance.message = message;
    this.modalRef.componentInstance.btnOkText = btnOkText;
    this.modalRef.componentInstance.btnCancelText = btnCancelText;
    this.modalRef.componentInstance.html = html;
    return this.modalRef.afterClosed().toPromise();
  }

}
