<div class="card">
  <a [routerLink]="[link]" title="{{ linkLabel }}">
    <div class="card-body p-3 d-flex align-items-center">
      <i class="{{ icon }} {{ 'bg-'+iconClass }} p-3 font-2xl mr-3"></i>
      <div>
        <div class="text-value-sm {{ 'text-' + iconClass }}">{{ text }}</div>
        <div class="text-muted text-uppercase font-weight-bold small"> {{ title }} </div>
      </div>
    </div>
  </a>
</div>
