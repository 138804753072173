<ng-template #myDialogC60 class="col-12">
  <!-- <app-input-progress-bar [(value)]="this.porcentajeTramitados"  class="col-12" i18n-label label="Progrés"></app-input-progress-bar> -->
  <div *ngIf="this.porcentajeC60 !== null" class="d-flex justify-content-center">
    <div>
      <h2> <span i18n >Creando C60 {{this.mensajePorcentajeC60}} </span></h2> 
    </div>
  </div>
  <section *ngIf="this.porcentajeC60 !== null" class="progress-bar-section">
      <div class="col-12">
          <mat-progress-bar mode="determinate" value={{this.porcentajeC60}}></mat-progress-bar>
      </div>
      
      
  </section>
  <div *ngIf="this.porcentajeC60 !== null" class="d-flex justify-content-center">
    <div style=" color:  #1E457C;" >
      <div> 
        <h3> {{this.porcentajeC60 | number:'1.2-2'}}% </h3>
      </div>    
    </div>
  </div>
</ng-template>

<ng-template #myDialogNotificaciones class="col-12">
  <!-- <app-input-progress-bar [(value)]="this.porcentajeTramitados"  class="col-12" i18n-label label="Progrés"></app-input-progress-bar> -->
  <div *ngIf="this.porcentajeNotificaciones !== null"   class="d-flex justify-content-center"> 
    <div>
      <h2> 
        <span i18n >Generando notificacion {{this.mensajePorcentajeNotificaciones}} </span>
      </h2>
    </div>
   </div>
  <section *ngIf="this.porcentajeNotificaciones !== null" class="progress-bar-section">
      <div class="col-12">
          <mat-progress-bar mode="determinate" value={{this.porcentajeNotificaciones}}></mat-progress-bar>
      </div>
  </section>
  <div *ngIf="this.porcentajeNotificaciones !== null" class="d-flex justify-content-center">
    <div style=" color: #1E457C;" >
      <div> 
        <h3> {{this.porcentajeNotificaciones | number:'1.2-2'}}% </h3>
      </div>    
    </div>
  </div>
</ng-template>


<div class="row mt-3">
  <div class="col-sm-1" title i18n>Tipo Envio</div>
  <div class="col-sm-2" title i18n>
    <mat-radio-group class="radiobutton" class="row">
      <mat-radio-button value="sicer" [disabled]="!this.infoNotificar.SicerActivo" [checked]="this.infoNotificar.SicerActivo" *ngIf="this.infoNotificar.SicerActivo" class="col-6">
        Sicer
      </mat-radio-button>
      <mat-radio-button value="manual" [checked]="!this.infoNotificar.SicerActivo" class="col-6">Manual</mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<mat-card>
  <mat-card-subtitle title i18n>Fecha Último Envio</mat-card-subtitle>
  <mat-card-content>
    <div class="row">
      <div class="col-4" style="width: 100%; align-items: center;display: flex; justify-content: center;" *ngIf="!this.infoNotificacioLoaded">
        <img src="assets\images\busy.gif" style="width: 30px; height: 30px;"/>
      </div>
      <mat-list class="col-4" *ngIf="this.infoNotificacioLoaded">
        <mat-list-item class="row card" style="margin-bottom: 0rem !important;"> 
          <div class="col-6">
            {{this.infoNotificar.MaxDataEnviamentDenuncies | date: 'dd/MM/yyyy'}}
          </div>
          <div class="col-6" style="margin-left: 5%;">
            <div class="row">
              <button class="btn btn-primary col-11" (click)="this.procesarDenuncias()" [disabled]="!this.infoNotificacioLoaded" >
                Fet Denuncias {{ this.infoNotificar.EnvioDenunciesCurrent }} - {{ this.infoNotificar.EnvioDenunciesAll }}
              </button>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item class="row card" style="margin-bottom: 0rem !important;"> 
          <div class="col-6">
            {{this.infoNotificar.MaxDataPropostes | date: 'dd/MM/yyyy'}}  
          </div>
          <div class="col-6" style="margin-left: 5%;">
            <div class="row">
              <button class="btn btn-primary col-11" (click)="this.procesarPropuestasResolucion()" [disabled]="!this.infoNotificacioLoaded" >
                Fet  Prop. Resolución {{ this.infoNotificar.PropostesCurrent }} - {{ this.infoNotificar.PropostesAll }}
              </button>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item class="row card" style="height: fit-content;margin-bottom: 0rem !important;">
          <div class="col-6 mt-1 mb-1">
            {{this.infoNotificar.MaxDataResolucions | date: 'dd/MM/yyyy'}}
          </div>
          <div class="col-6 mt-1 mb-1" style="margin-left: 5%;">
            <div class="row">
              <button class="btn btn-primary col-11" (click)="this.procesarResolucionesv2()" [disabled]="!this.infoNotificacioLoaded">Resoluciones {{ this.infoNotificar.Resolucions }}  </button> 
            </div>
            <div class="row">
              <button class="btn btn-primary col-11" (click)="this.procesarNotificacionesResoluciones()" [disabled]="!this.infoNotificacioLoaded">
                Not. Resoluciones {{ this.infoNotificar.NoResolucionsCurrent }} - {{ this.infoNotificar.NoResolucionsAll }}
              </button>  
            </div> 
          </div>
        </mat-list-item>
        <mat-list-item class="row card" style="margin-bottom: 0rem !important;"> 
          <div class="col-6">
            {{this.infoNotificar.MaxDataReposicio | date: 'dd/MM/yyyy'}}
          </div>
          <div class="col-6" style="margin-left: 5%;">
            <div class="row">
              <button class="btn btn-primary col-11" (click)="this.procesarRecursoReposicion()" [disabled]="!this.infoNotificacioLoaded" >
                Recursos Reposición {{ this.infoNotificar.RecursosCurrent }} - {{ this.infoNotificar.RecursosAll }}
              </button>    
            </div>
          </div>
        </mat-list-item>
        
        <!-- <mat-list-item class="row">
          <div class="col-4"></div>
          <button class="col-2 btn btn-primary col-11" (click)="this.consultar()">Consultas</button>  
          <div clasS="col-4"></div>
        </mat-list-item> -->
      </mat-list>
    </div>
  </mat-card-content>
</mat-card>
    