import { OnInit } from '@angular/core';
import { Builder } from 'path-shared/models/builder';
var EscalaOcupacionEditorComponent = /** @class */ (function () {
    function EscalaOcupacionEditorComponent() {
        this.isError = false;
        this.currentEscala = Builder.newEscalaOcupacion();
        this.currentEditing = false;
        this.coloresBase = new Array();
    }
    Object.defineProperty(EscalaOcupacionEditorComponent.prototype, "currentEditing", {
        get: function () {
            return this._currentEditing;
        },
        set: function (val) {
            this._currentEditing = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EscalaOcupacionEditorComponent.prototype, "currentEscala", {
        get: function () {
            return this._currentEscala;
        },
        set: function (val) {
            if (val !== undefined && val !== null) {
                this._currentEscala = val;
            }
            else {
                this._currentEscala = Builder.newEscalaOcupacion();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EscalaOcupacionEditorComponent.prototype, "coloresBase", {
        get: function () {
            return this._coloresBase;
        },
        set: function (val) {
            this._coloresBase = val;
        },
        enumerable: true,
        configurable: true
    });
    EscalaOcupacionEditorComponent.prototype.ngOnInit = function () {
    };
    EscalaOcupacionEditorComponent.prototype.getColor = function (color) {
        if (color !== this.currentEscala.color) {
            return color + '59';
        }
        else {
            return color;
        }
    };
    EscalaOcupacionEditorComponent.prototype.changeColor = function (color) {
        if (this.currentEscala !== undefined && this.currentEscala.color !== color) {
            this.currentEscala.color = color;
        }
    };
    EscalaOcupacionEditorComponent.prototype.showFillFieldsMessage = function () {
        this.isError = true;
    };
    return EscalaOcupacionEditorComponent;
}());
export { EscalaOcupacionEditorComponent };
