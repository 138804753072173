/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alarm-header-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./alarm-header-menu.component";
var styles_AlarmHeaderMenuComponent = [i0.styles];
var RenderType_AlarmHeaderMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlarmHeaderMenuComponent, data: {} });
export { RenderType_AlarmHeaderMenuComponent as RenderType_AlarmHeaderMenuComponent };
export function View_AlarmHeaderMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "dropdown-item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "badge badge-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "/alarmes/", _co.alarm.idTipusAlarma, ""); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.alarm.tipusAlarma; _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.alarm.count; _ck(_v, 4, 0, currVal_4); }); }
export function View_AlarmHeaderMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alarm-header-menu", [], null, null, null, View_AlarmHeaderMenuComponent_0, RenderType_AlarmHeaderMenuComponent)), i1.ɵdid(1, 114688, null, 0, i4.AlarmHeaderMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlarmHeaderMenuComponentNgFactory = i1.ɵccf("app-alarm-header-menu", i4.AlarmHeaderMenuComponent, View_AlarmHeaderMenuComponent_Host_0, { alarm: "alarm" }, {}, []);
export { AlarmHeaderMenuComponentNgFactory as AlarmHeaderMenuComponentNgFactory };
