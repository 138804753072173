<app-dialog-header label="Motiu Desestimar" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.MotiusDesestimar) || !this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.MotiusDesestimar) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.MotiusDesestimar) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="motius-desestimar-editor-descripcio" class="col-sm-9" i18n-label label="Descripcio" [(value)]="this.motiuDesestimar.descripcio" required maxLenght="50"></app-form-text-edit>
    </div>
    <div class="row">
        <app-form-check-edit class="col-sm-9" i18n-label label="Canvi Matrícula" [(value)]="this.motiuDesestimar.canviMatricula"></app-form-check-edit>
        <div [hidden]="this.motiuDesestimar?.canviMatricula === false" class="alert alert-warning" i18n>Només hi pot haber un tipus amb canvi de marícula. Es desmarcarà qualsevol altre tipus que tingui canvi de matrícula activat.</div>
    </div>
  </form>
</app-dialog-body>
