var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var DipositGruaFilter = /** @class */ (function (_super) {
    __extends(DipositGruaFilter, _super);
    function DipositGruaFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.dataEntradaInici = null;
        _this.dataEntradaFinal = null;
        _this.dataSortidaInici = null;
        _this.dataSortidaFinal = null;
        _this.idTipusVehicleFK = '';
        _this.idMotiuEntradaFK = '';
        _this.idTecnicFK = '';
        _this.matricula = '';
        _this.diposits = null;
        _this.labels['dataEntradaInici'] = _this.i18n('Data entrada inici');
        _this.labels['dataEntradaFinal'] = _this.i18n('Data entrada final');
        _this.labels['dataSortidaInici'] = _this.i18n('Data sortida inici');
        _this.labels['dataSortidaFinal'] = _this.i18n('Data sortida final');
        _this.labels['idTipusVehicleFK'] = _this.i18n('Tipus vehicle');
        _this.labels['idMotiuEntradaFK'] = _this.i18n('Motiu entrada');
        _this.labels['idTecnicFK'] = _this.i18n('Tècnic');
        _this.labels['matricula'] = _this.i18n('Matricula');
        _this.labels['diposits'] = _this.i18n('Dipòsits');
        _this.formatOutput['dataEntradaInici'] = 'shortDate';
        _this.formatOutput['dataEntradaFinal'] = 'shortDate';
        _this.formatOutput['dataSortidaInici'] = 'shortDate';
        _this.formatOutput['dataSortidaFinal'] = 'shortDate';
        _this.formatOutput['diposits'] = 'multiSelect';
        return _this;
    }
    return DipositGruaFilter;
}(FilterData));
export { DipositGruaFilter };
