import { CustomDataMarker } from './../../../../livemap/models/custom-data-marker';
import { MapComponent } from './../../../../livemap/components/map/map.component';
import { GenericFileDisplayerFrameComponent } from './../../../../../shared/components/generic-file-displayer/components/generic-file-displayer-frame/generic-file-displayer-frame.component';
import { GenericFileDisplayerPageComponent } from './../../../../../shared/components/generic-file-displayer/components/generic-file-displayer-page/generic-file-displayer-page.component';
import { GenericFile } from './../../../../../shared/components/generic-file-displayer/model/generic-file';
import { TipoSenalVertical } from './../../../tipos-senales-verticales/models/tipo-senal-vertical';
import { BehaviorSubject } from 'rxjs';
import { TipoSenalesVerticalesService } from './../../../tipos-senales-verticales/services/tipo-senales-verticales-service';
import { Dictionary } from './../../../../../shared/models/dictionary';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from './../../../../../shared/services/form/form.service';
import { SenalesVerticalesService } from './../../services/senales-verticales.service';
import { SenalVertical } from './../../models/senal-vertical';
import { Component, OnInit, ViewChild, ElementRef, Inject, EventEmitter } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material';
import { TipoSenalesVerticalesEditorComponent } from 'app/sections/configuracio/tipos-senales-verticales/components/tipo-senales-verticales-editor/tipo-senales-verticales-editor.component';
import { Builder } from 'path-shared/models/builder';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';
import { GenericFileEventsService } from 'path-shared/components/generic-file-displayer/services/generic-file-events.service';
import { MapFeatures } from 'app/sections/livemap/models/map-features';

@Component({
  selector: 'app-senales-verticales-editor',
  templateUrl: './senales-verticales-editor.component.html',
  styleUrls: ['./senales-verticales-editor.component.scss']
})
export class SenalesVerticalesEditorComponent implements OnInit {

  secureId: string;
  senal: SenalVertical;
  dialogData: ComponentDialog;
  genericFilesPageComponent: MatDialogRef<GenericFileDisplayerPageComponent>;

  tipoSenalesDictionary: BehaviorSubject<Array<Dictionary>>;
  tipoSenales: TipoSenalVertical[];

  senalLocation: BehaviorSubject<any>;

  @ViewChild ('comboTipoSenal') comboTipoSenal: ComboBoxInputComponent;
  @ViewChild('mainForm') mainForm: ElementRef;
  @ViewChild('map') map: MapComponent;

  fileEvents: GenericFileEventsService;

  constructor(
    public auth: AuthorizationService,
    private senalsService: SenalesVerticalesService,
    private i18n: I18n,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public thisDialogRef: MatDialogRef<SenalesVerticalesEditorComponent>,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data) {

    this.senal = Builder.newSenalVertical();
    this.dialogData = data.data;

    if (data.tiposSenalesVerticales !== undefined && data.tiposSenalesVerticales !== null) {
      this.tipoSenales = data.tiposSenalesVerticales;
    }

    this.secureId = this.dialogData.id;
    this.tipoSenalesDictionary = new BehaviorSubject(undefined);

    this.senalLocation = new BehaviorSubject(undefined);
  }

  ngOnInit() {
    // Si nos han abierto el modal con un secureId debemos descargar la info asociada a la señal con este secureId.
    if (this.secureId !== null) {
      this.senalsService.getSenalVertical(this.secureId).subscribe(data => {
        this.senal = data;

        // Ya tenemos un marker definido.
        this.setupLocation();

        // Si ya tenemos la info de los tipos de señales, seleccionamos en el combo el tipo que tiene la señal.
        if (this.tipoSenalesDictionary.value !== undefined && this.tipoSenalesDictionary.value !== null) {
          this.comboTipoSenal.markAsTouched();
          this.comboTipoSenal.setSelect(this.senal.tipoFk);
        }
      });
    }

    if (this.tipoSenales !== null && this.tipoSenales !== undefined) {
      this.comboTipoSenal.resetShowingOptions();
      const tipos = new Array();
      this.tipoSenales.forEach(element => {
        tipos.push(new Dictionary(element.id, element.descripcion));
      });
      this.tipoSenalesDictionary.next(tipos);

      // Si ya tenemos informacion de la señal, seleccionamos en el combo el tipo que tenga definida la señal.
      if (this.senal !== undefined && this.senal !== null && this.senal.tipoFk !== undefined && this.senal.tipoFk !== null) {
        this.comboTipoSenal.markAsTouched();
        this.comboTipoSenal.setSelect(this.senal.tipoFk);
      }
    }
  }

  save(): void {
    if (this.formService.allFieldsValid(this.mainForm)) {
      this.senalsService.setSenalVertical(this.senal).subscribe(result => {
        this.toastr.info(this.i18n('Senyal Vertical guardada correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel(): void {
    this.thisDialogRef.close(false);
  }

  remove(): void {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la senyal vertical?'))
    .then(confirmed => {
      if (confirmed) {
        this.senalsService.deleteSenalVertical(this.secureId).subscribe(result => {
          this.toastr.success(this.i18n('Senyal esborrada correctament'));
          this.thisDialogRef.close(true);
        });
      }
    });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let formattedResult = reader.result.toString();
          const indexTo = formattedResult.indexOf('base64,');
          formattedResult = formattedResult.substr(indexTo + 'base64,'.length);
          resolve(formattedResult);
        };
        reader.onerror = error => reject(error);
    });
  }

  fitxers(): void {
    // Nos suscribimos a los eventos de ficheros (solo la primera vez).
    this.fileEvents = new GenericFileEventsService();
    this.fileEvents.deleteFileCommand.subscribe(idsToDelete => {
      this.senalsService.deleteSenalFicheros(idsToDelete).subscribe(result => {
        // Ya hemos eliminado, indicamos al generic files component que updatee la lista.
        this.fileEvents.deleteFilesComplete.emit(idsToDelete);
      });
    });
    this.fileEvents.uploadFileCommand.subscribe((file: File) => {
      this.getBase64(file).then(data => {
        const ficheroGenerico = <GenericFile> {
            nombre: file.name,
            fichero: data,
            idRegistroAsociado: this.senal.id
        };
        // Tenemos el fichero para enviar al back.
        this.senalsService.uploadSenalFichero(ficheroGenerico).subscribe(result => {
          // Ya hemos subido el fichero, ahora lo mostramos en el listado.
          this.fileEvents.uploadFileComplete.emit(ficheroGenerico);
        });
      });
    });

    // Descargamos los ficheros y abrimos el componente para mostrarlos.
    this.senalsService.getFicherosSenal(this.senal.id).subscribe((result: GenericFile[]) => {
      // Si hemos obtenido los ficheros, podemos abrir la galeria para mostrarlos.
      if (result !== null && result !== undefined) {
        this.genericFilesPageComponent = this.dialog.open(
          GenericFileDisplayerPageComponent,
          {
            minWidth: '50%',
            minHeight: '90%',
            width: '50%',
            height: '90%',
            maxWidth: '50%',
            maxHeight: '90%',
            closeOnNavigation: false,
            data: {
              'files': result,
              'fileEvents': this.fileEvents
            },
          }
        );
      }
    });
  }

  tipoSenalSeleccionada(valor): void {
    this.senal.tipoFk = valor;
    const element = this.tipoSenalesDictionary.value.find(t => t.key === valor);
    this.senal.tipoDescripcion = element.value;
  }

  setupLocation() {
    if (this.senal.latitud !== undefined && this.senal.latitud !== null && this.senal.latitud !== 0 &&
        this.senal.longitud !== undefined && this.senal.longitud !== null && this.senal.longitud !== 0) {
      const locationStructure = {
        latitude: parseFloat(this.senal.latitud.toString()),
        longitude: parseFloat(this.senal.longitud.toString())
      };
      this.senalLocation.next(locationStructure);
      const dataMarker = new CustomDataMarker(
        this.senal.id,
        MapFeatures.SENYALS_VERTICALS,
        '',
        locationStructure.latitude,
        locationStructure.longitude,
        'google.maps.SymbolPath.CIRCLE',
        '#cf0469',
        0.15,
        '\uf041',
        true,
        true,
        false);
      this.map.removeMarkersOfType(MapFeatures.SENYALS_VERTICALS);
      this.map.appendNewMarkers([ dataMarker ]);
    }
  }

  latitudChanged(newValue) {
    this.senal.latitud = newValue;
    this.setupLocation();
  }

  longitudChanged(newValue) {
    this.senal.longitud = newValue;
    this.setupLocation();
  }

  updateMarkerPostion(markerPosition: Dictionary) {
    this.senal.latitud = markerPosition.key;
    this.senal.longitud = markerPosition.value;
  }

}
