import { Injectable } from '@angular/core';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Observable } from 'rxjs';
import { ZbRuta } from '../models/zb-ruta';

@Injectable({
  providedIn: 'root'
})
export class ZbRutasService {

  constructor(
    private http: HttpSecureClient,
    private authGuard:AuthGuardService) {
    }
    
    setZbRuta(zbRuta: ZbRuta): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetZbRuta'],zbRuta);
      }
    }

    getAllZbRutas(): Observable<any> {
      if (this.authGuard.canActivate()){
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllZbRutas'],null);
      }
    }

}
