import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { TarifesFilter } from '../models/tarifes-filter';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class TarifesFilterService extends CustomFilterService {
  public createFilterParams(filter: TarifesFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('descripcio', filter.descripcio)
    .append('codi', filter.codi === null ? '' : filter.codi.toString())
    .append('dataInici', filter.dataInici === null ? '' : filter.dataInici.toString())
    .append('dataFi', filter.dataFi === null ? '' : filter.dataFi.toString())
    .append('tipusCalcul', filter.tipusCalcul === null ? '' : filter.tipusCalcul.toString())
    .append('valor', filter.valor === null ? '' : filter.valor.toString())
    .append('valorMinim', filter.valorMinim === null ? '' : filter.valorMinim.toString())
    .append('valorMaxim', filter.valorMaxim === null ? '' : filter.valorMaxim.toString())
    .append('carenciaDenuncia', filter.carenciaDenuncia === null ? '' : filter.carenciaDenuncia.toString())
    .append('increment', filter.increment === null ? '' : filter.increment.toString())
    .append('anulSenseTiquet', filter.anulSenseTiquet === null ? '' : filter.anulSenseTiquet.toString())
    .append('tempsSenseTiquet', filter.tempsSenseTiquet === null ? '' : filter.tempsSenseTiquet.toString())
    .append('anulExhaurit', filter.anulExhaurit === null ? '' : filter.anulExhaurit.toString())
    .append('tempsExhaurit', filter.tempsExhaurit === null ? '' : filter.tempsExhaurit.toString())
    .append('desde1', filter.desde1.replace(':', ''))
    .append('iniciPausa1', filter.iniciPausa1.replace(':', ''))
    .append('fiPausa1', filter.fiPausa1.replace(':', ''))
    .append('hasta1', filter.hasta1.replace(':', ''))
    .append('desde2', filter.desde2.replace(':', ''))
    .append('iniciPausa2', filter.iniciPausa2.replace(':', ''))
    .append('fiPausa2', filter.fiPausa2.replace(':', ''))
    .append('hasta2', filter.hasta2.replace(':', ''))
    .append('desde3', filter.desde3.replace(':', ''))
    .append('iniciPausa3', filter.iniciPausa3.replace(':', ''))
    .append('fiPausa3', filter.fiPausa3.replace(':', ''))
    .append('hasta3', filter.hasta3.replace(':', ''))
    .append('desde4', filter.desde4.replace(':', ''))
    .append('iniciPausa4', filter.iniciPausa4.replace(':', ''))
    .append('fiPausa4', filter.fiPausa4.replace(':', ''))
    .append('hasta4', filter.hasta4.replace(':', ''))
    .append('desde5', filter.desde5.replace(':', ''))
    .append('iniciPausa5', filter.iniciPausa5.replace(':', ''))
    .append('fiPausa5', filter.fiPausa5.replace(':', ''))
    .append('hasta5', filter.hasta5.replace(':', ''))
    .append('desde6', filter.desde6.replace(':', ''))
    .append('iniciPausa6', filter.iniciPausa6.replace(':', ''))
    .append('fiPausa6', filter.fiPausa6.replace(':', ''))
    .append('hasta6', filter.hasta6.replace(':', ''))
    .append('desde7', filter.desde7.replace(':', ''))
    .append('iniciPausa7', filter.iniciPausa7.replace(':', ''))
    .append('fiPausa7', filter.fiPausa7.replace(':', ''))
    .append('hasta7', filter.hasta7.replace(':', ''))
    .append('idZona', filter.idZona === null ? '' : filter.idZona.toString())
    .append('idResident', filter.idResident === null ? '' : filter.idResident.toString())
    .append('idTiquet', filter.idTiquet === null ? '' : filter.idTiquet.toString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
