import { Component, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { AgmMap } from '@agm/core';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { Parametros } from 'path-shared/models/parametros';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { CFG_PARAMETROS } from 'path-models/CFG_PARAMETROS';
import { DeltacarHistoricoFilter } from '../models/deltacar-historico-filter';
import { DeltacarHistorico } from '../models/deltacar-historico.model';
import { GoogleMapsService } from 'path-shared/services/startup/google-maps.service';
import { DeltacarHistoricoTableComponent } from '../components/deltacar-historico-table/deltacar-historico-table.component';
import { AppComponent } from 'app/app.component';
import { DeltacarHistoricoFilterComponent } from '../components/deltacar-historico-filter/deltacar-historico-filter.component';

@Component({
  selector: 'app-deltacar-historico',
  templateUrl: './deltacar-historico.component.html',
  styleUrls: ['./deltacar-historico.component.scss']
})
export class DeltacarHistoricoComponent implements OnInit, AfterViewInit {
  activeFilters: string[] = [];
  filterData: DeltacarHistoricoFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  rows: DeltacarHistorico[];
  latitud: number;
  longitud: number;
  zoom: number;
  selectedRow: DeltacarHistorico;
  estados = [{descripcio: ''}, {descripcio: 'CADUCADA'},{descripcio: 'ACEPTADA'},{descripcio: 'DESESTIMADA'}]

  @ViewChild(DeltacarHistoricoTableComponent) deltacarHistoricoTable: DeltacarHistoricoTableComponent;
  @ViewChild('AgmMap') agmMap: AgmMap;


  constructor(
    private googleMapsService: GoogleMapsService,
    private authGuard: AuthGuardService,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    private parametersService: ParametersService,
    public thisDialogRef: MatDialogRef<DeltacarHistoricoComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.rows = [];
    this.filterData = new DeltacarHistoricoFilter('fecha', 'desc');
    if (data !== undefined) {
      if (data.filter !== undefined) {
        this.dataDialog = data;
        this.filterData = data.filter;
      }
    }
    this.setDefaultLatLon();

  }

  ngOnInit() {
    this.deltacarHistoricoTable.filter = this.filterData;

    this.deltacarHistoricoTable.data.subscribe((data) => {
      this.rows = data;
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Histórico DeltaCar'));
    }
  }

  setDefaultLatLon() {
    this.latitud = AppComponent.getInstitution().Latitud;
    this.longitud = AppComponent.getInstitution().Longitud;
    this.zoom = Number.parseInt(this.parametersService.getParameter(Parametros.GPSZOMINICIAL));
  }

  refresh() {
    this.rows = [];
    this.deltacarHistoricoTable.filter.set(this.filterData);
    this.deltacarHistoricoTable.load();

  }

  generateExcel() {
    this.deltacarHistoricoTable.generateExcel();
  }

  close() {
    this.thisDialogRef.close();
  }

  selectRow($event: number) {
    const index = this.rows.findIndex(x => x.id === $event);
    this.selectedRow = this.rows[index];

    this.latitud = +this.selectedRow.latitud;
    this.longitud = +this.selectedRow.longitud;
    this.zoom = Number.parseInt(this.parametersService.getParameter(Parametros.GPSZOMINICIAL));

  }

  onFilterClicked() {
    const ref = this.dialog.open(DeltacarHistoricoFilterComponent, {
      width: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.deltacarHistoricoTable.filter
      }
    });

  }

  rowsChecked(): DeltacarHistorico[] {
    return this.deltacarHistoricoTable.displayedItems.filter(x => x.checked === true);
  }
  markerClick(){}

}
