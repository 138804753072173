/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tramitador-configuration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i4 from "../../../../shared/components/dialog/dialog-body/dialog-body.component.ngfactory";
import * as i5 from "../../../../shared/components/dialog/dialog-body/dialog-body.component";
import * as i6 from "../../../../shared/components/form/form-check-edit/form-check-edit.component.ngfactory";
import * as i7 from "../../../../shared/components/form/form-check-edit/form-check-edit.component";
import * as i8 from "../../../../shared/services/validation/validation.service";
import * as i9 from "./tramitador-configuration.component";
import * as i10 from "../../../../shared/services/FiltersConfiguration/filters-configuration.service";
import * as i11 from "@angular/material/dialog";
var styles_TramitadorConfigurationComponent = [i0.styles];
var RenderType_TramitadorConfigurationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TramitadorConfigurationComponent, data: {} });
export { RenderType_TramitadorConfigurationComponent as RenderType_TramitadorConfigurationComponent };
export function View_TramitadorConfigurationComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["label", "Configurar filtres"]], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 17, "app-dialog-body", [], null, null, null, i4.View_DialogBodyComponent_0, i4.RenderType_DialogBodyComponent)), i1.ɵdid(3, 114688, null, 0, i5.DialogBodyComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "p", [["style", "margin-top:1vh"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Indiqui els diferents filtres que desitja que apareixin"])), (_l()(), i1.ɵeld(6, 0, null, 0, 10, "div", [["class", "card card-checks"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-form-check-edit", [["label", "Tipus de den\u00FAncia"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filtro.tipo = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FormCheckEditComponent_0, i6.RenderType_FormCheckEditComponent)), i1.ɵdid(8, 114688, null, 0, i7.FormCheckEditComponent, [i8.ValidationService], { label: [0, "label"], value: [1, "value"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-form-check-edit", [["label", "Legislaci\u00F3"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filtro.legislacion = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FormCheckEditComponent_0, i6.RenderType_FormCheckEditComponent)), i1.ɵdid(10, 114688, null, 0, i7.FormCheckEditComponent, [i8.ValidationService], { label: [0, "label"], value: [1, "value"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-form-check-edit", [["label", "Origen"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filtro.origen = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FormCheckEditComponent_0, i6.RenderType_FormCheckEditComponent)), i1.ɵdid(12, 114688, null, 0, i7.FormCheckEditComponent, [i8.ValidationService], { label: [0, "label"], value: [1, "value"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-form-check-edit", [["label", "Tipus de matr\u00EDcula"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filtro.matricula = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FormCheckEditComponent_0, i6.RenderType_FormCheckEditComponent)), i1.ɵdid(14, 114688, null, 0, i7.FormCheckEditComponent, [i8.ValidationService], { label: [0, "label"], value: [1, "value"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-form-check-edit", [["label", "Imatges"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filtro.fotos = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FormCheckEditComponent_0, i6.RenderType_FormCheckEditComponent)), i1.ɵdid(16, 114688, null, 0, i7.FormCheckEditComponent, [i8.ValidationService], { label: [0, "label"], value: [1, "value"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(17, 0, null, 0, 2, "div", [["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveChanges() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Guardar canvis"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Configurar filtres"; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); var currVal_1 = "Tipus de den\u00FAncia"; var currVal_2 = _co.filtro.tipo; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = "Legislaci\u00F3"; var currVal_4 = _co.filtro.legislacion; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = "Origen"; var currVal_6 = _co.filtro.origen; _ck(_v, 12, 0, currVal_5, currVal_6); var currVal_7 = "Tipus de matr\u00EDcula"; var currVal_8 = _co.filtro.matricula; _ck(_v, 14, 0, currVal_7, currVal_8); var currVal_9 = "Imatges"; var currVal_10 = _co.filtro.fotos; _ck(_v, 16, 0, currVal_9, currVal_10); }, null); }
export function View_TramitadorConfigurationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tramitador-configuration", [], null, null, null, View_TramitadorConfigurationComponent_0, RenderType_TramitadorConfigurationComponent)), i1.ɵdid(1, 114688, null, 0, i9.TramitadorConfigurationComponent, [i10.FiltersConfigurationService, i11.MatDialogRef, i11.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TramitadorConfigurationComponentNgFactory = i1.ɵccf("app-tramitador-configuration", i9.TramitadorConfigurationComponent, View_TramitadorConfigurationComponent_Host_0, {}, {}, []);
export { TramitadorConfigurationComponentNgFactory as TramitadorConfigurationComponentNgFactory };
