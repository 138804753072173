import { SortDirection } from "@angular/material";
import { FilterData } from "path-shared/models/filter-data";

export class ActuacioMatriculaFilter extends FilterData {
    matricula: string;
    datainici: Date;
    datafi: Date;

    constructor(sortField: string, sortDir: SortDirection) {
        super(sortField, sortDir);
        this.matricula='';
        this.datainici= new Date();
        this.datafi= new Date();

      this.labels['matricula'] = this.i18n('Matricula');
      this.labels['datainici'] = this.i18n('Data inicial');
      this.labels['datafi'] = this.i18n('Data final');


    }
}