import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Injectable } from '@angular/core';
import { ReservesFilter } from '../models/reserves-filter';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class ReservesFilterService extends CustomFilterService {

    public createFilterParams(filter: ReservesFilter, start: number, pageSize: number, sortField: string, sortDir: string) {
        const httpParams = new HttpParams()
        .append('id', filter.id ? filter.id.toString() : '')
        .append('matricula', filter.matricula)
        .append('email', filter.email)
        .append('fechaInicioDia', filter.fechaInicio ? this.formatDateToDecimal(new Date(filter.fechaInicio)) : '')
        .append('fechaFinDia', filter.fechaInicio ? this.formatDateToDecimal(new Date(filter.fechaInicio), false) : '')
        .append('tipoVehiculo', filter.tipoVehiculo ? filter.tipoVehiculo.toString() : '')
        .append('start', start.toString())
        .append('size', pageSize.toString())
        .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
        .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
        return httpParams;
    }

    formatDateToDecimal(date: Date, isFechaIni: boolean = true): string {
      let month: string;
      let day: string;
      let hhmmss: string;
      month = (date.getMonth() + 1).toString();
      if (month.length === 1) {
        month = '0' + month;
      }
      day = date.getDate().toString();
      if (day.length === 1) {
        day = '0' + day;
      }
      if (isFechaIni) {
        hhmmss = '000000';
      } else {
        hhmmss = '235959';
      }
      return date.getFullYear().toString()
              + month
              + day
              + hhmmss;
    }
}
