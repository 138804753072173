import { Dictionary } from 'path-shared/models/dictionary';
import { TextoInformativoFilter } from './../../model/texto-informativo.filter';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'app-texto-informativo-filter',
  templateUrl: './texto-informativo-filter.component.html',
  styleUrls: ['./texto-informativo-filter.component.scss']
})
export class TextoInformativoFilterComponent implements OnInit {

  filter: TextoInformativoFilter;
  idiomaDictionary: Array<Dictionary>;

  @Output() notify: EventEmitter<TextoInformativoFilter> = new EventEmitter<TextoInformativoFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<TextoInformativoFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.filter = data.filter;
    this.idiomaDictionary = data.idiomas;
  }

  ngOnInit() {
  }

  reset(): void {
    this.filter.clear();
    this.apply();
  }

  apply(): void {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss(): void {
    this.thisDialogRef.close();
  }

  siglaSeleccionada(sigla: any) {
    this.filter.idioma = sigla;
  }
}
