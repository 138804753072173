var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var AcuseFilter = /** @class */ (function (_super) {
    __extends(AcuseFilter, _super);
    function AcuseFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.expedient = null;
        _this.tipus = '';
        _this.resultat = 0;
        _this.dataInici = null;
        _this.dataFinal = null;
        _this.nomComplet = '';
        _this.numDenuncia = '';
        _this.labels['expedient'] = _this.i18n('Expedient');
        _this.labels['tipus'] = _this.i18n('Tipus');
        _this.labels['resultat'] = _this.i18n('Resultat');
        _this.labels['dataInici'] = _this.i18n('DataInici');
        _this.labels['dataFinal'] = _this.i18n('DataFinal');
        _this.labels['nomComplet'] = _this.i18n('NomComplet');
        _this.labels['numDenuncia'] = _this.i18n('NumDenuncia');
        return _this;
    }
    return AcuseFilter;
}(FilterData));
export { AcuseFilter };
