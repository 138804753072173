import { Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ZonaAparcare } from "app/sections/tiquets/zones-aparcare/models/zona-aparcare.model";
import { ZonesAparcareService } from "app/sections/tiquets/zones-aparcare/services/zones-aparcare.service";
import { Observable, Subscription } from "rxjs";
import { TiquetsClientFilter } from "./tiquets-client-filter";

@Component({
  selector: 'app-tiquets-client-filter',
  templateUrl: './tiquets-client-filter.component.html' 
})
export class TiquetsClientFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  typeLabels = ['Data ticket'];
  typeValues = [true, false];
  tipusData: boolean;

  filter: TiquetsClientFilter;
  fechaHoraActual: Date;
  public zonas: ZonaAparcare[];
  public secureIdZona:'';
  @Output() notify: EventEmitter<TiquetsClientFilter> = new EventEmitter<TiquetsClientFilter>();

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    public thisDialogRef: MatDialogRef<TiquetsClientFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
      ) {
    this.filter = data.filter;

    if (this.filter.fechaTicketInicio !== null || this.filter.fechaTicketFin !== null) {
      this.tipusData = true;
    }  else if (this.tipusData === null || this.tipusData === undefined) {
      this.tipusData = true;
    }

    this.fechaHoraActual = new Date();
    console.log(this.fechaHoraActual);
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

  resetDates(tipusData: boolean) {
    if (tipusData) {
      this.filter.fechaTicketInicio = null;
      this.filter.fechaTicketFin = null;
    } 
  }
}