<div class="row" >
  <app-form-text-edit id="diposit-editor-qui-recull-nom" class="col-xl-4" i18n-label label="Nom i cognoms" [(value)]="this.value.titularnom"  [isReadOnly]="false" ></app-form-text-edit>
  <app-form-text-edit id="diposit-editor-qui-recull-dni" class="col-xl-4" i18n-label label="DNI/PASP" [(value)]="this.value.titularnif"  (change)="this.consultarConductor()" ></app-form-text-edit>
  <app-form-text-edit id="diposit-editor-qui-recull-direccio" class="col-xl-4" i18n-label label="Direcció" [(value)]="this.value.titulardireccio"  [isReadOnly]="false" ></app-form-text-edit>

  <app-form-text-edit id="diposit-editor-qui-recull-cp" class="col-xl-4" i18n-label label="Codi postal" [(value)]="this.value.titularCP"  [isReadOnly]="false" ></app-form-text-edit>
  <app-form-text-edit id="diposit-editor-qui-recull-poblacio" class="col-xl-4" i18n-label label="Població" [(value)]="this.value.titularpoblacio"  [isReadOnly]="false" ></app-form-text-edit>
  <app-form-text-edit id="diposit-editor-qui-recull-telefon" class="col-xl-4" i18n-label label="Telèfon" [(value)]="this.value.titularTelefon"  [isReadOnly]="false" ></app-form-text-edit>
  
</div>



