/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-check-edit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i3 from "@angular/material/checkbox";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/forms";
import * as i7 from "./form-check-edit.component";
import * as i8 from "../../../services/validation/validation.service";
var styles_FormCheckEditComponent = [i0.styles];
var RenderType_FormCheckEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormCheckEditComponent, data: {} });
export { RenderType_FormCheckEditComponent as RenderType_FormCheckEditComponent };
export function View_FormCheckEditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.value = $event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatCheckbox_0, i2.RenderType_MatCheckbox)), i1.ɵdid(2, 4374528, null, 0, i3.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i4.FocusMonitor, i1.NgZone, [8, null], [2, i3.MAT_CHECKBOX_CLICK_ACTION], [2, i5.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatCheckbox]), i1.ɵdid(4, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(6, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵted(7, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_13 = _co.disabled; _ck(_v, 2, 0, currVal_13); var currVal_14 = _co.disabled; var currVal_15 = _co.value; _ck(_v, 4, 0, currVal_14, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; var currVal_1 = i1.ɵnov(_v, 2).indeterminate; var currVal_2 = i1.ɵnov(_v, 2).checked; var currVal_3 = i1.ɵnov(_v, 2).disabled; var currVal_4 = (i1.ɵnov(_v, 2).labelPosition == "before"); var currVal_5 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); var currVal_6 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 6).ngClassValid; var currVal_11 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); var currVal_16 = _co.label; _ck(_v, 7, 0, currVal_16); }); }
export function View_FormCheckEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-check-edit", [], null, null, null, View_FormCheckEditComponent_0, RenderType_FormCheckEditComponent)), i1.ɵdid(1, 114688, null, 0, i7.FormCheckEditComponent, [i8.ValidationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormCheckEditComponentNgFactory = i1.ɵccf("app-form-check-edit", i7.FormCheckEditComponent, View_FormCheckEditComponent_Host_0, { label: "label", value: "value", required: "required", disabled: "disabled" }, { valueChange: "valueChange", clicked: "clicked" }, []);
export { FormCheckEditComponentNgFactory as FormCheckEditComponentNgFactory };
