import { Component, OnInit, Inject } from '@angular/core';
import { Notificacio } from '../../models/notificacio.model';
import { Builder } from 'path-shared/models/builder';
import { NotificacionsService } from '../../services/notificacions.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DenunciesImagesComponent } from 'app/sections/denuncies/components/denuncies-editor/components/denuncies-images/denuncies-images.component';
import { RespostesComponent } from '../respostes/respostes.component';

@Component({
  selector: 'app-notificacions-editor',
  templateUrl: './notificacions-editor.component.html',
  styleUrls: ['./notificacions-editor.component.scss']
})
export class NotificacionsEditorComponent implements OnInit {

  notificacio: Notificacio;
  secureId: string;
  images: any;
  constructor(
    private notificacioService: NotificacionsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Notificacio,
  ) {
    this.notificacio = data;
   }

  ngOnInit() {
    this.notificacioService.GetImages(this.notificacio.somId).subscribe(res => {
      this.images = res;
    });
  }

  OpenImatges() {
    const imagesDialogRef = this.dialog.open(DenunciesImagesComponent,
      {
        minWidth: '50%',
        minHeight: '90%',
        data: {'ficheros': this.images, 'numDenuncia': this.notificacio.somId},
      });
  }

  OpenRespostes() {
    const respostesDialogRef = this.dialog.open(RespostesComponent,
      {
        minWidth: '70%',
        minHeight: '50%',
        data:  {'notificacioId': this.notificacio.notificacio, 'element': this.notificacio.element},
      });
  }
}
