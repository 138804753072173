<app-toolbar>
    <app-tool-button text="Actualitzar"  i18n-text (click)="updateDenuncies();" color="has-color-blue" icon="fa-sync"></app-tool-button>
    <app-tool-button text="Nou" i18n-text (click)="newDenuncia();" color="has-color-green" icon="fa-plus-square" [disabled]="!this.auth.UserCanCreate(this.auth.Pages.Denuncies)" ></app-tool-button>
    <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
    <app-tool-button text="Editar" i18n-text (click)="editDenuncia();" color="has-color-orange" icon="fa-edit" [disabled]="(selectCheckedItems() == 1 ? false : true) || !this.auth.UserCanWrite(this.auth.Pages.Denuncies)"></app-tool-button>
    <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
    <app-tool-button text="Cobrar" i18n-text (click)="cobrarDenuncia();"  color="has-color-darkgreen" icon="fa-university"  [disabled]="(selectCheckedItems() >= 1 ? false : true) || !this.auth.UserCanWrite(this.auth.Pages.Denuncies)"></app-tool-button>
    <app-tool-button text="Nova operació" i18n-text (click)="NovaOperacio();" color="has-color-purple" icon="fa-plus-circle"  [disabled]="(!selectCheckedItemsForOperacio()) || !this.auth.UserCanWrite(this.auth.Pages.Denuncies)"></app-tool-button>
    <app-tool-button text="Permisos" i18n-text (click)="onPermissionClick();" color="has-color-orange" icon="fa-lock"></app-tool-button>
    <app-tool-button text="Report" i18n-text (click)="this.report();" color="has-color-blue" icon="fa-print" *ngIf="reports.length > 0" [disabled]="(selectCheckedItems() == 1 ? false : true) || !this.auth.UserCanWrite(this.auth.Pages.Denuncies)"></app-tool-button>
    <app-tool-dropdown-button text="Importar expediente externo" i18n-text (click1)="descarregaExpedientsClick();" (click2)="carregaExpedientsClick();" 
        numeroSubmenus="2" i18n-label1 label1="Descarregar plantilla" i18n-label2 label2="Carregar plantilla" color="has-color-orange" icon="fa-tasks">
    </app-tool-dropdown-button>

    <input type="file" class="form-control-file" onclick="this.value = null" (change)="this.onFileChange($event)" #uploadFileInput hidden style="width: 0px"/>
</app-toolbar>

<app-denuncies-table [updatedFilterData]="filterData" (notify)="onRowSelected($event)"></app-denuncies-table>
