import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { SeguretatUsuarisFilter } from '../../models/seguretat-usuaris-filter';

@Component({
  selector: 'app-seguretat-usuaris-filter',
  templateUrl: './seguretat-usuaris-filter.component.html',
  styleUrls: ['./seguretat-usuaris-filter.component.scss']
})
export class SeguretatUsuarisFilterComponent implements OnInit {

  filter: SeguretatUsuarisFilter;

  @Output() notify: EventEmitter<SeguretatUsuarisFilter> = new EventEmitter<SeguretatUsuarisFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<SeguretatUsuarisFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter;
  }

  ngOnInit() {
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
