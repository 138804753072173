import { AuthorizationService } from '../../../shared/services/authorization/authorization-service';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { FormControl } from '@angular/forms';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { LoaderService } from 'path-shared/components/loader/loader.service';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { FormMultiSelectComponent } from 'path-shared/components/form/form-multi-select/form-multi-select/form-multi-select.component';
import { TiquetsService } from 'app/sections/tiquets/services/tiquets.service';
import { TiquetsClientTableComponent } from './tiquets-client-table/tiquets-client-table.component';
import { TiquetsClientFilterComponent } from './tiquets-client-filter/tiquets-client-filter.component';
import { TiquetsClientFilter } from './tiquets-client-filter/tiquets-client-filter';

@Component({
  selector: 'app-tiquets-page',
  templateUrl: './tiquets-client.component.html',
  styleUrls: ['./tiquets-client.component.scss']
})
export class TiquetsClientComponent implements OnInit, AfterViewInit {
  proveidors = [];
  proveidorsSelected = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selected = new FormControl(0);
  filter: TiquetsClientFilter = new TiquetsClientFilter('id', 'desc');
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild('tiquetsClientTable') tiquetsClientTable: TiquetsClientTableComponent;
  @ViewChild('comboProvs') provCombo: FormMultiSelectComponent;

  currentTiquets = 0;

  showResum = true;

  constructor(
    public authService: AuthorizationService,
    private not: NotificationService,
    private tiquetsService: TiquetsService,
    private filterService: FilterService,
    private loaderService: LoaderService,
    private dialog: MatDialog,
    private i18n: I18n
  ) {
  }

  //#region CICLO DE VIDA
  ngOnInit() {
    this.loadProveidors();
    this.authService.CheckUserPermission(this.authService.Pages.Tiquets);
    this.filterService.loadFilter('tiquets-client-filter', this.filter);

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.filterService.saveFilter('tiquets-client-filter', this.filter);
        this.tiquetsClientTable.updateFilterFromParent(this.filter);
      } 
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Tiquets'));
    }
  }

  //#endregion
  loadProveidors(){
    this.tiquetsService.getProveidors().subscribe(result => {
      const prov: Array<any> = Object.assign([], result);
      this.proveidors = [];
      prov.forEach(proveidor => {
        this.proveidors.push(new FormMultiSelect(proveidor, proveidor));

      });
    });
  }
  //#region Toolbar events
  refresh() {
    this.tiquetsClientTable.updateFilterFromParent(this.filter);
  }
  onFilterClicked() {
      const ref = this.dialog.open(TiquetsClientFilterComponent, {
        width: '50%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          'filter': this.filter
        }
      });

      ref.componentInstance.notify.subscribe((filter: TiquetsClientFilter) => {
        this.filter.set(filter);
      });
    }

  generateExcel() {
    this.tiquetsClientTable.generateExcel();
  }
  //#endregion

  //#region  Others 


  setBadgeCount(valueCount: number, page: number) {
    switch (page) {
      case 1:
        this.currentTiquets = valueCount;
        break;
    }
  }

  //#endregion
}