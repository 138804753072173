import { Infraccio } from '../models/infraccio.model';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { InfraccioFilterService } from './infraccions-filter-service';
import { InfraccioFilter } from '../models/infraccio-filter';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { InfraccioDetail } from '../models/infraccioDetail.model';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class InfraccioService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}


  setInfraccio(infraccio: InfraccioDetail ): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetInfraccio'], infraccio);
    }
  }

  removeInfraccio(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteInfraccio'].replace('{secure_id}', id), null);
    }
  }

  getInfraccio(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetInfraccio'].replace('{secure_id}', id), null);
    }
  }

  getInfrLegi(legi): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetInfrLegi'].replace('{legi}', legi), null);
    }
  }
  generateExcel(filter: InfraccioFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new InfraccioFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);
    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlInfraccionsExcel'],  params );
    }
  }

  getAllForCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new InfraccioFilterService().createFilterParams(new InfraccioFilter ('id', 'asc'), 0, 99999, 'id', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetInfraccionsForCombo'], params);
    }
  }

  getAllForComboInfraccionsGrua(): Observable<any> {
    if (this.authGuard.canActivate()) {      
      let filter = new InfraccioFilter ('id', 'asc'); 
      filter.inici= new Date();
      filter.final= new Date();
      filter.obsoleta = false;
      const params = new InfraccioFilterService().createFilterParams(filter, 0, 99999, 'id', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetInfraccionsForCombo'], params);
    }
  }

  getInfraccionsDenunciesCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetInfraccionsDenunciesCombo'], null);
    }
  }
  GetInfraccioTipusGravetat(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetInfraccioTipusGravetat'], null);
    }
  }

  GetInfraccioTipus(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetInfraccioTipus'], null);
    }
  }

  GetInfraccioDiesDescompte(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetInfraccioDiesDescompte'], null);
    }
  }
}
