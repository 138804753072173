<div class="row justify-content-between">
    <div class="col">
        <app-filter-selector [filter]="this.filter" exclude="['fechaActualConsulta']"></app-filter-selector>
    </div>
    <div class="col">
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
        </mat-paginator>
    </div>
</div>

<div class="table-sdw is-fixed">

    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

        <ng-container matColumnDef="incorpora" *ngIf="this.selectButton">
            <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
            <mat-cell *matCellDef="let row" class="text-center"><a (click)="this.selectCurrent(row.codi, row.SECURE_ID)" title="Incorporar" i18n-title><i class="fa fa-plus-square has-color-aqua"></i></a></mat-cell>
        </ng-container>

         <!--Codi-->
         <ng-container matColumnDef="codi">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
            <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.codi}}</a></mat-cell>
        </ng-container>

        <!--Categoria-->
        <ng-container matColumnDef="categoria">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Categoria</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.categoria}}</mat-cell>
        </ng-container>       

        <!--Nom Complert-->
        <ng-container matColumnDef="nomComplert">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nom complet</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.nomComplert}}</mat-cell>
        </ng-container>

        <!--DNI-->
        <ng-container matColumnDef="dni">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>DNI</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.dni}}</mat-cell>
        </ng-container>

        <!--Lletra DNI-->
        <ng-container matColumnDef="letraDni">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Lletra DNI</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.letraDni}}</mat-cell>
        </ng-container>

        <!--Obsolet-->
        <ng-container matColumnDef="obsolet">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Obsolet</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox [checked]="row.obsolet" [disabled]='true'></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="zonaBlava">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Zona Blava</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox [checked]="row.zonaBlava" [disabled]='true'></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="agentPDA">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Agent PDA</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox [checked]="row.agentPDA" [disabled]='true'></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dadoAlta">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Donat d'alta</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox [checked]="this.estaDeAlta(row)" [disabled]='true'></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="festivoHoy">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>De festa</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox [checked]="this.estaDeFiesta(row)" [disabled]='true'></mat-checkbox>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
    </mat-table>
</div>