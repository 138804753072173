import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { EstadistiquesService } from '../../services/estadistiques.service';
import { Aparell } from 'app/sections/configuracio/aparells/models/aparell.model';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DispositiuEstadistiques } from '../../models/dispositiu-estadistica.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { FormService } from 'path-shared/services/form/form.service';
import { ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { DenunciaFilter } from 'app/sections/denuncies/models/denuncia.filter';
import { DenunciesTableComponent } from 'app/sections/denuncies/components/denuncies-table/denuncies-table.component';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { AppComponent } from 'app/app.component';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { GoogleMapsService } from 'path-shared/services/startup/google-maps.service';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Parametros } from 'path-shared/models/parametros';
import { CFG_PARAMETROS } from 'path-models/CFG_PARAMETROS';
import { AgmMap } from '@agm/core';
import { InfraccioService } from 'app/sections/configuracio/Infraccions/services/infraccions-service.service';

@Component({
  selector: 'app-estadistiques-reincidents',
  templateUrl: './estadistiques-reincidents.component.html',
  styleUrls: ['./estadistiques-reincidents.component.scss']
})
export class EstadistiquesReincidentsComponent implements OnInit, AfterViewInit {

  numReincidencias = '';
  dataSource = null;
  mapVisible = false;
  selectedRow = null;
  fechas: Date[] = [new Date(), new Date()];
  latitud: any;
  longitud: any;
  zoom: any;
  init = true;
  infraccion = null;
  infracciones = [];

  displayedColumns = ['matricula', 'reincidencias'];

  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild('AgmMap') agmMap: AgmMap;
  @ViewChild('sort') sort: MatSort;


  constructor(
    private estadistiquesService: EstadistiquesService,
    private i18n: I18n,
    public auth: AuthorizationService,
    private not: NotificationService,
    private formService: FormService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private parametersService: ParametersService,
    private googleMapsService: GoogleMapsService,
    private authGuard: AuthGuardService,
    private infraccionsService: InfraccioService
  ) {
    this.setDefaultLatLon();
    this.fechas[0].setDate(this.fechas[0].getDate() - 14);
   }

  ngOnInit() {
    this.infraccionsService.getInfraccionsDenunciesCombo().subscribe(res => {
      this.infracciones = res;
      this.infracciones.unshift({'codi' : 0, 'descripcio': this.i18n('Todas')});
    });
  }

  setDefaultLatLon() {
    this.latitud = AppComponent.getInstitution().Latitud;
    this.longitud = AppComponent.getInstitution().Longitud;
    this.zoom = Number.parseInt(this.parametersService.getParameter(Parametros.GPSZOMINICIAL));
  }

  ngAfterViewInit() {
    this.not.titleChange(this.i18n('Estadísticas reincidentes'));
    this.numReincidencias = '';
  }

  buscar() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      this.estadistiquesService.getEstadisticasReincidencias(this.numReincidencias, this.fechas[0], this.fechas[1], this.infraccion).subscribe(res => {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.sort = this.sort;
        this.selectedRow = null;
        this.mapVisible = false;
        this.init = false;
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }

  }

  FiltroDenuncias(matricula) {
    const filter: DenunciaFilter = new DenunciaFilter('', '');
    filter.matricula = matricula;
    filter.fechaInfraccionInicio = this.fechas[0];
    filter.fechaInfraccionFin = this.fechas[1];
    const data: ComponentDialog = Builder.newComponentDialog(true, false, '', filter);
    const dlg = this.dialog.open(DenunciesTableComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      width: '90%',
      data: data,
    });
  }

  onRowClicked(row) {
    if (this.selectedRow === null || row.matricula !== this.selectedRow.matricula || row.ckecked === true) {
      if (this.selectedRow !== null && row.matricula !== this.selectedRow.matricula) {
        this.dataSource.data.find(x => x.matricula === this.selectedRow.matricula).clicked = false;
      }
      this.setDefaultLatLon();
      this.selectedRow = row;
      row.clicked = true;
      this.mapVisible = true;
    } else {
      this.selectedRow = null;
      row.clicked = false;
      this.mapVisible = false;
    }

  }

}
