import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import * as i0 from "@angular/core";
import * as i1 from "./loader.service";
var LoaderInterceptorService = /** @class */ (function () {
    function LoaderInterceptorService(loaderService) {
        this.loaderService = loaderService;
    }
    LoaderInterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        var method = req.method;
        var urlcontar = req.url.includes('count');
        if (req.headers.keys().indexOf('hideProgress') === -1) {
            req.headers.delete('void');
            this.showLoader();
        }
        else {
            req.headers.delete('hideProgress');
        }
        return next.handle(req).pipe(tap(function (event) {
            if (event instanceof HttpResponse) {
                if (method !== 'OPTIONS' && !urlcontar) {
                    _this.onEnd();
                }
            }
        }, function (err) {
            _this.onEnd();
        }));
    };
    LoaderInterceptorService.prototype.onEnd = function () {
        this.hideLoader();
    };
    LoaderInterceptorService.prototype.showLoader = function () {
        this.loaderService.show(false);
    };
    LoaderInterceptorService.prototype.hideLoader = function () {
        this.loaderService.hide(false);
    };
    LoaderInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function LoaderInterceptorService_Factory() { return new LoaderInterceptorService(i0.inject(i1.LoaderService)); }, token: LoaderInterceptorService, providedIn: "root" });
    return LoaderInterceptorService;
}());
export { LoaderInterceptorService };
