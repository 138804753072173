<app-dialog-header label="Domicilio" i18n-label ></app-dialog-header>
<app-toolbar>
  <app-tool-button *ngIf="action !== 'Delete' && action !== undefined" text="Guardar" i18n-text (click)="doAction()" color="has-color-green" icon="fa-save" ></app-tool-button>
  <app-tool-button *ngIf="action === 'Delete'" text="Eliminar" i18n-text (click)="doAction();" color="has-color-red" icon="fa-trash-alt" ></app-tool-button>
  <app-tool-button  text="Cancelar" i18n-text (click)="closeDialog()" color="has-color-red" icon="fa fa-times "></app-tool-button>
</app-toolbar>
<mat-dialog-content mat-dialog-content     style="overflow: hidden;">
  <form #mainForm>
  <div class="row"  *ngIf="action !== 'Delete' && action !== undefined">
    <div class="form-group col-xl-12">
      <mat-form-field >
        <mat-label  i18n-label>Domicili</mat-label>
        <input matInput   [(value)]="this.direccioCompleta.value"  disabled="true">
      </mat-form-field>
    </div>
    <!--<app-form-text-edit i18n-label class="col-xl-12" label="Domicili" [(value)]="this.direccioCompleta.value"  [disabled]="true" ></app-form-text-edit>-->
    <app-form-text-edit id="domicilis-editor-domicili" i18n-label class="col-xl-6" label="Domicili" [(value)]="this.local_data.dirSimple" (valueChange)="this.setDireccioCompleta()"  required></app-form-text-edit>
    <app-form-text-edit id="domicilis-editor-numero" i18n-label class="col-xl-6" label="Número" [(value)]="this.local_data.numero" (valueChange)="this.setDireccioCompleta()"></app-form-text-edit>
    
    <app-form-text-edit id="domicilis-editor-escala" i18n-label class="col-xl-6" label="Escala" [(value)]="this.local_data.escala" (valueChange)="this.setDireccioCompleta()" ></app-form-text-edit>
    <app-form-text-edit id="domicilis-editor-pis" i18n-label class="col-xl-6" label="Pis" [(value)]="this.local_data.pis" (valueChange)="this.setDireccioCompleta()"></app-form-text-edit>
    
    <app-form-text-edit id="domicilis-editor-porta" i18n-label class="col-xl-6" label="Porta" [(value)]="this.local_data.porta" (valueChange)="this.setDireccioCompleta()"></app-form-text-edit>
    <app-form-text-edit id="domicilis-editor-cp" i18n-label class="col-xl-6" label="Codi postal" [(value)]="this.local_data.codiPostal" (valueChange)="autofillByCP()"></app-form-text-edit>
    <!--<app-form-text-edit i18n-label class="col-xl-6" label="País" [(value)]="this.local_data.pais" ></app-form-text-edit>-->
    <div class="col-xl-6" >
        <app-autocomplete-combo #comboPaisos class="center-horizontal" i18n-label label="Pais" [options]="this.paisosDictionary"
        (valorSeleccionado)="this.paisSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompletePais"  required="false" >
      </app-autocomplete-combo>
    </div>  
    
    <!--<app-form-text-edit i18n-label class="col-xl-6" label="Provincia" [(value)]="this.local_data.provincia" ></app-form-text-edit>-->
    <div class="col-xl-6" >
      <app-autocomplete-combo #comboProvincia class="center-horizontal" i18n-label label="Provincia" [options]="this.provinciasDictionary"
      (valorSeleccionado)="this.provinciaSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteProvincia"  required="false" >
      </app-autocomplete-combo>
    </div>  

    <!--<app-form-text-edit i18n-label class="col-xl-6" label="Localitat" [(value)]="this.local_data.localitat" ></app-form-text-edit>-->
    <div class="col-xl-6" >
      <app-autocomplete-combo #combMunicipio class="center-horizontal" i18n-label label="Municipi" [options]="this.municipiosDictionary"
      (valorSeleccionado)="this.municipioSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteMunicipio"  required="false" >
      </app-autocomplete-combo>
    </div>  

    <app-form-text-edit id="domicilis-editor-idExt" i18n-label class="col-xl-6" label="Id extern" [(value)]="this.local_data.id_extern" ></app-form-text-edit>
    <app-form-check-edit class="col-xl-6" i18n-label label="Actual" [(value)]="this.local_data.actual" ></app-form-check-edit>
  </div>
</form>
</mat-dialog-content>
<div *ngIf="action === 'Delete'" style="justify-content: center;">
    Estàs segur que vols borrar el registre <b>{{local_data.nombre}}</b>?<br/>
</div>
