var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var NotificacionsFilter = /** @class */ (function (_super) {
    __extends(NotificacionsFilter, _super);
    function NotificacionsFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.element = null;
        _this.tipus = null;
        _this.notificacio = null;
        _this.enviament = null;
        _this.acceptacio = null;
        _this.labels['element'] = _this.i18n('Expedient');
        _this.labels['tipus'] = _this.i18n('Tipus');
        _this.labels['notificacio'] = _this.i18n('Notificació');
        _this.labels['enviament'] = _this.i18n('Enviament');
        _this.labels['acceptacio'] = _this.i18n('Acceptació');
        return _this;
    }
    return NotificacionsFilter;
}(FilterData));
export { NotificacionsFilter };
