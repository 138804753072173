import { ReservesFilter } from '../../models/reserves-filter';
import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-reserves-filter',
  templateUrl: './reserves-filter.component.html',
  styleUrls: ['./reserves-filter.component.scss']
})
export class ReservesFilterComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  filter: ReservesFilter;

  @Output() notify: EventEmitter<ReservesFilter> = new EventEmitter<ReservesFilter>();


  constructor(
    public thisDialogRef: MatDialogRef<ReservesFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }
}
