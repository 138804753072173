/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alarm-configuration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i4 from "../../../../shared/components/form/form-text-area/form-text-area.component.ngfactory";
import * as i5 from "../../../../shared/components/form/form-text-area/form-text-area.component";
import * as i6 from "../../../../shared/services/validation/validation.service";
import * as i7 from "./alarm-configuration.component";
import * as i8 from "../../services/alarmesGps.service";
import * as i9 from "ngx-toastr";
import * as i10 from "@angular/material/dialog";
var styles_AlarmConfigurationComponent = [i0.styles];
var RenderType_AlarmConfigurationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlarmConfigurationComponent, data: {} });
export { RenderType_AlarmConfigurationComponent as RenderType_AlarmConfigurationComponent };
export function View_AlarmConfigurationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["label", "Configuraci\u00F3 alarmes"]], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["style", "margin-top: 1vh;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cuando se genere una alarma, se enviar\u00E1 un correo a las siguientes direcciones:"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-form-text-area", [["label", "Introduzca los emails separados por comas"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.emails = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FormTextAreaComponent_0, i4.RenderType_FormTextAreaComponent)), i1.ɵdid(5, 4308992, null, 0, i5.FormTextAreaComponent, [i6.ValidationService], { label: [0, "label"], value: [1, "value"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["style", "float:right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveChanges() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Guardar cambios"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Configuraci\u00F3 alarmes"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "Introduzca los emails separados por comas"; var currVal_2 = _co.emails; _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
export function View_AlarmConfigurationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alarm-configuration", [], null, null, null, View_AlarmConfigurationComponent_0, RenderType_AlarmConfigurationComponent)), i1.ɵdid(1, 114688, null, 0, i7.AlarmConfigurationComponent, [i8.AlarmesGpsService, i9.ToastrService, i10.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlarmConfigurationComponentNgFactory = i1.ɵccf("app-alarm-configuration", i7.AlarmConfigurationComponent, View_AlarmConfigurationComponent_Host_0, {}, {}, []);
export { AlarmConfigurationComponentNgFactory as AlarmConfigurationComponentNgFactory };
