import { CustomDataSource } from './../../../../../shared/services/datasource/custom.datasource';
import { Component, OnInit, ViewChild, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { TipoSenalesVerticalesFilter } from '../../models/tipo-senales-verticales.filter';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { TipoSenalVertical } from '../../models/tipo-senal-vertical';
import { TipoSenalesVerticalesFilterService } from '../../services/tipo-senales-verticales-filter-service';
import { TipoSenalesVerticalesService } from '../../services/tipo-senales-verticales-service';

@Component({
  selector: 'app-tipo-senales-verticales-table',
  templateUrl: './tipo-senales-verticales-table.component.html',
  styleUrls: ['./tipo-senales-verticales-table.component.scss']
})
export class TipoSenalesVerticalesTableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: TipoSenalVertical[];
  dataSource: CustomDataSource<TipoSenalVertical, TipoSenalesVerticalesFilter>;
  filter: TipoSenalesVerticalesFilter = new TipoSenalesVerticalesFilter('descripcion', 'asc');
  displayedItems = [];
  highlightedRows = [];

  @Input() selectButton: boolean;
  @Input() updatedFilterData: TipoSenalesVerticalesFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  displayedColumns = ['id', 'descripcion'];

  constructor(
    private tipoSenalesService: TipoSenalesVerticalesService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n
  ) { }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<TipoSenalVertical, TipoSenalesVerticalesFilter>(
      this.paginator,
      new TipoSenalesVerticalesFilterService(),
      'urlGetTipoSenalesVerticales',
      'urlGetTipoSenalesVerticalesCount');

    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) {
          this.filterService.saveFilter('tipo-senales-verticales-filter', this.filter);
        }
      }
    });

    if (!this.selectButton) {
      this.filterService.loadFilter('tipo-senales-verticales-filter', this.filter);
    }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row.secureId);
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: Builder.newDownloadExcelDialogData(
        this.tipoSenalesService,
        filter,
        this.i18n('Tipus senyals verticals'),
        this.sort.direction,
        this.sort.active)
    });
  }

}
