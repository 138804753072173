import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilterHeaderDelegate } from './filter-header-delegate';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-filter-header',
  templateUrl: './filter-header.component.html',
  styleUrls: ['./filter-header.component.scss']
})
export class FilterHeaderComponent implements OnInit {
  @Input() title: string;
  @Output() public apply: EventEmitter<any> = new EventEmitter();
  @Output() public reset: EventEmitter<any> = new EventEmitter();
  @Output() public dismiss: EventEmitter<any> = new EventEmitter();

  constructor(public thisDialogRef: MatDialogRef<FilterHeaderComponent>) { }

  ngOnInit() {
  }
}
