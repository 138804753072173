import { EventSourcePolyfill } from 'event-source-polyfill';
import { I18n } from '@ngx-translate/i18n-polyfill';
import {Injectable, Injector, Inject} from '@angular/core';
import { HttpClient , HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Serializer } from '@angular/compiler';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RequestOptions, ResponseContentType } from '../../../../../node_modules/@angular/http';

@Injectable()
export class HttpSecureClient {

  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService, private i18n: I18n ) {
  }

  createAuthorizationHeader(hide = false) {

    const token = sessionStorage.getItem('access_token');
    const idInstitucion = sessionStorage.getItem('IdInstitucion');
    if (token !== undefined && token !== null && idInstitucion !== undefined && idInstitucion !== null ) {
      return new HttpHeaders()
      .append('Authorization', 'Bearer ' + token)
      .append('X-Origin', 'SomDenWeb')
      .append('Allow-Control-Allow-Origin', '*')
      .append('Institucio', idInstitucion)
      .append(hide ? 'hideProgress' : 'void', '');
    } else {
      return null;
    }

  }

  createAutorizationHeaderWithContentType(contentType: string, type: string) {
     const token = sessionStorage.getItem('access_token');
     const idInstitucion = sessionStorage.getItem('IdInstitucion');
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X-Origin', 'SomDenWeb').set('Allow-Control-Allow-Origin', '*')
      .set('Institucio', idInstitucion);
  }

  get(url: string, params: HttpParams, hide = false) {
    return this.http.get(url.replace('{hostname}', window.location.hostname), {params: params, headers: this.createAuthorizationHeader(hide) })
    .catch(err => this.handleError(err));
  }
  getWithType<T>(url: string, params: HttpParams, hide = false):Observable<T> {
    return this.http.get<T>(url.replace('{hostname}', window.location.hostname), {params: params, headers: this.createAuthorizationHeader(hide) })
    .catch(err => this.handleError(err));
  }

  getAndCatchError(url: string, params: HttpParams, hide = false) {
    return this.http.get(url.replace('{hostname}', window.location.hostname), {params: params, headers: this.createAuthorizationHeader(hide) })
    .catch(err => this.handleErrorAndThrow(err));
  }

  getFile(url: string, params: HttpParams) {
    return this.http.get(
      url.replace('{hostname}', window.location.hostname),
       {
        responseType: 'arraybuffer',
        params: params, 
        headers: this.createAuthorizationHeader().append('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      })
    .catch(err => this.handleError(err));
  }
  postFileArrayResponse(url, data, params: HttpParams) {
    url = url.replace('{hostname}', window.location.hostname);
      return this.http.post(url , data, { responseType: 'blob', params: params, headers: this.createAuthorizationHeader().append('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') })
        .catch(err => this.handleError(err));
  }
  getPdf(url: string, params: HttpParams) {
    return this.http.get(url.replace('{hostname}', window.location.hostname), {responseType: 'blob', params: params, headers: this.createAuthorizationHeader().append('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') })
    .catch(err => this.handleError(err));
  }
  getImage(url: string, params: HttpParams) {
    return this.http.get(url.replace('{hostname}', window.location.hostname), {responseType: 'blob', params: params, headers: this.createAuthorizationHeader().append('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') })
    .catch(err => this.handleError(err));
  }

  getFileResponse(url: string, params: HttpParams) {
    return this.http.get(url.replace('{hostname}', window.location.hostname), { responseType: 'blob', observe: 'response', params: params, headers: this.createAuthorizationHeader() })
    .catch(err => this.handleError(err));
  }

  postFileResponse(url, data, params: HttpParams) {
    url = url.replace('{hostname}', window.location.hostname);
      return this.http.post(url , data,
        { responseType: 'blob', observe: 'response', params: params, headers: this.createAuthorizationHeader() }
      ).catch(err => this.handleError(err));
  }

  delete(url: string, params: HttpParams) {
    return this.http.delete(url.replace('{hostname}', window.location.hostname), {params: params, headers: this.createAuthorizationHeader() })
    .catch(err => this.handleError(err));
  }

  post(url, data) {
    const headers = new HttpHeaders();
    url = url.replace('{hostname}', window.location.hostname);
      return this.http.post(url , data, {
        headers: this.createAuthorizationHeader()
      })
      .catch(err =>this.handleError(err));
  }
  postWithParams(url,data,params:HttpParams){
    url = url.replace('{hostname}', window.location.hostname);
    return this.http.post(url , data, {
      headers:this.createAuthorizationHeader(),
      params:params})
    .catch(err=>{
      return this.handleError(err);

    })

  }


  postWithError(url, data, errorFunction: (any)) {
    const headers = new HttpHeaders();
    url = url.replace('{hostname}', window.location.hostname);
      return this.http.post(url , data, {
        headers: this.createAuthorizationHeader()
      }).catch(err => errorFunction(err));
  }
 
  postFile(url, data) {
    url = url.replace('{hostname}', window.location.hostname);
    const formData: FormData = new FormData();
    formData.append('file', data);
    return this.http.post(url , formData, {
      headers: this.createAuthorizationHeader()
    }).catch(err => this.handleError(err));
  }

  put(url, data) {
    const headers = this.createAutorizationHeaderWithContentType('Content-Type', 'application/json');

    return this.http.put(url.replace('{hostname}', window.location.hostname) , data, {
      headers: headers
    }).catch(err => this.handleError(err));
  }

  postJSON(url, data) {

    const headers = this.createAutorizationHeaderWithContentType('Content-Type', 'application/json');
    return this.http.post(url.replace('{hostname}', window.location.hostname) , data, {
      headers: headers
    }).catch(err => this.handleError(err));
  }

  eventListener(url): EventSourcePolyfill {

    const token = sessionStorage.getItem('access_token');
    const idInstitucion = sessionStorage.getItem('IdInstitucion');
    // s'han hagut de crear els headers manualment ja que si no no funciona correctament
    return new EventSourcePolyfill(
      url.replace('{hostname}', window.location.hostname), {
        headers: {
        'Authorization': 'Bearer ' + token,
        'X-Origin': 'SomDenWeb',
        'Allow-Control-Allow-Origin': '*',
        'Institucio': idInstitucion
        }
      });
  }

  private handleError(error: Response | any ) {
    let errorMessage = 'Ooops';
    let redirectToLogin = false;
    console.log(error);
    switch (error.status) {
      case 0:
        errorMessage = this.i18n('El servidor està desconnectat');
        break;
      // client errors
      case 400:
        errorMessage = this.i18n('Petició incorrecte');
        break;
      case 401:
        redirectToLogin = true;
        errorMessage = this.i18n('No autoritzat');
        break;
      case 403:
        redirectToLogin = true;
        errorMessage = this.i18n('Accés prohibit');
        break;
      case 404:
        errorMessage = this.i18n('No trobat');
        break;
      case 408:
        errorMessage = this.i18n('Temps exhaurit');
        break;
      case 409:
        errorMessage = this.i18n('Element repetit');
        break;
      case 417:
        errorMessage = this.i18n('Report no existent');
        break;
      case 204:
          errorMessage = this.i18n('No hay datos a consultar');
          break;
      // server errors
      case 500:
        errorMessage = this.i18n('Error intern en el servidor');
        break;
      case 503:
        errorMessage = this.i18n('Servei no disponible');
        break;
    }

    if (error != null) {
      let errorText = '';
      if (typeof(error.error) === 'string') {
        errorText = error.error;
      } else if (error.status === 406 && typeof(error.error) === 'object') {
        errorText = this.i18n('Error descarregant el fitxer:\n El fitxer és massa gran. \nContacti amb informàtica.');
      }

      this.toastr.error(errorText, errorMessage, {
        closeButton: true
      });

      if (redirectToLogin) {
        this.router.navigate(['/']);
      }
    }

    return Observable.throw(Error);
  }

  private handleErrorAndThrow(error: Response | any ) {
    let errorMessage = 'Ooops';
    let redirectToLogin = false;
    console.log(error);
    switch (error.status) {
      case 0:
        errorMessage = this.i18n('El servidor està desconnectat');
        break;
      // client errors
      case 400:
        errorMessage = this.i18n('Petició incorrecte');
        break;
      case 401:
        redirectToLogin = true;
        errorMessage = this.i18n('No autoritzat');
        break;
      case 403:
        redirectToLogin = true;
        errorMessage = this.i18n('Accçes prohibit');
        break;
      case 404:
        errorMessage = this.i18n('No trobat');
        break;
      case 408:
        errorMessage = this.i18n('Temps exhaurit');
        break;
      case 409:
        errorMessage = this.i18n('Element repetit');
        break;
      case 417:
        errorMessage = this.i18n('Report no existent');
        break;
      case 204:
          errorMessage = this.i18n('No hay datos a consultar');
          break;
      // server errors
      case 500:
        errorMessage = this.i18n('Error intern en el servidor');
        break;
      case 503:
        errorMessage = this.i18n('Servei no disponible');
        break;
    }

    if (error != null) {
      let errorText = '';
      if (typeof(error.error) === 'string') {
        errorText = error.error;
      } else if (error.status === 406 && typeof(error.error) === 'object') {
        errorText = this.i18n('Error descarregant el fitxer:\n El fitxer és massa gran. \nContacti amb informàtica.');
      }

      this.toastr.error(errorText, errorMessage, {
        closeButton: true
      });

      if (redirectToLogin) {
        this.router.navigate(['/']);
      }
    }

    return Observable.throw(error);
  }

}

