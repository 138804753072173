<div class="drag-scroll" style="height:60%;" >
  <div id="imatgeViewer" *ngIf="this.currentlyShowingImage" style="margin-bottom: 5%;">
    <span style=" display: inline-block; height: 100%; vertical-align: middle;"></span>
    <img [attr.src]=this.displayImage class="col-xl-12" (click)="expandImage()"  style="vertical-align: middle;" />
  </div>
  <div id="pdfViewer" *ngIf="this.currentlyShowingPdf" style="margin-bottom: 5%;">
    <pdf-viewer style= "overflow: scroll" delegate-handle="relativity-special-general-theory" [zoom]= "0.5" [fit-to-page]="false" 
      [original-size]="true" [src]="this.displayPdf" [render-text]="true" class="col-xl-3" max-height='50%' scale="1" show-toolbar="true">
    </pdf-viewer>
  </div>

  <div class="drag-scroll__actions">
    <button class="action-button__prev" (click)="moveLeft()"><i class="fa fa-angle-left"></i></button>
    <button class="action-button__next" (click)="moveRight()"><i class="fa fa-angle-right"></i></button>
  </div>
</div>