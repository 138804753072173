/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i2 from "../../../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i3 from "../../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i4 from "../../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i5 from "../../../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i6 from "../../../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i7 from "../../../../../../shared/components/form/form-file-multiple-upload/form-file-multiple-upload.component.ngfactory";
import * as i8 from "../../../../../../shared/components/form/form-file-multiple-upload/form-file-multiple-upload.component";
import * as i9 from "../../../../../../shared/components/dialog/dialog-body/dialog-body.component.ngfactory";
import * as i10 from "../../../../../../shared/components/dialog/dialog-body/dialog-body.component";
import * as i11 from "../../../../../../shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component.ngfactory";
import * as i12 from "../../../../../../shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component";
import * as i13 from "../../../../../../shared/services/parameters/parameters.service";
import * as i14 from "@angular/material/dialog";
import * as i15 from "../../../../../../shared/services/images/images.service";
import * as i16 from "@ngx-translate/i18n-polyfill";
import * as i17 from "../../../../../../shared/components/confirmation-dialog/confirmation-dialog.service";
import * as i18 from "./denuncies-images.component";
import * as i19 from "../../../../services/denuncies.service";
import * as i20 from "ngx-toastr";
var styles_DenunciesImagesComponent = [];
var RenderType_DenunciesImagesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DenunciesImagesComponent, data: {} });
export { RenderType_DenunciesImagesComponent as RenderType_DenunciesImagesComponent };
export function View_DenunciesImagesComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { photoBoxGallery: 0 }), i0.ɵqud(402653184, 2, { fileInput: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-dialog-header", [["label", "Imatges"]], null, null, null, i1.View_DialogHeaderComponent_0, i1.RenderType_DialogHeaderComponent)), i0.ɵdid(3, 114688, null, 0, i2.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 7, "app-toolbar", [], null, null, null, i3.View_ToolbarComponent_0, i3.RenderType_ToolbarComponent)), i0.ɵdid(5, 114688, null, 0, i4.ToolbarComponent, [], null, null), (_l()(), i0.ɵeld(6, 0, null, 0, 1, "app-tool-button", [["color", "has-color-green"], ["icon", "fa-plus-square"], ["text", "Afegir"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Afegir() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ToolButtonComponent_0, i5.RenderType_ToolButtonComponent)), i0.ɵdid(7, 114688, null, 0, i6.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i0.ɵeld(8, 0, null, 0, 1, "app-form-file-multiple-upload", [["hidden", ""], ["label", "Fitxer"], ["labelHidden", "true"]], null, [[null, "fileReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fileReady" === en)) {
        var pd_0 = (_co.fileReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_FormFileMultipleUploadComponent_0, i7.RenderType_FormFileMultipleUploadComponent)), i0.ɵdid(9, 114688, [[2, 4], ["fileInput", 4]], 0, i8.FormFileMultipleUploadComponent, [], { label: [0, "label"], labelHidden: [1, "labelHidden"] }, { fileReady: "fileReady" }), (_l()(), i0.ɵeld(10, 0, null, 0, 1, "app-tool-button", [["color", "has-color-red"], ["icon", "fa-trash"], ["text", "Eliminar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Eliminar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ToolButtonComponent_0, i5.RenderType_ToolButtonComponent)), i0.ɵdid(11, 114688, null, 0, i6.ToolButtonComponent, [], { disabled: [0, "disabled"], text: [1, "text"], color: [2, "color"], icon: [3, "icon"] }, { click: "click" }), (_l()(), i0.ɵeld(12, 0, null, null, 3, "app-dialog-body", [], null, null, null, i9.View_DialogBodyComponent_0, i9.RenderType_DialogBodyComponent)), i0.ɵdid(13, 114688, null, 0, i10.DialogBodyComponent, [], null, null), (_l()(), i0.ɵeld(14, 0, null, 0, 1, "app-photo-box-gallery", [], null, [[null, "notify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("notify" === en)) {
        var pd_0 = (_co.getChildNumSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_PhotoBoxGalleryComponent_0, i11.RenderType_PhotoBoxGalleryComponent)), i0.ɵdid(15, 245760, [[1, 4], ["photoBoxGallery", 4]], 0, i12.PhotoBoxGalleryComponent, [i13.ParametersService, i14.MatDialogRef, i15.ImagesService, i16.I18n, i17.ConfirmationDialogService], { numDenuncia: [0, "numDenuncia"], imatges: [1, "imatges"], magnifierRight: [2, "magnifierRight"], magnifierTop: [3, "magnifierTop"], showLupa: [4, "showLupa"] }, { notify: "notify" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Imatges"; _ck(_v, 3, 0, currVal_0); _ck(_v, 5, 0); var currVal_1 = "Afegir"; var currVal_2 = "has-color-green"; var currVal_3 = "fa-plus-square"; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "Fitxer"; var currVal_5 = "true"; _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = (_co.numSelected == 0); var currVal_7 = "Eliminar"; var currVal_8 = "has-color-red"; var currVal_9 = "fa-trash"; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8, currVal_9); _ck(_v, 13, 0); var currVal_10 = _co.numDenuncia; var currVal_11 = _co.images; var currVal_12 = 25; var currVal_13 = 100; var currVal_14 = false; _ck(_v, 15, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }, null); }
export function View_DenunciesImagesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-denuncies-images", [], null, null, null, View_DenunciesImagesComponent_0, RenderType_DenunciesImagesComponent)), i0.ɵdid(1, 114688, null, 0, i18.DenunciesImagesComponent, [i14.MatDialogRef, i14.MAT_DIALOG_DATA, i19.DenunciesService, i15.ImagesService, i17.ConfirmationDialogService, i20.ToastrService, i16.I18n], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DenunciesImagesComponentNgFactory = i0.ɵccf("app-denuncies-images", i18.DenunciesImagesComponent, View_DenunciesImagesComponent_Host_0, {}, {}, []);
export { DenunciesImagesComponentNgFactory as DenunciesImagesComponentNgFactory };
