import { ImportacioDenunciaFilter } from './../models/importacio-denuncia-filter';
import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class ImportacioDenunciaFilterService extends CustomFilterService {
  public createFilterParams(filter: ImportacioDenunciaFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('idImportacio', filter.idImportacio)
    .append('descImportacio', filter.descImportacio)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
