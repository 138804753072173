/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alarmes-historic.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i6 from "../../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i7 from "../../../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i8 from "../../../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i9 from "../../../alarmes-table/alarmes-table.component.ngfactory";
import * as i10 from "../../../alarmes-table/alarmes-table.component";
import * as i11 from "../../../../services/alarmes.service";
import * as i12 from "../../../../../../shared/services/filter/filter.service";
import * as i13 from "../../../../../../shared/services/excel/excel-download.service";
import * as i14 from "@angular/material/dialog";
import * as i15 from "@ngx-translate/i18n-polyfill";
import * as i16 from "@angular/router";
import * as i17 from "../../../../../../shared/services/parameters/parameters.service";
import * as i18 from "./alarmes-historic.component";
import * as i19 from "../../../../../../shared/services/startup/google-maps.service";
import * as i20 from "../../../../../../shared/services/authentication/auth-guard.service";
import * as i21 from "../../../../../../shared/services/authorization/authorization-service";
import * as i22 from "ngx-toastr";
import * as i23 from "../../../../../../shared/services/notification/notification.service";
import * as i24 from "../../../../../../shared/components/permission-dialog/permission.service";
import * as i25 from "../../../../../../shared/components/confirmation-dialog/confirmation-dialog.service";
var styles_AlarmesHistoricComponent = [i0.styles];
var RenderType_AlarmesHistoricComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlarmesHistoricComponent, data: {} });
export { RenderType_AlarmesHistoricComponent as RenderType_AlarmesHistoricComponent };
function View_AlarmesHistoricComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["label", "Alarmes"]], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Alarmes"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AlarmesHistoricComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { paginator: 0 }), i1.ɵqud(402653184, 2, { alarmesTable: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlarmesHistoricComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "app-toolbar", [], null, null, null, i5.View_ToolbarComponent_0, i5.RenderType_ToolbarComponent)), i1.ɵdid(5, 114688, null, 0, i6.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "app-tool-button", [["color", "has-color-yellow"], ["icon", "fa-th-list"], ["text", "Llista"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goLlista() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(7, 114688, null, 0, i8.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "app-tool-button", [["color", "has-color-blue"], ["icon", "fa-sync"], ["text", "Actualitzar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(9, 114688, null, 0, i8.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "app-tool-button", [["color", "has-color-orange"], ["icon", "fa-filter"], ["text", "Filtre"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFilterClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ToolButtonComponent_0, i7.RenderType_ToolButtonComponent)), i1.ɵdid(11, 114688, null, 0, i8.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-alarmes-table", [], null, [[null, "notify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("notify" === en)) {
        var pd_0 = (_co.onRowSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_AlarmesTableComponent_0, i9.RenderType_AlarmesTableComponent)), i1.ɵdid(13, 4833280, [[2, 4]], 0, i10.AlarmesTableComponent, [i11.AlarmesService, i12.FilterService, i13.ExcelDownloadService, i14.MatDialog, i15.I18n, i16.Router, i17.ParametersService, i14.MAT_DIALOG_DATA], { updatedFilterData: [0, "updatedFilterData"], selectButton: [1, "selectButton"], isHistoric: [2, "isHistoric"] }, { notify: "notify" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataDialog.isDialog; _ck(_v, 3, 0, currVal_0); _ck(_v, 5, 0); var currVal_1 = "Llista"; var currVal_2 = "has-color-yellow"; var currVal_3 = "fa-th-list"; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "Actualitzar"; var currVal_5 = "has-color-blue"; var currVal_6 = "fa-sync"; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = "Filtre"; var currVal_8 = "has-color-orange"; var currVal_9 = "fa-filter"; _ck(_v, 11, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.filterData; var currVal_11 = (_co.dataDialog.isDialog && !_co.dataDialog.isReadOnly); var currVal_12 = true; _ck(_v, 13, 0, currVal_10, currVal_11, currVal_12); }, null); }
export function View_AlarmesHistoricComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alarmes-historic", [], null, null, null, View_AlarmesHistoricComponent_0, RenderType_AlarmesHistoricComponent)), i1.ɵdid(1, 4308992, null, 0, i18.AlarmesHistoricComponent, [i19.GoogleMapsService, i20.AuthGuardService, i16.ActivatedRoute, i14.MatDialog, i21.AuthorizationService, i16.Router, i11.AlarmesService, i15.I18n, i22.ToastrService, i23.NotificationService, i14.MatDialogRef, i14.MAT_DIALOG_DATA, i24.PermissionService, i25.ConfirmationDialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlarmesHistoricComponentNgFactory = i1.ɵccf("app-alarmes-historic", i18.AlarmesHistoricComponent, View_AlarmesHistoricComponent_Host_0, {}, {}, []);
export { AlarmesHistoricComponentNgFactory as AlarmesHistoricComponentNgFactory };
