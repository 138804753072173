/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./page-title.component";
var styles_PageTitleComponent = [i0.styles];
var RenderType_PageTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageTitleComponent, data: {} });
export { RenderType_PageTitleComponent as RenderType_PageTitleComponent };
export function View_PageTitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "header-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getClass(); _ck(_v, 0, 0, currVal_0); }); }
export function View_PageTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-title", [], null, null, null, View_PageTitleComponent_0, RenderType_PageTitleComponent)), i1.ɵdid(1, 114688, null, 0, i2.PageTitleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageTitleComponentNgFactory = i1.ɵccf("app-page-title", i2.PageTitleComponent, View_PageTitleComponent_Host_0, { float: "float" }, {}, ["*"]);
export { PageTitleComponentNgFactory as PageTitleComponentNgFactory };
