import { Component, OnInit } from '@angular/core';
import {MatSliderModule} from '@angular/material/slider';
import { HeatMapService } from './services/heat-map.service';
import { Instituciones } from 'path-shared/models/instituciones';
import { Tramo } from '../tramos/tramos/models/tramo.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Dia } from 'app/sections/ciutat/gestio-residents/models/gestio-residents-cites.model';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.scss']
})
export class HeatMapComponent implements OnInit {
  HeatMapForm:FormGroup;
  public zoomMapa: number;
  public tramos: Tramo[]= [];



  public currentLocation: {
    latitude: number,
    longitude: number
  };

  filtroTickets:FiltroTickets={
    Dia: new Date(Date.now()),
    HoraIni:"",
    HoraFin:""
  }

  
  constructor(
    private HeatMapService: HeatMapService,
    private formBuilder: FormBuilder ) { 
      this.zoomMapa = 16;
      const inst = sessionStorage.getItem('currentInst');
      if (inst !== '' && inst !== undefined && inst !== 'undefined') {
        // No tenemos ningun punto, intentamos definir como centro el municipio actual.
        const institution: Instituciones = JSON.parse(inst);
        if (institution.Latitud !== undefined && institution.Longitud !== undefined) {
          this.currentLocation = { latitude: institution.Latitud, longitude: institution.Longitud };
        }
      }
      this.HeatMapForm = this.formBuilder.group({
        Dia:[formatDate(this.filtroTickets.Dia, 'dd-MM-yyyy', 'es'), [Validators.required]],
      });

    }

  ngOnInit() {
   
  }

  submit(){
    console.log("submit");
    console.log(this.filtroTickets);
    this.HeatMapService.getHeatMapData(this.filtroTickets).subscribe((data:Tramo[])=>{
      try{
      this.tramos=data;
      }
      catch(e){
        console.log(e);
      }
    });
  }

  public getColors(): nombreColor[]{
    return [
      {name:"verde",color:"#bef018",rango:'0%-20%'},
      {name:"amarillo",color:"#f0de18",rango:'21%-40%'},
      {name:"naranja_suave",color:"#eda109",rango:'41%-60%'},
      {name:"naranja",color:"#ed7b09",rango:'61%-80%'},
      {name:"rojo",color:"#ed1109",rango:'81%-100%'}
    ];
  }
  
}

interface nombreColor{
  name:string;
  color:string;
  rango:string;
}
