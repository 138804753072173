var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'descripcio';
var defaultSortDirection = 'asc';
var BonosResidentesFilterService = /** @class */ (function (_super) {
    __extends(BonosResidentesFilterService, _super);
    function BonosResidentesFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BonosResidentesFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('id', Reflection.hasValue(filter.id) ? filter.id.toString() : '')
            .append('importe', Reflection.hasValue(filter.importe) ? filter.importe.toString() : '')
            .append('nombre', filter.nombre ? filter.nombre : '')
            .append('apellidos', filter.apellidos ? filter.apellidos : '')
            .append('zona', filter.zona ? filter.zona : '')
            .append('telefono', filter.telefono ? filter.telefono : '')
            .append('matricula', filter.matricula)
            .append('dni', filter.dni ? filter.dni : '')
            .append('tipResPrepago', filter.tipResPrepago !== null ? filter.tipResPrepago.toString() : '')
            .append('tipBono', filter.tipBono !== null ? filter.tipBono.toString() : '')
            .append('pago', filter.pago ? filter.pago : '')
            .append('dataIni', filter.dataIni !== null ? filter.dataIni.toDateString() : '')
            .append('dataFi', filter.dataFi !== null ? filter.dataFi.toDateString() : '')
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return BonosResidentesFilterService;
}(CustomFilterService));
export { BonosResidentesFilterService };
