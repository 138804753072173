import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ZonesModel } from 'app/sections/ciutat/zones/models/zones.model';
import { ZonesService } from 'app/sections/ciutat/zones/services/zones.service';
import { CarrersService } from 'app/sections/configuracio/carrers/services/carrers-service.service';

@Component({
  selector: 'app-comprovacio-carrers',
  templateUrl: './comprovacio-carrers.component.html',
  styleUrls: ['./comprovacio-carrers.component.scss']
})
export class ComprovacioCarrersComponent implements OnInit {

  carrers = [];
  selectedCarrer: string;
  zones: ZonesModel[];
  displayedColumns = ['ID_ZONA', 'DESC_ZONA', 'FECHA_INICIO', 'FECHA_FIN', 'ID_TARIFA', 'ID_TIPO_TICKET', 'DENUNCIAS_POR_AREA', 'ID_ZONA_PADRE'];

  constructor(
    private carrersService: CarrersService,
    private zonesService: ZonesService
  ) { }

  ngOnInit() {
    this.loadCalles();
  }

  loadCalles() {
    let json: string;
    let carrers: any[];

    if (sessionStorage.getItem('carrersForCombo')) {
      json = sessionStorage.getItem('carrersForCombo');
      carrers = JSON.parse(json.toString());
      this.setCalles(carrers);
    } else {
      this.carrersService.getAllForComboZona().subscribe(carrersForCombo => {
        json = JSON.stringify(carrersForCombo);
        sessionStorage.setItem('carrersForCombo', json);
        this.setCalles(carrersForCombo);
      });
    }
  }

  setCalles(carrers: any[]) {
    if (carrers !== null) {
      let i: number;
      i = 0;
      carrers.forEach(element => {
        this.carrers[i] = {'idCarrer': element.SECURE_ID, 'nomCarrer': element.nomCarrer};
        i++;
      });
    }
    this.carrers.unshift({'nomCarrer': ''});
  }

  comprovarZona() {
    this.zonesService.getZonesByCarrer(this.selectedCarrer).subscribe(res => {
      this.zones = res
    })
  }
}
