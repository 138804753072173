import { Sigla } from './../../model/sigla';
import { Dictionary } from 'path-shared/models/dictionary';
import { BehaviorSubject } from 'rxjs';
import { Builder } from 'path-shared/models/builder';
import { TextoInformativoTableComponent as TextoInformativoTableComponent } from '../../components/texto-informativo-table/texto-informativo-table.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { TextoInformativoFilter } from '../../model/texto-informativo.filter';
import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { TextoInformativoService } from '../../services/texto-informativo.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { TextoInformativoEditorComponent } from '../../components/texto-informativo-editor/texto-informativo-editor.component';
import { TextoInformativoFilterComponent } from '../../components/texto-informativo-filter/texto-informativo-filter.component';
import { TextoInformativo } from '../../model/texto-informativo';

@Component({
  selector: 'app-texto-informativo-page-component',
  templateUrl: './texto-informativo-page.component.html',
  styleUrls: ['./texto-informativo-page.component.scss']
})
export class TextoInformativoPageComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: TextoInformativoFilter;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  editing = false;
  creating = false;
  currentTexto: TextoInformativo;

  siglasDictionary: BehaviorSubject<Array<Dictionary>>;

  @ViewChild(TextoInformativoTableComponent) textoInformativoTable: TextoInformativoTableComponent;
  @ViewChild(TextoInformativoEditorComponent) textoInformativoEditor: TextoInformativoEditorComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private textoInformativoService: TextoInformativoService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<TextoInformativoPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog
  ) {
    this.filterData = new TextoInformativoFilter('descripcion', 'asc');
    if (data !== undefined && data !== null) {
      this.dataDialog = data;
    }
    this.siglasDictionary = new BehaviorSubject(new Array());
  }

  //#region COMPONENT LIFECYCLE

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.TextoInformativoAparcare);

    // Descargamos las siglas.
    this.textoInformativoService.getSiglasTextos().subscribe((result: Array<Sigla>) => {
      const siglas = new Array<Dictionary>();
      result.forEach(element => {
        siglas.push(new Dictionary(element.sigla, element.pais));
      });
      this.siglasDictionary.next(siglas);
    });
  }

  ngAfterViewInit(): void {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Text Informatiu Aparcare'));
    }
  }

  //#endregion

  //#region EVENTS

  edit(): void {
    this.editing = true;
  }

  create(): void {
    this.creating = true;
    this.currentTexto = Builder.newTextoInformativo();
    // Debemos indicar a la tabla que queremos deseleccionar los elementos seleccionados.
    this.textoInformativoTable.resetSelection();
  }

  refresh(): void {
    // this.textoInformativoTable.load();
    this.clear();
  }

  onRowSelected(row: TextoInformativo): void {
    if (row !== undefined) {
      this.currentTexto = Object.assign({}, row);
    } else {
      this.currentTexto = undefined;
    }
  }

  save(): void {
    this.textoInformativoService.setTextoInformativo(this.textoInformativoEditor.currentTexto).subscribe(result => {
      // Hemos guardado el nuevo elemento. Actualizamos la tabla y el editor.
      this.clear();
    });
  }

  clear() {
    this.textoInformativoTable.load();
    this.textoInformativoTable.resetSelection();
    this.creating = false;
    this.editing = false;
    this.currentTexto = undefined;
  }

  cancel(): void {
    this.creating = false;
    this.editing = false;
    this.currentTexto = this.currentTexto;
  }

  filterUpdated(updated: boolean) {
    if (updated) {
      this.clear();
    }
  }

  //#endregion

  //#region DIALOGS OPENING


  onFilterClicked(): void {
    const ref = this.dialog.open(TextoInformativoFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.textoInformativoTable.filter,
        'idiomas': this.siglasDictionary.value
      }
    });
    ref.componentInstance.notify.subscribe((filter: TextoInformativoFilter) => {
      this.textoInformativoTable.filter.set(filter);
      this.textoInformativoTable.load();
    });
  }

  //#endregion

}
