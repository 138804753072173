import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { LocalizacionFilterService } from './localizacion-filter.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../../shared/services/authentication/auth-guard.service";
var defaultSortParam = 'codi';
var defaultSortDirection = 'desc';
var LocalizacionService = /** @class */ (function () {
    function LocalizacionService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
    }
    LocalizacionService.prototype.addLocalizacion = function (data) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetLocalizacion'], data)
                .pipe(map((function (e) {
                return e;
            })));
        }
    };
    LocalizacionService.prototype.getLocalizacionPropuestas = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetLocalizacionPropuestas'], null);
        }
    };
    LocalizacionService.prototype.getLocalizacion = function (filter, sortDir, sortField) {
        var start = 0;
        var pageSize = 1000;
        sortField = sortField === undefined ? defaultSortParam : sortField;
        sortDir = sortDir === '' ? defaultSortDirection : sortDir;
        var params = new LocalizacionFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetLocalizacion'], params)
                .pipe(map(function (e) {
                return e;
            }));
        }
    };
    LocalizacionService.prototype.getLocalizacionesAparell = function (idAparell) {
        if (this.authGuard.canActivate()) {
            idAparell = idAparell.replace('/', '$');
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetLocalizacionesAparell'].replace('{idAparell}', idAparell), null)
                .pipe(map(function (e) {
                return e;
            }));
        }
    };
    LocalizacionService.prototype.removeLocalizacion = function (id) {
        if (this.authGuard.canActivate()) {
            return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteLocalizacion'].replace('{id}', id), null);
        }
    };
    LocalizacionService.prototype.setActiu = function (id, actiu) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlLocalizacionSetActiu'].replace('{id}', id), actiu ? 1 : 0);
        }
    };
    LocalizacionService.ngInjectableDef = i0.defineInjectable({ factory: function LocalizacionService_Factory() { return new LocalizacionService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: LocalizacionService, providedIn: "root" });
    return LocalizacionService;
}());
export { LocalizacionService };
