<app-toolbar>
  <app-tool-button-permissions [permission]="this.auth.Pages.Estadistiques"></app-tool-button-permissions>
  <!-- <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel">
  </app-tool-button> -->
  <app-tool-button text="Reiniciar filtros" i18n-text (click)="this.restartFilter();" color="has-color-blue"
    icon="fa-sync" badge="new"></app-tool-button>
</app-toolbar>
<div class="row justify-content-around">
  <app-form-combo-edit class="col-2" i18n-label label="Tipo" [(value)]="tipoSelected" [items]="tipos" valueField="codi"
    textField="codi"></app-form-combo-edit>
  <app-form-date-edit id="estadistiques-varios-data" [view]="'year'" class="col-2" i18n-label
    label="Fecha final período" [(value)]="dateSelected"></app-form-date-edit>
  <app-form-combo-edit class="col-2" i18n-label label="Agrupación" [(value)]="tipoTemporizacionSelected"
    [items]="tiposTemporizacion" valueField="label" textField="label"></app-form-combo-edit>
  <!-- MULTI-SELECT AGENTES -->
  <app-form-multi-select *ngIf="tipoSelected==='DENUNCIAS' || tipoSelected==='DETECCIONES'" tituloMultiSelect="Agente"
    class="col-2" [data]="agents" [(selectedValues)]="agentesSelected" maxItems="3"></app-form-multi-select>
  <app-form-combo-edit *ngIf="tipoSelected==='PROPUESTAS'" class="col-2" i18n-label label="Estado"
    [(value)]="estadoPropuestaSelected" [items]="estadosPropuesta" valueField="codi" textField="descripcio">
  </app-form-combo-edit>
  <!-- MULTI-SELECT EQUIPOS -->
  <app-form-multi-select *ngIf="tipoSelected!=='TICKETS'" tituloMultiSelect="Equipo" [data]="aparells" maxItems="3"
    [(selectedValues)]="aparellsSelected" class="col-2"></app-form-multi-select>

  <button class="btn btn-primary button-not-allowed btn-lg col-2" type="button" style="max-height: 5vh;" (click)="this.reload()">
    <i class="fas fa-sync-alt"></i> &nbsp;<span i18n>Consultar</span> &nbsp;
  </button>
</div>


<div class="row" *ngIf="barChartLabels && barChartData && !showMultiLine">
  <div class="col-10">
    <canvas *ngIf="dataRecived" baseChart [datasets]="barChartData" [labels]="barChartLabels"
      [options]="barChartOptions" [legend]="true" [chartType]="barChartType" [colors]="this.colors">
    </canvas>
  </div>
</div>

<div class="row" *ngIf="barChartLabels && barChartData && showMultiLine">
  <div class="col-10">
    <canvas *ngIf="dataRecived" baseChart [datasets]="barChartDataMultiLine" [labels]="barChartLabels"
      [options]="barChartOptions" [legend]="true" [chartType]="barChartType" [colors]="this.colors">
    </canvas>
  </div>
</div>