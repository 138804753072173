var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'descripcio';
var defaultSortDirection = 'asc';
var PersonesFilterService = /** @class */ (function (_super) {
    __extends(PersonesFilterService, _super);
    function PersonesFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PersonesFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('id', Reflection.hasValue(filter.id) ? filter.id.toString() : '')
            .append('nomComplert', filter.nomComplet ? filter.nomComplet : '')
            .append('dni_pasp', filter.dni_pasp ? filter.dni_pasp : '')
            .append('nom_pare', filter.nom_pare ? filter.nom_pare : '')
            .append('nom_mare', filter.nom_mare ? filter.nom_mare : '')
            .append('lloc_naixament', filter.lloc_naixament ? filter.lloc_naixament : '')
            .append('provincia', filter.provincia ? filter.provincia : '')
            .append('nacionalitat', filter.nacionalitat ? filter.nacionalitat : '')
            .append('telefon1', filter.telefon1 ? filter.telefon1 : '')
            .append('telefon2', filter.telefon2 ? filter.telefon2 : '')
            .append('id_extern', filter.id_extern ? filter.id_extern : '')
            .append('buscat', this.getTristateValue(filter.buscat))
            .append('antecedents', this.getTristateValue(filter.antecedents))
            .append('juridica', this.getTristateValue(filter.juridica))
            .append('data_naixament', Reflection.empty(filter.data_naixament) ? null : typeof (filter.data_naixament) === 'string' ?
            filter.data_naixament : filter.data_naixament.toDateString())
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    PersonesFilterService.prototype.getTristateValue = function (value) {
        if (value === null) {
            return '';
        }
        else {
            return value.toString();
        }
    };
    return PersonesFilterService;
}(CustomFilterService));
export { PersonesFilterService };
