export class SingleEditDialogData {
  public title: string;
  public text: string;
  public value: string;
  public mask: RegExp;
  public maskError: string;

  constructor(title, text, value, mask = null, maskError = '') {
    this.title = title;
    this.text = text;
    this.value = value;
    this.mask = mask;
    this.maskError = maskError;
  }
}
