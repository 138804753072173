<app-dialog-header label="Infraccions" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Infraccions) || !this.dialogData.isReadOnly" *ngIf="this.dialogData.isReadOnly && this.editable"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Infraccions) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Infraccions) || this.dialogData.isReadOnly || !this.dialogData.editable" ></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-combo-edit class="col-xl-3" i18n-label label="Legislació" [items]="this.tipusLegislacions" [(value)]="this.infraccio.legislacio" valueField="codi" textField="descripcio" [isReadOnly]="this.dialogData.isReadOnly" required></app-form-combo-edit>
      <app-form-text-edit id="infraccions-editor-article" class="col-xl-1" i18n-label label="Article" [(value)]="this.infraccio.article" [maxLength]="3" [isReadOnly]="this.dialogData.isReadOnly" required></app-form-text-edit>
      <app-form-text-edit id="infraccions-editor-apartat" class="col-xl-1" i18n-label label="Apartat" [(value)]="this.infraccio.apartat" [maxLength]="5" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-text-edit id="infraccions-editor-opcio" class="col-xl-1" i18n-label label="Opció" [(value)]="this.infraccio.opcio" [maxLength]="5" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-combo-edit class="col-xl-2" i18n-label label="Gravetat" [items]="this.tipusGravetats" [(value)]="this.infraccio.gravetat" valueField="codi" textField="descripcio" [isReadOnly]="this.dialogData.isReadOnly" required></app-form-combo-edit>
      <app-form-text-edit id="infraccions-editor-importMin" class="col-xl-1" i18n-label label="Import mínim" [(value)]="this.infraccio.import" number maxLenght="11" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-text-edit id="infraccions-editor-importMax" class="col-xl-1" i18n-label label="Import màxim" [(value)]="this.infraccio.fins" number maxLenght="11" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-text-edit id="infraccions-editor-descompte" class="col-xl-1" i18n-label label="Descompte" [(value)]="this.infraccio.descompte" number maxLenght="2" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit><span  style="padding-top: 1vh; margin-left: -2vh;">%</span>
    </div>
    <div class="row">
      <app-form-text-edit id="infraccions-editor-codPropi" class="col-xl-1" i18n-label label="Cod. Propi." [(value)]="this.infraccio.codiPropi" number maxLenght="5" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-combo-edit class="col-xl-2" i18n-label label="Alias grua" [items]="this.tipusGrues" [(value)]="this.infraccio.idGrua" valueField="codi" textField="descripcio" [isReadOnly]="this.dialogData.isReadOnly"></app-form-combo-edit>
      <app-form-text-edit id="infraccions-editor-condcuc" class="col-xl-1" i18n-label label="Conduc." [(value)]="this.infraccio.conduc" maxLenght="5" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-text-edit id="infraccions-editor-punts" class="col-xl-1" i18n-label label="Punts" [(value)]="this.infraccio.punts" maxLenght="2" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-combo-edit class="col-xl-2" i18n-label label="Infracció PDA" [items]="this.tipusZB" [(value)]="this.infraccio.tipusDenuncia" valueField="codi" textField="descripcio" [isReadOnly]="this.dialogData.isReadOnly"></app-form-combo-edit>
      <app-form-combo-edit class="col-xl-2" i18n-label label="Infracció DGT" [items]="this.infraccionsDGT" [(value)]="this.infraccio.infraccioDGT" valueField="codi" textField="descripcio" [isReadOnly]="this.dialogData.isReadOnly"></app-form-combo-edit>
      <app-form-combo-edit class="col-xl-1" i18n-label label="Tipus denúncia" [items]="this.tipusTipus" [(value)]="this.infraccio.tipus" valueField="codi" textField="descripcio" [isReadOnly]="this.dialogData.isReadOnly" required></app-form-combo-edit>
      <app-form-text-edit id="infraccions-editor-idExtern" class="col-xl-1" i18n-label label="ID extern" [(value)]="this.infraccio.idExterno" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
    </div>
    <div class="row"></div>
    <div class="row">
      <app-form-date-edit id="infraccions-editor-dataIni" class="col-xl-2" i18n-label label="Data Inici" [(value)]="this.infraccio.inici" type="calendar" [isReadOnly]="this.dialogData.isReadOnly"></app-form-date-edit>
      <app-form-date-edit id="infraccions-editor-dataFi" class="col-xl-2" i18n-label label="Data Fi" [(value)]="this.infraccio.final" type="calendar" [isReadOnly]="this.dialogData.isReadOnly"></app-form-date-edit>
      <app-form-text-edit id="infraccions-editor-numFotos" class="col-xl-1" i18n-label label="Núm fotos PDA" [(value)]="this.infraccio.numFotosPda" number maxLenght="1" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-text-edit id="infraccions-editor-infracParkeon" class="col-xl-1" i18n-label label="Infrac. Parkeon" [(value)]="this.infraccio.infracParkeon" number maxLenght="7" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-text-edit id="infraccions-editor-aliasPDA" class="col-xl-2" i18n-label label="Alias PDA" [(value)]="this.infraccio.aliasPda" maxLenght="50" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-text-edit id="infraccions-editor-tempsAnul" class="col-xl-1" i18n-label label="Temps anul·lació" [(value)]="this.infraccio.tempAnul" number maxLenght="4" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-text-edit id="infraccions-editor-preuAnul" class="col-xl-1" i18n-label label="Preu anul·lació" [(value)]="this.infraccio.preuAnul" number maxLenght="12" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-text-edit id="infraccions-editor-partidaCompt" class="col-xl-1" i18n-label label="Partida compt." [(value)]="this.infraccio.partidaCompt" maxLenght="50" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-multi-select class="col-4" titulo="Zones" [data]="zones" [(selectedValues)]="this.infraccio.zones" i18n-titulo tituloMultiSelect="Selecciona una o més" i18n-tituloMultiSelect [maxItems]="3" required [isReadOnly]="this.dialogData.isReadOnly"></app-form-multi-select>
    </div>
    <div>
      <div style="border:0.5px solid #afb1b3; border-radius:5px;">
        <label i18n class="row " style="margin-left:4vh;margin-top:1vh;">Infracció DGT</label>
        <br/>
        <div class="row" style="margin-left:4vh;" >
          <app-form-text-edit id="infraccions-editor-normativa" class="col-xl-2" i18n-label label="Normativa" [(value)]="this.infraccio.nomrativaDgt" maxLenght="2" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
          <app-form-text-edit id="infraccions-editor-articleDGT" class="col-xl-1" i18n-label label="Article" [(value)]="this.infraccio.articleDgt" maxLenght="2" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
          <app-form-text-edit id="infraccions-editor-apartatDGT" class="col-xl-1" i18n-label label="Apartat" [(value)]="this.infraccio.apartatDgt" maxLenght="10" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
          <app-form-text-edit id="infraccions-editor-opcioDGT" class="col-xl-1" i18n-label label="Opció" [(value)]="this.infraccio.opicoDgt" maxLenght="10" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
          <app-form-text-edit id="infraccions-editor-articleLSVFGT" class="col-xl-1" i18n-label label="Article LSV" [(value)]="this.infraccio.articleLSVDgt" maxLenght="3" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
          <app-form-text-edit id="infraccions-editor-apartatLSVDGT"class="col-xl-1" i18n-label label="Apartat LSV" [(value)]="this.infraccio.apartatLSVDgt" maxLenght="3" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
          <app-form-text-edit id="infraccions-editor-opcioLSVDGT" class="col-xl-1" i18n-label label="Opció LSV" [(value)]="this.infraccio.opcioLSVDgt" maxLenght="3" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
        </div>
      </div>             

    </div>
    <div class="row" style="margin-top:2vh;margin-left:2vh;">
      <app-form-check-edit class="col-xl-1" i18n-label label="Obsoleta" [(value)]="this.infraccio.obsoleta" [disabled]="this.dialogData.isReadOnly"></app-form-check-edit>
      <app-form-check-edit class="col-xl-1" i18n-label label="Preferida" [(value)]="this.infraccio.fav" [disabled]="this.dialogData.isReadOnly"></app-form-check-edit>
      <app-form-check-edit style="margin-right:5vh;" class="col-xl-1" i18n-label label="Obligar resolució" [(value)]="this.infraccio.obligResol" [disabled]="this.dialogData.isReadOnly"></app-form-check-edit>
      <app-form-check-edit class="col-xl-1" i18n-label label="Retirada" [(value)]="this.infraccio.retirada" [disabled]="this.dialogData.isReadOnly"></app-form-check-edit>
      <app-form-check-edit class="col-xl-2" i18n-label label="No aplicar Abreviat" [(value)]="this.infraccio.noAplicarAbreviat" [disabled]="this.dialogData.isReadOnly"></app-form-check-edit>
      <app-form-check-edit class="col-xl-1" i18n-label label="Requereix Vehicle" [(value)]="this.infraccio.datavehreq" [disabled]="this.dialogData.isReadOnly"></app-form-check-edit>
      <br/>
    </div>
    <app-form-text-area class="col-lg-10" i18n-label label="Descripció curta" [(value)]="this.infraccio.descCurta" maxLength="60" [disabled]="this.dialogData.isReadOnly"></app-form-text-area>

    <div class="row">
      <app-form-text-area class="col" i18n-label label="Descripció llarga" [(value)]="this.infraccio.descLlarga" maxLength="500" [disabled]="this.dialogData.isReadOnly"></app-form-text-area>
    </div>
  </form>
</app-dialog-body>
