import * as i0 from "@angular/core";
var ValidationService = /** @class */ (function () {
    function ValidationService() {
    }
    ValidationService.prototype.isEmpty = function (val) {
        return (val === undefined || val === null || val.toString().length === 0);
    };
    ValidationService.prototype.isNatural = function (val) {
        if (this.isEmpty(val)) {
            return false;
        }
        else {
            var regex = new RegExp(/^[0-9]+$/);
            return regex.test(val);
        }
    };
    ValidationService.prototype.isInteger = function (val) {
        if (this.isEmpty(val)) {
            return false;
        }
        else {
            var regex = new RegExp(/^[-]{0,1}[0-9]+$/);
            return regex.test(val);
        }
    };
    ValidationService.prototype.isDecimal = function (val) {
        if (this.isEmpty(val)) {
            return false;
        }
        else {
            var regex = new RegExp(/^[-]{0,1}[0-9]+[\.][0-9]*$/);
            return regex.test(val);
        }
    };
    ValidationService.prototype.isNumeric = function (val) {
        return this.isInteger(val) || this.isDecimal(val);
    };
    ValidationService.prototype.checkLength = function (val, maxLength) {
        if (this.isEmpty(val)) {
            return true;
        }
        else {
            return val.toString().length <= maxLength;
        }
    };
    ValidationService.prototype.isBoolean = function (val) {
        if (val === undefined || val === null) {
            return false;
        }
        else {
            var regex = new RegExp(/^(true)|(false)$/);
            return regex.test(val);
        }
    };
    ValidationService.prototype.isMatricula = function (val) {
        if (val === undefined || val === null) {
            return false;
        }
        else {
            var regex = new RegExp(/^[a-zA-Z0-9]*$/i);
            return regex.test(val);
        }
    };
    ValidationService.ngInjectableDef = i0.defineInjectable({ factory: function ValidationService_Factory() { return new ValidationService(); }, token: ValidationService, providedIn: "root" });
    return ValidationService;
}());
export { ValidationService };
