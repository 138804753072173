<div class="table-sdw">
  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <th i18n>Exp. Origen</th>
        <th i18n>Exp. Destí</th>
        <th i18n>Exp. Operació</th>
        <th i18n>Exp. Usuari</th>
        <th i18n>Exp. Data</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let row of data">
        <td><a (click)="onExpClicked(row.EXP_ORIGEN)" class="is-link">{{row.EXP_ORIGEN}}</a></td>
        <td><a (click)="onExpClicked(row.EXP_DESTINO)" class="is-link">{{row.EXP_DESTINO}}</a></td>
        <td>{{row.OPERACION_ID}}</td>
        <td>{{row.USUARIO}}</td>
        <td>{{row.FECHA_ALTA | shortDate}}</td>
      </tr>
    </tbody>
  </table>
</div>

