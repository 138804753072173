<app-filter-header title="Filtrar propuestas Anulacion" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  
    <div class="row">
      <app-form-text-edit id="tramitades-filter-vehicle" class="col-sm-3" i18n-label label="Exp" [(value)]="filter.exp" ></app-form-text-edit>
      <app-form-text-edit id="tramitades-filter-carrer" class="col-sm-3" i18n-label label="Matricula" [(value)]="filter.matricula" ></app-form-text-edit>
      <app-form-tri-state class="col-sm-3" i18n-label label="Estado" i18n-labelSi labelSi="Aceptada" i18n-labelNo labelNo= "Rechazada"
      i18n-labelTodos labelTodos="Tots" [(value)]="this.filter.acceptada"></app-form-tri-state>
    </div>
    <div class="row">
      <app-form-date-edit id="propostaAnulacio-filter-dataIni" class="col-sm-3" i18n-label label="Fecha Acep/Deng inicio" [(value)]="filter.dataAceptDengIni" type="calendar"></app-form-date-edit>
      <app-form-date-edit id="propostaAnulacio-filter-dataFi"  class="col-sm-3" i18n-label label="Fecha Acep/Deng fin" [(value)]="filter.dataAceptDengFi" type="calendar"></app-form-date-edit>
      <app-form-date-edit id="diposit-filter-dataIniSort" class="col-sm-3" i18n-label label="Fecha denuncia inicio" [(value)]="filter.dataDenunciaIni" type="calendar"></app-form-date-edit>
      <app-form-date-edit id="diposit-filter-dataFiSort" class="col-sm-3" i18n-label label="Fecha denuncia fin" [(value)]="filter.dataDenunciaFi" type="calendar"></app-form-date-edit>
      
    </div>
    <div class="row">
      <app-form-date-edit id="propostaAnulacio-filter-dataPropIni" class="col-sm-3" i18n-label label="Fecha propuesta inicio" [(value)]="filter.dataPropIni" type="calendar"></app-form-date-edit>
      <app-form-date-edit id="propostaAnulacio-filter-dataPropFi"  class="col-sm-3" i18n-label label="Fecha propuesta fin" [(value)]="filter.dataPropFi" type="calendar"></app-form-date-edit>
     
      
    </div>
  
</div>