import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { ReportsComponent } from 'path-shared/components/reports/reports.component';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { TramitarService } from '../../services/tramitar.service';
import { TableRevisioExecutivaOlotComponent } from './table-revisio-executiva-olot/table-revisio-executiva-olot.component';
import { TableTramitarExecutivaOlotComponent } from './table-tramitar-executiva-olot/table-tramitar-executiva-olot.component';
import { TableTramitarExecutivaComponent } from './table-tramitar-executiva/table-tramitar-executiva.component';
import { TramitarDatesComponent } from './tramitar-dates/tramitar-dates.component';


@Component({
  selector: 'app-executiva',
  templateUrl: './executiva.component.html',
  styleUrls: ['./executiva.component.scss']
})
export class ExecutivaComponent implements OnInit {
  visible: boolean;
  LimitarExecutiva: string;
  MostrarExecutiva: boolean;  
  reports = [];

  constructor( 
    private dialog: MatDialog,
    private i18n: I18n,
    private toastr: ToastrService,
    public auth: AuthorizationService,
    private tramitarService: TramitarService) {
    this.tramitarService.getReports(this.auth.Pages.TramitarExecutiva).subscribe( res => {
      this.reports = res;
    });
   }

  ngOnInit() {
    this.LimitarExecutiva = 'N';
    this.MostrarExecutiva = true;
    // Només Olot visible
    this.visible = (sessionStorage.getItem('currentInst') === '100105220425938306127'); 
  }

  OpenTramitarOlot() {
    const data: ComponentDialog = Builder.newComponentDialog(true, true);
      const ref = this.dialog.open(TableRevisioExecutivaOlotComponent, {
        width: '70%',
        height: '50%',
        maxHeight: '50vh',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });
  }
  

  OpenLlistaExecutiva() {
    this.tramitarService.getLimitarExecutiva().subscribe(x => {
      if (x !== undefined && x !== null) {
        this.LimitarExecutiva = x;
      }
      if (this.LimitarExecutiva === 'Y') {
      this.tramitarService.getComptadorsAZero().subscribe(y => {
        if (y !== undefined && y !== null) {
          this.MostrarExecutiva = y;
        }
      });
      }
      if (this.MostrarExecutiva === true) {
        const data: ComponentDialog = Builder.newComponentDialog(true, true);
        if (sessionStorage.getItem('currentInst') === '100105220425938306127') {
          const ref = this.dialog.open(TableTramitarExecutivaOlotComponent, {
            width: '70%',
            height: '50%',
            maxHeight: '50vh',
            closeOnNavigation: false,
            autoFocus: true,
            data: data
          });
        } else {
          const ref = this.dialog.open(TableTramitarExecutivaComponent, {
            width: '70%',
            height: '50%',
            maxHeight: '50vh',
            closeOnNavigation: false,
            autoFocus: true,
            data: data
          });
        }
      } else {
        this.toastr.error(this.i18n('No es pueden pasar expedientes a ejecutiva mientras haya expedientes pendientes de notificar'));
      }
      
    });
  }
  
  Impressio() {
    const data: ComponentDialog = Builder.newComponentDialog(true, true);
      const ref = this.dialog.open(TramitarDatesComponent, {
        width: '20%',
        height: '20%',
        maxHeight: '30vh',
        closeOnNavigation: false,
        autoFocus: true,
        data: data
      });
  }
  

}
