var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { DateUtils } from 'path-shared/utils/date-utils';
var defaultSortParam = 'NUMDENUNCIA';
var defaultSortDirection = 'desc';
var PropostesFilterService = /** @class */ (function (_super) {
    __extends(PropostesFilterService, _super);
    function PropostesFilterService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dateUtils = new DateUtils();
        return _this;
    }
    PropostesFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('proposta', Reflection.hasValue(filter.proposta) ? filter.proposta : '')
            .append('tipusproposta', Reflection.hasValue(filter.tipusproposta) ? filter.tipusproposta : '')
            .append('matricula', Reflection.hasValue(filter.matricula) ? filter.matricula : '')
            .append('numDenuncia', Reflection.hasValue(filter.numeroDenuncia) ? filter.numeroDenuncia : '')
            .append('fechaInfraccionInicio', Reflection.empty(filter.fechaInfraccionInicio) ? null : this.dateUtils.stringToDate(filter.fechaInfraccionInicio).toJSON())
            .append('fechaInfraccionFin', Reflection.empty(filter.fechaInfraccionFin) ? null : this.dateUtils.stringToDate(filter.fechaInfraccionFin).toJSON())
            .append('carrer', Reflection.hasValue(filter.carrer) ? filter.carrer : '')
            .append('dni', Reflection.hasValue(filter.dni) ? filter.dni : '')
            .append('infraccion', Reflection.hasValue(filter.infraccion) ? filter.infraccion : '')
            .append('agent', Reflection.hasValue(filter.agente) ? filter.agente : '')
            .append('aparell', Reflection.hasValue(filter.aparell) ? filter.aparell : '')
            .append('expedient', Reflection.hasValue(filter.expediente) ? filter.expediente : '')
            .append('nombreConductor', Reflection.hasValue(filter.nombreConductor) ? filter.nombreConductor : '')
            .append('Pais', Reflection.hasValue(filter.pais) ? filter.pais : '')
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return PropostesFilterService;
}(CustomFilterService));
export { PropostesFilterService };
