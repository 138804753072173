import { OnInit, EventEmitter, ElementRef } from '@angular/core';
import { MultipleDatePickerComponent } from 'multiple-date-picker-angular/dist';
import { MatDialog } from '@angular/material';
import { CalendarMultiselectInputModalComponent } from './calendar-multiselect-input-modal/calendar-multiselect-input-modal.component';
var CalendarMultiselectInputComponent = /** @class */ (function () {
    function CalendarMultiselectInputComponent(dialog) {
        this.dialog = dialog;
        this.valueChange = new EventEmitter();
        this.val = new Array();
    }
    Object.defineProperty(CalendarMultiselectInputComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            var _this = this;
            if (val !== null && val !== undefined) {
                // this.val = val;
                this.val = this.stringArrayToDate(val);
                if (this.multipleDatePickerComponent !== undefined) {
                    this.multipleDatePickerComponent.writeValue(this.val);
                }
                setTimeout(function () {
                    _this.valueChange.emit(_this.val);
                }, 10);
            }
        },
        enumerable: true,
        configurable: true
    });
    CalendarMultiselectInputComponent.prototype.ngOnInit = function () { };
    CalendarMultiselectInputComponent.prototype.isDate = function (value) {
        return value instanceof Date;
    };
    CalendarMultiselectInputComponent.prototype.stringToDate = function (value) {
        if (value !== null && !this.isDate(value)) {
            value = new Date(value);
        }
        return value;
    };
    CalendarMultiselectInputComponent.prototype.stringArrayToDate = function (value) {
        var _this = this;
        value.forEach(function (date, index) {
            value[index] = _this.stringToDate(date);
        });
        return value;
    };
    CalendarMultiselectInputComponent.prototype.getInputValue = function () {
        var ret = '';
        var arr = this.val.sort(function (a, b) { return a > b ? 1 : -1; });
        for (var i = 0; i < arr.length; i++) {
            var d = new Date(arr[i]);
            var str = d.getDate().toString().padStart(2, '0') + '/' + (d.getMonth() + 1).toString().padStart(2, '0') + '/' + d.getFullYear();
            if (i < 2) {
                ret += ret.length > 0 ? ', ' + str : str;
            }
            else {
                ret += '...';
                break;
            }
        }
        return ret;
    };
    CalendarMultiselectInputComponent.prototype.openModal = function () {
        var _this = this;
        var dialogData = {
            top: this.input.nativeElement.getBoundingClientRect().top,
            left: this.input.nativeElement.getBoundingClientRect().left,
            initData: this.value
        };
        var dialogRef = this.dialog.open(CalendarMultiselectInputModalComponent, {
            width: 'auto',
            height: '280px',
            data: dialogData,
            hasBackdrop: true,
            panelClass: 'calendar-multiselect-dialog'
        });
        dialogRef.afterClosed().subscribe(function (res) {
            _this.value = res;
        });
    };
    return CalendarMultiselectInputComponent;
}());
export { CalendarMultiselectInputComponent };
