<app-filter-header title="Filtrar recaptacions" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-filter-text-edit id="recaudacio-filter-numColecta" class="col-sm-4" i18n-label label="Núm.Colecta" [(value)]="filter.numColecta" [number]="true"></app-filter-text-edit>
    <div class="col-md-4">
      <app-form-date-edit id="recaudacio-filter-dataIni" i18n-label label="Data inici" [(value)]="filter.fechaInicio" type="calendar"></app-form-date-edit>
    </div>
    <div class="col-md-4">
      <app-form-date-edit id="recaudacio-filter-dataFi" i18n-label label="Data fi" [(value)]="filter.fechaFin" type="calendar"></app-form-date-edit>
    </div>
  </div>
  <div class="row">
    <!-- combo zona -->
    <app-combo-box-input #comboZones class="col-sm-4" [label]="'Zona'" [showingOptions]="this.zonesComboDictionary.value" 
      (optionChanged)="this.zonaSeleccionado($event)" [(value)]="this.filter.zonaFk"></app-combo-box-input>
    <!-- combo maquina (dependent de zona) -->
    <app-combo-box-input #comboMaquinesParkeon class="col-sm-4" [label]="'Nº Expenedor'" [showingOptions]="this.maquinesParkeonComboDictionary.value" 
      (optionChanged)="this.maquinaParkeonSeleccionado($event)" [(value)]="this.filter.maquinaParkeonFk" [disabled]="this.maquinesParkeonComboDictionary.value.length === 0"></app-combo-box-input>
  </div>
  <div class="row">
    <app-filter-text-edit id="recaudacio-filter-importMonedaD" class="col-sm-3" i18n-label label="Import moneda D" [(value)]="filter.importeMonedaD" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-numTicketsMoneda" class="col-sm-3" i18n-label label="Nº Tiquets moneda" [(value)]="filter.numeroTicketsMoneda" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-anulacionMoneda" class="col-sm-3" i18n-label label="Anul·lació moneda" [(value)]="filter.anulacionMoneda" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-numTicketsMonedaColecta" class="col-sm-3" i18n-label label="Nº Moneda comercial" [(value)]="filter.numeroTicketsMonedaComercial" [number]="true"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="recaudacio-filter-impTarjPayone" class="col-sm-3" i18n-label label="Import targeta PayOne" [(value)]="filter.importeTarjetaPayone" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-numTicketsTarjPayone" class="col-sm-3" i18n-label label="Nº Tiquets targeta PayOne" [(value)]="filter.numeroTicketsTarjetaPayone" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-impAnulTarjPayone" class="col-sm-3"  i18n-label label="Import anul·lació targeta PayOne" [(value)]="filter.importeAnulacionTarjetaPayOne" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-impRecTarjPayone" class="col-sm-3" i18n-label label="Import recàrrega Payone" [(value)]="filter.importeRecargaTarjetaPayone" [number]="true"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="recaudacio-filter-impTarjPayonePlus" class="col-sm-3" i18n-label label="Import targeta PayOnePlus" [(value)]="filter.importeTarjetaPayonePlus" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-numTicketsTarjPayonePlus" class="col-sm-3" i18n-label label="Nº Tiquets targeta PayOne Plus" [(value)]="filter.numeroTicketsTarjetaPayonePlus" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-impAnulTarjPayonePlus" class="col-sm-3" i18n-label label="Import anul·lació targeta PayOne Plus" [(value)]="filter.importeAnulacionTarjetaPayOnePlus" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-impRecTarjPayonePlus" class="col-sm-3" i18n-label label="Import recàrrega Payone Plus" [(value)]="filter.importeRecargaTarjetaPayonePlus" [number]="true"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="recaudacio-filter-impRecuento" class="col-sm-4" i18n-label label="Import recompte" [(value)]="filter.importeRecuento" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-impMonedaLegal" class="col-sm-4" i18n-label label="Import moneda legal" [(value)]="filter.importeMonedaLegal" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-impMonedaComercial" class="col-sm-4" i18n-label label="Import moneda comercial" [(value)]="filter.importeMonedaComercial" [number]="true"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="recaudacio-filter-impTarjTotal" class="col-sm-4" i18n-label label="Import total targeta" [(value)]="filter.importeTarjetaTotal" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-impRecTarjTotal" class="col-sm-4" i18n-label label="Import total recàrrega targeta" [(value)]="filter.importeRecargaTarjetaTotal" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-anulImpTotal" class="col-sm-4" i18n-label label="Import total anul·lacions" [(value)]="filter.anulacionImporteTotal" [number]="true"></app-filter-text-edit>
  </div>
  <div class="row">  
    <app-filter-text-edit id="recaudacio-filter-impRealAparcamiento" class="col-sm-4" i18n-label label="Import real aparcament" [(value)]="filter.importeRealAparcamiento" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-numTicketsTotal" class="col-sm-4" i18n-label label="Nº Total tiquets" [(value)]="filter.numeroTicketsTotal" [number]="true"></app-filter-text-edit>
    <app-filter-text-edit id="recaudacio-filter-diferenciaRecuentoTicket" class="col-sm-4" i18n-label label="Diferència recompte/tiquet" [(value)]="filter.diferenciaRecuentoTicket" [number]="true"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="recaudacio-filter-acumuladoMoneda" class="col-sm-3" i18n-label label="Acumulat moneda" [(value)]="filter.acumuladoMoneda" [number]="true"></app-filter-text-edit>
  </div>
</div>
