import * as i0 from "@angular/core";
var FilterService = /** @class */ (function () {
    function FilterService(
    // private localStorage: LocalSt
    ) {
    }
    FilterService.prototype.saveFilter = function (name, data) {
        localStorage.setItem(name, data.toJSON());
    };
    FilterService.prototype.loadFilter = function (name, data) {
        var json = localStorage.getItem(name);
        data.fromJSON(json);
    };
    FilterService.ngInjectableDef = i0.defineInjectable({ factory: function FilterService_Factory() { return new FilterService(); }, token: FilterService, providedIn: "root" });
    return FilterService;
}());
export { FilterService };
