/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auto-login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./auto-login.component";
import * as i3 from "../../services/login.service";
import * as i4 from "@angular/router";
import * as i5 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i6 from "../../../usuaris/services/usuaris.service";
import * as i7 from "../../../../app.component";
import * as i8 from "../../../../shared/services/authentication/auth.service";
import * as i9 from "../../../../shared/services/http/httpSecureClient";
import * as i10 from "../../../../shared/services/authentication/auth-guard.service";
import * as i11 from "../../../../shared/services/startup/google-maps.service";
var styles_AutoLoginComponent = [i0.styles];
var RenderType_AutoLoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AutoLoginComponent, data: {} });
export { RenderType_AutoLoginComponent as RenderType_AutoLoginComponent };
export function View_AutoLoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [], null, null, null, null, null))], null, null); }
export function View_AutoLoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auto-login", [], null, null, null, View_AutoLoginComponent_0, RenderType_AutoLoginComponent)), i1.ɵdid(1, 114688, null, 0, i2.AutoLoginComponent, [i3.LoginService, i4.Router, i4.ActivatedRoute, i5.CookieService, i6.UsuarisService, i7.AppComponent, i8.AuthService, i9.HttpSecureClient, i10.AuthGuardService, i11.GoogleMapsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AutoLoginComponentNgFactory = i1.ɵccf("app-auto-login", i2.AutoLoginComponent, View_AutoLoginComponent_Host_0, {}, {}, []);
export { AutoLoginComponentNgFactory as AutoLoginComponentNgFactory };
