var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { AutocompleteComboComponent } from 'path-shared/components/form/autocomplete-combo/autocomplete-combo.component';
import { LoaderService } from 'path-shared/components/loader/loader.service';
import { OnInit } from '@angular/core';
import { Domicili } from '../../models/domiciliDto';
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { Dictionary } from 'path-shared/models/dictionary';
import { PaisosService } from 'app/sections/configuracio/paisos/services/paisos.service.ts.service';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ElementRef } from '@angular/core';
import { FormService } from 'path-shared/services/form/form.service';
var DomicilisEditorComponent = /** @class */ (function () {
    function DomicilisEditorComponent(dialogRef, paisosService, toastr, i18n, formService, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.paisosService = paisosService;
        this.toastr = toastr;
        this.i18n = i18n;
        this.formService = formService;
        this.data = data;
        this.local_data = new Domicili();
        this.codigosPostales = [];
        this.valorAutoCompletePais = '';
        this.valorAutoCompleteProvincia = '';
        this.valorAutoCompleteMunicipio = '';
        this.loaderService = LoaderService.getInstance();
        this.action = this.data.action;
        this.loaderService.show();
        this.direccioCompleta = new BehaviorSubject('');
        this.getPaisos();
        this.getPronvicias();
        this.getMunicipios();
        this.getCodigosPostales();
        setTimeout(function () {
            _this.local_data = __assign({}, data.domicili);
            if (_this.action === 'Add') {
                _this.local_data.actual = true;
            }
            // this.valorAutoCompletePais = '';
            // this.valorAutoCompleteProvincia = '';
            // this.valorAutoCompleteMunicipio = '';
            _this.setDireccioCompleta();
            _this.setCombosWithInfo();
        }, 1500);
    }
    DomicilisEditorComponent.prototype.ngOnInit = function () {
        this.setDireccioCompleta();
    };
    DomicilisEditorComponent.prototype.getPaisos = function () {
        var _this = this;
        this.paisosDictionary = new Array();
        this.paisosService.getPaisosCombo().subscribe(function (newValue) {
            _this.loaderService.show();
            newValue.forEach(function (element) {
                _this.paisosDictionary.push(new Dictionary(element.secureId.toString(), element.descripcio));
            });
            // this.valorAutoCompletePais = this.local_data.pais;
            _this.loaderService.hide();
        });
    };
    DomicilisEditorComponent.prototype.getPronvicias = function () {
        var _this = this;
        this.provinciasDictionary = new Array();
        this.paisosService.getProvinciasCombo().subscribe(function (newValue) {
            _this.loaderService.show();
            newValue.forEach(function (element) {
                _this.provinciasDictionary.push(new Dictionary(element.secureId.toString(), element.nom));
            });
            //  this.valorAutoCompleteProvincia = this.local_data.provincia;
            _this.loaderService.hide();
        });
    };
    DomicilisEditorComponent.prototype.getCodigosPostales = function () {
        var _this = this;
        this.paisosService.getCodigosPostales().subscribe(function (res) {
            _this.codigosPostales = res;
        });
    };
    DomicilisEditorComponent.prototype.getPronviciasByPais = function (secureIdPais, reloadProv) {
        var _this = this;
        if (reloadProv === void 0) { reloadProv = false; }
        this.provinciasDictionary = new Array();
        this.paisosService.getProvinciasComboByPais(secureIdPais).subscribe(function (newValue) {
            _this.loaderService.show();
            newValue.forEach(function (element) {
                _this.provinciasDictionary.push(new Dictionary(element.secureId.toString(), element.nom));
            });
            if (reloadProv) {
                _this.resetComboProvinicia();
                _this.resetComboMunicipio();
            }
            _this.loaderService.hide();
        });
    };
    DomicilisEditorComponent.prototype.getMunicipios = function () {
        var _this = this;
        this.municipiosDictionary = new Array();
        this.paisosService.getMunicipiosCombo().subscribe(function (newValue) {
            _this.loaderService.show();
            newValue.forEach(function (element) {
                _this.municipiosDictionary.push(new Dictionary(element.secureId.toString(), element.nom));
            });
            //  this.valorAutoCompleteMunicipio = this.local_data.localitat;
            _this.loaderService.hide();
        });
    };
    DomicilisEditorComponent.prototype.getMunicipiosByProv = function (secureIdProv, reloadMunicipio) {
        var _this = this;
        if (reloadMunicipio === void 0) { reloadMunicipio = false; }
        this.municipiosDictionary = new Array();
        this.paisosService.GetMunicipiosComboByProv(secureIdProv).subscribe(function (newValue) {
            _this.loaderService.show();
            newValue.forEach(function (element) {
                _this.municipiosDictionary.push(new Dictionary(element.secureId.toString(), element.nom));
            });
            if (reloadMunicipio) {
                _this.resetComboMunicipio();
            }
            _this.loaderService.hide();
        });
    };
    DomicilisEditorComponent.prototype.doAction = function () {
        if (this.formService.allFieldsValid(this.mainForm)) {
            if (this.AreValidValuesCombos()) {
                this.setDireccioCompleta();
                this.local_data.domicili = this.direccioCompleta.value;
                this.dialogRef.close({ event: this.action, data: this.local_data });
            }
            else {
                this.toastr.warning(this.i18n('Error en la selecció.'));
            }
        }
        else {
            this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
            if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
                this.mainForm.nativeElement.classList.add('displayErrors');
            }
        }
    };
    DomicilisEditorComponent.prototype.AreValidValuesCombos = function () {
        var _this = this;
        if ((this.paisosDictionary !== undefined && this.paisosDictionary === null && this.local_data.pais !== undefined && this.local_data.pais !== null &&
            this.local_data.pais !== '' && this.paisosDictionary.filter(function (x) { return x.value.toLowerCase().includes(_this.local_data.pais); }).length !== 1)) {
            return false;
        }
        else if (this.provinciasDictionary !== undefined && this.provinciasDictionary === null && this.local_data.provincia !== undefined
            && this.local_data.provincia !== null && this.local_data.provincia !== '' &&
            this.provinciasDictionary.filter(function (x) { return x.value.toLowerCase().includes(_this.local_data.provincia); }).length !== 1) {
            return false;
        }
        else if (this.municipiosDictionary !== undefined && this.municipiosDictionary === null && this.local_data.localitat !== undefined
            && this.local_data.localitat !== null && this.local_data.localitat !== '' &&
            this.municipiosDictionary.filter(function (x) { return x.value.toLowerCase().includes(_this.local_data.localitat); }).length !== 1) {
            return false;
        }
        return true;
    };
    DomicilisEditorComponent.prototype.closeDialog = function () {
        this.dialogRef.close({ event: 'Cancel' });
    };
    DomicilisEditorComponent.prototype.setDireccioCompleta = function () {
        if (this.direccioCompleta === undefined || this.direccioCompleta === null) {
            this.direccioCompleta = new BehaviorSubject('');
        }
        var dir = '';
        if (this.local_data !== undefined && this.local_data !== null) {
            if (this.local_data !== undefined && this.local_data !== null && this.local_data.dirSimple !== undefined && this.local_data.dirSimple !== null && this.local_data.dirSimple !== '') {
                dir = this.local_data.dirSimple;
            }
            if (this.local_data !== undefined && this.local_data.numero !== undefined && this.local_data.numero !== null && this.local_data.numero !== '') {
                dir = dir + ' Nº ' + this.local_data.numero;
            }
            if (this.local_data !== undefined && this.local_data.escala !== undefined && this.local_data.escala !== null && this.local_data.escala !== '') {
                dir = dir + ' ESC ' + this.local_data.escala;
            }
            if (this.local_data !== undefined && this.local_data.pis !== undefined && this.local_data.pis !== null && this.local_data.pis !== '') {
                dir = dir + ' ' + this.local_data.pis;
            }
            if (this.local_data !== undefined && this.local_data.porta !== undefined && this.local_data.porta !== null && this.local_data.porta !== '') {
                dir = dir + ' ' + this.local_data.porta;
            }
            if (dir === '' && this.local_data !== undefined && this.local_data.domicili !== '' && this.local_data.domicili !== undefined) {
                dir = this.local_data.domicili;
            }
        }
        this.direccioCompleta.next(dir);
    };
    DomicilisEditorComponent.prototype.paisSeleccionado = function (idPais, reloadProv) {
        if (reloadProv === void 0) { reloadProv = true; }
        this.local_data.pais = this.paisosDictionary.find(function (x) { return x.key === idPais; }).value;
        this.getPronviciasByPais(idPais, reloadProv);
    };
    DomicilisEditorComponent.prototype.provinciaSeleccionado = function (idProvincia, reloadProv) {
        if (reloadProv === void 0) { reloadProv = true; }
        this.local_data.provincia = this.provinciasDictionary.find(function (x) { return x.key === idProvincia; }).value;
        this.getMunicipiosByProv(idProvincia, reloadProv);
    };
    DomicilisEditorComponent.prototype.municipioSeleccionado = function (idMunicipio) {
        this.local_data.localitat = this.municipiosDictionary.find(function (x) { return x.key === idMunicipio; }).value;
    };
    DomicilisEditorComponent.prototype.setCombosWithInfo = function () {
        this.loaderService.show();
        if (this.local_data.pais !== undefined && this.local_data.pais !== null && this.local_data.pais !== '') {
            this.comboPaisos.setValueComb(this.local_data.pais);
        }
        if (this.local_data.provincia !== undefined && this.local_data.provincia !== null && this.local_data.provincia !== '') {
            this.comboProvincia.setValueComb(this.local_data.provincia);
        }
        if (this.local_data.localitat !== undefined && this.local_data.localitat !== null && this.local_data.localitat !== '') {
            this.comboMunicipio.setValueComb(this.local_data.localitat);
        }
        this.loaderService.hide();
    };
    DomicilisEditorComponent.prototype.resetComboProvinicia = function () {
        var _this = this;
        if ((this.provinciasDictionary === undefined || this.provinciasDictionary === null) || (this.local_data.provincia !== undefined && this.local_data.provincia !== null &&
            this.local_data.provincia !== '' && this.provinciasDictionary.filter(function (x) { return x.value.toLowerCase().includes(_this.local_data.provincia); }).length !== 1)) {
            this.local_data.provincia = '';
            this.comboProvincia.setForcedValueComb(this.local_data.provincia);
        }
    };
    DomicilisEditorComponent.prototype.resetComboMunicipio = function () {
        var _this = this;
        if ((this.municipiosDictionary === undefined || this.municipiosDictionary === null) || (this.local_data.localitat !== undefined && this.local_data.localitat !== null &&
            this.local_data.localitat !== '' && this.municipiosDictionary.filter(function (x) { return x.value.toLowerCase().includes(_this.local_data.localitat); }).length !== 1)) {
            this.local_data.localitat = '';
            this.comboMunicipio.setForcedValueComb(this.local_data.localitat);
        }
    };
    DomicilisEditorComponent.prototype.resetComboPais = function () {
        var _this = this;
        if ((this.paisosDictionary === undefined || this.paisosDictionary === null) || (this.local_data.pais !== undefined && this.local_data.pais !== null &&
            this.local_data.pais !== '' && this.paisosDictionary.filter(function (x) { return x.value.toLowerCase().includes(_this.local_data.pais); }).length !== 1)) {
            this.local_data.pais = '';
            this.comboPaisos.setForcedValueComb(this.local_data.pais);
        }
    };
    DomicilisEditorComponent.prototype.autofillByCP = function () {
        var _this = this;
        if (this.local_data.codiPostal !== undefined && this.local_data.codiPostal.length === 5) {
            var cp = this.codigosPostales.find(function (x) { return x.codiPostal === _this.local_data.codiPostal; });
            if (cp !== undefined) {
                this.municipioSeleccionado(cp.secureId);
                this.provinciaSeleccionado(cp.SECURE_ID_Provincia, false);
                this.paisSeleccionado('102000000000002600001|138B8D0D213530F7298780F6CA89ABA8', false);
                this.setCombosWithInfo();
            }
        }
        else {
            this.resetComboProvinicia();
            this.resetComboMunicipio();
            this.resetComboPais();
            // this.setCombosWithInfo();
        }
    };
    return DomicilisEditorComponent;
}());
export { DomicilisEditorComponent };
