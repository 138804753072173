import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AgentFilter } from '../models/agent-filter';
import { Agent } from '../models/agent.model';
import { AgentsFilterService } from './agents-filter-service';
import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse  } from '@angular/common/http';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class AgentsService implements ExcelGenerator {
  displayedItems = [];

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  getAll(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new AgentsFilterService().createFilterParams(new AgentFilter('codi', 'asc', new Date()), 0, 99999, 'codi', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAgents'],  params);
    }
  }

  getAllCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllAgents'],  null);
    }
  }

  getAgent(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAgent'].replace('{secure_id}', secure_id), null);
    }
  }

  setAgent(agent: Agent): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetAgent'], agent);
    }
  }

  deleteAgent(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteAgent'].replace('{secure_id}', secure_id), null);
    }
  }

  generateExcel(filter: AgentFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new AgentsFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlAgentsExcel'],  params );
    }
  }

  uploadFileAgent(fitxer: FormData) {

    if (this.authGuard.canActivate()) {
      this.http.post(config.urls['apiUrl'] + api.endpoints['urlUpladFileAgent'], fitxer)
      // .map(res => res.json())
      .catch(error => Observable.throw(error))
      .subscribe(
      data => console.log('success'),
      error => console.log(error)
      );
    }
  }
}
