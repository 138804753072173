<app-filter-header title="Filtrar festius" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-form-date-edit id="festius-filter-dia" class="col-sm-2" i18n-label label="Dia festiu" [(value)]="filter.dia"></app-form-date-edit>
    <app-filter-text-edit id="festius-filter-descripcio" class="col-sm-7" i18n-label label="Descripció" [(value)]="filter.descripcio"></app-filter-text-edit>
    <app-form-text-edit id="festius-filter-tipus" class="col-sm-2" i18n-label label="Tipus festiu" [(value)]="filter.tipus"></app-form-text-edit> 

    
  </div>
</div>
