import { BoeService } from './../../../services/boe.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfiguracionBOEDto } from '../../../models/config-boe.model';

@Component({
  selector: 'app-boe-general',
  templateUrl: './boe-general.component.html',
  styleUrls: ['./boe-general.component.scss']
})
export class BOEGeneralComponent implements OnInit {
  val: ConfiguracionBOEDto;
  @Input() get value() {
    return this.val;
  } set value(val) {
    this.val = val;
    setTimeout(() => {
      this.valueChange.emit(this.val);
    }, 10);
  }
  @Output() valueChange = new EventEmitter();
  constructor() { 
    
  }

  ngOnInit() {
  }
  changeTipoExportacion(tipo: number){
    this.value.tipoExportacion = tipo;
  }
}
