import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { PropostesEstatCarregatsFilter } from '../models/propostes-estat-carregats.filter';

const defaultSortParam = '';
const defaultSortDirection = '';

@Injectable()
export class PropostesEstatCarregatsFilterService extends CustomFilterService {

  public createFilterParams(filter: PropostesEstatCarregatsFilter, start: number, pageSize: number, sortField: string, sortDir: string): HttpParams {
    return new HttpParams()
    .append('estat', filter.estat ? filter.estat : '')
    .append('fechaInicio', filter.fechaInicio ? filter.fechaInicio : '')
    .append('fechaFin', filter.fechaFin ? filter.fechaFin : '')
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir)
  }
}
