import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class LocalizacionFilter extends FilterData {
  
    vel_via:number;
    infraccion:number;
    propuesta: string;
    cod_aparat:string;
  

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
   
 
    this.vel_via = null;
    this.infraccion = null;
    this.propuesta = '';
    this.cod_aparat = '';
  

  }
}
