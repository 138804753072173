import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { ImportacioDenunciaFilter } from '../models/importacio-denuncia-filter';
import { ImportacioDenunciaFilterService } from './importacioDenuncia-filter.service';

@Injectable()
export class ImportacioDenunciaService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  getAll(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new ImportacioDenunciaFilterService().createFilterParams(new ImportacioDenunciaFilter('idImportacio', 'asc'), 0, 99999, 'idImportacio', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlImportacioDenuncies'],  params);
    }
  }

  loadImportacioDenuncia(formData: FormData, formatImportacio: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlImportacioDenunciesUploadFile'].replace('{formatImportacio}', formatImportacio), formData );
    }
  }

  processarLlista(formatImportacio: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlProcessarFitxers'].replace('{formatImportacio}', formatImportacio), {});
    }
  }

  forzarCarregaFitxers() {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlForzarCarregaFitxers'], {});
    }
  }
  

}
