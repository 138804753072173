import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatSort, MatTableDataSource } from '@angular/material';
import { CobrosService } from './services/cobros.service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { CobrosFilter } from './models/cobros-filter';
import { CobrosFiltreComponent } from './components/cobros-filtre/cobros-filtre.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { TipoCobroEnum } from './models/tipoCobroEnum.model';

@Component({
  selector: 'app-cobros',
  templateUrl: './cobros.component.html',
  styleUrls: ['./cobros.component.scss']
})
export class CobrosComponent implements OnInit, AfterViewInit {

  dataSource = null;
  init = true;
  filter = new CobrosFilter('fechas', 'asc');
  displayedColumns = ['FechaCobro', 'ImporteCobro', 'Origen', 'EXP', 'Aparato', 'Màquina', 'TipoCobro'];
  keyCounts = [];
  cobrosFilterDialogRef : MatDialogRef<CobrosFiltreComponent>;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild('sort') sort: MatSort;


  constructor(
    private cobrosService: CobrosService,
    private i18n: I18n,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private not: NotificationService,
  ) {
    let date = new Date();
    date.setDate(date.getDate() - 14)
    this.filter.fechas = [date, new Date()];
   }

  ngOnInit() {
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        if (!this.dataDialog.isDialog) { 
          this.buscar();
        }
      }
    });
  }

  ngAfterViewInit() {
    this.not.titleChange(this.i18n('Cobros'));
  }

  buscar() {
    this.cobrosService.getCobros(this.filter).subscribe(res => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.sort = this.sort;
      this.init = false;
      this.keyCountOrigen(res);
    });
  }

  onFilterClicked() {
    this.cobrosFilterDialogRef = this.dialog.open(CobrosFiltreComponent, {
      width: '90%',
      height: 'auto',
      closeOnNavigation: false,
      autoFocus: true,
      data: this.filter
    });
  }

  keyCountOrigen(res) {
    this.keyCounts = [];
    const map = new Map();
    for (const item of res) {
        if (!map.has(item.Origen)) {
            map.set(item.Origen, true);
            const key = item.Origen;
            const elements = res.filter(x => x.Origen === item.Origen);
            let suma = 0;
            elements.forEach(element => {
              suma += element.ImporteCobro;
            });
            this.keyCounts.push({'key': key, 'suma': suma});
        }
    }
    this.keyCounts.sort(s => s.key);
  }

  generateExcel() {
    this.cobrosService.generateExcel(this.filter).subscribe(result => {
      let fileName = 'Cobros';
      fileName += '.xlsx';
      FileDownloader.downLoadFile([result], 'application/vnd.ms-excel;charset=utf-8', fileName);
    });
  }

  getTipoCobroById(id: number) {
    return Object.keys(TipoCobroEnum)[Object.values(TipoCobroEnum).indexOf(id)]
  }

}
