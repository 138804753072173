var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'descripcio';
var defaultSortDirection = 'asc';
var AgentsFilterService = /** @class */ (function (_super) {
    __extends(AgentsFilterService, _super);
    function AgentsFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AgentsFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('codi', Reflection.hasValue(filter.codi) ? filter.codi.toString() : '')
            .append('categoria', filter.categoria ? filter.categoria : '')
            .append('nomComplert', filter.nomComplert ? filter.nomComplert : '')
            .append('dni', filter.dni ? filter.dni : '')
            .append('letraDni', filter.letraDni ? filter.letraDni : '')
            .append('obsolet', this.getTristateValue(filter.obsolet))
            .append('zonaBlava', this.getTristateValue(filter.zonaBlava))
            .append('agentPDA', this.getTristateValue(filter.agentPDA))
            .append('FechaActualConsulta', this.formatDateToLocal(filter.fechaActualConsulta))
            .append('AgenteDeFiesta', this.getTristateValue(filter.deFiesta))
            .append('AgenteDeAlta', this.getTristateValue(filter.deAlta))
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    AgentsFilterService.prototype.getTristateValue = function (value) {
        if (value === null) {
            return '';
        }
        else {
            return value.toString();
        }
    };
    AgentsFilterService.prototype.formatDateToLocal = function (fecha) {
        fecha = new Date(fecha);
        var dateString = fecha.getFullYear() + '-' +
            ('0' + (fecha.getMonth() + 1)).slice(-2) + '-' +
            ('0' + fecha.getDate()).slice(-2);
        return dateString;
    };
    return AgentsFilterService;
}(CustomFilterService));
export { AgentsFilterService };
