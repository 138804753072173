<app-filter-header title="Filtrar agents" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
     <app-filter-text-edit id="agents-filter-codi" autofocus class="col-sm-6" i18n-label label="Codi" [(value)]="filter.codi"></app-filter-text-edit>
     <app-filter-text-edit id="agents-filter-nom" class="col-sm-6" i18n-label label="Nom" [(value)]="filter.nomComplert"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="agents-filter-categoria" class="col-sm-6" i18n-label label="Categoria" [(value)]="filter.categoria"></app-filter-text-edit>
    <app-filter-text-edit id="agents-filter-dni" class="col-sm-6" i18n-label label="DNI" [(value)]="filter.dni"></app-filter-text-edit>
  </div>

  <div class="row">
    <div class="col-5">
      <app-form-tri-state label="Zona Blava" i18n-label i18n-labelSi labelSi="Si" i18n-labelNo labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.zonaBlava"></app-form-tri-state>
      <app-form-tri-state label="Obsolet" i18n-label i18n-labelSi labelSi="Si" i18n-labelNo labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.obsolet"></app-form-tri-state>
      <app-form-tri-state label="Agent PDA" i18n-label  i18n-labelSi labelSi="Si" i18n-labelNo labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.agentPDA"></app-form-tri-state>
    </div>
    <div class="col-1"></div>
    <div class="col-5">
      <app-form-tri-state label="De festa" i18n-label i18n-labelSi labelSi="Si" i18n-labelNo labelNo="No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.deFiesta"></app-form-tri-state>
      <app-form-tri-state label="D'alta" i18n-label i18n-labelSi labelSi="Si" i18n-labelNo labelNo="No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.deAlta"></app-form-tri-state>
    </div>
  </div>
</div>
