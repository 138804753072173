import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AnulacionZonaServiceManual } from '../service/anulacion-zona.service';
import { parse } from 'url';
import { DenunciaDetailModel } from 'app/sections/denuncies/models/denuncia-detail.model';
import { NgForm } from '@angular/forms';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { BehaviorSubject } from 'rxjs';
import { AnulacionZonaAzulAuto } from 'app/sections/anulacion-zona-azul-auto/models/anulacion-zona-azul-auto.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { MatDialog } from '@angular/material';
import { FileUploaderComponent } from '../components/file-uploader/file-uploader.component';



declare var $;
@Component({
  selector: 'app-anulacion-zona-azul-manual',
  templateUrl: './anulacion-zona-azul-manual.component.html',
  styleUrls: ['./anulacion-zona-azul-manual.component.scss']
})
export class AnulacionZonaAzulManualComponent implements OnInit, AfterViewInit {

  @ViewChild('denuncias') denuncias: ElementRef;
  @ViewChild('inputDenuncia') inputDenunciaField: ElementRef;
  nDenuncia: number;
  // sinDenuncias:boolean=false;
  columnesTaula = ['Eliminar', 'Expedient', 'NUMDENUNCIA', 'MATRICULA', 'FECHA', 'HORA', 'DataAnul', 'HoraAnul', 'CodAnul','ObservacionsAnulacions', 'Import', 'Resultat'];
  denunciasDet: any = [];
  denunciasDatos: Array<DenunciaDetailModel>;
  denunciesDades: BehaviorSubject<AnulacionZonaAzulAuto[]>;
  listadenunciesDades;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  show = true;
  showT = true;
  sinDenuncias = false;

  expArr = [];
  exp: any;
  resultadoSucces = '';
  resultadoError = '';
  bloquear = false;
  pantalla = false;
  constructor(
    private dialog: MatDialog,
    private _service: AnulacionZonaServiceManual,
    private not: NotificationService,
    private i18n: I18n,
    public auth: AuthorizationService) {
    this.denunciesDades = new BehaviorSubject(new Array<AnulacionZonaAzulAuto>());
    this.listadenunciesDades = this.denunciesDades.asObservable();
  }

  ngOnInit() {
    $('#denuncias').focus();
    this.denunciasDatos = new Array<DenunciaDetailModel>();

  }

  ngAfterViewInit() {
    console.log('anulació manual after view init');
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Anul·lació  manual'));
    }
  }


  enviarCodigo(nDenuncia: any) {
    /*for (let i = 0; i < this.denunciesDades.value.length; i++) {
      const element = this.denunciesDades.value[i].NumDenuncia;
      if(element === nDenuncia){
        return  $('#denunciaRepetida').modal({show: true, backdrop: 'static', keyboard: false});
      }
    }*/
    this.nDenuncia = nDenuncia;
    this._service.getDenuncia(this.nDenuncia)
      .subscribe(data => {
        const denunciaRecibida = data;
        if (denunciaRecibida == null) {
          $('#sinDenunciasModel').modal({ show: true, backdrop: 'static', keyboard: false });
        } else {
          /*if (this.denunciesDades === undefined || this.denunciesDades === null) {
            this.denunciesDades =   [];
          }*/
          for (let i = 0; i < this.denunciesDades.value.length; i++) {
            const element = this.denunciesDades.value[i].NumDenuncia;
            if (element === denunciaRecibida.NumDenuncia) {
              return $('#denunciaRepetida').modal({ show: true, backdrop: 'static', keyboard: false });
            }
          }
          const denunciasActuales = Object.assign([], this.denunciesDades.value);
          denunciasActuales.push(denunciaRecibida);
          this.denunciesDades.next(denunciasActuales);
          this.sinDenuncias = true;
          this.cerrarModal();
        }
      });
  }


  cerrarModal() {

    this.nDenuncia = null;
    this.inputDenunciaField.nativeElement.focus();
    /* $( "#denuncias" ).focus();
     setTimeout(()=>{
       this.denuncias.nativeElement.focus();
     },100)*/
  }



  list(item) {

    this.denunciasDatos.splice(item, 1);
    if (this.denunciasDatos.length === 0) {
      this.sinDenuncias = false;
    }

  }


  anularDenuncia(denunciaManual: NgForm) {
    this.exp = denunciaManual.value;
    this.expArr.push(this.exp);
    console.log(this.expArr);
    this.resultadoSucces = ' Expedients anul·lat corrrectamente';
    this.resultadoError = ' S\'ha produït un error en intentar anul·lar l\'expedient';
    if (this.resultadoSucces !== '' || this.resultadoError !== '') {
      this.pantalla = true;
      setTimeout(() => {
        this.bloquear = true;
        this.pantalla = false;
      }, 2000);
    }
  }

  reload() {
    this.bloquear = true;
    this.pantalla = true;
    setTimeout(() => {
      this.bloquear = false;
      this.pantalla = false;
      this.denunciasDatos = [];
      this.cerrarModal();
      this.sinDenuncias = false;
      this.resultadoSucces = '';
      this.resultadoError = '';
    }, 1000);
  }

  SendDenuncies() {

    const denunciasActuales = Object.assign([], []);
    this.denunciesDades.value.forEach(element => {
      this._service.processarAnulacio(element).subscribe(data => {

        const indexReal = this.denunciesDades.value.findIndex(x => x.Expedient === data.Expedient);
        if (indexReal !== -1) {
          const currentValue = Object.assign([], this.denunciesDades.value);
          currentValue.splice(indexReal, 1);
          currentValue.push(data);
          this.denunciesDades.next(currentValue);
        }
      });
    });
    // this.denunciesDades = new BehaviorSubject(new Array <AnulacionZonaAzulAuto>());
    // this.denunciesDades.next(denunciasActuales);
  }

  deleteRow(row: any) {
    const indexReal = this.denunciesDades.value.findIndex(x => x.Expedient === row.Expedient);
    if (indexReal !== -1) {
      const currentValue = Object.assign([], this.denunciesDades.value);
      currentValue.splice(indexReal, 1);
      this.denunciesDades.next(currentValue);
    }
  }

  loadFile() {
    const ref = this.dialog.open(FileUploaderComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      width: '30%',
      height: '10%'
    });

    ref.afterClosed().subscribe(res => {
      if (res) {
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          if (fileReader.result) {
            this.arrayBufferToString(fileReader.result, 'UTF-8', (resultparse) => {
              const stringParsed: string = resultparse;
              const list = stringParsed.split('\n');
              this._service.getDenunciasFromFile(list).subscribe(infoList => {
                // Hemos recibido respuesta del servidor.
                if (infoList === null || infoList === undefined) {
                  $('#sinDenunciasModel').modal({ show: true, backdrop: 'static', keyboard: false });
                } else {
                  // Hay valores (denuncias)
                  infoList.forEach(denunciaRecibida => {
                    for (let i = 0; i < this.denunciesDades.value.length; i++) {
                      const element = this.denunciesDades.value[i].NumDenuncia;
                      if (element === denunciaRecibida.NumDenuncia) {
                        return $('#denunciaRepetida').modal({ show: true, backdrop: 'static', keyboard: false });
                      }
                    }
                    const denunciasActuales = Object.assign([], this.denunciesDades.value);
                    denunciasActuales.push(denunciaRecibida);
                    this.denunciesDades.next(denunciasActuales);
                    this.sinDenuncias = true;
                    this.cerrarModal();
                  });
                }
              });
            });
          }
        }
        fileReader.readAsText(res);
      }
    });
  }

  arrayBufferToString(buffer, encoding, callback) {
    var blob = new Blob([buffer], { type: 'text/plain' });
    var reader = new FileReader();
    reader.onload = function (evt) { callback(evt.target.result); };
    reader.readAsText(blob, encoding);
  }

  cancelationDateTimeCopy: Date;
  recalculateAmount(row: any) {
    let regex = /^(0[1-9]|1\d|2[0-8]|29(?=-\d\d-(?!1[01345789]00|2[1235679]00)\d\d(?:[02468][048]|[13579][26]))|30(?!-02)|31(?=-0[13578]|-1[02]))-(0[1-9]|1[0-2])-([12]\d{3}) ([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    if (regex.test(row.DataAnul.replaceAll('/','-') + ' ' + row.HoraAnul)) {
      let date = +row.DataAnul.substring(0, 2);
      let month = +row.DataAnul.substring(3, 5) - 1;
      let year = +row.DataAnul.substring(6, 10);
      let hours = +row.HoraAnul.substring(0, 2);
      let minutes = +row.HoraAnul.substring(3, 5);
      let seconds = +row.HoraAnul.substring(6, 8);
      let cancelationDateTime: Date = new Date(year, month, date, hours, minutes, seconds);
      if (this.cancelationDateTimeCopy === undefined) this.cancelationDateTimeCopy = cancelationDateTime;
      let dateChanged = this.cancelationDateTimeCopy.toLocaleString() != cancelationDateTime.toLocaleString();

      if (dateChanged) {
        this._service.recalculateAmount(row.NumDenuncia, cancelationDateTime).subscribe(res => {
          row.Import = res;
        });
        this.cancelationDateTimeCopy = cancelationDateTime;
        (document.activeElement as HTMLElement).blur();
      }
    }
  }

}
