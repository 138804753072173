import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { MessagingService } from 'path-shared/services/messaging/messaging.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { WaitDialogComponent } from 'path-shared/components/dialogs/wait-dialog/wait-dialog.component';
import { WaitDialogData } from 'path-shared/components/dialogs/wait-dialog/wait-dialog-data.model';
import { Time } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ExcelDownloadService {

  private windowTimeoutMSeconds = 2000;

  private openTime: number;
  constructor(
    private msg: MessagingService,
    private dialog: MatDialog,
  ) { }

  downloadExcel(data: Observable<any>) {
    this.openTime = new Date().getTime();
    let dlg: MatDialogRef<WaitDialogComponent> = null;
    dlg = this.dialog.open(WaitDialogComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      width: '60%',
      data: new WaitDialogData(this.msg.GeneratingExcel, this.msg.generatingExcelMessage, this.msg.CancelGeneration, 'fa fa-file-excel has-color-green')
    });
    dlg.afterClosed().subscribe(result => {
      if (result !== 'autoClose') {
        generated.unsubscribe();
      }
    });

    const generated = data.subscribe(result => {
      FileDownloader.downLoadFile([result], 'application/vnd.ms-excel;charset=utf-8', 'MatriculesEspecials.xlsx');
      if (dlg !== null) {
        const mseconds = (new Date().getTime() - this.openTime);
        if (mseconds < this.windowTimeoutMSeconds) {
          setTimeout(() => {
            dlg.close('autoClose');
          }, this.windowTimeoutMSeconds - mseconds);
        } else {
          dlg.close('autoClose');
        }
      }
    });
  }
}
