import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges, Inject } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ExcelDownloadService } from 'path-shared/services/excel/excel-download.service';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { Persona } from '../../models/persona.model';
import { PersonaFilter } from '../../models/persona-filter';
import { PersonesService } from '../../services/persones.service';
import { PersonesFilterService } from '../../services/persones-filter-service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';

@Component({
  selector: 'app-persones-table',
  templateUrl: './persones-table.component.html',
  styleUrls: ['./persones-table.component.scss']
})
export class PersonesTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: Persona[];
  dataSource: CustomDataSource<Persona, PersonaFilter>;
  highlightedRows = [];
  filter: PersonaFilter = new PersonaFilter('id', 'asc');
  filterPage: PersonaFilter;
  displayedItems = [];
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @Input() updatedFilterData: PersonaFilter;
  @Input() selectButton: boolean;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'nomComplet', 'dni_pasp', 'nom_pare', 'nom_mare', 'data_naixament', 'lloc_naixament', 'provincia', 'buscat', 'antecedents'];

  constructor(
    private personesService: PersonesService,
    private filterService: FilterService,
    private excel: ExcelDownloadService,
    private dialog: MatDialog,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public dataComp: ComponentDialog
    ) {
      if (dataComp !== undefined) {
        this.dataDialog = dataComp;
      }
      this.selectButton = false;
  }

  ngOnInit() {

    if (this.dataDialog.filter !== null && this.dataDialog.filter !== undefined) {
      // this.propostesTable.userReincidentsDataSource = false;
      this.filter.set(this.dataDialog.filter);
    }

    if (this.selectButton) {
      this.displayedColumns = ['incorpora'].concat(this.displayedColumns);
    }
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Persona, PersonaFilter>(this.paginator, new PersonesFilterService(), 'urlGetPersones', 'urlPersonesPaginationCount');
    this.dataSource.subject.subscribe((data) => {
        data.forEach(item => {
          this.personesService.displayedItems[item.secure_id] = {
            id: item.secure_id,
            checked: false
          };
        });

      this.displayedItems = this.personesService.displayedItems;
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('persona-filter', this.filter); }
      }
    });
    if (!this.selectButton) { this.filterService.loadFilter('persona-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    } );


    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
    this.loadPersonesPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  updatePersonesPage() {
    this.dataSource.load(
      this.updatedFilterData,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction
    );
  }

  load() {

    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  loadPersonesPage() {
    if (this.dataSource !== undefined) {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }
  }

  onRowClicked(row) {
    this.itemClicked.next(row.secure_id);
  }

  selectCurrent(codi, secure_id, nomComplet, dni_pasp, secureIdDom, juridica) {
       this.itemSelected.next({codi: codi, secure_id: secure_id, nomComplet: nomComplet, dni_pasp: dni_pasp, secureIdDom: secureIdDom, juridica: juridica});
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.personesService, filter, this.i18n('Persones'), this.sort.direction, this.sort.active)
    });
  }

}
