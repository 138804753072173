import { OnInit, AfterViewInit, EventEmitter } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { TiquetsFilter } from '../../models/tiquets-filter';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { TiquetsAnulacionesService } from '../../services/tiquets-anulaciones.service';
import { TiquetsFilterService } from '../../services/tiquets-filter.service';
var TiquetsAnulacionesTableComponent = /** @class */ (function () {
    function TiquetsAnulacionesTableComponent(tiquetsAnulacionesService, dialog, i18n) {
        this.tiquetsAnulacionesService = tiquetsAnulacionesService;
        this.dialog = dialog;
        this.i18n = i18n;
        this.currentFilter = new TiquetsFilter('id', 'desc'); // Filtro concreto para esta tabla.
        this.highlightedRows = [];
        this.displayedColumns = ['tiquet', 'data', 'client', 'email', 'matricula', 'zona', 'importTiquet'];
        this.currentCount = new EventEmitter();
    }
    Object.defineProperty(TiquetsAnulacionesTableComponent.prototype, "sharedFilter", {
        get: function () {
            return this._sharedFilter;
        },
        set: function (val) {
            if (val !== undefined && val !== null) {
                this._sharedFilter = val;
                this.currentFilter.id = val.id;
                this.currentFilter.matricula = val.matricula;
                this.currentFilter.email = val.email;
                this.currentFilter.fechaTicketInicio = val.fechaTicketInicio;
                this.currentFilter.fechaTicketFin = val.fechaTicketFin;
                this.currentFilter.fechaBancoInicio = val.fechaBancoInicio;
                this.currentFilter.fechaBancoFin = val.fechaBancoFin;
                this.currentFilter.zonaSecureId = val.zonaSecureId;
            }
        },
        enumerable: true,
        configurable: true
    });
    //#region CICLO DE VIDA
    TiquetsAnulacionesTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sort.disableClear = true;
        this.dataSource = CustomDataSource.create(this.paginator, new TiquetsFilterService(), 'urlGetAnulacions', 'urlGetAnulacionsCount');
        this.dataSource.countSubject.subscribe(function (newCount) {
            _this.currentCount.emit(newCount);
        });
    };
    TiquetsAnulacionesTableComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // reset the paginator after sorting
        this.sort.sortChange.subscribe(function () {
            _this.paginator.pageIndex = 0;
            _this.load();
        });
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(tap(function () { return _this.currentFilter.updated(); }))
            .subscribe();
        this.load();
    };
    //#endregion
    //#region EVENTOS
    TiquetsAnulacionesTableComponent.prototype.paginatorChanged = function (value) {
        // Actualizamos la informacion al detectar un cambio en la paginacion.
        this.load();
    };
    TiquetsAnulacionesTableComponent.prototype.updateFilterFromParent = function (val) {
        // Cuando el componente parent nos pasa un nuevo filtro compartido, actualizamos.
        this.sharedFilter = val;
        this.load();
    };
    //#endregion
    //#region OTHERS
    TiquetsAnulacionesTableComponent.prototype.load = function () {
        this.currentFilter.paginator.set(this.sort, this.paginator);
        this.dataSource.loadFilter(this.currentFilter, this.i18n('No s\'han trobat registres per anul·lacions'));
    };
    TiquetsAnulacionesTableComponent.prototype.generateExcel = function () {
        var filter = this.currentFilter;
        this.dialog.open(DownloadExcelDialogComponent, {
            closeOnNavigation: false,
            autoFocus: true,
            data: Builder.newDownloadExcelDialogData(this.tiquetsAnulacionesService, filter, this.i18n('Anulacions'), this.sort.direction, this.sort.active)
        });
    };
    return TiquetsAnulacionesTableComponent;
}());
export { TiquetsAnulacionesTableComponent };
