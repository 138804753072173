<app-dialog-header *ngIf="this.dataDialog.isDialog" label="Zones" i18n-label></app-dialog-header>

<app-toolbar>
  <!-- <app-tool-button text="Nou" i18n-text (click)="create();" color="has-color-green" icon="fa-plus-square" [disabled]="!this.auth.UserCanCreate(this.auth.Pages.Tarifes)"></app-tool-button> -->
  <app-tool-button text="Actualizar" i18n-text (click)="refresh();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
  <!-- <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button> -->
  <app-tool-button-permissions [permission]="this.auth.Pages.Tarifes"></app-tool-button-permissions>
  <app-tool-button text="Descargar datos" i18n-text (click)="descargarDatos();" color="has-color-aqua" icon="fa-cloud-download-alt" [disabled]="!this.auth.UserCanCreate(this.auth.Pages.Zonas)"></app-tool-button>
  <app-tool-button text="Publicar" i18n-text (click)="publicar();" color="has-color-aqua" icon="fa-cloud-upload-alt" [disabled]="!this.auth.UserCanCreate(this.auth.Pages.Zonas) || !this.publicarAvailable"></app-tool-button>

</app-toolbar>
<app-zones-aparcare-table [selectButton]="this.dataDialog.isDialog && !this.dataDialog.isReadOnly" [updatedFilterData]="filterData" (notify)="onRowSelected($event)"></app-zones-aparcare-table>