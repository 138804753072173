var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from './../../../shared/models/filter-data';
var DenunciaFilter = /** @class */ (function (_super) {
    __extends(DenunciaFilter, _super);
    function DenunciaFilter(sortField, sortDir) {
        var _this = 
        // activamos notificacion evento clear para resetear el filtro con los datos correctos
        _super.call(this, sortField, sortDir) || this;
        _this.notifyClear = true;
        _this.somId = '';
        _this.expediente = '';
        _this.expExtern = '';
        _this.regEntAlegacio = '';
        _this.alegacion = '';
        _this.resolucion = '';
        _this.reposicion = '';
        _this.matricula = '';
        _this.agente = '';
        _this.dni = '';
        _this.dniSearchType = '';
        _this.numeroDenuncia = '';
        _this.codigoAnulacion = '';
        _this.calle = '';
        _this.nombreConductor = '';
        _this.nombreSearchType = '';
        _this.esJuridica = null;
        _this.fechaCobroInicio = null;
        _this.fechaCobroFin = null;
        _this.fechaInfraccionInicio = null;
        _this.fechaInfraccionFin = null;
        _this.esJudicial = null;
        _this.esJPT = null;
        _this.esSCT = null;
        _this.pda = '';
        _this.c60 = '';
        _this.JPT = '';
        _this.SCT = '';
        // this.ley = '';
        _this.articulo = '';
        _this.fechaEnvioInicio = null;
        _this.fechaEnvioFin = null;
        _this.esConfirmadas = null;
        _this.esNoConfirmadas = null;
        _this.fechaAnulacionInicio = null;
        _this.fechaAnulacionFin = null;
        _this.tipusAnul = null;
        _this.usuariAnul = null;
        _this.motiuAnul = null;
        _this.origen = '';
        _this.esCerradoOficina = null;
        _this.fechaCierreInicio = null;
        _this.fechaCierreFin = null;
        _this.registroEntAlegacion = '';
        _this.registroEntResolucion = '';
        _this.registroEntReposicion = '';
        _this.fechaCaducidadInicio = null;
        _this.fechaCaducidadFin = null;
        _this.fechaPrescripcionInicio = null;
        _this.fechaPrescripcionFin = null;
        _this.zonaBlava = '';
        _this.zonaAzul = null;
        _this.policia = null;
        _this.convivencia = null;
        _this.tancat = '';
        _this.notificadaConductor = '';
        _this.notificadaPropietario = '';
        _this.fechaNotiConFin = null;
        _this.fechaNotiConInicio = null;
        _this.fechaNotiPropFin = null;
        _this.fechaNotiPropInicio = null;
        _this.puntos = '';
        _this.filterTable = '';
        _this.aparato = '';
        _this.estatGrua = '';
        _this.fechaPagadaInicio = null;
        _this.fechaPagadaFin = null;
        _this.labels['simpleBooleanJuridica'] = _this.i18n('Jurídica');
        _this.labels['simpleBooleanSCT'] = _this.i18n('SCT');
        _this.labels['simpleBooleanJPT'] = _this.i18n('JPT');
        _this.labels['simpleBooleanJudicial'] = _this.i18n('Judicial');
        _this.labels['simpleBooleanCerrado'] = _this.i18n('Tancat');
        _this.labels['leyTextListHelper'] = _this.i18n('Llei');
        _this.labels['articuloTextListHelper'] = _this.i18n('Article');
        _this.labels['origenTextListHelper'] = _this.i18n('Origen');
        _this.labels['expediente'] = _this.i18n('Expedient');
        _this.labels['expExtern'] = _this.i18n('Exp. Extern');
        _this.labels['matricula'] = _this.i18n('Matrícula');
        _this.labels['agente'] = _this.i18n('Agent');
        _this.labels['dni'] = _this.i18n('DNI');
        _this.labels['dniSearchType'] = _this.i18n('DNI Tipus');
        _this.labels['numeroDenuncia'] = _this.i18n('Número Denuncia');
        _this.labels['codigoAnulacion'] = _this.i18n('Codi Anul·lació');
        _this.labels['calle'] = _this.i18n('Carrer');
        _this.labels['nombreConductor'] = _this.i18n('Conductor');
        _this.labels['nombreSearchType'] = _this.i18n('Tipus Cerca');
        _this.labels['esJuridica'] = _this.i18n('És Jurídica');
        _this.labels['fechaCobroInicio'] = _this.i18n('Fecha Cobro Inicial');
        _this.labels['fechaCobroFin'] = _this.i18n('Fecha Cobro Final');
        _this.labels['fechaInfraccionInicio'] = _this.i18n('Data Infracció Inicial');
        _this.labels['fechaInfraccionFin'] = _this.i18n('Data Infracció Final');
        _this.labels['esJudicial'] = _this.i18n('És Judicial');
        _this.labels['esJPT'] = _this.i18n('JPT');
        _this.labels['esSCT'] = _this.i18n('SCT');
        _this.labels['pda'] = _this.i18n('PDA');
        _this.labels['c60'] = _this.i18n('C60');
        _this.labels['JPT'] = _this.i18n('JPT');
        _this.labels['SCT'] = _this.i18n('SCT');
        _this.labels['ley'] = _this.i18n('Llei');
        _this.labels['articulo'] = _this.i18n('Article');
        _this.labels['fechaEnvioInicio'] = _this.i18n('Data Enviament Inicial');
        _this.labels['fechaEnvioFin'] = _this.i18n('Data Enviament Final');
        _this.labels['esConfirmadas'] = _this.i18n('Confirmada');
        _this.labels['esNoConfirmadas'] = _this.i18n('No confirmada');
        _this.labels['fechaAnulacionInicio'] = _this.i18n('Data Anul·lació Inicial');
        _this.labels['fechaAnulacionFin'] = _this.i18n('Data Anul·lació Final');
        _this.labels['tipusAnul'] = _this.i18n('Tipus anul·lació');
        _this.labels['usuariAnul'] = _this.i18n('Usuari anul·lació');
        _this.labels['motiuAnul'] = _this.i18n('Motiu anul·lació');
        _this.labels['origen'] = _this.i18n('Origen');
        _this.labels['fechaCierreInicio'] = _this.i18n('Data Tancament Inicial');
        _this.labels['fechaCierreFin'] = _this.i18n('Data Tancament Final');
        _this.labels['esCerradoOficina'] = _this.i18n('Tancat oficina');
        _this.labels['registroEntAlegacion'] = _this.i18n('Registre Entrada Alegació');
        _this.labels['registroEntResolucion'] = _this.i18n('Registre Entrada Resolució');
        _this.labels['registroEntReposicion'] = _this.i18n('Registre Entrada Reposició');
        _this.labels['fechaCaducidadInicio'] = _this.i18n('Data Caducitat Inicial');
        _this.labels['fechaCaducidadFin'] = _this.i18n('Data Caducitat Final');
        _this.labels['fechaPrescripcionInicio'] = _this.i18n('Data Prescripció Inicial');
        _this.labels['fechaPrescripcionFin'] = _this.i18n('Data Prescripció Final');
        _this.labels['zonaBlava'] = _this.i18n('Zona Blava');
        _this.labels['zonaAzul'] = _this.i18n('Zona Blava');
        _this.labels['policia'] = _this.i18n('Policia');
        _this.labels['convivencia'] = _this.i18n('Convivencia');
        _this.labels['tancat'] = _this.i18n('Tancat');
        _this.labels['notificadaConductor'] = _this.i18n('Notificada conductor');
        _this.labels['notificadaPropietario'] = _this.i18n('Notificada propietario');
        _this.labels['fechaNotiConFin'] = _this.i18n('Fecha notificación conductor final');
        _this.labels['fechaNotiConInicio'] = _this.i18n('Fecha notificación conductor inicial');
        _this.labels['fechaNotiPropFin'] = _this.i18n('Fecha notificación propietario final');
        _this.labels['fechaNotiPropInicio'] = _this.i18n('Fecha notificación propietario inicial');
        _this.labels['puntos'] = _this.i18n('Punts');
        _this.labels['aparato'] = _this.i18n('Aparato');
        _this.labels['estatGrua'] = _this.i18n('Grua');
        _this.labels['filterTable'] = _this.i18n('Filtro rápido');
        _this.labels['fechaPagadaInicio'] = _this.i18n('Data Pagada Inicial');
        _this.labels['fechaPagadaFin'] = _this.i18n('Data Pagada Final');
        _this.formatOutput['fechaInfraccionInicio'] = 'shortDate';
        _this.formatOutput['fechaInfraccionFin'] = 'shortDate';
        return _this;
    }
    return DenunciaFilter;
}(FilterData));
export { DenunciaFilter };
