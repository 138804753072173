import { EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
var TextoAvisoFilterComponent = /** @class */ (function () {
    function TextoAvisoFilterComponent(thisDialogRef, i18n, data) {
        this.thisDialogRef = thisDialogRef;
        this.i18n = i18n;
        this.data = data;
        this.notify = new EventEmitter();
        this.filter = data.filter;
        this.idiomaDictionary = data.idiomas;
    }
    Object.defineProperty(TextoAvisoFilterComponent.prototype, "bloqueaLogin", {
        // Necesaria esta variable que nos permite convertir los valores mostrados en el triState
        // y negarlos con el valor del filtro actual (bloquea === !permite).
        get: function () {
            if (this.filter.permiteLogIn === null) {
                return this.filter.permiteLogIn;
            }
            else {
                return !this.filter.permiteLogIn;
            }
        },
        set: function (val) {
            if (val !== null && val !== undefined) {
                this.filter.permiteLogIn = !val;
            }
            else {
                this.filter.permiteLogIn = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    TextoAvisoFilterComponent.prototype.ngOnInit = function () {
    };
    TextoAvisoFilterComponent.prototype.reset = function () {
        this.filter.clear();
        this.apply();
    };
    TextoAvisoFilterComponent.prototype.apply = function () {
        this.filter.updated();
        this.notify.emit(this.filter);
        this.thisDialogRef.close();
    };
    TextoAvisoFilterComponent.prototype.dismiss = function () {
        this.thisDialogRef.close();
    };
    TextoAvisoFilterComponent.prototype.siglaSeleccionada = function (sigla) {
        this.filter.idioma = sigla;
    };
    TextoAvisoFilterComponent.prototype.bloqueaLogInSeleccionado = function (bloquea) {
        this.filter.permiteLogIn = !bloquea;
    };
    return TextoAvisoFilterComponent;
}());
export { TextoAvisoFilterComponent };
