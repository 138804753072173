<div class="row">
  <div class="col-sm-7 table-sdw is-fixed"  style="height: 90%;width:100%; padding: 10px;">
      <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
        <ng-container matColumnDef="MAPA">
          <mat-header-cell *matHeaderCellDef i18n class="is-checkbox"><mat-checkbox #allCheck (change)="toggleAllCheckbox($event)"></mat-checkbox></mat-header-cell>
          <mat-cell *matCellDef="let row"><button class="btn btn-outline btn-xs"  [style.color]="getMarkerIconColor(row.SOM_ID)"  i18n-data-content i18n-title (click)="this.loadMarker(row.LATITUD, row.LONGITUD, row.SOM_ID, row.TIPUS_ACTUACIO)"></button></mat-cell>
        </ng-container>
        <ng-container matColumnDef="SOM_ID">
          <mat-header-cell *matHeaderCellDef i18n>Id</mat-header-cell>
          <mat-cell *matCellDef="let row"><a>{{row.SOM_ID}}</a></mat-cell>
        </ng-container>
        <ng-container matColumnDef="matricula">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link">{{row.MATRICULA}}</a></mat-cell>
        </ng-container>
        <ng-container matColumnDef="Latitud">
          <mat-header-cell *matHeaderCellDef  i18n>Latitud</mat-header-cell>
          <mat-cell *matCellDef="let row"><a>{{row.LATITUD}}</a></mat-cell>
        </ng-container>
        <ng-container matColumnDef="Longitud">
          <mat-header-cell *matHeaderCellDef  i18n>Longitud</mat-header-cell>
          <mat-cell *matCellDef="let row"><a>{{row.LONGITUD}}</a></mat-cell>
        </ng-container>
        <ng-container matColumnDef="Data">
          <mat-header-cell *matHeaderCellDef i18n>Data</mat-header-cell>
          <mat-cell *matCellDef="let row"><a>{{row.DATA}}</a></mat-cell>
        </ng-container>
        <ng-container matColumnDef="Tipus_Actuacio">
          <mat-header-cell *matHeaderCellDef i18n>Tipus d'actuació</mat-header-cell>
          <mat-cell *matCellDef="let row"><a>{{row.TIPUS_ACTUACIO.DESCRIPCIO}}</a></mat-cell>
        </ng-container>
        <ng-container matColumnDef="ID_INSTIT">
          <mat-header-cell *matHeaderCellDef i18n>Institución</mat-header-cell>
          <mat-cell *matCellDef="let row"><a>{{ row.ID_INSTIT }}</a></mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
        </mat-table>
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[100]"></mat-paginator>

  </div>
  <div class="col-sm-5" style="height: 90%;width:100%;  padding: 10px">
    <app-dialog-header label="Mapa" i18n-label></app-dialog-header>
<app-toolbar>

</app-toolbar>
<app-dialog-body> 
<agm-map
  (mapReady)="mapReady($event)"
  [latitude]="currentLocation.latitude"
  [longitude]="currentLocation.longitude"
  [styles]="styleMap"
  style="height: 700px; width: 100%;"
  >
  <agm-marker
    *ngFor="let m of markersArray; let i = index"
    [latitude]="m.lat"
    [longitude]="m.lng"
    [iconUrl]="{ url: m.iconPath }"
  >
    <agm-info-window>
      <div style="font-weight: bold;">{{ m.label }}</div>
    </agm-info-window>
  </agm-marker>
</agm-map>
</app-dialog-body>
</div>
</div>
