<app-dialog-header label="Festius" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Festius)  || !this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Festius) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Festius) ||this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-date-edit id="festius-editor-dia" class="col-3" i18n-label label="Dia festiu" [(value)]="this.festiu.dia" required></app-form-date-edit>
      <app-form-text-edit id="festius-editor-descripcio" class="col-9" i18n-label label="Descripcio" [(value)]="this.festiu.descripcio" required></app-form-text-edit>
      <!-- <app-form-text-edit id="festius-editor-tipus" class="col-2" i18n-label label="Tipus festiu" [(value)]="this.festiu.tipus" required></app-form-text-edit> -->
    </div>
  </form>
</app-dialog-body>