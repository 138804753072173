import { InfraccioService } from './../../../configuracio/Infraccions/services/infraccions-service.service';
import { ZonesModel } from './../../../ciutat/zones/models/zones.model';
import { ZonesService } from './../../../ciutat/zones/services/zones.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { ZB_ZONAS } from 'path-models/ZB_ZONAS';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { InstitucionsService } from 'path-shared/services/institucions/institucions.service';
import { EstadisticaDto } from '../../models/estadisticaDto';
import { FiltroTiempoEstadisticas } from '../../models/filtro-tiempo.model';
import { EstadistiquesService } from '../../services/estadistiques.service';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { isNullOrUndefined } from 'util';
import { AparellService } from 'app/sections/configuracio/aparells/services/aparells-service.service';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';

@Component({
  selector: 'app-estadisticas-varios',
  templateUrl: './estadisticas-varios.component.html',
  styleUrls: ['./estadisticas-varios.component.scss']
})
export class EstadisticasVariosComponent implements OnInit {
  //config grafico
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  colors = [
    { // ventas
      backgroundColor: 'rgba(0, 204, 102, 0.8)'
    },
    { // devol
      backgroundColor: 'rgba(255, 99, 132, 0.8)'
    },
    { // anul
      backgroundColor: 'rgba(54, 162, 235, 0.8)'
    }
  ]
  colorsDevol = [
    { // devol
      backgroundColor: 'rgba(255, 99, 132, 0.8)'
    }
  ]
  colorsAnul = [
    { // anul
      backgroundColor: 'rgba(54, 162, 235, 0.8)'
    }
  ]
  public barChartType: ChartType = 'bar';
  barChartData: ChartDataSets[];
  barChartDataMultiLine: ChartDataSets[];
  barChartLabels: any;
  public dataRecived: boolean = false;
  public dateSelected: Date = new Date();

  public showMultiLine: boolean = false;
  public tipoConsultado: string;
  public tiposTemporizacion = [{ label: FiltroTiempoEstadisticas.Anual }, { label: FiltroTiempoEstadisticas.Mensual }, 
    { label: FiltroTiempoEstadisticas.Semanal }, { label: FiltroTiempoEstadisticas.Diario }];
  public tipoTemporizacionSelected: string = FiltroTiempoEstadisticas.Mensual;
  public datos: EstadisticaDto = { chartDataSets: [], chartLabels: [], chartColors: [] }

  agents = new Array<FormMultiSelect>();
  aparells = new Array<FormMultiSelect>();
  tipos = [{ codi: 'DENUNCIAS' }, { codi: 'DETECCIONES' }, { codi: 'PROPUESTAS' }, { codi: 'TICKETS' }];
  estadosPropuesta = [{ codi: 0, descripcio: this.i18n('Aceptadas') }, { codi: 1, descripcio: this.i18n('Pendientes') }, 
    { codi: 2, descripcio: this.i18n('Desestimadas') }];
  estadoPropuestaSelected = 1;
  public agentesSelected = new Array<FormMultiSelect>();
  public aparellsSelected = new Array<FormMultiSelect>();
  public tipoSelected: string = 'DENUNCIAS';


  anuladaItems = [];
  public anuladaSelected: boolean;
  constructor(private estadistiquesService: EstadistiquesService,
    private authService: AuthorizationService,
    private aparellsService: AparellService,
    private agentsService: AgentsService,
    private dialog: MatDialog,
    private i18n: I18n,
    public auth: AuthorizationService,) {
    this.barChartLabels = [];
    this.barChartData = [];
    this.barChartDataMultiLine = [];


    // Carreguem els agents
    this.agentsService.getAllCombo().subscribe(result => {
      this.agents = result.map(s => new FormMultiSelect(s.codi, s.codi));
    });

    // Carreguem els aparells
    this.aparellsService.getAllCombo().subscribe(result => {
      this.aparells = result.map(s => new FormMultiSelect(s.id, s.descripcion));
    });
  }

  ngOnInit() {
    //this.reload();
  }
  reload() {
    let filter = this.getFilter();
    this.estadistiquesService.getEstadistiquesVarios(filter).subscribe(x => {

      this.dataRecived = true;
      this.datos = x;
      this.barChartData = [];
      Object.assign([], this.barChartData);
      this.barChartDataMultiLine = [];
      Object.assign([], this.barChartDataMultiLine);
      if (!this.barChartLabels) {
        this.barChartLabels = [];
      }
      this.barChartLabels.length = 0;
      this.barChartLabels.push(...Object.values(this.datos.chartLabels.barChartLabels));

      this.barChartData.length = 0;
      this.barChartDataMultiLine.length = 0;
      if (x.chartDataSets.length > 1) {
        this.showMultiLine = true;
        x.chartDataSets.forEach(element => {
          this.barChartDataMultiLine.push({
            data: element.data, label: element.label
          });
        });
      } else {
        this.showMultiLine = false;
        this.barChartData.push({ data: x.chartDataSets[0].data, label: x.chartDataSets[0].label }
        );
      }

    });
  }

  onDateChange(): void {
    this.reload();
  }

  getFilter(): any {
    let filter = {
      fecha: this.dateSelected, tipoTemporizacion: this.tipoTemporizacionSelected,
      agente: this.agentesSelected, aparell: this.aparellsSelected.map(m=>m.item_id), tipo: this.tipoSelected, estadoPropuesta: this.estadoPropuestaSelected
    };


    return filter;
  }

  generateExcel(): void {
    let filter = this.getFilter();
    this.dialog.open(DownloadExcelDialogComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: Builder.newDownloadExcelDialogDataGeneral(this.estadistiquesService, filter, this.i18n('Denuncias'))
    });
  }
  restartFilter() {
    this.agentesSelected = null;
  }
}
