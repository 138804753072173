import { Injectable } from '@angular/core';
import { HttpSecureClient } from '../http/httpSecureClient';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuardService } from '../authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { api } from 'path-shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { Instituciones } from 'path-shared/models/instituciones';

@Injectable({
  providedIn: 'root'
})
export class InstitucionsService {

  constructor(private http: HttpSecureClient, private cookieService: CookieService, private authGuard: AuthGuardService) { }

  getInstitucions(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlInstitucionesByUserAuthenticated'];
      return this.http.get(url, null);
    }
  }

  getAllInstitucions(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlInstituciones'];
      return this.http.get(url, null);
    }
  }

  setNewIdInstitution(id) {
    sessionStorage.setItem('IdInstitucion', id);
    localStorage.setItem('IdInstitucion', id);
    this.cookieService.set('InstitucioSeleccionada', id);
    location.reload();
  }

  getCurrentInstitution() {
    const id = sessionStorage.getItem('IdInstitucion');
    return id;
  }

  getInstitution(): Instituciones {
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== null) {
      const institution: Instituciones = JSON.parse(inst);
      return institution;
    }
  }
}
