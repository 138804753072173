import { Component, OnInit, EventEmitter, Output, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable , Subscription} from 'rxjs';
import { GruaMotiusEntradaFilter } from '../../models/grua-motius-entrada-filter';


@Component({
  selector: 'app-grua-motius-entrada-filter',
  templateUrl: './grua-motius-entrada-filter.component.html',
  styleUrls: ['./grua-motius-entrada-filter.component.scss']
})
export class GruaMotiusEntradaFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: GruaMotiusEntradaFilter;

  @Output() notify: EventEmitter<GruaMotiusEntradaFilter> = new EventEmitter<GruaMotiusEntradaFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<GruaMotiusEntradaFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

   
 ngOnDestroy(): void {
  this.subscription.unsubscribe();
}

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
