import { Time } from '@angular/common';
import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class TurnosFilter extends FilterData {
  somId: number;
  descripcion: string;
  hora_inicio: Time;
  hora_fin: Time;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.descripcion = '';
    this.hora_inicio = null;
    this.hora_fin = null;
    this.labels['descripcion'] = this.i18n('Descripció');
    this.labels['hora_inicio'] = this.i18n('Hora inici');
    this.labels['hora_fin'] = this.i18n('Hora fi');

  }
}
