import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { SeguretatUsuaris } from '../../models/seguretat-usuaris.model';
import { SeguretatUsuarisService } from '../../services/seguretat-usuaris.service';
import { TransformDataPipe } from 'path-shared/pipes/transform-data.pipe';
import { Instituciones } from 'path-shared/models/instituciones';
import { SeguretatFormsUsuari } from '../../models/seguretat-form-usuario.model';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { SeguretatGrups } from 'app/sections/seguretat/seguretat-grups/models/seguretat-grups.model';
import { SeguretatUsuarisAgents } from '../../models/seguretat-usuaris-agents.model';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { TirarUsuarisLdapComponent } from '../tirar-usuaris-ldap/tirar-usuaris-ldap.component';

@Component({
  selector: 'app-seguretat-usuaris-editor',
  templateUrl: './seguretat-usuaris-editor.component.html',
  styleUrls: ['./seguretat-usuaris-editor.component.scss']
})
export class SeguretatUsuarisEditorComponent implements OnInit {

  secureId: string;
  seguretatUsuari: SeguretatUsuaris;
  instituciones = [];
  institucionesAux: Instituciones[] = [];
  agentes = [];
  agentesAux: SeguretatUsuarisAgents[] = [];
  perfils = [];
  segPantallas = [];
  segGrupos = [];
  segPantallasbp = [];
  segGruposbp = [];
  nomInstitucio: string;
  rangDatesDateAux = new Array<Date>();
  institucioAssignada: number;
  showSuperUsuari: boolean;

  dataSourcePermisos =  new MatTableDataSource<SeguretatFormsUsuari>();
  dataSourceGrupos =  new MatTableDataSource<SeguretatGrups>();

  @ViewChild('sortPermisos') sortPermisos: MatSort;
  @ViewChild('sortGrupos') sortGrupos: MatSort;

  displayedColumnsPermisos = ['pantalla', 'lectura', 'alta', 'modif', 'borrar', 'eliminar'];
  displayedColumnsGrupos = ['grupo', 'eliminar'];

  @ViewChild('mainForm') private mainForm: ElementRef;
  isLdap= false;
  constructor(
    public auth: AuthorizationService,
    private seguretatUsuarisService: SeguretatUsuarisService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    public thisDialogRef: MatDialogRef<SeguretatUsuarisEditorComponent>,
    private formService: FormService,
    public dataTrans: TransformDataPipe,
    private confirmationDialogService: ConfirmationDialogService,
    private parametersService: ParametersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
      this.seguretatUsuari = Builder.newSeguretatUsuari();
      this.perfils = this.data.perfils;
      this.institucionesAux = this.data.instituciones;
      this.segPantallas = Object.assign([], this.data.segPantallas);
      this.segGrupos = Object.assign([], this.data.segGrupos);
      this.agentesAux = this.data.agentes;
      this.institucioAssignada = this.data.institucioAssignada;
      this.showSuperUsuari = this.parametersService.getParameter('EDITOR_SHOW_SUPERUSUARI') === 'Y';
      if (data.id !== null) {
        this.secureId = this.data.id;
      }
   }

  ngOnInit() {
    this.loadInstituciones();

    if (this.secureId !== null && this.secureId !== undefined) {
      // Dades de seguretat de l'usuari
      this.seguretatUsuarisService.getSeguretatUsuaris(this.secureId).subscribe((data: SeguretatUsuaris) => {
        this.rangDatesDateAux.push(this.dataTrans.transform(data.dataInici));
        this.rangDatesDateAux.push(this.dataTrans.transform(data.dataFi));
        this.seguretatUsuari = data;
        this.seguretatUsuari.rangDatesDate = this.rangDatesDateAux;
        this.loadLdapConfig();
        this.inicializaPantallas();
        this.loadPantallas();
        this.inicializaGrupos();
        this.loadGrupos();
        this.loadAgentes();
      });
    } else {
      this.loadLdapConfig();
      this.inicializaPantallas();
      this.loadPantallas();
      this.inicializaGrupos();
      this.loadGrupos();
      this.loadAgentes();
    }
  }
loadLdapConfig(){
  this.seguretatUsuarisService.getLdapConfig().subscribe(result => {
    
    let configLdap= result;
    if(configLdap !== undefined && configLdap !== null  && configLdap.ldapActivo){
      this.isLdap=true;
      if (!(this.secureId !== null && this.secureId !== undefined)) {
        const ref = this.dialog.open(TirarUsuarisLdapComponent, {
          width: '10%',
          height: '30%',
          closeOnNavigation: false,
          autoFocus: true,
          data: {
            'usuarisLdap': configLdap.listUsers
            }
        });
    
        ref.afterClosed().subscribe(nomUsuari => {
          if (configLdap !== undefined && configLdap !== null && configLdap !== '') {
            this.seguretatUsuari.nomUsuari = nomUsuari;
            }
        });
      }
    }
  });
}
  loadInstituciones() {
    if (this.institucionesAux.length > 1) {
      this.instituciones.push({'key': null, 'value': ''});
      this.institucionesAux.forEach((institucion: Instituciones) => {
        this.instituciones.push({'key': institucion.IdInstitucion, 'value': institucion.NombreInstitucion});
      });
    } else {
      this.seguretatUsuari.institucionId = this.institucionesAux[0].IdInstitucion;
      this.nomInstitucio = this.institucionesAux[0].NombreInstitucion;
    }
  }

  inicializaPantallas() {
    if (this.seguretatUsuari.pantallas === undefined) {
      this.seguretatUsuari.pantallas = [];
    }

    const emptyRow = Builder.newSeguretatFormsUsuari();
    this.seguretatUsuari.pantallas.push(emptyRow);
    this.dataSourcePermisos = new MatTableDataSource(this.seguretatUsuari.pantallas);
    this.dataSourcePermisos.sort = this.sortPermisos;
  }

  inicializaGrupos() {
    if (this.seguretatUsuari.grupos === undefined) {
      this.seguretatUsuari.grupos = [];
    }

    const emptyRow2 = Builder.newGrup();
    this.seguretatUsuari.grupos.push(emptyRow2);
    this.dataSourceGrupos = new MatTableDataSource(this.seguretatUsuari.grupos);
    this.dataSourceGrupos.sort = this.sortGrupos;
  }

  loadPantallas() {
    this.seguretatUsuari.pantallas.forEach(pantalla => {
      const index = this.segPantallas.findIndex(x => x.descripcio === pantalla.pantalla);
      if (index > -1) {
        this.segPantallas.splice(index, 1);
      }
    });

    this.segPantallas.unshift({'id': 0, 'descripcio': ''});
    Array.prototype.push.apply(this.segPantallasbp, this.segPantallas);
  }

  loadGrupos() {
    this.seguretatUsuari.grupos.forEach(grupo => {
      const index = this.segGrupos.findIndex(x => x.descripcio === grupo.descripcio);
      if (index > -1) {
        this.segGrupos.splice(index, 1);
      }
    });
    this.segGrupos.unshift({'id': 0, 'descripcio': ''});
    Array.prototype.push.apply(this.segGruposbp, this.segGrupos);

  }

  loadAgentes() {
    this.agentes.push({'key': null, 'value': ''});
    this.agentesAux.forEach(agente => {
      this.agentes.push({'key': agente.id, 'value': agente.descripcio});
    });
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      // convertir data a int yyyyMMdd
      this.seguretatUsuari.dataInici = this.formatDateToInt(this.seguretatUsuari.rangDatesDate[0]);
      this.seguretatUsuari.dataFi = this.formatDateToInt(this.seguretatUsuari.rangDatesDate[1]);

      this.seguretatUsuarisService.setSeguretatUsuaris(this.seguretatUsuari).subscribe(result => {
        this.toastr.info(this.i18n('Seguretat usuari guardada correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar l\'usuari?'))
    .then((confirmed) => {
        if (confirmed) {
          this.seguretatUsuarisService.removeSeguretatUsuari(this.secureId).subscribe(result => {
            this.toastr.success(this.i18n('Usuari esborrat correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
    });
  }

  darDeBaja() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Seguro que desea dar de baja el usuario?'))
    .then((confirmed) => {
        if (confirmed) {
          this.seguretatUsuari.actiu = false;
          this.checkActiuValidarRangDates(false);
          this.seguretatUsuari.dataInici = this.formatDateToInt(this.seguretatUsuari.rangDatesDate[0]);
          this.seguretatUsuari.dataFi = this.formatDateToInt(this.seguretatUsuari.rangDatesDate[1]);
          this.seguretatUsuarisService.setSeguretatUsuaris(this.seguretatUsuari).subscribe(result => {
            this.toastr.success(this.i18n('Usuario dado de baja correctamente'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
    });
  }

  // Hecho rápido para la presentación de Jordi
  // TODO: hacer un Set() en lugar de dos métodos separados
  darDeAlta() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Seguro que desea dar de alta el usuario?'))
    .then((confirmed) => {
        if (confirmed) {
          this.seguretatUsuari.actiu = true;
          this.checkActiuValidarRangDates(true);
          this.seguretatUsuari.dataInici = this.formatDateToInt(this.seguretatUsuari.rangDatesDate[0]);
          this.seguretatUsuari.dataFi = this.formatDateToInt(this.seguretatUsuari.rangDatesDate[1]);
          this.seguretatUsuarisService.setSeguretatUsuaris(this.seguretatUsuari).subscribe(result => {
            this.toastr.success(this.i18n('Usuario dado de alta correctamente'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
    });
  }

  filtrarAgents(institucioId: any) {
    if (institucioId !== null && institucioId !== undefined && institucioId !== '') {
      this.agentes = [];
      this.agentes.push({'key': null, 'value': ''});
      this.agentesAux.filter(x => x.institucioId === institucioId).forEach(agenteAux => {
        this.agentes.push({'key': agenteAux.id, 'value': agenteAux.descripcio});
      });
    } else if (institucioId === '') {
      this.agentesAux.forEach(agenteAux => {
        this.agentes.push({'key': agenteAux.id, 'value': agenteAux.descripcio});
      });
    }
  }

  //#region Acciones Tab de permisos

  novaFilaPantalla(id: any, row) {
    if (id !== null && id !== 0) {
      const emptyRow = Builder.newSeguretatFormsUsuari();
      this.seguretatUsuari.pantallas.push(emptyRow);
      this.dataSourcePermisos = new MatTableDataSource(this.seguretatUsuari.pantallas);
      this.dataSourcePermisos.sort = this.sortPermisos;
      row.idSegPantallas = id;
      const index = this.segPantallas.findIndex(x => x.id === id);
      row.pantalla = this.segPantallas[index].descripcio;
      if (index > -1) {
        this.segPantallas.splice(index, 1);
      }
    }
  }

  novaFilaGrup(id: any, row) {
    if (id !== null && id !== 0) {
      const emptyRow = Builder.newGrup();
      this.seguretatUsuari.grupos.push(emptyRow);
      this.dataSourceGrupos = new MatTableDataSource(this.seguretatUsuari.grupos);
      this.dataSourceGrupos.sort = this.sortGrupos;
      row.codiGrup = id;
      row.SOM_ID = id;
      const index = this.segGrupos.findIndex(x => x.id === id);
      row.descripcio = this.segGrupos[index].descripcio;
      if (index > -1) {
        this.segGrupos.splice(index, 1);
      }
    }
  }

  removePantalla(row: SeguretatFormsUsuari) {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la pantalla?'))
    .then((confirmed) => {
        if (confirmed) {
          const index = this.seguretatUsuari.pantallas.findIndex(x => x.idSegPantallas === row.idSegPantallas);
          if (index > -1) {
            this.seguretatUsuari.pantallas.splice(index, 1);
            this.dataSourcePermisos = new MatTableDataSource(this.seguretatUsuari.pantallas);
            this.segPantallas.push(this.segPantallasbp.find(x => x.id === row.idSegPantallas));
          }

          if (row.pantalla !== null) {
            this.seguretatUsuarisService.removeSeguretatFormsUsuari(row.secureId).subscribe(result => {
              this.toastr.success(this.i18n('Pantalla esborrada correctament'));
            });
          }
        }
    });
  }

  removeGrup(row: SeguretatGrups) {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar el grupo associat a l\'usuari?'))
    .then((confirmed) => {
        if (confirmed) {
          const index = this.seguretatUsuari.grupos.findIndex(x => x.codiGrup === row.codiGrup);
          if (index > -1) {
            this.seguretatUsuari.grupos.splice(index, 1);
            this.dataSourceGrupos = new MatTableDataSource(this.seguretatUsuari.grupos);

            this.segGrupos.push(this.segGruposbp.find(x => x.id === row.codiGrup));
          }

          if (row.secureId !== null) {
            this.seguretatUsuarisService.removeSeguretatGrupUsuari(this.seguretatUsuari.codiUser, row.secureId).subscribe(result => {
              this.toastr.success(this.i18n('Grup esborrat correctament'));
            });
          }
        }
    });
  }

  // #endregion

  // #region Events

  /*
    Al fer un canvi al rang de dates, calcules el check d'Actiu
  */
  calcularCheckActiu(rangDates: Date[]) {
    if (rangDates !== null && rangDates !== undefined) {
      const dataIni = rangDates[0];
      const dataFi = rangDates[1];
      const today = new Date();

      if (dataIni <= today && today <= dataFi) {
        this.seguretatUsuari.actiu = true;
      } else {
        this.seguretatUsuari.actiu = false;
      }
    }
  }

  /*
    Al fer un canvi al check d'Actiu, calculem les dates:
      - Si activem:
          Si la dataInici és més gran que avui o la dataInici és null --> La dataInici serà avui
          Si la dataFi és més petita que avui o la dataFi és null --> Si tenim dataFi posarem un anys endavant i si no la tenim un any endavant de la dataAvui
      - Si desactivem:
          Si la dataInici és més gran que avui --> La dataInici serà demà
          Si la dataInici és null i la dataFi anterior a demà --> posem dataInici i dataFi a ahir (perquè data inici/fi siguien coherents)
          Si la dataFi és més gran que avui o la dataFi és null --> La dataFi serà d'ahir (vigilant que la dataInici sigui coherent)
  */
  checkActiuValidarRangDates(event: any) {
    const today = new Date();
    const rangDatesDateAux = new Array<Date>();

    if (this.seguretatUsuari.rangDatesDate !== null && this.seguretatUsuari.rangDatesDate !== undefined
        && this.seguretatUsuari.rangDatesDate[0] !== undefined && this.seguretatUsuari.rangDatesDate[1] !== undefined) {

          if (event) {
            if (this.seguretatUsuari.rangDatesDate[0] > today || this.seguretatUsuari.rangDatesDate[0] === null) {
              rangDatesDateAux.push(today, this.seguretatUsuari.rangDatesDate[1]);
              this.seguretatUsuari.rangDatesDate = Object.assign([], rangDatesDateAux);
            }
            if (today > this.seguretatUsuari.rangDatesDate[1] || this.seguretatUsuari.rangDatesDate[1] === null) {
              let dateFiMesUnAny: Date = null;
              if (this.seguretatUsuari.rangDatesDate[1] === null) {
                dateFiMesUnAny = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
              } else {
                dateFiMesUnAny = new Date(this.seguretatUsuari.rangDatesDate[1].getFullYear() + 1,
                                          this.seguretatUsuari.rangDatesDate[1].getMonth(),
                                          this.seguretatUsuari.rangDatesDate[1].getDate());
              }

              rangDatesDateAux.push(this.seguretatUsuari.rangDatesDate[0], dateFiMesUnAny);

              this.seguretatUsuari.rangDatesDate = Object.assign([], rangDatesDateAux);
            }
          } else {
            if (this.seguretatUsuari.rangDatesDate[0] > today || this.seguretatUsuari.rangDatesDate[0] === null) {
              const dema = new Date();
              dema.setDate(dema.getDate() + 1);
              const ahir = new Date();
              ahir.setDate(ahir.getDate() - 1);

              // Controlar que la data fi no sigui anterior a la d'inici
              if (this.seguretatUsuari.rangDatesDate[1] < dema) {
                rangDatesDateAux.push(ahir, ahir);
              } else {
                rangDatesDateAux.push(dema, this.seguretatUsuari.rangDatesDate[1]);
              }

              this.seguretatUsuari.rangDatesDate = Object.assign([], rangDatesDateAux);
            }
            if (today < this.seguretatUsuari.rangDatesDate[1] || this.seguretatUsuari.rangDatesDate[1] === null) {
              const ahir = new Date();
              ahir.setDate(ahir.getDate() - 1);

              // Controlar que la data inici no sigui posterior a la de fi
              if (this.seguretatUsuari.rangDatesDate[0] > ahir) {
                rangDatesDateAux.push(ahir, ahir);
              } else {
                rangDatesDateAux.push(this.seguretatUsuari.rangDatesDate[0], ahir);
              }

              this.seguretatUsuari.rangDatesDate = Object.assign([], rangDatesDateAux);
            }
          }

    } else {
        rangDatesDateAux.push(new Date());
        rangDatesDateAux.push(new Date(2099, 12, 1));
        this.seguretatUsuari.rangDatesDate = Object.assign([], rangDatesDateAux);
    }
  }

  //#endregion

  //#region Support Methods

  formatDateToInt(date: Date) {
    let month: string;
    let day: string;

    month = (date.getMonth() + 1).toString();
    if (month.length === 1) {
      month = '0' + month;
    }

    day = date.getDate().toString();
    if (day.length === 1) {
      day = '0' + day;
    }

    const dateString = date.getFullYear().toString() + month + day;
    const dateInt = +dateString;

    return dateInt;
  }

  //#endregion

}
