import { SortDirection } from '@angular/material';
import { FilterData } from 'path-shared/models/filter-data';

export class EscalaOcupacionFilter extends FilterData {
    color: string;
    rangoSuperior: number;

    constructor(sortField: string, sortDir: SortDirection) {
        super(sortField, sortDir);
        this.color = '';
        this.rangoSuperior = null;

        this.labels['color'] = this.i18n('Color');
        this.labels['rangoSuperior'] = this.i18n('Rang Superior');
    }
}
