var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'id';
var defaultSortDirection = 'asc';
var RecaudacioFilterService = /** @class */ (function (_super) {
    __extends(RecaudacioFilterService, _super);
    function RecaudacioFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RecaudacioFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('numColecta', (filter.numColecta ? filter.numColecta.toString() : ''))
            .append('fechaInicio', Reflection.empty(filter.fechaInicio) ? '' : filter.fechaInicio.toDateString())
            .append('fechaFin', Reflection.empty(filter.fechaFin) ? '' : filter.fechaFin.toDateString())
            .append('maquinaCodi', (filter.maquinaCodi ? filter.maquinaCodi.toString() : ''))
            .append('zonaDesc', (filter.zonaDesc ? filter.zonaDesc.toString() : ''))
            .append('importeMonedaD', (filter.importeMonedaD ? filter.importeMonedaD.toString() : ''))
            .append('importeMonedaComercial', (filter.importeMonedaComercial ? filter.importeMonedaComercial.toString() : ''))
            .append('importeMonedaLegal', (filter.importeMonedaLegal ? filter.importeMonedaLegal.toString() : ''))
            .append('importeTarjetaPayone', (filter.importeTarjetaPayone ? filter.importeTarjetaPayone.toString() : ''))
            .append('importeTarjetaPayonePlus', (filter.importeTarjetaPayonePlus ? filter.importeTarjetaPayonePlus.toString() : ''))
            .append('numeroTicketsTarjetaPayone', (filter.numeroTicketsTarjetaPayone ? filter.numeroTicketsTarjetaPayone.toString() : ''))
            .append('importeAnulacionTarjetaPayOne', (filter.importeAnulacionTarjetaPayOne ? filter.importeAnulacionTarjetaPayOne.toString() : ''))
            .append('numeroTicketsTarjetaPayonePlus', (filter.numeroTicketsTarjetaPayonePlus ? filter.numeroTicketsTarjetaPayonePlus.toString() : ''))
            .append('importeAnulacionTarjetaPayOnePlus', (filter.importeAnulacionTarjetaPayOnePlus ? filter.importeAnulacionTarjetaPayOnePlus.toString() : ''))
            .append('importeTarjetaTotal', (filter.importeTarjetaTotal ? filter.importeTarjetaTotal.toString() : ''))
            .append('importeRecargaTarjetaPayone', (filter.importeRecargaTarjetaPayone ? filter.importeRecargaTarjetaPayone.toString() : ''))
            .append('importeRecargaTarjetaPayonePlus', (filter.importeRecargaTarjetaPayonePlus ? filter.importeRecargaTarjetaPayonePlus.toString() : ''))
            .append('importeRecargaTarjetaTotal', (filter.importeRecargaTarjetaTotal ? filter.importeRecargaTarjetaTotal.toString() : ''))
            .append('anulacionMoneda', (filter.anulacionMoneda ? filter.anulacionMoneda.toString() : ''))
            .append('anulacionImporteTotal', (filter.anulacionImporteTotal ? filter.anulacionImporteTotal.toString() : ''))
            .append('importeRealAparcamiento', (filter.importeRealAparcamiento ? filter.importeRealAparcamiento.toString() : ''))
            .append('numeroTicketsMoneda', (filter.numeroTicketsMoneda ? filter.numeroTicketsMoneda.toString() : ''))
            .append('numeroTicketsTotal', (filter.numeroTicketsTotal ? filter.numeroTicketsTotal.toString() : ''))
            .append('numeroTicketsMonedaComercial', (filter.numeroTicketsMonedaComercial ? filter.numeroTicketsMonedaComercial.toString() : ''))
            .append('importeRecuento', (filter.importeRecuento ? filter.importeRecuento.toString() : ''))
            .append('diferenciaRecuentoTicket', (filter.diferenciaRecuentoTicket ? filter.diferenciaRecuentoTicket.toString() : ''))
            .append('acumuladoMoneda', (filter.acumuladoMoneda ? filter.acumuladoMoneda.toString() : ''))
            .append('acumuladoTarjetaPayone', (filter.acumuladoTarjetaPayone ? filter.acumuladoTarjetaPayone.toString() : ''))
            .append('acumuladoTarjetaPayonePlus', (filter.acumuladoTarjetaPayonePlus ? filter.acumuladoTarjetaPayonePlus.toString() : ''))
            .append('acumuladoTarjetaTotal', (filter.acumuladoTarjetaTotal ? filter.acumuladoTarjetaTotal.toString() : ''))
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return RecaudacioFilterService;
}(CustomFilterService));
export { RecaudacioFilterService };
