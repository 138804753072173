import { TipoSenalesVerticalesService } from './../../services/tipo-senales-verticales-service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { TipoSenalVertical } from '../../models/tipo-senal-vertical';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { FormService } from 'path-shared/services/form/form.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { Builder } from 'path-shared/models/builder';

@Component({
  selector: 'app-tipo-senales-verticales-editor-component',
  templateUrl: './tipo-senales-verticales-editor.component.html',
  styleUrls: ['./tipo-senales-verticales-editor.component.scss']
})
export class TipoSenalesVerticalesEditorComponent implements OnInit {

  secureId: string;
  tipoSenalVertical: TipoSenalVertical;
  dialogData: ComponentDialog;
  closeButtonText: string;

  @ViewChild('mainForm') private mainForm: ElementRef;


  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private tipoSenalesService: TipoSenalesVerticalesService,
    private i18n: I18n,
    private toastr: ToastrService,
    public thisDialogRef: MatDialogRef<TipoSenalesVerticalesEditorComponent>,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog
  ) {
    this.tipoSenalVertical = Builder.newTipoSenalVertical();
    this.dialogData = data;
    this.secureId = this.dialogData.id;
    if (this.dialogData.isReadOnly) {
      this.closeButtonText = this.i18n('Tancar');
    } else {
      this.closeButtonText = this.i18n('Llista');
    }
  }

  ngOnInit() {
    if (this.secureId !== null) {
      this.tipoSenalesService.getTipoSenalVertical(this.secureId).subscribe(data => {
        this.tipoSenalVertical = data;
      });
    }
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      this.tipoSenalesService.setTipoSenalVertical(this.tipoSenalVertical).subscribe(result => {
        this.toastr.info(this.i18n('Tipus de Senyal guardada correctament.'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar el tipus?'))
    .then(confirmed => {
      if (confirmed) {
        this.tipoSenalesService.removeTipoSenalVertical(this.secureId).subscribe(result => {
          this.toastr.success(this.i18n('Tipus de Senyal esborrada correctament'));
          this.dialog.closeAll();
          this.thisDialogRef.close(true);
        });
      }
    });
  }

}
