import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { ErrorsMatriculaFilter } from '../../models/errorsMatriculaFilter';
import { LoaderService } from 'path-shared/components/loader/loader.service';

@Component({
  selector: 'app-carga-matriculas-filter',
  templateUrl: './carga-matriculas-filter.component.html',
  styleUrls: ['./carga-matriculas-filter.component.scss']
})
export class CargaMatriculasFilterComponent implements OnInit {

  subscription: Subscription;
  filter: ErrorsMatriculaFilter;

  vellesONoves: boolean;
  matriculasValues = [true, false];
  labelsMatriculas = ['No mostrar matrículas con errores','Mostrar solo matrículas con errores'];

  @Output() notify: EventEmitter<ErrorsMatriculaFilter> = new EventEmitter<ErrorsMatriculaFilter>();
  constructor(
    public thisDialogRef: MatDialogRef<ErrorsMatriculaFilter>,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: ErrorsMatriculaFilter) { 
    this.filter = data;
    if (data != null) {
      if (data.nomesNoves) this.vellesONoves = true;
      else if (data.nomesVelles) this.vellesONoves = false;
    }
  }


  ngOnInit() {
    this.loaderService.setBackgroundMode(true);
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

  ngOnDestroy(): void {
    this.loaderService.setBackgroundMode(false);
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    if (this.vellesONoves != null) {
      if (this.vellesONoves) {
        this.filter.nomesNoves = true;
        this.filter.nomesVelles = null;
      }
      else {
        this.filter.nomesVelles = true;
        this.filter.nomesNoves = null;
      }
    }
    this.filter.updated()
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
