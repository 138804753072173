<app-dialog-header [label]="this.options.title" ></app-dialog-header>
<app-dialog-body>
  <div class="row">
      {{this.options.text}}
  </div>
  <div class="row">
    <input autofocus #textfield [(ngModel)]="this.options.value" (keyup.enter)="this.acceptValue()">
  </div>
</app-dialog-body>
<app-dialog-footer>
    <button type="button" class="btn btn-primary" (click)="this.acceptValue()" i18n>Acceptar</button>
    <button type="button" class="btn btn-secondary" mat-dialog-close i18n>Cancel·lar</button>
</app-dialog-footer>
