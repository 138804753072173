import { DipositGruaFilter } from '../Models/diposit-filter';
import { DesperfectosDto } from '../Models/desperfectosDto';
import { VehicleDipositDto } from '../Models/diposit-vehicle.model';
import { PropostaGruaDto } from '../Models/proposta-grua.model';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { AliesGrua } from '../Models/alies-grua.model';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AliesGruaFilter } from '../Models/alies-grua-filter';
import { AliesGruaFilterService } from './alies-grua-filter-service';
import { HttpParams } from '@angular/common/http';
import { DipositGruaFilterService } from './diposit-grua-filter-service';
import { DesplacatsGruaFilter } from '../Models/desplacats-filter';
import { DesplacatsGruaFilterService } from './desplacats-grua-filter-service';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class DesplacatsGruaService {


  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}


  getImages(contenedor: string, numDenuncia: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      const url = config.urls['apiUrl'] + api.endpoints['urlGetImages'].replace('{somId}', numDenuncia).replace('{contenedor}', contenedor);

      return this.http.get(url, null);
    }
  }

  generateExcel(filter: DesplacatsGruaFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new DesplacatsGruaFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGruaDesplacatsExcel'],  params );
    }
  }
}
