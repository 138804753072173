import { SortDirection } from '@angular/material';
import { FilterData } from './../../../../shared/models/filter-data';

export class TipoSenalesVerticalesFilter extends FilterData {
    id: string;
    descripcion: string;

    constructor(sortField: string, sortDir: SortDirection) {
        super(sortField, sortDir);
        this.id = '';
        this.descripcion = '';

        this.labels['id'] = this.i18n('Id');
        this.labels['descripcion'] = this.i18n('Descripció');
    }
}
