import {Component, Inject, OnInit, Output, EventEmitter} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { PropostesDesestimarModel } from '../../../models/propostes-desestimar.model';
import { PropostesService } from 'path-propostes-services/propostes.service';
import {ToastrService} from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import {DenunciaFilter} from '../../../../denuncies/models/denuncia.filter';
import {PropostaReject} from '../../../models/proposta-reject.model';

@Component({
  selector: 'app-propostes-desestimar',
  templateUrl: './propostes-desestimar.component.html',
  styleUrls: ['./propostes-desestimar.component.scss']
})
export class PropostesDesestimarComponent implements OnInit {
  subjects: PropostesDesestimarModel[] = [];
  description: string;
  numDenuncia: number;
  secure_id: string;
  currentMotiuDesestimar: number;

  @Output() update = new EventEmitter<any>();

  constructor(
    private i18n: I18n,
    public toastr: ToastrService,
    private propostesService: PropostesService,
    public thisDialogRef: MatDialogRef<PropostesDesestimarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    data.subjects.forEach(element => {
      this.subjects.push(element);
    });

    this.numDenuncia = data.numProposta;
    this.secure_id = data.secure_id;
  }

  ngOnInit() {

  }

  rejectProposta() {
    if (this.numDenuncia) {

      if (this.currentMotiuDesestimar !== undefined) {

        const propostaData: PropostaReject = {
          ID_MOTIU_DESESTIMAR_PROPOSTA: this.currentMotiuDesestimar,
          TEXT_DESESTIMAR_PROPOSTA: this.description,
          propostes: []
        };

        this.propostesService.rejectProposta(this.secure_id, propostaData).subscribe(response => {
          this.toastr.info(this.i18n('La proposta {{numVar}} ha estat desestimada', {numVar: this.numDenuncia} ), this.i18n('Proposta desestimada'));

          this.update.emit(true);
          this.thisDialogRef.close(true);
        });
      } else {
        this.toastr.warning(this.i18n('Cal seleccionar un motiu per desestimar!'));
      }
    } else {
      this.toastr.warning(this.i18n('No hi ha nùmero de denúncia definit!'));
    }
  }
}
