<div class="form-group">
  <mat-form-field>
    <mat-label>{{this.label}}</mat-label>
    <textarea autosize #inputEdit matInput #autosize="cdkTextareaAutosize" cdkTextareaAutosize [type]="this.type" [placeholder]="this.label" [(ngModel)]="this.value"
      [disabled]="this.disabled" [required]="this.isRequired()" [ngClass]="!this.valid()?'ng-invalid':''"></textarea>
  </mat-form-field>
  <div [hidden]="this.valid()" class="text-danger alert-field-errors">
    <div [hidden]="!this.errorRequired()" i18n>Aquest camp és obligatori!</div>
    <div [hidden]="!this.errorNumber()" i18n>Aquest camp ha de ser un número vàlid!</div>
    <div [hidden]="!this.errorLength()"><span i18n>La longitud màxima és </span>{{ this.maxLength}}!</div>
    <div [hidden]="!this.errorMatricula()"><span i18n>La matrícula només pot contenir números i lletres sense espais i
        símbols!</span></div>
  </div>
</div>