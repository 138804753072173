import { NotificationService } from 'path-shared/services/notification/notification.service';
import { Injectable, EventEmitter } from '@angular/core';
import { AlarmaGps } from 'app/sections/alarmes/models/alarma-gps.model';
import { Alarma } from 'app/sections/alarmes/models/alarma.model';
import { NotificationType } from 'path-shared/services/notification/notification-type';
import { Tracking } from 'app/sections/utilitats/tracking/models/tracking.model';
import { DenunciaResumenDto } from 'app/sections/denuncies/models/denuncia-resumen-dto';
import { PropostaResumenDto } from 'app/sections/grua/Models/proposta-resumen-dto';
import { CustomDataMarker } from '../models/custom-data-marker';
import { Observer } from 'rxjs';
import { CustomDataPoligon } from '../models/custom-poligon';

export class LiveUpdatesService {

    private static instance: LiveUpdatesService;

    onAlarmasSistemaChange: EventEmitter<Alarma[]> = new EventEmitter();
    onAlarmasSistemaCurrentSubscription: any;
    markersAlarmasSistema: Array<CustomDataMarker> = new Array();

    onAlarmasSmartphoneChange: EventEmitter<AlarmaGps[]> = new EventEmitter();
    onAlarmasSmartphoneCurrentSubscription: any;
    markersAlarmasSmartphone: Array<CustomDataMarker> = new Array();

    onTrackingPdaChange: EventEmitter<Tracking[]> = new EventEmitter();
    onTrackingPdaCurrentSubscription: any;
    markersTrackingPda: Array<CustomDataMarker> = new Array();

    onTrackingGruaChange: EventEmitter<Tracking[]> = new EventEmitter();
    onTrackingGruaCurrentSubscription: any;
    markersTrackingGrua: Array<CustomDataMarker> = new Array();

    onDenunciasPoliciaChange: EventEmitter<DenunciaResumenDto[]> = new EventEmitter();
    onDenunciasPoliciaCurrentSubscription: any;
    markersDenunciasPolicia: Array<CustomDataMarker> = new Array();

    onDenunciasZonaAzulChange: EventEmitter<DenunciaResumenDto[]> = new EventEmitter();
    onDenunciasZonaAzulCurrentSubscription: any;
    markersDenunciasZonaAzul: Array<CustomDataMarker> = new Array();

    onPropuestasGruaRetiradasChange: EventEmitter<PropostaResumenDto[]> = new EventEmitter();
    onPropuestasGruaRetiradasCurrentSubscription: any;
    markersPropuestasGruaRetiradas: Array<CustomDataMarker> = new Array();

    onPropuestasGruaRechazadasChange: EventEmitter<PropostaResumenDto[]> = new EventEmitter();
    onPropuestasGruaRechazadasCurrentSubscription: any;
    markersPropuestasGruaRechazadas: Array<CustomDataMarker> = new Array();

    onPropuestasGruaPendientesChange: EventEmitter<PropostaResumenDto[]> = new EventEmitter();
    onPropuestasGruaPendientesCurrentSubscription: any;
    markersPropuestasGruaPendientes: Array<CustomDataMarker> = new Array();

    onPropuestasGruaAceptadasChange: EventEmitter<PropostaResumenDto[]> = new EventEmitter();
    onPropuestasGruaAceptadasCurrentSubscription: any;
    markersPropuestasGruaAceptadas: Array<CustomDataMarker> = new Array();

    poligonsOcupacion: Array<CustomDataPoligon> = new Array();


    constructor(private not: NotificationService) { }

    public static getInstance(not: NotificationService): LiveUpdatesService {
        if (this.instance === null || this.instance === undefined) {
            this.instance = new LiveUpdatesService(not);
        }
        return this.instance;
    }

    //#region ALARMAS SISTEMA

    subscribeToAlarmasSistema(x: void) {
        this.markersAlarmasSistema = new Array();
        this.not.subscribeToEvent(NotificationType.AlarmesSistemaPendentsMoreInfo, this.onAlarmasSistemaChange);
        this.onAlarmasSistemaCurrentSubscription = this.onAlarmasSistemaChange.subscribe(x);
        return this.onAlarmasSistemaCurrentSubscription;
    }

    unsubscribeAlarmasSistema() {
        this.not.unsubscribeToEvent(NotificationType.AlarmesSistemaPendentsMoreInfo);
        this.onAlarmasSistemaChange.observers.forEach((x: any) => {
            x.unsubscribe();
        });
        // this.onAlarmasSistemaCurrentSubscription.unsubscribe();
        this.markersAlarmasSistema = new Array();
    }

    //#endregion

    //#region ALARMAS SMARTPHONE
    subscribeToAlarmasSmartphones(x: void) {
        this.markersAlarmasSmartphone = new Array();
        this.not.subscribeToEvent(NotificationType.AlarmesSmartphonesPendentsMoreInfo, this.onAlarmasSmartphoneChange);
        this.onAlarmasSmartphoneCurrentSubscription = this.onAlarmasSmartphoneChange.subscribe(x);
        return this.onAlarmasSmartphoneCurrentSubscription;
    }

    unsubscribeAlarmasSmartphones() {
        this.not.unsubscribeToEvent(NotificationType.AlarmesSmartphonesPendentsMoreInfo);
        this.onAlarmasSmartphoneChange.observers.forEach((x: any) => {
            x.unsubscribe();
        });
        // this.onAlarmasSmartphoneCurrentSubscription.unsubscribe();
        this.markersAlarmasSmartphone = new Array();
    }

    //#endregion

    //#region TRACKING PDA

    subscribeToTrackingPda(x: void) {
        this.markersTrackingPda = new Array();
        this.not.subscribeToEvent(NotificationType.TrackingPda, this.onTrackingPdaChange);
        this.onTrackingPdaCurrentSubscription = this.onTrackingPdaChange.subscribe(x);
        return this.onTrackingPdaCurrentSubscription;
    }

    unsubscribeTrackingPda() {
        this.not.unsubscribeToEvent(NotificationType.TrackingPda);
        this.onTrackingPdaChange.observers.forEach((x: any) => {
            x.unsubscribe();
        });
        // this.onTrackingPdaCurrentSubscription.unsubscribe();
        this.markersTrackingPda = new Array();
    }

    //#endregion


    //#region TRACKIGN GRUA

    subscribeToTrackingGrua(x: void) {
        this.markersTrackingGrua = new Array();
        this.not.subscribeToEvent(NotificationType.TrackingGrua, this.onTrackingGruaChange);
        this.onTrackingGruaCurrentSubscription = this.onTrackingGruaChange.subscribe(x);
        return this.onTrackingGruaCurrentSubscription;
    }

    unsubscribeTrackingGrua() {
        this.not.unsubscribeToEvent(NotificationType.TrackingGrua);
        this.onTrackingGruaChange.observers.forEach((x: any) => {
            x.unsubscribe();
        });
        // this.onTrackingGruaCurrentSubscription.unsubscribe();
        this.markersTrackingGrua = new Array();
    }

    //#endregion

    //#region DENUNCIAS POLICIA

    subscribeToDenunciasPolicia(x: void) {
        this.markersDenunciasPolicia = new Array();
        this.not.subscribeToEvent(NotificationType.DenunciasPolicia, this.onDenunciasPoliciaChange);
        this.onDenunciasPoliciaCurrentSubscription = this.onDenunciasPoliciaChange.subscribe(x);
        return this.onDenunciasPoliciaCurrentSubscription;
    }

    unsubscribeDenunciasPolicia() {
        this.not.unsubscribeToEvent(NotificationType.DenunciasPolicia);
        this.onDenunciasPoliciaChange.observers.forEach((x: any) => {
            x.unsubscribe();
        });
        // this.onDenunciasPoliciaCurrentSubscription.unsubscribe();
        this.markersDenunciasPolicia = new Array();
    }

    //#endregion

    //#region DENUNCIAS ZONA AZUL

    subscribeToDenunciasZonaAzul(x: void) {
        this.markersDenunciasZonaAzul = new Array();
        this.not.subscribeToEvent(NotificationType.DenunciasZonaAzul, this.onDenunciasZonaAzulChange);
        this.onDenunciasZonaAzulCurrentSubscription = this.onDenunciasZonaAzulChange.subscribe(x);
        return this.onDenunciasZonaAzulCurrentSubscription;
    }

    unsubscribeDenunciasZonaAzul() {
        this.not.unsubscribeToEvent(NotificationType.DenunciasZonaAzul);
        this.onDenunciasZonaAzulChange.observers.forEach((x: any) => {
            x.unsubscribe();
        });
        // this.onDenunciasZonaAzulCurrentSubscription.unsubscribe();
        this.markersDenunciasZonaAzul = new Array();
    }

    //#endregion

    //#region PROPUESTAS GRUA ACEPTADAS

    subscribeToPropuestasGruaAceptadas(x: void) {
        this.markersPropuestasGruaAceptadas = new Array();
        this.not.subscribeToEvent(NotificationType.PropuestasGruaAceptadas, this.onPropuestasGruaAceptadasChange);
        this.onPropuestasGruaAceptadasCurrentSubscription = this.onPropuestasGruaAceptadasChange.subscribe(x);
        return this.onPropuestasGruaAceptadasCurrentSubscription;
    }

    unsubscribePropuestasGruaAceptadas() {
        this.not.unsubscribeToEvent(NotificationType.PropuestasGruaAceptadas);
        this.onPropuestasGruaAceptadasChange.observers.forEach((x: any) => {
            x.unsubscribe();
        });
        // this.onPropuestasGruaAceptadasCurrentSubscription.unsubscribe();
        this.markersPropuestasGruaAceptadas = new Array();
    }

    //#endregion

    //#region PROPUESTAS GRUA PENDIENTES

    subscribeToPropuestasGruaPendientes(x: void) {
        this.markersPropuestasGruaPendientes = new Array();
        this.not.subscribeToEvent(NotificationType.PropuestasGruaPendientes, this.onPropuestasGruaPendientesChange);
        this.onPropuestasGruaPendientesCurrentSubscription = this.onPropuestasGruaPendientesChange.subscribe(x);
        return this.onPropuestasGruaPendientesCurrentSubscription;
    }

    unsubscribePropuestasGruaPendientes() {
        this.not.unsubscribeToEvent(NotificationType.PropuestasGruaPendientes);
        this.onPropuestasGruaPendientesChange.observers.forEach((x: any) => {
            x.unsubscribe();
        });
        // this.onPropuestasGruaPendientesCurrentSubscription.unsubscribe();
        this.markersPropuestasGruaPendientes = new Array();
    }

    //#endregion

    //#region PROPUESTAS GRUA RECHAZADAS

    subscribeToPropuestasGruaRechazadas(x: void) {
        this.markersPropuestasGruaRechazadas = new Array();
        this.not.subscribeToEvent(NotificationType.PropuestasGruaRechazadas, this.onPropuestasGruaRechazadasChange);
        this.onPropuestasGruaRechazadasCurrentSubscription = this.onPropuestasGruaRechazadasChange.subscribe(x);
        return this.onPropuestasGruaRechazadasCurrentSubscription;
    }

    unsubscribePropuestasGruaRechazadas() {
        this.not.unsubscribeToEvent(NotificationType.PropuestasGruaRechazadas);
        this.onPropuestasGruaRechazadasChange.observers.forEach((x: any) => {
            x.unsubscribe();
        });
        // this.onPropuestasGruaRechazadasCurrentSubscription.unsubscribe();
        this.markersPropuestasGruaRechazadas = new Array();
    }

    //#endregion

    //#region PROPUESTAS GRUA RETIRADAS

    subscribeToPropuestasGruaRetiradas(x: void) {
        this.markersPropuestasGruaRetiradas = new Array();
        this.not.subscribeToEvent(NotificationType.PropuestasGruaRetiradas, this.onPropuestasGruaRetiradasChange);
        this.onPropuestasGruaRetiradasCurrentSubscription = this.onPropuestasGruaRetiradasChange.subscribe(x);
        return this.onPropuestasGruaRetiradasCurrentSubscription;
    }

    unsubscribePropuestasGruaRetiradas() {
        this.not.unsubscribeToEvent(NotificationType.PropuestasGruaRetiradas);
        this.onPropuestasGruaRetiradasChange.observers.forEach((x: any) => {
            x.unsubscribe();
        });
        // this.onPropuestasGruaRetiradasCurrentSubscription.unsubscribe();
        this.markersPropuestasGruaRetiradas = new Array();
    }

    //#endregion

    //#region  OCUPACION

    unsubscribeOcupacion() {
        this.poligonsOcupacion = new Array();
    }

    //#endregion

}
