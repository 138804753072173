import { Builder } from 'path-shared/models/builder';
import { OnInit } from '@angular/core';
var TextoAvisoEditorComponent = /** @class */ (function () {
    function TextoAvisoEditorComponent() {
        this.currentTexto = Builder.newTextoAviso();
        this.currentEditing = false;
        this.idiomaDictionary = new Array();
    }
    Object.defineProperty(TextoAvisoEditorComponent.prototype, "currentEditing", {
        get: function () {
            return this._currentEditing;
        },
        set: function (val) {
            this._currentEditing = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextoAvisoEditorComponent.prototype, "currentTexto", {
        get: function () {
            return this._currentTexto;
        },
        set: function (val) {
            if (val !== undefined && val !== null) {
                this._currentTexto = val;
            }
            else {
                this.currentTexto = Builder.newTextoAviso();
            }
        },
        enumerable: true,
        configurable: true
    });
    TextoAvisoEditorComponent.prototype.ngOnInit = function () { };
    TextoAvisoEditorComponent.prototype.textoFormatadoChanged = function (newText) {
        this.currentTexto.htmlValue = newText;
    };
    TextoAvisoEditorComponent.prototype.siglaSeleccionada = function (sigla) {
        this.currentTexto.idioma = sigla;
    };
    TextoAvisoEditorComponent.prototype.changeLogInBlock = function (loginLock) {
        this.currentTexto.permiteLogIn = !loginLock;
    };
    return TextoAvisoEditorComponent;
}());
export { TextoAvisoEditorComponent };
