/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/notificacions.component.ngfactory";
import * as i2 from "../components/notificacions.component";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../../shared/services/authorization/authorization-service";
import * as i5 from "./notificacions-page.component";
var styles_NotificacionsPageComponent = [];
var RenderType_NotificacionsPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificacionsPageComponent, data: {} });
export { RenderType_NotificacionsPageComponent as RenderType_NotificacionsPageComponent };
export function View_NotificacionsPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-notificacions", [], null, null, null, i1.View_NotificacionsComponent_0, i1.RenderType_NotificacionsComponent)), i0.ɵdid(1, 4308992, null, 0, i2.NotificacionsComponent, [i3.MatDialog, i4.AuthorizationService, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_NotificacionsPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-notificacions-page", [], null, null, null, View_NotificacionsPageComponent_0, RenderType_NotificacionsPageComponent)), i0.ɵdid(1, 114688, null, 0, i5.NotificacionsPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificacionsPageComponentNgFactory = i0.ɵccf("app-notificacions-page", i5.NotificacionsPageComponent, View_NotificacionsPageComponent_Host_0, {}, {}, []);
export { NotificacionsPageComponentNgFactory as NotificacionsPageComponentNgFactory };
