import { AfterViewInit, Component, OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { GestioResidentsCites } from '../../models/gestio-residents-cites.model';
import { GestioResidentsService } from '../../services/gestio-residents.service';


@Component({
  selector: 'app-cites-residentes',
  templateUrl: './cites-residents.component.html',
  styleUrls: ['./cites-residents.component.scss']
})


export class CitesResidentsComponent implements OnInit, AfterViewInit {

  gestioResidentsCites: GestioResidentsCites = null; // lo empiezo a null para controlar un error del html
  public dateSelected = new Date();

  constructor(
    private i18n: I18n,
    public auth: AuthorizationService,
    private not: NotificationService,
    private gestioResidentsService: GestioResidentsService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.not.titleChange(this.i18n('Gestión residentes'));

  }

  load(): void {
    this.gestioResidentsService.getCitas(this.dateSelected).subscribe((res: GestioResidentsCites) => {
      this.gestioResidentsCites = res;
    });
  }



}
