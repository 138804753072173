/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../dialog/dialog-draggable.directive";
import * as i4 from "@angular/common";
import * as i5 from "./dialog-header.component";
var styles_DialogHeaderComponent = [i0.styles];
var RenderType_DialogHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogHeaderComponent, data: {} });
export { RenderType_DialogHeaderComponent as RenderType_DialogHeaderComponent };
function View_DialogHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconClass; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "fa ", _co.icon, ""); _ck(_v, 1, 0, currVal_1); }); }
function View_DialogHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "times modal-close"], ["mat-dialog-close", ""], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).dialogRef.close(i1.ɵnov(_v, 1).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, null, [" \u00D7 "]))], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).ariaLabel; _ck(_v, 0, 0, currVal_0); }); }
export function View_DialogHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["appMatDialogDraggable", ""], ["class", "modal-title"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i3.DialogDraggableDirective, [i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogHeaderComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h5", [["class", "text-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogHeaderComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.icon.length > 0); _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.isCloseButton; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.label; _ck(_v, 5, 0, currVal_1); }); }
export function View_DialogHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [], null, null, null, View_DialogHeaderComponent_0, RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i5.DialogHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogHeaderComponentNgFactory = i1.ɵccf("app-dialog-header", i5.DialogHeaderComponent, View_DialogHeaderComponent_Host_0, { label: "label", icon: "icon", iconClass: "iconClass", isCloseButton: "isCloseButton" }, {}, []);
export { DialogHeaderComponentNgFactory as DialogHeaderComponentNgFactory };
