import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class InfraccioFilter extends FilterData {
    id: number;
    legislacio: string;
    article: string;
    apartat: string;
    opcio: string;
    gravetat: string;
    descCurta: string;
    import: number;
    fins: string;
    punts: string;
    obsoleta: boolean;
    inici: Date;
    final: Date;
    numFotosPda: number;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.id = null;
    this.legislacio = '';
    this.article = '';
    this.apartat = '';
    this.opcio = '';
    this.gravetat = '';
    this.descCurta = '';
    this.import = null;
    this.fins = '';
    this.punts = '';
    this.obsoleta = null; 
    this.inici = null;
    this.final = null;
    this.numFotosPda = null;

    this.labels['id'] = this.i18n('Id');
    this.labels['legislacio'] = this.i18n('Legislació');
    this.labels['article'] = this.i18n('Article');
    this.labels['apartat'] = this.i18n('Apartat');
    this.labels['opcio'] = this.i18n('Opció');
    this.labels['gravetat'] = this.i18n('Gravetat');
    this.labels['descCurta'] = this.i18n('Descripció curta');
    this.labels['import'] = this.i18n('Import');
    this.labels['fins'] = this.i18n('Fins');
    this.labels['punts'] = this.i18n('Punts');
    this.labels['obsoleta'] = this.i18n('Obsoleta');
    this.labels['inici'] = this.i18n('Inici');
    this.labels['final'] = this.i18n('Final');
    this.labels['numFotosPda'] = this.i18n('Número fotos PDA');

  }
}
