/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wait-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../dialog/dialog-header/dialog-header.component";
import * as i4 from "../../dialog/dialog-body/dialog-body.component.ngfactory";
import * as i5 from "../../dialog/dialog-body/dialog-body.component";
import * as i6 from "@angular/common";
import * as i7 from "../../dialog/dialog-footer/dialog-footer.component.ngfactory";
import * as i8 from "../../dialog/dialog-footer/dialog-footer.component";
import * as i9 from "@angular/material/dialog";
import * as i10 from "./wait-dialog.component";
import * as i11 from "@ngx-translate/i18n-polyfill";
import * as i12 from "ngx-toastr";
var styles_WaitDialogComponent = [i0.styles];
var RenderType_WaitDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WaitDialogComponent, data: {} });
export { RenderType_WaitDialogComponent as RenderType_WaitDialogComponent };
function View_WaitDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-spin"]], null, null, null, null, null))], null, null); }
export function View_WaitDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 8, "app-dialog-body", [], null, null, null, i4.View_DialogBodyComponent_0, i4.RenderType_DialogBodyComponent)), i1.ɵdid(3, 114688, null, 0, i5.DialogBodyComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "col-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WaitDialogComponent_1)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "app-dialog-footer", [], null, null, null, i7.View_DialogFooterComponent_0, i7.RenderType_DialogFooterComponent)), i1.ɵdid(12, 114688, null, 0, i8.DialogFooterComponent, [], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "button", [["class", "btn btn-secondary"], ["mat-dialog-close", ""], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).dialogRef.close(i1.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 606208, null, 0, i9.MatDialogClose, [[2, i9.MatDialogRef], i1.ElementRef, i9.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(15, null, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.title; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); var currVal_2 = _co.options.isSpinner; _ck(_v, 8, 0, currVal_2); _ck(_v, 12, 0); var currVal_5 = ""; _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.options.icon, " fa-2x"); _ck(_v, 6, 0, currVal_1); var currVal_3 = _co.options.text; _ck(_v, 10, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 14).ariaLabel; _ck(_v, 13, 0, currVal_4); var currVal_6 = _co.options.buttonText; _ck(_v, 15, 0, currVal_6); }); }
export function View_WaitDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-wait-dialog", [], null, null, null, View_WaitDialogComponent_0, RenderType_WaitDialogComponent)), i1.ɵdid(1, 114688, null, 0, i10.WaitDialogComponent, [i9.MatDialogRef, i11.I18n, i12.ToastrService, i9.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WaitDialogComponentNgFactory = i1.ɵccf("app-wait-dialog", i10.WaitDialogComponent, View_WaitDialogComponent_Host_0, {}, {}, []);
export { WaitDialogComponentNgFactory as WaitDialogComponentNgFactory };
