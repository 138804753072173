import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { Component, OnInit, EventEmitter, Input, Output, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { Dictionary } from 'path-shared/models/dictionary';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TextoAviso } from '../../model/texto-aviso';
import { TextoAvisoFilter } from '../../model/texto-aviso.filter';
import { TextoAvisoFilterService } from '../../services/texto-aviso.filter.service';
import { TextoAvisoService } from '../../services/texto-aviso.service';

@Component({
  selector: 'app-texto-aviso-table',
  templateUrl: './texto-aviso-table.component.html',
  styleUrls: ['./texto-aviso-table.component.scss']
})
export class TextoAvisoTableComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() updatedFilterData: TextoAvisoFilter;
  @Input() selectButton: boolean;
  @Input() siglasDictionary = new Array<Dictionary>();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterUpdated = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: CustomDataSource<TextoAviso, TextoAvisoFilter>;
  filter: TextoAvisoFilter = new TextoAvisoFilter('titulo', 'asc');

  currentSelect: string;
  displayedItems = [];
  displayedColumns = ['select', 'titulo', 'fechaInicio', 'fechaFin', 'idioma', 'permiteLogIn'];

  constructor(
    private textoAvisoService: TextoAvisoService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n
  ) { }


  /* #region COMPONENT LIFECYCLE */

  ngOnInit(): void {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<TextoAviso, TextoAvisoFilter>(
      this.paginator,
      new TextoAvisoFilterService(),
      'urlGetTextosAviso',
      'urlGetTextosAvisoCount');

    this.dataSource.subject.subscribe(data => {
      this.displayedItems = this.textoAvisoService.displayedItems;
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('texto-aviso-filter', this.filter); }
      }
    });
    if (!this.selectButton) {
      this.filterService.loadFilter('texto-aviso-filter', this.filter);
    }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
      this.filterUpdated.emit(true);
    });
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
    this.load();
  }

  ngOnChanges(): void {
    if (this.updatedFilterData !== undefined) {
      this.update();
    }
  }

  /* #endregion */

  load(): void {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  update(): void {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  selectRow(row: any): void {
    if (row.secureId !== this.currentSelect) {
      // Deseleccionamos los otros elementos. Solo podemos tener uno activado.
      this.dataSource.getData().forEach(element => {
        if (element.secureId !== row.secureId) {
          element.selected = false;
        }
      });
      this.currentSelect = row.secureId;
      this.itemSelected.next(Object.assign({}, row));
    } else {
      this.currentSelect = undefined;
      this.itemSelected.next(undefined);
    }
  }

  resetSelection(): void {
    this.dataSource.getData().forEach(element => {
      element.selected = false;
    });
    this.currentSelect = undefined;
  }

  getIdiomaFromSigla(sigla: string): string {
    const idioma = this.siglasDictionary.find(d => d.key === sigla);
    return idioma !== undefined ? idioma.value : '';
  }

}
