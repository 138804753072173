import { BonosResidentesFilterService } from './bonos-residentes-filter.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../../shared/services/authentication/auth-guard.service";
var defaultSortParam = 'data';
var defaultSortDirection = 'asc';
var GestioResidentsService = /** @class */ (function () {
    function GestioResidentsService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
        this.displayedItems = [];
        // this.displayedItems['checkedValues'] = 0;
        // this.displayedItems['checkedItems'] = Array();
    }
    GestioResidentsService.prototype.getCitas = function (date) {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGestioResidentsCites'], new HttpParams().append('date', date.toJSON()));
        }
    };
    GestioResidentsService.prototype.getBonosFilterInfo = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGestioResidentsBonosFilterInfo'], null);
        }
    };
    GestioResidentsService.prototype.comprovarPadro = function (matricula, dni) {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGestioResidentsComprovarPadro'], new HttpParams().
                append('matricula', matricula).
                append('dni', dni));
        }
    };
    GestioResidentsService.prototype.generateExcel = function (filter, sortDir, sortField) {
        var start = 0;
        var pageSize = 1000;
        sortField = sortField === undefined ? defaultSortParam : sortField;
        sortDir = sortDir === '' ? defaultSortDirection : sortDir;
        var params = new BonosResidentesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);
        if (this.authGuard.canActivate()) {
            return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlBonosResidentesExcel'], params);
        }
    };
    GestioResidentsService.ngInjectableDef = i0.defineInjectable({ factory: function GestioResidentsService_Factory() { return new GestioResidentsService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: GestioResidentsService, providedIn: "root" });
    return GestioResidentsService;
}());
export { GestioResidentsService };
