import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DenunciesService } from '../../../../services/denuncies.service';
import { DenunciaDetailModel } from '../../../../models/denuncia-detail.model';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { DenunciaCobrarModel } from 'app/sections/denuncies/models/denuncia-cobrar.model';
import { Builder } from 'path-shared/models/builder';
import { BehaviorSubject } from 'rxjs';
import { Dictionary } from 'path-shared/models/dictionary';
import { FormService } from 'path-shared/services/form/form.service';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';
import { CFG_FORMASPAGO } from 'path-models/CFG_FORMASPAGO';
import { CFG_PAGO_PORCENTAJE_AUMENTO } from 'path-models/CFG_PAGO_PORCENTAJE_AUMENTO';
import { DenunciaPagamentParcialComponent } from '../../../denuncia-pagament-parcial/denuncia-pagament-parcial.component';

@Component({
  selector: 'app-denuncia-cobrar',
  templateUrl: './denuncia-cobrar.component.html'
})
export class DenunciaCobrarComponent implements OnInit {

  denunciaDetail: DenunciaDetailModel;
  denunciaCobrar: DenunciaCobrarModel;
  cerrarPagosParciales: boolean;

  formasPagoDictionary: BehaviorSubject<Array<Dictionary>>;
  importeAumentoPorcentajes: BehaviorSubject<Array<Dictionary>>;
  importeInteresesPorcentajes: BehaviorSubject<Array<Dictionary>>;
  dataMax = new Date();
  denunciaPagamentParcialRef: MatDialogRef<DenunciaPagamentParcialComponent, any>;

  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild('comboFormaPago') comboFormaPago: ComboBoxInputComponent;
  @ViewChild('comboImporteAumentoPorcentaje') comboImporteAumentoPorcentaje: ComboBoxInputComponent;

  constructor(
    public auth: AuthorizationService,
    public thisDialogRef: MatDialogRef<DenunciaCobrarComponent>,
    private denunciesService: DenunciesService,
    private toastr: ToastrService,
    private i18n: I18n,
    private formService: FormService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    this.denunciaDetail = modalData.data;
    this.cerrarPagosParciales = modalData.cerrarPagosParciales;

    const avui = new Date();
    this.denunciaCobrar = Builder.newDenunciaCobrar();
    this.denunciaCobrar.fecha = avui;
    this.denunciaCobrar.expedient = this.denunciaDetail.expedient;

    this.formasPagoDictionary = new BehaviorSubject(new Array<Dictionary>());
    this.importeAumentoPorcentajes = new BehaviorSubject(new Array<Dictionary>());
    this.importeInteresesPorcentajes = new BehaviorSubject(new Array<Dictionary>());
  }

  ngOnInit() {
    this.loadFormasPago();
    this.loadImporteAumentoPorcentajes();
    this.loadImporteInteresesPorcentajes();

    this.calcularImporteBase();
    this.calcularImporteTotal();

    if (this.denunciaDetail.importeCobrado > 0) {
      this.getPagos();
    }
  }

  Abs(number) {
    return number > 0 ? number : 0;
  }
  cancel() {
    this.thisDialogRef.close(false);
  }

  executeOperation() {
    const dto = {
      'FechaPago': this.denunciaCobrar.fecha,
      'FormaPago': this.denunciaCobrar.formaPagoId,
      'Importe': this.denunciaCobrar.importeCobrar,
      'Motivo': '',
      'importeInteresesPorcentaje': this.denunciaCobrar.importeInteresesPorcentaje,
      'importeAumentoPorcentaje': this.denunciaCobrar.importeAumentoPorcentaje
    };
    this.denunciesService.createOpearcio(this.denunciaDetail.secure_id_denuncia, 'COBRA_EXP', dto).subscribe(() => {
      this.toastr.success(this.i18n('Denuncia cobrada correctament'));
      this.thisDialogRef.close(true);
    });
  }

  save() {
    // Sergio 11/11/2021 quitamos validacion no se pasa del importe por orden de jero: && this.denunciaCobrar.importeCobrar <= this.denunciaCobrar.importeMaximo
    if (this.formService.allFieldsValid(this.mainForm) ) {
      if (this.TancarPagamentParcial()) {
        this.OpenModalTancarPagamentParcial();
      } else {
        // this.denunciesService.setDenunciaCobrar(this.denunciaCobrar).subscribe(() => {
        //   this.toastr.info(this.i18n('Denuncia cobrada correctament'));
        //   this.thisDialogRef.close(true);
        // });
        this.executeOperation();
      }
    } else {
      this.comboFormaPago.markAsTouched();
      this.comboImporteAumentoPorcentaje.markAsTouched();

      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  TancarPagamentParcial(): boolean {
    let tancarPagamentParcial: boolean;

    if (this.denunciaDetail.fechaCobro === null && this.cerrarPagosParciales
      && this.denunciaCobrar.importeBase < this.denunciaCobrar.importeMaximo) {
      tancarPagamentParcial = true;
    } else {
      tancarPagamentParcial = false;
    }

    return tancarPagamentParcial;
  }

  OpenModalTancarPagamentParcial() {
    this.denunciaPagamentParcialRef = this.dialog.open(DenunciaPagamentParcialComponent,
    {
        height: '30%',
        width: '30%',
        closeOnNavigation: true,
        autoFocus: true,
        data: {
            'data': this.denunciaCobrar
        },
    });

    this.denunciaPagamentParcialRef.afterClosed().subscribe((denunciaCobrar: DenunciaCobrarModel) => {
      if (denunciaCobrar !== undefined && denunciaCobrar !== null) {
        // this.denunciesService.setDenunciaCobrar(this.denunciaCobrar).subscribe(() => {
        //   this.toastr.info(this.i18n('Denuncia cobrada correctament'));
        //   this.thisDialogRef.close(true);
        // });
        this.executeOperation();
      }
    });
  }

  loadFormasPago() {
    const formasPagoAux = new Array<Dictionary>();

    this.denunciesService.getFormasPago().subscribe((formasPago: CFG_FORMASPAGO[]) => {
      formasPago.forEach(formaPago => {
        formasPagoAux.push(new Dictionary(formaPago.codi, formaPago.descripcio));
      });

      this.formasPagoDictionary.next(formasPagoAux);
    });
  }

  loadImporteAumentoPorcentajes() {
    const importeAumentoPorcentajesAux = new Array<Dictionary>();

    this.denunciesService.getDenunciesPagoPorcentajeAumento().subscribe((porcentajes: CFG_PAGO_PORCENTAJE_AUMENTO[]) => {
      porcentajes.forEach(porcentaje => {
        importeAumentoPorcentajesAux.push(new Dictionary(porcentaje.percentatge, porcentaje.descripcio));
      });

      this.importeAumentoPorcentajes.next(importeAumentoPorcentajesAux);

      this.comboImporteAumentoPorcentaje.setSelect(this.denunciaCobrar.importeAumentoPorcentaje);
    });
  }

  loadImporteInteresesPorcentajes() {
    // this.importeInteresesPorcentajes.next();
  }

  formaPagoSeleccionado(formaPagoId: number) {
    this.denunciaCobrar.formaPagoId = formaPagoId;
  }

  /*
    Si la data de descompte és igual o superior a avui, aplicarem l'import amb descompte.
    Altrament, aplicarem l'import sense descompte.
  */
  calcularImporteBase() {
    const dataDescompte = this.stringToDate(this.denunciaDetail.dataDescompte);
    dataDescompte.setHours(0, 0, 0, 0);

    if (this.denunciaCobrar.fecha <= dataDescompte) {
      this.denunciaCobrar.importeBase = this.denunciaDetail.importDescompte;
      this.denunciaCobrar.importeMaximo = this.denunciaDetail.importDescompte - this.denunciaDetail.importeCobrado;
    } else {
      this.denunciaCobrar.importeBase = this.denunciaDetail.import;
      this.denunciaCobrar.importeMaximo = this.denunciaDetail.import - this.denunciaDetail.importeCobrado;

    }
    this.denunciaCobrar.importeCobrar = this.denunciaCobrar.importeMaximo;

  }

  calcularImporteTotal() {
    this.denunciaCobrar.importeTotal = this.denunciaCobrar.importeBase
                                      + this.denunciaCobrar.importeAumento
                                      + this.denunciaCobrar.importeIntereses
                                      + this.denunciaCobrar.importeAnadido;
    this.denunciaCobrar.importeCobrar = this.denunciaCobrar.importeCobrar < 0 ? this.denunciaCobrar.importeCobrar = 0 : this.denunciaCobrar.importeCobrar;
  }

  recalcularImportes() {
    this.calcularImporteBase();
    this.calcularImporteTotal();
  }

  aumentoPorcentajeChange(porcentaje: any) {
    this.denunciaCobrar.importeAumentoPorcentaje = porcentaje;
    this.denunciaCobrar.importeAumento = this.denunciaCobrar.importeBase * (porcentaje / 100);
    this.calcularImporteTotal();
  }

  getPagos() {
    this.denunciesService.getCobros(this.denunciaCobrar.expedient).subscribe(res => {
      this.aumentoPorcentajeChange(res[0].Aumento);
    });
  }

  //#region SUPPORT METHODS

  stringToDate (value) {
    if (!this.isDate (value)) {
      value = new Date(value);
    }
    return value;
  }

  isDate (value) {
    return value instanceof Date;
  }

  //#endregion

}
