import { ZonesAparcareService } from './../../../tiquets/zones-aparcare/services/zones-aparcare.service';
import { InstitucionsService } from 'path-shared/services/institucions/institucions.service';
import { FiltroTiempoEstadisticas } from './../../models/filtro-tiempo.model';
import { FiltroAparcareTipo, FiltroAparcareTipoValor } from './../../models/filtro-aparcare-tipo.model';
import { OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { EstadistiquesService } from '../../services/estadistiques.service';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { MatDialog } from '@angular/material';
var EstadisitcasAparcareComponent = /** @class */ (function () {
    function EstadisitcasAparcareComponent(estadistiquesService, institutionService, zonesAparcareService, dialog, i18n, auth) {
        var _this = this;
        this.estadistiquesService = estadistiquesService;
        this.institutionService = institutionService;
        this.zonesAparcareService = zonesAparcareService;
        this.dialog = dialog;
        this.i18n = i18n;
        this.auth = auth;
        // config grafico
        this.barChartOptions = {
            responsive: true,
        };
        this.colors = [
            {
                backgroundColor: 'rgba(0, 204, 102, 0.8)'
            },
            {
                backgroundColor: 'rgba(255, 99, 132, 0.8)'
            },
            {
                backgroundColor: 'rgba(54, 162, 235, 0.8)'
            }
        ];
        this.colorsDevol = [
            {
                backgroundColor: 'rgba(255, 99, 132, 0.8)'
            }
        ];
        this.colorsAnul = [
            {
                backgroundColor: 'rgba(54, 162, 235, 0.8)'
            }
        ];
        this.barChartType = 'bar';
        this.dataRecived = false;
        this.dateSelected = new Date();
        this.tipos = [{ label: FiltroAparcareTipo.Ventas }, { label: FiltroAparcareTipo.Anulaciones }, { label: FiltroAparcareTipo.Devoluciones }, { label: FiltroAparcareTipo.Todos }];
        this.tipoSelected = FiltroAparcareTipo.Ventas;
        this.tiposValor = [{ label: FiltroAparcareTipoValor.Importe }, { label: FiltroAparcareTipoValor.Cantidad }];
        this.tipoValorSelected = FiltroAparcareTipoValor.Importe;
        this.showMultiLine = false;
        this.tiposTemporizacion = [{ label: FiltroTiempoEstadisticas.Anual }, { label: FiltroTiempoEstadisticas.Mensual }, { label: FiltroTiempoEstadisticas.Semanal }, { label: FiltroTiempoEstadisticas.Diario }];
        this.tipoTemporizacionSelected = FiltroTiempoEstadisticas.Mensual;
        this.allTipos = FiltroAparcareTipo;
        this.datos = { chartDataSets: [], chartLabels: [], chartColors: [] };
        this.aparcareText = 'Aparcare';
        this.barChartLabels = [];
        this.barChartData = [];
        this.barChartDataMultiLine = [];
        var institution = this.institutionService.getInstitution();
        if (institution !== null && institution !== undefined) {
            if (institution.IdInstitucion === '100105229425938306122') {
                this.aparcareText = 'Apparquem';
            }
            else {
                this.aparcareText = 'Aparcare';
            }
        }
        this.zonesAparcareService.getAllZonas().subscribe(function (x) {
            _this.zonas = x;
        });
    }
    EstadisitcasAparcareComponent.prototype.ngOnInit = function () {
        // this.reload();
    };
    EstadisitcasAparcareComponent.prototype.restartFilter = function () {
        this.zonaSelected = '';
    };
    EstadisitcasAparcareComponent.prototype.reload = function () {
        var _this = this;
        var filter = { tipo: this.tipoSelected, tipoValor: this.tipoValorSelected, fecha: this.dateSelected, tipoTemporizacion: this.tipoTemporizacionSelected, zonaSecureId: null };
        if (this.tipoSelected === FiltroAparcareTipo.Ventas && this.zonaSelected !== undefined && this.zonaSelected !== null && this.zonaSelected !== '') {
            filter.zonaSecureId = this.zonaSelected;
        }
        this.tipoConsultado = this.tipoSelected;
        this.estadistiquesService.getEstadistiquesAparcare(filter).subscribe(function (x) {
            var _a;
            _this.dataRecived = true;
            _this.datos = x;
            _this.barChartData = [];
            Object.assign([], _this.barChartData);
            _this.barChartDataMultiLine = [];
            Object.assign([], _this.barChartDataMultiLine);
            // if ( !this.barChartLabels ) {
            //   this.barChartLabels = [];
            // }
            // this.barChartLabels.length = 0;
            // this.barChartLabels.push(...Object.values(this.datos.chartLabels.barChartLabels));
            if (!_this.barChartLabels) {
                _this.barChartLabels = [];
            }
            _this.barChartLabels.length = 0;
            (_a = _this.barChartLabels).push.apply(_a, Object.values(_this.datos.chartLabels.barChartLabels));
            _this.barChartData.length = 0;
            _this.barChartDataMultiLine.length = 0;
            if (x.chartDataSets.length > 1) {
                _this.showMultiLine = true;
                x.chartDataSets.forEach(function (element) {
                    _this.barChartDataMultiLine.push({
                        data: element.data, label: element.label
                    });
                });
            }
            else {
                _this.showMultiLine = false;
                _this.barChartData.push({ data: x.chartDataSets[0].data, label: x.chartDataSets[0].label });
            }
            // Object.assign(tempObj, this.barChartData);
            // this.barChartData.push({
            //   data: x.chartDataSets.data, label: x.chartDataSets.label
            // });
            // this.barChartData= this.datos.chartDataSets;
            // this.barChartLabels= this.datos.chartLabels;
        });
    };
    EstadisitcasAparcareComponent.prototype.canSelectZona = function () {
        if (this.tipoSelected === FiltroAparcareTipo.Ventas) {
            return true;
        }
        else {
            this.zonaSelected = '';
            return false;
        }
    };
    EstadisitcasAparcareComponent.prototype.onDateChange = function () {
        this.reload();
    };
    EstadisitcasAparcareComponent.prototype.getColors = function () {
        switch (this.tipoConsultado) {
            case FiltroAparcareTipo.Ventas: {
                return this.colors;
            }
            case FiltroAparcareTipo.Anulaciones: {
                return this.colorsAnul;
            }
            case FiltroAparcareTipo.Devoluciones: {
                return this.colorsDevol;
            }
            default: {
                return this.colors;
            }
        }
    };
    EstadisitcasAparcareComponent.prototype.generateExcel = function () {
        var filter = { tipo: this.tipoSelected, fecha: this.dateSelected, tipoTemporizacion: this.tipoTemporizacionSelected, zonaSecureId: null };
        if (this.tipoSelected === FiltroAparcareTipo.Ventas && this.zonaSelected !== undefined && this.zonaSelected !== null && this.zonaSelected !== '') {
            filter.zonaSecureId = this.zonaSelected;
        }
        this.dialog.open(DownloadExcelDialogComponent, {
            closeOnNavigation: false,
            autoFocus: true,
            data: Builder.newDownloadExcelDialogDataGeneral(this.estadistiquesService, filter, this.i18n('Estadisiques'))
        });
    };
    return EstadisitcasAparcareComponent;
}());
export { EstadisitcasAparcareComponent };
