import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { HttpParams } from '@angular/common/http';
import { EjecutivaReport } from '../models/EjecutivaReport';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../../shared/services/authentication/auth-guard.service";
var TramitarService = /** @class */ (function () {
    function TramitarService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
    }
    TramitarService.prototype.getInfoTancament = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlInfoTancaments'], null);
        }
    };
    TramitarService.prototype.getInfoNotificar = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlInfoNotificar'], null);
        }
    };
    TramitarService.prototype.getInfoPendents = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlInfoPendents'], null);
        }
    };
    TramitarService.prototype.getPendents = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlPendents'], null);
        }
    };
    TramitarService.prototype.getTancaments = function () {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlTancaments'], null);
    };
    TramitarService.prototype.SetTancaments = function (data) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTancaments'], data);
    };
    TramitarService.prototype.getLimitarExecutiva = function () {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlgetLimitarExecutiva'], null);
    };
    TramitarService.prototype.getComptadorsAZero = function () {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlgetComptadorsExecutiva'], null);
    };
    TramitarService.prototype.LoadG5 = function () {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlLoadG5'], null);
    };
    TramitarService.prototype.urlEnviarEjecutivesOlot = function (data) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['TraspassarExecutivaOlot'], data);
    };
    TramitarService.prototype.urlEnviarEjecutivasInforme = function (data) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlTraspassarExecutivaInforme'], data);
    };
    TramitarService.prototype.urlEnviarEjecutivasExcel = function (data) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlTraspassarExecutivaExcel'], data);
    };
    TramitarService.prototype.urlEnviarEjecutivasRecaptacio = function (data) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlTraspassarExecutivaRecaptacio'], data);
    };
    TramitarService.prototype.urlGetEjecutivas = function () {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetExecutives'], null);
    };
    TramitarService.prototype.urlGetNumberEjecutivas = function () {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlGetAllNumberExecutives'], null);
    };
    TramitarService.prototype.getTipusEnvio = function () {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlTipusEnvioTramitar'], null);
    };
    //#region REPORTS
    TramitarService.prototype.getReports = function (page) {
        if (this.authGuard.canActivate()) {
            page = page.replace(/\./g, 'xXx');
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlReports'].replace('{page}', page), null);
        }
    };
    TramitarService.prototype.generateReport = function (filter, reportId) {
        var reportInfo = new EjecutivaReport();
        reportInfo.reportId = reportId;
        reportInfo.fechaInicio = filter.fechaInicio;
        reportInfo.fechaFin = filter.fechaFin;
        if (this.authGuard.canActivate()) {
            return this.http.postFileResponse(config.urls['apiUrl'] + api.endpoints['urlGenerateReportEjecutivas'], reportInfo, new HttpParams());
        }
    };
    TramitarService.ngInjectableDef = i0.defineInjectable({ factory: function TramitarService_Factory() { return new TramitarService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: TramitarService, providedIn: "root" });
    return TramitarService;
}());
export { TramitarService };
