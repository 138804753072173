/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-list-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./view-list-icon.component";
var styles_ViewListIconComponent = [i0.styles];
var RenderType_ViewListIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewListIconComponent, data: {} });
export { RenderType_ViewListIconComponent as RenderType_ViewListIconComponent };
function View_ViewListIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-outline-secondary btn-xs text-center"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.listClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-search has-color-aqua"]], null, null, null, null, null))], null, null); }
function View_ViewListIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-outline-secondary btn-xs text-center"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-eye has-color-aqua"]], null, null, null, null, null))], null, null); }
export function View_ViewListIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewListIconComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewListIconComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideList; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.hideView; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ViewListIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view-list-icon", [], null, null, null, View_ViewListIconComponent_0, RenderType_ViewListIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.ViewListIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewListIconComponentNgFactory = i1.ɵccf("app-view-list-icon", i3.ViewListIconComponent, View_ViewListIconComponent_Host_0, { hideView: "hideView", hideList: "hideList" }, { viewClick: "viewClick", listClick: "listClick" }, []);
export { ViewListIconComponentNgFactory as ViewListIconComponentNgFactory };
