import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PropostesEditorComponent } from 'app/sections/propostes/components/propostes-classificar/propostes-editor/propostes-editor.component';
import { Proposta } from 'app/sections/propostes/models/proposta.model';
import { SugerenciaMatricula } from 'app/sections/propostes/models/SugerenciaMatricula';
import { SOM_CARAS } from 'path-models/SOM_CARAS';
import { ImagesService } from 'path-shared/services/images/images.service';
import { PhotoBoxService } from '../../photo-box.service';

declare const SVG: any;
let draw;

@Component({
  selector: 'app-single-image-viewer',
  templateUrl: './single-image-viewer.component.html',
  styleUrls: ['./single-image-viewer.component.scss']
})
export class SingleImageViewerComponent implements OnInit, AfterViewInit {
  image_source: string;
  file_name: string;
  imgClass = 'big';
  x = 0;
  y = 0;
  width = 1000;
  proposta: Proposta;
  botoMatricula: boolean;
  group: any;
  scale = 1;
  image: any;
  somId: string;
  llistaImatges: any[];
  isRectangleClick: boolean;
  pdf: boolean;
  isHTML : boolean = false;
  @ViewChild('img') imageRef: ElementRef;
  @ViewChild('imageDiv') imageDiv: ElementRef;

  constructor(
    private imagesService: ImagesService,
    public thisDialogRef: MatDialogRef<SingleImageViewerComponent>,
    private dialog: MatDialog,
    private photoBoxService: PhotoBoxService,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.image_source = data['src'];
    this.file_name =  data['name'];
    this.x = data['x'];
    this.y = data['y'];
    this.width = data['width'];
    this.proposta = this.data['Proposta'];
    this.botoMatricula = this.data['botoMatricula'];
    this.somId = this.data['somId'];
    this.llistaImatges = this.data['llistaImatges'];
    this.isHTML = this.data['isHTML'];
  }

  public innerHtml: SafeHtml;

  ngOnInit() {
    if (this.file_name.toLowerCase().endsWith('.pdf')) {
      this.pdf = true;
      this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(
          "<object data='" + this.image_source + "' type='application/pdf' width='520' height='422'>" +
          "Object " + this.image_source + " failed" +
          "</object>");
    }
    else if (this.isHTML) {
      this.innerHtml = "<p>WORKS"+  +"</p>";
    }

    if (this.proposta !== undefined) {
      draw = SVG('imageDiv');
      draw.on('click', this.closeClick.bind(this));
      this.initImage();
    }
  }

  initImage() {
    this.loadBackgroundImage();
    this.group.scale(this.scale);
  }

  ngAfterViewInit(): void {
    if (this.proposta === undefined) {
      const ratioX = this.width / this.imageRef.nativeElement.clientWidth;
      const height = this.imageRef.nativeElement.clientHeight * ratioX;
      const ratioY = height / this.imageRef.nativeElement.clientHeight;
      this.imageRef.nativeElement.parentElement.scrollTop = this.y / ratioY;
      this.imageRef.nativeElement.parentElement.scrollLeft = this.x / ratioX;
    }
  }

  close() {
    this.thisDialogRef.close();
  }

  closeClick() {
    if (!this.isRectangleClick) {
      this.thisDialogRef.close();
    }
  }

  toogleZoom() {
    if (this.imgClass === 'big') {
      this.imgClass = 'small';
    } else {
      this.imgClass = 'big';
    }
  }

  setPais(pais) {
    this.proposta.pais = pais;
  }

  setMatricula(matricula) {
    this.proposta.MATRICULA = matricula;

    // Combo component matrícula
    this.matriculesRequadrarPixelar(this.proposta.MATRICULA, true, true);
  }

  saveMatricula() {
    const ref = this.dialog.open(PropostesEditorComponent, {
                  closeOnNavigation: false,
                  autoFocus: true,
                  maxWidth: '50%',
                  maxHeight: '30%',
                  data: this.proposta,
                });

    ref.afterClosed().subscribe(updated => {
      // Al tancar component en modal per editar país/matrícula/vehícle
      if (this.proposta !== undefined && this.proposta.MATRICULA !== '' && this.proposta.MATRICULA !== null && this.proposta.MATRICULA !== undefined) {
        this.matriculesRequadrarPixelar(this.proposta.MATRICULA);
      } else {
        this.marcarMatriculesTrobades();
      }
    });
  }

  imageUrl(somId, suffix = '') {
    const src = this.imagesService.getImageUrl(somId, suffix) + '?' + new Date().getTime();
    return src;
  }

  loadBackgroundImage() {
    draw.each(function(i, children) {
      if (this.type === 'image') {
        this.remove();
      }
    });
    this.group = draw.group(this.image = draw
      .image(this.imageUrl(this.somId))
      .loaded(
        function(loader) {

          draw.size('100%', '1000');
          draw.attr('cursor', 'zoom-out');
          draw.viewbox({ x: 0, y: 0, width: loader.width, height: loader.height });

          if (this.proposta !== undefined) {
            this.marcarMatriculesTrobades();
          }

        }.bind(this)
      ));
  }

  //#region Tractar matrícules trobades per l'OCR

  /*
    Mètode que a partir de la informació guardada per l'OCR, ens envia al back on estàn les matrícules:
      - isPixelate = true
          Ens pixela aquelles matrícula que no coincideixin amb la que tenim informada.
      - isCrop = true
          Fa una nova imatge retallant la matrícula que tenim informada
  */
  matriculesRequadrarPixelar(matricula: string, isPixelate: boolean = false, isCrop: boolean = false) {
    const matriculasImageData: SOM_CARAS[] = [];
    let top;
    let left;
    let width;
    let height;
    let auxMatriculaImage;

    this.proposta.MATRICULA = matricula;

    // Només pixelem en cas de tenir més d'una matrícula i només el 1er cop que escollim
    if (isPixelate && this.proposta.sugMatriculesCoordenades.length > 1) {
      this.proposta.sugMatriculesCoordenades.forEach(sugMatriculaCoordenada => {
        top = sugMatriculaCoordenada.coordenadas[0];
        left = sugMatriculaCoordenada.coordenadas[1];
        width = sugMatriculaCoordenada.coordenadas[2];
        height = sugMatriculaCoordenada.coordenadas[3];

        auxMatriculaImage = new SOM_CARAS();
        auxMatriculaImage.TOP_IMAGE = top;
        auxMatriculaImage.LEFT_IMAGE = left;
        auxMatriculaImage.WIDTH_IMAGE = width - left;
        auxMatriculaImage.HEIGHT_IMAGE = height - top;
        auxMatriculaImage.brightness = 1;
        auxMatriculaImage.matricula = sugMatriculaCoordenada.matricula;

        matriculasImageData.push(auxMatriculaImage);
      });

      if (matricula === '' || matricula === null || matricula === undefined) {
        matricula = '-1';
      }

      this.imagesService.setMatriculasImage(this.proposta.NUMDENUNCIA, this.somId, matricula, matriculasImageData).subscribe(() => {
        this.initImage();

        if (isCrop) {
          const matriculasCrop: SOM_CARAS[] = matriculasImageData.filter(x => x.matricula === matricula);
          if (matriculasCrop.length === 1) {
            this.cropMatriculaSeleccionada(matriculasCrop);
          }
        } else {
          this.isRectangleClick = false;
        }
      });
    }

    this.marcarMatriculesTrobades();
  }

  /*
    A partir de la informació que ens ha tornat l'OCR, enquadrem les matricules:
      - Vermell: totes les matrícules si encara no en tenim cap d'informada
      - Taronja: aquelles matrícules que no coincideixen amb la que tenim informada
      - Verd: aquella matrícula que coincideix amb la que tenim informada
  */
  marcarMatriculesTrobades() {
    let colorMatricula = 'red';
    let strokeWidth: number;

    this.proposta.sugMatriculesCoordenades.forEach((sugMatriculaCoordenada: SugerenciaMatricula) => {
      const top = sugMatriculaCoordenada.coordenadas[0];
      const left = sugMatriculaCoordenada.coordenadas[1];
      const width = sugMatriculaCoordenada.coordenadas[2] - sugMatriculaCoordenada.coordenadas[1];
      const height = sugMatriculaCoordenada.coordenadas[3] - sugMatriculaCoordenada.coordenadas[0];

      if (this.proposta.MATRICULA !== undefined && this.proposta.MATRICULA !== null && this.proposta.MATRICULA !== '') {
        if (sugMatriculaCoordenada.matricula === this.proposta.MATRICULA) {
          colorMatricula = 'green';
        } else {
          colorMatricula = 'orange';
        }
      } else {
        colorMatricula = 'red';
      }

      // Si tenim un rectangle d'area petita, li baixem el gruix de la linia
      if ((width * height) < 1500) {
        strokeWidth = 3;
      } else {
        strokeWidth = 4;
      }

      const group = draw.group();
      const polyline = group.polyline([[0, 0], [0, height], [width, height], [width, 0], [0, 0]])
                            .fill('none').stroke({ color: colorMatricula, width: strokeWidth });

      group.move(left, top);
      polyline.scale(1.5); // Afegim marges a les coordenades inicials que ens passa l'OCR

      if (this.proposta.MATRICULA === '' || this.proposta.MATRICULA === null || this.proposta.MATRICULA === undefined) {
        const rect = group.rect(width, height);
        rect.attr({'fill-opacity': '0%', 'style': 'cursor: pointer'});
        rect.scale(1.5);

        rect.on('click', this.rectangleClick.bind(this, sugMatriculaCoordenada.matricula));
      }
    });
  }

  /*
    Mètode que fa la crida al back per fer el crop d'una matrícula
  */
  cropMatriculaSeleccionada(matriculasCrop: SOM_CARAS[]) {
    matriculasCrop = this.photoBoxService.margenExtra(matriculasCrop, 10);
    this.imagesService.cropImage(this.somId, matriculasCrop).subscribe( data => {
      this.llistaImatges.push({'imatge': data, 'checked' : true});
      this.somId = data.SOM_ID;
      this.file_name = data.NOMBRE_FICHERO;
      const nouId = this.llistaImatges.findIndex(g => g.imatge.SOM_ID === parseInt(this.somId, 10));
      this.isRectangleClick = false;
    });
  }

  /*
    Mètode que cridem en l'acció de click del rectangle transparent que envolta el requadre de la matrícula
  */
  rectangleClick(matricula: string) {
    console.log('rectangleClick matricula: ' + matricula);
    this.isRectangleClick = true;
    this.matriculesRequadrarPixelar(matricula, true, true);
  }

  //#endregion

}
