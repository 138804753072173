import { GraficoTramitacion } from './../../models/graficoTramitacion.model';
import { EstadoReservas } from 'app/sections/denuncies/models/estado-reservas.model';
import { AppComponent } from './../../../../app.component';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { Dashboard } from '../../models/dashboard.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { MAT_DIALOG_DATA, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { DashContadoresDuplicadosComponent } from './dash-contadores-duplicados/dash-contadores-duplicados.component';
import { ReleasedNotesComponent } from '../released-notes/released-notes.component';
import { Color } from 'ng2-charts';
import { PropostesPendentsCount } from 'app/sections/propostes/models/proposta-pendents-count.model';
import { ResumenTramitacion } from '../../models/resumenTramitacion.model';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { ActuacioMatricula } from '../../models/actuacioMatricula.model';
import { ActuacioMatriculaFilter } from '../../models/actuacioMatricula-filter';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ActuacioMatriculaFilterService } from '../../services/ActuacioMatricula-filter.service';
import { ActuacioMatriculasService } from '../../services/matriculas-service.service';
import { tap } from 'rxjs/operators';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { Subscription, merge } from 'rxjs';
import { Instituciones } from 'path-shared/models/instituciones';
import { ActuacionsMapaComponentComponent } from '../actuacions-mapa-component/actuacions-mapa-component.component';


@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html'
})

export class DashComponent implements OnInit {
  pieChartLabels: string[] = [];
  pieChartData: any;
  pieChartOptions: any;
  pieChartType = '';
  matriculaFilter: string;
  barChartData: ChartDataSets[];
  barChartLabels: any;
  barChartOptions: ChartOptions;
  barChartLegend: any;
  barChartType: ChartType;

  reservasVehiculosChartLabels: string[] = [];
  reservasVehiculosChartData: any;
  reservasVehiculosChartOptions: any;
  reservasVehiculosChartType = '';
  reservasVehiculosChartColors: Color[];
  reservasPersonasChartLabels: string[] = [];
  reservasPersonasChartData: number[];
  reservasPersonasChartOptions: any;
  reservasPersonasChartType = '';
  reservasPersonasChartColors: Color[];

  propuestasGruaChartData: number[];
  propuestasGruaChartLabels: any;
  propuestasGruaChartOptions: ChartOptions;
  propuestasGruaChartLegend: any;
  propuestasGruaChartType: ChartType;


  public dash: Dashboard = {} as any;
  
  public tramitacionData: ResumenTramitacion;
  subscription: Subscription;

  
  constructor(
    private dashService: DashboardService,
    private i18n: I18n,
    private notif: NotificationService,
    private app: AppComponent,
    public auth: AuthorizationService,
    private dialog: MatDialog,
    private matriculasService: ActuacioMatriculasService,
    private filterService: FilterService,
    @Inject(MAT_DIALOG_DATA) public dataOfDialog: ComponentDialog
    ) {
      {
      this.dash = {
        DenunciesEnabled: false,
        DenunciesPendentsTramitar: 0,
        PropostesEnabled: false,
        PropostesTotal: 0,
        PropostesPendents: 0,
        PropostesAcceptades: 0,
        PropostesRebutjades: 0,
        Prepropostes: 0,

        ReservasEnabled: false,
        CochesOcupados: 0,
        CochesLibres: 100,
        MotosOcupados: 0,
        MotosLibres: 100,
        PersonasOcupados: 0,
        PersonasLibres: 100,
        PersonasRealesOcupados: 0,


        GruaEnabled: false,
        PropostesGruaPendents: 0,
        PropostesGruaAcceptades: 0,
        PropostesGruaRebutjades: 0,

        AutomatitzacioEnabled: false,
        AutomatitzacioErrors: 0,

        EstatsFitxers: []
      };
    this.createProposteschart();
    this.createEstadistiquesChart();
    this.createReservasChart();
    this.createPropostesGruachart();
  }

}


  ngOnInit() {
    
    this.app.pageTitle = this.i18n('Dashboard');
    this.dashService.getDashboard().subscribe(data => {
      this.dash = data;
      this.fillPropostesChart();
      this.fillEstadistiquesChart();
      this.fillReservasChart(true, true);
      this.fillPropostesGruaChart();
    });
    this.dashService.getDashboardTramitacionResumen().subscribe(data =>{
      this.tramitacionData = data;
    });
    this.notif.onPropostesPendentsChange.subscribe( (value: PropostesPendentsCount) => {
      if (this.auth.UserCanSeePage(this.auth.Pages.Prepropostes) && !this.auth.IsSuperUser()) {
        this.dash.PropostesPendents = value.prepropostes;
      } else {
        this.dash.PropostesPendents = value.propostes;
      }
    });
    this.notif.onReservesEstatChange.subscribe((value: EstadoReservas) => {
      let changesVehiculos = false;
      let changesPersonas = false;
      if (this.dash.PersonasLibres !== value.PersonasLibres) {
        this.dash.PersonasLibres = value.PersonasLibres;
        changesPersonas = true;
      }
      if (this.dash.PersonasOcupados !== value.PersonasOcupados) {
        this.dash.PersonasOcupados = value.PersonasOcupados;
        changesPersonas = true;
      }
      if (this.dash.PersonasRealesOcupados !== value.PersonasRealesOcupados) {
        this.dash.PersonasRealesOcupados = value.PersonasRealesOcupados;
        changesPersonas = true;
      }
      if (this.dash.CochesLibres !== value.CochesLibres) {
        this.dash.CochesLibres = value.CochesLibres;
        changesVehiculos = true;
      }
      if (this.dash.CochesOcupados !== value.CochesOcupados) {
        this.dash.CochesOcupados = value.CochesOcupados;
        changesVehiculos = true;
      }
      if (this.dash.MotosLibres !== value.MotosLibres) {
        this.dash.MotosLibres = value.MotosLibres;
        changesVehiculos = true;
      }
      if (this.dash.MotosOcupados !== value.MotosOcupados) {
        this.dash.MotosOcupados = value.MotosOcupados;
        changesVehiculos = true;
      }
      this.fillReservasChart(changesVehiculos, changesPersonas);
    });

    this.dashService.getUnreadReleasedNotes().subscribe((res: any[]) => {
      if (res.length > 0 && this.dashService.firstTime) {
        this.dialog.open(ReleasedNotesComponent, {
          width: '60%',
          maxHeight: '90%',
          closeOnNavigation: false,
          autoFocus: true,
          data: res
        }).afterClosed().subscribe(leido => {
          if (leido) {
            const maxSomId = Math.max.apply(Math, res.map(function(o) { return o.SOM_ID; }));
            this.dashService.updateLastReleasedNoteRead(maxSomId).subscribe();
          }
          this.dashService.firstTime = false;
        });
      }
    });


    // TODO revisar endpoint notificacio
    // this.notif.onEstatFichersChange.subscribe(value => {
    //   this.dash.EstatsFitxers = value;
    // });

    // this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
    //   if (e.key === 'Enter') {
    //     this.matriculaFilterChange(null);
    //   }
    // });
  }

  
  


 
  createProposteschart() {
    this.pieChartLabels = [
      this.i18n('Desestimades'),
      this.i18n('Acceptades'),
      this.i18n('Pendents'),
    ];

    this.pieChartData = [0, 0, 0];
    this.pieChartType = 'pie';
    this.pieChartOptions = {
      legend: {
         display: true,
         position: 'left',
      },
      tooltips: {
         enabled: true
      }
     };
  }

  fillPropostesChart() {
    this.pieChartData = [];
    this.pieChartData.push(this.dash.PropostesRebutjades);
    this.pieChartData.push(this.dash.PropostesAcceptades);
    this.pieChartData.push(this.dash.PropostesPendents);
  }

  createEstadistiquesChart() {
    this.barChartType = 'bar';
    this.barChartLegend = false;
    this.barChartOptions = {
      responsive: true,
      scales: { xAxes: [ { ticks: { autoSkip: false } } ], yAxes: [{}] },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
        }
      }
    };

  }

  fillEstadistiquesChart() {
    this.barChartData = [];
    this.barChartLabels = [];
    const data = [];
    // tslint:disable-next-line: forin
    for (const key in this.dash.Grafic.Valors ) {
      data.push( this.dash.Grafic.Valors[key] );
      this.barChartLabels.push( this.dash.Grafic.ValorsX[key] );
    }
    this.barChartData.push( {
      data: data,
    } );

  }

  openContadoresDuplicados() {
    const ref =  this.dialog.open( DashContadoresDuplicadosComponent, {
      closeOnNavigation: false,
      autoFocus: true
    });
  }

  createReservasChart() {
    this.reservasVehiculosChartData = [0, 0, 0, 0];
    this.reservasVehiculosChartType = 'pie';
    this.reservasVehiculosChartLabels = [
      this.i18n('Moto ocupat'),
      this.i18n('Moto lliure'),
      this.i18n('Cotxe ocupat'),
      this.i18n('Cotxe lliure')
    ];
    this.reservasVehiculosChartOptions = {
      legend: {
         display: true
      },
      tooltips: {
         enabled: true
      }
     };

    this.reservasVehiculosChartColors = [
      {
        backgroundColor: [
          'lightcoral', /* Moto ocupat */
          'olivedrab', /* Moto lliure */
          'red', /* Cotxe ocupat */
          'green', /* Cotxe lliure */
        ]
      }
    ];

    this.reservasPersonasChartData = [0, 0];
    this.reservasPersonasChartType = 'pie';
    this.reservasPersonasChartLabels = [
      this.i18n('Persones ocupat'),
      this.i18n('Persones lliure'),
      this.i18n('Persones ocupat real')
    ];
    this.reservasPersonasChartOptions = {
      legend: {
         display: true
      },
      tooltips: {
         enabled: true
      }
     };

    this.reservasPersonasChartColors = [
      {
        backgroundColor: [
          'skyblue', /* ocupat */
          'green', /* lliure */
          'red' /* ocupat real */
        ]
      }
    ];
  }

  fillReservasChart(changesVehiculos: boolean, changesPersonas: boolean) {
    if (changesVehiculos) {
      this.reservasVehiculosChartData = [];

      this.reservasVehiculosChartData.push(this.dash.MotosOcupados);
      this.reservasVehiculosChartData.push(this.dash.MotosLibres);
      this.reservasVehiculosChartData.push(this.dash.CochesOcupados);
      this.reservasVehiculosChartData.push(this.dash.CochesLibres);
    }
    if (changesPersonas) {
      this.reservasPersonasChartData = [];

      this.reservasPersonasChartData.push(this.dash.PersonasOcupados);
      this.reservasPersonasChartData.push(this.dash.PersonasLibres);
      this.reservasPersonasChartData.push(this.dash.PersonasRealesOcupados);
    }
  }


  createPropostesGruachart() {
    this.propuestasGruaChartLabels = [
      this.i18n('Desestimades'),
      this.i18n('Acceptades'),
      this.i18n('Pendents'),
    ];

    this.propuestasGruaChartData = [0, 0, 0];
    this.propuestasGruaChartType = 'pie';
    this.propuestasGruaChartOptions = {
      legend: {
         display: true
      },
      tooltips: {
         enabled: true
      }
     };
  }

  fillPropostesGruaChart() {
    this.propuestasGruaChartData = [];
    this.propuestasGruaChartData.push(this.dash.PropostesGruaRebutjades);
    this.propuestasGruaChartData.push(this.dash.PropostesGruaAcceptades);
    this.propuestasGruaChartData.push(this.dash.PropostesGruaPendents);
  }


  onRowClicked(row): void {
    this.dialog.open(ActuacionsMapaComponentComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: {'somId' : row.SOM_ID, 'matricula' : row.MATRICULA, 'latitud' : row.LATITUD, 'longitud' : row.LONGITUD, 'fecha' : row.DATA, 'tipuActuacioIcon' : row.TIPUS_ACTUACIO.ICON_ROUTE},
      width: '90%',
      height: '90%',
    });
  }
}
