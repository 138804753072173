import { ComponentDialog } from './../../../../../shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AgentsService } from './../../services/agents-service.service';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Agent } from 'app/sections/configuracio/agents/models/agent.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Router, ActivatedRoute } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Builder } from 'path-shared/models/builder';
import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
import { NgForm, FormGroup, Form, FormBuilder, Validators } from '@angular/forms';
import { FormService } from 'path-shared/services/form/form.service';
import { FileBoxComponent } from '../../../../../shared/components/file-box/file-box.component';
@Component({
  selector: 'app-agents-editor',
  templateUrl: './agents-editor.component.html',
  styleUrls: ['./agents-editor.component.scss']
})
export class AgentsEditorComponent implements OnInit {
  secure_id: string;
  agent: Agent;
  resetPasswordRequired = false;
  dialogData: ComponentDialog;
  filesBoxDialogRef: MatDialogRef<FileBoxComponent>;
  closeButtonText: string;
  private file: File;
  llistaFitxers: any = [];
  editable = false;
  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    public auth: AuthorizationService,
    private router: Router,
    private route: ActivatedRoute,
    private agentsService: AgentsService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public thisDialogRef: MatDialogRef<AgentsEditorComponent>,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.agent = Builder.newAgent();
      this.dialogData = data;
      this.editable = data.editable;
      this.secure_id = this.dialogData.id;
      if (this.dialogData.isReadOnly) {
        this.closeButtonText = this.i18n('Tancar');
      } else {
        this.closeButtonText = this.i18n('Llista');
      }
   }

  ngOnInit() {
    if (this.secure_id !== null) {
      this.agentsService.getAgent(this.secure_id).subscribe(data => {
        this.agent = data;
        this.llistaFitxers = data.fitxers;
      });
    }
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      this.agent.institucio = sessionStorage.getItem('IdInstitucion');
      if (this.resetPasswordRequired) {
        this.agent.password = '';
        this.agent.resetPWD = true;
      } else {
        this.agent.resetPWD = false;
      }
      this.agentsService.setAgent(this.agent).subscribe(result => {
        this.toastr.info(this.i18n('Agent guardat correctament'));

        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  fitxers() {
    this.filesBoxDialogRef = this.dialog.open(FileBoxComponent,
      {
        minWidth: '50%',
        minHeight: '90%',
        width: '50%',
        height: '90%',
        maxWidth: '50%',
        maxHeight: '90%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {'llistaFitxers': this.llistaFitxers,
              'contenedor': this.agent.SECURE_ID }
      });

      this.filesBoxDialogRef.afterClosed().subscribe(() => {
        this.agentsService.getAgent(this.secure_id).subscribe(data => {
          this.agent = data;
          this.llistaFitxers = data.fitxers;
        });
      });
}

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar l\'agent?'))
    .then((confirmed) => {
        if (confirmed) {
          // const saved = this.denunciesService.saveDenuncia(dataJson).subscribe();
          this.agentsService.deleteAgent(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Agent esborrat correctament'));
            this.thisDialogRef.close(true);
          });
        }
      });
  }

  fileReady(file) {
    this.file = file;
  }

  diasFestivosChange(fechas: Array<Date>) {
    // nothing.
  }
}
