

<form #mainForm>
    <app-toolbar>  
        <app-tool-button-permissions [permission]="this.auth.Pages.ImportacioDenuncies"></app-tool-button-permissions>

    </app-toolbar>

    <div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <app-form-combo-edit i18n-label label="Format importació" [items]="this.importacioDenuncia" [(value)]="selectedValue"
                    valueField="idImportacio" textField="descImportacio" required></app-form-combo-edit>
                </div>
                <div class="row d-block">
                    <app-form-file-multiple-upload i18n-labelFitxer labelFitxer="Fitxers a processar" 
                        labelHidden="true" isTextarea="false" (fileReady)="fileReady($event)"></app-form-file-multiple-upload>
                </div>
                <div class="row">
                    <div class="col-9">
                        <button style="margin:1.7vh" class="btn btn-sq-lg btn-primary btn-block" (click)="this.processar()"><span i18n>Processar</span></button>
                    </div>
                </div>
            </div>
        </div>  
        <div class="row">
            <app-progress-bar class="col-9" #progressBar i18n-label pantalla="importacioDenuncia" label="Progrés"></app-progress-bar>
        </div>      
    </div>   
    
</form>