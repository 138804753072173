<ng-template #myDialogC60 class="col-12">
  <!-- <app-input-progress-bar [(value)]="this.porcentajeTramitados"  class="col-12" i18n-label label="Progrés"></app-input-progress-bar> -->
  <div *ngIf="this.porcentajeC60 !== null" class="d-flex justify-content-center">
    <div>
      <h2> <span i18n >Creando C60 {{this.mensajePorcentajeC60}} </span></h2> 
    </div>
  </div>
  <section *ngIf="this.porcentajeC60 !== null" class="progress-bar-section">
      <div class="col-12">
          <mat-progress-bar mode="determinate" value={{this.porcentajeC60}}></mat-progress-bar>
      </div>
      
      
  </section>
  <div *ngIf="this.porcentajeC60 !== null" class="d-flex justify-content-center">
    <div style=" color:  #1E457C;" >
      <div> 
        <h3> {{this.porcentajeC60 | number:'1.2-2'}}% </h3>
      </div>    
    </div>
  </div>
</ng-template>

<ng-template #myDialogNotificaciones class="col-12">
  <!-- <app-input-progress-bar [(value)]="this.porcentajeTramitados"  class="col-12" i18n-label label="Progrés"></app-input-progress-bar> -->
  <div *ngIf="this.porcentajeNotificaciones !== null"   class="d-flex justify-content-center"> 
    <div>
      <h2> 
        <span i18n >Generando notificacion {{this.mensajePorcentajeNotificaciones}} </span>
      </h2>
    </div>
   </div>
  <section *ngIf="this.porcentajeNotificaciones !== null" class="progress-bar-section">
      <div class="col-12">
          <mat-progress-bar mode="determinate" value={{this.porcentajeNotificaciones}}></mat-progress-bar>
      </div>
  </section>
  <div *ngIf="this.porcentajeNotificaciones !== null" class="d-flex justify-content-center">
    <div style=" color: #1E457C;" >
      <div> 
        <h3> {{this.porcentajeNotificaciones | number:'1.2-2'}}% </h3>
      </div>    
    </div>
  </div>
</ng-template>


<div class="alert alert-secondary" role="alert">
  <span i18n>Datos calculados en fecha {{this.datos.fechaUltimoCalculo | longDate }} si desa volver a calcular presione 
    <button class="btn btn-primary" (click)="recalcular()" >aqui <i class="fa fa-spinner"></i></button> 
  </span>
</div>
<div class="row">
  <div class=" col-lg-4 noPadding">
   <div class="card" >
     <div class="card-header"  style="padding-left: 0px; padding-right: 0px;" i18n> Propuestas de infracción</div>
    <div class="row">
      <div class="card-body col-12">
          <table  class="table table-sdw">
            <thead><tr class="col-md-5"><td> </td><td class="col-md-3">nº</td><td class="col-md-4">Última pub.</td> </tr></thead>
            <tbody>
              <tr>
              <td>Infracciones que prescriben en próximos 30 días </td>
              <td>
                <button class="btn btn-primary col-11" (click)="this.propuestas30Dias()">
                  {{this.datos.prescripcion_30}} 
                </button>
              </td>
              <td></td>
              </tr>
              <tr>
              <td>Infracciones que prescriben en próximos 31-60 días </td>
              <td>
                <button class="btn btn-primary col-11" (click)="this.propuestas30Dias()">
                  {{this.datos.prescripcion_31_60}} 
                </button>
              </td>
              <td></td>
              </tr>
              <tr>
              <td>Propuestas de infracción notificadas</td>
              <!-- <td>{{this.datos.infracciones_notificadas}} </td> -->
              <td>
                <button class="btn btn-primary col-11" (click)="this.procesarDenuncias()">
                  {{this.datos.infracciones_notificadas}} 
                </button>
              </td>
              <td >{{this.datos.infracciones_notificadas_ultima_publicacion | longDate}}</td>
              </tr>
              <tr>
              <td>Propuestas de infracción pendientes de BOE</td>
              <td>
                <button class="btn btn-primary col-11" (click)="this.infraccionesBOE()">
                  {{this.datos.infracciones_pendientes_boe}} 
                </button>
                 </td>
              <td >{{this.datos.infracciones_pendientes_boe_utlima_publicacion | longDate}}</td>
              </tr>
            </tbody>
        </table>
      </div>
    </div>
   </div>
 </div>
 <!--Second table-->
 <div class=" col-lg-4 noPadding">
  <div class="card" >
    <div class="card-header"  style="padding-left: 0px; padding-right: 0px;" i18n> Denuncias pendientes resolución</div>
   <div class="row">
     <div class="card-body col-12">
         <table  class="table table-sdw">
          <thead><tr class="col-md-5"><td> </td><td class="col-md-3">nº</td><td class="col-md-4">Última pub.</td> </tr></thead>
           <tbody>
             <tr>
             <td>Propuestas resolución pendientes notificación </td>
             <!-- <td>{{this.datos.resoluciones_pendientes_notificar}} </td> -->
             <td>
              <button class="btn btn-primary col-11" (click)="this.procesarPropuestasResolucion()">
                {{this.datos.resoluciones_pendientes_notificar}}
              </button>
             </td>
             <td >
              {{this.datos.resoluciones_pendientes_notificar_ultima_publicacion | longDate}} 
              </td>
             </tr>
             <tr>
             <td>Propuestas de resolución pendientes de BOE</td>
             <td>
              <button class="btn btn-primary col-11" (click)="this.alegacioneBOE()">
                {{this.datos.propuestas_resolucion_pendientes_boe}} 
              </button> </td>
             <td></td>
             </tr>
             <tr>
             <td>Denuncias pendientes de resolución</td>
             <td>
               
              <button class="btn btn-primary col-11" (click)="this.procesarResoluciones()">
                {{this.datos.denuncias_pendientes_resolucion}} </button>
             </td>
             <td> 
               {{this.datos.denuncias_pendientes_resolucion_ultima_publicacion | longDate}}
              </td>
             </tr>
             <tr>
             <td>Denuncias que caducan próximos 30 días</td>
             <td>{{this.datos.denuncias_caducan_30_dias}} </td>
             <td></td>
             </tr>
           </tbody>
       </table>
     </div>
   </div>
  </div>
</div>
<!--Third table-->
<div class=" col-lg-4 noPadding">
  <div class="card" >
    <div class="card-header"  style="padding-left: 0px; padding-right: 0px;" i18n> Denuncias firmes</div>
   <div class="row">
     <div class="card-body col-12">
         <table  class="table table-sdw">
          <thead><tr class="col-md-5"><td> </td><td class="col-md-3">nº</td><td class="col-md-4">Última pub.</td> </tr></thead>
           <tbody>
             <tr>
              <td>Denuncias firmes pendientes BOE </td>
              <td>
                <button class="btn btn-primary col-11" (click)="this.autoInitResolucionesBOE()">
                  {{this.datos.denuncias_firmes_pendientes_boe}} 
                </button>
              </td>
              <td >{{this.datos.denuncias_firmes_pendientes_boe_ultima_publicacion | longDate}}</td>
             </tr>
             <tr>
              <td>Recursos reposición pendientes notificar </td>
              <td>
                <button class="btn btn-primary col-11" (click)="this.procesarRecursoReposicion()">
                  {{this.datos.recursos_reposicion_pendientes_notificar}} 
                </button>
              </td>
              <td>{{this.datos.recursos_reposicion_pendientes_notificar_ultima_publicacion| longDate}} </td>
             </tr>
             <tr>
             <td>Recursos reposición pendientes BOE</td>
             <td>
              <button class="btn btn-primary col-11" (click)="this.autoInitSancionesFimresBOE()">
                {{this.datos.recursos_reposicion_penditentes_boe}} 
              </button> </td>
             <td>{{this.datos.recursos_reposicion_penditentes_boe_ultima_publicacion | longDate}}</td>
             </tr>
             <tr>
             <td>Denuncias pendientes vía apremio</td>
             <td>{{this.datos.denuncias_pendientes_apremio}} </td>
             <td >{{this.datos.denuncias_pendientes_apremio_ultima_publicacion | longDate}}</td>
             </tr>
           </tbody>
       </table>
     </div>
   </div>
  </div>
</div>
</div>  

<!-- <mat-tab-group class="col-xl-12">
  <mat-tab i18n-label label="Graficos" >
    <div class="alert alert-secondary" role="alert">
      <span i18n>Datos calculados en fecha {{this.datos[0].fechaUltimoCalculo | longDate }} si desa volver a calcular presione 
        <button class="btn btn-primary" >aqui <i class="fa fa-spinner"></i></button> 
      </span>
     </div>

   <div class="row">
     <div class=" col-lg-6 noPadding" *ngFor="let item of this.datos">
      <div class="card" >
        <div class="card-header"  style="padding-left: 0px; padding-right: 0px;" i18n>{{item.titulo}}</div>
      <div class="row">
        <div class="card-body col-12">
            <app-dash-tramitacion [datos]="item"></app-dash-tramitacion> 
        
        </div>
      </div>
      </div>
    </div>
   </div>  
    
  </mat-tab>
  <mat-tab i18n-label label="Tabla"> -->
    <!-- <div class="alert alert-secondary" role="alert">
      <span i18n>Datos calculados en fecha {{this.datos[0].fechaUltimoCalculo | longDate }} si desa volver a calcular presione 
        <button class="btn btn-primary" (click)="recalcular()" >aqui <i class="fa fa-spinner"></i></button> 
      </span>
     </div>
     <div class="row">
      <div class=" col-lg-6 noPadding" *ngFor="let item of this.datos">
       <div class="card" >
         <div class="card-header"  style="padding-left: 0px; padding-right: 0px;" i18n>{{item.titulo}}</div>
       <div class="row">
         <div class="card-body col-12">
                 <table  class="table table-sdw">
                   <thead><tr><td> </td><td>nº</td> </tr></thead>
                   <tbody>
                     <tr *ngFor="let currentData of this.item.data; ">
                      <td>{{this.item.descripciones[0] }} </td>
                      <td>{{currentData}} </td>
                     </tr>
                   </tbody>
                   </table>
         
         </div>
       </div>
       </div>
     </div>
    </div>   -->
<!--  
  </mat-tab>
  
</mat-tab-group> -->