/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./finalizar-turno.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i3 from "../../../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i4 from "../../../../../../shared/pipes/long-date-pipe";
import * as i5 from "@ngx-translate/i18n-polyfill";
import * as i6 from "../../../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i7 from "../../../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i8 from "../../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i9 from "../../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i10 from "@angular/common";
import * as i11 from "../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i12 from "@angular/material/button";
import * as i13 from "@angular/cdk/platform";
import * as i14 from "@angular/cdk/a11y";
import * as i15 from "@angular/platform-browser/animations";
import * as i16 from "./finalizar-turno.component";
import * as i17 from "../../services/turnos-service";
import * as i18 from "../../../../../usuaris/services/usuaris.service";
import * as i19 from "../../../../../../shared/services/authentication/auth.service";
import * as i20 from "@angular/material/dialog";
var styles_FinalizarTurnoComponent = [i0.styles];
var RenderType_FinalizarTurnoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FinalizarTurnoComponent, data: {} });
export { RenderType_FinalizarTurnoComponent as RenderType_FinalizarTurnoComponent };
function View_FinalizarTurnoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tool-button", [["color", "has-color-blue"], ["icon", "fa-print"], ["text", "Impressi\u00F3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadPDF() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToolButtonComponent_0, i2.RenderType_ToolButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToolButtonComponent, [], { disabled: [0, "disabled"], text: [1, "text"], color: [2, "color"], icon: [3, "icon"] }, { click: "click" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pdfActual.value === undefined); var currVal_1 = "Impressi\u00F3"; var currVal_2 = "has-color-blue"; var currVal_3 = "fa-print"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_FinalizarTurnoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.registroTurno.FECHA_INICIO)); _ck(_v, 1, 0, currVal_0); }); }
export function View_FinalizarTurnoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.LongDatePipe, [i5.I18n]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-dialog-header", [["label", "Finalitzar torn"]], null, null, null, i6.View_DialogHeaderComponent_0, i6.RenderType_DialogHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i7.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "app-toolbar", [], null, null, null, i8.View_ToolbarComponent_0, i8.RenderType_ToolbarComponent)), i1.ɵdid(4, 114688, null, 0, i9.ToolbarComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FinalizarTurnoComponent_1)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "app-tool-button", [["color", "has-color-yellow"], ["icon", "fa-th-list"], ["text", "Cancel\u00B7lar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToolButtonComponent_0, i2.RenderType_ToolButtonComponent)), i1.ɵdid(8, 114688, null, 0, i3.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(9, 0, null, null, 21, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 20, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "col-sm-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Data inici:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FinalizarTurnoComponent_2)), i1.ɵdid(15, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 6, "div", [["class", "col-sm-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 5, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.incrementZoom((0 - 0.1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.Platform, i14.FocusMonitor, [2, i15.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(19, 0, null, 0, 3, ":svg:svg", [["fill", "none"], ["height", "20"], ["stroke", "currentColor"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"], ["viewBox", "0 0 24 24"], ["width", "20"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, ":svg:circle", [["cx", "11"], ["cy", "11"], ["r", "8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, ":svg:line", [["x1", "21"], ["x2", "16.65"], ["y1", "21"], ["y2", "16.65"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, ":svg:line", [["x1", "8"], ["x2", "14"], ["y1", "11"], ["y2", "11"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 7, "div", [["class", "col-sm-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 6, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.incrementZoom(0.1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(25, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.Platform, i14.FocusMonitor, [2, i15.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(26, 0, null, 0, 4, ":svg:svg", [["fill", "none"], ["height", "20"], ["stroke", "currentColor"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"], ["viewBox", "0 0 24 24"], ["width", "20"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 0, ":svg:circle", [["cx", "11"], ["cy", "11"], ["r", "8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 0, ":svg:line", [["x1", "21"], ["x2", "16.65"], ["y1", "21"], ["y2", "16.65"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 0, ":svg:line", [["x1", "11"], ["x2", "11"], ["y1", "8"], ["y2", "14"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 0, ":svg:line", [["x1", "8"], ["x2", "14"], ["y1", "11"], ["y2", "11"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "div", [["id", "pdfViewer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 0, "pdf-viewer", [["class", "col-xl-3"], ["delegate-handle", "relativity-special-general-theory"], ["max-height", "50%"], ["scale", "1"], ["show-toolbar", "true"], ["style", "overflow: scroll"]], [[8, "zoom", 0], [8, "fit-to-page", 0], [8, "original-size", 0], [8, "src", 0]], null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 6, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 5, "button", [["class", "btn btn-success button-not-allowed btn-lg"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.finalizarTurno() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 0, "i", [["class", "fas fa-hourglass-start"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0"])), (_l()(), i1.ɵeld(37, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Finalitzar torn"])), (_l()(), i1.ɵted(-1, null, [" \u00A0 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Finalitzar torn"; _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); var currVal_1 = (_co.pdfActual.value !== undefined); _ck(_v, 6, 0, currVal_1); var currVal_2 = "Cancel\u00B7lar"; var currVal_3 = "has-color-yellow"; var currVal_4 = "fa-th-list"; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = ((((_co.registroTurno !== undefined) && (_co.registroTurno !== null)) && (_co.registroTurno.ID_TURNO !== undefined)) && (_co.registroTurno.ID_TURNO !== null)); _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = (i1.ɵnov(_v, 18).disabled || null); var currVal_7 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_6, currVal_7); var currVal_8 = (i1.ɵnov(_v, 25).disabled || null); var currVal_9 = (i1.ɵnov(_v, 25)._animationMode === "NoopAnimations"); _ck(_v, 24, 0, currVal_8, currVal_9); var currVal_10 = _co.zoom; var currVal_11 = false; var currVal_12 = true; var currVal_13 = i1.ɵinlineInterpolate(1, "", _co.pdfActual.value, ""); _ck(_v, 32, 0, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = ((((_co.registroTurno === undefined) || (_co.registroTurno === null)) || (_co.registroTurno.ID_TURNO === undefined)) || (_co.registroTurno.ID_TURNO === null)); _ck(_v, 34, 0, currVal_14); }); }
export function View_FinalizarTurnoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-finalizar-turno", [], null, null, null, View_FinalizarTurnoComponent_0, RenderType_FinalizarTurnoComponent)), i1.ɵdid(1, 114688, null, 0, i16.FinalizarTurnoComponent, [i17.TurnosService, i18.UsuarisService, i19.AuthService, i20.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FinalizarTurnoComponentNgFactory = i1.ɵccf("app-finalizar-turno", i16.FinalizarTurnoComponent, View_FinalizarTurnoComponent_Host_0, {}, {}, []);
export { FinalizarTurnoComponentNgFactory as FinalizarTurnoComponentNgFactory };
