import { ConfirmacionBOEFilter } from './../../../models/confirmacion-boe-filter.model';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmacion-boe-filter',
  templateUrl: './confirmacion-boe-filter.component.html',
  styleUrls: ['./confirmacion-boe-filter.component.scss']
})
export class ConfirmacionBoeFilterComponent implements OnInit {
  subscription: Subscription;
  filter: ConfirmacionBOEFilter;
  @Output() notify: EventEmitter<ConfirmacionBOEFilter> = new EventEmitter<ConfirmacionBOEFilter>();


  constructor(public thisDialogRef: MatDialogRef<ConfirmacionBoeFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data, ) {
      this.filter = data.filter;
    }


  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }
}
