var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
var QrViewerComponent = /** @class */ (function () {
    function QrViewerComponent(data) {
        this.data = data;
        this.qrData = null;
        this.errorCorrectionLevel = null;
        this.valor = null;
        this.label = 'QR';
        this.width = 256;
        this.nomImatge = 'qr';
        //       let encrypted  =// shajs('sha256').update(data['qrData'].toString()).digest('hex');
        // const encoder = new TextEncoder();
        // const encrypted = encoder.encode(data['qrData'].toString());
        this.valor = data['qrData'].toString();
        if (data['fileName'] !== undefined && data['fileName'] !== null && data['fileName'] !== '') {
            this.nomImatge = data['fileName'];
            this.label = this.nomImatge;
        }
    }
    QrViewerComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.qrData = this.digestMessage(this.valor);
                console.log(this.qrData);
                return [2 /*return*/];
            });
        });
    };
    QrViewerComponent.prototype.digestMessage = function (message) {
        // const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
        // const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
        // const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
        // const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
        var hashHex = btoa(message);
        return hashHex;
    };
    QrViewerComponent.prototype.downolad = function (qrImage) {
        var parentElement = qrImage.el.nativeElement.querySelector("img").src;
        var blobData = this.convertBase64ToBlob(parentElement);
        // saves as image
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
            window.navigator.msSaveOrOpenBlob(blobData, this.nomImatge);
        }
        else { // chrome
            var blob = new Blob([blobData], { type: "image/png" });
            var url = window.URL.createObjectURL(blob);
            // window.open(url);
            var link = document.createElement('a');
            link.href = url;
            link.download = this.nomImatge;
            link.click();
        }
    };
    QrViewerComponent.prototype.convertBase64ToBlob = function (Base64Image) {
        var parts = Base64Image.split(';base64,');
        var imageType = parts[0].split(':')[1];
        var decodedData = window.atob(parts[1]);
        var uInt8Array = new Uint8Array(decodedData.length);
        for (var i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: imageType });
    };
    return QrViewerComponent;
}());
export { QrViewerComponent };
