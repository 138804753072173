import { MatDialog } from "@angular/material/dialog";
import { I18n } from "@ngx-translate/i18n-polyfill";
import { ToastrService } from "ngx-toastr";
import { NotificationService } from "path-shared/services/notification/notification.service";
import { TramitarService } from "../../../services/tramitar.service";

import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from "path-shared/services/authorization/authorization-service";
import { ReportsComponent } from "path-shared/components/reports/reports.component";
import { EjecutivaFilterImpressio } from "../../../models/Ejecutiva-filter-impressio";
import { FileDownloader } from "path-shared/services/http/fileDownloader";

@Component({
  selector: 'app-tramitar-dates',
  templateUrl: './tramitar-dates.component.html',
  styleUrls: ['./tramitar-dates.component.scss']
})
export class TramitarDatesComponent implements OnInit {

  DataInici: Date;
  DataFi: Date;
  reports = [];  
  filterImpressio: EjecutivaFilterImpressio;

  constructor(private dialog: MatDialog,
              private tramitarService: TramitarService,              
              private not: NotificationService,
              private toastr: ToastrService,
              private i18n: I18n,
              public auth: AuthorizationService) { }

  ngOnInit() {
    this.tramitarService.getReports(this.auth.Pages.TramitarExecutiva).subscribe( res => {
      this.reports = res;
    });
    this.DataInici = null;
    this.DataFi = null;
  }

  Impressio() {    
    if (this.DataInici === null || this.DataFi === null || this.DataInici > this.DataFi) {
      this.toastr.error(this.i18n('Les fechas no són correctas'));
    } else {
      this.filterImpressio = new EjecutivaFilterImpressio(this.DataInici, this.DataFi, 'EXP', 'asc');
      const ref = this.dialog.open(ReportsComponent, {
        width: '30%',
        height: '30%',
        closeOnNavigation: false,
        autoFocus: true,
        data: this.reports
      }).afterClosed().subscribe(report => {
        if (report !== '') {
          this.tramitarService.generateReport(this.filterImpressio, report.id).subscribe(file => {
            const name = report.nom;
            const contentType = file.headers.get('Content-Type');
            if (contentType !== null && contentType !== undefined) {
                FileDownloader.downLoadFile([file.body],  contentType + ';charset=utf-8', name.toString());
            } else {
              this.toastr.error(this.i18n('No se ha podido generar el fichero'));
            }
          });
  
        }
  
      });
    }
    
  }

}
