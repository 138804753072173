var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var TemporadaFilter = /** @class */ (function (_super) {
    __extends(TemporadaFilter, _super);
    function TemporadaFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.id = '';
        _this.fechaInicio = '';
        _this.fechaFin = '';
        _this.descripcion = '';
        _this.activa = null;
        _this.labels['id'] = _this.i18n('Id');
        _this.labels['descripcion'] = _this.i18n('Descripció');
        _this.labels['fechaInicio'] = _this.i18n('Data inici');
        _this.labels['fechaFin'] = _this.i18n('Data final');
        _this.labels['activa'] = _this.i18n('Activa');
        return _this;
    }
    return TemporadaFilter;
}(FilterData));
export { TemporadaFilter };
