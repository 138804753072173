import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { TextoInformativoFilterComponent } from 'app/sections/aparcare/text-informatiu/components/texto-informativo-filter/texto-informativo-filter.component';
import { Dictionary } from 'path-shared/models/dictionary';
import { TextoAvisoFilter } from '../../model/texto-aviso.filter';

@Component({
  selector: 'app-texto-aviso-filter',
  templateUrl: './texto-aviso-filter.component.html',
  styleUrls: ['./texto-aviso-filter.component.scss']
})
export class TextoAvisoFilterComponent implements OnInit {

  filter: TextoAvisoFilter;
  idiomaDictionary: Array<Dictionary>;

  // Necesaria esta variable que nos permite convertir los valores mostrados en el triState
  // y negarlos con el valor del filtro actual (bloquea === !permite).
  get bloqueaLogin() {
    if (this.filter.permiteLogIn === null) {
      return this.filter.permiteLogIn;
    } else {
      return !this.filter.permiteLogIn;
    }
  } set bloqueaLogin(val) {
    if (val !== null && val !== undefined) {
      this.filter.permiteLogIn = !val;
    } else {
      this.filter.permiteLogIn = null;
    }
  }

  @Output() notify: EventEmitter<TextoAvisoFilter> = new EventEmitter<TextoAvisoFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<TextoInformativoFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.filter = data.filter;
    this.idiomaDictionary = data.idiomas;
  }

  ngOnInit() {
  }

  reset(): void {
    this.filter.clear();
    this.apply();
  }

  apply(): void {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss(): void {
    this.thisDialogRef.close();
  }

  siglaSeleccionada(sigla: any) {
    this.filter.idioma = sigla;
  }

  bloqueaLogInSeleccionado(bloquea: any) {
    this.filter.permiteLogIn = !bloquea;
  }

}
