import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { api } from 'path-shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { ZoneFilter } from '../models/zones-filtar';
import { ZonesFilterService } from './zones-filter.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../../shared/services/authentication/auth-guard.service";
var defaultSortParam = 'tipus';
var defaultSortDirection = 'asc';
var ZonesService = /** @class */ (function () {
    function ZonesService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
        this.displayedItems = [];
        this.displayedItems['checkedValues'] = 0;
        this.displayedItems['checkedItems'] = Array();
    }
    ZonesService.prototype.getZones = function () {
        if (this.authGuard.canActivate()) {
            var params = new ZonesFilterService().createFilterParams(new ZoneFilter('descripcio', 'asc'), 0, 99999, 'descripcio', 'asc');
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetZones'], params);
        }
    };
    ZonesService.prototype.setZones = function (zona) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetZones'], zona);
        }
    };
    ZonesService.prototype.deleteZones = function (secure_id) {
        console.log(secure_id);
        if (this.authGuard.canActivate()) {
            return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteZona'].replace('{secure_id}', secure_id), null);
        }
    };
    ZonesService.prototype.generateExcel = function (filter, sortDir, sortField) {
        var start = 0;
        var pageSize = 1000;
        sortField = sortField === undefined ? defaultSortParam : sortField;
        sortDir = sortDir === '' ? defaultSortDirection : sortDir;
        var params = new ZonesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);
        if (this.authGuard.canActivate()) {
            return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlZonesExcel'], params);
        }
    };
    ZonesService.prototype.loadZones = function (data) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlZonesLoad'], data);
        }
    };
    ZonesService.prototype.detalleZones = function (secure_id) {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDetalleZona'].replace('{secure_id}', secure_id), null);
        }
    };
    ZonesService.prototype.getZonesByCarrer = function (secureIdCarrer) {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetZonesByCarrer'].replace('{secureIdCarrer}', secureIdCarrer), null);
        }
    };
    ZonesService.prototype.getAllZonas = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllZonas'], null);
        }
    };
    ZonesService.ngInjectableDef = i0.defineInjectable({ factory: function ZonesService_Factory() { return new ZonesService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: ZonesService, providedIn: "root" });
    return ZonesService;
}());
export { ZonesService };
