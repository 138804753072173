import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-tirar-usuaris-ldap',
  templateUrl: './tirar-usuaris-ldap.component.html',
  styleUrls: ['./tirar-usuaris-ldap.component.scss']
})
export class TirarUsuarisLdapComponent implements OnInit {
  usuarios=[];
  constructor(public thisDialogRef: MatDialogRef<TirarUsuarisLdapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
      
      this.usuarios = Object.assign([], this.data.usuarisLdap);
     }

  ngOnInit() {
  }
incorporar(user){
  this.thisDialogRef.close(user);
}
close(){
  this.thisDialogRef.close('');
}
}
