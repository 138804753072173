import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';


@Component({
  selector: 'app-display-text-dialog',
  templateUrl: './display-text-dialog.component.html',
  styleUrls: ['./display-text-dialog.component.scss']
})
export class DisplayTextDialogComponent implements OnInit {

  @Input() text: string;
  @Input() type: string;
  @Input() label: string = null;
  src = null;
  constructor(
    public thisDialogRef: MatDialogRef<DisplayTextDialogComponent>,
    private toastr: ToastrService,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data: {text?: string, type?: string, label?: string},
  ) {
    if (data !== undefined) {
      this.type = data.type;
      this.text = data.text;
      this.label = data.label;
    }

  }

  ngOnInit() {
    if (this.type === 'json') {
      this.text = JSON.parse(this.text);
    }
  }
}
