<app-dialog-header i18n-label label="Cobrar"></app-dialog-header>


<div class="alert alert-danger" style=" text-align: center;" role="alert">
  <span i18n>Atención,hay varios importes para la fecha selecionada según el expediente. Seleccione una</span>
</div>


<div class="table-sdw-black center-content">
  <table class="table table-striped table-bordered">
    <thead>
        <tr style="color: black !important;">
            <th i18n><b>Seleccionar</b></th>
            <th i18n><b>Expediente</b></th>
            <th i18n><b>Importe</b></th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let row of SOM_C60_rows"  >
          <td (click)="SelectC60(row)"><mat-radio-button class="example-radio-button">
        
          </mat-radio-button>
        </td>
            <td>{{row.SOMEXPEDI}}</td>
            <td>{{row.IMPORT}}</td>
            <!-- <td>{{row.SOMC60IMP1}} €</td>
            <td>{{row.SOMFECLIM1 | shortDate}}</td>
             <td>{{row.SOMANULAD}}</td> 
            <td><app-form-check-edit [(value)]="row.SOMANULAD" disabled></app-form-check-edit></td>
            <td>{{row.SOMC60IMP2}} €</td>
            <td>{{row.SOMFECLIM2 | longDate}}</td>
            <td>{{row.SOMC60FINAL}}</td>
            <td>{{row.FECHA_CREACION | longDate}}</td> -->
        </tr>
    </tbody>
  </table>
</div>
<!-- <ul class="reports">
  <li *ngFor="let somc60 of SOM_C60_rows"
    (click)="SelectC60(somc60)">
    {{somc60.nom}}
  </li>
</ul> -->
