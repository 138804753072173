var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var ObservacionsPdaFilter = /** @class */ (function (_super) {
    __extends(ObservacionsPdaFilter, _super);
    function ObservacionsPdaFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.codi = null;
        _this.descripcio = '';
        _this.tipus = '';
        _this.labels['codi'] = _this.i18n('Codi');
        _this.labels['descripcio'] = _this.i18n('Descripció');
        _this.labels['tipus'] = _this.i18n('Tipo');
        return _this;
    }
    return ObservacionsPdaFilter;
}(FilterData));
export { ObservacionsPdaFilter };
