import { ZonesAparcareService } from './../../../tiquets/zones-aparcare/services/zones-aparcare.service';
import { InstitucionsService } from 'path-shared/services/institucions/institucions.service';
import { FiltroTiempoEstadisticas } from './../../models/filtro-tiempo.model';
import { FiltroAparcareTipo, FiltroAparcareTipoValor } from './../../models/filtro-aparcare-tipo.model';
import { Component, OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { EstadistiquesService } from '../../services/estadistiques.service';
import { EstadisticaDto } from '../../models/estadisticaDto';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { MatDialog } from '@angular/material';
import { ZonaAparcare } from 'app/sections/tiquets/zones-aparcare/models/zona-aparcare.model';

@Component({
  selector: 'app-estadisitcas-aparcare',
  templateUrl: './estadisitcas-aparcare.component.html',
  styleUrls: ['./estadisitcas-aparcare.component.scss']
})
export class EstadisitcasAparcareComponent implements OnInit {

  // config grafico
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  colors = [
    { // ventas
      backgroundColor: 'rgba(0, 204, 102, 0.8)'
    },
    { // devol
      backgroundColor: 'rgba(255, 99, 132, 0.8)'
    },
    { // anul
      backgroundColor: 'rgba(54, 162, 235, 0.8)'
    }
  ];
  colorsDevol = [
    { // devol
      backgroundColor: 'rgba(255, 99, 132, 0.8)'
    }
  ];
  colorsAnul = [
    { // anul
      backgroundColor: 'rgba(54, 162, 235, 0.8)'
    }
  ];
  public barChartType: ChartType = 'bar';
  barChartData: ChartDataSets[];
  barChartDataMultiLine: ChartDataSets[];
  barChartLabels: any;
  public dataRecived = false;
  public dateSelected: Date =  new Date();
  public tipos = [{label: FiltroAparcareTipo.Ventas}, {label: FiltroAparcareTipo.Anulaciones}, {label: FiltroAparcareTipo.Devoluciones}, {label: FiltroAparcareTipo.Todos}];
  public tipoSelected = FiltroAparcareTipo.Ventas;
  public tiposValor = [{label: FiltroAparcareTipoValor.Importe}, {label: FiltroAparcareTipoValor.Cantidad}];
  public tipoValorSelected = FiltroAparcareTipoValor.Importe;
  public showMultiLine = false;
  public tipoConsultado: string;
  public tiposTemporizacion = [ {label: FiltroTiempoEstadisticas.Anual}, {label: FiltroTiempoEstadisticas.Mensual}, {label: FiltroTiempoEstadisticas.Semanal}, {label: FiltroTiempoEstadisticas.Diario}];
  public tipoTemporizacionSelected = FiltroTiempoEstadisticas.Mensual;
  public allTipos = FiltroAparcareTipo;
  public datos: EstadisticaDto = {chartDataSets: [], chartLabels: [], chartColors: []};
  public aparcareText = 'Aparcare';
  public zonaSelected: string;
  public zonas: ZonaAparcare[];
  constructor(private estadistiquesService: EstadistiquesService,
    private institutionService: InstitucionsService,
    private zonesAparcareService: ZonesAparcareService,
    private dialog: MatDialog,
    private i18n: I18n,
    public auth: AuthorizationService) {
      this.barChartLabels = [];
      this.barChartData = [];
      this.barChartDataMultiLine = [];

      const institution = this.institutionService.getInstitution();
      if (institution !== null && institution !== undefined) {
        if (institution.IdInstitucion === '100105229425938306122') {
          this.aparcareText = 'Apparquem';
        } else {
          this.aparcareText = 'Aparcare';
        }
      }
      this.zonesAparcareService.getAllZonas().subscribe(x => {
        this.zonas = x;
      });
    }

  ngOnInit() {
    // this.reload();
  }
  restartFilter() {
    this.zonaSelected = '';
  }
  reload() {
    let filter = {tipo: this.tipoSelected, tipoValor: this.tipoValorSelected, fecha: this.dateSelected, tipoTemporizacion: this.tipoTemporizacionSelected, zonaSecureId: null};
    if (this.tipoSelected === FiltroAparcareTipo.Ventas &&  this.zonaSelected !== undefined && this.zonaSelected !== null && this.zonaSelected !== '') {
      filter.zonaSecureId = this.zonaSelected;
    }
    this.tipoConsultado = this.tipoSelected;
    this.estadistiquesService.getEstadistiquesAparcare(filter).subscribe(x => {

      this.dataRecived = true;
      this.datos = x;
      this.barChartData = [];
      Object.assign([], this.barChartData);
      this.barChartDataMultiLine = [];
      Object.assign([], this.barChartDataMultiLine);
      // if ( !this.barChartLabels ) {
      //   this.barChartLabels = [];
      // }
      // this.barChartLabels.length = 0;
      // this.barChartLabels.push(...Object.values(this.datos.chartLabels.barChartLabels));
      if ( !this.barChartLabels ) {
        this.barChartLabels = [];
      }
      this.barChartLabels.length = 0;
      this.barChartLabels.push(...Object.values(this.datos.chartLabels.barChartLabels));

      this.barChartData.length = 0;
      this.barChartDataMultiLine.length = 0;
      if (x.chartDataSets.length > 1) {
        this.showMultiLine = true;
        x.chartDataSets.forEach(element => {
          this.barChartDataMultiLine.push({
            data: element.data, label: element.label
          });
        });
      } else {
        this.showMultiLine = false;
        this.barChartData.push({data: x.chartDataSets[0].data, label: x.chartDataSets[0].label}
          );
      }
      // Object.assign(tempObj, this.barChartData);

      // this.barChartData.push({
      //   data: x.chartDataSets.data, label: x.chartDataSets.label
      // });
      // this.barChartData= this.datos.chartDataSets;
      // this.barChartLabels= this.datos.chartLabels;
    });
  }
  canSelectZona(): boolean {
    if (this.tipoSelected === FiltroAparcareTipo.Ventas) {
      return true;
    } else {
      this.zonaSelected = '';
      return false;
    }
  }
  onDateChange(): void {

    this.reload();
  }
  getColors(): any {
    switch (this.tipoConsultado) {
      case FiltroAparcareTipo.Ventas: {
        return this.colors;
      }
      case FiltroAparcareTipo.Anulaciones: {
        return this.colorsAnul;
      }
      case FiltroAparcareTipo.Devoluciones: {
        return this.colorsDevol;
    }
      default: {
        return this.colors;
      }
  }
  }

  generateExcel(): void {
    let filter = {tipo: this.tipoSelected, fecha: this.dateSelected, tipoTemporizacion: this.tipoTemporizacionSelected, zonaSecureId: null};
    if (this.tipoSelected === FiltroAparcareTipo.Ventas &&  this.zonaSelected !== undefined && this.zonaSelected !== null && this.zonaSelected !== '') {
      filter.zonaSecureId = this.zonaSelected;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogDataGeneral (this.estadistiquesService, filter, this.i18n('Estadisiques'))
    });
  }
}
