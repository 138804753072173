var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
var defaultSortParam = 'id';
var defaultSortDirection = 'desc';
var TiquetsClientFilterService = /** @class */ (function (_super) {
    __extends(TiquetsClientFilterService, _super);
    function TiquetsClientFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TiquetsClientFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        var fechaTicketInicio = '';
        var fechaTicketFin = '';
        if (filter.fechaTicketInicio !== null) {
            fechaTicketInicio = this.formatDateToDecimal(this.stringToDate(filter.fechaTicketInicio));
        }
        if (filter.fechaTicketFin !== null) {
            fechaTicketFin = this.formatDateToDecimal(this.stringToDate(filter.fechaTicketFin), false);
        }
        var httpParams = new HttpParams()
            .append('id', filter.id ? filter.id.toString() : '')
            .append('matricula', filter.matricula)
            .append('email', filter.email)
            .append('zona', filter.zona ? filter.zona.toString() : '')
            .append('proveidors', filter.proveidors)
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
        httpParams = httpParams
            .append('fechaTicketInicio', fechaTicketInicio)
            .append('fechaTicketFin', fechaTicketFin);
        return httpParams;
    };
    TiquetsClientFilterService.prototype.formatDateToDecimal = function (date, isFechaIni) {
        if (isFechaIni === void 0) { isFechaIni = true; }
        var month;
        var day;
        var hhmmss;
        month = (date.getMonth() + 1).toString();
        if (month.length === 1) {
            month = '0' + month;
        }
        day = date.getDate().toString();
        if (day.length === 1) {
            day = '0' + day;
        }
        if (isFechaIni) {
            hhmmss = '000000';
        }
        else {
            hhmmss = '235959';
        }
        return date.getFullYear().toString()
            + month
            + day
            + hhmmss;
    };
    TiquetsClientFilterService.prototype.stringToDate = function (value) {
        if (!this.isDate(value)) {
            value = new Date(value);
        }
        return value;
    };
    TiquetsClientFilterService.prototype.isDate = function (value) {
        return value instanceof Date;
    };
    TiquetsClientFilterService.ngInjectableDef = i0.defineInjectable({ factory: function TiquetsClientFilterService_Factory() { return new TiquetsClientFilterService(); }, token: TiquetsClientFilterService, providedIn: "root" });
    return TiquetsClientFilterService;
}(CustomFilterService));
export { TiquetsClientFilterService };
