import { OnInit, EventEmitter } from '@angular/core';
var ToolDropdownButtonComponent = /** @class */ (function () {
    function ToolDropdownButtonComponent() {
        this.click1 = new EventEmitter();
        this.click2 = new EventEmitter();
        this.click3 = new EventEmitter();
        this.iconClass = 'fa fa-question';
        this.propagate = false;
        this.badgeColor = 'badge-primary';
        this.badge = '';
        this.badgeActive = false;
        this.isFile = false;
        this.numeroSubmenus = 0;
    }
    Object.defineProperty(ToolDropdownButtonComponent.prototype, "disabled", {
        get: function () {
            return this.disabledValue;
        },
        set: function (dis) {
            this.disabledValue = dis;
            this.color = this.colorValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolDropdownButtonComponent.prototype, "color", {
        get: function () {
            return this.colorValue;
        },
        set: function (col) {
            this.colorValue = col;
            this.colorClass = 'actions-bar__button-icon';
            if (this.colorValue !== undefined) {
                if (!this.disabled) {
                    this.colorClass += ' ' + this.colorValue;
                }
                else {
                    this.colorClass += ' has-color-gray';
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ToolDropdownButtonComponent.prototype.ngOnInit = function () {
        this.badgeClass = 'badge badge-pill badge-pill-floating-right ';
        if (this.iconClass !== undefined) {
            this.iconClass = 'fa ' + this.icon;
        }
        if (this.isFile === null || this.isFile === undefined || this.isFile === false) {
            this.tipuCamp = 'hidden';
        }
        else {
            this.tipuCamp = 'file';
        }
    };
    ToolDropdownButtonComponent.prototype.clickEvent = function (event, boto) {
        if (!this.propagate) {
            event.stopPropagation();
        }
        if (!this.disabled) {
            if (this.numeroSubmenus >= 1 && boto === 'boto1') {
                this.click1.emit(event);
            }
            if (this.numeroSubmenus >= 2 && boto === 'boto2') {
                this.click2.emit(event);
            }
            if (this.numeroSubmenus >= 3 && boto === 'boto3') {
                this.click3.emit(event);
            }
        }
    };
    return ToolDropdownButtonComponent;
}());
export { ToolDropdownButtonComponent };
