var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var SomPolGruaFilter = /** @class */ (function (_super) {
    __extends(SomPolGruaFilter, _super);
    function SomPolGruaFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.codi = null;
        _this.dataPropostaPda = null;
        _this.dataInici = null;
        _this.dataFinal = null;
        _this.dataFinalitzacio = null;
        _this.idVehiclesFK = '';
        _this.idAgentFk = '';
        _this.numCarrer = '';
        _this.nombreCalle = '';
        _this.valida = null;
        _this.observacions = '';
        _this.idInfraccioFK = '';
        _this.latitud = null;
        _this.longitud = null;
        _this.finalitzada = null;
        _this.numPDA = null;
        _this.origen = '';
        _this.revisada = null;
        _this.implicaDenuncia = null;
        _this.idTecnicFK = '';
        _this.idMotiuDesestimada = '';
        _this.fechaAcepDenegInici = null;
        _this.fechaAcepDenegFinal = null;
        _this.numFotos = null;
        _this.sinCargo = null;
        _this.labels['codi'] = _this.i18n('Codi');
        _this.labels['dataPropostaPda'] = _this.i18n('Data proposta PDA');
        _this.labels['dataInici'] = _this.i18n('Data inici');
        _this.labels['dataFinal'] = _this.i18n('Data final');
        _this.labels['dataFinalitzacio'] = _this.i18n('Data finalització');
        _this.labels['idVehiclesFK'] = _this.i18n('Matricula');
        _this.labels['idAgentFk'] = _this.i18n('Agent');
        _this.labels['nombreCalle'] = _this.i18n('Carrer');
        _this.labels['numCarrer'] = _this.i18n('Núm carrer');
        _this.labels['valida'] = _this.i18n('Vàlida');
        _this.labels['observacions'] = _this.i18n('Observacions');
        _this.labels['idInfraccioFK'] = _this.i18n('Infracció');
        _this.labels['latitud'] = _this.i18n('Latitud');
        _this.labels['longitud'] = _this.i18n('Longitud');
        _this.labels['finalitzada'] = _this.i18n('Finalitzada');
        _this.labels['numPDA'] = _this.i18n('Núm PDA');
        _this.labels['origen'] = _this.i18n('Origen');
        _this.labels['revisada'] = _this.i18n('Revisada');
        _this.labels['implicaDenuncia'] = _this.i18n('Implica Denúncia');
        _this.labels['idTecnicFK'] = _this.i18n('Tecnic');
        _this.labels['idMotiuDesestimada'] = _this.i18n('Motiu desestimar');
        _this.labels['fechaAcepDenegInici'] = _this.i18n('Data inici Acep/Deng');
        _this.labels['fechaAcepDenegFinal'] = _this.i18n('Data final Acep/Deng');
        _this.labels['numFotos'] = _this.i18n('Núm fotos');
        _this.labels['sinCargo'] = _this.i18n('Sense càrrec');
        _this.formatOutput['fechaTicketInicio'] = 'shortDate';
        _this.formatOutput['fechaTicketFin'] = 'shortDate';
        _this.formatOutput['fechaBancoInicio'] = 'shortDate';
        _this.formatOutput['fechaBancoFin'] = 'shortDate';
        return _this;
    }
    return SomPolGruaFilter;
}(FilterData));
export { SomPolGruaFilter };
