<form #mainForm>
  <div class ="border border-secondary fondo" style="padding:10px;">
    <div class="row">
      <app-form-text-edit (valueChange)="this.Reset()" [(value)]="this.dto.matricula" id="cambio-matricula-matricula" class="col-9" i18n-label label="Matrícula"   required></app-form-text-edit>
      <app-view-list-icon hideView="true" style="right: 15px;" class="col-3" (listClick)="this.consultarMatricula()" ></app-view-list-icon>
    </div>
    
    <div class="row">
      <app-form-combo-edit id="cambio-matricula-marca" (valueChange)="this.marcaSeleccionado($event)" class="col-xl-12" i18n-label label="Marca" [(value)]="this.dto.marca" [items]="this.marcas" textField="descripcio" valueField="descripcio"></app-form-combo-edit>
    </div>
    
    <div class="row">
      <app-form-combo-edit id="cambio-matricula-modelo" class="col-xl-12" i18n-label label="Modelo" [(value)]="this.dto.modelo" [items]="this.modelos" textField="descripcio" valueField="descripcio"></app-form-combo-edit>
    </div>
    <div class="row">
      <app-form-combo-edit id="cambio-matricula-color" class="col-xl-12" i18n-label label="Color" [(value)]="this.dto.color" [items]="this.colores" textField="descripcio" valueField="descripcio"></app-form-combo-edit>
    </div>
  </div>
</form>
