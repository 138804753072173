/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./utilitats.component";
var styles_UtilitatsComponent = [];
var RenderType_UtilitatsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UtilitatsComponent, data: {} });
export { RenderType_UtilitatsComponent as RenderType_UtilitatsComponent };
export function View_UtilitatsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" utilitats works!\n"]))], null, null); }
export function View_UtilitatsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-utilitats", [], null, null, null, View_UtilitatsComponent_0, RenderType_UtilitatsComponent)), i0.ɵdid(1, 114688, null, 0, i1.UtilitatsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UtilitatsComponentNgFactory = i0.ɵccf("app-utilitats", i1.UtilitatsComponent, View_UtilitatsComponent_Host_0, {}, {}, []);
export { UtilitatsComponentNgFactory as UtilitatsComponentNgFactory };
