import { InfraccioService } from './../../../configuracio/Infraccions/services/infraccions-service.service';
import { ZonesService } from './../../../ciutat/zones/services/zones.service';
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { InstitucionsService } from 'path-shared/services/institucions/institucions.service';
import { FiltroTiempoEstadisticas } from '../../models/filtro-tiempo.model';
import { EstadistiquesService } from '../../services/estadistiques.service';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
var EstadisticasDenunciasComponent = /** @class */ (function () {
    function EstadisticasDenunciasComponent(estadistiquesService, institutionService, authService, zonasService, infraccionsService, agentsService, dialog, i18n, auth) {
        var _this = this;
        this.estadistiquesService = estadistiquesService;
        this.institutionService = institutionService;
        this.authService = authService;
        this.zonasService = zonasService;
        this.infraccionsService = infraccionsService;
        this.agentsService = agentsService;
        this.dialog = dialog;
        this.i18n = i18n;
        this.auth = auth;
        //config grafico
        this.barChartOptions = {
            responsive: true,
        };
        this.colors = [
            {
                backgroundColor: 'rgba(0, 204, 102, 0.8)'
            },
            {
                backgroundColor: 'rgba(255, 99, 132, 0.8)'
            },
            {
                backgroundColor: 'rgba(54, 162, 235, 0.8)'
            }
        ];
        this.colorsDevol = [
            {
                backgroundColor: 'rgba(255, 99, 132, 0.8)'
            }
        ];
        this.colorsAnul = [
            {
                backgroundColor: 'rgba(54, 162, 235, 0.8)'
            }
        ];
        this.barChartType = 'bar';
        this.dataRecived = false;
        this.dateSelected = new Date();
        //public tipos = [{label:FiltroAparcareTipo.Ventas},{label:FiltroAparcareTipo.Anulaciones},{label:FiltroAparcareTipo.Devoluciones}, {label:FiltroAparcareTipo.Todos}];
        //public tipoSelected:string =FiltroAparcareTipo.Ventas;
        this.showMultiLine = false;
        this.tiposTemporizacion = [{ label: FiltroTiempoEstadisticas.Anual }, { label: FiltroTiempoEstadisticas.Mensual }, { label: FiltroTiempoEstadisticas.Semanal }, { label: FiltroTiempoEstadisticas.Diario }];
        this.tipoTemporizacionSelected = FiltroTiempoEstadisticas.Mensual;
        //public allTipos= FiltroAparcareTipo;
        this.datos = { chartDataSets: [], chartLabels: [], chartColors: [] };
        this.isZonaAzul = false;
        this.agents = [];
        this.anuladaItems = [];
        this.barChartLabels = [];
        this.barChartData = [];
        this.barChartDataMultiLine = [];
        this.isZonaAzul = this.authService.CheckUserRole(this.authService.Roles.ZonaBlava);
        this.anuladaItems.push({ value: true, text: 'Sí' });
        this.anuladaItems.push({ value: false, text: 'No' });
        this.zonasService.getAllZonas().subscribe(function (x) {
            if (x.some(function (z) { return z.ID_ZONA_PADRE !== null; })) {
                _this.zonas = x.filter(function (zona) { return zona.ID_ZONA_PADRE !== null; });
            }
            else {
                _this.zonas = x;
            }
            _this.zonas.unshift(Builder.newZona());
        });
        this.infraccionsService.getInfraccionsDenunciesCombo().subscribe(function (result) {
            _this.infraccionesDenuncies = result;
            _this.infraccionesDenunciesAll = _this.infraccionesDenuncies;
        });
        this.agentsService.getAllCombo().subscribe(function (result) {
            _this.agents = [];
            if (result !== null) {
                var i_1;
                i_1 = 0;
                result.forEach(function (element) {
                    _this.agents[i_1] = element;
                    i_1++;
                });
            }
            _this.agents.unshift({ 'codi': '' });
        });
    }
    EstadisticasDenunciasComponent.prototype.ngOnInit = function () {
        //this.reload();
    };
    EstadisticasDenunciasComponent.prototype.reload = function () {
        var _this = this;
        var filter = this.getFilter();
        this.estadistiquesService.getEstadistiquesDenuncias(filter).subscribe(function (x) {
            var _a;
            _this.dataRecived = true;
            _this.datos = x;
            _this.barChartData = [];
            Object.assign([], _this.barChartData);
            _this.barChartDataMultiLine = [];
            Object.assign([], _this.barChartDataMultiLine);
            if (!_this.barChartLabels) {
                _this.barChartLabels = [];
            }
            _this.barChartLabels.length = 0;
            (_a = _this.barChartLabels).push.apply(_a, Object.values(_this.datos.chartLabels.barChartLabels));
            _this.barChartData.length = 0;
            _this.barChartDataMultiLine.length = 0;
            if (x.chartDataSets.length > 1) {
                _this.showMultiLine = true;
                x.chartDataSets.forEach(function (element) {
                    _this.barChartDataMultiLine.push({
                        data: element.data, label: element.label
                    });
                });
            }
            else {
                _this.showMultiLine = false;
                _this.barChartData.push({ data: x.chartDataSets[0].data, label: x.chartDataSets[0].label });
            }
        });
    };
    EstadisticasDenunciasComponent.prototype.canSelectZona = function () {
        // if(this.tipoSelected === FiltroAparcareTipo.Ventas){
        return true;
        // }else{
        //   this.zonaSelected='';
        //   return false;
        // }
    };
    EstadisticasDenunciasComponent.prototype.onDateChange = function () {
        this.reload();
    };
    EstadisticasDenunciasComponent.prototype.getFilter = function () {
        var filter = { fecha: this.dateSelected, tipoTemporizacion: this.tipoTemporizacionSelected, zonaSecureId: null, infraccion: null, agente: null, anulada: null };
        if (this.zonaSelected !== undefined && this.zonaSelected !== null && this.zonaSelected !== '') {
            filter.zonaSecureId = this.zonaSelected;
        }
        if (this.infraccionSelected !== undefined && this.infraccionSelected !== null && this.infraccionSelected !== '') {
            filter.infraccion = this.infraccionSelected;
        }
        if (this.agenteSelected !== undefined && this.agenteSelected !== null && this.agenteSelected !== '') {
            filter.agente = this.agenteSelected;
        }
        if (this.anuladaSelected !== undefined && this.anuladaSelected !== null) {
            filter.anulada = this.anuladaSelected;
        }
        return filter;
    };
    EstadisticasDenunciasComponent.prototype.generateExcel = function () {
        var filter = this.getFilter();
        this.dialog.open(DownloadExcelDialogComponent, {
            closeOnNavigation: false,
            autoFocus: true,
            data: Builder.newDownloadExcelDialogDataGeneral(this.estadistiquesService, filter, this.i18n('Denuncias'))
        });
    };
    EstadisticasDenunciasComponent.prototype.restartFilter = function () {
        this.zonaSelected = '';
        this.infraccionSelected = '';
        this.agenteSelected = '';
        this.anuladaSelected = null;
    };
    EstadisticasDenunciasComponent.prototype.filterInfracciones = function () {
        if (this.zonaSelected !== undefined) {
            var zonaCleaned_1 = this.zonaSelected.split('|')[0];
            this.infraccionesDenuncies = this.infraccionesDenunciesAll.filter(function (x) { return x.additionalData === zonaCleaned_1 || x.additionalData === null; });
        }
        else {
            this.infraccionesDenuncies = this.infraccionesDenunciesAll;
        }
    };
    return EstadisticasDenunciasComponent;
}());
export { EstadisticasDenunciasComponent };
