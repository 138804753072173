/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./estadistiques-reserves-aparcare.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng2-charts/charts/charts";
import * as i3 from "../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i4 from "../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i5 from "../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component.ngfactory";
import * as i6 from "../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component";
import * as i7 from "../../../../shared/services/authorization/authorization-service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i10 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i11 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i12 from "../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i13 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i14 from "@angular/common";
import * as i15 from "./estadistiques-reserves-aparcare.component";
import * as i16 from "../../services/estadistiques.service";
import * as i17 from "@ngx-translate/i18n-polyfill";
import * as i18 from "../../../../shared/services/notification/notification.service";
var styles_EstadistiquesReservesAparcareComponent = [i0.styles];
var RenderType_EstadistiquesReservesAparcareComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EstadistiquesReservesAparcareComponent, data: {} });
export { RenderType_EstadistiquesReservesAparcareComponent as RenderType_EstadistiquesReservesAparcareComponent };
function View_EstadistiquesReservesAparcareComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "col-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "canvas", [["baseChart", ""]], null, null, null, null, null)), i1.ɵdid(4, 737280, [[1, 4]], 0, i2.BaseChartDirective, [i1.ElementRef], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], legend: [4, "legend"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "col-1"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.barChartData; var currVal_1 = _co.barChartLabels; var currVal_2 = _co.barChartOptions; var currVal_3 = _co.barChartType; var currVal_4 = _co.barChartLegend; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_EstadistiquesReservesAparcareComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { chart: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-toolbar", [], null, null, null, i3.View_ToolbarComponent_0, i3.RenderType_ToolbarComponent)), i1.ɵdid(2, 114688, null, 0, i4.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "app-tool-button-permissions", [], null, null, null, i5.View_ToolButtonPermissionsComponent_0, i5.RenderType_ToolButtonPermissionsComponent)), i1.ɵdid(4, 114688, null, 0, i6.ToolButtonPermissionsComponent, [i7.AuthorizationService, i8.MatDialog], { permission: [0, "permission"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "row justify-content-around"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 3, "app-form-date-edit", [["class", "col-4"], ["id", "estadistiques-reserves-aparcare-data"], ["label", "Data"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.onDateChange() !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.dateSelected = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_FormDateEditComponent_0, i9.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i10.DateTimeAdapter, i11.MomentDateTimeAdapter, [i10.OWL_DATE_TIME_LOCALE]), i1.ɵdid(8, 114688, null, 0, i12.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"], id: [3, "id"], view: [4, "view"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i13.OWL_DATE_TIME_FORMATS, i12.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EstadistiquesReservesAparcareComponent_1)), i1.ɵdid(14, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = (_co.auth.Pages.Estadistiques && _co.auth.Pages.Reserves); _ck(_v, 4, 0, currVal_0); var currVal_1 = "Data"; var currVal_2 = _co.dateSelected; var currVal_3 = "col-4"; var currVal_4 = "estadistiques-reserves-aparcare-data"; var currVal_5 = "month"; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = (_co.barChartLabels && _co.barChartData); _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.title; _ck(_v, 12, 0, currVal_6); }); }
export function View_EstadistiquesReservesAparcareComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-estadistiques-reserves-aparcare", [], null, null, null, View_EstadistiquesReservesAparcareComponent_0, RenderType_EstadistiquesReservesAparcareComponent)), i1.ɵdid(1, 4308992, null, 0, i15.EstadistiquesReservesAparcareComponent, [i16.EstadistiquesService, i17.I18n, i7.AuthorizationService, i18.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EstadistiquesReservesAparcareComponentNgFactory = i1.ɵccf("app-estadistiques-reserves-aparcare", i15.EstadistiquesReservesAparcareComponent, View_EstadistiquesReservesAparcareComponent_Host_0, {}, {}, []);
export { EstadistiquesReservesAparcareComponentNgFactory as EstadistiquesReservesAparcareComponentNgFactory };
