import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { MatDialog } from '@angular/material';
import { TipoSenalesVerticalesFilter } from '../models/tipo-senales-verticales.filter';
import { TipoSenalesVerticalesTableComponent } from '../components/tipo-senales-verticales-table/tipo-senales-verticales-table.component';
import { TipoSenalesVerticalesEditorComponent } from '../components/tipo-senales-verticales-editor/tipo-senales-verticales-editor.component';
import { TipoSenalesVerticalesFilterComponent } from '../components/tipo-senales-verticales-filter/tipo-senales-verticales-filter.component';

@Component({
  selector: 'app-tipos-senales-verticales-page',
  templateUrl: './tipos-senales-verticales-page.component.html',
  styleUrls: ['./tipos-senales-verticales-page.component.scss']
})
export class TiposSenalesVerticalesPageComponent implements OnInit {

  filterData: TipoSenalesVerticalesFilter;
  @ViewChild(TipoSenalesVerticalesTableComponent) tipoSenalesTable: TipoSenalesVerticalesTableComponent;


  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n) {
    this.filterData = new TipoSenalesVerticalesFilter('id', 'desc');
  }

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.SenyalsVerticals);

    this.tipoSenalesTable.itemClicked.subscribe(secureId => {
      this.update(secureId);
    });
  }

  ngAfterViewInit() { }

  edit() {
    const idSeleccionat: string = this.tipoSenalesTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secureId: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secureId);

    const ref = this.dialog.open(TipoSenalesVerticalesEditorComponent, {
      width: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) {
        this.tipoSenalesTable.load();
      }
    });
  }

  refresh() {
    this.tipoSenalesTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(TipoSenalesVerticalesFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.tipoSenalesTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: TipoSenalesVerticalesFilter) => {
      this.tipoSenalesTable.filter.set(filter);
      this.tipoSenalesTable.load();
    });
  }

  onRowSelected(secureId: string) {
    this.update(secureId);
  }

  generateExcel() {
    this.tipoSenalesTable.generateExcel();
  }

}
