/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tirar-usuaris-ldap.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "../../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i5 from "../../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i6 from "../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i7 from "../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i8 from "../../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i9 from "../../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i10 from "@angular/common";
import * as i11 from "./tirar-usuaris-ldap.component";
import * as i12 from "@angular/material/dialog";
var styles_TirarUsuarisLdapComponent = [i0.styles];
var RenderType_TirarUsuarisLdapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TirarUsuarisLdapComponent, data: {} });
export { RenderType_TirarUsuarisLdapComponent as RenderType_TirarUsuarisLdapComponent };
function View_TirarUsuarisLdapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "mat-list-item", [["class", "row card mat-list-item"], ["style", "margin-bottom: 0rem !important;"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleBlur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1097728, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵeld(5, 0, null, 2, 1, "button", [["class", "btn btn-primary col-12"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.incorporar(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 6, 0, currVal_2); }); }
export function View_TirarUsuarisLdapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["label", "Incorporar"]], null, null, null, i4.View_DialogHeaderComponent_0, i4.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i5.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "app-toolbar", [], null, null, null, i6.View_ToolbarComponent_0, i6.RenderType_ToolbarComponent)), i1.ɵdid(3, 114688, null, 0, i7.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "app-tool-button", [["color", "has-color-yellow"], ["icon", "fa-times"], ["text", "Tancar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ToolButtonComponent_0, i8.RenderType_ToolButtonComponent)), i1.ɵdid(5, 114688, null, 0, i9.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(6, 0, null, null, 5, "mat-list", [["class", "mat-list"]], null, null, null, i2.View_MatList_0, i2.RenderType_MatList)), i1.ɵdid(7, 49152, null, 0, i3.MatList, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TirarUsuarisLdapComponent_1)), i1.ɵdid(9, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "mat-list", [["class", "mat-list"]], null, null, null, i2.View_MatList_0, i2.RenderType_MatList)), i1.ɵdid(11, 49152, null, 0, i3.MatList, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Incorporar"; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); var currVal_1 = "Tancar"; var currVal_2 = "has-color-yellow"; var currVal_3 = "fa-times"; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.usuarios; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_TirarUsuarisLdapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tirar-usuaris-ldap", [], null, null, null, View_TirarUsuarisLdapComponent_0, RenderType_TirarUsuarisLdapComponent)), i1.ɵdid(1, 114688, null, 0, i11.TirarUsuarisLdapComponent, [i12.MatDialogRef, i12.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TirarUsuarisLdapComponentNgFactory = i1.ɵccf("app-tirar-usuaris-ldap", i11.TirarUsuarisLdapComponent, View_TirarUsuarisLdapComponent_Host_0, {}, {}, []);
export { TirarUsuarisLdapComponentNgFactory as TirarUsuarisLdapComponentNgFactory };
