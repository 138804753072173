<app-dialog-header i18n-label label="Accions expedient" [isCloseButton]="false"></app-dialog-header>

<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <div class="col">
        {{ message }}
      </div>
    </div>
    <div class="row">
      <!-- Motiu -->
      <app-form-text-edit id="denuncia-pagament-motiu" class="col-sm-12" i18n-label label="Motiu" [(value)]="this.motiu"></app-form-text-edit>
    </div>
  </form>
</app-dialog-body>

<app-dialog-footer>
  <button type="button" class="btn btn-primary" (click)="cobrarITancarExpedient()">{{ cobrarTancarExpedient }}</button>
  <button type="button" class="btn btn-danger" (click)="cobrarIMantenirExpedientObert()">{{ cobrarMantenirExpedient }}</button>
</app-dialog-footer>