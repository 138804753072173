/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cambio-matricula.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i4 from "../../../../../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i5 from "../../../../../../../../shared/services/validation/validation.service";
import * as i6 from "../../../../../../../../shared/components/helpers/view-list-icon/view-list-icon.component.ngfactory";
import * as i7 from "../../../../../../../../shared/components/helpers/view-list-icon/view-list-icon.component";
import * as i8 from "../../../../../../../../shared/components/form/form-combo-edit/form-combo-edit.component.ngfactory";
import * as i9 from "../../../../../../../../shared/components/form/form-combo-edit/form-combo-edit.component";
import * as i10 from "./cambio-matricula.component";
import * as i11 from "../../../../../../../ciutat/vehicles/services/vehicles.service";
import * as i12 from "@angular/material/dialog";
import * as i13 from "../../../../../../../../shared/components/confirmation-dialog/confirmation-dialog.service";
import * as i14 from "@ngx-translate/i18n-polyfill";
import * as i15 from "ngx-toastr";
import * as i16 from "../../../../../../../../shared/services/form/form.service";
var styles_CambioMatriculaComponent = [i0.styles];
var RenderType_CambioMatriculaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CambioMatriculaComponent, data: {} });
export { RenderType_CambioMatriculaComponent as RenderType_CambioMatriculaComponent };
export function View_CambioMatriculaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mainForm: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["mainForm", 1]], null, 19, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(3, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 14, "div", [["class", "border border-secondary fondo"], ["style", "padding:10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-form-text-edit", [["class", "col-9"], ["id", "cambio-matricula-matricula"], ["label", "Matr\u00EDcula"], ["required", ""]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.Reset() !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.dto.matricula = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_FormTextEditComponent_0, i3.RenderType_FormTextEditComponent)), i1.ɵdid(9, 4308992, null, 0, i4.FormTextEditComponent, [i5.ValidationService], { label: [0, "label"], required: [1, "required"], value: [2, "value"], class: [3, "class"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-view-list-icon", [["class", "col-3"], ["hideView", "true"], ["style", "right: 15px;"]], null, [[null, "listClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("listClick" === en)) {
        var pd_0 = (_co.consultarMatricula() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ViewListIconComponent_0, i6.RenderType_ViewListIconComponent)), i1.ɵdid(11, 114688, null, 0, i7.ViewListIconComponent, [], { hideView: [0, "hideView"] }, { listClick: "listClick" }), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-form-combo-edit", [["class", "col-xl-12"], ["id", "cambio-matricula-marca"], ["label", "Marca"], ["textField", "descripcio"], ["valueField", "descripcio"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.marcaSeleccionado($event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.dto.marca = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_FormComboEditComponent_0, i8.RenderType_FormComboEditComponent)), i1.ɵdid(14, 4308992, null, 0, i9.FormComboEditComponent, [i5.ValidationService], { label: [0, "label"], value: [1, "value"], items: [2, "items"], valueField: [3, "valueField"], textField: [4, "textField"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-form-combo-edit", [["class", "col-xl-12"], ["id", "cambio-matricula-modelo"], ["label", "Model"], ["textField", "descripcio"], ["valueField", "descripcio"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.dto.modelo = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormComboEditComponent_0, i8.RenderType_FormComboEditComponent)), i1.ɵdid(17, 4308992, null, 0, i9.FormComboEditComponent, [i5.ValidationService], { label: [0, "label"], value: [1, "value"], items: [2, "items"], valueField: [3, "valueField"], textField: [4, "textField"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-form-combo-edit", [["class", "col-xl-12"], ["id", "cambio-matricula-color"], ["label", "Color"], ["textField", "descripcio"], ["valueField", "descripcio"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.dto.color = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormComboEditComponent_0, i8.RenderType_FormComboEditComponent)), i1.ɵdid(20, 4308992, null, 0, i9.FormComboEditComponent, [i5.ValidationService], { label: [0, "label"], value: [1, "value"], items: [2, "items"], valueField: [3, "valueField"], textField: [4, "textField"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = "Matr\u00EDcula"; var currVal_8 = ""; var currVal_9 = _co.dto.matricula; var currVal_10 = "col-9"; var currVal_11 = "cambio-matricula-matricula"; _ck(_v, 9, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = "true"; _ck(_v, 11, 0, currVal_12); var currVal_13 = "Marca"; var currVal_14 = _co.dto.marca; var currVal_15 = _co.marcas; var currVal_16 = "descripcio"; var currVal_17 = "descripcio"; _ck(_v, 14, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); var currVal_18 = "Model"; var currVal_19 = _co.dto.modelo; var currVal_20 = _co.modelos; var currVal_21 = "descripcio"; var currVal_22 = "descripcio"; _ck(_v, 17, 0, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22); var currVal_23 = "Color"; var currVal_24 = _co.dto.color; var currVal_25 = _co.colores; var currVal_26 = "descripcio"; var currVal_27 = "descripcio"; _ck(_v, 20, 0, currVal_23, currVal_24, currVal_25, currVal_26, currVal_27); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_CambioMatriculaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cambio-matricula", [], null, null, null, View_CambioMatriculaComponent_0, RenderType_CambioMatriculaComponent)), i1.ɵdid(1, 114688, null, 0, i10.CambioMatriculaComponent, [i11.VehiclesService, i12.MatDialog, i13.ConfirmationDialogService, i14.I18n, i15.ToastrService, i16.FormService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CambioMatriculaComponentNgFactory = i1.ɵccf("app-cambio-matricula", i10.CambioMatriculaComponent, View_CambioMatriculaComponent_Host_0, {}, {}, []);
export { CambioMatriculaComponentNgFactory as CambioMatriculaComponentNgFactory };
