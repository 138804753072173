import { TarifasGruaService } from './../../services/tarifa-grua-service';
import { TarifasGruaFilterService } from './../../services/tarifa-grua-filter-service';
import { TarifasGruaFilter } from './../../models/tarifa-grua-filter';
import { TarifaGruaDto } from './../../models/tarifaGruaDto.model';

import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { TipoVehiculo } from 'app/sections/tiquets/reserves/models/reserves-filter';

@Component({
  selector: 'app-tarifas-grua-table',
  templateUrl: './tarifas-grua-table.component.html',
  styleUrls: ['./tarifas-grua-table.component.scss']
})
export class TarifasGruaTableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: TarifaGruaDto[];
  dataSource: CustomDataSource<TarifaGruaDto, TarifasGruaFilter>;
  filter: TarifasGruaFilter = new TarifasGruaFilter('descripcion', 'asc');
  displayedItems = [];
  highlightedRows = [];

  @Input() updatedFilterData: TarifasGruaFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

    fechaHasta: Date;
    displayedColumns = ['Idtarifa', 'Descripcion', 'Tipustarifa', 'TipusVehicle', 'importtarifa' , 'TipusEstancia', 'Fecha_desde', 'Fecha_hasta'];
  constructor(   private tarifasGruaService: TarifasGruaService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n) { }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<TarifaGruaDto, TarifasGruaFilter>(this.paginator, new TarifasGruaFilterService(), 'urlGetTarifesGrua', 'urlGetTarifesGruaCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        if (item.tipoVehiculoModel == null) item.tipoVehiculoModel = {id:null,descripcio:'',matriculaObligatoria:false,secureId:''}
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        this.filterService.saveFilter('tarifes-grua-filter', this.filter);
      }
    });

    this.filterService.loadFilter('tarifes-grua-filter', this.filter);
    this.filter.paginator.init(this.sort, this.paginator);
  }
  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row.secureId);
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.tarifasGruaService, filter, this.i18n('Tarifes grua'), this.sort.direction, this.sort.active)
    });
  }

  tipoEstanciaLabel(id: string): string{
    switch (id) {
        case 'D': {
          return this.i18n('Dia');
        }
        case 'M': {
          return this.i18n('Mes');
        }
        case 'H': {
          return this.i18n('Hora');
      }
        default: {
          return '';
        }
    }
  }


  tipoTarifaLabel(id: string): string{
    switch (id) {
        case 'R': {
          return this.i18n('Remolc');
        }
        case 'E': {
          return this.i18n('Estància');
        }
        case 'I': {
          return this.i18n('Immovilitació');
      }
        default: {
          return '';
        }
    }
  }
}
