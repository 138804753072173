var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
var defaultSortParam = 'expediente';
var defaultSortDirection = 'asc';
var ConfirmacionDetalleBoeFilterService = /** @class */ (function (_super) {
    __extends(ConfirmacionDetalleBoeFilterService, _super);
    function ConfirmacionDetalleBoeFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConfirmacionDetalleBoeFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('secureId', (filter.secureId ? filter.secureId.toString() : ''))
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return ConfirmacionDetalleBoeFilterService;
}(CustomFilterService));
export { ConfirmacionDetalleBoeFilterService };
