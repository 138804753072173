import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';

import { FestiusFilter } from '../models/festius-filter';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class FestiusFilterService extends CustomFilterService {
  public createFilterParams(filter: FestiusFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('dia', filter.dia === null ? '' : filter.dia.toString())
    .append('descripcio', filter.descripcio)
    .append('tipus', filter.tipus === null ? '' : filter.tipus.toString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
