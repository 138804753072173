import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DesplacatsGruaFilter } from 'app/sections/grua/Models/desplacats-filter';
import { SenseRetiradaGruaFilter } from 'app/sections/grua/Models/sense-retirada-filter';
import { SenseRetiradaGruaTableComponent } from '../sense-retirada-table/sense-retirada-table.component';
import { SenseRetiradaGruaFilterComponent } from '../sense-retirada-filter/sense-retirada-filter.component';



@Component({
  selector: 'app-sense-retirada',
  templateUrl: './sense-retirada.component.html',
  styleUrls: ['./sense-retirada.component.scss']
})
export class SenseRetiradaGruaComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selected = new FormControl(0);
  filter: SenseRetiradaGruaFilter = new SenseRetiradaGruaFilter('id', 'desc');
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild('senseRetirada') senseRetiradaTable: SenseRetiradaGruaTableComponent;

  constructor( public authService: AuthorizationService,
    private not: NotificationService,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n) {
      this.filter = new SenseRetiradaGruaFilter('id', 'desc');
    }

  ngOnInit() {
    this.authService.CheckUserPermission(this.authService.Pages.DipositGrua);
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Sense retirada'));
    }
  }

  onFilterClicked() {
    this.dialog.open(SenseRetiradaGruaFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.senseRetiradaTable.filter
      }
    });

  }

  refresh() {
    this.senseRetiradaTable.load();
  }
  generateExcel() {
    this.senseRetiradaTable.generateExcel();
  }

}
