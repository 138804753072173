<app-toolbar>
  <app-tool-button text="Actualitzar"  i18n-text (click)="load();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
  <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.Maquinas"></app-tool-button-permissions>
</app-toolbar>
<div style="width: 20%;">
  <app-form-combo-edit i18n-label 
    label="Agent" 
    [items]="this.agentsList" 
    [(value)]="this.filter.agent" 
    valueField="codi" 
    textField="codi"
    (valueChange)="filterChange($event)"
    (keyup.enter)="filterChange($event)"></app-form-combo-edit>
</div>

<div class="row justify-content-between">
  <!-- <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div> -->
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="row">
  <div class="col-sm-7 table-sdw is-fixed">
    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">
      <ng-container matColumnDef="mapa">
        <mat-header-cell *matHeaderCellDef i18n class="is-checkbox"><mat-checkbox #allCheck (change)="toggleAllCheckbox($event)"></mat-checkbox>Mapa</mat-header-cell>
        <mat-cell *matCellDef="let row"><button class="btn btn-outline btn-xs" [style.color]="getMarkerIconColor(row.SOM_ID)" i18n-data-content i18n-title (click)="this.loadMarker(row.LATITUD, row.LONGITUD, row.SOM_ID, row.TIPUS_ACTUACIO)"><i class="fas fa-map-pin"></i></button></mat-cell>
      </ng-container>
      <ng-container matColumnDef="SOM_ID">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
        <mat-cell *matCellDef="let row"><a>{{row.SOM_ID}}</a></mat-cell>
      </ng-container>
      <ng-container matColumnDef="AGENT">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Agent</mat-header-cell>
        <mat-cell *matCellDef="let row"><a>{{row.AGENT}}</a></mat-cell>
      </ng-container>
      <ng-container matColumnDef="DATA">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
        <mat-cell *matCellDef="let row"><a>{{row.DATA}}</a></mat-cell>
      </ng-container>
      <ng-container matColumnDef="LONGITUD">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Longitud</mat-header-cell>
        <mat-cell *matCellDef="let row"><a>{{row.LONGITUD}}</a></mat-cell>
      </ng-container>
      <ng-container matColumnDef="LATITUD">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Latitud</mat-header-cell>
        <mat-cell *matCellDef="let row"><a>{{row.LATITUD}}</a></mat-cell>
      </ng-container>
      <ng-container matColumnDef="TIPUS_ACTUACIO">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipus actuació</mat-header-cell>
        <mat-cell *matCellDef="let row"><a>{{row.TIPUS_ACTUACIO.DESCRIPCIO}}</a></mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
    </mat-table>
  </div>

  <div class="col-sm-5" style="height: 70vh;width:100%;">
    <agm-map (mapReady)="this.mapReady($event)" [latitude]="currentLocation.latitude" [longitude]="currentLocation.longitude" [styles]="this.styleMap">
      <agm-marker *ngFor="let m of markersArray; let i = index"
      [latitude]="m.lat"
      [longitude]="m.lng"
      (mouseOver)="showInfo()"
      [iconUrl]="{ url: m.iconPath }">
      <agm-info-window *ngIf="show">
        <div style="font-weight: bold;">{{m.label}}</div>
      </agm-info-window>
    </agm-marker>
    </agm-map>
  </div>
</div>
<!-- [title]="m.label" -->