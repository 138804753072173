/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./filter-selector.component";
var styles_FilterSelectorComponent = [i0.styles];
var RenderType_FilterSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterSelectorComponent, data: {} });
export { RenderType_FilterSelectorComponent as RenderType_FilterSelectorComponent };
function View_FilterSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-xs btn-outline-warning"], ["type", "button"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.context.$implicit.property) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", ": ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.valid(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.getLabel(_v.context.$implicit.property); var currVal_2 = _co.getValue(_v.context.$implicit.property); _ck(_v, 1, 0, currVal_1, currVal_2); }); }
export function View_FilterSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "tags"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterSelectorComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-xs btn-outline-warning"], ["type", "button"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.filter.clear() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-broom"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.items; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.filter.active(); _ck(_v, 0, 0, currVal_0); var currVal_2 = !_co.filter.active(); _ck(_v, 3, 0, currVal_2); }); }
export function View_FilterSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-selector", [], null, null, null, View_FilterSelectorComponent_0, RenderType_FilterSelectorComponent)), i1.ɵdid(1, 114688, null, 0, i3.FilterSelectorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilterSelectorComponentNgFactory = i1.ɵccf("app-filter-selector", i3.FilterSelectorComponent, View_FilterSelectorComponent_Host_0, { filter: "filter", exclude: "exclude" }, {}, []);
export { FilterSelectorComponentNgFactory as FilterSelectorComponentNgFactory };
