import { AuthService } from 'path-shared/services/authentication/auth.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import { HttpSecureClient } from './../http/httpSecureClient';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { NotificationType } from './notification-type';
import { EstadoTramitacion } from 'app/sections/denuncies/models/estado-tramitacion.model';
import { AlarmaGps } from 'app/sections/alarmes/models/alarma-gps.model';
import { Terminal } from 'app/sections/comunicacio-pda/models/terminal.model';
import { EstadoReservas } from 'app/sections/denuncies/models/estado-reservas.model';
import { Alarma } from 'app/sections/alarmes/models/alarma.model';
import { EstatTramitarTancament } from 'app/sections/tramitar/expedients/models/EstatTramitarTancament.model';
import { PropostesPendentsCount } from 'app/sections/propostes/models/proposta-pendents-count.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  onReservesEstatChange: EventEmitter<EstadoReservas> = new EventEmitter();
  onPropostesPendentsChange: EventEmitter<PropostesPendentsCount> = new EventEmitter();
  onTramitacioEstatChange: EventEmitter<EstadoTramitacion> = new EventEmitter();
  onEstatFichersChange: EventEmitter<number> = new EventEmitter();
  onTitleChange: EventEmitter<string> = new EventEmitter();
  onAlarmesPendentsChange: EventEmitter<number> = new EventEmitter();
  onTramitacioPendentsChange: EventEmitter<number> = new EventEmitter();
  onPropostesGruaPendentsChange: EventEmitter<number> = new EventEmitter();
  onAlegacionsPendentsChange: EventEmitter<number> = new EventEmitter();
  onAlarmesGpsPendentsChange: EventEmitter<any> = new EventEmitter();
  onPdasPendentsLlegirChange: EventEmitter<any> = new EventEmitter();
  onNovesDadesGpsChange: EventEmitter<any> = new EventEmitter();
  onTramitarTancamentChange: EventEmitter<EstatTramitarTancament> = new EventEmitter();
  onErrorConsultaParkeonChange: EventEmitter<string> = new EventEmitter();
  onLoadG5: EventEmitter<EstatTramitarTancament> = new EventEmitter();
  EjecutivaResultats: EventEmitter<EstatTramitarTancament> = new EventEmitter();
  onProgressBarChange: EventEmitter<any> = new EventEmitter();
  onEstatAnulacioProcessadaChange: EventEmitter<any> = new EventEmitter();
  onBOEC60Change: EventEmitter<any> = new EventEmitter();
  onTramitacionC60Change: EventEmitter<any> = new EventEmitter();
  onTramitacionNotificacionesChange: EventEmitter<any> = new EventEmitter();
  onGtwinSyncChange: EventEmitter<Boolean> = new EventEmitter();

  currentEvents: EventSourcePolyfill;

  constructor(
    private http: HttpSecureClient,
    private auth: AuthService
    ) {
  }


  subscribeToEvent(notificationType: NotificationType, delegate: EventEmitter<any>) {
    if (this.auth.isAuthenticated()) {
      // Se suscribe a los eventos de tipo notificacion (si es que no esta ya susrito).
      if (this.currentEvents === undefined) {
        this.currentEvents = this.http.eventListener(config.urls['apiUrl'] + api.endpoints['urlGetNotification']);
      }

      // Registramos (en el front) el tipo de evento del que debemos recibir updates.
    this.currentEvents.addEventListener(notificationType, result => {
      if (result !== undefined && !delegate.closed && delegate.observers.length > 0) {
        delegate.emit(JSON.parse(result.data));
      }
    });
  }
}

  unsubscribeToEvent(notificationType: NotificationType) {
    this.currentEvents.removeEventListener(notificationType);
  }

  configure() {
    if (this.auth.isAuthenticated()) {
      const events: EventSourcePolyfill = this.http.eventListener(config.urls['apiUrl'] + api.endpoints['urlGetNotification']);

      events.addEventListener('heartbeat', message => {
        // console.log(message);
      });

      events.addEventListener('error', error => {
         console.log(error);
      });

      events.addEventListener(NotificationType.PropostesPendents, result => {
        console.log('PropostesPendents ' + result.data);
        this.onPropostesPendentsChange.emit(PropostesPendentsCount.Parse(result.data));
      });

      // events.addEventListener(NotificationType.EstatReserves, result => {
      //   this.onReservesEstatChange.emit(JSON.parse(result.data));
      // });

      events.addEventListener(NotificationType.EstatTramitacio, result => {
        console.log('EstatTramitacio ' + result.data);
        this.onTramitacioEstatChange.emit(JSON.parse(result.data));
      });

      events.addEventListener(NotificationType.EstatFitxers, result => {
        console.log('EstatFitxers ' + result.data);
        this.onEstatFichersChange.emit(JSON.parse(result.data));
      });

      events.addEventListener(NotificationType.AlarmesPendents, result => {
        console.log('AlarmesPendents ' + result.data);
        this.onAlarmesPendentsChange.emit(parseInt(result.data, 10));
      });

      events.addEventListener(NotificationType.TramitacioPendents, result => {
        console.log('TramitacioPendents ' + result.data);
        this.onTramitacioPendentsChange.emit(parseInt(result.data, 10));
      });

      events.addEventListener(NotificationType.AlarmesGpsPendents, result => {
        this.onAlarmesGpsPendentsChange.emit(result);
      });

      events.addEventListener(NotificationType.PdasPendentsLlegir, result => {
        // const pdasPendentsLlegir: Array<Terminal> = JSON.parse(result.data);
        // console.log('PdasPendentsLlegir ' + pdasPendentsLlegir.length);
        this.onPdasPendentsLlegirChange.emit(result);
      });

      events.addEventListener(NotificationType.NovesDadesGps, result => {
        this.onNovesDadesGpsChange.emit(result);
      });

      events.addEventListener(NotificationType.PropostesGruaPendents, result => {
        console.log('PropoesteGruaPendents ' + result.data);
        this.onPropostesGruaPendentsChange.emit(parseInt(result.data, 10));
      });

      events.addEventListener(NotificationType.TramitarTancament, result => {
        console.log('TramitarTancaments ' + result.data);
        this.onTramitarTancamentChange.emit(JSON.parse(result.data));
      });

      events.addEventListener(NotificationType.LoadG5, result => {
        console.log('LoadG5 ' + result.data);
        this.onLoadG5.emit(JSON.parse(result));
      });

      events.addEventListener(NotificationType.EjecutivaResultats, result => {
        console.log('EjecutivaResultats ' + result.data);
        this.EjecutivaResultats.emit(JSON.parse(result));
      });

      events.addEventListener(NotificationType.ErrorConsultaParkeon, result => {
        console.log('ErrorConsultaParkeon ' + result.data);
        this.onErrorConsultaParkeonChange.emit(result.data);
      });

      events.addEventListener(NotificationType.ProgressBar, result => {
        console.log('ProgressBar ' + result.data);
        this.onProgressBarChange.emit(JSON.parse(result.data));
      });
      events.addEventListener(NotificationType.EstatAnulacioProcessada, result => {
        console.log('EstatAnulacioProcessada ' + result.data);
        this.onEstatAnulacioProcessadaChange.emit(JSON.parse(result.data));
      });
      events.addEventListener(NotificationType.TramitacioC60Status, result => {
        console.log('C60 generada ' + result.data);
        this.onTramitacionC60Change.emit(JSON.parse(result.data));
      });
      events.addEventListener(NotificationType.BOEC60Status, result => {
        console.log('C60 generada ' + result.data);
        this.onBOEC60Change.emit(JSON.parse(result.data));
      });

      events.addEventListener(NotificationType.TramitacioNotificacionStatus, result => {
        console.log('Notificacion generada ' + result.data);
        this.onTramitacionNotificacionesChange.emit(JSON.parse(result.data));
      });

      events.addEventListener(NotificationType.GtwinSync, result => {
        this.onGtwinSyncChange.emit(result.data === 'true');
      });

      events.addEventListener(NotificationType.AlegacionsPendents, result => {
        console.log('Alegacions pendents: ' + result.data);
        this.onAlegacionsPendentsChange.emit(parseInt(result.data, 10));
      });
    }
  }

  titleChange(title: string) {
    this.onTitleChange.next(title);
  }

}
