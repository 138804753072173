/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./livemap-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../components/map/map.component.ngfactory";
import * as i3 from "../components/map/map.component";
import * as i4 from "../components/leyenda-map/leyenda-map.component.ngfactory";
import * as i5 from "../components/leyenda-map/leyenda-map.component";
import * as i6 from "./livemap-page.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../shared/services/notification/notification.service";
import * as i9 from "@ngx-translate/i18n-polyfill";
import * as i10 from "../services/map.service";
import * as i11 from "../../../shared/services/parameters/parameters.service";
import * as i12 from "../../grua/services/grua-pendents.service";
import * as i13 from "../../../shared/services/authentication/auth-guard.service";
import * as i14 from "../../../shared/services/startup/google-maps.service";
import * as i15 from "../../zona-blava/estacionament/escala-ocupacio/services/escala-ocupacion.service";
var styles_LivemapPageComponent = [i0.styles];
var RenderType_LivemapPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LivemapPageComponent, data: {} });
export { RenderType_LivemapPageComponent as RenderType_LivemapPageComponent };
export function View_LivemapPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mapComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-map-component", [["class", "col-9 map"]], null, [[null, "markerRouteEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("markerRouteEvent" === en)) {
        var pd_0 = (_co.navigateToDetail($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MapComponent_0, i2.RenderType_MapComponent)), i1.ɵdid(3, 114688, [[1, 4], ["map", 4]], 0, i3.MapComponent, [], { zoom: [0, "zoom"] }, { markerRouteEvent: "markerRouteEvent" }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-leyenda-map-component", [["class", "col-3"]], null, [[null, "featureChanges"], [null, "hoverVisivilityChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("featureChanges" === en)) {
        var pd_0 = (_co.updateMap($event) !== false);
        ad = (pd_0 && ad);
    } if (("hoverVisivilityChange" === en)) {
        var pd_1 = (_co.changeHoversVisibility($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_LeyendaMapComponent_0, i4.RenderType_LeyendaMapComponent)), i1.ɵdid(5, 114688, null, 0, i5.LeyendaMapComponent, [], null, { featureChanges: "featureChanges", hoverVisivilityChange: "hoverVisivilityChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.zoomMapa; _ck(_v, 3, 0, currVal_0); _ck(_v, 5, 0); }, null); }
export function View_LivemapPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-livemap-page", [], null, null, null, View_LivemapPageComponent_0, RenderType_LivemapPageComponent)), i1.ɵdid(1, 4440064, null, 0, i6.LivemapPageComponent, [i7.MatDialog, i8.NotificationService, i9.I18n, i10.MapService, i11.ParametersService, i12.GruaPendentsService, i13.AuthGuardService, i14.GoogleMapsService, i15.EscalaOcupacionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LivemapPageComponentNgFactory = i1.ɵccf("app-livemap-page", i6.LivemapPageComponent, View_LivemapPageComponent_Host_0, {}, {}, []);
export { LivemapPageComponentNgFactory as LivemapPageComponentNgFactory };
