import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { UsuarisService } from 'app/sections/usuaris/services/usuaris.service';
import { CookieService } from 'ngx-cookie-service';
import { CFG_PAISES } from 'path-models/CFG_PAISES';
import { CFG_PARAMETROS } from 'path-models/CFG_PARAMETROS';
import { CFG_TIPODENUNCIA } from 'path-models/CFG_TIPODENUNCIA';
import { SOM_DEN_INFRACCIONES_GRAVEDAD } from 'path-models/SOM_DEN_INFRACCIONES_GRAVEDAD';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { AuthService } from 'path-shared/services/authentication/auth.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { GoogleMapsService } from 'path-shared/services/startup/google-maps.service';
import { StartupService } from 'path-shared/services/startup/startup.service';
import { DenunciaAutoLogin } from '../../models/denunciaAutoLogin.model';
import { LoginModel } from '../../models/login.model';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss']
})
export class AutoLoginComponent implements OnInit {
  login: LoginModel;
  token = '';
  token_type = '';
  expires_in = '';
  instit = '';
  screen = '';
  secondScreen='';
  thirdScreen = '';
  tradesegurId = '';
  constructor(
    private apiService: LoginService,
    private router: Router,
    private route: ActivatedRoute,

    private cookieService: CookieService,
    private usuarisService: UsuarisService,
    private app: AppComponent,
    private auth: AuthService,
    private http: HttpSecureClient,
    private authGuard: AuthGuardService,
    private googleMapsService: GoogleMapsService,
   // private startupService: StartupService
  ) {
    this.login = new LoginModel();
  }

  ngOnInit() {
debugger;
    this.token = this.route.snapshot.paramMap.get('token');
    this.token_type = this.route.snapshot.paramMap.get('token_type');
    this.expires_in = this.route.snapshot.paramMap.get('expires_in');
    this.instit = this.route.snapshot.paramMap.get('instit');
    this.screen = this.route.snapshot.paramMap.get('screenId');
    this.tradesegurId = this.route.snapshot.paramMap.get('tradesegurId');
    if(this.screen !== undefined && this.screen !== null && this.screen !== ''){
      this.secondScreen = this.route.snapshot.paramMap.get('secondScreen');
      this.thirdScreen = this.route.snapshot.paramMap.get('thirdScreen');
    }
    // autoLogin/:token:screen
    if (this.token !== undefined && this.token !== null && this.token !== '' &&
      ((this.screen !== undefined && this.screen !== null && this.screen !== '')
      || this.tradesegurId !== undefined && this.tradesegurId !== null && this.tradesegurId !== '')) {
       if (this.apiService.setCredentials(this.token, this.expires_in, this.instit)) {
        this.apiService.getInstitucions().subscribe(institucions => {
          const institFound = institucions.find(x => x.IdInstitucion === this.login.institution);
          if (institFound === undefined) {
            sessionStorage.setItem('IdInstitucion', institucions[0].IdInstitucion);
            localStorage.setItem('IdInstitucion', institucions[0].IdInstitucion);
            sessionStorage.setItem('currentInst', JSON.stringify(institucions[0]));
            this.cookieService.set('InstitucioSeleccionada', institucions[0].IdInstitucion);
            this.login.institution = institucions[0].IdInstitucion;
          } else {
            sessionStorage.setItem('IdInstitucion', this.login.institution);
            localStorage.setItem('IdInstitucion', this.login.institution);
            sessionStorage.setItem('currentInst', JSON.stringify(institFound));
            this.cookieService.set('InstitucioSeleccionada', this.login.institution);
          }

          this.deleteFilterCookies();
          this.getCurrentUser();
          // this.usuarisService.initStartUp();
          this.InitStartup();
        });
       }
      }
  }

  private getCurrentUser() {
    this.usuarisService.getUsuari().subscribe(usuari => {
      sessionStorage.setItem('usuari', JSON.stringify(usuari));
      this.apiService.loginComplete();
      // this.router.navigateByUrl('');
      if (this.screen !== undefined && this.screen !== null &&  this.screen !== '' && this.screen !== 'dash') {
        let finalPath='/' + this.screen;
        if(this.secondScreen !== undefined && this.secondScreen !== null && this.secondScreen !== ''){
           finalPath=finalPath+'/' + this.secondScreen;
        }
        if(this.thirdScreen !== undefined && this.thirdScreen !== null && this.thirdScreen !== ''){
          finalPath=finalPath+'/' + this.thirdScreen;
        }
        document.location.href = finalPath;
      } else if(this.tradesegurId !== undefined && this.tradesegurId !== null &&  this.tradesegurId !== '') {
        this.getDenunciaForPageAndRedirect(this.tradesegurId);
      }else if(this.screen !== undefined && this.screen !== null &&  this.screen !== '' && this.screen === 'dash'){
        document.location.href = '/';
      }else{
        document.location.href = '/';
      }

      // this.router.navigate(['/']);
    });
  }
  getDenunciaForPageAndRedirect(tradesegurId: string) {
    this.http.get(config.urls['apiUrl'] + api.endpoints['getDenunciaAutoLogin'].replace('{tradesegurId}',tradesegurId), null).subscribe( x=> {
      const json = JSON.stringify(x);
      const denunciaAutologin: DenunciaAutoLogin = JSON.parse(json.toString());
      if(denunciaAutologin.isDenuncia){
        document.location.href = '/denuncies/editar-denuncia/'+denunciaAutologin.numDenuncia;
      }else{
        if (sessionStorage.getItem('propostaAutologin')) {
          sessionStorage.removeItem('propostaAutologin');
        }
        sessionStorage.setItem('propostaAutologin', json);
          //const json = sessionStorage.getItem('tiposdenuncia');
        document.location.href = '/visorPropostesLogin';
      }
    });
  }

  InitStartup() {
    this.GetTiposDenuncia();
    this.GetPaisos();
    this.GetParametres();
    this.GetTipusVehicles();
    this.GetInfraccioTipusGravetat();
    this.GetInfraccioDiesDescompte();
    this.GetDenunciesOrigensForCombo();
  }

  /*
    Mètode per esborrar les cookies que es guardan a l'aplicar un filtre
  */
  private deleteFilterCookies() {
    const filterPattern = '-filter';
    const allCookies = this.cookieService.getAll();
    const propertyNames = Object.getOwnPropertyNames(allCookies);

    const filterCookiesToDelete = propertyNames.filter(function (str) { return str.includes(filterPattern); });
    filterCookiesToDelete.forEach(filterCookie => {
      this.cookieService.delete(filterCookie, '/', window.location.hostname);
    });
  }


  GetTiposDenuncia(): CFG_TIPODENUNCIA[] {
    if (this.authGuard.canActivate()) {
      if (sessionStorage.getItem('tiposdenuncia')) {
        const json = sessionStorage.getItem('tiposdenuncia');
        const values: CFG_TIPODENUNCIA[] = JSON.parse(json.toString());
        return values;
      }

      this.http.get(config.urls['apiUrl'] + api.endpoints['tiposdenuncia'], null).subscribe(tiposdenuncia => {
          const json = JSON.stringify(tiposdenuncia);
          sessionStorage.setItem('tiposdenuncia', json);
          return tiposdenuncia;
        });
    }
    return null;
  }

 GetTipusVehicles(): any[] {
  if (this.authGuard.canActivate()) {
    if (sessionStorage.getItem('vehicles')) {
      const json = sessionStorage.getItem('vehicles');
      const values: CFG_PAISES[] = JSON.parse(json.toString());
      return values;
    }

    this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTipusVehiclesCombo'], null).subscribe(result => {
      const json = JSON.stringify(result);
      sessionStorage.setItem('vehicles', json);
      return result;
    });
  }
  return [];
 }

  GetPaisos(): CFG_PAISES[] {
    if (this.authGuard.canActivate()) {
      if (sessionStorage.getItem('paisos')) {
        const json = sessionStorage.getItem('paisos');
        const values: CFG_PAISES[] = JSON.parse(json.toString());
        return values;
      }

      this.http.get(config.urls['apiUrl'] + api.endpoints['urlConfigPaisos'], null).subscribe(result => {
        const json = JSON.stringify(result);
        sessionStorage.setItem('paisos', json);
        return result;
      });
    }
    return [];
  }

  GetInfraccioTipusGravetat(): SOM_DEN_INFRACCIONES_GRAVEDAD[] {
    if (this.authGuard.canActivate()) {
      if (sessionStorage.getItem('infraccioTipusGravetat')) {
        const json = sessionStorage.getItem('infraccioTipusGravetat');
        const values: SOM_DEN_INFRACCIONES_GRAVEDAD[] = JSON.parse(json.toString());
        return values;
      }

      this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetInfraccioTipusGravetat'], null).subscribe(infraccioTipusGravetat => {
          const json = JSON.stringify(infraccioTipusGravetat);
          sessionStorage.setItem('infraccioTipusGravetat', json);
          return infraccioTipusGravetat;
        });
    }
    return null;
  }

  GetDenunciesOrigensForCombo(): any[] {
    if (this.authGuard.canActivate()) {
      if (sessionStorage.getItem('denunciesOrigensForCombo')) {
        const json = sessionStorage.getItem('denunciesOrigensForCombo');
        const values: any[] = JSON.parse(json.toString());
        return values;
      }

      this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetOrigensDenuncia'], null).subscribe(denunciesOrigensForCombo => {
          const json = JSON.stringify(denunciesOrigensForCombo);
          sessionStorage.setItem('denunciesOrigensForCombo', json);
          return denunciesOrigensForCombo;
        });
    }
    return null;
  }

  GetInfraccioDiesDescompte(): number {
    if (this.authGuard.canActivate()) {
      if (sessionStorage.getItem('infraccioDiesDescompte')) {
        const diesDescompte = sessionStorage.getItem('infraccioDiesDescompte');
        return Number.parseInt(diesDescompte);
      }

      this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetInfraccioDiesDescompte'], null).subscribe(diesDescompte => {
          sessionStorage.setItem('infraccioDiesDescompte', diesDescompte.toString());
          return diesDescompte;
        });
    }
    return null;
  }

  GetParametres() {
    if (this.authGuard.canActivate()) {
      let json = sessionStorage.getItem('parameters');
      if (!Reflection.empty(json)) {
        const values: CFG_PARAMETROS[] = JSON.parse(json.toString());
        return values;
      }
      this.http.get(config.urls['apiUrl'] + api.endpoints['urlConfigParametres'], null).subscribe(result => {
        json = JSON.stringify(result);
        sessionStorage.setItem('parameters', json);
        return result;
      });
    }
    return [];
  }

  GetParametresObservable() {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlConfigParametres'], null);
    }
  }

}
