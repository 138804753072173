import { I18n } from '@ngx-translate/i18n-polyfill';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef,  } from '@angular/core';
import { DenunciesService } from '../services/denuncies.service';
import { XDENUNCIES } from '../../../models/XDENUNCIES';
import { MatDialogRef, MatDialog } from '@angular/material';
import { DenunciesEditorComponent } from '../components/denuncies-editor/denuncies-editor.component';
import { DenunciesFilterComponent } from '../components/denuncies-filter/denuncies-filter.component';
import { AuthorizationService } from '../../../shared/services/authorization/authorization-service';
import { DenunciaFilter } from '../models/denuncia.filter';
import { DenunciesImagesComponent } from '../../../sections/denuncies/components/denuncies-editor/components/denuncies-images/denuncies-images.component';
import { DenunciesTableComponent } from '../components/denuncies-table/denuncies-table.component';
import { DenunciesNewComponent } from '../components/denuncies-new/denuncies-new.component';
import { DenunciaDetailModel } from '../models/denuncia-detail.model';
import { Router } from '@angular/router';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { Builder } from 'path-shared/models/builder';
import { DenunciaCobrarComponent } from '../components/denuncies-editor/components/denuncia-cobrar/denuncia-cobrar.component';
import { NovaOperacioComponent } from '../components/denuncies-editor/components/nova-operacio/nova-operacio.component';
import { DenunciesCobrarService } from 'path-denuncies-services/denuncies-cobrar.service';
import { ExpedientsModel } from 'app/sections/expedients/models/expedients.model';
import { DenunciaExpedientModel } from '../models/denuncia-expedient.model';
import { OperacionGenericaDto } from '../models/operacionGenericaDto.model';
import { OperacionesModel } from '../models/operaciones.model';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { FilesService } from 'path-shared/services/files/files.service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { DenunciaExpedienteAcuse } from '../models/denuncia-expediente-acuse';
import { ReportService } from 'path-shared/services/report/report.service';
import { ReportsComponent } from 'path-shared/components/reports/reports.component';
import { Denuncia } from '../models/denuncia.model';
import { DenunciaExpedienteExterno } from '../models/denuncia-expediente-externo';
import * as XLSX from 'xlsx';

enum TipoImportador {
  AcusosRebut,
  ExpedientExtern   
}

@Component({
  selector: 'app-denunciespage',
  templateUrl: './denunciespage.component.html'
})

export class DenunciesPageComponent implements OnInit, AfterViewInit {

  details: XDENUNCIES = null;
  denunciesNewDialogRef: MatDialogRef<DenunciesNewComponent>;
  denunciesEditorDialogRef: MatDialogRef<DenunciesEditorComponent>;
  denunciesFilterDialogRef: MatDialogRef<DenunciesFilterComponent>;
  denunciesImageDialogRef: MatDialogRef<DenunciesImagesComponent>;
  tipusLegislacions = Array();
  dialogResult = '';
  formName = 'ClassSomintec.frExpedientes';
  somIdFoo: number;
  numDenuncia: string;
  filterData: DenunciaFilter;
  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  denunciesCobrarRef: MatDialogRef<DenunciaCobrarComponent>;

  name: string;
  size: number;

  rutaPlantillaExpedient: string;
  public dataExpedientExtern: DenunciaExpedienteExterno

  reports = [];
  URL_REPORT = 'urlGenerateReportDenunciaInfo';

  denunciaDetail: DenunciaDetailModel;
  denunciesNovaOperacioRef: MatDialogRef<NovaOperacioComponent>;

  tipoImportador: TipoImportador

  @ViewChild(DenunciesTableComponent) denunciesTable: DenunciesTableComponent;
  @ViewChild('uploadFileInput') input: ElementRef;

  constructor(
    private router: Router,
    private denunciesService: DenunciesService,
    private denunciesCobrarService: DenunciesCobrarService,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private toastr: ToastrService,
    private not: NotificationService,
    private confirmationDialogService: ConfirmationDialogService,
    public filesService: FilesService,
    private reportService: ReportService,
    ) {
      this.denunciaDetail = Builder.newDenunciaDetail();
      this.dataExpedientExtern = Builder.newDenunciaExpedienteExterno();
      this.rutaPlantillaExpedient = '#BaseDirectory#Plantillas/Plantilla_Expedient.xlsx';
      this.size = 0;

      this.reportService.getReports(this.auth.Pages.Denuncies).subscribe( res => {
        this.reports = res;
      });
  }

  ngOnInit() {
    this.auth.CheckUserPermission(this.auth.Pages.Denuncies);
  }

  ngAfterViewInit() {
    this.not.titleChange(this.i18n('Denúncies'));
  }

  selectCheckedItems() {
    return this.denunciesService.displayedItems['checkedValues'];
  }
  selectCheckedItemsForOperacio() { 
    var canMakeNovaOperacio  = false;
    for (const item in this.denunciesService.displayedItems) {
      if (this.denunciesService.displayedItems[item].checked 
        && (this.denunciesService.displayedItems[item].tancat === undefined 
          || this.denunciesService.displayedItems[item].tancat === null || this.denunciesService.displayedItems[item].tancat === false )
        ) {
          canMakeNovaOperacio= true;
          return canMakeNovaOperacio;
      }
    }
    return canMakeNovaOperacio;
  }

  onRowSelected(somid) {
    this.somIdFoo = somid;

    this.router.navigate(['denuncies/editar-denuncia', somid]);
  }

  closeDialog() {
    this.denunciesEditorDialogRef.close();
  }

  onFilterClicked() {
    this.denunciesFilterDialogRef = this.dialog.open(DenunciesFilterComponent, {
      width: '90%',
      height: 'auto',
      closeOnNavigation: false,
      autoFocus: true,
      data: this.denunciesTable.filter
    });

    this.denunciesFilterDialogRef.componentInstance.notify.subscribe((filter: DenunciaFilter) => {
      this.denunciesTable.filter.set(filter);
      this.denunciesTable.loadDenunciasPage();
    });
  }

  generateExcel() {
    this.denunciesTable.generateExcel();
  }

  editDenuncia() {
    for (const item in this.denunciesService.displayedItems) {
      if (this.denunciesService.displayedItems[item].checked) {
        this.onRowSelected(item);
      }
    }
  }

  newDenuncia() {

    this.denunciesService.createDenuncia().subscribe(() => {
      this.router.navigate(['denuncies/nova-denuncia']);
    });
  }

  onPermissionClick() {
    this.permissionDialogRef = this.dialog.open(PermissionDialogComponent,
      {
        width: '50%',
        minHeight: '70%',
        closeOnNavigation: false,
        autoFocus: true,
        data: this.auth.Pages.Denuncies,
      });
  }

  updateDenuncies() {
    this.denunciesTable.loadDenunciasPage();
  }

  NovaOperacio() {
    const selectedItems = [];
    for (const item in this.denunciesService.displayedItems) {
      if (this.denunciesService.displayedItems[item].checked 
        && (this.denunciesService.displayedItems[item].tancat === undefined 
          || this.denunciesService.displayedItems[item].tancat === null || this.denunciesService.displayedItems[item].tancat === false )
        ) {
        this.somIdFoo = Number.parseInt(item);
        selectedItems.push(this.somIdFoo);
      }
    }

    this.denunciesService.getDenunciesDetails(selectedItems).subscribe(denunciesDetails => {
      const tipoOpe = this.denunciesService.getTipoOperacions().subscribe( novaOperacio => {
        this.denunciesNovaOperacioRef = this.dialog.open(NovaOperacioComponent,
          {
            height: '80%',
            width: '80%',
            closeOnNavigation: false,
            autoFocus: true,
            data: {
              'operacions': novaOperacio,
              'data': denunciesDetails,
              'isMassiva': true
            },
          });
          this.denunciesNovaOperacioRef.afterClosed().subscribe(res => {
            if (res === true) { // s'ha executat alguna operació
              this.updateDenuncies();
            }
          });
      });
    });
  }

  cobrarDenunciaIndividual(selectedItems: any[]) {
    for (const item in this.denunciesService.displayedItems) {
      if (this.denunciesService.displayedItems[item].checked) {
        this.numDenuncia = item;
      }
    }

    this.denunciesService.getDenunciaDetails(Number.parseInt(this.numDenuncia)).subscribe((detailsdata: DenunciaDetailModel) => {
      this.denunciaDetail = detailsdata;
      this.denunciesService.getExpedient(detailsdata.expedient).subscribe( (expedientsData: ExpedientsModel) => {
        this.denunciesService.getSomExpedient(detailsdata.expedient).subscribe((denunciaExpedient: DenunciaExpedientModel) => {
          // Validacions i si tot va bé obrim modal per cobrar
          this.denunciesCobrarService.cobrarDenuncia(detailsdata, expedientsData, denunciaExpedient).subscribe(result => {
            if (result) {
              selectedItems.forEach(selectedItem => {
                const denunciaActualizar = this.denunciesTable.dataSource.getData().find(x => x.somid === selectedItem);
                // denunciaActualizar.dataCobra = (new Date()).toString();
                this.denunciesService.getVDenunciaIndividual(denunciaActualizar.somid).subscribe((res: Denuncia) => {
                  denunciaActualizar.cobroTotal = res.cobroTotal;
                  denunciaActualizar.cobroParcial = res.cobroParcial;
                  denunciaActualizar.dataCobra = res.dataCobra;

                });

              });
            }
          });
        });
      });
    });
  }

  cobrarDenunciasLista(selectedItems: any[]) {
    // TODO: confirm preguntando si quiere seguir
    // "Està apunt de procedir al cobrament 100% de les N denúncies sel·leccionades, vol continuar?"
    this.denunciesService.getDenunciesDetails(selectedItems).subscribe(denunciesDetails => {
      this.sendOperationCobrar(denunciesDetails, selectedItems);
    });
  }

  sendOperationCobrar(denunciesDetails: DenunciaDetailModel[], selectedItems: any[]) {
    const codigo = 'COBRA_EXP';
    const dto = new OperacionGenericaDto;

    const message = this.i18n('Está a punto de proceder al cobro 100% de las ') + selectedItems.length + this.i18n(' denuncias seleccionadas, quiere continuar?');
    this.confirmationDialogService.confirm(this.i18n('Avís'), message)
    .then((confirmed) => {
      if (confirmed) {
        this.denunciesService.createOpearcioMasiva(denunciesDetails.map(x => x.secure_id_denuncia), codigo, dto).subscribe((result: OperacionesModel) => {
          selectedItems.forEach(selectedItem => {
            const denunciaActualizar = this.denunciesTable.dataSource.getData().find(x => x.somid === selectedItem);
            denunciaActualizar.dataCobra = (new Date()).toString();
          });

          this.toastr.success(this.i18n('Operacio realitzada correctament'));
        });
      }
    });
  }

  cobrarDenuncia() {
    const selectedItems = [];

    for (const item in this.denunciesService.displayedItems) {
      if (this.denunciesService.displayedItems[item].checked) {
        this.somIdFoo = Number.parseInt(item);
        selectedItems.push(this.somIdFoo);
      }
    }

    if (selectedItems.length === 1) {
      this.cobrarDenunciaIndividual(selectedItems);
    } else {
      this.cobrarDenunciasLista(selectedItems);
    }
  }

  carregaExpedientsClick() {
    this.tipoImportador = TipoImportador.ExpedientExtern
    this.input.nativeElement.click();
  }

  descarregaExpedientsClick() {
    this.filesService.getFile(this.rutaPlantillaExpedient).subscribe(file => {
      const name = 'Plantilla_Expedients.xlsx';
      const contentType = file.headers.get('Content-Type');
      if (contentType !== null && contentType !== undefined) {
        FileDownloader.downLoadFile([file.body],  contentType + ';charset=utf-8', name);
      } else {
        this.toastr.error(this.i18n('No s\'ha trobat el fitxer'));
      }
    });
  }


  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      if (this.tipoImportador === TipoImportador.ExpedientExtern) {
        this.loadExcelToData(file);
        this.denunciesService.cargarExpedienteExerno(this.dataExpedientExtern).subscribe(result => {
          this.toastr.info(this.i18n('Total expedients processats correctament: ')  + Number.parseInt(result));
        });
      }
    }
  }

  loadExcelToData(file : File) {
    var reader = new FileReader();
    reader.readAsArrayBuffer(file);     
    reader.onload = async (e) => {    
      var arrayBuffer : any = reader.result;    
      var data = new Uint8Array(arrayBuffer);    
      var arr = new Array();    
      
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
      
      var bstr = arr.join("");
      var workBook = XLSX.read(bstr, {type:"binary"});
      
      let csvData = await workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_csv(sheet); //we convert excel to csv
        return initial;
      }, {});
      this.dataExpedientExtern.name = Object.keys(csvData)[0]
      this.dataExpedientExtern.value = Object.values(csvData)[0].toString()
    }
  };

  //#region REPORTS

  report() {

    let somIdDenuncia;
    for (const item in this.denunciesService.displayedItems) {
      if (this.denunciesService.displayedItems[item].checked) {
        somIdDenuncia = item;
      }
    }

    const ref = this.dialog.open(ReportsComponent, {
      width: '30%',
      height: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: this.reports
    }).afterClosed().subscribe(report => {
      if (report !== '') {
        const reportInfo = {
          'reportId': report.id,
          'somIdDenuncia': somIdDenuncia
        };

        this.reportService.generateReport(reportInfo, this.URL_REPORT).subscribe(file => {
          const name = report.nom;
          const contentType = file.headers.get('Content-Type');
          if (contentType !== null && contentType !== undefined) {
              FileDownloader.downLoadFile([file.body],  contentType + ';charset=utf-8', name.toString());
          } else {
            this.toastr.error(this.i18n('No s\'ha pogut generar el fitxer'));
          }
        });
      }
    });
  }

  //#endregion

}
