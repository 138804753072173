import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { PuntosDgtFilter } from '../models/puntos-dt-filter';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'EXP';
const defaultSortDirection = 'asc';

@Injectable()
export class NotificacionsPuntsDGTFilterService extends CustomFilterService {
  public createFilterParams(filter: PuntosDgtFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    if (filter.fechaInicio !== undefined && filter.fechaInicio !== null) {
      const tempDate = new Date(+filter.fechaInicio);
      tempDate.setHours(0,0,1);
      filter.fechaInicio = tempDate;
    }
   
    if (filter.fechaFin !== undefined && filter.fechaFin !== null) {
      const tempDate = new Date(+filter.fechaFin);
      tempDate.setHours(23,59,59);
      filter.fechaFin = tempDate;
    }
   
    return new HttpParams()
    .append('EXP', filter.EXP ? filter.EXP : '')
    .append('fechaInicio',  Reflection.empty(filter.fechaInicio) ? null :  typeof(filter.fechaInicio) === 'string' ?
    filter.fechaInicio : filter.fechaInicio.toDateString())
    .append('fechaFin',  Reflection.empty(filter.fechaFin) ? null :  typeof(filter.fechaFin) === 'string' ?
    filter.fechaFin : filter.fechaFin.toDateString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
