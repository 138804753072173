import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { I18n } from "@ngx-translate/i18n-polyfill";
import { PropostesEstatCarregatsFilter } from "app/sections/propostes/models/propostes-estat-carregats.filter";
import { FilterHeaderDelegate } from "path-shared/components/dialog/filter-header/filter-header-delegate";

@Component({
  selector: 'app-propostes-estat-carregats-filter',
  templateUrl: './propostes-estat-carregats-filter.component.html',
  styleUrls: ['./propostes-estat-carregats-filter.component.scss']
})
export class PropostesEstatCarregatsFilterComponent implements OnInit, FilterHeaderDelegate {
  filter: PropostesEstatCarregatsFilter;

  estados = [
    {text: '', value: null},
    {text: this.i18n('CARGADO'), value: 'CARGADO'},
    {text: this.i18n('PENDIENTE'), value: 'PENDIENTE'},
    {text: this.i18n('ELIMINADO'), value: 'ELIMINADO'},
    {text: this.i18n('DESCARTADO'), value: 'DESCARTADO'},
    {text: this.i18n('INCIDENCIA'), value: 'INCIDENCIA'},
    {text: this.i18n('REPETIDO'), value: 'REPETIDO'},
    {text: this.i18n('FORMATO'), value: 'FORMATO'},
  ];
  @Output() notify: EventEmitter<PropostesEstatCarregatsFilter> = new EventEmitter<PropostesEstatCarregatsFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<PropostesEstatCarregatsFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data: PropostesEstatCarregatsFilter,
  ) {
    this.filter = data;

  }

  ngOnInit() {

  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    if (this.filter.fechaInicioDisplay != undefined) {
      this.filter.fechaInicio = this.formatToCustomDateTime(this.filter.fechaInicioDisplay)
    }
    if (this.filter.fechaFinDisplay != undefined) {
      this.filter.fechaFinDisplay.setHours(23,59,59)
      this.filter.fechaFin = this.formatToCustomDateTime(this.filter.fechaFinDisplay)
    }
    this.filter.updated(false);
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

  public printFilter() {
    return JSON.stringify(this.filter);
  }

  public formatToCustomDateTime(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }

  private padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
