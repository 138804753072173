/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./_photo-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./photo-box.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../services/images/images.service";
import * as i6 from "@angular/platform-browser";
var styles_PhotoBoxComponent = [i0.styles];
var RenderType_PhotoBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhotoBoxComponent, data: {} });
export { RenderType_PhotoBoxComponent as RenderType_PhotoBoxComponent };
function View_PhotoBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-img-magnifier", [["class", "img-zoom-container"]], [[8, "thumbImage", 0], [8, "fullImage", 0], [8, "lensWidth", 0], [8, "lensHeight", 0], [8, "resultWidth", 0], [8, "resultHeight", 0], [8, "right", 0], [8, "top", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Navegador no compatible "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.zoomImageSrc; var currVal_1 = _co.previewImageSrc; var currVal_2 = 70; var currVal_3 = 70; var currVal_4 = 50; var currVal_5 = 50; var currVal_6 = _co.magnifierRight; var currVal_7 = _co.magnifierTop; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_PhotoBoxComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "title"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("title" === en)) {
        var pd_0 = (_co.nom !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageSource; _ck(_v, 1, 0, currVal_0); }); }
function View_PhotoBoxComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "title"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("title" === en)) {
        var pd_0 = (_co.nom !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileSource; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.imageSource; _ck(_v, 1, 0, currVal_1); }); }
function View_PhotoBoxComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.innerHtml; _ck(_v, 0, 0, currVal_0); }); }
function View_PhotoBoxComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Obrir PDF en una pestanya nova "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileSource; _ck(_v, 0, 0, currVal_0); }); }
function View_PhotoBoxComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoBoxComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "pdf"], ["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoBoxComponent_6)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoBoxComponent_7)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.pdf; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.innerHtml; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.pdf; _ck(_v, 7, 0, currVal_2); }, null); }
function View_PhotoBoxComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "video", [["controls", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Navegador no compatible "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileSource; _ck(_v, 2, 0, currVal_0); }); }
function View_PhotoBoxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["style", "padding: 2px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoBoxComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoBoxComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoBoxComponent_8)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((!_co.video && !_co.downloadable) && (_co.proposta === undefined)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.video && _co.downloadable); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.video; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageDiv; _ck(_v, 2, 0, currVal_0); }); }
export function View_PhotoBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "overflow: visible;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoBoxComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoBoxComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLupa; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.showLupa; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PhotoBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-photo-box", [], null, null, null, View_PhotoBoxComponent_0, RenderType_PhotoBoxComponent)), i1.ɵdid(1, 4308992, null, 0, i3.PhotoBoxComponent, [i4.MatDialog, i5.ImagesService, i6.DomSanitizer, i2.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhotoBoxComponentNgFactory = i1.ɵccf("app-photo-box", i3.PhotoBoxComponent, View_PhotoBoxComponent_Host_0, { showLupa: "showLupa", numDenuncia: "numDenuncia", somId: "somId", nom: "nom", proposta: "proposta", botoMatricula: "botoMatricula", llistaImatges: "llistaImatges", imageDiv: "imageDiv", magnifierRight: "magnifierRight", magnifierTop: "magnifierTop", drawList: "drawList" }, { updated: "updated", drawListChange: "drawListChange" }, []);
export { PhotoBoxComponentNgFactory as PhotoBoxComponentNgFactory };
