export class WaitDialogData {
  public title: string;
  public text: string;
  public buttonText: string;
  public icon: string;
  public isSpinner: boolean;

  constructor(title, text, buttonText, icon, isSpinner = true) {
    this.title = title;
    this.text = text;
    this.buttonText = buttonText;
    this.icon = icon;
    this.isSpinner = isSpinner;
  }
}
