export class PropostaGruaFilter {

    public pendiente: boolean;
    public rechazada: boolean;
    public aceptada: boolean;
    public retirada: boolean;
    public fechaConsulta: Date;

    constructor(pendiente: boolean, aceptada: boolean, rechazada: boolean, retirada: boolean, fechaConsulta: Date) {
        this.pendiente = pendiente;
        this.rechazada = rechazada;
        this.aceptada = aceptada;
        this.retirada = retirada;
        this.fechaConsulta = fechaConsulta;
    }
}
