<app-dialog-header label="Seguretat Usuari" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save"></app-tool-button>
  <app-tool-button text="Dar de baja" i18n-text (click)="this.darDeBaja()" *ngIf="this.secureId !== null && this.secureId !== undefined && this.seguretatUsuari.actiu" 
  color="has-color-red" icon="fa-user-slash"></app-tool-button>
  <app-tool-button text="Dar de alta" i18n-text (click)="this.darDeAlta()" *ngIf="this.secureId !== null && this.secureId !== undefined && !this.seguretatUsuari.actiu" 
  color="has-color-darkgreen" icon="fa-user"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="seguretat-usuaris-editor-nom" class="col-sm-3" i18n-label label="Nom Usuari" [(value)]="this.seguretatUsuari.nomUsuari" [disabled]="this.isLdap" required maxLength="50"></app-form-text-edit>
      <app-form-text-edit id="seguretat-usuaris-editor-descripcio" class="col-sm-3" i18n-label label="Descripció" [(value)]="this.seguretatUsuari.descUser" maxLength="200"></app-form-text-edit>
      <app-form-text-edit id="seguretat-usuaris-editor-pwd" class="col-sm-3" type="password" i18n-label label="Contrasenya" [(value)]="this.seguretatUsuari.password"  [disabled]="this.isLdap"
        [required]="this.seguretatUsuari.secureId == null || this.seguretatUsuari.secureId == undefined" maxLength="11"></app-form-text-edit>
      <app-form-text-edit id="seguretat-usuaris-editor-email" class="col-sm-3" i18n-label label="Email" [(value)]="this.seguretatUsuari.email" maxLength="50"></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-date-range-edit class="col-sm-3" label1="Data inici" label2="Data fi" [(value)]="this.seguretatUsuari.rangDatesDate"
        (valueChange)="this.calcularCheckActiu($event)" required></app-form-date-range-edit>

      <!-- <app-form-date-edit class="col-sm-3" i18n-label label="Data inici" [(value)]="this.seguretatUsuari.dataIniciDate" type="calendar" required></app-form-date-edit>
      <app-form-date-edit class="col-sm-3" i18n-label label="Data Fi" [(value)]="this.seguretatUsuari.dataFiDate" type="calendar" required></app-form-date-edit> -->
      <app-form-date-edit id="seguretat-usuaris-editor-ultimaConn" class="col-sm-3" i18n-label label="Última connexió" [(value)]="this.seguretatUsuari.ultimaConexio" 
        type="calendar" [isReadOnly]="true" *ngIf="this.seguretatUsuari.secureId !== null && this.seguretatUsuari.secureId !== undefined"></app-form-date-edit>
    </div>
    <div class="row">
      <!-- Tipus combo -->
      <app-form-combo-edit #tipusUsuariCombo class="col-sm-3" i18n-label label="Tipus" [items]="this.perfils" 
        [(value)]="this.seguretatUsuari.tipus" valueField="key" textField="value" required></app-form-combo-edit>
      <!-- Institució combo -->
      <app-form-combo-edit *ngIf="this.institucioAssignada === null && this.institucionesAux.length > 1" class="col-sm-3" i18n-label label="Institució" [items]="this.instituciones" 
        [(value)]="this.seguretatUsuari.institucionId" (valueChange)="this.filtrarAgents($event)" valueField="key" textField="value">
      </app-form-combo-edit>
      <app-form-text-edit id="seguretat-usuaris-editor-instit" *ngIf="this.institucioAssignada !== null || this.institucionesAux.length <= 1" class="col-sm-3" i18n-label label="Institució" 
        [(value)]="this.nomInstitucio" [isReadOnly]="true" required>
      </app-form-text-edit>
      <!-- Agents combo -->
      <app-form-combo-edit class="col-sm-3" i18n-label label="Agents" [items]="this.agentes" 
        [(value)]="this.seguretatUsuari.idAgenteFK" valueField="key" textField="value"></app-form-combo-edit>
        <!-- Actiu check -->
      <app-form-check-edit class="col-md-1" i18n-label label="Actiu" [(value)]="this.seguretatUsuari.actiu" (valueChange)="this.checkActiuValidarRangDates($event)"></app-form-check-edit>
      <!-- Super usuari check -->
      <app-form-check-edit *ngIf="this.showSuperUsuari" class="col-md-1" i18n-label label="Super usuari" [(value)]="this.seguretatUsuari.superuser"></app-form-check-edit>
    </div>
    <div class="row">
      <!-- Denuncies (agrupat de checks) -->
      <div class="card col-md-7">
        <div class="card-body row">
          <h5 class="card-header1" i18n>Denúncies</h5>
          <!-- Zona blava -->
          <app-form-check-edit class="col-md-2" i18n-label label="Zona blava" [(value)]="this.seguretatUsuari.zonaBlava"></app-form-check-edit>
          <!-- Tràfic (policia) -->
          <app-form-check-edit class="col-md-2" i18n-label label="Tràfic" [(value)]="this.seguretatUsuari.policia"></app-form-check-edit>
          <!-- Convivència -->
          <app-form-check-edit class="col-md-2" i18n-label label="Convivència" [(value)]="this.seguretatUsuari.convivencia"></app-form-check-edit>
          <!-- grua -->
          <app-form-check-edit class="col-md-2" i18n-label label="Grua" [(value)]="this.seguretatUsuari.grua"></app-form-check-edit>
          
        </div>
      </div>
    </div>
      
    <mat-tab-group>
      <!-- Tab grupos -->
      <mat-tab i18n-label label="Grupos">
        <!-- Lista de los grupos a los que está asociado el usuario -->
        <div class="row" style="margin: auto">
            <div class="table-sdw is-fixed" style="max-height: 50vh">
              <mat-table [dataSource]="dataSourceGrupos" matSort #sortGrupos="matSort" class="is-header-sticky">
                  <ng-container matColumnDef="grupo">
                    <mat-header-cell *matHeaderCellDef i18n>Grupo</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <span *ngIf="row.descripcio!==null">{{row.descripcio}}</span>
                      <app-form-combo-edit #gruposCombo *ngIf="row.descripcio===null" (valueChange)="novaFilaGrup($event, row)" [items]="segGrupos" [(value)]="row.idSegGrupos" valueField="id" textField="descripcio" ></app-form-combo-edit>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="eliminar">
                    <mat-header-cell *matHeaderCellDef i18n>Eliminar</mat-header-cell>
                    <mat-cell *matCellDef="let row;">
                      <i *ngIf="row.codiGrup!==null" (click)="removeGrup(row)" class="cursor fa fa-trash-alt btn btn-outline-danger btn-sm"></i>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumnsGrupos"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsGrupos;"></mat-row>
                </mat-table>
            </div>
          </div>
      </mat-tab>

      <!-- Tab permiso -->
      <mat-tab label="Permisos"> 
        <!-- Lista con Pantallas, Lectura, Alta, Modificación, Borrar, botonDelete -->
        <!-- Poder añadir más pantallas/permisos -->
        <div class="row" style="margin: auto">
          <div class="table-sdw is-fixed" style="max-height: 50vh">
            <mat-table [dataSource]="dataSourcePermisos" matSort #sortPermisos="matSort" class="is-header-sticky">
                <ng-container matColumnDef="pantalla">
                  <mat-header-cell *matHeaderCellDef i18n>Pantalla</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span *ngIf="row.pantalla!==null">{{row.pantalla}}</span>
                    <app-form-combo-edit #PantallasCombo *ngIf="row.pantalla===null" (valueChange)="novaFilaPantalla($event, row)" [items]="segPantallas" [(value)]="row.idSegPantallas" valueField="id" textField="descripcio" ></app-form-combo-edit>
                  </mat-cell>
                </ng-container>
          
                <ng-container matColumnDef="lectura">
                  <mat-header-cell *matHeaderCellDef i18n>Lectura</mat-header-cell>
                  <mat-cell *matCellDef="let row;"><app-form-check-edit *ngIf="row.idSegPantallas!==null" [(value)]="row.lectura"></app-form-check-edit></mat-cell>
                </ng-container>
          
                <ng-container matColumnDef="alta">
                  <mat-header-cell *matHeaderCellDef i18n>Alta</mat-header-cell>
                  <mat-cell *matCellDef="let row;"><app-form-check-edit *ngIf="row.idSegPantallas!==null" [(value)]="row.alta"></app-form-check-edit></mat-cell>
                </ng-container>
          
                <ng-container matColumnDef="modif">
                  <mat-header-cell *matHeaderCellDef i18n>Modif</mat-header-cell>
                  <mat-cell *matCellDef="let row;"><app-form-check-edit *ngIf="row.idSegPantallas!==null" [(value)]="row.modif"></app-form-check-edit></mat-cell>
                </ng-container>
          
                <ng-container matColumnDef="borrar">
                  <mat-header-cell *matHeaderCellDef i18n>Borrar</mat-header-cell>
                  <mat-cell *matCellDef="let row;"><app-form-check-edit *ngIf="row.idSegPantallas!==null" [(value)]="row.borrar"></app-form-check-edit></mat-cell>
                </ng-container>
    
                <ng-container matColumnDef="eliminar">
                  <mat-header-cell *matHeaderCellDef i18n>Eliminar</mat-header-cell>
                  <mat-cell *matCellDef="let row;">
                    <i *ngIf="row.idSegPantallas!==null" (click)="removePantalla(row)" class="cursor fa fa-trash-alt btn btn-outline-danger btn-sm"></i>
                  </mat-cell>
                </ng-container>
          
                <mat-header-row *matHeaderRowDef="displayedColumnsPermisos"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsPermisos;"></mat-row>
              </mat-table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</app-dialog-body>
