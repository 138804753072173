<app-filter-header title="Filtrar Tipos de envío" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-form-text-edit id="tipus-enviament-filter-tipoEnvio" class="col-sm-4" i18n-label label="Tipo envío" [(value)]="this.filter.tipoEnvio" ></app-form-text-edit>
    <app-form-text-edit id="tipus-enviament-filter-descripcion" class="col-sm-6" i18n-label label="Descripción" [(value)]="this.filter.descripcion" ></app-form-text-edit>
  </div>
  <div class="row">
    <app-form-check-edit id="tipus-enviament-filter-bop" class="col-sm-4" i18n-label label="BOP" [(value)]="this.filter.bop" required></app-form-check-edit>
    <app-form-text-edit id="tipus-enviament-filter-producto" class="col-sm-6" i18n-label label="Producto" [(value)]="this.filter.producto" ></app-form-text-edit>
  </div>
  <div class="row">
    <app-form-text-edit id="tipus-enviament-filter-cliente" class="col-sm-4" i18n-label label="Cliente" [(value)]="this.filter.cliente"></app-form-text-edit>
    <app-form-text-edit id="tipus-enviament-filter-codigoAdmision" class="col-sm-6" i18n-label label="Código admisión" [(value)]="this.filter.codigoAdmision" ></app-form-text-edit>
  </div>
</div>
