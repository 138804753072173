import { KeyCounter } from 'path-shared/models/keycounter';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Dictionary } from 'path-shared/models/dictionary';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-generic-file-displayer-table',
  templateUrl: './generic-file-displayer-table.component.html',
  styleUrls: ['./generic-file-displayer-table.component.scss']
})
export class GenericFileDisplayerTableComponent implements OnInit {

  @Output() show: EventEmitter<any>;
  @Output() delete: EventEmitter<any>;
  @Output() upload: EventEmitter<any>;
  @Output() download: EventEmitter<any>;

  _files: Array<Dictionary>; // Key: id fichero, value: nombre fichero.
  @Input() get files() {
    return this._files;
  } set files(val) {
    this._files = val;
    this.filesDataSource = new MatTableDataSource(this.files);
  }
  filesDataSource: MatTableDataSource<any>;

  currentSelects: Array<any>; // Keys/registros que actualmente estan seleccionados.
  displayedColumns = ['select', 'nombre'];

  constructor() {
    this.files = new Array();
    this.currentSelects = new Array();

    this.show = new EventEmitter<any>(undefined);
    this.delete = new EventEmitter<any>(undefined);
    this.upload = new EventEmitter<any>(undefined);
    this.download = new EventEmitter<any>(undefined);
  }

  ngOnInit() {
  }


  selectAll(): void {
    // Selecciona todos los registros siempre y cuando quede alguno por seleccionar.
    // Solo cuando estan TODOS los registros marcados, en vex de seleccionar, deseleccoinará.
    if (this.isAllSelected()) {
      this.currentSelects = new Array();
    } else {
      this.files.forEach(element => {
        if (this.currentSelects.findIndex(x => x === element.key) === -1) {
          // No esta seleccionado, lo seleccionamos.
          this.currentSelects.push(element.key);
        }
      });
    }
  }

  selectRow(row: Dictionary) {
    if (this.isRowSelected(row.key)) {
      // La deseleccionamos.
      const index = this.currentSelects.findIndex(x => x === row.key);
      this.currentSelects.splice(index, 1);
    } else {
      // La seleccionamos.
      this.currentSelects.push(row.key);
    }
  }

  clickRow(row: Dictionary) {
    // Debemos mostrar la imagen.
    this.show.emit(row.key);
  }

  isAllSelected(): boolean {
    let i = 0;
    let allSelected = true;
    while (i < this.files.length && allSelected) {
      if (this.currentSelects.find(e => e === this.files[i].key) === undefined) {
        allSelected = false;
      }
      i++;
    }
    return allSelected && this.files.length > 0;
  }

  isRowSelected(id): boolean {
    return id !== undefined && this.currentSelects !== undefined && this.currentSelects.find(e => e === id) !== undefined;
  }

  uploadFile(file: any): void {
    this.upload.emit(file);
  }

  downloadFiles(): void {
    this.download.emit(this.currentSelects);
  }

  deleteFiles(): void {
    this.delete.emit(this.currentSelects);
  }
}
