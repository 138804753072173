<mat-table [dataSource]="dataSource" aria-label="Elements">
  <ng-container matColumnDef="Operacio">
    <mat-header-cell *matHeaderCellDef i18n>Operació</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.COD_OPE}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="Data">
    <mat-header-cell *matHeaderCellDef i18n>Data</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.FEC_OPE | shortDate}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="Usuari">
    <mat-header-cell *matHeaderCellDef i18n>Usuari</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.USER_OPE}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="Descripcio">
    <mat-header-cell *matHeaderCellDef i18n>Descripció</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.DESCRIPCION}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="Obs.">
    <mat-header-cell *matHeaderCellDef i18n>Obs.</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.NOTAS_OPE}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="Anular">
    <mat-header-cell *matHeaderCellDef i18n>Anular.</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button type="button" class="btn btn-danger" [disabled]="this.isReadOnly" (click)="anularSingleOperacio(row)" i18n> 
        Anul·lar operació
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="Fichero">
    <mat-header-cell *matHeaderCellDef i18n>Fichero</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div *ngIf="row.REF_ELEME2; else senseFitxer" style="display: flex; height: 100%">
        <div style="margin: 5px;">{{row.REF_ELEME1}}</div>
        <button class="mdc-icon-button material-icons" (click)="this.getFilePath(row.REF_ELEME2)">
          <div class="mdc-icon-button__ripple"></div>
          insert_drive_file
        </button>
      </div>
      <ng-template #senseFitxer>
        <mat-cell>-</mat-cell>
      </ng-template>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef = "displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<!-- <div class="p-1">
  <button type="button" class="btn btn-light" [disabled]="this.isReadOnly" (click)="anularOperacio()" i18n> Anul·lar operació</button>
  
</div> -->