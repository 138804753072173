import { FilterData } from './../../../../shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class BonosResidentesFilter extends FilterData {
  id: number;
  fecha: Date;
  nombre: string;
  apellidos: string;
  telefono: string;
  dni: string;
  zona: string;
  importe: number;
  tipResPrepago: number;
  tipBono: number;
  pago: string;
  dataIni: Date;
  dataFi: Date;
  matricula: string;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.nombre = '';
    this.id = null;
    this.fecha = null;
    this.zona = '';
    this.importe = null;
    this.apellidos = '';
    this.telefono = '';
    this.dni = '';
    this.tipResPrepago = null;
    this.tipBono = null;
    this.pago = '';
    this.dataIni = null;
    this.dataFi = null;
    this.matricula = '';

    this.labels['nombre'] = this.i18n('Nombre');
    this.labels['id'] = this.i18n('Id');
    this.labels['fecha'] = this.i18n('Fecha');
    this.labels['zona'] = this.i18n('Zona');
    this.labels['importe'] = this.i18n('Importe');
    this.labels['apellidos'] = this.i18n('Apellidos');
    this.labels['telefono'] = this.i18n('Teléfono');
    this.labels['dni'] = this.i18n('Dni');
    this.labels['tipResPrepago'] = this.i18n('Tipo respuesta prepago');
    this.labels['tipBono'] = this.i18n('Tipo bono');
    this.labels['pago'] = this.i18n('Pago');
    this.labels['dataIni'] = this.i18n('Fecha inicial');
    this.labels['dataFi'] = this.i18n('Fecha final');
    this.labels['matricula'] = this.i18n('Matrícula');

  }
}
