import { TestService } from './test-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  constructor(private testService: TestService) {}

  ngOnInit() {
    this.testService.getPages().subscribe(result => console.log('pages:' + result));
  }

}
