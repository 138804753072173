<app-filter-header title="Filtrar carregues C60" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-filter-text-edit id="banc-carregues-c60-filter-codi" class="col-sm-4" i18n-label label="Codi propi" comparsion="=" [(value)]="filter.id"></app-filter-text-edit>
    <app-filter-text-edit id="banc-carregues-c60-filter-codiCarrega" class="col-sm-4" i18n-label label="Codi càrrega" [(value)]="filter.codiCarrega" contains></app-filter-text-edit>
    <app-filter-text-edit id="banc-carregues-c60-filter-descripcio" class="col-sm-4" i18n-label label="Descripció càrrega" [(value)]="filter.descripcioCarrega" contains></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="banc-carregues-c60-filter-nomFitxer" class="col-sm-4" i18n-label label="Nom fitxer" [(value)]="filter.nomFitxer" contains></app-filter-text-edit>
    <app-form-combo-edit  class="col-sm-4" i18n-label label="Estat" [items]="this.situacioCombo" [(value)]="filter.situacio" textField="text" valueField="value"></app-form-combo-edit>
  </div>
</div>
