import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription} from 'rxjs';
import { ZonesAparcareFilter } from '../../models/zones-aparcare-filter';
import { ZonesAparcareService } from '../../services/zones-aparcare.service';


@Component({
  selector: 'app-zones-aparcare-filter',
  templateUrl: './zones-aparcare-filter.component.html',
  styleUrls: ['./zones-aparcare-filter.component.scss']
})
export class ZonesAparcareFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: ZonesAparcareFilter;
  zones: null;
  residents: null;
  tipusTiquets: null;
  @Output() notify: EventEmitter<ZonesAparcareFilter> = new EventEmitter<ZonesAparcareFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<ZonesAparcareFilterComponent>,
    private i18n: I18n,
    private zonesAparcareService: ZonesAparcareService,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter;
  }

  ngOnInit() {

    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
