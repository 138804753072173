import { Component, OnInit, Inject } from '@angular/core';
import { ComprovacionCargaFicheroC60 } from '../../models/ComprovacionCargaFicheroC60';
import { MatPaginator, MatSort, MatDialog, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-resultad-carga-component',
  templateUrl: './resultad-carga-component.component.html',
  styleUrls: ['./resultad-carga-component.component.scss']
})
export class ResultadCargaComponentComponent implements OnInit {
  resultCarga: ComprovacionCargaFicheroC60;
  displayedColumns = ['exp', 'refC60' ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
      this.resultCarga = data;
   }

  ngOnInit() {
  }

}
