import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { DeteccioMatriculesFilterService } from './deteccio-matricules-filter.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../../shared/services/authentication/auth-guard.service";
var defaultSortParam = 'data';
var defaultSortDirection = 'asc';
var DeteccioMatriculesService = /** @class */ (function () {
    function DeteccioMatriculesService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
        this.displayedItems = [];
        this.displayedItems['checkedValues'] = 0;
        this.displayedItems['checkedItems'] = Array();
    }
    DeteccioMatriculesService.prototype.generateExcel = function (filter, sortDir, sortField) {
        var start = 0;
        var pageSize = 1000;
        sortField = sortField === undefined ? defaultSortParam : sortField;
        sortDir = sortDir === '' ? defaultSortDirection : sortDir;
        var params = new DeteccioMatriculesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);
        if (this.authGuard.canActivate()) {
            return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlDeteccioMatriculesExcel'], params);
        }
    };
    DeteccioMatriculesService.prototype.getImageUrl = function (secure_id, index, suffix) {
        return config.urls['apiUrl'].replace('{hostname}', window.location.hostname) + api.endpoints['urlDeteccioMatriculesImatge'].replace('{secure_id}', secure_id).replace('{n}', index).replace('{suffix}', suffix);
    };
    DeteccioMatriculesService.ngInjectableDef = i0.defineInjectable({ factory: function DeteccioMatriculesService_Factory() { return new DeteccioMatriculesService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: DeteccioMatriculesService, providedIn: "root" });
    return DeteccioMatriculesService;
}());
export { DeteccioMatriculesService };
