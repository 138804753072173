import { DesperfectosDto } from './../../Models/desperfectosDto';
import { Component, OnInit, AfterViewInit, Optional, Inject, ViewChild } from '@angular/core';
import { Domicili } from 'app/sections/ciutat/persones/models/domiciliDto';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DipositGruaService } from '../../services/diposit-grua-service';

@Component({
  selector: 'app-desperfectos-editor',
  templateUrl: './desperfectos-editor.component.html',
  styleUrls: ['./desperfectos-editor.component.scss']
})
export class DesperfectosEditorComponent implements OnInit {
  action: string;
  local_data: any;
  horaEntrada: any;
  constructor(
    public dialogRef: MatDialogRef<DesperfectosEditorComponent>,
    private dipositService: DipositGruaService,
    private toastr: ToastrService,
    private i18n: I18n,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = {...data};
    this.action = this.local_data.action;

    if(this.local_data !== undefined && this.local_data !== null && this.local_data.FechaHora !== undefined && this.local_data.FechaHora !== null){
      const t = new Date(this.local_data.FechaHora);
      this.horaEntrada = t.toLocaleTimeString();
    }
  }

  ngOnInit() {
  }

  doAction() {
    this.local_data.FechaHora = new Date(this.local_data.FechaHora);

      const  arrayHora = this.horaEntrada.split(':');
      if (arrayHora.length > 1) {
        //this.local_data.FechaHora.setHours(arrayHora[0], arrayHora[1]);
        this.local_data.FechaHora = new Date(this.local_data.FechaHora.getFullYear(),this.local_data.FechaHora.getMonth(),this.local_data.FechaHora.getDate(),arrayHora[0],arrayHora[1])
        //this.local_data.FechaHora.getFullYear()+'-'+this.local_data.FechaHora.getMonth()+''+this.local_data.FechaHora.getDay()+'T'+arrayHora[0]+':'+arrayHora[1];
      }
      //, data: this.local_data row_obj.idDipositVehicle = Number(this.value.idDipositVehicle);
      this.dipositService.setDesperfectos(this.local_data).subscribe(res => {
      this.dialogRef.close({event: this.action, data: this.local_data});
      });
    
  }

  closeDialog() {
    this.dialogRef.close({event: 'Cancel'});
  }

}
