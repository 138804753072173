var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'data';
var defaultSortDirection = 'asc';
var BancCarreguesIncidenciesFilterService = /** @class */ (function (_super) {
    __extends(BancCarreguesIncidenciesFilterService, _super);
    function BancCarreguesIncidenciesFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BancCarreguesIncidenciesFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('desde', Reflection.empty(filter.desde) || filter.desde === null ? null : filter.desde.toJSON())
            .append('hasta', Reflection.empty(filter.hasta) || filter.desde === null ? null : filter.hasta.toJSON())
            .append('noTramitados', this.getTristateValue(filter.noTramitados))
            .append('tramitados', this.getTristateValue(filter.tramitados))
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    BancCarreguesIncidenciesFilterService.prototype.getTristateValue = function (value) {
        if (value === undefined || value === null) {
            return '';
        }
        else {
            return value.toString();
        }
    };
    return BancCarreguesIncidenciesFilterService;
}(CustomFilterService));
export { BancCarreguesIncidenciesFilterService };
