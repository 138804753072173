<app-filter-header title="Filtrar propostes grua" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-filter-text-edit id="tramitades-filter-codi" class="col-sm-3" i18n-label label="Codi" [(value)]="filter.codi" contains></app-filter-text-edit>
    <app-filter-text-edit id="tramitades-filter-vehicle" class="col-sm-3" i18n-label label="Vehicle" [(value)]="filter.idVehiclesFK" contains></app-filter-text-edit>
    <app-filter-text-edit id="tramitades-filter-carrer" class="col-sm-3" i18n-label label="Carrer" [(value)]="filter.nombreCalle" contains></app-filter-text-edit>
    <app-filter-text-edit id="tramitades-filter-numCarrer" class="col-sm-3" i18n-label label="Núm carrer" [(value)]="filter.numCarrer" contains></app-filter-text-edit>
    <div class="row">
      <app-form-date-edit id="tramitades-filter-dataSIni" class="col-sm-3" i18n-label label="Data inici Acep/Den" [(value)]="filter.fechaAcepDenegInici" type="calendar"></app-form-date-edit>
      <app-form-date-edit id="tramitades-filter-dataSFi" class="col-sm-3" i18n-label label="Data final Acep/Den" [(value)]="filter.fechaAcepDenegFinal" type="calendar"></app-form-date-edit>
      <app-form-date-edit id="tramitades-filter-dataIni" class="col-sm-3" i18n-label label="Data inici" [(value)]="filter.dataInici" type="calendar"></app-form-date-edit>
      <app-form-date-edit id="tramitades-filter-dataFi" class="col-sm-3" i18n-label label="Data final" [(value)]="filter.dataFinal" type="calendar"></app-form-date-edit>
      <app-filter-text-edit id="tramitades-filter-numFotos"class="col-sm-3" i18n-label label="Núm fotos PDA" [(value)]="filter.numFotos" maxLenght="50" number="true"></app-filter-text-edit>
      <app-filter-text-edit id="tramitades-filter-numPDA" class="col-sm-3" i18n-label label="Núm PDA" [(value)]="filter.numPDA" maxLenght="50" number="true"></app-filter-text-edit>
      <app-combo-box-input-autocomplete #comboAgent class="center-horizontal col-sm-3" i18n-label label="Agent" [options]="this.agentsDictionary"
        (valorSeleccionado)="this.agentSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteAgent"  required="false" >
      </app-combo-box-input-autocomplete>

      <app-combo-box-input-autocomplete #comboTecnic class="center-horizontal col-sm-3" i18n-label label="Tecnic" [options]="this.tecnicsDictionary"
        (valorSeleccionado)="this.tecnicSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteTecnic"  required="false" >
      </app-combo-box-input-autocomplete>

      <app-combo-box-input-autocomplete #comboMotiusDesestimar class="center-horizontal col-sm-3" i18n-label label="Motiu desestimar" [options]="this.motiusDesestimarDictionary"
        (valorSeleccionado)="this.motiusDesestimarSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteMotiuDesestimar"  required="false" >
      </app-combo-box-input-autocomplete>
    </div>
    
    <div class="row">
      <div class="col-6">
        <app-form-tri-state  class="col-sm-12"  label="Implica Denúncia" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="this.filter.implicaDenuncia"></app-form-tri-state>
    
        <app-form-tri-state label="Sense càrrec" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="this.filter.sinCargo"></app-form-tri-state>
      </div>
    </div>
  </div>
</div>
