import { AuthorizationService } from './../../../shared/services/authorization/authorization-service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aparcare-page',
  templateUrl: './aparcare-page.component.html'
})
export class AparcarePageComponent implements OnInit {

  constructor(private authService: AuthorizationService) { }

  ngOnInit() {
    this.authService.CheckUserPermission(this.authService.Pages.Aparcare);
  }

}
