import { AuthorizationService } from './../../../shared/services/authorization/authorization-service';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { TiquetsFilterComponent } from '../component/tiquets-filter/tiquets-filter.component';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { TiquetsTiquetsTableComponent } from '../component/tiquets-table/tiquets-tiquets-table.component';
import { TiquetsFilter } from '../models/tiquets-filter';
import { FormControl } from '@angular/forms';
import { TiquetsDevolucionesTableComponent } from '../component/tiquets-devoluciones-table/tiquets-devoluciones-table.component';
import { TiquetsAnulacionesTableComponent } from '../component/tiquets-anulaciones-table/tiquets-anulaciones-table.component';
import { TiquetsService } from '../services/tiquets.service';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { TiquetsLeyenda } from '../models/tiquets-leyenda.model';
import { LoaderService } from 'path-shared/components/loader/loader.service';
import { TiquetsDevolucionesService } from '../services/tiquets-devoluciones.service';
import { TiquetsAnulacionesService } from '../services/tiquets-anulaciones.service';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { FormMultiSelectComponent } from 'path-shared/components/form/form-multi-select/form-multi-select/form-multi-select.component';

@Component({
  selector: 'app-tiquets-page',
  templateUrl: './tiquets-page.component.html',
  styleUrls: ['./tiquets-page.component.scss']
})
export class TiquetsPageComponent implements OnInit, AfterViewInit {
  proveidors = [];
  proveidorsSelected = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selected = new FormControl(0);
  filter: TiquetsFilter = new TiquetsFilter('id', 'desc');
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild('tiquetsTiquets') tiquetsTiquetsTable: TiquetsTiquetsTableComponent;
  @ViewChild('tiquetsDevolucions') tiquetsDevolucionesTable: TiquetsDevolucionesTableComponent;
  @ViewChild('tiquetsAnulacions') tiquetsAnulacionesTable: TiquetsAnulacionesTableComponent;
  @ViewChild('comboProvs') provCombo: FormMultiSelectComponent;
  tiquetLeyenda = new TiquetsLeyenda();
  tiquetLeyendaDevolucion = new TiquetsLeyenda();
  tiquetLeyendaAnulacion = new TiquetsLeyenda();

  currentTiquets = 0;
  currentDevolucions = 0;
  currentAnulacions = 0;

  showResum = true;

  constructor(
    public authService: AuthorizationService,
    private not: NotificationService,
    private tiquetsService: TiquetsService,
    private tiquetsDevolucionesService: TiquetsDevolucionesService,
    private tiquetsAnulacionesService: TiquetsAnulacionesService,
    private filterService: FilterService,
    private loaderService: LoaderService,
    private dialog: MatDialog,
    private i18n: I18n
  ) {
    if ((this.filter.fechaTicketInicio === null || this.filter.fechaTicketInicio === undefined || this.filter.fechaTicketInicio.toString() === '')
      && (this.filter.fechaTicketFin === null || this.filter.fechaTicketFin === undefined || this.filter.fechaTicketFin.toString() === '')
      && (this.filter.fechaBancoInicio === null || this.filter.fechaBancoInicio === undefined || this.filter.fechaBancoInicio.toString() === '')
      && (this.filter.fechaBancoFin === null || this.filter.fechaBancoFin === undefined || this.filter.fechaBancoFin.toString() === '')) {

        this.filter.fechaTicketInicio = new Date();
        this.filter.fechaTicketInicio.setHours(0, 0, 0, 0);
        this.filter.fechaTicketFin = new Date();

        this.filterService.saveFilter('tiquets-filter', this.filter);
        this.filter.set(this.filter);
        this.filter.updated();
    }
  }

  //#region CICLO DE VIDA
  ngOnInit() {
    this.loadProveidors();
    this.authService.CheckUserPermission(this.authService.Pages.Tiquets);
    this.filterService.loadFilter('tiquets-filter', this.filter);

    this.getTiquetLeyenda();
    this.getDevolucionesLeyenda();
    this.getAnulacionesLeyenda();

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.filterService.saveFilter('tiquets-filter', this.filter);
        this.tiquetsTiquetsTable.updateFilterFromParent(this.filter);
        this.tiquetsDevolucionesTable.updateFilterFromParent(this.filter);
        this.tiquetsAnulacionesTable.updateFilterFromParent(this.filter);

        this.getTiquetLeyenda();
        this.getDevolucionesLeyenda();
        this.getAnulacionesLeyenda();
      } else {
        this.tiquetLeyenda = new TiquetsLeyenda();
        this.tiquetLeyendaDevolucion = new TiquetsLeyenda();
        this.tiquetLeyendaAnulacion = new TiquetsLeyenda();
      }
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Tiquets'));
    }
  }

  //#endregion
  loadProveidors(){
    this.tiquetsService.getProveidors().subscribe(result => {
      const prov: Array<any> = Object.assign([], result);
      this.proveidors = [];
      prov.forEach(proveidor => {
        this.proveidors.push(new FormMultiSelect(proveidor, proveidor));

      });
    });
  }
  //#region Toolbar events
  refresh() {
    this.tiquetsTiquetsTable.updateFilterFromParent(this.filter);
    this.tiquetsDevolucionesTable.updateFilterFromParent(this.filter);
    this.tiquetsAnulacionesTable.updateFilterFromParent(this.filter);

    this.getTiquetLeyenda();
    this.getDevolucionesLeyenda();
    this.getAnulacionesLeyenda();
  }
  proveedoresChanged(){
    
    this.filter.proveidors = this.provCombo.getItemsSelected();
    this.filter.set(this.filter);
    this.filter.updated();
  }
  onFilterClicked() {
      const ref = this.dialog.open(TiquetsFilterComponent, {
        width: '50%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          'filter': this.filter
        }
      });

      ref.componentInstance.notify.subscribe((filter: TiquetsFilter) => {
        this.filter.set(filter);
      });
    }

  generateExcel() {
      switch (this.selected.value) {
        case 0: // Tiquets
          this.tiquetsTiquetsTable.generateExcel();
          break;
        case 1: // Devolucions
          this.tiquetsDevolucionesTable.generateExcel();
          break;
        case 2: // Anulacions
          this.tiquetsAnulacionesTable.generateExcel();
          break;
    }
  }
  //#endregion

  //#region  Others
  selectedTab(tab: string): boolean {
    let isTabSelected = false;
    switch (this.selected.value) {
      case 0: // Tiquets
        isTabSelected = 'tiquets' === tab;
        break;
      case 1: // Devolucions
        isTabSelected = 'devolucions' === tab;
        break;
      case 2: // Anulacions
        isTabSelected = 'anulacions' === tab;
        break;
    }

    return isTabSelected;
  }

  setBadgeCount(valueCount: number, page: number) {
    switch (page) {
      case 1:
        this.currentTiquets = valueCount;
        break;
      case 2:
        this.currentDevolucions = valueCount;
        break;
      case 3:
        this.currentAnulacions = valueCount;
        break;
    }
  }

  //#endregion

  //#region Peticions llegendes
  getTiquetLeyenda(filter: TiquetsFilter = this.filter) {
    this.loaderService.show(true);
    this.tiquetsService.getTiquetsLeyenda(filter).subscribe((tiquetLeyenda: TiquetsLeyenda) => {
      this.tiquetLeyenda = tiquetLeyenda;
      this.loaderService.hide(true);
    }, (e: any) => {
      this.loaderService.hide(true);
    });
  }

  getDevolucionesLeyenda(filter: TiquetsFilter = this.filter) {
    this.loaderService.show(true);
    this.tiquetsDevolucionesService.getDevolucionesLeyenda(filter).subscribe((tiquetLeyendaDevolucion: TiquetsLeyenda) => {
      this.tiquetLeyendaDevolucion = tiquetLeyendaDevolucion;
      this.loaderService.hide(true);
    }, (e: any) => {
      this.loaderService.hide(true);
    });
  }

  getAnulacionesLeyenda(filter: TiquetsFilter = this.filter) {
    this.loaderService.show(true);
    this.tiquetsAnulacionesService.getAnulacionesLeyenda(filter).subscribe((tiquetLeyendaAnulacion: TiquetsLeyenda) => {
      this.tiquetLeyendaAnulacion = tiquetLeyendaAnulacion;
      this.loaderService.hide(true);
    }, (e: any) => {
      this.loaderService.hide(true);
    });
  }
  //#endregion

}
