import { MatriculesLoaderComponent } from '../../components/matricules-loader/matricules-loader.component';
import { MatriculesFilterComponent } from '../../components/matricules-filter/matricules-filter.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Component, OnInit, ViewChild, AfterViewInit, Inject} from '@angular/core';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Router } from '@angular/router';
import { MatriculesService } from '../../services/matricules.service';
import { MatriculaFilter } from '../../models/matricula-filter';
import { MatriculesTableComponent } from '../../components/matricules-table/matricules-table.component';
import { ToastrService } from 'ngx-toastr';
import { TipusMatriculesService } from '../../../tipus-matricules/services/tipus-matricules.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatriculesEditorComponent } from '../../components/matricules-editor/matricules-editor.component';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { Builder } from 'path-shared/models/builder';
import { MarquesVehiclesService } from '../../../../configuracio/marques-vehicles/services/marques-vehicles-service.service';
import { MatriculesAddComponent } from '../../components/matricules-add/matricules-add.component';

@Component({
  selector: 'app-matricules',
  templateUrl: './matricules.component.html',
  styleUrls: ['./matricules.component.scss']
})
export class MatriculesComponent implements OnInit, AfterViewInit {
  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: MatriculaFilter;
  somIdFoo: number;
  tipusMatricules = Array();
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  marques = Array();
  selected = 0;


  @ViewChild(MatriculesTableComponent) matriculesTable: MatriculesTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private router: Router,
    private matriculesService: MatriculesService,
    private i18n: I18n,
    private toastr: ToastrService,
    private tipusMatriculesService: TipusMatriculesService,
    private marquesService: MarquesVehiclesService,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<MatriculesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new MatriculaFilter('', '');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.Matricules);
    this.tipusMatriculesService.getAll().subscribe(result => {
      this.tipusMatricules = result;
      /*if (result !== null) {
        result.forEach(element => {
          this.tipusMatricules.push();
        });
        this.matriculesTable.tipusMatricules = this.tipusMatricules;
      }*/
      this.marquesService.getAllMarques().subscribe(marc => {
        this.marques = marc;
      });
    });

    this.matriculesTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.matriculesTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Matrícules especials'));
    }
  }

  edit() {
    const idSeleccionat: string = this.matriculesService.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, null);
    // data. = this.tipusMatricules;
    const ref = this.dialog.open(MatriculesAddComponent, {

      width: '40%',
      closeOnNavigation: false,
      autoFocus: true,
      data: { 'data': data,
              'marques': this.marques,
              'tipusMatricules': this.tipusMatricules,
              'matricula': this.matriculesTable,
              'secureId': null
            },
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.matriculesTable.load(); }
    });
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);
    // data. = this.tipusMatricules;
    const ref = this.dialog.open(MatriculesEditorComponent, {

      width: '40%',
      closeOnNavigation: false,
      autoFocus: true,
      data: { 'data': data,
              'marques': this.marques,
              'tipusMatricules': this.tipusMatricules,
              'matricula': this.matriculesTable,
              'secureId': secure_id
            },
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.matriculesTable.load(); }
    });
  }



  refresh() {
    this.matriculesTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(MatriculesFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.matriculesTable.filter,
        'tipusMatricules': this.tipusMatricules
      }
    });

    ref.componentInstance.notify.subscribe((filter: MatriculaFilter) => {
      this.matriculesTable.filter.set(filter);
      this.matriculesTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.matriculesTable.generateExcel();
  }

  viewTipusMatricules() {
    this.router.navigate(['/ciutat/tipus-matricules']);
  }

  viewHistoricMatricules() {
    this.router.navigate(['/ciutat/matricules-historic']);
  }

  carregarMatricules() {
    const ref = this.dialog.open(MatriculesLoaderComponent, {
      width: '45%',
      closeOnNavigation: false,
      autoFocus: true,
      data: null
    });

    ref.afterClosed().subscribe(result => {
      if (result) {
        this.matriculesTable.load();
      }
    });
  }


}
