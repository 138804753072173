var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { I18n } from '@ngx-translate/i18n-polyfill';
import { OnInit, AfterViewInit, ElementRef, } from '@angular/core';
import { DenunciesService } from '../services/denuncies.service';
import { MatDialog } from '@angular/material';
import { DenunciesFilterComponent } from '../components/denuncies-filter/denuncies-filter.component';
import { AuthorizationService } from '../../../shared/services/authorization/authorization-service';
import { DenunciesTableComponent } from '../components/denuncies-table/denuncies-table.component';
import { Router } from '@angular/router';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { Builder } from 'path-shared/models/builder';
import { NovaOperacioComponent } from '../components/denuncies-editor/components/nova-operacio/nova-operacio.component';
import { DenunciesCobrarService } from 'path-denuncies-services/denuncies-cobrar.service';
import { OperacionGenericaDto } from '../models/operacionGenericaDto.model';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { FilesService } from 'path-shared/services/files/files.service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { ReportService } from 'path-shared/services/report/report.service';
import { ReportsComponent } from 'path-shared/components/reports/reports.component';
import * as XLSX from 'xlsx';
var TipoImportador;
(function (TipoImportador) {
    TipoImportador[TipoImportador["AcusosRebut"] = 0] = "AcusosRebut";
    TipoImportador[TipoImportador["ExpedientExtern"] = 1] = "ExpedientExtern";
})(TipoImportador || (TipoImportador = {}));
var DenunciesPageComponent = /** @class */ (function () {
    function DenunciesPageComponent(router, denunciesService, denunciesCobrarService, dialog, auth, i18n, toastr, not, confirmationDialogService, filesService, reportService) {
        var _this = this;
        this.router = router;
        this.denunciesService = denunciesService;
        this.denunciesCobrarService = denunciesCobrarService;
        this.dialog = dialog;
        this.auth = auth;
        this.i18n = i18n;
        this.toastr = toastr;
        this.not = not;
        this.confirmationDialogService = confirmationDialogService;
        this.filesService = filesService;
        this.reportService = reportService;
        this.details = null;
        this.tipusLegislacions = Array();
        this.dialogResult = '';
        this.formName = 'ClassSomintec.frExpedientes';
        this.reports = [];
        this.URL_REPORT = 'urlGenerateReportDenunciaInfo';
        this.denunciaDetail = Builder.newDenunciaDetail();
        this.dataExpedientExtern = Builder.newDenunciaExpedienteExterno();
        this.rutaPlantillaExpedient = '#BaseDirectory#Plantillas/Plantilla_Expedient.xlsx';
        this.size = 0;
        this.reportService.getReports(this.auth.Pages.Denuncies).subscribe(function (res) {
            _this.reports = res;
        });
    }
    DenunciesPageComponent.prototype.ngOnInit = function () {
        this.auth.CheckUserPermission(this.auth.Pages.Denuncies);
    };
    DenunciesPageComponent.prototype.ngAfterViewInit = function () {
        this.not.titleChange(this.i18n('Denúncies'));
    };
    DenunciesPageComponent.prototype.selectCheckedItems = function () {
        return this.denunciesService.displayedItems['checkedValues'];
    };
    DenunciesPageComponent.prototype.selectCheckedItemsForOperacio = function () {
        var canMakeNovaOperacio = false;
        for (var item in this.denunciesService.displayedItems) {
            if (this.denunciesService.displayedItems[item].checked
                && (this.denunciesService.displayedItems[item].tancat === undefined
                    || this.denunciesService.displayedItems[item].tancat === null || this.denunciesService.displayedItems[item].tancat === false)) {
                canMakeNovaOperacio = true;
                return canMakeNovaOperacio;
            }
        }
        return canMakeNovaOperacio;
    };
    DenunciesPageComponent.prototype.onRowSelected = function (somid) {
        this.somIdFoo = somid;
        this.router.navigate(['denuncies/editar-denuncia', somid]);
    };
    DenunciesPageComponent.prototype.closeDialog = function () {
        this.denunciesEditorDialogRef.close();
    };
    DenunciesPageComponent.prototype.onFilterClicked = function () {
        var _this = this;
        this.denunciesFilterDialogRef = this.dialog.open(DenunciesFilterComponent, {
            width: '90%',
            height: 'auto',
            closeOnNavigation: false,
            autoFocus: true,
            data: this.denunciesTable.filter
        });
        this.denunciesFilterDialogRef.componentInstance.notify.subscribe(function (filter) {
            _this.denunciesTable.filter.set(filter);
            _this.denunciesTable.loadDenunciasPage();
        });
    };
    DenunciesPageComponent.prototype.generateExcel = function () {
        this.denunciesTable.generateExcel();
    };
    DenunciesPageComponent.prototype.editDenuncia = function () {
        for (var item in this.denunciesService.displayedItems) {
            if (this.denunciesService.displayedItems[item].checked) {
                this.onRowSelected(item);
            }
        }
    };
    DenunciesPageComponent.prototype.newDenuncia = function () {
        var _this = this;
        this.denunciesService.createDenuncia().subscribe(function () {
            _this.router.navigate(['denuncies/nova-denuncia']);
        });
    };
    DenunciesPageComponent.prototype.onPermissionClick = function () {
        this.permissionDialogRef = this.dialog.open(PermissionDialogComponent, {
            width: '50%',
            minHeight: '70%',
            closeOnNavigation: false,
            autoFocus: true,
            data: this.auth.Pages.Denuncies,
        });
    };
    DenunciesPageComponent.prototype.updateDenuncies = function () {
        this.denunciesTable.loadDenunciasPage();
    };
    DenunciesPageComponent.prototype.NovaOperacio = function () {
        var _this = this;
        var selectedItems = [];
        for (var item in this.denunciesService.displayedItems) {
            if (this.denunciesService.displayedItems[item].checked
                && (this.denunciesService.displayedItems[item].tancat === undefined
                    || this.denunciesService.displayedItems[item].tancat === null || this.denunciesService.displayedItems[item].tancat === false)) {
                this.somIdFoo = Number.parseInt(item);
                selectedItems.push(this.somIdFoo);
            }
        }
        this.denunciesService.getDenunciesDetails(selectedItems).subscribe(function (denunciesDetails) {
            var tipoOpe = _this.denunciesService.getTipoOperacions().subscribe(function (novaOperacio) {
                _this.denunciesNovaOperacioRef = _this.dialog.open(NovaOperacioComponent, {
                    height: '80%',
                    width: '80%',
                    closeOnNavigation: false,
                    autoFocus: true,
                    data: {
                        'operacions': novaOperacio,
                        'data': denunciesDetails,
                        'isMassiva': true
                    },
                });
                _this.denunciesNovaOperacioRef.afterClosed().subscribe(function (res) {
                    if (res === true) { // s'ha executat alguna operació
                        _this.updateDenuncies();
                    }
                });
            });
        });
    };
    DenunciesPageComponent.prototype.cobrarDenunciaIndividual = function (selectedItems) {
        var _this = this;
        for (var item in this.denunciesService.displayedItems) {
            if (this.denunciesService.displayedItems[item].checked) {
                this.numDenuncia = item;
            }
        }
        this.denunciesService.getDenunciaDetails(Number.parseInt(this.numDenuncia)).subscribe(function (detailsdata) {
            _this.denunciaDetail = detailsdata;
            _this.denunciesService.getExpedient(detailsdata.expedient).subscribe(function (expedientsData) {
                _this.denunciesService.getSomExpedient(detailsdata.expedient).subscribe(function (denunciaExpedient) {
                    // Validacions i si tot va bé obrim modal per cobrar
                    _this.denunciesCobrarService.cobrarDenuncia(detailsdata, expedientsData, denunciaExpedient).subscribe(function (result) {
                        if (result) {
                            selectedItems.forEach(function (selectedItem) {
                                var denunciaActualizar = _this.denunciesTable.dataSource.getData().find(function (x) { return x.somid === selectedItem; });
                                // denunciaActualizar.dataCobra = (new Date()).toString();
                                _this.denunciesService.getVDenunciaIndividual(denunciaActualizar.somid).subscribe(function (res) {
                                    denunciaActualizar.cobroTotal = res.cobroTotal;
                                    denunciaActualizar.cobroParcial = res.cobroParcial;
                                    denunciaActualizar.dataCobra = res.dataCobra;
                                });
                            });
                        }
                    });
                });
            });
        });
    };
    DenunciesPageComponent.prototype.cobrarDenunciasLista = function (selectedItems) {
        var _this = this;
        // TODO: confirm preguntando si quiere seguir
        // "Està apunt de procedir al cobrament 100% de les N denúncies sel·leccionades, vol continuar?"
        this.denunciesService.getDenunciesDetails(selectedItems).subscribe(function (denunciesDetails) {
            _this.sendOperationCobrar(denunciesDetails, selectedItems);
        });
    };
    DenunciesPageComponent.prototype.sendOperationCobrar = function (denunciesDetails, selectedItems) {
        var _this = this;
        var codigo = 'COBRA_EXP';
        var dto = new OperacionGenericaDto;
        var message = this.i18n('Está a punto de proceder al cobro 100% de las ') + selectedItems.length + this.i18n(' denuncias seleccionadas, quiere continuar?');
        this.confirmationDialogService.confirm(this.i18n('Avís'), message)
            .then(function (confirmed) {
            if (confirmed) {
                _this.denunciesService.createOpearcioMasiva(denunciesDetails.map(function (x) { return x.secure_id_denuncia; }), codigo, dto).subscribe(function (result) {
                    selectedItems.forEach(function (selectedItem) {
                        var denunciaActualizar = _this.denunciesTable.dataSource.getData().find(function (x) { return x.somid === selectedItem; });
                        denunciaActualizar.dataCobra = (new Date()).toString();
                    });
                    _this.toastr.success(_this.i18n('Operacio realitzada correctament'));
                });
            }
        });
    };
    DenunciesPageComponent.prototype.cobrarDenuncia = function () {
        var selectedItems = [];
        for (var item in this.denunciesService.displayedItems) {
            if (this.denunciesService.displayedItems[item].checked) {
                this.somIdFoo = Number.parseInt(item);
                selectedItems.push(this.somIdFoo);
            }
        }
        if (selectedItems.length === 1) {
            this.cobrarDenunciaIndividual(selectedItems);
        }
        else {
            this.cobrarDenunciasLista(selectedItems);
        }
    };
    DenunciesPageComponent.prototype.carregaExpedientsClick = function () {
        this.tipoImportador = TipoImportador.ExpedientExtern;
        this.input.nativeElement.click();
    };
    DenunciesPageComponent.prototype.descarregaExpedientsClick = function () {
        var _this = this;
        this.filesService.getFile(this.rutaPlantillaExpedient).subscribe(function (file) {
            var name = 'Plantilla_Expedients.xlsx';
            var contentType = file.headers.get('Content-Type');
            if (contentType !== null && contentType !== undefined) {
                FileDownloader.downLoadFile([file.body], contentType + ';charset=utf-8', name);
            }
            else {
                _this.toastr.error(_this.i18n('No s\'ha trobat el fitxer'));
            }
        });
    };
    DenunciesPageComponent.prototype.onFileChange = function (event) {
        var _this = this;
        if (event.target.files && event.target.files.length) {
            var file = event.target.files[0];
            if (this.tipoImportador === TipoImportador.ExpedientExtern) {
                this.loadExcelToData(file);
                this.denunciesService.cargarExpedienteExerno(this.dataExpedientExtern).subscribe(function (result) {
                    _this.toastr.info(_this.i18n('Total expedients processats correctament: ') + Number.parseInt(result));
                });
            }
        }
    };
    DenunciesPageComponent.prototype.loadExcelToData = function (file) {
        var _this = this;
        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var arrayBuffer, data, arr, i, bstr, workBook, csvData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        arrayBuffer = reader.result;
                        data = new Uint8Array(arrayBuffer);
                        arr = new Array();
                        for (i = 0; i != data.length; ++i)
                            arr[i] = String.fromCharCode(data[i]);
                        bstr = arr.join("");
                        workBook = XLSX.read(bstr, { type: "binary" });
                        return [4 /*yield*/, workBook.SheetNames.reduce(function (initial, name) {
                                var sheet = workBook.Sheets[name];
                                initial[name] = XLSX.utils.sheet_to_csv(sheet); //we convert excel to csv
                                return initial;
                            }, {})];
                    case 1:
                        csvData = _a.sent();
                        this.dataExpedientExtern.name = Object.keys(csvData)[0];
                        this.dataExpedientExtern.value = Object.values(csvData)[0].toString();
                        return [2 /*return*/];
                }
            });
        }); };
    };
    ;
    //#region REPORTS
    DenunciesPageComponent.prototype.report = function () {
        var _this = this;
        var somIdDenuncia;
        for (var item in this.denunciesService.displayedItems) {
            if (this.denunciesService.displayedItems[item].checked) {
                somIdDenuncia = item;
            }
        }
        var ref = this.dialog.open(ReportsComponent, {
            width: '30%',
            height: '30%',
            closeOnNavigation: false,
            autoFocus: true,
            data: this.reports
        }).afterClosed().subscribe(function (report) {
            if (report !== '') {
                var reportInfo = {
                    'reportId': report.id,
                    'somIdDenuncia': somIdDenuncia
                };
                _this.reportService.generateReport(reportInfo, _this.URL_REPORT).subscribe(function (file) {
                    var name = report.nom;
                    var contentType = file.headers.get('Content-Type');
                    if (contentType !== null && contentType !== undefined) {
                        FileDownloader.downLoadFile([file.body], contentType + ';charset=utf-8', name.toString());
                    }
                    else {
                        _this.toastr.error(_this.i18n('No s\'ha pogut generar el fitxer'));
                    }
                });
            }
        });
    };
    return DenunciesPageComponent;
}());
export { DenunciesPageComponent };
