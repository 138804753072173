import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, AfterViewInit, Input, Output, ViewChild, EventEmitter, ElementRef, NgZone } from '@angular/core';
import { ValidationService } from 'path-shared/services/validation/validation.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-form-text-area',
  templateUrl: './form-text-area.component.html',
  styleUrls: ['./form-text-area.component.scss']
})
export class FormTextAreaComponent implements  OnInit, AfterViewInit  {
  val: string;

  @Input() label: string;
  @Input() get value() {
    return this.val;
  } set value(val) {
    if (this.val !== val) {
      this.val = val;
      setTimeout(() => {
        this.valueChange.emit(this.val);
      }, 10);
    }
  }
  @Output() valueChange = new EventEmitter();
  @Input() class: string;
  @Input() type: string;
  @Input() disabled: boolean;
  @Input() autofocus;
  @Input() required: boolean;
  @Input() name: string;
  @Input() number: boolean;
  @Input() maxLength: number;
  @Input() matricula: boolean;
  @Input() resize: boolean = false;

  @ViewChild('inputEdit') private elementRef: ElementRef;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  
  constructor(
    public validator: ValidationService
  ) {
    this.val = null;
    this.disabled = false;
    this.required = false;
    this.number = false;
    this.matricula = false;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.autosize.enabled = this.resize;
    if (this.autofocus !== undefined) {
      timer(500).subscribe(() => {
        this.elementRef.nativeElement.focus();
      });
    }
  }

  valid(): boolean {
    return !this.errorRequired() && !this.errorNumber() && !this.errorLength() && !this.errorMatricula();
  }

  isRequired() {
    return this.required !== false;
  }

  isRequiredNumber() {
    return this.number !== false;
  }

  isRequiredMatricula() {
    return this.matricula !== false;
  }

  hasMaxLength() {
    return this.validator.isNatural(this.maxLength.toString());
  }

  errorRequired() {
    return this.isRequired() && (this.value === null || this.value.length === 0);
  }

  errorNumber() {
    return this.isRequiredNumber() && !this.validator.isNumeric(this.value);
  }

  errorMatricula() {
    return this.isRequiredMatricula() && !this.validator.isMatricula(this.value);
  }

  errorLength() {
    if (this.maxLength === undefined) {
      return false;
    } else {
      return !this.validator.checkLength(this.value, this.maxLength);
    }
  }
}
