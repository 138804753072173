var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from "path-shared/models/filter-data";
var ConfirmacionBOEFilter = /** @class */ (function (_super) {
    __extends(ConfirmacionBOEFilter, _super);
    function ConfirmacionBOEFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.fechaCreacionInici = null;
        _this.fechaCreacionFinal = null;
        _this.fechaEdictoInici = null;
        _this.fechaEdictoFinal = null;
        _this.lote = null;
        _this.labels['fechaCreacionInici'] = _this.i18n('Fecha creación inicio');
        _this.labels['fechaCreacionFinal'] = _this.i18n('Fecha creación final');
        _this.labels['fechaEdictoInici'] = _this.i18n('Fecha edicto inicio');
        _this.labels['fechaEdictoFinal'] = _this.i18n('Fecha edicto final');
        _this.labels['lote'] = _this.i18n('Lote');
        _this.formatOutput['fechaCreacionInici'] = 'shortDate';
        _this.formatOutput['fechaCreacionFinal'] = 'shortDate';
        _this.formatOutput['fechaEdictoInici'] = 'shortDate';
        _this.formatOutput['fechaEdictoFinal'] = 'shortDate';
        return _this;
    }
    return ConfirmacionBOEFilter;
}(FilterData));
export { ConfirmacionBOEFilter };
