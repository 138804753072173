import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-radio-group',
  templateUrl: './form-radio-group.component.html',
  styleUrls: ['./form-radio-group.component.scss']
})
export class FormRadioGroupComponent implements OnInit {
  val: string;
  
  @Input() title: string;
  @Input() labels: string[];
  @Input() values: string[];
  @Input() horizontal: boolean;
  @Input()
  get value() { return this.val; }
  set value(val) { 
    if (val !== this.val) { 
      this.val = val; 
      this.valueChange.emit(this.val); 
    }
  }

  @Output() valueChange = new EventEmitter();

  constructor() {
    this.val = null;
    this.horizontal = false;
  }

  ngOnInit() {
  }
}
