import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { TarifasFilter } from '../models/tarifas-filters';
import { TarifaFilterService } from './tarifa-filter.service';
import { TiketFilterService } from './tiket-filter.service.';
import { TiketFilter } from '../models/tiket-filters';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';


@Injectable()
export class TiketService {
  displayedItems = [];

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  getAll(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new TiketFilterService().createFilterParams(new TiketFilter('DESC_TIPO', 'asc'), 0, 99999, 'DESC_TIPO', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTipotiket'],  params);
    }
  }

}
