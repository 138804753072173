/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./acusos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i3 from "../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i4 from "../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i5 from "../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i6 from "../../../shared/components/toolbar/tool-dropdown-button/tool-dropdown-button.component.ngfactory";
import * as i7 from "../../../shared/components/toolbar/tool-dropdown-button/tool-dropdown-button.component";
import * as i8 from "../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i9 from "../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i10 from "./acusos-table/acusos-table.component.ngfactory";
import * as i11 from "./acusos-table/acusos-table.component";
import * as i12 from "../../../shared/services/filter/filter.service";
import * as i13 from "../../denuncies/services/denuncies.service";
import * as i14 from "@angular/material/dialog";
import * as i15 from "../services/acusos.service";
import * as i16 from "./acusos.component";
import * as i17 from "../../../shared/services/authorization/authorization-service";
import * as i18 from "../../../shared/services/files/files.service";
import * as i19 from "@ngx-translate/i18n-polyfill";
import * as i20 from "ngx-toastr";
var styles_AcusosComponent = [i0.styles];
var RenderType_AcusosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AcusosComponent, data: {} });
export { RenderType_AcusosComponent as RenderType_AcusosComponent };
export function View_AcusosComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { acusosTable: 0 }), i1.ɵqud(402653184, 2, { input: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 14, "app-toolbar", [], null, null, null, i2.View_ToolbarComponent_0, i2.RenderType_ToolbarComponent)), i1.ɵdid(3, 114688, null, 0, i3.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "app-tool-button", [["color", "has-color-blue"], ["icon", "fa-sync"], ["text", "Actualitzar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateAcusos() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(5, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "app-tool-button", [["color", "has-color-orange"], ["icon", "fa-filter"], ["text", "Filtre"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFilterClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(7, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "app-tool-button", [["color", "has-color-orange"], ["icon", "fa-lock"], ["text", "Permisos"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPermissionClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(9, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "app-tool-dropdown-button", [["color", "has-color-orange"], ["icon", "fa-tasks"], ["label1", "Descarregar plantilla"], ["label2", "Carregar plantilla"], ["numeroSubmenus", "2"], ["text", "Importar acusos de rebut"]], null, [[null, "click1"], [null, "click2"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click1" === en)) {
        var pd_0 = (_co.descarregaAcusosClick() !== false);
        ad = (pd_0 && ad);
    } if (("click2" === en)) {
        var pd_1 = (_co.carregaAcusosClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ToolDropdownButtonComponent_0, i6.RenderType_ToolDropdownButtonComponent)), i1.ɵdid(11, 114688, null, 0, i7.ToolDropdownButtonComponent, [], { label1: [0, "label1"], label2: [1, "label2"], numeroSubmenus: [2, "numeroSubmenus"], text: [3, "text"], color: [4, "color"], icon: [5, "icon"] }, { click1: "click1", click2: "click2" }), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "app-tool-button", [["color", "has-color-green"], ["icon", "fa-save"], ["text", "Informar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.informarAcusos(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(13, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(14, 0, null, 0, 1, "app-tool-button", [["color", "has-color-red"], ["icon", "fa-save"], ["text", "Rebutjar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.informarAcusos(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(15, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(16, 0, [[2, 0], ["uploadFileInput", 1]], 0, 0, "input", [["class", "form-control-file"], ["hidden", ""], ["onclick", "this.value = null"], ["style", "width: 0px"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "app-dialog-header", [["label", "Acusos de rebut"]], null, null, null, i8.View_DialogHeaderComponent_0, i8.RenderType_DialogHeaderComponent)), i1.ɵdid(18, 114688, null, 0, i9.DialogHeaderComponent, [], { label: [0, "label"], isCloseButton: [1, "isCloseButton"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-acusos-table", [], null, null, null, i10.View_AcusosTableComponent_0, i10.RenderType_AcusosTableComponent)), i1.ɵdid(20, 4833280, [[1, 4]], 0, i11.AcusosTableComponent, [i12.FilterService, i13.DenunciesService, i14.MatDialog, i15.AcusosService, i14.MAT_DIALOG_DATA], { updatedFilterData: [0, "updatedFilterData"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = "Actualitzar"; var currVal_1 = "has-color-blue"; var currVal_2 = "fa-sync"; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Filtre"; var currVal_4 = "has-color-orange"; var currVal_5 = "fa-filter"; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = "Permisos"; var currVal_7 = "has-color-orange"; var currVal_8 = "fa-lock"; _ck(_v, 9, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = "Descarregar plantilla"; var currVal_10 = "Carregar plantilla"; var currVal_11 = "2"; var currVal_12 = "Importar acusos de rebut"; var currVal_13 = "has-color-orange"; var currVal_14 = "fa-tasks"; _ck(_v, 11, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = "Informar"; var currVal_16 = "has-color-green"; var currVal_17 = "fa-save"; _ck(_v, 13, 0, currVal_15, currVal_16, currVal_17); var currVal_18 = "Rebutjar"; var currVal_19 = "has-color-red"; var currVal_20 = "fa-save"; _ck(_v, 15, 0, currVal_18, currVal_19, currVal_20); var currVal_21 = "Acusos de rebut"; var currVal_22 = false; _ck(_v, 18, 0, currVal_21, currVal_22); var currVal_23 = _co.filterData; _ck(_v, 20, 0, currVal_23); }, null); }
export function View_AcusosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-acusos", [], null, null, null, View_AcusosComponent_0, RenderType_AcusosComponent)), i1.ɵdid(1, 114688, null, 0, i16.AcusosComponent, [i13.DenunciesService, i17.AuthorizationService, i18.FilesService, i19.I18n, i14.MatDialog, i20.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AcusosComponentNgFactory = i1.ɵccf("app-acusos", i16.AcusosComponent, View_AcusosComponent_Host_0, {}, {}, []);
export { AcusosComponentNgFactory as AcusosComponentNgFactory };
