import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { ActuacioMatricula } from '../../models/actuacioMatricula.model';
import { ActuacioMatriculaFilter } from '../../models/actuacioMatricula-filter';
import { MAT_DIALOG_DATA, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { ActuacioMatriculaFilterService } from '../../services/ActuacioMatricula-filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActuacionsMapaComponentComponent } from '../actuacions-mapa-component/actuacions-mapa-component.component';

@Component({
  selector: 'app-actuacionsmatricula-table-component',
  templateUrl: './actuacionsmatricula-table-component.component.html',
  styleUrls: ['./actuacionsmatricula-table-component.component.scss']
})
export class ActuacionsmatriculaTableComponentComponent {
  datainici: Date;
  datafi: Date;
  matricula: String;s

  constructor(
    private filterService: FilterService,     
    @Inject(MAT_DIALOG_DATA) public dataOfDialog: ComponentDialog,
    private dialog: MatDialog,
    ) {
   
   }

  ngOnInit() {
    this.datafi = new Date();
    this.datainici = new Date();
    this.datainici.setDate(this.datainici.getDate() - 3);
  }

  
  ngAfterViewInit() {
  
  }

  Consultar(): void {
    this.dialog.open(ActuacionsMapaComponentComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: {'datainici' : this.datainici, 'datafi':this.datafi,'matricula':this.matricula},
      width: '90%',
      height: '90%',
      
    });
  }
}
