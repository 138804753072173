var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material';
import { DipositGruaService } from '../../services/diposit-grua-service';
var DesperfectosEditorComponent = /** @class */ (function () {
    function DesperfectosEditorComponent(dialogRef, dipositService, toastr, i18n, data) {
        this.dialogRef = dialogRef;
        this.dipositService = dipositService;
        this.toastr = toastr;
        this.i18n = i18n;
        this.data = data;
        this.local_data = __assign({}, data);
        this.action = this.local_data.action;
        if (this.local_data !== undefined && this.local_data !== null && this.local_data.FechaHora !== undefined && this.local_data.FechaHora !== null) {
            var t = new Date(this.local_data.FechaHora);
            this.horaEntrada = t.toLocaleTimeString();
        }
    }
    DesperfectosEditorComponent.prototype.ngOnInit = function () {
    };
    DesperfectosEditorComponent.prototype.doAction = function () {
        var _this = this;
        this.local_data.FechaHora = new Date(this.local_data.FechaHora);
        var arrayHora = this.horaEntrada.split(':');
        if (arrayHora.length > 1) {
            //this.local_data.FechaHora.setHours(arrayHora[0], arrayHora[1]);
            this.local_data.FechaHora = new Date(this.local_data.FechaHora.getFullYear(), this.local_data.FechaHora.getMonth(), this.local_data.FechaHora.getDate(), arrayHora[0], arrayHora[1]);
            //this.local_data.FechaHora.getFullYear()+'-'+this.local_data.FechaHora.getMonth()+''+this.local_data.FechaHora.getDay()+'T'+arrayHora[0]+':'+arrayHora[1];
        }
        //, data: this.local_data row_obj.idDipositVehicle = Number(this.value.idDipositVehicle);
        this.dipositService.setDesperfectos(this.local_data).subscribe(function (res) {
            _this.dialogRef.close({ event: _this.action, data: _this.local_data });
        });
    };
    DesperfectosEditorComponent.prototype.closeDialog = function () {
        this.dialogRef.close({ event: 'Cancel' });
    };
    return DesperfectosEditorComponent;
}());
export { DesperfectosEditorComponent };
