import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { VehicleFilter } from '../models/vehicle-filter';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class VehiclesFilterService extends CustomFilterService {
  public createFilterParams(filter: VehicleFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('id_vehicles', Reflection.hasValue(filter.id_vehicles) ? filter.id_vehicles.toString() : '')
    .append('matricula', filter.matricula ? filter.matricula : '')
    .append('marca', filter.marca ? filter.marca : '')
    .append('model', filter.model ? filter.model : '')
    .append('color', filter.color ? filter.color : '')
    .append('asseguranca', filter.asseguranca ? filter.asseguranca : '')
    .append('poliza', filter.poliza ? filter.poliza : '')
    .append('nacionalitat', filter.observacions ? filter.observacions : '')
    .append('telefon1', filter.origen ? filter.origen : '')
    .append('id_extern', filter.tipus_vehicle ? filter.tipus_vehicle : '')
    .append('buscat', this.getTristateValue(filter.requisitoria))
    .append('proposta', Reflection.empty(filter.proposta) ? null : typeof(filter.proposta) === 'string' ?
    filter.proposta : filter.proposta.toDateString())
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }

  getTristateValue(value) {
    if (value === null) {
      return '';
    } else {
      return value.toString();
    }
  }

}
