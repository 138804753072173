/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./toolbar.component";
var styles_ToolbarComponent = [i0.styles];
var RenderType_ToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToolbarComponent, data: {} });
export { RenderType_ToolbarComponent as RenderType_ToolbarComponent };
export function View_ToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "actions-bar"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "actions-bar__wrapper"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(5, 1), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "actions-bar"; var currVal_1 = _ck(_v, 2, 0, ((_co.half !== null) ? "half" : "")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "actions-bar__wrapper"; var currVal_3 = _ck(_v, 5, 0, ((_co.half !== null) ? "half" : "")); _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
export function View_ToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toolbar", [], null, null, null, View_ToolbarComponent_0, RenderType_ToolbarComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToolbarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolbarComponentNgFactory = i1.ɵccf("app-toolbar", i3.ToolbarComponent, View_ToolbarComponent_Host_0, { half: "half" }, {}, ["*"]);
export { ToolbarComponentNgFactory as ToolbarComponentNgFactory };
