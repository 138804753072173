<div class="row justify-content-between">
  <div class="col">
      <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  <div class="col">
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>
  </div>
</div>


<div class="table-sdw is-fixed">

  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky">

      <ng-container matColumnDef="select" >
          <mat-header-cell  *matHeaderCellDef title="Seleccionar" i18n-title class="text-center"></mat-header-cell>
          <mat-cell  *matCellDef="let row" >
              <mat-checkbox #checkbox (click)="selectRow(row)" [disabled]="this.disableSelect" [(ngModel)]="row.selected"></mat-checkbox>
          </mat-cell>
      </ng-container>

       <!-- Descripcion -->
      <ng-container matColumnDef="descripcion">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripció</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.descripcion }}</mat-cell>
      </ng-container>

      <!-- Fecha inicio -->
      <ng-container matColumnDef="fechaInicio">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data Inici</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.fechaInicio | shortDate }}</mat-cell>
      </ng-container>       

      <!-- Fecha fin -->
      <ng-container matColumnDef="fechaFin">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data fi</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.fechaFin |shortDate }}</mat-cell>
      </ng-container>

      <!-- Idioma -->
      <ng-container matColumnDef="idioma">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Idioma</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ this.getIdiomaFromSigla(row.idioma) }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
  </mat-table>
</div>