<app-dialog-header label="Recaptació" i18n-label [isCloseButton]="false"></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.data.isReadOnly = false" color="has-color-green" icon="fa-edit" *ngIf="this.auth.UserCanWrite(this.auth.Pages.Recaudacio) && this.data.isReadOnly" [disabled]="!this.data.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar (F10)" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" *ngIf="this.auth.UserCanWrite(this.auth.Pages.Recaudacio)" [disabled]="this.data.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" *ngIf="this.auth.UserCanDelete(this.auth.Pages.Recaudacio) && this.secure_id !== null" [disabled]="this.data.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-date-edit id="recaudacio-editor-data" class="col-sm-2" i18n-label label="Data" [(value)]="this.recaudacio.fecha" type="calendar" required></app-form-date-edit>
      <!-- combo zona -->
      <app-combo-box-input #comboZones class="col-sm-3" [label]="'Zona'" [showingOptions]="this.zonesComboDictionary.value" 
          (optionChanged)="this.zonaSeleccionado($event)" [(value)]="this.recaudacio.zonaFk"></app-combo-box-input>
      <!-- combo maquina (dependent de zona) -->
      <app-combo-box-input #comboMaquinesParkeon class="col-sm-2" [label]="'Nº Expenedor'" [showingOptions]="this.maquinesParkeonComboDictionary.value" 
          (optionChanged)="this.maquinaParkeonSeleccionado($event); this.expenedorInfo($event)" [(value)]="this.recaudacio.maquinaParkeonFk" [disabled]="this.maquinesParkeonComboDictionary.value.length === 0"></app-combo-box-input>
    </div>
    <div class="row">
      <app-form-text-edit id="recaudacio-editor-numColecta" class="col-sm-3" type="number" i18n-label label="Nº Colecta" [(value)]="this.recaudacio.numColecta"
        (valueChange)="this.numColectaCorrelativo()" required></app-form-text-edit>
      <app-form-text-edit id="recaudacio-editor-numColectaJustificacio" class="col-sm-9" type="text" i18n-label label="Nº Colecta Justificació" [(value)]="this.recaudacio.numColectaJustificante" 
        maxlength="200" *ngIf="this.numColectaJustificanteActiu" required></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-currency-text-edit class="col-sm-3" i18n-label label="Import moneda D" [(value)]="this.recaudacio.importeMonedaD"
      (valueChange)="this.formulaImporteMonedaLegal(); this.formulaImporteRealAparcamiento()" required></app-form-currency-text-edit>
      <app-form-text-edit id="recaudacio-editor-numTicketMoneda" class="col-sm-3" type="number" i18n-label label="Nº Tiquets moneda" [(value)]="this.recaudacio.numeroTicketsMoneda"
        (valueChange)="this.formulaNumeroTicketsTotal()" required></app-form-text-edit>
      <app-form-currency-text-edit class="col-sm-3" i18n-label label="Anul·lació moneda" [(value)]="this.recaudacio.anulacionMoneda"
        (valueChange)="this.formulaImporteAnulacionImporteTotal()" required></app-form-currency-text-edit>
      <app-form-text-edit id="recaudacio-editor-numTicketMonedaComercial" class="col-sm-3" type="number" i18n-label label="Nº Tiquets moneda comercial" [(value)]="this.recaudacio.numeroTicketsMonedaComercial"
        (valueChange)="this.formulaImporteMonedaComercial()" required></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-currency-text-edit class="col-sm-3" i18n-label label="Import targeta PayOne" [(value)]="this.recaudacio.importeTarjetaPayone"
        (valueChange)="this.formulaImporteTarjetaTotal()" required></app-form-currency-text-edit>
      <app-form-text-edit id="recaudacio-editor-numTicketTarjetaPago" class="col-sm-3" type="number" i18n-label label="Nº Tiquets targeta PayOne" [(value)]="this.recaudacio.numeroTicketsTarjetaPayone"
        (valueChange)="this.formulaNumeroTicketsTotal()" required></app-form-text-edit>
      <app-form-currency-text-edit class="col-sm-3" i18n-label label="Import anul·lació targeta PayOne" [(value)]="this.recaudacio.importeAnulacionTarjetaPayOne"
      (valueChange)="this.formulaImporteAnulacionImporteTotal()" required></app-form-currency-text-edit>
      <app-form-currency-text-edit class="col-sm-3" i18n-label label="Import recàrrega Payone" [(value)]="this.recaudacio.importeRecargaTarjetaPayone"
        (valueChange)="this.formulaImporteRecargaTarjetaTotal()" required></app-form-currency-text-edit>
    </div>
    <div class="row">
      <app-form-currency-text-edit class="col-sm-3" i18n-label label="Import targeta PayOnePlus" [(value)]="this.recaudacio.importeTarjetaPayonePlus"
        (valueChange)="this.formulaImporteTarjetaTotal()" required></app-form-currency-text-edit>
      <app-form-text-edit id="recaudacio-editor-numTicketTarjetaPagoPlus" class="col-sm-3" type="number" i18n-label label="Nº Tiquets targeta PayOnePlus" [(value)]="this.recaudacio.numeroTicketsTarjetaPayonePlus"
        (valueChange)="this.formulaNumeroTicketsTotal()" required></app-form-text-edit>
      <app-form-currency-text-edit class="col-sm-3" i18n-label label="Import anul·lació targeta PayOnePlus" [(value)]="this.recaudacio.importeAnulacionTarjetaPayOnePlus"
      (valueChange)="this.formulaImporteAnulacionImporteTotal()" required></app-form-currency-text-edit>
      <app-form-currency-text-edit class="col-sm-3" i18n-label label="Import recàrrega Payone Plus" [(value)]="this.recaudacio.importeRecargaTarjetaPayonePlus"
        (valueChange)="this.formulaImporteRecargaTarjetaTotal()" required></app-form-currency-text-edit>
    </div>

    <div class="row">
      <app-form-currency-text-edit class="col-sm-4" i18n-label label="Import recompte" [(value)]="this.recaudacio.importeRecuento"
        (valueChange)="this.formulaDiferenciaRecuentoTicket()" required></app-form-currency-text-edit>
      <app-form-currency-text-edit class="col-sm-4" i18n-label label="Import moneda legal (calculat)" [(value)]="this.recaudacio.importeMonedaLegal"
        (valueChange)="this.formulaDiferenciaRecuentoTicket()" [isReadOnly]="true" required [tabindex]="-1"></app-form-currency-text-edit>
      <app-form-currency-text-edit class="col-sm-4" i18n-label label="Import moneda comercial (calculat)" [(value)]="this.recaudacio.importeMonedaComercial"
      (valueChange)="this.formulaImporteMonedaLegal()" [isReadOnly]="true" required [tabindex]="-1"></app-form-currency-text-edit>
    </div>
    <div class="row">
      <app-form-currency-text-edit class="col-sm-4" i18n-label label="Import total targeta (calculat)" [(value)]="this.recaudacio.importeTarjetaTotal"
        (valueChange)="this.formulaImporteRealAparcamiento()" [isReadOnly]="true" required [tabindex]="-1"></app-form-currency-text-edit>
      <app-form-currency-text-edit class="col-sm-4" i18n-label label="Import total recàrrega targeta (calculat)" [(value)]="this.recaudacio.importeRecargaTarjetaTotal"
        (valueChange)="this.formulaImporteRealAparcamiento()" [isReadOnly]="true" required [tabindex]="-1"></app-form-currency-text-edit>
      <app-form-currency-text-edit class="col-sm-4" i18n-label label="Import total anul·lacions (calculat)" [(value)]="this.recaudacio.anulacionImporteTotal"
        (valueChange)="this.formulaImporteRealAparcamiento()" [isReadOnly]="true" required [tabindex]="-1"></app-form-currency-text-edit>
    </div>
    <div class="row">
      <app-form-currency-text-edit class="col-sm-4" i18n-label label="Import real aparcament (calculat)" [(value)]="this.recaudacio.importeRealAparcamiento"
        [isReadOnly]="true" required [tabindex]="-1"></app-form-currency-text-edit>
      <app-form-text-edit id="recaudacio-editor-numeroTicketsTotal" class="col-sm-4" type="number" i18n-label label="Nº Total tiquets (calculat)" [(value)]="this.recaudacio.numeroTicketsTotal"
        [isReadOnly]="true" required [tabindex]="-1"></app-form-text-edit>
      <app-form-currency-text-edit class="col-sm-4" i18n-label label="Diferència recompte/tiquet (calculat)" [(value)]="this.recaudacio.diferenciaRecuentoTicket"
        [isReadOnly]="true" required [tabindex]="-1"></app-form-currency-text-edit>
    </div>

    <!-- Sumatorios retroactivos -->
    <div class="card">
      <div class="card-body">
        <h5 class="card-title" i18n>Acumulats</h5>
        <div class="row">
          <app-form-currency-text-edit class="col-sm-3" i18n-label label="Acumulat moneda" [(value)]="this.recaudacio.acumuladoMoneda"
            (valueChange)="this.acumuladoMonedaValidar()" required></app-form-currency-text-edit>
          <app-form-text-edit id="recaudacio-editor-acumulatMonedaJustificacio"  class="col-sm-9" type="text" i18n-label label="Acumulat moneda justificació" [(value)]="this.recaudacio.acumuladoMonedaJustificacion"
            *ngIf="this.acumuladoMonedaJustificacionActiu" maxlength="200" required></app-form-text-edit>
        </div>
      </div>
    </div>
  </form>
</app-dialog-body>
