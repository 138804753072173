import { EnvioDetailComponent } from './../envio-detail/envio-detail.component';
import { Component, Input, OnInit, SimpleChanges, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EnviosPagedRequestDto } from '../../models/envios-filter';
import { EnviosDto } from '../../models/enviosDto';
import { EnviosFilterService } from '../../services/envios-filter-service';
import { EnviosService } from '../../services/envios-service';

@Component({
  selector: 'app-envios-table',
  templateUrl: './envios-table.component.html',
  styleUrls: ['./envios-table.component.scss']
})
export class EnviosTableComponent implements OnInit , AfterViewInit, OnChanges{

  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  filter: EnviosPagedRequestDto = new EnviosPagedRequestDto('codi', 'desc');
  dataSource: CustomDataSource<EnviosDto, EnviosPagedRequestDto>;
  highlightedRows = [];
  displayedItems = [];
  denunciesImages: any = [];
  matriculaFilter: string;
  @Input() updatedFilterData: EnviosPagedRequestDto;
  // @Output() notify: EventEmitter<string> = new EventEmitter<string>();
  displayedColumns = ['codi', 'entidad', 'idEnvio', 'tipoEnvio', 'fechaCreacion', 'usuarioCreacion', 'fechaEnvio', 'usuarioEnvio', 'pdfBoe', 'fechaNotiBoe', 'usuarioBOE', 'pubBOE'];
  constructor(
    private enviosService: EnviosService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n,
  ) { }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<EnviosDto, EnviosPagedRequestDto>(this.paginator, new EnviosFilterService(), 'urlGetEnviosTramitacion', 'urlGetEnviosTramitacionCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
        // item.IMATGES = this.loadImatges(item);
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
      }
    });

    this.filter.paginator.init(this.sort, this.paginator);
  }
  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }
  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }
  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }
  onRowClicked(row) {
    if (row !== undefined && row !== null) {
      this.enviosService.getRowsEnvio(row.idEnvio).subscribe(x => {
        const ref = this.dialog.open(EnvioDetailComponent, {
          width: '90%',
          height: '90%',
          closeOnNavigation: true,
          autoFocus: true,
          data: {
            'isDialog': true,
            'isReadOnly': false,
            'detail': row,
            'rows': x.rowsEnvios,
            'reports': x.reports
          }
        });
        ref.afterClosed().subscribe(data => {
          this.update();
        });
      });
      

    }
  }
}
