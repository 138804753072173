/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./progress-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "./progress-bar.component";
import * as i7 from "../../services/notification/notification.service";
var styles_ProgressBarComponent = [i0.styles];
var RenderType_ProgressBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgressBarComponent, data: {} });
export { RenderType_ProgressBarComponent as RenderType_ProgressBarComponent };
function View_ProgressBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
function View_ProgressBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["class", "progress-bar-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "determinate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(3, 49152, null, 0, i3.MatProgressBar, [i1.ElementRef, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"], mode: [1, "mode"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["style", "position: absolute; padding: 10px; color: white;"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", "% "]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.value, ""); var currVal_5 = "determinate"; _ck(_v, 3, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.class; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).value; var currVal_2 = i1.ɵnov(_v, 3).mode; var currVal_3 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); var currVal_6 = _co.value; _ck(_v, 5, 0, currVal_6); }); }
function View_ProgressBarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "progress-bar-section"], ["style", "height: auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
function View_ProgressBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressBarComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressBarComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressBarComponent_4)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.value !== null) && (_co.label !== null)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.value !== null); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.value !== null) && (_co.text !== null)); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ProgressBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressBarComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pantalla === _co.type); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProgressBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-progress-bar", [], null, null, null, View_ProgressBarComponent_0, RenderType_ProgressBarComponent)), i1.ɵdid(1, 114688, null, 0, i6.ProgressBarComponent, [i7.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProgressBarComponentNgFactory = i1.ɵccf("app-progress-bar", i6.ProgressBarComponent, View_ProgressBarComponent_Host_0, { class: "class", label: "label", pantalla: "pantalla" }, {}, []);
export { ProgressBarComponentNgFactory as ProgressBarComponentNgFactory };
