<app-dialog-header label="Operació" i18n-label></app-dialog-header>
<app-dialog-body>
  <div class="row" style="padding: 5px;">
    <div class="col-md-6">
      <mat-table #table [dataSource]="tipoOps" matSort aria-label="Elements" matSortActive="DESC_OPE" matSortDirection="asc">

        <ng-container matColumnDef="Desc">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Operació</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.DESC_OPE}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked(row)"></mat-row>
      </mat-table>
    </div>

    <div class="col-md-6">

      <mat-form-field>
        <input [(ngModel)]='desc' matInput i18n-placeholder placeholder="Operació" [value]="this.i18n('Cap operació seleccionada')" disabled>
      </mat-form-field>

      <label for="motius" *ngIf="showMotivosDesestimar" i18n>Selecciona un motiu:</label>
      <select id="motius" style="margin:5px;" *ngIf="showMotivosDesestimar" [(ngModel)]="selectedMotivoDesestimar">
        <option class='option' *ngFor='let option of motivosDesestimar' [value]="option.ID">{{option.DESCRIPCIO}}</option>
      </select>

      
      <mat-form-field *ngIf="showMotivosDesestimar">
        <input [(ngModel)]='obs' matInput i18n-placeholder placeholder="Comentari" value="">
      </mat-form-field>
      <div class="row">
        <div class="col-md-6">
          <button class="btn btn-sq-lg btn-primary" (click)="executeOperation()">
              <i class="fas fa-edit"></i><br/><span i18n>Executar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-dialog-body>
