<app-dialog-header *ngIf="this.dataDialog.isDialog" label="Text Informatiu Aparcare" i18n-label></app-dialog-header>

<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="edit();" color="has-color-orange" icon="fa-edit" 
    [disabled]="this.currentTexto === undefined || this.creating || this.editing">
  </app-tool-button>
  <app-tool-button text="Nou" i18n-text (click)="create();" color="has-color-green" icon="fa-plus-square" 
    [disabled]="!this.auth.UserCanCreate(this.auth.Pages.Agents) || this.creating || this.editing">
  </app-tool-button>
  <app-tool-button text="Actualizar" i18n-text (click)="refresh();" color="has-color-blue" icon="fa-sync" 
    [disabled]="this.creating || this.editing">
  </app-tool-button>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"
    [disabled]="this.creating || this.editing">
  </app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.Agents">
  </app-tool-button-permissions>
  <app-tool-button text="Guardar" i18n-text (click)="save();" color="has-color-green" icon="fa-save" 
                   [disabled]="!(this.currentTexto !== undefined && (this.editing || this.creating))">
  </app-tool-button>
  <app-tool-button text="Cancelar" i18n-text (click)="cancel();" color="has-color-red" icon="fa-times"
                   [disabled]="!(this.currentTexto !== undefined && (this.editing || this.creating))">
  </app-tool-button>
</app-toolbar>

<div class="row">
  <app-texto-informativo-table #textoInformativoTable class="col-6" [selectButton]="this.dataDialog.isDialog && !this.dataDialog.isReadOnly" [updatedFilterData]="this.filterData"
                              (itemSelected)="this.onRowSelected($event)" [siglasDictionary]="this.siglasDictionary.value" (filterUpdated)="this.filterUpdated($event)">
  </app-texto-informativo-table>

  <div class="col-6">
    <span *ngIf="this.editing"><h5 class="text-center">{{"Editant Text"}}</h5></span>
    <span *ngIf="this.creating"><h5 class="text-center">{{"Creant Text"}}</h5></span>
  
    <app-texto-informativo-editor #textoInformativoEditor [currentTexto]="this.currentTexto" [idiomaDictionary]="this.siglasDictionary.value"
                      [currentEditing]="this.currentTexto !== undefined && (this.editing || this.creating)">
    </app-texto-informativo-editor>
  </div>
</div>
