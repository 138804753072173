import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { BonosFilterService } from './bonos-filter.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { BonosFilter } from '../models/bonos-filter';

const defaultSortParam = 'fechaFin';
const defaultSortDirection = 'desc';

@Injectable({
  providedIn: 'root'
})
export class BonosService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {

  }

  generateExcel(filter: BonosFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new BonosFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlBonosExcel'],  params );
    }
  }



}
