var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'codi';
var defaultSortDirection = 'asc';
var DipositGruaFilterService = /** @class */ (function (_super) {
    __extends(DipositGruaFilterService, _super);
    function DipositGruaFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DipositGruaFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        if (filter.dataEntradaFinal !== undefined && filter.dataEntradaFinal !== null) {
            filter.dataEntradaFinal.setHours(23, 59, 59);
        }
        if (filter.dataSortidaFinal !== undefined && filter.dataSortidaFinal !== null) {
            filter.dataSortidaFinal.setHours(23, 59, 59);
        }
        return new HttpParams()
            .append('idTipusVehicleFK', (filter.idTipusVehicleFK ? filter.idTipusVehicleFK.toString() : ''))
            .append('idMotiuEntradaFK', (filter.idMotiuEntradaFK ? filter.idMotiuEntradaFK.toString() : ''))
            .append('idTecnicFK', (filter.idTecnicFK ? filter.idTecnicFK.toString() : ''))
            .append('dataEntradaInici', Reflection.empty(filter.dataEntradaInici) ? null : typeof (filter.dataEntradaInici) === 'string' ?
            filter.dataEntradaInici : filter.dataEntradaInici.toDateString())
            .append('dataEntradaFinal', Reflection.empty(filter.dataEntradaFinal) ? null : typeof (filter.dataEntradaFinal) === 'string' ?
            filter.dataEntradaFinal : filter.dataEntradaFinal.toDateString())
            .append('dataSortidaInici', Reflection.empty(filter.dataSortidaInici) ? null : typeof (filter.dataSortidaInici) === 'string' ?
            filter.dataSortidaInici : filter.dataSortidaInici.toDateString())
            .append('dataSortidaFinal', Reflection.empty(filter.dataSortidaFinal) ? null : typeof (filter.dataSortidaFinal) === 'string' ?
            filter.dataSortidaFinal : filter.dataSortidaFinal.toDateString())
            .append('matricula', (filter.matricula ? filter.matricula.toString() : ''))
            .append('idDipositList', filter.diposits !== undefined && filter.diposits !== null ? filter.diposits.map(function (s) { return s.item_id; }).toString() : '')
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return DipositGruaFilterService;
}(CustomFilterService));
export { DipositGruaFilterService };
