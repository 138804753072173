<app-dialog-header label="Vehícle" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" [disabled]="!this.dialogData.isReadOnly" icon="fa-edit" *ngIf="this.dialogData.isReadOnly && this.editable" ></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="this.dialogData.isReadOnly || !this.dialogData.editable"></app-tool-button>
  <app-tool-button text="Tancar" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-times"></app-tool-button>
  
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="vehicles-editor-matricula" class="col-sm-6" i18n-label label="Matricula:" [(value)]="this.vehicle.matricula"  required  [disabled]="this.dialogData.isReadOnly || !this.editable"   maxLength="25"></app-form-text-edit>
      <app-form-text-edit id="vehicles-editor-asseguranca" class="col-sm-6" i18n-label label="CIA. Asseguradora:" [(value)]="this.vehicle.asseguranca"   maxLength="30"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>
    </div>
    <div class="row">
      <!--<app-form-text-edit class="col-sm-6" i18n-label label="Marca:" [(value)]="this.vehicle.marca"   maxLength="15"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>-->
      <app-combo-box-input #comboMarca   class="col-sm-6" label="Marca" [showingOptions]="this.marquesDictionary.value"  [disabled]="this.dialogData.isReadOnly"
      (optionChanged)="this.marcaSeleccionado($event)">
    </app-combo-box-input>
      <app-form-text-edit  id="vehicles-editor-polissa" class="col-sm-6" i18n-label label="Polissa:" [(value)]="this.vehicle.poliza"  maxLength="25"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>
    </div>
    
    <div class="row">
      <app-form-text-edit id="vehicles-editor-model" class="col-sm-6" i18n-label label="Model:" [(value)]="this.vehicle.model"   maxLength="50"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>
      <app-form-date-edit id="vehicles-editor-proposta" class="col-sm-6" i18n-label label="Data proposta:" [(value)]="this.vehicle.proposta" type="calendar"  [isReadOnly]="this.dialogData.isReadOnly" ></app-form-date-edit>
    </div>

    <div class="row">
      <app-form-text-edit id="vehicles-editor-color" class="col-sm-6" i18n-label label="Color:" [(value)]="this.vehicle.color"   maxLength="15"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>
      <app-form-check-edit class="col-sm-2" i18n-label label="Requisitori" [(value)]="this.vehicle.requisitoria" (valueChange)="null"   [disabled]="this.dialogData.isReadOnly" ></app-form-check-edit>
    </div>
    
    <div class="row">
      <!--<app-form-text-edit class="col-sm-6" i18n-label label="Tipus vehicle:" [(value)]="this.vehicle.tipus_vehicle"  maxLength="15"  [disabled]="this.dialogData.isReadOnly" ></app-form-text-edit>-->
      <app-combo-box-input #comboTipusVehicle   class="col-sm-6" label="Tipus vehicle" [showingOptions]="this.tipusVehicleDictionary.value"  [disabled]="this.dialogData.isReadOnly"
        (optionChanged)="this.tipusVehicleSeleccionado($event)">
      </app-combo-box-input>
      <app-combo-box-input #comboPais   class="col-sm-6" label="Pais" [showingOptions]="this.paisDictionary.value"  [disabled]="this.dialogData.isReadOnly"
        (optionChanged)="this.paisSeleccionado($event)">
      </app-combo-box-input>
      <!--<app-form-check-edit class="col-sm-6" i18n-label label="Pais" [(value)]="this.vehicle.pais" (valueChange)="null"   [disabled]="this.dialogData.isReadOnly" ></app-form-check-edit>-->
    </div>
  </form>
</app-dialog-body>
