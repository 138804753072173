<app-filter-header title="Filtrar confirmaciones" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <div class="row">
      <app-form-date-edit id="confirmacion-boe-filter-dataIni" class="col-sm-3" i18n-label 
        label="Fecha creación inicio" [(value)]="filter.fechaCreacionInici" type="calendar"></app-form-date-edit>
      <app-form-date-edit id="confirmacion-boe-filter-dataFi"  class="col-sm-3" i18n-label 
        label="Fecha creación final" [(value)]="filter.fechaCreacionFinal" type="calendar"></app-form-date-edit>
      <app-form-date-edit id="confirmacion-boe-filter-dataIniEdice" class="col-sm-3" i18n-label 
        label="Fecha edicto inicio" [(value)]="filter.fechaEdictoInici" type="calendar"></app-form-date-edit>
      <app-form-date-edit id="confirmacion-boe-filter-dataFiEdice" class="col-sm-3" i18n-label 
        label="Fecha edicto final" [(value)]="filter.fechaEdictoFinal" type="calendar"></app-form-date-edit>
      <!-- <app-text-edit id="confirmacion-boe-filter-lote" class="col-sm-3" i18n-label label="Lote" [(value)]="filter.lote" maxLenght="50" number="true"></app-text-edit> -->
      <app-form-text-edit id="confirmacion-boe-filter-lote"  class="col-xl-3" i18n-label label="Lote" [(value)]="filter.lote" ></app-form-text-edit>
    </div>
  </div>
</div>
