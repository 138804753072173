<app-dialog-header [label]="this.name"></app-dialog-header>
<app-toolbar>
    <app-tool-button text="Afegir" i18n-text (click)="this.newCara()" color="this.getAddButtonColor()"
        icon="fa-plus-circle" [disabled]="this.croping"></app-tool-button>
    <app-tool-button text="Pixelar" i18n-text (click)="this.saveCares()" color="has-color-green" icon="fa-chess-board"
        [disabled]="this.croping || !this.isElementSelected"></app-tool-button>
    <app-tool-button text="Eliminar" i18n-text (click)="this.deleteSelectedCara()" color="has-color-red" icon="fa-trash"
        [disabled]="this.croping || !this.selectedElement"></app-tool-button>
    <app-tool-button text="Rotar" i18n-text (click)="this.rotar()" color="has-color-blue" icon="fa-redo-alt"
        [disabled]="this.croping"></app-tool-button>
    <app-tool-button text="Restaurar" i18n-text (click)="this.undo(this.data['SomId'])" color="has-color-orange"
        icon="fa-undo" [disabled]="this.croping"></app-tool-button>
    <app-tool-button text="Mostrar original" i18n-text (click)="this.mostrarOriginal()" color="has-color-purple"
        icon="fa-search-plus" [disabled]="this.croping"></app-tool-button>
    <app-tool-button text="Retallar" i18n-text (click)="this.Crop()" color="has-color-green" icon="fa-crop"
        [disabled]="this.croping"></app-tool-button>
    <app-tool-button text="Confirmar" i18n-text (click)="this.ConfirmCrop()" color="has-color-green" icon="fa-check"
        *ngIf="this.croping"></app-tool-button>
    <app-tool-button text="Cancelar" i18n-text (click)="this.CancelCrop()" color="has-color-red" icon="fa-times"
        *ngIf="this.croping"></app-tool-button>
    <app-tool-button text="Requadres" i18n-text (click)="this.toggleMostrarCaras()" [color]="colorRequadresButton()"
        icon="fa-vector-square" [disabled]="this.croping"></app-tool-button>
    <app-tool-button *ngIf="botoMatricula" text="Matrícula" i18n-text (click)="this.saveMatricula()"
        color="has-color-blue" icon="fa-car"></app-tool-button>
    <app-tool-button text="Descargar" (click)="forceDownload()" i18n-text color="has-color-blue" icon="fa-download"></app-tool-button>




</app-toolbar>

<div class="row">
    <div *ngIf="this.proposta !== undefined" class="col-sm-1"></div>
    <div [ngClass]="[ this.proposta !== undefined ? 'col-sm-5 text-left' : 'col-sm-12 text-center']">
        <span i18n>Brillantor</span>
        <mat-slider thumbLabel #slider [ngStyle]="this.getWidthBrillantor()" min="0" value="5" max="10"
            (input)="BrightnessChanged($event)"></mat-slider>
        <button mat-mini-fab (click)="addBrightness(-1)">-</button>
        <button mat-mini-fab (click)="addBrightness(+1)">+</button>
    </div>

    <div class="col-sm-5 text-right" *ngIf="this.proposta !== undefined" style=" padding-bottom: 10px; margin:auto">
        <app-matricula [(matricula)]="this.proposta.MATRICULA" [(vehicle)]="this.proposta.VEHICLE"
            [(pais)]="this.proposta.pais" [sugPais]="this.proposta.sugPaises"
            [sugMatricula]="this.proposta.sugMatricules" [activarSugerencias]="true" (canvioPais)="this.setPais($event)"
            (canvioMatricula)="this.setMatricula($event)"></app-matricula>
        <i class="fas fa-pen click-icon" (click)="this.saveMatricula()" i18n-title title="Modificar"
            style="margin-left: 10px; cursor:pointer; margin-top: auto; margin-bottom: auto;"></i>
    </div>
    <div *ngIf="this.proposta !== undefined" class="col-sm-1"></div>
</div>


<app-dialog-body>
    <div class="drag-scroll" style="height:60%">
        <div align="center" id="canvas" style="height:630px;width:930px;overflow:auto;" #canvas
            [ngClass]="[ this.addRectangleOnClick ? 'add' : '']">
        </div>
        <div class="drag-scroll__actions">
            <button class="action-button__prev" *ngIf="indexImatgeNew > 0" (click)="moveLeft()"><i
                    class="fa fa-angle-left"></i></button>
            <button class="action-button__next" *ngIf="indexImatgeNew < llistaImatges.length-1" (click)="moveRight()"><i
                    class="fa fa-angle-right"></i></button>
        </div>
    </div>
</app-dialog-body>