
    <app-toolbar>  
        <app-tool-button text="Actualizar" i18n-text (click)="cargarFicheros();" color="has-color-blue" icon="fa-sync"></app-tool-button>
        <app-tool-button text="Processar" i18n-text [disabled]="!canProces()" (click)="processar();" color="has-color-orange" icon="fa-filter"></app-tool-button>
        <app-tool-button-permissions [permission]="this.authService.Pages.Tramitar"></app-tool-button-permissions>
      </app-toolbar>
    <div>
      <div class="row">
        <mat-table #table [dataSource]="ficheros" matSort aria-label="Elements"  class="is-header-sticky"  >
            <ng-container matColumnDef="select" >
                <mat-header-cell *matHeaderCellDef title="Seleccionar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
                <mat-cell *matCellDef="let row"  ><mat-checkbox #checkbox [(ngModel)]="row.selected" [disabled]="true"   ></mat-checkbox></mat-cell><!--(change)="this.Checked(row)"-->
              </ng-container>
        
              <ng-container matColumnDef="nombre">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fichero</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row.fichero}}</mat-cell>
              </ng-container>
    
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" (click)="seleccionarFila(row)" [ngClass]="{'rowSelectedClass': row.selected}"></mat-row><!--[ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"-->
            </mat-table>
      </div>  
  </div>   
  