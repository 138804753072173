/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./desperfectos-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i3 from "../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i4 from "../photo-box-grua/photo-box-grua.component.ngfactory";
import * as i5 from "../photo-box-grua/photo-box-grua.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../../../denuncies/services/denuncies.service";
import * as i8 from "../../../../shared/services/images/images.service";
import * as i9 from "../../../../shared/components/confirmation-dialog/confirmation-dialog.service";
import * as i10 from "ngx-toastr";
import * as i11 from "@ngx-translate/i18n-polyfill";
import * as i12 from "../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i13 from "../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i14 from "@angular/common";
import * as i15 from "../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i16 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i17 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i18 from "../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i19 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i20 from "../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i21 from "../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i22 from "../../../../shared/services/validation/validation.service";
import * as i23 from "./desperfectos-editor.component";
import * as i24 from "../../services/diposit-grua-service";
var styles_DesperfectosEditorComponent = [i0.styles];
var RenderType_DesperfectosEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DesperfectosEditorComponent, data: {} });
export { RenderType_DesperfectosEditorComponent as RenderType_DesperfectosEditorComponent };
function View_DesperfectosEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tool-button", [["color", "has-color-green"], ["icon", "fa-save"], ["text", "Guardar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToolButtonComponent_0, i2.RenderType_ToolButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" })], function (_ck, _v) { var currVal_0 = "Guardar"; var currVal_1 = "has-color-green"; var currVal_2 = "fa-save"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DesperfectosEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tool-button", [["color", "has-color-red"], ["icon", "fa-trash-alt"], ["text", "Eliminar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToolButtonComponent_0, i2.RenderType_ToolButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" })], function (_ck, _v) { var currVal_0 = "Eliminar"; var currVal_1 = "has-color-red"; var currVal_2 = "fa-trash-alt"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DesperfectosEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-xl-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-photo-box-grua", [["height", "auto"]], [[8, "botoMatricula", 0], [8, "allowSelect", 0]], null, null, i4.View_PhotoBoxGruaComponent_0, i4.RenderType_PhotoBoxGruaComponent)), i1.ɵdid(3, 114688, [["pbg", 4]], 0, i5.PhotoBoxGruaComponent, [i6.MatDialogRef, i6.MAT_DIALOG_DATA, i7.DenunciesService, i8.ImagesService, i9.ConfirmationDialogService, i10.ToastrService, i11.I18n], { imagesInput: [0, "imagesInput"], isDialog: [1, "isDialog"], contenedor: [2, "contenedor"], numDenuncia: [3, "numDenuncia"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.local_data == null) ? null : _co.local_data.IMATGES); var currVal_3 = false; var currVal_4 = _co.local_data.SomId; var currVal_5 = _co.local_data.iddenuncia; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = false; var currVal_1 = false; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_DesperfectosEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "app-toolbar", [], null, null, null, i12.View_ToolbarComponent_0, i12.RenderType_ToolbarComponent)), i1.ɵdid(1, 114688, null, 0, i13.ToolbarComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DesperfectosEditorComponent_1)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DesperfectosEditorComponent_2)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "app-tool-button", [["color", "has-color-red"], ["icon", "fa fa-times "], ["text", "Cancel\u00B7lar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToolButtonComponent_0, i2.RenderType_ToolButtonComponent)), i1.ɵdid(7, 114688, null, 0, i3.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 3, "app-form-date-edit", [["id", "desperfectos-editor-data"], ["label", "Data"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.local_data.FechaHora = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_FormDateEditComponent_0, i15.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i16.DateTimeAdapter, i17.MomentDateTimeAdapter, [i16.OWL_DATE_TIME_LOCALE]), i1.ɵdid(11, 114688, null, 0, i18.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], id: [2, "id"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i19.OWL_DATE_TIME_FORMATS, i18.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-form-text-edit", [["class", "col-sm-1"], ["id", "desperfectos-editor-hora"], ["label", "Hora"], ["type", "time"]], [[8, "readOnly", 0]], [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.horaEntrada = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i20.View_FormTextEditComponent_0, i20.RenderType_FormTextEditComponent)), i1.ɵdid(14, 4308992, null, 0, i21.FormTextEditComponent, [i22.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], type: [3, "type"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-3"], ["id", "desperfectos-editor-descripcio"], ["label", "Descripci\u00F3"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.local_data.Descripcio = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i20.View_FormTextEditComponent_0, i20.RenderType_FormTextEditComponent)), i1.ɵdid(16, 4308992, null, 0, i21.FormTextEditComponent, [i22.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DesperfectosEditorComponent_3)), i1.ɵdid(18, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = (_co.action !== "Delete"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.action === "Delete"); _ck(_v, 5, 0, currVal_1); var currVal_2 = "Cancel\u00B7lar"; var currVal_3 = "has-color-red"; var currVal_4 = "fa fa-times "; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = "Data"; var currVal_6 = _co.local_data.FechaHora; var currVal_7 = "desperfectos-editor-data"; _ck(_v, 11, 0, currVal_5, currVal_6, currVal_7); var currVal_9 = "Hora"; var currVal_10 = _co.horaEntrada; var currVal_11 = "col-sm-1"; var currVal_12 = "time"; var currVal_13 = "desperfectos-editor-hora"; _ck(_v, 14, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = "Descripci\u00F3"; var currVal_15 = _co.local_data.Descripcio; var currVal_16 = "col-xl-3"; var currVal_17 = false; var currVal_18 = "desperfectos-editor-descripcio"; _ck(_v, 16, 0, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); var currVal_19 = (_co.action !== "Add"); _ck(_v, 18, 0, currVal_19); }, function (_ck, _v) { var currVal_8 = false; _ck(_v, 13, 0, currVal_8); }); }
export function View_DesperfectosEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-desperfectos-editor", [], null, null, null, View_DesperfectosEditorComponent_0, RenderType_DesperfectosEditorComponent)), i1.ɵdid(1, 114688, null, 0, i23.DesperfectosEditorComponent, [i6.MatDialogRef, i24.DipositGruaService, i10.ToastrService, i11.I18n, [2, i6.MAT_DIALOG_DATA]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DesperfectosEditorComponentNgFactory = i1.ɵccf("app-desperfectos-editor", i23.DesperfectosEditorComponent, View_DesperfectosEditorComponent_Host_0, {}, {}, []);
export { DesperfectosEditorComponentNgFactory as DesperfectosEditorComponentNgFactory };
