import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FilterTypes } from 'path-shared/models/filter-types';
import { Reflection } from 'path-shared/services/reflection/reflection';

@Component({
  selector: 'app-filter-type',
  templateUrl: './filter-type.component.html',
  styleUrls: ['./filter-type.component.scss']
})
export class FilterTypeComponent implements OnInit {
  val: string;
  in: string;
  replacableItems = ['=', '%', '!=', '!%', '>=', '>', '<=', '<'];
  public Types = FilterTypes;
  number: boolean;

  @Input() operator = '=';
  @Input() type: FilterTypes;
  @Input() defaultComparsion: string;
  @Input() isNumber: boolean;

  @Input()
  get input() { return this.in; }
  set input(val) {
    if (this.in !== val) {
      if (val === undefined || val === null || val === '') {
        this.in = '';
        this.value = '';
      } else {
        this.in = val;
        this.value  = this.operator + val;
      }
      this.inputChange.next(this.in);
    }

  }
  @Output() inputChange = new EventEmitter();
  @Input()
    get value() { return this.val; }
    set value(val) {
      if (this.val !== val) {
        setTimeout(() => {
          if (val !== null && val !== undefined) {
            this.val = val;
          } else {
            this.val = '';
          }
          this.operator = this.operatorFromString(this.val);
          this.input = this.fromString(this.val);
          this.valueChange.next(this.val);
         }, 20);
      }
    }
    @Output() valueChange = new EventEmitter();

  constructor(
    private cdRef: ChangeDetectorRef
  ) {
    this.operator = '=';
  }

  ngOnInit() {
    //
    if (this.isNumber != null && this.isNumber) {
      this.type = FilterTypes.NUMERO;
      this.defaultComparsion = '>=';
    } else {
      this.type = FilterTypes.TEXT;
    }
    setTimeout(() => {
      if (this.defaultComparsion !== this.operator && Reflection.empty(this.value)) {
        this.operator = this.defaultComparsion;
      }
    }, 100);
  }

  selectedChange() {
    this.value = this.operator + this.input;
  }

  setValue(val) {
    this.operator = val;
    this.selectedChange();
  }

  public fromString(val) {
    if (val !== null && val !== undefined && val !== '') {
      const filter = this.getFilter(val);
      if (filter.length === 0) {
        return val;
      } else {
        return val.substr(filter.length);
      }
    } else {
      return val;
    }
  }

  operatorFromString(val) {
    if (val !== null && val !== undefined && val !== '') {
      const filter = this.getFilter(val);
      if (filter.length === 0) {
        return this.operator;
      } else {
        return filter;
      }
    } else {
      return this.operator;
    }
  }

  public hasFilter(val): boolean {
    return this.getFilter(val).length > 0;
  }

  private getFilter(val): string {
    let n = 0;
    let found = false;
    while (n < this.replacableItems.length && !found) {
      found = val.startsWith(this.replacableItems[n]);
      if (!found) { n++; }
    }
    if (found) {
      return this.replacableItems[n];
    } else {
      return '';
    }

  }

  /*public isNumber(val) {
    this.number = val;
    if (this.number != null && this.number) {
      this.type = FilterTypes.NUMERO;
      this.defaultComparsion = '=';
    } else {
      this.type = FilterTypes.TEXT;
    }
  }*/

}
