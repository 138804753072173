var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'codi';
var defaultSortDirection = 'asc';
var LegislacionsFilterService = /** @class */ (function (_super) {
    __extends(LegislacionsFilterService, _super);
    function LegislacionsFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LegislacionsFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('codi', filter.codi)
            .append('descripcio', filter.descripcio)
            .append('obsoleta', Reflection.empty(filter.obsoleta) ? '' : filter.obsoleta.toString())
            .append('trafic', Reflection.empty(filter.trafic) ? '' : filter.trafic.toString())
            .append('mesesSLInici', Reflection.empty(filter.mesesSLInici) ? '' : filter.mesesSLInici.toString())
            .append('mesesSLFi', Reflection.empty(filter.mesesSLFi) ? '' : filter.mesesSLFi.toString())
            .append('mesesSGInici', Reflection.empty(filter.mesesSGInici) ? '' : filter.mesesSGInici.toString())
            .append('mesesSGFi', Reflection.empty(filter.mesesSGFi) ? '' : filter.mesesSGFi.toString())
            .append('mesesSMGInici', Reflection.empty(filter.mesesSMGInici) ? '' : filter.mesesSMGInici.toString())
            .append('mesesSMGFi', Reflection.empty(filter.mesesSMGFi) ? '' : filter.mesesSMGFi.toString())
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return LegislacionsFilterService;
}(CustomFilterService));
export { LegislacionsFilterService };
