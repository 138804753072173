import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { FestiusFilter } from './models/festius-filter';
import { FestiusTableComponent } from './components/festius-table/festius-table.component';
import { FestiusEditorComponent } from './components/festius-editor/festius-editor.component';
import { FestiusFilterComponent } from './components/festius-filter/festius-filter.component';
import { FestiusService } from './services/festius.service';
import { ConfirmationDialogComponent } from 'path-shared/components/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-festius',
  templateUrl: './festius.component.html',
  styleUrls: ['./festius.component.scss']
})
export class FestiusComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: FestiusFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(FestiusTableComponent) festiusTable: FestiusTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<FestiusComponent>,
    private festiusService: FestiusService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new FestiusFilter('dia', 'desc');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.Festius);
    this.festiusTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.festiusTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Festius'));
    }
  }

  edit() {
    const idSeleccionat: string = this.festiusTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

    const ref = this.dialog.open(FestiusEditorComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.festiusTable.load(); }
    });
  }

  refresh() {
    this.festiusTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(FestiusFilterComponent, {
      width: '70%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.festiusTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: FestiusFilter) => {
      this.festiusTable.filter.set(filter);
      this.festiusTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  // generateExcel() {
  //   this.colorsTable.generateExcel();
  // }


  publicar() {
    this.festiusService.publicar();

    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol publicar aquests dies festius?'))
    .then((confirmed) => {
        if (confirmed) {
          this.festiusService.publicar().subscribe(result => {
            this.toastr.success(this.i18n('Festius publicats correctament'));
          });
        }
      });
  }
}
