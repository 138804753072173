import { HttpParams } from '@angular/common/http';
import { TipoSenalesVerticalesFilter } from './../models/tipo-senales-verticales.filter';
import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';

const defaultSortParam = 'descripcion';
const defaultSortDirection = 'asc';

@Injectable()
export class TipoSenalesVerticalesFilterService extends CustomFilterService {

    public createFilterParams(
        filter: TipoSenalesVerticalesFilter,
        start: number,
        pageSize: number,
        sortField: string,
        sortDir: string) {
        return new HttpParams()
        .append('id', filter.id)
        .append('descripcion', filter.descripcion)
        .append('start', start.toString())
        .append('size', pageSize.toString())
        .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
        .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    }
}
