import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpSecureClient } from '../../../shared/services/http/httpSecureClient';
import { AuthGuardService } from '../../../shared/services/authentication/auth-guard.service';
import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { TiquetsClientFilter } from '../tiquets-client/tiquets-client-filter/tiquets-client-filter';
import { TiquetsClientFilterService } from './tiquets-client-filter.service';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class TiquetsClientService implements ExcelGenerator { 
  constructor(
    private http: HttpSecureClient,
    private authGuard: AuthGuardService
  ) {}

  generateExcel(filter: TiquetsClientFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new TiquetsClientFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFileResponse(config.urls['apiUrl'] + api.endpoints['urlGetTiquetsClientExcel'],  params );
    }
  }
}