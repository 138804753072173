/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-alegaciones.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../../../../../shared/components/form/form-text-area/form-text-area.component.ngfactory";
import * as i4 from "../../../../../../../../shared/components/form/form-text-area/form-text-area.component";
import * as i5 from "../../../../../../../../shared/services/validation/validation.service";
import * as i6 from "../../../../../../../../shared/components/helpers/view-list-icon/view-list-icon.component.ngfactory";
import * as i7 from "../../../../../../../../shared/components/helpers/view-list-icon/view-list-icon.component";
import * as i8 from "../../../../../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i9 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i10 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i11 from "../../../../../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i12 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i13 from "./form-alegaciones.component";
import * as i14 from "@ngx-translate/i18n-polyfill";
import * as i15 from "ngx-toastr";
import * as i16 from "../../../../../../../../shared/services/form/form.service";
import * as i17 from "@angular/material/dialog";
var styles_FormAlegacionesComponent = [i0.styles];
var RenderType_FormAlegacionesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormAlegacionesComponent, data: {} });
export { RenderType_FormAlegacionesComponent as RenderType_FormAlegacionesComponent };
export function View_FormAlegacionesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mainForm: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["mainForm", 1]], null, 15, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(3, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "border border-secondary fondo"], ["style", "padding:10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-form-text-area", [["id", "form-alegaciones-alegacion"], ["label", "Al\u00B7legaci\u00F3"], ["required", ""]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.dto.alegacion = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FormTextAreaComponent_0, i3.RenderType_FormTextAreaComponent)), i1.ɵdid(10, 4308992, null, 0, i4.FormTextAreaComponent, [i5.ValidationService], { label: [0, "label"], value: [1, "value"], required: [2, "required"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-view-list-icon", [], null, [[null, "listClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("listClick" === en)) {
        var pd_0 = (_co.buscarAlegacions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ViewListIconComponent_0, i6.RenderType_ViewListIconComponent)), i1.ɵdid(12, 114688, null, 0, i7.ViewListIconComponent, [], { hideView: [0, "hideView"] }, { listClick: "listClick" }), (_l()(), i1.ɵeld(13, 16777216, null, null, 3, "app-form-date-edit", [["class", "col-2"], ["label", "Data"], ["required", ""]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.dto.fechaAlegacion = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormDateEditComponent_0, i8.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i9.DateTimeAdapter, i10.MomentDateTimeAdapter, [i9.OWL_DATE_TIME_LOCALE]), i1.ɵdid(15, 114688, null, 0, i11.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"], required: [3, "required"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i12.OWL_DATE_TIME_FORMATS, i11.MY_CUSTOM_FORMATS, [])], function (_ck, _v) { var _co = _v.component; var currVal_7 = "Al\u00B7legaci\u00F3"; var currVal_8 = _co.dto.alegacion; var currVal_9 = ""; _ck(_v, 10, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = true; _ck(_v, 12, 0, currVal_10); var currVal_11 = "Data"; var currVal_12 = _co.dto.fechaAlegacion; var currVal_13 = "col-2"; var currVal_14 = ""; _ck(_v, 15, 0, currVal_11, currVal_12, currVal_13, currVal_14); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_FormAlegacionesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-alegaciones", [], null, null, null, View_FormAlegacionesComponent_0, RenderType_FormAlegacionesComponent)), i1.ɵdid(1, 114688, null, 0, i13.FormAlegacionesComponent, [i14.I18n, i15.ToastrService, i16.FormService, i17.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormAlegacionesComponentNgFactory = i1.ɵccf("app-form-alegaciones", i13.FormAlegacionesComponent, View_FormAlegacionesComponent_Host_0, {}, {}, []);
export { FormAlegacionesComponentNgFactory as FormAlegacionesComponentNgFactory };
