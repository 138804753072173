<div class="form-group">
  <mat-form-field>
    <ng-container *ngIf="this.type == 'number'">
      <input class="" #inputEdit id={{id}} matInput type="number" [placeholder]="this.label" [(ngModel)]="this.value" [disabled]="this.disabled" [required]="this.isRequired()" 
      [readonly]="this.isReadOnly" [ngClass]="[this.validVal ? 'ng-valid' : 'ng-invalid']" [tabIndex]="this.tabindex" >
    </ng-container>

    <ng-container *ngIf="this.type != 'number' && this.type != 'currency'">
      <input #inputEdit id={{id}} matInput [type]="this.type" [placeholder]="this.label" [(ngModel)]="this.value" [disabled]="this.disabled" [required]="this.isRequired()" 
      [readonly]="this.isReadOnly"  [ngClass]="[this.validVal ? 'ng-valid' : 'ng-invalid']"  [title]="this.tooltip===undefined ? '' : this.tooltip" [tabIndex]="this.tabindex" autocomplete="new-password">
    </ng-container>

  </mat-form-field>
  <div [hidden]="this.validVal" class="text-danger alert-field-errors">
      <div [hidden]="!this.errorRequired()" i18n>Aquest camp és obligatori!</div>
      <div [hidden]="!this.errorNumber()" i18n>Aquest camp ha de ser un número vàlid!</div>
      <div [hidden]="!this.errorNumberMaxValue()" i18n>Aquest camp ha de ser menor o igual a {{this.maxNumberValue}}!</div>
      <div [hidden]="!this.errorLength()"><span i18n>La longitud màxima és </span>{{ this.maxLength}}!</div>
      <div [hidden]="!this.errorMatricula()"><span i18n>La matrícula només pot contenir números i lletres sense espais i símbols!</span></div>
  </div>
</div>

