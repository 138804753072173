/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-live-state.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-live-state.component";
var styles_FormLiveStateComponent = [i0.styles];
var RenderType_FormLiveStateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormLiveStateComponent, data: {} });
export { RenderType_FormLiveStateComponent as RenderType_FormLiveStateComponent };
function View_FormLiveStateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-spin loading"]], null, null, null, null, null))], null, null); }
function View_FormLiveStateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-check ok"]], null, null, null, null, null))], null, null); }
function View_FormLiveStateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-times error"]], null, null, null, null, null))], null, null); }
export function View_FormLiveStateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormLiveStateComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormLiveStateComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormLiveStateComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.state === _co.Types.LOADING); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.state === _co.Types.OK); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.state === _co.Types.ERROR); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_FormLiveStateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-live-state", [], null, null, null, View_FormLiveStateComponent_0, RenderType_FormLiveStateComponent)), i1.ɵdid(1, 114688, null, 0, i3.FormLiveStateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormLiveStateComponentNgFactory = i1.ɵccf("app-form-live-state", i3.FormLiveStateComponent, View_FormLiveStateComponent_Host_0, { state: "state" }, {}, []);
export { FormLiveStateComponentNgFactory as FormLiveStateComponentNgFactory };
