import { BoeService } from './../../../services/boe.service';
import { ConfirmacionBOEFilter } from './../../../models/confirmacion-boe-filter.model';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ConfirmacionBoeFilterService } from '../../../services/confirmacion-boe-filter.service';
import { ConfirmacionBoeDto } from '../../../models/confirmacion-boe.model';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-confirmacion-listado',
  templateUrl: './confirmacion-listado.component.html',
  styleUrls: ['./confirmacion-listado.component.scss']
})
export class ConfirmacionListadoComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    filter: ConfirmacionBOEFilter = new ConfirmacionBOEFilter('lote', 'desc');
    dataSource: CustomDataSource<ConfirmacionBoeDto, ConfirmacionBOEFilter>;
    highlightedRows = [];
    displayedItems = [];
    @Input() updatedFilterData: ConfirmacionBOEFilter;
    @Output() notify: EventEmitter<any> = new EventEmitter<any>();
    displayedColumns = ['lote', 'fechaCreacion', 'fechaEdicto', 'tipo'];
    constructor(
      private boeService: BoeService,
      private filterService: FilterService,
      private dialog: MatDialog,
      private i18n: I18n,
    ) { }

  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<ConfirmacionBoeDto, ConfirmacionBOEFilter>(this.paginator, new ConfirmacionBoeFilterService(), 'urlGetConfirmacionBoeFilter', 'urlGetConfirmacionBoeFilterCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.secureId] = {
          id: item.secureId
        };
        // item.IMATGES = this.loadImatges(item);
      });
    });
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
      }
    });

    this.filter.paginator.init(this.sort, this.paginator);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }
  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }
  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }
  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    if (row !== undefined && row !== null) {
        this.notify.emit(row);
    }
  }
}
