/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i2 from "../../../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i3 from "../../../../../../shared/services/validation/validation.service";
import * as i4 from "../../../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i5 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i6 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i7 from "../../../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i8 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i9 from "./retirada.component";
var styles_RetiradaComponent = [];
var RenderType_RetiradaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RetiradaComponent, data: {} });
export { RenderType_RetiradaComponent as RenderType_RetiradaComponent };
export function View_RetiradaComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-4"], ["id", "retirada-num"], ["label", "N\u00BA retirada"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.data.numRetirada = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FormTextEditComponent_0, i1.RenderType_FormTextEditComponent)), i0.ɵdid(2, 4308992, null, 0, i2.FormTextEditComponent, [i3.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-4"], ["id", "retirada-import"], ["label", "Import retirada"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.data.retiradaImporte = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FormTextEditComponent_0, i1.RenderType_FormTextEditComponent)), i0.ɵdid(4, 4308992, null, 0, i2.FormTextEditComponent, [i3.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i0.ɵeld(5, 16777216, null, null, 3, "app-form-date-edit", [["class", "col-xl-4"], ["id", "retirada-data"], ["label", "Data retirada"], ["type", "calendar"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.data.fechaRetirada = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FormDateEditComponent_0, i4.RenderType_FormDateEditComponent)), i0.ɵprd(4608, null, i5.DateTimeAdapter, i6.MomentDateTimeAdapter, [i5.OWL_DATE_TIME_LOCALE]), i0.ɵdid(7, 114688, null, 0, i7.FormDateEditComponent, [i0.ViewContainerRef, i0.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"], type: [3, "type"], id: [4, "id"], isReadOnly: [5, "isReadOnly"] }, { valueChange: "valueChange" }), i0.ɵprd(256, null, i8.OWL_DATE_TIME_FORMATS, i7.MY_CUSTOM_FORMATS, []), (_l()(), i0.ɵeld(9, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-4"], ["id", "retirada-impDip"], ["label", "Import dip\u00F2sit"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.data.retiradaImporteDeposito = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FormTextEditComponent_0, i1.RenderType_FormTextEditComponent)), i0.ɵdid(11, 4308992, null, 0, i2.FormTextEditComponent, [i3.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i0.ɵeld(12, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-4"], ["id", "retirada-impCob"], ["label", "Import cobrat"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.data.retiradaImporteCobrado = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FormTextEditComponent_0, i1.RenderType_FormTextEditComponent)), i0.ɵdid(13, 4308992, null, 0, i2.FormTextEditComponent, [i3.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i0.ɵeld(14, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-4"], ["id", "retirada-liquidacio"], ["label", "Liquidaci\u00F3"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.data.retiradaLiquidacion = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FormTextEditComponent_0, i1.RenderType_FormTextEditComponent)), i0.ɵdid(15, 4308992, null, 0, i2.FormTextEditComponent, [i3.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"], id: [4, "id"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "N\u00BA retirada"; var currVal_1 = _co.data.numRetirada; var currVal_2 = "col-xl-4"; var currVal_3 = _co.isReadOnly; var currVal_4 = "retirada-num"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "Import retirada"; var currVal_6 = _co.data.retiradaImporte; var currVal_7 = "col-xl-4"; var currVal_8 = _co.isReadOnly; var currVal_9 = "retirada-import"; _ck(_v, 4, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = "Data retirada"; var currVal_11 = _co.data.fechaRetirada; var currVal_12 = "col-xl-4"; var currVal_13 = "calendar"; var currVal_14 = "retirada-data"; var currVal_15 = _co.isReadOnly; _ck(_v, 7, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_16 = "Import dip\u00F2sit"; var currVal_17 = _co.data.retiradaImporteDeposito; var currVal_18 = "col-xl-4"; var currVal_19 = _co.isReadOnly; var currVal_20 = "retirada-impDip"; _ck(_v, 11, 0, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20); var currVal_21 = "Import cobrat"; var currVal_22 = _co.data.retiradaImporteCobrado; var currVal_23 = "col-xl-4"; var currVal_24 = _co.isReadOnly; var currVal_25 = "retirada-impCob"; _ck(_v, 13, 0, currVal_21, currVal_22, currVal_23, currVal_24, currVal_25); var currVal_26 = "Liquidaci\u00F3"; var currVal_27 = _co.data.retiradaLiquidacion; var currVal_28 = "col-xl-4"; var currVal_29 = _co.isReadOnly; var currVal_30 = "retirada-liquidacio"; _ck(_v, 15, 0, currVal_26, currVal_27, currVal_28, currVal_29, currVal_30); }, null); }
export function View_RetiradaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-retirada", [], null, null, null, View_RetiradaComponent_0, RenderType_RetiradaComponent)), i0.ɵdid(1, 114688, null, 0, i9.RetiradaComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RetiradaComponentNgFactory = i0.ɵccf("app-retirada", i9.RetiradaComponent, View_RetiradaComponent_Host_0, { data: "data", isReadOnly: "isReadOnly" }, {}, []);
export { RetiradaComponentNgFactory as RetiradaComponentNgFactory };
