var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'tipus';
var defaultSortDirection = 'desc';
var MatriculaFilterService = /** @class */ (function (_super) {
    __extends(MatriculaFilterService, _super);
    function MatriculaFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MatriculaFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('tipus', Reflection.hasValue(filter.tipus) ? filter.tipus : '')
            .append('observacions', filter.observacions ? filter.observacions : '')
            .append('model', filter.model ? filter.model : '')
            .append('matricula', filter.matricula ? filter.matricula : '')
            .append('marca', filter.marca ? filter.marca : '')
            .append('dataInici', filter.dataInici === null ? '' : typeof (filter.dataInici) === 'string' ?
            filter.dataInici : filter.dataInici.toDateString())
            .append('dataFi', filter.dataFi === null ? '' : typeof (filter.dataFi) === 'string' ?
            filter.dataFi : filter.dataFi.toDateString())
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    MatriculaFilterService.prototype.createLoadParams = function (content) {
        return new HttpParams()
            .append('content', content ? content : '');
    };
    return MatriculaFilterService;
}(CustomFilterService));
export { MatriculaFilterService };
