<app-toolbar>  
    <app-tool-button text="Actualizar" i18n-text (click)="refresh();" color="has-color-blue" icon="fa-sync"></app-tool-button>
    <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
    <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel"></app-tool-button>
    <app-tool-button-permissions [permission]="this.authService.Pages.Tiquets"></app-tool-button-permissions>
</app-toolbar>

<div class="float-right filtreProv"> 
    <app-form-multi-select #comboProvs style="width:20%" [data]="proveidors" (selectedValuesChange)="proveedoresChanged()" [(selectedValues)]="proveidorsSelected" i18n-titulo tituloMultiSelect="Proveidors" i18n-tituloMultiSelect></app-form-multi-select>
</div>
<div class="float-right resum">
    <!-- tab amb la llegenda corresponent -->
    <div *ngIf="selectedTab('tiquets')" class="card w-auto mt-2 float-right">
        <div class="card-body">
            <h5 class="card-header1" i18n>Resum Tiquets
                <i [ngClass]="showResum? 'fas fa-angle-up' : 'fas fa-angle-down'" style="float: right;" (click)="showResum=!showResum" style="float:right; padding: 0 1vh"></i>
            </h5>     
            <div *ngIf="showResum">
                <div class="row px-1 d-block" >
                    <span i18n class="mr-5">Tiquets: </span>
                    <span class="float-right">{{ this.tiquetLeyenda.numTiquets }}</span>
                </div>
                <div class="row px-1 d-block">
                    <span i18n class="mr-5">Import tiquets: </span>
                    <span class="float-right">{{ this.tiquetLeyenda.sumImportTiquets | currency:'EUR': 'symbol' }}</span>
                </div>
                <div class="row px-1 d-block ml-3">
                    <span i18n class="mr-5">Import bonus: </span>
                    <span class="float-right">{{ this.tiquetLeyenda.sumImportBonus | currency:'EUR': 'symbol' }}</span>
                </div>
                <div class="row px-1 d-block ml-3">
                    <span i18n class="mr-5">Import metàl·lic: </span>
                    <span class="float-right">{{ this.tiquetLeyenda.sumImportMetalic | currency:'EUR': 'symbol' }}</span>
                </div>
                <div class="row px-1 d-block">
                    <span i18n class="mr-5">Import devolucions: </span>
                    <span class="float-right">{{ this.tiquetLeyenda.sumImportDevolucions | currency:'EUR': 'symbol' }}</span>
                </div>
                <div class="row px-1 d-block">
                    <span i18n class="mr-5">Import anul·lacions: </span>
                    <span class="float-right">{{ this.tiquetLeyenda.sumImportAnulacions  | currency:'EUR': 'symbol' }}</span>
                </div>
                <div class="row px-1 d-block">
                    <span class="mr-5 float-left font-weight-bold" i18n>Recaptació:</span>
                    <span class="float-right">{{ this.tiquetLeyenda.recaptacio | currency:'EUR': 'symbol' }}</span>
                </div>
            </div>                
        </div>
    </div>
   

    <div *ngIf="selectedTab('devolucions')" class="card w-auto mt-2 float-right">
        <div class="card-body">
            <h5 class="card-header1" i18n>Resum Devolucions
                <i [ngClass]="showResum? 'fas fa-angle-up' : 'fas fa-angle-down'" style="float: right;" (click)="showResum=!showResum" style="float:right; padding: 0 1vh"></i>
            </h5>
            <div *ngIf="showResum">
                <div class="row px-1 d-block" >
                    <span i18n class="mr-5">Devolucions: </span>
                    <span class="float-right">{{ this.tiquetLeyendaDevolucion.numDevolucions }}</span>
                </div>
                <div class="row px-1 d-block">
                    <span i18n class="mr-5">Import tiquets: </span>
                    <span class="float-right">{{ this.tiquetLeyendaDevolucion.sumImportTiquets | currency:'EUR': 'symbol' }}</span>
                </div>
                <div class="row px-1 d-block">
                    <span i18n class="mr-5">Import devolucions: </span>
                    <span class="float-right">{{ this.tiquetLeyendaDevolucion.sumImportDevolucions | currency:'EUR': 'symbol' }}</span>
                </div>
                <div class="row px-1 d-block ml-3">
                    <span i18n class="mr-5">Import bonus: </span>
                    <span class="float-right">{{ this.tiquetLeyendaDevolucion.sumImportBonus | currency:'EUR': 'symbol' }}</span>
                </div>
                <div class="row px-1 d-block ml-3">
                    <span i18n class="mr-5">Import metàl·lic: </span>
                    <span class="float-right">{{ this.tiquetLeyendaDevolucion.sumImportMetalic | currency:'EUR': 'symbol' }}</span>
                </div>
                <div class="row px-1 d-block">
                    <span class="mr-5 float-left font-weight-bold" i18n>Recaptació:</span>
                    <span class="float-right">{{ this.tiquetLeyendaDevolucion.recaptacio | currency:'EUR': 'symbol' }}</span>
                </div>
            </div>                
        </div>
    </div>

    <div *ngIf="selectedTab('anulacions')" class="card w-auto mt-2 float-right">
        <div class="card-body">
            <h5 class="card-header1" i18n>Resum Anul·lacions
                <i [ngClass]="showResum? 'fas fa-angle-up' : 'fas fa-angle-down'" style="float: right;" (click)="showResum=!showResum" style="float:right; padding: 0 1vh"></i>
            </h5>
            <div *ngIf="showResum">
                <div class="row px-1 d-block" >
                    <span i18n class="mr-5">Tiquets: </span>
                    <span class="float-right">{{ this.tiquetLeyendaAnulacion.numAnulacions }}</span>
                </div>
                <div class="row px-1 d-block">
                    <span i18n class="mr-5">Import anul·lacions: </span>
                    <span class="float-right">{{ this.tiquetLeyendaAnulacion.sumImportAnulacions  | currency:'EUR': 'symbol' }}</span>
                </div>
                <div class="row px-1 d-block">
                    <span class="mr-5 float-left font-weight-bold" i18n>Recaptació:</span>
                    <span class="float-right">{{ this.tiquetLeyendaAnulacion.recaptacio | currency:'EUR': 'symbol' }}</span>
                </div>
            </div>                
        </div>
    </div>
</div>
<div class="row"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>

<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
    <mat-tab>
        <ng-template mat-tab-label> 
            <span matBadge="{{ this.currentTiquets }}" matBadgeOverlap="false" i18n>Tiquets</span>
        </ng-template>
        <app-tiquets-tiquets-table #tiquetsTiquets [sharedFilter]="this.filter" (currentCount)="this.setBadgeCount($event, 1)"></app-tiquets-tiquets-table>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label> 
            <span matBadge="{{ this.currentDevolucions }}" matBadgeOverlap="false" i18n>Devolucions</span>
        </ng-template>
        <app-tiquets-devoluciones-table #tiquetsDevolucions [sharedFilter]="this.filter" (currentCount)="this.setBadgeCount($event, 2)"></app-tiquets-devoluciones-table>
    </mat-tab>
    <mat-tab i18n-label label="Anul·lacions">
        <ng-template mat-tab-label> 
            <span matBadge="{{ this.currentAnulacions }}" matBadgeOverlap="false" i18n>Anul·lacions</span>
        </ng-template>
        <app-tiquets-anulaciones-table #tiquetsAnulacions [sharedFilter]="this.filter" (currentCount)="this.setBadgeCount($event, 3)"></app-tiquets-anulaciones-table>
    </mat-tab>
</mat-tab-group>