import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { AgentFilter } from '../models/agent-filter';
import { HttpParams } from '@angular/common/http';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class AgentsFilterService extends CustomFilterService {
  public createFilterParams(filter: AgentFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    return new HttpParams()
    .append('codi', Reflection.hasValue(filter.codi) ? filter.codi.toString() : '')
    .append('categoria', filter.categoria ? filter.categoria : '')
    .append('nomComplert', filter.nomComplert ? filter.nomComplert : '')
    .append('dni', filter.dni ? filter.dni : '')
    .append('letraDni', filter.letraDni ? filter.letraDni : '')
    .append('obsolet', this.getTristateValue(filter.obsolet))
    .append('zonaBlava', this.getTristateValue(filter.zonaBlava))
    .append('agentPDA', this.getTristateValue(filter.agentPDA))
    .append('FechaActualConsulta', this.formatDateToLocal(filter.fechaActualConsulta))
    .append('AgenteDeFiesta', this.getTristateValue(filter.deFiesta))
    .append('AgenteDeAlta', this.getTristateValue(filter.deAlta))
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }

  getTristateValue(value) {
    if (value === null) {
      return '';
    } else {
      return value.toString();
    }
  }

  formatDateToLocal(fecha: Date): string {
    fecha = new Date(fecha);
    const dateString =
        fecha.getFullYear() + '-' +
        ('0' + (fecha.getMonth() + 1)).slice(-2) + '-' +
        ('0' + fecha.getDate()).slice(-2);
    return dateString;
}
}
