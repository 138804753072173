<app-filter-header title="Filtrar paisos" i18n-title (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row col">
    <app-form-text-edit id="paisos-filter-codi" class="col-sm-3" i18n-label label="Codi" [(value)]="this.filter.codi" number></app-form-text-edit>
    <app-filter-text-edit id="paisos-filter-descripcio" class="col-sm-6" i18n-label label="Descripció" [(value)]="this.filter.descripcio" contains></app-filter-text-edit>
    <app-filter-text-edit id="paisos-filter-sigles" class="col-sm-3" i18n-label label="Sigles" [(value)]="this.filter.sigles" contains></app-filter-text-edit>
  </div>
  <div class="row">
    <div class="col-6">
      <app-form-tri-state class="col-sm-3" label="Provincia codificada" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="this.filter.provCodificada"></app-form-tri-state>
      <app-form-tri-state class="col-sm-3" label="Pais actiu" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="this.filter.paisActiu"></app-form-tri-state>
    </div>
  </div>
</div>
