import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { MaquinasService } from '../../services/maquinas.service';
import { MaquinaIncidencia } from '../../models/maquinaIncidencia';

@Component({
  selector: 'app-actuacions-new',
  templateUrl: './actuacions-new.component.html',
  styleUrls: ['./actuacions-new.component.scss']
})
export class ActuacionsNewComponent implements OnInit {

  dataInici: Date;
  dataFi: Date;
  observacions: string = "";

  constructor(
    public i18n: I18n,
    private maquinesService: MaquinasService,
    public thisDialogRef: MatDialogRef<ActuacionsNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MaquinaIncidencia,
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) { 
  }

  ngOnInit() {
  }

  public decline() {
    this.thisDialogRef.close(false);
  }

  public accept() {
    this.thisDialogRef.close(true);
  }

  public dismiss() {
    this.thisDialogRef.close(false);
  }

  guardar() {
    if (this.dataInici != null && this.dataFi != null) {
      let obj = {
        dataInici: this.dataInici,
        dataFi: this.dataFi,
        observacions: this.observacions,
        idIncidencia: this.data.somId
      }
      this.maquinesService.newActuacioIncidencia(obj).subscribe(
        {
          next : () => this.accept()
        }
      );
    }
    else {
      this.toastr.warning(this.i18n('Falten camps per omplir'));
    }
  }

}
