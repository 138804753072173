import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AcuseFilter } from '../../models/acus-de-rebut.filter';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-acusos-filter',
  templateUrl: './acusos-filter.component.html',
  styleUrls: ['./acusos-filter.component.scss']
})
export class AcusosFilterComponent implements OnInit {
  subscription: Subscription;
  filter: AcuseFilter;
  public tipo : string;
  public tipus = ["NOTIFICACION TITULAR", "NOTIFICACION INFRACTOR", "NOTIFICACION ALEGACIONES", "NOTIFICACION RESOLUCIÓN"];;

  @Output() notify: EventEmitter<AcuseFilter> = new EventEmitter<AcuseFilter>();
  constructor(
    public thisDialogRef: MatDialogRef<AcuseFilter>,
    @Inject(MAT_DIALOG_DATA) public data) { 
    this.filter = data.filter;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

  onTipusSelectionChanged(selectedTipus : string) {
    this.filter.tipus = selectedTipus
  }

}
