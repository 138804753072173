import { Component, OnInit, AfterViewInit, OnChanges, ViewChild, Input, Output, EventEmitter, SimpleChanges, AfterViewChecked } from '@angular/core';
import { MatPaginator, MatSort, MatPaginatorModule, MatTableDataSource, MatDialog } from '@angular/material';
import { TipusMatricula } from 'app/sections/ciutat/tipus-matricules/models/tipus-matricula.model';
import { TipusMatriculaFilter } from 'app/sections/ciutat/tipus-matricules/models/tipus-matricula-filter';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { ActivatedRoute, Router } from '@angular/router';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TipusMatriculesService } from 'app/sections/ciutat/tipus-matricules/services/tipus-matricules.service';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { TipusMatriculaFilterService } from 'app/sections/ciutat/tipus-matricules/services/tipus-matricules-filter.service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { ExcelDownloadService } from 'path-shared/services/excel/excel-download.service';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { Builder } from 'path-shared/models/builder';

@Component({
  selector: 'app-tipus-matricules-table',
  templateUrl: './tipus-matricules-table.component.html',
  styleUrls: ['./tipus-matricules-table.component.scss']
})
export class TipusMatriculesTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  data: TipusMatricula[];
  dataSource: CustomDataSource<TipusMatricula, TipusMatriculaFilter>;
  highlightedRows = [];
  filter: TipusMatriculaFilter = new TipusMatriculaFilter('descripcio', 'asc');
  filterPage: TipusMatriculaFilter;
  displayedItems = [];

  @Input() selectButton: boolean;
  @Input() updatedFilterData: TipusMatriculaFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['codi', 'descripcio', 'denunciaZB', 'denunciaPOL', 'generarAlarma', 'email'];// 'guardaLOG', 'reincidents',

  constructor(
    private tipusMatriculesService: TipusMatriculesService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n
  ) {
  }

  ngOnInit() {
    if (this.selectButton) {
      this.displayedColumns = ['incorpora'].concat(this.displayedColumns);
    }
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<TipusMatricula, TipusMatriculaFilter>(this.paginator, new TipusMatriculaFilterService(), 'urlGetTipusMatricules', 'urlTipusMatriculesPaginationCount');
    this.dataSource.subject.subscribe((data) => {


      data.forEach(item => {
        this.tipusMatriculesService.displayedItems[item.SECURE_ID] = {
          SECURE_ID: item.SECURE_ID,
          checked: false
        };
      });

      this.displayedItems = this.tipusMatriculesService.displayedItems;

    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('tipus-matricules-filter', this.filter); }
      }
    });
    // reset the paginator after sorting
    if (!this.selectButton) { this.filterService.loadFilter('tipus-matricules-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    } );
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  onRowClicked(row) {
    this.itemClicked.next(row.SECURE_ID);
  }

  selectCurrent(codi) {
    this.itemSelected.next(codi);
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: Builder.newDownloadExcelDialogData (this.tipusMatriculesService, filter, this.i18n('TipusMatrícules'), this.sort.direction, this.sort.active)
    });
  }

  emailActive(row) {
    return !Reflection.empty(row.emails);
  }
}
