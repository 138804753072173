import { OnInit, EventEmitter, ViewContainerRef } from '@angular/core';
export var MY_CUSTOM_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm:ss',
    fullPickerInput: 'DD/MM/YYYY HH:mm:ss',
    datePickerInput: 'dd/MM/yyyy',
    timePickerInput: 'HH:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};
var DateRangeInputComponent = /** @class */ (function () {
    function DateRangeInputComponent(cr) {
        this.cr = cr;
        this.validVal = false;
        this.valueChange = new EventEmitter();
        this.initialValue = undefined;
        this.isReadOnly = false;
        this.val = new Array();
        this.type = 'calendar';
        this.disabled = false;
        this.required = false;
    }
    Object.defineProperty(DateRangeInputComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            var _this = this;
            this.val = val;
            this.errorRequired();
            setTimeout(function () {
                _this.valueChange.emit(_this.val);
            }, 10);
        },
        enumerable: true,
        configurable: true
    });
    DateRangeInputComponent.prototype.ngOnInit = function () {
        if (this.initialValue !== undefined) {
            this.value = this.initialValue;
        }
    };
    DateRangeInputComponent.prototype.errorRequired = function () {
        if ((this.required || this.required === '') && ((this.value === null || this.value === undefined) || (this.value[0] === undefined || this.value[1] === undefined))) {
            this.validVal = false;
        }
        else {
            this.validVal = true;
        }
    };
    return DateRangeInputComponent;
}());
export { DateRangeInputComponent };
