import { PropostaEstats } from 'app/sections/propostes/models/proposta-estats.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import {Component, EventEmitter, OnInit, Output, Inject} from '@angular/core';
import {CustomListFill} from 'path-shared/models/customlistfill';
import {PropostaFilter} from '../../../models/proposta.filter';
import { FilterHeaderDelegate } from 'path-shared/components/dialog/filter-header/filter-header-delegate';
import { StartupService } from 'path-shared/services/startup/startup.service';
import { CarrersService } from 'app/sections/configuracio/carrers/services/carrers-service.service';
import { AparellService } from 'app/sections/configuracio/aparells/services/aparells-service.service';

@Component({
  selector: 'app-propostes-filter',
  templateUrl: './propostes-filter.component.html',
  styleUrls: ['./propostes-filter.component.scss']
})
export class PropostesFilterComponent implements OnInit, FilterHeaderDelegate {
  filter: PropostaFilter;
  estatSeleccionat: string;

  estados = [
    {text: '', value: null},
    {text: this.i18n('Pre-Propuesta'), value: 4},
    {text: this.i18n('Pendents'), value: 1},
    {text: this.i18n('Acceptades'), value: 0},
    {text: this.i18n('Desestimades'), value: 2}
  ];
  articuloTextListHelper: CustomListFill;
  origenTextListHelper: string[] = ['SMARTPHONE', 'RADAR', 'CAMARA', 'MULTACAR'];
  simpleBooleanHelperCerrado: string[] = ['SI', 'NO', ''];
  paisos = [];
  carrers = [];
  aparells = [];

  @Output() notify: EventEmitter<PropostaFilter> = new EventEmitter<PropostaFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<PropostesFilterComponent>,
    private i18n: I18n,
    private carrersService: CarrersService,
    @Inject(MAT_DIALOG_DATA) public data: PropostaFilter,
    private startupService: StartupService,
    private aparellsService: AparellService,
  ) {
    this.filter = data;
    this.paisos.push({text: '', value: ''});
        this.startupService.GetPaisos().forEach(pais => {
      if (pais.PAIACTIVO) {
        this.paisos.push({text: pais.PAINOMPAI, value: pais.SIGLA});
      }
    });

  }

  ngOnInit() {
    // Carreguem els carrers
    this.loadCalles();

    this.aparellsService.getAllCombo().subscribe(result => {
      if (result !== null) {
        let i: number;
        i = 0;
        result.forEach(element => {
          this.aparells[i] = element;
          i++;
        });
      }
      this.aparells.unshift({'': ''});
    });

  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated(false);
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

  setEstatFilter(estat) {
    if (estat !== undefined) {
      if (estat === '') {
       this.filter.proposta = null;
      } else if (estat === this.estados[1]) {
        this.filter.proposta = PropostaEstats.PENDENT.toString();
      } else if (estat === this.estados[2]) {
      this.filter.proposta = PropostaEstats.ACCEPTADA.toString();
      } else if (estat === this.estados[3]) {
      this.filter.proposta = PropostaEstats.DESESTIMADA.toString();
      }
    }
  }

  public printFilter() {
    return JSON.stringify(this.filter);
  }

  loadCalles() {
    let json: string;
    let carrers: any[];

    if (sessionStorage.getItem('carrersForCombo')) {
      json = sessionStorage.getItem('carrersForCombo');
      carrers = JSON.parse(json.toString());
      this.setCalles(carrers);
    } else {
      this.carrersService.getAllForCombo().subscribe(carrersForCombo => {
        json = JSON.stringify(carrersForCombo);
        sessionStorage.setItem('carrersForCombo', json);
        this.setCalles(carrersForCombo);
      });
    }
  }

  setCalles(carrers: any[]) {
    if (carrers !== null) {
      let i: number;
      i = 0;
      carrers.forEach(element => {
        this.carrers[i] = element;
        i++;
      });
    }
    this.carrers.unshift({'nomCarrer': ''});
  }

}
