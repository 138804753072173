var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var SenseRetiradaGruaFilter = /** @class */ (function (_super) {
    __extends(SenseRetiradaGruaFilter, _super);
    function SenseRetiradaGruaFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.matricula = '';
        _this.marca = '';
        _this.modelo = '';
        _this.idNoRetirada = '';
        _this.dataInici = null;
        _this.dataFi = null;
        _this.labels['matricula'] = _this.i18n('Matrícula');
        _this.labels['marca'] = _this.i18n('Marca');
        _this.labels['modelo'] = _this.i18n('Model');
        _this.labels['idNoRetirada'] = _this.i18n('Motiu no retirada');
        _this.labels['dataInici'] = _this.i18n('Data inici');
        _this.labels['dataFi'] = _this.i18n('Data fi');
        return _this;
    }
    return SenseRetiradaGruaFilter;
}(FilterData));
export { SenseRetiradaGruaFilter };
