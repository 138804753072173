import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';
import { Instituciones } from 'path-shared/models/instituciones';


export class EjecutivaFilterImpressio extends FilterData {
  fechaInicio: Date;
  fechaFin: Date;

  constructor(DataInici: Date, DataFi: Date, sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);

    if (DataInici !== null) {
      this.fechaInicio = DataInici;
      this.fechaFin = DataFi;
      
    } else {
      const date = new Date();
      const dateCurrentDay = new Date(date.getTime());

      const dateYesterday = new Date(date.getTime());
      dateYesterday.setDate(date.getDate() - 1);

      this.fechaInicio = dateYesterday;
      this.fechaFin = dateCurrentDay;
    }
  }
  }
  