<app-filter-header title="Filtrar vehicles dipòsit" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <!-- <app-filter-text-edit class="col-sm-3" i18n-label label="Codi" [(value)]="filter.codi" contains></app-filter-text-edit>
    <app-filter-text-edit class="col-sm-3" i18n-label label="Vehicle" [(value)]="filter.idVehiclesFK" contains></app-filter-text-edit>
    <app-filter-text-edit class="col-sm-3" i18n-label label="Carrer" [(value)]="filter.nombreCalle" contains></app-filter-text-edit>
    <app-filter-text-edit class="col-sm-3" i18n-label label="Núm carrer" [(value)]="filter.numCarrer" contains></app-filter-text-edit> -->
    <div class="row">
      <app-form-date-edit id="diposit-filter-dataIni" class="col-sm-3" i18n-label label="Data inici entrada" [(value)]="filter.dataEntradaInici" type="calendar"></app-form-date-edit>
      <app-form-date-edit id="diposit-filter-dataFi"  class="col-sm-3" i18n-label label="Data final entrada" [(value)]="filter.dataEntradaFinal" type="calendar"></app-form-date-edit>
      <app-form-date-edit id="diposit-filter-dataIniSort" class="col-sm-3" i18n-label label="Data inici sortida" [(value)]="filter.dataSortidaInici" type="calendar"></app-form-date-edit>
      <app-form-date-edit id="diposit-filter-dataFiSort" class="col-sm-3" i18n-label label="Data final sortida" [(value)]="filter.dataSortidaFinal" type="calendar"></app-form-date-edit>
      <!--<app-filter-text-edit class="col-sm-3" i18n-label label="Núm fotos PDA" [(value)]="filter.numFotos" maxLenght="50" number="true"></app-filter-text-edit>
      <app-filter-text-edit class="col-sm-3" i18n-label label="Núm PDA" [(value)]="filter.numPDA" maxLenght="50" number="true"></app-filter-text-edit>-->
      <!--<app-combo-box-input-autocomplete #comboAgent class="center-horizontal col-sm-3" i18n-label label="Agent" [options]="this.agentsDictionary"
        (valorSeleccionado)="this.agentSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteAgent"  required="false" >
      </app-combo-box-input-autocomplete>-->

      <app-combo-box-input-autocomplete #comboTecnic class="center-horizontal col-sm-3" i18n-label label="Tecnic" [options]="this.tecnicsDictionary"
        (valorSeleccionado)="this.tecnicSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteTecnic"  required="false" >
      </app-combo-box-input-autocomplete>

      <app-combo-box-input-autocomplete #comboTipusVehicles class="center-horizontal col-sm-3" i18n-label label="Tipus vehicle" [options]="this.tipusVehiclesDictionary"
        (valorSeleccionado)="this.tipusVehiclSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteTipusVehicle"  required="false" >
      </app-combo-box-input-autocomplete>

      <app-form-multi-select style="width: 45%;" titulo="Dipòsits" [data]="diposits" [(selectedValues)]="this.filter.diposits" i18n-titulo tituloMultiSelect="Selecciona uno o más" i18n-tituloMultiSelect></app-form-multi-select>
    </div>
  </div>
</div>
