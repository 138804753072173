import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { GruaMotiusEntradaFilterService } from './grua-motius-entrada-filter.service';
import { GruaMotiusEntradaFilter } from '../models/grua-motius-entrada-filter';
import { MotiusEntradaGrua } from 'app/sections/grua/Models/motius-entrada.model';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class GruaMotiusEntradaService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setGruaMotiusEntrada(gruaMotiusEntrada: MotiusEntradaGrua): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetGruaMotiusEntrada'], gruaMotiusEntrada);
    }
  }

  removeGruaMotiusEntrada(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteGruaMotiusEntrada'].replace('{secure_id}', secure_id), null);
    }
  }

  getGruaMotiusEntrada(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetGruaMotiuEntrada'].replace('{secure_id}', secure_id), null);
    }
  }

  generateExcel(filter: GruaMotiusEntradaFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new GruaMotiusEntradaFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGruaMotiusEntradaExcel'],  params );
    }
  }
}
