import { Component, OnInit, ElementRef, ViewChild, Input, Inject } from '@angular/core';
import { FormService } from 'path-shared/services/form/form.service';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { UsuarisService } from '../../services/usuaris.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public oldPwd: String;
  public newPwd: String;
  public repeatPwd: String;
  userSecureId: String;
  message: String = null;
  idInstit: string;


  validarSuperSegura = false;
  @ViewChild('mainForm') private mainForm: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formService: FormService,
    private toastr: ToastrService,
    private i18n: I18n,
    private userService: UsuarisService,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private parameterService: ParametersService
  ) {
    if (data !== undefined && data !== null && Object.keys(data).length > 0 ) {
      this.message = data.message;
      this.userSecureId = data.userSecureId;
      this.idInstit = data.idInstit;
    }
   }

  ngOnInit() {
    this.parameterService.getForzarContraseñaSuperSegura(this.idInstit).subscribe(res => {
      this.validarSuperSegura = res === 'Y';
    });
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {

      if ( this.newPwd !== this.repeatPwd) {
        this.toastr.warning(this.i18n('Les dues contrasenyes han de ser iguals'));
      } else {
        if (this.newPwd === this.oldPwd) {
          this.toastr.warning(this.i18n('Les contrasenya nova no pot ser igual a la antiga'));
        } else {
          if (this.newPwd.length < 8) {
            this.toastr.warning(this.i18n('La contrasenya ha de tenir mínim 8 caràcters'));
          } else {
            if (this.validarSuperSegura && !this.superSegura()) {
              this.toastr.warning(this.i18n('La contrasenya ha d\'incloure majúscules, minúscules, números i símbols'));
            } else {
              this.userService.changePassword( this.oldPwd, this.newPwd, this.userSecureId).subscribe(() => {
                this.toastr.success(this.i18n('Contrasenya canviada correctament'));
                this.dialogRef.close(this.newPwd);
              });
            }

          }
        }
      }

    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }

  }
  /** Devuelve true si la contraseña contiene minúsculas, mayúsculas, número y símbolo */
  superSegura() {
    const symbols = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return (this.newPwd.match(/[a-z]/) && this.newPwd.match(/[A-Z]/) && this.newPwd.match(/[0-9]/) && this.newPwd.match(symbols));
  }
}
