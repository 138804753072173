import { Injectable } from "@angular/core";
import { JsonConvert } from "json2typescript";
import { config } from "path-shared/configuration/config";
import { api } from "path-shared/configuration/urls";
import { stringify } from "querystring";
import { Observable } from "rxjs-compat";
import { AuthGuardService } from "../authentication/auth-guard.service";
import { HttpSecureClient } from "../http/httpSecureClient";
import { Reflection } from "../reflection/reflection";


enum Pantallas {
  TRAMITADOR = 'TRAMITADOR',
}

@Injectable()
export class FiltersConfigurationService {

  public Pantallas = Pantallas;  

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {    
  }

  save(pantalla:string, filtro: any): Observable<any>  {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSaveConfigurationFilters'] + '/' + pantalla, filtro);
    }
  }

  get<T>(pantalla: string): Promise<any>  {
    return new Promise(resolve => {

      if (this.authGuard.canActivate()) {
        this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetConfigurationFilters'] + '/' + pantalla, null).subscribe((res:string)=>{
          let aux: T = Reflection.deepCopy<T>(JSON.parse(res));
          resolve(aux);
        });        
      }

    });    
  }

}