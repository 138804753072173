import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ProgressBar } from './progress-bar.model';

enum Pantallas {
    CargaC60 = 'CargaC60',
    CargaMatriculas = 'CargaMatriculas',
    Tramitador = 'Tramitador',
    ImportacioDenuncia = 'ImportacioDenuncia',
    Anulaciones = 'Anulaciones'
}
@Component({
    selector: 'app-progress-bar',
    templateUrl: 'progress-bar.component.html',
    styleUrls: ['progress-bar.component.scss']
})

   
export class ProgressBarComponent implements OnInit {

    static pantallas: Pantallas;
    @Input() class: string;
    value: number;
    text: string;
    @Input() label: string;
    @Input() pantalla: string;
    type: string;

    constructor(
        private not: NotificationService
    ) {

    }

    ngOnInit() {
        this.value = null;
        this.not.onProgressBarChange.subscribe(progressBar => {
            this.value = progressBar.value;
            this.text = progressBar.text;
            this.type = progressBar.type;
        });
    }

    reset() {
        this.value = null;
    }

    next(progressBarValue: ProgressBar) {
        this.value = progressBarValue.value;
        this.text = progressBarValue.text;
        this.type = progressBarValue.type;
    }
}
