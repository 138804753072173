import { AuthService } from 'path-shared/services/authentication/auth.service';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { HttpSecureClient } from './../http/httpSecureClient';
import { EventEmitter } from '@angular/core';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { NotificationType } from './notification-type';
import { PropostesPendentsCount } from 'app/sections/propostes/models/proposta-pendents-count.model';
import * as i0 from "@angular/core";
import * as i1 from "../http/httpSecureClient";
import * as i2 from "../authentication/auth.service";
var NotificationService = /** @class */ (function () {
    function NotificationService(http, auth) {
        this.http = http;
        this.auth = auth;
        this.onReservesEstatChange = new EventEmitter();
        this.onPropostesPendentsChange = new EventEmitter();
        this.onTramitacioEstatChange = new EventEmitter();
        this.onEstatFichersChange = new EventEmitter();
        this.onTitleChange = new EventEmitter();
        this.onAlarmesPendentsChange = new EventEmitter();
        this.onTramitacioPendentsChange = new EventEmitter();
        this.onPropostesGruaPendentsChange = new EventEmitter();
        this.onAlegacionsPendentsChange = new EventEmitter();
        this.onAlarmesGpsPendentsChange = new EventEmitter();
        this.onPdasPendentsLlegirChange = new EventEmitter();
        this.onNovesDadesGpsChange = new EventEmitter();
        this.onTramitarTancamentChange = new EventEmitter();
        this.onErrorConsultaParkeonChange = new EventEmitter();
        this.onLoadG5 = new EventEmitter();
        this.EjecutivaResultats = new EventEmitter();
        this.onProgressBarChange = new EventEmitter();
        this.onEstatAnulacioProcessadaChange = new EventEmitter();
        this.onBOEC60Change = new EventEmitter();
        this.onTramitacionC60Change = new EventEmitter();
        this.onTramitacionNotificacionesChange = new EventEmitter();
        this.onGtwinSyncChange = new EventEmitter();
    }
    NotificationService.prototype.subscribeToEvent = function (notificationType, delegate) {
        if (this.auth.isAuthenticated()) {
            // Se suscribe a los eventos de tipo notificacion (si es que no esta ya susrito).
            if (this.currentEvents === undefined) {
                this.currentEvents = this.http.eventListener(config.urls['apiUrl'] + api.endpoints['urlGetNotification']);
            }
            // Registramos (en el front) el tipo de evento del que debemos recibir updates.
            this.currentEvents.addEventListener(notificationType, function (result) {
                if (result !== undefined && !delegate.closed && delegate.observers.length > 0) {
                    delegate.emit(JSON.parse(result.data));
                }
            });
        }
    };
    NotificationService.prototype.unsubscribeToEvent = function (notificationType) {
        this.currentEvents.removeEventListener(notificationType);
    };
    NotificationService.prototype.configure = function () {
        var _this = this;
        if (this.auth.isAuthenticated()) {
            var events = this.http.eventListener(config.urls['apiUrl'] + api.endpoints['urlGetNotification']);
            events.addEventListener('heartbeat', function (message) {
                // console.log(message);
            });
            events.addEventListener('error', function (error) {
                console.log(error);
            });
            events.addEventListener(NotificationType.PropostesPendents, function (result) {
                console.log('PropostesPendents ' + result.data);
                _this.onPropostesPendentsChange.emit(PropostesPendentsCount.Parse(result.data));
            });
            // events.addEventListener(NotificationType.EstatReserves, result => {
            //   this.onReservesEstatChange.emit(JSON.parse(result.data));
            // });
            events.addEventListener(NotificationType.EstatTramitacio, function (result) {
                console.log('EstatTramitacio ' + result.data);
                _this.onTramitacioEstatChange.emit(JSON.parse(result.data));
            });
            events.addEventListener(NotificationType.EstatFitxers, function (result) {
                console.log('EstatFitxers ' + result.data);
                _this.onEstatFichersChange.emit(JSON.parse(result.data));
            });
            events.addEventListener(NotificationType.AlarmesPendents, function (result) {
                console.log('AlarmesPendents ' + result.data);
                _this.onAlarmesPendentsChange.emit(parseInt(result.data, 10));
            });
            events.addEventListener(NotificationType.TramitacioPendents, function (result) {
                console.log('TramitacioPendents ' + result.data);
                _this.onTramitacioPendentsChange.emit(parseInt(result.data, 10));
            });
            events.addEventListener(NotificationType.AlarmesGpsPendents, function (result) {
                _this.onAlarmesGpsPendentsChange.emit(result);
            });
            events.addEventListener(NotificationType.PdasPendentsLlegir, function (result) {
                // const pdasPendentsLlegir: Array<Terminal> = JSON.parse(result.data);
                // console.log('PdasPendentsLlegir ' + pdasPendentsLlegir.length);
                _this.onPdasPendentsLlegirChange.emit(result);
            });
            events.addEventListener(NotificationType.NovesDadesGps, function (result) {
                _this.onNovesDadesGpsChange.emit(result);
            });
            events.addEventListener(NotificationType.PropostesGruaPendents, function (result) {
                console.log('PropoesteGruaPendents ' + result.data);
                _this.onPropostesGruaPendentsChange.emit(parseInt(result.data, 10));
            });
            events.addEventListener(NotificationType.TramitarTancament, function (result) {
                console.log('TramitarTancaments ' + result.data);
                _this.onTramitarTancamentChange.emit(JSON.parse(result.data));
            });
            events.addEventListener(NotificationType.LoadG5, function (result) {
                console.log('LoadG5 ' + result.data);
                _this.onLoadG5.emit(JSON.parse(result));
            });
            events.addEventListener(NotificationType.EjecutivaResultats, function (result) {
                console.log('EjecutivaResultats ' + result.data);
                _this.EjecutivaResultats.emit(JSON.parse(result));
            });
            events.addEventListener(NotificationType.ErrorConsultaParkeon, function (result) {
                console.log('ErrorConsultaParkeon ' + result.data);
                _this.onErrorConsultaParkeonChange.emit(result.data);
            });
            events.addEventListener(NotificationType.ProgressBar, function (result) {
                console.log('ProgressBar ' + result.data);
                _this.onProgressBarChange.emit(JSON.parse(result.data));
            });
            events.addEventListener(NotificationType.EstatAnulacioProcessada, function (result) {
                console.log('EstatAnulacioProcessada ' + result.data);
                _this.onEstatAnulacioProcessadaChange.emit(JSON.parse(result.data));
            });
            events.addEventListener(NotificationType.TramitacioC60Status, function (result) {
                console.log('C60 generada ' + result.data);
                _this.onTramitacionC60Change.emit(JSON.parse(result.data));
            });
            events.addEventListener(NotificationType.BOEC60Status, function (result) {
                console.log('C60 generada ' + result.data);
                _this.onBOEC60Change.emit(JSON.parse(result.data));
            });
            events.addEventListener(NotificationType.TramitacioNotificacionStatus, function (result) {
                console.log('Notificacion generada ' + result.data);
                _this.onTramitacionNotificacionesChange.emit(JSON.parse(result.data));
            });
            events.addEventListener(NotificationType.GtwinSync, function (result) {
                _this.onGtwinSyncChange.emit(result.data === 'true');
            });
            events.addEventListener(NotificationType.AlegacionsPendents, function (result) {
                console.log('Alegacions pendents: ' + result.data);
                _this.onAlegacionsPendentsChange.emit(parseInt(result.data, 10));
            });
        }
    };
    NotificationService.prototype.titleChange = function (title) {
        this.onTitleChange.next(title);
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
