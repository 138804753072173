import { FitxerContentTab } from './fitxer-content-tab.model';

export class ContentTab {
    idContingut: number;
    isMe: boolean;
    fechaHora: Date;
    mensaje: string;
    fitxers: Array<FitxerContentTab>;

    constructor(idContingut: number, isMe: boolean, fechaHora: Date, mensaje: string, fitxers: Array<FitxerContentTab>) {
        this.idContingut = idContingut;
        this.isMe = isMe;
        this.fechaHora = fechaHora;
        this.mensaje = mensaje;
        this.fitxers = fitxers;
    }
}