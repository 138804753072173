import { Injectable } from '@angular/core';

@Injectable()
export class AudioService {
    audio: HTMLAudioElement;

    new(src: string) {
        this.audio = new Audio(src);
        this.audio.loop = true;
    }

    start() {

        this.audio.play();
    }

    stop() {
        this.audio.pause();
    }
}
