import { DeteccioMatriculaFilter } from './../models/deteccio-matricula-filter';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { DeteccioMatriculesFilterService } from './deteccio-matricules-filter.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';

const defaultSortParam = 'data';
const defaultSortDirection = 'asc';

@Injectable({
  providedIn: 'root'
})
export class DeteccioMatriculesService {
  displayedItems = [];

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  generateExcel(filter: DeteccioMatriculaFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new DeteccioMatriculesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlDeteccioMatriculesExcel'],  params );
    }
  }

  getImageUrl(secure_id, index, suffix) {
    return config.urls['apiUrl'].replace('{hostname}', window.location.hostname) + api.endpoints['urlDeteccioMatriculesImatge'].replace('{secure_id}', secure_id).replace('{n}', index).replace('{suffix}', suffix);
  }

}
