import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { ErrorNotificacionTramitarDto } from '../../../../models/notificar/error-notificacion-tramitar.dto';
import { NotificarComponent } from '../../notificar-page-component/notificar.component';

@Component({
  selector: 'app-listado-errores-notificaciones',
  templateUrl: './listado-errores-notificaciones.component.html',
  styleUrls: ['./listado-errores-notificaciones.component.scss']
})
export class ListadoErroresNotificacionesComponent implements OnInit {

  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  dataDialog: ComponentDialog = Builder.newComponentDialog();
  dataSource: MatTableDataSource<ErrorNotificacionTramitarDto[]>;

  displayedColumns = ['expediente', 'mensaje', 'codigoPostal'];

  constructor(
    private i18n: I18n,
    public thisDialogRef: MatDialogRef<NotificarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataSource = new MatTableDataSource();
    if (data !== undefined) {
      this.dataDialog = data;
      this.dataDialog.isDialog = true;
      this.dataSource = new MatTableDataSource(data);
    }
  }

  ngOnInit() { }

  close() {
    this.notify.emit(true);
    this.thisDialogRef.close();
  }

}
