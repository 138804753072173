/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-file-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../pipes/size-pipe";
import * as i3 from "@angular/common";
import * as i4 from "./form-file-upload.component";
var styles_FormFileUploadComponent = [i0.styles];
var RenderType_FormFileUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormFileUploadComponent, data: {} });
export { RenderType_FormFileUploadComponent as RenderType_FormFileUploadComponent };
function View_FormFileUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col-sm-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormFileUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SizePipe, []), i1.ɵqud(402653184, 1, { input: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "row align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFileUploadComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.upload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Seleccionar fitxer"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-sm-5"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " (", ")"])), i1.ɵppd(10, 1), (_l()(), i1.ɵeld(11, 0, [[1, 0], ["uploadFileInput", 1]], null, 0, "input", [["class", "form-control-file"], ["hidden", ""], ["style", "width: 0px"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.labelHidden; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.size == 0); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.name; var currVal_3 = i1.ɵunv(_v, 9, 1, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.size)); _ck(_v, 9, 0, currVal_2, currVal_3); }); }
export function View_FormFileUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-file-upload", [], null, null, null, View_FormFileUploadComponent_0, RenderType_FormFileUploadComponent)), i1.ɵdid(1, 114688, null, 0, i4.FormFileUploadComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormFileUploadComponentNgFactory = i1.ɵccf("app-form-file-upload", i4.FormFileUploadComponent, View_FormFileUploadComponent_Host_0, { label: "label", labelHidden: "labelHidden", name: "name" }, { fileReady: "fileReady" }, []);
export { FormFileUploadComponentNgFactory as FormFileUploadComponentNgFactory };
