<div class="container-fluid">
    <form #mainForm>
        <div class="row">
            <div class="col-6">
                <app-form-text-edit id="ajuda-titol" i18n-label label="Títol" [(value)]="this.titol" required maxLength="50"></app-form-text-edit>
            </div>
        </div>
        <div class="row fixed-height">
            <div class="col-6">
                <app-form-text-area class="col cosMissatge" i18n-label label="Cos del missatge" [type]="'text'" [(value)]="this.cosMissatge" maxLength="5000"></app-form-text-area>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-1"><button class="btn btn-primary" i18n (click)="enviar()">Enviar</button></div>
        </div>
    </form>
</div>