
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SomPolGruaFilter } from '../../Models/som-pol-grua-filter';
import { Dictionary } from 'path-shared/models/dictionary';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { AgentsService } from 'app/sections/configuracio/agents/services/agents-service.service';
import { GruaPendentsService } from '../../services/grua-pendents.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-tramitades-filter',
  templateUrl: './tramitades-filter.component.html',
  styleUrls: ['./tramitades-filter.component.scss']
})
export class TramitadesFilterComponent  implements OnInit, OnDestroy {
  subscription: Subscription;
  agentsDictionary: Dictionary[];
  valorAutoCompleteAgent;
  agentsList = [];
  @ViewChild('comboAgent') comboAgent: ComboBoxInputAutocompleteComponent;

  tecnicsDictionary: Dictionary[];
  valorAutoCompleteTecnic;
  tecnicsList = [];
  @ViewChild('comboTecnics') comboTecnics: ComboBoxInputAutocompleteComponent;

  motiusDesestimarDictionary: Dictionary[];
  valorAutoCompleteMotiuDesestimar;
  motiusDesestimarList = [];
  @ViewChild('comboMotiusDesestimar') comboMotiusDesestimar: ComboBoxInputAutocompleteComponent;

  typeLabels = ['Data ticket', 'Data Banc'];
  typeValues = [true, false];

  filter: SomPolGruaFilter;

  @Output() notify: EventEmitter<SomPolGruaFilter> = new EventEmitter<SomPolGruaFilter>();

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(public thisDialogRef: MatDialogRef<SomPolGruaFilter>,
    @Inject(MAT_DIALOG_DATA) public data,
    private agentsService: AgentsService,
    private gruaSerevice: GruaPendentsService,
  ) {
    this.valorAutoCompleteAgent = '';
    this.valorAutoCompleteTecnic = '';
    this.valorAutoCompleteMotiuDesestimar = '';
    this.filter = data.filter;
  }

  ngOnInit() {
    this.loadAgents();
    this.loadTecnics();
    this.loadMotiusDesestimar();
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

  loadAgents() {
    this.agentsDictionary = new Array<Dictionary>();
    this.agentsService.getAllCombo().subscribe( x => {
      this.agentsList = x;
      x.forEach(element => {
        this.agentsDictionary.push(new Dictionary(element.SECURE_ID.toString(), element.codi));
      });
      this.setDefalutValuesCombosAgent();
    });
  }

  agentSeleccionado(secureIdAgent: string) {
    const agentSelected = this.agentsList.find(x => x.SECURE_ID === secureIdAgent);
    this.filter.idAgentFk = agentSelected.codi;
  }

  setDefalutValuesCombosAgent() {
    //agent 
    if (this.filter.idAgentFk !== undefined && this.filter.idAgentFk !== null && this.filter.idAgentFk !== ''
     && this.agentsDictionary.length > 0 ) {
      this.comboAgent.setForcedValueComb(this.filter.idAgentFk);
    }
  }

  loadTecnics() {
    this.tecnicsDictionary = new Array<Dictionary>();
    this.gruaSerevice.getAllTecnicsCombo().subscribe( x => {
      this.tecnicsList = x;
      x.forEach(element => {
        this.tecnicsDictionary.push(new Dictionary(element.secureId.toString(), element.codi));
      });
      this.setDefalutValuesCombosTecnic();
    });
  }

  tecnicSeleccionado(secureIdTecnic: string) {
    const tecnicSelected = this.tecnicsList.find(x => x.secureId === secureIdTecnic);
    this.filter.idTecnicFK = tecnicSelected.somId;
  }

  setDefalutValuesCombosTecnic() {
    //agent 
    if (this.filter.idTecnicFK !== undefined && this.filter.idTecnicFK !== null && this.filter.idTecnicFK !== ''
     && this.tecnicsDictionary.length > 0 ) {
      this.comboTecnics.setForcedValueComb(this.filter.idTecnicFK);
    }
  }

  loadMotiusDesestimar() {
    this.motiusDesestimarDictionary = new Array<Dictionary>();
    this.gruaSerevice.getAllMotiusDesestimarCombo().subscribe( x => {
      this.motiusDesestimarList = x;
      x.forEach(element => {
        this.motiusDesestimarDictionary.push(new Dictionary(element.secureId.toString(), element.motiu));
      });
      this.setDefalutValuesCombosMotiusDesestimar();
    });
  }

  motiusDesestimarSeleccionado(secureIdMotiu: string) {
    const motiusSelected = this.motiusDesestimarList.find(x => x.secureId === secureIdMotiu);
    this.filter.idMotiuDesestimada = motiusSelected.somId;
  }

  setDefalutValuesCombosMotiusDesestimar() {
    //agent 
    if (this.filter.idMotiuDesestimada !== undefined && this.filter.idMotiuDesestimada !== null && this.filter.idMotiuDesestimada !== ''
     && this.tecnicsDictionary.length > 0 ) {
      this.comboMotiusDesestimar.setForcedValueComb(this.filter.idMotiuDesestimada);
    }
  }

}
