// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';
import { UserPermissionsByPage } from '../../models/userPermissionsByPage';
import { HttpSecureClient } from '../http/httpSecureClient';
import { api } from '../../../shared/configuration/urls';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { debug } from 'util';
import { SOM_CARAS } from 'path-models/SOM_CARAS';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ImagesService {
  constructor(private router: Router, private http: HttpSecureClient ) {}
  // ...
  public getImageUrl(somId, suffix = ''): string {
    let url = config.urls['apiUrl'].replace('{hostname}', window.location.hostname) + '/image/' + somId;
    if (suffix != null && suffix !== '') {
      url = url + '/' + suffix;
    }
    return url;
  }

  public getImagesByContenedor(contenedor): Observable<any> {
    const url = config.urls['apiUrl'] + api.endpoints['GetImagesByContenedor'].replace('{contenedor}', contenedor);
    return this.http.get(url, null);

  }

  public getImagsByPath(path: string): Observable<any> {
    const url = config.urls['apiUrl'] + api.endpoints['GetImageByPath'].replace('{PATH}', path);
    return this.http.get(url, null);

  }

  public deleteImage(secureId: string): Observable<any> {

    const url = config.urls['apiUrl'] + api.endpoints['urlDeleteImage'] + '/' + secureId;
    return this.http.delete(url, null);

  }

  public pasarOCR(idAparell: string, idSomFichero: string): Observable<any> {

    const url =  config.urls['apiUrl'] + api.endpoints['urlPasarOCR'];
    const params = new HttpParams()
    .append('idAparell', idAparell)
    .append('idSomFichero', idSomFichero);
    return this.http.get(url, params);

  }
  public getFileUrl(somId): string {
    const url = config.urls['apiUrl'].replace('{hostname}', window.location.hostname) + '/file/' + somId;
    return url;
  }
  public getImageFaces(somId, width):  Observable<any> {
    const url = config.urls['apiUrl'] + '/images/' + somId + '/faces/' + width;
    return this.http.get(url, null);
  }
  public setImageFaces(somId, cares, width):  Observable<any> {
    const url = config.urls['apiUrl'] + '/images/' + somId + '/faces/' + width;
    return this.http.post(url, cares);
  }
  public setImageUndo(somId): Observable<any> {
    const url = config.urls['apiUrl'] + '/images/' + somId + '/faces/undo';
    return this.http.post(url, null);
  }

  public rotateImage(somId): Observable<any> {
    const url = config.urls['apiUrl'] + api.endpoints['UrlRotateImage'].replace('{somId}', somId);
    return this.http.post(url, null);
  }
  public cropImage(somId, cropData): Observable<any> {
    const url = config.urls['apiUrl'] + api.endpoints['UrlCropImage'].replace('{somId}', somId);
    return this.http.post(url, cropData);
  }

  public setBrightness(somId, brightness): Observable<any> {
    const url = config.urls['apiUrl'] + api.endpoints['UrlSetBrightnessImage'].replace('{somId}', somId);
    return this.http.post(url,  brightness);
  }

  public setMatriculasImage(numDenuncia: number, somId: string, matricula: string, matriculasImageData: SOM_CARAS[]): Observable<any> {
    const url = config.urls['apiUrl'] + api.endpoints['UrlSetMatriculasImage'].replace('{somId}', somId).replace('{numDenuncia}', numDenuncia.toString()).replace('{matricula}', matricula);
    return this.http.post(url,  matriculasImageData);
  }
  setTodasMatriculasImage(numDenuncia: number,  matricula: string, ): Observable<any> {
    const url = config.urls['apiUrl'] + api.endpoints['UrlSetTodasMatriculasImage'].replace('{numDenuncia}', numDenuncia.toString()).replace('{matricula}', matricula);
    return this.http.post(url,  null);
  }

  afegirImatges(formData: FormData, contenedor: string): Observable<any> {
    const url = config.urls['apiUrl'] + api.endpoints['urlAddImagesByContenedor'].replace('{contenedor}', contenedor);
    return this.http.post(url, formData);
  }
}
