<mat-card>
  <mat-card-content>
    <mat-list>
      <mat-list-item class="row" *ngIf="this.visible">        
        <button class="btn btn-primary col-3" (click)="this.OpenTramitarOlot()" >Exp. A Revisar</button>
      </mat-list-item>
      <mat-list-item class="row">
        <button class="btn btn-primary col-3" (click)="this.OpenLlistaExecutiva()">Exp.A Trasladar</button>
      </mat-list-item>
      <mat-list-item class="row">
        <button class="btn btn-primary col-3" (click)="this.Impressio()">Impresión</button>
      </mat-list-item>
    </mat-list> 
  </mat-card-content>
</mat-card>
