import { FormControl } from '@angular/forms';
import { OnInit, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { startWith } from 'rxjs/operators/startWith';
import { map } from 'rxjs/operators/map';
import { ToastrService } from 'ngx-toastr';
var State = /** @class */ (function () {
    function State(name, population, flag) {
        this.name = name;
        this.population = population;
        this.flag = flag;
    }
    return State;
}());
export { State };
var AutocompleteComboComponent = /** @class */ (function () {
    function AutocompleteComboComponent(toastr) {
        var _this = this;
        this.toastr = toastr;
        this.control = new FormControl();
        this.valueMapper = function (key) {
            if (_this.options !== undefined && _this.options !== null && _this.options.length > 0) {
                var selection = _this.options.find(function (c) { return c.key === key; });
                if (selection) {
                    return selection.value;
                }
            }
        };
        this.required = false;
        this.valorSeleccionado = new EventEmitter();
        this.funcionSeleccionada = new EventEmitter();
        this.valorAutocomplete = new BehaviorSubject('');
    }
    Object.defineProperty(AutocompleteComboComponent.prototype, "options", {
        get: function () {
            return this._options;
        },
        set: function (val) {
            this._options = val;
            this.defineOptions();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComboComponent.prototype, "disableComponent", {
        get: function () {
            return this._disableComponent;
        },
        set: function (val) {
            this._disableComponent = val;
            if (val === undefined || val === null || !val) {
                this.control.enable();
            }
            else {
                this.control.disable();
            }
        },
        enumerable: true,
        configurable: true
    });
    AutocompleteComboComponent.prototype.ngOnInit = function () {
        this.defineOptions();
        if (this.disableComponent !== undefined && this.disableComponent !== null && this.disableComponent) {
            this.control.disable();
        }
    };
    AutocompleteComboComponent.prototype.defineOptions = function () {
        var _this = this;
        this.filteredOptions = this.control.valueChanges.pipe(startWith(''), map(function (value) { return _this._filter(value); }));
    };
    AutocompleteComboComponent.prototype.valueSelected = function (optionSelected) {
        this.valorSeleccionado.emit(optionSelected.value);
    };
    AutocompleteComboComponent.prototype.valueSelectedDirect = function (optionSelected) {
        this.valorSeleccionado.emit(optionSelected);
    };
    AutocompleteComboComponent.prototype.functionPressed = function (functionKey) {
        this.funcionSeleccionada.emit(functionKey);
    };
    AutocompleteComboComponent.prototype._filter = function (option) {
        if (option !== undefined && option !== null && option !== '' && this.options !== undefined && this.options !== null) {
            var filterValue_1 = option.toLowerCase();
            return this.options.filter(function (x) { return x.value.toLowerCase().includes(filterValue_1); });
        }
        else {
            return this.options;
        }
    };
    AutocompleteComboComponent.prototype.isRequired = function () {
        return this.required;
    };
    AutocompleteComboComponent.prototype.isDisabled = function (key) {
        var disabled = false;
        if (this.elementsDisableds !== undefined && this.elementsDisableds !== null && this.elementsDisableds.length > 0) {
            var value = this.elementsDisableds.find(function (x) { return x === key; });
            if (value !== undefined && value !== null) {
                disabled = true;
            }
        }
        return disabled;
    };
    AutocompleteComboComponent.prototype.getColor = function (key) {
        var color = '#000000';
        if (this.colorsKey !== undefined && this.colorsKey !== null) {
            var index = this.colorsKey.findIndex(function (x) { return x.key === key; });
            if (index !== -1) {
                color = this.colorsKey[index].value;
            }
        }
        return color;
    };
    AutocompleteComboComponent.prototype.clickOption = function (key) {
        if (this.isDisabled(key) && this.mensajeDeshabilitado !== undefined && this.mensajeDeshabilitado !== null) {
            this.toastr.warning(this.mensajeDeshabilitado);
        }
        var lowerCaseValue = this.control.value.toLowerCase();
        if (this.options.filter(function (x) { return x.key.toLowerCase().includes(lowerCaseValue); }).length === 1) {
            if (this.control.value !== this.valorAutocomplete.value) {
                var selectedOption = this.options.filter(function (x) { return x.key.toLowerCase().includes(lowerCaseValue); })[0];
                //  this.setValueComb(selectedOption.value);
                this.valueSelectedDirect(selectedOption.key);
            }
        }
    };
    AutocompleteComboComponent.prototype.selectedOption = function (key) {
        if (this.isDisabled(key) && this.mensajeDeshabilitado !== undefined && this.mensajeDeshabilitado !== null) {
            this.toastr.warning(this.mensajeDeshabilitado);
        }
        if (this.options.filter(function (x) { return x.key.toLowerCase().includes(key.toLowerCase()); }).length === 1) {
            var selectedOption = this.options.filter(function (x) { return x.key.toLowerCase().includes(key.toLowerCase()); })[0];
            //this.setValueComb(selectedOption.value);
            this.valueSelectedDirect(selectedOption.key);
        }
    };
    AutocompleteComboComponent.prototype.setValueComb = function (option) {
        if (option !== '') {
            // const filterValue = option.toLowerCase();
            this.control.setValue(option);
            //this.valorAutocomplete = new BehaviorSubject<string>(option);
            this.valorAutocomplete = new BehaviorSubject(option);
            //return this.options.filter(x => x.value.toLowerCase().includes(filterValue));
        } /*else {
          return this.options;
        }*/
    };
    AutocompleteComboComponent.prototype.setForcedValueComb = function (option) {
        this.control.setValue(option);
        this.valorAutocomplete = new BehaviorSubject(option);
    };
    AutocompleteComboComponent.prototype.checkSelected = function () {
        if (this.control.value !== undefined && this.control.value !== null && this.control.value !== '') {
            var lowerCaseValue_1 = this.control.value.toLowerCase();
            if (this.options.filter(function (x) { return x.value.toString().toLowerCase().includes(lowerCaseValue_1); }).length === 1) {
                if (this.control.value !== this.valorAutocomplete.value) {
                    var selectedOption = this.options.filter(function (x) { return x.value.toLowerCase().includes(lowerCaseValue_1); })[0];
                    this.setValueComb(selectedOption.value);
                    this.valueSelectedDirect(selectedOption.key);
                }
                else {
                }
            }
            else {
                this.resetCombo();
            }
        }
        else {
            this.resetCombo();
            this.valorSeleccionado.emit('');
        }
    };
    AutocompleteComboComponent.prototype.resetCombo = function () {
        this.control.setValue(null);
        this.defineOptions();
    };
    return AutocompleteComboComponent;
}());
export { AutocompleteComboComponent };
