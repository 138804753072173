import { Component, OnInit, ElementRef, ViewChild, Inject, HostListener } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { Recaudacio } from '../../models/recaudacio.model';
import { RecaudacioService } from '../../services/recaudacio.service';
import { RecaudacioExpenedorInfo } from '../../models/recaudacio-expenedor-info.model';
import { ZB_MAQUINES_PARKEON } from 'path-models/ZB_MAQUINES_PARKEON';
import { Dictionary } from 'path-shared/models/dictionary';
import { BehaviorSubject } from 'rxjs';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';

@Component({
  selector: 'app-recaudacio-editor',
  templateUrl: './recaudacio-editor.component.html',
  styleUrls: ['./recaudacio-editor.component.scss']
})
export class RecaudacioEditorComponent implements OnInit {

  closeButtonText: string;

  secure_id: string;
  recaudacio: Recaudacio;

  numColectaAnterior: number;
  acumuladoMonedaCalculado: number;

  numColectaJustificanteActiu: boolean;
  acumuladoMonedaJustificacionActiu: boolean;
  readonly MULTIPLICADOR_MONEDA_COMERCIAL = 0.75;

  zonesAux = [];
  zonesComboDictionary: BehaviorSubject<Array<Dictionary>>;
  maquinesParkeonComboDictionary: BehaviorSubject<Array<Dictionary>>;;
  maquinesParkeonAux: ZB_MAQUINES_PARKEON[] = [];

  expenedorIdAnterior: string;

  F10 = 121;

  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild('comboZones') comboZones: ComboBoxInputComponent;
  @ViewChild('comboMaquinesParkeon') comboMaquinesParkeon: ComboBoxInputComponent;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === this.F10) {
      this.save();
    }
  }

  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private recaudacioService: RecaudacioService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<RecaudacioEditorComponent>,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
      const ahir = new Date();
      ahir.setDate(ahir.getDate() - 1);

      this.zonesComboDictionary = new BehaviorSubject(new Array<Dictionary>());
      this.maquinesParkeonComboDictionary = new BehaviorSubject(new Array<Dictionary>());

      this.recaudacio = Builder.newRecaudacio();
      this.recaudacio.fecha = ahir;

      this.zonesAux = this.data.zones;
      this.maquinesParkeonAux = this.data.maquinesParkeon;
      this.secure_id = this.data.id;
      this.numColectaJustificanteActiu = false;
      this.acumuladoMonedaJustificacionActiu = false;

      this.expenedorIdAnterior = null;
      this.acumuladoMonedaCalculado = null;

      if (this.data.isReadOnly) {
        this.closeButtonText = this.i18n('Tancar');
      } else {
        this.closeButtonText = this.i18n('Llista');
      }
   }

  ngOnInit() {
    if (this.secure_id !== null) {
      this.recaudacioService.getRecaudacio(this.secure_id).subscribe((recaudacio: Recaudacio) => {
        this.recaudacio = recaudacio;

        this.loadZones();
        this.loadMaquinesParkeon();
      });
    } else {
      this.loadZones();
    }
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm) && this.recaudacio.maquinaParkeonFk !== null) {
      this.recaudacioService.setRecaudacio(this.recaudacio).subscribe(result => {
        this.toastr.info(this.i18n('Recaptació guardada correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    if (this.recaudacio.id !== null && this.recaudacio.id !== undefined) {
      this.thisDialogRef.close(false);
    } else {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol tancar el formulari? Perdrà els canvis si no els guarda.'))
    .then((confirmed) => {
        if (confirmed) {
          this.thisDialogRef.close(false);
        }
      });
    }
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la recaptació?'))
    .then((confirmed) => {
        if (confirmed) {
          this.recaudacioService.removeRecaudacio(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Recaptació esborrada correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

  expenedorInfo(expenedorId: any) {
    if (expenedorId !== null && expenedorId !== undefined && expenedorId !== this.expenedorIdAnterior) {
      this.expenedorIdAnterior = expenedorId;
      const expenedorSecureId = this.secure_id !== null && this.secure_id !== undefined ? this.secure_id : '-1';

      this.recaudacioService.getExpenedorInfo(expenedorSecureId, expenedorId).subscribe((expenedorInfo: RecaudacioExpenedorInfo) => {
        this.recaudacio.numColectaAnterior = expenedorInfo.numColectaAnterior;
        this.numColectaAnterior = expenedorInfo.numColectaAnterior;
        this.acumuladoMonedaCalculado = expenedorInfo.acumuladoMoneda;

        this.acumuladoMonedaValidar();
        this.numColectaCorrelativo();
      });
    }
  }

  loadZones() {
    const zonesComboAux = new Array<Dictionary>();
    this.zonesAux.forEach(zona => {
      zonesComboAux.push(new Dictionary(zona.id, zona.descripcio));
    });
    this.zonesComboDictionary.next(zonesComboAux);

    if (this.recaudacio.zonaFk !== undefined && this.recaudacio.zonaFk != null) {
      this.comboZones.setSelect(this.recaudacio.zonaFk);
    }
  }

  loadMaquinesParkeon() {
    const maquinesComboAux = new Array<Dictionary>();

    if (this.recaudacio.zonaFk !== undefined && this.recaudacio.zonaFk !== null) {
      this.maquinesParkeonAux.filter(x => x.ZB_ZONAS_FK === this.recaudacio.zonaFk).forEach(maquina => {
        maquinesComboAux.push(new Dictionary(maquina.idZBMaquinaParkeon, maquina.CODI_MAQUINA));
      });
      this.maquinesParkeonComboDictionary.next(maquinesComboAux);

      if (this.recaudacio.maquinaParkeonFk !== undefined && this.recaudacio.maquinaParkeonFk != null) {
        this.comboMaquinesParkeon.setSelect(this.recaudacio.maquinaParkeonFk);
        this.expenedorInfo(this.recaudacio.maquinaParkeonFk);
      }
    }
  }

  zonaSeleccionado(idZona: number) {
    this.recaudacio.zonaFk = idZona;

    //Calculem el combo dependent de maquines
    const maquinesComboAux = new Array<Dictionary>();
    this.maquinesParkeonAux.filter(x => x.ZB_ZONAS_FK === idZona).forEach(maquina => {
      maquinesComboAux.push(new Dictionary(maquina.idZBMaquinaParkeon, maquina.CODI_MAQUINA));
    });
    this.maquinesParkeonComboDictionary.next(maquinesComboAux);
  }

  maquinaParkeonSeleccionado(idMaquina: number) {
    this.recaudacio.maquinaParkeonFk = idMaquina;
  }

  //#region Cálculo de validaciones y fórmulas

  /*
    Validar que el numColecta sea correlativo al numColectaAnterior.
    Si es el primer tiquet de la colecta, nos marcamos el numColectaAnterior=0
    En cas de no ser correlatius, mostrar input de numColectaJustificante
  */
  numColectaCorrelativo() {
    if (this.numColectaAnterior !== undefined && this.numColectaAnterior !== 0 && this.recaudacio.numColecta - 1 !== this.numColectaAnterior) {
      this.numColectaJustificanteActiu = true;
    } else {
      this.numColectaJustificanteActiu = false;
    }
  }

  acumuladoMonedaValidar() {
    const acumuladoMoneda = this.recaudacio.acumuladoMoneda !== null ? parseFloat(this.recaudacio.acumuladoMoneda.toString()) : 0;
    const importeMonedaD = this.recaudacio.importeMonedaD !== null ? parseFloat(this.recaudacio.importeMonedaD.toString()) : 0;

    if (this.acumuladoMonedaCalculado !== undefined && this.acumuladoMonedaCalculado !== null
      && acumuladoMoneda !== (importeMonedaD + parseFloat(this.acumuladoMonedaCalculado.toString()))) {
      this.acumuladoMonedaJustificacionActiu = true;
    } else {
      this.acumuladoMonedaJustificacionActiu = false;
      this.recaudacio.acumuladoMonedaJustificacion = null;
    }
  }

  formulaImporteMonedaComercial() {
    this.recaudacio.importeMonedaComercial = this.recaudacio.numeroTicketsMonedaComercial * this.MULTIPLICADOR_MONEDA_COMERCIAL;
  }

  formulaImporteMonedaLegal() {
    const importeMonedaD = this.recaudacio.importeMonedaD !== null ? parseFloat(this.recaudacio.importeMonedaD.toString()) : 0;
    const importeMonedaComercial = this.recaudacio.importeMonedaComercial !== null ? parseFloat(this.recaudacio.importeMonedaComercial.toString()) : 0;
    this.recaudacio.importeMonedaLegal = importeMonedaD - importeMonedaComercial;
  }

  formulaImporteTarjetaTotal() {
    const importeTarjetaPayone = this.recaudacio.importeTarjetaPayone !== null ? parseFloat(this.recaudacio.importeTarjetaPayone.toString()) : 0;
    const importeTarjetaPayonePlus = this.recaudacio.importeTarjetaPayonePlus !== null ? parseFloat(this.recaudacio.importeTarjetaPayonePlus.toString()) : 0;

    this.recaudacio.importeTarjetaTotal = importeTarjetaPayone + importeTarjetaPayonePlus;
  }

  formulaImporteRecargaTarjetaTotal() {
    const importeRecargaTarjetaPayone = this.recaudacio.importeRecargaTarjetaPayone !== null ? parseFloat(this.recaudacio.importeRecargaTarjetaPayone.toString()) : 0;
    const importeRecargaTarjetaPayonePlus = this.recaudacio.importeRecargaTarjetaPayonePlus !== null ? parseFloat(this.recaudacio.importeRecargaTarjetaPayonePlus.toString()) : 0;

    this.recaudacio.importeRecargaTarjetaTotal = importeRecargaTarjetaPayone + importeRecargaTarjetaPayonePlus;
  }

  formulaImporteAnulacionImporteTotal() {
    const anulacionMoneda = this.recaudacio.anulacionMoneda !== null ? parseFloat(this.recaudacio.anulacionMoneda.toString()) : 0;
    const anulacionTarjetaPayOne = this.recaudacio.importeAnulacionTarjetaPayOne !== null ? parseFloat(this.recaudacio.importeAnulacionTarjetaPayOne.toString()) : 0;
    const anulacionTarjetaPayOnePlus = this.recaudacio.importeAnulacionTarjetaPayOnePlus !== null ? parseFloat(this.recaudacio.importeAnulacionTarjetaPayOnePlus.toString()) : 0;

    this.recaudacio.anulacionImporteTotal = anulacionMoneda + anulacionTarjetaPayOne + anulacionTarjetaPayOnePlus;
  }

  formulaImporteRealAparcamiento() {
    const importeMonedaD = this.recaudacio.importeMonedaD !== null ? parseFloat(this.recaudacio.importeMonedaD.toString()) : 0;
    const importeTarjetaTotal = this.recaudacio.importeTarjetaTotal !== null ? parseFloat(this.recaudacio.importeTarjetaTotal.toString()) : 0;
    const importeRecargaTarjetaTotal = this.recaudacio.importeRecargaTarjetaTotal !== null ? parseFloat(this.recaudacio.importeRecargaTarjetaTotal.toString()) : 0;
    const anulacionImporteTotal = this.recaudacio.anulacionImporteTotal !== null ? parseFloat(this.recaudacio.anulacionImporteTotal.toString()) : 0;

    this.recaudacio.importeRealAparcamiento = importeMonedaD + importeTarjetaTotal - importeRecargaTarjetaTotal - anulacionImporteTotal;
  }

  formulaNumeroTicketsTotal() {
    this.recaudacio.numeroTicketsTotal = this.recaudacio.numeroTicketsMoneda + this.recaudacio.numeroTicketsTarjetaPayone + this.recaudacio.numeroTicketsTarjetaPayonePlus;
  }

  formulaDiferenciaRecuentoTicket() {
    const importeMonedaLegal = this.recaudacio.importeMonedaLegal !== null ? parseFloat(this.recaudacio.importeMonedaLegal.toString()) : 0;
    const importeRecuento = this.recaudacio.importeRecuento !== null ? parseFloat(this.recaudacio.importeRecuento.toString()) : 0;

    this.recaudacio.diferenciaRecuentoTicket = importeRecuento - importeMonedaLegal;
  }

  //#endregion

}
