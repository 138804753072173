import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Paisos } from '../models/paisos.model';
import { Observable } from 'rxjs';
import { api } from 'path-shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { PaisosFilter } from '../models/paisos-filter';
import { PaisosFilterService } from './paisos-filter.service.ts.service';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';

@Injectable()
export class PaisosService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setPais(p: Paisos): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetPais'], p);
    }
  }

  removePais(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeletePais'].replace('{secure_id}', secure_id), null);
    }
  }

  getPais(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetPais'].replace('{secure_id}', secure_id), null);
    }
  }
  getPaisosCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
    /*  const filtre =  new HttpParams()
      .append('codi',  '')
      .append('descripcio', '')
      .append('paisActiu', '')
      .append('provCodificada', '')
      .append('sigles', '')
      .append('start', '0') 
      .append('size', '1000')
      .append('sortparam', 'descripcio')
      .append('sortdir', 'asc');*/
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetPaisosCombo'], null);
    }
  }

  getProvinciasCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetProvinciasCombo'], null);
    }
  }

  getCodigosPostales(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetCodigosPostales'], null);
    }
  }

  getProvinciasComboByPais(secureIdPais: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetProvinciasComboByPais'].replace('{secure_idPais}', secureIdPais) , null);
    }
  }

  getMunicipiosCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMunicipiosCombo'], null);
    }
  }

  GetMunicipiosComboByProv(secureIdProv: string): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMunicipiosComboByProv'].replace('{secure_idProv}', secureIdProv) , null);
    }
  }

  generateExcel(filter: PaisosFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new PaisosFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlPaisosExcel'],  params );
    }
  }
}
