import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { timer } from 'rxjs';
import { FormGroup, FormControl, NgControl } from '@angular/forms';
import { ValidationService } from 'path-shared/services/validation/validation.service';

@Component({
  selector: 'app-filter-text-edit',
  templateUrl: './filter-text-edit.component.html',
  styleUrls: ['./filter-text-edit.component.scss']
})
export class FilterTextEditComponent implements OnInit, AfterViewInit {
  val: string;
  inputVal: string;
  defaultComparsion: string;

  @Input() label: string;
  @Input() get value() {
    return this.val;
  } set value(val) {
    if (this.val !== val) {
      this.val = val;
      this.valueChange.emit(this.val);
    }
  }
  @Output() valueChange = new EventEmitter();
  @Output() keyEnter = new EventEmitter();
  @Input() class: string;
  @Input() type: string;
  @Input() disabled: boolean;
  @Input() autofocus;
  @Input() required: boolean;
  @Input() name: string;
  @Input() number: boolean;
  @Input() maxLength: number;
  @Input() matricula: boolean;
  @Input() equals: boolean;
  @Input() contains: boolean;
  @Input() comparsion: string;
  @Input() id: string;
  @ViewChild('inputEdit') private elementRef: ElementRef;

  constructor(
    public validator: ValidationService,
   ) {
    this.val = null;
    this.disabled = false;
    this.required = false;
    this.equals = true;
    this.contains = false;
    this.number = false;
    this.matricula = false;
    this.comparsion = null;
  }

  ngOnInit() {
    this.setDefaultComparsion();
  }

  ngAfterViewInit() {
    if (this.contains !== false) {
      timer(20).subscribe(() => {
        this.setDefaultComparsion();
      });
    }
    if (this.autofocus !== undefined) {
      timer(500).subscribe(() => {
        this.elementRef.nativeElement.focus();
      });
    }
  }

  valid(): boolean {
    if (this.elementRef.nativeElement.className.indexOf('ng-invalid') === -1) {
      return !this.errorRequired() && !this.errorNumber() && !this.errorLength() && !this.errorMatricula();
    } else {
      return false;
    }
  }

  isRequired() {
    return this.required !== false;
  }

  isRequiredNumber() {
    if (this.required) {
      return this.number !== false;
    } else {
      return false;
    }
  }

  isRequiredMatricula() {
    return this.matricula !== false;
  }

  hasMaxLength() {
    return this.validator.isNatural(this.maxLength.toString());
  }

  errorRequired() {
    return this.isRequired() && (this.value === null || this.value.length === 0);
  }

  errorNumber() {

    if (!this.isRequiredNumber() ) {
        return false;
    } else if ( this.isRequiredNumber() && this.value != null && this.value.length > 0 && this.validator.isNumeric(this.value) ) {
      return false;
    } else if ( this.isRequiredNumber() && (this.value === null || this.value.length === 0 ) ) {
      return true;
    } else {
      return true;
    }
    // return this.isRequiredNumber() && !this.validator.isNumeric(this.value);
  }

  errorMatricula() {
    return this.isRequiredMatricula() && !this.validator.isMatricula(this.value);
  }

  errorLength() {
    if (this.maxLength === undefined) {
      return false;
    } else {
      return !this.validator.checkLength(this.value, this.maxLength);
    }
  }

  setDefaultComparsion(): any {
    if (this.comparsion === null || this.comparsion === undefined) {
      this.defaultComparsion = '%';
    } else {
      this.defaultComparsion = this.comparsion;
    }
  }

  onEnter() {
    this.keyEnter.emit(true);
  }

}
