<app-dialog-header [label]="this.file_name"></app-dialog-header>
<app-toolbar *ngIf="botoMatricula">
  <app-tool-button text="Matrícula" i18n-text (click)="this.saveMatricula()" color="has-color-blue" icon="fa-car"></app-tool-button>
</app-toolbar>

<div class="row" *ngIf="this.proposta !== undefined">  
    <div style="margin-left:45%; padding-bottom: 10px">
       <app-matricula  [matricula]="this.proposta.MATRICULA" [vehicle]="this.proposta.VEHICLE" [pais]="this.proposta.pais" [sugPais]="this.proposta.sugPaises" [sugMatricula]="this.proposta.sugMatricules" [activarSugerencias]="true" (canvioPais)="this.setPais($event)" (canvioMatricula)="this.setMatricula($event)"></app-matricula>
       <i class="fas fa-pen click-icon" (click)="this.saveMatricula()" i18n-title title="Modificar" style="margin-left: 10px; cursor:pointer; margin-top: auto; margin-bottom: auto;"></i>
   </div>
</div>

<div #imageDiv id="imageDiv" class="modal-body text-center">
  <!-- <img #img [class]="this.imgClass" (click)="close()" [src]="this.image_source" /> -->
  <img #img *ngIf="this.proposta === undefined && !this.pdf" [class]="this.imgClass" [src]="this.image_source" (click)="close()"/>
</div>

<div *ngIf="this.pdf">        
  <div class="pdf" style="text-align: center;">
      <!-- <embed *ngIf="this.pdf" [atrr.src]="this.fileSource" type="application/pdf" width="420px" height="350px"> -->
      <div *ngIf="innerHtml"
          [innerHTML]="innerHtml">
      </div>
      <a *ngIf="this.pdf"[href]="this.image_source" target="_blank"i18n><br>Obrir PDF en una pestanya nova</a>


  </div>
  
</div>
