export class ProgressBar {
    value: number;
    text: string;
    type: string;

    constructor(type, value, text ) {
        this.value = value;
        this.text = text;
        this.type = type;
    }
}