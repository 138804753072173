import { PropostaAnulacioDto } from './../../models/proposta-anulacio.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PropostesAnulacioService } from '../../services/propostes-anulacio.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';

@Component({
  selector: 'app-propostes-anulacio-editor',
  templateUrl: './propostes-anulacio-editor.component.html',
  styleUrls: ['./propostes-anulacio-editor.component.scss']
})
export class PropostesAnulacioEditorComponent implements OnInit {
  propuestaAnulacion: PropostaAnulacioDto;
  dialogData: ComponentDialog;
  constructor( 
    @Inject(MAT_DIALOG_DATA) public data: any,
  private i18n: I18n,
  private propostesAnulacioService: PropostesAnulacioService, 
  private dialog: MatDialog,
  public thisDialogRef: MatDialogRef<PropostesAnulacioEditorComponent>) {
    if ( data.propuestaAnulacion !== null ) {
     
      this.propuestaAnulacion = data.propuestaAnulacion;
    }
    this.dialogData = data;
    
  }

  ngOnInit() {
  }
  isEditMode():boolean{
    return this.propuestaAnulacion.fechaAcceptacionDenegacion===undefined || this.propuestaAnulacion.fechaAcceptacionDenegacion===null;
  }
  acceptProposta(){
    this.propuestaAnulacion.aceptada=true;
    this.propostesAnulacioService.updateProposta(this.propuestaAnulacion).subscribe(x =>{
      this.dialog.closeAll();
    });
  }
  rejectProposta(){
    this.propuestaAnulacion.aceptada=false;
    this.propostesAnulacioService.updateProposta(this.propuestaAnulacion).subscribe(x =>{
      this.dialog.closeAll();
    });
  }
}
