import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class MotiusDesestimarFilter extends FilterData {
  id: number;
  descripcio: string;
  canviMatricula: boolean;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.id = 0;
    this.descripcio = '';
    this.canviMatricula = null;

    this.labels['id'] = this.i18n('Id');
    this.labels['descripcio'] = this.i18n('Descripció');
    this.labels['canviMatricula'] = this.i18n('Canvi Matrícula');
  }
}
