<app-dialog-header label="Agent" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = !this.dialogData.isReadOnly" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Agents) || !this.dialogData.isReadOnly" *ngIf="this.dialogData.isReadOnly && this.editable"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Agents) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Agents) || this.dialogData.isReadOnly || !this.dialogData.editable"></app-tool-button>
  <app-tool-button text="Fitxers" i18n-text (click)="this.fitxers();" color="has-color-blue" icon="fas fa-file-archive" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Agents) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="agents-editor-codi" name="codi" autofocus class="col-sm-4" i18n-label label="Codi" [(value)]="this.agent.codi" [disabled]="this.dialogData.isReadOnly" required number maxLength="8"></app-form-text-edit>
      <app-form-text-edit id="agents-editor-categoria" class="col-sm-4" i18n-label label="Categoria" [(value)]="this.agent.categoria" [disabled]="this.dialogData.isReadOnly" required maxLength="50"></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-text-edit id="agents-editor-nom" name="nom" class="col-sm-4" i18n-label label="Nom" [(value)]="this.agent.nom" [disabled]="this.dialogData.isReadOnly" required maxLength="20"></app-form-text-edit>
      <app-form-text-edit id="agents-editor-cognom1" class="col-sm-4" i18n-label label="Cognom 1" [(value)]="this.agent.cognom1" [disabled]="this.dialogData.isReadOnly" maxLength="25"></app-form-text-edit>
      <app-form-text-edit id="agents-editor-cognom2" class="col-sm-4" i18n-label label="Cognom 2" [(value)]="this.agent.cognom2" [disabled]="this.dialogData.isReadOnly" maxLength="25"></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-text-edit id="agents-editor-dni" class="col-sm-2" i18n-label label="DNI" [(value)]="this.agent.dni" [disabled]="this.dialogData.isReadOnly" maxLength="15"></app-form-text-edit>
      <app-form-text-edit id="agents-editor-lletraDni" class="col-sm-2" i18n-label label="Lletra DNI" [(value)]="this.agent.letraDni" [disabled]="this.dialogData.isReadOnly" maxLength="1"></app-form-text-edit>
    </div>
    <div class="row">
      <app-calendar-multiselect-input class="col-sm-4" i18n-label label="Dies festius" [(value)]="this.agent.diasFestivos" (valueChange)="this.diasFestivosChange($event)"></app-calendar-multiselect-input>
      <app-form-date-edit id="agents-editor-dataAlta" class="col-sm-4" i18n-label label="Data Alta" [(value)]="this.agent.fechaInicio" type="calendar"></app-form-date-edit>
      <app-form-date-edit class="col-sm-4" id="agents-editor-dataBaixa" i18n-label label="Data Baixa" [(value)]="this.agent.fechaFin" type="calendar"></app-form-date-edit>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <app-form-tri-state label="Obsolet" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-label labelTodos="Tots" [(value)]="this.agent.obsolet" triStateDisabled="true" [disabled]="this.dialogData.isReadOnly" required  ></app-form-tri-state>
        <app-form-tri-state  label="Zona Blava" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-label labelTodos="Tots" [(value)]="this.agent.zonaBlava" triStateDisabled="true" [disabled]="this.dialogData.isReadOnly"></app-form-tri-state>
        <app-form-tri-state label="Agent PDA" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-label labelTodos="Tots" [(value)]="this.agent.agentPDA" triStateDisabled="true" [disabled]="this.dialogData.isReadOnly"></app-form-tri-state>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-form-check-edit class="col-xl-2" i18n-label label="Agent OCR" [(value)]="this.agent.agentOCR" [disabled]="this.dialogData.isReadOnly"></app-form-check-edit>
        <app-form-check-edit class="col-xl-2" i18n-label label="Agent Grua" [(value)]="this.agent.agentGrua" [disabled]="this.dialogData.isReadOnly"></app-form-check-edit>
        <div class="row">
          <app-form-text-edit  id="agents-editor-ref"class="col-4" i18n-label label="Referencia Integración" [(value)]="this.agent.referenciaIntegracion" [disabled]="this.dialogData.isReadOnly" maxLength="50"></app-form-text-edit>
          <app-form-text-edit  id="agents-editor-parameter-diba"class="col-3" i18n-label label="Paràmetre DIBA" [(value)]="this.agent.parameterDiba" [disabled]="this.dialogData.isReadOnly" maxLength="1"></app-form-text-edit>  
        </div>
        <!--<app-form-file-upload label="Fitxer" i18n-label labelHidden="true" (fileReady)="fileReady($event)"></app-form-file-upload>-->
        <!-- <app-form-single-toggle class="col-2" i18n-label label="Reset Password" [(value)]="this.resetPasswordRequired" *ngIf="!this.dialogData.isReadOnly"></app-form-single-toggle> -->
        <app-form-check-edit class="col-2" i18n-label label="Reset Password" [(value)]="this.resetPasswordRequired" *ngIf="!this.dialogData.isReadOnly"></app-form-check-edit>
      </div>
    </div>      
    <div class="alert alert-primary important" role="alert" [hidden]="!this.resetPasswordRequired" >Guarda per restablir la contrasenya</div>
  </form>
</app-dialog-body>
