import { HttpParams } from '@angular/common/http';
import { GenericFileFilter } from './../../../../shared/components/generic-file-displayer/model/generic-file.filter';
import { GenericFileFilterService } from './../../../../shared/components/generic-file-displayer/services/generic-file-filter.service';
import { config } from './../../../../shared/configuration/config';
import { SenalVertical } from './../models/senal-vertical';
import { SenalesVerticalesFilterService } from './senales-verticales-filter-service';
import { SenalesVerticalesFilter } from './../models/senales-verticales.filter';
import { Injectable } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Observable } from 'rxjs/Observable';
import { api } from 'path-shared/configuration/urls';
import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { GenericFile } from 'path-shared/components/generic-file-displayer/model/generic-file';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';

@Injectable()
export class SenalesVerticalesService implements ExcelGenerator {
    displayedItems = [];

    constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
        this.displayedItems['checkedValues'] = 0;
        this.displayedItems['checkedItems'] = Array();
    }

    getSenalVertical(secureId: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetSenalVertical'].replace('{secureId}', secureId), null);
        }
    }

    setSenalVertical(senalVertical: SenalVertical): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetSenalVertical'], senalVertical);
        }
    }

    deleteSenalVertical(secureId: string): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteSenalVertical'].replace('{secureId}', secureId), null);
        }
    }

    deleteSenalFicheros(ids: string[]): Observable<any> {
        // const httpParams = new SenalesVerticalesFilterService().createFilesFilterParams(ids);
        if (this.authGuard.canActivate()) {
            return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteFilesSenalVertical']
            .replace('{ids}', JSON.stringify(ids)), null);
        }
    }

    uploadSenalFichero(file: GenericFile): Observable<any> {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlPostFileSenalVertical'], file);
        }
    }

    generateExcel(filter: SenalesVerticalesFilter, sortDir: string, sortField: string): Observable<any> {
        const start = 0;
        const pageSize = 1000;

        sortField = sortField === undefined ? defaultSortParam : sortField;
        sortDir = sortDir === '' ? defaultSortDirection : sortDir;
        const params = new SenalesVerticalesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

        if (this.authGuard.canActivate()) {
          return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlSenalesVerticalesExcel'],  params );
        }
    }

    getFicherosSenal(id: number): Observable<any> {

        const sortField = 'id';
        const sortdir = 'desc';
        const start = 0;
        const pageSize = 1000;

        const filter = new GenericFileFilter(sortField, sortdir);
        filter.idRegistroAsociado = id.toString();
        filter.eliminado = false.toString();
        const params = new GenericFileFilterService().createFilterParams(filter, start, pageSize, sortField, sortdir);

        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetFicherosSenalvertical'], params);
        }
    }

}
