<div class="row">
  <div class="col-8" *ngIf="this.label!=undefined || this.isRequired()">
    <label [ngClass]="[this.disabled ? 'disabled' : '']">{{this.label}}</label>
    <span class="required" [ngClass]="[!this.valid() ? 'invalid' : '']" *ngIf="this.isRequired()">*</span>
  </div>
  <div class="col-2">
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value="{{this.selected}}">
      <mat-button-toggle value="option1" (click)="clickedOption('option1', true)" [disabled]="this.disabled">{{this.labelSi}}</mat-button-toggle>
      <mat-button-toggle value="option2" (click)="clickedOption('option2', false)" [disabled]="this.disabled">{{this.labelNo}}</mat-button-toggle>
      <mat-button-toggle *ngIf="!this.triStateDisabled" value="option3" (click)="clickedOption('option3', null)" [disabled]="this.disabled">{{this.labelTodos}}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<div class="row" [hidden]="this.valid()" class="text-danger alert-field-errors">
  <div [hidden]="!this.errorRequired()" i18n>Camp obligatori!</div>
</div>

