import { OnInit } from '@angular/core';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { BaseChartDirective } from 'ng2-charts';
import { EstadistiquesService } from '../../services/estadistiques.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
var EstadistiquesReservesAparcareComponent = /** @class */ (function () {
    function EstadistiquesReservesAparcareComponent(estadistiquesService, i18n, auth, not) {
        this.estadistiquesService = estadistiquesService;
        this.i18n = i18n;
        this.auth = auth;
        this.not = not;
        // Preseleccionamos el dia de hoy.
        this.dateSelected = new Date();
    }
    EstadistiquesReservesAparcareComponent.prototype.ngOnInit = function () {
        this.createChart();
    };
    EstadistiquesReservesAparcareComponent.prototype.ngAfterViewInit = function () { };
    EstadistiquesReservesAparcareComponent.prototype.createChart = function () {
        this.barChartType = 'bar';
        this.barChartLegend = true;
        this.barChartOptions = {
            responsive: true,
            scales: { xAxes: [{ ticks: { autoSkip: false } }], yAxes: [{}] },
            plugins: {
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                }
            }
        };
    };
    EstadistiquesReservesAparcareComponent.prototype.onDateChange = function () {
        // Tenemos otro valor de fecha. Debemos volver a descargar la info de estadisticas.
        if (this.dateSelected !== this.oldDateSelected) {
            this.oldDateSelected = this.dateSelected;
            this.getEstadisticasReservas();
        }
    };
    EstadistiquesReservesAparcareComponent.prototype.getEstadisticasReservas = function () {
        var _this = this;
        if (this.dateSelected) {
            // Creamos el filtro con las fechas correspondientes.
            var fechaInicio = new Date(this.dateSelected);
            fechaInicio.setDate(fechaInicio.getDate() - fechaInicio.getDay() + 1);
            var fechaFin = new Date(this.dateSelected);
            fechaFin.setDate(fechaFin.getDate() - fechaFin.getDay() + 6 + 1);
            this.filterFechas = {
                fechaInicioConsulta: fechaInicio,
                fechaFinConsulta: fechaFin
            };
            // Realizamos la llamada para obtener la informacion.
            this.estadistiquesService.getEstadistiquesAparcareReserves(this.filterFechas).subscribe(function (result) {
                var _a;
                var arrayLabels = [];
                var arrayCoches = [];
                var arrayMotos = [];
                var arrayPersonas = [];
                var data = eval(result);
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        arrayCoches.push(data[key].CochesOcupados);
                        arrayMotos.push(data[key].MotosOcupados);
                        arrayPersonas.push(data[key].PersonasOcupados);
                        arrayLabels.push(new Date(key).toLocaleDateString('es-ES'));
                    }
                }
                if (!_this.barChartLabels) {
                    _this.barChartLabels = [];
                }
                _this.barChartLabels.length = 0;
                (_a = _this.barChartLabels).push.apply(_a, Object.values(arrayLabels));
                _this.barChartData = [];
                _this.barChartData.push({
                    data: arrayCoches, label: _this.i18n('Cotxes')
                });
                _this.barChartData.push({
                    data: arrayMotos, label: _this.i18n('Motos')
                });
                _this.barChartData.push({
                    data: arrayPersonas, label: _this.i18n('Persones')
                });
            });
        }
    };
    return EstadistiquesReservesAparcareComponent;
}());
export { EstadistiquesReservesAparcareComponent };
