<app-filter-header title="Filtrar textes" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <app-filter-text-edit id="texto-informativo-filter-descripcio" class="col-sm-6" i18n-label label="Descripció" [(value)]="filter.descripcion"></app-filter-text-edit>
    <app-form-date-edit id="texto-informativo-filter-dataIni" class="col-sm-6" i18n-label label="Data Inici" [(value)]="this.filter.fechaInicio"></app-form-date-edit>
    
    </div>
  <div class="row">
    <app-combo-box-input class="col-sm-3" #comboSiglas i18n-label [label]="'Idioma'" [showingOptions]="this.idiomaDictionary" 
                         (optionChanged)="this.siglaSeleccionada($event)" [(value)]="this.filter.idioma">
    </app-combo-box-input>
    <div class="col-sm-3"></div>
    <app-form-date-edit id="texto-informativo-filter-dataFi" class="col-sm-6" i18n-label label="Data Fi" [(value)]="this.filter.fechaFin"></app-form-date-edit>
  </div>
</div>
