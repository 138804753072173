/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./actuacionsmatricula-table-component.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i3 from "../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i4 from "../../../../shared/services/validation/validation.service";
import * as i5 from "../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i6 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i7 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i8 from "../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i9 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i10 from "./actuacionsmatricula-table-component.component";
import * as i11 from "../../../../shared/services/filter/filter.service";
import * as i12 from "@angular/material/dialog";
var styles_ActuacionsmatriculaTableComponentComponent = [i0.styles];
var RenderType_ActuacionsmatriculaTableComponentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActuacionsmatriculaTableComponentComponent, data: {} });
export { RenderType_ActuacionsmatriculaTableComponentComponent as RenderType_ActuacionsmatriculaTableComponentComponent };
export function View_ActuacionsmatriculaTableComponentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "container"], ["style", "display: flex; flex-direction: column; width: 100%;margin-bottom: 30px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["style", "display: flex; flex-direction: row; justify-content: center; gap: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-form-text-edit", [["class", "col-xl-2"], ["label", "Matr\u00EDcula"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.matricula = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormTextEditComponent_0, i2.RenderType_FormTextEditComponent)), i1.ɵdid(3, 4308992, null, 0, i3.FormTextEditComponent, [i4.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(4, 16777216, null, null, 3, "app-form-date-edit", [["label", "Des de"], ["type", "calendar"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.datainici = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FormDateEditComponent_0, i5.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i6.DateTimeAdapter, i7.MomentDateTimeAdapter, [i6.OWL_DATE_TIME_LOCALE]), i1.ɵdid(6, 114688, null, 0, i8.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], type: [2, "type"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i9.OWL_DATE_TIME_FORMATS, i8.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(8, 16777216, null, null, 3, "app-form-date-edit", [["label", "Fins"], ["type", "calendar"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.datafi = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FormDateEditComponent_0, i5.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i6.DateTimeAdapter, i7.MomentDateTimeAdapter, [i6.OWL_DATE_TIME_LOCALE]), i1.ɵdid(10, 114688, null, 0, i8.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], type: [2, "type"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i9.OWL_DATE_TIME_FORMATS, i8.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["style", "vertical-align: middle; height: 30px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Consultar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Consultar"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Matr\u00EDcula"; var currVal_1 = _co.matricula; var currVal_2 = "col-xl-2"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Des de"; var currVal_4 = _co.datainici; var currVal_5 = "calendar"; _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = "Fins"; var currVal_7 = _co.datafi; var currVal_8 = "calendar"; _ck(_v, 10, 0, currVal_6, currVal_7, currVal_8); }, null); }
export function View_ActuacionsmatriculaTableComponentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-actuacionsmatricula-table-component", [], null, null, null, View_ActuacionsmatriculaTableComponentComponent_0, RenderType_ActuacionsmatriculaTableComponentComponent)), i1.ɵdid(1, 4308992, null, 0, i10.ActuacionsmatriculaTableComponentComponent, [i11.FilterService, i12.MAT_DIALOG_DATA, i12.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActuacionsmatriculaTableComponentComponentNgFactory = i1.ɵccf("app-actuacionsmatricula-table-component", i10.ActuacionsmatriculaTableComponentComponent, View_ActuacionsmatriculaTableComponentComponent_Host_0, {}, {}, []);
export { ActuacionsmatriculaTableComponentComponentNgFactory as ActuacionsmatriculaTableComponentComponentNgFactory };
