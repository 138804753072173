import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { BancCarreguesC60Filter } from '../../models/banc-carregues-c60-filter';

@Component({
  selector: 'app-banc-carregues-c60-filter',
  templateUrl: './banc-carregues-c60-filter.component.html',
  styleUrls: ['./banc-carregues-c60-filter.component.scss']
})
export class BancCarreguesC60FilterComponent implements OnInit {

  filter: BancCarreguesC60Filter;
  situacioCombo = [
    {text: '', value: null},
    {text: this.i18n('Definit'), value: 'D'},
    {text: this.i18n('Finalitzat'), value: 'F'},
    {text: this.i18n('Finalitzat correcte'), value: 'P'},
    {text: this.i18n('Error'), value: 'E'}
  ];

  @Output() notify: EventEmitter<BancCarreguesC60Filter> = new EventEmitter<BancCarreguesC60Filter>();

  constructor(
    public thisDialogRef: MatDialogRef<BancCarreguesC60FilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter;
  }

  ngOnInit() {
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
