import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { ZbHorario } from '../models/zb-horarios';
import { ZbHorariosFilter } from './zb-horarios-filter';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ZbHorariosFilterService } from './zb-horarios-filter-service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ZbHorariosService } from '../services/zb-horarios.service';
import { ZbHorarioEditorComponent } from '../zb-horario-editor/zb-horario-editor.component';

@Component({
  selector: 'app-zb-horarios-table',
  templateUrl: './zb-horarios-table.component.html',
  styleUrls: ['./zb-horarios-table.component.scss']
})
export class ZbHorariosTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort:MatSort;

  dataSource: CustomDataSource<ZbHorario,ZbHorariosFilter>;
  filter: ZbHorariosFilter= new ZbHorariosFilter('Nombre','asc');
  displayedColumns = ['Nombre','Descripcion','Horas','actions'];
  
  constructor(
    private dialog:MatDialog,
    private auth: AuthorizationService,
    private zbHorariosService:ZbHorariosService
  ) { }
  
  ngAfterViewInit():void{
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort,this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }
  load():void{
    this.filter.paginator.set(this.sort,this.paginator);
    this.dataSource.loadFilter(this.filter);
  }
  ngOnInit() {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<ZbHorario, ZbHorariosFilter>(this.paginator, new ZbHorariosFilterService(), 'urlGetZbHorarios', 'urlGetZbHorariosCount');
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        console.log(item);
      });
    });
    
    this.filter.paginator.init(this.sort, this.paginator);

  this.filter.filterUpdated.subscribe(updated => {
    if (updated) {
      this.load();
    }
  });
  }

  editHorario(row){
    debugger;
    const ZbHorario:ZbHorario=row;
    const dialogRef = this.dialog.open(ZbHorarioEditorComponent, {
      data: ZbHorario
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.load();
      }
    });
  }

}
