import { Component, OnInit, EventEmitter, Output, Inject, OnDestroy } from '@angular/core';
import { SiglasFilter } from '../../models/siglas-filter';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-siglas-filter',
  templateUrl: './siglas-filter.component.html',
  styleUrls: ['./siglas-filter.component.scss']
})
export class SiglasFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: SiglasFilter;

  @Output() notify: EventEmitter<SiglasFilter> = new EventEmitter<SiglasFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<SiglasFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
