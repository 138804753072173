import { GoogleMapsService } from './../../../shared/services/startup/google-maps.service';
import { AuthGuardService } from './../../../shared/services/authentication/auth-guard.service';
import { Component, OnInit, ViewChild, Input, SimpleChanges, ChangeDetectorRef, OnChanges, AfterViewInit } from '@angular/core';
import { Instituciones } from 'path-shared/models/instituciones';
import { Avis} from './models/avis.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AppInjectorModule } from 'app/app-injector/app-injector.module';
import { TravelMarker, TravelMarkerOptions, TravelData, TravelEvents, EventType } from 'travel-marker';
import { Parametros } from 'path-shared/models/parametros';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { CFG_PARAMETROS } from 'path-models/CFG_PARAMETROS';
import { ParameterDto } from 'path-shared/services/parameters/model/ParametroDto';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { AvisosService } from './services/avisos.service';
import { AvisosFilter } from './models/avisos-filter';
import { PageEvent, MatDialog, MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { AvisosFilterService } from './models/avisos-filter-service';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { AvisosFilterComponent } from './components/avisos-filter/avisos-filter.component';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { ToastrService } from 'ngx-toastr';


declare var google: any;
declare var require: any;


@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.scss']
})
export class AvisosComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() updatedFilterData: AvisosFilter;
  @Input() selectButton: boolean;
  public currentLocation: {
    latitude: number,
    longitude: number
  };


  public colors  = [ require('./resources/red-circle-lv.png'),
                    require('./resources/purple-circle-lv.png'),
                    require('./resources/grn-circle-lv.png'),
                    require('./resources/wht-circle-lv.png'),
                    require('./resources/blu-circle-lv.png')];
  public icons  = [ require('./resources/red-blank.png'),
                    require('./resources/purple-blank.png'),
                    require('./resources/grn-blank.png'),
                    require('./resources/wht-blank.png'),
                    require('./resources/blu-blank.png')];

  public zoom: number;

  mapaActiu: boolean;
  marker: TravelMarker = null;
  map: any;

  speedMultiplier = 1;

  displayedColumns = [ 'carpeta', 'descTipus', 'data' , 'hora', 'observacio', 'observacio2', 'agent', 'PDA'];

  displayedColumns2 = [ 'nomFitxer'];

  protected i18n: I18n;
  public avisos: Avis[];
  dataSource: CustomDataSource<Avis, AvisosFilter>;
  dataSource2 = new MatTableDataSource<string>();

  fitxers = new Array<string>();

  private idInst: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  highlightedRows = [];
  public timeOut: number;
  public refresc: number;
  _timeOut: number;
  _refresc: number;

  // dataActual: Date;

  filter: AvisosFilter = new AvisosFilter('data', 'desc');

  allSelected = false;
  displayedItems = [];

  constructor(
    private avisosService: AvisosService,
    private parametersService: ParametersService,
    private not: NotificationService,
    public auth: AuthorizationService,
    private filterService: FilterService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private authGuard: AuthGuardService,
    private googleMapsService: GoogleMapsService) {
    const inst = sessionStorage.getItem('currentInst');
    if (inst !== '' && inst !== undefined && inst !== 'undefined') {
      const institution: Instituciones = JSON.parse(inst);
      this.currentLocation = {latitude: institution.Latitud, longitude: institution.Longitud};
      this.idInst =  Number.parseInt(institution.IdInstitucion);
    }
    const parmSess: CFG_PARAMETROS[] = JSON.parse(sessionStorage.getItem('parameters'));
    if (parmSess !== undefined && parmSess !== null && parmSess.length > 0 && parmSess[0] !== null && parmSess[0] !== undefined) {
      this.idInst = parmSess[0].CODINSTIT;
    }
    this.i18n = AppInjectorModule.injector.get(I18n);
    this.selectButton = false;
    this.getParameters();
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    if (this.selectButton) {
      this.displayedColumns = ['incorpora'].concat(this.displayedColumns);
    }

    this.sort.disableClear = true;

    this.dataSource = CustomDataSource.create<Avis, AvisosFilter>(this.paginator, new AvisosFilterService, 'urlAvisos', 'urlGetAvisosPaginationCount');
    let pos = 0;
    this.dataSource.subject.subscribe((data) => {
      this.avisos = data;
      data.forEach(item => {
        this.avisosService.displayedItems[item.id] = {
          id: item.id,
          checked: false
        };
        item.Selected = false;
            if (pos < 5 ) {
              item.ColorHexa =  this.colors[pos];
              item.IconMarker = this.icons[pos];
            } else {
              pos = pos - 5;
            item.ColorHexa = this.colors[ pos];
            item.IconMarker = this.icons[pos];
            }
            pos++;

      });
      this.displayedItems = this.avisosService.displayedItems;
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('avisos-filter', this.filter); }
      }
    });

    if (!this.selectButton) { this.filterService.loadFilter('avisos-filter', this.filter); }
    this.filter.paginator.init(this.sort, this.paginator);

  }

  ngAfterViewInit() {

    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Avisos'));
    }

    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.update();
    }
  }

  update() {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load() {

    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }


  getFitxer(row: any) {
    this.avisosService.getFitxer(row.m_Item1).subscribe(file => {
      // const name: number = Date.now();
      const name = row.m_Item2.split('\\').pop();
      const contentType = file.headers.get('Content-Type');
      if (contentType !== null && contentType !== undefined) {
          FileDownloader.downLoadFile([file.body],  contentType + ';charset=utf-8', name);
      } else {
        this.toastr.error(this.i18n('No s\'ha trobat el fitxer'));
      }
    });
  }

  generateExcel(): void {
    let filter = this.filter;
    if (this.updatedFilterData != null) {
      filter = this.updatedFilterData;
    }
    this.dialog.open(DownloadExcelDialogComponent, {
        closeOnNavigation: false,
        autoFocus: true,
        data: Builder.newDownloadExcelDialogData (this.avisosService, filter, this.i18n('Avisos'), this.sort.direction, this.sort.active)
    });
  }


  onMapReady(map: any) {
    this.map = map;
  }


  getParameters() {

    this.refresc = Number.parseInt( this.parametersService.getParameter(Parametros.GPSTRACKING));
    this.timeOut = Number.parseInt( this.parametersService.getParameter(Parametros.GPSTIMEOUT));
    this.zoom = Number.parseInt(this.parametersService.getParameter(Parametros.GPSZOMINICIAL));
    this._refresc = Number.parseInt( this.parametersService.getParameter(Parametros.GPSTRACKING));
    this._timeOut = Number.parseInt( this.parametersService.getParameter(Parametros.GPSTIMEOUT));
    this.mapaActiu = this.parametersService.getParameter('AVISOSMAPA') === 'Y';
  }
  canviTimeOut(e: any) {

    if (this.timeOut !== this._timeOut ) {
      const time: ParameterDto = {
        CODINSTIT: this.idInst,
        DESCRIPCION: 'GPSTIMEOUT',
        PARAMETRO: 'GPSTIMEOUT',
        VALOR: this.timeOut.toString()
      };
      this.parametersService.setParameter(time).subscribe( result => {
      });
      this._timeOut = this.timeOut;
    }
  }
  canviRefresc(e: any) {

    if (this.refresc !== this._refresc ) {
      const time: ParameterDto = {
        CODINSTIT: this.idInst,
        DESCRIPCION: 'GPSTRACKING',
        PARAMETRO: 'GPSTRACKING',
        VALOR: this.refresc.toString()
      };
      this.parametersService.setParameter(time).subscribe( result => {
      });
      this._refresc = this.refresc;
    }
  }



  onMouseOver(infoWindow, gm) {

    if (gm.lastOpen && gm.lastOpen.isOpen) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }
  mouseOut(infoWindow, gm) {

    infoWindow.close();
  }

  onFilterClicked() {
    const data: ComponentDialog = Builder.newComponentDialog();
    const ref = this.dialog.open(AvisosFilterComponent, {
      width: '50%',

      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.filter,
      }
    });
  }

  SeleccionarTotes() {
    this.dataSource.getData().forEach(row => {
      row.Selected = this.allSelected;
    });
  }

  SeleccionarFilaAux(row: any) {
    row.Selected = !row.Selected;
    this.SeleccionarFila(row);
  }

  ClickCheckbox(row: any) {
    row.Selected = !row.Selected;
  }
  SeleccionarFila(row: any) {

    this.currentLocation.latitude = row.latitud;
    this.currentLocation.longitude = row.longitud;

    this.avisos.forEach(a => {
      if (a.id !== row.id) {
        a.Selected = false;
      }
    });
    this.fitxers = [];

    const avisIndex = this.avisos.findIndex(x => x.id === row.id);

    let numChecked = this.avisos.filter(x => x.Selected === true);

    this.avisosService.getFitxers(this.avisos[avisIndex].id).subscribe(data => {

      if (this.avisos[avisIndex].Selected === true) {
        data.forEach(element => {
          element.m_Item2 = element.m_Item2.split('\\').pop();
          this.fitxers.push(element);
        });
      } else {
        data.forEach(element => {
          element.m_Item2 = element.m_Item2.split('\\').pop();
          this.fitxers.splice(element, 1);

        });
      }
      numChecked = this.avisos.filter(x => x.Selected === true);
      if (numChecked.length === 1) {
        this.dataSource2.data = this.fitxers;
      } else {
        this.dataSource2.data = [];
      }
      if (numChecked.length === this.avisos.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    });
  }
}
