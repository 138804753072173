<div class="btn-group dropleft">
  <div class="dropdown">
    <button
      class="btn btn-sm btn-outline-secondary dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ this.operator }}
    </button>
    <div class="dropdown-menu text-sm" aria-labelledby="dropdownMenuButton">
      <a
        class="dropdown-item"
        (click)="this.setValue('=')"
        [ngClass]="[this.operator === '=' ? 'active' : '']"
        *ngIf="this.type === Types.TEXT"
        ><span class="badge badge-sm">=</span><span i18n>Igual</span></a
      >
      <a
        class="dropdown-item"
        (click)="this.setValue('%')"
        [ngClass]="[this.operator === '%' ? 'active' : '']"
        *ngIf="this.type === Types.TEXT"
        ><span class="badge badge-sm">%</span><span i18n>Conté</span></a
      >
      <a
        class="dropdown-item"
        (click)="this.setValue('!=')"
        [ngClass]="[this.operator === '!=' ? 'active' : '']"
        *ngIf="this.type === Types.TEXT"
        ><span class="badge badge-sm">!=</span><span i18n>Diferent</span></a
      >
      <a
        class="dropdown-item"
        (click)="this.setValue('!%')"
        [ngClass]="[this.operator === '!%' ? 'active' : '']"
        *ngIf="this.type === Types.TEXT"
        ><span class="badge badge-sm">!%</span><span i18n>No conté</span></a
      >
      <a
        class="dropdown-item"
        (click)="this.setValue('=')"
        [ngClass]="[this.operator === '=' ? 'active' : '']"
        *ngIf="this.type === Types.NUMERO || this.type === Types.DATA"
        ><span class="badge badge-sm">=</span><span i18n>Igual</span></a
      >
      <a
        class="dropdown-item"
        (click)="this.setValue('>')"
        [ngClass]="[this.operator === '>' ? 'active' : '']"
        *ngIf="this.type === Types.NUMERO || this.type === Types.DATA"
        ><span class="badge badge-sm">></span><span i18n>Major</span></a
      >
      <a
        class="dropdown-item"
        (click)="this.setValue('>=')"
        [ngClass]="[this.operator === '>=' ? 'active' : '']"
        *ngIf="this.type === Types.NUMERO || this.type === Types.DATA"
        ><span class="badge badge-sm">>=</span
        ><span i18n>Major o igual</span></a
      >
      <a
        class="dropdown-item"
        (click)="this.setValue('<')"
        [ngClass]="[this.operator === '<' ? 'active' : '']"
        *ngIf="this.type === Types.NUMERO || this.type === Types.DATA"
        ><span class="badge badge-sm"><</span><span i18n>Menor</span></a
      >
      <a
        class="dropdown-item"
        (click)="this.setValue('<=')"
        [ngClass]="[this.operator === '<=' ? 'active' : '']"
        *ngIf="this.type === Types.NUMERO || this.type === Types.DATA"
        ><span class="badge badge-sm"><=</span>
        <span i18n>Menor o igual</span></a
      >
    </div>
  </div>
</div>
