<mat-card>
  <mat-card-subtitle title i18n>Denuncias de tráfico</mat-card-subtitle>
  <mat-card-content>
    <div class="row">
      <app-form-date-edit *ngIf="this.teDescompte" class="col-6" i18n-label label="Fecha límite descuento" [(value)]="this.denunciesNotificarFiltre.fechaPrimeraNotificacion">
      </app-form-date-edit>

      <app-form-date-edit class="col-6" i18n-label label="Fecha límite sin descuento" [(value)]="this.denunciesNotificarFiltre.fechaSegundaNotificacion">
      </app-form-date-edit>
    </div>
  </mat-card-content>


  <mat-card-subtitle title i18n>Denuncias de convivencia</mat-card-subtitle>
  <mat-card-content>
    <div class="row">
      <app-form-date-edit *ngIf="this.teDescompte" class="col-6" i18n-label label="Fecha límite descuento" [(value)]="this.denunciesNotificarFiltre.fechaTerceraNotificacion">
      </app-form-date-edit>
    
      <app-form-date-edit class="col-6" i18n-label label="Fecha límite sin descuento" [(value)]="this.denunciesNotificarFiltre.fechaCuartaNotificacion"></app-form-date-edit>
    </div>
  </mat-card-content>

  <mat-card-content>
    <div class="row">
      <div class="col text-center">
        <button mat-stroked-button (click)="this.apply()">Continuar</button>
      </div>
    </div> 
  </mat-card-content>
</mat-card>
