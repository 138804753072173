import { Component, Input, OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DenunciaBOPDto } from '../../../models/envio-boe-denuncia.model';

@Component({
  selector: 'app-envio-boe-table',
  templateUrl: './envio-boe-table.component.html',
  styleUrls: ['./envio-boe-table.component.scss']
})
export class EnvioBoeTableComponent implements OnInit {
  displayedColumns = ['expedient','tipusAnuncio', 'data','hora','article','llei','import','lloc', 'infraccio'];
 // @Input()  dataSource: DenunciaBOPDto[];
  dataSource: DenunciaBOPDto[];
  @Input() get value() {
    return this.dataSource;
  } set value(val) {
    this.dataSource = val;
  }
  
  constructor(private i18n: I18n) { }

  ngOnInit() {
  }
  filtrar2(){
    let a='';
  }
  getTipolabel(id:number):string{
    
    switch (id) {
      case 1: {
        return this.i18n('Denuncia');
      }
      case 2: {
        return this.i18n('Alegación');
      }
      case 3: {
        return this.i18n('Sanción firme');
      }
      case 4: {
        return this.i18n('Recurso');
      }
      case 0: {
        return this.i18n('Ejecutiva');
      }      
      default: {
        return '';  
      }
    }
  }
}
