
<app-toolbar>  
  <app-tool-button-permissions [permission]="this.auth.Pages.AlarmPDA"></app-tool-button-permissions>
  <app-tool-button text="Configuración" i18n-text icon="fa-cog" color="has-color-grey" (click)="openModal()"></app-tool-button>
</app-toolbar>

<div class="row" style="height: 100%">
    <div class="col-5 map-icon" style="max-height: 80vh;">
      <agm-map  id="map" [latitude]="currentLocation.latitude" [longitude]="currentLocation.longitude" [zoom]="zoom"  >
          <!--<agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>-->
          <agm-marker *ngFor="let point of mapPoints;" [latitude]="point.latitude" [longitude]="point.longitude" [animation]="point.animation" >
          </agm-marker>
        </agm-map>
  </div>
  <div class="col-7" style="padding-right: 0px;">
    <div class="row">
      <div [className]="showTable ? 'col-xl-2' : 'col-xl-2'"   >
        <app-form-date-edit id="visor-alarmes-data" i18n-label label="Data" [(value)]="this.dataActual" type="calendar" (valueChange)="onDateChange()"></app-form-date-edit>  
  
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12"   *ngIf="showTable">
          <div  >
            <div style="height: 65vh;overflow: auto;"> 
                <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements"   *ngIf="showTable" >
                    <!-- [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir"-->
                            <ng-container matColumnDef="incorpora" >
                                <mat-header-cell *matHeaderCellDef title="Incorporar" i18n-title class="text-center"><i class="fa fa-plus-square has-color-grey"></i></mat-header-cell>
                                <mat-cell *matCellDef="let row"  ><mat-checkbox #checkbox [(ngModel)]="row.selected" (change)="this.Checked(row)"  ></mat-checkbox></mat-cell>
                            </ng-container>
                        
                              <ng-container matColumnDef="Smartphone">
                                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Smartphone</mat-header-cell>
                                <mat-cell *matCellDef="let row">{{row.Text}}</mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="Agente">
                                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Agent</mat-header-cell>
                                <mat-cell *matCellDef="let row">{{row.codigoAgente}}</mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="Data">
                                <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data</mat-header-cell>
                                <mat-cell *matCellDef="let row">{{row.Data | date: 'dd/MM/yyyy HH:mm'}}</mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="accion">
                                <mat-header-cell *matHeaderCellDef i18n>Acción</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                  <!-- <button class="btn btn-outline-primary btn-xs" *ngIf="automatitzacio(row)" title="Ir a detalle" i18n-title (click)="openHistorico(row)"><i class="fa fa-location-arrow"></i></button> -->
                                  <i [hidden]="row.Estat === 0" class="fa fa-check-double" title="Leído" i18n-title></i>
                                  <button class="btn btn-outline-primary btn-xs" [hidden]="row.Estat === 1" title="Marcar com llegida" i18n-title (click)="AlarmRead(row.Id)"><i class="fa fa-check-double"></i></button>
                                </mat-cell>
                  
                              </ng-container>
                              
                              <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                              <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" ></mat-row><!--[ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"-->
                            
                            <!-- Group header -->
                            <!--<ng-container matColumnDef="groupHeader">
                              <td colspan="999" mat-cell *matCellDef="let groupBy"><strong> {{groupBy.tipusLabel}}</strong></td>
                            </ng-container>
                          
                            <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>
                            -->
                            </mat-table>
            </div>
          </div>
        </div>
    </div>
    <div class="empty-case" *ngIf="!showTable">
      <span class="empty-case__icon"><i class="fa fa-search"></i></span>
      <h4  class="empty-case__title" i18n>Sin resultados</h4>
      <p i18n>No hay alarmas para este día</p>
    </div>
    <!-- <div class="row col-xl-12"  style="position: absolute;bottom: 10vh;">
        <app-form-text-area class="col-lg-10" i18n-label label="Missatge"  maxLength="60"></app-form-text-area>
        
        <button class="btn btn-success offset-md-1 col-lg-2" type="button" >
            <i class="fa fa-check-circle"></i>&nbsp;<span i18n>Enviar</span>
          </button>
    </div> -->
  </div>
</div>