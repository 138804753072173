var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
var defaultSortParam = 'descripcion';
var defaultSortDirection = 'asc';
var TurnosFilterService = /** @class */ (function (_super) {
    __extends(TurnosFilterService, _super);
    function TurnosFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TurnosFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('descripcion', filter.descripcion)
            /*  .append('hora_inicio',  Reflection.empty(filter.hora_inicio) ? null :  typeof(filter.hora_inicio) === 'string' ?
              filter.hora_inicio : filter.hora_inicio.toString())
              .append('hora_fin',  Reflection.empty(filter.hora_fin) ? null :  typeof(filter.hora_fin) === 'string' ?
              filter.hora_fin : filter.hora_fin.toString())*/
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return TurnosFilterService;
}(CustomFilterService));
export { TurnosFilterService };
