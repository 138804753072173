var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var LegislacioFilter = /** @class */ (function (_super) {
    __extends(LegislacioFilter, _super);
    function LegislacioFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.codi = '';
        _this.descripcio = '';
        _this.obsoleta = null;
        _this.trafic = null;
        _this.mesesSLInici = null;
        _this.mesesSLFi = null;
        _this.mesesSGInici = null;
        _this.mesesSGFi = null;
        _this.mesesSMGInici = null;
        _this.mesesSMGFi = null;
        _this.labels['codi'] = _this.i18n('Codi');
        _this.labels['descripcio'] = _this.i18n('Descripció');
        _this.labels['obsoleta'] = _this.i18n('Obsoleta');
        _this.labels['trafic'] = _this.i18n('Trànsit');
        _this.labels['mesesSLInici'] = _this.i18n('Mesos SL inici');
        _this.labels['mesesSLFi'] = _this.i18n('Mesos SL fi');
        _this.labels['mesesSGInici'] = _this.i18n('Mesos SG inici');
        _this.labels['mesesSGFi'] = _this.i18n('Mesos SG fi');
        _this.labels['mesesSMGInici'] = _this.i18n('Mesos SMG inici');
        _this.labels['mesesSMGFi'] = _this.i18n('Mesos SMG fi');
        return _this;
    }
    return LegislacioFilter;
}(FilterData));
export { LegislacioFilter };
