var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var TransformDataPipe = /** @class */ (function (_super) {
    __extends(TransformDataPipe, _super);
    function TransformDataPipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TransformDataPipe.prototype.transform = function (value, args) {
        var data = value.toString();
        return new Date(parseInt(data.substr(0, 4)), parseInt(data.substr(4, 2)) - 1, parseInt(data.substr(6, 2)));
    };
    return TransformDataPipe;
}(DatePipe));
export { TransformDataPipe };
