<app-filter-header title="Filtrar persones" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
     <app-filter-text-edit id="persones-filter-id" class="col-sm-6" i18n-label label="Id" [(value)]="filter.id"></app-filter-text-edit>
     <app-filter-text-edit id="persones-filter-nom"  class="col-sm-6" i18n-label label="Nom" [(value)]="filter.nomComplet"></app-filter-text-edit>
  </div>
  <div class="row">
    <app-filter-text-edit id="persones-filter-dataNaix" class="col-sm-6" i18n-label label="Data naixament" [(value)]="filter.data_naixament"></app-filter-text-edit>
    <app-filter-text-edit id="persones-filter-dni" class="col-sm-6" i18n-label label="DNI" [(value)]="filter.dni_pasp"></app-filter-text-edit>
  </div>
  
  <div class="row">
    <app-filter-text-edit id="persones-filter-nomPare" class="col-sm-6" i18n-label label="Nom pare" [(value)]="filter.nom_pare"></app-filter-text-edit>
    <app-filter-text-edit id="persones-filter-nomMare" class="col-sm-6" i18n-label label="Nom mare" [(value)]="filter.nom_mare"></app-filter-text-edit>
  </div>

  <div class="row">
    <div class="col-6">
      <app-form-tri-state label="Buscat" i18n-label i18n-labelSi labelSi="Si" i18n-labelNo labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.buscat"></app-form-tri-state>
      <app-form-tri-state label="Juridica" i18n-label i18n-labelSi labelSi="Si" i18n-labelNo labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.juridica"></app-form-tri-state>
      <app-form-tri-state label="Antecedents" i18n-label  i18n-labelSi labelSi="Si" i18n-labelNo labelNo = "No" i18n-labelTodos labelTodos="Tots" [(value)]="filter.antecedents"></app-form-tri-state>
    </div>
  </div>
</div>
