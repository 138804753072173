/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-tab-chat-dynamic.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "@angular/common";
import * as i10 from "../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i11 from "@angular/material/tabs";
import * as i12 from "./form-tab-chat-dynamic.component";
var styles_FormTabChatDynamicComponent = [i0.styles];
var RenderType_FormTabChatDynamicComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormTabChatDynamicComponent, data: {} });
export { RenderType_FormTabChatDynamicComponent as RenderType_FormTabChatDynamicComponent };
function View_FormTabChatDynamicComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " ", " "])), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "tab-dynamic-icon"], ["mat-icon-button", ""], ["style", "float:right;"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeTab(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(4, 638976, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["cancel"]))], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabLabel; var currVal_1 = _v.parent.context.$implicit.id; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 2).disabled || null); var currVal_3 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 4).inline; _ck(_v, 3, 0, currVal_4); }); }
function View_FormTabChatDynamicComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "is-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getFitxer(_v.context.$implicit.idFitxer) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.nomFitxer; _ck(_v, 3, 0, currVal_0); }); }
function View_FormTabChatDynamicComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "message sent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "saltLinia"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormTabChatDynamicComponent_5)), i1.ɵdid(4, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [["class", "metadata"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "time"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 2)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.fitxers; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.mensaje; _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.fechaHora, "dd/MM/yyyy HH:mm")); _ck(_v, 7, 0, currVal_2); }); }
function View_FormTabChatDynamicComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "is-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getFitxer(_v.context.$implicit.idFitxer) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.nomFitxer; _ck(_v, 3, 0, currVal_0); }); }
function View_FormTabChatDynamicComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "message received"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "saltLinia"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormTabChatDynamicComponent_7)), i1.ɵdid(4, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [["class", "metadata"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "time"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 2)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.fitxers; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.mensaje; _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.fechaHora, "dd/MM/yyyy HH:mm")); _ck(_v, 7, 0, currVal_2); }); }
function View_FormTabChatDynamicComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormTabChatDynamicComponent_4)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormTabChatDynamicComponent_6)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.isMe; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.isMe; _ck(_v, 4, 0, currVal_1); }, null); }
function View_FormTabChatDynamicComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 21, "mat-tab", [], null, null, null, i10.View_MatTab_0, i10.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i11.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(335544320, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FormTabChatDynamicComponent_2)), i1.ɵdid(5, 16384, [[2, 4]], 0, i11.MatTabLabel, [i1.TemplateRef, i1.ViewContainerRef], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 15, "div", [["class", "tab-dynamic-content chatbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "text-center clearBoth"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "btn btn-outline-primary btn-sm btn-lg btn-block"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.recuperaMissatgesNou() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-chevron-circle-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Recupera missatges nous"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormTabChatDynamicComponent_3)), i1.ɵdid(13, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "text-center clearBoth"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["class", "btn btn-outline-primary btn-sm btn-lg btn-block"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.recuperaMissatgesAntic() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "fa fa-chevron-circle-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Recupera missatges antics"])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "text-center clearBoth"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "span", [["class", "label label-block label-primary label-outlined"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No hi ha contingut per mostrar"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_2 = _v.context.$implicit.contingut; _ck(_v, 13, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = (_v.context.$implicit.pendentsLlegir === 0); _ck(_v, 7, 0, currVal_1); var currVal_3 = (_v.context.$implicit.contingut.length === 0); _ck(_v, 14, 0, currVal_3); var currVal_4 = (_v.context.$implicit.contingut.length > 0); _ck(_v, 19, 0, currVal_4); }); }
export function View_FormTabChatDynamicComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i9.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i9.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "hidden": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedIndexChange" === en)) {
        var pd_0 = (_co.selected.setValue($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatTabGroup_0, i10.RenderType_MatTabGroup)), i1.ɵdid(6, 3325952, null, 1, i11.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef], { selectedIndex: [0, "selectedIndex"] }, { selectedIndexChange: "selectedIndexChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormTabChatDynamicComponent_1)), i1.ɵdid(9, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, (!_co.tabs || (_co.tabs.length === 0))); _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.selected.value; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.tabs; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 6).dynamicHeight; var currVal_2 = (i1.ɵnov(_v, 6).headerPosition === "below"); _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_FormTabChatDynamicComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-tab-chat-dynamic", [], null, null, null, View_FormTabChatDynamicComponent_0, RenderType_FormTabChatDynamicComponent)), i1.ɵdid(1, 114688, null, 0, i12.FormTabChatDynamicComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormTabChatDynamicComponentNgFactory = i1.ɵccf("app-form-tab-chat-dynamic", i12.FormTabChatDynamicComponent, View_FormTabChatDynamicComponent_Host_0, { tabLabel: "tabLabel", tabs: "tabs", selected: "selected" }, { recuperaMissatgesAntics: "recuperaMissatgesAntics", recuperaMissatgesNous: "recuperaMissatgesNous", downloadFitxer: "downloadFitxer" }, []);
export { FormTabChatDynamicComponentNgFactory as FormTabChatDynamicComponentNgFactory };
