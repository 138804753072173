/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./change-password.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i4 from "../../../../shared/components/dialog/dialog-body/dialog-body.component.ngfactory";
import * as i5 from "../../../../shared/components/dialog/dialog-body/dialog-body.component";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i9 from "../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i10 from "../../../../shared/services/validation/validation.service";
import * as i11 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i12 from "@angular/material/icon";
import * as i13 from "@angular/material/tooltip";
import * as i14 from "@angular/cdk/overlay";
import * as i15 from "@angular/cdk/scrolling";
import * as i16 from "@angular/cdk/platform";
import * as i17 from "@angular/cdk/a11y";
import * as i18 from "@angular/cdk/bidi";
import * as i19 from "./change-password.component";
import * as i20 from "@angular/material/dialog";
import * as i21 from "../../../../shared/services/form/form.service";
import * as i22 from "ngx-toastr";
import * as i23 from "@ngx-translate/i18n-polyfill";
import * as i24 from "../../services/usuaris.service";
import * as i25 from "../../../../shared/services/parameters/parameters.service";
var styles_ChangePasswordComponent = [i0.styles];
var RenderType_ChangePasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChangePasswordComponent, data: {} });
export { RenderType_ChangePasswordComponent as RenderType_ChangePasswordComponent };
function View_ChangePasswordComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "margin:1vh; text-align:center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "hr", [["style", "width: 90%;"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 2, 0, currVal_0); }); }
export function View_ChangePasswordComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mainForm: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-dialog-header", [["icon", "fa-key"], ["label", "Canviar contrassenya"]], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"], icon: [1, "icon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 28, "app-dialog-body", [], null, null, null, i4.View_DialogBodyComponent_0, i4.RenderType_DialogBodyComponent)), i1.ɵdid(4, 114688, null, 0, i5.DialogBodyComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["mainForm", 1]], 0, 26, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i6.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(7, 4210688, null, 0, i6.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i6.ControlContainer, null, [i6.NgForm]), i1.ɵdid(9, 16384, null, 0, i6.NgControlStatusGroup, [[4, i6.ControlContainer]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 21, "div", [["class", "form-group card"], ["style", "margin-top: 1vh;padding-bottom: 1vh; padding-top: 1vh;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangePasswordComponent_1)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-form-text-edit", [["id", "change-password-antiga"], ["label", "Contrassenya antiga"], ["name", "Contrasenya antiga"], ["required", ""], ["type", "password"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.oldPwd = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormTextEditComponent_0, i8.RenderType_FormTextEditComponent)), i1.ɵdid(15, 4308992, null, 0, i9.FormTextEditComponent, [i10.ValidationService], { label: [0, "label"], required: [1, "required"], value: [2, "value"], type: [3, "type"], name: [4, "name"], id: [5, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(16, 0, null, null, 0, "hr", [["style", "width: 90%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-form-text-edit", [["id", "change-password-nova"], ["label", "Contrassenya nova"], ["name", "Contrasenya nova"], ["required", ""], ["type", "password"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.newPwd = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormTextEditComponent_0, i8.RenderType_FormTextEditComponent)), i1.ɵdid(19, 4308992, null, 0, i9.FormTextEditComponent, [i10.ValidationService], { label: [0, "label"], required: [1, "required"], value: [2, "value"], type: [3, "type"], name: [4, "name"], id: [5, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(20, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "password-strength-meter", [["class", "col-11"]], [[8, "password", 0]], null, null, null, null)), (_l()(), i1.ɵeld(22, 16777216, null, null, 3, "mat-icon", [["class", "col-1 info-icon mat-icon"], ["matTooltip", "Una contrassenya segura inclou lletres, n\u00FAmeros, i car\u00E0cters especials"], ["matTooltipClass", "info-tooltip"], ["role", "img"]], [[2, "mat-icon-inline", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 24)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 24)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(23, 638976, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null]], null, null), i1.ɵdid(24, 147456, null, 0, i13.MatTooltip, [i14.Overlay, i1.ElementRef, i15.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i16.Platform, i17.AriaDescriber, i17.FocusMonitor, i13.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i18.Directionality], [2, i13.MAT_TOOLTIP_DEFAULT_OPTIONS]], { message: [0, "message"], tooltipClass: [1, "tooltipClass"] }, null), (_l()(), i1.ɵted(-1, 0, ["info"])), (_l()(), i1.ɵeld(26, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "app-form-text-edit", [["id", "change-password-repetir"], ["label", "Repetir contrassenya"], ["name", "Repetir contrasenya"], ["required", ""], ["type", "password"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.repeatPwd = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormTextEditComponent_0, i8.RenderType_FormTextEditComponent)), i1.ɵdid(28, 4308992, null, 0, i9.FormTextEditComponent, [i10.ValidationService], { label: [0, "label"], required: [1, "required"], value: [2, "value"], type: [3, "type"], name: [4, "name"], id: [5, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(29, 0, null, null, 2, "div", [["style", "text-align: -webkit-center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Guardar"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Canviar contrassenya"; var currVal_1 = "fa-key"; _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 4, 0); var currVal_9 = (_co.message !== null); _ck(_v, 12, 0, currVal_9); var currVal_10 = "Contrassenya antiga"; var currVal_11 = ""; var currVal_12 = _co.oldPwd; var currVal_13 = "password"; var currVal_14 = "Contrasenya antiga"; var currVal_15 = "change-password-antiga"; _ck(_v, 15, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_16 = "Contrassenya nova"; var currVal_17 = ""; var currVal_18 = _co.newPwd; var currVal_19 = "password"; var currVal_20 = "Contrasenya nova"; var currVal_21 = "change-password-nova"; _ck(_v, 19, 0, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21); _ck(_v, 23, 0); var currVal_24 = "Una contrassenya segura inclou lletres, n\u00FAmeros, i car\u00E0cters especials"; var currVal_25 = "info-tooltip"; _ck(_v, 24, 0, currVal_24, currVal_25); var currVal_26 = "Repetir contrassenya"; var currVal_27 = ""; var currVal_28 = _co.repeatPwd; var currVal_29 = "password"; var currVal_30 = "Repetir contrasenya"; var currVal_31 = "change-password-repetir"; _ck(_v, 28, 0, currVal_26, currVal_27, currVal_28, currVal_29, currVal_30, currVal_31); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 9).ngClassValid; var currVal_7 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_22 = _co.newPwd; _ck(_v, 21, 0, currVal_22); var currVal_23 = i1.ɵnov(_v, 23).inline; _ck(_v, 22, 0, currVal_23); }); }
export function View_ChangePasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-change-password", [], null, null, null, View_ChangePasswordComponent_0, RenderType_ChangePasswordComponent)), i1.ɵdid(1, 114688, null, 0, i19.ChangePasswordComponent, [i20.MAT_DIALOG_DATA, i21.FormService, i22.ToastrService, i23.I18n, i24.UsuarisService, i20.MatDialogRef, i25.ParametersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChangePasswordComponentNgFactory = i1.ɵccf("app-change-password", i19.ChangePasswordComponent, View_ChangePasswordComponent_Host_0, {}, {}, []);
export { ChangePasswordComponentNgFactory as ChangePasswordComponentNgFactory };
