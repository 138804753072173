import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { TipusTiquetsFilter } from '../../models/tipus-tiquets-filter';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-tipus-tiquets-filter',
  templateUrl: './tipus-tiquets-filter.component.html',
  styleUrls: ['./tipus-tiquets-filter.component.scss']
})
export class TipusTiquetsFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  filter: TipusTiquetsFilter;

  @Output() notify: EventEmitter<TipusTiquetsFilter> = new EventEmitter<TipusTiquetsFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<TipusTiquetsFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }


}
