import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from '../../../shared/configuration/config';
import { api } from '../../../shared/configuration/urls';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EstadisticaSetmanal } from '../models/estadistica-setmanal.model';
import { DispositiuEstadistiques } from '../models/dispositiu-estadistica.model';
import { EstadistiquesReservesAparcareFilter } from '../models/estadistiques-reserves-aparcare-filter';

@Injectable({
  providedIn: 'root'
})
export class EstadistiquesService {

  constructor(
    private http: HttpSecureClient,
    private authGuard: AuthGuardService
  ) { }

  getAparells(dia: Date): Observable<DispositiuEstadistiques[]> {
    if ( this.authGuard.canActivate() ) {
      const params = new HttpParams()
        .append('Fecha', dia.toISOString());

      return this.http.get(
        config.urls['apiUrl'] + api.endpoints['urlGetAparellsEstadistiques'], params
      )
      .pipe(map( (res: any) => res as DispositiuEstadistiques[] ));
    }
  }

  getEstadistiquesSetmanals(aparell: string, dia: Date): Observable<EstadisticaSetmanal> {

    const params = new HttpParams()
      .append( 'IdAparato', aparell || '0' )
      .append( 'Fecha', dia.toISOString() );
    return this.http.get(
      config.urls['apiUrl'] + api.endpoints['urlGetEstadisticaSetmanal'], params
    ).pipe(map( r => r as EstadisticaSetmanal ));

  }

  getEstadistiquesMensuals(aparell: string, dia: Date) {

    const params = new HttpParams()
      .append( 'IdAparato', aparell || '0' )
      .append( 'Fecha', dia.toISOString() );
      return this.http.get(
        config.urls['apiUrl'] + api.endpoints['urlGetEstadisticaMensual'], params
      );

  }

  getEstadistiquesAparcareReserves(filter: EstadistiquesReservesAparcareFilter): Observable<any> {
    const params = new HttpParams()
    .append('fechaInicioConsulta', filter.fechaInicioConsulta ? this.formatDate(filter. fechaInicioConsulta) : '')
    .append('fechaFinConsulta', filter.fechaFinConsulta ? this.formatDate(filter.fechaFinConsulta) : '');

    return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasAparcareReservas'], params);
  }

  getEstadisticasReincidencias(reincidencias: string, fechaInicio: Date, fechaFin: Date, infraccion?: number): Observable<any> {
    const params = new HttpParams()
    .append('reincidencias', reincidencias)
    .append('fechaInicio', fechaInicio ? fechaInicio.toDateString() : '')
    .append('fechaFin', fechaFin ? fechaFin.toDateString() : '')
    .append('infraccion', infraccion ? infraccion.toString() : '');


    return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasReincidencias'], params);
  }

  formatDate(fecha: Date) {
    const d = new Date(fecha);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('/');
  }

  getEstadistiquesAparcare(filter: any): Observable<any>{
    
    const params = new HttpParams()
    .append('tipo', filter.tipo)
    .append('tipoValor', filter.tipoValor)
    .append('tipoTemporizacion', filter.tipoTemporizacion)
    .append('fecha', filter.fecha ? this.formatDate(filter.fecha) : '')
    .append('zonaSecureId', filter.zonaSecureId);

    return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasAparcare'], params);
  }
  getEstadistiquesDenuncias(filter: any): Observable<any>{
    
    const params = new HttpParams()
    .append('tipoTemporizacion', filter.tipoTemporizacion)
    .append('fecha', filter.fecha ? this.formatDate(filter.fecha) : '')
    .append('zonaSecureId', filter.zonaSecureId)
    .append('infraccion', filter.infraccion)
    .append('agente', filter.agente)
    .append('anulada', filter.anulada);

    return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasDenuncias'], params);
  }

  getEstadistiquesVarios(filter: any): Observable<any>{
    
    const params = new HttpParams()
    .append('tipoTemporizacion', filter.tipoTemporizacion)
    .append('fecha', filter.fecha ? this.formatDate(filter.fecha) : '')
    .append('aparell', filter.aparell)
    .append('tipo', filter.tipo)
    .append('agente', filter.agente)
    .append('estadoPropuesta', filter.estadoPropuesta);

    return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasVarios'], params);
  }

  generateExcelDenuncias(filter: any): Observable<any> {
    const params = new HttpParams()
    .append('tipoTemporizacion', filter.tipoTemporizacion)
    .append('fecha', filter.fecha ? this.formatDate(filter.fecha) : '')
    .append('zonaSecureId', filter.zonaSecureId)
    .append('agente', filter.agente)
    .append('anulada', filter.anulada)
    .append('infraccion', filter.infraccion);
    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasDenunciasExcel'],  params );
    }
  }
  generateExcelAparcare(filter: any): Observable<any> {
    const params = new HttpParams()
    .append('tipo', filter.tipo)
    .append('tipoValor', filter.tipoValor)
    .append('tipoTemporizacion', filter.tipoTemporizacion)
    .append('fecha', filter.fecha ? this.formatDate(filter.fecha) : '')
    .append('zonaSecureId', filter.zonaSecureId);
    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasAparcareExcel'],  params );
    }
  }
  generateExcel(filter: any): Observable<any> {

    if(filter.hasOwnProperty('agente')){
      return this.generateExcelDenuncias(filter);
    }else{
      return this.generateExcelAparcare(filter);
    }
  }
  getDatosAgentes(filter:any){
    const params = new HttpParams()
    .append('mensual', filter.mensual)
    .append('pdf', filter.pdf)
    .append('dataInici', filter.dataInici ? this.formatDate(filter.dataInici) : '')
    .append('dataFi', filter.dataFi ? this.formatDate(filter.dataFi) : '')
    .append('tipo', filter.tipo)
    .append('agentes', filter.agentes);
    return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGetEstadisticasAgents'],params);
  }
}
