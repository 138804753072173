import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { AparellService } from 'app/sections/configuracio/aparells/services/aparells-service.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { FormService } from 'path-shared/services/form/form.service';
import { CarrerDetail } from '../../models/carrerDetail.model';
import { CarrersService } from '../../services/carrers-service.service';
import { Builder } from 'path-shared/models/builder';
import { Siglas } from 'app/sections/configuracio/siglas/models/siglas.model';
import { ZonesService } from 'app/sections/ciutat/zones/services/zones.service';
import { ZonesModel } from 'app/sections/ciutat/zones/models/zones.model';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { PaisosService } from 'app/sections/configuracio/paisos/services/paisos.service.ts.service';
import { Dictionary } from 'path-shared/models/dictionary';
import { LoaderService } from 'path-shared/components/loader/loader.service';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { BehaviorSubject } from 'rxjs-compat';

@Component({
  selector: 'app-calle-editor',
  templateUrl: './calle-editor.component.html',
  styleUrls: ['./calle-editor.component.scss']
})
export class CalleEditorComponent implements OnInit {

  sigles = Array<Siglas>();
  secure_id: string;
  carrer: CarrerDetail;
  dialogData: ComponentDialog;
  closeButtonText: string;
  test1 = 0;
  test2 = 0;
  zones = Array<FormMultiSelect>();
  editable = false;
  municipiosDictionary: Dictionary[];
  valorAutoCompleteMunicipio;

  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild('combMunicipio') comboMunicipio: ComboBoxInputAutocompleteComponent;

  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private carrersService: CarrersService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<CalleEditorComponent>,
    private formService: FormService,
    private zonesService: ZonesService,
    private paisosService: PaisosService,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
        this.carrer = Builder.newCarrerDetail();
        this.dialogData = data;
        this.editable = data.editable;
        this.sigles = <Array<Siglas>> data.tag;
        this.secure_id = this.dialogData.id;
        this.valorAutoCompleteMunicipio = new BehaviorSubject<string>('');
        if (this.dialogData.isReadOnly) {
          this.closeButtonText = this.i18n('Tancar');
        } else {
          this.closeButtonText = this.i18n('Llista');
        }
   }

  ngOnInit() {

    if (this.secure_id !== null && this.secure_id !== undefined) {
      this.carrersService.getCarrer(this.secure_id).subscribe((data: CarrerDetail) => {
        this.carrer = data;
      });
    }
    this.getZones();
    this.getMunicipios();
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {
      this.carrersService.setCarrer(this.carrer).subscribe(result => {
        this.actualizarCacheCalles(this.carrer);
        this.toastr.info(this.i18n('Carrer guardat correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar el carrer?'))
    .then((confirmed) => {
        if (confirmed) {
          this.carrersService.removeCarrer(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Carrer esborrat correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
    });
  }

  getZones() {
    this.zonesService.getZones().subscribe(result => {
      const zones: Array<ZonesModel> = Object.assign([], result);
      this.zones = [];
      zones.forEach(zona => {

        if (this.carrer.zones.find(x => x.item_id === zona.idZona)) {
          this.zones.unshift(new FormMultiSelect(zona.idZona, zona.descripcio));
        } else {
          this.zones.push(new FormMultiSelect(zona.idZona, zona.descripcio));
        }
      });
    });
  }

  getMunicipios() {
    this.municipiosDictionary = new Array<Dictionary>();
    this.paisosService.getMunicipiosCombo().subscribe(newValue => {
      this.loaderService.show();

      newValue.forEach(element => {
      this.municipiosDictionary.push(new Dictionary(element.id, element.nom));
     });

     if (this.carrer.idMunicipi !== null && this.carrer.idMunicipi !== undefined) {
      this.comboMunicipio.setValueCombAux(this.municipiosDictionary.find(x => x.key === this.carrer.idMunicipi).value);
     }

     this.loaderService.hide();
   });
  }

  municipioSeleccionado(idMunicipio: string) {
    this.carrer.idMunicipi = idMunicipio;
  }

  actualizarCacheCalles(carrer: CarrerDetail) {
    if (sessionStorage.getItem('carrersForCombo')) {
      const json = sessionStorage.getItem('carrersForCombo');
      const carrersList: any[] = JSON.parse(json.toString());

      if (carrer.id !== undefined && carrer.id !== null) {
        const index = carrersList.findIndex(x => x.SECURE_ID === carrer.SECURE_ID);
        if (index !== -1) {
          carrersList[index].nomCarrer = carrer.nomCarrer;
          sessionStorage.setItem('carrersForCombo', JSON.stringify(carrersList));
        }
      } else {
        sessionStorage.removeItem('carrersForCombo');
      }
    }
  }

}
