import { DatePipe } from '@angular/common';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TipusMatriculesService } from 'app/sections/ciutat/tipus-matricules/services/tipus-matricules.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatSort } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { ZonesService } from '../../services/zones.service';
import { ZonesModel } from '../../models/zones.model';
import { TarifaService } from '../../services/tarifa-service';
import { TiketService } from '../../services/tiket-service.';
import { TransformDataPipe } from 'path-shared/pipes/transform-data.pipe';
import { ColorPickerService, Cmyk, Rgba } from 'ngx-color-picker';
import { Dictionary } from 'path-shared/models/dictionary';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { TemporadaDto } from 'app/sections/zona-blava/estacionament/temporada/models/temporadaDto';
import { TemporadaService } from 'app/sections/zona-blava/estacionament/temporada/services/temporada.service';

@Component({
  selector: 'app-zones-editar',
  templateUrl: './zones-editar.component.html',
  styleUrls: ['./zones-editar.component.scss']
})
export class ZonesEditarComponent implements OnInit {

  secure_id: string;
  zona: ZonesModel;
  noFinalDate: boolean;
  finalDate: Date;
  dialogData: any;
  closeButtonText: string;
  ticketSelected: string;
  tarifes = [];
  tickets = [];
  zones = [];
  tipusMatricules = [];
  allTipusMatricules = Array<FormMultiSelect>();


  public color1: string = '#2889e9';

  @ViewChild('mainForm') private mainForm: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


    //autocomplete combo Infracciones
    temporadas: TemporadaDto[];
    temporadasDictionary: Array<Dictionary>;
    valorAutoCompleteTemporada;
    @ViewChild('comboTemporada') comboTemporada: ComboBoxInputAutocompleteComponent;


  dataSource: any;

  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private zonaService: ZonesService,
    private ticketService: TiketService,
    private tarifaService: TarifaService,
    private zonesService: ZonesService,
    private tipusMatriculesService: TipusMatriculesService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    public dataTrans: TransformDataPipe,
    public thisDialogRef: MatDialogRef<ZonesEditarComponent>,
    private formService: FormService,
    private cpService: ColorPickerService,
    private temporadaService: TemporadaService,

    @Inject(MAT_DIALOG_DATA) public data,
    ) {
      this.zona = Builder.newZona();

      // this.tipo_tiket = Array();
      this.noFinalDate = true;
      this.dialogData = data;
      this.secure_id = this.dialogData.data.id;
      if (this.dialogData.isReadOnly) {
        this.closeButtonText = this.i18n('Tancar');
      } else {
        this.closeButtonText = this.i18n('Llista');
      }

      this.valorAutoCompleteTemporada = '';
    }

    ngOnInit() {

      this.getTipusMatricules();
      this.getTarifas();
      this.getTickets();
      this.getDetalles();
      this.getZones();
    }
    loadTemporadas() {
      this.temporadaService.GetAllForCombo().subscribe( result => {
        if (result !== null) {
          this.temporadas = result;
          this.temporadasDictionary = new Array<Dictionary>();
          result.forEach(element => {
            if(element.secureId !== undefined && element.secureId !== null &&
              element.descripcion !== undefined && element.descripcion !== null ){
                this.temporadasDictionary.push(new Dictionary( element.secureId, element.descripcion));
              }
          });
          this.setDefaultValueTemporada();
        }
      });
    }
    
  getAllTipusMatricules() {

    this.tipusMatriculesService.getAll().subscribe(result => {
      const tipMat: Array<ZonesModel> = result;
      this.allTipusMatricules = [];
      result.forEach(element => {

        if (this.zona.tiposMatriculas !== undefined && this.zona.tiposMatriculas !== null && this.zona.tiposMatriculas.find(x => x.item_id === element.codi.toString())) {
          this.allTipusMatricules.unshift(new FormMultiSelect(element.codi.toString(), element.descripcio));
        } else {
          this.allTipusMatricules.push(new FormMultiSelect(element.codi.toString(), element.descripcio));
        }

        //this.tipusMatricules.push({'codi': element.codi, 'descripcio': element.descripcio});
      });
    });
  }
    setDefaultValueTemporada(){
      if (this.zona.secureIdTemporada !== undefined && this.zona.secureIdTemporada  !== null && this.temporadasDictionary.length > 0 ) {
        const temporadaSelected = this.temporadasDictionary.find(x => x.key === this.zona.secureIdTemporada.toString());
        this.comboTemporada.setForcedValueComb(temporadaSelected.value);
      }
    }

    // temporadaSeleccionado(secureIdTemporada: string) {
    //   var res = this.temporadas.find(x => x.secureId === secureIdTemporada);
    //   if (res !== undefined && res !== null) {
    //     this.zona.temporada = res;
    //     this.zona.secureIdTemporada = res.secureId;
    //   }
    // }

  getTipusMatricules() {
    this.tipusMatriculesService.getAll().subscribe(result => {
      result.forEach(element => {
        this.tipusMatricules.push({'codi': element.codi, 'descripcio': element.descripcio});
      });
    });
  }

  getZones(){
    this.zonesService.getAllZonas().subscribe(result => {
      result.forEach(element=> {
        this.zones.push({'codi': element.idZona, 'descripcio': element.descripcio});
      });
    })
  }

  getTarifas() {
    this.tarifaService.getAll().subscribe(result => {
      result.forEach(element => {
        this.tarifes.push({'codi': element.SOM_ID_TARIFA, 'descripcio': element.DESC_TARIFA});
      });
    });
  }

  getTickets() {
    this.ticketService.getAll().subscribe(result => {
      result.forEach(element => {
        this.tickets.push({'codi': element.COD_TIPO, 'descripcio': element.DESC_TIPO});
      });
    });
  }


  getDetalles() {

    if (this.secure_id != null && this.secure_id !== '') {
      this.zonaService.detalleZones( this.secure_id ).subscribe(zona => {
        this.zona = zona;
        this.zona.fechaInici = this.dataTrans.transform(this.zona.fechaInici);
        this.zona.fechaFin = this.dataTrans.transform(this.zona.fechaFin);
        if ( this.zona.color !== undefined && this.zona.color !== null && this.zona.color !== ''){
          this.color1 = this.zona.color;
        }
        this.loadTemporadas();
        this.getAllTipusMatricules();
      });

    } else {
      this.loadTemporadas();
      this.getAllTipusMatricules();
    }

  }




  save() {
       this.zona.fechaInici = this.datepipe.transform( this.zona.fechaInici , 'yyyyMMdd000000');
       this.zona.fechaFin = this.datepipe.transform(  this.zona.fechaFin, 'yyyyMMdd000000');
    if (this.formService.allFieldsValid(this.mainForm)) {
        this.zonaService.setZones(this.zona).subscribe(() => {
        this.toastr.info(this.i18n('Zona guardada correctament'));
        this.thisDialogRef.close(true);

      });

    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }


  cancel() {
    this.thisDialogRef.close(false);
  }


  noFinalCheckChangd(event) {
    if (!event) {
     // this.zona.fechaFin = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    }
  }


  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la zona?'))
    .then((confirmed) => {
        if (confirmed) {
          // const saved = this.denunciesService.saveDenuncia(dataJson).subscribe();
          this.zonaService.deleteZones(this.zona.SECURE_ID).subscribe(result => {
            this.toastr.success(this.i18n('Zona esborrada correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

  
  public onChangeColor(color: string): Cmyk {
    let trueColor: Rgba = this.getRGB(color);
    //new Rgba (this.getRGB(color).red, this.getRGB(color).green, this.getRGB(color).blue, this.getRGB(color).opacity);
    // if(!color.startsWith('#')){

    //   this.zona.color = color;
    //   return this.cpService.rgbaToCmyk(color);
    // }else{
      // const hsva = this.cpService.stringToHsva(color);
      // this.zona.opacidad = hsva.a;
      // const rgba = this.cpService.hsvaToRgba(hsva);
      this.zona.color = color;//'(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')';
      return this.cpService.rgbaToCmyk(trueColor);
    //}
  }
 getRGB(str){
  var colorsOnly = str.substring(str.indexOf('(') + 1, str.lastIndexOf(')')).split(/,\s*/);
  var components: Rgba = new Rgba(colorsOnly[0], colorsOnly[1], colorsOnly[2], colorsOnly[3]) ;
  return components;
  // components.red = colorsOnly[0];
// components.green = colorsOnly[1];
// components.blue = colorsOnly[2];
// components.opacity = colorsOnly[3];

    // var match =  str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d*)?)\))?;
    // return match ? {
    //   red: match[1],
    //   green: match[2],
    //   blue: match[3],
    //   a: match[4]
    // } : {};
  }
}
