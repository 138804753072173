import { Dictionary } from 'path-shared/models/dictionary';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { Component, OnInit, ViewChild, AfterViewInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { TextoInformativo } from '../../model/texto-informativo';
import { TextoInformativoFilter } from '../../model/texto-informativo.filter';
import { TextoInformativoService } from '../../services/texto-informativo.service';
import { TextoInformativoFilterService } from '../../services/texto-informativo.filter.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-texto-informativo-table',
  templateUrl: './texto-informativo-table.component.html',
  styleUrls: ['./texto-informativo-table.component.scss']
})
export class TextoInformativoTableComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() updatedFilterData: TextoInformativoFilter;
  @Input() selectButton: boolean;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() siglasDictionary = new Array<Dictionary>();
  @Input() disableSelect: boolean = false;
  @Output() filterUpdated = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: CustomDataSource<TextoInformativo, TextoInformativoFilter>;
  filter: TextoInformativoFilter = new TextoInformativoFilter('id', 'asc');

  currentSelect: string;
  displayedItems = [];
  displayedColumns = ['select', 'descripcion', 'fechaInicio', 'fechaFin', 'idioma'];

  constructor(
    private textoInformativoService: TextoInformativoService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private i18n: I18n
  ) { }

  ngOnInit(): void {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<TextoInformativo, TextoInformativoFilter>(
      this.paginator,
      new TextoInformativoFilterService(),
      'urlGetTextosInformativos',
      'urlGetTextosInformativosCount');
    this.dataSource.subject.subscribe(data => {
      this.displayedItems = this.textoInformativoService.displayedItems;
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
        if (!this.selectButton) { this.filterService.saveFilter('texto-informativo-filter', this.filter); }
      }
    });
    if (!this.selectButton) {
      this.filterService.loadFilter('texto-informativo-filter', this.filter);
    }
    this.filter.paginator.init(this.sort, this.paginator);
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
      this.filterUpdated.emit(true);
    });
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter.updated()))
      .subscribe();
    this.load();
  }

  ngOnChanges(): void {
    if (this.updatedFilterData !== null) {
      this.update();
    }
  }

  update(): void {
    if (this.dataSource !== undefined) {
      this.updatedFilterData.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.updatedFilterData);
    }
  }

  load(): void {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  selectRow(row: any) {
    if (row.secureId !== this.currentSelect) {
      // Deseleccionamos los otros elementos. Solo podemos tener uno activado.
      this.dataSource.getData().forEach(element => {
        if (element.secureId !== row.secureId) {
          element.selected = false;
        }
      });
      this.currentSelect = row.secureId;
      this.itemSelected.next(Object.assign({}, row));
    } else {
      this.currentSelect = undefined;
      this.itemSelected.next(undefined);
    }
  }

  resetSelection() {
    this.dataSource.getData().forEach(element => {
      element.selected = false;
    });
    this.currentSelect = undefined;
  }

  getIdiomaFromSigla(sigla: string): string {
    const idioma = this.siglasDictionary.find(d => d.key === sigla);
    return idioma !== undefined ? idioma.value : '';
  }

}
