<app-toolbar>
  
    <app-tool-button text="Actualitzar" i18n-text (click)="this.update(false);" color="has-color-blue" icon="fa-sync" badge="new" [badgeActive]="this.novesPropostes" badgeColor='badge-warning'></app-tool-button>
    <app-tool-button text="Filtre" id="filtreDropDown" propagate=true i18n-text color="has-color-yellow" icon="fa-filter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></app-tool-button>
    <div class="dropdown-menu" aria-labelledby="#filtreDropDown">
      <app-tool-button text="Pendents" i18n-text (click)="this.veurePropostes(1);" color="has-color-grey" icon="fa-clock" [badge]="this.getCounter('1')" badgeActive="true"></app-tool-button>
      <app-tool-button text="Acceptades" i18n-text (click)="this.veurePropostes(0);" color="has-color-green" icon="fa-check" [badge]="this.getCounter('0')" badgeActive="true"></app-tool-button>
      <app-tool-button text="Desestimades" i18n-text (click)="this.veurePropostes(2);" color="has-color-red" icon="fa-times" [badge]="this.getCounter('2')" badgeActive="true"></app-tool-button>
    </div>
    <app-tool-button text="Llista" i18n-text (click)="veureLlistaPropostes();" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
    <app-tool-button *ngIf="ordre==='asc'" text="Antigues primer" i18n-text (click)="changeOrdre();" color="has-color-blue" icon="fa-sort-down"></app-tool-button>
    <app-tool-button *ngIf="ordre==='desc'" text="Noves primer" i18n-text (click)="changeOrdre();" color="has-color-blue" icon="fa-sort-up"></app-tool-button>
    <app-tool-button-permissions [permission]="this.auth.Pages.Propostes"></app-tool-button-permissions>
    <app-tool-button text="Anterior" i18n-text (click)="previousProposta();" color="has-color-aqua" icon="fa-chevron-left" [disabled]="!this.current"></app-tool-button>
    <app-tool-button [text]="getCurrentPropostaText()" disabled="true"></app-tool-button>
    <app-tool-button text="Següent" i18n-text (click)="nextProposta();" color="has-color-aqua" icon="fa-chevron-right" [disabled]="getPropostaPos() == this.totalPropostes" ></app-tool-button>  
  
    <app-chips-list-input #tipoPropuestaCombo style="display:inline-block; padding-left:10px; vertical-align: top;" i18n-label label="Tipus Proposta" 
      [valuesList]="this.tiposValidosSource.value" (valueChange)="this.tipoSeleccionado($event)">
    </app-chips-list-input>
  
    <app-app-chips-list-input *ngIf="false" #paisCombo style="display:inline-block; padding-left:10px; vertical-align: top;" i18n-label label="País" 
      [valuesList]="this.paisesValidosSource.value" (valueChange)="this.paisSeleccionado($event)">
    </app-app-chips-list-input>

</app-toolbar>
<div class="row justify-content-left">
 
  <div style="width: 100%;">
      <div *ngIf="this.data.length === 0 && this.filter.tipusproposta ==='-1'" >
        <div class="empty-case">
          <span class="empty-case__icon"><i class="fa fa-search"></i></span>
          <h4 class="empty-case__title" i18n>Seleccioni el tipus de proposta</h4>
          <p i18n>Per poder veure les propostes, primerament ha de seleccionar el tipus de proposta que vol validar</p>
        </div>
      </div>
      <app-proposta-viewer #propostaViewer *ngIf="this.currentProposta!== null && this.filter.tipusproposta!=='-1' && (imagesLoaded | async)"  [proposta]="this.currentProposta" (update)="this.update($event)"></app-proposta-viewer>

      <div *ngIf="this.data.length === 0 && this.filter.tipusproposta!=='-1'" >
        <div class="empty-case">
          <span class="empty-case__icon"><i class="fa fa-search"></i></span>
          <h4 class="empty-case__title" i18n>No hem trobat propostes!</h4>
          <p i18n>Sembla ser, que encara no hi ha cap proposta publicada. Si us plau, torna a intentar-ho més tard.</p>
        </div>
      </div>
  </div>
</div>
