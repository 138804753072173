import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AlarmesGpsService } from '../../services/alarmesGps.service';

@Component({
  selector: 'app-alarm-configuration',
  templateUrl: './alarm-configuration.component.html',
  styleUrls: ['./alarm-configuration.component.scss']
})
export class AlarmConfigurationComponent implements OnInit {

  emails: string;
  constructor (
    private alarmesGpsService: AlarmesGpsService, 
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<AlarmConfigurationComponent>,
  ) { }

  ngOnInit() {
    this.alarmesGpsService.getAlarmaEmail().subscribe(res=>{
      this.emails = res.toString();
    })
  }

  saveChanges() {
    this.alarmesGpsService.setAlarmaEmail(this.emails).subscribe(()=>{
      this.toastr.success('Configuración guardada correctamente');
      this.dialogRef.close();      
    })
  }
}
