var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from "path-shared/models/filter-data";
var ZbHorariosFilter = /** @class */ (function (_super) {
    __extends(ZbHorariosFilter, _super);
    function ZbHorariosFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.somId = null;
        _this.nombre = null;
        _this.descripcion = null;
        _this.labels['somId'] = _this.i18n('somId');
        _this.labels['nombre'] = _this.i18n('Nombre');
        _this.labels['description'] = _this.i18n('Descripcion');
        return _this;
    }
    return ZbHorariosFilter;
}(FilterData));
export { ZbHorariosFilter };
