<app-dialog-header label="Tipus Trams" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.TipusVehicles) ||!this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.TipusVehicles)||this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.TipusVehicles) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <!--<app-form-text-edit class="col-sm-3" i18n-label label="Codi" [(value)]="this.tramoTipo.Som_id" required maxLength="3" number></app-form-text-edit>-->
      <app-form-text-edit class="col-sm-6" i18n-label label="Descripcio" [(value)]="this.tramoTipo.descripcion" required maxLength="30"></app-form-text-edit>
      
        <!--<input class="col-sm-2"  #inputEdit  [style.background]="color1" [(colorPicker)]="color1" (colorPickerChange)="onChangeColor($event)"/>-->
        <div class="col-sm-2">
          <!-- <div class="input-group-prepend">
            <div class="input-group-text"> -->
              <input  class="form-check-input col-sm-2" style="color: transparent" [style.background]="color1" [(colorPicker)]="color1" (colorPickerChange)="onChangeColor($event)">  
            <!-- </div>
          </div> -->
          </div>
          
        
        
        <!-- <div class="form-group" >
          <mat-form-field #input>            
            <ng-container [style.background]="color1" >
              <input  matInput  [style.background]="color1" [(colorPicker)]="color1" (colorPickerChange)="onChangeColor($event)">
            </ng-container>
          </mat-form-field>
        </div> -->
    </div>
    
  </form>
</app-dialog-body>
