import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { GruaTecnics } from '../../models/grua-tecnics.model';
import { GruaTecnicsService } from '../../services/grua-tecnics.service';

@Component({
  selector: 'app-grua-tecnics-editor',
  templateUrl: './grua-tecnics-editor.component.html',
  styleUrls: ['./grua-tecnics-editor.component.scss']
})
export class GruaTecnicsEditorComponent implements OnInit {

  secure_id: string;
  gruaTecnics: GruaTecnics;
  dialogData: ComponentDialog;
  closeButtonText: string;

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private gruaTecnicsService: GruaTecnicsService,
    private i18n: I18n,
    private toastr: ToastrService,
    public thisDialogRef: MatDialogRef<GruaTecnicsEditorComponent>,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.gruaTecnics = Builder.newGruaTecnics();
      this.dialogData = data;
      this.secure_id = this.dialogData.id;
      if (this.dialogData.isReadOnly) {
        this.closeButtonText = this.i18n('Tancar');
      } else {
        this.closeButtonText = this.i18n('Llista');
      }
   }

  ngOnInit() {
    if (this.secure_id !== null) {
      this.gruaTecnicsService.getGruaTecnics(this.secure_id).subscribe(data => {
        this.gruaTecnics = data;
      });
    }
  }

  save() {
    if (this.formService.allFieldsValid(this.mainForm)) {

      this.gruaTecnicsService.setGruaTecnics(this.gruaTecnics).subscribe(result => {
        this.toastr.info(this.i18n('Tècnic guardat correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar el tècnic?'))
    .then((confirmed) => {
        if (confirmed) {
          this.gruaTecnicsService.removeGruaTecnics(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Tècnic esborrat correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }

}
