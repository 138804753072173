var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Builder } from 'path-shared/models/builder';
import { ElementRef, OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { MatDialogRef, MatDialog } from '@angular/material';
// import { DenunciaExpedientModel } from '../models/denuncia-expedient.model';
// import { DenunciaCobrarComponent } from '../components/denuncies-editor/components/denuncia-cobrar/denuncia-cobrar.component';
import { BehaviorSubject } from 'rxjs';
import { Dictionary } from 'path-shared/models/dictionary';
import { DenunciaPagamentParcialComponent } from '../../../denuncia-pagament-parcial/denuncia-pagament-parcial.component';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { FormService } from 'path-shared/services/form/form.service';
import { ToastrService } from 'ngx-toastr';
var DenunciaCobrarSmartPolComponent = /** @class */ (function () {
    function DenunciaCobrarSmartPolComponent(auth, thisDialogRef, denunciesService, toastr, i18n, formService, dialog, modalData) {
        this.auth = auth;
        this.thisDialogRef = thisDialogRef;
        this.denunciesService = denunciesService;
        this.toastr = toastr;
        this.i18n = i18n;
        this.formService = formService;
        this.dialog = dialog;
        this.modalData = modalData;
        this.dataMax = new Date();
        this.cargadaPrimeraVegada = false;
        this.denunciaDetail = modalData.data;
        this.cerrarPagosParciales = modalData.cerrarPagosParciales;
        var avui = new Date();
        this.denunciaCobrar = Builder.newDenunciaCobrar();
        this.denunciaCobrar.fecha = avui;
        this.denunciaCobrar.expedient = this.denunciaDetail.expedient;
        this.formasPagoDictionary = new BehaviorSubject(new Array());
        this.importeAumentoPorcentajes = new BehaviorSubject(new Array());
        this.importeInteresesPorcentajes = new BehaviorSubject(new Array());
    }
    DenunciaCobrarSmartPolComponent.prototype.ngOnInit = function () {
        this.loadFormasPago();
        this.loadImporteAumentoPorcentajes();
        this.loadImporteInteresesPorcentajes();
        //com que es fa un set del valor de data es criden ja a recalcularImportes()
        //this.calcularImporteBase();
        //this.calcularImporteTotal();
        if (this.denunciaDetail.importeCobrado > 0) {
            this.getPagos();
        }
    };
    DenunciaCobrarSmartPolComponent.prototype.Abs = function (number) {
        return number > 0 ? number : 0;
    };
    DenunciaCobrarSmartPolComponent.prototype.cancel = function () {
        this.thisDialogRef.close(false);
    };
    DenunciaCobrarSmartPolComponent.prototype.executeOperation = function () {
        var _this = this;
        var dto = {
            'FechaPago': this.denunciaCobrar.fecha,
            'FormaPago': this.denunciaCobrar.formaPagoId,
            'Importe': this.denunciaCobrar.importeCobrar,
            'Motivo': '',
            'importeInteresesPorcentaje': this.denunciaCobrar.importeInteresesPorcentaje,
            'importeAumentoPorcentaje': this.denunciaCobrar.importeAumentoPorcentaje
        };
        this.denunciesService.createOpearcio(this.denunciaDetail.secure_id_denuncia, 'COBRA_EXP', dto).subscribe(function () {
            _this.toastr.success(_this.i18n('Denuncia cobrada correctament'));
            _this.thisDialogRef.close(true);
        });
    };
    DenunciaCobrarSmartPolComponent.prototype.save = function () {
        // Sergio 11/11/2021 quitamos validacion no se pasa del importe por orden de jero: && this.denunciaCobrar.importeCobrar <= this.denunciaCobrar.importeMaximo
        if (this.formService.allFieldsValid(this.mainForm)) {
            if (this.TancarPagamentParcial()) {
                this.OpenModalTancarPagamentParcial();
            }
            else {
                // this.denunciesService.setDenunciaCobroSmartPol(this.denunciaCobrar).subscribe(() => {
                //   this.toastr.info(this.i18n('Denuncia cobrada correctament'));
                //   this.thisDialogRef.close(true);
                // });
                this.executeOperation();
            }
        }
        else {
            this.comboFormaPago.markAsTouched();
            this.comboImporteAumentoPorcentaje.markAsTouched();
            this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
            if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
                this.mainForm.nativeElement.classList.add('displayErrors');
            }
        }
    };
    DenunciaCobrarSmartPolComponent.prototype.TancarPagamentParcial = function () {
        var tancarPagamentParcial;
        if (this.denunciaDetail.fechaCobro === null && this.cerrarPagosParciales
            && this.denunciaCobrar.importeBase < this.denunciaCobrar.importeMaximo) {
            tancarPagamentParcial = true;
        }
        else {
            tancarPagamentParcial = false;
        }
        return tancarPagamentParcial;
    };
    DenunciaCobrarSmartPolComponent.prototype.OpenModalTancarPagamentParcial = function () {
        var _this = this;
        this.denunciaPagamentParcialRef = this.dialog.open(DenunciaPagamentParcialComponent, {
            height: '30%',
            width: '30%',
            closeOnNavigation: true,
            autoFocus: true,
            data: {
                'data': this.denunciaCobrar
            },
        });
        this.denunciaPagamentParcialRef.afterClosed().subscribe(function (denunciaCobrar) {
            if (denunciaCobrar !== undefined && denunciaCobrar !== null) {
                // this.denunciesService.setDenunciaCobrar(this.denunciaCobrar).subscribe(() => {
                //   this.toastr.info(this.i18n('Denuncia cobrada correctament'));
                //   this.thisDialogRef.close(true);
                // });
                _this.executeOperation();
            }
        });
    };
    DenunciaCobrarSmartPolComponent.prototype.loadFormasPago = function () {
        var _this = this;
        var formasPagoAux = new Array();
        this.denunciesService.getFormasPago().subscribe(function (formasPago) {
            formasPago.forEach(function (formaPago) {
                formasPagoAux.push(new Dictionary(formaPago.codi, formaPago.descripcio));
            });
            _this.formasPagoDictionary.next(formasPagoAux);
        });
    };
    DenunciaCobrarSmartPolComponent.prototype.loadImporteAumentoPorcentajes = function () {
        var _this = this;
        var importeAumentoPorcentajesAux = new Array();
        this.denunciesService.getDenunciesPagoPorcentajeAumento().subscribe(function (porcentajes) {
            porcentajes.forEach(function (porcentaje) {
                importeAumentoPorcentajesAux.push(new Dictionary(porcentaje.percentatge, porcentaje.descripcio));
            });
            _this.importeAumentoPorcentajes.next(importeAumentoPorcentajesAux);
            _this.comboImporteAumentoPorcentaje.setSelect(_this.denunciaCobrar.importeAumentoPorcentaje);
        });
    };
    DenunciaCobrarSmartPolComponent.prototype.loadImporteInteresesPorcentajes = function () {
        // this.importeInteresesPorcentajes.next();
    };
    DenunciaCobrarSmartPolComponent.prototype.formaPagoSeleccionado = function (formaPagoId) {
        this.denunciaCobrar.formaPagoId = formaPagoId;
    };
    /*
      Si la data de descompte és igual o superior a avui, aplicarem l'import amb descompte.
      Altrament, aplicarem l'import sense descompte.
    */
    DenunciaCobrarSmartPolComponent.prototype.calcularImporteBase = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    DenunciaCobrarSmartPolComponent.prototype.calcularImporteTotal = function () {
        this.denunciaCobrar.importeTotal = this.denunciaCobrar.importeBase
            + this.denunciaCobrar.importeAumento
            + this.denunciaCobrar.importeIntereses
            + this.denunciaCobrar.importeAnadido;
        this.denunciaCobrar.importeCobrar = this.denunciaCobrar.importeCobrar < 0 ? this.denunciaCobrar.importeCobrar = 0 : this.denunciaCobrar.importeCobrar;
    };
    DenunciaCobrarSmartPolComponent.prototype.recalcularImportes = function () {
        debugger;
        if (this.auxDate === undefined || this.auxDate === null || this.auxDate !== this.denunciaCobrar.fecha) {
            this.auxDate = this.denunciaCobrar.fecha;
            this.calcularImporteBase();
            this.calcularImporteTotal();
        }
    };
    DenunciaCobrarSmartPolComponent.prototype.aumentoPorcentajeChange = function (porcentaje) {
        this.denunciaCobrar.importeAumentoPorcentaje = porcentaje;
        this.denunciaCobrar.importeAumento = this.denunciaCobrar.importeBase * (porcentaje / 100);
        this.calcularImporteTotal();
    };
    DenunciaCobrarSmartPolComponent.prototype.getPagos = function () {
        var _this = this;
        this.denunciesService.getCobros(this.denunciaCobrar.expedient).subscribe(function (res) {
            _this.aumentoPorcentajeChange(res[0].Aumento);
        });
    };
    //#region SUPPORT METHODS
    DenunciaCobrarSmartPolComponent.prototype.stringToDate = function (value) {
        if (!this.isDate(value)) {
            value = new Date(value);
        }
        return value;
    };
    DenunciaCobrarSmartPolComponent.prototype.isDate = function (value) {
        return value instanceof Date;
    };
    return DenunciaCobrarSmartPolComponent;
}());
export { DenunciaCobrarSmartPolComponent };
