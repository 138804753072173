import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AlarmesFilterService } from './alarmes-filter.service';
import { AlarmaFilter } from '../models/alarma-filter';
import { Reflection } from 'path-shared/services/reflection/reflection';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../shared/services/authentication/auth-guard.service";
var defaultSortParam = 'data';
var defaultSortDirection = 'desc';
var AlarmesService = /** @class */ (function () {
    function AlarmesService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
        this.displayedItems = [];
        this.displayedItems['checkedValues'] = 0;
        this.displayedItems['checkedItems'] = Array();
    }
    AlarmesService.prototype.getAll = function () {
        if (this.authGuard.canActivate()) {
            var params = new AlarmesFilterService().createFilterParams(new AlarmaFilter('data', 'desc'), 0, 99999, 'data', 'desc');
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAlarmes'], params);
        }
    };
    AlarmesService.prototype.getByAparell = function (secureId) {
        if (this.authGuard.canActivate()) {
            secureId = secureId.replace('/', '$');
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAlarmesByAparell'].replace('{secureIdAparell}', secureId), null);
        }
    };
    AlarmesService.prototype.getGroups = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAlarmsGroup'], null, true);
        }
    };
    AlarmesService.prototype.getTipus = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAlarmsTipus'], null, true);
        }
    };
    AlarmesService.prototype.ackAlarma = function (secure_id) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlAckAlarma'].replace('{secure_id}', secure_id), null);
        }
    };
    AlarmesService.prototype.generateExcel = function (filter, sortDir, sortField) {
        var start = 0;
        var pageSize = 1000;
        sortField = sortField === undefined ? defaultSortParam : sortField;
        sortDir = sortDir === '' ? defaultSortDirection : sortDir;
        var params = new AlarmesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);
        if (this.authGuard.canActivate()) {
            return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlAlarmesExcel'], params);
        }
    };
    AlarmesService.prototype.ackAlarms = function (alarms) {
        if (this.authGuard.canActivate()) {
            var idList_1 = [];
            alarms.forEach(function (alarm) {
                if (Reflection.isNull(alarm.usuari)) {
                    idList_1.push(alarm.secure_id);
                }
            });
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlAckAlarms'], idList_1);
        }
    };
    AlarmesService.ngInjectableDef = i0.defineInjectable({ factory: function AlarmesService_Factory() { return new AlarmesService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: AlarmesService, providedIn: "root" });
    return AlarmesService;
}());
export { AlarmesService };
