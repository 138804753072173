<div class="row justify-content-between">
  <div class="col">
      <app-filter-selector [filter]="this.filter"></app-filter-selector>
  </div>
  <div class="col">
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="this.filter.paginator.pageSize" [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">
  
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements"  class="is-header-sticky">

      <!-- codi Column -->
      <ng-container matColumnDef="lote">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Lote</mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="onRowClicked(row)" class="is-link cursor detalle">{{row.lote}}</a></mat-cell>
      </ng-container>

      <!-- data Column -->
      <ng-container matColumnDef="fechaCreacion">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha creacion</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.fechaCreacion | shortDate }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="fechaEdicto">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha edicto</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.fechaEdicto | shortDate }}</mat-cell>
        </ng-container>

         <!-- fechaAcepDeneg Column -->
      <ng-container matColumnDef="tipo">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Tipo</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.tipo  }}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>
