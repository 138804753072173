/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-file-multiple-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/form-field";
import * as i4 from "@angular/material/input";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/material/core";
import * as i8 from "@angular/cdk/text-field";
import * as i9 from "./form-file-multiple-upload.component";
var styles_FormFileMultipleUploadComponent = [i0.styles];
var RenderType_FormFileMultipleUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormFileMultipleUploadComponent, data: {} });
export { RenderType_FormFileMultipleUploadComponent as RenderType_FormFileMultipleUploadComponent };
function View_FormFileMultipleUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col-sm-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
function View_FormFileMultipleUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "row align-middle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFileMultipleUploadComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.upload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Seleccionar fitxer"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "col-sm-9"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["uploadFileInput", 1]], null, 0, "input", [["class", "form-control-file"], ["hidden", ""], ["multiple", ""], ["style", "width: 0px"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.labelHidden; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.size == 0); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.fileInfo; _ck(_v, 7, 0, currVal_2); }); }
function View_FormFileMultipleUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "row fixed-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "col-sm-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [["inputEdit", 1]], null, 7, "textarea", [["autosize", ""], ["class", "fixed-height-scroll mat-input-element mat-form-field-autofill-control"], ["matInput", ""], ["type", "text"]], [[2, "mat-input-server", null], [1, "id", 0], [1, "placeholder", 0], [8, "disabled", 0], [8, "required", 0], [8, "readOnly", 0], [1, "aria-describedby", 0], [1, "aria-invalid", 0], [1, "aria-required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("blur" === en)) {
        var pd_4 = (i1.ɵnov(_v, 9)._focusChanged(false) !== false);
        ad = (pd_4 && ad);
    } if (("focus" === en)) {
        var pd_5 = (i1.ɵnov(_v, 9)._focusChanged(true) !== false);
        ad = (pd_5 && ad);
    } if (("input" === en)) {
        var pd_6 = (i1.ɵnov(_v, 9)._onInput() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.fileInfo = $event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i3.MatFormFieldControl, null, [i4.MatInput]), i1.ɵdid(5, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(9, 999424, null, 0, i4.MatInput, [i1.ElementRef, i6.Platform, [6, i5.NgControl], [2, i5.NgForm], [2, i5.FormGroupDirective], i7.ErrorStateMatcher, [8, null], i8.AutofillMonitor, i1.NgZone], { disabled: [0, "disabled"], placeholder: [1, "placeholder"], type: [2, "type"] }, null), i1.ɵdid(10, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "col-sm-3 align-self-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.upload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Seleccionar fitxers"])), (_l()(), i1.ɵeld(14, 0, [[1, 0], ["uploadFileInput", 1]], null, 0, "input", [["class", "form-control-file"], ["hidden", ""], ["multiple", ""], ["style", "width: 0px"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["N\u00FAmero de fitxers: ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_16 = true; var currVal_17 = _co.fileInfo; _ck(_v, 7, 0, currVal_16, currVal_17); var currVal_18 = true; var currVal_19 = _co.labelFitxer; var currVal_20 = "text"; _ck(_v, 9, 0, currVal_18, currVal_19, currVal_20); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 9)._isServer; var currVal_1 = i1.ɵnov(_v, 9).id; var currVal_2 = i1.ɵnov(_v, 9).placeholder; var currVal_3 = i1.ɵnov(_v, 9).disabled; var currVal_4 = i1.ɵnov(_v, 9).required; var currVal_5 = i1.ɵnov(_v, 9).readonly; var currVal_6 = (i1.ɵnov(_v, 9)._ariaDescribedby || null); var currVal_7 = i1.ɵnov(_v, 9).errorState; var currVal_8 = i1.ɵnov(_v, 9).required.toString(); var currVal_9 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 10).ngClassValid; var currVal_14 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 3, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15]); var currVal_21 = _co.numFitxers; _ck(_v, 16, 0, currVal_21); }); }
export function View_FormFileMultipleUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { file: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFileMultipleUploadComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFileMultipleUploadComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isTextarea; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isTextarea; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_FormFileMultipleUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-file-multiple-upload", [], null, null, null, View_FormFileMultipleUploadComponent_0, RenderType_FormFileMultipleUploadComponent)), i1.ɵdid(1, 114688, null, 0, i9.FormFileMultipleUploadComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormFileMultipleUploadComponentNgFactory = i1.ɵccf("app-form-file-multiple-upload", i9.FormFileMultipleUploadComponent, View_FormFileMultipleUploadComponent_Host_0, { label: "label", labelFitxer: "labelFitxer", labelHidden: "labelHidden", name: "name", isTextarea: "isTextarea" }, { fileReady: "fileReady" }, []);
export { FormFileMultipleUploadComponentNgFactory as FormFileMultipleUploadComponentNgFactory };
