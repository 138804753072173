<app-dialog-header label="Operació" i18n-label></app-dialog-header>
<app-dialog-body>
  <div style="display: flex;" *ngIf="!fetching">
    <div>
      <mat-table #table [dataSource]="tipoOps" matSort aria-label="Elements" matSortActive="COD_OPE" matSortDirection="asc">
        <ng-container matColumnDef="Codi">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
          <mat-cell *matCellDef="let row" >{{row.COD_OPE}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Desc">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripció</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.DESC_OPE}}
            <!-- <button><i class="material-icons" (click)="onRowClicked(row)">keyboard_arrow_right</i></button> -->
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="btn">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index;">
            <button mat-raised-button [disabled]="this.isButtonDisabled(row)" (click)="onRowClicked(row, i)" [style.background]="this.currentRow === i ? '#20a8d8' : 'white'"><i class="material-icons">keyboard_arrow_right</i></button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>

    <div style="overflow-x: auto; white-space: nowrap; width: 100%; margin: 4px;">
      <mat-form-field>
        <input id="nova-operacio-codi" [(ngModel)]='cod' matInput i18n-placeholder placeholder="Codi de la operació" value="" disabled>
      </mat-form-field>

      <mat-form-field *ngIf="showDescripcion">
        <input id="nova-operacio-descripcio" [(ngModel)]='desc' matInput i18n-placeholder placeholder="Descripció" value="" disabled>
      </mat-form-field>

      <select *ngIf="showMotivosAnulacion" [(ngModel)]="selectedMotivoAnulacion">
        <option class='option' *ngFor='let option of motivosAnulacion' [value]="option.ID">{{option.DESCRIPCION}}</option>
      </select>

      <app-infor-infractor #inforInfract *ngIf="this.cod === 'INFOR_INFRACTOR'"></app-infor-infractor>
      <app-infor-titular #inforTitular *ngIf="this.cod === 'INFOR_TITULAR'"></app-infor-titular>
      <app-cambio-matricula #cambioMatricula *ngIf="this.cod === 'CAMBIO_MATRICULA'"></app-cambio-matricula>
      <app-form-alegaciones #alegacion *ngIf="this.cod === 'ALEGACIONES'"></app-form-alegaciones>
      <div *ngIf="!this.isMassiva && this.cod === 'PRIMERAS_ALEGACIONES'">
        <app-intro-alegaciones #introAlegacion [exp]='this.exp'></app-intro-alegaciones>
      </div>
      <div *ngIf="!this.isMassiva && this.cod === 'SEGUNDAS_ALEGACIONES'">
        <app-segundas-alegaciones #segundaAlegacion [exp]='this.exp'></app-segundas-alegaciones>
      </div>
    
      <mat-form-field>
        <input id="nova-operacio-observacions" [(ngModel)]='obs' matInput i18n-placeholder placeholder="Observacions / Motiu" value="">
      </mat-form-field>
      <div class="row">
        <button class="btn btn-sq-lg btn-primary" style="width: 100%" (click)="executeOperation()">
          <i class="fas fa-edit"></i><br/><span i18n>Executar</span>
        </button>
      </div>
    </div>

  </div>
  
</app-dialog-body>
