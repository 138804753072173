import { GenericFileDisplayerPageComponent } from 'path-shared/components/generic-file-displayer/components/generic-file-displayer-page/generic-file-displayer-page.component';
import { NotificarService } from './../../../expedients/services/notificar/notificar.service';
import { EnviosDto } from './../../models/enviosDto';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ExpedientsComponent } from 'app/sections/expedients/expedients.component';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { TipoEnviosEnum } from '../../models/tipoEnviosEnum.model';
import { NotificacionTramitarResponseDto } from '../../../expedients/models/notificar/notificacion-tramitar-response.dto';
import { ReportsComponent } from 'path-shared/components/reports/reports.component';
import { ReportService } from 'path-shared/services/report/report.service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { GenericFile } from 'path-shared/components/generic-file-displayer/model/generic-file';
import { CustomButtonInfo } from 'path-shared/components/generic-file-displayer/model/CustomButtonInfo';
import { t } from '@angular/core/src/render3';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { RowEnvioDetalleDto } from '../../models/rowEnvioDetalleDto';

@Component({
  selector: 'app-envio-detail',
  templateUrl: './envio-detail.component.html',
  styleUrls: ['./envio-detail.component.scss']
})
export class EnvioDetailComponent implements OnInit {
  dialogData: ComponentDialog;
  
  expedientsDialog: MatDialogRef<ExpedientsComponent>;
  closeButtonText: string;
  public envio: EnviosDto;
  public envioRows: RowEnvioDetalleDto[];
  public currentRow: RowEnvioDetalleDto;
  displayedColumns: string[] = ['expediente', 'nombre','juridica', 'aceptacion', 'poblacion',  'cp', 'fechaRespuesta', 'resultado'];
  reports = [];
  URL_REPORT_Generico = 'urlGenerateReport';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private i18n: I18n,
  private toastr: ToastrService,
  public auth: AuthorizationService,
  private dialog: MatDialog,
  public thisDialogRef: MatDialogRef<EnvioDetailComponent>,
  private denunciesService: DenunciesService,
  private notificarService: NotificarService,
  private reportService: ReportService,
  private confirmationDialogService: ConfirmationDialogService) { 
    if (data.detail !== undefined && data.detail !== null ) {
      this.envio = data.detail;
      if (data.rows !== undefined && data.rows !== null ) {
          this.envioRows = data.rows;
      }
      if (data.reports !== undefined && data.reports !== null ) {
        this.reports = data.reports;
      }
    } else{
      this.cancel();
    }
    this.dialogData = data;
    if (this.dialogData.isReadOnly) {
      this.closeButtonText = this.i18n('Tancar');
    } else {
      this.closeButtonText = this.i18n('Llista');
    }

  }

  ngOnInit() {
  }
  cancel() {
    this.thisDialogRef.close(false);
  }
  viewDetail(row: RowEnvioDetalleDto){
    this.currentRow = row;
  }
  viewExpedient(){
    this.denunciesService.getExpedient(this.currentRow.expediente.toString()).subscribe( expedientsData => {
      this.denunciesService.getDenunciaImages(this.currentRow.numDenuncia).subscribe( images => {
        this.expedientsDialog = this.dialog.open(ExpedientsComponent,
        {
          height: '80%',
          width: '52%',
          closeOnNavigation: false,
          autoFocus: true,
          data: {'expedient': expedientsData, 'numDenuncia': this.currentRow.numDenuncia, 'imatges': images}

        });
      });
    });
  }
  finalizarEnvio(){
    let notificacionesPendientes: Array<NotificacionTramitarResponseDto> = new Array<NotificacionTramitarResponseDto>();
    this.envioRows.forEach(x =>{
      let currentNoti:NotificacionTramitarResponseDto = {
        entidad : this.envio.entidad,
        idEnvio: this.envio.idEnvio.toString(),
        numeroPublicacion:x.nPublicacionBop,
        usuario:x.nombre,
        direccion:x.direccion,
        poblacion:x.poblacion,
        cp:x.cp,
        idNotificacion: x.idNotificacion.toString()
      };
      notificacionesPendientes.push(currentNoti);
    });
    switch (this.envio.tipoEnvio) {
      case TipoEnviosEnum.DENUNCIA: {
        this.notificarService.finalizaProcesoNotificarDenuncias(notificacionesPendientes).subscribe(x =>{
          if(x !== undefined && x !== null && x){
            this.toastr.success(this.i18n('Proceso finalizado correctamente!'));
            this.cancel();
          }else{
            this.toastr.error(this.i18n('Error, al finalizar el proceso!'));
          }
        });
        break;
      }
      case TipoEnviosEnum.ALEGACIONS: {
        this.notificarService.finalizaProcesoNotificarPropuestasResolucion(notificacionesPendientes).subscribe(x =>{
          if(x !== undefined && x !== null && x){
            this.toastr.success(this.i18n('Proceso finalizado correctamente!'));
            this.cancel();
          }else{
            this.toastr.error(this.i18n('Error, al finalizar el proceso!'));
          }
        });
        break;
      }
      case TipoEnviosEnum.RECURS: {
        this.notificarService.finalizaProcesoNotificarNotificacionesResoluciones(notificacionesPendientes).subscribe(x =>{
          if(x !== undefined && x !== null && x){
            this.toastr.success(this.i18n('Proceso finalizado correctamente!'));
            this.cancel();
          }else{
            this.toastr.error(this.i18n('Error, al finalizar el proceso!'));
          }
        });
        break;
      }
      case TipoEnviosEnum.SANCIO: {
        this.notificarService.finalizaProcesoNotificarRecursosReposicion(notificacionesPendientes).subscribe(x =>{
          if(x !== undefined && x !== null && x){
            this.toastr.success(this.i18n('Proceso finalizado correctamente!'));
            this.cancel();
          }else{
            this.toastr.error(this.i18n('Error, al finalizar el proceso!'));
          }
        });
        break;
      }
      default: {
         // res
         break;
      }
    }
  }
  askDeshacer(){
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol confirmar totes les alarmes pendents?'))
    .then((confirmed) => {
      if (confirmed) {
        this.deshacer();
      }
    });
  }
  deshacer(){
    this.notificarService.undoEnvio(this.envio.secureId).subscribe(x =>{
      if(x !== undefined && x !== null && x){
        this.toastr.success(this.i18n('Proceso finalizado correctamente!'));
        this.cancel();
      }else{
        this.toastr.error(this.i18n('Error, al finalizar el proceso!'));
      }
    });
  }
  

   //#region REPORTS
  mostrarInformes(){
    this.impressioReport();
  }
  impressioReport() {
    const ref = this.dialog.open(ReportsComponent, {

      width: '30%',
      height: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: this.reports
    }).afterClosed().subscribe(report => {

      const reportStructure = {
        // 'institucionId' : localStorage.getItem('IdInstitucion'),
        'reportId' : 'report.id',
        'metode': 'report.metode',
        'envioId' : this.envio.idEnvio
      };
      if (report !== undefined && report !=='' ) {
        let usableDataStructure = Object.assign({}, reportStructure);
        for (let [key, value] of Object.entries(reportStructure)) {
            usableDataStructure[key] = reportStructure[key];
        }
        this.reportService.generateReport(usableDataStructure, this.URL_REPORT_Generico).subscribe((result: any) => {
            if (result) {
                if (result.body.type === 'application/json') {
                    // Parseamos el resultado. Es un documento generico.
                    result.body.text().then(value => {
                        const documentoParseado = JSON.parse(value);
                        // Ahora abrimos el fichero generico.
                        this.openReportView(documentoParseado);
                    });
                } else {
                    // Solo report.
                    const name = report.nom;
                    const contentType = result.headers.get('Content-Type');
                    if (contentType !== null && contentType !== undefined) {
                        FileDownloader.downLoadFile([result.body],  contentType + ';charset=utf-8', name.toString());
                    } else {
                    this.toastr.error(this.i18n('No s\'ha pogut generar el fitxer'));
                    }
                }
            }
        });
      }
    });
  }

  openReportView(fichero: GenericFile): void {
    const customButtons = new Array<CustomButtonInfo>();
    if (fichero.metodoFinalizacion !== undefined && fichero.metodoFinalizacion !== null) {
        // Definimos el callback del boton.
        const callback = new EventEmitter<any>();
        callback.subscribe(result => {
            // Se ha realizado click en el boton de accion dentro del report.
            if (result) {
                // todo, accion.
                // this.reportService.performFinalAction(fichero.metodoFinalizacion).subscribe(resultadoFinalizacion => {
                //     // Hemos finalizado el report. Mostramos un mensaje indicandolo.
                //     this.toastr.info('Finalización report correcto.', 'Estado report', {
                //         closeButton: true
                //     });
                // });
            }
        });
        customButtons.push(new CustomButtonInfo('Finalizar Reporte', '#865EC7', 'fas fa-hand-point-right', false, callback));
    }

    const dialogReference = this.dialog.open(
        GenericFileDisplayerPageComponent,
        {
            minWidth: '50%',
            minHeight: '90%',
            width: '50%',
            height: '90%',
            maxWidth: '50%',
            maxHeight: '90%',
            closeOnNavigation: false,
            data: {
                'files': [ fichero ],
                'fileEvents': undefined,
                'updateFileEnabled': false,
                'deleteFileEnabled': false,
                'downloadFileEnabled': true,
                'displayFileListEnabled': false,
                'singleFile': true,
                'customButtons': customButtons
            },
        }
    );
  }

}

