/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-file-displayer-frame.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./generic-file-displayer-frame.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/material/dialog";
var styles_GenericFileDisplayerFrameComponent = [i0.styles];
var RenderType_GenericFileDisplayerFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericFileDisplayerFrameComponent, data: {} });
export { RenderType_GenericFileDisplayerFrameComponent as RenderType_GenericFileDisplayerFrameComponent };
function View_GenericFileDisplayerFrameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["id", "imatgeViewer"], ["style", "margin-bottom: 5%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["style", " display: inline-block; height: 100%; vertical-align: middle;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "col-xl-12"], ["style", "vertical-align: middle;"]], [[1, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.expandImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayImage; _ck(_v, 2, 0, currVal_0); }); }
function View_GenericFileDisplayerFrameComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["id", "pdfViewer"], ["style", "margin-bottom: 5%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "pdf-viewer", [["class", "col-xl-3"], ["delegate-handle", "relativity-special-general-theory"], ["max-height", "50%"], ["scale", "1"], ["show-toolbar", "true"], ["style", "overflow: scroll"]], [[8, "zoom", 0], [8, "fit-to-page", 0], [8, "original-size", 0], [8, "src", 0], [8, "render-text", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = 0.5; var currVal_1 = false; var currVal_2 = true; var currVal_3 = _co.displayPdf; var currVal_4 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_GenericFileDisplayerFrameComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "drag-scroll"], ["style", "height:60%;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericFileDisplayerFrameComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericFileDisplayerFrameComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "drag-scroll__actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "action-button__prev"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveLeft() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-angle-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "action-button__next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveRight() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-angle-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentlyShowingImage; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.currentlyShowingPdf; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_GenericFileDisplayerFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-file-displayer-frame", [], null, null, null, View_GenericFileDisplayerFrameComponent_0, RenderType_GenericFileDisplayerFrameComponent)), i1.ɵdid(1, 114688, null, 0, i3.GenericFileDisplayerFrameComponent, [i4.DomSanitizer, i5.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericFileDisplayerFrameComponentNgFactory = i1.ɵccf("app-generic-file-displayer-frame", i3.GenericFileDisplayerFrameComponent, View_GenericFileDisplayerFrameComponent_Host_0, {}, { previousFile: "previousFile", nextFile: "nextFile" }, []);
export { GenericFileDisplayerFrameComponentNgFactory as GenericFileDisplayerFrameComponentNgFactory };
