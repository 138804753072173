var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var TiquetsFilter = /** @class */ (function (_super) {
    __extends(TiquetsFilter, _super);
    function TiquetsFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.id = null;
        _this.matricula = '';
        _this.email = '';
        _this.fechaTicketInicio = null;
        _this.fechaTicketFin = null;
        _this.fechaBancoInicio = null;
        _this.fechaBancoFin = null;
        _this.zonaSecureId = '';
        _this.proveidors = null;
        _this.labels['id'] = _this.i18n('Id');
        _this.labels['matricula'] = _this.i18n('Matrícula');
        _this.labels['email'] = _this.i18n('Email');
        _this.labels['fechaTicketInicio'] = _this.i18n('Data Tiquet Inici');
        _this.labels['fechaTicketFin'] = _this.i18n('Data Tiquet Fi');
        _this.labels['fechaBancoInicio'] = _this.i18n('Data Banc Inici');
        _this.labels['fechaBancoFin'] = _this.i18n('Data Banc Fi');
        _this.labels['zonaSecureId'] = _this.i18n('Zona ID');
        _this.labels['proveidors'] = _this.i18n('Proveidors');
        _this.formatOutput['fechaTicketInicio'] = 'shortDate';
        _this.formatOutput['fechaTicketFin'] = 'shortDate';
        _this.formatOutput['fechaBancoInicio'] = 'shortDate';
        _this.formatOutput['fechaBancoFin'] = 'shortDate';
        return _this;
    }
    return TiquetsFilter;
}(FilterData));
export { TiquetsFilter };
