/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./propostes-anulacio-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../shared/components/dialog/dialog-header/dialog-header.component.ngfactory";
import * as i4 from "../../../../../shared/components/dialog/dialog-header/dialog-header.component";
import * as i5 from "../../../../../shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component.ngfactory";
import * as i6 from "../../../../../shared/components/photo-box/components/photo-box-gallery/photo-box-gallery.component";
import * as i7 from "../../../../../shared/services/parameters/parameters.service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../../../shared/services/images/images.service";
import * as i10 from "@ngx-translate/i18n-polyfill";
import * as i11 from "../../../../../shared/components/confirmation-dialog/confirmation-dialog.service";
import * as i12 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component.ngfactory";
import * as i13 from "../../../../../shared/components/form/form-text-edit/form-text-edit.component";
import * as i14 from "../../../../../shared/services/validation/validation.service";
import * as i15 from "../../../../../shared/components/form/form-date-edit/form-date-edit.component.ngfactory";
import * as i16 from "ng-pick-datetime/date-time/adapter/date-time-adapter.class";
import * as i17 from "ng-pick-datetime-moment/moment-adapter/moment-date-time-adapter.class";
import * as i18 from "../../../../../shared/components/form/form-date-edit/form-date-edit.component";
import * as i19 from "ng-pick-datetime/date-time/adapter/date-time-format.class";
import * as i20 from "../../../../../shared/components/form/form-text-area/form-text-area.component.ngfactory";
import * as i21 from "../../../../../shared/components/form/form-text-area/form-text-area.component";
import * as i22 from "./propostes-anulacio-editor.component";
import * as i23 from "../../services/propostes-anulacio.service";
var styles_PropostesAnulacioEditorComponent = [i0.styles];
var RenderType_PropostesAnulacioEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PropostesAnulacioEditorComponent, data: {} });
export { RenderType_PropostesAnulacioEditorComponent as RenderType_PropostesAnulacioEditorComponent };
function View_PropostesAnulacioEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "button", [["class", "btn btn-danger button-not-allowed btn-block"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rejectProposta() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-times-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Desestimar"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["class", "btn btn-success  button-not-allowed  btn-block"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptProposta() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-check-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Acceptar"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isEditMode(); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isEditMode(); _ck(_v, 8, 0, currVal_1); }); }
function View_PropostesAnulacioEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = "fas fa-check-circle has-color-green fa-3x"; _ck(_v, 0, 0, currVal_0); }); }
function View_PropostesAnulacioEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = "far fa-times-circle has-color-red fa-3x"; _ck(_v, 0, 0, currVal_0); }); }
function View_PropostesAnulacioEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "row "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "col-6 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "col-1 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PropostesAnulacioEditorComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PropostesAnulacioEditorComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "col-5 "]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.propuestaAnulacion.aceptada; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.propuestaAnulacion.aceptada; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_PropostesAnulacioEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-header", [["label", "Proposta anul\u00B7laci\u00F3"]], null, null, null, i3.View_DialogHeaderComponent_0, i3.RenderType_DialogHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-photo-box-gallery", [], null, null, null, i5.View_PhotoBoxGalleryComponent_0, i5.RenderType_PhotoBoxGalleryComponent)), i1.ɵdid(5, 245760, [["photoBoxGallery", 4]], 0, i6.PhotoBoxGalleryComponent, [i7.ParametersService, i8.MatDialogRef, i9.ImagesService, i10.I18n, i11.ConfirmationDialogService], { imatges: [0, "imatges"], allowSelect: [1, "allowSelect"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-form-text-edit", [["class", "col-4"], ["label", "Exp"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.propuestaAnulacion.exp = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_FormTextEditComponent_0, i12.RenderType_FormTextEditComponent)), i1.ɵdid(8, 4308992, null, 0, i13.FormTextEditComponent, [i14.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-form-text-edit", [["class", "col-4"], ["label", "Matr\u00EDcula"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.propuestaAnulacion.matricula = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_FormTextEditComponent_0, i12.RenderType_FormTextEditComponent)), i1.ɵdid(10, 4308992, null, 0, i13.FormTextEditComponent, [i14.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], isReadOnly: [3, "isReadOnly"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(11, 16777216, null, null, 3, "app-form-date-edit", [["class", "col-4"], ["label", "Data entrada"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.propuestaAnulacion.fechaDenuncia = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_FormDateEditComponent_0, i15.RenderType_FormDateEditComponent)), i1.ɵprd(4608, null, i16.DateTimeAdapter, i17.MomentDateTimeAdapter, [i16.OWL_DATE_TIME_LOCALE]), i1.ɵdid(13, 114688, null, 0, i18.FormDateEditComponent, [i1.ViewContainerRef, i1.LOCALE_ID], { label: [0, "label"], value: [1, "value"], class: [2, "class"], disabled: [3, "disabled"] }, { valueChange: "valueChange" }), i1.ɵprd(256, null, i19.OWL_DATE_TIME_FORMATS, i18.MY_CUSTOM_FORMATS, []), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Comentari PDA"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Comentari Backoffice"])), (_l()(), i1.ɵeld(20, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "app-form-text-area", [["class", "col-6"]], null, null, null, i20.View_FormTextAreaComponent_0, i20.RenderType_FormTextAreaComponent)), i1.ɵdid(22, 4308992, null, 0, i21.FormTextAreaComponent, [i14.ValidationService], { value: [0, "value"], class: [1, "class"], disabled: [2, "disabled"] }, null), (_l()(), i1.ɵeld(23, 0, null, null, 1, "app-form-text-area", [["class", "col-6"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.propuestaAnulacion.comentarioUsuarioBackoffice = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i20.View_FormTextAreaComponent_0, i20.RenderType_FormTextAreaComponent)), i1.ɵdid(24, 4308992, null, 0, i21.FormTextAreaComponent, [i14.ValidationService], { value: [0, "value"], class: [1, "class"], disabled: [2, "disabled"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PropostesAnulacioEditorComponent_1)), i1.ɵdid(26, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PropostesAnulacioEditorComponent_2)), i1.ɵdid(28, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Proposta anul\u00B7laci\u00F3"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.propuestaAnulacion.IMATGES; var currVal_2 = false; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = "Exp"; var currVal_4 = _co.propuestaAnulacion.exp; var currVal_5 = "col-4"; var currVal_6 = true; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = "Matr\u00EDcula"; var currVal_8 = _co.propuestaAnulacion.matricula; var currVal_9 = "col-4"; var currVal_10 = true; _ck(_v, 10, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "Data entrada"; var currVal_12 = _co.propuestaAnulacion.fechaDenuncia; var currVal_13 = "col-4"; var currVal_14 = true; _ck(_v, 13, 0, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = _co.propuestaAnulacion.textoAnulacion; var currVal_16 = "col-6"; var currVal_17 = true; _ck(_v, 22, 0, currVal_15, currVal_16, currVal_17); var currVal_18 = _co.propuestaAnulacion.comentarioUsuarioBackoffice; var currVal_19 = "col-6"; var currVal_20 = !_co.isEditMode(); _ck(_v, 24, 0, currVal_18, currVal_19, currVal_20); var currVal_21 = _co.isEditMode(); _ck(_v, 26, 0, currVal_21); var currVal_22 = !_co.isEditMode(); _ck(_v, 28, 0, currVal_22); }, null); }
export function View_PropostesAnulacioEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-propostes-anulacio-editor", [], null, null, null, View_PropostesAnulacioEditorComponent_0, RenderType_PropostesAnulacioEditorComponent)), i1.ɵdid(1, 114688, null, 0, i22.PropostesAnulacioEditorComponent, [i8.MAT_DIALOG_DATA, i10.I18n, i23.PropostesAnulacioService, i8.MatDialog, i8.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PropostesAnulacioEditorComponentNgFactory = i1.ɵccf("app-propostes-anulacio-editor", i22.PropostesAnulacioEditorComponent, View_PropostesAnulacioEditorComponent_Host_0, {}, {}, []);
export { PropostesAnulacioEditorComponentNgFactory as PropostesAnulacioEditorComponentNgFactory };
