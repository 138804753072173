
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { GenericFileFilter } from '../model/generic-file.filter';

const defaultSortParam = 'id';
const defaultSortDirection = 'asc';

export class GenericFileFilterService extends CustomFilterService {

    public createFilterParams(filter: GenericFileFilter, start: number, pageSize: number, sortField: string, sortDir: string) {
        return new HttpParams()
        .append('id', filter.id ? filter.id : '')
        .append('nombre', filter.nombre ? filter.nombre : '')
        .append('fechaGravacion', filter.fechaGravacion ? filter.fechaGravacion : '')
        .append('eliminado', filter.eliminado ? filter.eliminado : '')
        .append('tipoRecurso', filter.tipoRecurso ? filter.tipoRecurso : '')
        .append('idRegistroAsociado', filter.idRegistroAsociado ? filter.idRegistroAsociado : '')
        .append('start', start.toString())
        .append('size', pageSize.toString())
        .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
        .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    }
}
