
import { SenalesVerticalesFilter } from './../models/senales-verticales.filter';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = 'id';
const defaultSortDirection = 'asc';

export class SenalesVerticalesFilterService extends CustomFilterService {

    public createFilterParams(filter: SenalesVerticalesFilter, start: number, pageSize: number, sortField: string, sortDir: string) {
        return new HttpParams()
        .append('id', filter.id ? filter.id : '')
        .append('nombre', filter.nombreSenal ? filter.nombreSenal : '')
        .append('tipoSenalVertical', filter.tipoFk ? filter.tipoFk.toString() : '')
        .append('start', start.toString())
        .append('size', pageSize.toString())
        .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
        .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    }

    public createFilesFilterParams(ids: string[]) {
        return new HttpParams()
        .append('ids', JSON.stringify(ids));
    }
}
