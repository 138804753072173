import { TurnosEditorComponent } from './components/turnos-editor/turnos-editor.component';
import { TurnosFilter } from './models/turnos-filter';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { TurnosTableComponentComponent } from './components/turnos-table-component/turnos-table-component.component';
import { TurnosFilterComponent } from './components/turnos-filter/turnos-filter.component';

@Component({
  selector: 'app-turnos',
  templateUrl: './turnos.component.html',
  styleUrls: ['./turnos.component.scss']
})
export class TurnosComponent implements OnInit {
  filterData: TurnosFilter;
  @ViewChild(TurnosTableComponentComponent) turnosTable: TurnosTableComponentComponent;
  constructor(  private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService){ 
      this.filterData = new TurnosFilter('descripcio', 'asc');
    }

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.GruaMotiusEntrada);
    this.turnosTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
  }
  edit() {
    const idSeleccionat: string = this.turnosTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

    const ref = this.dialog.open(TurnosEditorComponent, {
      width: '40%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.turnosTable.load(); }
    });
  }

  refresh() {
    this.turnosTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(TurnosFilterComponent, {
      width: '40%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.turnosTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: TurnosFilter) => {
      this.turnosTable.filter.set(filter);
      this.turnosTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.turnosTable.generateExcel();
  }
  

}
