var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from "@angular/common/http";
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";
var PlanificacioFilterService = /** @class */ (function (_super) {
    __extends(PlanificacioFilterService, _super);
    function PlanificacioFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PlanificacioFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        console.log('filterDia:' + filter.Dia);
        return new HttpParams()
            .append('somId', filter.somId ? filter.somId.toString() : '')
            .append('Dia', filter.Dia ? filter.Dia.toDateString() : '')
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField)
            .append('sortdir', sortDir);
    };
    return PlanificacioFilterService;
}(CustomFilterService));
export { PlanificacioFilterService };
