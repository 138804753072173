import { BehaviorSubject } from 'rxjs';
import { Usuari } from './../../../../../usuaris/models/usuari.model';
import { RegistroTurno } from './../../models/registro-turno';
import { Component, OnInit } from '@angular/core';
import { TurnosService } from '../../services/turnos-service';
import { UsuarisService } from 'app/sections/usuaris/services/usuaris.service';
import { AuthService } from 'path-shared/services/authentication/auth.service';
import { MatDialogRef } from '@angular/material'; 

declare let pdfMake: any ;

@Component({
  selector: 'app-finalizar-turno',
  templateUrl: './finalizar-turno.component.html',
  styleUrls: ['./finalizar-turno.component.scss']
})
export class FinalizarTurnoComponent implements OnInit {
idRegistroTurno: number;
registroTurno: RegistroTurno;
usuari: Usuari;
pdfActual: BehaviorSubject<string> = new BehaviorSubject('');
pdfb64: any;
zoom = 0.5;
  constructor(private turnosService: TurnosService,
    private usuarisService: UsuarisService, 
    private authService: AuthService,
    public thisDialogRef: MatDialogRef<FinalizarTurnoComponent>) { 
    const reg = sessionStorage.getItem('idRegistroTurno');
    if( reg !== undefined && reg !== null){
      this.idRegistroTurno = Number.parseInt(reg);
    }
  }

  ngOnInit() {
    this.usuari = this.usuarisService.getCurrentUser();
    if (this.idRegistroTurno === undefined || this.idRegistroTurno === null || this.idRegistroTurno === 0 ){
      this.usuari
      this.turnosService.getLastTurnActive( this.usuari.UserID).subscribe(x => {
        this.registroTurno = x;
        this.turnosService.getReportTurnoUsuarioByID(this.registroTurno.secureId).subscribe(report => {
          this.pdfb64 = report;
         this.pdfActual.next( this.buildPdf(report));
      });
      });
    } else {
      this.turnosService.getRegistroTurnoUsuarioByID(this.idRegistroTurno).subscribe(x => {
        this.registroTurno = x;
        this.turnosService.getReportTurnoUsuarioByID(this.registroTurno.secureId).subscribe(report => {
          this.pdfb64 = report;
          this.pdfActual.next( this.buildPdf(report));
        });
      });
    }
  }

  finalizarTurno() {
    this.registroTurno.ID_USUARIO_FIN = this.usuari.UserID;
    this.turnosService.finalizarTurno(this.registroTurno).subscribe(x => {
      this.thisDialogRef.close(x);
      this.authService.logOut();
    });
  }

  buildPdf(detailsdata: any): any {
    return 'data:image/pdf;base64,' + btoa(new Uint8Array(detailsdata).reduce(function (data, byte) {
      return data + String.fromCharCode(byte);
      }, '')) ;
  }

  downloadPDF() {
    const linkSource = this.pdfActual.value;
    const downloadLink = document.createElement("a");
    const fileName = "Turno"+new Date().getDay().toString() +new Date().getMonth().toString()+new Date().getFullYear()+".pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}
printPDF() {
 
  pdfMake.createPdf(this.pdfb64).print();
   
}

b64toBlob(content, contentType){
  contentType = contentType || '';
  const sliceSize = 512;
   // method which converts base64 to binary
  const byteCharacters = window.atob(content); 

  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {
      type: contentType
  }); // statement which creates the blob
  return blob;
}
cancel() {
  this.thisDialogRef.close(false);
}

incrementZoom(amount: number) {
  this.zoom += amount;
}
}
