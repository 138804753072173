var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PersonesService } from 'app/sections/ciutat/persones/services/persones.service';
import { PersonaFilter } from 'app/sections/ciutat/persones/models/persona-filter';
import { Builder } from 'path-shared/models/builder';
import { PersonesComponent } from 'app/sections/ciutat/persones/pages/persones/persones.component';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { PersonesEditorComponent } from 'app/sections/ciutat/persones/components/persones-editor/persones-editor.component';
import { Dictionary } from 'path-shared/models/dictionary';
import { BehaviorSubject } from 'rxjs-compat';
import { ComboBoxInputComponent } from 'path-shared/components/form/combo-box-input/combo-box-input.component';
import { OperacionInforInfractorDto } from 'app/sections/denuncies/models/operacionInforInfractorDto.model';
import { FormService } from 'path-shared/services/form/form.service';
var InforInfractorComponent = /** @class */ (function () {
    function InforInfractorComponent(personesService, dialog, confirmationDialogService, i18n, toastr, formService) {
        this.personesService = personesService;
        this.dialog = dialog;
        this.confirmationDialogService = confirmationDialogService;
        this.i18n = i18n;
        this.toastr = toastr;
        this.formService = formService;
        this.dto = new OperacionInforInfractorDto();
        this.dniInfractor = null;
        this.sidInfractor = '';
        this.juridica = false;
        this.sidDomiciliInfractor = '';
    }
    InforInfractorComponent.prototype.ngOnInit = function () {
        this.domicilisInfractorDictionary = new BehaviorSubject(new Array());
    };
    InforInfractorComponent.prototype.consultarConductor = function () {
        var _this = this;
        if (this.dniInfractor !== undefined
            && this.dniInfractor !== null
            && this.dniInfractor !== '') {
            this.personesService.findPersonaByDniNif(this.dniInfractor).subscribe(function (x) {
                if (x !== undefined && x !== null && x.length > 0) {
                    if (x.length === 1) {
                        _this.sidInfractor = x[0].secure_id;
                        _this.dto.idInfractor = x[0].id.toString();
                        _this.dto.nom = x[0].nomComplet;
                        _this.dniInfractor = x[0].dni_pasp;
                        _this.juridica = x[0].juridica;
                        _this.sidDomiciliInfractor = x[0].SECURE_ID_DOMICILI_ACTUAL;
                        // : nomComplet, dni_pasp: dni_pasp
                        _this.getDomicilis(_this.sidInfractor, false, x[0].SECURE_ID_DOMICILI_ACTUAL);
                        _this.dto.idDomicili = _this.sidDomiciliInfractor.split('|')[0];
                    }
                    else if (x.length > 1) {
                        var filter = new PersonaFilter('id', 'asc');
                        filter.dni_pasp = _this.dniInfractor;
                        // this.filterService.saveFilter('persona-filter', filter);
                        // Carraguem la taula filtrada per dni
                        var data = Builder.newComponentDialog(true, false, '', filter);
                        var ref = _this.dialog.open(PersonesComponent, {
                            width: '80%',
                            height: '90%',
                            closeOnNavigation: false,
                            autoFocus: true,
                            data: data
                        });
                        ref.afterClosed().subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                                    this.sidInfractor = data.secure_id;
                                    this.dto.idInfractor = data.codi;
                                    this.dto.nom = data.nomComplet;
                                    this.dniInfractor = data.dni_pasp;
                                    this.juridica = data.juridica;
                                    this.sidDomiciliInfractor = data.secureIdDom;
                                    // : nomComplet, dni_pasp: dni_pasp
                                    this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
                                    this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];
                                }
                                return [2 /*return*/];
                            });
                        }); });
                    }
                }
                else {
                    // Obrim dialeg per crear persona
                    _this.confirmationDialogService.confirm(_this.i18n('Confirmi'), _this.i18n('No s\ha trobat cap persona amb aquest DNI o Passaport. Vols crear-la ara?'), _this.i18n('Si'), _this.i18n('No'))
                        .then(function (confirmed) {
                        if (confirmed) {
                            var data = Builder.newComponentDialog(true, false, null);
                            var ref = _this.dialog.open(PersonesEditorComponent, {
                                width: '80%',
                                height: '90%',
                                closeOnNavigation: false,
                                autoFocus: true,
                                data: { isDialog: true,
                                    isReadOnly: false,
                                    id: null,
                                    filter: null,
                                    dni: _this.dniInfractor }
                            });
                            ref.afterClosed().subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                                        this.sidInfractor = data.secure_id;
                                        this.dto.nom = data.nomComplet;
                                        this.dto.idInfractor = data.codi;
                                        this.dniInfractor = data.dni_pasp;
                                        this.juridica = data.juridica;
                                        this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
                                        this.sidDomiciliInfractor = data.secureIdDom;
                                        this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];
                                    }
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        else {
                            _this.dto.nom = '';
                            _this.domicilisInfractorDictionary = new BehaviorSubject(new Array());
                        }
                    });
                }
            });
        }
        else {
            var data = Builder.newComponentDialog(true, false, null);
            var ref = this.dialog.open(PersonesComponent, {
                width: '80%',
                height: '90%',
                closeOnNavigation: false,
                autoFocus: true,
                data: data,
                disableClose: true
            });
            ref.afterClosed().subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
                        this.sidInfractor = data.secure_id;
                        this.dto.nom = data.nomComplet;
                        this.dto.idInfractor = data.codi;
                        this.dniInfractor = data.dni_pasp;
                        this.sidDomiciliInfractor = data.secureIdDom;
                        this.juridica = data.juridica;
                        this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
                        this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];
                    }
                    return [2 /*return*/];
                });
            }); });
        }
    };
    InforInfractorComponent.prototype.reset = function () {
        // this.dniInfractor = null;
        this.sidDomiciliInfractor = null;
        this.sidInfractor = null;
        this.dto.nom = null;
        this.dto.idInfractor = null;
        this.dto.idDomicili = null;
        this.comboDomPropietari.resetShowingOptions();
    };
    //   seleccionarConductor(secureId = null) {
    //     if (secureId !== null) {
    //   } else {
    //     if (Reflection.empty(this.sidInfractor)) {
    //       const data = Builder.newComponentDialog(true, false, secureId);
    //       const ref = this.dialog.open(PersonesComponent, {
    //         width: '80%',
    //         height: '90%',
    //         closeOnNavigation: false,
    //         autoFocus: true,
    //         data: data,
    //         disableClose: true
    //       });
    //       ref.afterClosed().subscribe(async data => {
    //         if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
    //           this.sidInfractor = data.secure_id;
    //           this.dto.nom = data.nomComplet;
    //           this.dto.idInfractor = data.codi;
    //           this.dniInfractor = data.dni_pasp;
    //           this.sidDomiciliInfractor = data.secureIdDom;
    //           this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
    //           this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];
    //         }
    //       });
    //     } else {
    //       const data = Builder.newComponentDialog(true, false, secureId);
    //       const ref = this.dialog.open(PersonesComponent, {
    //         width: '80%',
    //         height: '90%',
    //         closeOnNavigation: false,
    //         autoFocus: true,
    //         data: data,
    //         disableClose: true
    //       });
    //       ref.afterClosed().subscribe(async data => {
    //         if (data.codi !== undefined && data.codi !== null && data.codi !== '') {
    //           this.sidInfractor = data.secure_id;
    //           this.dto.nom = data.nomComplet;
    //           this.dto.idInfractor = data.codi;
    //           this.dniInfractor = data.dni_pasp;
    //           this.sidDomiciliInfractor = data.secureIdDom;
    //           this.getDomicilis(this.sidInfractor, false, data.secureIdDom);
    //           this.dto.idDomicili = this.sidDomiciliInfractor.split('|')[0];
    //         }
    //       });
    //     }
    //   }
    // }
    InforInfractorComponent.prototype.getDomicilis = function (secureIdPersona, propietari, secureIdDom) {
        var _this = this;
        this.personesService.getDomicilis(secureIdPersona).subscribe(function (dom) {
            _this.comboDomPropietari.resetShowingOptions();
            if (dom === undefined || dom === null || dom.length === 0) {
                _this.domicilisInfractorDictionary = new BehaviorSubject(new Array());
            }
            else {
                // this.domicilisConductor = dom;
                // this.comboDomConductor.resetCombo();
                _this.domicilisInfractorDictionary = new BehaviorSubject(new Array());
                var dict_1 = new Array();
                dom.forEach(function (element) {
                    dict_1.push(new Dictionary(element.secure_id, element.domicili + ' (' + element.codiPostal + ' - ' + element.localitat + ')'));
                });
                _this.domicilisInfractorDictionary.next(dict_1);
                if (secureIdDom !== undefined) {
                    _this.comboDomPropietari.markAsTouched();
                    _this.comboDomPropietari.setSelect(secureIdDom);
                }
            }
        });
    };
    InforInfractorComponent.prototype.domicilioConductorSeleccionado = function (secure_id) {
        this.sidDomiciliInfractor = secure_id;
    };
    InforInfractorComponent.prototype.correcte = function () {
        if (!this.formService.allFieldsValid(this.mainForm)) {
            this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
            if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
                this.mainForm.nativeElement.classList.add('displayErrors');
            }
            return false;
        }
        else if (this.juridica) {
            this.toastr.warning(this.i18n('No se puede seleccionar una persona jurídica como infractor'));
            return false;
        }
        else {
            return true;
        }
    };
    return InforInfractorComponent;
}());
export { InforInfractorComponent };
