import { ComponentDialog } from './../../../../models/component-dialog.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-generic-file-viewer-dialog',
  templateUrl: './generic-file-viewer-dialog.component.html',
  styleUrls: ['./generic-file-viewer-dialog.component.scss']
})
export class GenericFileViewerDialogComponent implements OnInit {

  imageData: any;
  fileName: String;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.fileName = data.fileName;
    this.imageData = this.data.imageData;
  }

  ngOnInit() {
  }

}
