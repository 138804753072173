import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Legislacio } from '../models/legislacio.model';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { LegislacioFilter } from '../models/legislacio-filter';
import { LegislacionsFilterService } from './legislacions-filter-service';

const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class LegislacioService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  getAll(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new LegislacionsFilterService().createFilterParams(new LegislacioFilter('descripcio', 'asc'), 0, 99999, 'descripcio', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetLegislacions'],  params);
    }
  }

  getAllForCombo(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new LegislacionsFilterService().createFilterParams(new LegislacioFilter ('descricpcio', 'asc'), 0, 99999, 'descripcio', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetLegislacionsForCombo'], params);
    }
  }

  setLegislacio(aparell: Legislacio): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetLegislacio'], aparell);
    }
  }

  removeLegislacio(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteLegislacio'].replace('{id}', id), null);
    }
  }

  getLegislacio(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetLegislacio'].replace('{id}', id), null);
    }
  }

  generateExcel(filter: LegislacioFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new LegislacionsFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlLegislacioExcel'],  params );
    }
  }
}
