<div class="row justify-content-between" [hidden]="true">
    <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
    <div class="col">
      <mat-paginator #paginator
        [pageIndex]="0"
        [pageSize]="this.filter.paginator.pageSize"
        [pageSizeOptions]="[20, 40, 60, 100]">
      </mat-paginator>
    </div>
  </div>
<app-dialog-header label="Pendientes" i18n-label></app-dialog-header>
<app-dialog-body>
  <div class="table-sdw is-fixed">
    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" matSortActive="{{this.sort.active}}" matSortDirection="{{this.sort.direction}}" class="is-header-sticky">

        <!-- Matricula Column -->
        <ng-container matColumnDef="EXP">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expediente</mat-header-cell>
            <mat-cell *matCellDef="let row"><a *ngIf="row.SomIdDenuncia!==''" (click)="onRowClicked(row)" class="is-link">{{row.EXP}}</a> <span *ngIf="row.SomIdDenuncia===''">{{row.EXP}}</span> </mat-cell>
        </ng-container>

        <!-- Expedient Column -->
        <ng-container matColumnDef="Matricula">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Matrícula</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.Matricula}}</mat-cell>
        </ng-container>

        <!-- Bulleti Column -->
        <ng-container matColumnDef="Nom">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nombre</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.Nom}}</mat-cell>
        </ng-container>
       
        <!-- Data Column -->
        <ng-container matColumnDef="Fecha_Caducidad">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha Caducidad</mat-header-cell>
            <mat-cell *matCellDef="let row"  [ngClass]="{'deadline' : isOld(row.Fecha_Caducidad)}">{{row.Fecha_Caducidad | shortDate}}</mat-cell>
        </ng-container>

         <!-- Data Column -->
         <ng-container matColumnDef="DataNoti">
          <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fecha Notificación</mat-header-cell>
          <mat-cell *matCellDef="let row" [ngClass]="{'deadline' : isOldDataNoti(row)}">{{row.DataNoti | shortDate}}</mat-cell>
      </ng-container>

       <!-- Data Column -->
       <ng-container matColumnDef="DataNotiPropuesta">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Notificación Propuesta</mat-header-cell>
        <mat-cell *matCellDef="let row" [ngClass]="{'deadline' : isOldDataNotiPropuesta(row)}">{{row.DataNotiPropuesta | shortDate}}</mat-cell>
    </ng-container>

     <!-- Data Column -->
     <ng-container matColumnDef="DataNotiReso">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Notificación Resolución</mat-header-cell>
      <mat-cell *matCellDef="let row" [ngClass]="{'deadline' : isOldDataNotiReso(row)}">{{row.DataNotiReso | shortDate}}</mat-cell>
  </ng-container>

   <!-- Data Column -->
   <ng-container matColumnDef="DataNotiReposicio">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Notificación Resposición</mat-header-cell>
    <mat-cell *matCellDef="let row" [ngClass]="{'deadline' : isOldDataNotiReposicio(row)}">{{row.DataNotiReposicio | shortDate}}</mat-cell>
</ng-container>
        

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>        
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset"></mat-row>
    </mat-table>
</div>
</app-dialog-body>
