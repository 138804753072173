<app-dialog-header label="Càrrega C60" isCloseButton="false" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Banc) || !this.dialogData.isReadOnly || this.YaProcesado"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Banc) || this.dialogData.isReadOnly"></app-tool-button>
  <!-- <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Banc) || this.dialogData.isReadOnly"></app-tool-button> -->
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
  <app-tool-button text="Detall" i18n-text (click)="this.carregaDetall();" color="has-color-blue" icon="fa-receipt" *ngIf="this.secure_id !== null"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>

    <!-- 
    id: number;
    codiCarrega: string;
    descripcioCarrega: string;
    nomFitxer: string;
    directoriFitxer: string;
    situacio: string; -->
    <div class="row">
      <app-form-text-edit id="banc-carregues-c60-editor-codi" class="col-sm-4" i18n-label label="Codi càrrega" [(value)]="this.bancCarreguesC60.codiCarrega" required maxLength="50" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-text-edit id="banc-carregues-c60-editor-descripcio" class="col-sm-4" i18n-label label="Descripció càrrega" [(value)]="this.bancCarreguesC60.descripcioCarrega" required maxLength="50" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-form-date-edit id="banc-carregues-c60-editor-data" class="col-sm-4" i18n-label label="Data liquidació" id="dataLiquidacio" [(value)]="this.bancCarreguesC60.dataComptabilitzacio" type="calendar" [initialValue]="null" [isReadOnly]="this.dialogData.isReadOnly"></app-form-date-edit>
    </div>
    <div class="row">
      <!-- <app-form-text-edit id="banc-carregues-c60-editor-dir" class="col-sm-4" i18n-label label="Directori fitxer" [(value)]="this.bancCarreguesC60.directoriFitxer" required maxLength="50"></app-form-text-edit> -->
      <app-form-text-edit id="banc-carregues-c60-editor-nom" class="col-sm-4" i18n-label label="Nom fitxer" [(value)]="this.bancCarreguesC60.nomFitxer" required maxLength="50" [isReadOnly]="this.dialogData.isReadOnly"></app-form-text-edit>
      <app-upload-tool-button-component text="Afegir" i18n-text (uploadFile)="this.uploadFile($event)"  color="has-color-blue" icon="fas fa-file-medical"  [disabled]="this.dialogData.isReadOnly"></app-upload-tool-button-component>
      <!-- <div class="col-md-8">
        <app-form-file-upload label="Fitxer" i18n-label labelHidden="true" (fileReady)="fileReady($event)"></app-form-file-upload>
      </div> -->
      <div class="col-sm-2"><i class="{{this.bancCarreguesC60.situacioClass}}" title="{{this.bancCarreguesC60.situacioTitle}}" i18n-title></i></div>

    </div>
  </form>
</app-dialog-body>
