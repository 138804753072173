var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from "@angular/common/http";
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";
var defaultSortParam = 'expedient';
var defaultSortDirection = 'desc';
var CargaMatriculasFilterService = /** @class */ (function (_super) {
    __extends(CargaMatriculasFilterService, _super);
    function CargaMatriculasFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CargaMatriculasFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('nomesNoves', filter.nomesNoves ? 'true' : null)
            .append('nomesVelles', filter.nomesVelles ? 'true' : null)
            .append('exp', filter.exp)
            .append('matricula', filter.matricula)
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return CargaMatriculasFilterService;
}(CustomFilterService));
export { CargaMatriculasFilterService };
