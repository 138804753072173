/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tool-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tool-button.component";
var styles_ToolButtonComponent = [i0.styles];
var RenderType_ToolButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToolButtonComponent, data: {} });
export { RenderType_ToolButtonComponent as RenderType_ToolButtonComponent };
function View_ToolButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "navbar-brand-full"], ["height", "20px"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; _ck(_v, 0, 0, currVal_0); }); }
function View_ToolButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconClass; _ck(_v, 0, 0, currVal_0); }); }
export function View_ToolButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "button", [["class", "actions-bar__button"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], [[8, "className", 0], [8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToolButtonComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToolButtonComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "actions-bar__button-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_5 = (_co.src !== undefined); _ck(_v, 5, 0, currVal_5); var currVal_6 = (_co.src === undefined); _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.badgeClass + " ") + _co.badgeColor); var currVal_2 = !_co.badgeActive; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.badge; _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.colorClass; _ck(_v, 3, 0, currVal_4); var currVal_7 = _co.text; _ck(_v, 9, 0, currVal_7); }); }
export function View_ToolButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tool-button", [], null, null, null, View_ToolButtonComponent_0, RenderType_ToolButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToolButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToolButtonComponentNgFactory = i1.ɵccf("app-tool-button", i3.ToolButtonComponent, View_ToolButtonComponent_Host_0, { disabled: "disabled", text: "text", color: "color", icon: "icon", badge: "badge", badgeActive: "badgeActive", badgeColor: "badgeColor", propagate: "propagate", isFile: "isFile", src: "src" }, { click: "click" }, []);
export { ToolButtonComponentNgFactory as ToolButtonComponentNgFactory };
