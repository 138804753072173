import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseDgtVehiculo } from '../models/vehiculoDgt.model';
import { ResponseDgtIndicador } from '../models/indicador.model';
import { ArrendatarioModel } from '../models/arrendatario.model';

@Injectable()
export class VehiculoParserService {
    vehiculo: ResponseDgtVehiculo;
    basePathResources = 'assets/images/';
    constructor(private i18n: I18n) {
        this.inicializeObject();
    }
    inicializeObject() {
        this.vehiculo = Builder.newResponseDgtVehiculo();
    }


    public parsebjectMatricula(data: any, fechaDenuncia: Date): ResponseDgtVehiculo {

        this.inicializeObject();
        const datosGenerales = data.find(x => x.nomOriginal === 'datosGenerales');
        if (datosGenerales !== undefined && datosGenerales !== null ) {
            // region vehiculo
          const descripcionVehiculo = datosGenerales.fills.find(x => x.nomOriginal === 'descripcionVehiculo');
          if (descripcionVehiculo !== undefined && descripcionVehiculo !== null ) {
            const marca = descripcionVehiculo.fills.find(x => x.nomOriginal === 'marca');
            if ( marca !== undefined  && marca !== null ) {
                const descMarca = marca.fills.find(x => x.nomOriginal === 'descripcion');
                if ( descMarca !== undefined && descMarca !== null ) {
                    this.vehiculo.marca = descMarca.valor;
                }
            }
            const modelo = descripcionVehiculo.fills.find(x => x.nomOriginal === 'modelo');
            if (modelo !== undefined && modelo !== null) {
                this.vehiculo.modelo = modelo.valor;
            }
            const tipoVehiculo = descripcionVehiculo.fills.find(x => x.nomOriginal === 'tipoVehiculo');
            if ( tipoVehiculo !== undefined  && tipoVehiculo !== null ) {
                const descTipoVeh = tipoVehiculo.fills.find(x => x.nomOriginal === 'descripcion');
                if ( descTipoVeh !== undefined && descTipoVeh !== null ) {
                    this.vehiculo.tipoVehiculo = descTipoVeh.valor;
                }
                const codigoTipoVeh = tipoVehiculo.fills.find(x => x.nomOriginal === 'codigo');
                if ( codigoTipoVeh !== undefined && codigoTipoVeh !== null ) {
                    this.vehiculo.codigoTipoVeh = codigoTipoVeh.valor;
                }
            }
          }
          // region domicilio vehiculo
          const domicilioVehiculo = datosGenerales.fills.find(x => x.nomOriginal === 'domicilioVehiculo');
          if (domicilioVehiculo !== undefined && domicilioVehiculo !== null ) {
            const calle = domicilioVehiculo.fills.find(x => x.nomOriginal === 'calle');
            if ( calle !== undefined && calle !== null ) {
                this.vehiculo.domicilioVehiculo = calle.valor;
            }

            const codPostal = domicilioVehiculo.fills.find(x => x.nomOriginal === 'codPostal');
            if ( codPostal !== undefined && codPostal !== null ) {
                this.vehiculo.cpVehiculo = codPostal.valor;
            }

            const municipio = domicilioVehiculo.fills.find(x => x.nomOriginal === 'municipio');
            if ( municipio !== undefined && municipio !== null ) {
                this.vehiculo.municipioVehiculo = municipio.valor;
            }
            const provincia = domicilioVehiculo.fills.find(x => x.nomOriginal === 'provincia');
            if ( provincia !== undefined && provincia !== null ) {
                const descripcion = provincia.fills.find(x => x.nomOriginal === 'descripcion');
                if ( descripcion !== undefined && descripcion !== null ) {
                    this.vehiculo.provinciaVehiculo = descripcion.valor;
                }
            }
        }

        // region domicilio vehiculo
        const indicadores = datosGenerales.fills.find(x => x.nomOriginal === 'indicadores');
        if (indicadores !== undefined && indicadores !== null ) {
          indicadores.fills.forEach(indicador => {
              if (indicador.valor !== undefined && indicador.valor !== null && indicador.valor !== ''
               && indicador.nomOriginal !== 'limitacionDisposicion' && indicador.nomOriginal !== 'reformas' && indicador.nomOriginal !== 'renting' && indicador.nomOriginal !== 'subasta') {
                  let ind = new ResponseDgtIndicador();
                  ind.indicador = indicador.nom;

                  if ( indicador.valor === 'S') {
                    ind.valor = this.i18n('SI');
                  } else if ( indicador.valor === 'N') {
                    ind.valor = this.i18n('NO');
                  } else {
                    ind.valor = indicador.valor;
                  }
                  this.vehiculo.indicadores.push(ind);
              }
          });
      }
          // region titular
          const titular = datosGenerales.fills.find(x => x.nomOriginal === 'titular');
          if (titular !== undefined && titular !== null ) {
            const datosPersona = titular.fills.find(x => x.nomOriginal === 'datosPersona');
            if ( datosPersona !== undefined  && datosPersona !== null ) {
                const identificacionPFisica = datosPersona.fills.find(x => x.nomOriginal === 'identificacionPFisica');
                if ( identificacionPFisica !== undefined  && identificacionPFisica !== null ) {
                    const indicadorDevPF = identificacionPFisica.fills.find(x => x.nomOriginal === 'indicadorDevPF');
                    if ( indicadorDevPF !== undefined && indicadorDevPF !== null ) {
                        // nos indica si es empresa o persona fisica
                        // se comprueba validando que el xml contega el dni de persona fisica
                        const tieneDNI = identificacionPFisica.fills.find(x => x.nomOriginal === 'idDocumento');
                        this.vehiculo.personaFisica = (tieneDNI !== undefined && tieneDNI !== null && tieneDNI !== '' && tieneDNI.valor !== null);
                        if ( this.vehiculo.personaFisica ) {
                            const apellido1 = identificacionPFisica.fills.find(x => x.nomOriginal === 'apellido1');
                            if ( apellido1 !== undefined && apellido1 !== null ) {
                                this.vehiculo.conductorApellido1 = apellido1.valor;
                            }
                            const apellido2 = identificacionPFisica.fills.find(x => x.nomOriginal === 'apellido2');
                            if ( apellido2 !== undefined && apellido2 !== null ) {
                                this.vehiculo.conductorApellido2 = apellido2.valor;
                            }
                            const fechaNacimiento = identificacionPFisica.fills.find(x => x.nomOriginal === 'fechaNacimiento');
                            if ( fechaNacimiento !== undefined && fechaNacimiento !== null ) {
                                this.vehiculo.fechaNacimientoConductor = fechaNacimiento.valor;
                            }
                            const idDocumento = identificacionPFisica.fills.find(x => x.nomOriginal === 'idDocumento');
                            if ( idDocumento !== undefined && idDocumento !== null ) {
                                this.vehiculo.conductorNif = idDocumento.valor;
                            }
                            const nombre = identificacionPFisica.fills.find(x => x.nomOriginal === 'nombre');
                            if ( nombre !== undefined && nombre !== null ) {
                                this.vehiculo.conductorNombre = nombre.valor;
                            }

                        } else {
                            const personaJuridica = datosPersona.fills.find(x => x.nomOriginal === 'personaJuridica');
                            if ( personaJuridica !== undefined && personaJuridica !== null ) {
                                const cif = personaJuridica.fills.find(x => x.nomOriginal === 'cif');
                                if ( cif !== undefined && cif !== null ) {
                                    this.vehiculo.empresaCif = cif.valor;
                                }
                                const razonSocial = personaJuridica.fills.find(x => x.nomOriginal === 'razonSocial');
                                if ( razonSocial !== undefined && razonSocial !== null ) {
                                    this.vehiculo.empresaRazonSocial = razonSocial.valor;
                                }
                            }
                        }
                    }
                }

                // domicilio
                const domicilio = titular.fills.find(x => x.nomOriginal === 'domicilio');
                if ( domicilio !== undefined && domicilio !== null ) {
                    const calle = domicilio.fills.find(x => x.nomOriginal === 'calle');
                    if ( calle !== undefined && calle !== null ) {
                        this.vehiculo.domicilio = calle.valor;
                    }

                    const codPostal = domicilio.fills.find(x => x.nomOriginal === 'codPostal');
                    if ( codPostal !== undefined && codPostal !== null ) {
                        this.vehiculo.codigoPostal = codPostal.valor;
                    }

                    const municipio = domicilio.fills.find(x => x.nomOriginal === 'municipio');
                    if ( municipio !== undefined && municipio !== null ) {
                        this.vehiculo.municipio = municipio.valor;
                    }
                    const provincia = domicilio.fills.find(x => x.nomOriginal === 'provincia');
                    if ( provincia !== undefined && provincia !== null ) {
                        const descripcion = provincia.fills.find(x => x.nomOriginal === 'descripcion');
                        if ( descripcion !== undefined && descripcion !== null ) {
                            this.vehiculo.provincia = descripcion.valor;
                        }
                    }
                }
            }
          }
        }

        // region itv

        const datosITVReformas = data.find(x => x.nomOriginal === 'datosITVReformas');
        if (datosITVReformas !== undefined && datosITVReformas !== null ) {
            const listaItvs = datosITVReformas.fills.find(x => x.nomOriginal === 'listaItvs');
            if (listaItvs !== undefined && listaItvs !== null ) {
                const itvs = listaItvs.fills;
                if (itvs !== undefined && itvs !== null ) {
                    itvs.forEach(itv => {
                        const fechaItv = itv.fills.find(x => x.nomOriginal === 'fechaItv');
                        if (fechaItv !== undefined && fechaItv !== null ) {
                            if ( this.vehiculo.fechaItv === undefined || this.vehiculo.fechaItv === null) {
                                this.vehiculo.fechaItv = fechaItv.valor;
                                // asignamos valores itv
                                const fechaCaducidad = itv.fills.find(x => x.nomOriginal === 'fechaCaducidad');
                                if (fechaCaducidad !== undefined && fechaCaducidad !== null ) {
                                    this.vehiculo.fechaCaducidadItv = fechaCaducidad.valor;
                                }
                                const fechaFinAnterior = itv.fills.find(x => x.nomOriginal === 'fechaFinAnterior');
                                if (fechaFinAnterior !== undefined && fechaFinAnterior !== null ) {
                                    this.vehiculo.fechaAnterirorItv = fechaFinAnterior.valor;
                                }
                                const resultadoItv = itv.fills.find(x => x.nomOriginal === 'resultadoItv');
                                if (resultadoItv !== undefined && resultadoItv !== null ) {
                                    const descripcion = resultadoItv.fills.find(x => x.nomOriginal === 'descripcion');
                                    if (descripcion !== undefined && descripcion !== null ) {
                                        this.vehiculo.resultadoItv = descripcion.valor;
                                    }
                                }
                            } else if ( this.vehiculo.fechaItv < fechaItv.valor ) {
                                this.vehiculo.fechaItv = fechaItv.valor;
                                // asignamos valores itv
                                const fechaCaducidad = itv.fills.find(x => x.nomOriginal === 'fechaCaducidad');
                                if (fechaCaducidad !== undefined && fechaCaducidad !== null ) {
                                    this.vehiculo.fechaCaducidadItv = fechaCaducidad.valor;
                                }
                                const fechaFinAnterior = itv.fills.find(x => x.nomOriginal === 'fechaFinAnterior');
                                if (fechaFinAnterior !== undefined && fechaFinAnterior !== null ) {
                                    this.vehiculo.fechaAnterirorItv = fechaFinAnterior.valor;
                                }
                                const resultadoItv = itv.fills.find(x => x.nomOriginal === 'resultadoItv');
                                if (resultadoItv !== undefined && resultadoItv !== null ) {
                                    const descripcion = resultadoItv.fills.find(x => x.nomOriginal === 'descripcion');
                                    if (descripcion !== undefined && descripcion !== null ) {
                                        this.vehiculo.resultadoItv = descripcion.valor;
                                    }
                                }
                            }
                        }
                    });
                }
            }
        }

        const datosSeguros = data.find(x => x.nomOriginal === 'datosSeguros');
        if (datosSeguros !== undefined && datosSeguros !== null ) {
            const listaSeguros = datosSeguros.fills.find(x => x.nomOriginal === 'listaSeguros');
            if (listaSeguros !== undefined && listaSeguros !== null ) {
                const seguros = listaSeguros.fills;
                if (seguros !== undefined && seguros !== null ) {
                    seguros.forEach(seguro => {
                        const fechaFin = seguro.fills.find(x => x.nomOriginal === 'fechaFin');
                        if (fechaFin === undefined || fechaFin === null || fechaFin.valor === null) {
                            this.vehiculo.seguroEnVigor = true;
                            const fechaInicio = seguro.fills.find(x => x.nomOriginal === 'fechaInicio');
                            if (fechaInicio !== undefined && fechaInicio !== null ) {
                                this.vehiculo.seguroFechaInicio = fechaInicio.valor;
                            }
                            const entidad = seguro.fills.find(x => x.nomOriginal === 'entidad');
                            if (entidad !== undefined && entidad !== null ) {
                                const descripcion = entidad.fills.find(x => x.nomOriginal === 'descripcion');
                                if (descripcion !== undefined && descripcion !== null ) {
                                    this.vehiculo.seguroDescripcion = descripcion.valor;
                                }
                            }
                        }
                        if (this.vehiculo.seguroEnVigor === undefined || this.vehiculo.seguroEnVigor === null ||  this.vehiculo.seguroEnVigor === false
                            && fechaFin !== undefined && fechaFin !== null
                            && fechaFin.valor !== undefined && fechaFin.valor !== null && this.vehiculo.seguroFechaFin < fechaFin.value ) {
                            this.vehiculo.seguroFechaFin = fechaFin.valor;
                            const fechaInicio = seguro.fills.find(x => x.nomOriginal === 'fechaInicio');
                            if (fechaInicio !== undefined && fechaInicio !== null ) {
                                this.vehiculo.seguroFechaInicio = fechaInicio.valor;
                            }
                            const entidad = seguro.fills.find(x => x.nomOriginal === 'entidad');
                            if (entidad !== undefined && entidad !== null ) {
                                const descripcion = entidad.fills.find(x => x.nomOriginal === 'descripcion');
                                if (descripcion !== undefined && descripcion !== null ) {
                                    this.vehiculo.seguroDescripcion = descripcion.valor;
                                }
                            }
                        }
                    });
                }
            }
        }
        const etiquetaAmbiental = data.find(x => x.nomOriginal === 'etiquetaAmbiental');
        if (etiquetaAmbiental !== undefined && etiquetaAmbiental !== null
            && etiquetaAmbiental.fills !== undefined && etiquetaAmbiental.fills !== null &&  etiquetaAmbiental.fills.length > 0) {
                const idSomintec = etiquetaAmbiental.fills.find(x => x.nomOriginal === 'idSomintec');
                if (idSomintec !== undefined && idSomintec !== null ) {
                    this.vehiculo.somIdEtiqueta = idSomintec.valor;
                }
                const imgEtiqueta = etiquetaAmbiental.fills.find(x => x.nomOriginal === 'imgEtiqueta');
                if (imgEtiqueta !== undefined && imgEtiqueta !== null ) {
                    this.vehiculo.pathImagenEtiqueta = this.basePathResources + imgEtiqueta.valor;
                }
        }

        // region responsables
        const datosResponsables = data.find(x => x.nomOriginal === 'datosResponsables');
        if (datosResponsables !== undefined && datosResponsables !== null && datosResponsables.fills !== undefined && datosResponsables.fills !== null) {
            const listaArrendatarios = datosResponsables.fills.find(x => x.nomOriginal === 'listaArrendatarios');
            if (listaArrendatarios !== undefined && listaArrendatarios !== null && listaArrendatarios.fills !== undefined && listaArrendatarios.fills !== null
                && listaArrendatarios.fills.length > 0) {
                if(fechaDenuncia !== null && fechaDenuncia !== undefined){
                    let found = false;
                    let i = 0;
                    while (i < listaArrendatarios.fills.length && !found) {
                        const arrendatario = listaArrendatarios.fills[i];
                        if (arrendatario !== undefined && arrendatario !== null ) {
                            let arren = new ArrendatarioModel();
                            const fechaInicio = arrendatario.fills.find(x => x.nomOriginal === 'fechaInicio');
                            if (fechaInicio !== undefined && fechaInicio !== null ) {
                                arren.fechaInicio = fechaInicio.valor;
                            }
                            const fechaFin = arrendatario.fills.find(x => x.nomOriginal === 'fechaFin');
                            if (fechaFin !== undefined && fechaFin !== null ) {
                                arren.fechaFin = fechaFin.valor;
                            }
                            
                            const idDocumento = arrendatario.fills.find(x => x.nomOriginal === 'idDocumento');
                            if (idDocumento !== undefined && idDocumento !== null ) {
                                arren.idDocumento = idDocumento.valor;
                            }
        
                            const filiacion = arrendatario.fills.find(x => x.nomOriginal === 'filiacion');
                            if (filiacion !== undefined && filiacion !== null ) {
                                arren.filiacion = filiacion.valor;
                            }
                            
                            if (new Date(arren.fechaInicio) <= new Date(fechaDenuncia) && ( arren.fechaFin === undefined || arren.fechaFin === null  || new Date(arren.fechaFin) >= new Date(fechaDenuncia)) ) {
                                found = true;
                                this.vehiculo.arrendatario = arren;
                              }                           
                        }                      
                        i++;
                    }
                }else{
                    const arrendatario = listaArrendatarios.fills[0];
                    if (arrendatario !== undefined && arrendatario !== null ) {
                        let arren = new ArrendatarioModel();
                        const fechaInicio = arrendatario.fills.find(x => x.nomOriginal === 'fechaInicio');
                        if (fechaInicio !== undefined && fechaInicio !== null ) {
                            arren.fechaInicio = fechaInicio.valor;
                        }
    
                        const idDocumento = arrendatario.fills.find(x => x.nomOriginal === 'idDocumento');
                        if (idDocumento !== undefined && idDocumento !== null ) {
                            arren.idDocumento = idDocumento.valor;
                        }
    
                        const filiacion = arrendatario.fills.find(x => x.nomOriginal === 'filiacion');
                        if (filiacion !== undefined && filiacion !== null ) {
                            arren.filiacion = filiacion.valor;
                        }
                        this.vehiculo.arrendatario = arren;
                    }
                }
                
            }
        }
      return this.vehiculo;
    }
}
