import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { Builder } from 'path-shared/models/builder';
import { ToastrService } from 'ngx-toastr';
import { TipusTiquetsFilter } from './models/tipus-tiquets-filter';
import { TipusTiquetsTableComponent } from './components/tipus-tiquets-table/tipus-tiquets-table.component';
import { TipusTiquetsService } from './services/tipus-tiquets.service';
import { TipusTiquetsEditorComponent } from './components/tipus-tiquets-editor/tipus-tiquets-editor.component';
import { TipusTiquetsFilterComponent } from './components/tipus-tiquets-filter/tipus-tiquets-filter.component';

@Component({
  selector: 'app-tipus-tiquets',
  templateUrl: './tipus-tiquets.component.html',
  styleUrls: ['./tipus-tiquets.component.scss']
})
export class TipusTiquetsComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: TipusTiquetsFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  @ViewChild(TipusTiquetsTableComponent) tipusTiquetsTable: TipusTiquetsTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<TipusTiquetsComponent>,
    private tipusTiquetsService: TipusTiquetsService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new TipusTiquetsFilter('codi', 'asc');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.Colors);
    this.tipusTiquetsTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.tipusTiquetsTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Tipus de tiquet'));
    }
  }

  edit() {
    const idSeleccionat: string = this.tipusTiquetsTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

    const ref = this.dialog.open(TipusTiquetsEditorComponent, {
      width: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.tipusTiquetsTable.load(); }
    });
  }

  refresh() {
    this.tipusTiquetsTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(TipusTiquetsFilterComponent, {
      width: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.tipusTiquetsTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: TipusTiquetsFilter) => {
      this.tipusTiquetsTable.filter.set(filter);
      this.tipusTiquetsTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  // generateExcel() {
  //   this.colorsTable.generateExcel();
  // }


  publicar() {
    this.tipusTiquetsService.publicar();

    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol publicar aquests tipus de tiquets?'))
    .then((confirmed) => {
        if (confirmed) {
          this.tipusTiquetsService.publicar().subscribe(result => {
            this.toastr.success(this.i18n('tipus de tiquets publicats correctament'));
          });
        }
      });
  }

}
