import { GenericFileDisplayerPageComponent } from 'path-shared/components/generic-file-displayer/components/generic-file-displayer-page/generic-file-displayer-page.component';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatPaginator, MatSort } from '@angular/material';
import { Resolucion } from 'app/sections/notificacions/models/NotificacionDenuncia/resolucion';
import { ResolucionFilter } from 'app/sections/notificacions/models/NotificacionDenuncia/resolucion-filter';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResolucionsFilterService } from 'app/sections/notificacions/services/resolucions-filter.service';
import { ListadoResolucionesFilterComponent } from './listado-resoluciones-filter/listado-resoluciones-filter.component';

@Component({
  selector: 'app-listado-resoluciones',
  templateUrl: './listado-resoluciones.component.html',
  styleUrls: ['./listado-resoluciones.component.scss']
})
export class ListadoResolucionesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Output() notify: EventEmitter<ResolucionFilter> = new EventEmitter<ResolucionFilter>();

  dataSource: CustomDataSource<Resolucion,ResolucionFilter>;
  filter: ResolucionFilter= new ResolucionFilter('exp', 'asc');
  displayedColumns = ['element','nom','direccio','poblacio','cp','provincia'];

  constructor(
    private dialog:MatDialog,
    public auth:AuthorizationService,
  ){}

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });
    
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated())).subscribe();

    this.load();
  }

  load(){
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  ngOnInit(): void {
    this.sort.disableClear = true;
    this.dataSource = CustomDataSource.create<Resolucion,ResolucionFilter>(this.paginator, new ResolucionsFilterService(), 'urlGetResolucions', 'urlGetResolucionsCount');
    this.filter.paginator.init(this.sort, this.paginator);

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
      }
    });
  }

  continue(){
    this.notify.emit(this.filter);
  }

  onFilterClick(){
    const DialogRef= this.dialog.open(ListadoResolucionesFilterComponent, {
      width:'50%',
      data:this.filter
    });
    DialogRef.componentInstance.notify.subscribe((filter:ResolucionFilter)=>{
      this.filter=filter;
      this.load();
    });
  }

}
