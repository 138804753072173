import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { FormControl } from '@angular/forms';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { SeguretatUsuarisFilter } from '../models/seguretat-usuaris-filter';
import { SeguretatUsuarisTableComponent } from '../components/seguretat-usuaris-table/seguretat-usuaris-table.component';
import { SeguretatUsuarisEditorComponent } from '../components/seguretat-usuaris-editor/seguretat-usuaris-editor.component';
import { SeguretatUsuarisFilterComponent } from '../components/seguretat-usuaris-filter/seguretat-usuaris-filter.component';
import { SeguretatUsuarisService } from '../services/seguretat-usuaris.service';
import { SeguretatUsuarisPerfils } from '../models/seguretat-usuaris-perfils.model';
import { InstitucionsService } from 'path-shared/services/institucions/institucions.service';
import { Instituciones } from 'path-shared/models/instituciones';
import { SeguretatGrupsService } from '../../seguretat-grups/services/seguretat-grups.service';
import { SeguretatUsuarisAgents } from '../models/seguretat-usuaris-agents.model';

@Component({
  selector: 'app-seguretat-usuaris-page',
  templateUrl: './seguretat-usuaris-page.component.html',
  styleUrls: ['./seguretat-usuaris-page.component.scss']
})
export class SeguretatUsuarisPageComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selected = new FormControl(0);
  filter: SeguretatUsuarisFilter = new SeguretatUsuarisFilter('CodUser', 'desc');
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  perfils = [];
  instituciones: Instituciones[] = [];
  segPantallas = [];
  segGrupos = [];
  agentes: SeguretatUsuarisAgents[] = [];
  institucioAssignada: number;

  @ViewChild(SeguretatUsuarisTableComponent) seguretatUsuarisTable: SeguretatUsuarisTableComponent;

  constructor(
    public authService: AuthorizationService,
    private not: NotificationService,
    private dialog: MatDialog,
    private i18n: I18n,
    private seguretatUsuarisService: SeguretatUsuarisService,
    private institucionService: InstitucionsService,
    private grupsService: SeguretatGrupsService,
  ) { }

  ngOnInit() {
    if (this.authService.CheckUserPermission(this.authService.Pages.SeguretatUsuaris)) {
      this.loadPerfiles();
      this.loadInstituciones();
      this.loadPantallas();
      this.loadGrupos();
      this.loadAgentes();
      this.loadInstitucioAssignada();

      this.seguretatUsuarisTable.itemClicked.subscribe(secure_id => {
        this.update(secure_id);
      });
    }
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Seguretat Usuaris'));
    }
  }

  update(secure_id: string) {
    const ref = this.dialog.open(SeguretatUsuarisEditorComponent, {
      width: '70%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'id': secure_id,
        'perfils': this.perfils,
        'instituciones': this.instituciones,
        'segPantallas': this.segPantallas,
        'segGrupos': this.segGrupos,
        'agentes': this.agentes,
        'institucioAssignada': this.institucioAssignada
        }
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.seguretatUsuarisTable.load(); }
    });
  }

  create() {
    const ref = this.dialog.open(SeguretatUsuarisEditorComponent, {
      width: '60%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'perfils': this.perfils,
        'instituciones': this.instituciones,
        'segPantallas': this.segPantallas,
        'segGrupos': this.segGrupos,
        'agentes': this.agentes,
        'institucioAssignada': this.institucioAssignada
        }
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.seguretatUsuarisTable.load(); }
    });
  }

  refresh() {
    this.seguretatUsuarisTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(SeguretatUsuarisFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.seguretatUsuarisTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: SeguretatUsuarisFilter) => {
      this.seguretatUsuarisTable.filter.set(filter);
      this.seguretatUsuarisTable.load();
    });
  }

  generateExcel() {
    this.seguretatUsuarisTable.generateExcel();
  }

  //#region Load combos

  loadPerfiles() {
    this.seguretatUsuarisService.getSeguretatUsuarisPerfils().subscribe((perfils: SeguretatUsuarisPerfils[]) => {
      perfils.forEach(perfil => {
        this.perfils.push({'key': perfil.codPerfil, 'value': perfil.descPerfil});
      });
    });
  }

  loadInstituciones() {
    this.institucionService.getInstitucions().subscribe((instituciones: Instituciones[]) => {
      this.instituciones = instituciones;
    });
  }

  loadPantallas() {
    this.grupsService.getSeguretatPantallas().subscribe(data => {
      this.segPantallas = data;
    });
  }

  loadGrupos() {
    this.seguretatUsuarisService.getSeguretatUsuarisGrups().subscribe(data => {
      this.segGrupos = data;
    });
  }

  loadAgentes() {
    this.seguretatUsuarisService.getSeguretatUsuarisAgents().subscribe((agentes: SeguretatUsuarisAgents[]) => {
      this.agentes = agentes;
    });
  }

  loadInstitucioAssignada() {
    this.seguretatUsuarisService.getSeguretatUsuarisInstitucionAsignada().subscribe(institucioAssignada => {
      this.institucioAssignada = institucioAssignada;
    });
  }

  //#endregion

}
