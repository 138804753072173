import { ConfirmacionBoeDto } from './../../models/confirmacion-boe.model';
import { BoeService } from './../../services/boe.service';
import { subscribeOn } from 'rxjs/operators';
import { ConfirmacionListadoComponent } from './../../components/confirmacion/confirmacion-listado/confirmacion-listado.component';
import { ConfirmacionBoeFilterComponent } from './../../components/confirmacion/confirmacion-boe-filter/confirmacion-boe-filter.component';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { ConfirmacionBOEFilter } from '../../models/confirmacion-boe-filter.model';
import { DetalleConfirmacionBoeDto } from '../../models/confirmacion-boe-detalle.model';
import { ConfirmacionDetalleBOEFilter } from '../../models/confirmacion-detalle-boe-filter.model';
import { RowsDetalleConfirmacionComponent } from '../../components/confirmacion/confirmacion-boe-detalle/rows-detalle-confirmacion/rows-detalle-confirmacion.component';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html',
  styleUrls: ['./confirmacion.component.scss']
})
export class ConfirmacionComponent implements OnInit {
  filter: ConfirmacionBOEFilter = new ConfirmacionBOEFilter('lote', 'desc');
  filterRows: ConfirmacionDetalleBOEFilter = new ConfirmacionDetalleBOEFilter('expediente', 'desc');
  //filterDetalle: ConfirmacionDetalleBOEFilter = new ConfirmacionDetalleBOEFilter('expediente', 'desc');
  @ViewChild('listado') ConfirmacionTable: ConfirmacionListadoComponent;
  @ViewChild('listadoRows') listadoRows: RowsDetalleConfirmacionComponent;

  @Output() notifyDetalleFilter: EventEmitter<ConfirmacionDetalleBOEFilter> = new EventEmitter<ConfirmacionDetalleBOEFilter>();
  public rowSelectedObj: ConfirmacionBoeDto;
  public details: DetalleConfirmacionBoeDto[];
  constructor(
    private not: NotificationService,
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private boeService: BoeService) {
      this.filter = new ConfirmacionBOEFilter('', '');
      this.filterRows = new ConfirmacionDetalleBOEFilter('', '');
      //this.filterDetalle = new ConfirmacionDetalleBOEFilter('', '');
      this.details=[];
    }

  ngOnInit() {
  }
  referesh(){
    this.ConfirmacionTable.load();
  }
  onFilterClicked(){
    const ref = this.dialog.open(ConfirmacionBoeFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.ConfirmacionTable.filter
      }
    });
  }
  rowSelected(row){
    // this.details= [];
    // this.rowSelectedObj=undefined;
    // this.boeService.getDetail(row.secureId).subscribe(x=>{
      this.rowSelectedObj = row;
      this.filterRows.secureId=row.secureId;
      this.listadoRows.update();
    //   if(this.filterDetalle === undefined){
    //     this.filterDetalle = new ConfirmacionDetalleBOEFilter('expediente', 'desc');
    //   }
    // this.filterDetalle.secureId=  row.secureId;
    // this.filterDetalle.updated();
    // this.notifyDetalleFilter.emit(this.filterDetalle);
    // this.ConfirmacionDetalleTable.load();
    // this.rowSelectedObj = row;

    //   this.details=x;
    // });
  }
}
