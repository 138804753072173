<div class="card">
  <div class="card-body p-3 d-flex align-items-center">
    <i class="{{ this.icon }} bg-primary p-3 font-2xl mr-3"></i>
    <div class="w-100">
      <div class="text-muted text-uppercase font-weight-bold small">{{ this.label }}</div>
      <div class="row justify-content-between">
          <div class="col"><span i18n>Pendents</span></div>
          <div class="col-3"><span class="badge badge-secondary">{{this.pendingFiles}}</span></div>
      </div>
      <div class="row" (click)="openErroneos()" style="cursor: pointer;">
        <div class="col"><span i18n>Error</span></div>
        <div class="col-3"><span class="badge badge-danger">{{this.errorFiles}}</span></div>
      </div>
      <div class="row" *ngIf="this.contadoresDuplicados !== undefined && this.contadoresDuplicados !== null && this.nom ==='PDA'" (click)="openDuplicados()"  style="cursor: pointer;">
        <div class="col"><span i18n>Contadors duplicats</span></div>
        <div class="col-3"><span class="badge badge-danger">{{this.contadoresDuplicados}}</span></div>
      </div>
      <div class="row" *ngIf="this.pendingFilesImg !== undefined && this.pendingFilesImg !== null && this.nom ==='Delta Car'" (click)="openPendentsImatge()"  style="cursor: pointer;">
        <div class="col"><span i18n>Pendents imatge</span></div>
        <div class="col-3"><span class="badge badge-danger">{{this.pendingFilesImg}}</span></div>
      </div>
  </div>
</div>
