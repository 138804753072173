/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./single-image-viewer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../toolbar/toolbar/toolbar.component.ngfactory";
import * as i3 from "../../../toolbar/toolbar/toolbar.component";
import * as i4 from "../../../toolbar/tool-button/tool-button.component.ngfactory";
import * as i5 from "../../../toolbar/tool-button/tool-button.component";
import * as i6 from "../../../helpers/matricula/matricula.component.ngfactory";
import * as i7 from "../../../helpers/matricula/matricula.component";
import * as i8 from "../../../../services/startup/startup.service";
import * as i9 from "@angular/common";
import * as i10 from "../../../dialog/dialog-header/dialog-header.component.ngfactory";
import * as i11 from "../../../dialog/dialog-header/dialog-header.component";
import * as i12 from "./single-image-viewer.component";
import * as i13 from "../../../../services/images/images.service";
import * as i14 from "@angular/material/dialog";
import * as i15 from "../../photo-box.service";
import * as i16 from "@angular/platform-browser";
var styles_SingleImageViewerComponent = [i0.styles];
var RenderType_SingleImageViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SingleImageViewerComponent, data: {} });
export { RenderType_SingleImageViewerComponent as RenderType_SingleImageViewerComponent };
function View_SingleImageViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-toolbar", [], null, null, null, i2.View_ToolbarComponent_0, i2.RenderType_ToolbarComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-tool-button", [["color", "has-color-blue"], ["icon", "fa-car"], ["text", "Matr\u00EDcula"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveMatricula() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(3, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" })], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = "Matr\u00EDcula"; var currVal_1 = "has-color-blue"; var currVal_2 = "fa-car"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_SingleImageViewerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["style", "margin-left:45%; padding-bottom: 10px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-matricula", [], null, [[null, "canvioPais"], [null, "canvioMatricula"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("canvioPais" === en)) {
        var pd_0 = (_co.setPais($event) !== false);
        ad = (pd_0 && ad);
    } if (("canvioMatricula" === en)) {
        var pd_1 = (_co.setMatricula($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_MatriculaComponent_0, i6.RenderType_MatriculaComponent)), i1.ɵdid(3, 114688, null, 0, i7.MatriculaComponent, [i8.StartupService], { matricula: [0, "matricula"], pais: [1, "pais"], vehicle: [2, "vehicle"], sugPais: [3, "sugPais"], sugMatricula: [4, "sugMatricula"], activarSugerencias: [5, "activarSugerencias"] }, { canvioPais: "canvioPais", canvioMatricula: "canvioMatricula" }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-pen click-icon"], ["style", "margin-left: 10px; cursor:pointer; margin-top: auto; margin-bottom: auto;"], ["title", "Modificar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveMatricula() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.proposta.MATRICULA; var currVal_1 = _co.proposta.pais; var currVal_2 = _co.proposta.VEHICLE; var currVal_3 = _co.proposta.sugPaises; var currVal_4 = _co.proposta.sugMatricules; var currVal_5 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_SingleImageViewerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["img", 1]], null, 0, "img", [], [[8, "className", 0], [8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgClass; var currVal_1 = _co.image_source; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SingleImageViewerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.innerHtml; _ck(_v, 0, 0, currVal_0); }); }
function View_SingleImageViewerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Obrir PDF en una pestanya nova "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image_source; _ck(_v, 0, 0, currVal_0); }); }
function View_SingleImageViewerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "pdf"], ["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SingleImageViewerComponent_5)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SingleImageViewerComponent_6)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.innerHtml; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.pdf; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_SingleImageViewerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { imageRef: 0 }), i1.ɵqud(402653184, 2, { imageDiv: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-dialog-header", [], null, null, null, i10.View_DialogHeaderComponent_0, i10.RenderType_DialogHeaderComponent)), i1.ɵdid(3, 114688, null, 0, i11.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SingleImageViewerComponent_1)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SingleImageViewerComponent_2)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, [[2, 0], ["imageDiv", 1]], null, 2, "div", [["class", "modal-body text-center"], ["id", "imageDiv"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SingleImageViewerComponent_3)), i1.ɵdid(10, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SingleImageViewerComponent_4)), i1.ɵdid(12, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file_name; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.botoMatricula; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.proposta !== undefined); _ck(_v, 7, 0, currVal_2); var currVal_3 = ((_co.proposta === undefined) && !_co.pdf); _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.pdf; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_SingleImageViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-single-image-viewer", [], null, null, null, View_SingleImageViewerComponent_0, RenderType_SingleImageViewerComponent)), i1.ɵdid(1, 4308992, null, 0, i12.SingleImageViewerComponent, [i13.ImagesService, i14.MatDialogRef, i14.MatDialog, i15.PhotoBoxService, i16.DomSanitizer, i14.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SingleImageViewerComponentNgFactory = i1.ɵccf("app-single-image-viewer", i12.SingleImageViewerComponent, View_SingleImageViewerComponent_Host_0, {}, {}, []);
export { SingleImageViewerComponentNgFactory as SingleImageViewerComponentNgFactory };
