/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./propostes-anulacio.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i3 from "../../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i4 from "../../../../../shared/components/toolbar/tool-button/tool-button.component.ngfactory";
import * as i5 from "../../../../../shared/components/toolbar/tool-button/tool-button.component";
import * as i6 from "../../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component.ngfactory";
import * as i7 from "../../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component";
import * as i8 from "../../../../../shared/services/authorization/authorization-service";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../components/propostes-anulacio-table/propostes-anulacio-table.component.ngfactory";
import * as i11 from "../../components/propostes-anulacio-table/propostes-anulacio-table.component";
import * as i12 from "@ngx-translate/i18n-polyfill";
import * as i13 from "../../services/propostes-anulacio.service";
import * as i14 from "../../../../denuncies/services/denuncies.service";
import * as i15 from "./propostes-anulacio.component";
import * as i16 from "../../../../../shared/services/notification/notification.service";
var styles_PropostesAnulacioComponent = [i0.styles];
var RenderType_PropostesAnulacioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PropostesAnulacioComponent, data: {} });
export { RenderType_PropostesAnulacioComponent as RenderType_PropostesAnulacioComponent };
export function View_PropostesAnulacioComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { paginator: 0 }), i1.ɵqud(402653184, 2, { sort: 0 }), i1.ɵqud(402653184, 3, { tablePropostes: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 7, "app-toolbar", [], null, null, null, i2.View_ToolbarComponent_0, i2.RenderType_ToolbarComponent)), i1.ɵdid(4, 114688, null, 0, i3.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "app-tool-button", [["color", "has-color-blue"], ["icon", "fa-sync"], ["text", "Actualitzar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(6, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "app-tool-button", [["color", "has-color-orange"], ["icon", "fa-filter"], ["text", "Filtre"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFilterClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ToolButtonComponent_0, i4.RenderType_ToolButtonComponent)), i1.ɵdid(8, 114688, null, 0, i5.ToolButtonComponent, [], { text: [0, "text"], color: [1, "color"], icon: [2, "icon"] }, { click: "click" }), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-tool-button-permissions", [], null, null, null, i6.View_ToolButtonPermissionsComponent_0, i6.RenderType_ToolButtonPermissionsComponent)), i1.ɵdid(10, 114688, null, 0, i7.ToolButtonPermissionsComponent, [i8.AuthorizationService, i9.MatDialog], { permission: [0, "permission"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-propostes-anulacio-table", [], null, null, null, i10.View_PropostesAnulacioTableComponent_0, i10.RenderType_PropostesAnulacioTableComponent)), i1.ɵdid(12, 4833280, [[3, 4], ["table", 4]], 0, i11.PropostesAnulacioTableComponent, [i9.MatDialog, i12.I18n, i13.PropostesAnulacioService, i14.DenunciesService], { updatedFilterData: [0, "updatedFilterData"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_0 = "Actualitzar"; var currVal_1 = "has-color-blue"; var currVal_2 = "fa-sync"; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Filtre"; var currVal_4 = "has-color-orange"; var currVal_5 = "fa-filter"; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.authService.Pages.PropuestasAnulacionZB; _ck(_v, 10, 0, currVal_6); var currVal_7 = _co.filter; _ck(_v, 12, 0, currVal_7); }, null); }
export function View_PropostesAnulacioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-propostes-anulacio", [], null, null, null, View_PropostesAnulacioComponent_0, RenderType_PropostesAnulacioComponent)), i1.ɵdid(1, 4308992, null, 0, i15.PropostesAnulacioComponent, [i8.AuthorizationService, i16.NotificationService, i9.MatDialog, i8.AuthorizationService, i12.I18n], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PropostesAnulacioComponentNgFactory = i1.ɵccf("app-propostes-anulacio", i15.PropostesAnulacioComponent, View_PropostesAnulacioComponent_Host_0, {}, {}, []);
export { PropostesAnulacioComponentNgFactory as PropostesAnulacioComponentNgFactory };
