import { ResponseDgtVehiculo } from './../../models/vehiculoDgt.model';
import { Component, OnInit, Inject } from '@angular/core';
import { DgtService } from '../../services/dgtService.service';
import { TreeElementDto } from '../../models/TreeElementDto.model';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { VehicleDtoDgt } from '../../models/updateVehicleDenuncia.model';
import { VehicleResumen } from 'app/sections/ciutat/vehicles/models/vehicleDto';
import { ResponseDgt } from '../../models/ResponseDgt.model';
import { HttpErrorResponse } from '@angular/common/http';
import { VehiculoParserService } from '../../services/vehiculoParser.service';
import { timer } from 'rxjs';
import { ResponseDgtPersona } from '../../models/personaDgt.model';
import { PersonaParserService } from '../../services/personaParser.service';
import { ToastrService } from 'ngx-toastr';

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-xml-tree-viewer',
  templateUrl: './xml-tree-viewer.component.html',
  styleUrls: ['./xml-tree-viewer.component.scss']
})
export class XmlTreeViewerComponent implements OnInit {
  message = '';
  tabs = [];
  intervalItv;
  intervalSeguro;
  intervalIndicadores;
  intervalPermiso0;
  intervalPermiso1;
  intervalPermiso2;
  intervalPermiso3;
  intervalPermiso4;
  intervalPermiso5;
  intervalPermiso6;
  intervalPermiso7;
  intervalPermiso8;
  intervalPermiso9;
  timeInterval = 750;
  public vehiculoDgt: ResponseDgtVehiculo;
  public personaDgt: ResponseDgtPersona;
  public matricula: string;
  public persona: string;
  public eucaris: string;
  public propietario: string;
  public allowDownload: boolean;
  public incorporaData: boolean;
  public isReadOnly: boolean;
  public isDialog: boolean;
  public consulta: boolean;
  matriculaLoad: boolean;
  personaLoad: boolean;
  prpopetariLoad: boolean;
  secureIdDenuncia: string;
  public allLoad: boolean;
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);
    private _transformer = (node: TreeElementDto, level: number) => {
      return {
        expandable: !!node.fills && node.fills.length > 0,
        name: node.nom,
        valor: node.valor,
        level: level,
      };
    }
  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.fills);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  fechaDenuncia: Date;



  // datasource: TreeElementDto[];
  constructor(
    private dgtService: DgtService,
    private confirmationDialogService: ConfirmationDialogService,
    private i18n: I18n,
    public thisDialogRef: MatDialogRef<XmlTreeViewerComponent>,
    public vehiculoParserService: VehiculoParserService,
    public personaParserService: PersonaParserService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.matricula = '';
    this.persona = '';
    this.eucaris = '';
    this.propietario = '';
    this.allowDownload = false;
    this.incorporaData = false;
    this.isReadOnly = true;
    this.isDialog = false;
    this.matriculaLoad = false;
    this.personaLoad = false;
    this.prpopetariLoad = false;
    this.consulta = true;
    if (data !== undefined && data !== null ) {
      if ( data.incorporaData !== undefined && data.incorporaData !== null) {
        this.incorporaData = data.incorporaData;
      }
      if (data.matricula !== undefined && data.matricula !== null && data.matricula !== '' ) {
        this.matricula = data.matricula;
        this.matriculaLoad = true;
      }
      if (data.persona !== undefined && data.persona !== null && data.persona !== '' ) {
        this.persona = data.persona;
        this.personaLoad = true;
      }
      if (data.propietario !== undefined && data.propietario !== null && data.propietario !== '' ) {
        this.propietario = data.propietario;
        this.prpopetariLoad = true;
      }
      if ( data.isReadOnly !== undefined && data.isReadOnly !== null) {
        this.isReadOnly = data.isReadOnly;
      }
      if ( data.isDialog !== undefined && data.isDialog !== null) {
        this.isDialog = data.isDialog;
      }
      if ( data.consulta !== undefined && data.consulta !== null) {
        this.consulta = data.consulta;
      }
      if ( data.secureIdDenuncia !== undefined && data.secureIdDenuncia !== null && data.secureIdDenuncia !== '') {
        this.secureIdDenuncia = data.secureIdDenuncia;
      }
      if ( data.fechaDenuncia !== undefined && data.fechaDenuncia !== null && data.fechaDenuncia !== '') {
        this.fechaDenuncia = data.fechaDenuncia;
      }
      
      if ( data.loadDataOnLoad !== undefined && data.loadDataOnLoad !== null && data.loadDataOnLoad) {
        this.dgtMatricula();
      }
    }
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  ngOnInit() {
    /*this.dgtService.goDgt().subscribe(x => {
      this.dataSource.data = x;
    });*/
    if ( this.incorporaData ) {
      if (this.matricula !== '' ) {
        this.dgtMatricula();
      } else if ( this.propietario !== '' ) {
        this.dgtPropietario();
      } else if ( this.persona !== '' ) {
        this.dgtPersona();
      }
    }
  }
  getLabel(tab): string {
    switch (tab) {
      case 'persona': {
        return this.i18n('Persona');
        break;
      }
      case 'matricula': {
        return this.i18n('Matricula');
        break;
      }
      case 'tree': {
        return this.i18n('Arbre de dades');
        break;
      }
      default: {
        return '';
      }
    }
  }
  dgtMatricula() {
    this.resetObjectsDgt();
    this.clearAllIntervals();
    const mat = this.matricula;
    this.tabs = ['matricula', 'tree'];
    this.dgtService.goDgtMatricula(this.matricula.toUpperCase()).subscribe(x => {
      this.dataSource.data = x;
      this.isAllowDownload();
      this.expandNode();
      this.loadObjectMatricula(x);
    });
    this.resetInputs();
    this.matricula  = mat;
  }
  resetObjectsDgt() {
    this.vehiculoDgt = null;
    this.personaDgt = null;
    this.allLoad = false;
  }
  clearAllIntervals() {
    clearInterval(this.intervalItv);
     clearInterval(this.intervalSeguro);
     clearInterval(this.intervalIndicadores);
     clearInterval(this.intervalPermiso0);
     clearInterval(this.intervalPermiso1);
     clearInterval(this.intervalPermiso2);
     clearInterval(this.intervalPermiso3);
     clearInterval(this.intervalPermiso4);
     clearInterval(this.intervalPermiso5);
     clearInterval(this.intervalPermiso6);
     clearInterval(this.intervalPermiso7);
     clearInterval(this.intervalPermiso8);
     clearInterval(this.intervalPermiso9);
  }
  loadObjectMatricula(data: any) {
    if (data !== undefined && data !== null) {
     this.vehiculoDgt = this.vehiculoParserService.parsebjectMatricula(data, this.fechaDenuncia);
     this.vehiculoDgt.matricula = this.matricula;
     this.defaultClassToVehicle();
     if ( new Date(this.vehiculoDgt.fechaCaducidadItv) <= new Date()) {
      this.intervalItv = setInterval(() => { this.changeClassItv(); },  this.timeInterval); // 1.25 segons
     }
     if ( this.vehiculoDgt.seguroEnVigor === undefined || this.vehiculoDgt.seguroEnVigor === null
      || this.vehiculoDgt.seguroEnVigor === false) {
      this.intervalSeguro = setInterval(() => { this.changeClassSeguro(); },  this.timeInterval); // 1.25 segons
     }

     if ( this.vehiculoDgt.indicadores === undefined || this.vehiculoDgt.indicadores === null
      || this.vehiculoDgt.indicadores.length > 0) {
      this.intervalIndicadores = setInterval(() => { this.changeClassIndicadores(); },  this.timeInterval); // 1.25 segons
     }
     if (this.vehiculoDgt.personaFisica) {
       // peticion persona para cargar los datos del titular
      this.dgtService.goDgtPersona(this.vehiculoDgt.conductorNif).subscribe(x => {
        this.loadObjectPersona(x);
        this.allLoad = true;
      }, error => {
        this.allLoad = true;
        this.allowDownload = false;
        this.message = this.i18n('No se puede incorporar porque ha fallado la consulta de datos del propietario');
      });
     } else if ( this.vehiculoDgt.arrendatario !== undefined && this.vehiculoDgt.arrendatario !== null ) {
        this.dgtService.goDgtPersona(this.vehiculoDgt.arrendatario.idDocumento).subscribe(x => {
          this.loadObjectPersona(x);
          this.allLoad = true;
        });
     } else {
       this.allLoad = true;
     }
    }
  }
  defaultClassToVehicle() {
    const element = document.getElementById('iconoITV');
    const elementFecha = document.getElementById('fechaCaducidadITV');
    if (element !== undefined && element !== null && element.className.includes('red') ) {
      element.className = element.className.replace('red', 'blue' );
    }
    if (elementFecha !== undefined && elementFecha !== null && element.className.includes('danger') ) {
      // elementFecha.className = 'text-muted text-uppercase font-weight small';
      elementFecha.className = elementFecha.className.replace('danger', 'muted' );
    }

    const elementSeguro = document.getElementById('iconoSeguro');
    const elementFechaSeguro = document.getElementById('fechaCaducidadSeguro');
    if (elementSeguro !== undefined && elementSeguro !== null  && elementSeguro.className.includes('red') ) {
      // elementSeguro.className = 'fa fa-car-crash fa-3x has-color-blue';
      elementSeguro.className = elementSeguro.className.replace('red', 'blue' );
    }
    if (elementFechaSeguro !== undefined && elementFechaSeguro !== null   && elementFechaSeguro.className.includes('danger') ) {
      elementFechaSeguro.className = elementFechaSeguro.className.replace('danger', 'muted' );
      // elementFechaSeguro.className = 'text-muted text-uppercase font-weight small';
    }
  }
  changeClassItv() {
    const element = document.getElementById('iconoITV');
    const elementFecha = document.getElementById('fechaCaducidadITV');
    if (element.className.includes('blue')) {
      if ( element !== undefined && element !== null ) {
        element.className = element.className.replace('blue', 'red');
        elementFecha.className = elementFecha.className.replace('muted', 'danger');
      }
    } else {
      if ( element !== undefined && element !== null ) {
        element.className = element.className.replace('red', 'blue');
        elementFecha.className = elementFecha.className.replace('danger', 'muted');
      }
    }
  }

  changeClassSeguro() {
    const element = document.getElementById('iconoSeguro');
    const elementFecha = document.getElementById('fechaCaducidadSeguro');
    if (element.className.includes('blue')) {
      if ( element !== undefined && element !== null ) {
        element.className = element.className.replace('blue', 'red');
        elementFecha.className = elementFecha.className.replace( 'muted', 'danger');
      }
    } else {
      if ( element !== undefined && element !== null ) {
        element.className = element.className.replace('red', 'blue');
        elementFecha.className = elementFecha.className.replace('danger', 'muted' );
      }
    }
  }
  changeClassIndicadores() {
    const element = document.getElementById('icono_Indicador');
    if (element.className.includes('blue')) {
      if ( element !== undefined && element !== null ) {
        element.className = element.className.replace('blue', 'red');
      }
    } else {
      if ( element !== undefined && element !== null ) {
        element.className = element.className.replace('red', 'blue');
      }
    }
  }
  expandNode() {
    const arrayExpandNodes = ['Vehicle', 'Dades Generals', 'Descripció Vehicle', 'Marca', 'Model', 'Titular', 'Dades Persona', 'Persona Física', 'Persona Jurídica', 'Vehículo', 'Datos Generales', 'Descripción Vehiculo', 'Modelo', 'Datos Persona'];

    this.treeControl.dataNodes.forEach((node) => {
      if (arrayExpandNodes.includes(node.name) ) {
        this.treeControl.expand(node);
      }
    });
  }
  dgtPersona() {
    this.resetObjectsDgt();
    this.clearAllIntervals();
    this.tabs = ['persona', 'tree'];
    const per = this.persona;
    this.dgtService.goDgtPersona(this.persona).subscribe(x => {
      this.dataSource.data = x;
      this.isAllowDownload();

      this.loadObjectPersona(x);
    });
    this.resetInputs();
    this.persona = per;
  }

  loadObjectPersona(data: any) {
    if (data !== undefined && data !== null) {
     this.personaDgt = this.personaParserService.parsebjectPersona(data);
     this.defaultClassToPersona();
     if ( this.personaDgt !== undefined && this.personaDgt !== null &&
      this.personaDgt.listaPermisosVigentes !== undefined && this.personaDgt.listaPermisosVigentes !== null
      && this.personaDgt.listaPermisosVigentes.length > 0) {
        let counter = 0;
        this.personaDgt.listaPermisosVigentes.forEach(x => {
          if ( new Date(x.fechaFin) <= new Date()) {
            switch (counter) {
              case 0: {
                this.intervalPermiso0 = setInterval(() => { this.changeClassPersonaByPermiso(x.tipoPermiso); },  this.timeInterval); // 1.25 segons
                break;
              }
              case 1: {
                this.intervalPermiso1 = setInterval(() => { this.changeClassPersonaByPermiso(x.tipoPermiso); },  this.timeInterval); // 1.25 segons
                break;
              }
              case 2: {
                this.intervalPermiso2 = setInterval(() => { this.changeClassPersonaByPermiso(x.tipoPermiso); },  this.timeInterval); // 1.25 segons
                break;
              }
              case 3: {
                this.intervalPermiso3 = setInterval(() => { this.changeClassPersonaByPermiso(x.tipoPermiso); },  this.timeInterval); // 1.25 segons
                break;
              }
              case 4: {
                this.intervalPermiso4 = setInterval(() => { this.changeClassPersonaByPermiso(x.tipoPermiso); },  this.timeInterval); // 1.25 segons
                break;
              }
              case 5: {
                this.intervalPermiso5 = setInterval(() => { this.changeClassPersonaByPermiso(x.tipoPermiso); },  this.timeInterval); // 1.25 segons
                break;
              }
              case 6: {
                this.intervalPermiso6 = setInterval(() => { this.changeClassPersonaByPermiso(x.tipoPermiso); },  this.timeInterval); // 1.25 segons
                break;
              }
              case 7: {
                this.intervalPermiso7 = setInterval(() => { this.changeClassPersonaByPermiso(x.tipoPermiso); },  this.timeInterval); // 1.25 segons
                break;
              }
              case 8: {
                this.intervalPermiso8 = setInterval(() => { this.changeClassPersonaByPermiso(x.tipoPermiso); },  this.timeInterval); // 1.25 segons
                break;
              }
              case 9: {
                this.intervalPermiso9 = setInterval(() => { this.changeClassPersonaByPermiso(x.tipoPermiso); },  this.timeInterval); // 1.25 segons
                break;
              }
              default: {
                // statements;
                break;
              }
            }
          }
         counter = counter + 1;
        });
      }
    //  if ( new Date(this.vehiculoDgt.fechaCaducidadItv) <= new Date()) {
    //   this.intervalItv = setInterval(() => { this.changeClassItv(); },  1250); //1.25 segons
    //  }
    //  if ( this.vehiculoDgt.seguroEnVigor === undefined || this.vehiculoDgt.seguroEnVigor === null
    //   || this.vehiculoDgt.seguroEnVigor === false) {
    //   this.intervalSeguro = setInterval(() => { this.changeClassSeguro(); },  1250); //1.25 segons
    //  }
    this.allLoad = true;
    }
  }
  defaultClassToPersona() {
    if ( this.personaDgt !== undefined && this.personaDgt !== null &&
      this.personaDgt.listaPermisosVigentes !== undefined && this.personaDgt.listaPermisosVigentes !== null
      && this.personaDgt.listaPermisosVigentes.length > 0) {
        this.personaDgt.listaPermisosVigentes.forEach(x => {
          const element = document.getElementById('icono_' + x.tipoPermiso);
          const elementFecha = document.getElementById('fecha_' + x.tipoPermiso);
          if (element !== undefined && element !== null ) {
            element.className = element.className.replace('red', 'blue');
          }
          if (elementFecha !== undefined && elementFecha !== null ) {
            elementFecha.className = elementFecha.className.replace('text-danger', 'text-muted');
          }
        });
      }
  }
  changeClassPersonaByPermiso(permiso) {
    const element = document.getElementById('icono_' + permiso);
    const elementFecha = document.getElementById('fecha_' + permiso);
    if (element.className.includes('blue')) {
      if ( element !== undefined && element !== null ) {
        element.className = element.className.replace('blue', 'red');
      }
      if (elementFecha !== undefined && elementFecha !== null ) {
        elementFecha.className = elementFecha.className.replace('text-muted', 'text-danger');
      }
    } else {
      if ( element !== undefined && element !== null ) {
        element.className = element.className.replace('red', 'blue');
      }
      if (elementFecha !== undefined && elementFecha !== null ) {
        elementFecha.className = elementFecha.className.replace('text-danger', 'text-muted');
      }
    }
  }


  dgtEucaris() {
    this.resetObjectsDgt();
    this.clearAllIntervals();
    this.tabs = ['tree'];
    const euc = this.eucaris;
    this.dgtService.goDgtEucaris(this.eucaris).subscribe(x => {
      this.dataSource.data = x;
      this.isAllowDownload();
      this.allLoad = true;
    });
    this.resetInputs();
    this.eucaris = euc;
  }
    
  dgtPropietario() {
    this.resetObjectsDgt();
    this.clearAllIntervals();
    this.tabs = ['tree'];
    const prop = this.propietario;
    this.dgtService.goDgtPropietario(this.propietario).subscribe(x => {
      this.dataSource.data = x;
      this.isAllowDownload();
      this.allLoad = true;
    });
    this.resetInputs();
    this.propietario = prop;
  }
  personaDgtEnabled(): boolean {
    return this.persona !== undefined && this.persona !== null && this.persona !== '';
  }
  matriculaDgtEnabled(): boolean {
    return this.matricula !== undefined && this.matricula !== null && this.matricula !== '';
  }
  eucarisDgtEnabled(): boolean {
    return this.eucaris !== undefined && this.eucaris !== null && this.eucaris !== '';
  }
  propietarioDgtEnabled(): boolean {
    return this.propietario !== undefined && this.propietario !== null && this.propietario !== '';
  }

  isAllowDownload() {
    
    if ( this.dataSource.data !== undefined && this.dataSource.data !== null && this.dataSource.data.length > 0 ) {
      this.allowDownload = true;
    } else {
      this.allowDownload = false;
    }
  }
  resetInputs() {
    this.matricula = '';
    this.persona = '';
    this.eucaris = '';
    this.propietario = '';
    this.clearAllIntervals();
  }

  erroHandling(error: any) {

  }

  incorpora() {
    if (this.matricula === '' && this.isReadOnly == false && (this.persona !== undefined && this.persona !== null && this.persona !== ''))
    {
      this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('S\' actualitzarà la denúncia. Estàs segur que vols incorporar les dades del propietari?'))
      .then((confirmed) => {
          if (confirmed) {
              this.dgtService.updatePropietarioDenuncia(this.personaDgt, this.secureIdDenuncia).subscribe({next:x => {
                this.thisDialogRef.close({persona: this.persona});
              }})
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    } else
    {
      if (this.matricula != '' && this.isReadOnly == false) {
        this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('S\' actualitzarà la denúncia. Estàs segur que vols incorporar les dades del vehicle?'))
        .then((confirmed) => {
            if (confirmed) {
              if (this.vehiculoDgt.arrendatario !== undefined && this.vehiculoDgt.arrendatario !== null) {
                this.vehiculoDgt.arrendatarioDgtPersona = this.personaDgt;
              }
              const vehicle: VehicleDtoDgt = {
                secureIdDenuncia: this.secureIdDenuncia,
                matricula: this.matricula,
                confirmOverride: false,
                datosVehiculo: this.vehiculoDgt,
              };
              this.dgtService.UpdateVehicle(vehicle, (responseError: HttpErrorResponse) => {
                if (responseError.status === 409) {
                  this.confirmationDialogService.confirm(this.i18n('Confirmi'), responseError.error).then((confirmedOverride) => {
                    if (confirmedOverride) {
                      vehicle.confirmOverride = true;
                      this.dgtService.OverrideVehicle(vehicle).subscribe((x: ResponseDgt) => {
    
    
                        this.thisDialogRef.close({marca: x.vehicle.marca, model: x.vehicle.model, operacio: x.operacio, persona: x.persona});
                      });
                    }
                  });
                } else {
                  this.toastr.error('Error incorporant: ' + responseError.error, responseError.statusText, {
                    closeButton: true
                  });
                }
              }).subscribe((x: ResponseDgt) => {
    
                  this.thisDialogRef.close({marca: x.vehicle.marca, model: x.vehicle.model, operacio: x.operacio, persona: x.persona});
                }
              );
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
      }    

    }
   
  }


  exportarXml() {
    if ( this.matricula !== '') {
      this.dgtService.xmlMatricula(this.matricula).subscribe(x => {
        FileDownloader.downLoadFile([x], 'application/xml;charset=utf-8', this.matricula.replace(' ', '') + '.xml');
      });
    } else if ( this.persona !== '') {
      this.dgtService.xmlPersona(this.persona).subscribe(x => {
        FileDownloader.downLoadFile([x], 'application/xml;charset=utf-8', this.persona.replace(' ', '') + '.xml');
      });
    } else if ( this.eucaris !== '') {
      this.dgtService.xmlEucaris(this.eucaris).subscribe(x => {
        FileDownloader.downLoadFile([x], 'application/xml;charset=utf-8', this.eucaris.replace(' ', '') + '.xml');
      });
    } else if ( this.propietario !== '') {
      this.dgtService.xmlPropietario(this.propietario).subscribe( x => {
        FileDownloader.downLoadFile([x], 'application/xml;charset=utf-8', this.propietario.replace(' ', '') + '.xml');
      });
    }
  }
  viewInputMatricula(): boolean {
    return ( this.consulta || this.matriculaLoad );
  }

  viewInputPersona(): boolean {
    return ( this.consulta || this.personaLoad );
  }
  viewInputPropietari(): boolean {
    // return ( this.consulta || this.prpopetariLoad );
    return false;
  }
  viewInputEucaris(): boolean {
    return false;
    return ( this.consulta );
  }

  keyPressMatricula(e) {
    if (e.key === 'Enter') {
      this.dgtMatricula();
    }
  }

  keyPressPersona(e) {
    if (e.key === 'Enter') {
      this.dgtPersona();
    }
  }

  keyPressEucaris(e) {
    if (e.key === 'Enter') {
      this.dgtEucaris();
    }
  }

  keyPressPropietario(e) {
    if (e.key === 'Enter') {
      this.dgtPropietario();
    }
  }
}
