<app-dialog-header label="Fitxers" i18n-label></app-dialog-header>

<!-- Tabla con los titulos de los ficheros y los botones de accion -->
<app-generic-file-displayer-table [files]="this.filesDictionary.value" (delete)="this.deleteFiles($event)" (download)="this.downloadFiles($event)"
  (show)="this.showFile($event)" (upload)="this.uploadFile($event)">
</app-generic-file-displayer-table>

<!-- Viewer que muestra el/la imagen/fichero seleccionada/o -->
<app-generic-file-displayer-frame #fileDisplayer 
  (previousFile)="this.changeShowingFile('previous', $event)" (nextFile)="this.changeShowingFile('next', $event)">
</app-generic-file-displayer-frame>