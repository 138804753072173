import { Router } from '@angular/router';
import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { OperacionesModel } from 'app/sections/denuncies/models/operaciones.model';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent implements OnInit {
  @Input() text: string;
  @Input() icon: string;
  @Input() link: string;
  @Input() linkActive: string;
  @Input() badge: string;
  @Input() badgeClass: string;
  @Input() src: string;
  @Output() onClickNavItem: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private router: Router) {
    this.link = '#';
    this.linkActive = '';
    this.badge = '';
    this.badgeClass = 'badge-primary';
    this.src = undefined;
  }

  ngOnInit() {
  }

  getRouterLink() {
    if (this.link === null || this.link === undefined) {
      return ['#'];
    } else {
      return JSON.parse(this.link);
    }
  }

  getClass() {
    let active = false;
    if (this.router.url.length > 1) {
      if (this.link.length > 1) {
        if (this.link === this.router.url) {
          active = true;
        } else if (this.linkActive.length > 0) {
          if (this.router.url.indexOf(this.linkActive) >= 0) {
            active = true;
          }
        }
      } else {
        active = false;
      }
    } else if (this.link === '/') {
      active = true;
    }
    if (active) {
      return 'nav-link active';
    } else {
      return 'nav-link';
    }
  }

  hasBadge() {
    if (Reflection.empty(this.badge)) {
      return false;
    } else if (this.badge.toString() === '0') {
      return false;
    } else {
      return true;
    }
  }

  getId() {
    return this.link.replace(/\//g, '-');
  }

  itemClicked(event) {
    console.log(event);
    console.log(event.target);
   if (this.onClickNavItem !== undefined && this.onClickNavItem !== null && 
    this.onClickNavItem.observers !== undefined && this.onClickNavItem.observers !== null && 
    this.onClickNavItem.observers.length > 0) {
    this.onClickNavItem.emit(true);
   } else {
   // this.onClickNavItem.emit(false);
    const elements = document.querySelectorAll('li.nav-item.nav-dropdown.open');
    for (let n = 0; n < elements.length; n++) {
      if (!this.hasChild(elements[n], event.target)) {
        elements[n].classList.remove('open');
      }
    }
  }
  }

  private hasChild(item: Element, find: Element): boolean {
    let has = false;
    if (this.validSelector('#' + find.id)) {
      has = item.querySelectorAll('#' + find.id).length > 0;
    }
    return has;
  }

  private validSelector(id: string): boolean {
    return id !== '#-';
  }
}
