import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatriculaHistoricFilter } from '../../models/matricula-historic-filter';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-matricules-historic-filter',
  templateUrl: './matricules-historic-filter.component.html',
  styleUrls: ['./matricules-historic-filter.component.scss']
})
export class MatriculesHistoricFilterComponent implements OnInit, OnDestroy {
  filter: MatriculaHistoricFilter;
  tipusMatricules = [];
  subscription: Subscription;
  @Output() notify: EventEmitter<MatriculaHistoricFilter> = new EventEmitter<MatriculaHistoricFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<MatriculesHistoricFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.tipusMatricules = [];
    this.tipusMatricules.push({'codi': '', 'descripcio': ''});
    this.filter = data.filter;
    data.tipusMatricules.forEach(function(value, key) {
      this.push({'codi': key, 'descripcio': value});
    }, this.tipusMatricules);
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }
}
