<app-dialog-header label="Tipus de tiquet" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.TipusTiquets) || !this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.TipusTiquets) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.TipusTiquets) ||this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="tipus-tiquets-editor-codi" class="col-4" i18n-label label="Codi" [(value)]="this.tipusTiquets.codi" required></app-form-text-edit>
      <app-form-text-edit id="tipus-tiquets-editor-descripcio" class="col-8" i18n-label label="Descripcio" [(value)]="this.tipusTiquets.descripcio" required></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-date-edit id="tipus-tiquets-editor-dataIni" class="col-6" i18n-label label="Data inici" [(value)]="this.tipusTiquets.dataInici" [type]="'both'" required></app-form-date-edit>
      <app-form-date-edit id="tipus-tiquets-editor-dataFi" class="col-6" i18n-label label="Data fi" [(value)]="this.tipusTiquets.dataFi" [type]="'both'" required></app-form-date-edit>
    </div>
  </form>
</app-dialog-body>