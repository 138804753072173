import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CobrosFilter } from '../../models/cobros-filter';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LoaderService } from 'path-shared/components/loader/loader.service';
import { FormMultiSelect } from 'path-shared/components/form/form-multi-select/model/form-multi-select.model';
import { AparellService } from 'app/sections/configuracio/aparells/services/aparells-service.service';

@Component({
  selector: 'app-cobros-filtre',
  templateUrl: './cobros-filtre.component.html',
  styleUrls: ['./cobros-filtre.component.scss']
})
export class CobrosFiltreComponent implements OnInit {
  subscription: Subscription;
  filter: CobrosFilter;
  aparatos = Array<FormMultiSelect>();

  @Output() notify: EventEmitter<CobrosFilter> = new EventEmitter<CobrosFilter>();
  constructor(
    public thisDialogRef: MatDialogRef<CobrosFilter>,
    private loaderService: LoaderService,
    private aparellService: AparellService,
    @Inject(MAT_DIALOG_DATA) public data: CobrosFilter) { 
    this.filter = data;
   }

  ngOnInit() {
    this.GetAparatos();
    // this.loaderService.setBackgroundMode(true);
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }

  GetAparatos() {
    this.aparellService.getAllCombo().subscribe(result => {
      const aparatos: Array<any> = Object.assign([], result);
      this.aparatos = [];
      aparatos.forEach(aparato => {
        this.aparatos.push(new FormMultiSelect(aparato.id, aparato.descripcion));

      });
    });
  }

  ngOnDestroy(): void {
    // this.loaderService.setBackgroundMode(false);
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated(true)
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
