import { saveAs } from 'file-saver';
import { GenericFileDisplayerFrameComponent } from './../generic-file-displayer-frame/generic-file-displayer-frame.component';
import { BehaviorSubject } from 'rxjs';
import { OnInit } from '@angular/core';
import { Dictionary } from 'path-shared/models/dictionary';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
var GenericFileDisplayerPageComponent = /** @class */ (function () {
    // @ViewChild('fileUploader') fileUploader: GenericFileUploader;
    function GenericFileDisplayerPageComponent(data, toastr, i18n) {
        var _this = this;
        this.data = data;
        this.toastr = toastr;
        this.i18n = i18n;
        this.filesDictionary = new BehaviorSubject(undefined);
        this.files = data.files;
        this.fileEvents = data.fileEvents;
        // Nos suscribimos a los eventos de ficheros (comunicacion con el componente que lo haya llamado)
        this.fileEvents.deleteFilesComplete.subscribe(function (ids) {
            // El componente parent ha eliminado ficheros, updateamos la lista.
            var newFiles = [];
            _this.files.forEach(function (element) {
                if (ids.find(function (id) { return element.id === id; }) === undefined) {
                    // Este no se ha borrado, lo mantenemos.
                    newFiles.push(element);
                }
            });
            _this.files = newFiles;
        });
        this.fileEvents.uploadFileComplete.subscribe(function (newFile) {
            var newFiles = _this.files;
            newFiles.push(newFile);
            _this.files = newFiles;
        });
    }
    Object.defineProperty(GenericFileDisplayerPageComponent.prototype, "files", {
        get: function () {
            return this._files;
        },
        set: function (val) {
            this._files = val;
            var filesDic = new Array();
            this.files.forEach(function (element) {
                filesDic.push(new Dictionary(element.id, element.nombre));
            });
            this.filesDictionary.next(filesDic);
        },
        enumerable: true,
        configurable: true
    });
    GenericFileDisplayerPageComponent.prototype.ngOnInit = function () {
    };
    GenericFileDisplayerPageComponent.prototype.deleteFiles = function (fileIds) {
        this.fileEvents.deleteFileCommand.emit(fileIds);
    };
    GenericFileDisplayerPageComponent.prototype.downloadFiles = function (fileIds) {
        var _this = this;
        if (fileIds.length > 1) {
            var fecha = new Date();
            var NomFitxer_1 = this.i18n('Fitxers_') + (fecha.getFullYear() + (fecha.getMonth() + 1).toString() + fecha.getDate().toString());
            NomFitxer_1 += '_' + (fecha.getHours().toString() + fecha.getMinutes().toString() + fecha.getSeconds().toString()) + '.zip';
            var JSZip = require('jszip');
            var zip_1 = new JSZip();
            fileIds.forEach(function (id) {
                var file = _this.files.find(function (f) { return f.id === id; });
                var bytearray = _this.getDisplayablePdf(file.fichero);
                var blob = new Blob([bytearray]);
                zip_1.file(file.nombre, bytearray, { binary: true });
            });
            zip_1.generateAsync({ type: 'blob' }).then(function (content) {
                saveAs(content, NomFitxer_1);
            });
        }
        else if (fileIds.length === 1) {
            var id_1 = fileIds[0];
            var file = this.files.find(function (f) { return f.id === id_1; });
            var bytearray = this.getDisplayablePdf(file.fichero);
            var blob = new Blob([bytearray]);
            saveAs(blob, file.nombre);
        }
    };
    GenericFileDisplayerPageComponent.prototype.uploadFile = function (file) {
        this.fileEvents.uploadFileCommand.next(file);
    };
    GenericFileDisplayerPageComponent.prototype.showFile = function (fileId) {
        // Determinamos si es un pdf o una imagen.
        var fileToShow = this.files.find(function (f) { return f.id === fileId; });
        var extension = fileToShow.nombre.substring(fileToShow.nombre.lastIndexOf('.') + 1).toLowerCase();
        if (!fileToShow.fileFound || extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'jfif') {
            // Es una imagen.
            this.fileDisplayer.showImage(fileToShow);
        }
        else {
            // Es un pdf.
            this.fileDisplayer.showPdf(fileToShow);
        }
    };
    GenericFileDisplayerPageComponent.prototype.changeShowingFile = function (orientation, fileId) {
        var currentPosition = this.files.findIndex(function (f) { return f.id === fileId; });
        if (currentPosition !== -1) {
            if (orientation === 'previous') {
                // Buscamos el id anterior al fileId pasado.
                var previousPosition = currentPosition - 1;
                if (previousPosition >= 0 && previousPosition !== currentPosition) {
                    // Tenemos algun fichero anterior que podemos mostrar.
                    var newFileId = this.files[previousPosition].id;
                    this.showFile(newFileId);
                }
            }
            else {
                // Buscamos el id posterior al fileId pasado.
                var nextPosition = currentPosition + 1;
                if (nextPosition <= this.files.length - 1 && nextPosition !== currentPosition) {
                    // Tenemos algun fichero posterior que podemos mostrar.
                    var newFileId = this.files[nextPosition].id;
                    this.showFile(newFileId);
                }
            }
        }
    };
    GenericFileDisplayerPageComponent.prototype.getDisplayablePdf = function (dataURI) {
        var raw = window.atob(dataURI);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
        var i = 0;
        for (i; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    };
    return GenericFileDisplayerPageComponent;
}());
export { GenericFileDisplayerPageComponent };
