import { DatePipe } from '@angular/common';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { MatriculesService } from './../../services/matricules.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from './../../../../../shared/services/authorization/authorization-service';
import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Matricula } from 'app/sections/ciutat/matricules/models/matricula.model';
import { TipusMatricula } from '../../../tipus-matricules/models/tipus-matricula.model';
import { MarquesVehicles } from '../../../../configuracio/marques-vehicles/models/marques-vehicles.model';
import { ToastrService } from 'ngx-toastr';
import { TipusMatriculesService } from 'app/sections/ciutat/tipus-matricules/services/tipus-matricules.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { FormService } from 'path-shared/services/form/form.service';
import { Builder } from 'path-shared/models/builder';
import { debug } from 'util';
import { ReportsComponent } from 'path-shared/components/reports/reports.component';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';

@Component({
  selector: 'app-matricules-editor',
  templateUrl: './matricules-editor.component.html',
  styleUrls: ['./matricules-editor.component.scss']
})
export class MatriculesEditorComponent implements OnInit {
  secure_id: string;
  matricula: Matricula;
  noFinalDate: boolean;
  finalDate: Date;
  tipusMatricules: TipusMatricula[];
  marques: MarquesVehicles [];
  dialogData: ComponentDialog;
  closeButtonText: string;
  reports = [];

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public auth: AuthorizationService,
    private matriculesService: MatriculesService,
    private i18n: I18n,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private tipusMatriculesService: TipusMatriculesService,
    public thisDialogRef: MatDialogRef<MatriculesEditorComponent>,
    private formService: FormService,

    @Inject(MAT_DIALOG_DATA) public data,
    ) {
      this.matricula = Builder.newMatricula();
      this.noFinalDate = true;
      this.dialogData = data;
      this.marques = data.marques;
      this.tipusMatricules = data.tipusMatricules;
      // this.secure_id = this.dialogData.id;
      if (this.dialogData.isReadOnly) {
        this.closeButtonText = this.i18n('Tancar');
      } else {
        this.closeButtonText = this.i18n('Llista');
      }
      this.secure_id = data.secureId;
      this.matriculesService.getReports(this.auth.Pages.Matricules).subscribe( res => {
        this.reports = res;
      });
    }

  ngOnInit() {
    if (this.secure_id !== null) {
      this.matriculesService.getMatricula(this.secure_id).subscribe(data => {
        this.matricula = data;

        if (this.matricula.dataFi !== null) {
          this.finalDate = new Date(this.matricula.dataFi);
          const year = this.finalDate.getFullYear();
          this.noFinalDate =  year >= 2100;
        }
      });
    } else {
      this.matricula.dataInici = new Date();
      this.matricula.hora = new Date().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    }
  }

  models() {
    if (this.matricula.marca !== null) {
      if (this.marques.find(x => x.descripcio === this.matricula.marca) !== undefined) {
        return this.marques.find(x => x.descripcio === this.matricula.marca).modelos;
      }
    }
  }

  impressio() {
    const ref = this.dialog.open(ReportsComponent, {

      width: '30%',
      height: '30%',
      closeOnNavigation: false,
      autoFocus: true,
      data: this.reports
    }).afterClosed().subscribe(report => {
      if (report !== '') {
        this.matriculesService.generateReport(this.matricula.id, report.id).subscribe(file => {
          const name = report.nom + '_' + this.matricula.matricula;
          const contentType = file.headers.get('Content-Type');
          if (contentType !== null && contentType !== undefined) {
              FileDownloader.downLoadFile([file.body],  contentType + ';charset=utf-8', name.toString());
          } else {
            this.toastr.error(this.i18n('No s\'ha pogut generar el fitxer'));
          }
        });

      }

    });
  }

  save() {

    if (this.formService.allFieldsValid(this.mainForm) && this.matricula.hora !== '') {
      if (this.noFinalDate) {
        this.matricula.dataFi = '4000-12-31';
      }

      this.matriculesService.setMatricula(this.matricula).subscribe(result => {
        this.toastr.info(this.i18n('Matricula guardada correctament'));
        this.thisDialogRef.close(true);
      });
    } else {
      this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
      if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
        this.mainForm.nativeElement.classList.add('displayErrors');
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }


  noFinalCheckChangd(event) {
    // if (!event) {
    //   this.matricula.dataFi = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    // }
  }

  remove() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol esborrar la matrícula?'))
    .then((confirmed) => {
        if (confirmed) {
          // const saved = this.denunciesService.saveDenuncia(dataJson).subscribe();
          this.matriculesService.deleteMatricula(this.secure_id).subscribe(result => {
            this.toastr.success(this.i18n('Matricula esborrada correctament'));
            this.dialog.closeAll();
            this.thisDialogRef.close(true);
          });
        }
      });
  }
}

