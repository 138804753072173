import { Injectable } from '@angular/core';
import { ExportAllDeclaration } from 'estree';
import { ExcelGenerator } from 'path-shared/interfaces/excel-generator';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { api } from 'path-shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import { ZonesModel } from '../models/zones.model';
import { ZoneFilter } from '../models/zones-filtar';
import { ZonesFilterService } from './zones-filter.service';
import { ZonesLoader } from '../models/zones-load';
import { TarifasFilter } from '../models/tarifas-filters';
import { TarifaFilterService } from './tarifa-filter.service';
import { TarifaModel } from '../models/tarifa.model.';

const defaultSortParam = 'tipus';
const defaultSortDirection = 'asc';


@Injectable({
  providedIn: 'root'
})
export class ZonesService implements ExcelGenerator {

  displayedItems = [];

    constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
      this.displayedItems['checkedValues'] = 0;
      this.displayedItems['checkedItems'] = Array();
    }

    getZones(): Observable<any> {
      if (this.authGuard.canActivate()) {
        const params = new ZonesFilterService().createFilterParams(new ZoneFilter('descripcio', 'asc'), 0, 99999, 'descripcio', 'asc');
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetZones'], params);
      }
    }

    setZones(zona: ZonesModel): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetZones'], zona);
      }
    }


    deleteZones(secure_id): Observable<any> {
      console.log(secure_id);

      if (this.authGuard.canActivate()) {
        return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteZona'].replace('{secure_id}', secure_id), null);
      }
    }

    generateExcel(filter: ZoneFilter, sortDir: string, sortField: string): Observable<any> {

      const start = 0;
      const pageSize = 1000;
      sortField = sortField === undefined ? defaultSortParam : sortField;
      sortDir = sortDir === '' ? defaultSortDirection : sortDir;
      const params = new ZonesFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

      if (this.authGuard.canActivate()) {
        return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlZonesExcel'],  params );
      }
    }

    loadZones(data: ZonesLoader): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.post(config.urls['apiUrl'] + api.endpoints['urlZonesLoad'], data );
      }
    }

    detalleZones(secure_id: string): Observable<any> {
      if (this.authGuard.canActivate()) {
        return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetDetalleZona'].replace('{secure_id}', secure_id), null);
      }
    }

    getZonesByCarrer(secureIdCarrer: string): Observable<any> {
      if (this.authGuard.canActivate()) {
          return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetZonesByCarrer'].replace('{secureIdCarrer}', secureIdCarrer), null);
      }
  }
  getAllZonas(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllZonas'], null);
    }
  }

}
