var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var RecaudacioFilter = /** @class */ (function (_super) {
    __extends(RecaudacioFilter, _super);
    function RecaudacioFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.id = null;
        _this.numColecta = null;
        _this.fechaInicio = null;
        _this.fechaFin = null;
        _this.maquinaParkeonFk = null;
        _this.maquinaCodi = '';
        _this.zonaFk = null;
        _this.zonaDesc = '';
        _this.importeMonedaD = null;
        _this.importeMonedaComercial = null;
        _this.importeMonedaLegal = null;
        _this.importeTarjetaPayone = null;
        _this.importeTarjetaPayonePlus = null;
        _this.importeTarjetaTotal = null;
        _this.numeroTicketsTarjetaPayone = null;
        _this.importeAnulacionTarjetaPayOne = null;
        _this.numeroTicketsTarjetaPayonePlus = null;
        _this.importeAnulacionTarjetaPayOnePlus = null;
        _this.importeRecargaTarjetaPayone = null;
        _this.importeRecargaTarjetaPayonePlus = null;
        _this.importeRecargaTarjetaTotal = null;
        _this.anulacionMoneda = null;
        _this.anulacionImporteTotal = null;
        _this.importeRealAparcamiento = null;
        _this.numeroTicketsMoneda = null;
        _this.numeroTicketsTotal = null;
        _this.numeroTicketsMonedaComercial = null;
        _this.importeRecuento = null;
        _this.diferenciaRecuentoTicket = null;
        _this.acumuladoMoneda = null;
        _this.acumuladoTarjetaPayone = null;
        _this.acumuladoTarjetaPayonePlus = null;
        _this.acumuladoTarjetaTotal = null;
        _this.labels['id'] = _this.i18n('Id');
        _this.labels['numColecta'] = _this.i18n('Núm.Colecta');
        _this.labels['fechaInicio'] = _this.i18n('Desde');
        _this.labels['fechaFin'] = _this.i18n('Fins');
        _this.labels['zonaDesc'] = _this.i18n('Zona');
        _this.labels['maquinaCodi'] = _this.i18n('Expenedor');
        _this.labels['importeMonedaD'] = _this.i18n('Import moneda D');
        _this.labels['importeMonedaComercial'] = _this.i18n('Import moneda comercial');
        _this.labels['importeMonedaLegal'] = _this.i18n('Import moneda legal');
        _this.labels['importeTarjetaPayone'] = _this.i18n('Import targeta PayOne');
        _this.labels['importeTarjetaPayonePlus'] = _this.i18n('Import targeta PayOnePlus');
        _this.labels['importeTarjetaTotal'] = _this.i18n('Import total targeta');
        _this.labels['numeroTicketsTarjetaPayone'] = _this.i18n('Nº Tiquets targeta PayOne');
        _this.labels['importeAnulacionTarjetaPayOne'] = _this.i18n('Import anul·lació targeta PayOne');
        _this.labels['numeroTicketsTarjetaPayonePlus'] = _this.i18n('Nº Tiquets targeta PayOnePlus');
        _this.labels['importeAnulacionTarjetaPayOnePlus'] = _this.i18n('Import anul·lació targeta PayOnePlus');
        _this.labels['importeRecargaTarjetaPayone'] = _this.i18n('Import recàrrega Payone');
        _this.labels['importeRecargaTarjetaPayonePlus'] = _this.i18n('Import recàrrega Payone Plus');
        _this.labels['importeRecargaTarjetaTotal'] = _this.i18n('Import total recàrrega targeta');
        _this.labels['anulacionMoneda'] = _this.i18n('Anul·lació moneda');
        _this.labels['anulacionImporteTotal'] = _this.i18n('Import total anul·lacions');
        _this.labels['importeRealAparcamiento'] = _this.i18n('Import real aparcament');
        _this.labels['numeroTicketsMoneda'] = _this.i18n('Nº Tiquets moneda');
        _this.labels['numeroTicketsTotal'] = _this.i18n('Nº Total tiquets');
        _this.labels['numeroTicketsMonedaComercial'] = _this.i18n('Nº Moneda comercial');
        _this.labels['importeRecuento'] = _this.i18n('Import recompte');
        _this.labels['diferenciaRecuentoTicket'] = _this.i18n('Diferència recompte/tiquet');
        _this.labels['acumuladoMoneda'] = _this.i18n('Acumulat moneda');
        _this.labels['acumuladoTarjetaPayone'] = _this.i18n('Acumulat targeta PayOne');
        _this.labels['acumuladoTarjetaPayonePlus'] = _this.i18n('Acumulat targeta PayOnePlus');
        _this.labels['acumuladoTarjetaTotal'] = _this.i18n('Total acumulat targeta');
        return _this;
    }
    return RecaudacioFilter;
}(FilterData));
export { RecaudacioFilter };
