<app-dialog-header label="Incidències" [isCloseButton]="true" *ngIf="this.isDialog" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Actualitzar"  i18n-text (click)="load();" color="has-color-blue" icon="fa-sync"></app-tool-button>
  <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter"></app-tool-button>
</app-toolbar>
<div style="width: 20%;">
  <app-form-text-edit *ngIf="!this.dataDialog.isDialog" i18n-label label="Codi màquina" [(value)]="this.filter.codiMaquina" (focusout)="maquinaFilterChange($event)" (keyup.enter)="maquinaFilterChange($event)"></app-form-text-edit>
</div>

<div class="row justify-content-between">
  <div class="col"><app-filter-selector [filter]="this.filter"></app-filter-selector></div>
  <div class="col">
    <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="this.filter.paginator.pageSize"
      [pageSizeOptions]="[20, 40, 60, 100]">
    </mat-paginator>
  </div>
</div>

<div class="table-sdw is-fixed">
  <mat-table class="mat-elevation-z8" #table [dataSource]="dataSource" matSort aria-label="Elements" [matSortActive]="this.filter.paginator.sortField" [matSortDirection]="this.filter.paginator.sortDir" class="is-header-sticky"  multiTemplateDataRows>
    <ng-container matColumnDef="Afegir_Actuacio">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Afegir actuació</mat-header-cell>
      <mat-cell *matCellDef="let row" class="text-center">
        <button [class]="'btn btn-outline-success btn-xs'" title="Resoldre incidència" i18n-title (click)="afegirActuacio(row)">
          <i [class]="'fa fa-plus'"></i>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="SOM_ID">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.somId}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="CODI_MAQUINA">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Codi Màquina</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.codiMaquina}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="INCIDENCIA">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripció</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.INCIDENCIA}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="DATA_INCIDENCIA">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data de creació</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.dataCreacio}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="RESOLTA">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Resolta</mat-header-cell>
      <mat-cell *matCellDef="let row"><mat-checkbox [(ngModel)]="row.RESOLTA" [disabled]="true" ></mat-checkbox></mat-cell>
    </ng-container>
    <ng-container matColumnDef="USUARI_RESOLUCIO">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Usuari Resolució</mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.usuariResolucio}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="DATA_RESOLUCIO">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data de resolució</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.dataResolucio}}</mat-cell>
    </ng-container>
    <!-- Imatges Column -->
    <ng-container matColumnDef="imatges">
      <mat-header-cell *matHeaderCellDef mat-sort-header><i class="fa fa-images" text="Imatges" i18n-text></i></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <a (click)="this.viewImages(row.somId)" class="is-link">
            <i class="imatges-n fa fa-images"></i> {{row.imatges}}
        </a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Resoldre">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Resoldre incidència</mat-header-cell>
      <mat-cell *matCellDef="let row" class="text-center">
        <button [class]="row.RESOLTA ? 'btn btn-outline-danger btn-xs' : 'btn btn-outline-primary btn-xs'" title="Resoldre incidència" i18n-title (click)="resoldre(row.somId, row.resolta)"><i [class]="row.RESOLTA ? 'fas fa-times' :'fa fa-check-double'"></i></button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="expandedDetailButton">
      <mat-header-cell *matHeaderCellDef i18n>Actuacions</mat-header-cell>
      <mat-cell *matCellDef="let row" class="text-center">
        <button (click)="expandDetailButtonClick(row)">
          <i [class]="expandedElement === row ? 'fas fa-caret-square-down' :'fas fa-caret-square-right'"></i>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail" [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'">
            <app-actuacions-table style="width: 100%" [inputIncidenciaId]="row.somId"></app-actuacions-table>
        </div>
      </td>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row 
      *matRowDef="let row; columns: displayedColumns;" 
      class="has-iconset" 
      [class.example-expanded-row]="expandedElement === row" 
      [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"
      style="border-bottom-width: 0;">
    </mat-row>
    <tr mat-row *matRowDef="let search; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </mat-table>
</div>
