import { ValidationService } from './../../../services/validation/validation.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatSelect, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material';
import {FormControl} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {map, startWith, first} from 'rxjs/operators';
import { Reflection } from 'path-shared/services/reflection/reflection';

@Component({
  selector: 'app-form-combo-edit',
  templateUrl: './form-combo-edit.component.html',
  styleUrls: ['./form-combo-edit.component.scss']
})
export class FormComboEditComponent<T> implements OnInit, AfterViewInit {
  val: string;
  public myControl = new FormControl();
  public filteredOptions = new Subject<T[]>();
  selectedValue: T;
  first: T;
  inputChange: Observable<any>;

  @Input() label: string;
  @Input() get value() {
    return this.val;
  } set value(val) {
    if (this.val !== val) {
      this.val = val;
      this.setCurrentValue();
      setTimeout(() => {
        this.valueChange.emit(this.val);
      }, 10);
    }
  }
  @Output() valueChange = new EventEmitter();
  @Input() items: T[];
  @Input() valueField: string;
  @Input() textField: string;
  @Input() required: boolean;
  @Input() isReadOnly: boolean;
  @Input() disableInput: boolean;

  @ViewChild(MatAutocompleteTrigger) inputAutoComplit: MatAutocompleteTrigger;
  @ViewChild('input') input: ElementRef;

  constructor(
    private validation: ValidationService
  ) {
    this.required = false;
    this.isReadOnly = false;
    this.disableInput = false;
  }

  autosize() {
    const el = document.querySelector('textarea');
    setTimeout(function() {
      if (el !== null) {
        el.style.cssText = 'height:auto; padding:0';
        // for box-sizing other than "content-box" use:
        // el.style.cssText = '-moz-box-sizing:content-box';
        el.style.cssText = 'height:' + el.scrollHeight + 'px';
      }
    }, 0);
  }
  
  ngOnInit() {
    this.myControl.valueChanges.subscribe(value => {
      if (typeof value !== 'string') {
        this.setValue(value);
      }
      if (value !== undefined && value !== '' && value !== null) {
        if (value[this.textField] === undefined) {
          this.filteredOptions.next(this._filter(value));
        } else  {
          this.filteredOptions.next(this._filter(value[this.textField]));
        }
      } else {
        this.filteredOptions.next(this._filter(''));
      }
    });
    this.setFilterOptions();
  }

  private setFilterOptions() {
    if (Reflection.empty(this.items)) {
      setTimeout(() => this.setFilterOptions(), 200);
    } else {
      this.filteredOptions.subscribe(items => {
        if (items.length > 0) {
          this.first = items[0];
        } else {
          this.first = undefined;
        }
      });

    }
  }

  ngAfterViewInit(): void {

  }

  private _filter(value: string): T[] {
    if (Reflection.empty(this.items)) {
      setTimeout(() => this._filter(value), 100);
      return [];
    } else {
      if (value === null || value === undefined) {
        value = '';
      }
      if (value === '') {
        return this.items;
      } else {
        const filterValue = value.toLowerCase();
        return this.items.filter(x => x[this.textField].toString().toLowerCase().includes(filterValue)).sort((a: any, b: any) => a.length - b.length);
      }
    }
  }

  setValue(option: T) {
    if (option !== undefined) {
      this.value = this.getValue(option);
      this.autosize();
    }
  }

  setCurrentValue() {
    if (this.items !== undefined && this.items.length > 0) {
      this.selectedValue = this.items.find(x => x[this.valueField] !== undefined && x[this.valueField] !== null 
        && this.value !== null && this.value !== undefined && x[this.valueField].toString() === this.value.toString());
      this.myControl.setValue(this.selectedValue);
    } else {
      setTimeout(() => this.setCurrentValue(), 100);
    }
  }


  getValue(option: T) {
    if (this.valueField === undefined) {
      return null;
    } else {
      if (option === null || option === undefined) {
        return null;
      } else {
        return option[this.valueField];
      }
    }
  }

  getText(option: T) {
    if (this.textField === undefined) {
      return null;
    } else {
      if (option === null || option === undefined) {
        return null;
      } else {
        return option[this.textField];
      }
    }
  }

  getDisplay() {
    return (val) => this.getText(val);
  }

  valid(): boolean {
    return !this.errorRequired();
  }

  isRequired() {
    return this.required !== false;
  }

  errorRequired() {
    return this.isRequired() && this.validation.isEmpty(this.value);
  }

  togglePanel(evt) {
    evt.stopPropagation();
    if (this.inputAutoComplit.panelOpen) {
      this.inputAutoComplit.closePanel();
    } else {
      this.filteredOptions.next(this.items);
      this.inputAutoComplit.openPanel();
      this.input.nativeElement.focus();
    }
  }

  getClass() {
    let ret = '';
    if (this.isRequired()) {
      if (this.errorRequired()) {
        ret = 'ng-invalid';
      }
    }
    return ret;
  }
  onEnter() {
    if (this.first !== undefined) {
      this.myControl.setValue(this.first);
      this.inputAutoComplit.closePanel();
    }
  }

  desFocus() {
    this.input.nativeElement.blur();
  }

  focusOut() {
    if (!this.disableInput){
      setTimeout(() => {
        if (this.myControl.value !== undefined ) {
          if (this.myControl.value !== '' && this.first !== undefined) {
            try {
              this.myControl.setValue(this.myControl.value);
              this.setValue(this.myControl.value);
            } catch {
              this.myControl.setValue(this.first);
              this.setValue(this.first);
            }
            this.inputAutoComplit.closePanel();
  
          } else {
            this.myControl.setValue(undefined);
            this.setValue(undefined);
            this.inputAutoComplit.closePanel();
            this.value = '';
          }
        }
      }, 150);
    }
  }
    

  setSelect(value: any) {
    if (value !== undefined) {
      this.myControl.patchValue([]);
      this.myControl.setValue(value);
    }
  }
  resetCombo() {
    this.myControl.patchValue([]);
  }
}
