import { Builder } from 'path-shared/models/builder';
import { TextoAvisoFilterComponent } from './../../components/texto-aviso-filter/texto-aviso-filter.component';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { TextoAvisoEditorComponent } from './../../components/texto-aviso-editor/texto-aviso-editor.component';
import { TextoAvisoTableComponent } from './../../components/texto-aviso-table/texto-aviso-table.component';
import { BehaviorSubject } from 'rxjs';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Dictionary } from 'path-shared/models/dictionary';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Sigla } from 'app/sections/aparcare/text-informatiu/model/sigla';
import { TextoInformativoService } from 'app/sections/aparcare/text-informatiu/services/texto-informativo.service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { TextoAviso } from '../../model/texto-aviso';
import { TextoAvisoFilter } from '../../model/texto-aviso.filter';
import { TextoAvisoService } from '../../services/texto-aviso.service';

@Component({
  selector: 'app-texto-aviso-page',
  templateUrl: './texto-aviso-page.component.html',
  styleUrls: ['./texto-aviso-page.component.scss']
})
export class TextoAvisoPageComponent implements OnInit, AfterViewInit {

  permissionDialofRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: TextoAvisoFilter;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  editing = false;
  creating = false;
  currentTexto: TextoAviso;

  siglasDictionary: BehaviorSubject<Array<Dictionary>>;

  @ViewChild('textoAvisoTable') textoAvisoTable: TextoAvisoTableComponent;
  @ViewChild('textoAvisoEditor') textoAvisoEditor: TextoAvisoEditorComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private textoAvisoService: TextoAvisoService,
    private textoInformativoService: TextoInformativoService, // Extraemos las siglas del controlador de texcto informativo
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<TextoAvisoPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog
  ) {
    this.filterData = new TextoAvisoFilter('titulo', 'asc');
    if (data !== undefined && data !== null) {
      this.dataDialog = data;
    }
    this.siglasDictionary = new BehaviorSubject(new Array());
  }

  /* #region  COMPONENT LIFECYCLE */

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.TextoAvisoAparcare);

    // Descargamos las siglas.
    this.textoInformativoService.getSiglasTextos().subscribe((result: Array<Sigla>) => {
      const siglas = new Array<Dictionary>();
      result.forEach(element => {
        siglas.push(new Dictionary(element.sigla, element.pais));
      });
      this.siglasDictionary.next(siglas);
    });
  }

  ngAfterViewInit(): void {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Text Avís Aparcare'));
    }
  }

  /* #endregion */

  /* #region  EVENTS */

  edit(): void {
    this.editing = true;
  }

  create(): void {
    this.creating = true;
    this.currentTexto = Builder.newTextoAviso();
    // Debemos indicar a la tabla que queremos deseleccionar los elementos seleccionados.
    this.textoAvisoTable.resetSelection();
  }

  refresh(): void {
    // this.textoAvisoTable.load();
    this.clear();
  }

  onRowSelected(row: TextoAviso): void {
    if (row !== undefined) {
      this.currentTexto = Object.assign({}, row);
    } else {
      this.currentTexto = undefined;
    }
  }

  save(): void {
    this.textoAvisoService.setTextoAviso(this.textoAvisoEditor.currentTexto).subscribe(result => {
      // Hemos guardado el nuevo elemento. Actualizamos la tabla y el editor -> (Editor vacío y tabla con el nuevo elemento).
      this.clear();
    });
  }

  clear(): void {
    this.textoAvisoTable.load();
    this.textoAvisoTable.resetSelection();
    this.creating = false;
    this.editing = false;
    this.currentTexto = undefined;
  }

  cancel(): void {
    this.creating = false;
    this.editing = false;
    this.currentTexto = this.currentTexto;
  }

  filterUpdated(updated: boolean) {
    if (updated) {
      this.clear();
    }
  }

  /* #endregion */

  /* #region DIALOG's OPENING */

  onFilterClicked(): void {
    const ref = this.dialog.open(TextoAvisoFilterComponent, {
      width: '50%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.textoAvisoTable.filter,
        'idiomas': this.siglasDictionary.value
      }
    });
    ref.componentInstance.notify.subscribe((filter: TextoAvisoFilter) => {
      this.textoAvisoTable.filter.set(filter);
      this.textoAvisoTable.load();
    });
  }

  /* #endregion */

}
