<div style="display: flex; align-items: flex-start;">
  <app-form-combo-edit style="margin-right: 8ch;" [items]="this.carrers" valueField="idCarrer" textField="nomCarrer" [(value)]="this.selectedCarrer"></app-form-combo-edit>
  <button class="btn btn-outline-primary btn-xl" (click)="this.comprovarZona()">Comprovar zona</button>
</div>
<br/>
<mat-table #table matSort aria-label="Elements" [dataSource]="this.zones" matSortActive="ID_ZONA" matSortDirection="asc" class="is-header-sticky">
  
  <ng-container matColumnDef="ID_ZONA">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>ID</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.idZona}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="DESC_ZONA">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Descripció</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.descripcio}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="FECHA_INICIO">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data inici</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.fechaInici}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="FECHA_FIN">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data fi</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.fechaFin}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="ID_TARIFA">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>ID Tarifa</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.id_tarifa}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="ID_TIPO_TICKET">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>ID Tipus Tiquet</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.idTipoTicket}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="DENUNCIAS_POR_AREA">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Denúncies per àrea</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.DENUNCIAS_POR_AREA}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="ID_ZONA_PADRE">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>ID zona pare</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.idZonaPadre}}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>