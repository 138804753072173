import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ListadoErroresNotificacionesComponent } from 'app/sections/tramitar/expedients/components/notificar/components/listado-errores-notificaciones/listado-errores-notificaciones.component';
import { ListadoNotificacionesComponent } from 'app/sections/tramitar/expedients/components/notificar/components/listado-notificaciones/listado-notificaciones.component';
import { ListadoResolucionesComponent } from 'app/sections/tramitar/expedients/components/notificar/components/listado-resoluciones/listado-resoluciones.component';
import { SelectorFechasNotificarComponent } from 'app/sections/tramitar/expedients/components/notificar/components/selector-fechas-notificar/selector-fechas-notificar.component';
import { InfoNotificacio } from 'app/sections/tramitar/expedients/models/info-notificacio.model';
import { FechasNotificacionDto } from 'app/sections/tramitar/expedients/models/notificar/fechas-notificacion.dto';
import { NotificarInicioRequestDto } from 'app/sections/tramitar/expedients/models/notificar/notificar-inicio-request.dto';
import { NotificarRequestDto } from 'app/sections/tramitar/expedients/models/notificar/notificar-request.dto';
import { ResultadoNotificacionDenunciasCurrent } from 'app/sections/tramitar/expedients/models/notificar/resultado-notificacion-denuncias-current.dto';
import { ProgressBarTramitacionDto } from 'app/sections/tramitar/expedients/models/ProgressBarTramitacionDto';
import { NotificarService } from 'app/sections/tramitar/expedients/services/notificar/notificar.service';
import { TramitarService } from 'app/sections/tramitar/expedients/services/tramitar.service';
import { EnvioBopComponent } from 'app/sections/tramitar/teu/pages/envio-bop/envio-bop.component';
import { PropostesTableComponent } from 'app/sections/propostes/components/propostes-list/propostes-table/propostes-table.component';
import { PropostesListComponent } from 'app/sections/propostes/pages/propostes-list/propostes-list.component';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { GraficoTramitacion } from '../../models/graficoTramitacion.model';
import { ResumenTramitacion } from '../../models/resumenTramitacion.model';
import { DashboardService } from '../../services/dashboard.service';
import { NotificacionsDenunciaFilter } from 'app/sections/notificacions/models/NotificacionDenuncia/notificacions-denuncia-filter';
import { Observable } from 'rxjs';
import { ResolucionFilter } from 'app/sections/notificacions/models/NotificacionDenuncia/resolucion-filter';

@Component({
  selector: 'app-complete-dash-tramitacion',
  templateUrl: './complete-dash-tramitacion.component.html',
  styleUrls: ['./complete-dash-tramitacion.component.scss']
})
export class CompleteDashTramitacionComponent implements OnInit {
  //@Input() datos: GraficoTramitacion;
  @Input() datos: ResumenTramitacion;
  @ViewChild('myDialogNotificaciones') myDialogNotificaciones: TemplateRef<any>;
  @ViewChild('myDialogC60') myDialogC60: TemplateRef<any>;
  infoNotificar: InfoNotificacio = new InfoNotificacio();
  porcentajeC60: number;
  mensajePorcentajeC60: string='';
  porcentajeNotificaciones: number;
  mensajePorcentajeNotificaciones: string='';
  /* #region  INICIALIZACION */

  constructor(
    private notificarService: NotificarService,
    private tramitarService: TramitarService,
    public auth: AuthorizationService,
    private dialog: MatDialog,
    private not: NotificationService,
    private dashService: DashboardService,) { }

  ngOnInit() {
    this.infoNotificar.SicerActivo= true;
  }

  recalcular(){
    this.dashService.recalcularDashTramitacion().subscribe(x =>{
      this.datos = x;
    });
  }

  procesarDenuncias(): void {
    // Iniciamos el proceso de generacion de c60 y obtenemos informacion necesaria para continuar.
    this.notificarService.inicioProcesoNotificacion(new NotificarInicioRequestDto(this.infoNotificar.SicerActivo, true)).subscribe((info: FechasNotificacionDto) => {
      if (info) {
        /* Ya se ha iniciado el proceso de notificacion de denuncias. Podemos proceder... */
        // Ahora mostramos un popup de seleccion de fechas con la informacion recibida.
        const ref = this.dialog.open(SelectorFechasNotificarComponent, {
          width: '45%',
          closeOnNavigation: false,
          autoFocus: true,
          data: {'info':info,'teDescompte':true}
        });
        ref.componentInstance.notify.subscribe((newFechas: FechasNotificacionDto) => {
          // Hemos modificado/o aceptado las fechas. Podemos realizar el proceso de notificacion de denuncias.
          const request = new NotificarRequestDto(
            newFechas.fechaPrimeraNotificacion,
            newFechas.fechaSegundaNotificacion,
            newFechas.fechaTerceraNotificacion,
            newFechas.fechaCuartaNotificacion,
            this.infoNotificar.SicerActivo);
            // region Progress Bar
            this.initProgressBar();
          
          this.notificarService.iniciaProcesoNotificarDenuncias(request,new NotificacionsDenunciaFilter('exp', 'asc')).subscribe((result: ResultadoNotificacionDenunciasCurrent) => {
             // Recibimos el resultado, dependera de si sicert esta activo o no.
             if (this.infoNotificar.SicerActivo) {
              // Mostramos los errores y la pantalla de listado de notificaciones.
              const refNotificaciones = this.dialog.open(ListadoNotificacionesComponent, {
                width: '70%',
                height: '75%',
                autoFocus: true,
                closeOnNavigation: false,
                data: result,
               // position: { top: '25%' }
              });
              refNotificaciones.componentInstance.notify.subscribe(finalizar => {
                if (finalizar) {
                  // Enviamos peticion para finalizar el envio.
                  this.notificarService.finalizaProcesoNotificarDenuncias(result.notificacionesPendientes).subscribe(() => {
                    // Hemos podido finalizar el proceso de notificacion de denuncias.
                    this.recalcular();
                  }, error => {
                    // Ha habido error pero debemos refrescar igualmente para ver que informacion tenemos ahora.
                    this.recalcular();
                  });
                }
              });  
              if(result.errors !== undefined && result.errors !== null && result.errors.length>0){
                const refErrores = this.dialog.open(ListadoErroresNotificacionesComponent, {
                  width: '45%',
                  closeOnNavigation: false,
                  autoFocus: true,
                  data: result.errors,
                  position: { top: '25%' }
                });
              }
            } else {
              // Mostramos directamente la pantalla de seleccion de reports.
              // TODO -> MANUAL AUN NO.
              // this.refresh();
            }
          });
        });
      }
    });
  }
  procesarPropuestasResolucion(): void {
    // Iniciamos el proceso de generacion de c60 y obtenemos informacion necesaria para continuar.
    this.notificarService.inicioProcesoNotificacion(new NotificarInicioRequestDto(false, false)).subscribe(info => {
      if (info) {
        /* Ya se ha iniciado el proceso de notificacion de propuestas de resolucion. Podemos proceder... */
        // Ahora mostramos un popup de seleccion de fechas con la informacion recibida.
        const ref = this.dialog.open(SelectorFechasNotificarComponent, {
          width: '45%',
          closeOnNavigation: false,
          autoFocus: true,
          data: {'info':info,'teDescompte':true}
        });
        ref.componentInstance.notify.subscribe((newFechas: FechasNotificacionDto) => {
          // Hemos modificado/o aceptado las fechas. Podemos realizar el proceso de notificacion de denuncias.
          const request = new NotificarRequestDto(
            newFechas.fechaPrimeraNotificacion,
            newFechas.fechaSegundaNotificacion,
            newFechas.fechaTerceraNotificacion,
            newFechas.fechaCuartaNotificacion,
            this.infoNotificar.SicerActivo);
            
            this.initProgressBar();

            this.notificarService.iniciaProcesoNotificarPropuestasResolucion(request).subscribe((result: ResultadoNotificacionDenunciasCurrent) => {
              // Recibimos el resultado, dependera de si sicert esta activo o no.
              if (this.infoNotificar.SicerActivo) {
               // Mostramos los errores y la pantalla de listado de notificaciones.
               const refNotificaciones = this.dialog.open(ListadoNotificacionesComponent, {
                 width: '70%',
                 height: '75%',
                 autoFocus: true,
                 closeOnNavigation: false,
                 data: result,
                // position: { top: '25%' }
               });
               refNotificaciones.componentInstance.notify.subscribe(finalizar => {
                 if (finalizar) {
                   // Enviamos peticion para finalizar el envio.
                   this.notificarService.finalizaProcesoNotificarPropuestasResolucion(result.notificacionesPendientes).subscribe(result => {
                    // Recibimos el resultado, dependera de si sicert esta activo o no.
                    if (this.infoNotificar.SicerActivo) {
                      // Mostramos los errores y la pantalla de listado de notificaciones.
                    } else {
                      // Mostramos directamente la pantalla de seleccion de reports.
                    }
                  });
                 }
               });
               if(result.errors !== undefined && result.errors !== null && result.errors.length>0){
                const refErrores = this.dialog.open(ListadoErroresNotificacionesComponent, {
                  width: '45%',
                  closeOnNavigation: false,
                  autoFocus: true,
                  data: result.errors,
                  position: { top: '25%' }
                });
               }
               
             } else {
               // Mostramos directamente la pantalla de seleccion de reports.
               // TODO -> MANUAL AUN NO.
               // this.refresh();
             }
           });
         
            // this.refresh();
          });
      }
    });
  }
  procesarResoluciones(): void {
    const dialogResoluciones=this.ShowResoluciones();
    const filtroResoluciones$=this.getFilterResoluciones(dialogResoluciones);

    this.initProgressBar();
    filtroResoluciones$.subscribe((filtroResoluciones)=>{ 
      const resultadoResolucionesDto$=this.notificarService.procesarResoluciones(filtroResoluciones).subscribe(result=>{
        const refNotificaciones = this.dialog.open(ListadoNotificacionesComponent, {
          width: '70%',
          height: '75%',
          autoFocus: true,
          closeOnNavigation: false,
          data: { 'result': result, 'isSicer': this.infoNotificar.SicerActivo, 'tipoNoti': 2 }
        });
        dialogResoluciones.close();
        refNotificaciones.componentInstance.notify.subscribe(finalizar => {
          if (finalizar) {
              this.notificarService.finalizaResoluciones(result.notificacionesPendientes).subscribe(result => {
                // Hemos podido procesar la notificacion de resoluciones. Refrescamos la informacion.
                this.recalcular();
              }, error => {
                // Ha habido error pero debemos refrescar igualmente para ver que informacion tenemos ahora.
                this.recalcular();
              });
          }
        });

      });
    })
  }
  
  procesarRecursoReposicion(): void {
    if (this.infoNotificar.SicerActivo) {
      // Si tienen sicer activo, generamos la c60 e ininciamos el procedimiento.
      // Iniciamos el proceso de generacion de c60 y obtenemos informacion necesaria para continuar.
      this.notificarService.inicioProcesoNotificacion(new NotificarInicioRequestDto(false, false)).subscribe(info => {
        if (info) {
          /* Ya se ha iniciado el proceso de notificacion de propuestas de resolucion. Podemos proceder... */
          // Ahora mostramos un popup de seleccion de fechas con la informacion recibida.
          const ref = this.dialog.open(SelectorFechasNotificarComponent, {
            width: '45%',
            closeOnNavigation: false,
            autoFocus: true,
            data: {'info':info,'teDescompte':true}
          });
          ref.componentInstance.notify.subscribe((newFechas: FechasNotificacionDto) => {
            const request = new NotificarRequestDto(
              newFechas.fechaPrimeraNotificacion,
              newFechas.fechaSegundaNotificacion,
              newFechas.fechaTerceraNotificacion,
              newFechas.fechaCuartaNotificacion,
              this.infoNotificar.SicerActivo);
              
              this.initProgressBar();
              this.notificarService.iniciaProcesoNotifcarRecursosReposicion(request).subscribe((result: ResultadoNotificacionDenunciasCurrent) => {
                // Recibimos el resultado, dependera de si sicert esta activo o no.
                if (this.infoNotificar.SicerActivo) {
                 // Mostramos los errores y la pantalla de listado de notificaciones.
                 const refNotificaciones = this.dialog.open(ListadoNotificacionesComponent, {
                   width: '70%',
                   height: '75%',
                   autoFocus: true,
                   closeOnNavigation: false,
                   data: result,
                  // position: { top: '25%' }
                 });
                 refNotificaciones.componentInstance.notify.subscribe(finalizar => {
                   if (finalizar) {
                    this.notificarService.finalizaProcesoNotificarRecursosReposicion(result.notificacionesPendientes).subscribe(result => {
                      // Hemos podido procesar la notificacion de resoluciones. Refrescamos la informacion.
                      this.recalcular();
                     }, error => {
                       // Ha habido error pero debemos refrescar igualmente para ver que informacion tenemos ahora.
                       this.recalcular();
                     });
                   }
                 });  
                 if(result.errors !== undefined && result.errors !== null && result.errors.length>0){
                   const refErrores = this.dialog.open(ListadoErroresNotificacionesComponent, {
                     width: '45%',
                     closeOnNavigation: false,
                     autoFocus: true,
                     data: result.errors,
                     position: { top: '25%' }
                   });
                 }
               } else {
                 // Mostramos directamente la pantalla de seleccion de reports.
                 // TODO -> MANUAL AUN NO.
                 // this.refresh();
               }
             });
              // Hemos modificado/o aceptado las fechas. Podemos realizar el proceso de notificacion de resoluciones.
             
           
          });
        }
      });
    } else {
      // No tienen licencia de sicer. Generamos el informe y lo descargamos.
      this.notificarService.procesarRecursosReposiciones().subscribe(result => {
        if (result) {
          // Descargamos los informes.
        }
      });
    }
  }

  initProgressBar(){
    // region Progress Bar
    this.not.onTramitacionC60Change.subscribe((progressBar:ProgressBarTramitacionDto) => {
      if(this.porcentajeC60 === undefined || this.porcentajeC60 === null ){
        this.dialog.open(this.myDialogC60, { disableClose: true, width: '25%', height: '12%', id: 'porcentajeC60', position: { bottom: '15%' }});
      }
      this.mensajePorcentajeC60 = progressBar.mensaje;
      this.porcentajeC60 = progressBar.porcentaje;
      if(this.porcentajeC60 === 100){
        this.porcentajeC60 = null;
        this.dialog.getDialogById('porcentajeC60').close();
      }
    });

    this.not.onTramitacionNotificacionesChange.subscribe((progressBar:ProgressBarTramitacionDto) => {

    if(this.porcentajeNotificaciones === undefined || this.porcentajeNotificaciones === null ){
      this.dialog.open(this.myDialogNotificaciones, { disableClose: true, width: '25%', height: '12%', id: 'porcentajeNotificaciones', position: { bottom: '15%' }});
    }
    this.mensajePorcentajeNotificaciones = progressBar.mensaje;
    this.porcentajeNotificaciones = progressBar.porcentaje;
    if(this.porcentajeNotificaciones === 100){
      this.porcentajeNotificaciones = null;
      this.dialog.getDialogById('porcentajeNotificaciones').close();
    }
    });
    // end region Progress Bar
  }

  infraccionesBOE(){
    const ref = this.dialog.open(EnvioBopComponent, {
      width: '90%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {autoInit: true, autoInitDenuncias:true}
    });
  }

  alegacioneBOE(){
    const ref = this.dialog.open(EnvioBopComponent, {
      width: '90%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {autoInit: true, autoInitAlegaciones:true}
    });
  }
  
  recursoAlegacioneBOE(){
    const ref = this.dialog.open(EnvioBopComponent, {
      width: '90%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {autoInit: true, autoInitRecursoAlegaciones:true}
    });
  }
  autoInitResolucionesBOE(){
    const ref = this.dialog.open(EnvioBopComponent, {
      width: '90%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {autoInit: true, autoInitResoluciones:true}
    });
  }
  autoInitSancionesFimresBOE(){
    const ref = this.dialog.open(EnvioBopComponent, {
      width: '90%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {autoInit: true, autoInitSancionesFimres:true}
    });
  }
  propuestas30Dias(){
    const ref = this.dialog.open(PropostesListComponent, {
      width: '90%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {isDialog: true, autoInit: true, autoInit30Dias:true}
    });
  }
  propuestas60Dias(){
    const ref = this.dialog.open(PropostesListComponent, {
      width: '90%',
      height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {isDialog: true, autoInit: true, autoInit60Dias:true}
    });
  }

  ShowResoluciones() {
    const dialogResoluciones= this.dialog.open(ListadoResolucionesComponent,{
      width: '75%',
      height: '80%',
      autoFocus: true,
    });
    return dialogResoluciones;
  }
  getFilterResoluciones(dialogResoluciones:MatDialogRef<ListadoResolucionesComponent,any>): Observable<ResolucionFilter> {
    return dialogResoluciones.componentInstance.notify;
  }

  /* #endregion */
}
