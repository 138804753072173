var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import * as i0 from "@angular/core";
var defaultSortParam = 'descripcio';
var defaultSortDirection = 'desc';
var ZonesFilterService = /** @class */ (function (_super) {
    __extends(ZonesFilterService, _super);
    function ZonesFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ZonesFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        return new HttpParams()
            .append('idZona', filter.idZona ? filter.idZona : '')
            .append('descripcio', filter.descripcio ? filter.descripcio : '')
            .append('fechaInici', filter.fechaInici === null ? null : filter.fechaInici.toString())
            .append('fechaFin', filter.fechaFin === null ? null : filter.fechaFin.toString())
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    ZonesFilterService.ngInjectableDef = i0.defineInjectable({ factory: function ZonesFilterService_Factory() { return new ZonesFilterService(); }, token: ZonesFilterService, providedIn: "root" });
    return ZonesFilterService;
}(CustomFilterService));
export { ZonesFilterService };
