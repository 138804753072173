import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FilesService } from 'path-shared/services/files/files.service';
import { FileDownloader } from 'path-shared/services/http/fileDownloader';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DenunciaExpedienteAcuse } from 'app/sections/denuncies/models/denuncia-expediente-acuse';
import { Builder } from 'path-shared/models/builder';
import { DenunciesService } from 'path-denuncies-services/denuncies.service';
import { AcusosFilterComponent } from './acusos-filter/acusos-filter.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AcusosTableComponent } from './acusos-table/acusos-table.component';
import { AcuseFilter } from '../models/acus-de-rebut.filter';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { InformarAcusosComponent } from './informar-acusos/informar-acusos.component';

enum TipoImportador {
  AcusosRebut,
  ExpedientExtern
}

@Component({
  selector: 'app-acusos',
  templateUrl: './acusos.component.html',
  styleUrls: ['./acusos.component.scss']
})
export class AcusosComponent implements OnInit {

  rutaPlantillaAcuse: string;
  acusesFilterDialogRef: MatDialogRef<AcusosFilterComponent>;
  tipoImportador: TipoImportador
  filterData: AcuseFilter;
  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  informarAcusosDialogRef: MatDialogRef<InformarAcusosComponent>;

  public dataAcuse: DenunciaExpedienteAcuse;
  
  @ViewChild(AcusosTableComponent) acusosTable: AcusosTableComponent;
  @ViewChild('uploadFileInput') input: ElementRef;

  constructor(
    private denunciesService: DenunciesService,
    public auth: AuthorizationService,
    public filesService: FilesService,
    private i18n: I18n,
    private dialog: MatDialog,
    private toastr: ToastrService
    ) {
      this.dataAcuse = Builder.newDenunciaExpedienteAcuse();
      this.rutaPlantillaAcuse = '#BaseDirectory#Plantillas/Plantilla_Acuse.txt';
     }

  ngOnInit() { 
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];

      if (this.tipoImportador === TipoImportador.AcusosRebut) {
        const reader: FileReader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = () => {
          this.dataAcuse.name = file.name;
          this.dataAcuse.size = file.size;
          this.dataAcuse.content = reader.result.toString();
  
          this.denunciesService.cargarExpedienteAcuse(this.dataAcuse).subscribe(result => {
            this.toastr.info(this.i18n('Total expedients processats correctament: ')  + Number.parseInt(result));
            this.acusosTable.updateAcusesPage();
          });
        }
      }
    }
  }

  onFilterClicked() {
    this.acusesFilterDialogRef = this.dialog.open(AcusosFilterComponent, {
      width: '50%',
      height: 'auto',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.acusosTable.filter
      }
    });
  }

  carregaAcusosClick() {
    this.tipoImportador = TipoImportador.AcusosRebut
    this.input.nativeElement.click();
  }

  descarregaAcusosClick() {
    this.filesService.getFile(this.rutaPlantillaAcuse).subscribe(file => {
      const name = 'Plantilla_Acuse.txt';
      const contentType = file.headers.get('Content-Type');
      if (contentType !== null && contentType !== undefined) {
        FileDownloader.downLoadFile([file.body],  contentType + ';charset=utf-8', name);
      } else {
        this.toastr.error(this.i18n('No s\'ha trobat el fitxer'));
      }
    });
  }

  updateAcusos() {
    this.acusosTable.loadAcusesPage();
  }

  onPermissionClick() {
    this.permissionDialogRef = this.dialog.open(PermissionDialogComponent,
      {
        width: '50%',
        minHeight: '70%',
        closeOnNavigation: false,
        autoFocus: true,
        data: this.auth.Pages.Acusos,
      });
  }

  informarAcusos(esAcceptat: boolean) {
    this.informarAcusosDialogRef = this.dialog.open(InformarAcusosComponent,
      {
        width: '30%',
        minHeight: '70%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {
          acusos: this.acusosTable.displayedItems.filter(x => x.expedient != null && x.checked),
          isAcceptat: esAcceptat
        },
      }
    );

    this.informarAcusosDialogRef.afterClosed().do(()=> this.updateAcusos());
  }
}
