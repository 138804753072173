import { api } from 'path-shared/configuration/urls';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { CookieService } from 'ngx-cookie-service';
import { StartupService } from 'path-shared/services/startup/startup.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/http/httpSecureClient";
import * as i2 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i3 from "../../services/authentication/auth-guard.service";
import * as i4 from "../../services/startup/startup.service";
var DropdownInstitucionsService = /** @class */ (function () {
    function DropdownInstitucionsService(http, cookieService, authGuard, startupService) {
        this.http = http;
        this.cookieService = cookieService;
        this.authGuard = authGuard;
        this.startupService = startupService;
    }
    DropdownInstitucionsService.prototype.getInstitucions = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlInstitucionesByUserAuthenticated'], null);
        }
    };
    DropdownInstitucionsService.prototype.setNewIdInstitution = function (id) {
        sessionStorage.setItem('IdInstitucion', id);
        localStorage.setItem('IdInstitucion', id);
        this.cookieService.set('InstitucioSeleccionada', id);
        this.startupService.GetParametresObservable().subscribe(function (result) {
            sessionStorage.setItem('parameters', JSON.stringify(result));
            location.reload();
        });
    };
    DropdownInstitucionsService.ngInjectableDef = i0.defineInjectable({ factory: function DropdownInstitucionsService_Factory() { return new DropdownInstitucionsService(i0.inject(i1.HttpSecureClient), i0.inject(i2.CookieService), i0.inject(i3.AuthGuardService), i0.inject(i4.StartupService)); }, token: DropdownInstitucionsService, providedIn: "root" });
    return DropdownInstitucionsService;
}());
export { DropdownInstitucionsService };
