export class OpcionesLeyenda {

    trackingPda: boolean;
    denuncias: boolean;
    denunciasPolicia: boolean;
    denunciasZonaAzul: boolean;
    alarmasSistema: boolean;
    alarmasSmartphones: boolean;
    maquinas: boolean;
    ocupacion: boolean;
    ocupacionGeneral: boolean;
    ocupacionCalor: boolean;
    trackingGrua: boolean;
    propuestasGrua: boolean;
    propuestasGruaAceptadas: boolean;
    propuestasGruaRechazadas: boolean;
    propuestasGruaPendientes: boolean;
    senalesVerticales: boolean;
    propuestasGruaRetiradas: boolean;

    constructor() {
        this.trackingPda = false;
        this.denuncias = false;
        this.denunciasPolicia = false;
        this.denunciasZonaAzul = false;
        this.alarmasSistema = false;
        this.alarmasSmartphones = false;
        this.maquinas = false;
        this.ocupacion = false;
        this.ocupacionGeneral = false;
        this.ocupacionCalor = false;
        this.trackingGrua = false;
        this.propuestasGrua = false;
        this.propuestasGruaAceptadas = false;
        this.propuestasGruaPendientes = false;
        this.propuestasGruaRechazadas = false;
        this.propuestasGruaRetiradas = false;
        this.senalesVerticales = false;
    }
}
