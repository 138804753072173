import { Component, OnInit, ViewChild, AfterViewInit, OnChanges, Output, Input, EventEmitter, SimpleChanges, Inject } from '@angular/core';
import { MatPaginator, MatSort, SortDirection, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Denuncia } from '../../models/denuncia.model';
import { DenunciaFilter } from '../../models/denuncia.filter';
import { HttpSecureClient } from '../../../../shared/services/http/httpSecureClient';
import { merge, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DenunciesService } from '../../services/denuncies.service';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { DenunciesFilterService } from 'path-denuncies-services/denuncies-filter.service';
import { DenunciesImagesComponent } from '../denuncies-editor/components/denuncies-images/denuncies-images.component';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { MessagingService } from 'path-shared/services/messaging/messaging.service';
import { ExcelDownloadService } from 'path-shared/services/excel/excel-download.service';
import { DownloadExcelDialogComponent } from 'path-shared/components/dialogs/download-excel-dialog/download-excel-dialog.component';
import { Builder } from 'path-shared/models/builder';
import { LoaderService } from 'path-shared/components/loader/loader.service';
import { ExpedientsComponent } from 'app/sections/expedients/expedients.component';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { DenunciesEditorComponent } from '../denuncies-editor/denuncies-editor.component';

@Component({
  selector: 'app-denuncies-table',
  templateUrl: './denuncies-table.component.html'
})
export class DenunciesTableComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataDialog: ComponentDialog = Builder.newComponentDialog();

  denunciesImages: any = [];
  denunciesImageDialogRef: MatDialogRef<DenunciesImagesComponent>;
  expedienteFilter: string;
  subscription: Subscription;
  data: Denuncia[];
  dataSource: CustomDataSource<Denuncia, DenunciaFilter>;
  highlightedRows = [];
  filter: DenunciaFilter = new DenunciaFilter('data', 'desc');
  filterPage: PaginationFilter;
  // selectedItems = [];
  displayedItems = [];
  expedientsDialog: MatDialogRef<ExpedientsComponent>;

  // Emits
  // @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  @Input() updatedFilterData: DenunciaFilter;
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['Check', 'estats', 'matricula', 'expedient', 'bulleti', 'imatges', 'data', 'hora', 'agent', 'dataAnul', 'dataTrans',
    'dataRecep', 'dataCobra', 'lloc', 'import', 'dataLimitDte', 'impDesc', 'codAnul', 'infractor', 'llei', 'article', 'descInf', 'zona', 'motiu_anulacio','userAnul', 'aparato'];

  constructor(
    private i18n: I18n,
    private http: HttpSecureClient,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private denunciesService: DenunciesService,
    private filterService: FilterService,
    private msg: MessagingService,
    private excel: ExcelDownloadService,
    private loader: LoaderService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public dataOfDialog: ComponentDialog
    ) {
      if (dataOfDialog !== undefined && dataOfDialog.filter !==  undefined) {
        this.dataDialog = dataOfDialog;
      }
  }

  ngOnInit() {
    this.dataSource = CustomDataSource.create<Denuncia, DenunciaFilter>(this.paginator, new DenunciesFilterService(), 'urldenunciesquery', 'urlpaginationcount');
    this.dataSource.subject.subscribe((data) => {
      this.denunciesService.displayedItems = [];
      this.denunciesService.displayedItems['checkedValues'] = 0;
      data.forEach(item => {
        this.denunciesService.displayedItems[item.somid] = {
          id: item.somid,
          checked: false,
          tancat: item.tancat
        };
      });

      this.displayedItems = this.denunciesService.displayedItems;
    });
    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.paginator.pageIndex = 0;
        this.filterService.saveFilter('denuncies-filter', this.filter);
        this.loadDenunciasPage();
      }
    });

    this.filter.filterCleared.subscribe(updated => {
      if (updated) {
        this.paginator.pageIndex = 0;
        this.filterService.saveFilter('denuncies-filter', this.filter);
        this.loadDenunciasPage();
      }
    });
    if (this.dataDialog !== undefined && this.dataDialog.filter !== null) {
      this.filter.set(this.dataDialog.filter);
      this.displayedColumns.splice(0, 1);
    } else {
       this.filterService.loadFilter('denuncies-filter', this.filter);
    }
    this.filter.paginator.init(this.sort, this.paginator);

    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.expedienteFilterChange(null);
      }
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    this.paginator.page.subscribe(data => {
      this.paginator.pageIndex = data.pageIndex;
      this.paginator.pageSize = data.pageSize;
      this.loadDenunciasPage();
      console.log('paginator size: ' + data.pageSize);
    });

    this.loadDenunciasPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.updatedFilterData != null) {
      this.updateDenunciasPage();
    }
  }

  updateDenunciasPage() {
    this.updatedFilterData.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.updatedFilterData);
  }

  toggleAllCheckbox() {
    for (const item in this.displayedItems) {
      if (item !== 'checkedValues') {
        this.toggleItem(item);
      }
    }

    // this.getAllSelectedItems();
  }

  loadDenunciasPage() {
    if (this.dataSource !== undefined) {
      this.filter.paginator.set(this.sort, this.paginator);
      this.dataSource.loadFilter(this.filter);
    }
  }

  onRowClicked(row) {
    if (this.dataDialog !== undefined && this.dataDialog.filter !== null) {
      const denunciaDialog = this.dialog.open(DenunciesEditorComponent,
        {
          closeOnNavigation: false,
          autoFocus: true,
          data: {'id' : row.somid},
          width: '90%',

        });
    } else {
      this.notify.emit(row.somid);
    }
  }

  getCheckedValues() {
    return this.denunciesService.displayedItems['checkedValues'];
  }

  toggleItem(id) {
    const itemStatus = this.denunciesService.displayedItems[id].checked = !this.denunciesService.displayedItems[id].checked;

    if (itemStatus) {
      this.denunciesService.displayedItems['checkedValues'] = this.getCheckedValues() + 1;
    } else {
      this.denunciesService.displayedItems['checkedValues'] = this.getCheckedValues() - 1;
    }
  }

  generateExcel() {
    this.dialog.open(DownloadExcelDialogComponent, {
      closeOnNavigation: false,
      autoFocus: true,
      data: Builder.newDownloadExcelDialogData (this.denunciesService, this.filter, this.i18n('Denúncies'), this.sort.direction, this.sort.active)
    });

    // this.excel.downloadExcel(this.denunciesService.generateExcel(this.filter, this.sort.direction, this.sort.active));
  }

  viewImages(numDenuncia, id) {
    this.denunciesService.getDenunciaImagesBySomId(id).subscribe( images => {
      let img=[];
      img.push(images[0]);
      this.denunciesImages = images;
      this.denunciesImages.forEach(element => {
        element.NUM_DENUNCIA = numDenuncia;
      });

      this.denunciesImageDialogRef = this.dialog.open(DenunciesImagesComponent,
      {
        minWidth: '50%',
        maxWidth: '90%',
        maxHeight: '70%',
        closeOnNavigation: false,
        autoFocus: true,
        data: {'ficheros': images, 'numDenuncia': numDenuncia},
      });
      this.denunciesImageDialogRef.componentInstance.somId = id;

      this.denunciesImageDialogRef.afterClosed().subscribe(() => {
        this.denunciesService.getDenunciaImagesBySomId(id).subscribe( newImages => {
          this.dataSource.getData().find(x => x.somid === id).imatges = newImages.length;
          this.denunciesImages = newImages;
          this.denunciesImages.forEach(element => {
            element.NUM_DENUNCIA = numDenuncia;
          });
        });
      });
    });
  }
  expedienteFilterChange(event: any) {
    if (this.expedienteFilter !== undefined && this.expedienteFilter !== null && this.expedienteFilter !== '' ) {
      this.filter.filterTable = this.expedienteFilter;
      this.filter.updated(true);
    } else if (this.expedienteFilter === '' && this.filter.expediente !== this.expedienteFilter) {
      this.filter.filterTable = '';
      this.filter.updated(true);
    } else {
      this.filter.filterTable = '';
      this.filter.updated(false);
    }
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
  }

  getExpedients(expedient: string, numeroDenuncia: string) {
    this.denunciesService.getExpedient(expedient).subscribe( expedientsData => {
      this.expedientsDialog = this.dialog.open(ExpedientsComponent,
        {
          height: '80%',
          width: '52%',
          closeOnNavigation: false,
          autoFocus: true,
          data: {'expedient': expedientsData, 'numDenuncia': numeroDenuncia, 'imatges': this.denunciesImages}

        });
    });
  }
}
