import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { PaisosFilter } from '../models/paisos-filter';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class PaisosFilterService extends CustomFilterService {
  public createFilterParams(filter: PaisosFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('codi', (filter.codi ? filter.codi.toString() : ''))
    .append('descripcio', filter.descripcio)
    .append('paisActiu', this.getTristateValue(filter.paisActiu))
    .append('provCodificada', this.getTristateValue(filter.provCodificada))
    .append('sigles', filter.sigles)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
