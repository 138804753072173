<app-dialog-header label="Desestimar proposta" i18n-label></app-dialog-header>
<app-dialog-body>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <app-form-large-combo-edit i18n-label label="Motiu desestimar" [(value)]="this.currentMotiuDesestimar" [items]="this.subjects" valueField="ID" textField="DESCRIPCIO"></app-form-large-combo-edit>
        <!-- <mat-form-field>
          <mat-select [(ngModel)]="this.currentMotiuDesestimar" i18n-placeholder placeholder="Motiu desestinar">
            <mat-option *ngFor="let subject of this.subjects" [value]="subject.ID">
              {{ subject.DESCRIPCIO }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
    </div>
  </div>

  <mat-form-field>
    <textarea cols="20" matInput [ngModel]="this.description" (ngModelChange)="this.description = $event" i18n-placeholder placeholder="Comentari"></textarea>
  </mat-form-field>
</app-dialog-body>

<app-dialog-footer>
    <button type="button" class="btn btn-danger" (click)="rejectProposta()" i18n>Desestimar</button>
    <button type="button" class="btn btn-secondary" mat-dialog-close i18n>Cancel·lar</button>
</app-dialog-footer>
