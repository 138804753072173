import { BancIncidenciesRequestDto } from './../models/banc-inicidencies-requestDto';
import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'data';
const defaultSortDirection = 'asc';

@Injectable()
export class BancCarreguesIncidenciesFilterService extends CustomFilterService {
  public createFilterParams(filter: BancIncidenciesRequestDto, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('desde', Reflection.empty(filter.desde) || filter.desde === null ? null : filter.desde.toJSON())
    .append('hasta', Reflection.empty(filter.hasta) || filter.desde === null ? null : filter.hasta.toJSON())
    .append('noTramitados', this.getTristateValue(filter.noTramitados))
    .append('tramitados', this.getTristateValue(filter.tramitados))
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
  getTristateValue(value) {
    if (value === undefined || value === null) {
      return '';
    } else {
      return value.toString();
    }
  }
}
