/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/permisos.component.ngfactory";
import * as i2 from "../components/permisos.component";
import * as i3 from "./permisos-page.component";
var styles_PermisosPageComponent = [];
var RenderType_PermisosPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PermisosPageComponent, data: {} });
export { RenderType_PermisosPageComponent as RenderType_PermisosPageComponent };
export function View_PermisosPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-permisos", [], null, null, null, i1.View_PermisosComponent_0, i1.RenderType_PermisosComponent)), i0.ɵdid(1, 114688, null, 0, i2.PermisosComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_PermisosPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-permisos-page", [], null, null, null, View_PermisosPageComponent_0, RenderType_PermisosPageComponent)), i0.ɵdid(1, 114688, null, 0, i3.PermisosPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PermisosPageComponentNgFactory = i0.ɵccf("app-permisos-page", i3.PermisosPageComponent, View_PermisosPageComponent_Host_0, {}, {}, []);
export { PermisosPageComponentNgFactory as PermisosPageComponentNgFactory };
