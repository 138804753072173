import { Injectable } from "@angular/core";
import { CustomFilterService } from "path-shared/services/datasource/custom-filter-service";
import { HttpParams } from "@angular/common/http";
import { ActuacioMatriculaFilter } from "../models/actuacioMatricula-filter";

const defaultSortParam = 'matricula';
const defaultSortDirection = 'asc';

@Injectable()
export class ActuacioMatriculaFilterService extends CustomFilterService {
    public createFilterParams(filter: ActuacioMatriculaFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
        return new HttpParams()
        .append('matricula', (filter.matricula ? filter.matricula : ''))
        .append('datafi', filter.datafi == undefined ? '' : typeof(filter.datafi) === 'string' ?
        filter.datafi : filter.datafi.toDateString())
        .append('datainici', filter.datainici == undefined ? '' : typeof(filter.datainici) === 'string' ?
        filter.datainici : filter.datainici.toDateString())


        .append('start', start.toString())
        .append('size', pageSize.toString()) 
        .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
        .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    }
    
}