import { TurnosFilter } from './../models/turnos-filter';
import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';

import { Reflection } from 'path-shared/services/reflection/reflection';

const defaultSortParam = 'descripcion';
const defaultSortDirection = 'asc';

@Injectable()
export class TurnosFilterService extends CustomFilterService {
  public createFilterParams(filter: TurnosFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('descripcion', filter.descripcion)
  /*  .append('hora_inicio',  Reflection.empty(filter.hora_inicio) ? null :  typeof(filter.hora_inicio) === 'string' ?
    filter.hora_inicio : filter.hora_inicio.toString())
    .append('hora_fin',  Reflection.empty(filter.hora_fin) ? null :  typeof(filter.hora_fin) === 'string' ?
    filter.hora_fin : filter.hora_fin.toString())*/
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
