<app-filter-header title="Filtrar Reserves" i18n-title  (apply)="this.apply()" (reset)="this.reset()" (dismiss)="this.dismiss()"></app-filter-header>
<div class="modal-body">
  <div class="row">
    <div class="col-7">
      <div class="row">
        <app-form-date-edit id="reserves-filter-data" class="col-sm-4" i18n-label label="Data tiquet" id="fechaTicketInicio" [(value)]="filter.fechaInicio" type="calendar"></app-form-date-edit>
      </div>
    </div>
  </div>

  <div class="row">
    <app-form-text-edit id="reserves-filter-matricula" class="col-6" i18n-label label="Matrícula" [(value)]="filter.matricula"></app-form-text-edit>
    <app-form-text-edit id="reserves-filter-email" class="col-6" i18n-label label="Email" [(value)]="filter.email"></app-form-text-edit>
  </div>
</div>

