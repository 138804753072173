var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from './../../../../shared/models/filter-data';
var BonosResidentesFilter = /** @class */ (function (_super) {
    __extends(BonosResidentesFilter, _super);
    function BonosResidentesFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.nombre = '';
        _this.id = null;
        _this.fecha = null;
        _this.zona = '';
        _this.importe = null;
        _this.apellidos = '';
        _this.telefono = '';
        _this.dni = '';
        _this.tipResPrepago = null;
        _this.tipBono = null;
        _this.pago = '';
        _this.dataIni = null;
        _this.dataFi = null;
        _this.matricula = '';
        _this.labels['nombre'] = _this.i18n('Nombre');
        _this.labels['id'] = _this.i18n('Id');
        _this.labels['fecha'] = _this.i18n('Fecha');
        _this.labels['zona'] = _this.i18n('Zona');
        _this.labels['importe'] = _this.i18n('Importe');
        _this.labels['apellidos'] = _this.i18n('Apellidos');
        _this.labels['telefono'] = _this.i18n('Teléfono');
        _this.labels['dni'] = _this.i18n('Dni');
        _this.labels['tipResPrepago'] = _this.i18n('Tipo respuesta prepago');
        _this.labels['tipBono'] = _this.i18n('Tipo bono');
        _this.labels['pago'] = _this.i18n('Pago');
        _this.labels['dataIni'] = _this.i18n('Fecha inicial');
        _this.labels['dataFi'] = _this.i18n('Fecha final');
        _this.labels['matricula'] = _this.i18n('Matrícula');
        return _this;
    }
    return BonosResidentesFilter;
}(FilterData));
export { BonosResidentesFilter };
