import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { DenunciesEditorComponent } from 'app/sections/denuncies/components/denuncies-editor/denuncies-editor.component';
import { ToastrService } from 'ngx-toastr';
import { Builder } from 'path-shared/models/builder';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { CustomDataSource } from 'path-shared/services/datasource/custom.datasource';
import { FilterService } from 'path-shared/services/filter/filter.service';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EjecutivasOlot } from '../../../models/EjecutivasOlot.models';
import { TramitarExOlotFilter } from '../../../models/TramitarExOlot-filter.model';
import { TramitarExOlotFilterService } from '../../../services/tramitar-ex-olot-filter.service';
import { TramitarService } from '../../../services/tramitar.service';

@Component({
  selector: 'app-table-revisio-executiva-olot',
  templateUrl: './table-revisio-executiva-olot.component.html',
  styleUrls: ['./table-revisio-executiva-olot.component.scss']
})
export class TableRevisioExecutivaOlotComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  data: EjecutivasOlot[];
  dataSource: CustomDataSource<EjecutivasOlot, TramitarExOlotFilter>;
  displayedItems = [];
  highlightedRows = [];
  Seleccionats = 0;
  Total = 0;
  allSelected: boolean;
  filter: TramitarExOlotFilter = new TramitarExOlotFilter('EXP', 'asc');
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  displayedColumns = ['Check', 'EXP', 'Matricula', 'DNI', 'IdExterno', 'DomCod', 'Persona', 'CP', 'Domicili', 'Lloc', 'NomG5'];

  constructor(private dialog: MatDialog,
              private tramitarService: TramitarService,
              private filterService: FilterService,
              private not: NotificationService,
              private toastr: ToastrService,
              private i18n: I18n) { }

  ngOnInit() {
    this.allSelected = false;
    this.Seleccionats = 0;
    this.loadInici();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.filter.paginator.set(this.sort, this.paginator);
      this.filter.updated();
    });

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(tap(() => this.filter.updated()))
    .subscribe();

    this.load();
  }

  load() {
    this.filter.paginator.set(this.sort, this.paginator);
    this.dataSource.loadFilter(this.filter);
    this.Total = this.dataSource.getData().length;
  }

  loadInici() {
    this.dataSource = CustomDataSource.create<EjecutivasOlot, TramitarExOlotFilter>(this.paginator, new TramitarExOlotFilterService(), 'urlTramitarExOlot', 'urlInfoTramitarExOlot');
    
    this.dataSource.subject.subscribe((data) => {
      data.forEach(item => {
        this.displayedItems[item.EXP] = {
          id: item.EXP,
          checked: false
        };
      });
    });

    this.filter.filterUpdated.subscribe(updated => {
      if (updated) {
        this.load();
      }
    });

    this.not.onLoadG5.subscribe(value => {
      const item = this.dataSource.get('EXP', value.EXP);
      if (item !== null) {
        item.NomG5 = value.Response;
      }
    });

  }
  

  toggleAllCheckbox() {
    this.dataSource.getData().forEach(item => {
        item.Check = this.allSelected;
    });
    this.Seleccionats = this.dataSource.getData().filter(x => x.Check === true).length;
  }
  
  SelectedRow(row: EjecutivasOlot) {
    row.Check = !row.Check;
    this.Seleccionats = this.dataSource.getData().filter(x => x.Check === true).length;
    }

    onRowClicked(row: EjecutivasOlot) {
      const data: ComponentDialog = Builder.newComponentDialog(true, true, row.SomIdDenuncia);
        const ref = this.dialog.open(DenunciesEditorComponent, {
          width: '80%',
          height: '80%',
          maxHeight: '100vh',
          closeOnNavigation: false,
          autoFocus: true,
          data: data
        });
  }

  CarregarG5() {
    this.tramitarService.LoadG5().subscribe(y => {
      if (y !== undefined && y !== null) {
      }
    });
  }

  EnviarEjecutivesOlot() {
    let taula: EjecutivasOlot[];
    taula = [];
    if (this.dataSource.getData().filter(x => x.Check === true).length > 0) {
        this.tramitarService.urlEnviarEjecutivesOlot(this.dataSource.getData().filter(x => x.Check === true)).subscribe(y => {
          if (y === undefined || y === null || y !== true) {
            this.toastr.error(this.i18n('Algo ha fallado'));
          }
        });
    } else {
      this.toastr.info(this.i18n('Ningun Seleccionado'));
    }


  }
}
