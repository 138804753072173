<!-- <div class="row">
  <button class="btn btn-primary " type="button" [disabled]="!areFilesSelected()" (click)="moveFiles()" >
    <i class="fa fa-redo fa-lg"><span i18n> &nbsp; Recargar</span></i>  
  </button>
</div> -->
<app-toolbar *ngIf="!this.isContadorDup">
    <app-tool-button text="Recargar" i18n-text [disabled]="!areFilesSelected()" (click)="moveFiles()" color="has-color-blue" icon="fa-sync"></app-tool-button>
</app-toolbar>



<div class="row">
  <mat-table #table [dataSource]="files" matSort aria-label="Elements"  class="is-header-sticky"  >
  <ng-container matColumnDef="incorpora" >
    <mat-header-cell *matHeaderCellDef class="is-checkbox">
      <mat-checkbox (change)="toggleAllCheckbox()"></mat-checkbox>
  </mat-header-cell>
      <mat-cell *matCellDef="let row"  ><mat-checkbox #checkbox [(ngModel)]="row.selected" [disabled]="true"   ></mat-checkbox></mat-cell><!--(change)="this.Checked(row)"-->
    </ng-container>

    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Nombre</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.nombre}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" (click)="SeleccionarFila(row)" [ngClass]="{'rowSelectedClass': row.selected}"></mat-row><!--[ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"-->
  </mat-table>

</div>

