/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./envio-bop.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "../../components/envio/envio-boe-page/envio-boe-page.component.ngfactory";
import * as i7 from "../../components/envio/envio-boe-page/envio-boe-page.component";
import * as i8 from "../../../../../shared/services/authorization/authorization-service";
import * as i9 from "@ngx-translate/i18n-polyfill";
import * as i10 from "../../components/envio/envio-boe-table/envio-boe-table.component.ngfactory";
import * as i11 from "../../components/envio/envio-boe-table/envio-boe-table.component";
import * as i12 from "./envio-bop.component";
import * as i13 from "../../../expedients/services/notificar/notificar.service";
import * as i14 from "../../services/boe.service";
import * as i15 from "@angular/material/dialog";
import * as i16 from "ngx-toastr";
import * as i17 from "../../../../../shared/services/notification/notification.service";
var styles_EnvioBopComponent = [i0.styles];
var RenderType_EnvioBopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnvioBopComponent, data: {} });
export { RenderType_EnvioBopComponent as RenderType_EnvioBopComponent };
function View_EnvioBopComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Creant C60 ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mensajePorcentajeC60; _ck(_v, 4, 0, currVal_0); }); }
function View_EnvioBopComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "section", [["class", "progress-bar-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "determinate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(3, 49152, null, 0, i3.MatProgressBar, [i1.ElementRef, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"], mode: [1, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.porcentajeC60, ""); var currVal_4 = "determinate"; _ck(_v, 3, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).value; var currVal_1 = i1.ɵnov(_v, 3).mode; var currVal_2 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
function View_EnvioBopComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["style", " color:  #1E457C;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", "% "])), i1.ɵppd(5, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _co.porcentajeC60, "1.2-2")); _ck(_v, 4, 0, currVal_0); }); }
function View_EnvioBopComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnvioBopComponent_2)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnvioBopComponent_3)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EnvioBopComponent_4)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.porcentajeC60 !== null); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.porcentajeC60 !== null); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.porcentajeC60 !== null); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_EnvioBopComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DecimalPipe, [i1.LOCALE_ID]), i1.ɵqud(402653184, 1, { myDialogC60: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["myDialogC60", 2]], null, 0, null, View_EnvioBopComponent_1)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-envio-boe-page", [], null, [[null, "valueChange"], [null, "applyFilter"], [null, "publish"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter = $event) !== false);
        ad = (pd_0 && ad);
    } if (("applyFilter" === en)) {
        var pd_1 = (_co.filtrar() !== false);
        ad = (pd_1 && ad);
    } if (("publish" === en)) {
        var pd_2 = (_co.publish() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_EnvioBoePageComponent_0, i6.RenderType_EnvioBoePageComponent)), i1.ɵdid(4, 114688, null, 0, i7.EnvioBoePageComponent, [i8.AuthorizationService, i9.I18n], { value: [0, "value"], resumen: [1, "resumen"] }, { valueChange: "valueChange", applyFilter: "applyFilter", publish: "publish" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-envio-boe-table", [], null, null, null, i10.View_EnvioBoeTableComponent_0, i10.RenderType_EnvioBoeTableComponent)), i1.ɵdid(6, 114688, null, 0, i11.EnvioBoeTableComponent, [i9.I18n], { value: [0, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filter; var currVal_1 = _co.resumen; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.denuncias; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_EnvioBopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-envio-bop", [], null, null, null, View_EnvioBopComponent_0, RenderType_EnvioBopComponent)), i1.ɵdid(1, 114688, null, 0, i12.EnvioBopComponent, [i13.NotificarService, i14.BoeService, i15.MatDialog, i16.ToastrService, i17.NotificationService, i9.I18n, i15.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnvioBopComponentNgFactory = i1.ɵccf("app-envio-bop", i12.EnvioBopComponent, View_EnvioBopComponent_Host_0, {}, {}, []);
export { EnvioBopComponentNgFactory as EnvioBopComponentNgFactory };
