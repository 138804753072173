import { MotiusDesestimar } from './../models/motius-desestimar.model';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { MotiusDesestimarFilter } from '../models/motius-desestimar-filter';
import { MotiusDesestimarFilterService } from './motius-desestimar-filter-service';

const defaultSortParam = 'id';
const defaultSortDirection = 'desc';


@Injectable()
export class MotiusDesestimarService {

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setMotiusDesestimar(aparell: MotiusDesestimar): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetMotiuDesestimar'], aparell);
    }
  }

  removeMotiusDesestimar(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteMotiuDesestimar'].replace('{secure_id}', secure_id), null);
    }
  }

  getMotiusDesestimar(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMotiuDesestimar'].replace('{secure_id}', secure_id), null);
    }
  }

  getMotiusDesestimarCanviMatricula(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetMotiuDesestimarCanviMatricula'], null);
    }
  }

  generateExcel(filter: MotiusDesestimarFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new MotiusDesestimarFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlMotiusDesestimarExcel'],  params );
    }
  }
}
