import { SortDirection } from "@angular/material";
import { FilterData } from "path-shared/models/filter-data";

export class ActuacioIncidenciaFilter extends FilterData {
    somId: number;

    constructor(sortField: string, sortDir: SortDirection) {
      super(sortField, sortDir);
      this.somId = null;
  
      this.labels['somId'] = this.i18n('Id');
    }
}