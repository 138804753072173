import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TramitadorService } from 'path-denuncies-services/tramitador.service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { FiltersConfigurationService } from 'path-shared/services/FiltersConfiguration/filters-configuration.service';
import { TramitadorConfiguracionFiltro } from '../../models/tramitador-configuracion-filtro';

@Component({
  selector: 'app-tramitador-configuration',
  templateUrl: './tramitador-configuration.component.html',
  styleUrls: ['./tramitador-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TramitadorConfigurationComponent implements OnInit {

  filtro = new TramitadorConfiguracionFiltro();
  constructor(
    private filtersConfiguration: FiltersConfigurationService,
    private thisDialogRef: MatDialogRef<TramitadorConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TramitadorConfiguracionFiltro,
  ) {
    this.filtro = data;
   }

  ngOnInit() {
  }

  saveChanges() {
    this.filtersConfiguration.save(this.filtersConfiguration.Pantallas.TRAMITADOR, this.filtro).subscribe(()=>{
      this.thisDialogRef.close(true);
    })
  }
}
