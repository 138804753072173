export class CustomDataMarker {

    associatedResourceId: any;
    internalId: string;

    text: String;
    latitude: number;
    longitude: number;

    iconPath: String;
    iconFillColor: String;
    iconFillOpacity: number;
    innerIcon: String;

    dragable: boolean;
    animable: boolean;
    showHover: boolean;

    constructor(
        associatedResourceId: any,
        internalId: string,
        text: String,
        latitude: number,
        longitude: number,
        iconPath: String,
        fillColor: String,
        fillOpacity: number,
        innerIcon: String,
        dragable: boolean,
        animable: boolean,
        showHover: boolean) {
        this.associatedResourceId = associatedResourceId;
        this.internalId = internalId;
        this.text = text;
        this.latitude = latitude;
        this.longitude = longitude;
        this.iconPath = iconPath;
        this.iconFillColor = fillColor;
        this.iconFillOpacity = fillOpacity;
        this.innerIcon = innerIcon;

        this.dragable = dragable;
        this.animable = animable;
        this.showHover = showHover;
    }

}
