import { TipusMatriculaFilterService } from './../../../tipus-matricules/services/tipus-matricules-filter.service';
import { TipusMatricula } from 'app/sections/ciutat/tipus-matricules/models/tipus-matricula.model';
import { DropdownInstitucionsService } from 'path-shared/components/dropdown-institucions/dropdown-institucions.service';
import { MatriculesService } from './../../services/matricules.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormService } from 'path-shared/services/form/form.service';
import { MatDialogRef } from '@angular/material';
import { MatriculesEditorComponent } from '../matricules-editor/matricules-editor.component';
import { MatriculaLoader } from '../../models/matricula-loader.model';
import { Builder } from 'path-shared/models/builder';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Instituciones } from 'path-shared/models/instituciones';
import { InstitucionsService } from 'path-shared/services/institucions/institucions.service';
import { TipusMatriculesService } from 'app/sections/ciutat/tipus-matricules/services/tipus-matricules.service';

@Component({
  selector: 'app-matricules-loader',
  templateUrl: './matricules-loader.component.html',
  styleUrls: ['./matricules-loader.component.scss']
})
export class MatriculesLoaderComponent implements OnInit {

  private file: File;
  public data: MatriculaLoader;
  public deleteLabels = [this.i18n('Afegir'), this.i18n('Afegir i esborrar')];
  public deleteValues = [false, true];
  public typeLabels = ['Somintec', 'Parkeon'];
  public typeValues = [true, false];
  institucions: Instituciones[];
  tipusMatricules: TipusMatricula[];

  @ViewChild('mainForm') private mainForm: ElementRef;

  constructor(
    private toastr: ToastrService,
    private i18n: I18n,
    public thisDialogRef: MatDialogRef<MatriculesEditorComponent>,
    private formService: FormService,
    private matriculesService: MatriculesService,
    private institucionsService: InstitucionsService,
    private tipusMatriculaService: TipusMatriculesService
  ) {
    this.data = Builder.newMatriculaLoader();
    this.institucions = [];
  }

  ngOnInit() {
    this.institucionsService.getInstitucions().subscribe(data => {
      this.institucions = data;
      this.data.institution = this.institucionsService.getCurrentInstitution();
    });
  }

  loadMatricules() {

    if (this.file === undefined || this.file === null) {
      this.toastr.warning(this.i18n('Cal seleccionar un fitxer per carregar!'));
    } else {
      if (this.formService.allFieldsValid(this.mainForm)) {
        const reader: FileReader = new FileReader();
        this.data.name = this.file.name;
        this.data.size = this.file.size;
        reader.readAsText(this.file);
        reader.onloadend = () => {
          this.data.content = reader.result.toString();
          this.matriculesService.loadMatricules(this.data).subscribe(result => {
            this.toastr.info(this.i18n('Matrícules carregades correctament'));
            this.thisDialogRef.close(true);
          });
        };
      } else {
        this.toastr.warning(this.i18n('Hi ha errors en el formulari!'));
        if (!this.mainForm.nativeElement.classList.contains('displayErrors')) {
          this.mainForm.nativeElement.classList.add('displayErrors');
        }
      }
    }
  }

  cancel() {
    this.thisDialogRef.close(false);
  }

  fileReady(file) {
    this.file = file;
  }


  institutionSelected() {
    this.data.type = null;
    if (this.data.institution !== null && this.data.institution !== undefined && this.data.institution !== '') {
      this.tipusMatriculaService.getTipusMatriculaByInstitution(this.data.institution).subscribe(result => {
        this.tipusMatricules = result;
        const emptyRow = Builder.newTipusMatricula();
        emptyRow.codi = 0;
        emptyRow.descripcio = this.i18n('PRIORITAT CÀRREGA');
        this.tipusMatricules.unshift(emptyRow);
      });
    }
  }
}
