import { TipusMatricula } from './../../../tipus-matricules/models/tipus-matricula.model';
import { MatriculaFilter } from 'app/sections/ciutat/matricules/models/matricula-filter';
import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-matricules-filter',
  templateUrl: './matricules-filter.component.html',
  styleUrls: ['./matricules-filter.component.scss']
})
export class MatriculesFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: MatriculaFilter;
  tipusMatricules = [];

  @Output() notify: EventEmitter<MatriculaFilter> = new EventEmitter<MatriculaFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<MatriculesFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data,) 
    {

      
    }

  ngOnInit() {

    this.tipusMatricules = [];
    this.tipusMatricules.push({'codi': '', 'descripcio': ''});
    this.filter = this.data.filter;
    this.data.tipusMatricules.forEach(element => 
    {
      this.tipusMatricules.push({'codi': element.codi, 'descripcio': element.descripcio});
    });
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }
}
