<app-toolbar>
  <app-tool-button text="Històric" i18n-text (click)="irHistorico();" color="has-color-orange" icon="fa-history" ></app-tool-button>
  <app-tool-button text="Processar" i18n-text (click)="Processar();" color="has-color-green" icon="fa-file-export" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.AnulacioZBAuto)" ></app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.AnulacioZBAuto"></app-tool-button-permissions>
</app-toolbar>


<div class="row options d-flex">
  <div class="p-1">
    <app-form-date-edit id="anulacion-zona-azul-auto-desde" i18n-label label="Desde" [(value)]="filter.fechaInicio" type="calendar"></app-form-date-edit>
  </div>
  <div class="p-1">
    <app-form-date-edit id="anulacion-zona-azul-auto-hasta" i18n-label label="Fins" [(value)]="filter.fechaFin" type="calendar"></app-form-date-edit>
  </div>
  <div class="p-1">
    <button class="btn btn-primary" (click)="consultar()" i18n>Consultar</button>
  </div>
  <div class="p-1">
    <span i18n>Anul·lacions</span>: {{this.dataSource.subject.getValue().length}}
  </div>
  <div class="col-6" style="margin-top: -3vh;">
    <app-progress-bar #progressBar class="col-12" pantalla="Anulaciones"></app-progress-bar>
  </div>

</div>

<mat-paginator #paginator hidden="true"></mat-paginator>

<div style="height:72vh" class="table-sdw is-fixed">
  <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements" matSortActive="Expedient" matSortDirection="asc" class="is-header-sticky">

    <!-- Check Column -->
    <ng-container matColumnDef="Check">
      <mat-header-cell *matHeaderCellDef class="is-checkbox">
          <mat-checkbox #allCheck (change)="toggleAllCheckbox($event)"></mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox (change)="toggleItem(row.SomId)" [checked]="displayedItems[row.SomId].checked"></mat-checkbox>
      </mat-cell>
    </ng-container>
    
    <!-- Expedient Column -->
    <ng-container matColumnDef="Expedient">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Expedient</mat-header-cell>
      <mat-cell *matCellDef="let row"><a (click)="this.veureDenuncia(row.idDenuncia)" class="is-link">{{row.Expedient}}</a></mat-cell> 
    </ng-container>

    <!-- Processada Column -->
    <ng-container matColumnDef="Processada">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Processada</mat-header-cell>
      <mat-cell *matCellDef="let row"><app-form-check-edit [value]="row.Processada" [disabled]="true" ></app-form-check-edit></mat-cell>
    </ng-container>

    <!-- dataExp Column -->
    <ng-container matColumnDef="DataExp">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data Exp.</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.DataExp}}</mat-cell>
    </ng-container>

    <!-- horaExp Column -->
    <ng-container matColumnDef="HoraExp">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora Exp.</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.HoraExp}}</mat-cell>
    </ng-container>

    <!-- anulacio Column -->
    <ng-container matColumnDef="Anulacio">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Anul·lació</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.Anulacio}}</mat-cell>
    </ng-container>

    <!-- dataAnul Column -->
    <ng-container matColumnDef="DataAnul">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data anul.</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.DataAnul}}</mat-cell>
    </ng-container>

    <!-- horaAnul Column -->
    <ng-container matColumnDef="HoraAnul">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Hora anul.</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.HoraAnul}}</mat-cell>
    </ng-container>

    <!-- import Column -->
    <ng-container matColumnDef="Import">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Import</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.Import}}</mat-cell>
    </ng-container>

    <!-- maquina Column -->
    <ng-container matColumnDef="Maquina">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Màquina</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.Maquina}}</mat-cell>
    </ng-container>

    <!-- dataProc Column -->
    <ng-container matColumnDef="DataProc">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Data proc.</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.DataProc}}</mat-cell>
    </ng-container>

    <!-- resultat Column -->
    <ng-container matColumnDef="Resultat">
      <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Resultat</mat-header-cell>
      <mat-cell *matCellDef="let row" (click)="valorarAnulacion(row)">{{row.Resultat}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let row" class="action-link"> 
       
        <i *ngIf="this.puedeValorarAnulacion(row)" (click)="this.valorarAnulacion(row)" class="cursor fa fa-edit btn btn-outline-warning btn-sm"></i>
      </td>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="has-iconset" [ngClass]="highlightedRows.indexOf(row) != -1 ? 'is-active' : ''"></mat-row>
  </mat-table>
</div>
