<form #mainForm>
  <div class="row">
    <div class="col-6">
      <app-form-date-edit style="width: 100%;" type="calendar" [(value)]="this.filtroTickets.Dia" required></app-form-date-edit>
    </div>
    <div class="col">
      <mat-form-field>
          <input type="time" matInput placeholder="Hora" [value]="this.filtroTickets.HoraIni" (input)="this.filtroTickets.HoraIni=$event.target.value"  >
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field>
        <input type="time" matInput placeholder="Hora" [value]="this.filtroTickets.HoraFin" (input)="this.filtroTickets.HoraFin=$event.target.value" >
      </mat-form-field>
    </div>
    <div class="col-2">
      <button style="width: 100%;" (click)="this.submit()" mat-raised-button color="primary">Cargar</button>
    </div>
  </div>    
</form>

<div style="display: flex; flex-direction: row; align-content: space-between; width: 100%;">
  <ng-container *ngFor="let color of this.getColors()">
  <div class="colorSquareStyle" [ngStyle]="{'background-color': color.color }"><div>{{color.rango}}</div></div>
</ng-container>
</div>


<agm-map [latitude]="currentLocation.latitude" [longitude]="currentLocation.longitude" [zoom]="this.zoomMapa">
  <agm-polygon *ngFor="let polygon of this.tramos" [paths]="polygon.poligon"
  [fillColor]="polygon.zona.color "
  [strokeColor]="polygon.strokeColor !== undefined && polygon.strokeColor !== null  ? polygon.strokeColor :  polygon.zona.color"  (polyClick)="selectPoligono(polygon)">
</agm-polygon>
</agm-map>

