import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { ZonesAparcareFilter } from '../models/zones-aparcare-filter';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class ZonesAparcareFilterService extends CustomFilterService {
  public createFilterParams(filter: ZonesAparcareFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {

    return new HttpParams()
    .append('descripcio', filter.descripcio)
    .append('codi', filter.codi === null ? '' : filter.codi.toString())
    .append('dataInici', filter.dataInici === null ? '' : filter.dataInici.toString())
    .append('dataFi', filter.dataFi === null ? '' : filter.dataFi.toString())

    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
