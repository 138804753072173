import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { notImplemented } from '@angular/core/src/render3/util';
import { ZbHorario } from '../models/zb-horarios';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ZbHorariosService } from '../services/zb-horarios.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-zb-horario-editor',
  templateUrl: './zb-horario-editor.component.html',
  styleUrls: ['./zb-horario-editor.component.scss']
})
export class ZbHorarioEditorComponent implements OnInit {

  
  horario:ZbHorario;
  HorarioForm:FormGroup; 
  constructor(
    private toastr: ToastrService,
    private i18n: I18n,
    private zbHorariosService:ZbHorariosService, 
    @Inject(MAT_DIALOG_DATA) public data: ZbHorario,
    public thisDialogRef: MatDialogRef<ZbHorarioEditorComponent>,
  ) {   
   
  }

  ngOnInit() {
    debugger;
    var horesForm:FormArray=new FormArray([]);
    if(this.data!==null && this.data!==undefined && this.data.SomId!==undefined && this.data.SomId!==null){
      this.createForm(this.data.Nombre,this.data.Descripcion);
      const control=<FormArray>this.HorarioForm.controls["Horas"];
      control.removeAt(0);
      this.data.Horas.forEach(h=>{
        const Hour_start = parseInt(h.split('-')[0].split(':')[0]).toLocaleString('es-es',{ minimumIntegerDigits: 2,});
        const Minute_start = parseInt(h.split('-')[0].split(':')[1]).toLocaleString('es-es',{ minimumIntegerDigits: 2,});
        const Hour_end = parseInt(h.split('-')[1].split(':')[0]).toLocaleString('es-es',{ minimumIntegerDigits: 2,});
        const Minute_end = parseInt(h.split('-')[1].split(':')[1]).toLocaleString('es-es',{ minimumIntegerDigits: 2,});
        debugger;

        control.push(new FormGroup({
          HoraInicio: new FormControl(Hour_start+":"+Minute_start,Validators.required),
          HoraFin: new FormControl(Hour_end+":"+Minute_end,Validators.required),
        }));
      
      });
    }
    else{
      this.createForm('','');
    }
  }

  submit():void{
    let somId=0;
    if(this.data!==null && this.data!==undefined){
      somId=this.data.SomId;
    }
    this.horario={
      SomId:somId,
      Descripcion:"",
      Nombre:"",
      Horas:[]
    };
    debugger;
    this.horario.Nombre=this.HorarioForm.get("Nombre").value;
    this.horario.Descripcion=this.HorarioForm.get("Descripcion").value;
    this.horario.Horas=[];
    this.HorarioForm.get("Horas").value.forEach(element => {
      this.horario.Horas.push(element.HoraInicio+"-"+element.HoraFin);
    });
    this.zbHorariosService.setZbHorario(this.horario).subscribe(
      (res:any)=>{
        debugger;
        if(res=="OK"){
          this.toastr.success(this.i18n('Cambios realizados correctamente'));
        }
        else{
          this.toastr.error(res);
        }
      },
      error=>{
        this.toastr.error(error);
      },
      ()=>{
        this.thisDialogRef.close(true);
      }
    )
  }
  createForm(name:string,desc:string):void {
    this.HorarioForm= new FormGroup({
      Nombre: new FormControl(name,Validators.required),
      Descripcion: new FormControl(desc,Validators.required),
      Horas:new FormArray([
        new FormGroup({
          HoraInicio: new FormControl('00:00',Validators.required),
          HoraFin: new FormControl('00:00',Validators.required),
        })
      ])
    })
  }

  addHour(){

    const control=<FormArray>this.HorarioForm.controls["Horas"];
    control.push(new FormGroup({
      HoraInicio: new FormControl('00'),
      HoraFin: new FormControl('10'),
    }));
  }
  removeHour(){
    
    const control=<FormArray>this.HorarioForm.controls["Horas"];
    if(control.length<1)
      this.toastr.warning(this.i18n('No se puede eliminar mas franjas horarias'));
    else
      control.removeAt(control.length-1);
  }


}


