<app-dialog-header label="{{ this.i18n('Nova actuació per a la incidència') + '&nbsp;' + this.data.somId}}" i18n-label iconClass="has-color-orange"></app-dialog-header>

<app-toolbar>
  <app-tool-button text="Afegir" i18n-text (click)="this.guardar()" color="has-color-green" icon="fa-save"></app-tool-button>
</app-toolbar>

<div>
  <app-form-date-edit required class="col form-text-area" i18n-label label="Data inici" [(value)]="this.dataInici"></app-form-date-edit>
  <app-form-date-edit required class="col form-text-area" i18n-label label="Data fi" [(value)]="this.dataFi"></app-form-date-edit>
  <app-form-text-area required class="col form-text-area" i18n-label label="Observacions" [(value)]="this.observacions" resize="true" maxLength="5000" draggable="false"></app-form-text-area>
</div>