import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    constructor(private i18n: I18n) {
        super();
        this.getAndInitTranslations();
    }

    getAndInitTranslations() {
        this.itemsPerPageLabel = this.i18n('Items per pàgina:');
        this.nextPageLabel     = this.i18n('Pàgina següent');
        this.previousPageLabel = this.i18n('Pàgina anterior');
        this.changes.next();
    }

    escatRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) { return `0 de ${length}`; }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} de ${length}`;
    }
}