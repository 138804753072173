var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var TarifasGruaFilter = /** @class */ (function (_super) {
    __extends(TarifasGruaFilter, _super);
    function TarifasGruaFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.somId = '';
        _this.descripcion = '';
        _this.tipoTarifa = '';
        _this.tipoVehiculo = '';
        _this.importeTarifa = null;
        _this.tipoEstancia = '';
        _this.fechaDesde = null;
        _this.fechaHasta = null;
        _this.labels['somId'] = _this.i18n('Codi');
        _this.labels['tipoTarifa'] = _this.i18n('Tipus tarifa');
        _this.labels['tipoVehiculo'] = _this.i18n('Tipus vehicle');
        _this.labels['importeTarifa'] = _this.i18n('Import tarifa');
        _this.labels['tipoEstancia'] = _this.i18n('Tipus estància');
        _this.labels['descripcion'] = _this.i18n('Descripció');
        _this.labels['hora_inicio'] = _this.i18n('Data inici');
        _this.labels['hora_fin'] = _this.i18n('Data fi');
        return _this;
    }
    return TarifasGruaFilter;
}(FilterData));
export { TarifasGruaFilter };
