/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./banc-carregues-c60-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/dialog/filter-header/filter-header.component.ngfactory";
import * as i3 from "../../../../../shared/components/dialog/filter-header/filter-header.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../../shared/components/filter/filter-text-edit/filter-text-edit.component.ngfactory";
import * as i6 from "../../../../../shared/components/filter/filter-text-edit/filter-text-edit.component";
import * as i7 from "../../../../../shared/services/validation/validation.service";
import * as i8 from "../../../../../shared/components/form/form-combo-edit/form-combo-edit.component.ngfactory";
import * as i9 from "../../../../../shared/components/form/form-combo-edit/form-combo-edit.component";
import * as i10 from "./banc-carregues-c60-filter.component";
import * as i11 from "@ngx-translate/i18n-polyfill";
var styles_BancCarreguesC60FilterComponent = [i0.styles];
var RenderType_BancCarreguesC60FilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BancCarreguesC60FilterComponent, data: {} });
export { RenderType_BancCarreguesC60FilterComponent as RenderType_BancCarreguesC60FilterComponent };
export function View_BancCarreguesC60FilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-header", [["title", "Filtrar c\u00E0rregues C60"]], null, [[null, "apply"], [null, "reset"], [null, "dismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("apply" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (_co.reset() !== false);
        ad = (pd_1 && ad);
    } if (("dismiss" === en)) {
        var pd_2 = (_co.dismiss() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_FilterHeaderComponent_0, i2.RenderType_FilterHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.FilterHeaderComponent, [i4.MatDialogRef], { title: [0, "title"] }, { apply: "apply", reset: "reset", dismiss: "dismiss" }), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-filter-text-edit", [["class", "col-sm-4"], ["comparsion", "="], ["id", "banc-carregues-c60-filter-codi"], ["label", "Codi propi"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.id = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FilterTextEditComponent_0, i5.RenderType_FilterTextEditComponent)), i1.ɵdid(5, 4308992, null, 0, i6.FilterTextEditComponent, [i7.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], comparsion: [3, "comparsion"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-filter-text-edit", [["class", "col-sm-4"], ["contains", ""], ["id", "banc-carregues-c60-filter-codiCarrega"], ["label", "Codi c\u00E0rrega"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.codiCarrega = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FilterTextEditComponent_0, i5.RenderType_FilterTextEditComponent)), i1.ɵdid(7, 4308992, null, 0, i6.FilterTextEditComponent, [i7.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], contains: [3, "contains"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-filter-text-edit", [["class", "col-sm-4"], ["contains", ""], ["id", "banc-carregues-c60-filter-descripcio"], ["label", "Descripci\u00F3 c\u00E0rrega"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.descripcioCarrega = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FilterTextEditComponent_0, i5.RenderType_FilterTextEditComponent)), i1.ɵdid(9, 4308992, null, 0, i6.FilterTextEditComponent, [i7.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], contains: [3, "contains"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-filter-text-edit", [["class", "col-sm-4"], ["contains", ""], ["id", "banc-carregues-c60-filter-nomFitxer"], ["label", "Nom fitxer"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.nomFitxer = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_FilterTextEditComponent_0, i5.RenderType_FilterTextEditComponent)), i1.ɵdid(12, 4308992, null, 0, i6.FilterTextEditComponent, [i7.ValidationService], { label: [0, "label"], value: [1, "value"], class: [2, "class"], contains: [3, "contains"], id: [4, "id"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-form-combo-edit", [["class", "col-sm-4"], ["label", "Estat"], ["textField", "text"], ["valueField", "value"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.filter.situacio = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormComboEditComponent_0, i8.RenderType_FormComboEditComponent)), i1.ɵdid(14, 4308992, null, 0, i9.FormComboEditComponent, [i7.ValidationService], { label: [0, "label"], value: [1, "value"], items: [2, "items"], valueField: [3, "valueField"], textField: [4, "textField"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Filtrar c\u00E0rregues C60"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "Codi propi"; var currVal_2 = _co.filter.id; var currVal_3 = "col-sm-4"; var currVal_4 = "="; var currVal_5 = "banc-carregues-c60-filter-codi"; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "Codi c\u00E0rrega"; var currVal_7 = _co.filter.codiCarrega; var currVal_8 = "col-sm-4"; var currVal_9 = ""; var currVal_10 = "banc-carregues-c60-filter-codiCarrega"; _ck(_v, 7, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "Descripci\u00F3 c\u00E0rrega"; var currVal_12 = _co.filter.descripcioCarrega; var currVal_13 = "col-sm-4"; var currVal_14 = ""; var currVal_15 = "banc-carregues-c60-filter-descripcio"; _ck(_v, 9, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_16 = "Nom fitxer"; var currVal_17 = _co.filter.nomFitxer; var currVal_18 = "col-sm-4"; var currVal_19 = ""; var currVal_20 = "banc-carregues-c60-filter-nomFitxer"; _ck(_v, 12, 0, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20); var currVal_21 = "Estat"; var currVal_22 = _co.filter.situacio; var currVal_23 = _co.situacioCombo; var currVal_24 = "value"; var currVal_25 = "text"; _ck(_v, 14, 0, currVal_21, currVal_22, currVal_23, currVal_24, currVal_25); }, null); }
export function View_BancCarreguesC60FilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-banc-carregues-c60-filter", [], null, null, null, View_BancCarreguesC60FilterComponent_0, RenderType_BancCarreguesC60FilterComponent)), i1.ɵdid(1, 114688, null, 0, i10.BancCarreguesC60FilterComponent, [i4.MatDialogRef, i11.I18n, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BancCarreguesC60FilterComponentNgFactory = i1.ɵccf("app-banc-carregues-c60-filter", i10.BancCarreguesC60FilterComponent, View_BancCarreguesC60FilterComponent_Host_0, {}, { notify: "notify" }, []);
export { BancCarreguesC60FilterComponentNgFactory as BancCarreguesC60FilterComponentNgFactory };
