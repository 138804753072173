/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./usuaris.component";
var styles_UsuarisComponent = [];
var RenderType_UsuarisComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UsuarisComponent, data: {} });
export { RenderType_UsuarisComponent as RenderType_UsuarisComponent };
export function View_UsuarisComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" usuaris works!\n"]))], null, null); }
export function View_UsuarisComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-usuaris", [], null, null, null, View_UsuarisComponent_0, RenderType_UsuarisComponent)), i0.ɵdid(1, 114688, null, 0, i1.UsuarisComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UsuarisComponentNgFactory = i0.ɵccf("app-usuaris", i1.UsuarisComponent, View_UsuarisComponent_Host_0, {}, {}, []);
export { UsuarisComponentNgFactory as UsuarisComponentNgFactory };
