import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AlarmaGps } from '../../models/alarma-gps.model';
import { Router } from '@angular/router';
import { AudioService } from 'path-shared/services/audio/audio.service';

@Component({
  selector: 'app-alarma-detall',
  templateUrl: './alarma-detall.component.html',
  styleUrls: ['./alarma-detall.component.scss']
})
export class AlarmaDetallComponent implements OnInit, OnDestroy {

  dialogData: AlarmaGps;
  audio: any;
  constructor(
    private i18n: I18n,
    private dialog: MatDialogRef<AlarmaDetallComponent>,
    private router: Router,
    private audioService: AudioService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
      this.dialogData = data;

  }
  ngOnInit() {

    this.audioService.new('assets/when.mp3');
    this.audioService.start();
  }

  ngOnDestroy() {
    // this.audio.pause();
  }

  close() {
    this.audioService.stop();
    this.dialog.close();
  }

  ViewDetall() {
    this.router.navigate(['/alarmes/visor/' + this.dialogData.Id] );
    this.dialog.close();

  }

}
