import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export enum TipoVehiculo {
  SinVehiculo = 1,
  VehiculoCoche = 2,
  VehiculoMoto = 3
}

export class ReservesFilter extends FilterData {

  id: number;
  fechaInicio: Date;
  matricula: string;
  email: string;
  tipoVehiculo: TipoVehiculo;

  constructor(sortField: string, sortDir: SortDirection, tipoVehiculo: TipoVehiculo) {
    super(sortField, sortDir);
    this.id = null;
    this.matricula = '';
    this.email = '';
    this.fechaInicio = new Date();
    this.fechaInicio.setHours(0, 0, 0 , 0);
    this.tipoVehiculo = tipoVehiculo;

    this.labels['id'] = this.i18n('Id');
    this.labels['matricula'] = this.i18n('Matrícula');
    this.labels['email'] = this.i18n('Email');
    this.labels['fechaInicio'] = this.i18n('Data Reserva');
    this.labels['tipoVehiculo'] = this.i18n('Tipus Vehicle');

    this.formatOutput['fechaInicio'] = 'shortDate';
    this.formatOutput['fechaFin'] = 'shortDate';
  }

}
