var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var AgentFilter = /** @class */ (function (_super) {
    __extends(AgentFilter, _super);
    function AgentFilter(sortField, sortDir, fechaConsulta) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.codi = '';
        _this.categoria = '';
        _this.nomComplert = '';
        _this.dni = '';
        _this.letraDni = '';
        _this.obsolet = null;
        _this.zonaBlava = null;
        _this.agentPDA = null;
        _this.fechaActualConsulta = fechaConsulta;
        _this.deFiesta = null;
        _this.deAlta = null;
        _this.labels['codi'] = _this.i18n('Codi');
        _this.labels['zonaBlava'] = _this.i18n('Zona blava');
        _this.labels['agentPDA'] = _this.i18n('Agent PDA');
        _this.labels['nomComplert'] = _this.i18n('Nom');
        _this.labels['letraDni'] = _this.i18n('Lletra DNI');
        _this.labels['categoria'] = _this.i18n('Categoria');
        _this.labels['dni'] = _this.i18n('DNI');
        _this.labels['obsolet'] = _this.i18n('Obsolet');
        _this.labels['deFiesta'] = _this.i18n('De festa');
        _this.labels['deAlta'] = _this.i18n('D\'alta');
        return _this;
    }
    return AgentFilter;
}(FilterData));
export { AgentFilter };
