/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-multi-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../node_modules/ng-multiselect-dropdown/ng-multiselect-dropdown.ngfactory";
import * as i4 from "ng-multiselect-dropdown";
import * as i5 from "@angular/forms";
import * as i6 from "./form-multi-select.component";
import * as i7 from "@ngx-translate/i18n-polyfill";
var styles_FormMultiSelectComponent = [i0.styles];
var RenderType_FormMultiSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormMultiSelectComponent, data: {} });
export { RenderType_FormMultiSelectComponent as RenderType_FormMultiSelectComponent };
function View_FormMultiSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titulo; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormMultiSelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormMultiSelectComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "ng-multiselect-dropdown", [["style", "min-width: 10vh;"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onSelect"], [null, "onSelectAll"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.selectedValues = $event) !== false);
        ad = (pd_1 && ad);
    } if (("onSelect" === en)) {
        var pd_2 = (_co.onItemSelect($event) !== false);
        ad = (pd_2 && ad);
    } if (("onSelectAll" === en)) {
        var pd_3 = (_co.onSelectAll($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i3.View_MultiSelectComponent_0, i3.RenderType_MultiSelectComponent)), i1.ɵdid(4, 49152, null, 0, i4.MultiSelectComponent, [i4.ɵb], { placeholder: [0, "placeholder"], settings: [1, "settings"], data: [2, "data"] }, { onSelect: "onSelect", onSelectAll: "onSelectAll" }), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MultiSelectComponent]), i1.ɵdid(6, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(8, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.titulo != undefined); _ck(_v, 2, 0, currVal_0); var currVal_8 = _co.tituloMultiSelect; var currVal_9 = _co.dropdownSettings; var currVal_10 = _co.data; _ck(_v, 4, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.selectedValues; _ck(_v, 6, 0, currVal_11); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 8).ngClassValid; var currVal_6 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_FormMultiSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-multi-select", [], null, null, null, View_FormMultiSelectComponent_0, RenderType_FormMultiSelectComponent)), i1.ɵdid(1, 114688, null, 0, i6.FormMultiSelectComponent, [i7.I18n], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormMultiSelectComponentNgFactory = i1.ɵccf("app-form-multi-select", i6.FormMultiSelectComponent, View_FormMultiSelectComponent_Host_0, { tituloMultiSelect: "tituloMultiSelect", titulo: "titulo", maxItems: "maxItems", data: "data", selectedValues: "selectedValues" }, { selectedValuesChange: "selectedValuesChange" }, []);
export { FormMultiSelectComponentNgFactory as FormMultiSelectComponentNgFactory };
