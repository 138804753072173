<app-dialog-header label="Senyal Vertical" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = !this.dialogData.isReadOnly" color="has-color-green"
    icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.SenyalsVerticals) || !this.dialogData.isReadOnly">
  </app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" 
    [disabled]="!this.auth.UserCanWrite(this.auth.Pages.SenyalsVerticals) || this.dialogData.isReadOnly">
  </app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" 
    [disabled]="!this.auth.UserCanDelete(this.auth.Pages.SenyalsVerticals) || this.dialogData.isReadOnly">
  </app-tool-button>
  <app-tool-button text="Fitxers" i18n-text (click)="this.fitxers();" color="has-color-blue" icon="fas fa-file-archive"
    [disabled]="!this.auth.UserCanWrite(this.auth.Pages.SenyalsVerticals) || this.dialogData.isReadOnly">
  </app-tool-button>
  <app-tool-button text="Llista" i18n-text (click)="this.cancel()" 
    color="has-color-yellow" icon="fa-th-list">
  </app-tool-button>
</app-toolbar>

<app-dialog-body>
  <form #mainForm>
    <!-- Informacion general -->
    <div class="row">
      <div class="col-4">
        <div class="row" style="padding-left: 15px;">
          <app-form-text-edit id="senales-verticales-editor-id" name="id" i18n-label label="Codi" [(value)]="this.senal.id" [disabled]="true"></app-form-text-edit>
        </div>
        <div class="row" style="padding-left: 15px;">
          <app-form-text-edit id="senales-verticales-editor-nom" name="nombre" i18n-label label="Nom" [(value)]="this.senal.nombre" required></app-form-text-edit>
        </div>
        <div class="row">
          <app-combo-box-input class="col-12" #comboTipoSenal i18n-label [label]="'Tipus Senyal'" [showingOptions]="this.tipoSenalesDictionary.value" 
            [disabled]="this.dialogData.isReadOnly" (optionChanged)="this.tipoSenalSeleccionada($event)" required>
          </app-combo-box-input>
        </div>
        <div class="row" style="padding-left: 15px;">
          <app-form-text-area i18n-label label="Observacions" [(value)]="this.senal.observaciones" maxlength="1500"></app-form-text-area>
        </div>
      </div>
      <div class="col-8">
        <!-- Coordenadas -->
        <div class="row">
          <div class="col-6">
            <app-form-text-edit id="senales-verticales-editor-latitud" name="latitud" i18n-label label="Latitud" (valueChange)="this.latitudChanged($event)" [(value)]="this.senal.latitud">
            </app-form-text-edit>
          </div>
          <div class="col-6">
            <app-form-text-edit id="senales-verticales-editor-longitud" name="longitud" i18n-label label="Longitud" (valueChange)="this.longitudChanged($event)" [(value)]="this.senal.longitud">
            </app-form-text-edit>
          </div>
        </div>
        <!-- Mapa -->
        <app-map-component #map class="col-9 map" (markerPositionUpdate)="this.updateMarkerPostion($event)" [overridedLocation]="this.senalLocation.value">
        </app-map-component>
      </div>
    </div>
  </form>
</app-dialog-body>