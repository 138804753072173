<app-photo-box-gallery (update)="UpdateProposta($event)" #pbg [proposta]="this.proposta"  [numDenuncia]="this.proposta?.NUMDENUNCIA" 
[imatges]="this.proposta?.IMATGES" height="auto" [botoMatricula]="true" [(showLupa)]="this.showLupa"></app-photo-box-gallery>

<div class="row no-margin" >
  <div class="col-sm-5 map-icon map-featured" style="max-height: 35vh;">
    <agm-map id="map" [zoom]="17" [latitude]="this.proposta?.LATITUD" [longitude]="this.proposta?.LONGITUD"
            [fullscreenControl]="true" >
        <ng-container *ngIf="!this.longitudeInstitucio && !this.latitudeInstitucio">
          <agm-marker [latitude]="this.proposta?.LATITUD" [longitude]="this.proposta?.LONGITUD"></agm-marker>
        </ng-container>
    </agm-map>
  </div>

  <div class="col-sm-7" style="padding-right: 0px; height: fit-content;">
    <div class="details-featured">
      <div class="row" >
        <div class="col-sm-2 details-featured__title align-middle text-right"><span class="align-middle" i18n>Matrícula:</span></div>
        
        <div class="col-sm-10">
          <div class="row">
            <!-- Boton Dgt -->
            <div class="col-sm-auto" style="padding-right: 12px;">
              <div class="btn btn-sm btn-outline-info align-center " style="height: 100%;padding-top: 15px;" (click)="this.openDGT(this.proposta.SECURE_ID ,this.proposta.MATRICULA)"> 
                <img class="navbar-brand-full" src="/assets/images/dgt6.png"  height="14,55px" >
              </div>
            </div>
            <!-- Componente matrícula -->
            <app-matricula  class="col-sm-auto pl-0"  [matricula]="this.proposta?.MATRICULA" [pais]="this.proposta?.pais" 
            [vehicle]="this.proposta?.VEHICLE"[sugPais]="this.proposta?.sugPaises" [sugMatricula]="this.proposta?.sugMatricules" 
            [activarSugerencias]="true" [marca]="this.proposta?.Marca" [model]="this.proposta?.Model" (canvioPais)="this.setPais($event)" 
            (canvioMatricula)="this.setMatricula($event)"></app-matricula>
            <!-- Editar matricula -->
            <div  class="col-sm-auto pl-0 img_container2 p-2">
              <i class="fas fa-pen click-icon fa-lg"   *ngIf="this.auth.UserCanWrite(this.auth.Pages.Propostes) && this.esModificable();" (click)="this.editProposta()" i18n-title title="Modificar" style=" cursor:pointer; margin-left: -1vh; margin-right: -1vh;"></i>
            </div>
            <!-- Validado -->
            <app-form-check-edit *ngIf="validarFotosProposta"  class="col-sm-1"  i18n-label label="Validat" [(value)]="this.matriculaValidat" [hidden]="this.pendentValidacio()"></app-form-check-edit>
            <!-- Importe/puntos -->
            <div class="col-sm-auto pl-0 text-right align-middle ml-auto">
                <div class="badge badge-primary price">{{this.proposta?.IMPORT}}€ ({{this.proposta?.PUNTS}}&nbsp;<span i18n>punts</span>)</div>
            </div>
          </div>

          <div class="row">
            <!-- Alarmes -->
            <div class="col-sm-auto pr-0" *ngIf="this.proposta?.Avisos > 0" >
              <div class="btn btn-sm btn-danger align-middle reincident" *ngIf="this.proposta?.Avisos > 0" (click)="this.veureAvisos(this.proposta.MATRICULA,  this.proposta?.FECHA )"><span i18n>Alarmes</span>! {{this.proposta.Avisos}}</div>
            </div>
            <!-- Reincident -->
            <div class="col-sm-auto pr-0" *ngIf="this.proposta?.reincidents > 1" >
              <div class="btn btn-sm btn-warning align-middle reincident" *ngIf="this.proposta?.reincidents > 1" (click)="this.veureReincidencies(this.proposta.MATRICULA)"><span i18n>Reincident</span>! {{this.proposta.reincidents}}</div>
            </div>
            <!-- Detectat en llista -->
            <div class="col-sm-auto" *ngIf="this.proposta?.lista!==null" >
              <div class="btn btn-sm btn-orange-custom align-middle reincident" *ngIf="this.proposta?.lista!==null" (click)="this.openDeteccio()"><span i18n>Detectat en llista:</span> {{this.proposta.lista}}</div>
            </div>
          </div>
        </div>

    </div>
    <div class="row">
      <div class="col-sm-2 details-featured__title text-right" i18n>Número:</div>
      <div class="col-sm-1 details-featured__content" style="min-width: 20vh;">
        {{this.proposta?.NUMDENUNCIA}}
      </div>
      <div class="col-sm-1 details-featured__title  text-right" i18n>Tipus:</div>
      <div class="col-sm-2">
        <div class=" details-featured__content" *ngIf="this.proposta?.VEHICLE" [ngClass]="[ this.proposta?.VEHICLE != null ? '' :  'empty']"> {{this.vehicle()}} -</div>
        <div class=" details-featured__content">
          {{this.proposta?.Marca  }} &nbsp;  {{this.proposta?.Model  }}
        </div>
      </div>
      <div class="col-sm-1 details-featured__title  text-right" i18n>País:</div>
        <div class="col-sm-1 details-featured__content">
          {{this.proposta?.pais | nomPais }}
      </div>
    </div>        
     
      <div class="row">
        <div class="col-sm-2 details-featured__title text-right" i18n>Data:</div>
        <div class="col-sm-3 details-featured__content" style="max-width: 20vh;">
          {{this.proposta?.FECHA | shortDate}} {{this.proposta?.HORA}}
        </div>
        <div class="col-sm-1 details-featured__title text-right" i18n>Lloc:</div>
        <div class="col-sm-5 details-featured__content">
          {{this.proposta?.LLOC!==null? this.proposta?.LLOC + ' ' + this.proposta?.NUMERO:''}}
          <i class="fas fa-pen click-icon" *ngIf="this.auth.UserCanWrite(this.auth.Pages.Propostes);" (click)="this.editPropostaLloc()" i18n-title title="Modificar" style="margin-left: 10px; cursor:pointer; margin-top: auto; margin-bottom: auto;">
          </i>
          <!-- <app-form-check-edit *ngIf="validarFotosProposta" style="margin-left: 10px" label="Validat" [(value)]="this.llocValidat" [hidden]="this.pendentValidacio()"></app-form-check-edit> -->

        </div>
      </div>    

      <div class="row" >
        <div class="col-sm-2 details-featured__title  text-right align-to" i18n>Infracció:</div>
        <div class="col-sm-10 details-featured__content align-to">
          <p class="text-justify"><span i18n>Art.</span>&nbsp;{{this.proposta?.ARTICLE}}&nbsp;{{this.proposta?.INFRACCIO}}</p>
        </div>
      </div>
      <div class="row" *ngIf="this.proposta?.tipoInfraccion==='V'" style="margin-top: -2vh;">
        <div class="col-sm-2 details-featured__title text-right" i18n>V. Límit:</div>
        <div class="col-sm-3 details-featured__content" style="max-width: 20vh;">
          {{this.proposta?.velLimit}} Km/h
        </div>
        <div class="col-sm-2 details-featured__title text-right" i18n style="max-width: fit-content;">V. Captada:</div>
        <div class="col-sm-2 details-featured__content" style="color:red; font-weight:bold;max-width: 12vh;">
          {{this.proposta?.velCaptada}} Km/h
        </div>
        <div *ngIf="this.proposta?.velCaptada!==this.proposta?.velCorregida" class="col-sm-2 details-featured__title text-right" i18n>V. Corregida:</div>
        <div *ngIf="this.proposta?.velCaptada!==this.proposta?.velCorregida" class="col-sm-2 details-featured__content" style="color:red; font-weight:bold;max-width: 12vh;">
          {{this.proposta?.velCorregida}} Km/h
        </div>
      </div> 
      
      <div class="row" *ngIf="this.notificadaEnMa" >
        <div class="col-sm-2 details-featured__title  text-right align-to" i18n></div>
        <div class="col-sm-10 details-featured__content align-to">
          <app-form-check-edit label="Notificada en mano" i18n-label [(value)]="this.proposta.notificadaEnMa"></app-form-check-edit>
          <app-form-text-edit  class="col-3" label="Código interno" i18n-label *ngIf="this.proposta.notificadaEnMa" [(value)]="this.proposta.expExterno"></app-form-text-edit>
          <app-form-combo-edit class="col-3" label="Agente" i18n-label *ngIf="this.proposta.notificadaEnMa" [(value)]="this.proposta.agent" [items]="this.agents" textField="codi" valueField="codi"></app-form-combo-edit>
        </div>
      </div>      
      

      <div class="row" *ngIf="this.desestimada()">
        <div class="alert alert-danger col-sm-8 offset-md-2">
          <span i18n>Desestimada</span>&nbsp;{{ this.proposta?.TEXT_DESESTIMACIO_PROPOSTA }}
        </div>
      </div>

      <div class="row" *ngIf="this.acceptada()">
        <div class="alert alert-success col-sm-8 offset-md-2">
          <span i18n>Acceptada</span>
        </div>
      </div>
      <div class="alert alert-info" style="float: left" *ngIf="this.proposta!==null && this.proposta.proposta === 4">
        <span i18n>Pre-propuesta</span>
      </div>
      <div class="row proposta-buttons" style="margin-top:1vh;">
          <div class="col-md-8 offset-md-2 d-flex">
            <span i18n-matTooltip matTooltip="Per a poder desestimar has de validar totes les imatges, la matrícula i el lloc" matTooltipClass="my-custom-tooltip" [matTooltipDisabled]="!disabled()">
              <button [disabled]="disabled() || this.proposta.notificadaEnMa" 
                class="btn btn-danger button-not-allowed" type="button" (click)="openModalRejectProposta()" [hidden]="!pendent() || this.pendentValidacio()">           
              <i class="fa fa-times-circle"></i>&nbsp;<span i18n>Desestimar</span>
              </button>
            </span>
            <div class="col-2"></div>            
            <span [matTooltip]="this.missatgeAcceptar" matTooltipClass="my-custom-tooltip" [matTooltipDisabled]="!disabled2()">            
              <button [disabled]="disabled2() || !this.propostaValida()" 
                class="btn btn-success offset-md-1 button-not-allowed" type="button" (click)="this.acceptProposta(this.proposta?.SECURE_ID)" [hidden]="!pendent() || this.pendentValidacio()">
              <i class="fa fa-check-circle"></i>&nbsp;<span i18n>Denunciar</span>
              </button>
            </span>
            <div class="alert alert-danger validationErrors" style="float: left" [hidden]="this.propostaValida() || !this.pendent()">
              <div *ngFor="let err of this.validacioError">
                {{ err }}
              </div>
            </div>
            <!-- Proposta Pendent Validacio ZBE -->
            <div class="alert alert-danger validationErrors" style="float: left" [hidden]="!this.pendentValidacio()">
              <span i18n>Pendent validació autorització ZBE</span>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
