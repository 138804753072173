import { SortDirection } from "@angular/material";
import { FilterData } from "path-shared/models/filter-data";

export class TextosPredefinidosFilter extends FilterData {
    id: number;
    descripcion: string;
  
    constructor(sortField: string, sortDir: SortDirection) {
      super(sortField, sortDir);
      this.id = 0;
      this.descripcion = '';
      this.labels['id'] = this.i18n('Id');
      this.labels['descripcio'] = this.i18n('Descripció');
    }
  }
  