import { NotificarFilterService } from './notificar-filter.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { NotificacionsDenunciesFilterService } from 'app/sections/notificacions/services/notificacions-denuncies-filter.services';
import { ResolucionsFilterService } from 'app/sections/notificacions/services/resolucions-filter.service';
import * as i0 from "@angular/core";
import * as i1 from "./notificar-filter.service";
import * as i2 from "../../../../../shared/services/http/httpSecureClient";
import * as i3 from "../../../../../shared/services/authentication/auth-guard.service";
var NotificarService = /** @class */ (function () {
    function NotificarService(notificarFilterService, http, authGuard) {
        this.notificarFilterService = notificarFilterService;
        this.http = http;
        this.authGuard = authGuard;
    }
    NotificarService.prototype.inicioProcesoNotificacion = function (inicioNotificarInfo) {
        if (this.authGuard.canActivate()) {
            var filter = this.notificarFilterService.createFilterParams(inicioNotificarInfo);
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlInicioProcesoNotificar'], filter);
        }
    };
    NotificarService.prototype.iniciaProcesoNotificarDenuncias = function (fechas, filtro) {
        var filterService = new NotificacionsDenunciesFilterService();
        var params = filterService.createFilterParams(filtro, 0, 20, 'exp', 'asc');
        if (this.authGuard.canActivate()) {
            return this.http.postWithParams(config.urls['apiUrl'] + api.endpoints['urlContinuaProcesoNotificarDenuncias'], fechas, params);
        }
    };
    NotificarService.prototype.finalizaProcesoNotificarDenuncias = function (notificaciones) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlFinalizaProcesoNotificarDenuncias'], notificaciones);
        }
    };
    NotificarService.prototype.iniciaProcesoNotificarPropuestasResolucion = function (fechas) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlContinuaProcesoNotificarPropuestasResolucion'], fechas);
        }
    };
    NotificarService.prototype.finalizaProcesoNotificarPropuestasResolucion = function (notificaciones) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlFinalizaProcesoNotificarPropuestasResolucion'], notificaciones);
        }
    };
    NotificarService.prototype.procesarResoluciones = function (filtro) {
        if (this.authGuard.canActivate()) {
            var filterService = new ResolucionsFilterService();
            var params = filterService.createFilterParams(filtro, 0, 20, 'exp', 'asc');
            return this.http.postWithParams(config.urls['apiUrl'] + api.endpoints['urlProcesaResoluciones'], null, params);
        }
    };
    NotificarService.prototype.finalizaResoluciones = function (res) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlProcesaFinalizaResoluciones'], res);
        }
        return null;
    };
    NotificarService.prototype.iniciaProcesoNotificarNotificacionesResoluciones = function (fechas) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlContinuaProcesoNotificarNotificaciconesResoluciones'], fechas);
        }
    };
    NotificarService.prototype.finalizaProcesoNotificarNotificacionesResoluciones = function (notificaciones) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlFinalizaProcesoNotificarNotificacionesResoluciones'], notificaciones);
        }
    };
    NotificarService.prototype.iniciaProcesoNotifcarRecursosReposicion = function (fechas) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlContinuaProcesoNotificarRecursosReposicion'], fechas);
        }
    };
    NotificarService.prototype.finalizaProcesoNotificarRecursosReposicion = function (notificaciones) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlFinalizaProcesoNotificarRecursosReposicion'], notificaciones);
        }
    };
    NotificarService.prototype.procesarRecursosReposiciones = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlProcesaNotificacionRecusosReposiciones'], null);
        }
    };
    NotificarService.prototype.finalizaProcesoNotificarPropuestasReposicion = function () {
        if (this.authGuard.canActivate()) {
            return this.http.get(config.urls['apiUrl'] + api.endpoints['urlFinalizaProcesoNotificarPropuestasReposicion'], null);
        }
    };
    NotificarService.prototype.undoEnvio = function (secureId) {
        if (this.authGuard.canActivate()) {
            return this.http.post(config.urls['apiUrl'] + api.endpoints['urlUndoEnvio'].replace('{secureId}', secureId), null);
        }
    };
    NotificarService.prototype.updateNotificaciones = function (notificaciones) {
        if (this.authGuard.canActivate()) {
            try {
                console.log(config.urls['apiUrl'] + api.endpoints['urlUpdateNotificaciones']);
                var result = this.http.post(config.urls['apiUrl'] + api.endpoints['urlUpdateNotificaciones'], notificaciones);
                return result;
            }
            catch (e) {
                console.log(e);
            }
            return null;
        }
    };
    NotificarService.ngInjectableDef = i0.defineInjectable({ factory: function NotificarService_Factory() { return new NotificarService(i0.inject(i1.NotificarFilterService), i0.inject(i2.HttpSecureClient), i0.inject(i3.AuthGuardService)); }, token: NotificarService, providedIn: "root" });
    return NotificarService;
}());
export { NotificarService };
