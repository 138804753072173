var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterData } from 'path-shared/models/filter-data';
var Origenes;
(function (Origenes) {
    Origenes[Origenes["Radar"] = 0] = "Radar";
    Origenes[Origenes["Otros"] = 1] = "Otros";
    Origenes[Origenes["Todos"] = 2] = "Todos";
})(Origenes || (Origenes = {}));
;
var TramitadorFilter = /** @class */ (function (_super) {
    __extends(TramitadorFilter, _super);
    function TramitadorFilter(sortField, sortDir) {
        var _this = _super.call(this, sortField, sortDir) || this;
        _this.tipos = new Array();
        _this.legislaciones = new Array();
        _this.origen = Origenes.Todos;
        var date = new Date();
        var dateCurrentDay = new Date(date.getTime());
        var datePastWeek = new Date(date.getTime());
        datePastWeek.setDate(date.getDate() - 7);
        _this.fechaInicio = datePastWeek;
        _this.fechaFin = dateCurrentDay;
        _this.matriculasExtrangeras = null;
        _this.fotos = null;
        return _this;
    }
    return TramitadorFilter;
}(FilterData));
export { TramitadorFilter };
