/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-file-displayer-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../dialog/dialog-header/dialog-header.component.ngfactory";
import * as i3 from "../../../dialog/dialog-header/dialog-header.component";
import * as i4 from "../generic-file-displayer-table/generic-file-displayer-table.component.ngfactory";
import * as i5 from "../generic-file-displayer-table/generic-file-displayer-table.component";
import * as i6 from "../generic-file-displayer-frame/generic-file-displayer-frame.component.ngfactory";
import * as i7 from "../generic-file-displayer-frame/generic-file-displayer-frame.component";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/material/dialog";
import * as i10 from "./generic-file-displayer-page.component";
import * as i11 from "ngx-toastr";
import * as i12 from "@ngx-translate/i18n-polyfill";
var styles_GenericFileDisplayerPageComponent = [i0.styles];
var RenderType_GenericFileDisplayerPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericFileDisplayerPageComponent, data: {} });
export { RenderType_GenericFileDisplayerPageComponent as RenderType_GenericFileDisplayerPageComponent };
export function View_GenericFileDisplayerPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { fileDisplayer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-dialog-header", [["label", "Fitxers"]], null, null, null, i2.View_DialogHeaderComponent_0, i2.RenderType_DialogHeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.DialogHeaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-generic-file-displayer-table", [], null, [[null, "delete"], [null, "download"], [null, "show"], [null, "upload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("delete" === en)) {
        var pd_0 = (_co.deleteFiles($event) !== false);
        ad = (pd_0 && ad);
    } if (("download" === en)) {
        var pd_1 = (_co.downloadFiles($event) !== false);
        ad = (pd_1 && ad);
    } if (("show" === en)) {
        var pd_2 = (_co.showFile($event) !== false);
        ad = (pd_2 && ad);
    } if (("upload" === en)) {
        var pd_3 = (_co.uploadFile($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i4.View_GenericFileDisplayerTableComponent_0, i4.RenderType_GenericFileDisplayerTableComponent)), i1.ɵdid(4, 114688, null, 0, i5.GenericFileDisplayerTableComponent, [], { files: [0, "files"] }, { show: "show", delete: "delete", upload: "upload", download: "download" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-generic-file-displayer-frame", [], null, [[null, "previousFile"], [null, "nextFile"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("previousFile" === en)) {
        var pd_0 = (_co.changeShowingFile("previous", $event) !== false);
        ad = (pd_0 && ad);
    } if (("nextFile" === en)) {
        var pd_1 = (_co.changeShowingFile("next", $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_GenericFileDisplayerFrameComponent_0, i6.RenderType_GenericFileDisplayerFrameComponent)), i1.ɵdid(6, 114688, [[1, 4], ["fileDisplayer", 4]], 0, i7.GenericFileDisplayerFrameComponent, [i8.DomSanitizer, i9.MatDialog], null, { previousFile: "previousFile", nextFile: "nextFile" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Fitxers"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.filesDictionary.value; _ck(_v, 4, 0, currVal_1); _ck(_v, 6, 0); }, null); }
export function View_GenericFileDisplayerPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-file-displayer-page", [], null, null, null, View_GenericFileDisplayerPageComponent_0, RenderType_GenericFileDisplayerPageComponent)), i1.ɵdid(1, 114688, null, 0, i10.GenericFileDisplayerPageComponent, [i9.MAT_DIALOG_DATA, i11.ToastrService, i12.I18n], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericFileDisplayerPageComponentNgFactory = i1.ɵccf("app-generic-file-displayer-page", i10.GenericFileDisplayerPageComponent, View_GenericFileDisplayerPageComponent_Host_0, { files: "files" }, {}, []);
export { GenericFileDisplayerPageComponentNgFactory as GenericFileDisplayerPageComponentNgFactory };
