<app-dialog-header label="Dipòsit grua" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.DipositGrua) || !this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.DipositGrua) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.DipositGrua) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Impressió" i18n-text (click)="this.impressio();" color="has-color-blue" icon="fa-print" *ngIf="reports.length > 0" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.DipositGrua)"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<div class="row">
  <mat-tab-group class="col-xl-12"><!--(selectedIndexChange)="selected.setValue($event)"-->
    <mat-tab i18n-label label="General" >
      <!-- <app-form-text-edit class="col-xl-6" i18n-label label="Id Retirada/Den" [(value)]="this.vehicleDiposit.idDipositVehicle"  [isReadOnly]="true" ></app-form-text-edit>
      <app-form-text-edit class="col-xl-6" i18n-label label="Lloc retirada" [(value)]="this.vehicleDiposit.llocRetirada"  [isReadOnly]="true" ></app-form-text-edit>

      <app-form-text-edit class="col-xl-8" i18n-label label="Matricula" [(value)]="this.vehicleDiposit.idVehiclesFK"  [isReadOnly]="true" ></app-form-text-edit>
   -->
   <app-diposit-editor-general #general [(value)]="this.vehicleDiposit"></app-diposit-editor-general>
    </mat-tab>
    <mat-tab i18n-label label="Qui recull">    
      <app-diposit-editor-qui-recull [(value)]="this.vehicleDiposit"></app-diposit-editor-qui-recull>
    </mat-tab>
    <mat-tab i18n-label label="Fotos">
      <!--<app-photo-box-gallery #pbg  style="width: 100%;" [numDenuncia]="this.proposta.codi" [imatges]="this.proposta?.IMATGES" height="auto" [botoMatricula]="false" [allowSelect]="false"></app-photo-box-gallery>-->
      <!--<app-photo-box-gallery #pbg  style="width: 100%;"  [numDenuncia]="this.vehicleDiposit.iddenuncia"  [imatges]="this.vehicleDiposit?.IMATGES" height="auto" [botoMatricula]="false" [allowSelect]="false"></app-photo-box-gallery>-->
      <app-photo-box-grua #pbg   [numDenuncia]="this.vehicleDiposit.iddenuncia"  [imagesInput]="this.vehicleDiposit?.IMATGES" [isDialog]="false" height="auto" [botoMatricula]="false" [allowSelect]="false"></app-photo-box-grua>
    </mat-tab>
    <mat-tab i18n-label label="Desperfectes">
      <app-diposit-grua-desperfectos-v2 [codiSomPolGrua]="this.vehicleDiposit.iddenuncia"  ></app-diposit-grua-desperfectos-v2>
    </mat-tab>
</mat-tab-group>
      <!--<app-form-combo-edit class="col-xl-3" i18n-label label="Legislació" [items]="this.tipusLegislacions" [(value)]="this.infraccio.legislacio" valueField="codi" textField="descripcio" [isReadOnly]="this.dialogData.isReadOnly" required></app-form-combo-edit>-->
     <!-- <app-form-text-edit class="col-xl-4" i18n-label label="numPDA" [(value)]="this.proposta.numPDA"  [isReadOnly]="true" ></app-form-text-edit>
      
      <app-form-text-edit class="col-xl-6" i18n-label label="Matricula" [(value)]="this.proposta.idVehiclesFK"  [isReadOnly]="true" ></app-form-text-edit>
      <app-form-text-edit class="col-xl-6" i18n-label label="Marca"  [isReadOnly]="true" ></app-form-text-edit>

      <app-form-text-edit class="col-xl-6" i18n-label label="Marca"   [isReadOnly]="true" ></app-form-text-edit>
      <app-form-text-edit class="col-xl-6" i18n-label label="Color"   [isReadOnly]="true" ></app-form-text-edit>
      
      <div class="col-xl-12" >
        <app-combo-box-input-autocomplete #comboAliasGrua class="center-horizontal" i18n-label label="Alias Grua" [options]="this.aliasGruaDictionary"
        (valorSeleccionado)="this.aliasGruaSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteAliasGrua"  required="false" [disableComponent]="!this.isProposta">
      </app-combo-box-input-autocomplete>
    </div>  -->
      <!--<app-form-combo-edit class="col-xl-12" i18n-label label="Alias Grua" [items]="this.aliasGruaList" [(value)]="this.proposta.secureId" valueField="secureId" textField="descripcio"  required></app-form-combo-edit>-->
       
      <!--<div class="col-xl-12" >
        <app-combo-box-input-autocomplete #comboInfraccions class="center-horizontal" i18n-label label="Infracció" [options]="this.infraccionsDictionary" [disableComponent]="!this.isProposta"
        (valorSeleccionado)="this.infraccionsSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteInfraccions"  required="false" >
      </app-combo-box-input-autocomplete>
    </div> 
      <app-form-combo-edit class="col-xl-12" i18n-label label="Infracció" [items]="this.infraccionsList" [(value)]="this.proposta.secureId" valueField="secureId" textField="descCurta"  required></app-form-combo-edit>
    <div class="col-xl-6" >
      <app-combo-box-input-autocomplete #comboAgent class="center-horizontal" i18n-label label="Agent" [options]="this.agentsDictionary" [disableComponent]="!this.isProposta"
      (valorSeleccionado)="this.agentSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteAgent"  required="false" >
    </app-combo-box-input-autocomplete>
    </div> 
      <<app-form-combo-edit class="col-xl-12" i18n-label label="Agent" [items]="this.aliasGruaList" [(value)]="this.proposta.secureId" valueField="codi" textField="descripcio"  ></app-form-combo-edit>
      <div class="col-xl-6" >
        <app-combo-box-input-autocomplete #comboMotiuDesestimar class="center-horizontal" i18n-label label="Motiu desestimar" [options]="this.motiusDesestimarDictionary" [disableComponent]="!this.isProposta"
        (valorSeleccionado)="this.motiuDesestimarSeleccionado($event)" [valorAutocomplete]="this.valorAutoCompleteMotiusDesestimar"  required="false" >
      </app-combo-box-input-autocomplete>
      </div> 
      <app-form-combo-edit class="col-xl-12" i18n-label label="Motiu desestimar" [items]="this.aliasGruaList" [(value)]="this.proposta.secureId" valueField="codi" textField="descripcio"  ></app-form-combo-edit>
      
      <mat-checkbox class="col-xl-6" [(ngModel)]="this.proposta.implicaDenuncia" [checked]="this.proposta.implicaDenuncia" i18n  [disabled]="!this.isProposta">Crear denúncia &nbsp;</mat-checkbox>
      <mat-checkbox class="col-xl-6" [(ngModel)]="this.proposta.conCargo" [checked]="this.proposta.conCargo" i18n [disabled]="!this.isProposta">Amb càrrec &nbsp;</mat-checkbox>
     
      <textarea cols="12"  matInput [(ngModel)]="this.proposta.observacions" ></textarea>-->
      <!-- <app-form-text-area class="col-xl-12" i18n-label label="Observacions" [(value)]="this.proposta.observacions" maxLength="5000" [disabled]="!this.isProposta"></app-form-text-area>
     
      <div class="row proposta-buttons" *ngIf="this.isProposta">
        <div class="col-md-8 offset-md-2 d-flex">
          <span >
            <button [disabled]="disabled()" 
              class="btn btn-danger button-not-allowed" type="button" (click)="this.rejectProposta()">           
            <i class="fa fa-times-circle"></i>&nbsp;<span i18n>Denegar</span>
            </button>
          </span>            
          <span >            
            <button [disabled]="disabledButtonAccept()" 
              class="btn btn-success offset-md-1 button-not-allowed" type="button" (click)="this.acceptProposta()">
            <i class="fa fa-check-circle"></i>&nbsp;<span i18n>Acceptar</span>
            </button>
          </span>
        </div>
      </div>
      <div class="row proposta-buttons" *ngIf="!this.isProposta">
        <div class="col-md-8 offset-md-2 d-flex">
          <i *ngIf="this.proposta.valida" [class]="'fas fa-check-circle has-color-green fa-3x'" ></i>
          <i *ngIf="!this.proposta.valida" [class]="'far fa-times-circle has-color-red fa-3x'" ></i>
        </div>
      </div> -->
</div>