import { Component, OnInit, EventEmitter, Output, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';
import { ColorFilter } from '../../models/color-filter';

@Component({
  selector: 'app-colors-filter',
  templateUrl: './colors-filter.component.html',
  styleUrls: ['./colors-filter.component.scss']
})
export class ColorsFilterComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  filter: ColorFilter;

  @Output() notify: EventEmitter<ColorFilter> = new EventEmitter<ColorFilter>();

  constructor(
    public thisDialogRef: MatDialogRef<ColorsFilterComponent>,
    private i18n: I18n,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.filter = data.filter;
  }

  ngOnInit() {
    this.subscription = Observable.fromEvent(document, 'keypress').subscribe((e: KeyboardEvent ) => {
      if (e.key === 'Enter') {
        this.apply();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset() {
    this.filter.clear();
    this.apply();
  }

  apply() {
    this.filter.updated();
    this.notify.emit(this.filter);
    this.thisDialogRef.close();
  }

  dismiss() {
    this.thisDialogRef.close();
  }

}
