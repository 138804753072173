import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { ZonaAparcare } from '../models/zona-aparcare.model';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class ZonesAparcareService {
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setZona(zonaAparcare: ZonaAparcare): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetZonaAparcare'], zonaAparcare);
    }
  }

  // removeTarifa(secure_id): Observable<any> {
  //   if (this.authGuard.canActivate()) {
  //     return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteTarifa'].replace('{secure_id}', secure_id), null);
  //   }
  // }


  getZona(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetZonaAparcare'].replace('{secure_id}', secure_id), null);
    }
  }

  publicar(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlPublicarZonesAparcare'], null);
    }
  }

  descargarDatos(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlDescargarDatosZonesAparcare'], null);
    }
  }
  getAllZonas(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllZonesAparcare'], null);
    }
  }

  // generateExcel(filter: ColorFilter, sortDir: string, sortField: string): Observable<any> {
  //   const start = 0;
  //   const pageSize = 1000;

  //   sortField = sortField === undefined ? defaultSortParam : sortField;
  //   sortDir = sortDir === '' ? defaultSortDirection : sortDir;
  //   const params = new ColorsFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

  //   if (this.authGuard.canActivate()) {
  //     return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlColorsExcel'],  params );
  //   }
  // }
}
