import { SortDirection } from "@angular/material";
import { FormMultiSelect } from "path-shared/components/form/form-multi-select/model/form-multi-select.model";
import { FilterData } from "path-shared/models/filter-data";

export class CobrosFilter extends FilterData {
  fechas: Date[];
  aparatos: Array<FormMultiSelect>;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.fechas = null;
    this.aparatos = null; 

    this.labels['fechas'] = this.i18n('Dates');
    this.labels['aparatos'] = this.i18n('Aparells');
  }
}
