import { IFilterService } from './filter-service';
import { HttpParams } from '@angular/common/http';
import { PaginationFilter } from 'path-shared/models/pagination.filter';
import { FilterData } from 'path-shared/models/filter-data';

export abstract class CustomFilterService implements IFilterService {
  public abstract createFilterParams(filter: any, start: number, pageSize: number, sortField: string, sortDir: string): HttpParams;
  /* {
    throw new Error('You should reimplement this method in your class');
  }*/

  public createFilterPaginator(filter: any, start: number, paginator: PaginationFilter): HttpParams {
    return this.createFilterParams(filter, start, paginator.pageSize, paginator.sortField, paginator.sortDir);
  }

  createParamsFromFilter(filter: any): HttpParams {
    const filterData: FilterData = filter;
    return this.createFilterParams(filter, filterData.paginator.pageNumber, filterData.paginator.pageSize, filterData.paginator.sortField, filterData.paginator.sortDir);
  }

  protected getTristateValue(value) {
    if (value === null) {
      return '';
    } else {
      return value.toString();
    }
  }

}
