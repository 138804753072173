import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class FestiusFilter extends FilterData {
  dia: Date;
  descripcio: string;
  tipus: number;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.dia = null;
    this.descripcio = '';
    this.tipus = null;

    this.labels['dia'] = this.i18n('Dia');
    this.labels['descripcio'] = this.i18n('Descripció');
    this.labels['tipus'] = this.i18n('Tipus');
  }
}
