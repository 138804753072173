var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
var defaultSortParam = 'codi';
var defaultSortDirection = 'asc';
var SomPolGruaFilterService = /** @class */ (function (_super) {
    __extends(SomPolGruaFilterService, _super);
    function SomPolGruaFilterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SomPolGruaFilterService.prototype.createFilterParams = function (filter, start, pageSize, sortField, sortDir) {
        if (filter.fechaAcepDenegFinal !== undefined && filter.fechaAcepDenegFinal !== null) {
            filter.fechaAcepDenegFinal.setHours(23, 59, 59);
        }
        if (filter.dataFinal !== undefined && filter.dataFinal !== null) {
            filter.dataFinal.setHours(23, 59, 59);
        }
        return new HttpParams()
            .append('codi', (filter.codi ? filter.codi.toString() : ''))
            .append('idVehiclesFK', (filter.idVehiclesFK ? filter.idVehiclesFK.toString() : ''))
            .append('nombreCalle', (filter.nombreCalle ? filter.nombreCalle.toString() : ''))
            .append('numCarrer', (filter.numCarrer ? filter.numCarrer.toString() : ''))
            .append('idAgentFk', (filter.idAgentFk ? filter.idAgentFk.toString() : ''))
            .append('idTecnicFK', (filter.idTecnicFK ? filter.idTecnicFK.toString() : ''))
            .append('idMotiuDesestimada', (filter.idMotiuDesestimada ? filter.idMotiuDesestimada.toString() : ''))
            .append('dataInici', Reflection.empty(filter.dataInici) ? '' : typeof (filter.dataInici) === 'string' ?
            filter.dataInici : filter.dataInici.toDateString())
            .append('dataFinal', Reflection.empty(filter.dataFinal) ? '' : typeof (filter.dataFinal) === 'string' ?
            filter.dataFinal : filter.dataFinal.toDateString())
            .append('fechaAcepDenegInici', Reflection.empty(filter.fechaAcepDenegInici) ? '' : typeof (filter.fechaAcepDenegInici) === 'string' ?
            filter.fechaAcepDenegInici : filter.fechaAcepDenegInici.toDateString())
            .append('fechaAcepDenegFinal', Reflection.empty(filter.fechaAcepDenegFinal) ? '' : typeof (filter.fechaAcepDenegFinal) === 'string' ?
            filter.fechaAcepDenegFinal : filter.fechaAcepDenegFinal.toDateString())
            .append('implicaDenuncia', this.getTristateValue(filter.implicaDenuncia))
            .append('sinCargo', this.getTristateValue(filter.sinCargo))
            .append('start', start.toString())
            .append('size', pageSize.toString())
            .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
            .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
    };
    return SomPolGruaFilterService;
}(CustomFilterService));
export { SomPolGruaFilterService };
