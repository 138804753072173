import { Component, ViewChild } from '@angular/core';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Instituciones } from 'path-shared/models/instituciones';
import { Parametros } from 'path-shared/models/parametros';
import { ParametersService } from 'path-shared/services/parameters/parameters.service';
import { ZbRutaEditorComponent } from './zb-ruta-editor/zb-ruta-editor.component';
import { Builder } from 'path-shared/models/builder';
import { MatDialog } from '@angular/material';
import { ZbRutasTableComponent } from './zb-rutas-table/zb-rutas-table.component';

@Component({
  selector: 'app-zb-rutas',
  templateUrl: './zb-rutas.component.html',
  styleUrls: ['./zb-rutas.component.scss']
})
export class ZbRutasComponent {
 
  @ViewChild(ZbRutasTableComponent) ZbRutasTable: ZbRutasTableComponent;
  
  constructor(
    private dialog: MatDialog,
   
  ) {
    
  }

  
  create(){
    const data: ComponentDialog= Builder.newComponentDialog(true, false,null);
    const ref= this.dialog.open(ZbRutaEditorComponent, {
      width: '90%',
      maxHeight: '90vh',
      data: data,
      closeOnNavigation: true,
      autoFocus: true,
    }).afterClosed().subscribe((result) => {
      if(result){
        this.refresh();
      }
    });
  }
  refresh(){
    this.ZbRutasTable.load();
  }
}