export class ReservesResum {
    CochesOcupados: number;
    CochesLibres: number;
    MotosOcupados: number;
    MotosLibres: number;
    PersonasOcupados: number;
    PersonasLibres: number;
    PersonasRealesOcupados: number;

    constructor() {
        this.CochesLibres = 0;
        this.CochesOcupados = 0;
        this.MotosOcupados = 0;
        this.MotosLibres = 0;
        this.PersonasOcupados = 0;
        this.PersonasLibres = 0;
        this.PersonasRealesOcupados = 0;
    }
}
