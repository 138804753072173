import { FilterData } from 'path-shared/models/filter-data';
import { SortDirection } from '@angular/material';

export class ZonesAparcareFilter extends FilterData {

  codi: number;
  descripcio: string;
  dataInici: Date;
  dataFi: Date;

  constructor(sortField: string, sortDir: SortDirection) {
    super(sortField, sortDir);
    this.codi = null;
    this.descripcio = '';
    this.dataInici = null;
    this.dataFi = null;


    this.labels['codi'] = this.i18n('Codi');
    this.labels['descripcio'] = this.i18n('Descripció');
    this.labels['dataInici'] = this.i18n('Data inici');
    this.labels['dataFi'] = this.i18n('Data fi');

  }
}
