<app-dialog-header label="Legislació" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green" icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Legislacions) || !this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Legislacions) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt" [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Legislacions) || this.dialogData.isReadOnly"></app-tool-button>
  <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>
<app-dialog-body>
  <form #mainForm>
    <div class="row">
      <app-form-text-edit id="legislacions-editor-codi" class="col-sm-3" i18n-label label="Codi" [(value)]="this.legislacio.codi" [disabled]="this.legislacio.secureId != null" required maxLength="3"></app-form-text-edit>
      <app-form-text-edit id="legislacions-editor-descripcio" class="col-sm-9" i18n-label label="Descripció" [(value)]="this.legislacio.descripcio" required maxLenght="50"></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-text-edit id="legislacions-editor-mesesSL" class="col-sm-4" i18n-label label="Mesos sanció lleu" [(value)]="this.legislacio.mesesSL" number maxLenght="3"></app-form-text-edit>
      <app-form-text-edit id="legislacions-editor-mesesSG" class="col-sm-4" i18n-label label="Mesos sanció greu" [(value)]="this.legislacio.mesesSG" number maxLenght="3"></app-form-text-edit>
      <app-form-text-edit id="legislacions-editor-mesesSMG" class="col-sm-4" i18n-label label="Mesos sanció molt greu" [(value)]="this.legislacio.mesesSMG" number maxLenght="3"></app-form-text-edit>
    </div>
    <div class="row">
      <app-form-tri-state label="Obsoleta" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-label labelTodos="Tots" [(value)]="this.legislacio.obsoleta" triStateDisabled="true" [disabled]="this.dialogData.isReadOnly"></app-form-tri-state>
    </div>
    <div class="row">
      <app-form-tri-state label="Denúncia trànsit" i18n-label labelSi="Si" i18n-label labelNo = "No" i18n-label labelTodos="Tots" [(value)]="this.legislacio.trafic" triStateDisabled="true" [disabled]="this.dialogData.isReadOnly"></app-form-tri-state>
    </div>
  </form>
</app-dialog-body>
