import { OnInit, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { timer } from 'rxjs';
import { ValidationService } from 'path-shared/services/validation/validation.service';
var FilterTextEditComponent = /** @class */ (function () {
    function FilterTextEditComponent(validator) {
        this.validator = validator;
        this.valueChange = new EventEmitter();
        this.keyEnter = new EventEmitter();
        this.val = null;
        this.disabled = false;
        this.required = false;
        this.equals = true;
        this.contains = false;
        this.number = false;
        this.matricula = false;
        this.comparsion = null;
    }
    Object.defineProperty(FilterTextEditComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            if (this.val !== val) {
                this.val = val;
                this.valueChange.emit(this.val);
            }
        },
        enumerable: true,
        configurable: true
    });
    FilterTextEditComponent.prototype.ngOnInit = function () {
        this.setDefaultComparsion();
    };
    FilterTextEditComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.contains !== false) {
            timer(20).subscribe(function () {
                _this.setDefaultComparsion();
            });
        }
        if (this.autofocus !== undefined) {
            timer(500).subscribe(function () {
                _this.elementRef.nativeElement.focus();
            });
        }
    };
    FilterTextEditComponent.prototype.valid = function () {
        if (this.elementRef.nativeElement.className.indexOf('ng-invalid') === -1) {
            return !this.errorRequired() && !this.errorNumber() && !this.errorLength() && !this.errorMatricula();
        }
        else {
            return false;
        }
    };
    FilterTextEditComponent.prototype.isRequired = function () {
        return this.required !== false;
    };
    FilterTextEditComponent.prototype.isRequiredNumber = function () {
        if (this.required) {
            return this.number !== false;
        }
        else {
            return false;
        }
    };
    FilterTextEditComponent.prototype.isRequiredMatricula = function () {
        return this.matricula !== false;
    };
    FilterTextEditComponent.prototype.hasMaxLength = function () {
        return this.validator.isNatural(this.maxLength.toString());
    };
    FilterTextEditComponent.prototype.errorRequired = function () {
        return this.isRequired() && (this.value === null || this.value.length === 0);
    };
    FilterTextEditComponent.prototype.errorNumber = function () {
        if (!this.isRequiredNumber()) {
            return false;
        }
        else if (this.isRequiredNumber() && this.value != null && this.value.length > 0 && this.validator.isNumeric(this.value)) {
            return false;
        }
        else if (this.isRequiredNumber() && (this.value === null || this.value.length === 0)) {
            return true;
        }
        else {
            return true;
        }
        // return this.isRequiredNumber() && !this.validator.isNumeric(this.value);
    };
    FilterTextEditComponent.prototype.errorMatricula = function () {
        return this.isRequiredMatricula() && !this.validator.isMatricula(this.value);
    };
    FilterTextEditComponent.prototype.errorLength = function () {
        if (this.maxLength === undefined) {
            return false;
        }
        else {
            return !this.validator.checkLength(this.value, this.maxLength);
        }
    };
    FilterTextEditComponent.prototype.setDefaultComparsion = function () {
        if (this.comparsion === null || this.comparsion === undefined) {
            this.defaultComparsion = '%';
        }
        else {
            this.defaultComparsion = this.comparsion;
        }
    };
    FilterTextEditComponent.prototype.onEnter = function () {
        this.keyEnter.emit(true);
    };
    return FilterTextEditComponent;
}());
export { FilterTextEditComponent };
