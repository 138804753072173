/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog-body.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./dialog-body.component";
var styles_DialogBodyComponent = [i0.styles];
var RenderType_DialogBodyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogBodyComponent, data: {} });
export { RenderType_DialogBodyComponent as RenderType_DialogBodyComponent };
export function View_DialogBodyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), i1.ɵncd(null, 0)], null, null); }
export function View_DialogBodyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-body", [], null, null, null, View_DialogBodyComponent_0, RenderType_DialogBodyComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogBodyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogBodyComponentNgFactory = i1.ɵccf("app-dialog-body", i3.DialogBodyComponent, View_DialogBodyComponent_Host_0, {}, {}, ["*"]);
export { DialogBodyComponentNgFactory as DialogBodyComponentNgFactory };
