/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./importacio-denuncies.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../shared/components/toolbar/toolbar/toolbar.component.ngfactory";
import * as i4 from "../../../../shared/components/toolbar/toolbar/toolbar.component";
import * as i5 from "../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component.ngfactory";
import * as i6 from "../../../../shared/components/toolbar/tool-button-permissions/tool-button-permissions.component";
import * as i7 from "../../../../shared/services/authorization/authorization-service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../../shared/components/form/form-combo-edit/form-combo-edit.component.ngfactory";
import * as i10 from "../../../../shared/components/form/form-combo-edit/form-combo-edit.component";
import * as i11 from "../../../../shared/services/validation/validation.service";
import * as i12 from "../../../../shared/components/form/form-file-multiple-upload/form-file-multiple-upload.component.ngfactory";
import * as i13 from "../../../../shared/components/form/form-file-multiple-upload/form-file-multiple-upload.component";
import * as i14 from "../../../../shared/components/progress-bar/progress-bar.component.ngfactory";
import * as i15 from "../../../../shared/components/progress-bar/progress-bar.component";
import * as i16 from "../../../../shared/services/notification/notification.service";
import * as i17 from "./importacio-denuncies.component";
import * as i18 from "@ngx-translate/i18n-polyfill";
import * as i19 from "../../services/importacioDenuncia.service";
import * as i20 from "ngx-toastr";
import * as i21 from "../../../../shared/services/form/form.service";
import * as i22 from "@angular/router";
import * as i23 from "../../../../shared/components/loader/loader.service";
var styles_ImportacioDenunciesComponent = [i0.styles];
var RenderType_ImportacioDenunciesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImportacioDenunciesComponent, data: {} });
export { RenderType_ImportacioDenunciesComponent as RenderType_ImportacioDenunciesComponent };
export function View_ImportacioDenunciesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mainForm: 0 }), i1.ɵqud(402653184, 2, { progressBar: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["mainForm", 1]], null, 25, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(4, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "app-toolbar", [], null, null, null, i3.View_ToolbarComponent_0, i3.RenderType_ToolbarComponent)), i1.ɵdid(8, 114688, null, 0, i4.ToolbarComponent, [], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-tool-button-permissions", [], null, null, null, i5.View_ToolButtonPermissionsComponent_0, i5.RenderType_ToolButtonPermissionsComponent)), i1.ɵdid(10, 114688, null, 0, i6.ToolButtonPermissionsComponent, [i7.AuthorizationService, i8.MatDialog], { permission: [0, "permission"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 11, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-form-combo-edit", [["label", "Format importaci\u00F3"], ["required", ""], ["textField", "descImportacio"], ["valueField", "idImportacio"]], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.selectedValue = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_FormComboEditComponent_0, i9.RenderType_FormComboEditComponent)), i1.ɵdid(16, 4308992, null, 0, i10.FormComboEditComponent, [i11.ValidationService], { label: [0, "label"], value: [1, "value"], items: [2, "items"], valueField: [3, "valueField"], textField: [4, "textField"], required: [5, "required"] }, { valueChange: "valueChange" }), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "row d-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-form-file-multiple-upload", [["isTextarea", "false"], ["labelFitxer", "Fitxers a processar"], ["labelHidden", "true"]], null, [[null, "fileReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fileReady" === en)) {
        var pd_0 = (_co.fileReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_FormFileMultipleUploadComponent_0, i12.RenderType_FormFileMultipleUploadComponent)), i1.ɵdid(19, 114688, null, 0, i13.FormFileMultipleUploadComponent, [], { labelFitxer: [0, "labelFitxer"], labelHidden: [1, "labelHidden"], isTextarea: [2, "isTextarea"] }, { fileReady: "fileReady" }), (_l()(), i1.ɵeld(20, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 3, "div", [["class", "col-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 2, "button", [["class", "btn btn-sq-lg btn-primary btn-block"], ["style", "margin:1.7vh"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.processar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Processar"])), (_l()(), i1.ɵeld(25, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "app-progress-bar", [["class", "col-9"], ["label", "Progr\u00E9s"], ["pantalla", "importacioDenuncia"]], null, null, null, i14.View_ProgressBarComponent_0, i14.RenderType_ProgressBarComponent)), i1.ɵdid(27, 114688, [[2, 4], ["progressBar", 4]], 0, i15.ProgressBarComponent, [i16.NotificationService], { class: [0, "class"], label: [1, "label"], pantalla: [2, "pantalla"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 8, 0); var currVal_7 = _co.auth.Pages.ImportacioDenuncies; _ck(_v, 10, 0, currVal_7); var currVal_8 = "Format importaci\u00F3"; var currVal_9 = _co.selectedValue; var currVal_10 = _co.importacioDenuncia; var currVal_11 = "idImportacio"; var currVal_12 = "descImportacio"; var currVal_13 = ""; _ck(_v, 16, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = "Fitxers a processar"; var currVal_15 = "true"; var currVal_16 = "false"; _ck(_v, 19, 0, currVal_14, currVal_15, currVal_16); var currVal_17 = "col-9"; var currVal_18 = "Progr\u00E9s"; var currVal_19 = "importacioDenuncia"; _ck(_v, 27, 0, currVal_17, currVal_18, currVal_19); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ImportacioDenunciesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-importacio-denuncies", [], null, null, null, View_ImportacioDenunciesComponent_0, RenderType_ImportacioDenunciesComponent)), i1.ɵdid(1, 4308992, null, 0, i17.ImportacioDenunciesComponent, [i8.MatDialog, i7.AuthorizationService, i18.I18n, i19.ImportacioDenunciaService, i8.MatDialogRef, i20.ToastrService, i21.FormService, i16.NotificationService, i22.Router, i23.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImportacioDenunciesComponentNgFactory = i1.ɵccf("app-importacio-denuncies", i17.ImportacioDenunciesComponent, View_ImportacioDenunciesComponent_Host_0, {}, {}, []);
export { ImportacioDenunciesComponentNgFactory as ImportacioDenunciesComponentNgFactory };
