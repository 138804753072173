<app-dialog-header *ngIf="this.dataDialog.isDialog" label="Histórico Deltacar" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Consultar" i18n-text (click)="refresh();" color="has-color-blue" icon="fa-sync">
  </app-tool-button>
  <!-- <app-tool-button text="Filtre" i18n-text (click)="onFilterClicked();" color="has-color-orange" icon="fa-filter">
  </app-tool-button> -->
  <app-tool-button text="Excel" i18n-text (click)="generateExcel();" color="has-color-darkgreen" icon="fa-file-excel">
  </app-tool-button>
  <app-tool-button-permissions [permission]="this.auth.Pages.DeltacarHistorico"></app-tool-button-permissions>
</app-toolbar>

<div class="row">
  <app-form-text-edit id="deltacar-historico-filter-matricula" class="col-2" i18n-label label="Matrícula"
    [(value)]="this.deltacarHistoricoTable.filter.matricula"></app-form-text-edit>
  <app-form-combo-edit class="col-2" i18n-label label="Estado" [items]="estados" valueField="descripcio"
    textField="descripcio" [(value)]="this.deltacarHistoricoTable.filter.estado"></app-form-combo-edit>
  <app-form-date-edit id="deltacar-historico-filter-dataIni" class="col-2" i18n-label label="Fecha inicio"
    [(value)]="deltacarHistoricoTable.filter.dataInici" type="calendar"></app-form-date-edit>
  <app-form-date-edit id="deltacar-historico-filter-dataFi" class="col-2" i18n-label label="Fecha fin"
    [(value)]="deltacarHistoricoTable.filter.dataFi" type="calendar"></app-form-date-edit>
</div>


<div class="row">
  <div class="col-sm-4" style="margin-top: auto;">
    <agm-map #AgmMap [latitude]="this.latitud" [longitude]="this.longitud" [zoom]="this.zoom">
      <!-- <agm-marker *ngIf="this.selectedRow !== undefined" [latitude]="this.selectedRow.latitud"
        [longitude]="this.selectedRow.longitud"></agm-marker> -->
      <agm-marker *ngFor="let row of rowsChecked(); let i = index" [latitude]="this.row.latitud"
        [longitude]="this.row.longitud" (markerClick)="markerClick(row)"></agm-marker>
    </agm-map>
  </div>
  <div class="col-sm-8">
    <app-deltacar-historico-table (itemClicked)="this.selectRow($event);"></app-deltacar-historico-table>
  </div>
</div>