import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { ActuacioMatriculaFilterService } from './ActuacioMatricula-filter.service';
import { ActuacioMatriculaFilter } from '../models/actuacioMatricula-filter';

const defaultSortParam = 'matricula';
const defaultSortDirection = 'desc';


@Injectable()
export class ActuacioMatriculasService {
  displayedItems = [];

  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  getAll(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new ActuacioMatriculaFilterService().createFilterParams(new ActuacioMatriculaFilter('matricula', 'asc'), 0, 99999, 'matricula', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetActuacionsMatriculas'],  params);
    }
  }
}
