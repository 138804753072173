 
<app-dialog-header label="Finalitzar torn" i18n-label></app-dialog-header>
<app-toolbar>
  <app-tool-button text="Impressió" i18n-text (click)="this.downloadPDF();" color="has-color-blue" icon="fa-print" *ngIf="this.pdfActual.value !== undefined " [disabled]="this.pdfActual.value === undefined "></app-tool-button>
  <app-tool-button text="Cancelar" i18n-text (click)="this.cancel()" color="has-color-yellow" icon="fa-th-list"></app-tool-button>
</app-toolbar>

<!-- <app-form-text-edit class="col-sm-3" type="text" i18n-label label="Data Inici" [(value)]="{{this.registroTurno.horaSortida | longDate}}" [readonly]="true" ></app-form-text-edit> -->
<div class="form-group">
  <div class="row">
    <div class="col-sm-5">
      <label  i18n>Data inici: </label>
      <ng-container *ngIf="this.registroTurno !== undefined && this.registroTurno !== null &&  this.registroTurno.ID_TURNO !== undefined && this.registroTurno.ID_TURNO !== null">
        {{ this.registroTurno.FECHA_INICIO | longDate}}
      </ng-container>
    </div>
    <div class="col-sm-2">
    <button (click)="incrementZoom(-0.1)" mat-button type="button">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        <line x1="8" y1="11" x2="14" y2="11"></line>
      </svg>
    </button>
    </div>
    <!--<div class="col-sm-3">

    <mat-form-field class="page-number">
      <input matInput type="number" placeholder="Zoom" [(ngModel)]="zoom" pattern="-?[0-9]*(\.[0-9]+)?">
      <mat-error>  <label  i18n>Introdueix un número!</label></mat-error>
    </mat-form-field>
  </div>-->
  <div class="col-sm-2">
    <button (click)="incrementZoom(0.1)" mat-button type="button">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          <line x1="11" y1="8" x2="11" y2="14"></line>
          <line x1="8" y1="11" x2="14" y2="11"></line>
        </svg>
      </button>
    </div>
  </div>
</div>



<div id="pdfViewer">
  <pdf-viewer style= "overflow: scroll"
  delegate-handle="relativity-special-general-theory" [zoom]= "zoom" [fit-to-page]="false" [original-size]="true"
  src="{{ this.pdfActual.value }}" class="col-xl-3" max-height='50%'
  scale="1"
  show-toolbar="true"></pdf-viewer>
 </div>

<div  class="text-center">
  <button 
    class="btn btn-success button-not-allowed btn-lg" type="button" (click)="this.finalizarTurno()"
    [disabled]="this.registroTurno === undefined || this.registroTurno === null ||  this.registroTurno.ID_TURNO === undefined || this.registroTurno.ID_TURNO === null  ">           
    <i class="fas fa-hourglass-start"></i> &nbsp;<span i18n>Finalitzar torn</span>  &nbsp;
  </button>
</div> 