import { RegistroTurno } from './../models/registro-turno';
import { Turno } from './../models/turnos-model';
import { TurnosFilter } from './../models/turnos-filter';
import { Injectable } from '@angular/core';
import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { MotiusEntradaGrua } from 'app/sections/grua/Models/motius-entrada.model';
import { TurnosFilterService } from './turnos-filter-service';
import { HttpParams } from '@angular/common/http';

const defaultSortParam = 'descripcio';
const defaultSortDirection = 'asc';

@Injectable()
export class TurnosService {
  
  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {}

  setTurno(turno: Turno): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlSetTurno'], turno);
    }
  }

  removeTurno(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteTurno'].replace('{secure_id}', secure_id), null);
    }
  }

  getTurno(secure_id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetTurno'].replace('{secure_id}', secure_id), null);
    }
  }

  getAllTurnos(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetAllTurnos'], null);
    }
  }

  iniciarTurno(regTurno: RegistroTurno): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlIniciarTurno'], regTurno);
    }
  }

  getLastTurnActive(UserID: number) : Observable<any>{
    if (this.authGuard.canActivate()) {
      return this.http.getAndCatchError(config.urls['apiUrl'] + api.endpoints['urlGetLastTurnActive'].replace('{UserID}', UserID.toString()), null);
    }
  }

  getRegistroTurnoUsuarioByID(idTurno: number) : Observable<any>{
    if (this.authGuard.canActivate()) {
      return this.http.getAndCatchError(config.urls['apiUrl'] + api.endpoints['getRegistroTurnoUsuarioByID'].replace('{idTurno}', idTurno.toString()), null);
    }
  }
  finalizarTurno(regTurno: RegistroTurno) : Observable<any>{
    if (this.authGuard.canActivate()) {
      return this.http.post(config.urls['apiUrl'] + api.endpoints['urlFinalizarTurno'], regTurno);
    }
  }
  
  getReportTurnoUsuarioByID(secureId: string): Observable<any>{
    if (this.authGuard.canActivate()) {
      //return this.http.getFile(config.urls['apiUrl'] + '/file/' + somId ,  new HttpParams())
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGetReportTurnoUsuarioByID'].replace('{secureId}', secureId.toString()),  new HttpParams());
    }
  }

  generateExcel(filter: TurnosFilter, sortDir: string, sortField: string): Observable<any> {
    const start = 0;
    const pageSize = 1000;

    sortField = sortField === undefined ? defaultSortParam : sortField;
    sortDir = sortDir === '' ? defaultSortDirection : sortDir;
    const params = new TurnosFilterService().createFilterParams(filter, start, pageSize, sortField, sortDir);

    if (this.authGuard.canActivate()) {
      return this.http.getFile(config.urls['apiUrl'] + api.endpoints['urlGruaMotiusEntradaExcel'],  params );
    }
  }
}
