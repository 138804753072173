var _a;
export var TravelModeEnum;
(function (TravelModeEnum) {
    TravelModeEnum["Coche"] = "Drive";
    TravelModeEnum["Bicicleta"] = "BICYCLE";
    TravelModeEnum["Andando"] = "WALK";
    TravelModeEnum["Moto"] = "TWO_WHEELER";
    TravelModeEnum["TransportePublico"] = "TRANSIT";
})(TravelModeEnum || (TravelModeEnum = {}));
;
export var TravelMode2LabelMapping = (_a = {},
    _a[TravelModeEnum.Coche] = "directions_walk",
    _a[TravelModeEnum.Andando] = "directions_car",
    _a[TravelModeEnum.Moto] = "directions_bike",
    _a[TravelModeEnum.Bicicleta] = "pedal_bike",
    _a[TravelModeEnum.TransportePublico] = "directions_bus",
    _a);
