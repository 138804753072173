<div class="form-group">
  <div [ngClass]="{'hidden': !tabs || tabs.length === 0}">
    <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
      <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab">
          <ng-template mat-tab-label>
              {{tabLabel}} {{tab.id}}
              <button class="tab-dynamic-icon" style="float:right;" mat-icon-button (click)="removeTab(index)">
                <mat-icon>cancel</mat-icon>
              </button>
          </ng-template>
          <div class="tab-dynamic-content chatbox">
            <!-- Botó recupera missastges nous -->
            <div class="text-center clearBoth" [hidden]="tab.pendentsLlegir === 0">
              <button type="button" class="btn btn-outline-primary btn-sm btn-lg btn-block" (click)="recuperaMissatgesNou()">
                <i class="fa fa-chevron-circle-up"></i>
                <span i18n>Recupera missatges nous</span>
              </button>
            </div>

            <!-- Pinta missatges chat -->
            <div *ngFor="let content of tab.contingut">
              <div class="message sent" *ngIf="content.isMe">
                <span class="saltLinia">{{content.mensaje}}</span>
                <ng-container *ngFor="let fitxer of content.fitxers">
                  <div><a class="is-link" (click)="getFitxer(fitxer.idFitxer)">{{fitxer.nomFitxer}}</a></div>
                </ng-container>
                <span class="metadata">
                  <span class="time">{{content.fechaHora | date: 'dd/MM/yyyy HH:mm'}}</span>
                </span>
              </div>
              <div class="message received"  *ngIf="!content.isMe">
                <span class="saltLinia">{{content.mensaje}}</span>
                <ng-container *ngFor="let fitxer of content.fitxers">
                  <div><a class="is-link" (click)="getFitxer(fitxer.idFitxer)">{{fitxer.nomFitxer}}</a></div>
                </ng-container>
                <span class="metadata">
                  <span class="time">{{content.fechaHora | date: 'dd/MM/yyyy HH:mm'}}</span>
                </span>
              </div>
            </div>

            <!-- Botó recupera missastges antics -->
            <div class="text-center clearBoth" [hidden]="tab.contingut.length === 0">
              <button type="button" class="btn btn-outline-primary btn-sm btn-lg btn-block" (click)="recuperaMissatgesAntic()">
                <i class="fa fa-chevron-circle-down"></i>
                <span i18n>Recupera missatges antics</span>
              </button>
            </div>
            <div class="text-center clearBoth" [hidden]="tab.contingut.length > 0">
              <span class="label label-block label-primary label-outlined" i18n>No hi ha contingut per mostrar</span>
            </div>
          </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>