import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { PermissionDialogComponent } from 'path-shared/components/permission-dialog/permission-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from 'path-shared/components/confirmation-dialog/confirmation-dialog.service';
import { ZonesAparcareTableComponent } from './components/zones-aparcare-table/zones-aparcare-table.component';
import { ZonesAparcareService } from './services/zones-aparcare.service';
import { ZonesAparcareEditorComponent } from './components/zones-aparcare-editor/zones-aparcare-editor.component';
import { ZonesAparcareFilterComponent } from './components/zones-aparcare-filter/zones-aparcare-filter.component';
import { ZonesAparcareFilter } from './models/zones-aparcare-filter';

@Component({
  selector: 'app-zones-aparcare',
  templateUrl: './zones-aparcare.component.html',
  styleUrls: ['./zones-aparcare.component.scss']
})
export class ZonesAparcareComponent implements OnInit, AfterViewInit {

  permissionDialogRef: MatDialogRef<PermissionDialogComponent>;
  activeFilters: string[] = [];
  filterData: ZonesAparcareFilter;
  somIdFoo: number;
  dataDialog: ComponentDialog = Builder.newComponentDialog();
  publicarAvailable = false;

  @ViewChild(ZonesAparcareTableComponent) zonesAparcareTable: ZonesAparcareTableComponent;

  constructor(
    private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService,
    public thisDialogRef: MatDialogRef<ZonesAparcareComponent>,
    private zonesAparcareService: ZonesAparcareService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog,
    ) {
      this.filterData = new ZonesAparcareFilter('descripcio', 'asc');
      if (data !== undefined) {
        this.dataDialog = data;
      }
    }

  ngOnInit(): void {
    this.auth.UserCanSeePage(this.auth.Pages.Zonas);

    this.zonesAparcareTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
    this.zonesAparcareTable.itemSelected.subscribe(codi => {
      this.thisDialogRef.close(codi);
    });
  }

  ngAfterViewInit() {
    if (!this.dataDialog.isDialog) {
      this.not.titleChange(this.i18n('Zonas'));
    }
  }


  edit() {
    const idSeleccionat: string = this.zonesAparcareTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, !this.publicarAvailable, secure_id);

    const ref = this.dialog.open(ZonesAparcareEditorComponent, {
      width: '50%',
      // height: '0%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data,
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.zonesAparcareTable.load(); }
    });
  }

  refresh() {
    this.zonesAparcareTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(ZonesAparcareFilterComponent, {
      width: '50%',
      // height: '90%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.zonesAparcareTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: ZonesAparcareFilter) => {
      this.zonesAparcareTable.filter.set(filter);
      this.zonesAparcareTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  // generateExcel() {
  //   this.colorsTable.generateExcel();
  // }


  publicar() {
    // this.tarifesService.publicar();

    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('Segur que vol publicar aquestes zones?'))
    .then((confirmed) => {
        if (confirmed) {
          this.zonesAparcareService.publicar().subscribe(result => {
            this.toastr.success(this.i18n('Zones publicades correctament'));
          });
        }
      });
  }

  descargarDatos() {
    this.confirmationDialogService.confirm(this.i18n('Confirmi'), this.i18n('¿Seguro que desea descargar datos? Este proceso eliminará la información aquí mostrada y obtendrá la información que hay actualmente en la aplicación'))
    .then((confirmed) => {
        if (confirmed) {
          this.zonesAparcareService.descargarDatos().subscribe(result => {
            this.toastr.success(this.i18n('Zonas descargadas correctamente'));
            this.refresh();
            this.publicarAvailable = true;
          });
        }
      });
  }
}
