import { HeaderComponent } from 'path-shared/components/header/header.component';
import { Turno } from 'app/sections/configuracio/grua/turnos/models/turnos-model';
import { Component, OnInit, ViewChild, EventEmitter, Inject } from '@angular/core';
import { RegistroTurno } from '../../models/registro-turno';
import { Builder } from 'path-shared/models/builder';
import { TurnosService } from '../../services/turnos-service';
import { ComboBoxInputAutocompleteComponent } from 'path-shared/components/form/combo-box-input-autocomplete/combo-box-input-autocomplete.component';
import { Dictionary } from 'path-shared/models/dictionary';
import { UsuarisService } from 'app/sections/usuaris/services/usuaris.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EventEmitterService } from 'path-shared/services/eventEmitter/event-emitter-service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';

@Component({
  selector: 'app-iniciar-turno',
  templateUrl: './iniciar-turno.component.html',
  styleUrls: ['./iniciar-turno.component.scss']
})
export class IniciarTurnoComponent implements OnInit {

  registroTurno: RegistroTurno;
  turnos: Turno[];


  turnosDictionary: Dictionary[];
  valorAutoCompleteTurno;
  turnosList = [];
  @ViewChild('comboTurno') comboTecnics: ComboBoxInputAutocompleteComponent;
  dialogData: ComponentDialog;
  constructor(
    private turnosService: TurnosService,
    private usuarisService: UsuarisService, 
    private eventEmitter: EventEmitterService,
    public thisDialogRef: MatDialogRef<IniciarTurnoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialog, ) {
     this.registroTurno = Builder.newRegistroTurno();
     this.valorAutoCompleteTurno = '';
     this.dialogData = data;
     }

  ngOnInit() {
    this.turnosDictionary = new Array<Dictionary>();
    this.turnosService.getAllTurnos().subscribe(x =>{
      x.forEach(element => {
        this.turnosDictionary.push(new Dictionary(element.Som_id.toString(), element.descripcion));
      });
    });

    if ( this.dialogData !== undefined && this.dialogData !== null ) {

    }
  }

  turnoSeleccionado(idTurno: string) {
    this.registroTurno.ID_TURNO = Number.parseInt(idTurno);
  }

  iniciarTurno() {
    var usuario= this.usuarisService.getCurrentUser();
    this.registroTurno.ID_USUARIO_INICIO = usuario.UserID;
    this.turnosService.iniciarTurno(this.registroTurno).subscribe(x =>{
      this.thisDialogRef.close(x);
      this.eventEmitter.onFirstComponentButtonClick();
    });
  }

}
