import { HttpSecureClient } from './../../../shared/services/http/httpSecureClient';
import { AuthGuardService } from '../../../shared/services/authentication/auth-guard.service';
import { api } from '../../../shared/configuration/urls';
import { config } from 'path-shared/configuration/config';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/services/http/httpSecureClient";
import * as i2 from "../../../shared/services/authentication/auth-guard.service";
var DashboardService = /** @class */ (function () {
    function DashboardService(http, authGuard) {
        this.http = http;
        this.authGuard = authGuard;
        this.firstTime = true;
    }
    DashboardService.prototype.getDashboard = function () {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlDashboard'];
            return this.http.get(url, null);
        }
    };
    DashboardService.prototype.getDashboardTramitacion = function () {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlDashboardTramitacion'];
            return this.http.get(url, null);
        }
    };
    DashboardService.prototype.getDashboardTramitacionResumen = function () {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlDashboardResumenTramitacion'];
            return this.http.get(url, null);
        }
    };
    DashboardService.prototype.recalcularDashTramitacion = function () {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlRecalcularDashboardTramitacion'];
            return this.http.get(url, null);
        }
    };
    DashboardService.prototype.getFilesContadoresDup = function () {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlDashBoardContadoresDup'];
            return this.http.get(url, null);
        }
    };
    DashboardService.prototype.getErrorFiles = function () {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlDashBoardErrorFiles'];
            return this.http.get(url, null);
        }
    };
    DashboardService.prototype.reloadFilesContadoresDup = function (files) {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlDashBoardMoveContadoresDup'];
            return this.http.post(url, files);
        }
    };
    DashboardService.prototype.getUnreadReleasedNotes = function () {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlUnreadReleasedNotes'];
            return this.http.get(url, null);
        }
    };
    DashboardService.prototype.updateLastReleasedNoteRead = function (SomId) {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlUpdateLastReleasedNote'].replace('{SomId}', SomId);
            return this.http.post(url, null);
        }
    };
    DashboardService.prototype.getFicherosPendienteImagen = function () {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlDashBoardFicherosPendienteImagen'];
            return this.http.get(url, null);
        }
    };
    DashboardService.prototype.reloadFicherosPendienteImagen = function (files) {
        if (this.authGuard.canActivate()) {
            var url = config.urls['apiUrl'] + api.endpoints['urlDashBoardMoveFicherosPendienteImagen'];
            return this.http.post(url, files);
        }
    };
    DashboardService.ngInjectableDef = i0.defineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.inject(i1.HttpSecureClient), i0.inject(i2.AuthGuardService)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
