   export class LocalizacionModel {

      fecha_inicio: Date;
      fecha_fin: Date;
      rango_fechas: Date[];
      vel_via: number;
      infraccion: string;
      infraccionDesc: string;
      propuesta: string;
      cod_aparat: string;
      cod_calle: string;
      calleDesc: string;
      numCalle: number;
      SOM_ID: number;
      actiu: boolean;
      prepropostes: boolean;

      constructor () {
         this.infraccion = null;
         this.infraccionDesc = '';
         this.cod_calle = null;
         this.calleDesc = '';
         this.prepropostes = false;
      }
   }
