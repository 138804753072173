/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../shared/pipes/short-date-pipe";
import * as i2 from "@ngx-translate/i18n-polyfill";
import * as i3 from "@angular/common";
import * as i4 from "./exp-relacionats.component";
import * as i5 from "../../../../services/denuncies.service";
import * as i6 from "@angular/router";
var styles_ExpRelacionatsComponent = [];
var RenderType_ExpRelacionatsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ExpRelacionatsComponent, data: {} });
export { RenderType_ExpRelacionatsComponent as RenderType_ExpRelacionatsComponent };
function View_ExpRelacionatsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "is-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onExpClicked(_v.context.$implicit.EXP_ORIGEN) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["class", "is-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onExpClicked(_v.context.$implicit.EXP_DESTINO) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), (_l()(), i0.ɵeld(11, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), i0.ɵppd(13, 1)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.EXP_ORIGEN; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.EXP_DESTINO; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.OPERACION_ID; _ck(_v, 8, 0, currVal_2); var currVal_3 = _v.context.$implicit.USUARIO; _ck(_v, 10, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v.parent, 0), _v.context.$implicit.FECHA_ALTA)); _ck(_v, 12, 0, currVal_4); }); }
export function View_ExpRelacionatsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.ShortDatePipe, [i2.I18n]), (_l()(), i0.ɵeld(1, 0, null, null, 16, "div", [["class", "table-sdw"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 15, "table", [["class", "table table-striped table-bordered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 11, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Exp. Origen"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Exp. Dest\u00ED"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Exp. Operaci\u00F3"])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Exp. Usuari"])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Exp. Data"])), (_l()(), i0.ɵeld(15, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExpRelacionatsComponent_1)), i0.ɵdid(17, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 17, 0, currVal_0); }, null); }
export function View_ExpRelacionatsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-exp-relacionats", [], null, null, null, View_ExpRelacionatsComponent_0, RenderType_ExpRelacionatsComponent)), i0.ɵdid(1, 114688, null, 0, i4.ExpRelacionatsComponent, [i5.DenunciesService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExpRelacionatsComponentNgFactory = i0.ɵccf("app-exp-relacionats", i4.ExpRelacionatsComponent, View_ExpRelacionatsComponent_Host_0, { data: "data" }, {}, []);
export { ExpRelacionatsComponentNgFactory as ExpRelacionatsComponentNgFactory };
