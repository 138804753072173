import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { EnviosPagedRequestDto } from '../models/envios-filter';

const defaultSortParam = 'idEnvio';
const defaultSortDirection = 'asc';

@Injectable()
export class EnviosFilterService extends CustomFilterService {
  public createFilterParams(filter: EnviosPagedRequestDto, start: number, pageSize: number, sortField: string, sortDir: string ) {
if (filter.fechaCreacionInicio !== undefined && filter.fechaCreacionInicio !== null) {
  filter.fechaCreacionInicio.setHours(23, 59, 59);
}
if (filter.fechaEnvioInicio !== undefined && filter.fechaEnvioInicio !== null) {
  filter.fechaEnvioInicio.setHours(23, 59, 59);
}
if (filter.fechaNotificacionBOEInicio !== undefined && filter.fechaNotificacionBOEInicio !== null) {
  filter.fechaNotificacionBOEInicio.setHours(23, 59, 59);
}
if (filter.fechaCreacionFin !== undefined && filter.fechaCreacionFin !== null) {
  filter.fechaCreacionFin.setHours(23, 59, 59);
}
if (filter.fechaEnvioFin !== undefined && filter.fechaEnvioFin !== null) {
  filter.fechaEnvioFin.setHours(23, 59, 59);
}
if (filter.fechaNotificacionBOEFin !== undefined && filter.fechaNotificacionBOEFin !== null) {
  filter.fechaNotificacionBOEFin.setHours(23, 59, 59);
}
    return new HttpParams()
    .append('idEnvio', (filter.idEnvio ? filter.idEnvio.toString() : ''))
    .append('tipoEnvio', (filter.tipoEnvio ? filter.tipoEnvio.toString() : ''))
    .append('fechaCreacionInicio',  Reflection.empty(filter.fechaCreacionInicio) ? null :  typeof(filter.fechaCreacionInicio) === 'string' ?
    filter.fechaCreacionInicio : filter.fechaCreacionInicio.toDateString())
    .append('fechaCreacionFin',  Reflection.empty(filter.fechaCreacionFin) ? null :  typeof(filter.fechaCreacionFin) === 'string' ?
    filter.fechaCreacionFin : filter.fechaCreacionFin.toDateString())
    .append('usuarioCreacion', (filter.usuarioCreacion ? filter.usuarioCreacion.toString() : ''))
    .append('fechaEnvioInicio',  Reflection.empty(filter.fechaEnvioInicio) ? null :  typeof(filter.fechaEnvioInicio) === 'string' ?
    filter.fechaEnvioInicio : filter.fechaEnvioInicio.toDateString())
    .append('fechaEnvioFin',  Reflection.empty(filter.fechaEnvioFin) ? null :  typeof(filter.fechaEnvioFin) === 'string' ?
    filter.fechaEnvioFin : filter.fechaEnvioFin.toDateString())
    .append('usuarioEnvio', (filter.usuarioEnvio ? filter.usuarioEnvio.toString() : ''))
    .append('pdfBOE', (filter.pdfBOE ? filter.pdfBOE.toString() : ''))
    .append('fechaNotificacionBOEInicio',  Reflection.empty(filter.fechaNotificacionBOEInicio) ? null :  typeof(filter.fechaNotificacionBOEInicio) === 'string' ?
    filter.fechaNotificacionBOEInicio : filter.fechaNotificacionBOEInicio.toDateString())
    .append('fechaNotificacionBOEFin',  Reflection.empty(filter.fechaNotificacionBOEFin) ? null :  typeof(filter.fechaNotificacionBOEFin) === 'string' ?
    filter.fechaNotificacionBOEFin : filter.fechaNotificacionBOEFin.toDateString())
    .append('usuarioBOE', (filter.usuarioBOE ? filter.usuarioBOE.toString() : ''))
    .append('numeroPublicacionBOE', (filter.numeroPublicacionBOE ? filter.numeroPublicacionBOE.toString() : ''))
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir);
  }
}
