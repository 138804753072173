<button class="actions-bar__button dropdown-toggle" type="button" id="dropdownMenuButton" 
  [disabled]="this.disabled" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <div [class]="this.badgeClass + ' ' + this.badgeColor" [hidden]="!this.badgeActive">{{this.badge}}</div>
  <span [class]="this.colorClass">
    <img *ngIf="this.src !== undefined" class="navbar-brand-full" [src]="this.src"  height="20px" >
    <i *ngIf="this.src === undefined" [class]="this.iconClass"></i>
  </span>
  <span class="actions-bar__button-label">{{this.text}}</span>
</button>

<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
  <a *ngIf="numeroSubmenus >= 1" class="dropdown-item" (click)="this.clickEvent($event, 'boto1')">{{this.label1}}</a>
  <a *ngIf="numeroSubmenus >= 2" class="dropdown-item" (click)="this.clickEvent($event, 'boto2')">{{this.label2}}</a>
  <a *ngIf="numeroSubmenus >= 3" class="dropdown-item" (click)="this.clickEvent($event, 'boto3')">{{this.label3}}</a>
</div>