import { EventEmitter, OnInit } from '@angular/core';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { I18n } from '@ngx-translate/i18n-polyfill';
var EnvioBoePageComponent = /** @class */ (function () {
    function EnvioBoePageComponent(auth, i18n) {
        this.auth = auth;
        this.i18n = i18n;
        this.dropdownSettings = {
            idField: 'item_id',
            textField: 'item_text',
            itemsShowLimit: 2,
            singleSelection: true
        };
        this.dropdownEnvios = [
            { 'item_id': 0, 'item_text': this.i18n('Denuncias') },
            { 'item_id': 1, 'item_text': this.i18n('Alegaciones') },
            { 'item_id': 2, 'item_text': this.i18n('Recurso alegaciones') },
            { 'item_id': 3, 'item_text': this.i18n('Resoluciones') },
            { 'item_id': 4, 'item_text': this.i18n('Sanciones firmes') }
        ];
        this.valueChange = new EventEmitter();
        this.applyFilter = new EventEmitter();
        this.publish = new EventEmitter();
        this.historico = new EventEmitter();
    }
    Object.defineProperty(EnvioBoePageComponent.prototype, "value", {
        get: function () {
            return this.val;
        },
        set: function (val) {
            var _this = this;
            this.val = val;
            setTimeout(function () {
                _this.valueChange.emit(_this.val);
            }, 10);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnvioBoePageComponent.prototype, "resumen", {
        get: function () {
            return this.valueResumen;
        },
        set: function (valueResumen) {
            this.valueResumen = valueResumen;
        },
        enumerable: true,
        configurable: true
    });
    EnvioBoePageComponent.prototype.ngOnInit = function () {
    };
    EnvioBoePageComponent.prototype.filtrar = function () {
        this.applyFilter.emit();
    };
    EnvioBoePageComponent.prototype.publicar = function () {
        this.publish.emit();
    };
    EnvioBoePageComponent.prototype.history = function () {
        this.historico.emit();
    };
    EnvioBoePageComponent.prototype.onDropdownItemSelect = function (item) {
        this.value.envioDenuncias = false;
        this.value.envioAlegaciones = false;
        this.value.envioRecursoAlegaciones = false;
        this.value.envioSancionesFirmes = false;
        this.value.envioResoluciones = false;
        switch (item.item_id) {
            case 0:
                this.value.envioDenuncias = true;
                break;
            case 1:
                this.value.envioAlegaciones = true;
                break;
            case 2:
                this.value.envioRecursoAlegaciones = true;
                break;
            case 3:
                this.value.envioResoluciones = true;
                break;
            case 4:
                this.value.envioSancionesFirmes = true;
                break;
        }
        this.filtrar();
    };
    return EnvioBoePageComponent;
}());
export { EnvioBoePageComponent };
