import { Injectable } from '@angular/core';
import { CustomFilterService } from 'path-shared/services/datasource/custom-filter-service';
import { HttpParams } from '@angular/common/http';
import { Reflection } from 'path-shared/services/reflection/reflection';
import { DeltacarHistoricoFilter } from '../models/deltacar-historico-filter';
import { DatePipe } from '@angular/common';

const defaultSortParam = 'data';
const defaultSortDirection = 'desc';

@Injectable({
  providedIn: 'root'
})
export class DeltacarHistoricoFilterService  extends CustomFilterService {
  public createFilterParams(filter: DeltacarHistoricoFilter, start: number, pageSize: number, sortField: string, sortDir: string ) {
    const datepipe: DatePipe = new DatePipe('es-ES');

    return new HttpParams()
    .append('matricula', filter.matricula ? filter.matricula : '')
    .append('dataInici', Reflection.empty(filter.dataInici) ? null : datepipe.transform(filter.dataInici, 'yyyyMMdd'))
    .append('dataFi', Reflection.empty(filter.dataFi) ? null : datepipe.transform(filter.dataFi, 'yyyyMMdd'))
    .append('estado', filter.estado)
    .append('start', start.toString())
    .append('size', pageSize.toString())
    .append('sortparam', sortField === undefined ? defaultSortParam : sortField)
    .append('sortdir', sortDir === '' ? defaultSortDirection : sortDir)
    ;
  }

  public createLoadParams(content: string) {
    return new HttpParams()
    .append('content', content ? content : '')
    ;
  }


}
