import { TarifesFilter } from './../../../../tiquets/tarifes/models/tarifes-filter';
import { TarifasGruaFilterComponent } from './../components/tarifas-grua-filter/tarifas-grua-filter.component';
import { TarifasGruaEditorComponent } from './../components/tarifas-grua-editor/tarifas-grua-editor.component';

import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AuthorizationService } from 'path-shared/services/authorization/authorization-service';
import { ComponentDialog } from 'path-shared/models/component-dialog.model';
import { Builder } from 'path-shared/models/builder';
import { NotificationService } from 'path-shared/services/notification/notification.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { TarifasGruaFilter } from '../models/tarifa-grua-filter';
import { TarifasGruaTableComponent } from '../components/tarifas-grua-table/tarifas-grua-table.component';


@Component({
  selector: 'app-tarifas-grua',
  templateUrl: './tarifas-grua.component.html',
  styleUrls: ['./tarifas-grua.component.scss']
})
export class TarifasGruaComponent implements OnInit {

  filterData: TarifasGruaFilter;
  @ViewChild(TarifasGruaTableComponent) tarifasGruaTable: TarifasGruaTableComponent;
  constructor(  private dialog: MatDialog,
    public auth: AuthorizationService,
    private i18n: I18n,
    private not: NotificationService){ 
      this.filterData = new TarifasGruaFilter('descripcio', 'asc');
    }

  ngOnInit() {
    this.auth.UserCanSeePage(this.auth.Pages.TarifesGrua);
    this.tarifasGruaTable.itemClicked.subscribe(secure_id => {
      this.update(secure_id);
    });
  }
  edit() {
    const idSeleccionat: string = this.tarifasGruaTable.displayedItems['checkedItems'][0];
    this.update(idSeleccionat);
  }

  create() {
    this.update(null);
  }

  update(secure_id: string) {
    const data: ComponentDialog = Builder.newComponentDialog(true, false, secure_id);

    const ref = this.dialog.open(TarifasGruaEditorComponent, {
      width: '40%',
      closeOnNavigation: false,
      autoFocus: true,
      data: data
    });

    ref.afterClosed().subscribe(updated => {
      if (updated) { this.tarifasGruaTable.load(); }
    });
  }

  refresh() {
    this.tarifasGruaTable.load();
  }

  onFilterClicked() {
    const ref = this.dialog.open(TarifasGruaFilterComponent, {
      width: '40%',
      closeOnNavigation: false,
      autoFocus: true,
      data: {
        'filter': this.tarifasGruaTable.filter
      }
    });

    ref.componentInstance.notify.subscribe((filter: TarifasGruaFilter) => {
      this.tarifasGruaTable.filter.set(filter);
      this.tarifasGruaTable.load();
    });
  }

  onRowSelected(somid) {
    this.update(somid);
  }

  generateExcel() {
    this.tarifasGruaTable.generateExcel();
  }
  

}
