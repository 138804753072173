/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./nav-item.component";
var styles_NavItemComponent = [i0.styles];
var RenderType_NavItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavItemComponent, data: {} });
export { RenderType_NavItemComponent as RenderType_NavItemComponent };
function View_NavItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "navbar-brand-full"], ["height", "14,55px"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; _ck(_v, 0, 0, currVal_0); }); }
function View_NavItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "nav-icon ", _co.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_NavItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "a", [], [[8, "className", 0], [8, "id", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.itemClicked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavItemComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavItemComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], [[8, "hidden", 0], [8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _ck(_v, 3, 0, _co.link); _ck(_v, 2, 0, currVal_4); var currVal_5 = (_co.src !== undefined); _ck(_v, 5, 0, currVal_5); var currVal_6 = (_co.src === undefined); _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getClass(); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.getId(), ""); var currVal_2 = i1.ɵnov(_v, 2).target; var currVal_3 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_7 = _co.text; _ck(_v, 8, 0, currVal_7); var currVal_8 = !_co.hasBadge(); var currVal_9 = i1.ɵinlineInterpolate(1, "badge ", _co.badgeClass, ""); _ck(_v, 9, 0, currVal_8, currVal_9); var currVal_10 = _co.badge; _ck(_v, 10, 0, currVal_10); }); }
export function View_NavItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav-item", [], null, null, null, View_NavItemComponent_0, RenderType_NavItemComponent)), i1.ɵdid(1, 114688, null, 0, i4.NavItemComponent, [i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavItemComponentNgFactory = i1.ɵccf("app-nav-item", i4.NavItemComponent, View_NavItemComponent_Host_0, { text: "text", icon: "icon", link: "link", linkActive: "linkActive", badge: "badge", badgeClass: "badgeClass", src: "src" }, { onClickNavItem: "onClickNavItem" }, []);
export { NavItemComponentNgFactory as NavItemComponentNgFactory };
